import React, { useCallback, useEffect, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Label } from "@/components/Label";
import MakoListagem from "@/components/MakoListagem";

import { ModalApontamentoVisualizar } from "./visualizarApontamento";
import { ModalFaturamento } from "./modalFaturamento";
import { DadosCabecalho } from "./dadosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useContratoServico from "@/hooks/useContratoServico";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const FormFaturamentos = () => {
    const initialModal = {
        visible: false,
        title: "",
        content: null,
    };
    const [indicadores, setIndicadores] = useState({ sem_apontamento: 0, faturar: 0 });
    const [selecionados, setSelecionados] = useState(null);
    const [modal, setModal] = useState(initialModal);
    const [loading, setLoading] = useState(false);

    const { httpGet, httpPost } = useHttp();
    const { showSuccess } = useToast();
    const { contrato } = useContratoServico();

    const menuAcoesRef = useRef(null);
    const listagemApontamentos = useRef(null);

    useClearRefs(menuAcoesRef, listagemApontamentos);

    const fecharModal = () => {
        setModal((prev) => ({ ...prev, visible: false }));
    };

    const actionBodyTemplateApontamentos = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon={MAKO_ICONS.VISUALIZAR}
                    className="p-button-rounded p-button-info"
                    onClick={() => {
                        setModal({
                            visible: true,
                            title: "Visualizar Apontamento",
                            content: (
                                <ModalApontamentoVisualizar
                                    apontamento={{
                                        id: rowData.id,
                                        tipo: rowData.servico_contratado.servico.tipo,
                                        data_inicial: rowData.data_inicial,
                                        data_final: rowData.data_final,
                                        total_horas: rowData.total_horas,
                                        custo: rowData.custo,
                                        valor: rowData.valor,
                                        valor_nfs: rowData.valor_nfs,
                                        descricao: rowData.descricao,
                                        servico_contratado: {
                                            id: rowData.servico_contratado.id,
                                            label: `${rowData.servico_contratado.servico.codigo} | ${rowData.servico_contratado.servico.descricao}`,
                                        },
                                    }}
                                />
                            ),
                        });
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunasApontamentos = [
        {
            selectionMode: "multiple",
            style: { width: "5%" },
        },
        {
            field: "servico_contratado.servico_municipio.descricao",
            header: "Descrição do serviço",
        },
        {
            field: "id",
            header: "N° apontamento",
        },
        {
            field: "data_inicial",
            header: "Data Inical",
            dateFormat: "dd/MM/yyy",
            align: "center",
            style: { width: "10%" },
        },
        {
            field: "data_final",
            header: "Data final",
            dateFormat: "dd/MM/yyy",
            align: "center",
            style: { width: "10%" },
        },
        {
            field: "valor",
            header: "Valor",
            money: true,
        },
        {
            field: "valor_nfs",
            header: "Valor NF",
            money: true,
        },
        {
            field: "action",
            header: "Ações",
            align: "center",
            style: { width: "10%" },
            action: (e) => actionBodyTemplateApontamentos(e),
        },
    ];

    const handleSelecionar = (data, remove) => {
        if (!remove) setSelecionados((prev) => (prev === null ? [data] : [...prev, data]));
        else setSelecionados((prev) => prev.filter((items) => items.id !== data.id));
    };

    const atualizarListagens = useCallback(() => {
        listagemApontamentos.current?.buscarDados();
    }, []);

    const atualizarIndicadores = useCallback(
        async (signal) => {
            const fetchFaturar = async () => {
                let status = 400;
                let data = null;
                const handlers = {
                    200: ({ data: d }) => {
                        status = 200;
                        data = d;
                    },
                };
                await httpGet(
                    {
                        url: `/servicos/apontamento-servico/?servico_contratado__contrato_id=${contrato.id}&faturamento_contrato__isnull=True`,
                        signal,
                    },
                    handlers
                );
                return { status, data };
            };
            const fetchApontamentos = async () => {
                let status = 400;
                let data = null;
                const handlers = {
                    200: ({ data: d }) => {
                        status = 200;
                        data = d;
                    },
                };
                await httpGet(
                    {
                        url: `/servicos/servicos-contrato/?query={id,servico_municipio}&servico_municipio__servico__requer_apontamento=false&contrato=${contrato.id}`,
                        signal,
                    },
                    handlers
                );
                return { status, data };
            };
            setLoading(true);
            const [respostaFaturar, respostaSemApontamento] = await Promise.all([
                fetchFaturar(signal),
                fetchApontamentos(signal),
            ]);
            setLoading(false);
            setIndicadores((prev) => {
                if (respostaFaturar.status === 200)
                    prev.faturar = respostaFaturar.data.results.reduce((total, item) => total + item.valor, 0);
                if (respostaSemApontamento.status === 200)
                    prev.sem_apontamento = respostaSemApontamento.data.results.reduce(
                        (total, item) => total + item.servico_municipio.servico.valor_venda,
                        0
                    );
                return { ...prev };
            });
        },
        [contrato, httpGet]
    );

    const handleFaturamento = useCallback(
        async (values, apontamentoServico = true) => {
            const handlers = {
                200: () => {
                    setSelecionados(null);
                    atualizarListagens();
                    atualizarIndicadores();
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Faturamento gerado com sucesso!",
                        life: 1500,
                    });
                },
            };
            const body = apontamentoServico
                ? { apontamentos: selecionados.map((s) => (s = s.id)), ...values }
                : { ...values };
            await httpPost({ url: `/servicos/gerar-faturamento-contrato/${contrato.id}`, body }, handlers);
            fecharModal();
        },
        [selecionados, httpPost, contrato, atualizarListagens, atualizarIndicadores, showSuccess]
    );

    useEffect(() => {
        const controller = new AbortController();
        atualizarIndicadores(controller.signal);
        return () => controller.abort();
    }, [atualizarIndicadores]);

    return (
        <div>
            <DadosCabecalho />
            <div className="p-fluid p-formgrid p-grid p-my-4">
                <div className="p-field p-col-12 p-md-6">
                    <div className="p-fluid p-formgrid p-grid p-mt-3">
                        <div className="p-field p-col-12 p-md-4">
                            <Button
                                type="button"
                                className="p-button-success"
                                label="Faturar serviço"
                                onClick={() => {
                                    setModal({
                                        visible: true,
                                        title: "Gerar faturamento",
                                        content: (
                                            <ModalFaturamento onClose={fecharModal} onFinish={handleFaturamento} />
                                        ),
                                    });
                                }}
                                disabled={!selecionados?.length}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Button
                                type="button"
                                className="p-button-success"
                                label="Faturar contrato"
                                onClick={() => {
                                    setModal({
                                        visible: true,
                                        title: "Gerar faturamento",
                                        content: (
                                            <ModalFaturamento
                                                onClose={fecharModal}
                                                onFinish={(e) => handleFaturamento(e, false)}
                                            />
                                        ),
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <div className="p-fluid p-formgrid p-grid" style={{ justifyContent: "end" }}>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="sem_apontamento" label="Sem apontamento" />
                            <div className="p-inputgroup">
                                <Button
                                    type="button"
                                    className="p-button-info"
                                    icon="pi pi-sync"
                                    loading={loading}
                                    onClick={() => atualizarIndicadores()}
                                />
                                <MakoInputMoeda
                                    id="sem_apontamento"
                                    name="sem_apontamento"
                                    valueMoeda={indicadores.sem_apontamento}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="faturar" label="Valor a faturar" />
                            <MakoInputMoeda id="faturar" name="faturar" valueMoeda={indicadores.faturar} disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-my-4">
                <MakoListagem
                    ref={listagemApontamentos}
                    titulo="Apontamentos para faturar"
                    colunas={colunasApontamentos}
                    urlPesquisa={`/servicos/apontamento-servico/?servico_contratado__contrato_id=${contrato.id}&faturamento_contrato__isnull=True`}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        selectionMode: "multiple",
                        selection: selecionados,
                        onRowSelect: (e) => handleSelecionar({ ...e.data }),
                        onRowUnselect: (e) => handleSelecionar(e.data, "rmv"),
                    }}
                />
            </div>
            <Dialog
                visible={modal.visible}
                header={modal.title}
                style={{ width: "60vw" }}
                onHide={() => setModal((prev) => ({ ...prev, visible: false }))}
            >
                {modal.content}
            </Dialog>
        </div>
    );
};

export default React.memo(FormFaturamentos);
