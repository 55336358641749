import React, { forwardRef, useCallback, useImperativeHandle } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from "classnames";

import { InputText } from "primereact/inputtext";

import { MakoDropdownEstoques, MakoInputPeriodo, MakoInputQuantidadeSku } from "@/components/MakoInputs";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoButton } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { dataToStr } from "@/assets/util/datas";
import { TIPO_CHOICE_LOTE_SERIAL } from "@/assets/constants/constants";

const INITIAL_VALUES = {
    fabricacao__gte: null,
    fabricacao__lte: null,
    vencimento__gte: null,
    vencimento__lte: null,
    saldo__gte: null,
    saldo__lte: null,
    enderecamento: "",
    complemento: "",
    centro_estocagem: null,
    sku__lote_serial: null,
};

const Component = ({ cancelCallback = () => {}, successCallback = () => {} }, ref) => {
    const { setFieldValue, setValues, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values, helpers) {
        try {
            let filtros = {};
            for (const [k, v] of Object.entries(values)) {
                if (![undefined, null, ""].includes(v)) {
                    if (["fabricacao__gte", "fabricacao__lte", "vencimento__gte", "vencimento__lte"].includes(k)) {
                        filtros[k] = dataToStr(v, "yyyy-MM-dd");
                    } else {
                        filtros[k] = v;
                    }
                }
            }
            successCallback(filtros);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                helpers.setErrors(errorMessages);
            }
        }
    }

    const preencher = useCallback(
        (values) => {
            setValues({ ...INITIAL_VALUES, ...values });
        },
        [setValues]
    );

    useImperativeHandle(ref, () => ({ preencher }), [preencher]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <MakoInputPeriodo
                    label="Fabricados entre"
                    nameInicio="fabricacao__gte"
                    nameFinal="fabricacao__lte"
                    valueInicio={formik.values.fabricacao__gte}
                    valueFinal={formik.values.fabricacao__lte}
                    errorInicio={formik.errors.fabricacao__gte}
                    errorFinal={formik.errors.fabricacao__lte}
                    onChangeInicio={formik.handleChange}
                    onChangeFinal={formik.handleChange}
                />
                <MakoInputPeriodo
                    label="Vencimento entre"
                    nameInicio="vencimento__gte"
                    nameFinal="vencimento__lte"
                    valueInicio={formik.values.vencimento__gte}
                    valueFinal={formik.values.vencimento__lte}
                    errorInicio={formik.errors.vencimento__gte}
                    errorFinal={formik.errors.vencimento__lte}
                    onChangeInicio={formik.handleChange}
                    onChangeFinal={formik.handleChange}
                />
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="saldo__gte" label="Saldo entre" />
                    <MakoInputQuantidadeSku
                        id="saldo__gte"
                        name="saldo__gte"
                        value={formik.values.saldo__gte}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.saldo__gte })}
                    />
                    {formik.errors.saldo__gte && <small className="p-error">{formik.errors.saldo__gte}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "5px" }}>
                    <MakoInputQuantidadeSku
                        id="saldo__lte"
                        name="saldo__lte"
                        value={formik.values.saldo__lte}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.saldo__lte })}
                    />
                    {formik.errors.saldo__lte && <small className="p-error">{formik.errors.saldo__lte}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid ">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="sku__lote_serial" label="Tipo de lote / serial" />
                    <Dropdown
                        id="sku__lote_serial"
                        name="sku__lote_serial"
                        options={TIPO_CHOICE_LOTE_SERIAL}
                        optionValue="value"
                        optionLabel="label"
                        value={formik.values.sku__lote_serial}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.sku__lote_serial })}
                    />
                    {formik.errors.sku__lote_serial && (
                        <small className="p-error">{formik.errors.sku__lote_serial}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="centro_estocagem" label="Centro de estocagem" />
                    <MakoDropdownEstoques
                        id="centro_estocagem"
                        name="centro_estocagem"
                        optionValue="id"
                        value={formik.values.centro_estocagem}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                    />
                    {formik.errors.centro_estocagem && (
                        <small className="p-error">{formik.errors.centro_estocagem}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="enderecamento" label="Endereçamento" />
                    <InputText
                        id="enderecamento"
                        name="enderecamento"
                        value={formik.values.enderecamento}
                        onInput={formik.handleChange}
                        maxLength={20}
                        className={classNames({ "p-invalid": formik.errors.enderecamento })}
                    />
                    {formik.errors.enderecamento && <small className="p-error">{formik.errors.enderecamento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="complemento" label="Complemento" />
                    <InputText
                        id="complemento"
                        name="complemento"
                        value={formik.values.complemento}
                        onInput={formik.handleChange}
                        maxLength={20}
                        className={classNames({ "p-invalid": formik.errors.complemento })}
                    />
                    {formik.errors.complemento && <small className="p-error">{formik.errors.complemento}</small>}
                </div>
            </div>
            <MakoActionsButtons className="p-jc-end">
                <MakoButton
                    icon={MAKO_ICONS.FILTRAR}
                    label="Filtrar"
                    className="p-button p-button-info"
                    type="submit"
                />
                <MakoButton
                    icon={MAKO_ICONS.LIMPAR_FILTROS}
                    label="Limpar"
                    className="p-button p-button-warning"
                    type="reset"
                    onClick={formik.resetForm}
                />
                <MakoButton
                    icon={MAKO_ICONS.CANCEL}
                    label="Cancelar"
                    className="p-button p-button-danger"
                    type="reset"
                    onClick={cancelCallback}
                />
            </MakoActionsButtons>
        </form>
    );
};

const Form = forwardRef(Component);

export const FiltroAvancado = { Form, FILTROS: INITIAL_VALUES };
