import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { BlockUI } from "primereact/blockui";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { useFormik } from "formik";
import { LoteInventarioComponente } from "./formLoteInventario/form";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import MakoListagem from "@/components/MakoListagem";
import useAuth from "@/hooks/useAuth";
import useLoading from "@/hooks/useLoading";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import * as Yup from "yup";
import { PageBase } from "@/components/PageBase";

export const ItemInventarioForm = (props) => {
    const [loteInventario, setLoteInventario] = useState(null);
    const listagemRef = useRef(null);
    const history = useHistory();
    const { user } = useAuth();
    const { showLoading, hideLoading } = useLoading();
    const { httpPost, httpDelete } = useHttp();
    const { showSuccess, showError } = useToast();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            sku: null,
            unidade_medida: "",
            quantidade_fisica: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                quantidade_fisica: Yup.number()
                    .when("sku", {
                        is: (val) => val && val.permite_fracionamento === true,
                        then: Yup.number().min(0.001, "O campo 'quantidade' não pode ser ZERO."),
                        otherwise: Yup.number().min(1, "O campo 'quantidade' não pode ser ZERO."),
                    })
                    .required("O campo 'quantidade' não pode ser ZERO."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const itemInventario = {
                ...values,
                sku: values.sku.id,
                unidade_medida: values.unidade_medida.id,
                lote_inventario: loteInventario.id,
                usuario: user.id,
            };

            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: `Item de inventário lançado com sucesso no lote ${loteInventario.id || 0}`,
                        life: 1500,
                    });
                    formik.resetForm();
                },
            };

            showLoading();
            await httpPost({ url: "/produtos/itens-inventario/", body: itemInventario }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const deletarItemInventario = async (itemInventario) => {
        const handlers = {
            204: () => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Item removido com sucesso!",
                    life: 1500,
                });
                listagemRef.current.buscarDados();
            },
        };

        await httpDelete({ url: `/produtos/itens-inventario/${itemInventario.id}` }, handlers);
    };

    const confirmarDelete = (rowData) => {
        confirmDialog({
            message: (
                <span>
                    Confirma a remoção do item <b>{rowData.sku.descricao_derivada}</b> do lote de inventário?
                </span>
            ),
            header: "Confirmação",
            icon: "pi pi-exclamation-triangle",
            accept: () => deletarItemInventario(rowData),
            acceptLabel: "Confirmar",
            acceptClassName: "p-button-danger",
            rejectLabel: "Cancelar",
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2"
                    onClick={() => confirmarDelete(rowData)}
                />
            </div>
        );
    };

    const calcularDiferencaSaldoInventario = (rowData) => {
        const diferenca = rowData.quantidade_fisica - 0;

        if (diferenca < 0) {
            return <span style={{ color: "#ff5252" }}>{diferenca}</span>;
        }

        return <span>{diferenca}</span>;
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" } },
        { field: "sku.descricao_derivada", header: "Descrição do produto" },
        { field: "unidade_medida.sigla", header: "Un", style: { width: "8%" } },
        { field: "quantidade_fisica", header: "Qtd. física", style: { width: "10%" } },
        { field: "quantidade_sistema", header: "Qtd. sistema", style: { width: "10%" }, action: (e) => <span>0</span> },
        {
            field: "diferenca",
            header: "Diferença",
            style: { width: "10%" },
            action: (e) => calcularDiferencaSaldoInventario(e),
        },
        { field: "actions", header: "Ações", style: { width: "5%" }, action: (e) => actionBodyTemplate(e) },
    ];

    useEffect(() => {
        if (props.location.state?.loteInventario) {
            setLoteInventario(props.location.state.loteInventario);
        }
    }, [props.location.state.loteInventario, setLoteInventario]);

    useEffect(() => {
        if (formik.values.sku && formik.values.sku instanceof Object) {
            const { unidade_padrao } = formik.values.sku;
            setFieldValue("unidade_medida", unidade_padrao);
        }
    }, [formik.values.sku, setFieldValue]);

    return (
        <PageBase>
            <h3>Lançamento de itens no lote de inventário físico</h3>
            <BlockUI blocked>
                <LoteInventarioComponente loteInventario={loteInventario} />
            </BlockUI>
            <br />
            <br />
            <form onSubmit={formik.handleSubmit}>
                <MakoBuscaSkuPersonalizada
                    skuValue={formik.values.sku}
                    skuChange={(e) => setFieldValue("sku", e)}
                    skuError={formik.errors.sku}
                    categoriaId={loteInventario?.categoria?.id}
                />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="unidade-medida">Unidade de medida</label>
                        <InputText
                            id="unidade-medida"
                            name="unidade_medida"
                            value={formik.values.unidade_medida ? formik.values.unidade_medida.nome : ""}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="quantidade">Quantidade física *</label>
                        <InputNumber
                            id="quantidade"
                            name="quantidade_fisica"
                            mode="decimal"
                            minFractionDigits={formik.values.sku?.permite_fracionamento ? 3 : 0}
                            maxFractionDigits={formik.values.sku?.permite_fracionamento ? 3 : 0}
                            value={formik.values.quantidade_fisica}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade_fisica })}
                        />
                        {formik.errors.quantidade_fisica && (
                            <small className="p-error">{formik.errors.quantidade_fisica}</small>
                        )}
                    </div>
                </div>
                <p>* Campos obrigatórios.</p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Adicionar" icon="pi pi-plus" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        label="Limpar"
                        type="button"
                        icon="pi pi-trash"
                        className="p-button-warning p-mr-2"
                        onClick={() => formik.resetForm()}
                    />
                    <Button
                        label="Voltar"
                        icon="pi pi-angle-double-left"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={() => history.push("/estoque/inventario/lotes-inventario")}
                    />
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={loteInventario && `/produtos/itens-inventario/?lote_inventario=${loteInventario.id}`}
                configTabela={{
                    lazy: true,
                    paginator: true,
                }}
            />
        </PageBase>
    );
};
