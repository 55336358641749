import React, { useRef } from "react";
import { Button } from "primereact/button";
import { ModalImportarTributos } from "./modals/importarTributos";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import { ModalRegraOperacaoFiscalSKU } from "./regras/sku";

export const ProdutosMercadoriasForm = ({ opFiscal }) => {
    const listagemRef = useRef(null);
    const modalRegraSKURef = useRef(null);
    const modalImpTributosRef = useRef(null);

    useClearRefs(listagemRef, modalRegraSKURef, modalImpTributosRef);

    const exibirEditarItem = async (item) => {
        const {
            sku,
            skuoperacaofiscalbr_set: [skuOfBr],
            skuoperacaofiscaluf_set: [skuOfUf],
        } = item;
        const regraSkuOfBr = {
            ...skuOfBr,
            cst_ipi: skuOfBr?.cst_ipi?.id,
            cst_pis: skuOfBr?.cst_pis?.id,
            cst_cofins: skuOfBr?.cst_cofins?.id,
            enquadramento_ipi: skuOfBr?.enquadramento_ipi?.id,
        };
        const regraSkuOfUf = {
            ...skuOfUf,
            cst_icms: skuOfUf?.cst_icms?.id,
        };
        modalRegraSKURef.current?.abrirModal(sku, regraSkuOfBr, regraSkuOfUf);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    onClick={() => exibirEditarItem(rowData)}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_EDITAR]}
                    componente={BotaoDelete}
                    url="/fiscal/itens-operacoes-fiscais/"
                    classNames="p-mb-1"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente remover o item <b>{rowData.sku.descricao_reduzida}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O produto / mercadoria não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Adicionar"
                icon="pi pi-plus"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={() => modalRegraSKURef.current?.abrirModal()}
            />
            <Button
                label="Importar tributos"
                icon="pi pi-upload"
                className="p-button-help p-button-outlined p-mr-2"
                onClick={() => modalImpTributosRef.current?.abrirModal()}
            />
        </>
    );

    const colunas = [
        { field: "sku.codigo", header: "Código", style: { width: "20%" } },
        { field: "sku.descricao_reduzida", header: "Produto/Mercadoria" },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <>
            <MakoListagem
                ref={listagemRef}
                titulo="Produtos e mercadorias permitidos"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/fiscal/itens-operacoes-fiscais?operacao_fiscal=${opFiscal?.id}`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
            <ModalRegraOperacaoFiscalSKU
                ref={modalRegraSKURef}
                operacaoFiscal={opFiscal}
                onGravar={() => listagemRef.current?.buscarDados()}
            />
            <ModalImportarTributos ref={modalImpTributosRef} operacaoFiscalId={opFiscal?.id} />
        </>
    );
};
