import React from "react";
import { InputText } from "primereact/inputtext";

export const validarIntervaloParcelas = (intervaloParcelas, numeroParcelas) => {
    if (intervaloParcelas && intervaloParcelas.split(",").length === numeroParcelas) return true;

    return false;
};

export const validarProgressaoIntervaloParcelas = (intervaloParcelas) => {
    if (!intervaloParcelas) return false;

    const intervalo = intervaloParcelas.split(",");

    for (let x = 0; x < intervalo.length; x++) {
        if (x < intervalo.length) {
            if (parseInt(intervalo[x]) > parseInt(intervalo[x + 1])) {
                return false;
            }
        }
    }

    return true;
};

export const MakoInputIntervaloParcelas = (props) => {
    return <InputText placeholder="Ex: 10,20,30" keyfilter={/[0-9,]/} {...props} />;
};
