import { useEffect, useRef } from "react";

const useClearRefs = (...refs) => {
    const cleanupRefs = useRef(refs);

    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            cleanupRefs.current.forEach((ref) => {
                if (typeof ref === "function") {
                    ref(null);
                } else if (ref !== null) {
                    ref.current = null;
                }
            });
        };
    }, []);

    return cleanupRefs;
};

export default useClearRefs;
