import React, { useCallback, useState } from "react";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useAuth from "@/hooks/useAuth";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";

const queryClient = new QueryClient();

function Componente() {
    const [visivel, setVisivel] = useState();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { user, reloadPermissions } = useAuth();
    const { httpGet, httpPatch } = useHttp();
    const { empresaSelecionada, alterarEmpresaSelecionada } = useEmpresa();

    const alterarEmpresaPadrao = useCallback(
        async (empresaPadraoId) => {
            if (user?.usuario_empresapadrao && empresaPadraoId) {
                const body = {
                    empresa_padrao: empresaPadraoId,
                };
                const { id } = user.usuario_empresapadrao;
                await httpPatch({ url: `/pessoas/usuarios-empresa-padrao/${id}/`, body: body }, {});
            }
        },
        [httpPatch, user]
    );

    const { isLoading, data } = useQuery({
        queryKey: ["something"],
        queryFn: async () => {
            let empresas = [];
            const handlers = {
                200: async ({ data }) => {
                    await data.results.forEach(async ({ perfil }) => {
                        const handlers = {
                            200: ({ data: _resp }) => {
                                if (_resp?.length > 0)
                                    empresas.push({
                                        ...perfil,
                                        label: perfil.nome_curto ? perfil.nome_curto : perfil.nome,
                                    });
                            },
                        };
                        await httpGet(
                            { url: `/pessoas/permissoes-perfis-vigentes?empresa=${perfil.id}&perfil=${user?.id}` },
                            handlers
                        );
                    });
                },
            };
            await httpGet({ url: "/pessoas/grupos-perfis?limit=100" }, handlers);
            return empresas;
        },
        isStale: true,
        staleTime: Infinity,
        cacheTime: Infinity,
    });

    const onChangeEmpresa = async (value) => {
        if (value) {
            const empresaFull = data?.find((el) => el.id === value);
            alterarEmpresaSelecionada({ id: value, empresa: empresaFull });
            await reloadPermissions(value);
            await alterarEmpresaPadrao(value);
        } else alterarEmpresaSelecionada({ id: null, empresa: null });
        setVisivel(false);
    };

    return (
        <>
            <div className="p-inputgroup">
                <i className="pi pi-building p-mr-2 p-ml-2 p-mt-2" style={{ fontSize: "1.2rem" }} />
                <label className="p-mt-2">
                    {isLoading
                        ? "Carregando..."
                        : empresaSelecionada
                        ? empresaSelecionada?.nome_curto
                        : "Selecione uma empresa"}
                </label>
                <Button
                    icon="pi pi-sync"
                    disabled={isLoading}
                    text
                    tooltip="Clique para selecionar uma empresa"
                    severity="secondary"
                    onClick={() => setVisivel(true)}
                />
            </div>
            <Dialog
                header="Selecione uma empresa:"
                visible={visivel}
                style={{ width: "40vw", display: "block" }}
                breakpoints={{ "960px": "90vw" }}
                onHide={() => setVisivel(false)}
            >
                <div className="xl:flex xl:justify-content-center">
                    <Menu
                        style={{ width: "100%" }}
                        model={data?.map((e) => {
                            return {
                                id: e.id,
                                label: `${e.nome} - ${formatarDocumento(e.identificacao)}`,
                                command: ({ item }) => onChangeEmpresa(item.id),
                            };
                        })}
                    />
                </div>
            </Dialog>
        </>
    );
}

export const MakoTrocaEmpresas = () => {
    return (
        <QueryClientProvider client={queryClient} contextSharing>
            <Componente />
        </QueryClientProvider>
    );
};
