import React, { useMemo, useState } from "react";

import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { Editor } from "primereact/editor";
import { Panel } from "primereact/panel";

import { MakoAutoComplete } from "../MakoAutoComplete";
import { Dropdown } from "../Dropdown";
import MakoListagem from "../MakoListagem";

import useEmpresa from "@/hooks/useEmpresa";
import useParam from "@/hooks/useParam";

import { RELATORIO_EMAIL_POLITICAS } from "@/assets/constants/parametros";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { gerarId } from "@/assets/util/util";

export const MakoSelecionarEmails = ({
    onCancel = null,
    onConfirm = null,
    pesquisarPorEmpresa = true,
    permitirCorpoEmail = true,
}) => {
    const [noValidate, setNoValidate] = useState(false);
    const [corpoEmail, setCorpoEmail] = useState("");
    const [porBuscar, setPorBuscar] = useState(true);
    const [textEmail, setTextEmail] = useState("");
    const [toEmail, setToEmail] = useState([]);
    const [enviarRodape, setEnviarRodape] = useState(true);

    const { empresaSelecionadaId } = useEmpresa();
    const { getParam } = useParam();

    const regexEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    const handleAddEmail = () => {
        if (!porBuscar) {
            if (regexEmail.test(textEmail)) {
                setToEmail((prev) => [...prev, { id: gerarId(), email: textEmail }]);
                setTextEmail("");
            } else {
                setNoValidate(true);
                setTimeout(() => setNoValidate(false), [2000]);
            }
        } else if (textEmail?.id) {
            setToEmail((prev) => [...prev, textEmail]);
            setTextEmail("");
        }
    };

    const handleCancelEmail = () => {
        setToEmail([]);
        setTextEmail("");
        setCorpoEmail("");

        if (onCancel && typeof onCancel === "function") {
            onCancel();
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button
                    type="button"
                    icon="pi pi-trash"
                    onClick={() => setToEmail((prev) => prev.filter((item) => item.id !== rowData.id))}
                    className="p-button-rounded p-button-danger"
                />
            </>
        );
    };

    const colunas = [
        { field: "email", header: "E-mail" },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (rowData) => actionBodyTemplate(rowData),
        },
    ];

    const autoCompleteItemTemplate = (item) => {
        return (
            <div>
                <div className="p-grid">
                    <div className="p-col-6">
                        <span>{`E-mail: `}</span>
                        <b>{item.email}</b>
                    </div>
                    <div className="p-col-6">
                        <span>{`Perfil: `}</span>
                        <b>{item.perfil.nome}</b>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-6">
                        <span>{`Identificação: `}</span>
                        <b>{item.identificacao}</b>
                    </div>
                    <div className="p-col-6">
                        <span>{`Tipo: `}</span>
                        <b>{item.principal ? "Principal" : "Alternativo"}</b>
                    </div>
                </div>
            </div>
        );
    };

    const handleConfirm = () => {
        onConfirm(
            toEmail.map((email) => email.email),
            enviarRodape && textoRodape ? `${corpoEmail}<br/><br/>${textoRodape}` : corpoEmail
        );
    };

    const urlPesquisa = useMemo(() => {
        if (!pesquisarPorEmpresa) return "/pessoas/emails-perfis/?ativo=true&search=";
        return !empresaSelecionadaId
            ? "/pessoas/emails-perfis/?ativo=true&search="
            : `/pessoas/emails-perfis/?ativo=true&=perfil__vinculoperfilempresa__empresa=${empresaSelecionadaId}&search=`;
    }, [empresaSelecionadaId, pesquisarPorEmpresa]);

    const textoRodape = useMemo(() => {
        const politicas_email = getParam(RELATORIO_EMAIL_POLITICAS);
        if (politicas_email) return politicas_email.valor;
        return null;
    }, [getParam]);

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                {!porBuscar ? (
                    <div className="p-field p-col-12 p-md-7">
                        <InputText
                            placeholder="Digite o e-mail"
                            type="email"
                            value={textEmail}
                            onChange={(e) => setTextEmail(e.target.value)}
                        />
                    </div>
                ) : (
                    <div className="p-field p-col-12 p-md-7">
                        <MakoAutoComplete
                            value={textEmail}
                            delay={500}
                            onChange={(e) => setTextEmail(e.value)}
                            itemTemplate={autoCompleteItemTemplate}
                            field="email"
                            urlSearch={urlPesquisa}
                            placeholder="Digite um nome/e-mail para pesquisar..."
                            showEmptyMessage
                            emptyMessage="E-mail não encontrado."
                        />
                    </div>
                )}
                <div className="p-field p-col-12 p-md-2">
                    <Button
                        type="button"
                        label="Adicionar"
                        onClick={handleAddEmail}
                        icon="pi pi-plus"
                        className="p-button-success"
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Button
                        type="button"
                        label={!porBuscar ? "E-mail cadastrado" : "E-mail não cadastrado"}
                        onClick={() => {
                            setPorBuscar((prev) => !prev);
                            setTextEmail("");
                        }}
                        icon="pi pi-sync"
                        className="p-button-info"
                    />
                </div>
            </div>
            {noValidate && <Message severity="error" text="Informe um email valido." className="p-mb-4" />}
            {permitirCorpoEmail && (
                <>
                    <div className="p-fluid p-formgrid p-grid p-mt-2">
                        <div className="p-field p-col-12">
                            <label htmlFor="corpo">Corpo do e-mail</label>
                            <Editor
                                id="corpo"
                                name="corpo"
                                value={corpoEmail}
                                onTextChange={(e) => setCorpoEmail(e.htmlValue)}
                                style={{ height: "200px" }}
                            />
                        </div>
                    </div>
                    {textoRodape && (
                        <div className="p-fluid p-formgrid p-grid p-mt-1">
                            <div className="p-field p-col-12">
                                <Panel header="Visualizar rodapé do e-mail" toggleable collapsed>
                                    <label>Enviar rodapé?</label>
                                    <Dropdown
                                        placeholder="Enviar rodapé?"
                                        options={SIM_NAO_BOOLEAN}
                                        optionValue="id"
                                        optionLabel="label"
                                        value={enviarRodape}
                                        onChange={({ target }) => setEnviarRodape(target.value)}
                                        className="p-mt-1 p-mb-3"
                                        style={{ width: "20%" }}
                                    />
                                    <Editor
                                        showHeader={false}
                                        value={textoRodape}
                                        style={{ height: "200px" }}
                                        readOnly
                                    />
                                </Panel>
                            </div>
                        </div>
                    )}
                </>
            )}
            <div className="p-mb-4">
                <MakoListagem
                    titulo="E-mails selecionados:"
                    dadosLocal={toEmail}
                    colunas={colunas}
                    msgTabelaVazia="Nenhum e-mail selecionado"
                />
            </div>
            <div>
                <Button
                    type="button"
                    label="Concluir"
                    onClick={handleConfirm}
                    disabled={!toEmail?.length}
                    className="p-button-success p-mr-2"
                />
                <Button type="button" label="Cancelar" onClick={handleCancelEmail} className="p-button-danger p-mr-2" />
            </div>
        </>
    );
};
