import React, { useImperativeHandle, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";

import { TemplateRateioFormBase } from "../formBase";

const Modal = ({ formProps }, ref) => {
    const [visible, setVisible] = useState(false);

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog visible={visible} onHide={() => setVisible(false)} style={{ width: "70vw" }}>
            <TemplateRateioFormBase
                propsButtonCancelar={{
                    icon: "pi pi-times",
                    label: "Cancelar",
                    onClick: () => setVisible(false),
                }}
                {...formProps}
            />
        </Dialog>
    );
};

export const ModalTemplateRateio = forwardRef(Modal);
