import React, { useCallback } from "react";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { RELATORIO_VENDAS_TABELACONTROLEENTREGA } from "@/assets/constants/relatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

import useFetchRelatorio from "@/hooks/useFetchRelatorio";
import useRelatorio from "@/hooks/useRelatorio";

const { RELATORIOS_EMITIR_VENDAS_CONTROLEENTREGA } = permissoes;

const CHAVE = RELATORIO_VENDAS_TABELACONTROLEENTREGA;

export const ControleEntrega = ({ vendaId, itemId, className = "" }) => {
    const { solicitarRelatorio } = useRelatorio();
    const { versao } = useFetchRelatorio({ chave: CHAVE });

    const solicitar = useCallback(() => {
        solicitarRelatorio({
            chave: CHAVE,
            filtros: { vendas: [vendaId], items_vendas: [itemId] },
            enviar_fila: false,
            versao,
        });
    }, [solicitarRelatorio, vendaId, itemId, versao]);

    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[RELATORIOS_EMITIR_VENDAS_CONTROLEENTREGA]}
            label="2° via controle entrega"
            icon={MAKO_ICONS.ARQUIVO_PDF}
            className={classNames("p-button-info", className)}
            onClick={solicitar}
            disabled={!versao}
        />
    );
};
