import React, { useRef } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";

import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { Tooltip } from "primereact/tooltip";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const EstoquesPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    useClearRefs(listagemRef);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_ESTOQUES_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de estoque"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/cadastros/estoques-empresas/form",
                            state: { estoque: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_ESTOQUES_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/produtos/estoques-empresas/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o estoque <b>{rowData.nome}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar estoque"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O estoque não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                onClick={() => history.push("/estoque/cadastros/estoques-empresas/form")}
            />
        </>
    );

    const enderecoTemplate = (endereco) => {
        const target = endereco.logradouro?.replace(/\s/g, "-");
        return (
            <>
                <Tooltip
                    target={`.${target}`}
                    mouseTrack
                    mouseTrackLeft={10}
                    content={`${endereco.logradouro} - ${endereco.numero} - ${endereco.bairro} - ${endereco.cep}`}
                />
                <span className={target}>
                    {endereco.cidade.nome} - {endereco.cidade.estado.uf}
                </span>
            </>
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "endereco.logradouro",
            header: "Endereço",
            filter: true,
            action: ({ endereco }) => enderecoTemplate(endereco),
            filterElement: TextoFiltroTemplate,
            filterField: "endereco__logradouro",
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de estoques"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/produtos/estoques-empresas/"
                filtarPorEmpresa
                fieldFiltroEmpresa="empresa__id"
                naoBuscarSemEmpresa
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    nome: { value: null, matchMode: "unaccent_icontains" },
                    descricao: { value: null, matchMode: "unaccent_icontains" },
                    endereco__logradouro: { value: null, matchMode: "unaccent_icontains" },
                }}
            />
        </PageBase>
    );
};
