import React from "react";

//import { PessoaProvider } from "@/contexts/pessoaContext";
import { TabImportarPessoas } from "./tabs";

export const ImportarPessoasPage = (props) => {
    return (
        // <PessoaProvider>
        <TabImportarPessoas />
        // </PessoaProvider>
    );
};
