/* eslint-disable no-template-curly-in-string */
import React, { useImperativeHandle, useState, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Dropdown } from "@/components/Dropdown";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import useEntrada from "@/hooks/useEntrada";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const Modal = ({ onSuccess }, ref) => {
    const [visible, setVisible] = useState(false);
    const [itemEntrada, setItemEntrada] = useState(null);
    const { dadosBasicos } = useEntrada();
    const { showWarning, showError } = useToast();
    const { httpPut } = useHttp();

    const formik = useFormik({
        initialValues: {
            item_entrada: null,
            destinacao: null,
            quantidade: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                destinacao: Yup.number()
                    .required("O campo 'destinação' é obrigatório.")
                    .typeError("Selecione uma destinação."),
                quantidade: Yup.number()
                    .when({
                        is: itemEntrada?.sku.movimenta_cubagem,
                        then: Yup.number().min(0.001, "O campo 'quantidade' não pode ser ZERO."),
                        otherwise: Yup.number().min(1, "O campo 'quantidade' não pode ser ZERO."),
                    })
                    .max(itemEntrada?.quantidade, "A quantidade não pode ser superior a ${max}"),
            });
            await formSchema.validate(values, { abortEarly: false });
            const handlers = {
                200: () => {
                    if (typeof onSuccess === "function") onSuccess();
                    formik.resetForm();
                    setVisible(false);
                },
                error: ({ status, err }) => {
                    if (status !== 500) {
                        showWarning({
                            summary: "Aviso!",
                            detail: err.msg,
                            life: 3000,
                        });
                    } else {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não conseguimos processar sua requisição.",
                            life: 3000,
                        });
                    }
                },
            };
            await httpPut({ url: `/compras/dividir-item-entrada/${itemEntrada?.id}/`, body: values }, handlers);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (itemEntrada) => {
        if (itemEntrada) setItemEntrada(itemEntrada);
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const renderFooter = () => {
        return <Button type="submit" label="Dividir produto" icon="fas fa-cogs" onClick={formik.handleSubmit} />;
    };

    return (
        <Dialog
            header={`Dividir produto: ${itemEntrada?.sku.descricao_derivada}`}
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "30vw" }}
            footer={renderFooter}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="destinacao-padrao">Destinação *</label>
                        <Dropdown
                            id="destinacao-padrao"
                            name="destinacao"
                            buscar={!!dadosBasicos}
                            url={`/compras/destinacoes-entradas/?tipo_entrada=${dadosBasicos?.tipo_entrada}&query={id,nome}&limit=100`}
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.destinacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.destinacao })}
                        />
                        {formik.errors.destinacao && <small className="p-error">{formik.errors.destinacao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="quantidade-original">Quantidade original</label>
                        <MakoInputQuantidadeSku
                            id="quantidade-original"
                            disabled
                            value={itemEntrada?.quantidade}
                            permiteFracionario={itemEntrada?.sku.movimenta_cubagem}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="quantidade">Quantidade *</label>
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            permiteFracionario={itemEntrada?.sku.movimenta_cubagem}
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalDividirProduto = forwardRef(Modal);
