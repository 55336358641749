import React, { useEffect, useImperativeHandle, useRef, useState, forwardRef } from "react";

import { ModalMotivoAprovacaoPreco } from "./ModalMotivoAprovacaoPreco";
import { MakoDialogConfirmarSenha } from "@/components/MakoDialogConfirmarSenha";
import useToast from "@/hooks/useToast";
import useNotificacao from "@/hooks/useNotificacao";
import useHttp from "@/hooks/useHttp";

const Factory = ({ notificacao, comentario, setLoading }, ref) => {
    const [bodyAprovacao, setBodyNotificacao] = useState({
        notificacao: notificacao.id,
        comentario: comentario || "",
    });
    const modalMotivoAprovacaoPrecoRef = useRef(null);
    const modalSenhaRef = useRef(null);
    const { showSuccess, showWarning } = useToast();
    const { marcarMsgComoLida } = useNotificacao();
    const { httpPut } = useHttp();

    useEffect(() => {
        return () => {
            modalMotivoAprovacaoPrecoRef.current = null;
            modalSenhaRef.current = null;
        };
    }, []);

    async function aprovarNotificacao() {
        const handlers = {
            200: ({ data }) => {
                marcarMsgComoLida();
                showSuccess({
                    summary: "Sucesso!",
                    detail: data.msg,
                    life: 2000,
                });
            },
            400: ({ err }) =>
                showWarning({
                    summary: "Falhou...",
                    detail: err.msg,
                    life: 3000,
                }),
            404: ({ err }) =>
                showWarning({
                    summary: "Falhou...",
                    detail: err.msg,
                    life: 3000,
                }),
        };

        setLoading(true);
        await httpPut({ url: "/pessoas/aprovar-notificacao/", body: bodyAprovacao }, handlers);
        setLoading(false);
    }

    const aprovar = () => {
        if (notificacao.tipo === "AS") {
            modalSenhaRef.current?.abrirModal();
        } else {
            aprovarNotificacao();
        }
    };

    const executar = () => {
        const { evento_content_type } = notificacao;
        if (!evento_content_type) aprovar();
        if (evento_content_type.app_label === "vendas" && evento_content_type.model === "itemvenda") {
            modalMotivoAprovacaoPrecoRef.current?.abrirModal();
        }
    };

    useImperativeHandle(ref, () => ({ executar }));

    return (
        <>
            <ModalMotivoAprovacaoPreco
                ref={modalMotivoAprovacaoPrecoRef}
                setBodyNotificacao={setBodyNotificacao}
                onClick={() => aprovar()}
            />
            <MakoDialogConfirmarSenha ref={modalSenhaRef} onConfirm={() => aprovarNotificacao()} />
        </>
    );
};

export const FactoryAprovacaoNotificacao = forwardRef(Factory);
