import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";

import MakoListagem from "@/components/MakoListagem";

import { formatarCasasDecimais } from "@/assets/util/util";

const COLUMNS = [
    {
        selectionMode: "multiple",
        style: { width: "5%" },
    },
    {
        field: "sku",
        style: { width: "8%" },
        header: "Código",
        action: ({ sku }) => sku.codigo,
    },
    {
        field: "sku",
        header: "Descrição",
        action: ({ sku }) => sku.descricao_reduzida,
    },
    {
        field: "sku",
        style: { width: "8%" },
        header: "Un",
        align: "center",
        action: ({ sku }) => sku.unidade_padrao.sigla,
    },
    {
        field: "quantidade",
        style: { width: "5%" },
        header: "Qtd.",
        decimal: true,
        align: "center",
    },
    {
        field: "data_previsao",
        style: { width: "10%" },
        header: "Data previsão",
        align: "center",
        dateFormat: "dd/MM/yyyy",
    },
];

const rowClassName = ({ retirar }) => {
    return {
        "p-text-bold": retirar,
    };
};

const Component = ({ venda_id, getSelecionados, onSelecionadosChange = () => {} }, ref) => {
    const [selecionados, setSelecionados] = useState(null);
    const [items, setItems] = useState([]);

    const painelEsquerdo = useMemo(() => {
        const total = items?.length
            ? items.reduce((total, item) => total + formatarCasasDecimais(item.quantidade), 0)
            : 0;
        return <b>Qtd. para ser entregue: {total}</b>;
    }, [items]);

    const onSelectionChange = (event) => {
        setSelecionados(event.value);
    };

    const aposPesquisar = useCallback((data) => {
        setItems(data);
        return data;
    }, []);

    const preencherSelecionados = useCallback(() => {
        const response = getSelecionados();
        if (response) {
            let newData = [];
            response.forEach((item_sele) => {
                let aux = items.find((item) => item.id === item_sele.id);
                if (aux && venda_id === aux?.venda_id) newData.push(aux);
            });
            if (newData.length > 0) setSelecionados(newData);
        }
    }, [getSelecionados, venda_id, items, setSelecionados]);

    useEffect(() => {
        preencherSelecionados();
    }, [preencherSelecionados]);

    useEffect(() => {
        onSelecionadosChange(selecionados);
    }, [onSelecionadosChange, selecionados]);

    useImperativeHandle(ref, () => ({ setSelecionados, getSelecionados: () => selecionados }), [selecionados]);

    return (
        <>
            <MakoListagem
                titulo="Itens da entrega"
                colunas={COLUMNS}
                painelEsquerdo={painelEsquerdo}
                aposPesquisar={aposPesquisar}
                fazerBusca={!!venda_id}
                urlPesquisa={`/vendas/previsoes-entregas-itens-vendas/?item_venda__venda=${venda_id}&entregue=false&item_venda__venda__situacao=F`}
                configTabela={{
                    selectionMode: "checkbox",
                    selection: selecionados,
                    onSelectionChange: onSelectionChange,
                    rowClassName: rowClassName,
                }}
            />
            <p className="p-text-bold p-mt-2 p-mb-0">Itens em destaque serão retirados pelo cliente</p>
        </>
    );
};

export const VisualizarPendentes = forwardRef(Component);
