import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";

import { gerarFiltrosUrl } from "@/assets/util/util";

import useReceberCrediario from "../../../../hooks/useReceberCrediario";

const BASE_URL = "/financeiro/formas-recebimentos-efetivados/";

const BASE_COLUMS = [
    {
        field: "conta_financeira.descricao",
        header: "Conta financeira",
    },
    {
        field: "forma_recebimento.descricao",
        header: "Forma recebimento",
    },
    {
        field: "valor",
        header: "Valor recebido",
        money: true,
        style: { width: "10%" },
    },
];

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { width: "8%" },
};

export const Listagem = ({ listagemRef, actions }) => {
    const { pendencia } = useReceberCrediario();
    const { grupo } = pendencia || {};

    const colunas = useMemo(() => {
        if (!actions) return BASE_COLUMS;
        const actionColum = {
            ...BASE_COLUMN_ACTIONS,
            action: (e) => actions(e),
        };
        return [...BASE_COLUMS, actionColum];
    }, [actions]);

    const url = useMemo(() => {
        return `${BASE_URL}?${gerarFiltrosUrl({ grupo_recebimento: grupo?.id })}`;
    }, [grupo]);

    return (
        <MakoListagem
            ref={listagemRef}
            colunas={colunas}
            urlPesquisa={url}
            fazerBusca={!!grupo}
            configTabela={{
                paginator: true,
                lazy: true,
            }}
        />
    );
};
