import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { InputText } from "primereact/inputtext";

import { PainelBackgroundCinza } from "@/components/PainelBackgroundCinza";
import { parseNumber } from "@/assets/helpers/number";
import { TIPO_LOTE_SKU } from "@/assets/constants/estoque";
import useHttp from "@/hooks/useHttp";

const BASE_URL_API = "/produtos/lotes-sku-movimentacao/?evento=transferencias.itemtransferenciamercadoria&evento_id=";

const Painel = ({ itemTransf }, ref) => {
    const [remanescente, setRemanescente] = useState(0);
    const { httpGet } = useHttp();

    const formatarTipoLote = (tipoLote) => {
        const tpLoteSku = TIPO_LOTE_SKU.find((e) => e.value === tipoLote);
        if (!tpLoteSku) return "--";
        return tpLoteSku.label;
    };

    const calcularRemanescente = useCallback(async () => {
        if (!itemTransf) return;
        const handlers = {
            200: ({ data }) => {
                const { results } = data;
                const totalLotes = results.reduce((total, lote) => total + parseNumber(lote.quantidade), 0);
                setRemanescente(parseNumber(itemTransf?.quantidade) - totalLotes);
            },
        };
        await httpGet({ url: `${BASE_URL_API}${itemTransf?.id}` }, handlers);
    }, [httpGet, itemTransf]);

    useEffect(() => {
        calcularRemanescente();
    }, [calcularRemanescente]);

    useImperativeHandle(ref, () => ({ calcularRemanescente }));

    return (
        <PainelBackgroundCinza>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="codigo-sku">Código</label>
                    <InputText id="codigo-sku" value={itemTransf?.sku.codigo || "0"} disabled />
                </div>
                <div className="p-field p-col-12 p-md-8">
                    <label htmlFor="produto">Descrição do produto / mercadoria</label>
                    <InputText id="produto" value={itemTransf?.sku.descricao_derivada} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="unidade">Unidade</label>
                    <InputText
                        id="unidade"
                        name="data_devolucao"
                        value={itemTransf?.unidade_medida.sigla || ""}
                        disabled
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="cubagem">Cubagem</label>
                    <InputText id="cubagem" value={`${parseNumber(itemTransf?.cubagem)}`} disabled />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="lote-serial">Tipo de controle de lote / nº serial</label>
                    <InputText id="lote-serial" value={formatarTipoLote(itemTransf?.sku.lote_serial)} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="quantidade">Qtd vendida</label>
                    <InputText id="quantidade" value={`${parseNumber(itemTransf?.quantidade)}`} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="remanescente">Sem lote</label>
                    <InputText id="remanescente" value={remanescente} disabled />
                </div>
            </div>
        </PainelBackgroundCinza>
    );
};

export const PainelDetalheLoteSKU = forwardRef(Painel);
