import React, { useCallback, useEffect, useState } from "react";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useDevolucao from "@/hooks/useDevolucao";
import { parseNumber } from "@/assets/helpers/number";
import useHttp from "@/hooks/useHttp";

export const FinanceiroDevolucaoForm = () => {
    const { dadosBasicos } = useDevolucao();
    const [itensDevolvidos, setItensDevolvidos] = useState(0);
    const [valorDevolvido, setValorDevolvido] = useState(0);
    const [estornadoCartao, setEstornadoCartao] = useState(0);
    const { httpGet } = useHttp();

    const carregarItensDevolucao = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                let quantidade = 0,
                    valor = 0,
                    estornado_cartao = 0;
                data.results.forEach((item) => {
                    quantidade += parseNumber(item.quantidade);
                    valor += parseNumber(item.valor_unitario);
                    estornado_cartao += parseNumber(item.valor_estorno_cartao);
                });
                setItensDevolvidos(quantidade);
                setValorDevolvido(valor);
                setEstornadoCartao(estornado_cartao);
            },
        };

        await httpGet({ url: `/vendas/itens-troca-devolucao/?devolucao=${dadosBasicos?.id}` }, handlers);
    }, [httpGet, dadosBasicos?.id]);

    useEffect(() => {
        carregarItensDevolucao();
    }, [carregarItensDevolucao]);

    return (
        <>
            <Panel header="Resumo Financeiro">
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="itens-devolvidos">Itens devolvidos</label>
                        <InputText id="itens-devolvidos" value={itensDevolvidos} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-devolvido">Valor devolvido</label>
                        <MakoInputMoeda id="valor-devolvido" value={valorDevolvido} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="estornado-cartao">Estornado cartão</label>
                        <MakoInputMoeda id="estornado-cartao" value={estornadoCartao} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="crediario">Crediário</label>
                        <InputText id="crediario" value={dadosBasicos.venda.situacao_crediario} disabled />
                    </div>
                </div>
            </Panel>
        </>
    );
};
