import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { ToggleButton } from "primereact/togglebutton";
import { ModalFiltroItem } from "./filtrosAvancados/filtroItem";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { STATUS_ITEM } from "@/assets/constants/constants";
import { Tag } from "primereact/tag";
import { key_filtros } from "@/assets/constants/filtros";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { ModalFiltroSku } from "./filtrosAvancados/filtroSku";
import useClearRefs from "@/hooks/useClearRefs";
import {
    AtivoFiltroTemplate,
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
    UnidadeMedidaFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { Menu } from "primereact/menu";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoVisualizarSku } from "@/components/MakoVisualizarSku";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

const urlLimpaSku = `/produtos/sku?query={id,item,descricao_derivada,descricao_reduzida,descricao_complementar,unidade_padrao,ativo,codigo}&status__exclude=B&ativo=true`;
const urlLimpaItem = `/produtos/itens?query={id,nome,ncm,marca,status,ativo}&status__exclude=B`;

export const ProdutoProdutosPage = ({ location }) => {
    const [filtros, setFiltro, removerFiltro, filtroString] = useLocalFiltro(
        key_filtros.PRODUTOS_PRODUTOS_SKU_AVANCADO
    );
    const [filtrosItem, setFiltroItem, removerFiltroItem, filtroStringItem] = useLocalFiltro(
        key_filtros.PRODUTOS_PRODUTOS_SKU_TABELA
    );
    const [urlSku, setUrlSku] = useState(() => {
        const url = location?.flagDescricao === false ? location?.url : urlLimpaSku;
        if (filtros) return `${url}&${filtroString}`;
        return url;
    });
    const [urlItem, setUrlItem] = useState(() => {
        const url = location?.flagDescricao === false ? location?.url : urlLimpaItem;
        if (filtros) return `${url}&${filtroStringItem}`;
        return url;
    });
    const [descricaoExibida, setDescricaoExibida] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [produto, setProduto] = useState(null);

    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError, showWarning } = useToast();
    const { verifyPermission } = useAuth();
    const { httpPatch } = useHttp();
    const history = useHistory();

    const visualizarSkuRef = useRef(null);
    const modalFiltroItem = useRef(null);
    const modalFiltroSku = useRef(null);
    const listagemRef = useRef(null);
    const menu = useRef(null);

    useClearRefs(listagemRef, modalFiltroSku, modalFiltroItem, visualizarSkuRef);

    const handleSituacao = async (situacao) => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: `Produto ${!produto.ativo ? "ativado" : "desativado"} com sucesso!`,
                    life: 1500,
                });
                listagemRef.current?.buscarDados();
            },
            400: () => showError(),
        };

        showLoading();
        await httpPatch(
            {
                url: `/produtos/${!descricaoExibida ? "sku" : "itens"}/${produto.id}/`,
                body: {
                    ativo: situacao,
                    descricao_reduzida: produto?.descricao_reduzida,
                },
            },
            handlers
        );
        hideLoading();
    };

    const handleBaixar = async () => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: `Produto modificado com sucesso!`,
                    life: 1500,
                });
                listagemRef.current?.buscarDados();
            },
            400: () => showError(),
        };

        showLoading();
        await httpPatch(
            {
                url: `/produtos/itens/${produto.id}/`,
                body: {
                    status: "B",
                    descricao_reduzida: produto?.descricao_reduzida,
                },
            },
            handlers
        );
        setDeleteDialog(false);
        hideLoading();
    };

    const handleValidar = async () => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: `Produto validado com sucesso!`,
                    life: 1500,
                });
            },
            400: ({ data }) => {
                if (data?.cadastro_validado?.length > 0 && data?.cadastro_validado[0] === "False") {
                    let msgErro = "";
                    data.erros.forEach((erro) => {
                        msgErro = `${msgErro} ${msgErro !== "" ? "-" : ""} ${erro}`;
                    });
                    if (msgErro !== "")
                        msgErro = `O cadastro não pode ser validado, por favor verifique as informações. (${msgErro} )`;
                    else msgErro = "O cadastro não pode ser validado, por favor verifique as informações.";

                    showWarning({
                        summary: "Informações pendentes",
                        detail: msgErro,
                        life: 10000,
                    });
                } else {
                    showError({
                        summary: "Falha ao validar",
                        detail: "Desculpe, não foi possível validar o status do produto.",
                        life: 10000,
                    });
                }
            },
        };

        showLoading();
        await httpPatch(
            {
                url: `/produtos/itens/${produto.id}/`,
                body: {
                    status: "F",
                },
            },
            handlers
        );
        hideLoading();
    };

    const opcoes = [
        {
            label: "Opções",
            items: descricaoExibida
                ? [
                      {
                          label: "Validar",
                          icon: "fa fa-solid fa-check-double",
                          disabled: !produto?.ativo || !verifyPermission([permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]),
                          command: () => handleValidar(),
                      },
                      {
                          label: "Baixar",
                          icon: "pi pi-times",
                          disabled: !produto?.ativo || !verifyPermission([permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]),
                          command: () => setDeleteDialog(true),
                      },
                  ]
                : [
                      {
                          label: "Visualizar",
                          icon: MAKO_ICONS.VISUALIZAR,
                          disabled: !verifyPermission([permissoes.PRODUTO_PRODUTO_PRODUTOS_CONSULTAR]),
                          command: () =>
                              visualizarSkuRef.current?.show({
                                  id: produto.id,
                                  buscarPor: "sku",
                              }),
                      },
                      {
                          label: "Ativar",
                          icon: "pi pi-chevron-circle-down",
                          disabled: produto?.ativo || !verifyPermission([permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]),
                          command: () => handleSituacao(true),
                      },
                      {
                          label: "Desativar",
                          icon: "pi pi-times-circle",
                          disabled: !produto?.ativo || !verifyPermission([permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]),
                          command: () => handleSituacao(false),
                      },
                  ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de Produto"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status === "B"}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/produto/produtos/form",
                            state: {
                                id: rowData.item?.id || rowData.id,
                                flagDescricao: descricaoExibida,
                                url: descricaoExibida ? urlItem : urlSku,
                            },
                        })
                    }
                />
                <Button
                    model={opcoes}
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    icon={MAKO_ICONS.OPCOES}
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setProduto(rowData);
                    }}
                />
            </div>
        );
    };

    const alteraListagem = (valor) => {
        setDescricaoExibida(valor);
        if (!valor) setUrlSku(urlLimpaSku);
        else setUrlItem(urlLimpaItem);
    };

    const limparFiltro = useCallback(() => {
        if (descricaoExibida) {
            modalFiltroItem.current?.limparFiltro();
            setUrlItem(urlLimpaItem);
        } else {
            modalFiltroSku.current?.limparFiltro();
            setUrlSku(urlLimpaSku);
        }
    }, [descricaoExibida]);

    const handleModal = useCallback(() => {
        descricaoExibida ? modalFiltroItem.current?.abrirModal() : modalFiltroSku.current?.abrirModal();
    }, [descricaoExibida]);

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() =>
                    history.push({
                        pathname: "/produto/produto/produtos/form",
                        state: { url: descricaoExibida ? urlItem : urlSku },
                    })
                }
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2 p-mb-2"
                onClick={() => handleModal()}
            />
            <ToggleButton
                checked={descricaoExibida}
                onChange={(e) => alteraListagem(e.value)}
                onLabel="Exibir desagrupado"
                offLabel="Exibir agrupado"
                onIcon="pi pi-th-large"
                offIcon="pi pi-stop"
                className="p-mr-2 p-mb-2"
                style={{ width: "15em" }}
            />
            {urlSku !== urlLimpaSku || urlItem !== urlLimpaItem ? (
                <Button
                    label="Limpar filtros"
                    icon="pi pi-trash"
                    className="p-button-warning p-mr-2 p-mb-2"
                    onClick={limparFiltro}
                />
            ) : null}
        </>
    );

    const statusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "label",
                    options: STATUS_ITEM,
                }}
                options={options}
            />
        );
    };

    const marcaFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "nome",
                    url: "/produtos/marcas/",
                }}
                options={options}
            />
        );
    };

    const colunasDinamica = [
        {
            field: "codigo",
            header: "Código",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "codigo",
            style: { minWidth: "50px" },
        },
        {
            field: "item.id",
            header: "Agrupador",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            filterField: "item__id",
            style: { minWidth: "50px" },
        },
        {
            field: "descricao_derivada",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "descricao_derivada",
            style: { minWidth: "250px" },
        },
        {
            field: "unidade_padrao.sigla",
            header: "Unidade",
            filter: true,
            filterElement: UnidadeMedidaFiltroTemplate,
            filterField: "unidade_padrao__id",
            style: { minWidth: "60px" },
        },
        {
            field: "ativo",
            header: "Situação de uso",
            align: "center",
            filter: true,
            filterElement: AtivoFiltroTemplate,
            filterField: "ativo",
            style: { minWidth: "80px" },
            action: ({ ativo }) => {
                return <Tag severity={ativo ? "success" : "danger"} value={ativo ? "ATIVO" : "INATIVO"} />;
            },
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { minWidth: "100px" } },
    ];

    const colunasGenerica = [
        {
            field: "id",
            header: "Agrupador",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            style: { minWidth: "50px" },
        },
        {
            field: "nome",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
            style: { minWidth: "250px" },
        },
        {
            field: "marca.nome",
            header: "Marca",
            filter: true,
            filterField: "marca",
            filterElement: marcaFiltroTemplate,
            style: { minWidth: "120px" },
        },
        {
            field: "ncm.codigo",
            header: "NCM",
            filterField: "ncm__codigo",
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "status",
            header: "Situação cadastral",
            align: "center",
            filter: true,
            filterElement: statusFiltroTemplate,
            filterField: "status",
            style: { minWidth: "80px" },
            action: ({ status }) => {
                const _status = STATUS_ITEM.find(({ id }) => id === status);
                if (_status) return <Tag severity={_status.tag} value={_status.label.toLocaleUpperCase()} />;
            },
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { minWidth: "100px" } },
    ];

    const filtrosCabecalho = useMemo(() => {
        return descricaoExibida
            ? {
                  id: { value: null, matchMode: "equals" },
                  nome: { value: "", matchMode: "icontains" },
                  marca: { value: null, matchMode: "equals" },
                  ncm__codigo: { value: "", matchMode: "icontains" },
                  status: { value: null, matchMode: "equals" },
              }
            : {
                  codigo: { value: null, matchMode: "icontains" },
                  item__id: { value: null, matchMode: "equals" },
                  descricao_derivada: { value: "", matchMode: "equals" },
                  unidade_padrao__id: { value: null, matchMode: "equals" },
                  ativo: { value: null, matchMode: "equals" },
              };
    }, [descricaoExibida]);

    useEffect(() => listagemRef.current?.trocarFiltros(), [descricaoExibida]);

    return (
        <PageBase>
            <MakoVisualizarSku ref={visualizarSkuRef} />
            <Menu model={opcoes} popup ref={menu} id="popup_menu" />
            {descricaoExibida ? (
                <MakoListagem
                    ref={listagemRef}
                    titulo="Produtos e mercadorias"
                    colunas={colunasGenerica}
                    painelEsquerdo={cabecalhoTabela}
                    urlPesquisa={urlItem}
                    filtarPorEmpresa
                    naoBuscarSemEmpresa
                    keyFiltrosStorage={key_filtros.PRODUTOS_PRODUTOS_ITEM_TABELA}
                    filtros={filtrosCabecalho}
                    fieldFiltroEmpresa={"empresa"}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            ) : (
                <MakoListagem
                    ref={listagemRef}
                    titulo="Produtos e mercadorias"
                    colunas={colunasDinamica}
                    painelEsquerdo={cabecalhoTabela}
                    urlPesquisa={urlSku}
                    filtarPorEmpresa
                    naoBuscarSemEmpresa
                    keyFiltrosStorage={key_filtros.PRODUTOS_PRODUTOS_SKU_TABELA}
                    filtros={filtrosCabecalho}
                    fieldFiltroEmpresa={"item_empresa"}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            )}
            <ConfirmDialog
                visible={deleteDialog}
                onHide={() => setDeleteDialog(false)}
                header="Confirmação"
                message={
                    <span>
                        Deseja realmente <b>BAIXAR</b> o produto{" "}
                        <b>
                            {produto?.nome} (Cód: {produto?.id})
                        </b>
                        ? Após esse procedimento o produto não poderá ser visualizado nas operações do sistema."
                    </span>
                }
                icon="pi pi-info-circle p-mr-3"
                accept={handleBaixar}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={() => setDeleteDialog(false)}
                rejectLabel="Não"
            />
            <ModalFiltroSku
                ref={modalFiltroSku}
                setUrl={setUrlSku}
                filtros={filtros}
                setFiltro={setFiltro}
                removerFiltro={removerFiltro}
            />
            <ModalFiltroItem
                ref={modalFiltroItem}
                setUrl={setUrlItem}
                filtros={filtrosItem}
                setFiltro={setFiltroItem}
                removerFiltro={removerFiltroItem}
            />
        </PageBase>
    );
};
