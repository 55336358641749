import React, { useCallback, useMemo, useRef, useState } from "react";

import { Entregas as Listagem } from "./listagens/Entregas";
import { Buttons } from "./buttons";

import useClearRefs from "@/hooks/useClearRefs";

const MemoPendentes = Buttons.Pendentes;

export const EntregasPage = () => {
    const [selecionados, setSelecionados] = useState(null);
    const listagemRef = useRef();

    useClearRefs(listagemRef);

    const onHideConfirmar = useCallback((atualizarListagem = false) => {
        if (atualizarListagem) {
            listagemRef.current?.buscarDados();
            setSelecionados(null);
        }
    }, []);

    const painelEsquerdoTemplate = (
        <>
            <Buttons.Confirmar
                className="p-mr-2"
                selecionados={selecionados}
                onHideCallback={onHideConfirmar}
                onCancelCallback={onHideConfirmar}
            />
            <Buttons.Documento className="p-mr-2" selecionados={selecionados} />
            <Buttons.Cancelar
                className="p-mr-2"
                disabled={!selecionados?.length}
                onClick={() => setSelecionados(null)}
            />
        </>
    );

    const handleSelectionAll = useCallback(
        (values = [], venda_id) => {
            setSelecionados((prev) => {
                if (!prev && values.length === 0) return null;
                if (!prev && values.length > 0) return [...values];
                if (prev && values.length > 0) return [...prev.filter((i) => i.venda_id !== venda_id), ...values];
                if (prev && values.length === 0) return prev.filter((i) => i.venda_id !== venda_id);
                return prev;
            });
        },
        [setSelecionados]
    );

    const getSelecionados = useCallback(() => {
        return selecionados;
    }, [selecionados]);

    const actions = useCallback(
        (rowData) => {
            return (
                <div className="actions">
                    <MemoPendentes
                        entrega={rowData}
                        selecionados={selecionados}
                        getSelecionados={getSelecionados}
                        handleSelectionAll={handleSelectionAll}
                        className="p-mr-2 p-mb-1"
                    />
                </div>
            );
        },
        [getSelecionados, handleSelectionAll, selecionados]
    );

    const rowClass = useCallback(
        (rowData) => {
            return {
                "p-selectable-row p-highlight ": selecionados?.some((item) => rowData?.venda_id === item?.venda_id),
            };
        },
        [selecionados]
    );

    const header = useMemo(() => {
        if (selecionados === null || selecionados?.length === 0) return <h5>Listagem de entregas</h5>;
        if (selecionados?.length === 1) return <h5>Listagem de entregas: (1 item selecionado)</h5>;
        return <h5>Listagem de entregas: ({selecionados?.length} itens selecionados)</h5>;
    }, [selecionados]);

    return (
        <div className="card">
            {header}
            <Listagem
                listagemRef={listagemRef}
                actions={actions}
                listagemProps={{
                    painelEsquerdo: painelEsquerdoTemplate,
                    filtros: {
                        item_venda__venda: { value: null, matchMode: "equals" },
                        item_venda__venda__cliente_id: { value: null, matchMode: "equals" },
                        rota_entrega: { value: null, matchMode: "equals" },
                        endereco_entrega__cidade_id: { value: null, matchMode: "equals" },
                        data_previsao: { value: null, matchMode: "equals" },
                        hora_previsao: { value: null, matchMode: "equals" },
                    },
                }}
                configTabela={{
                    rowClassName: rowClass,
                }}
            />
        </div>
    );
};
