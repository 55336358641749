import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel";
import axios from "axios";
import { OrientacoesForm } from "./form";
import permissoes from "@/assets/constants/permissoes";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

const MakoAjuda = () => {
    const [orientacao, setOrientacao] = useState(null);
    const [loading, setLoading] = useState(false);
    const overlayRef = useRef(null);
    const { pathname } = useLocation();
    const { verifyPermission } = useAuth();
    const { httpGet } = useHttp();

    const buscarOrientacao = useCallback(
        async (cancelToken) => {
            const handlers = {
                200: ({ data }) => {
                    const [result] = data.results;
                    setOrientacao(result);
                },
            };

            setLoading(true);
            await httpGet({ url: `/configuracoes/orientacoes/?endpoint=${pathname}`, cancelToken }, handlers);
            setLoading(false);
        },
        [pathname, httpGet]
    );

    useEffect(() => {
        const { token, ...cancelToken } = axios.CancelToken.source();
        buscarOrientacao(token);
        return function clear() {
            cancelToken.cancel();
            overlayRef.current = null;
        };
    }, [buscarOrientacao]);

    const podeCriar = useMemo(() => {
        return verifyPermission([permissoes.CONFIGURACOES_ORIENTACOES_CRIAR]);
    }, [verifyPermission]);

    return (
        <>
            <button type="button" className="p-link" disabled={loading} onClick={(e) => overlayRef.current.toggle(e)}>
                <i className={`pi ${loading ? "pi-spin pi-spinner" : "pi-question-circle"}`} />
            </button>
            <OverlayPanel ref={overlayRef} style={{ width: "80vw" }} showCloseIcon>
                <TabView>
                    <TabPanel header="Orientações">
                        {orientacao?.ativo && orientacao?.publicado ? (
                            <>
                                <h1>Orientações</h1>
                                <div dangerouslySetInnerHTML={{ __html: orientacao.descricao }} />
                            </>
                        ) : (
                            <p className="p-m-0">Nenhuma orientação publicada.</p>
                        )}
                    </TabPanel>
                    <TabPanel header="Edição" disabled={!podeCriar}>
                        <OrientacoesForm
                            pathname={pathname}
                            orientacao={orientacao}
                            onSubmit={(e) => setOrientacao(e)}
                        />
                    </TabPanel>
                </TabView>
            </OverlayPanel>
        </>
    );
};

export default React.memo(MakoAjuda);
