import React from "react";

import { ReceberCrediarioProvider } from "./contexts";

import { Sessoes } from "./sessoes";

const Content = () => {
    return (
        <div>
            <Sessoes.Info />
            <Sessoes.Tabs />
        </div>
    );
};

export const ReceberCrediario = ({ pendencia, onRemoverParcelaCallback = () => {} }) => {
    return (
        <ReceberCrediarioProvider pendencia={pendencia} onRemoverParcelaCallback={onRemoverParcelaCallback}>
            <Content />
        </ReceberCrediarioProvider>
    );
};
