import React, { useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";

import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIOS_EMITIR_SERVICOS_CONTRATOSSERVICOSATRASOS } from "@/assets/constants/relatorios";
import { TIPO_STATUS_CONTRATO_CHOICE } from "@/assets/constants/constants";

import useRelatorio from "@/hooks/useRelatorio";
import useEmpresa from "@/hooks/useEmpresa";
import useClearRefs from "@/hooks/useClearRefs";

export const ContratosServicoAtrasos = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const { solicitarRelatorio } = useRelatorio();
    const { empresaSelecionadaId, empresaSelecionada: empresa } = useEmpresa();

    const empresaSelecionada = useRef(empresa);

    useClearRefs(empresaSelecionada);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            empresa: empresaSelecionadaId,
            status: null,
            cliente: null,
        },
        onSubmit: handleSubmit,
    });

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "cliente",
            label: "Cliente",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "status",
            label: "Status",
            optionLabel: "label",
            optionKey: "value",
            options: TIPO_STATUS_CONTRATO_CHOICE,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
        setFieldValue("empresa", e.id);
    };

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório")
                    .typeError("Informe uma 'empresa' válida"),
                cliente: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'cliente' válido"),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.cliente instanceof Object) dadosValidados.cliente = dadosValidados.cliente.id;
            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            solicitarRelatorio({
                chave: RELATORIOS_EMITIR_SERVICOS_CONTRATOSSERVICOSATRASOS,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de contratos de servicos em atraso</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="empresa">Empresa: *</label>
                            <MakoDropdownEmpresas
                                id="empresa"
                                name="empresa"
                                placeholder="Selecione uma empresa"
                                value={formik.values.empresa}
                                onChange={(e) => onChangeEmpresa(e)}
                                className={classNames({ "p-invalid": formik.errors.empresa })}
                            />
                            {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="cliente">Cliente:</label>
                            <MakoInputCliente
                                id="cliente"
                                name="cliente"
                                value={formik.values.cliente}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.cliente })}
                            />
                            {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="status">Status:</label>
                            <Dropdown
                                id="status"
                                name="status"
                                placeholder="Selecione um status"
                                optionLabel="label"
                                optionValue="value"
                                options={TIPO_STATUS_CONTRATO_CHOICE}
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.status })}
                            />
                            {formik.errors.status && <small className="p-error">{formik.errors.status}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-6 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={handlePreSubmit}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={limparFiltro}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
