import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

import { isAfter, isBefore, isEqual, startOfDay } from "date-fns";

import { InputText } from "primereact/inputtext";

import { MakoDropdownCompetenciaFinanceira } from "@/components/MakoInputs";

import { parseData } from "@/assets/util/datas";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { CalendarDataRecebimento } from "./inputs";

const HOJE = startOfDay(new Date());

const BASE_DEVEDOR = {
    doc: "",
    nome: "",
};

const competenciaNaoValida = (competencia) => {
    if (!competencia) return true;
    const { data_inicio, data_fim } = competencia;
    if (
        (isBefore(new Date(data_inicio), new Date()) || isEqual(new Date(data_inicio), new Date())) &&
        (isAfter(new Date(data_fim), new Date()) || isEqual(new Date(data_fim), new Date()))
    ) {
        return false;
    }
    return true;
};

const Component = ({ devedor = BASE_DEVEDOR, submitted = false, competencia }, ref) => {
    const [formatarDocumento] = useFormatCNPJCPF();
    const { caixaMov: caixa } = useCaixaMovimento();

    const [competenciaRecebimento, setCompetenciaRecebimento] = useState({ value: null, competencia: null });
    const [dataRecebimento, setDataRecebimento] = useState(parseData(caixa?.data_abertura) || HOJE);
    const [competencias, setCompetencias] = useState([]);

    const aposBuscarCompetencia = useCallback((data) => {
        setCompetencias(data);
        return data;
    }, []);

    const gerarCompetenciaPadrao = useCallback(() => {
        if (competencia && !competenciaNaoValida(competencia)) {
            setCompetenciaRecebimento({
                value: competencia.id,
                competencia: competencia,
            });
        } else {
            const recomendada = competencias.filter((c) => !competenciaNaoValida(c)).at(0);
            if (recomendada) {
                setCompetenciaRecebimento({
                    value: recomendada.id,
                    competencia: recomendada,
                });
            }
        }
    }, [competencias, competencia]);

    useImperativeHandle(ref, () => {
        return {
            valueCompetencia: competenciaRecebimento.value,
            valueDataRecebimento: dataRecebimento,
            setDataRecebimento,
        };
    });

    useEffect(() => {
        gerarCompetenciaPadrao();
    }, [gerarCompetenciaPadrao]);

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="doc">CPF / CNPJ</label>
                    <InputText id="doc" disabled value={formatarDocumento(devedor.identificacao)} />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="nome-completo">Nome completo</label>
                    <InputText id="nome-completo" disabled value={devedor.nome} />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="competencia">Competência</label>
                    <MakoDropdownCompetenciaFinanceira
                        id="competencia"
                        showClear
                        value={competenciaRecebimento.value}
                        aposBuscar={aposBuscarCompetencia}
                        onChange={(e) =>
                            setCompetenciaRecebimento({
                                value: e.value,
                                competencia: competencias.find(({ id }) => id === e.value),
                            })
                        }
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-recebimento">Data do recebimento *</label>
                    <CalendarDataRecebimento
                        id="data-recebimento"
                        competencia={competenciaRecebimento.competencia}
                        valueCalendar={dataRecebimento}
                        onChange={setDataRecebimento}
                    />
                    {submitted && !dataRecebimento && (
                        <small className="p-error">O campo 'data do recebimento' é obrigatório.</small>
                    )}
                </div>
            </div>
        </>
    );
};

export const Sacado = forwardRef(Component);
