import React, { useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ModalCadastroUsuario } from "./modal/CadastrarUsuario";
import { ModalAlterarSenhaUsuario } from "./modal/AlterarSenhaUsuario";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import { PageBase } from "@/components/PageBase";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { Menu } from "primereact/menu";
import { Dropdown } from "@/components/Dropdown";
import { ModalEditarUsuario } from "./modal/EditarUsuario";
import { MakoErrosPersonalizados } from "@/components/MakoErrosPersonalizados";
import useClearRefs from "@/hooks/useClearRefs";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import useHttp from "@/hooks/useHttp";

const FILTROS_STATUS = [
    { label: "Todos", value: "todos" },
    { label: "Ativos", value: true },
    { label: "Inativos", value: false },
];

const BASE_URL =
    "/pessoas/perfis?query={id,usuario,nome,identificacao,emailperfil_set}&usuario__isnull=false&limit=1000";

export const GestaoGerenciarUsuariosPage = () => {
    const [usuario, setUsuario] = useState(null);
    const [filtro, setFiltro] = useState(true);
    const [url, setUrl] = useState(BASE_URL + `&usuario__is_active=true`);
    const { showLoading, hideLoading } = useLoading();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { verifyPermission } = useAuth();
    const { showError } = useToast();
    const { httpPatch } = useHttp();

    const filtroStatus = ({ value }) => {
        setFiltro(value);
        if (typeof value === "string") {
            setUrl(BASE_URL);
        } else setUrl(BASE_URL + `&usuario__is_active=${value}`);
    };

    const modalEditarUsuarioRef = useRef(null);
    const modalErrosValidacaoRef = useRef(null);
    const modalCadastroUsuarioRef = useRef(null);
    const modalAltSenhaUsuarioRef = useRef(null);
    const listagemRef = useRef(null);
    const menu = useRef(null);

    useClearRefs(
        modalEditarUsuarioRef,
        modalErrosValidacaoRef,
        modalCadastroUsuarioRef,
        modalAltSenhaUsuarioRef,
        listagemRef,
        menu
    );

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_INCLUIR]}
                componente={Button}
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                label="Novo"
                onClick={() => modalCadastroUsuarioRef.current?.abrirModal()}
            />
            <Dropdown value={filtro} options={FILTROS_STATUS} showClear={false} onChange={filtroStatus} />
        </>
    );

    const alterarStatusUsuario = async (usuario) => {
        const body = { is_active: !usuario.is_active };
        const handlers = {
            200: () => {
                listagemRef.current?.buscarDados();
            },
            400: ({ data }) => {
                let erros = [];
                if (typeof data === "object") {
                    for (const [k, v] of Object.entries(data)) {
                        erros.push({ field: k, error: v });
                    }
                }
                modalErrosValidacaoRef.current?.abrirModal(erros);
                showError();
            },
        };
        showLoading();
        await httpPatch({ url: `/pessoas/usuario/${usuario.id}/`, body }, handlers);
        hideLoading();
    };

    const confirmarAlteracaoStatusUsuario = ({ usuario }) => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <span>
                    Deseja mesmo {usuario.is_active ? "desativar" : "ativar"} o usuário <b>{usuario.username}</b>?
                </span>
            ),
            icon: "pi pi-exclamation-triangle",
            rejectLabel: "Cancelar",
            accept: () => alterarStatusUsuario(usuario),
            acceptClassName: usuario.is_active ? "p-button-danger" : "p-button-info",
            acceptLabel: "Confirmar",
        });
    };

    const opcoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Alterar senha",
                    icon: MAKO_ICONS.BLOQUEADO,
                    command: () => modalAltSenhaUsuarioRef.current?.abrirModal(usuario?.usuario),
                },
                {
                    label: "Editar usuário",
                    icon: MAKO_ICONS.EDITAR,
                    command: () => modalEditarUsuarioRef.current?.abrirModal(usuario),
                },
                {
                    label: "Ativar",
                    icon: MAKO_ICONS.ATIVO,
                    disabled: usuario?.usuario?.is_active ? true : false,
                    command: () => confirmarAlteracaoStatusUsuario(usuario),
                },
                {
                    label: "Desativar",
                    icon: MAKO_ICONS.INATIVO,
                    disabled: usuario?.usuario?.is_active ? false : true,
                    command: () => confirmarAlteracaoStatusUsuario(usuario),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-check-square"
                    className="p-button-rounded p-button-warning"
                    tooltip="Editar permissões"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/gestao/usuarios/gerenciar-usuarios/form",
                        state: { gerenciarUsuario: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_EDITAR]}
                    model={opcoes}
                    componente={Button}
                    className="p-button-rounded p-button-info"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    icon={MAKO_ICONS.OPCOES}
                    onClick={(event) => {
                        menu.current?.toggle(event);
                        setUsuario(rowData);
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const rowClass = (rowData) => {
        return {
            "mako-table-status-inativo": !rowData.usuario.is_active,
        };
    };

    const exibeEmail = (emails) => {
        if (emails.length > 0) return emails[0].email;
        return "-";
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "8rem" },
            filter: true,
            filterField: "id",
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "usuario.username",
            header: "Usuário",
            style: { minWidth: "15rem" },
            filter: true,
            filterField: "usuario__username",
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "nome",
            header: "Nome",
            filter: true,
            style: { minWidth: "25rem" },
            filterField: "nome",
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "identificacao",
            header: "CPF",
            style: { minWidth: "10rem" },
            action: (e) => formatarDocumento(e.identificacao),
            filter: true,
            filterField: "identificacao",
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "email",
            header: "Email",
            style: { minWidth: "25rem" },
            filter: true,
            filterField: "email",
            action: (e) => exibeEmail(e.emailperfil_set),
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            style: { minWidth: "8rem" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <Menu model={opcoes} popup ref={menu} id="popup_menu" />
            <MakoListagem
                titulo="Gerenciar Usuários"
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={url}
                painelEsquerdo={cabecalhoTabela}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    usuario__username: { value: null, matchMode: "icontains" },
                    nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    identificacao: { value: null, matchMode: "equals" },
                    email: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                    rowClassName: rowClass,
                }}
            />
            <ConfirmDialog />
            <ModalCadastroUsuario ref={modalCadastroUsuarioRef} onCreate={() => listagemRef.current?.buscarDados()} />
            <ModalEditarUsuario ref={modalEditarUsuarioRef} onEdit={() => listagemRef.current?.buscarDados()} />
            <ModalAlterarSenhaUsuario ref={modalAltSenhaUsuarioRef} isRoot={verifyPermission([permissoes.ROOT])} />
            <MakoErrosPersonalizados
                colunas={[
                    { field: "field", header: "Campo" },
                    { field: "error", header: "Mensagem" },
                ]}
                ref={modalErrosValidacaoRef}
            />
        </PageBase>
    );
};
