import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";

import { useHistory } from "react-router-dom";

import { ConfirmDialog } from "primereact/confirmdialog";
import { PageBase } from "@/components/PageBase";
import { Menu } from "primereact/menu";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoCardFinanceiro } from "@/components/MakoCards";
import { Dropdown } from "@/components/Dropdown";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import MakoListagem from "@/components/MakoListagem";

import { FiltroPagamentosModalForm } from "./modals/filtroAvancadoModalForm";
import { ModalVisualizarPagamento } from "./EfetivacaoPagamento/VisualizarPagamento";
import { ValidadeOverlay } from "./EfetivacaoPagamento/VisualizarPagamento/validadeOverlay";

import { FILTRO_INDICADORES } from "@/assets/constants/financeiro";
import permissoes from "@/assets/constants/permissoes";

import { COLORS_VALIDADE, MAKO_ICONS } from "@/assets/constants/constants_styles";
import { key_filtros } from "@/assets/constants/filtros";
import { parseNumber } from "@/assets/helpers/number";

import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const URL_BACKEND = "/financeiro/pagamentos/?";

export const FinanceiroPagamentosPage = () => {
    const [parcelasSelecionadas, setParcelasSelecionadas] = useState([]);
    const [loadingIndicadores, setLoadingIndicadores] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [indicadores, setIndicadores] = useState(null);
    const [colorAjuda, setColorAjuda] = useState(COLORS_VALIDADE.PENDENTE);
    const [pagamento, setPagamento] = useState(null);

    const [filtros, setFiltro, removerFiltro, filtroString] = useLocalFiltro(
        key_filtros.FINANCEIRO_FINANCEIRO_PAGAMENTOS_AVANCADO
    );
    const [urlPesquisa, setUrlPesquisa] = useState(() => {
        if (filtros) return `${URL_BACKEND}&${filtroString}`;
        return URL_BACKEND;
    });
    const [filtroIndicadores, setFiltroIndicadores] = useLocalFiltro(
        key_filtros.FINANCEIRO_FINANCEIRO_PAGAMENTOS_PERIODO,
        "mes"
    );

    const { showWarning, showSuccess } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet, httpPatch } = useHttp();
    const history = useHistory();

    const modalFiltroAvancadoRef = useRef(null);
    const visualizarModalRef = useRef(null);
    const listagemRef = useRef(null);
    const menuRef = useRef(null);

    const handleCancelar = async () => {
        let ok = false;
        const handlers = {
            200: () => {
                listagemRef.current?.buscarDados();
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Pagamento cancelado com sucesso.",
                    life: 1500,
                });
            },
        };
        await httpPatch(
            {
                url: `/financeiro/pagamentos/${pagamento.id}/`,
                body: {
                    cancelado: true,
                },
            },
            handlers
        );
        return ok;
    };

    const actionBodyTemplate = (rowData) => {
        const items = [
            {
                label: "Editar",
                icon: "pi pi-pencil",
                disabled: rowData.cancelado || rowData.quitado,
                command: () =>
                    history.push({
                        pathname: "/financeiro/financeiro/pagamentos/form",
                        documento: pagamento.documento,
                    }),
            },
            {
                label: "Fracionar",
                icon: "fas fa-chart-pie",
                disabled: rowData.cancelado || rowData.quitado,
                command: () =>
                    history.push({
                        pathname: "/financeiro/financeiro/pagamentos/fracionar",
                        state: pagamento,
                    }),
            },
            {
                label: "Cancelar",
                icon: "pi pi-times",
                disabled: rowData.cancelado || rowData.quitado,
                command: () => {
                    setConfirmDialog(true);
                },
            },
            {
                label: "Visualizar",
                icon: MAKO_ICONS.VISUALIZAR,
                command: () => visualizarModalRef.current?.show(pagamento),
            },
        ];
        return (
            <MakoActionsButtonsColumn>
                <Menu model={items} popup ref={menuRef} />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PAGAMENTOS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.OPCOES}
                    tooltip="Alterar cadastro de pagamento"
                    tooltipOptions={{ position: "left" }}
                    className="p-button-rounded p-button-info"
                    onClick={(e) => {
                        setPagamento(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const bloqueiaEfetivacao = useMemo(() => {
        if (parcelasSelecionadas?.length > 0) {
            const credores = parcelasSelecionadas
                .map((parcela) => {
                    return !parcela.quitado && !parcela.cancelado && parcela.credor?.id;
                })
                .filter(Boolean);
            return [...new Set(credores)].length === 1 ? false : true;
        }
        return true;
    }, [parcelasSelecionadas]);

    const totalizadorFiltros = useMemo(() => {
        if (!filtros) return 0;
        return Object.keys(filtros).length;
    }, [filtros]);

    const painelEsquerdo = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_PAGAMENTOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/financeiro/financeiro/pagamentos/form"}
            />
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_PAGAMENTOEFETIVADO_INCLUIR]}
                componente={Button}
                disabled={bloqueiaEfetivacao}
                label="Pagar"
                icon="pi pi-dollar"
                className="p-button-info p-mr-2"
                to={{
                    pathname: "/financeiro/financeiro/pagamentos/efetivados/form",
                    state: parcelasSelecionadas,
                }}
            />
            <Button
                label="Filtro Avançado"
                icon={MAKO_ICONS.FILTRAR}
                className="p-button-help p-mr-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => modalFiltroAvancadoRef.current?.abrirModal()}
            />
            <Button
                label="Limpar filtros"
                icon={MAKO_ICONS.LIMPAR_FILTROS}
                className="p-button-warning p-mr-2"
                onClick={() => {
                    setUrlPesquisa("/financeiro/pagamentos?cancelado=false");
                    modalFiltroAvancadoRef.current?.limparFiltros();
                }}
            />
        </>
    );

    const painelDireito = (
        <Dropdown
            options={FILTRO_INDICADORES}
            placeholder="Filtro de indicadores"
            value={filtroIndicadores}
            onChange={(e) => setFiltroIndicadores(e.value)}
        />
    );

    const numeroParcelaBodyTemplate = (rowData) => {
        return <span>{`${rowData.numero_parcela}/${rowData.quantidade_parcelas}`}</span>;
    };

    const colunas = [
        { selectionMode: "multiple", style: { width: "5%" } },
        {
            field: "id",
            header: "Pagamento",
            minWidth: { width: "12%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "documento",
            header: "Documento",
            filter: true,
            filterElement: TextoFiltroTemplate,
            minWidth: { width: "10%" },
        },
        {
            field: "numero_parcela",
            header: "Parcela",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            action: (e) => numeroParcelaBodyTemplate(e),
        },
        {
            field: "data_emissao",
            header: "Emissão",
            filter: true,
            filterElement: DateFiltroTemplate,
            minWidth: { width: "10%" },
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "valor",
            header: "Valor",
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
            action: ({ valor, valor_pago }) => {
                if (parseNumber(valor_pago) === 0) return parseNumber(valor).toFixed(2).toString().replace(".", ",");
                else return parseNumber(valor_pago).toFixed(2).toString().replace(".", ",");
            },
        },
        {
            field: "credor.nome",
            header: "Credor",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "credor",
            minWidth: { width: "15%" },
        },
        {
            field: "vencimento",
            header: "Vencimento",
            filter: true,
            filterElement: DateFiltroTemplate,
            minWidth: { width: "10%" },
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            minWidth: { width: "6%" },
        },
    ];

    const rowClass = (pagamento) => {
        const dias_atraso = pagamento.dias_atraso;
        if (pagamento.cancelado) return "table-pagamentos-overdue";
        else if (pagamento.quitado) return "table-pagamentos-effective";
        else if (!pagamento.quitado && dias_atraso > 0) return "table-pagamentos-maturity";
        else return "table-pagamentos-pending";
    };

    const getIndicadoresPagamento = useCallback(async () => {
        if (!empresaSelecionadaId || !filtroIndicadores) return;
        const params = {
            empresa: empresaSelecionadaId,
            filtro: filtroIndicadores,
        };
        const handlers = {
            200: ({ data }) => setIndicadores(data.result),
            500: ({ data }) => {
                if (data?.msg) {
                    showWarning({
                        summary: "Oopss",
                        detail: data.msg,
                        life: 3000,
                    });
                }
            },
        };
        setLoadingIndicadores(true);
        await httpGet({ url: "/financeiro/indicadores/pagamentos/", params }, handlers);
        setLoadingIndicadores(false);
    }, [empresaSelecionadaId, filtroIndicadores, httpGet, showWarning]);

    const onFinish = (url, filtros) => {
        setUrlPesquisa(url);
        setFiltro(filtros);
    };

    const aposPesquisar = useCallback((data = []) => {
        let somatorios = [];
        Object.values(COLORS_VALIDADE).forEach((k) => somatorios.push({ key: k, qtd: 0 }));
        data.forEach((values) => {
            const { dias_atraso, quitado, cancelado } = values;
            let _key = COLORS_VALIDADE.PENDENTE;
            if (cancelado) _key = COLORS_VALIDADE.CANCELADA;
            else if (!quitado && dias_atraso > 0) _key = COLORS_VALIDADE.VENCIDA;
            else if (quitado) _key = COLORS_VALIDADE.CONCLUIDA;
            somatorios[somatorios?.findIndex(({ key }) => key === _key)].qtd++;
        });
        somatorios = somatorios.toSorted((a, b) => b.qtd - a.qtd);
        setColorAjuda(() => somatorios[0].key);
        return data;
    }, []);

    useEffect(() => {
        getIndicadoresPagamento();
    }, [getIndicadoresPagamento]);

    return (
        <>
            <div className="p-fluid p-grid">
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro blocked={loadingIndicadores} title="A pagar" value={indicadores?.a_pagar} />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro
                        blocked={loadingIndicadores}
                        title="Para hoje"
                        value={indicadores?.pagar_hoje}
                    />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro blocked={loadingIndicadores} title="Pago hoje" value={indicadores?.pago_hoje} />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro
                        blocked={loadingIndicadores}
                        title="Total pago"
                        value={indicadores?.total_pago}
                    />
                </div>
            </div>
            <PageBase>
                <MakoListagem
                    ref={listagemRef}
                    titulo={
                        <div className="p-d-flex p-justify-between">
                            <span>Pagamentos</span>
                            {<ValidadeOverlay color={colorAjuda} />}
                        </div>
                    }
                    colunas={colunas}
                    painelEsquerdo={painelEsquerdo}
                    painelDireito={painelDireito}
                    urlPesquisa={`${urlPesquisa}&filtro_periodo=${filtroIndicadores}`}
                    botaoExportar
                    filtarPorEmpresa
                    naoBuscarSemEmpresa
                    configTabela={{
                        selection: parcelasSelecionadas,
                        onSelectionChange: (e) => setParcelasSelecionadas(e.value),
                        selectionMode: "multiple",
                        rowClassName: rowClass,
                        paginator: true,
                        lazy: true,
                        scrollable: true,
                    }}
                    filtros={{
                        id: { value: null, matchMode: "equals" },
                        documento: { value: null, matchMode: "equals" },
                        numero_parcela: { value: null, matchMode: "equals" },
                        data_emissao: { value: null, matchMode: "equals" },
                        vencimento: { value: null, matchMode: "equals" },
                        valor: { value: null, matchMode: "equals" },
                        credor: { value: null, matchMode: "equals" },
                    }}
                    aposPesquisar={aposPesquisar}
                />
                <FiltroPagamentosModalForm
                    ref={modalFiltroAvancadoRef}
                    onFilter={onFinish}
                    baseUrl={URL_BACKEND + "cancelado=false"}
                    filtros={filtros}
                    setFiltro={setFiltro}
                    removerFiltro={removerFiltro}
                />
                <ConfirmDialog
                    visible={confirmDialog}
                    onHide={() => setConfirmDialog(false)}
                    header="Confirmação"
                    message={
                        pagamento && (
                            <span>
                                {`Deseja realmente cancelar o pagamento número `}
                                <b>{pagamento.id}</b>?
                            </span>
                        )
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={() => handleCancelar()}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={() => setConfirmDialog(false)}
                    rejectLabel="Não"
                />
            </PageBase>
            <ModalVisualizarPagamento ref={visualizarModalRef} />
        </>
    );
};
