import React, { useRef } from "react";

import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, EstoqueFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";

export const VinculoCentroEstocagemResultado = () => {
    const history = useHistory();

    const listagemRef = useRef();
    useClearRefs(listagemRef);

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_CENTROESTOCAGEM_CENTRORESULTADO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/produto/cadastros/vinculo-estocagem-resultado/form")}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_CENTROESTOCAGEM_CENTRORESULTADO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/cadastros/vinculo-estocagem-resultado/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_CENTROESTOCAGEM_CENTRORESULTADO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/produtos/vinculo-centros-estocagem-resultados/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir o vinculo entre <b>{rowData.centro_estocagem.nome}</b> e{" "}
                            <b>{rowData.centro_resultado.nome}</b> ?
                        </span>
                    }
                    msgToastErroExclusao="O vinculo não pode ser excluído."
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                    }}
                />
            </div>
        );
    };

    const EstoquesFiltroTemplate = (options) => {
        return <EstoqueFiltroTemplate options={options} />;
    };

    const CentroResultadoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "nome",
                    url: "/plano-operacional/centros-resultados/?limit=500",
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "40px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "centro_estocagem.nome",
            header: "Centro de estocagem",
            style: { minWidth: "200px" },
            filter: true,
            filterElement: EstoquesFiltroTemplate,
        },
        {
            field: "centro_resultado.nome",
            header: "Centro de resultados",
            style: { minWidth: "200px" },
            filter: true,
            filterElement: CentroResultadoFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            style: { minWidth: "50px" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo={
                    <h5>
                        <b>Vinculo de Produtos:</b> Centros de Resultados e Centros de Estocagem
                    </h5>
                }
                urlPesquisa="/produtos/vinculo-centros-estocagem-resultados/"
                painelEsquerdo={cabecalhoTabela}
                colunas={colunas}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    centro_estocagem: { value: null, matchMode: "equals" },
                    centro_resultado: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
