import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLoading from "@/hooks/useLoading";
import { useHistory } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { ConfirmDialog } from "primereact/confirmdialog";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import { PageBase } from "@/components/PageBase";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BotaoDelete } from "@/components/BotaoDelete";

const url = "/produtos/descricao-padrao/";

export const ProdutoDescricaoPadraoForm = () => {
    const [niveis, setNiveis] = useState([]);
    const [nivelAtivo, setNivelAtivo] = useState(0);
    const [ultimoNivel, setUltimoNivel] = useState([]);
    const [deleteNivel, setDeleteNivel] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const { httpDelete, httpGet, httpPost } = useHttp();
    const { showSuccess, showError } = useToast();
    const { state } = useLocation();
    const listagemRef = useRef(null);
    const dropRef = useRef(null);
    const history = useHistory();

    const formik = useFormik({
        initialValues: state
            ? state.descricao
            : {
                  id: null,
                  descricao: "",
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                nivel: Yup.string().required("O campo 'nível' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Descrição cadastrada com sucesso!",
                        life: 1500,
                    });
                    formik.resetForm();
                    listagemRef.current?.buscarDados();
                },
            };

            showLoading();
            await httpPost({ url, body: values }, handlers);
            hideLoading();
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    async function criarNivel() {
        try {
            const handlers = {
                201: ({ data }) => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Nível cadastrada com sucesso!",
                        life: 1500,
                    });
                    dropRef.current?.fetchDados();
                    setUltimoNivel(data);
                },
            };

            showLoading();
            await httpPost(
                { url: "/produtos/niveis-padrao-descricao/", body: { nivel: ultimoNivel.nivel + 1 } },
                handlers
            );
            hideLoading();
        } catch (error) {
            hideLoading();
            showError();
        }
    }

    async function deletarNivel() {
        try {
            const handlers = {
                204: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Nível deletado com sucesso!",
                        life: 1500,
                    });
                    dropRef.current?.fetchDados();
                    carregarUltimoNivel();
                },
            };

            showLoading();
            await httpDelete({ url: `/produtos/niveis-padrao-descricao/${ultimoNivel.id}/` }, handlers);
            hideLoading();
        } catch (error) {
            hideLoading();
            showError();
        }
    }

    const carregarUltimoNivel = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                if (data.results?.length > 0) setUltimoNivel(data.results[0]);
                else setUltimoNivel({ nivel: 0 });
            },
        };
        await httpGet({ url: "/produtos/niveis-padrao-descricao?ordering=-id&limit=1" }, handlers);
    }, [httpGet]);

    useEffect(() => {
        carregarUltimoNivel();
    }, [carregarUltimoNivel]);

    const cancelar = () => {
        formik.resetForm();
        history.push("/");
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_DESCRICAOPADRAO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a descrição <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A descrição não pode ser excluída."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "15%" } },
        { field: "descricao", header: "Descrição" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const colunasDinamicas = niveis.map((nivel, index) => {
        return (
            <TabPanel key={index} header={`Nível ${nivel.nivel}`} leftIcon="pi pi-circle-fill">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={`/produtos/descricao-padrao?nivel=${nivel.id}`}
                    responsiva
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            </TabPanel>
        );
    });

    return (
        <PageBase>
            <h3>Descrição Padronizada</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            keyfilter={/^[a-zA-Z0-9}{)(/#$@!%&*-*+]/}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="nivel">Selecione um nível: *</label>
                        <div className="p-inputgroup">
                            <Dropdown
                                ref={dropRef}
                                id="nivel"
                                name="nivel"
                                placeholder="Selecione um nivel"
                                onChange={formik.handleChange}
                                value={formik.values.nivel}
                                showClear
                                optionValue="id"
                                optionLabel="nivel"
                                setObjects={setNiveis}
                                url="/produtos/niveis-padrao-descricao?ordering=id&limit=50"
                                autoComplete="off"
                                autoFocus
                            />
                            <MakoControleAcesso
                                permissao={[permissoes.PRODUTO_CADASTRO_NIVELDESCRICAOPADRAO_INCLUIR]}
                                componente={Button}
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-success"
                                onClick={() => criarNivel()}
                            />
                            <MakoControleAcesso
                                permissao={[permissoes.PRODUTO_CADASTRO_NIVELDESCRICAOPADRAO_EXCLUIR]}
                                componente={Button}
                                type="button"
                                icon="pi pi-minus"
                                className="p-button-danger"
                                onClick={() => setDeleteNivel(true)}
                            />
                        </div>
                        {formik.errors.nivel && <small className="p-error">{formik.errors.nivel}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12">
                    <Button label="Adicionar" type="submit" className="p-button-info p-mr-2" />
                    <Button label="Voltar" type="reset" className="p-button-danger p-mr-2" onClick={cancelar} />
                </div>
            </form>
            <TabView
                className="tabview-custom"
                activeIndex={nivelAtivo}
                onTabChange={(e) => setNivelAtivo(e.index)}
                type={"button"}
            >
                {colunasDinamicas ? colunasDinamicas : null}
            </TabView>
            <ConfirmDialog
                visible={deleteNivel}
                onHide={() => setDeleteNivel(false)}
                header="Confirmação"
                message={
                    ultimoNivel && (
                        <span>
                            {"Deseja realmente excluir o nível "}
                            <b>{ultimoNivel.nivel}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={() => deletarNivel()}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={() => setDeleteNivel(false)}
                rejectLabel="Não"
            />
        </PageBase>
    );
};
