import React, { useState } from "react";
import { Button } from "primereact/button";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";

export const ImprimirFichaCrediarioForm = ({ esconderDialog }) => {
    const [ficha, setFicha] = useState(null);
    const { showError } = useToast();

    async function handleImprimir() {
        try {
            // /relatorios/gerar/
            // usar o hook useRelatorio, ele ja faz todas as tratativas
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Falha ao gerar ficha de crediário, por favor tente novamente.",
                life: 1500,
            });
        }
    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-fluid p-formgrid p-grid ">
                    <div className="p-field p-col-12 p-md--12">
                        <label htmlFor="ficha">Selecione uma ficha: *</label>
                        <Dropdown
                            id="ficha"
                            name="ficha"
                            url={`/relatorios/relatorios?tipo=2`}
                            optionValue="id"
                            optionLabel="descricao"
                            filter
                            filterBy="descricao"
                            placeholder="Selecione..."
                            value={ficha}
                            onChange={(e) => setFicha(e.target.value)}
                        />
                    </div>
                </div>
                <div className="p-grid p-justify-center p-mt-3">
                    <Button
                        type="button"
                        icon="pi pi-print"
                        label="Imprimir"
                        className="p-button-info p-mr-2"
                        disabled={!ficha}
                        onClick={() => handleImprimir()}
                    />
                    <Button
                        type="button"
                        icon="pi pi-times"
                        label="Fechar"
                        className="p-button-danger p-button-outlined"
                        onClick={() => esconderDialog(false)}
                    />
                </div>
            </div>
        </div>
    );
};
