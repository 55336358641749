export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

export function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}

/*
  Retorna a nova área delimitadora de um retângulo girado.
 */
export function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);

    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
}

export default async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
        return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calcula a caixa delimitadora da imagem girada
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

    // define o tamanho da tela para corresponder à caixa delimitadora
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // traduz o contexto da tela para um local central para permitir girar e girar ao redor do centro
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // desenha a rotação da imagem
    ctx.drawImage(image, 0, 0);

    // Os valores croppedAreaPixels são relativos à caixa delimitadora
    // extrai a imagem cortada usando esses valores
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

    // define a largura da tela para o tamanho final de corte desejado - isso limpará o contexto existente
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // cola a imagem de rotação gerada no canto superior esquerdo
    ctx.putImageData(data, 0, 0);

    // retorno como Base64 string
    return canvas.toDataURL("image/jpeg");

    // // caso queira retornar com formato blob
    // return new Promise((resolve, reject) => {
    //     canvas.toDataURL((file) => {
    //         resolve(URL.createObjectURL(file));
    //     }, "image/png");
    // });
}
