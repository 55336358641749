import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";

import { MovimentacoesCaixa, PendenciasCaixa } from "./listagens";
import { Sessoes as S } from "./sessoes";

import {
    ACTIONS_OPERAR_MODAIS_CAIXA,
    INITIAL_STATE_OPERAR_MODAIS,
    operarModaisCaixaReducer,
} from "./reducers/operar-modais-caixa";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useClearRefs from "@/hooks/useClearRefs";

import { COLORS_STATUS_CAIXA, MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

export const GerenciarCaixa = () => {
    const [modal, dispatchModal] = useReducer(operarModaisCaixaReducer, INITIAL_STATE_OPERAR_MODAIS);
    const [pendencias, setPendentes] = useState(true);

    const { caixaMov, loadCaixa: handleCaixa } = useCaixaMovimento();

    const listagemRef = useRef();

    useClearRefs(listagemRef);

    const fecharModal = useCallback((updateMovimentacao = false) => {
        if (updateMovimentacao) listagemRef.current?.buscarDados();
        dispatchModal({ type: ACTIONS_OPERAR_MODAIS_CAIXA.ESCONDERMODAL });
    }, []);

    const iconStatus = (
        <i
            className={caixaMov?.bloqueado ? MAKO_ICONS.BLOQUEADO : MAKO_ICONS.ABERTO}
            style={{ color: caixaMov?.bloqueado ? COLORS_STATUS_CAIXA.BLOQUEADO : COLORS_STATUS_CAIXA.ABERTO }}
        />
    );

    const listagem = useMemo(() => {
        if (!pendencias) return <MovimentacoesCaixa listagemRef={listagemRef} actionDispatch={dispatchModal} />;
        return <PendenciasCaixa />;
    }, [pendencias]);

    useEffect(() => handleCaixa(), [handleCaixa]);

    return (
        <PageBase>
            <h5>{caixaMov ? <span>Gestão de caixa {iconStatus}</span> : "Caixa"}</h5>
            {caixaMov ? (
                <>
                    <S.Info />
                    <S.Acoes
                        dispatch={dispatchModal}
                        fecharModal={fecharModal}
                        pendencias={pendencias}
                        setPendentes={setPendentes}
                    />
                    <S.Pendencia />
                    {listagem}
                </>
            ) : (
                <MakoControleAcesso
                    componente={Button}
                    permissao={[
                        permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_MOVIMENTAR,
                        permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_ABRIR,
                    ]}
                    exibirDesabilitadoSemPermissao={true}
                    label="Abrir o caixa"
                    onClick={() =>
                        dispatchModal({
                            type: ACTIONS_OPERAR_MODAIS_CAIXA.ABRIR,
                            payload: {
                                formProps: {
                                    fecharModal,
                                    handleCaixa,
                                },
                            },
                        })
                    }
                />
            )}
            <Dialog visible={modal.visible} header={modal.header} style={modal.style} onHide={fecharModal}>
                {modal.content}
            </Dialog>
        </PageBase>
    );
};
