import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import useClearRefs from "@/hooks/useClearRefs";
import {
    CategoriaFiltroTemplate,
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    EstoqueFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { MOTIVOS_INVENTARIO_ESTOQUE } from "@/assets/constants/constants";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import { dataToStr } from "@/assets/util/datas";
import { MakoCalendar } from "@/components/MakoCalendar";

export const LoteInventarioPage = () => {
    const [loteInventario, setLoteInventario] = useState(null);
    const history = useHistory();

    const listagemRef = useRef(null);
    const menuRef = useRef(null);

    useClearRefs(menuRef, listagemRef);

    const itensMenu = [
        {
            label: "Opções",
            items: [
                {
                    label: "Lançar itens",
                    command: () =>
                        history.push({
                            pathname: "/estoque/inventario/lotes-inventario/itens",
                            state: { loteInventario },
                        }),
                },
                {
                    label: "Processar",
                    // command: () => {
                    //     showWarning({ summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                    // }
                },
                {
                    label: "Gerar SPED",
                    disabled: true,
                    // command: () => {
                    //     showWarning({ summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                    // }
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Menu model={itensMenu} popup ref={menuRef} id="popup_menu" />
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/inventario/lotes-inventario/form",
                            state: rowData,
                        })
                    }
                />
                <Button
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info p-mr-2"
                    aria-controls="popup_menu"
                    aria-haspopup
                    onClick={(e) => {
                        setLoteInventario(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
                <BotaoDelete
                    url="/produtos/lotes-inventario/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Confirma a remoção do lote de inventário número <b>{rowData.id}</b>?
                            <p>
                                <b>LEMBRE-SE</b>: Essa ação apagará também todos os itens associados e <b>NÃO</b> pode
                                ser revertida.
                            </p>
                        </span>
                    }
                    msgToastErroExclusao="O lote não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const EstoquesFiltroTemplate = (options) => {
        return <EstoqueFiltroTemplate filtrarPorEmpresa={false} options={options} />;
    };

    const MotivoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: MOTIVOS_INVENTARIO_ESTOQUE,
                }}
                options={options}
            />
        );
    };

    const dateFiltroTemplate = (options) => {
        return (
            <MakoCalendar
                valueCalendar={options.value}
                onChange={(e) => {
                    options.filterCallback(dataToStr(e.value, "yyyy-MM-dd"));
                }}
                className="p-column-filter"
            />
        );
    };

    const colunas = [
        { field: "id", header: "Número", style: { width: "10%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "categoria.nome",
            header: "Categoria",
            filter: true,
            filterElement: CategoriaFiltroTemplate,
            filterField: "categoria",
        },
        {
            field: "centro_estocagem.nome",
            header: "Centro de estocagem",
            filter: true,
            filterElement: EstoquesFiltroTemplate,
            filterField: "centro_estocagem",
        },
        {
            field: "motivo_inventario.descricao",
            header: "Motivo",
            style: { width: "35%" },
            filter: true,
            filterElement: MotivoFiltroTemplate,
            filterField: "motivo_inventario",
        },
        {
            field: "data_hora",
            header: "Data",
            style: { width: "10%" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: dateFiltroTemplate,
            filterField: "data_hora",
        },
        { field: "actions", header: "Ações", style: { width: "15%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const painelEsquerdoTabela = (
        <Button
            label="Novo"
            icon="pi pi-plus"
            className="p-button-success p-mr-2 p-mb-2"
            onClick={() => history.push("/estoque/inventario/lotes-inventario/form")}
        />
    );

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Inventário de estoque físico"
                        urlPesquisa="/produtos/lotes-inventario/"
                        colunas={colunas}
                        painelEsquerdo={painelEsquerdoTabela}
                        botaoExportar
                        configTabela={{ lazy: true, paginator: true }}
                        filtros={{
                            id: { value: null, matchMode: "equals" },
                            categoria: { value: null, matchMode: "equals" },
                            centro_estocagem: { value: null, matchMode: "equals" },
                            motivo_inventario: { value: null, matchMode: "equals" },
                            data_hora: { value: null, matchMode: "equals" },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
