import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { BaseModalAjusteComissao } from "./BaseModal";
import { Dropdown } from "@/components/Dropdown";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const Modal = ({ onFechar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [submit, setSubmit] = useState(null);
    const [novoComissionado, setNovoComissionado] = useState(null);
    const [baseComissao, setBaseComissao] = useState(null);
    const { httpPatch } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();

    async function handleSubmit() {
        if (!novoComissionado) {
            setSubmit({ err: "Selecione um novo comissionado." });
            return;
        }
        if (baseComissao.comissionado?.id === novoComissionado) {
            setSubmit({ err: "Informe um novo comissionado diferente do atual." });
            return;
        }
        const url = `/vendas/bases-comissoes-vendas/${baseComissao.id}/`;
        const body = { comissionado: novoComissionado };
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Comissionado alterado com sucesso!",
                    life: 1500,
                });
                fecharModal();
            },
        };
        showLoading();
        await httpPatch({ url, body }, handlers);
        hideLoading();
    }

    const abrirModal = (baseComissao) => {
        setVisible(true);
        setBaseComissao(baseComissao);
    };

    const fecharModal = () => {
        setVisible(false);
        setBaseComissao(null);
        setSubmit(null);
        setNovoComissionado(null);
        if (typeof onFechar === "function") onFechar();
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const filtrarVendedoresAtivos = useCallback((vendedores) => {
        return vendedores.filter((vendedor) => vendedor.usuario?.is_active);
    }, []);

    const footer = (
        <div>
            <Button
                type="button"
                icon="pi pi-times"
                label="Cancelar"
                loading={loading}
                severity="danger"
                onClick={fecharModal}
            />
            <Button type="button" icon="pi pi-save" label="Salvar" loading={loading} onClick={handleSubmit} />
        </div>
    );

    if (!baseComissao) return <></>;

    return (
        <Dialog header="Alterando comissionado de uma comissão" visible={visible} onHide={fecharModal} footer={footer}>
            <BaseModalAjusteComissao baseComissao={baseComissao} />
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="comissionado-atual">Comissionado(a) atual</label>
                    <InputText id="comissionado-atual" disabled value={baseComissao.comissionado?.nome || ""} />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="novo-comissionado">Novo comissionado(a)</label>
                    <Dropdown
                        id="novo-comissionado"
                        name="vendedor"
                        url="/pessoas/vendedores/?query={id,usuario,nome}"
                        aposBuscar={filtrarVendedoresAtivos}
                        optionValue="id"
                        optionLabel="nome"
                        filter
                        filterBy="nome"
                        value={novoComissionado}
                        onChange={(e) => {
                            setNovoComissionado(e.value);
                            setSubmit(null);
                        }}
                        className={classNames({ "p-invalid": submit })}
                    />
                    {submit && <small className="p-error">{submit.err}</small>}
                </div>
            </div>
        </Dialog>
    );
};

export const ModalAlteraComissionado = forwardRef(Modal);
