import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Menu } from "primereact/menu";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import {
    ClienteFiltroTemplate,
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

export const VendaTrocaPage = () => {
    const [troca, setTroca] = useState(null);
    const menu = useRef(null);
    const listagemRef = useRef(null);
    const history = useHistory(null);
    const { showSuccess, showError, showWarning } = useToast();
    const { httpPut } = useHttp();

    const statusTroca = async (op) => {
        try {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Troca validada com sucesso!",
                        life: 3000,
                    });
                    listagemRef.current?.buscarDados();
                },
                409: (err) =>
                    showWarning({
                        summary: "Falha",
                        detail: err.msg,
                        life: 3000,
                    }),
            };

            await httpPut(
                {
                    url: `/vendas/status-troca/${troca.id}/`,
                    body: {
                        status: op,
                    },
                },
                handlers
            );
        } catch {
            showError();
        }
    };

    const inserirVenda = async () => {
        try {
            const handlers = {
                200: ({ data }) => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Venda inserida com sucesso validada com sucesso!",
                        life: 3000,
                    });
                    history.push({
                        pathname: "/vendas/venda/form",
                        state: {
                            idVenda: data.venda,
                        },
                    });
                },
                409: (err) =>
                    showWarning({
                        summary: "Falha",
                        detail: err.msg,
                        life: 3000,
                    }),
            };

            await httpPut(
                {
                    url: `/vendas/inserir-venda-troca/${troca.id}/`,
                },
                handlers
            );
        } catch {
            showError({
                summary: "Erro",
                detail: "Desculpe, não foi possível processar a sua requisição.",
                life: 3000,
            });
        }
    };

    async function processarDevolucao() {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Troca processada com sucesso!",
                    life: 1500,
                });
                setTroca(null);
                listagemRef.current?.buscarDados();
            },
            409: (err) =>
                showWarning({
                    summary: "Aviso",
                    detail: err.msg,
                    life: 3000,
                }),
        };

        await httpPut(
            {
                url: `/vendas/processar-troca/${troca?.id}/`,
            },
            handlers
        );
    }

    const confirmarProcessamentoDevolucao = () => {
        confirmDialog({
            message: "Confirma a devolução dos itens da troca?",
            header: "Confirmação de processamento",
            icon: "pi pi-exclamation-triangle",
            accept: processarDevolucao,
            acceptLabel: "Confirmar",
            rejectLabel: "Cancelar",
        });
    };

    const botoesTroca = [
        {
            label: "Ações",
            items: [
                {
                    label: "Validar",
                    icon: "pi pi-check",
                    disabled: troca?.status.id === "C" || troca?.status.id === "F" || troca?.status.id === "D",
                    command: () => statusTroca("V"),
                },
                {
                    label: "Processar",
                    icon: "pi pi-cog",
                    disabled: troca?.status.id !== "V",
                    command: confirmarProcessamentoDevolucao,
                },
                {
                    label: !troca?.nova_venda ? "Gerar venda" : "Consultar venda",
                    icon: "pi pi-ticket",
                    disabled: troca?.status.id === "C" || troca?.status.id === "P" || troca?.status.id === "V",
                    command: () => {
                        if (troca?.nova_venda) {
                            history.push({
                                pathname: "/vendas/venda/form",
                                state: {
                                    idVenda: troca.nova_venda,
                                },
                            });
                        } else confirmarInclusaoVenda();
                    },
                },
                {
                    label: "Cancelar",
                    icon: "pi pi-times",
                    disabled: troca?.status.id === "C" || troca?.status.id === "F",
                    command: () => confirmarCancelamentoTroca(),
                },
            ],
        },
    ];

    const confirmarInclusaoVenda = () => {
        confirmDialog({
            message: `Deseja realmente incluir uma venda referênte a troca número ${troca?.id}?`,
            header: "Confirmação de inclusão",
            icon: "pi pi-exclamation-triangle",
            accept: inserirVenda,
            acceptLabel: "Confirmar",
            acceptClassName: "p-button-succes",
            rejectLabel: "Cancelar",
        });
    };

    const confirmarCancelamentoTroca = () => {
        confirmDialog({
            message: `Deseja realmente cancelar a troca número ${troca?.id}?`,
            header: "Confirmação de cancelamento",
            icon: "pi pi-exclamation-triangle",
            accept: () => statusTroca("C"),
            acceptLabel: "Confirmar",
            acceptClassName: "p-button-danger",
            rejectLabel: "Cancelar",
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TROCA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar informações da troca"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status?.id === "C"}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/trocas/form",
                            state: { troca: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TROCA_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    model={botoesTroca}
                    tooltip="Operações da troca"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status?.id === "C"}
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setTroca(rowData);
                    }}
                />
            </div>
        );
    };

    const StatusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: "P", label: "Pendente" },
                        { value: "v", label: "Validada" },
                        { value: "F", label: "Finalizada" },
                        { value: "C", label: "Cancelada" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "cliente.nome",
            header: "Cliente",
            filter: true,
            filterElement: ClienteFiltroTemplate,
            filterField: "cliente",
        },
        {
            field: "data_venda",
            header: "Data",
            style: { width: "15%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "status.descricao",
            header: "Situação",
            style: { width: "15%" },
            filter: true,
            filterElement: StatusFiltroTemplate,
            filterField: "status",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_TROCA_INCLUIR]}
                componente={Button}
                icon="pi pi-sync"
                label="Realizar Troca"
                className="p-button-info p-mr-2"
                type="button"
                onClick={() => history.push("/vendas/venda/trocas/form")}
            />
        </>
    );

    const rowClass = (transferencia) => {
        return {
            "table-recebimentos-pending": transferencia.status.id === "V",
            "table-recebimentos-overdue": transferencia.status.id === "C",
            "mako-table-status-ativo ": transferencia.status.id === "F",
            "table-recebimentos-effective": transferencia.status.id === "D",
        };
    };

    return (
        <PageBase>
            <Menu model={botoesTroca} popup ref={menu} id="popup_menu" />
            <h5>Trocas</h5>
            <MakoListagem
                ref={listagemRef}
                painelEsquerdo={cabecalhoTabela}
                colunas={colunas}
                urlPesquisa={"/vendas/troca/"}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                configTabela={{
                    paginator: true,
                    lazy: true,
                    rowClassName: rowClass,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    cliente: { value: null, matchMode: "equals" },
                    data_venda: { value: null, matchMode: "equals" },
                    status: { value: null, matchMode: "equals" },
                }}
            />
            <ConfirmDialog />
        </PageBase>
    );
};
