import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ParametrosGeraisForm } from "./formParametros";
import { NcmForm } from "./formNcm";
import { ProdutosMercadoriasForm } from "./formProdutosMercadorias";
import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export const TabOperacoes = () => {
    const { opFiscal } = useLocation();
    const [titulo, setTitulo] = useState(opFiscal?.descricao);
    const history = useHistory();

    const navegacaoDesabilitada = !opFiscal;

    const TABS = [
        {
            header: "Parâmetros gerais",
            icon: "pi pi-fw pi-book",
            component: <ParametrosGeraisForm opFiscal={opFiscal} setTitulo={setTitulo} />,
        },
        {
            header: "NCM's",
            icon: "pi pi-fw pi-user",
            component: <NcmForm opFiscal={opFiscal} />,
            disabled: navegacaoDesabilitada,
        },
        {
            header: "Produtos / mercadorias",
            icon: "fas pi-fw fa-cubes",
            component: <ProdutosMercadoriasForm opFiscal={opFiscal} />,
            disabled: navegacaoDesabilitada,
        },
    ];

    const voltarParaListagem = () => {
        history.push("/fiscal/cadastros/operacoes-fiscais");
    };

    return (
        <PageBase>
            <MakoMultiTabs
                title={`Cadastro de operação fiscal ${titulo ? `- ${titulo}` : ""}`}
                tabs={TABS}
                ocultarButtonFinalizar
                propsButtonAnt={{ disabled: navegacaoDesabilitada }}
                propsButtonProx={{ disabled: navegacaoDesabilitada }}
                propsButtonCancelar={{ onClick: voltarParaListagem }}
            />
        </PageBase>
    );
};
