import * as Yup from "yup";

import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_FINANCEIRO_MOVIMENTACAOCONTAFINANCEIRA, RELATORIO_FISCAL_TRANSFERENCIAPRODUTOS } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";
import MakoListagem from "@/components/MakoListagem";
import { parseMoeda } from "@/assets/util/util";
import { useRef, useState } from "react";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { InputText } from "primereact/inputtext";
import { MakoInputNcm } from "@/components/MakoInputs/MakoInputNcm";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

const BASE_URL = "/relatorios/fiscal-transferencia-produtos"

const FILTROS_VIEWSET = {
    data_start: 'nota_fiscal__transferencia__data_emissao__gte',
    data_end: 'nota_fiscal__transferencia__data_emissao__lte',
    categoria: "item__categoriasku__categoria__id",
    empresa_origem: "nota_fiscal__transferencia__ce_origem__estoque_empresa__empresa__id",
    empresa_destino: "nota_fiscal__transferencia__ce_destino__estoque_empresa__empresa__id",
    destinacao: "operacao_fiscal__destinacao__id",
    lote: "lote",
    descricao: "item__descricao_reduzida",
    ncm: "item__item__ncm__codigo"
}

export const TransferenciaProdutos = () => {
    
    const { solicitarRelatorio } = useRelatorio();
    const [buscarListagem, setBuscarListagem] = useState(false)
    const [url, setUrl] = useState('')
    const listagemRef = useRef()
    
    const categoriaRef = useRef();
    const empresaSelecionada = useRef();
    const [formatarDocumento] = useFormatCNPJCPF()
    const formRef = useRef();

    useClearRefs(empresaSelecionada, formRef);

    const filtrosTemplate = [
        {
            key: "empresa_origem",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "empresa_destino",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["data_start", "data_end"],
            label: "Período",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "destinacao",
            label: "Destinação",
            path: "nome",
        },
        {
            key: "categoria",
            label: "Categoria",
            path: "nome",
            type: TIPOS_FILTROS_TEMPLATE.MULTISELECT,
        },
        {
            key: "lote",
            label: "Lote",
            path: "motivo_inventario.descricao",
        },
        {
            label: "Descrição contém",
            inputId: "descricao",
            inputName: "descricao",
            component: InputText,
            componentProps: {
                eventChangeKey: "onInput",
            },
            fieldSize: 3,
        },
        {
            key: "descricao",
            label: "Descrição",
        },
    ];

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setBuscarListagem(false)
        setUrl(null);
    };

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setErrors = (values) => {
        formRef.current.setErrors(values);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    async function handleSubmit({ background, emails, corpo_email,versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                data_start: Yup.date().required("Informe um 'data inicial de transferência' válido").typeError("Informe um 'data final de transferência' válido"),
                data_end: Yup.date().when("data_start", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.data_start || new Date(), "O campo 'data final' não pode ser anterior a inicial")
                        .typeError("Informe um 'data final de transferência' válido"),
                    otherwise: Yup.date().nullable(),
                }),
                empresa_origem: Yup.number().nullable().typeError("Informe uma 'empresa de origem' válida"),
                empresa_destino: Yup.number().nullable().typeError("Informe uma 'empresa de destino' válida"),
                categoria: Yup.array().nullable().typeError("Informe 'categorias' válidas"),
                destinacao: Yup.object().nullable().default(null).nullable(),
                lote: Yup.object().nullable().default(null).typeError("Seleciona um 'lote' válido."),
                descricao: Yup.string().typeError("Informe uma 'descrição' válida"),
                ncm: Yup.string().typeError("Informe uma 'descrição' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });
            
            const selecionados = categoriaRef.current;

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
                categoria: selecionados,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.data_start instanceof Date)
                dadosValidados.data_start = dataToStr(dadosValidados.data_start, "yyyy-MM-dd");
            if (dadosValidados.data_end instanceof Date)
                dadosValidados.data_end = dataToStr(dadosValidados.data_end, "yyyy-MM-dd");
            if (dadosValidados.conta) dadosValidados.conta = dadosValidados.conta.id;
            if (dadosValidados.destinacao) dadosValidados.destinacao = dadosValidados.destinacao.id;
            if (selecionados) dadosValidados.categoria = selecionados.map((c) => c.id);
            if (dadosValidados.lote) dadosValidados.lote = dadosValidados.lote.id
            if(buscarListagem === false){

                let filtros = {};
                if (dadosValidados) {
                    Object.keys(dadosValidados).forEach((key) => {
                        if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                    });
                }
                solicitarRelatorio({
                    chave: RELATORIO_FISCAL_TRANSFERENCIAPRODUTOS,
                    emails,
                    filtros,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                    corpo_email,
                    versao
                });
                limparFormulario();
            }
            else{
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        {field: 'codigo', header: 'Código'},
        {field: 'descricao', header: "Descrição"},
        {field: 'nota_fiscal', header: "Nota Fiscal"},
        {field: 'cfop_id', header: "CFOP"},
        {field: 'unidade_medida', header: "Un"},
        {field: 'data_transf', header: "Data Transf.", action: (e) => e.data_transf},
        {field: 'nome_curto_origem', header: "Nome Curto Emp. Origem", action: (e) => `${e.nome_curto_origem} - ${formatarDocumento(e.cnpj_empresa_origem)}`},
        {field: 'nome_curto_destino', header: "Nome Curto Emp. Destino", action: (e) => `${e.nome_curto_destino} - ${formatarDocumento(e.cnpj_empresa_destino)}`},
        {field: 'quantidade', header: "Quantidade"},
        {field: 'valor_unitario', header: "Custo Unitário", action: (e) => parseMoeda(e.valor_unitario)},
        {field: 'custo_total', header: "Total", action: (e) => parseMoeda(e.custo_total)}
    ]

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    const getCategoriaCompleta = (e) => {
        categoriaRef.current = e;
    };

    return (
        <R.Wrapper titulo={"transferência de produtos"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        data_start: null,
                        data_end: null,
                        categoria: null,
                        destinacao: null,
                        empresa_origem: null,
                        empresa_destino: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa Origem",
                        inputId: "empresa_origem",
                        inputName: "empresa_origem",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            placeholder: "Selecione uma empresa de origem",
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Empresa Destino",
                        inputId: "empresa_destino",
                        inputName: "empresa_destino",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            placeholder: "Selecione uma empresa de destino",
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Categoria",
                        inputId: "categoria",
                        inputName: "categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: getCategoriaCompleta,
                            showClear: true,
                            selectionMode: "checkbox",
                            metaKeySelection: false,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Destinação",
                        inputId: "destinacao",
                        inputName: "destinacao",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um tipo...",
                            url: "/compras/destinacoes-entradas/",
                            optionLabel: "nome",
                            filter: true,
                            filterBy: "nome",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Periodo de Transferência: ",
                        inputId: "data_start",
                        inputName: "data_start",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "data_end",
                        inputName: "data_end",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "NCM",
                        inputId: "ncm",
                        inputName: "ncm",
                        component: InputText,
                        componentProps: {
                            showClear: true,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Descrição contém",
                        inputId: "descricao",
                        inputName: "descricao",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                        },
                        fieldSize: 3,
                    },
                ]}
            ></MakoFormGerador>
            <R.Buttons.Wrapper>
                <R.Buttons.Visualizar onClick={handleVisualizar}/>
                <R.Buttons.GerarPdf
                    chave={RELATORIO_FISCAL_TRANSFERENCIAPRODUTOS}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </R.Buttons.Wrapper>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={url}
                msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </R.Wrapper>
    );
}