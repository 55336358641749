import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { MakoButton as Button } from "@/components/MakoButton";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import { CodigoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

export const VinculoMotoristaVeiculoPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_TAGSPERFIL_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/pessoas/vinculo-motorista-veiculo/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente remover o vínculo?</span>}
                    msgToastErroExclusao="A o vínculo não pode ser desfeito."
                    tooltip="Remover vínculo"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_TAGSPERFIL_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/gestao/cadastros/vinculo-motorista-veiculo/form"}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "6%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        { field: "perfil_motorista.nome", header: "Motorista", filter: true, filterField: "perfil_motorista__nome" },
        {
            field: "perfil_motorista.identificacao",
            header: "CPF/Cnpj",
            filter: true,
            filterField: "perfil_motorista__identificacao",
        },
        { field: "veiculo_entrega.placa", header: "Placa", filter: true, filterField: "veiculo_entrega__placa" },
        { field: "veiculo_entrega.chassi", header: "Chassi", filter: true, filterField: "veiculo_entrega__chassi" },
        { field: "veiculo_entrega.renavan", header: "Renavan", filter: true, filterField: "veiculo_entrega__renavan" },
        {
            field: "action",
            header: "Ações",
            style: { width: "8%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Vínculo Motorista com Veículo"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={"/pessoas/vinculo-motorista-veiculo/"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    perfil_motorista__nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    perfil_motorista__identificacao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    veiculo_entrega__placa: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    veiculo_entrega__chassi: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    veiculo_entrega__renavan: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
