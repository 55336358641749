import React from "react";

import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import useToast from "@/hooks/useToast";
import useLoading from "@/hooks/useLoading";
import { dataToStr } from "@/assets/util/datas";
import * as Yup from "yup";
import useHttp from "@/hooks/useHttp";

export const VoucherForm = () => {
    const { showSuccess, showError } = useToast();
    const { showLoading, hideLoading } = useLoading();
    const { httpPost } = useHttp();
    const history = useHistory();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            codigo: "",
            cliente: null,
            devolucao: null,
            data_validade: null,
            valor: 0,
            situacao: "A",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string()
                    .max(10, "Quantidade máxima de caractere: 10")
                    .required("O campo 'código' é obrigatório")
                    .typeError("Informe um 'código' válido"),
                cliente: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'cliente' é obrigatório."),
                    })
                    .typeError("Informe uma 'cliente' válida."),
                devolucao: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'devolução' é obrigatório."),
                    })
                    .typeError("Informe uma 'devolução' válida."),
                data_validade: Yup.date()
                    .required("O campo 'data de validade' é obrigatório")
                    .typeError("Informe uma 'data validade' válida"),
                valor: Yup.number()
                    .min(0.01, "O valor não pode ser ZERO.")
                    .required("O campo 'valor' é obrigatório.")
                    .typeError("Informe um 'valor' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.cliente = values.cliente.id;
            values.devolucao = values.devolucao.id;
            values.data_validade = dataToStr(values.data_validade, "yyyy-MM-dd");

            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso, :)",
                        detail: "Voucher cadastrado com sucesso",
                        life: 3000,
                    });
                    history.push("/vendas/cadastros/voucher");
                },
            };

            showLoading();
            await httpPost(
                {
                    url: "/vendas/voucher/",
                    body: {
                        ...values,
                        cliente: values.cliente.id,
                        devolucao: values.devolucao.id,
                        data_validade: dataToStr(values.data_validade, "yyyy-MM-dd"),
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    function gerarCodigo() {
        let id = "";
        let chars = "012345678901234567890123456789012345678901245646465487934567890123456789";

        for (let i = 0; i < 10; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        setFieldValue("codigo", id);
        showSuccess({
            summary: "Mako",
            detail: "Código gerado com sucesso!.",
            life: 3000,
        });
    }

    const handleReset = () => {
        formik.resetForm();
    };

    const autoCompleteClienteTemplate = (item) => {
        return <div>{`${item.nome} - ${item.identificacao}`}</div>;
    };

    return (
        <div className="card p-grid">
            <h3>Novo voucher</h3>
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="codigo">Código *</label>
                            <div className="p-inputgroup">
                                <InputText
                                    id="codigo"
                                    name="codigo"
                                    onChange={formik.handleChange}
                                    value={formik.values.codigo}
                                    autoComplete="off"
                                    className={classNames({ "p-invalid": formik.errors.codigo })}
                                />
                                <Button
                                    type="button"
                                    icon="pi pi-refresh"
                                    className="p-button-info"
                                    tooltip="Gerar código aleatório"
                                    onClick={() => gerarCodigo()}
                                />
                            </div>
                            {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="cliente">Cliente:</label>
                            <MakoAutoComplete
                                id="cliente"
                                name="cliente"
                                value={formik.values.cliente}
                                onChange={formik.handleChange}
                                minCaracteresBusca={4}
                                itemTemplate={autoCompleteClienteTemplate}
                                field="nome"
                                key="id"
                                urlSearch="/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,telefoneperfil_set}&ativo=true&nome__unaccent__icontains="
                                placeholder="Digite o nome do cliente para buscar... (min 4 caracteres)"
                                className={classNames({ "p-invalid": formik.errors.cliente })}
                            />
                            {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="devolucao">Devolução:</label>
                            <MakoAutoComplete
                                id="devolucao"
                                name="devolucao"
                                value={formik.values.devolucao}
                                onChange={formik.handleChange}
                                disabled={formik.values.cliente === null}
                                minCaracteresBusca={4}
                                itemTemplate={autoCompleteClienteTemplate}
                                field="nome"
                                key="id"
                                urlSearch={`/vendas/devolucao?&status=P&cancelada=false&cliente=${formik.values.cliente?.id}&search=`}
                                placeholder="Digite para buscar... (min 4 caracteres)"
                                className={classNames({ "p-invalid": formik.errors.devolucao })}
                            />
                            {formik.errors.cliente && <small className="p-error">{formik.errors.devolucao}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor">Valor: </label>
                            <MakoInputMoeda
                                id="valor"
                                name="valor"
                                somentePositivo
                                valueMoeda={formik.values.valor}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor })}
                            />
                            {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="data_validade">Data validade: </label>
                            <MakoCalendar
                                id="data_validade"
                                name="data_validade"
                                valueCalendar={formik.values.data_validade}
                                minDate={new Date()}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_validade })}
                            />
                            {formik.errors.data_validade && (
                                <small className="p-error">{formik.errors.data_validade}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-8">
                            <label htmlFor="referencia">Referência *</label>
                            <InputTextarea
                                id="referencia"
                                name="referencia"
                                value={formik.values.referencia}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.referencia })}
                                autoResize
                                rows={1}
                                maxLength={100}
                            />
                            {formik.errors.referencia && <small className="p-error">{formik.errors.referencia}</small>}
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <Button type="submit" icon="pi pi-check" label="Gravar" className="p-mr-2 p-mb-2" />
                            <Button
                                type="reset"
                                icon="pi pi-trash"
                                label="Limpar"
                                className="p-button-warning p-mr-2 p-mb-2"
                                onClick={handleReset}
                            />
                            <Button
                                type="reset"
                                label="Cancelar"
                                className="p-button-danger p-mb-2"
                                onClick={() => history.goBack()}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
