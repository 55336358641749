import { useContext } from "react";

import TrocaContext from "@/contexts/trocaContext";

const useTroca = () => {
    const troca = useContext(TrocaContext);

    return troca;
};

export default useTroca;
