import React, { memo } from "react";

import { FormCustomFields } from "@/components/FormCustomFields";
import { PRODUTOS_ITEM } from "@/assets/constants/contentTypes";
import useProduto from "@/hooks/useProduto";

const CustomFieldsForm = () => {
    const { dadosBasicos, customFields, handleCustomFields } = useProduto();
    return (
        <FormCustomFields
            localValues={customFields}
            contentType={PRODUTOS_ITEM}
            instance={dadosBasicos?.id}
            onSubmit={(e) => handleCustomFields(e)}
        />
    );
};

export default memo(CustomFieldsForm);
