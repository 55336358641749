import React from "react";

import { Button } from "primereact/button";

import { ListagemMovimentacoes } from "../../features";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

import { ACTIONS_OPERAR_MODAIS_CAIXA } from "../../reducers/operar-modais-caixa";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

const { VISUALIZARLANCAMENTO, ESTORNAR } = ACTIONS_OPERAR_MODAIS_CAIXA;

export const MovimentacoesCaixa = ({ listagemRef, actionDispatch = () => {} }) => {
    const { caixaMov } = useCaixaMovimento();

    const handleAction = (type, rowData) => {
        actionDispatch({
            type,
            payload: {
                lancamentoId: rowData.evento_id,
                formProps: {
                    lancamento: rowData,
                },
            },
        });
    };

    const actions = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_MOVIMENTAR]}
                    componente={Button}
                    icon={MAKO_ICONS.VISUALIZAR}
                    className="p-button-rounded p-button-primary"
                    onClick={() => handleAction(VISUALIZARLANCAMENTO, rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_ESTORNARMOVIMENTACAO]}
                    componente={Button}
                    icon="fa pi-fw fa-reply"
                    className="p-button-rounded p-button-danger"
                    tooltip="Estornar"
                    onClick={() => handleAction(ESTORNAR, rowData)}
                    disabled={caixaMov?.bloqueado}
                />
            </MakoActionsButtonsColumn>
        );
    };

    return <ListagemMovimentacoes listagemRef={listagemRef} caixaMovimento={caixaMov} actions={actions} />;
};
