import React, { memo, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoUploadPreviewImage } from "@/components/MakoUploadPreviewImage";
import { Label } from "@/components/Label";
import { dataToStr } from "@/assets/util/datas";
import { validarCPF } from "@/assets/util/validacoes";
import {
    PESSOAS_PERFILPF_RG_OPCIONAL,
    PESSOAS_PERFIL_CADASTRO_PAIS_OBRIGATORIEDADE,
    PESSOAS_PERFIL_CADASTRO_PESSOASFISICA_OBRIGATORIEDADE,
} from "@/assets/constants/parametros";
import { SEXO, ESTADO_CIVIL } from "@/assets/constants/constants";
import { url } from "@/services/axios";
import usePessoa from "@/hooks/usePessoa";
import useFormatCnpjCpf from "@/hooks/useFomatCNPJCPF";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import useHttp from "@/hooks/useHttp";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const IdentificacaoPFForm = () => {
    const [loading, setLoading] = useState(false);
    const [cpfDuplicado, setCpfDuplicado] = useState(false);
    const { handlePessoaFisica, handleDeletaImagem, handleFotoPerfil, pessoa, setSubmit } = usePessoa();
    const { getParam } = useParam();
    const [, limparDocumento] = useFormatCnpjCpf();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpGet } = useHttp();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            nome: "",
            sobrenome: "",
            nome_social: "",
            nome_curto: "",
            sobrenome_social: "",
            usa_nome_social: false,
            cpf: "",
            rg: "",
            rg_orgao_emissor: null,
            rg_data_emissao: null,
            rg_estado_emissor: "",
            estado_civil: "",
            sexo: "",
            data_nascimento: null,
            nome_pai: "",
            nome_mae: "",
            obs: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório.").typeError("Informe um 'nome' válido."),
                nome_curto: Yup.string()
                    .when({
                        is: () => camposBasicosObrigatorios,
                        then: Yup.string()
                            .required("O campo 'nome curto' é obrigatório.")
                            .typeError("Informe um 'nome curto' válido."),
                        otherwise: Yup.string().nullable().typeError("Informe um 'nome curto' válido."),
                    })
                    .default("")
                    .max(25, "Informe no máximo 25 caracteres."),
                sobrenome: Yup.string().required("O campo 'sobrenome' é obrigatório."),
                cpf: Yup.string().when({
                    is: () => camposBasicosObrigatorios,
                    then: Yup.string()
                        .required("O campo 'cpf' é obrigatório.")
                        .test("CPF validation", "O 'cpf' é inválido.", (value) => validarCPF(value) === true),
                    otherwise: Yup.string().nullable().typeError("Informe um 'cpf' válido."),
                }),
                rg: Yup.string().when({
                    is: () => rgObrigatorio,
                    then: Yup.string().required("O campo 'rg' é obrigatório.").typeError("Informe um 'rg' válido."),
                    otherwise: Yup.string().nullable().typeError("Informe um 'rg' válido."),
                }),
                nome_mae: Yup.string().when({
                    is: () => paisObrigatorios,
                    then: Yup.string()
                        .required("O campo 'nome da máe' é obrigatório.")
                        .typeError("Informe um 'nome da máe' válido."),
                    otherwise: Yup.string().nullable().typeError("Informe um 'nome da máe' válido."),
                }),
                nome_pai: Yup.string().when({
                    is: () => paisObrigatorios,
                    then: Yup.string()
                        .required("O campo 'nome da pai' é obrigatório.")
                        .typeError("Informe um 'nome da pai' válido."),
                    otherwise: Yup.string().nullable().typeError("Informe um 'nome da pai' válido."),
                }),
                estado_civil: Yup.string().required("O campo 'estado civil' é obrigatório."),
                sexo: Yup.string().required("O campo 'sexo' é obrigatório."),
                rg_data_emissao: Yup.date()
                    .when("data_nascimento", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                Yup.ref("data_nascimento") || new Date(),
                                "A data de emissão do RG não pode ser inferior a data de nascimento."
                            )
                            .max(new Date(), "A data de emissão do RG não pode ser posterior ao dia de hoje.")
                            .nullable()
                            .typeError("Informe uma data válida."),
                        otherwise: Yup.date().nullable(),
                    })
                    .nullable(),
                nome_social: Yup.string()
                    .when("usa_nome_social", {
                        is: (val) => !!val,
                        then: Yup.string()
                            .max(40, "O nome social deve ter no máximo 40 caracteres.")
                            .required("O campo social deve ser preenchido (usa nome social marcado).")
                            .typeError("Informe um nome válido."),
                        otherwise: Yup.string().nullable(),
                    })
                    .nullable(),
                sobrenome_social: Yup.string()
                    .when("usa_nome_social", {
                        is: (val) => !!val,
                        then: Yup.string()
                            .max(40, "O sobrenome social deve ter no máximo 30 caracteres.")
                            .required("O campo social deve ser preenchido (usa nome social marcado).")
                            .typeError("Informe um sobrenome válido."),
                        otherwise: Yup.string().nullable(),
                    })
                    .nullable(),
                data_nascimento: Yup.date().when({
                    is: () => camposBasicosObrigatorios,
                    then: Yup.date()
                        .max(new Date(), "A data de nascimento não pode ser posterior ao dia de hoje.")
                        .typeError("Informe uma 'data' válida."),
                    otherwise: Yup.date()
                        .max(new Date(), "A data de nascimento não pode ser posterior ao dia de hoje.")
                        .nullable()
                        .typeError("Informe uma 'data' válida"),
                }),
            });
            const formValidado = await formSchema.validate(values, {
                abortEarly: false,
            });
            const perfil_pf = {
                ...formValidado,
                cpf: limparDocumento(values.cpf),
                data_nascimento: dataToStr(values.data_nascimento, "yyyy-MM-dd"),
                rg_data_emissao: dataToStr(values.rg_data_emissao, "yyyy-MM-dd"),
                rg_estado_emissor: values.rg_estado_emissor ? values.rg_estado_emissor : null,
            };
            handlePessoaFisica(perfil_pf);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    function resetForm() {
        formik.resetForm();
        setSubmit(false);
    }

    const verificarCpfExiste = async (value) => {
        const cpf = limparDocumento(value);
        const handlers = {
            200: ({ data }) => {
                if (data.results.length > 0) {
                    if (!data.results[0].ativo) {
                        showWarning({
                            summary: "Aviso!",
                            detail: "Esse CPF já se encontra cadastrado na base de dados, porém está desativado.",
                            life: 5000,
                        });
                    } else {
                        showWarning({
                            summary: "Aviso!",
                            detail: "Esse CPF já se encontra cadastrado na base de dados.",
                            life: 5000,
                        });
                    }
                    setCpfDuplicado(true);
                    setSubmit(false);
                } else setCpfDuplicado(false);
            },
        };
        setLoading(true);
        await httpGet({ url: `/pessoas/perfis/?query={nome,identificacao,ativo}&identificacao=${cpf}` }, handlers);
        setLoading(false);
    };

    const onUploadFotoPerfil = (e) => {
        const { xhr } = e;
        const { data } = JSON.parse(xhr.response);
        handleFotoPerfil(data.foto);
        showSuccess({
            summary: "Sucesso",
            detail: "Foto de perfil enviada com sucesso!",
            life: 1500,
        });
    };

    const onErrorFotoPerfil = (e) => {
        const { xhr } = e;
        if (xhr.status === 400) {
            const { msg } = JSON.parse(xhr.response);
            showWarning({
                summary: "Falha",
                detail: msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível enviar a foto de perfil.",
                life: 3000,
            });
        }
    };

    const rgObrigatorio = useMemo(() => {
        const param = getParam(PESSOAS_PERFILPF_RG_OPCIONAL);
        return !!param?.valor === "1";
    }, [getParam]);

    const paisObrigatorios = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_PAIS_OBRIGATORIEDADE);
        return param?.valor === "1";
    }, [getParam]);

    const camposBasicosObrigatorios = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_PESSOASFISICA_OBRIGATORIEDADE);
        if (!param) return true;
        return param?.valor === "1";
    }, [getParam]);

    useEffect(() => {
        if (pessoa.perfil_pf) setValues(pessoa.perfil_pf);
    }, [pessoa.perfil_pf, setValues]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-grid">
                <div className="p-col-12 p-md-2">
                    <div className="p-fluid p-formgrid p-grid p-jc-center">
                        <MakoUploadPreviewImage
                            ocultarImage={!!!pessoa?.foto}
                            exibirDeletar={pessoa?.foto}
                            imageConfig={{
                                src: pessoa.foto,
                                alt: "Foto de perfil",
                                width: "140rem",
                                preview: true,
                            }}
                            uploadConfig={{
                                url: `${url()}/pessoas/perfis-upload-foto/${pessoa?.id}/`,
                                name: "foto",
                                disabled: !!!pessoa?.id,
                                chooseLabel: "Selecionar foto",
                                maxFileSize: 1000000,
                                auto: true,
                                invalidFileSizeMessageSummary: "{0}: Tamanho do arquivo inválido, ",
                                invalidFileSizeMessageDetail: "tamanho máximo permitido: {0}.",
                                onUpload: onUploadFotoPerfil,
                                onError: onErrorFotoPerfil,
                            }}
                            deleteConfig={{
                                onClick: () => handleDeletaImagem(),
                            }}
                        />
                        {!!pessoa?.id && !pessoa?.foto ? (
                            <small className="p-error" style={{ color: "#FF0000", alignItems: "center" }}>
                                * Tamanho da imagem permitido: <b>1MB</b> (Dimensões <b>recomendáveis</b>: 180x420). *
                            </small>
                        ) : null}
                        {!!!pessoa?.id && (
                            <small className="p-error">
                                ** Poderá selecionar uma foto para o perfil após finalizar o cadastro. **
                            </small>
                        )}
                    </div>
                </div>
                <div className="p-col-12 p-md-10">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="cpf" label="CPF" obrigatorio={camposBasicosObrigatorios} />
                            <span className="p-input-icon-right">
                                {loading && <i className="pi pi-spin pi-spinner" />}
                                <InputMask
                                    id="cpf"
                                    name="cpf"
                                    mask="999.999.999-99"
                                    autoClear={false}
                                    onComplete={(e) => verificarCpfExiste(e.value)}
                                    value={formik.values.cpf}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cpf })}
                                />
                                {formik.errors.cpf && <small className="p-error">{formik.errors.cpf}</small>}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="nome" label="Nome" obrigatorio={true} />
                            <InputText
                                id="nome"
                                name="nome"
                                value={formik.values.nome}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.nome })}
                            />
                            {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <Label htmlFor="sobrenome" label="Sobrenome" obrigatorio />
                            <InputText
                                id="sobrenome"
                                name="sobrenome"
                                value={formik.values.sobrenome}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.sobrenome,
                                })}
                            />
                            {formik.errors.sobrenome && <small className="p-error">{formik.errors.sobrenome}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="nome_curto" label="Nome curto" obrigatorio={camposBasicosObrigatorios} />
                            <InputText
                                id="nome_curto"
                                name="nome_curto"
                                value={formik.values.nome_curto}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.nome_curto,
                                })}
                            />
                            {formik.errors.nome_curto && <small className="p-error">{formik.errors.nome_curto}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="rg" label="RG" obrigatorio={rgObrigatorio} />
                            <InputText
                                id="rg"
                                name="rg"
                                value={formik.values.rg}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.rg })}
                            />
                            {formik.errors.rg && <small className="p-error">{formik.errors.rg}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="orgao-emissor" label="Orgão Emissor" />
                            <InputText
                                id="orgao-emissor"
                                name="rg_orgao_emissor"
                                value={formik.values.rg_orgao_emissor}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="estado-emissor" label="Estado Emissor" />
                            <Dropdown
                                id="estado-emissor"
                                name="rg_estado_emissor"
                                url="/pessoas/estados?query={id,nome,uf,codigo_uf}&pais__sigla=BR&limit=100"
                                optionLabel="uf"
                                optionValue="id"
                                placeholder="Selecione..."
                                filter
                                showClear
                                filterBy="uf"
                                value={formik.values.rg_estado_emissor}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="data-emissao" label="Data de Emissão" />
                            <MakoCalendar
                                id="data-emissao"
                                name="rg_data_emissao"
                                maxDate={new Date()}
                                valueCalendar={formik.values.rg_data_emissao}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.rg_data_emissao,
                                })}
                            />
                            {formik.errors.rg_data_emissao && (
                                <small className="p-error">{formik.errors.rg_data_emissao}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2 p-d-flex p-flex-column">
                            <div className="p-field-checkbox p-mt-auto">
                                <Checkbox
                                    inputId="usa_nome_social"
                                    name="usa_nome_social"
                                    checked={formik.values.usa_nome_social}
                                    onChange={formik.handleChange}
                                />
                                <Label htmlFor="usa_nome_social" label="Usa nome social?" />
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <Label htmlFor="nome_social" label="Nome social" />
                            <InputText
                                id="nome_social"
                                name="nome_social"
                                disabled={!formik.values.usa_nome_social}
                                value={formik.values.nome_social}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.nome_social && (
                                <small className="p-error">{formik.errors.nome_social}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <Label htmlFor="sobrenome_social" label="Sobrenome social" />
                            <InputText
                                id="sobrenome_social"
                                name="sobrenome_social"
                                disabled={!formik.values.usa_nome_social}
                                value={formik.values.sobrenome_social}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.sobrenome_social && (
                                <small className="p-error">{formik.errors.sobrenome_social}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="estado-civil" label="Estado Civil" obrigatorio />
                            <Dropdown
                                id="estado-civil"
                                name="estado_civil"
                                options={ESTADO_CIVIL}
                                placeholder="Selecione..."
                                optionValue="id"
                                value={formik.values.estado_civil}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.estado_civil,
                                })}
                            />
                            {formik.errors.estado_civil && (
                                <small className="p-error">{formik.errors.estado_civil}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="sexo" label="Sexo" obrigatorio />
                            <Dropdown
                                id="sexo"
                                name="sexo"
                                options={SEXO}
                                placeholder="Selecione..."
                                optionValue="id"
                                value={formik.values.sexo}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.sexo })}
                            />
                            {formik.errors.sexo && <small className="p-error">{formik.errors.sexo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <Label
                                htmlFor="data-nascimento"
                                label="Data de Nascimento"
                                obrigatorio={camposBasicosObrigatorios}
                            />
                            <MakoCalendar
                                id="data-nascimento"
                                name="data_nascimento"
                                maxDate={new Date()}
                                valueCalendar={formik.values.data_nascimento}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.data_nascimento,
                                })}
                            />
                            {formik.errors.data_nascimento && (
                                <small className="p-error">{formik.errors.data_nascimento}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <Label htmlFor="nome_pai" label="Nome do pai" obrigatorio={paisObrigatorios} />
                            <InputText
                                id="nome_pai"
                                name="nome_pai"
                                value={formik.values.nome_pai}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.nome_pai,
                                })}
                            />
                            {formik.errors.nome_pai && <small className="p-error">{formik.errors.nome_pai}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <Label htmlFor="nome_mae" label="Nome da mãe" obrigatorio={paisObrigatorios} />
                            <InputText
                                id="nome_mae"
                                name="nome_mae"
                                value={formik.values.nome_mae}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.nome_mae,
                                })}
                            />
                            {formik.errors.nome_mae && <small className="p-error">{formik.errors.nome_mae}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <Label htmlFor="obs" label="Observação" />
                            <InputText id="obs" name="obs" value={formik.values.obs} onChange={formik.handleChange} />
                        </div>
                    </div>
                </div>
            </div>
            <CamposObrigatorios />
            <p className="p-error">* Lembre-se de gravar os dados antes de prosseguir ou finalizar</p>
            <MakoActionsButtons>
                <Button
                    type="submit"
                    icon={`pi ${!cpfDuplicado ? MAKO_ICONS.GRAVAR : "pi-times"}`}
                    label={!cpfDuplicado ? "Gravar" : "CPF já cadastrado"}
                    className={`${cpfDuplicado ? "p-button-danger" : ""}`}
                    disabled={cpfDuplicado}
                />
                <Button
                    type="reset"
                    icon={MAKO_ICONS.LIMPAR_FORM}
                    label="Limpar"
                    className="p-button-warning"
                    onClick={() => resetForm()}
                />
            </MakoActionsButtons>
        </form>
    );
};

export default memo(IdentificacaoPFForm);
