import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import { TIPO_MOVIMENTACAO_ESTOQUE_CHOICE, CLASSE_MOVIMENTACAO_ESTOQUE } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export const TipoMovimentacaoEstoqueForm = () => {
    const toastRef = useRef(null);
    const history = useHistory();
    const { httpPost, httpPut } = useHttp();
    const { showWarning, showError, showSuccess } = useToast();
    const { showLoading, hideLoading } = useLoading();
    const { state } = useLocation();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            codigo: "",
            nome: "",
            tipo: "",
            classe_movimentacao: null,
            content_type: null,
            altera_custo: false,
            padrao_entrada: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string()
                    .min(1, "O campo precisa de no mínimo 1 caracter.")
                    .max(3, "O campo pode receber no máximo 3 caracteres.")
                    .required("O campo 'código' é obrigatório!"),
                nome: Yup.string().required("O campo 'nome' é obrigatório!"),
                tipo: Yup.string().required("Selecione um tipo de movimentação de estoque."),
                classe_movimentacao: Yup.number()
                    .required("Selecione uma classe de movimentação.")
                    .typeError("Informe uma 'classe' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Novo tipo de movimentação de estoque cadastrado!",
                            life: 3000,
                        });
                        formik.resetForm();
                    },
                    400: ({ err }) =>
                        showWarning({
                            summary: "Falha",
                            detail: err.codigo,
                            life: 6000,
                        }),
                };

                showLoading();
                await httpPost({ url: "/produtos/tipos-movimentacoes-estoques/", body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de movimentação de estoque alterado!",
                            life: 2000,
                        });
                        history.push("/estoque/cadastros/tipos-movimentacoes");
                    },
                };

                showLoading();
                await httpPut({ url: `/produtos/tipos-movimentacoes-estoques/${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    useEffect(() => {
        if (state) setValues(state);
    }, [state, setValues]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>
                        {!formik.values.id
                            ? "Novo tipo de movimentação de estoque"
                            : "Manutenção de tipo de movimentação de estoque"}
                    </h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="codigo">Código *</label>
                                <InputText
                                    id="codigo"
                                    name="codigo"
                                    value={formik.values.codigo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.codigo })}
                                />
                                {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-10">
                                <label htmlFor="nome">Nome *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="tipo">Natureza *</label>
                                <Dropdown
                                    id="tipo"
                                    name="tipo"
                                    options={TIPO_MOVIMENTACAO_ESTOQUE_CHOICE}
                                    optionValue="id"
                                    optionLabel="label"
                                    placeholder="Selecione"
                                    value={formik.values.tipo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo })}
                                />
                                {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                            </div>

                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="content-type">Tabela</label>
                                <Dropdown
                                    id="content-type"
                                    name="content_type"
                                    url="/custom-fields/content-types/?limit=10000"
                                    optionValue="id"
                                    optionLabel="model"
                                    filter
                                    showClear
                                    placeholder="Selecione"
                                    value={formik.values.content_type}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="classe-movimentacao">Classe de movimentação *</label>
                                <Dropdown
                                    id="classe-movimentacao"
                                    name="classe_movimentacao"
                                    options={CLASSE_MOVIMENTACAO_ESTOQUE}
                                    placeholder="Selecione"
                                    value={formik.values.classe_movimentacao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.classe_movimentacao })}
                                />
                                {formik.errors.classe_movimentacao && (
                                    <small className="p-error">{formik.errors.classe_movimentacao}</small>
                                )}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-auto">
                                <Checkbox
                                    inputId="altera-custo"
                                    name="altera_custo"
                                    checked={formik.values.altera_custo}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="altera-custo">Altera o custo?</label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-auto">
                                <Checkbox
                                    inputId="padrao-entrada"
                                    name="padrao_entrada"
                                    checked={formik.values.padrao_entrada}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="altera-custo">Padrão Entrada?</label>
                            </div>
                        </div>
                        <p>* Campos obrigatórios.</p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" icon="pi pi-save" className="p-button-info p-mr-2" />
                            <Button
                                label="Voltar"
                                type="button"
                                icon="pi pi-angle-double-left"
                                className="p-button-danger p-mr-2"
                                onClick={() => history.push("/estoque/cadastros/tipos-movimentacoes")}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
