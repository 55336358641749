import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ModalEditarEnderecoEntrega } from "./ModalEditarEnderecoEntrega";
import { ModalEnderecos } from "@/components/ModalEnderecos";
import { MakoListagem } from "@/components/MakoListagem";
import { Dropdown } from "@/components/Dropdown";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoTime } from "@/components/MakoTime";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import { BotaoDelete } from "@/components/BotaoDelete";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { TIPOS_ENTREGA_PRODUTO } from "@/assets/constants/vendas";
import { parseNumber } from "@/assets/helpers/number";
import { dataToStr } from "@/assets/util/datas";
import { formatarCasasDecimais } from "@/assets/util/util";
import useVenda from "@/hooks/useVenda";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

export const PrevisaoEntregaForm = () => {
    const [itensVenda, setItensVenda] = useState([]);
    const [bloqueiaCentroEstocagem, setBloqueiaCentroEstocagem] = useState(false);
    const [permiteReservar, setPermiteReservar] = useState(false);
    const [tipoCEDistribuicao, setTipoCEDistribuicao] = useState(["C", "D"]);
    const listagemRef = useRef(null);
    const dropEnderecosRef = useRef(null);
    const modalEnderecosRef = useRef(null);
    const modalRef = useRef(null);
    const dropCentroEstocagemRef = useRef(null);
    const dropCentroDistribuicaoRef = useRef(null);
    const { dadosBasicos, vendaFinalizada, bloqueiaItens } = useVenda();
    const { showWarning, showError, showSuccess } = useToast();
    const { httpGet, httpPost } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [loadingItens, showLoadingItens, hideLoadingItens] = useLoadingLocal();

    const bloqueiaPrevisaoEntrega = vendaFinalizada || bloqueiaItens;

    const formik = useFormik({
        initialValues: {
            tipo_entrega: null,
            item_venda: [],
            retirado_em: null,
            centro_estocagem: null,
            centro_distribuicao: null,
            rota_entrega: null,
            quantidade: 0,
            quantidade_restante: 0,
            data_previsao: new Date(),
            hora_previsao: null,
            endereco_entrega: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_entrega: Yup.string()
                    .required("O campo 'entrega / retirada' é obrigatório.")
                    .typeError("Selecione será será uma entrega ou retirada."),
                item_venda: Yup.array().min(1, "Você precisa selecionar pelo menos um produto."),
                centro_estocagem: Yup.string()
                    .required("O campo 'centro de estocagem' é obrigatório.")
                    .typeError("Selecione um centro de estocagem."),
                quantidade:
                    values.item_venda.length === 1
                        ? Yup.number().when("item_venda", {
                              is: (val) => val && val.movimenta_cubagem,
                              then: Yup.number()
                                  .min(0.000001, "O campo 'quantidade' não pode ser ZERO.")
                                  .test({
                                      test: (val) => val <= values.quantidade_restante,
                                      message: "A quatidade informada excede a quantidade restante.",
                                  }),
                              otherwise: Yup.number()
                                  .min(1, "O campo 'quantidade' não pode ser ZERO.")
                                  .test({
                                      test: (val) => val <= values.quantidade_restante,
                                      message: "A quatidade informada excede a quantidade restante.",
                                  }),
                          })
                        : Yup.number()
                              .default(0)
                              .test({
                                  test: (val) => val <= values.quantidade_restante,
                                  message: "A quatidade informada excede a quantidade restante.",
                              }),
                data_previsao: Yup.date()
                    .nullable()
                    .when("tipo_entrega", {
                        is: (val) => val === "E",
                        then: Yup.date()
                            .typeError("Informe uma data válida.")
                            .required("O campo 'data da entrega' é obrigatório."),
                    }),
                endereco_entrega: Yup.number()
                    .nullable()
                    .when("tipo_entrega", {
                        is: (val) => val === "E",
                        then: Yup.number()
                            .required("O campo 'endereço' é obrigatório.")
                            .typeError("Selecione o endereço de entrega."),
                    }),
                retirado_em: Yup.date()
                    .nullable()
                    .when("tipo_entrega", {
                        is: (val) => val === "R",
                        then: Yup.date()
                            .typeError("Informe uma data válida.")
                            .required("O campo 'data da retirada' é obrigatório."),
                    }),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            let entregas = [];
            if (values.item_venda.length > 1) {
                const eEntrega = values.tipo_entrega === "E";
                entregas = values.item_venda.map((item) => ({
                    ...values,
                    retirar: !eEntrega,
                    quantidade: formatarCasasDecimais(parseNumber(item.quantidade) - item.quantidade_entregue, 6),
                    item_venda: item.id,
                    sku: item.sku.id,
                    data_previsao: eEntrega ? dataToStr(values.data_previsao, "yyyy-MM-dd") : null,
                    hora_previsao: eEntrega ? dataToStr(values.hora_previsao, "HH:mm:ss") : null,
                    retirado_em: !eEntrega ? dataToStr(values.retirado_em, "yyyy-MM-dd HH:mm:ss") : null,
                }));
            } else {
                const eEntrega = values.tipo_entrega === "E";
                entregas.push({
                    ...values,
                    retirar: !eEntrega,
                    item_venda: values.item_venda[0].id,
                    sku: values.item_venda[0].sku.id,
                    data_previsao: eEntrega ? dataToStr(values.data_previsao, "yyyy-MM-dd") : null,
                    hora_previsao: eEntrega ? dataToStr(values.hora_previsao, "HH:mm:ss") : null,
                    retirado_em: !eEntrega ? dataToStr(values.retirado_em, "yyyy-MM-dd HH:mm:ss") : null,
                });
            }
            const handlers = {
                201: () => {
                    resetForm();
                    listagemRef.current?.buscarDados();
                    const _itens = itensVenda.map((item) => {
                        const entrega = entregas.find((el) => el.item_venda === item.id);
                        if (entrega) {
                            return {
                                ...item,
                                quantidade_entregue: item.quantidade_entregue + entrega.quantidade,
                            };
                        }
                        return item;
                    });
                    setItensVenda(_itens);
                },
            };
            showLoading();
            await httpPost({ url: `/vendas/previsoes-entregas-itens-vendas/`, body: entregas }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    function resetForm() {
        formik.resetForm();
        setPermiteReservar(false);
    }

    const validarSaldoSKU = async () => {
        let dataSaldo = null;
        if (formik.values.tipo_entrega === "E") dataSaldo = formik.values.data_previsao;
        if (formik.values.tipo_entrega === "R") dataSaldo = formik.values.retirado_em;
        if (!dataSaldo) {
            showWarning({
                summary: "Aviso!",
                detail: "Informe a data que será entregue / retirado.",
                life: 2000,
            });
            return;
        }
        const qtdItensVendaSelecionados = formik.values.item_venda.length;
        if (qtdItensVendaSelecionados === 1 && !formik.values.quantidade) {
            showWarning({
                summary: "Aviso!",
                detail: "Informe a quantidade que será entregue.",
                life: 2000,
            });
            return;
        }
        const cb = (e) => e.id === formik.values.centro_estocagem;
        let centroEstocagem = dropCentroEstocagemRef.current?.getCentroEstocagem(cb);
        if (formik.values.centro_distribuicao) {
            const cb = (e) => e.id === formik.values.centro_distribuicao;
            const centroDistribuicao = dropCentroDistribuicaoRef.current?.getCentroEstocagem(cb);
            centroEstocagem = centroDistribuicao;
        }
        if (!centroEstocagem.formula_venda) {
            showWarning({
                summary: "Aviso!",
                detail: `O centro de estocagem "${centroEstocagem.nome}" não possui fórmula de venda configurada. Verifique!`,
                life: 3000,
            });
            return;
        }
        const params = {
            formula: centroEstocagem.formula_venda,
            data: dataToStr(dataSaldo, "yyyy-MM-dd"),
        };
        const validacoes = [];
        for (const formItemVenda of formik.values.item_venda) {
            const itemVenda = itensVenda.find((iv) => iv.id === formItemVenda.id);
            const url = `/produtos/visualizar-sku-saldo-formula/${itemVenda.sku.id}/`;
            let interromper = false;
            const handlers = {
                200: ({ data }) => {
                    const quantidade =
                        qtdItensVendaSelecionados === 1 ? formik.values.quantidade : parseNumber(itemVenda.quantidade);
                    if (data.saldo < quantidade) {
                        showWarning({
                            summary: "Aviso!",
                            detail: `O produto "${itemVenda.descricao_derivada}" está com saldo insuficiente, faça uma previsão de entrega separada e informe o campo "centro de distribuição".`,
                            life: 5000,
                        });
                        validacoes.push(false);
                        interromper = true;
                    } else {
                        validacoes.push(true);
                    }
                },
                error: ({ err }) => {
                    showError({
                        summary: "Erro :(",
                        detail: err?.msg || "Não foi possível verificar o saldo do produto.",
                        life: 3000,
                    });
                    validacoes.push(false);
                },
            };
            await httpGet({ url, params }, handlers);
            if (interromper) break;
        }
        if (validacoes.every((v) => v))
            showSuccess({
                summary: "Válido!",
                detail: `Saldo disponível.`,
                life: 2500,
            });
        setPermiteReservar(validacoes.every((v) => v));
    };

    useEffect(() => {}, [formik.values.centro_estocagem]);

    const listarItensVenda = useCallback(async () => {
        const params = {
            venda: dadosBasicos?.id,
            query: "{id,descricao_derivada,sku,quantidade,quantidade_entregue,centro_estocagem}",
        };
        const handlers = {
            200: ({ data }) => setItensVenda(data.results),
        };
        showLoadingItens();
        await httpGet({ url: "/vendas/itens-vendas/", params }, handlers);
        hideLoadingItens();
    }, [dadosBasicos, httpGet, showLoadingItens, hideLoadingItens]);

    useEffect(() => {
        listarItensVenda();
    }, [listarItensVenda]);

    const enderecoTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{`${option.identificacao} - ${option.logradouro}, ${option.numero} - ${option.bairro} - ${option.cep}`}</div>
            </div>
        );
    };

    const enderecoSelecionadoTemplate = (option, props) => {
        if (option) return enderecoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const dataEntregaBodyTemplate = (rowData) => {
        const data = rowData.retirar ? rowData.retirado_em : rowData.data_previsao;
        return <span>{dataToStr(data, "dd/MM/yyyy")}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-map-marker"
                    disabled={bloqueiaPrevisaoEntrega}
                    tooltip="Alterar endereço de entrega"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => modalRef.current?.abrirModal(rowData)}
                    className="p-button-rounded p-button-help p-mr-2"
                />
                <BotaoDelete
                    url="/vendas/previsoes-entregas-itens-vendas/"
                    objetoId={rowData.id}
                    disabled={bloqueiaPrevisaoEntrega}
                    classNames="p-mr-2 p-mt-2"
                    msgToastErroExclusao="Desculpe, não foi possível excluir a previsão de entrega."
                    onDelete={() => {
                        const _itens = itensVenda.map((item) => {
                            if (item.id === rowData.item_venda) {
                                return {
                                    ...item,
                                    quantidade_entregue: item.quantidade_entregue - parseNumber(rowData.quantidade),
                                };
                            }
                            return item;
                        });
                        setItensVenda(_itens);
                        listagemRef.current?.buscarDados();
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "sku.codigo", header: "Código", style: { maxWidth: "10%" } },
        { field: "sku.descricao_derivada", header: "Produto / Mercadoria" },
        { field: "centro_estocagem.nome", header: "Estoque" },
        { field: "quantidade", header: "Qtd.", decimal: true, style: { maxWidth: "8%" } },
        {
            field: "data_previsao",
            header: "Data",
            style: { maxWidth: "8%" },
            action: (e) => dataEntregaBodyTemplate(e),
        },
        {
            field: "hora_previsao",
            header: "Hora",
            style: { maxWidth: "8%" },
        },
        { field: "endereco_entrega.logradouro", header: "Endereço de entrega" },
        {
            field: "action",
            header: "Ações",
            className: "p-text-center",
            style: { maxWidth: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const handleChangeItemVenda = ({ value }) => {
        if (value instanceof Array) {
            const ceItensVenda = value.map((v) => v.centro_estocagem).filter(Boolean);
            const setCeItensVenda = [...new Set(ceItensVenda)];
            if (setCeItensVenda.length > 1) {
                showWarning({
                    summary: "Aviso!",
                    detail: "Essa combinação de itens não é possível, devido aos centros de estocagens.",
                    life: 4000,
                });
            } else {
                formik.setFieldValue("item_venda", value);
                if (setCeItensVenda.length === 1) {
                    setBloqueiaCentroEstocagem(true);
                    formik.setFieldValue("centro_estocagem", ceItensVenda[0]);
                } else {
                    setBloqueiaCentroEstocagem(false);
                    formik.setFieldValue("centro_estocagem", null);
                }
                if (value.length === 1) {
                    const [itemVenda] = value;
                    const qtdRestante = parseNumber(itemVenda.quantidade) - itemVenda.quantidade_entregue;
                    formik.setFieldValue("quantidade_restante", qtdRestante);
                }
            }
        } else {
            formik.setFieldValue("item_venda", []);
            setBloqueiaCentroEstocagem(false);
            formik.setFieldValue("centro_estocagem", null);
        }
    };

    const itemEntregaTemplate = (option) => {
        const { sku } = option;
        return <span>{`${sku.codigo} - ${sku.descricao_derivada}`}</span>;
    };

    const itemEntregaSelecionadoTemplate = (option) => {
        if (option) return itemEntregaTemplate(option);
        return null;
    };

    const filtrarEstoqueOutrasEmpresas = useCallback(
        (estoques) => {
            return estoques.filter((est) => est.empresa.id !== dadosBasicos?.empresa.id);
        },
        [dadosBasicos]
    );

    return (
        <>
            {bloqueiaPrevisaoEntrega && (
                <h5 className="p-error p-mt-0">O estágio atual não permite alterar a previsão de entrega</h5>
            )}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="item-venda">Produto / mercadoria *</label>
                        <MultiSelect
                            id="item-venda"
                            name="item_venda"
                            placeholder="Selecione"
                            emptyMessage="Nenhum produto listado."
                            disabled={loadingItens || bloqueiaPrevisaoEntrega}
                            options={itensVenda}
                            optionDisabled={(option) =>
                                parseNumber(option.quantidade_entregue) >= parseNumber(option.quantidade)
                            }
                            optionLabel="descricao_derivada"
                            selectedItemsLabel="{0} produtos selecionados"
                            itemTemplate={itemEntregaTemplate}
                            selectedItemTemplate={itemEntregaSelecionadoTemplate}
                            maxSelectedLabels={2}
                            filter
                            filterBy="descricao_derivada"
                            showClear
                            value={formik.values.item_venda}
                            onChange={handleChangeItemVenda}
                            className={classNames({ "p-invalid": formik.errors.item_venda })}
                        />
                        {formik.errors.item_venda && <small className="p-error">{formik.errors.item_venda}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade">Quantidade **</label>
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            disabled={formik.values.item_venda.length > 1}
                            permiteFracionario={formik.values.item_venda[0]?.sku.item.movimenta_cubagem}
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade-restante">Quantidade restante</label>
                        <MakoInputQuantidadeSku
                            id="quantidade-restante"
                            name="quantidade_restante"
                            disabled
                            permiteFracionario={formik.values.item_venda[0]?.sku.item.movimenta_cubagem}
                            value={formik.values.quantidade_restante}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="tipo-entrega">Entrega / Retirada *</label>
                        <Dropdown
                            id="tipo-entrega"
                            name="tipo_entrega"
                            options={TIPOS_ENTREGA_PRODUTO}
                            value={formik.values.tipo_entrega}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_entrega })}
                        />
                        {formik.errors.tipo_entrega && <small className="p-error">{formik.errors.tipo_entrega}</small>}
                    </div>
                    {formik.values.tipo_entrega === "E" && (
                        <>
                            {/* <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="rota-entrega">Rota de entrega</label>
                            <Dropdown
                                id="rota-entrega"
                                name="rota_entrega"
                                url="/vendas/rotas-entrega/"
                                // setObjects={setRotasEntregas}
                                optionValue="id"
                                optionLabel="descricao"
                                value={formik.values.rota_entrega}
                                onChange={formik.handleChange}
                            />
                        </div> */}
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="endereco">Endereço de entrega *</label>
                                <div className="p-inputgroup">
                                    <Dropdown
                                        ref={dropEnderecosRef}
                                        id="endereco"
                                        name="endereco_entrega"
                                        buscar={!!dadosBasicos}
                                        ur={`/pessoas/enderecos-perfis/?perfil__id=${dadosBasicos?.cliente.id}ativo=true&query={id,identificacao,logradouro,numero,bairro,cep}`}
                                        optionValue="id"
                                        optionLabel="logradouro"
                                        valueTemplate={enderecoSelecionadoTemplate}
                                        itemTemplate={enderecoTemplate}
                                        value={formik.values.endereco_entrega}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.endereco_entrega })}
                                    />
                                    <Button
                                        type="button"
                                        icon="pi pi-pencil"
                                        className="p-button-warning"
                                        tooltip="Gerenciar endereços"
                                        tooltipOptions={{ position: "left" }}
                                        onClick={() => modalEnderecosRef.current?.abrirModal(dadosBasicos?.cliente.id)}
                                    />
                                </div>
                                {formik.errors.endereco_entrega && (
                                    <small className="p-error">{formik.errors.endereco_entrega}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="data-entrega">Data da entrega *</label>
                                <MakoCalendar
                                    id="data-entrega"
                                    name="data_previsao"
                                    minDate={dadosBasicos ? dadosBasicos.data : null}
                                    valueCalendar={formik.values.data_previsao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.data_previsao })}
                                />
                                {formik.errors.data_previsao && (
                                    <small className="p-error">{formik.errors.data_previsao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="hora-entrega">Hora da entrega</label>
                                <MakoTime
                                    id="hora-entrega"
                                    name="hora_previsao"
                                    valueTime={formik.values.hora_previsao}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </>
                    )}
                    {formik.values.tipo_entrega === "R" && (
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="retirado-em">Data da retirada *</label>
                            <MakoCalendar
                                id="retirado-em"
                                name="retirado_em"
                                minDate={dadosBasicos ? dadosBasicos.data : null}
                                valueCalendar={formik.values.retirado_em}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.retirado_em })}
                            />
                            {formik.errors.retirado_em && (
                                <small className="p-error">{formik.errors.retirado_em}</small>
                            )}
                        </div>
                    )}
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="estoque">Centro de estocagem *</label>
                        <MakoDropdownEstoques
                            ref={dropCentroEstocagemRef}
                            id="estoque"
                            name="centro_estocagem"
                            disabled={bloqueiaCentroEstocagem}
                            optionValue="id"
                            empresaId={dadosBasicos?.empresa.id}
                            tipoCentroEstocagem={["C", "D"]}
                            tipoSKU="M"
                            value={formik.values.centro_estocagem}
                            onChange={(e) => {
                                formik.handleChange(e);
                                if (e.value) {
                                    const cb = (ce) => ce.id === e.value;
                                    const centroEstocagem = dropCentroEstocagemRef.current?.getCentroEstocagem(cb);
                                    setTipoCEDistribuicao(centroEstocagem.tipo);
                                }
                            }}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="centro-distribuicao">Centro de distribuição</label>
                        <MakoDropdownEstoques
                            ref={dropCentroDistribuicaoRef}
                            id="centro-distribuicao"
                            name="centro_distribuicao"
                            optionValue="id"
                            aposPesquisar={filtrarEstoqueOutrasEmpresas}
                            tipoCentroEstocagem={tipoCEDistribuicao}
                            tipoSKU="M"
                            value={formik.values.centro_distribuicao}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2 p-mt-auto">
                        <Button
                            type="button"
                            icon="pi pi-box"
                            label="Validar saldo"
                            disabled={!formik.values.centro_estocagem}
                            onClick={validarSaldoSKU}
                        />
                    </div>
                </div>
                <CamposObrigatorios className="p-m-0" />
                <CamposObrigatorios className="p-m-0">
                    ** Campo obrigatório apenas se tipo de lançamento for parcial.
                </CamposObrigatorios>
                <p className="p-mt-0 p-error">
                    <b>É necessário validar o saldo antes de gravar a previsão de entrega.</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-plus"
                            label="Gravar previsão de entrega"
                            disabled={!permiteReservar || bloqueiaPrevisaoEntrega}
                            loading={loading}
                            className="p-mr-2 p-mb-2"
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={`/vendas/previsoes-entregas-itens-vendas/?item_venda__venda=${dadosBasicos?.id}`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
            <ModalEditarEnderecoEntrega ref={modalRef} onSuccess={() => listagemRef.current?.buscarDados()} />
            <ModalEnderecos ref={modalEnderecosRef} onSave={() => dropEnderecosRef?.current.fetchDados()} />
        </>
    );
};
