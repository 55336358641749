import React, { useMemo } from "react";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";

export const Receber = ({ recebimentos, onClick = () => {} }) => {
    const bloquear = useMemo(() => {
        if (!recebimentos || recebimentos?.length <= 0) return true;
        const devedores = recebimentos.map((parcela) => !parcela.quitado && parcela.devedor.id).filter(Boolean);
        const boletos = recebimentos.map((parcela) => !parcela.quitado && parcela.boleto);
        const quitados = recebimentos.map((parcela) => parcela.quitado).filter(Boolean);
        const blockDevedores = [...new Set(devedores)].length > 1;
        const blockBoletos = [...new Set(boletos)].length > 1;
        const blockQuitados = quitados?.length > 0;
        return blockBoletos || blockDevedores || blockQuitados;
    }, [recebimentos]);

    return (
        <MakoControleAcesso
            permissao={[
                permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EFETIVAR,
                permissoes.FINANCEIRO_FINANCEIRO_CAIXA_MOVIMENTAR,
            ]}
            componente={Button}
            disabled={bloquear}
            label="Receber"
            icon="pi pi-dollar"
            className="p-button-info p-mr-2 p-mb-2"
            onClick={onClick}
        />
    );
};
