import React from 'react';

import MakoListagem from '@/components/MakoListagem';

import { formatarCasasDecimais, parseMoeda } from '@/assets/util/util';
import { dataToStr } from '@/assets/util/datas';

export const VisualizacaoRequisicao = ({ data = null }) => {
    const styleP = { marginBottom: "2px" };
    const styleH = { marginBottom: "4px" };


    const colunas = [
        {
            field: "sku",
            header: "Sku",
            action: ({ sku }) => sku.id,
            style: { width: "10%" },
        },
        {
            field: "sku",
            header: "Desc. Reduzida",
            action: ({ sku }) => sku.descricao_reduzida,
        },
        {
            field: "descricao",
            header: "Descrição",
        },
        {
            field: "quantidade",
            header: "Quantidade",
            action: ({ quantidade }) => formatarCasasDecimais(quantidade),
            style: { width: "10%" }
        },
        {
            field: "unidade_medida",
            header: "Un. Medida",
            action: ({ unidade_medida }) => `${unidade_medida.nome} (${unidade_medida.sigla})`,
            style: { width: "10%" }
        },
        {
            field: "custo_total",
            header: "Cst. Total",
            action: ({ custo_total }) => parseMoeda(custo_total),
            style: { width: "15%" }
        },
        {
            field: "custo_unitario",
            header: "Cst. Unitário",
            action: ({ custo_unitario }) => parseMoeda(custo_unitario),
            style: { width: "15%" }
        },
        {
            field: "data_prevista",
            header: "Data Prevista",
            action: ({ data_prevista }) => data_prevista !== null ? dataToStr(data_prevista, "dd/MM/yyyy") : "",
            style: { width: "15%" }
        },
    ];

    return (
        <div>
            <div className='card'>
                <h5 style={styleH}>Dados básicos:</h5>
                <p style={styleP}>
                    <b>Solicitante: </b>
                    {data.solicitante.nome}
                </p>
                <p style={styleP}>
                    <b>Data/Hora: </b>
                    {dataToStr(data.data_hora, "dd/mm/yyyy HH:mm")}
                </p>
                <p style={styleP}>
                    <b>Grupo Categoria: </b>
                    {data.grupo_categoria.descricao}
                </p>
                <p style={styleP}>
                    <b>Setor: </b>
                    {data.setor.nome}
                </p>
                <p style={styleP}>
                    <b>Centro de Resultado: </b>
                    {data.centro_resultados.nome}
                </p>
                <p style={styleP}>
                    <b>Centro de estocagem: </b>
                    {data.centro_estocagem.nome}
                </p>
                <p style={styleP}>
                    <b>Observação: </b>
                    {data.observacao}
                </p>
                {data.motivo_cancelamento != null && (
                    <p style={styleP}>
                        <b>Motivo cancelamento: </b>
                        {data.motivo_cancelamento}
                    </p>
                )}
            </div>
            <div className='card'>
                <MakoListagem
                    titulo="Items requisição:"
                    dadosLocal={data.itemrequisicao_set}
                    colunas={colunas}
                />
            </div>

        </div>
    )
}