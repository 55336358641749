import React, { useCallback, useImperativeHandle, useMemo, useRef, forwardRef } from "react";

import { Dropdown } from "../../Dropdown";
import { TIPO_CENTRO_ESTOCAGEM } from "@/assets/constants/estoque";

const BASE_URL = "/produtos/estoques-empresas/";

const DropdownEstoques = ({ empresaId, tipoSKU, tipoCentroEstocagem, estoqueId, aposPesquisar, ...props }, ref) => {
    const dropdownRef = useRef(null);

    const estoqueTemplate = (option) => {
        const { nome, endereco } = option;
        const { cidade } = endereco;
        return (
            <span>{`${nome} (${endereco.logradouro}, ${endereco.numero} - ${cidade.nome}/${cidade.estado.uf})`}</span>
        );
    };

    const centroEstocagemTemplate = (option) => {
        const tipoCE = TIPO_CENTRO_ESTOCAGEM.find((el) => el.value === option.tipo);
        return <span>{`${option.nome} [${tipoCE ? tipoCE.label : "Desconhecido"}]`}</span>;
    };

    const centroEstocagemSelecionadoTemplate = (option, props) => {
        if (option) return centroEstocagemTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const aposBuscar = useCallback(
        (data) => {
            let estoques = [...data];
            if (typeof aposPesquisar === "function") estoques = aposPesquisar(estoques);
            if (!tipoCentroEstocagem && !tipoSKU) return estoques;
            return estoques
                .map((item) => {
                    if (tipoCentroEstocagem) {
                        item.centroestocagem_set = item.centroestocagem_set?.filter((c) =>
                            [...tipoCentroEstocagem].includes(c.tipo)
                        );
                    }
                    if (tipoSKU) {
                        item.centroestocagem_set = item.centroestocagem_set?.filter((c) => c.tipo_sku === tipoSKU);
                    }
                    return item;
                })
                .filter((est) => est.centroestocagem_set?.length > 0);
        },
        [tipoCentroEstocagem, tipoSKU, aposPesquisar]
    );

    const getCentroEstocagem = (callback) => {
        const estoques = dropdownRef.current?.getObjects();
        const centrosEstocagem = [];
        estoques.forEach((estoque) => {
            centrosEstocagem.push(...estoque.centroestocagem_set);
        });
        return centrosEstocagem.find(callback);
    };

    useImperativeHandle(ref, () => ({ getCentroEstocagem }));

    const url = useMemo(() => {
        let filtros = [];
        if (empresaId) filtros.push(`empresa=${empresaId}`);
        if (estoqueId) filtros.push(`id=${estoqueId}`);
        return `${BASE_URL}?${filtros.join("&")}`;
    }, [empresaId, estoqueId]);

    return (
        <Dropdown
            ref={dropdownRef}
            url={url}
            {...props}
            optionLabel="nome"
            optionGroupLabel="nome"
            optionGroupChildren="centroestocagem_set"
            optionGroupTemplate={estoqueTemplate}
            itemTemplate={centroEstocagemTemplate}
            valueTemplate={centroEstocagemSelecionadoTemplate}
            aposBuscar={aposBuscar}
        />
    );
};

export const MakoDropdownEstoques = forwardRef(DropdownEstoques);
