import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import { BotaoStatus } from "@/components/BotaoStatus";
import {
    BooleanFiltroTemplate,
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import permissoes from "@/assets/constants/permissoes";
import useHttp from "@/hooks/useHttp";

export const TipoEstagioPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory(null);
    const { httpPatch } = useHttp();

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_TIPOESTAGIO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/cadastros/tipos-estagios/form")}
            />
        </>
    );

    const editarTipoEstagio = (tipoEstagio) => {
        history.push({
            pathname: "/vendas/cadastros/tipos-estagios/form",
            state: { tipoEstagio },
        });
    };

    const alterarStatusTipoEstagio = async (tipoEstagio) => {
        const body = { ativo: !tipoEstagio.ativo };
        const handlers = {
            200: () => listagemRef.current?.buscarDados(),
        };
        await httpPatch({ url: `/vendas/tipos-estagios-vendas/${tipoEstagio.id}/`, body }, handlers);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TIPOESTAGIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro."
                    tooltipOptions={{ position: "left" }}
                    onClick={() => editarTipoEstagio(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TIPOESTAGIO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/vendas/tipos-estagios-vendas/"
                    icon="pi pi-trash"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo apagar o tipo de estágio <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O tipo de estágio não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                    classNames="p-mr-2 p-mb-1"
                />
                <BotaoStatus
                    status={rowData.ativo}
                    rounded
                    onClick={() => alterarStatusTipoEstagio(rowData)}
                    className="p-mr-2 p-mb-1"
                />
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const operacaoTipoEstagioBodyTemplate = (value) => {
        if (value) {
            return <span>Sim</span>;
        } else if (value === false) {
            return <span>Não</span>;
        } else {
            return <span>Mantém</span>;
        }
    };

    const FiltroEstagioVendaTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: true, label: "Sim" },
                        { value: false, label: "Não" },
                        { value: -1, label: "Mantém" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "8%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "30%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Ativo",
            action: statusBodyTemplate,
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "baixa_estoque",
            header: "Baixa Estoque",
            action: (e) => operacaoTipoEstagioBodyTemplate(e.baixa_estoque),
            filter: true,
            filterElement: FiltroEstagioVendaTemplate,
        },
        {
            field: "gera_financeiro",
            header: "Gera Financeiro",
            action: (e) => operacaoTipoEstagioBodyTemplate(e.gera_financeiro),
            filter: true,
            filterElement: FiltroEstagioVendaTemplate,
        },
        {
            field: "gera_documento_fiscal",
            header: "Gera Doc. Fiscal",
            action: (e) => operacaoTipoEstagioBodyTemplate(e.gera_documento_fiscal),
            filter: true,
            filterElement: FiltroEstagioVendaTemplate,
        },
        {
            field: "reserva_estoque",
            header: "Reserva Estoque",
            action: (e) => operacaoTipoEstagioBodyTemplate(e.reserva_estoque),
            filter: true,
            filterElement: FiltroEstagioVendaTemplate,
        },
        {
            field: "gera_comissao",
            header: "Gera Comissão",
            action: (e) => operacaoTipoEstagioBodyTemplate(e.gera_comissao),
            filter: true,
            filterElement: FiltroEstagioVendaTemplate,
        },
        { field: "action", header: "Ações", style: { width: "8%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Tipos de estágios de vendas"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/vendas/tipos-estagios-vendas/"
                configTabela={{ lazy: true, paginator: true }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    ativo: { value: true, matchMode: "equals" },
                    baixa_estoque: { value: null, matchMode: "equals" },
                    gera_financeiro: { value: null, matchMode: "equals" },
                    gera_documento_fiscal: { value: null, matchMode: "equals" },
                    reserva_estoque: { value: null, matchMode: "equals" },
                    gera_comissao: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
