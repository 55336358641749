import React from "react";

export const IconDeposito = ({ color = "black", ...props }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.9083 16.667C11.0083 17.267 11.2166 17.8253 11.5083 18.3337H4.99992C4.07492 18.3337 3.33325 17.592 3.33325 16.667V3.33366C3.33325 2.89163 3.50885 2.46771 3.82141 2.15515C4.13397 1.84259 4.55789 1.66699 4.99992 1.66699H11.6666L16.6666 6.66699V10.9087C16.3916 10.867 16.1166 10.8337 15.8333 10.8337C15.5499 10.8337 15.2749 10.867 14.9999 10.9087V7.50033H10.8333V3.33366H4.99992V16.667H10.9083ZM19.1666 14.167L16.6666 12.0837V13.3337H13.3333V15.0003H16.6666V16.2503L19.1666 14.167ZM14.9999 15.417L12.4999 17.5003L14.9999 19.5837V18.3337H18.3333V16.667H14.9999V15.417Z"
                fill={color}
            />
        </svg>
    );
};
