import React from "react";
import { useHistory } from "react-router-dom";

import { BoletoDadosBasicosForm } from "./formDadosBasicos";
import { BoletoValoresTaxasForm } from "./formValores";
import { BoletoAntecipacaoForm } from "./formAntecipacao";
import { BoletoPagamentoForm } from "./formPagamento";
import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import useBoleto from "@/hooks/useBoleto";

export const TabBoletos = (props) => {
    const history = useHistory();
    const { submitDadosBasicos, submitDadosValores, handleBoleto } = useBoleto();

    const TABS = [
        {
            header: "Dados básicos",
            icon: "pi pi-fw pi-align-left",
            component: <BoletoDadosBasicosForm />,
        },
        {
            header: "Valores / taxas",
            icon: "pi pi-fw pi-dollar",
            disabled: !submitDadosBasicos,
            component: <BoletoValoresTaxasForm />,
        },
        {
            header: "Antecipação",
            icon: "pi pi-fw pi-clock",
            disabled: !submitDadosBasicos,
            component: <BoletoAntecipacaoForm />,
        },
        {
            header: "Pagamento",
            icon: "pi pi-fw pi-money-bill",
            disabled: !submitDadosBasicos,
            component: <BoletoPagamentoForm />,
        },
    ];

    const persistirBoletoBancario = async () => {
        await handleBoleto();
    };

    const voltarParaListagem = () => {
        history.push("/financeiro/boletos/lancamento-manual");
    };

    const submit = submitDadosBasicos && submitDadosValores;

    return (
        <PageBase>
            <MakoMultiTabs
                tabs={TABS}
                title="Cadastro de boleto bancário"
                propsButtonProx={{ disabled: !submitDadosBasicos }}
                propsButtonFinalizar={{ disabled: !submit, onClick: persistirBoletoBancario }}
                // confirmDialogAoFinalizar={!submit}
                // propsConfirmDialog={{ accept: persistirDadosPessoa }}
                propsButtonCancelar={{ onClick: voltarParaListagem }}
            />
        </PageBase>
    );
};
