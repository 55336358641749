import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_CATEGORIA_CARTEIRA_CHOICE, TIPO_VEICULO_ENTREGA_CHOICE } from "@/assets/constants/constants";
import { InputNumber } from "primereact/inputnumber";
import { MakoCalendar } from "@/components/MakoCalendar";
import { InputTextarea } from "primereact/inputtextarea";
import { dataToStr } from "@/assets/util/datas";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const VeiculoEntregaForm = (props) => {
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();

    const formik = useFormik({
        initialValues: props.location.state?.veiculo
            ? {
                  ...props.location.state?.veiculo,
                  tipo: props.location.state?.veiculo.tipo?.id,
                  categoria_exigida: props.location.state?.veiculo.categoria_exigida?.id,
              }
            : {
                  placa: "",
                  chassi: "",
                  renavan: "",
                  tipo: null,
                  categoria_exigida: "",
                  maximo_passageiros: null,
                  area_maxima_m3: null,
                  carga_maxima: null,
                  ano_fabricacao: null,
                  ano_modelo: null,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                placa: Yup.string().min(7).max(7).required("O campo 'placa' é obrigatório."),
                chassi: Yup.string().min(17).max(17).required("O campo 'chassi' é obrigatório."),
                renavan: Yup.string().min(11).max(11).required("O campo 'renavan' é obrigatório."),
                tipo: Yup.number()
                    .required("O campo 'tipo de veículo' é obrigatório.")
                    .typeError("Informe um 'tipo' válido."),
                categoria_exigida: Yup.string().required("O campo 'categoria cnh' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.ano_fabricacao = dataToStr(values.ano_fabricacao, "yyyy-MM-dd");
            values.ano_modelo = dataToStr(values.ano_modelo, "yyyy-MM-dd");

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Veículo de entrega cadastrado com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };
                showLoading();
                await httpPost({ url: `/pessoas/veiculo-entrega/`, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Veículo de entrega alterado com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };
                showLoading();
                await httpPut({ url: `/pessoas/veiculo-entrega/${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        history.push("/gestao/cadastros/veiculo-entrega");
    };

    return (
        <PageBase>
            {<h3>{!formik.values.id ? "Novo Veículo de Entrega" : "Informação de Veículo de Entrega"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="placa" label="Placa" obrigatorio />
                        <InputText
                            id="placa"
                            name="placa"
                            value={formik.values.placa}
                            onChange={formik.handleChange}
                            placeholder="Ex: AAA1111"
                            className={classNames({ "p-invalid": formik.errors.placa })}
                        />
                        {formik.errors.placa && <small className="p-error">{formik.errors.placa}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="chassi" label="Chassi" obrigatorio />
                        <InputText
                            id="chassi"
                            name="chassi"
                            placeholder="Ex: 8aE AnTnpA j2 V28918"
                            value={formik.values.chassi}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chassi })}
                        />
                        {formik.errors.chassi && <small className="p-error">{formik.errors.chassi}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="renavan" label="Renavan" obrigatorio />
                        <InputText
                            id="renavan"
                            name="renavan"
                            placeholder="Ex: 96606352648"
                            value={formik.values.renavan}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.renavan })}
                        />
                        {formik.errors.renavan && <small className="p-error">{formik.errors.renavan}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo" label="Tipo de veículo" obrigatorio />
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            placeholder="Selecione"
                            options={TIPO_VEICULO_ENTREGA_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            onChange={formik.handleChange}
                            value={formik.values.tipo}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="categoria_exigida" label="Categoria CNH" obrigatorio />
                        <Dropdown
                            id="categoria_exigida"
                            name="categoria_exigida"
                            placeholder="Selecione"
                            options={TIPO_CATEGORIA_CARTEIRA_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            onChange={formik.handleChange}
                            value={formik.values.categoria_exigida}
                            className={classNames({
                                "p-invalid": formik.errors.categoria_exigida,
                            })}
                        />
                        {formik.errors.categoria_exigida && (
                            <small className="p-error">{formik.errors.categoria_exigida}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="maximo_passageiros" label="Máximo de Passageiros" />
                        <InputNumber
                            inputId="maximo_passageiros"
                            name="maximo_passageiros"
                            value={formik.values.maximo_passageiros}
                            onValueChange={formik.handleChange}
                            showButtons
                            min={0}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="carga_maxima" label="Carga máxima" />
                        <InputNumber
                            inputId="carga_maxima"
                            name="carga_maxima"
                            value={formik.values.carga_maxima}
                            onValueChange={formik.handleChange}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            suffix="kg"
                            min={0}
                        />
                        {formik.errors.carga_maxima && <small className="p-error">{formik.errors.carga_maxima}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="area_maxima_m3" label="Área máxima em m3" />
                        <InputNumber
                            inputId="area_maxima_m3"
                            name="area_maxima_m3"
                            value={formik.values.area_maxima_m3}
                            onValueChange={formik.handleChange}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            suffix="m3"
                            min={0}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="ano_fabricacao" label="Ano fabricação" />
                        <MakoCalendar
                            id="ano_fabricacao"
                            name="ano_fabricacao"
                            valueCalendar={formik.values.ano_fabricacao}
                            onChange={formik.handleChange}
                            view="year"
                            dateFormat="yy"
                            placeholder="aaaa"
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="ano_modelo" label="Ano modelo" />
                        <MakoCalendar
                            id="ano_modelo"
                            name="ano_modelo"
                            valueCalendar={formik.values.ano_modelo}
                            onChange={formik.handleChange}
                            view="year"
                            dateFormat="yy"
                            placeholder="aaaa"
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="observacao" label="Observação" />
                        <InputTextarea
                            id="observacao"
                            name="observacao"
                            value={formik.values.observacao}
                            onChange={formik.handleChange}
                            autoResize
                            rows={3}
                            maxLength={100}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        icon={MAKO_ICONS.VOLTAR}
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
