import React, { useRef, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { dataToStr } from "@/assets/util/datas";
import { TabPanel, TabView } from "primereact/tabview";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import classNames from "classnames";
import * as Yup from "yup";
import { TIPOS_ITENS_FINALIDADE, TIPOS_ITENS_INVENTARIO } from "@/assets/constants/constants";
import MakoListagem from "@/components/MakoListagem";
import useEmpresa from "@/hooks/useEmpresa";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Dropdown } from "@/components/Dropdown";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";

export const FiscalSped = () => {
    const [activeTab, setActiveTab] = useState(0);
    const { empresaSelecionada, empresaSelecionadaId } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const listagemRef = useRef(null);
    const { user } = useAuth();
    const { showSuccess, showError, showWarning } = useToast();
    const { httpGet, httpPost } = useHttp();

    const history = useHistory();

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            empresa: null,
            periodo: new Date(),
            gerar_bloco_h: false,
            gerar_bloco_m_s: false,
            motivo_inventario: null,
            finalidade: 1,
            tipo: null,
            registro_74: false,
        },
    });

    async function handleSintegra() {
        try {
            const { perfil_pj } = empresaSelecionada;
            const { cidade, ...endereco } = empresaSelecionada.enderecoperfil_set[0];
            const ano = dataToStr(formik.values.periodo, "yyyy");
            const mes = dataToStr(formik.values.periodo, "MM");

            const body = {
                ano: ano,
                mes: mes,
                empresa_id: empresaSelecionadaId,
                registro_10: {
                    cnpj_mf: perfil_pj.cnpj,
                    ie: perfil_pj.inscricao_estadual,
                    nome_contribuinte: perfil_pj.razao_social,
                    municipio: cidade.nome,
                    unidade_federacao: cidade.estado.uf,
                },
                registro_11: {
                    logradouro: endereco.logradouro,
                    numero: endereco.numero,
                    complemento: endereco.complemento,
                    bairro: endereco.bairro,
                    cep: endereco.cep,
                    nome_contato: endereco.identificacao,
                },
                uf: empresaSelecionada?.enderecoperfil_set[0]?.cidade.estado.uf,
                registro_74: formik.values.registro_74,
            };

            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "SINTEGRA gerado com sucesso!",
                        life: 3000,
                    });
                    listagemRef.current?.buscarDados();
                },
                400: ({ err }) => {
                    showError({
                        summary: "Erro",
                        detail: err.erro || "Não foi possível gerar o arquivo fiscal.",
                        life: 3000,
                    });
                },
                409: ({ err }) => {
                    showError({
                        summary: "Erro",
                        detail: err.erro || "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                },
            };

            showLoading();
            await httpPost({ url: `/fiscal/gerar-sintegra/`, body }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    async function handleSped() {
        try {
            const body = {
                ano: dataToStr(formik.values.periodo, "yyyy"),
                mes: dataToStr(formik.values.periodo, "MM"),
                usuario_gerou: user?.id,
                gerar_bloco_h: formik.values.gerar_bloco_h,
                gerar_bloco_m_s: formik.values.gerar_bloco_m_s,
                motivo_inventario: formik.values.motivo_inventario,
                finalidade: formik.values.finalidade,
                tipo: formik.values.tipo,
            };

            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "SPED gerado com sucesso!",
                        life: 3000,
                    });
                    listagemRef.current?.buscarDados();
                },
                400: ({ err }) => {
                    showError({
                        summary: "Erro",
                        detail: err.msg || "Não foi possível gerar o arquivo fiscal.",
                        life: 3000,
                    });
                },
                409: ({ err }) => {
                    showError({
                        summary: "Erro",
                        detail: err.msg || "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                },
            };

            showLoading();
            await httpPost({ url: `/fiscal/gerar-registros-sped/${empresaSelecionadaId}/`, body }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const colSintegra = [
        {
            field: "id",
            header: "Código",
            style: { width: "15%" },
        },
        {
            field: "periodo_inicial",
            header: "Período Inicial",
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "periodo_final",
            header: "Período Final",
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "registro_74",
            header: "Possui Inventário",
            action: ({ registro_74 }) => (registro_74 ? <b>Sim</b> : "Não"),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplateSintegra(e),
            style: { width: "10%" },
        },
    ];

    const colSped = [
        {
            field: "id",
            header: "Código",
            style: { width: "15%" },
        },
        {
            field: "periodo_inicial",
            header: "Período Inicial",
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "periodo_final",
            header: "Período Final",
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "bloco_1600",
            header: "Bloco 1600",
            action: ({ bloco_1600 }) => (bloco_1600 ? <b>Sim</b> : "Não"),
        },
        {
            field: "bloco_h",
            header: "Bloco H",
            action: ({ bloco_h }) => (bloco_h ? <b>Sim</b> : "Não"),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplateSped(e),
            style: { width: "10%" },
        },
    ];

    const consultarSintegra = useCallback(
        async (rowData) => {
            const handlers = {
                200: ({ data }) => {
                    const { results } = data;

                    if (results instanceof Array && results.length > 0) {
                        let _linhas = "";

                        for (let i = 0; i < results.length; i++) {
                            _linhas +=
                                i !== results.length - 1 ? `${results[i].linha_dados} \n` : `${results[i].linha_dados}`;
                        }

                        const blobFile = new Blob([_linhas], {
                            type: "text/plain;charset-utf-8",
                        });

                        const a = document.createElement("a");
                        a.href = window.URL.createObjectURL(blobFile);
                        a.download = `${empresaSelecionada.identificacao}_${dataToStr(
                            rowData.periodo_inicial,
                            "ddMMyyyy"
                        )}_${dataToStr(rowData.periodo_final, "ddMMyyyy")}.txt`;
                        a.click();
                    }
                },
            };

            showLoading();
            await httpGet(
                { url: `/fiscal/dados-sintegra/?ordering=numero_linha&sintegra=${rowData.id}&limit=1000000` },
                handlers
            );
            hideLoading();
        },
        [showLoading, hideLoading, empresaSelecionada, httpGet]
    );

    const actionBodyTemplateSintegra = (rowData) => {
        return (
            <Button
                type="button"
                icon="pi pi-file"
                tooltip="Gerar arquivo sintegra TXT"
                className="p-button-info p-button-rounded"
                onClick={() => consultarSintegra(rowData)}
            />
        );
    };

    const baixarSped = useCallback(
        async (rowData) => {
            try {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Download SPED realizado com sucesso!",
                            life: 3000,
                        });
                        const link = document.createElement("a");
                        link.href = "data:application/txt;base64," + data.arquivo.replace("b'", "").replace("'", "");
                        link.download = data.nome_arquivo;
                        link.click();
                    },
                };

                showLoading();
                await httpPost({ url: `/fiscal/gerar-arquivo-sped/${rowData.id}/` }, handlers);
                hideLoading();
                listagemRef.current?.buscarDados();
            } catch (error) {
                showError();
            }
        },
        [showLoading, hideLoading, showError, showSuccess, httpPost]
    );

    const actionBodyTemplateSped = (rowData) => {
        return (
            <>
                <Button
                    type="button"
                    icon="pi pi-file"
                    tooltip="Baixar arquivo"
                    className="p-button-info p-button-rounded p-mr-1"
                    onClick={() => baixarSped(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_ENTRADA_ENTRADA_EDITAR]}
                    componente={BotaoDelete}
                    url={`/fiscal/speds-gerados/`}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja mesmo excluir o registro de Sped?</span>}
                    msgToastErroExclusao="O Sped não pode ser excluído."
                    className="p-mt-1"
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                    }}
                />
            </>
        );
    };

    return (
        <PageBase>
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header="Gerar SPED" leftIcon="pi pi-book">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="periodo">Periodo:</label>
                                <MakoCalendar
                                    id="periodo"
                                    name="periodo"
                                    value={formik.values.periodo}
                                    onChange={formik.handleChange}
                                    view="month"
                                    showIcon
                                    dateFormat="mm/yy"
                                    maxDate={new Date()}
                                />
                                {formik.errors.periodo && <small className="p-error">{formik.errors.periodo}</small>}
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="tipo">Modelo</label>
                                <Dropdown
                                    id="tipo"
                                    name="tipo"
                                    url="/fiscal/sped-tipo"
                                    placeholder="Selecione..."
                                    filter
                                    filterBy="descricao"
                                    optionLabel="nome"
                                    optionValue="id"
                                    value={formik.values.tipo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo })}
                                />
                                {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                            </div>
                            <div className="p-field p-col-3">
                                <label htmlFor="finalidade">Finalidade:</label>
                                <Dropdown
                                    id="finalidade"
                                    name="finalidade"
                                    placeholder="Selecione uma finalidade"
                                    filter
                                    filterBy="descricao"
                                    optionLabel="label"
                                    optionValue="value"
                                    options={TIPOS_ITENS_FINALIDADE}
                                    value={formik.values.finalidade}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.finalidade })}
                                />
                                {formik.errors.finalidade && (
                                    <small className="p-error">{formik.errors.finalidade}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="motivo_inventario">Motivo inventário:</label>
                                <Dropdown
                                    id="motivo_inventario"
                                    name="motivo_inventario"
                                    placeholder="Selecione um motivo"
                                    filter
                                    filterBy="label"
                                    optionLabel="label"
                                    optionValue="value"
                                    showClear
                                    options={TIPOS_ITENS_INVENTARIO}
                                    value={formik.values.motivo_inventario}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.motivo_inventario })}
                                />
                                {formik.errors.motivo_inventario && (
                                    <small className="p-error">{formik.errors.motivo_inventario}</small>
                                )}
                            </div>
                            <div
                                className="p-field p-col-2 p-mt-4"
                                style={{ display: "flex", alignItems: "center", gap: "5px" }}
                            >
                                <Checkbox
                                    id="gerar_bloco_h"
                                    name="gerar_bloco_h"
                                    checked={formik.values.gerar_bloco_h}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="gerar_bloco_h" className="p-mt-2">
                                    Gerar bloco H
                                </label>
                            </div>
                            <div
                                className="p-field p-col-2 p-mt-4"
                                style={{ display: "flex", alignItems: "center", gap: "5px" }}
                            >
                                <Checkbox
                                    id="gerar_bloco_m_s"
                                    name="gerar_bloco_m_s"
                                    checked={formik.values.gerar_bloco_m_s}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="gerar_bloco_m_s" className="p-mt-2">
                                    Gerar bloco 1600
                                </label>
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios</b>
                        </p>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Button
                                    type="button"
                                    icon="pi pi-cog"
                                    label="Gerar"
                                    className="p-mr-2"
                                    onClick={() => handleSped()}
                                />
                                <Button
                                    type="reset"
                                    icon="pi pi-trash"
                                    label="Limpar"
                                    className="p-button-warning p-mr-2"
                                    onClick={() => resetForm()}
                                />
                                <Button
                                    type="reset"
                                    label="Cancelar"
                                    className="p-button-danger"
                                    onClick={() => history.goBack()}
                                />
                            </div>
                        </div>
                    </form>
                </TabPanel>
                <TabPanel header="Gerar SINTEGRA" leftIcon="pi pi-file">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="periodo">Periodo:</label>
                                <MakoCalendar
                                    id="periodo"
                                    name="periodo"
                                    value={formik.values.periodo}
                                    onChange={formik.handleChange}
                                    view="month"
                                    showIcon
                                    dateFormat="mm/yy"
                                    maxDate={new Date()}
                                />
                                {formik.errors.periodo && <small className="p-error">{formik.errors.periodo}</small>}
                            </div>
                            <div
                                className="p-field p-col-12 p-md-3 p-mt-4"
                                style={{ display: "flex", alignItems: "center", gap: "5px" }}
                            >
                                <Checkbox
                                    id="registro_74"
                                    name="registro_74"
                                    checked={formik.values.registro_74}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="registro_74" className="p-mt-2">
                                    Gerar registro 74
                                </label>
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios</b>
                        </p>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Button
                                    type="button"
                                    icon="pi pi-cog"
                                    label="Gerar"
                                    className="p-mr-2"
                                    onClick={() => handleSintegra()}
                                />
                                <Button
                                    type="reset"
                                    icon="pi pi-trash"
                                    label="Limpar"
                                    className="p-button-warning p-mr-2"
                                    onClick={() => resetForm()}
                                />
                                <Button
                                    type="reset"
                                    label="Cancelar"
                                    className="p-button-danger"
                                    onClick={() => history.goBack()}
                                />
                            </div>
                        </div>
                    </form>
                </TabPanel>
            </TabView>
            {activeTab === 1 ? (
                <MakoListagem
                    ref={listagemRef}
                    titulo="Registros de Sintegra"
                    colunas={colSintegra}
                    urlPesquisa={"/fiscal/sintegra/"}
                    filtarPorEmpresa
                    naoBuscarSemEmpresa
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            ) : (
                <MakoListagem
                    ref={listagemRef}
                    titulo="Registros de Sped"
                    colunas={colSped}
                    urlPesquisa={"/fiscal/speds-gerados/"}
                    filtarPorEmpresa
                    naoBuscarSemEmpresa
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            )}
        </PageBase>
    );
};
