import React from "react";

import { LoteInventarioComponente } from "./form";

export const LoteInventarioForm = (props) => {
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <LoteInventarioComponente exibeCabecalho exibeRodape loteInventario={props.location.state} />
                </div>
            </div>
        </div>
    );
};
