import React from "react";
export const IconCarneCrediario = ({ color = "black", ...props }) => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M17 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M14 1V5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 1V5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 9H19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M9.52273 16.2557V16.1591C9.52462 15.8277 9.55398 15.5634 9.6108 15.3665C9.66951 15.1695 9.75473 15.0104 9.86648 14.8892C9.97822 14.768 10.1127 14.6581 10.2699 14.5597C10.3873 14.4839 10.4924 14.4053 10.5852 14.3239C10.678 14.2424 10.7519 14.1525 10.8068 14.054C10.8617 13.9536 10.8892 13.8419 10.8892 13.7188C10.8892 13.5881 10.858 13.4735 10.7955 13.375C10.733 13.2765 10.6487 13.2008 10.5426 13.1477C10.4384 13.0947 10.3229 13.0682 10.196 13.0682C10.0729 13.0682 9.95644 13.0956 9.84659 13.1506C9.73674 13.2036 9.64678 13.2831 9.5767 13.3892C9.50663 13.4934 9.46875 13.6231 9.46307 13.7784H8.30398C8.31345 13.3996 8.40436 13.0871 8.5767 12.8409C8.74905 12.5928 8.97727 12.4081 9.26136 12.2869C9.54545 12.1638 9.8589 12.1023 10.2017 12.1023C10.5786 12.1023 10.9119 12.1648 11.2017 12.2898C11.4915 12.4129 11.7188 12.5919 11.8835 12.8267C12.0483 13.0616 12.1307 13.3447 12.1307 13.6761C12.1307 13.8977 12.0938 14.0947 12.0199 14.267C11.9479 14.4375 11.8466 14.589 11.7159 14.7216C11.5852 14.8523 11.4309 14.9706 11.2528 15.0767C11.1032 15.1657 10.9801 15.2585 10.8835 15.3551C10.7888 15.4517 10.7178 15.5634 10.6705 15.6903C10.625 15.8172 10.6013 15.9735 10.5994 16.1591V16.2557H9.52273ZM10.0852 18.0739C9.89583 18.0739 9.7339 18.0076 9.59943 17.875C9.46686 17.7405 9.40152 17.5795 9.40341 17.392C9.40152 17.2064 9.46686 17.0473 9.59943 16.9148C9.7339 16.7822 9.89583 16.7159 10.0852 16.7159C10.2652 16.7159 10.4233 16.7822 10.5597 16.9148C10.696 17.0473 10.7652 17.2064 10.767 17.392C10.7652 17.517 10.732 17.6316 10.6676 17.7358C10.6051 17.8381 10.5227 17.9205 10.4205 17.983C10.3182 18.0436 10.2064 18.0739 10.0852 18.0739Z"
                fill={color}
            />
        </svg>
    );
};
