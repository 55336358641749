import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";

import { gerarFiltrosUrl } from "@/assets/util/util";

const BASE_URL = "/financeiro/formas-recebimentos-efetivados/";

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { width: "10%" },
};

const BASE_COLUMS = [
    {
        field: "forma_recebimento.descricao",
        header: "Forma recebimento",
    },
    {
        field: "conta_financeira.descricao",
        header: "Conta financeira",
    },
    {
        field: "valor",
        header: "Valor",
        money: true,
    },
];

export const ListagemFracionamentos = ({ recebimentoId, listagemRef, actions }) => {
    const url = useMemo(() => {
        return `${BASE_URL}?${gerarFiltrosUrl({ recebimento: recebimentoId })}`;
    }, [recebimentoId]);

    const colunas = useMemo(() => {
        if (!actions) return BASE_COLUMS;
        const actionColum = {
            ...BASE_COLUMN_ACTIONS,
            action: (e) => actions(e),
        };
        return [...BASE_COLUMS, actionColum];
    }, [actions]);

    return (
        <MakoListagem
            ref={listagemRef}
            urlPesquisa={url}
            fazerBusca={!!recebimentoId}
            colunas={colunas}
            configTabela={{
                paginator: true,
                lazy: true,
            }}
        />
    );
};
