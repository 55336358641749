import React, { useMemo, memo } from "react";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";

import { Label } from "@/components/Label";

export const Componente = ({ fieldOptions, valueOptions, instance, onChange, error }) => {
    const { id: name, label, is_required, default_value, field_type, content_type, field_choices } = fieldOptions;

    const valueField = valueOptions || {
        id: null,
        field: name,
        value: default_value || "",
        object_id: instance,
        content_type,
    };

    const onChangeValue = (e) => {
        onChange({ ...valueField, value: e.value });
    };

    const options = useMemo(() => {
        if (field_type === "m") {
            return field_choices.split("|").map((e) => ({ label: e, value: e }));
        }

        return [
            { label: "Sim", value: 1 },
            { label: "Não", value: 0 },
        ];
    }, [field_choices, field_type]);

    return (
        <>
            {label && <Label htmlFor={name} label={label} obrigatorio={is_required} />}
            <Dropdown
                id={name}
                name={name}
                options={options}
                placeholder="Selecione"
                value={valueField.value}
                onChange={onChangeValue}
                className={classNames({ "p-invalid": error })}
            />
            {error && <small className="p-error">{error}</small>}
        </>
    );
};

export const CustomFieldDropdown = memo(Componente);
