import React, { useCallback, useMemo } from "react";

import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";

import { InputText } from "primereact/inputtext";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoButton } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { TIPO_CHOICE_LOTE_SERIAL } from "@/assets/constants/constants";
import { gerarFiltrosUrl } from "@/assets/util/util";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/produtos/saldos-lotes-sku/";

const BASE_DADOS = {
    lote_serial: "",
    item_entrada: null,
    sku: null,
    fabricacao: null,
    vencimento: null,
    quantidade: 0,
    enderecamento: "",
    unico: false,
    complemento: "",
    bloqueado_vender: false,
    id: null,
};

export const AjustarSaldo = ({ lote = BASE_DADOS, cancelCallback = () => {}, successCallback = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPatch } = useHttp();

    const { setFieldValue, setValues, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: null,
            centro_estocagem: null,
            saldo_anterior: 0,
            saldo: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values, helpers) {
        try {
            showLoading();
            const formSchema = Yup.object().shape({
                saldo: Yup.number().required("O campo 'saldo' é obrigatório").typeError("Informe um 'produto' válido"),
                centro_estocagem: Yup.object()
                    .required("O campo 'centro de estocagem' é obrigatório")
                    .typeError("Informe um 'centro de estocagem' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { id, saldo } = values;

            const handlers = {
                200: ({ data }) => {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Saldo ajustado com sucesso.",
                        life: 1500,
                    });
                    successCallback(data);
                },
            };
            await httpPatch(
                {
                    url: `${BASE_URL}${id}/`,
                    body: { saldo },
                },
                handlers
            );
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                helpers.setErrors(errorMessages);
            }
        } finally {
            hideLoading();
        }
    }

    const aposBuscarSaldos = useCallback((data) => {
        return data.map((d) => ({
            ...d,
            label: `${d.centro_estocagem.descricao} (${d.centro_estocagem.tipo})`,
        }));
    }, []);

    const onChangeCentro = useCallback(
        (e) => {
            const { saldo, id } = e.value || {};
            setFieldValue("saldo_anterior", saldo);
            setFieldValue("id", id);
            formik.handleChange(e);
        },
        [formik, setFieldValue]
    );

    const urlSaldos = useMemo(() => {
        const query = {
            lote: lote.id,
            lote__sku: lote.sku?.id,
            ordering: "-id",
            limit: 100,
        };
        return `${BASE_URL}?${gerarFiltrosUrl(query)}`;
    }, [lote]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid ">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="sku" label="Produto" />
                    <MakoBuscaSkuPersonalizada skuValue={lote.sku} exibirVisualizacaoSku disabledBusca />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid ">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="serie" label="Tipo de lote / serial" />
                    <Dropdown
                        id="serie"
                        name="serie"
                        value={lote.sku?.lote_serial}
                        options={TIPO_CHOICE_LOTE_SERIAL}
                        placeholder=""
                        dropdownIcon=" "
                        optionValue="value"
                        optionLabel="label"
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="lote_serial" label="N° do lote / N° de série" />
                    <InputText id="lote_serial" name="lote_serial" value={lote.lote_serial} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="vencimento" label="Data do vencimento" />
                    <MakoCalendar id="vencimento" name="vencimento" valueCalendar={lote.vencimento} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="fabricacao" label="Data da fabricação" />
                    <MakoCalendar id="fabricacao" name="fabricacao" valueCalendar={lote.fabricacao} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="enderecamento" label="Endereçamento" />
                    <InputText id="enderecamento" name="enderecamento" value={lote.enderecamento} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="complemento" label="Complemento" />
                    <InputText id="complemento" name="complemento" value={lote.complemento} disabled />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid ">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="centro_estocagem" label="Centro estocagem" obrigatorio />
                    <Dropdown
                        id="centro_estocagem"
                        name="centro_estocagem"
                        optionLabel="label"
                        aposBuscar={aposBuscarSaldos}
                        url={urlSaldos}
                        value={formik.values.centro_estocagem}
                        onChange={onChangeCentro}
                        className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                    />
                    {formik.errors.centro_estocagem && (
                        <small className="p-error">{formik.errors.centro_estocagem}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="saldo_anterior" label="Saldo anterior" />
                    <MakoInputQuantidadeSku
                        id="saldo_anterior"
                        name="saldo_anterior"
                        value={formik.values.saldo_anterior}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="saldo" label="Novo saldo" obrigatorio />
                    <MakoInputQuantidadeSku
                        id="saldo"
                        name="saldo"
                        value={formik.values.saldo}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.saldo })}
                    />
                    {formik.errors.saldo && <small className="p-error">{formik.errors.saldo}</small>}
                </div>
            </div>
            <CamposObrigatorios />
            <MakoActionsButtons className="p-jc-end">
                <MakoButton
                    icon={MAKO_ICONS.CANCEL}
                    label="Cancelar"
                    className="p-button p-button-danger"
                    type="reset"
                    loading={loading}
                    onClick={cancelCallback}
                />
                <MakoButton
                    icon={MAKO_ICONS.GRAVAR}
                    label="Gravar saldo"
                    className="p-button p-button-info"
                    type="submit"
                    loading={loading}
                />
            </MakoActionsButtons>
        </form>
    );
};
