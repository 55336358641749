import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

import { Dropdown } from "@/components/Dropdown";
import { Button } from "primereact/button";

import MakoListagem from "@/components/MakoListagem";

import { ModalTemplateRateio } from "@/pages/Financeiro/Financeiro/TemplateRateio/ModalTemplateRateio";

import { parseNumber } from "@/assets/helpers/number";

const COLUNAS = [
    { expander: true, style: { width: "5rem" } },
    { field: "descricao", header: "Regra de rateio" },
    { field: "percentual_plano_conta_financeira", header: "% conta financeira", decimal: true },
    { field: "percentual_plano_conta_contabil", header: "% conta contábil", decimal: true },
    { field: "percentual_fase_projeto", header: "% fase de projeto", decimal: true },
    { field: "percentual_centro_resultado", header: "% centro de resultado", decimal: true },
];

const Component = ({ parcelas = [] }, ref) => {
    const [templateRateioSelecionado, setTemplateRateioSelecionado] = useState(null);
    const [linhasExpandidas, setLinhasExpandidas] = useState(null);
    const [templatesRateio, setTemplatesRateio] = useState([]);
    const [resultadoRateio, setResultadoRateio] = useState([]);

    const modalTemplateRateioRef = useRef(null);

    const rowExpansionTemplate = (data) => {
        const colunas = [
            { field: "tipo_detalhe", header: "Tipo de detalhe" },
            { field: "detalhe", header: "Detalhe" },
            {
                field: "percentual",
                header: "Percentual",
                decimal: true,
                action: (e) => <span>{`${e.percentual} %`}</span>,
            },
            { field: "valor", header: "Valor do rateio", money: true },
        ];
        return (
            <div className="p-p-3">
                <MakoListagem colunas={colunas} dadosLocal={data.detalhe} />
            </div>
        );
    };

    const calcularValoresRateados = useCallback(() => {
        const templatesId = parcelas.map((parcela) => parcela.template_rateio.id);
        if (templatesId.length >= 1 && [...new Set(templatesId)].length === 1 && templatesRateio.length > 0) {
            const template_rateio = templatesRateio.find(
                (tr) => tr.id === (templateRateioSelecionado || templatesId[0])
            );
            const template = {
                descricao: template_rateio.descricao,
                percentual_plano_conta_financeira: template_rateio.percentual_plano_conta_financeira,
                percentual_plano_conta_contabil: template_rateio.percentual_plano_conta_contabil,
                percentual_fase_projeto: template_rateio.percentual_fase_projeto,
                percentual_centro_resultado: template_rateio.percentual_centro_resultado,
            };
            let data = template_rateio.templatedetalherateio_set.map((detalhe) => {
                const percentual = parseNumber(detalhe.percentual);
                const TIPOS_DETALHES = {
                    1: "plano_conta_contabil",
                    2: "plano_conta_financeira",
                    3: "centro_resultado",
                    4: "fase_projeto",
                };
                return {
                    tipo_detalhe: detalhe.tipo_detalhe.descricao,
                    detalhe: detalhe[TIPOS_DETALHES[detalhe.tipo_detalhe.id]].descricao,
                    percentual,
                    valor: 0,
                };
            });
            parcelas.forEach((parcela) => {
                data.forEach((detalhe) => {
                    detalhe.valor += (parcela.recebido * detalhe.percentual) / 100;
                });
            });
            setResultadoRateio([{ ...template, detalhe: data }]);
        }
    }, [parcelas, templateRateioSelecionado, templatesRateio]);

    useEffect(() => {
        calcularValoresRateados();
    }, [calcularValoresRateados]);

    useImperativeHandle(ref, () => {
        return {
            resultadoRateio,
        };
    });

    return (
        <>
            <ModalTemplateRateio
                ref={modalTemplateRateioRef}
                formProps={{
                    derivar: true,
                    location: { state: templatesRateio.find((tr) => tr.id === templateRateioSelecionado) },
                }}
            />
            <div className="p-inputgroup p-mb-2">
                <Dropdown
                    url="/financeiro/templates-rateios/"
                    setObjects={setTemplatesRateio}
                    optionValue="id"
                    optionLabel="descricao"
                    disabled={resultadoRateio.length === 0}
                    showClear={false}
                    value={templateRateioSelecionado}
                    onChange={(e) => setTemplateRateioSelecionado(e.value)}
                />
                <Button
                    type="button"
                    label="Derivar"
                    icon="pi pi-copy"
                    onClick={() => modalTemplateRateioRef.current?.abrirModal()}
                />
            </div>
            <MakoListagem
                colunas={COLUNAS}
                dadosLocal={resultadoRateio}
                configTabela={{
                    expandedRows: linhasExpandidas,
                    onRowToggle: (e) => setLinhasExpandidas(e.data),
                    rowExpansionTemplate,
                }}
            />
        </>
    );
};

export const Rateio = forwardRef(Component);
