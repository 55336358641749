import { useContext } from "react";

import CaixaMovimentoContext from "@/contexts/caixaMovimentoContext";

/**
 * @deprecated Use o hook useCaixaMovimento em vez disso
 */
const useCaixa = () => {
    const { caixaMov: caixa, loadCaixa, setCaixaUsuario, clearCaixa } = useContext(CaixaMovimentoContext);

    return { caixa, loadCaixa, setCaixaUsuario, clearCaixa };
};

export default useCaixa;
