import React from "react";
import { BlockUI } from "primereact/blockui";

import { parseMoeda } from "@/assets/util/util";

const MakoCardFinanceiro = ({ levelOverviewClass = 1, blocked, title, value, moeda = true }) => {
    return (
        <BlockUI blocked={blocked} template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2.5rem" }} />}>
            <div className={`card no-gutter widget-overview-box widget-overview-box-${levelOverviewClass}`}>
                <span className="overview-title">{title}</span>
                <div className="p-grid overview-detail p-m-0">
                    {moeda ? (
                        <div className="overview-number p-ml-2">{blocked ? "R$ --" : parseMoeda(value, true)}</div>
                    ) : (
                        <div className="overview-number p-ml-2">{blocked ? "--" : value}</div>
                    )}
                </div>
            </div>
        </BlockUI>
    );
};

export default MakoCardFinanceiro;
