import React, { useState } from "react";
import { Button } from "primereact/button";
import useLoading from "@/hooks/useLoading";
import { Checkbox } from "primereact/checkbox";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

export const ClonarProtocoloModalForm = ({ crediario, esconderDialog, listagem }) => {
    const [dadosComplementares, setDadosComplementares] = useState(false);
    const [grupoFamiliar, setGrupoFamiliar] = useState(false);
    const [dadosProfissionais, setDadosProfissionais] = useState(false);
    const [dadosRenda, setDadosRenda] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess } = useToast();
    const { httpPost } = useHttp();

    async function handleClone() {
        const listaRelacoes = crediario.relacaoperfil_set.map(
            ({ nome_relacao, perfil_vinculado, perfil_titular, rendarelacaoperfil_set, ...relacao }) => {
                if (grupoFamiliar || dadosProfissionais)
                    return {
                        ...relacao,
                        nome_relacao: nome_relacao.id,
                        perfil_vinculado: perfil_vinculado.id,
                        perfil_titular: perfil_titular.id,
                        rendarelacaoperfil_set: rendarelacaoperfil_set?.length > 0 ? rendarelacaoperfil_set : {},
                    };
            }
        );

        const body = {
            ...crediario,
            id: null,
            regra: crediario.regra.id,
            ocupacao: crediario.ocupacao.id,
            orcamento: crediario.orcamento.id,
            perfil_titular: crediario.perfil_titular.id,
            relacaoperfil_set: listaRelacoes ? { create: listaRelacoes } : {},
            referenciacrediarioperfil_set: dadosComplementares
                ? {
                      create: crediario.referenciacrediarioperfil_set.map((e) => {
                          return {
                              ...e,
                              validado: false,
                              usuario_verificacao: null,
                              nota_verificacao: null,
                              data_verificacao: null,
                          };
                      }),
                  }
                : {},
            documentosrenda_set: {},
            dadoscrediario_set: {},
            analisecrediariovenda_set: {},
        };

        const handlers = {
            201: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Protocolo de crediário clonado com sucesso!",
                    life: 3000,
                });
                esconderDialog();
                listagem.current?.buscarDados();
            },
        };

        showLoading();
        await httpPost({ url: "/crediario/protocolo-crediario/", body }, handlers);
        hideLoading();
    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <label>
                    <b>Selecione as informações que deverão ser clonadas:</b>
                </label>
                <br />
                <div className="p-fluid p-formgrid p-grid p-mt-4">
                    <div className="field-checkbox p-col-12 p-md-12">
                        <Checkbox inputId="protocolo" name="protocolo" value={true} checked={true} disabled />
                        <label htmlFor="protocolo" className="p-ml-3">
                            Protocolo
                        </label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid p-mt-4">
                    <div className="field-checkbox p-col-12 p-md-12">
                        <Checkbox
                            inputId="dados-complementares"
                            name="dados-complementares"
                            value={dadosComplementares}
                            onChange={(e) => setDadosComplementares(e.checked)}
                            checked={dadosComplementares}
                        />
                        <label htmlFor="dados-complementares" className="p-ml-3">
                            Dados Complementares
                        </label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid p-mt-3">
                    <div className="field-checkbox p-col-12 p-md-12">
                        <Checkbox
                            inputId="grupo-familiar"
                            name="grupo-familiar"
                            value={grupoFamiliar}
                            onChange={(e) => setGrupoFamiliar(e.checked)}
                            checked={grupoFamiliar}
                        />
                        <label htmlFor="grupo-familiar" className="p-ml-3">
                            Grupo familiar
                        </label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid p-mt-3">
                    <div className="field-checkbox p-col-12 p-md-12">
                        <Checkbox
                            inputId="dados-profissionais"
                            name="dados-profissionais"
                            value={dadosProfissionais}
                            onChange={(e) => setDadosProfissionais(e.checked)}
                            checked={dadosProfissionais}
                        />
                        <label htmlFor="dados-profissionais" className="p-ml-3">
                            Dados Profissionais
                        </label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid p-mt-3">
                    <div className="field-checkbox p-col-12 p-md-12">
                        <Checkbox
                            inputId="dados-renda"
                            name="dados-renda"
                            value={dadosRenda}
                            onChange={(e) => setDadosRenda(e.checked)}
                            checked={dadosRenda}
                        />
                        <label htmlFor="dados-renda" className="p-ml-3">
                            Dados Renda
                        </label>
                    </div>
                </div>
                <div align="center" className="p-mt-5">
                    <Button
                        type="submit"
                        icon="pi pi-check-square"
                        label="Confirmar"
                        className="p-button-primary p-mr-2"
                        onClick={() => handleClone()}
                    />
                </div>
            </div>
        </div>
    );
};
