import React from "react";

import { PageBase } from "@/components/PageBase";

import { Consultar } from "./features";

export const ConsultarSaldosPage = () => {
    return (
        <PageBase>
            <h4>Consultar saldos</h4>
            <Consultar />
        </PageBase>
    );
};
