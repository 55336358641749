import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Sidebar } from "primereact/sidebar";

import { Consultar } from "@/pages/Produto/ConsultarSaldos/features";

const Component = (_, ref) => {
    const [visible, setVisible] = useState(false);

    const onHide = () => {
        setVisible(false);
    };

    const show = () => {
        setVisible(true);
    };

    const hide = () => {
        setVisible(false);
    };

    const customIcons = <h4 className="p-ml-3 p-mb-0">Consultar saldos</h4>;

    useImperativeHandle(ref, () => ({ show, hide }), []);

    return (
        <Sidebar
            visible={visible}
            icons={customIcons}
            position="right"
            onHide={onHide}
            className="sidebar-consultar-saldo"
        >
            <Consultar />
        </Sidebar>
    );
};

export const MakoSidebarConsultarSaldos = forwardRef(Component);
