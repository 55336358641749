import { useContext } from "react";

import EntradaContext from "@/contexts/entradaContext";

const useEntrada = () => {
    const entrada = useContext(EntradaContext);

    return entrada;
};

export default useEntrada;
