import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { SelecionarArquivoModalForm } from "./modals/selecionarArquivosModalForm";
import { ListaPendencias } from "./listaPendencias";
import permissoes from "@/assets/constants/permissoes";
import { PageBase } from "@/components/PageBase";

export const ImportarProdutosPage = () => {
    const [exibirPendencias, setExibirPendencias] = useState(false);
    const [exibirSelecao, setExibirSelecao] = useState(false);

    return (
        <PageBase>
            <h5>Importação de Produtos</h5>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_IMPORTARPRODUTOS_IMPORTAR]}
                componente={Button}
                icon="pi pi-upload"
                className="p-button-info p-mr-2"
                label="Carregar arquivo"
                onClick={() => setExibirSelecao(true)}
            />
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_IMPORTARPRODUTOS_CONSULTAR]}
                componente={Button}
                icon="pi pi-info-circle"
                className="p-button-help p-mr-2"
                label="Importações pendentes"
                onClick={() => setExibirPendencias(!exibirPendencias)}
            />
            {exibirPendencias && (
                <div className="card">
                    <ListaPendencias listaPend={exibirPendencias} />
                </div>
            )}
            <Dialog
                header="Arquivo para importação"
                visible={exibirSelecao}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "40vw", display: "block" }}
                onHide={() => setExibirSelecao(false)}
            >
                <SelecionarArquivoModalForm esconderDialog={setExibirSelecao} esconderList={setExibirPendencias} />
            </Dialog>
        </PageBase>
    );
};
