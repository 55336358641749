import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import useLoading from "@/hooks/useLoading";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

const url = "/produtos/atributos/";
const urlvoltar = "/produto/cadastros/atributos";

export const ProdutoAtributosForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { state } = useLocation();
    const { httpPost, httpPut } = useHttp();
    const { showSuccess, showError } = useToast();

    const formik = useFormik({
        initialValues: state
            ? state.atributo
            : {
                  codigo: "",
                  nome: "",
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string()
                    .min(2, "O código precisa ter no mínimo 2 dígitos.")
                    .required("O campo 'código' é obrigatório.")
                    .typeError("Informe um código válido."),
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
            });

            await formSchema.validate(values, { abortEarly: false });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Atributo cadastrado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };

                showLoading();
                await httpPost({ url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Atributo alterado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };

                showLoading();
                await httpPut({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
            showError();
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo Atributo" : "Manutenção de Atributo"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="codigo">Nome curto *</label>
                        <InputText
                            id="codigo"
                            name="codigo"
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                            tooltip="Código do atributo Exemplo: Código 'T' do Atributo 'Tamanho'."
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="nome">Nome *</label>
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                            tooltip="Nome que será dado ao atributo."
                            autoComplete="off"
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button label="Cancelar" type="reset" className="p-button-danger" onClick={cancelar} />
                </div>
            </form>
        </PageBase>
    );
};
