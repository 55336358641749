import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
// import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import {
    // COMPONENTES_ESPECIAIS,
    MakoFormGerador,
} from "@/components/MakoFormGerador";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_VENDAS_EXTRATOSINTETICOCOMISSAO } from "@/assets/constants/relatorios";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";
import { Tag } from "primereact/tag";

const { ExportCSV } = R.Buttons;

const OPTIONS_SITUACAO = [
    { value: "C", label: "Calculada" },
    { value: "P", label: "Processada " },
    { value: "-1", label: "Qualquer" },
];

const FILTROS_VIEWSET = {
    apuracao__data_inicial__gte: "apuracao__data_inicial__gte",
    apuracao__data_final__lte: "apuracao__data_final__lte",
    empresa: "comissionado__vinculoperfilempresa__empresa",
    apuracao__status: "apuracao__status",
};

const BASE_URL = "/relatorios/comissao-sintetica/";

const FILE_NAME = gerarFileName("Comissao Sintetica");

export const RelatorioExtratoComissaoSintetico = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const [formatDocumento] = useFormatCNPJCPF();
    const { solicitarRelatorio } = useRelatorio();

    // const empresaSelecionada = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(
        // empresaSelecionada,
        formRef,
        listagemRef,
        botaoCsv
    );

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.apuracao__status !== 1) delete dadosValidados.apuracao__status;
        if (dadosValidados.apuracao__data_inicial__gte instanceof Date)
            dadosValidados.apuracao__data_inicial__gte = dataToStr(
                dadosValidados.apuracao__data_inicial__gte,
                "yyyy-MM-dd"
            );
        if (dadosValidados.apuracao__data_final__lte instanceof Date)
            dadosValidados.apuracao__data_final__lte = dataToStr(
                dadosValidados.apuracao__data_final__lte,
                "yyyy-MM-dd"
            );
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const filtrosTemplate = [
        // {
        //     key: "empresa",
        //     label: "Empresa",
        //     type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        // },
        {
            key: ["apuracao__data_inicial__gte", "apuracao__data_final__lte"],
            label: "Período emissão",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "apuracao__status",
            label: "Situação",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_SITUACAO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                apuracao__data_inicial__gte: Yup.date()
                    .default(null)
                    .nullable()
                    .typeError("Informe um 'periodo  inicial' válido"),
                apuracao__data_final__lte: Yup.date()
                    .default(null)
                    .nullable()
                    .typeError("Informe um 'periodo final' válido"),
                // empresa: Yup.number()
                //     .default(null)
                //     .required("O campo 'empresa' é obrigatório.")
                //     .typeError("Informe uma 'empresa' válida"),
                apuracao__status: Yup.string()
                    .required("O campo 'situação' é obrigatório")
                    .typeError("Informe uma 'situação' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                // empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });

            if (dadosValidados.apuracao__status === "-1") delete dadosValidados.apuracao__status;
            if (dadosValidados.apuracao__data_inicial__gte instanceof Date)
                dadosValidados.apuracao__data_inicial__gte = dataToStr(
                    dadosValidados.apuracao__data_inicial__gte,
                    "yyyy-MM-dd"
                );
            if (dadosValidados.apuracao__data_final__lte instanceof Date)
                dadosValidados.apuracao__data_final__lte = dataToStr(
                    dadosValidados.apuracao__data_final__lte,
                    "yyyy-MM-dd"
                );

            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);
            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);

                solicitarRelatorio({
                    chave: RELATORIO_VENDAS_EXTRATOSINTETICOCOMISSAO,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
                limparFormulario();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    // const onChangeEmpresa = (e) => {
    //     empresaSelecionada.current = e?.empresa;
    // };

    const colunas = [
        { field: "apuracao", header: "Apuracão", style: { width: "5%" } },
        {
            field: "comissionado",
            header: "Nome do comissionado",
            action: ({ comissionado: { nome, identificacao } }) => `${nome} - ${formatDocumento(identificacao)}`,
        },
        { field: "chave", header: "Chave", align: "center" },
        { field: "escopo", header: "Escopo", align: "center" },
        {
            field: "processado",
            header: "Processado",
            align: "center",
            style: { width: "5%" },
            action: ({ processado }) => (
                <Tag severity={processado ? "success" : "danger"} value={processado ? "SIM" : "NÂO"} />
            ),
        },
        { field: "soma_base", header: "Soma base", money: true, align: "right", style: { width: "8%" } },
        { field: "valor_comissao", header: "Comissão", money: true, align: "right", style: { width: "8%" } },
    ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_VENDAS_EXTRATOSINTETICOCOMISSAO}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    return (
        <R.Wrapper titulo={"de extrato de comissão sintético"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        apuracao__data_inicial__gte: null,
                        apuracao__data_final__lte: null,
                        // empresa: null,
                        apuracao__status: "-1",
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    // {
                    //     label: "Empresa",
                    //     inputId: "empresa",
                    //     inputName: "empresa",
                    //     required: true,
                    //     component: MakoDropdownEmpresas,
                    //     componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                    //     componentProps: {
                    //         getOnChange: onChangeEmpresa,
                    //     },
                    //     fieldSize: 5,
                    // },
                    {
                        label: "Situação",
                        inputId: "apuracao__status",
                        inputName: "apuracao__status",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione uma situação...",
                            options: OPTIONS_SITUACAO,
                            optionValue: "value",
                            optionLabel: "label",
                            showClear: false,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Período de apuração",
                        inputId: "apuracao__data_inicial__gte",
                        inputName: "apuracao__data_inicial__gte",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "apuracao__data_final__lte",
                        inputName: "apuracao__data_final__lte",
                        component: MakoCalendar,
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_VENDAS_EXTRATOSINTETICOCOMISSAO}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
