import React from "react";

import { TabBoletos } from "./tabs";
import { BoletoProvider } from "@/contexts/boletoContext";

export const TabBoletosContext = () => {
    return (
        <BoletoProvider>
            <TabBoletos />
        </BoletoProvider>
    );
};
