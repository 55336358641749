import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { DadosBasicosOrdemCompraForm } from "./formDadosBasicos";
import { ParcelasOrdemCompraForm } from "./formParcelas";
import { ItensOrdemCompraForm } from "./formItens";
import { TransportadoresOrdemCompraForm } from "./formTransportadores";
import { AnotacoesOrdemCompraForm } from "./formAnotacoes";
import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import { BlockUI } from "@/components/BlockUI";
import { parseMoeda } from "@/assets/util/util";
import useOrdemCompra from "@/hooks/useOrdemCompra";

export const TabOrdemCompra = ({ ordemCompraId }) => {
    const history = useHistory();
    const { loadingOC, dadosBasicos, handlePreencherOrdemCompra, permiteAlterar } = useOrdemCompra();

    const TABS = [
        {
            header: "Informações Básicas",
            icon: "pi pi-fw pi-book",
            component: (
                <BlockUI blocked={!permiteAlterar}>
                    <DadosBasicosOrdemCompraForm />
                </BlockUI>
            ),
        },
        {
            header: "Itens",
            icon: "fas pi-fw fa-cubes",
            disabled: !!!dadosBasicos,
            component: <ItensOrdemCompraForm />,
        },
        {
            header: "Transportadores",
            icon: "fas pi-fw fa-truck",
            disabled: !!!dadosBasicos,
            component: <TransportadoresOrdemCompraForm />,
        },
        {
            header: "Parcelas",
            icon: "pi pi-fw pi-money-bill",
            disabled: !!!dadosBasicos,
            component: <ParcelasOrdemCompraForm />,
        },
        {
            header: "Anotações",
            icon: "pi pi-fw pi-pencil",
            component: <AnotacoesOrdemCompraForm />,
        },
    ];

    const voltar = () => {
        history.push("/compras/ordens-compra/ordens-compra");
    };

    const carregarOrdemCompra = useCallback(async () => {
        if (ordemCompraId) {
            await handlePreencherOrdemCompra(ordemCompraId);
        }
    }, [ordemCompraId, handlePreencherOrdemCompra]);

    useEffect(() => {
        carregarOrdemCompra();
    }, [carregarOrdemCompra]);

    const header = dadosBasicos?.id
        ? `: (${dadosBasicos.id}) - ${dadosBasicos.fornecedor.nome} - Total OC: ${parseMoeda(
              dadosBasicos.valor_total_ordem_compra,
              true
          )}`
        : "";

    return (
        <PageBase blocked={loadingOC}>
            <MakoMultiTabs
                title={`Lançamento de ordem de compra${header}`}
                tabs={TABS}
                ocultarButtonFinalizar
                propsButtonProx={{ disabled: !!!dadosBasicos }}
                propsButtonCancelar={{ onClick: voltar }}
            />
        </PageBase>
    );
};
