import React from "react";
import { Button } from "primereact/button";

import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";

export const ListagemModal = ({ itemTransfId, onClickBotaoEditar, listagemRef }) => {
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => {
                        if (typeof onClickBotaoEditar === "function") onClickBotaoEditar(rowData);
                    }}
                />
                <BotaoDelete
                    url="/produtos/lotes-sku-movimentacao/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao="Deseja realmente excluir essa movimentação de lote?"
                    msgToastErroExclusao="O lote não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                    classNames="p-mb-1"
                />
            </div>
        );
    };

    const colunas = [
        { field: "lote.lote_serial", header: "Nº do lote / Série" },
        { field: "lote.fabricacao", header: "Fabricação", dateFormat: "dd/MM/yyyy", style: { width: "10%" } },
        { field: "lote.vencimento", header: "Vencimento", dateFormat: "dd/MM/yyyy", style: { width: "10%" } },
        { field: "quantidade", header: "Quantidade", decimal: true, style: { width: "10%" } },
        { field: "lote.enderecamento", header: "Endereçamento" },
        { field: "lote.complemento", header: "Complemento" },
        {
            field: "action",
            header: "Ações",
            style: { width: "12%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <MakoListagem
            ref={listagemRef}
            colunas={colunas}
            fazerBusca={!!itemTransfId}
            urlPesquisa={`/produtos/lotes-sku-movimentacao/?evento=transferencias.itemtransferenciamercadoria&evento_id=${itemTransfId}`}
            limit={5}
            configTabela={{
                lazy: true,
                paginator: true,
            }}
        />
    );
};
