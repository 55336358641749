import { useCallback } from "react";
import useLocalStorage from "use-local-storage";
import useToast from "./useToast";
import storageKeys from "@/assets/constants/storage";
import useHttp from "./useHttp";

const useNotificacao = () => {
    const { showInfo } = useToast();
    const [storageNotifications, setStorageNotifications] = useLocalStorage(storageKeys.NOTIFICACOES_KEY, 0);
    const { httpGet, httpPut } = useHttp();

    const ouvirNotificacoes = useCallback(
        async (cancelToken) => {
            const handlers = {
                200: ({ data }) => {
                    if (data.results.length > 0) {
                        const qtd = data.results.length;
                        setStorageNotifications((old) => old + qtd);
                        const sulfixoMsg = qtd > 1 ? `${qtd} novas notificações.` : "1 nova notificação.";
                        showInfo({
                            summary: "Info.",
                            detail: `Você tem ${sulfixoMsg}`,
                            life: 2000,
                        });
                    }
                },
            };

            await httpGet({ url: "/pessoas/minhas-notificacoes/", cancelToken }, handlers);
        },
        [showInfo, setStorageNotifications, httpGet]
    );

    const marcarTodasMsgComoLidas = useCallback(async () => {
        const handlers = {
            204: () => {
                setStorageNotifications(0);
            },
        };

        await httpPut({ url: "/pessoas/ler-todas-notificacoes/" }, handlers);
    }, [setStorageNotifications, httpPut]);

    const marcarMsgComoLida = useCallback(() => {
        if (storageNotifications > 0) setStorageNotifications((old) => Math.max(0, old - 1));
    }, [storageNotifications, setStorageNotifications]);

    return { marcarTodasMsgComoLidas, marcarMsgComoLida, ouvirNotificacoes, notifications: storageNotifications };
};

export default useNotificacao;
