import { useCallback, useEffect, useState } from "react";

import useHttp from "@/hooks/useHttp";

const BASE_URL = "/financeiro/taxas-administracao-cartao/";

const useTaxaCartaoContaFinanceira = ({ conta_id = null, bandeira_id = null, numero_parcela = 1 }) => {
    const [taxa, setTaxa] = useState({ porcentagem: 0, valor: 0 });
    const { httpGet } = useHttp();

    const buscar = useCallback(async () => {
        if (conta_id) {
            let _taxa = 0;
            const handlers = {
                200: ({ data }) => {
                    _taxa = data?.results?.at(0)?.taxa || 0;
                },
            };
            await httpGet(
                {
                    url: BASE_URL,
                    params: {
                        conta_bancaria__conta_financeira: conta_id,
                        bandeira: bandeira_id,
                        query: "{id,taxa,conta_bancaria,tipo_cartao}",
                        tipo_cartao: numero_parcela > 1 ? "P" : "R",
                        limit: 1,
                    },
                },
                handlers
            );
            setTaxa({ porcentagem: _taxa, valor: _taxa * 100 });
        }
    }, [conta_id, bandeira_id, numero_parcela, httpGet]);

    useEffect(() => buscar(), [buscar]);

    return taxa;
};

export default useTaxaCartaoContaFinanceira;
