import React from "react";
import { Button } from "primereact/button";
import { parseNumber } from "@/assets/helpers/number";
import { dataToStr } from "@/assets/util/datas";
import { parseMoeda } from "@/assets/util/util";

export const ItemRequisicao = ({ item, onEdit, onDelete }) => {
    return (
        <div className="p-grid p-col-12">
            <div className="p-md-10">
                <p style={{ marginBottom: 0 }}>
                    <span>
                        <b>Produto: </b>
                    </span>
                    {item.sku.codigo} - {item.sku.descricao_derivada}
                </p>
                <p style={{ marginBottom: 0 }}>
                    <span>
                        <b>Quantidade: </b>
                    </span>
                    {`${parseNumber(item.quantidade)} (${item.unidade_medida.sigla})`}
                </p>
                <p style={{ marginBottom: 0 }}>
                    <span>
                        <b>Custo unitário: </b>
                    </span>
                    {parseMoeda(item.custo_unitario, false)}
                </p>
                <p style={{ marginBottom: 0 }}>
                    <span>
                        <b>Custo total: </b>
                    </span>
                    {parseMoeda(item.custo_total, false)}
                </p>
                <p style={{ marginBottom: 0 }}>
                    <span>
                        <b>Data prevista: </b>
                    </span>
                    {dataToStr(item.data_prevista, "dd/MM/yyyy")}
                </p>
                <p style={{ marginBottom: 0 }}>
                    <span>
                        <b>Descrição: </b>
                    </span>
                    {item.descricao}
                </p>
            </div>
            <div className="p-md-2" style={{ display: "flex", justifyContent: "right" }}>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    onClick={onEdit}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    tooltip="Remover"
                    tooltipOptions={{ position: "left" }}
                    onClick={onDelete}
                />
            </div>
        </div>
    );
};
