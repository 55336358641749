import React, { useCallback } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { TIPOS_REFERENCIA_CURVA_CHOICE } from "@/assets/constants/constants";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";

const BASE_URL = "/relatorios/curva-abc/";
const INITIAL_VALUES = {
    data_geracao: null,
    data_inicial: null,
    data_final: null,
    empresa: null,
    referencia: null,
    descricao: null,
    percentual_c: null,
    percentual_a: null,
    percentual_b: null,
};

export const FormFiltroAvancado = ({ onClose }) => {
    const { showError } = useToast();
    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_geracao: Yup.date()
                    .required("O campo 'data geração' é obrigatório.")
                    .typeError("Informe um 'data geração' válido."),
                data_inicial: Yup.date()
                    .required("O campo 'período inicial' é obrigatório.")
                    .typeError("Informe um 'período inicial' válido."),
                data_final: Yup.date()
                    .min(values.data_inicial, "O 'período final' não pode ser menor que o inicial.")
                    .required("O campo 'período final' é obrigatório."),
                empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório.")
                    .typeError("Informe uma 'empresa' válida."),
                referencia: Yup.number().nullable().notRequired().typeError("Seleciona uma 'referência' válida."),
                descricao: Yup.string().nullable().notRequired(),
                percentual_a: Yup.number().nullable().notRequired(),
                percentual_b: Yup.number()
                    .nullable()
                    .notRequired()
                    .min(values.percentual_a, "O 'percentual b' não pode ser menor que o 'percentual a'."),
                percentual_c: Yup.number()
                    .nullable()
                    .notRequired()
                    .min(values.percentual_b, "O 'percentual b' não pode ser menor que o 'percentual b'.")
                    .max(100, "O 'percentual c' não pode ser menor que 100."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let params = [];

            Object.keys(values).forEach((key) => {
                if (values[key] !== null) {
                    if (key === "descricao") return params.push(`${key}=${values[key].id}`);
                    if (key === "data_geracao")
                        return params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd HH:mm:ss")}`);
                    if (key === "data_inicial") return params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd")}`);
                    if (key === "data_final") return params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd")}`);
                    return params.push(`${key}=${values[key]}`);
                }
            });

            if (params.length > 0) {
                const url = BASE_URL + "?" + params.join("&");
                if (typeof onClose === "function") onClose(url, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            } else {
                if (typeof onClose === "function")
                    onClose(BASE_URL, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const handleReset = useCallback(() => {
        resetForm();
    }, [resetForm]);

    const handleCancel = useCallback(() => {
        resetForm();
        onClose(BASE_URL);
    }, [onClose, resetForm]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="empresa">Empresa: *</label>
                    <MakoDropdownEmpresas
                        id="empresa"
                        name="empresa"
                        placeholder="Selecione uma empresa"
                        value={formik.values.empresa}
                        onChange={(e) => setFieldValue("empresa", e.id)}
                        className={classNames({ "p-invalid": formik.errors.empresa })}
                    />
                    {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="data_geracao">Data geração: *</label>
                    <MakoCalendar
                        id="data_geracao"
                        name="data_geracao"
                        valueCalendar={formik.values.data_geracao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_geracao })}
                    />
                    {formik.errors.data_geracao && <small className="p-error">{formik.errors.data_geracao}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="descricao">Descrição *</label>
                    <InputText
                        id="descricao"
                        name="descricao"
                        value={formik.values.descricao}
                        onChange={formik.handleChange}
                        className={classNames({
                            "p-invalid": formik.errors.descricao,
                        })}
                        autoComplete="off"
                        autoFocus
                    />
                    {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="data_inicial">Periodo inicial: *</label>
                    <MakoCalendar
                        id="data_inicial"
                        name="data_inicial"
                        valueCalendar={formik.values.data_inicial}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_inicial })}
                    />
                    {formik.errors.data_inicial && <small className="p-error">{formik.errors.data_inicial}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="data_final">Periodo final: *</label>
                    <MakoCalendar
                        id="data_final"
                        name="data_final"
                        valueCalendar={formik.values.data_final}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_final })}
                    />
                    {formik.errors.data_final && <small className="p-error">{formik.errors.data_final}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="percentual_a">Percentual A</label>
                    <InputNumber
                        id="percentual_a"
                        name="percentual_a"
                        type="decimal"
                        prefix="%"
                        min={0}
                        max={100}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.percentual_a}
                        onValueChange={formik.handleChange}
                    />
                    {formik.errors.percentual_a && <small className="p-error">{formik.errors.percentual_a}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="percentual_b">Percentual B</label>
                    <InputNumber
                        id="percentual_b"
                        name="percentual_b"
                        type="decimal"
                        prefix="%"
                        min={0}
                        max={100}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.percentual_b}
                        onValueChange={formik.handleChange}
                    />
                    {formik.errors.percentual_b && <small className="p-error">{formik.errors.percentual_b}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="percentual_c">Percentual C</label>
                    <InputNumber
                        id="percentual_c"
                        name="percentual_c"
                        type="decimal"
                        prefix="%"
                        min={0}
                        max={100}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.percentual_c}
                        onValueChange={formik.handleChange}
                    />
                    {formik.errors.percentual_c && <small className="p-error">{formik.errors.percentual_c}</small>}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="referencia">Referência: *</label>
                    <Dropdown
                        id="referencia"
                        name="referencia"
                        placeholder="Selecione uma referência para a curva"
                        optionLabel="label"
                        optionValue="value"
                        options={TIPOS_REFERENCIA_CURVA_CHOICE}
                        value={formik.values.referencia}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.referencia && <small className="p-error">{formik.errors.referencia}</small>}
                </div>
            </div>
            <div className="p-grid p-justify-end p-mt-2">
                <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                <Button
                    type="reset"
                    icon="pi pi-trash"
                    label="Limpar"
                    className="p-button-warning p-mr-2"
                    onClick={handleReset}
                />
                <Button type="button" label="Cancelar" className="p-button-danger p-mr-3" onClick={handleCancel} />
            </div>
        </form>
    );
};
