import { useContext } from "react";

import RequisicaoContext from "@/contexts/requisicaoContext";

const useRequisicao = () => {
    const { isEditing, requisicao, PERSISTIR_TYPES_REQUISICAO, fetchRequisicao, updateRequisicao, persistirRequisicao } = useContext(RequisicaoContext);

    return { isEditing, requisicao, PERSISTIR_TYPES_REQUISICAO, fetchRequisicao, updateRequisicao, persistirRequisicao };
};

export default useRequisicao;
