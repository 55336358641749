import React, { useMemo } from "react";

import { PESSOAS_TAGPERFIL_PADRAO_FORNECEDOR } from "@/assets/constants/parametros";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useParam from "@/hooks/useParam";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputFornecedor = ({ disabled, somenteAtivo, value, ...props }) => {
    const { getParam } = useParam();
    const [formatarDocumento] = useFormatCNPJCPF();

    const formatarFornecedor = (fornecedor) => {
        return `${fornecedor.nome} - ${formatarDocumento(fornecedor.identificacao)}`;
    };

    const itemFornecedorTemplate = (item) => {
        return <div>{formatarFornecedor(item)}</div>;
    };

    const fornecedorSelecionadoTemplate = (option) => {
        let endereco = option.enderecoperfil_set?.find((end) => end.principal);
        if (!endereco && option.enderecoperfil_set?.length > 0) endereco = option.enderecoperfil_set[0];
        return formatarFornecedor(option);
    };

    const tagFornecedor = useMemo(() => {
        const tagPerfilFornec = getParam(PESSOAS_TAGPERFIL_PADRAO_FORNECEDOR);
        if (tagPerfilFornec) return tagPerfilFornec.valor;
        return null;
    }, [getParam]);

    const uf = useMemo(() => {
        if (typeof value !== "object") return null;
        let endereco = value?.enderecoperfil_set?.find((end) => end.principal);
        if (!endereco && value?.enderecoperfil_set?.length > 0) endereco = value.enderecoperfil_set[0];
        return endereco?.cidade?.estado?.uf;
    }, [value]);

    const url = useMemo(() => {
        const filtroAtivo = somenteAtivo ? "&ativo=true" : "";
        return `/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,enderecoperfil_set}${filtroAtivo}&tags=${tagFornecedor}&nome__unaccent__icontains=`;
    }, [somenteAtivo, tagFornecedor]);

    return (
        <>
            <div className="p-inputgroup">
                <MakoAutoComplete
                    disabled={!!!tagFornecedor || disabled}
                    minCaracteresBusca={4}
                    itemTemplate={itemFornecedorTemplate}
                    selectedItemTemplate={fornecedorSelecionadoTemplate}
                    field="nome"
                    urlSearch={url}
                    placeholder="Digite o nome do fornecedor para buscar... (min 4 caracteres)"
                    value={value}
                    {...props}
                />
                {uf && <span className="p-inputgroup-addon">{`UF: ${uf}`}</span>}
            </div>
            {!!!tagFornecedor && <small className="p-error">Falta parametrizar a identificação do fornecedor</small>}
        </>
    );
};
