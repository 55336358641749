import React, { createContext, useCallback, useState } from "react";

import useEmpresa from "@/hooks/useEmpresa";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

const DevolucaoContext = createContext({});

export const DevolucaoProvider = ({ children }) => {
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [submit, setSubmit] = useState(false);
    const { user } = useAuth();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet, httpPost } = useHttp();

    const handleDadosBasicos = useCallback(
        async (values) => {
            if (!values.id) {
                let _DATA = null;
                let status = 400;

                const novaDevolucao = {
                    ...values,
                    empresa: empresaSelecionadaId,
                    cliente: values.cliente.id,
                    incluido_por: user?.id,
                    venda: values.venda.id,
                };

                const handlers = {
                    201: ({ data }) => {
                        _DATA = { ...data };
                        status = 201;
                        setDadosBasicos(_DATA);
                        setSubmit(true);
                    },
                };

                await httpPost({ url: "/vendas/devolucao/", body: novaDevolucao }, handlers);
                return { status, data: _DATA };
            }
        },
        [empresaSelecionadaId, user, httpPost]
    );

    const buscarDevolucao = useCallback(
        async (devolucaoId) => {
            const handlers = {
                200: ({ data }) => {
                    setDadosBasicos(data);
                    setSubmit(true);
                },
            };

            await httpGet({ url: `/vendas/devolucao/${devolucaoId}/` }, handlers);
        },
        [httpGet]
    );

    const atualizarTotalDevolucao = useCallback(async () => {
        if (dadosBasicos) {
            const handlers = {
                200: ({ data }) => {
                    setDadosBasicos((old) => ({ ...old, valor_total: data.valor_total }));
                },
            };

            await httpGet({ url: `/vendas/devolucao/${dadosBasicos.id}/?query={valor_total}` }, handlers);
        }
    }, [dadosBasicos, httpGet]);

    return (
        <DevolucaoContext.Provider
            value={{
                dadosBasicos,
                submit,
                handleDadosBasicos,
                buscarDevolucao,
                atualizarTotalDevolucao,
            }}
        >
            {children}
        </DevolucaoContext.Provider>
    );
};

export default DevolucaoContext;
