import React, { useCallback, useRef } from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Button } from "primereact/button";
import { Editar as Modal } from "../../modals";

import useClearRefs from "@/hooks/useClearRefs";

const { OUTROS_PARAMETROS_MANUTENCAO, OUTROS_PARAMETROS_EDITARVALOR } = permissoes;

export const Editar = ({ className = "", onSuccessCallback = () => {}, parametro }) => {
    const modalRef = useRef(null);

    useClearRefs(modalRef);

    const show = useCallback(() => {
        modalRef.current?.show(parametro);
    }, [parametro]);

    return (
        <>
            <MakoControleAcesso
                componente={Button}
                permissao={[OUTROS_PARAMETROS_MANUTENCAO, OUTROS_PARAMETROS_EDITARVALOR]}
                icon={MAKO_ICONS.EDITAR}
                tooltip="Alterar cadastro do parâmetro"
                tooltipOptions={{ position: "left" }}
                className={`p-button-rounded p-button-warning ${className}`}
                onClick={show}
            />
            <Modal ref={modalRef} successCallback={onSuccessCallback} />
        </>
    );
};
