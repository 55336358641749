import React, { useCallback, useRef } from "react";

import { Menu } from "primereact/menu";
import classNames from "classnames";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton } from "@/components/MakoButton";
import { Modals } from "../../modals";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

import useClearRefs from "@/hooks/useClearRefs";
import useAuth from "@/hooks/useAuth";

const {
    PRODUTO_PRODUTO_LOTES_EDITAR,
    PRODUTO_PRODUTO_LOTES_BLOQUEARVENDA,
    PRODUTO_PRODUTO_LOTES_DESBLOQUEARVENDA,
    PRODUTO_PRODUTO_LOTES_AJUSTARSALDO,
} = permissoes;

export const Opcoes = ({ lote, className = "", onSuccessCallback = () => {} }) => {
    const { bloqueado_vender } = lote || {};

    const { verifyPermission } = useAuth();

    const menuRef = useRef(null);
    const modalBloqueioRef = useRef(null);
    const modalAjustarSaldoRef = useRef(null);

    useClearRefs(menuRef, modalBloqueioRef, modalAjustarSaldoRef);

    const showBloqueio = useCallback(() => {
        modalBloqueioRef.current?.show(lote);
    }, [lote]);

    const showAjustarSaldo = useCallback(() => {
        modalAjustarSaldoRef.current?.show(lote);
    }, [lote]);

    const opcoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Bloquear venda",
                    disabled: bloqueado_vender || !verifyPermission([PRODUTO_PRODUTO_LOTES_BLOQUEARVENDA]),
                    command: showBloqueio,
                },
                {
                    label: "Liberar venda",
                    disabled: !bloqueado_vender || !verifyPermission([PRODUTO_PRODUTO_LOTES_DESBLOQUEARVENDA]),
                    command: showBloqueio,
                },
                {
                    label: "Ajustar saldo",
                    disabled: !verifyPermission([PRODUTO_PRODUTO_LOTES_AJUSTARSALDO]),
                    command: showAjustarSaldo,
                },
            ],
        },
    ];

    return (
        <>
            <MakoControleAcesso
                componente={MakoButton}
                permissao={[PRODUTO_PRODUTO_LOTES_EDITAR]}
                tooltip="Opcoes"
                icon={MAKO_ICONS.OPCOES}
                onClick={(e) => {
                    menuRef.current?.toggle(e);
                }}
                className={classNames("p-button p-button-rounded p-component p-button-info", className)}
            />
            <Menu model={opcoes} popup ref={menuRef} id="popup_menu" />
            <Modals.Bloqueio ref={modalBloqueioRef} onSuccessCallback={onSuccessCallback} />
            <Modals.AjustarSaldo ref={modalAjustarSaldoRef} onSuccessCallback={onSuccessCallback} />
        </>
    );
};
