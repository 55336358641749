import React, { useCallback } from "react";

import { SaldoInfo } from "./saldoInfo";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

export const Pendencia = () => {
    const { caixaMov, atualizarResumos } = useCaixaMovimento();

    const updateCallback = useCallback(
        ({ resumos }) => {
            if (resumos) atualizarResumos(resumos);
        },
        [atualizarResumos]
    );

    return (
        <div className="p-fluid p-formgrid p-grid p-pt-2">
            <SaldoInfo caixaMovimento={caixaMov} updateCallback={updateCallback} />
        </div>
    );
};
