import React, { useRef } from "react";
import { Button } from "primereact/button";

import { ModalItensOrdemCompra } from "./ModalItensOrdemCompra";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import { parseMoeda } from "@/assets/util/util";
import useOrdemCompra from "@/hooks/useOrdemCompra";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

export const ItensOrdemCompraForm = () => {
    const listagemRef = useRef(null);
    const modalRef = useRef(null);
    const { dadosBasicos, permiteAlterar, atualizarValoresOC } = useOrdemCompra();

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon={MAKO_ICONS.EDITAR}
                    disabled={!permiteAlterar}
                    className="p-button-rounded p-button-warning"
                    onClick={() => modalRef.current?.abrirModal(rowData)}
                />
                <BotaoDelete
                    url={`/compras/itens-ordens-compra/`}
                    objetoId={rowData.id}
                    disabled={!permiteAlterar}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir o item <b>{rowData.sku.descricao_derivada}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O item não pode ser excluído."
                    classNames="p-mr-2 p-mb-1"
                    onDelete={async () => {
                        listagemRef.current?.buscarDados();
                        await atualizarValoresOC();
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Adicionar item"
                icon="pi pi-plus"
                disabled={!permiteAlterar}
                className="p-button-success"
                onClick={() => modalRef.current?.abrirModal()}
            />
        </>
    );

    const colunas = [
        { field: "sku.codigo", header: "Código", style: { width: "8%" } },
        { field: "sku.descricao_derivada", header: "Item" },
        { field: "quantidade", header: "Qtd.", style: { width: "8%" }, decimal: true },
        { field: "cubagem", header: "Dimensão", style: { width: "8%" }, decimal: true },
        // { field: "quantidade_recebida", header: "Qtd. recebida", style: { width: "8%" } },
        { field: "valor_unitario", header: "Vlr. unitário", money: true, style: { width: "10%" } },
        { field: "unitario_outros_valores", header: "Outros valores", money: true, style: { width: "10%" } },
        { field: "total_NF_item", header: "Total na NF", money: true, style: { width: "10%" } },
        { field: "total_item", header: "Total do item", money: true, style: { width: "10%" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <>
            <ModalItensOrdemCompra
                ref={modalRef}
                ordemCompraId={dadosBasicos?.id}
                onSave={() => listagemRef.current?.buscarDados()}
            />
            <MakoListagem
                ref={listagemRef}
                urlPesquisa={`/compras/itens-ordens-compra/?ordem_compra=${dadosBasicos?.id}`}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                configTabela={{ lazy: true, paginator: true }}
            />
            <div className="p-text-right p-mt-2">
                <h5>{`Total dos itens: ${parseMoeda(dadosBasicos?.valor_total_itens, false)}`}</h5>
            </div>
        </>
    );
};
