import { useCallback, useState } from "react";

const useLoadingLocal = (defaultLoading = false) => {
    const [loading, setLoading] = useState(defaultLoading);
    const showLoading = useCallback(() => {
        setLoading(true);
    }, []);
    const hideLoading = useCallback(() => {
        setLoading(false);
    }, []);
    return [loading, showLoading, hideLoading];
};

export default useLoadingLocal;
