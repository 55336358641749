import React, { useState, useImperativeHandle, forwardRef } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

import { MakoButton as Button } from "../MakoButton";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const Componente = (
    { url, objetoId, exigeConfirmacao, msgConfirmacao, onDelete, msgToastErroExclusao, msgErro400, hideLoad },
    ref
) => {
    const [visible, setVisible] = useState(false);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showWarning, showError } = useToast();
    const { httpDelete } = useHttp();

    const deletar = async () => {
        const handlers = {
            204: () => {
                if (typeof onDelete === "function") onDelete();
                setVisible(false);
            },
            error: ({ status, err }) => {
                if (status === 400) {
                    showWarning({
                        summary: "Alerta!",
                        detail: msgErro400 || "Desculpe, não foi possível efetuar a exclusão.",
                        life: 5000,
                    });
                } else if (
                    typeof err === "string" &&
                    err?.includes("because they are referenced through protected foreign keys:")
                ) {
                    showWarning({
                        summary: "Alerta!",
                        detail: "Esse registro não pode ser excluído pois está vinculado a outra informação do sistema.",
                        life: 5000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: msgToastErroExclusao || "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            },
        };
        const urlExclusao = url.endsWith("/") ? url.slice(0, -1) : url;
        if (!hideLoad) showLoading();
        await httpDelete({ url: `${urlExclusao}/${objetoId}/` }, handlers);
        if (!hideLoad) hideLoading();
    };

    const footer = (
        <>
            <Button label="Cancelar" text disabled={loading} onClick={() => setVisible(false)} />
            <Button label="Deletar" loading={loading} onClick={deletar} icon={null} className="p-button-danger" />
        </>
    );

    const handleClick = () => {
        if (exigeConfirmacao) {
            setVisible(true);
        } else {
            deletar();
        }
    };

    useImperativeHandle(ref, () => ({ handleClick }));

    return (
        <ConfirmDialog
            visible={visible}
            onHide={() => setVisible(false)}
            message={msgConfirmacao}
            header="Confirmação de exclusão"
            icon="pi pi-info-circle"
            accept={deletar}
            acceptLabel="Deletar"
            acceptClassName="p-button-danger"
            rejectLabel="Cancelar"
            footer={footer}
        />
    );
};

export const Delete = forwardRef(Componente);
