import React, { useCallback } from "react";

import { Dropdown } from "@/components/Dropdown";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const InputPerfil = ({ aposBuscar, ...props }) => {
    const [formatarDocumento] = useFormatCNPJCPF();

    const buscar = useCallback(
        async (data = []) => {
            data = data.map((vinculo) => ({
                ...vinculo.perfil,
                label: vinculo.perfil.nome,
            }));
            if (typeof aposBuscar === "function") return aposBuscar(data);
            return data;
        },
        [aposBuscar]
    );

    const empresaOpcaoTemplate = (option) => {
        return <span>{`${option.label} - ${formatarDocumento(option.identificacao)}`}</span>;
    };

    const empresaSelecionadaTemplate = (option, props) => {
        if (option) return empresaOpcaoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    return (
        <Dropdown
            url={"/pessoas/grupos-perfis/"}
            aposBuscar={buscar}
            optionValue="id"
            optionLabel="label"
            filter
            filterBy="label"
            showClear
            valueTemplate={empresaSelecionadaTemplate}
            itemTemplate={empresaOpcaoTemplate}
            placeholder="Selecione um perfil"
            {...props}
        />
    );
};
