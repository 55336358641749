import React from "react";
import classNames from "classnames";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoInputPorcentagem } from "@/components/MakoInputs/MakoInputPorcentagem";
import useBoleto from "@/hooks/useBoleto";

export const BoletoValoresTaxasForm = (props) => {
    const { handleValores } = useBoleto();

    const formik = useFormik({
        initialValues: {
            tarifa_emissao: 0,
            valor: 0,
            percentual_juros: 0,
            percentual_multa: 0,
            multa: 0,
            percentual_descontos: 0,
            maximo_descontos: 0,
            desconto_pos_vencimento: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tarifa_emissao: Yup.number().notRequired().default(0),
                valor: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .min(0.01, "Esse campo não pode ser 0 (ZERO)."),
                percentual_juros: Yup.number().notRequired().default(0),
                percentual_multa: Yup.number().notRequired().default(0),
                multa: Yup.number().notRequired().default(0),
                percentual_descontos: Yup.number().notRequired().default(0),
                maximo_descontos: Yup.number().notRequired().default(0),
                desconto_pos_vencimento: Yup.boolean().notRequired().default(false),
            });
            const dadosValidos = await formSchema.validate(values, { abortEarly: false });
            handleValores(dadosValidos);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="tarifa-emissao">Tarifa de emissão</label>
                    <MakoInputMoeda
                        id="tarifa-emissao"
                        name="tarifa_emissao"
                        valueMoeda={formik.values.tarifa_emissao}
                        onChangeMoeda={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor">Valor</label>
                    <MakoInputMoeda
                        id="valor"
                        name="valor"
                        valueMoeda={formik.values.valor}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor })}
                    />
                    {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="percentual-juros">Percent. de juros</label>
                    <MakoInputPorcentagem
                        id="percentual-juros"
                        name="percentual_juros"
                        value={formik.values.percentual_juros}
                        onValueChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="percentual-multa">Percent. de multa</label>
                    <MakoInputPorcentagem
                        id="percentual-multa"
                        name="percentual_multa"
                        value={formik.values.percentual_multa}
                        onValueChange={formik.handleChange}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="multa">Valor da multa</label>
                    <MakoInputMoeda
                        id="multa"
                        name="multa"
                        valueMoeda={formik.values.multa}
                        onChangeMoeda={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="percentual-descontos">Percent. de descontos</label>
                    <MakoInputPorcentagem
                        id="percentual-descontos"
                        name="percentual_descontos"
                        value={formik.values.percentual_descontos}
                        onValueChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="max-descontos">Máximo de descontos</label>
                    <MakoInputMoeda
                        id="max-descontos"
                        name="maximo_descontos"
                        valueMoeda={formik.values.maximo_descontos}
                        onChangeMoeda={formik.handleChange}
                    />
                </div>
                <div className="p-field-checkbox p-col-12 p-md-3 p-mt-5">
                    <Checkbox
                        inputId="desconto-pos-vencimento"
                        name="desconto_pos_vencimento"
                        checked={formik.values.desconto_pos_vencimento}
                        onChange={formik.handleChange}
                    />
                    <label htmlFor="desconto-pos-vencimento">Desconto pós vencimento?</label>
                </div>
            </div>
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <Button type="submit" icon="pi pi-save" label="Gravar" className="p-mr-2 p-mb-2" />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        className="p-button-warning p-mr-2 p-mb-2"
                        onClick={() => formik.resetForm()}
                    />
                </div>
            </div>
        </form>
    );
};
