import React, { useRef } from "react";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const url = "/produtos/unidades-medida-fc/";

export const ProdutoFatorConversaoPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDAFC_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de unidade de medida"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/produto/unidade-medida-fc/form",
                            state: { unidadeMedidaFc: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDAFC_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o fator de conversão <b>{rowData.id}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar fator de conversão"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O fator de conversão não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDAFC_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/produto/produto/unidade-medida-fc/form")}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" } },
        { field: "unidade.nome", header: "Unidade" },
        { field: "operacao", header: "Operação", style: { width: "10%" } },
        { field: "fator_conversao", header: "Fator de conversão" },
        { field: "unidade_correspondente.nome", header: "Unidade correspondente" },
        { field: "calculo", header: "Cálculo" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <MakoListagem
            ref={listagemRef}
            colunas={colunas}
            painelEsquerdo={cabecalhoTabela}
            urlPesquisa={url}
            configTabela={{
                lazy: true,
                paginator: true,
            }}
        />
    );
};
