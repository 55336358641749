import React, { useMemo } from "react";

import { MakoListagem } from "@/components/MakoListagem";

const booleanBodyTemplate = (value) => {
    if (value) return <span>Sim</span>;
    return <span>Não</span>;
};

const BASE_COLUMNS = [
    {
        field: "numero_parcela",
        header: "N°",
        style: { width: "3%" },
    },
    {
        field: "vencimento",
        header: "Vencimento",
        dateFormat: "dd/MM/yyyy",
        style: { width: "12%" },
    },
    {
        field: "valor",
        header: "Valor",
        money: true,
        style: { width: "12%" },
    },
    { field: "historico_original", header: "Histórico" },
    {
        field: "paga_comissao_recebimento",
        header: "Paga comissão?",
        style: { width: "10%" },
        action: ({ paga_comissao_recebimento }) => booleanBodyTemplate(paga_comissao_recebimento),
    },
    {
        field: "previsao",
        header: "É previsão?",
        style: { width: "10%" },
        action: ({ previsao }) => booleanBodyTemplate(previsao),
    },
];

const BASE_COLUMN_ACTION = {
    field: "actions",
    header: "Ações",
    align: "center",
    style: { width: "8%" },
};

export const Parcelas = ({ parcelas = [], actions }) => {
    const columns = useMemo(() => {
        if (typeof actions !== "function") return BASE_COLUMNS;
        const actionColumn = { ...BASE_COLUMN_ACTION, action: actions };
        return [...BASE_COLUMNS, actionColumn];
    }, [actions]);

    return (
        <>
            <MakoListagem dadosLocal={parcelas} colunas={columns} />
        </>
    );
};
