import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";
import {
    AutoCompleteFiltroTemplate,
    ClienteFiltroTemplate,
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    TimeFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

import { formatDocumento, gerarFiltrosUrl } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";

const rotaEntregaFiltroTemplate = (options) => {
    return (
        <DropdownFiltroTemplate
            dropdownProps={{
                url: "/vendas/rotas-entrega/?limit=300",
                optionValue: "id",
                optionLabel: "descricao",
            }}
            options={options}
        />
    );
};

const cidadeFiltroTemplate = (options) => {
    return (
        <AutoCompleteFiltroTemplate
            url="/pessoas/cidades?limit=20&query={id,nome}&nome__unaccent__icontains="
            field="nome"
            minCaracteresBusca={3}
            placeholder="Digite o nome da cidade para buscar..."
            options={options}
        />
    );
};

const URL_BACKEND = "/vendas/entregas-previsao/?";

const BASE_FILTROS = {
    query: "{id,data_previsao,hora_previsao,venda_id,endereco_entrega,rota_entrega,datahora_entrega}",
    entregue: false,
    item_venda__venda__situacao: "F",
};

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    align: "center",
    style: { width: "10%" },
};

const BASE_COLUMNS = [
    {
        field: "venda_id",
        header: "Orçamento",
        filter: true,
        filterElement: CodigoFiltroTemplate,
        filterField: "item_venda__venda",
        style: { width: "8%" },
    },
    {
        field: "estagio_venda.descricao",
        header: "Estágio de venda",
        style: { width: "12%" },
    },
    {
        field: "cliente",
        header: "Cliente",
        filter: true,
        filterElement: ClienteFiltroTemplate,
        filterField: "item_venda__venda__cliente_id",
        action: ({ cliente }) => `${cliente.nome} - ${formatDocumento(cliente.identificacao)}`,
    },
    {
        field: "rota_entrega.descricao",
        header: "Rota",
        filter: true,
        filterElement: rotaEntregaFiltroTemplate,
        filterField: "rota_entrega",
    },
    {
        field: "endereco_entrega",
        header: "Cidade",
        filter: true,
        filterElement: cidadeFiltroTemplate,
        filterField: "endereco_entrega__cidade_id",
        action: ({ endereco_entrega }) => {
            if (!endereco_entrega) return "";
            return `${endereco_entrega.cidade.estado.nome} - ${endereco_entrega.cidade.nome}`;
        },
    },
    {
        field: "data_previsao",
        header: "Data previsão",
        filter: true,
        filterElement: DateFiltroTemplate,
        style: { width: "10%" },
        action: ({ data_previsao }) => dataToStr(data_previsao, "dd/MM/yyyy"),
    },
    {
        field: "hora_previsao",
        header: "Hora previsão",
        filter: true,
        filterElement: TimeFiltroTemplate,
        style: { width: "10%" },
    },
];

export const Entregas = ({
    actions,
    aposPesquisar,
    listagemProps = {},
    configTabela = {},
    filtros = {},
    listagemRef = null,
}) => {
    const url = useMemo(() => {
        let _url = URL_BACKEND;
        if (typeof filtros === "object") _url = `${_url}&${gerarFiltrosUrl({ ...BASE_FILTROS, ...filtros })}`;
        return _url;
    }, [filtros]);

    const colunas = useMemo(() => {
        if (actions) return [...BASE_COLUMNS, { ...BASE_COLUMN_ACTIONS, action: actions }];
        return BASE_COLUMNS;
    }, [actions]);

    return (
        <MakoListagem
            ref={listagemRef}
            colunas={colunas}
            urlPesquisa={url}
            aposPesquisar={aposPesquisar}
            {...listagemProps}
            configTabela={{
                lazy: true,
                paginator: true,
                ...configTabela,
            }}
        />
    );
};
