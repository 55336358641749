import React, { useCallback, useEffect, useState, memo } from "react";
import { MultiSelect } from "primereact/multiselect";
import useHttp from "@/hooks/useHttp";

const Component = ({
    url = "",
    options,
    aposBuscar,
    setObjects,
    buscar = true,
    disabled = false,
    id = "",
    ...props
}) => {
    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);
    const { httpGet } = useHttp();

    const fetchDados = useCallback(
        async (signal) => {
            if (!url || !buscar) return null;

            const handlers = {
                200: async ({ data }) => {
                    let { results } = data;
                    if (typeof aposBuscar === "function") results = await aposBuscar(results);
                    setDados(results);
                    if (typeof setObjects === "function") setObjects(results);
                },
            };
            setLoading(true);
            await httpGet({ url, signal }, handlers);
            setLoading(false);
        },
        [url, buscar, httpGet, aposBuscar, setObjects]
    );

    useEffect(() => {
        const controller = new AbortController();
        if (options instanceof Array) {
            setDados(options);
        } else {
            fetchDados(controller.signal);
        }

        return () => {
            controller.abort();
        };
    }, [fetchDados, options]);

    return (
        <MultiSelect
            options={dados}
            disabled={loading || disabled}
            placeholder={!loading ? "Selecione" : "Buscando..."}
            selectedItemsLabel="{0} items selecionadas"
            emptyFilterMessage="Nenhum registro encontrado"
            inputId={id}
            {...props}
        />
    );
};

export const MakoMultiSelect = memo(Component);
