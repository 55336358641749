import React from "react";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

export const Novo = ({ onClick = () => {} }) => {
    return (
        <MakoControleAcesso
            permissao={[permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_INCLUIR]}
            componente={Button}
            label="Novo"
            icon={MAKO_ICONS.NOVO}
            className="p-button-success p-mr-2 p-mb-2"
            onClick={onClick}
        />
    );
};
