import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { FiltroAvancadoModalForm } from "./modal/filtroAvancado";
import { useHistory } from "react-router-dom";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { Menu } from "primereact/menu";
import useRelatorio from "@/hooks/useRelatorio";
import { RELATORIO_ESTOQUE_ROMANEIOTRANSFERENCIA } from "@/assets/constants/relatorios";
import {
    PRODUTOS_ESTOQUE_MOVIMENTACAO_TIPOMOVIMENTACAO_DESTINO,
    PRODUTOS_ESTOQUE_MOVIMENTACAO_TIPOMOVIMENTACAO_ORIGEM,
} from "@/assets/constants/parametros";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import useEmpresa from "@/hooks/useEmpresa";
import useParam from "@/hooks/useParam";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

export const TransferenciaMercadoriaPage = () => {
    const [desabilitarTransf, setDesabilitarTransf] = useState(false);
    const [filtroAvancado, setFiltroAvancado] = useState(false);
    const [gerarSaldo, setGerarSaldo] = useState(false);
    const [transferencia, setTransferencia] = useState(null);
    const [processo, setProcesso] = useState("");
    const [tiposTransf, setTiposTransf] = useState([]);
    const menuNovaVendaRef = useRef(null);
    const listagemRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { solicitarRelatorio } = useRelatorio();
    const { showWarning, showSuccess } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const menuAcoesVendaRef = useRef();
    const { getParam } = useParam();
    const { user } = useAuth();
    const { httpGet, httpPost, httpPut } = useHttp();

    const paramOrigem = useMemo(() => {
        const param = getParam(PRODUTOS_ESTOQUE_MOVIMENTACAO_TIPOMOVIMENTACAO_ORIGEM);
        return param?.valor;
    }, [getParam]);

    const paramDestino = useMemo(() => {
        const param = getParam(PRODUTOS_ESTOQUE_MOVIMENTACAO_TIPOMOVIMENTACAO_DESTINO);
        return param?.valor;
    }, [getParam]);

    const handleGerarSaldo = useCallback(async () => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "O saldo foi registrado com sucesso.",
                    life: 7000,
                });
                setGerarSaldo(false);
                listagemRef.current?.buscarDados();
            },
            409: ({ err }) =>
                showWarning({
                    summary: "Aviso!",
                    detail: err.msg,
                    life: 9000,
                }),
        };

        showLoading();
        await httpPost(
            {
                url: `/transferencias/${processo}-saldo-transferencia/${transferencia.id}/`,
                body: {
                    tipo_movimentacao_origem: paramOrigem,
                    tipo_movimentacao_destino: paramDestino,
                    perfil: user?.id,
                },
            },
            handlers
        );
        hideLoading();
    }, [
        transferencia?.id,
        user?.id,
        showWarning,
        httpPost,
        showSuccess,
        showLoading,
        hideLoading,
        paramOrigem,
        paramDestino,
        processo,
    ]);

    const handleSituacao = useCallback(
        async (situacao) => {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "A situação da transferência foi alterada com sucesso.",
                        life: 10000,
                    });
                    listagemRef.current?.buscarDados();
                },
                409: ({ err }) =>
                    showWarning({
                        summary: "Aviso!",
                        detail: err.msg,
                        life: 9000,
                    }),
            };

            await httpPut(
                {
                    url: `/transferencias/alterar-situacao-transferencia/${transferencia.id}/`,
                    body: {
                        status: situacao,
                    },
                },
                handlers
            );
        },
        [showSuccess, showWarning, httpPut, transferencia?.id]
    );

    const itensMenuAcoes = useMemo(() => {
        let processo = [
            {
                icon: "pi pi-check",
                label: "Validar",
                disabled:
                    transferencia?.status?.id !== "P" ||
                    transferencia?.ce_origem?.estoque_empresa?.empresa !== empresaSelecionadaId,
                command: () => handleSituacao("V"),
            },
            {
                icon: "pi pi-times",
                label: "Cancelar",
                disabled:
                    (transferencia?.status?.id !== "P" && transferencia?.status?.id !== "V") ||
                    transferencia?.status?.id === "F" ||
                    transferencia?.ce_origem?.estoque_empresa?.empresa !== empresaSelecionadaId,
                command: () => handleSituacao("C"),
            },
        ];
        let opcoes = [
            {
                label: "Emissão de documentos",
                items: [
                    {
                        icon: "pi pi-book",
                        label: "Romaneio",
                        disabled: transferencia?.status?.id === "P" || transferencia?.status?.id === "C",
                        command: () =>
                            solicitarRelatorio({
                                chave: RELATORIO_ESTOQUE_ROMANEIOTRANSFERENCIA,
                                filtros: { id: transferencia.id },
                            }),
                    },
                ],
            },
        ];
        if (transferencia?.ce_destino?.estoque_empresa?.empresa === empresaSelecionadaId)
            processo.push({
                icon: "pi pi-ticket",
                label: "Receber saldo",
                disabled: transferencia?.status?.id !== "E",
                command: () => {
                    setProcesso("receber");
                    setGerarSaldo(true);
                },
            });
        else if (transferencia?.ce_origem?.estoque_empresa?.empresa === empresaSelecionadaId)
            processo.push({
                icon: "pi pi-truck",
                label: "Enviar saldo",
                disabled: transferencia?.status?.id !== "V",
                command: () => {
                    setProcesso("enviar");
                    setGerarSaldo(true);
                },
            });

        if (transferencia?.ce_origem?.estoque_empresa?.empresa === empresaSelecionadaId)
            processo.push({
                icon: "pi pi-undo",
                label: "Estornar saldo",
                disabled: transferencia?.status?.id !== "F",
                command: () => {
                    setProcesso("estornar");
                    setGerarSaldo(true);
                },
            });

        opcoes.push({
            label: "Opções",
            items: processo,
        });

        return opcoes;
    }, [transferencia, solicitarRelatorio, empresaSelecionadaId, setGerarSaldo, handleSituacao]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_MOVIMENTACOES_TRANSFERENCIAMERCADORIA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData?.ce_origem?.estoque_empresa?.empresa !== empresaSelecionadaId}
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/movimentacoes/transferencia-mercadoria/form",
                            state: {
                                transferencia: rowData.id,
                                tipoTransf: rowData.tipo_transferencia,
                                status: rowData.status.id,
                            },
                        })
                    }
                />
                <Menu model={itensMenuAcoes} popup ref={menuAcoesVendaRef} id="popup_menu_acoes" />
                <MakoControleAcesso
                    componente={Button}
                    permissao={[permissoes.ESTOQUE_MOVIMENTACOES_TRANSFERENCIAMERCADORIA_EDITAR]}
                    icon="pi pi-cog"
                    aria-haspopup
                    aria-controls="popup_menu_acoes"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    disabled={
                        rowData.status === "C" ||
                        (rowData?.ce_destino?.estoque_empresa?.empresa !== empresaSelecionadaId &&
                            rowData?.ce_origem?.estoque_empresa?.empresa !== empresaSelecionadaId)
                    }
                    onClick={(e) => {
                        setTransferencia(rowData);
                        menuAcoesVendaRef.current?.toggle(e);
                    }}
                />
            </div>
        );
    };

    const listarTiposTransf = useCallback(async () => {
        const params = {
            sequencia: true,
            query: "{id,descricao}",
            empresa: empresaSelecionadaId,
        };

        const handlers = {
            200: ({ data }) => {
                if (data.count > 1) {
                    setTiposTransf(
                        data.results.map((transferencia) => ({
                            label: transferencia.descricao,
                            command: () =>
                                history.push({
                                    pathname: "/estoque/movimentacoes/transferencia-mercadoria/form",
                                    state: {
                                        tipoTransf: transferencia,
                                    },
                                }),
                        }))
                    );
                } else if (data.count === 1) {
                    setTiposTransf(data.results[0]);
                } else {
                    setDesabilitarTransf(true);
                    showWarning({
                        summary: "Aviso!",
                        detail: "Antes de realizar a transferência é preciso cadastrar um tipo de transferência.",
                        life: 10000,
                    });
                }
            },
        };

        showLoading();
        await httpGet({ url: "/transferencias/tipo-transferencia/", params }, handlers);
        hideLoading();
    }, [showLoading, hideLoading, history, showWarning, httpGet, empresaSelecionadaId]);

    useEffect(() => {
        listarTiposTransf();
    }, [listarTiposTransf]);

    const itensMenuTransf = [
        {
            label: "Escolha um tipo de transferência para continuar.",
            items: tiposTransf,
        },
    ];

    const painelEsquerdoTabela = (
        <>
            {tiposTransf.length > 1 ? (
                <>
                    <Menu model={itensMenuTransf} popup ref={menuNovaVendaRef} id="popup_menu" />
                    <Button
                        label="Nova"
                        icon="pi pi-plus"
                        className="p-button-success p-mr-2 p-mb-2"
                        aria-controls="popup_menu"
                        aria-haspopup
                        disabled={desabilitarTransf}
                        onClick={(e) => menuNovaVendaRef.current.toggle(e)}
                    />
                </>
            ) : (
                <Button
                    label="Nova"
                    icon="pi pi-plus"
                    className="p-button-success p-mr-2 p-mb-2"
                    disabled={desabilitarTransf}
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/movimentacoes/transferencia-mercadoria/form",
                            state: {
                                tipoTransf: tiposTransf,
                            },
                        })
                    }
                />
            )}
        </>
    );

    const possuiCarga = (carga) => {
        if (carga) return carga;
        return "Não possui";
    };

    const StatusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: "P", label: "Pendente" },
                        { value: "C", label: "Cancelado" },
                        { value: "F", label: "Finalizado" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Transferência",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            style: { minWidth: "120px" },
        },
        {
            field: "carga",
            header: "Carga",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            style: { minWidth: "120px" },
            action: (e) => possuiCarga(e.carga),
        },
        {
            field: "ce_origem.nome",
            header: "Centro de origem",
            filterElement: TextoFiltroTemplate,
            filter: true,
            filterField: "ce_origem__nome",
            style: { minWidth: "220px" },
        },
        {
            field: "ce_destino.nome",
            header: "Centro de destino",
            filterElement: TextoFiltroTemplate,
            filter: true,
            filterField: "ce_destino__nome",
            style: { minWidth: "220px" },
        },
        {
            field: "data_emissao",
            header: "Data emissão",
            filter: true,
            filterElement: DateFiltroTemplate,
            style: { minWidth: "120px" },
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "status.descricao",
            header: "Situação",
            filterElement: StatusFiltroTemplate,
            filterField: "status",
            filter: true,
            style: { minWidth: "120px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "120px" },
        },
    ];

    const rowClass = (transferencia) => {
        return {
            "table-recebimentos-effective": transferencia.status.id === "E",
            "table-recebimentos-pending": transferencia.status.id === "V",
            "table-recebimentos-overdue": transferencia.status.id === "C",
            "mako-table-status-ativo ": transferencia.status.id === "F",
            "mako-table-status-pendente": transferencia.status.id === "R",
        };
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Transferência entre Mercadorias"
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                urlPesquisa="/transferencias/transferencia-mercadoria/"
                filtarPorEmpresa
                naoBuscarSemEmpresa
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    carga: { value: null, matchMode: "equals" },
                    data_emissao: { value: null, matchMode: "equals" },
                    ce_origem__nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    ce_destino__nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    status: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    rowClassName: rowClass,
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                }}
            />
            <Dialog
                header="Filtro avançado"
                visible={filtroAvancado}
                style={{ width: "70vw", display: "block" }}
                onHide={() => setFiltroAvancado(false)}
            >
                <FiltroAvancadoModalForm setFiltroAvancado={setFiltroAvancado} />
            </Dialog>

            <Dialog
                header="Confirmação de processamento"
                visible={gerarSaldo}
                style={{ width: "45vw", display: "block" }}
                onHide={() => {
                    setProcesso("");
                    setGerarSaldo(false);
                }}
            >
                {transferencia && (
                    <label className="p-field p-grid p-justify-center p-mb-0 p-mt-2">
                        Deseja realmente {processo} o saldo da transferência número {transferencia?.id}?
                    </label>
                )}
                <div className="p-field p-grid p-justify-center p-mb-0 p-mt-5">
                    <Button
                        icon="pi pi-save"
                        label={`${processo.charAt(0).toUpperCase() + processo.slice(1)} saldo`}
                        severity="info"
                        disabled={!paramOrigem || !paramDestino}
                        onClick={() => handleGerarSaldo()}
                    />
                    <Button
                        icon="pi pi-times"
                        label="cancelar"
                        severity="danger"
                        className="p-ml-2"
                        onClick={() => setGerarSaldo(false)}
                    />
                </div>
                {!paramOrigem || !paramDestino ? (
                    <label style={{ color: "#FF0000" }}>
                        Os parâmetros de processamento de transferência precisam ser destinos.
                    </label>
                ) : null}
            </Dialog>
        </PageBase>
    );
};
