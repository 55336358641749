import React, { useCallback, useState } from "react";
import { Dialog } from "primereact/dialog";
import { SelectButton } from "primereact/selectbutton";
import { PickList } from "primereact/picklist";
import { useFormik } from "formik";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoButton as Button } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import MakoListagem from "@/components/MakoListagem";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";
import * as Yup from "yup";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const OPTIONS_SIM_NAO_BOOLEAN = SIM_NAO_BOOLEAN.map((item) => ({
    id: item.id,
    label: item.label === "Sim" ? "Apenas produtos fora da tabela atual" : "Todos os produtos",
}));

export const ItemTabelaModalForm = ({
    tabela,
    acrescimo,
    toastRef,
    listagemRef,
    setExibirDialog,
    exibirDialog = false,
    vigencia,
}) => {
    const [produtos, setProdutos] = useState([]);
    const [opcaoExibicao, setOpcaoExibicao] = useState("");
    const [produtosSelecionados, setProdutosSelecionados] = useState([]);
    const [buscarNaoCadastrados, setBuscarNaoCadastrados] = useState(null);
    const [errorsProdutos, setErrosProdutos] = useState({ preco: [], repetidos: [] });
    const [visible, setVisible] = useState(false);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPut, httpGet, httpPost } = useHttp();

    const opcoesPesquisa = ["Categoria", "Pesquisa avançada", "Todos"];

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            categoria_selecionada: null,
            categoria: null,
            meses_encargos: 0,
            isento_encargos: false,
            sku: null,
        },
        onSubmit: handleSubmit,
    });

    async function arredondaValores() {
        let lista = [];
        produtosSelecionados.forEach((item) => {
            lista.push(
                item.precosku_set?.length > 0 ? parseFloat(item.precosku_set[0].preco_base) * (acrescimo / 100 + 1) : 0
            );
        });
        const handlers = {
            400: () => (lista = []),
            500: () => (lista = []),
        };
        await httpPut(
            {
                url: "/vendas/arredondar-precos/",
                body: {
                    precos: lista,
                },
            },
            handlers
        );
        return lista;
    }

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                meses_encargos: Yup.number().required("O campo é obrigatório.").typeError("O campo deve ser informado"),
                isento_encargos: Yup.boolean()
                    .required("O campo é obrigatório.")
                    .typeError("O campo deve ser informado"),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const valorArredondado = await arredondaValores();
            if (valorArredondado.length > 0) {
                const itens = produtosSelecionados.map((item, index) => {
                    return {
                        sku: item.sku?.id || item.id,
                    };
                });
                const handlers = {
                    200: ({ data }) => {
                        toastRef.current.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Alteração gravada com sucesso!",
                            life: 1500,
                        });
                        setProdutosSelecionados([]);
                        listagemRef.current?.buscarDados();
                        if (data.erros_existencia.length > 0 || data.erros_preco.length > 0) {
                            let { erros_existencia = [], erros_preco = [] } = data;
                            erros_existencia = [...new Set(erros_existencia)];
                            erros_preco = [...new Set(erros_preco)];
                            setErrosProdutos((prev) => {
                                prev.preco = [];
                                prev.repetidos = [];
                                erros_preco.forEach((e) =>
                                    prev.preco.push(produtosSelecionados.find(({ id }) => id === e))
                                );
                                erros_existencia.forEach((e) =>
                                    prev.repetidos.push(produtosSelecionados.find(({ id }) => id === e))
                                );
                                return prev;
                            });
                            setVisible(true);
                        } else {
                            setExibirDialog(false);
                        }
                    },
                };
                showLoading();
                await httpPost(
                    {
                        url: `/vendas/incluir-itens-tabela/${tabela}/`,
                        body: {
                            vigencia_preco: vigencia,
                            lista_itens: itens,
                            meses_encargos: values.meses_encargos,
                            isento_encargos: values.isento_encargos,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const selecionarPesquisa = (opcao = "") => {
        setOpcaoExibicao(opcao);
        if (opcao === "Todos") {
            setFieldValue("sku", null);
            listarProdutos();
        } else if (opcao === "Categoria") setProdutos([]);
        else if (opcao === "Pesquisa avançada") setProdutos([]);
        else setProdutosSelecionados([]);
    };

    const listarProdutos = useCallback(
        async (buscarNaoCadastrados = false) => {
            showLoading();
            let resposta;
            const handlers = {
                200: ({ data }) => {
                    resposta = { status: 200, data };
                },
            };
            if (buscarNaoCadastrados === false) {
                await httpGet(
                    { url: "/produtos/sku?query={id,descricao_reduzida,codigo}&limit=1000000&ordering=-codigo" },
                    handlers
                );
            } else {
                await httpGet(
                    {
                        url: "/produtos/sku?query={id,descricao_reduzida,codigo}&limit=1000000&ordering=-codigo&precostabelapreco__sku__isnull=true",
                    },
                    handlers
                );
            }
            hideLoading();
            if (resposta?.status === 200) setProdutos(resposta.data.results);
        },
        [showLoading, hideLoading, httpGet]
    );

    const esconderDialogProdutos = () => {
        setProdutosSelecionados([]);
        setProdutos([]);
        setOpcaoExibicao("");
        setFieldValue("sku", null);
        setExibirDialog(false);
    };

    const insereProduto = () => {
        setProdutosSelecionados([...produtosSelecionados, formik.values.sku]);
        setFieldValue("sku", null);
    };

    const selecionaCategoria = async (categoria) => {
        setFieldValue("categoria", categoria);
        if (categoria) {
            const handlers = {
                200: ({ data }) => {
                    let lista = data.results;
                    lista.forEach((item) => {
                        item.descricao_reduzida = item.sku.descricao_reduzida;
                        item.codigo = item.sku.codigo;
                    });
                    setProdutos(lista);
                },
            };
            showLoading();
            await httpGet({ url: `/produtos/categorias-skus?categoria__id=${categoria}&limit=10000` }, handlers);
            hideLoading();
        } else setProdutos([]);
    };

    const onChangeCategoria = useCallback(
        (e) => {
            setFieldValue("categoria", e);
        },
        [setFieldValue]
    );

    return (
        <>
            <Dialog
                header={"Adicionar produtos a tabela"}
                visible={exibirDialog}
                onHide={() => esconderDialogProdutos()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "65vw" }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-grid p-fluid p-justify-center">
                        <div className="p-col-12 p-md-12">
                            <SelectButton
                                value={opcaoExibicao}
                                options={opcoesPesquisa}
                                onChange={(e) => selecionarPesquisa(e.value)}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        {opcaoExibicao === "Pesquisa avançada" ? (
                            <div className="p-field p-col-12 p-md-12">
                                <MakoBuscaSkuPersonalizada
                                    skuValue={formik.values.sku}
                                    skuChange={(e) => setFieldValue("sku", e)}
                                    skuError={formik.errors.sku}
                                />
                            </div>
                        ) : null}

                        {opcaoExibicao === "Categoria" ? (
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="categoria">Selecione uma categoria *</label>
                                <MakoDropdownCategoriasHierarquicas
                                    id="categoria"
                                    name="categoria"
                                    value={formik.values.categoria_selecionada}
                                    getCategoriaCompleta={onChangeCategoria}
                                    showClear
                                    onChange={(e) => {
                                        setFieldValue("categoria_selecionada", e.value);
                                        selecionaCategoria(e.value);
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                    {opcaoExibicao === "Pesquisa avançada" ? (
                        <div className="p-grid p-justify-end p-mb-3 p-mr-2">
                            <Button
                                type="button"
                                icon={MAKO_ICONS.NOVO}
                                label="Incluir"
                                className="p-button-success"
                                disabled={!formik.values.sku?.id}
                                onClick={() => insereProduto()}
                                loading={loading}
                            />
                        </div>
                    ) : null}

                    {opcaoExibicao === "Todos" ? (
                        <Dropdown
                            options={OPTIONS_SIM_NAO_BOOLEAN}
                            placeholder="Critério para buscar todos"
                            value={buscarNaoCadastrados}
                            onChange={(e) => {
                                setBuscarNaoCadastrados(e.target.value);
                                listarProdutos(e.target.value);
                            }}
                            optionLabel="label"
                            optionValue="id"
                            className="p-mb-3"
                            style={{ width: "100%" }}
                        />
                    ) : null}
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <PickList
                                source={produtos}
                                target={produtosSelecionados}
                                sourceHeader="Produtos"
                                targetHeader="Selecionados"
                                showSourceControls={false}
                                showTargetControls={false}
                                itemTemplate={(item) => (
                                    <div>
                                        {item.codigo} - {item.descricao_reduzida}
                                    </div>
                                )}
                                onChange={(e) => {
                                    setProdutos(e.source);
                                    setProdutosSelecionados(e.target);
                                }}
                                sourceStyle={{ height: "200px" }}
                                targetStyle={{ height: "200px" }}
                            ></PickList>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="meses_encargos">Meses de encargos incluídos</label>
                            <InputNumber
                                id="meses_encargos"
                                name="meses_encargos"
                                min={0}
                                minFractionDigits={0}
                                maxFractionDigits={0}
                                value={formik.values.meses_encargos}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.meses_encargos })}
                            />
                            {formik.errors.meses_encargos && (
                                <small className="p-error">{formik.errors.meses_encargos}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="isento_encargos">Isento de encargos</label>
                            <SelectButton
                                id="isento_encargos"
                                name="isento_encargos"
                                options={SIM_NAO_BOOLEAN}
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.isento_encargos}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.isento_encargos })}
                            />
                            {formik.errors.isento_encargos && (
                                <small className="p-error">{formik.errors.isento_encargos}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Button
                                type="submit"
                                label="Adicionar produtos"
                                className="p-mt-5"
                                disabled={produtosSelecionados.length > 0 ? false : true}
                                loadin={loading}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
            <Dialog
                header={"Erros ao inserir produtos"}
                visible={visible}
                onHide={() => setVisible(false)}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "60vw" }}
            >
                <>
                    {errorsProdutos.preco.length > 0 && (
                        <div className="p-my-1">
                            <MakoListagem
                                titulo={"Produtos com preço não encontrado"}
                                colunas={[
                                    { field: "codigo", header: "Codigo", style: { width: "8%" } },
                                    { field: "descricao_reduzida", header: "Descrição" },
                                ]}
                                dadosLocal={errorsProdutos.preco}
                            />
                        </div>
                    )}
                    {errorsProdutos.repetidos.length > 0 && (
                        <div className="p-my-1">
                            <MakoListagem
                                titulo={"Produtos já existentes na tabela"}
                                colunas={[
                                    { field: "codigo", header: "Codigo", style: { width: "8%" } },
                                    { field: "descricao_reduzida", header: "Descrição" },
                                ]}
                                dadosLocal={errorsProdutos.repetidos}
                            />
                        </div>
                    )}
                </>
            </Dialog>
        </>
    );
};
