import React from "react";
export const IconBoleto = ({ color = "black", ...props }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0.833252 15.8337V4.16699H2.49992V15.8337H0.833252ZM3.33325 15.8337V4.16699H4.99992V15.8337H3.33325ZM5.83325 15.8337V4.16699H6.66659V15.8337H5.83325ZM8.33325 15.8337V4.16699H9.99992V15.8337H8.33325ZM10.8333 15.8337V4.16699H13.3333V15.8337H10.8333ZM14.1666 15.8337V4.16699H14.9999V15.8337H14.1666ZM16.6666 15.8337V4.16699H19.1666V15.8337H16.6666Z"
                fill={color}
            />
        </svg>
    );
};
