import React from "react";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";

import { ACTIONS_OPERAR_MODAIS_CAIXA } from "../../../reducers/operar-modais-caixa";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

const { FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR, FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR_TERCEIRO } = permissoes;

export const Desbloquear = ({ className = "", dispatcher = () => {} }) => {
    const { caixaMov } = useCaixaMovimento();

    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[
                FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR,
                FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR_TERCEIRO,
            ]}
            label="Desbloqueio"
            className={className}
            onClick={() => {
                dispatcher({
                    type: ACTIONS_OPERAR_MODAIS_CAIXA.DESBLOQUEAR,
                    payload: {
                        nome: caixaMov.caixa.nome,
                    },
                    reloadCaixa: true,
                });
            }}
            disabled={!caixaMov?.bloqueado}
        />
    );
};
