import React from "react";
import { Skeleton as SkeletonPR } from "primereact";

export const Skeleton = () => {
    return (
        <>
            <div className="p-d-flex p-mb-3">
                <SkeletonPR size="3rem" className="p-mr-2" />
                <SkeletonPR width="10rem" height="2rem" />
            </div>
            <div className="p-d-flex p-justify-around p-mt-3">
                <div>
                    <SkeletonPR width="5rem" height="2rem" />
                    <SkeletonPR width="5rem" height="1rem" className="p-mt-2" />
                </div>
                <div>
                    <SkeletonPR width="5rem" height="2rem" />
                    <SkeletonPR width="5rem" height="1rem" className="p-mt-2" />
                </div>
            </div>
        </>
    );
};
