import React, { useRef } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoStatus } from "@/components/BotaoStatus";
import permissoes from "@/assets/constants/permissoes";
import useToast from "@/hooks/useToast";
import { ModalInsereNotaAssistencia } from "./modalInsereNota";
import useHttp from "@/hooks/useHttp";

export const ListagemModal = ({ assistenciaId, onClickBotaoEditar, listagemRef }) => {
    const insereRef = useRef();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpPut } = useHttp();

    const comMovimentacaoBodyTemplate = (rowData) => {
        if (rowData.tipo_ocorrencia.com_movimentacao) return <span>Sim</span>;
        return <span>Não</span>;
    };

    async function desativarOcorrencia(rowData) {
        try {
            const handlers = {
                204: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Ocorrência desativa com sucesso.",
                        life: 1500,
                    });
                    listagemRef.current?.buscarDados();
                },
                400: ({ err }) =>
                    showWarning({
                        summary: "Opsss",
                        detail: err.msg,
                        life: 3000,
                    }),
                409: ({ err }) =>
                    showWarning({
                        summary: "Opsss",
                        detail: err.msg,
                        life: 3000,
                    }),
            };

            await httpPut({ url: `/vendas/ocorrencias-assistencia-tecnica/${rowData.id}/cancelar/` }, handlers);
        } catch (error) {
            showError({
                summary: "Erro :(",
                detail: `Não foi possível submeter a requisição. MOTIVO: ${error}`,
                life: 3000,
            });
        }
    }

    const confirmarDesativacao = (rowData) => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma o cancelamento da ocorrência nº <strong>{rowData.id}</strong>?
                </p>
            ),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Confirmar",
            rejectLabel: "Fechar",
            accept: () => desativarOcorrencia(rowData),
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_ASSISTENCIATECNICA_LANCAROCORRENCIA]}
                    componente={Button}
                    icon="pi pi-pencil"
                    disabled={!!rowData.excluida_em}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => {
                        if (typeof onClickBotaoEditar === "function") onClickBotaoEditar(rowData);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_ASSISTENCIATECNICA_LANCAROCORRENCIA]}
                    componente={Button}
                    disabled={!!!rowData.tipo_ocorrencia?.emitir_nota}
                    icon="pi pi-qrcode"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    onClick={() => insereRef.current?.abrirModal(rowData.id)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_ASSISTENCIATECNICA_LANCAROCORRENCIA]}
                    componente={BotaoStatus}
                    rounded
                    status={!rowData.excluida_em}
                    disabled={!!rowData.excluida_em}
                    className="p-button-rounded p-mr-2 p-mb-1"
                    onClick={() => confirmarDesativacao(rowData)}
                />
            </div>
        );
    };

    const colunas = [
        { field: "id", header: "Ocorrência", style: { width: "8%" } },
        {
            field: "datahora_ocorrencia",
            header: "Data / hora",
            dateFormat: "dd/MM/yyyy HH:mm",
            style: { width: "10%" },
        },
        { field: "tipo_ocorrencia.descricao", header: "Tipo de ocorrência", style: { width: "20%" } },
        { field: "resumo", header: "Resumo" },
        {
            field: "tipo_ocorrencia.com_movimentacao",
            header: "Mov.",
            style: { width: "8%" },
            action: comMovimentacaoBodyTemplate,
        },
        { field: "nota_fiscal", header: "Nº nota fiscal", style: { width: "10%" } },
        {
            field: "action",
            header: "Ações",
            style: { width: "12%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const rowClass = (rowData) => {
        return {
            "table-recebimentos-overdue": !!rowData.excluida_em,
        };
    };

    return (
        <>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                fazerBusca={!!assistenciaId}
                urlPesquisa={`/vendas/ocorrencias-assistencia-tecnica/?assistencia=${assistenciaId}`}
                limit={5}
                configTabela={{
                    lazy: true,
                    pagination: true,
                    rowClassName: rowClass,
                }}
            />
            <ConfirmDialog />
            <ModalInsereNotaAssistencia ref={insereRef} />
        </>
    );
};
