import React from 'react';

import MakoListagem from "@/components/MakoListagem";
import { Dropdown } from 'primereact/dropdown';

const MakoCardListagemTabela = ({ title, subtitle, dropdownProps, tableProps }) => {
    return (
        <div className="card">
            <div className="card-header">
                <h4>{title}</h4>
                <Dropdown {...dropdownProps}></Dropdown>
            </div>

            <p>{subtitle}</p>

            <MakoListagem {...tableProps} />
        </div>
    )
}

export default React.memo(MakoCardListagemTabela);