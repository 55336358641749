import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { InputText } from "primereact/inputtext";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { formatarCasasDecimais, parseNumberToMoneyHTML } from "@/assets/util/util";
import {
    TIPO_DESTAQUE_PRODUTOS_PERIGOSOS_CHOICE,
    TIPO_INDICADOR_EXIGIBILIDADE_ISS_CHOICE,
    TIPO_INDICA_ITEM_TOTAL_CHOICE,
    TIPO_ITEM_NF_CHOICE,
    TIPO_MODALIDADE_BASE_CALCULO_ICMS_CHOICE,
    TIPO_MODALIDADE_BASE_CALCULO_ST_CHOICE,
    TIPO_MOTIVO_DESONERACAO_ICMS_CHOICE,
    TIPO_ORIGEM_NF_CHOICE,
    TIPO_SIM_NAO_NUMERICO_CHOICE,
} from "@/assets/constants/constants";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useNotaFiscal from "@/hooks/useNotaFiscal";
import classNames from "classnames";
import * as Yup from "yup";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import * as pd from "@/assets/util/persistenciaDjango";
import useEmpresa from "@/hooks/useEmpresa";
import { BotaoDelete } from "@/components/BotaoDelete";
import { TabPanel, TabView } from "primereact";
import useHttp from "@/hooks/useHttp";

export const ProdutosForm = () => {
    const [listaCfops, setListaCfops] = useState([]);
    const [dialog, setDialog] = useState(false);
    const { handleItem, dadosBasicos, handlePreencherNotaFiscal } = useNotaFiscal();
    const { empresaSelecionadaId } = useEmpresa();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);
    const { showWarning, showError, showSuccess } = useToast();
    const { httpGet } = useHttp();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: null,
            operacao_fiscal: null,
            item: null,
            nota_fiscal: null,
            ncm: null,
            codigo_item: null,
            codigo_barras: "",
            ncm_descricao: "",
            cest: null,
            cest_descricao: "",
            cfop: null,
            quantidade: 1,
            unidade_comercial: "",
            valor_unitario: 0,
            valor_total: 0,
            origem: 0,
            valor_frete: 0,
            valor_seguro: 0,
            outras_despesas: 0,
            indica_total: 1,
            tipo_item: 0,
            destaque_perigoso: 0,
            fci: "",
            pedido: "",
            beneficio: "",
            modalidade_bc: 0,
            cst_icms: null,
            base_icms: 0,
            aliquota_icms: 0,
            valor_icms: 0,
            modalidade_bcst: 0,
            margem_icms: 0,
            reducao_bcst: 0,
            base_st: 0,
            base_st_retido: 0,
            aliquota_icmsst: 0,
            icms_st: 0,
            icms_st_retido: 0,
            reducao_bc: 0,
            motivo_deso: null,
            icms_deso: 0,
            icms_op: 0,
            diferimento: 0,
            icms_dif: 0,
            base_op: 0,
            base_st_destino: 0,
            icms_st_destino: 0,
            aliq_credito: 0,
            credito_icms: 0,
            percentual_fcp: 0,
            valor_fcp: 0,
            base_fcp: 0,
            base_fcp_st: 0,
            fcp_retido_st: 0,
            icms_substituto: 0,
            base_fcp_retido: 0,
            perc_fcpst_retido: 0,
            valor_fcpst_retido: 0,
            reducao_bc_efetiva: 0,
            base_efetiva: 0,
            perc_icms_efetivo: 0,
            icms_efetivo: 0,
            cst_ipi: null,
            enquadramento_ipi: null,
            selo_controle: 0,
            base_ipi: 0,
            aliquota_ipi: 0,
            valor_ipi: 0,
            valor_un_tributavel: 0,
            qtd_un_tributavel: 0,
            base_ii: 0,
            despesas_aduaneiras: 0,
            valor_ii: 0,
            valor_iof: 0,
            cst_pis: null,
            base_pis: 0,
            aliquota_pis: 0,
            qtd_vendida_pis: 0,
            valor_aliq_pis: 0,
            valor_pis: 0,
            base_pisst: 0,
            aliquota_pisst: 0,
            qtd_vendida_pisst: 0,
            valor_aliq_pisst: 0,
            valor_pisst: 0,
            cst_cofins: null,
            base_cofins: 0,
            aliquota_cofins: 0,
            valor_aliq_cofins: 0,
            valor_cofins: 0,
            qtd_vendida_cofins: 0,
            base_issqn: 0,
            aliquota_cofinsst: 0,
            qtd_vendida_cofinsst: 0,
            valor_aliq_cofinsst: 0,
            valor_cofinsst: 0,
            aliquota_issqn: 0,
            valor_issqn: 0,
            municipio_issqn: dadosBasicos.municipio_ocorrencia,
            valor_deducao: 0,
            outras_retencoes: 0,
            desc_incondicionado: 0,
            desc_condicionado: 0,
            valor_fcp_st: 0,
            iss_retido: 2,
            retencao_iss: 0,
            indicador_iss: 2,
            valor_desconto: 0,
            municipio_incidencia: dadosBasicos.municipio_ocorrencia,
            incentivo_fiscal: 2,
            base_cofinsst: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                operacao_fiscal: Yup.number().required("O campo 'operação fiscal' é obrigatório!"),
                quantidade: Yup.number().required("O campo 'quantidade' é obrigatório!"),
                unidade_comercial: Yup.string().required("O campo 'unidade comercial' é obrigatório!"),
                municipio_incidencia: Yup.object().required("O campo 'município de incidência' é obrigatório!"),
                valor_unitario: Yup.number().required("O campo 'valor unitário' é obrigatório!"),
                valor_total: Yup.number().required("O campo 'valor total' é obrigatório!"),
                valor_desconto: Yup.number().required("O campo 'valor de desconto' é obrigatório!"),
                valor_frete: Yup.number().required("O campo 'valor do frete' é obrigatório!"),
                outras_despesas: Yup.number().required("O campo 'outras despesas' é obrigatório!"),
                cst_icms: Yup.number().required("O campo 'CST Icms' é obrigatório!"),
                cfop: Yup.number().required("O campo 'cfop' é obrigatório!"),
                indica_total: Yup.number().required("O campo é obrigatório!"),
                tipo_item: Yup.number().required("O campo 'tipo do item' é obrigatório!"),
                modalidade_bc: Yup.number().required("O campo é obrigatório!"),
                modalidade_bcst: Yup.number().required("O campo é obrigatório!"),
                destaque_perigoso: Yup.number().required("O campo é obrigatório!"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status, data } = await handleItem(
                {
                    ...values,
                    valor_icms: formatarCasasDecimais(values.valor_icms),
                    valor_cofins: formatarCasasDecimais(values.valor_cofins),
                    valor_ipi: formatarCasasDecimais(values.valor_ipi),
                    valor_pis: formatarCasasDecimais(values.valor_pis),
                    valor_pisst: formatarCasasDecimais(values.valor_pisst),
                    valor_fcp: formatarCasasDecimais(values.valor_fcp),
                },
                values.id ? pd.OP_CRUD_DJANGO.editar : pd.OP_CRUD_DJANGO.novo
            );

            if (status === 200 || status === 201) {
                await handlePreencherNotaFiscal(data.nota_fiscal);
                if (values.id) {
                    esconderDialog();
                } else
                    showSuccess({
                        summary: "Sucesso",
                        detail: "O produto foi inserido na nota fiscal.",
                        life: 4000,
                    });
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const editarNF = async (nota) => {
        const { ncm, cest, cfop, cst_cofins, cst_icms, cst_ipi, cst_pis, enquadramento_ipi, operacao_fiscal, ...nf } =
            nota;
        setValues({
            ...nf,
            operacao_fiscal: operacao_fiscal.id,
            ncm: ncm.id,
            ncm_descricao: `${ncm.codigo} - ${ncm.descricao}`,
            cest: cest?.id || null,
            cest_descricao: cest ? `${cest?.codigo} - ${cest?.descricao}` : null,
            cfop: cfop.id,
            cst_cofins: cst_cofins?.id || null,
            cst_icms: cst_icms.id,
            cst_ipi: cst_ipi?.id || null,
            cst_pis: cst_pis?.id || null,
            enquadramento_ipi: enquadramento_ipi?.id || null,
        });
        setDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() => editarNF(rowData)}
                    tooltip="Alterar"
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EDITAR]}
                    componente={BotaoDelete}
                    url={"/fiscal/itens-notas-fiscais/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente deletar o item da nota?</span>}
                    msgToastErroExclusao="O item não pode ser deletado."
                    onDelete={async () => {
                        listagemRef.current?.buscarDados();
                        await handlePreencherNotaFiscal(rowData.nota_fiscal);
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Adicionar item"
                icon="pi pi-plus"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={(e) => setDialog(true)}
            />
        </>
    );

    const colunas = [
        { field: "codigo_item", header: "Código", style: { minWidth: "50px" } },
        { field: "descricao_reduzida", header: "Descrição reduzida", style: { minWidth: "250px" } },
        { field: "quantidade", header: "Qtd.", style: { width: "150px" } },
        {
            field: "valor_unitario",
            header: "Vlr. unid.",
            style: { minWidth: "50px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_unitario),
        },
        {
            field: "valor_total",
            header: "Total item",
            style: { minWidth: "50px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_total),
        },
        {
            field: "valor_frete",
            header: "Valor frete",
            style: { minWidth: "80px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_frete),
        },
        {
            field: "valor_seguro",
            header: "Valor seguro",
            style: { minWidth: "100px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_seguro),
        },
        {
            field: "valor_desconto",
            header: "Valor desconto",
            style: { minWidth: "110px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_desconto),
        },
        {
            field: "outras_despesas",
            header: "Outras despesas",
            style: { minWidth: "120px" },
            action: (e) => parseNumberToMoneyHTML(e.outras_despesas),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "90px" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    const buscarInformacoesTributarias = useCallback(
        async (recarrega = false) => {
            if (formik.values.item instanceof Object && formik.values.operacao_fiscal) {
                const { item, precosku_set = [], ...sku } = formik.values.item;

                const handlers = {
                    200: ({ data }) => {
                        const { cfops_permitidos, cst_icms, cst_pis, cst_cofins, ...infoTributaria } = data.results;
                        setListaCfops(
                            cfops_permitidos.map((e) => {
                                return { ...e, label: `${e.codigo} - ${e.descricao}` };
                            })
                        );
                        if (!formik.values?.id || recarrega) {
                            const total_itens = formatarCasasDecimais(
                                formik.values.quantidade * formik.values.valor_unitario
                            );
                            const total =
                                total_itens +
                                parseFloat(formik.values.valor_frete) +
                                parseFloat(formik.values.valor_seguro) +
                                parseFloat(formik.values.outras_despesas) -
                                parseFloat(formik.values.valor_desconto);
                            if (infoTributaria) {
                                setFieldValue("cst_icms", cst_icms?.id || null);
                                if (cst_icms.destaca_icms_saida) {
                                    setFieldValue("aliquota_icms", infoTributaria.aliquota_icms_origem || 0);
                                    setFieldValue("base_icms", total);
                                }
                                setFieldValue("reducao_base_icms", infoTributaria.reducao_base_icms || 0);
                                setFieldValue("tributado_por_st", infoTributaria.tributado_por_st || 0);
                                setFieldValue(
                                    "modalidade_contribuinte",
                                    infoTributaria.modalidade_contribuinte || null
                                );
                                if (cst_icms.destaca_icmsst_saida) {
                                    setFieldValue("aliquota_icmsst", infoTributaria.aliquota_icmsst || 0);
                                    setFieldValue("base_st", total);
                                }
                                setFieldValue("diferimento_icms", infoTributaria.diferimento_icms || 0);
                                setFieldValue("reducao_base_st", infoTributaria.reducao_base_st || 0);
                                setFieldValue("mva", infoTributaria.aliquota_icms_origem || null);
                                setFieldValue("cst_ipi", infoTributaria.cst_ipi?.id || null);
                                setFieldValue("aliquota_ipi", infoTributaria.aliquota_ipi || 0);
                                setFieldValue("reducao_base_ipi", infoTributaria.reducao_base_ipi || 0);
                                setFieldValue("cst_pis", cst_pis?.id || null);
                                if (cst_pis?.destaca_nf) {
                                    setFieldValue("aliquota_pis", infoTributaria.aliquota_pis || 0);
                                    setFieldValue("base_pis", total);
                                }
                                setFieldValue("reducao_base_pis", infoTributaria.reducao_base_pis || 0);
                                setFieldValue("cst_cofins", cst_cofins?.id || null);
                                if (cst_cofins?.destaca_nf) {
                                    setFieldValue("aliquota_cofins", infoTributaria.aliquota_cofins || 0);
                                    setFieldValue("base_cofins", total);
                                }
                                setFieldValue("reducao_base_cofins", infoTributaria.reducao_base_cofins || 0);
                                setFieldValue("aliquota_fcp_origem", infoTributaria.aliquota_fcp_origem || 0);
                                setFieldValue("aliquota_fcp_destino", infoTributaria.aliquota_fcp_destino || 0);
                                setFieldValue("uf_origem", infoTributaria.uf_origem || null);
                                setFieldValue("uf_destino", infoTributaria.uf_destino || null);
                                setFieldValue("enquadramento_ipi", infoTributaria.enquadramento_ipi?.id || null);
                                if (cfops_permitidos?.length > 0) {
                                    setFieldValue("cfop", cfops_permitidos[0].id);
                                }
                            }
                        }
                    },
                    400: ({ data }) =>
                        showWarning({
                            summary: "Falha",
                            detail: data.msg,
                            life: 4000,
                        }),
                    404: ({ data }) =>
                        showWarning({
                            summary: "Falha",
                            detail: data.msg,
                            life: 4000,
                        }),
                    500: () =>
                        showError({
                            summary: "Erro",
                            detail: "Desculpe, não conseguimos listar as informações tributárias do produto.",
                            life: 3000,
                        }),
                };

                await httpGet(
                    { url: `/fiscal/buscar-informacoes-tributarias-sku/${sku.id}/${formik.values.operacao_fiscal}/` },
                    handlers
                );

                if (!formik.values?.id || recarrega) {
                    setFieldValue("unidade_comercial", sku.unidade_padrao.sigla);
                    setFieldValue("ncm", item.ncm.id);
                    setFieldValue("ncm_descricao", `${item.ncm.codigo} - ${item.ncm.descricao}`);
                    if (item.cest) {
                        setFieldValue("cest", item.cest.id);
                        setFieldValue("cest_descricao", `${item.cest.codigo} - ${item.cest.descricao}`);
                    }

                    if (precosku_set?.length > 0)
                        setFieldValue("valor_unitario", parseFloat(precosku_set[0].preco_base));

                    setFieldValue("origem", sku.origem);
                }
            }
        },
        [formik.values.item, formik.values.id, formik.values.operacao_fiscal, setFieldValue, showWarning, showError]
    );

    useEffect(() => {
        const controller = new AbortController();
        buscarInformacoesTributarias(false);
        return function clear() {
            controller.abort();
        };
    }, [buscarInformacoesTributarias]);

    const autoCompleteCidadeTemplate = (valor) => {
        return `${valor.codigo} - ${valor.nome}`;
    };

    const calcularTotal = useCallback(async () => {
        if (formik.values.quantidade > 0 && formik.values.valor_unitario > 0) {
            const total_itens = formatarCasasDecimais(
                formik.values.quantidade * formik.values.valor_unitario - formik.values.valor_desconto
            );
            setFieldValue("valor_total", total_itens);
        }
    }, [formik.values.valor_unitario, formik.values.quantidade, formik.values.valor_desconto, setFieldValue]);

    useEffect(() => {
        calcularTotal();
    }, [calcularTotal]);

    const calcularTributos = useCallback(() => {
        setFieldValue("valor_icms", formik.values.base_icms * (formik.values.aliquota_icms / 100));
        setFieldValue("icms_st", formik.values.base_st * (formik.values.aliquota_icmsst / 100));
        setFieldValue("valor_cofins", formik.values.base_cofins * (formik.values.aliquota_cofins / 100));
        setFieldValue("valor_cofinsst", formik.values.base_cofinsst * (formik.values.aliquota_cofinsst / 100));
        setFieldValue("valor_fcp", formik.values.base_fcp * (formik.values.aliquota_fcp_destino / 100));
        setFieldValue("valor_pisst", formik.values.base_pisst * (formik.values.aliquota_pisst / 100));
        setFieldValue("valor_pis", formik.values.base_pis * (formik.values.aliquota_pis / 100));
    }, [
        formik.values.base_icms,
        formik.values.aliquota_icms,
        formik.values.base_cofins,
        formik.values.base_cofinsst,
        formik.values.base_fcp,
        formik.values.aliquota_cofins,
        formik.values.aliquota_cofinsst,
        formik.values.aliquota_fcp_destino,
        formik.values.base_pisst,
        formik.values.aliquota_pisst,
        formik.values.base_pis,
        formik.values.aliquota_pis,
        formik.values.base_st,
        formik.values.aliquota_icmsst,
        setFieldValue,
    ]);

    const cstTemplate = (cst) => {
        if (cst) return `${cst.codigo} - ${cst.descricao}`;
        return "-";
    };

    const esconderDialog = () => {
        setDialog(false);
        resetForm();
    };

    useEffect(() => {
        calcularTributos();
    }, [calcularTributos]);

    const ipiTemplate = (ipi) => {
        return `${ipi.codigo} - ${ipi.descricao}`;
    };

    const ipiValueTemplate = (ipi) => {
        if (ipi) return `${ipi.codigo} - ${ipi.descricao}`;
    };

    return (
        <div>
            <Toast ref={toastRef} />
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/fiscal/itens-notas-fiscais?nota_fiscal=${dadosBasicos.id}&limit=100`}
                configTabela={{
                    scrollable: true,
                    frozenWidth: "0.5vw",
                }}
            />
            <Dialog
                header={
                    formik.values?.id
                        ? `${formik.values.codigo_item} - ${formik.values.descricao_reduzida}`
                        : "Adicionar item"
                }
                visible={dialog}
                breakpoints={{ "1080px": "90vw" }}
                style={{ width: "90vw" }}
                onHide={() => {
                    resetForm();
                    setDialog(false);
                }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <label className="html">Operação fiscal * </label>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <Dropdown
                                id="operacao_fiscal"
                                name="operacao_fiscal"
                                placeholder="Selecione uma operação fiscal"
                                url={`/fiscal/operacoes-fiscais?empresa=${empresaSelecionadaId}&tipo_movimento=S&limit=1000&ativo=true&query={id,empresa,descricao,tipo_movimento,esfera}`}
                                filter
                                filterBy="id,descricao"
                                optionValue="id"
                                optionLabel="descricao"
                                value={formik.values.operacao_fiscal}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.operacao_fiscal })}
                            />
                            {formik.errors.operacao_fiscal && (
                                <small className="p-error">{formik.errors.operacao_fiscal}</small>
                            )}
                        </div>
                    </div>
                    {formik.values?.id ? null : (
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <MakoBuscaSkuPersonalizada
                                    statusItem="F"
                                    skuValue={formik.values.item}
                                    skuChange={(e) => setFieldValue("item", e)}
                                    skuError={formik.errors.item}
                                />
                            </div>
                        </div>
                    )}
                    {formik.values?.id && formik.values?.item?.id ? (
                        <Button
                            icon="pi pi-refresh"
                            type="button"
                            label="Recarregar impostos"
                            onClick={() => buscarInformacoesTributarias(true)}
                            className="p-button-help p-button-text"
                        />
                    ) : null}
                    <TabView className="tabview-custom">
                        <TabPanel header="Dados básicos">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="codigo_barras">Código de barras*</label>
                                    <Dropdown
                                        id="codigo_barras"
                                        name="codigo_barras"
                                        placeholder="SEM GTIN"
                                        url={`/produtos/codigos-sku?sku__id=${
                                            formik.values.item?.id || "0"
                                        }&tipo__padrao_gtin=true`}
                                        disabled={!formik.values.item}
                                        optionValue="codigo"
                                        optionLabel="codigo"
                                        value={formik.values.codigo_barras}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.codigo_barras })}
                                    />
                                    {formik.errors.codigo_barras && (
                                        <small className="p-error">{formik.errors.codigo_barras}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="unidade_comercial">Unidade *</label>
                                    <Dropdown
                                        id="unidade_comercial"
                                        name="unidade_comercial"
                                        placeholder="Unidade necessária"
                                        url={`/produtos/unidades-medida?limit=300`}
                                        disabled={!formik.values.item}
                                        optionValue="sigla"
                                        optionLabel="sigla"
                                        showClear={false}
                                        value={formik.values.unidade_comercial}
                                        onChange={formik.handleChange}
                                        emptyMessage="Não existem unidades vinculadas ao produto."
                                        className={classNames({ "p-invalid": formik.errors.unidade_comercial })}
                                    />
                                    {formik.errors.unidade_comercial && (
                                        <small className="p-error">{formik.errors.unidade_comercial}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="quantidade">Quantidade *</label>
                                    <InputNumber
                                        id="quantidade"
                                        name="quantidade"
                                        showButtons
                                        min={1}
                                        disabled={!formik.values.item}
                                        value={formik.values.quantidade}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.quantidade })}
                                    />
                                    {formik.errors.quantidade && (
                                        <small className="p-error">{formik.errors.quantidade}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_unitario">Vlr. unitário *</label>
                                    <InputNumber
                                        id="valor_unitario"
                                        name="valor_unitario"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_unitario}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                                    />
                                    {formik.errors.valor_unitario && (
                                        <small className="p-error">{formik.errors.valor_unitario}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="valor_total">Total do item *</label>
                                    <InputNumber
                                        id="valor_total"
                                        name="valor_total"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_total}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_total })}
                                    />
                                    {formik.errors.valor_total && (
                                        <small className="p-error">{formik.errors.valor_total}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="ncm_descricao">NCM *</label>
                                    <InputText
                                        id="ncm_descricao"
                                        name="ncm_descricao"
                                        value={formik.values.ncm_descricao}
                                        disabled
                                        readOnly
                                        className={classNames({ "p-invalid": formik.errors.ncm_descricao })}
                                    />
                                    {formik.errors.ncm_descricao && (
                                        <small className="p-error">{formik.errors.ncm_descricao}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="cest_descricao">Cest *</label>
                                    <InputText
                                        id="cest_descricao"
                                        name="cest_descricao"
                                        value={formik.values.cest_descricao}
                                        disabled
                                        readOnly
                                        className={classNames({ "p-invalid": formik.errors.cest_descricao })}
                                    />
                                    {formik.errors.cest_descricao && (
                                        <small className="p-error">{formik.errors.cest_descricao}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="cfop">Cfop *</label>
                                    <Dropdown
                                        id="cfop"
                                        name="cfop"
                                        placeholder="Selecione um cfop"
                                        options={listaCfops}
                                        disabled={!formik.values.item}
                                        filter
                                        filterBy="codigo,descricao"
                                        optionValue="id"
                                        optionLabel="label"
                                        showClear={false}
                                        value={formik.values.cfop}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.cfop })}
                                    />
                                    {formik.errors.cfop && <small className="p-error">{formik.errors.cfop}</small>}
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="origem">Origem *</label>
                                    <Dropdown
                                        id="origem"
                                        name="origem"
                                        placeholder="Selecione uma origem"
                                        options={TIPO_ORIGEM_NF_CHOICE}
                                        disabled={!formik.values.item}
                                        optionValue="id"
                                        optionLabel="label"
                                        showClear={false}
                                        value={formik.values.origem}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.origem })}
                                    />
                                    {formik.errors.origem && <small className="p-error">{formik.errors.origem}</small>}
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="indica_total">Indica item na NF *</label>
                                    <Dropdown
                                        id="indica_total"
                                        name="indica_total"
                                        placeholder="Selecione um indicador"
                                        options={TIPO_INDICA_ITEM_TOTAL_CHOICE}
                                        disabled={!formik.values.item}
                                        filter
                                        filterBy="label"
                                        optionValue="id"
                                        optionLabel="label"
                                        showClear={false}
                                        value={formik.values.indica_total}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.indica_total })}
                                    />
                                    {formik.errors.indica_total && (
                                        <small className="p-error">{formik.errors.indica_total}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_desconto">Vlr. desconto *</label>
                                    <InputNumber
                                        id="valor_desconto"
                                        name="valor_desconto"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_desconto}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_desconto })}
                                    />
                                    {formik.errors.valor_desconto && (
                                        <small className="p-error">{formik.errors.valor_desconto}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_frete">Vlr. frete *</label>
                                    <InputNumber
                                        id="valor_frete"
                                        name="valor_frete"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_frete}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_frete })}
                                    />
                                    {formik.errors.valor_frete && (
                                        <small className="p-error">{formik.errors.valor_frete}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_seguro">Vlr. seguro *</label>
                                    <InputNumber
                                        id="valor_seguro"
                                        name="valor_seguro"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_seguro}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_seguro })}
                                    />
                                    {formik.errors.valor_seguro && (
                                        <small className="p-error">{formik.errors.valor_seguro}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="outras_despesas">Outras despesas *</label>
                                    <InputNumber
                                        id="outras_despesas"
                                        name="outras_despesas"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.outras_despesas}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.outras_despesas })}
                                    />
                                    {formik.errors.outras_despesas && (
                                        <small className="p-error">{formik.errors.outras_despesas}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="tipo_item">Tipo de item *</label>
                                    <Dropdown
                                        id="tipo_item"
                                        name="tipo_item"
                                        placeholder="Selecione um indicador"
                                        options={TIPO_ITEM_NF_CHOICE}
                                        disabled={!formik.values.item}
                                        filter
                                        filterBy="label"
                                        optionValue="id"
                                        optionLabel="label"
                                        showClear={false}
                                        value={formik.values.tipo_item}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.tipo_item })}
                                    />
                                    {formik.errors.tipo_item && (
                                        <small className="p-error">{formik.errors.tipo_item}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="pedido">Pedido </label>
                                    <InputText
                                        id="pedido"
                                        name="pedido"
                                        value={formik.values.pedido}
                                        onChange={formik.handleChange}
                                        disabled={!formik.values.item}
                                        className={classNames({ "p-invalid": formik.errors.pedido })}
                                        autoFocus
                                    />
                                    {formik.errors.pedido && <small className="p-error">{formik.errors.pedido}</small>}
                                </div>
                                <div className="p-field p-col-12 p-md-5">
                                    <label htmlFor="municipio_incidencia">Município de incidência *</label>
                                    <MakoAutoComplete
                                        id="municipio_incidencia"
                                        name="municipio_incidencia"
                                        placeholder="Busque pelo nome ou código do município ... (min 4 caracteres)"
                                        minCaracteresBusca={4}
                                        value={formik.values.municipio_incidencia}
                                        disabled={!formik.values.item}
                                        onChange={formik.handleChange}
                                        itemTemplate={autoCompleteCidadeTemplate}
                                        field="nome"
                                        urlSearch={"/pessoas/cidades?query={id, codigo, nome}&search="}
                                        className={classNames({ "p-invalid": formik.errors.municipio_incidencia })}
                                    />
                                    {formik.errors.municipio_incidencia && (
                                        <small className="p-error">{formik.errors.municipio_incidencia}</small>
                                    )}
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid"></div>
                        </TabPanel>
                        <TabPanel header="ICMS">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="cst_icms">CST Icms *</label>
                                    <Dropdown
                                        id="cst_icms"
                                        name="cst_icms"
                                        placeholder="Selecione..."
                                        url={`/fiscal/cst-icms?empresa=${empresaSelecionadaId}&limit=100&ativo=true`}
                                        disabled={!formik.values.item}
                                        filter
                                        filterBy="codigo,descricao"
                                        optionValue="id"
                                        optionLabel="descricao"
                                        itemTemplate={cstTemplate}
                                        valueTemplate={cstTemplate}
                                        showClear={false}
                                        value={formik.values.cst_icms}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.cst_icms })}
                                    />
                                    {formik.errors.cst_icms && (
                                        <small className="p-error">{formik.errors.cst_icms}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="modalidade_bc">Modalidade BC. *</label>
                                    <Dropdown
                                        id="modalidade_bc"
                                        name="modalidade_bc"
                                        placeholder="Selecione uma modalidade"
                                        options={TIPO_MODALIDADE_BASE_CALCULO_ICMS_CHOICE}
                                        disabled={!formik.values.item}
                                        optionValue="id"
                                        optionLabel="label"
                                        showClear={false}
                                        value={formik.values.modalidade_bc}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.modalidade_bc })}
                                    />
                                    {formik.errors.modalidade_bc && (
                                        <small className="p-error">{formik.errors.modalidade_bc}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_icms">Base Icms *</label>
                                    <InputNumber
                                        id="base_icms"
                                        name="base_icms"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_icms}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_icms })}
                                    />
                                    {formik.errors.base_icms && (
                                        <small className="p-error">{formik.errors.base_icms}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="aliquota_icms">Alíq. Icms *</label>
                                    <InputNumber
                                        id="aliquota_icms"
                                        name="aliquota_icms"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={4}
                                        min={0}
                                        max={100}
                                        disabled={!formik.values.item}
                                        value={formik.values.aliquota_icms}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.aliquota_icms })}
                                    />
                                    {formik.errors.aliquota_icms && (
                                        <small className="p-error">{formik.errors.aliquota_icms}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_icms">Valor Icms *</label>
                                    <InputNumber
                                        id="valor_icms"
                                        name="valor_icms"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_icms}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_icms })}
                                    />
                                    {formik.errors.valor_icms && (
                                        <small className="p-error">{formik.errors.valor_icms}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="modalidade_bcst">Modalidade BC ST. *</label>
                                    <Dropdown
                                        id="modalidade_bcst"
                                        name="modalidade_bcst"
                                        placeholder="Selecione uma modalidade"
                                        options={TIPO_MODALIDADE_BASE_CALCULO_ST_CHOICE}
                                        disabled={!formik.values.item}
                                        optionValue="id"
                                        optionLabel="label"
                                        showClear={false}
                                        value={formik.values.modalidade_bcst}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.modalidade_bcst })}
                                    />
                                    {formik.errors.modalidade_bcst && (
                                        <small className="p-error">{formik.errors.modalidade_bcst}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="margem_icms">Margem Icms *</label>
                                    <InputNumber
                                        id="margem_icms"
                                        name="margem_icms"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.margem_icms}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.margem_icms })}
                                    />
                                    {formik.errors.margem_icms && (
                                        <small className="p-error">{formik.errors.margem_icms}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="reducao_bcst">Redução BC ST. *</label>
                                    <InputNumber
                                        id="reducao_bcst"
                                        name="reducao_bcst"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.reducao_bcst}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.reducao_bcst })}
                                    />
                                    {formik.errors.reducao_bcst && (
                                        <small className="p-error">{formik.errors.reducao_bcst}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_st">BC Icms ST. *</label>
                                    <InputNumber
                                        id="base_st"
                                        name="base_st"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_st}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_st })}
                                    />
                                    {formik.errors.base_st && (
                                        <small className="p-error">{formik.errors.base_st}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_st_retido">BC Icms ST Retido *</label>
                                    <InputNumber
                                        id="base_st_retido"
                                        name="base_st_retido"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_st_retido}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_st_retido })}
                                    />
                                    {formik.errors.base_st_retido && (
                                        <small className="p-error">{formik.errors.base_st_retido}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="aliquota_icmsst">Alíq. Icms ST. *</label>
                                    <InputNumber
                                        id="aliquota_icmsst"
                                        name="aliquota_icmsst"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={4}
                                        min={0}
                                        max={100}
                                        disabled={!formik.values.item}
                                        value={formik.values.aliquota_icmsst}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.aliquota_icmsst })}
                                    />
                                    {formik.errors.aliquota_icmsst && (
                                        <small className="p-error">{formik.errors.aliquota_icmsst}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="icms_st">Vlr. Icms ST. *</label>
                                    <InputNumber
                                        id="icms_st"
                                        name="icms_st"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.icms_st}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.icms_st })}
                                    />
                                    {formik.errors.icms_st && (
                                        <small className="p-error">{formik.errors.icms_st}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="icms_st_retido">Icms ST Retido *</label>
                                    <InputNumber
                                        id="icms_st_retido"
                                        name="icms_st_retido"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.icms_st_retido}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.icms_st_retido })}
                                    />
                                    {formik.errors.icms_st_retido && (
                                        <small className="p-error">{formik.errors.icms_st_retido}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="reducao_bc">% Redução BC. *</label>
                                    <InputNumber
                                        id="reducao_bc"
                                        name="reducao_bc"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.reducao_bc}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.reducao_bc })}
                                    />
                                    {formik.errors.reducao_bc && (
                                        <small className="p-error">{formik.errors.reducao_bc}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="motivo_deso">Motivo desoneração Icms</label>
                                    <Dropdown
                                        id="motivo_deso"
                                        name="motivo_deso"
                                        placeholder="Selecione um motivo"
                                        options={TIPO_MOTIVO_DESONERACAO_ICMS_CHOICE}
                                        disabled={!formik.values.item}
                                        optionValue="id"
                                        optionLabel="label"
                                        value={formik.values.motivo_deso}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.motivo_deso })}
                                    />
                                    {formik.errors.motivo_deso && (
                                        <small className="p-error">{formik.errors.motivo_deso}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="icms_deso">Icms desonerado *</label>
                                    <InputNumber
                                        id="icms_deso"
                                        name="icms_deso"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.icms_deso}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.icms_deso })}
                                    />
                                    {formik.errors.icms_deso && (
                                        <small className="p-error">{formik.errors.icms_deso}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="icms_op">Icms operação *</label>
                                    <InputNumber
                                        id="icms_op"
                                        name="icms_op"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.icms_op}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.icms_op })}
                                    />
                                    {formik.errors.icms_op && (
                                        <small className="p-error">{formik.errors.icms_op}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="diferimento">Diferimento *</label>
                                    <InputNumber
                                        id="diferimento"
                                        name="diferimento"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={4}
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.diferimento}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.diferimento })}
                                    />
                                    {formik.errors.diferimento && (
                                        <small className="p-error">{formik.errors.diferimento}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="icms_dif">Icms diferido *</label>
                                    <InputNumber
                                        id="icms_dif"
                                        name="icms_dif"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.icms_dif}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.icms_dif })}
                                    />
                                    {formik.errors.icms_dif && (
                                        <small className="p-error">{formik.errors.icms_dif}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_op">% BC. Operação *</label>
                                    <InputNumber
                                        id="base_op"
                                        name="base_op"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={4}
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_op}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_op })}
                                    />
                                    {formik.errors.base_op && (
                                        <small className="p-error">{formik.errors.base_op}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="base_st_destino">BC Icms ST UF Destino *</label>
                                    <InputNumber
                                        id="base_st_destino"
                                        name="base_st_destino"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_st_destino}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_st_destino })}
                                    />
                                    {formik.errors.base_st_destino && (
                                        <small className="p-error">{formik.errors.base_st_destino}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="icms_st_destino">Icms ST UF Destino *</label>
                                    <InputNumber
                                        id="icms_st_destino"
                                        name="icms_st_destino"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.icms_st_destino}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.icms_st_destino })}
                                    />
                                    {formik.errors.icms_st_destino && (
                                        <small className="p-error">{formik.errors.icms_st_destino}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="icms_substituto">Icms substituto *</label>
                                    <InputNumber
                                        id="icms_substituto"
                                        name="icms_substituto"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.icms_substituto}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.icms_substituto })}
                                    />
                                    {formik.errors.icms_substituto && (
                                        <small className="p-error">{formik.errors.icms_substituto}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="aliq_credito">Alíq calc. crédito *</label>
                                    <InputNumber
                                        id="aliq_credito"
                                        name="aliq_credito"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={4}
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.aliq_credito}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.aliq_credito })}
                                    />
                                    {formik.errors.aliq_credito && (
                                        <small className="p-error">{formik.errors.aliq_credito}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="credito_icms">Crédito Icms *</label>
                                    <InputNumber
                                        id="credito_icms"
                                        name="credito_icms"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.credito_icms}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.credito_icms })}
                                    />
                                    {formik.errors.credito_icms && (
                                        <small className="p-error">{formik.errors.credito_icms}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="reducao_bc_efetiva">% Red. BC Efetiva *</label>
                                    <InputNumber
                                        id="reducao_bc_efetiva"
                                        name="reducao_bc_efetiva"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.reducao_bc_efetiva}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.reducao_bc_efetiva })}
                                    />
                                    {formik.errors.reducao_bc_efetiva && (
                                        <small className="p-error">{formik.errors.reducao_bc_efetiva}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_efetiva">Vlr. BC. Efetiva *</label>
                                    <InputNumber
                                        id="base_efetiva"
                                        name="base_efetiva"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_efetiva}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_efetiva })}
                                    />
                                    {formik.errors.base_efetiva && (
                                        <small className="p-error">{formik.errors.base_efetiva}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="perc_icms_efetivo">% Icms Efetivo *</label>
                                    <InputNumber
                                        id="perc_icms_efetivo"
                                        name="perc_icms_efetivo"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.perc_icms_efetivo}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.perc_icms_efetivo })}
                                    />
                                    {formik.errors.perc_icms_efetivo && (
                                        <small className="p-error">{formik.errors.perc_icms_efetivo}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="icms_efetivo">Vlr. ICMS Efetivo *</label>
                                    <InputNumber
                                        id="icms_efetivo"
                                        name="icms_efetivo"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.icms_efetivo}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.icms_efetivo })}
                                    />
                                    {formik.errors.icms_efetivo && (
                                        <small className="p-error">{formik.errors.icms_efetivo}</small>
                                    )}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="PIS">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="cst_pis">CST PIS *</label>
                                    <Dropdown
                                        id="cst_pis"
                                        name="cst_pis"
                                        placeholder="Selecione..."
                                        url={`/fiscal/cst-pis?empresa=${empresaSelecionadaId}&limit=100`}
                                        disabled={!formik.values.item}
                                        filter
                                        filterBy="codigo,descricao"
                                        optionValue="id"
                                        optionLabel="descricao"
                                        itemTemplate={cstTemplate}
                                        valueTemplate={cstTemplate}
                                        showClear={false}
                                        value={formik.values.cst_pis}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.cst_pis })}
                                    />
                                    {formik.errors.cst_pis && (
                                        <small className="p-error">{formik.errors.cst_pis}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_pis">Base PIS *</label>
                                    <InputNumber
                                        id="base_pis"
                                        name="base_pis"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_pis}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_pis })}
                                    />
                                    {formik.errors.base_pis && (
                                        <small className="p-error">{formik.errors.base_pis}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="qtd_vendida_pis">Qtd. vendida PIS *</label>
                                    <InputNumber
                                        id="qtd_vendida_pis"
                                        name="qtd_vendida_pis"
                                        min={0}
                                        minFractionDigits={0}
                                        maxFractionDigits={4}
                                        showButtons
                                        disabled={!formik.values.item}
                                        value={formik.values.qtd_vendida_pis}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.qtd_vendida_pis })}
                                    />
                                    {formik.errors.qtd_vendida_pis && (
                                        <small className="p-error">{formik.errors.qtd_vendida_pis}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="aliquota_pis">% Alíquota PIS *</label>
                                    <InputNumber
                                        id="aliquota_pis"
                                        name="aliquota_pis"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={4}
                                        min={0}
                                        max={100}
                                        disabled={!formik.values.item}
                                        value={formik.values.aliquota_pis}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.aliquota_pis })}
                                    />
                                    {formik.errors.aliquota_pis && (
                                        <small className="p-error">{formik.errors.aliquota_pis}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_aliq_pis">Vlr. Alíq. PIS *</label>
                                    <InputNumber
                                        id="valor_aliq_pis"
                                        name="valor_aliq_pis"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={0}
                                        maxFractionDigits={4}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_aliq_pis}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_aliq_pis })}
                                    />
                                    {formik.errors.valor_aliq_pis && (
                                        <small className="p-error">{formik.errors.valor_aliq_pis}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_pis">Valor PIS *</label>
                                    <InputNumber
                                        id="valor_pis"
                                        name="valor_pis"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_pis}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_pis })}
                                    />
                                    {formik.errors.valor_pis && (
                                        <small className="p-error">{formik.errors.valor_pis}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_pisst">BC. PIS ST. *</label>
                                    <InputNumber
                                        id="base_pisst"
                                        name="base_pisst"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_pisst}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_pisst })}
                                    />
                                    {formik.errors.base_pisst && (
                                        <small className="p-error">{formik.errors.base_pisst}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="aliquota_pisst">% Alíquota PIS ST *</label>
                                    <InputNumber
                                        id="aliquota_pisst"
                                        name="aliquota_pisst"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={4}
                                        min={0}
                                        max={100}
                                        disabled={!formik.values.item}
                                        value={formik.values.aliquota_pisst}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.aliquota_pisst })}
                                    />
                                    {formik.errors.aliquota_pisst && (
                                        <small className="p-error">{formik.errors.aliquota_pisst}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="qtd_vendida_pisst">Qtd. vendida PIS ST *</label>
                                    <InputNumber
                                        id="qtd_vendida_pisst"
                                        name="qtd_vendida_pisst"
                                        min={0}
                                        minFractionDigits={0}
                                        maxFractionDigits={4}
                                        showButtons
                                        disabled={!formik.values.item}
                                        value={formik.values.qtd_vendida_pisst}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.qtd_vendida_pisst })}
                                    />
                                    {formik.errors.qtd_vendida_pisst && (
                                        <small className="p-error">{formik.errors.qtd_vendida_pisst}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_aliq_pisst">Vlr. Alíq. PIS ST *</label>
                                    <InputNumber
                                        id="valor_aliq_pisst"
                                        name="valor_aliq_pisst"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={0}
                                        maxFractionDigits={4}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_aliq_pisst}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_aliq_pisst })}
                                    />
                                    {formik.errors.valor_aliq_pisst && (
                                        <small className="p-error">{formik.errors.valor_aliq_pisst}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_pisst">Valor PIS ST *</label>
                                    <InputNumber
                                        id="valor_pisst"
                                        name="valor_pisst"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_pisst}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_pisst })}
                                    />
                                    {formik.errors.valor_pisst && (
                                        <small className="p-error">{formik.errors.valor_pisst}</small>
                                    )}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Cofins">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="cst_cofins">CST Cofins *</label>
                                    <Dropdown
                                        id="cst_cofins"
                                        name="cst_cofins"
                                        placeholder="Selecione..."
                                        url={`/fiscal/cst-cofins?empresa=${empresaSelecionadaId}&limit=100`}
                                        disabled={!formik.values.item}
                                        filter
                                        filterBy="codigo,descricao"
                                        optionValue="id"
                                        optionLabel="descricao"
                                        itemTemplate={cstTemplate}
                                        valueTemplate={cstTemplate}
                                        showClear={false}
                                        value={formik.values.cst_cofins}
                                        onChange={({ value }) => setFieldValue("cst_cofins", value)}
                                        className={classNames({ "p-invalid": formik.errors.cst_cofins })}
                                    />
                                    {formik.errors.cst_cofins && (
                                        <small className="p-error">{formik.errors.cst_cofins}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_cofins">Base Cofins *</label>
                                    <InputNumber
                                        id="base_cofins"
                                        name="base_cofins"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_cofins}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_cofins })}
                                    />
                                    {formik.errors.base_cofins && (
                                        <small className="p-error">{formik.errors.base_cofins}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="qtd_vendida_cofins">Qtd. vendida Cofins *</label>
                                    <InputNumber
                                        id="qtd_vendida_cofins"
                                        name="qtd_vendida_cofins"
                                        min={0}
                                        minFractionDigits={0}
                                        maxFractionDigits={4}
                                        showButtons
                                        disabled={!formik.values.item}
                                        value={formik.values.qtd_vendida_cofins}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.qtd_vendida_cofins })}
                                    />
                                    {formik.errors.qtd_vendida_cofins && (
                                        <small className="p-error">{formik.errors.qtd_vendida_cofins}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="aliquota_cofins">% Alíquota Cofins *</label>
                                    <InputNumber
                                        id="aliquota_cofins"
                                        name="aliquota_cofins"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={4}
                                        min={0}
                                        max={100}
                                        disabled={!formik.values.item}
                                        value={formik.values.aliquota_cofins}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.aliquota_cofins })}
                                    />
                                    {formik.errors.aliquota_cofins && (
                                        <small className="p-error">{formik.errors.aliquota_cofins}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_aliq_cofins">Vlr. Alíq. Cofins *</label>
                                    <InputNumber
                                        id="valor_aliq_cofins"
                                        name="valor_aliq_cofins"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={0}
                                        maxFractionDigits={4}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_aliq_cofins}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_aliq_cofins })}
                                    />
                                    {formik.errors.valor_aliq_cofins && (
                                        <small className="p-error">{formik.errors.valor_aliq_cofins}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_cofins">Valor Cofins *</label>
                                    <InputNumber
                                        id="valor_cofins"
                                        name="valor_cofins"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_cofins}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_cofins })}
                                    />
                                    {formik.errors.valor_cofins && (
                                        <small className="p-error">{formik.errors.valor_cofins}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_cofinsst">Base Cofins ST *</label>
                                    <InputNumber
                                        id="base_cofinsst"
                                        name="base_cofinsst"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_cofinsst}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_cofinsst })}
                                    />
                                    {formik.errors.base_cofinsst && (
                                        <small className="p-error">{formik.errors.base_cofinsst}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="qtd_vendida_cofins">Qtd. vend. Cofins ST *</label>
                                    <InputNumber
                                        id="qtd_vendida_cofins"
                                        name="qtd_vendida_cofins"
                                        min={0}
                                        minFractionDigits={0}
                                        maxFractionDigits={4}
                                        showButtons
                                        disabled={!formik.values.item}
                                        value={formik.values.qtd_vendida_cofins}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.qtd_vendida_cofins })}
                                    />
                                    {formik.errors.qtd_vendida_cofins && (
                                        <small className="p-error">{formik.errors.qtd_vendida_cofins}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="aliquota_cofinsst">% Alíq. Cofins ST *</label>
                                    <InputNumber
                                        id="aliquota_cofinsst"
                                        name="aliquota_cofinsst"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={4}
                                        min={0}
                                        max={100}
                                        disabled={!formik.values.item}
                                        value={formik.values.aliquota_cofinsst}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.aliquota_cofinsst })}
                                    />
                                    {formik.errors.aliquota_cofinsst && (
                                        <small className="p-error">{formik.errors.aliquota_cofinsst}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_aliq_cofinsst">Vlr. Alíq. Cofins ST *</label>
                                    <InputNumber
                                        id="valor_aliq_cofinsst"
                                        name="valor_aliq_cofinsst"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={0}
                                        maxFractionDigits={4}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_aliq_cofinsst}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_aliq_cofinsst })}
                                    />
                                    {formik.errors.valor_aliq_cofinsst && (
                                        <small className="p-error">{formik.errors.valor_aliq_cofinsst}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_cofinsst">Valor Cofins ST *</label>
                                    <InputNumber
                                        id="valor_cofinsst"
                                        name="valor_cofinsst"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_cofinsst}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_cofinsst })}
                                    />
                                    {formik.errors.valor_cofinsst && (
                                        <small className="p-error">{formik.errors.valor_cofinsst}</small>
                                    )}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="IPI">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="cst_ipi">CST IPI</label>
                                    <Dropdown
                                        id="cst_ipi"
                                        name="cst_ipi"
                                        placeholder="Selecione..."
                                        url={`/fiscal/cst-ipi?empresa=${empresaSelecionadaId}&limit=100`}
                                        disabled={!formik.values.item}
                                        filter
                                        filterBy="codigo,descricao"
                                        optionValue="id"
                                        optionLabel="descricao"
                                        itemTemplate={cstTemplate}
                                        valueTemplate={cstTemplate}
                                        showClear={false}
                                        value={formik.values.cst_ipi}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.cst_ipi })}
                                    />
                                    {formik.errors.cst_ipi && (
                                        <small className="p-error">{formik.errors.cst_ipi}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="enquadramento_ipi">Enquadramento IPI</label>
                                    <Dropdown
                                        id="enquadramento_ipi"
                                        name="enquadramento_ipi"
                                        placeholder="Selecione uma modalidade"
                                        url="/fiscal/enquadramento-ipi?limit=10000"
                                        disabled={!formik.values.item}
                                        filter
                                        filterBy="descricao"
                                        itemTemplate={ipiTemplate}
                                        valueTemplate={ipiValueTemplate}
                                        optionValue="id"
                                        optionLabel="descricao"
                                        style={{ height: "58%" }}
                                        value={formik.values.enquadramento_ipi}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.enquadramento_ipi })}
                                    />
                                    {formik.errors.enquadramento_ipi && (
                                        <small className="p-error">{formik.errors.enquadramento_ipi}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="selo_controle">Selo de controle</label>
                                    <InputNumber
                                        id="selo_controle"
                                        name="selo_controle"
                                        min={0}
                                        showButtons
                                        disabled={!formik.values.item}
                                        value={formik.values.selo_controle}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.selo_controle })}
                                    />
                                    {formik.errors.selo_controle && (
                                        <small className="p-error">{formik.errors.selo_controle}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_ipi">Base IPI *</label>
                                    <InputNumber
                                        id="base_ipi"
                                        name="base_ipi"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_ipi}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_ipi })}
                                    />
                                    {formik.errors.base_ipi && (
                                        <small className="p-error">{formik.errors.base_ipi}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="qtd_un_tributavel">Qtd. unidade trib. *</label>
                                    <InputNumber
                                        id="qtd_un_tributavel"
                                        name="qtd_un_tributavel"
                                        min={0}
                                        minFractionDigits={0}
                                        maxFractionDigits={4}
                                        showButtons
                                        disabled={!formik.values.item}
                                        value={formik.values.qtd_un_tributavel}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.qtd_un_tributavel })}
                                    />
                                    {formik.errors.qtd_un_tributavel && (
                                        <small className="p-error">{formik.errors.qtd_un_tributavel}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_un_tributavel">Vlr. unidade trib. *</label>
                                    <InputNumber
                                        id="valor_un_tributavel"
                                        name="valor_un_tributavel"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={0}
                                        maxFractionDigits={4}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_un_tributavel}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_un_tributavel })}
                                    />
                                    {formik.errors.valor_un_tributavel && (
                                        <small className="p-error">{formik.errors.valor_un_tributavel}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="aliquota_ipi">% Alíquota IPI *</label>
                                    <InputNumber
                                        id="aliquota_ipi"
                                        name="aliquota_ipi"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={4}
                                        min={0}
                                        max={100}
                                        disabled={!formik.values.item}
                                        value={formik.values.aliquota_ipi}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.aliquota_ipi })}
                                    />
                                    {formik.errors.aliquota_ipi && (
                                        <small className="p-error">{formik.errors.aliquota_ipi}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_ipi">Valor IPI *</label>
                                    <InputNumber
                                        id="valor_ipi"
                                        name="valor_ipi"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_ipi}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_ipi })}
                                    />
                                    {formik.errors.valor_ipi && (
                                        <small className="p-error">{formik.errors.valor_ipi}</small>
                                    )}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="FCP">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="percentual_fcp">% FCP *</label>
                                    <InputNumber
                                        id="percentual_fcp"
                                        name="percentual_fcp"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.percentual_fcp}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.percentual_fcp })}
                                    />
                                    {formik.errors.percentual_fcp && (
                                        <small className="p-error">{formik.errors.percentual_fcp}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_fcp">Valor FCP *</label>
                                    <InputNumber
                                        id="valor_fcp"
                                        name="valor_fcp"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_fcp}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_fcp })}
                                    />
                                    {formik.errors.valor_fcp && (
                                        <small className="p-error">{formik.errors.valor_fcp}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_fcp">BC. FCP *</label>
                                    <InputNumber
                                        id="base_fcp"
                                        name="base_fcp"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_fcp}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_fcp })}
                                    />
                                    {formik.errors.base_fcp && (
                                        <small className="p-error">{formik.errors.base_fcp}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_fcp_st">BC. FCP ST. *</label>
                                    <InputNumber
                                        id="base_fcp_st"
                                        name="base_fcp_st"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_fcp_st}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_fcp_st })}
                                    />
                                    {formik.errors.base_fcp_st && (
                                        <small className="p-error">{formik.errors.base_fcp_st}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="fcp_retido_st">FCP Retido ST. *</label>
                                    <InputNumber
                                        id="fcp_retido_st"
                                        name="fcp_retido_st"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.fcp_retido_st}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.fcp_retido_st })}
                                    />
                                    {formik.errors.fcp_retido_st && (
                                        <small className="p-error">{formik.errors.fcp_retido_st}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_fcp_retido">BC. FCP Retido *</label>
                                    <InputNumber
                                        id="base_fcp_retido"
                                        name="base_fcp_retido"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_fcp_retido}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_fcp_retido })}
                                    />
                                    {formik.errors.base_fcp_retido && (
                                        <small className="p-error">{formik.errors.base_fcp_retido}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="perc_fcpst_retido">% FCP ST Retido *</label>
                                    <InputNumber
                                        id="perc_fcpst_retido"
                                        name="perc_fcpst_retido"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0}
                                        disabled={!formik.values.item}
                                        value={formik.values.perc_fcpst_retido}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.perc_fcpst_retido })}
                                    />
                                    {formik.errors.perc_fcpst_retido && (
                                        <small className="p-error">{formik.errors.perc_fcpst_retido}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_fcpst_retido">Vlr. FCP ST Retido *</label>
                                    <InputNumber
                                        id="valor_fcpst_retido"
                                        name="valor_fcpst_retido"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_fcpst_retido}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_fcpst_retido })}
                                    />
                                    {formik.errors.valor_fcpst_retido && (
                                        <small className="p-error">{formik.errors.valor_fcpst_retido}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_fcp_st">FCP ST. *</label>
                                    <InputNumber
                                        id="valor_fcp_st"
                                        name="valor_fcp_st"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_fcp_st}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_fcp_st })}
                                    />
                                    {formik.errors.valor_fcp_st && (
                                        <small className="p-error">{formik.errors.valor_fcp_st}</small>
                                    )}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Outros">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="destaque_perigoso">Destaque de perigo *</label>
                                    <Dropdown
                                        id="destaque_perigoso"
                                        name="destaque_perigoso"
                                        placeholder="Selecione..."
                                        options={TIPO_DESTAQUE_PRODUTOS_PERIGOSOS_CHOICE}
                                        disabled={!formik.values.item}
                                        filter
                                        filterBy="label"
                                        optionValue="id"
                                        optionLabel="label"
                                        showClear={false}
                                        value={formik.values.destaque_perigoso}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.destaque_perigoso })}
                                    />
                                    {formik.errors.destaque_perigoso && (
                                        <small className="p-error">{formik.errors.destaque_perigoso}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="fci">FCI </label>
                                    <InputText
                                        id="fci"
                                        name="fci"
                                        value={formik.values.fci}
                                        onChange={formik.handleChange}
                                        disabled={!formik.values.item}
                                        className={classNames({ "p-invalid": formik.errors.fci })}
                                        autoFocus
                                    />
                                    {formik.errors.fci && <small className="p-error">{formik.errors.fci}</small>}
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="beneficio">Benefício </label>
                                    <InputText
                                        id="beneficio"
                                        name="beneficio"
                                        value={formik.values.beneficio}
                                        onChange={formik.handleChange}
                                        disabled={!formik.values.item}
                                        className={classNames({ "p-invalid": formik.errors.beneficio })}
                                        autoFocus
                                    />
                                    {formik.errors.beneficio && (
                                        <small className="p-error">{formik.errors.beneficio}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_ii">Base II *</label>
                                    <InputNumber
                                        id="base_ii"
                                        name="base_ii"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_ii}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_ii })}
                                    />
                                    {formik.errors.base_ii && (
                                        <small className="p-error">{formik.errors.base_ii}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="despesas_aduaneiras">Despesas aduaneiras *</label>
                                    <InputNumber
                                        id="despesas_aduaneiras"
                                        name="despesas_aduaneiras"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.despesas_aduaneiras}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.despesas_aduaneiras })}
                                    />
                                    {formik.errors.despesas_aduaneiras && (
                                        <small className="p-error">{formik.errors.despesas_aduaneiras}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_ii">Valor II *</label>
                                    <InputNumber
                                        id="valor_ii"
                                        name="valor_ii"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_ii}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_ii })}
                                    />
                                    {formik.errors.valor_ii && (
                                        <small className="p-error">{formik.errors.valor_ii}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_iof">Valor IOF *</label>
                                    <InputNumber
                                        id="valor_iof"
                                        name="valor_iof"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_iof}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_iof })}
                                    />
                                    {formik.errors.valor_iof && (
                                        <small className="p-error">{formik.errors.valor_iof}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="base_issqn">Base ISSQN *</label>
                                    <InputNumber
                                        id="base_issqn"
                                        name="base_issqn"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.base_issqn}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.base_issqn })}
                                    />
                                    {formik.errors.base_issqn && (
                                        <small className="p-error">{formik.errors.base_issqn}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="aliquota_issqn">% Alíq. ISSQN *</label>
                                    <InputNumber
                                        id="aliquota_issqn"
                                        name="aliquota_issqn"
                                        mode="decimal"
                                        prefix={"%"}
                                        minFractionDigits={2}
                                        maxFractionDigits={4}
                                        min={0}
                                        max={100}
                                        disabled={!formik.values.item}
                                        value={formik.values.aliquota_issqn}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.aliquota_issqn })}
                                    />
                                    {formik.errors.aliquota_issqn && (
                                        <small className="p-error">{formik.errors.aliquota_issqn}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_issqn">Valor ISSQN *</label>
                                    <InputNumber
                                        id="valor_issqn"
                                        name="valor_issqn"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_issqn}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_issqn })}
                                    />
                                    {formik.errors.valor_issqn && (
                                        <small className="p-error">{formik.errors.valor_issqn}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="valor_deducao">Valor dedução *</label>
                                    <InputNumber
                                        id="valor_deducao"
                                        name="valor_deducao"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.valor_deducao}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_deducao })}
                                    />
                                    {formik.errors.valor_deducao && (
                                        <small className="p-error">{formik.errors.valor_deducao}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="outras_retencoes">Outras retenções *</label>
                                    <InputNumber
                                        id="outras_retencoes"
                                        name="outras_retencoes"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.outras_retencoes}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.outras_retencoes })}
                                    />
                                    {formik.errors.outras_retencoes && (
                                        <small className="p-error">{formik.errors.outras_retencoes}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="desc_incondicionado">Desc. incondicionado *</label>
                                    <InputNumber
                                        id="desc_incondicionado"
                                        name="desc_incondicionado"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.desc_incondicionado}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.desc_incondicionado })}
                                    />
                                    {formik.errors.desc_incondicionado && (
                                        <small className="p-error">{formik.errors.desc_incondicionado}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="desc_condicionado">Desc. condicionado *</label>
                                    <InputNumber
                                        id="desc_condicionado"
                                        name="desc_condicionado"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.desc_condicionado}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.desc_condicionado })}
                                    />
                                    {formik.errors.desc_condicionado && (
                                        <small className="p-error">{formik.errors.desc_condicionado}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="iss_retido">ISS retido *</label>
                                    <Dropdown
                                        id="iss_retido"
                                        name="iss_retido"
                                        placeholder="Código necessário"
                                        options={TIPO_SIM_NAO_NUMERICO_CHOICE}
                                        disabled={!formik.values.item}
                                        optionValue="id"
                                        optionLabel="label"
                                        showClear={false}
                                        value={formik.values.iss_retido}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.iss_retido })}
                                    />
                                    {formik.errors.iss_retido && (
                                        <small className="p-error">{formik.errors.iss_retido}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="retencao_iss">Vlr. retenção ISS *</label>
                                    <InputNumber
                                        id="retencao_iss"
                                        name="retencao_iss"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        min={0}
                                        maxFractionDigits={2}
                                        disabled={!formik.values.item}
                                        value={formik.values.retencao_iss}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.retencao_iss })}
                                    />
                                    {formik.errors.retencao_iss && (
                                        <small className="p-error">{formik.errors.retencao_iss}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="indicador_iss">Indicador exigibilidade ISS *</label>
                                    <Dropdown
                                        id="indicador_iss"
                                        name="indicador_iss"
                                        placeholder="Código necessário"
                                        options={TIPO_INDICADOR_EXIGIBILIDADE_ISS_CHOICE}
                                        disabled={!formik.values.item}
                                        optionValue="id"
                                        optionLabel="label"
                                        showClear={false}
                                        value={formik.values.indicador_iss}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.indicador_iss })}
                                    />
                                    {formik.errors.indicador_iss && (
                                        <small className="p-error">{formik.errors.indicador_iss}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="incentivo_fiscal">Incentivo fiscal *</label>
                                    <Dropdown
                                        id="incentivo_fiscal"
                                        name="incentivo_fiscal"
                                        placeholder="Código necessário"
                                        options={TIPO_SIM_NAO_NUMERICO_CHOICE}
                                        disabled={!formik.values.item}
                                        optionValue="id"
                                        optionLabel="label"
                                        showClear={false}
                                        value={formik.values.incentivo_fiscal}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.incentivo_fiscal })}
                                    />
                                    {formik.errors.incentivo_fiscal && (
                                        <small className="p-error">{formik.errors.incentivo_fiscal}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="municipio_issqn">Município de ocorrência ISSQN *</label>
                                    <MakoAutoComplete
                                        id="municipio_issqn"
                                        name="municipio_issqn"
                                        placeholder="Busque pelo nome ou código do município ... (min 4 caracteres)"
                                        minCaracteresBusca={4}
                                        value={formik.values.municipio_issqn}
                                        disabled={!formik.values.item}
                                        onChange={formik.handleChange}
                                        itemTemplate={autoCompleteCidadeTemplate}
                                        field="nome"
                                        urlSearch={"/pessoas/cidades?query={id, codigo, nome}&search="}
                                        className={classNames({ "p-invalid": formik.errors.municipio_issqn })}
                                    />
                                    {formik.errors.municipio_issqn && (
                                        <small className="p-error">{formik.errors.municipio_issqn}</small>
                                    )}
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                    <div className="p-grid p-justify-end p-mr-1">
                        <Button
                            icon={formik.values.id ? "pi pi-save" : "pi pi-plus"}
                            type="submit"
                            label={formik.values.id ? "Salvar" : "Inserir"}
                            className="p-button-success p-mr-2 p-mt-5"
                        />
                        <Button
                            icon="pi pi-times"
                            type="reset"
                            label="Fechar"
                            onClick={() => esconderDialog()}
                            className="p-button-danger p-button-outlined p-mr-2 p-mt-5"
                        />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
