import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { Checkbox } from "primereact/checkbox";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";

const url = "/crediario/ocupacao/";
const urlvoltar = "/vendas/venda/crediario/ocupacao";

export const CrediarioOcupacaoForm = () => {
    const { showLoading, hideLoading } = useLoading();
    const history = useHistory();
    const { state } = useLocation();
    const { httpPost, httpPatch } = useHttp();
    const { showSuccess, showError } = useToast();

    const { resetForm, ...formik } = useFormik({
        initialValues: state
            ? state.ocupacao
            : {
                  id: null,
                  codigo: "",
                  descricao: "",
                  especialidade: "",
                  natureza_ocupacao: null,
                  ativo: true,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().max(3).required("O campo 'código' é obrigatório."),
                descricao: Yup.string().max(100).required("O campo 'descrição' é obrigatório."),
                especialidade: Yup.string().max(100),
                natureza_ocupacao: Yup.number().required("O campo 'natureza da ocupação' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Ocupação cadastrada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/vendas/venda/crediario/ocupacao");
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Ocupação alterada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/vendas/venda/crediario/ocupacao");
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo Ocupação" : "Manutenção de Ocupação"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="codigo">Código *</label>
                        <InputText
                            id="codigo"
                            name="codigo"
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="descricao">Descricao *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="natureza_ocupacao">Natureza da ocupação *</label>
                        <Dropdown
                            id="natureza_ocupacao"
                            name="natureza_ocupacao"
                            placeholder="Selecione uma natureza"
                            url="/crediario/natureza-ocupacao?limit=10000"
                            optionValue="id"
                            optionLabel="descricao"
                            showClear={false}
                            value={formik.values.natureza_ocupacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.natureza_ocupacao })}
                        />
                        {formik.errors.natureza_ocupacao && (
                            <small className="p-error">{formik.errors.natureza_ocupacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="especialidade">Especialidade</label>
                        <InputText
                            id="especialidade"
                            name="especialidade"
                            value={formik.values.especialidade}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.especializacao })}
                            autoComplete="off"
                        />
                        {formik.errors.especialidade && (
                            <small className="p-error">{formik.errors.especialidade}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-3">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            disabled={!formik.values.id}
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                            className="p-ml-2"
                        />
                        <label htmlFor="ativo">Ativo</label>
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    <Button
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
