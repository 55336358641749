import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import MakoListagem from "@/components/MakoListagem";
import { Tag } from "primereact/tag";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { ESCOPO_FORMA_RECEB_PGTO_CHOICE } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import useHttp from "@/hooks/useHttp";
import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";
import { PageBase } from "@/components/PageBase";

const url = "/financeiro/formas-recebimentos/";

const opcoesPesquisa = [
    { id: "todos", label: "Todos", value: "" },
    { id: "ativos", label: "Ativos", value: true },
    { id: "inativos", label: "Inativos", value: false },
];

export const FinanceiroFormaRecebimentoPage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [filtro, setFiltro] = useState("");
    const { httpDelete } = useHttp();

    const [formaRecebimento, setFormaRecebimento] = useState(null);
    const { showSuccess } = useToast();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const confirmarDelete = (formaRecebimento) => {
        setFormaRecebimento(formaRecebimento);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const removerForma = async () => {
        let ok = false;
        const handlers = {
            204: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Forma de recebimento deletada com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
                ok = false;
            },
        };
        await httpDelete({ url: `${url}${formaRecebimento.id}` }, handlers);
        return ok;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_FORMARECEBIMENTOS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de forma de recebimento"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/financeiro/cadastros/formas-recebimentos/form",
                        state: { formaRecebimento: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_FORMARECEBIMENTOS_EXCLUIR]}
                    componente={Button}
                    icon={MAKO_ICONS.DELETE}
                    className="p-button-rounded p-button-danger"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar forma de recebimento"
                    tooltipOptions={{ position: "left" }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const filtroStatus = (status) => {
        listagemRef.current.filter(status.value, "ativo", "contains");
        setFiltro(status.value);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_FORMARECEBIMENTOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/financeiro/cadastros/formas-recebimentos/form"}
            />
            <Dropdown
                id="opcoesPesquisa"
                name="opcoesPesquisa"
                style={{ width: "120px" }}
                value={filtro}
                options={opcoesPesquisa}
                optionLabel="label"
                placeholder={!filtro ? ("" ? filtro : "Todos") : filtro ? "Ativos" : false ? filtro : "Inativos"}
                onChange={filtroStatus}
            />
        </>
    );

    const escopoBodyTemplate = (escopo) => {
        if (escopo === "R") return <Tag severity="success" value="Recebimento" style={{ width: "130px" }} />;
        if (escopo === "P") return <Tag severity="danger" value="Pagamento" style={{ width: "130px" }} />;
        return <Tag severity="info" value="Pagamento/Recebimento" style={{ width: "130px" }} />;
    };

    const TipoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "label",
                    options: TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE,
                }}
                options={options}
            />
        );
    };

    const EscopoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: ESCOPO_FORMA_RECEB_PGTO_CHOICE,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "codigo",
            header: "Código",
            style: { width: "8%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "tipo.descricao",
            header: "Tipo",
            style: { width: "12%" },
            filter: true,
            filterElement: TipoFiltroTemplate,
            filterField: "tipo",
        },
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "escopo",
            header: "Escopo",
            action: (e) => escopoBodyTemplate(e.escopo),
            filter: true,
            filterElement: EscopoFiltroTemplate,
            align: "center",
            style: { width: "10%" },
        },
        {
            field: "ativo",
            header: "Ativo",
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "DESATIVADO"),
            align: "center",
            style: { width: "8%" },
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "8%" },
            align: "center",
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Formas Recebimento e Pagamento"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                }}
                filtros={{
                    codigo: { value: null, matchMode: "equals" },
                    tipo: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    escopo: { value: null, matchMode: "equals" },
                }}
            />
            <MakoConfirmDialog
                visible={deleteDialog}
                setVisible={esconderDeleteDialog}
                message={
                    formaRecebimento && (
                        <span>
                            {"Deseja realmente excluir a forma de recebimento. "}
                            <b>{formaRecebimento.descricao}</b>?
                        </span>
                    )
                }
                accept={removerForma}
                showErrorToast={false}
            />
        </PageBase>
    );
};
