import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import useEntrada from "@/hooks/useEntrada";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const BuscaSku = ({ onVinculo }, ref) => {
    const [visible, setVisible] = useState(false);
    const [sku, setSku] = useState(null);
    const [itemXml, setItemXml] = useState(0);
    const { dadosBasicos } = useEntrada();
    const { showWarning, showError } = useToast();
    const { httpPut } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    const abrirModal = (e) => {
        if (e) setItemXml(e);
        setVisible(true);
    };

    const fecharModal = () => {
        setSku(null);
        setVisible(false);
    };

    async function vincularSkuComItemXml() {
        if (sku instanceof Object && itemXml) {
            const handlers = {
                200: () => {
                    fecharModal();
                    if (typeof onVinculo === "function") onVinculo();
                },
                error: ({ status, err }) => {
                    if (status !== 500) {
                        showWarning({
                            summary: "Aviso!",
                            detail:
                                err?.msg ||
                                "Desculpe, não foi possível fazer o vínculo entre os itens. Tente novamente.",
                            life: 3000,
                        });
                    } else {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não foi possível fazer o vínculo entre os itens. Tente novamente.",
                            life: 3000,
                        });
                    }
                },
            };
            showLoading();
            await httpPut({ url: `/compras/vincular-item-xml-sku/${itemXml}/${sku.id}/` }, handlers);
            hideLoading();
        }
    }

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label="Confirmar"
                    icon="pi pi-check"
                    onClick={() => vincularSkuComItemXml()}
                    loading={loading}
                    autoFocus
                />
            </div>
        );
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog header="Vincular produto com XML" visible={visible} onHide={fecharModal} footer={renderFooter}>
            <MakoBuscaSkuPersonalizada
                skuChange={(e) => setSku(e)}
                skuValue={sku}
                categoriaId={dadosBasicos?.categoria_titulo}
            />
        </Dialog>
    );
};

export const ModalBuscaSku = forwardRef(BuscaSku);
