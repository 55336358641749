import React from "react";

export const IconCartaoDebito = ({ color = "black", ...props }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.66675 4V17.3333H7.50008V15.6667H3.33341V10.6667H18.3334V4H1.66675ZM16.6667 7.33333H3.33341V5.66667H16.6667V7.33333ZM12 15H10V17H12H15V17.5L17 16L15 14.5V15H12Z"
                fill={color}
            />
        </svg>
    );
};
