import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

import { MakoButton as Button } from "../MakoButton";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

export const MakoConfirmDialog = ({
    accept = async () => {},
    setVisible = () => {},
    acceptLabel = "Sim",
    rejectLabel = "Não",
    reject,
    showErrorToast = true,
    errorToast,
    visible,
    ...props
}) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showError } = useToast();

    const action = async () => {
        showLoading();
        const status = await accept();
        hideLoading();
        if (!status && showErrorToast) errorToast ? showError(errorToast) : showError();
        else setVisible(false);
    };

    const hide = () => {
        setVisible(false);
    };

    const actionReject = () => {
        if (typeof reject === "function") reject();
        hide();
    };

    const footer = (
        <>
            <Button label={rejectLabel} text disabled={loading} onClick={actionReject} />
            <Button label={acceptLabel} loading={loading} onClick={action} icon={null} className="p-button-danger" />
        </>
    );

    return (
        <ConfirmDialog
            visible={visible}
            onHide={hide}
            header="Confirmação"
            icon="pi pi-info-circle"
            acceptClassName="p-button-success"
            acceptLabel={acceptLabel}
            rejectLabel={rejectLabel}
            {...props}
            accept={action}
            footer={footer}
        />
    );
};
