import React, { useState } from "react";

import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Label } from "@/components/Label";
import { PageBase } from "@/components/PageBase";

const INITIAL_VALUES = {
    chave: "",
    registro: "",
    search: "",
};

export const PermissoesPerfil = () => {
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState("/pessoas/permissoes-perfis/");
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    const limparFiltro = () => {
        setTotalizadorFiltros(0);
        formik.resetForm();
    };

    async function handleSubmit(values) {
        let queryParams = [];
        let contador = 0;
        if (values.chave !== "") {
            contador++;
            queryParams.push(`chave__icontains=${values.chave}`);
        }

        if (values.registro !== "") {
            contador++;
            queryParams.push(`registro=${values.registro}`);
        }

        if (values.search !== "") {
            contador++;
            queryParams.push(`search=${values.search}`);
        }

        queryParams = queryParams.join("&");
        setUrl("/pessoas/permissoes-perfis/?" + queryParams);
        setShow(false);
        setTotalizadorFiltros(contador);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.OUTROS_PARAMETROS_PERMISSOES_PERFIL_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/configuracoes/permissoes-perfis/form",
                        state: { permissao: rowData },
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const leftHeader = (
        <>
            <Button
                label="Filtro Avançado"
                icon={MAKO_ICONS.FILTRAR}
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                className="p-button-help"
                onClick={() => setShow(true)}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
        },
        {
            field: "chave",
            header: "Chave",
        },
        {
            field: "descricao",
            header: "Descrição",
        },
        {
            field: "registro",
            header: "Registro",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "5%" },
        },
    ];

    return (
        <PageBase>
            <h5>Permissões</h5>
            <MakoListagem
                colunas={colunas}
                urlPesquisa={url}
                responsiva
                painelEsquerdo={leftHeader}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                }}
            />
            <Dialog
                header="Filtrar permissões"
                visible={show}
                style={{ width: "40vw" }}
                breakpoints={{ "960px": "75vw" }}
                onHide={() => setShow(false)}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-8">
                            <Label htmlFor="chave" label="Chave" />
                            <InputText
                                id="chave"
                                name="chave"
                                placeholder="Digite uma chave"
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="registro" label="Registro" />
                            <InputText
                                id="registro"
                                name="registro"
                                placeholder="Digite um registro"
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <Label htmlFor="search" label="Pesquisar por texto" />
                            <InputText
                                id="search"
                                name="search"
                                placeholder="Digite algo..."
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <MakoActionsButtons className="p-jc-end">
                        <Button
                            type="reset"
                            label="Cancelar"
                            icon={MAKO_ICONS.CANCEL}
                            onClick={() => setShow(false)}
                            className="p-button-danger"
                        />
                        <Button
                            type="reset"
                            icon={MAKO_ICONS.LIMPAR_FILTROS}
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning"
                        />
                        <Button type="submit" icon={MAKO_ICONS.FILTRAR} label="Filtrar" />
                    </MakoActionsButtons>
                </form>
            </Dialog>
        </PageBase>
    );
};
