import React, { useRef } from "react";

import { MakoListagemHierarquica } from "@/components/MakoListagemHierarquica";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";

import permissoes from "@/assets/constants/permissoes";

import useEmpresa from "@/hooks/useEmpresa";

const BASE_URL = "/pessoas/hierarquia-funcional-menu/";

export const HierarquiaFuncionalPage = () => {
    const { empresaSelecionadaId } = useEmpresa();

    const listagemRef = useRef();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar Cadastro de hierarquia"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/gestao/cadastros/hierarquia-funcional/form",
                        state: { hierarquia: { ...rowData.data, cargo: rowData.data.cargo.id } },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/pessoas/hierarquia-funcional/"
                    objetoId={rowData.data.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a hierarquia de código <b>{rowData.data.id}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A hierarquia não pode ser excluída."
                    tooltip="Deletar hierarquia"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "6%" },
            expander: true,
        },
        {
            field: "pessoa.nome",
            header: "Pessoa",
        },
        {
            field: "cargo.nome",
            header: "Cargo",
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "8%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success"
                to={"/gestao/cadastros/hierarquia-funcional/form"}
            />
        </>
    );

    const Titulo = (
        <>
            {!!empresaSelecionadaId ? (
                <span>Hierarquia Funcional</span>
            ) : (
                <span className="p-error">Hierarquia Funcional (selecione uma empresa)</span>
            )}
        </>
    );

    return (
        <PageBase>
            <MakoListagemHierarquica
                ref={listagemRef}
                titulo={Titulo}
                colunas={colunas}
                cabecalho={cabecalhoTabela}
                urlPesquisa={BASE_URL + `?empresa=${empresaSelecionadaId}`}
                tabelaConfig={{
                    scrollable: true,
                }}
                fazerBusca={!!empresaSelecionadaId}
            />
        </PageBase>
    );
};
