import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputTextarea } from "primereact/inputtextarea";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { NATUREZA_PLANO_CONTAS } from "@/assets/constants/constants";
import { dataToStr, parseData } from "@/assets/util/datas";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { gerarFiltrosUrl } from "@/assets/util/util";

const BASE_HISTORICO = "Efetuação de transferência";

const FORMAS_VALIDAS = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.filter(({ id }) => [0, 4].includes(id));

export const FormTransferencia = ({
    user,
    caixaMov,
    planoPadrao,
    successCalback = () => {},
    cancelCallback = () => {},
}) => {
    const [caixaTr, setCaixaTr] = useState(null);
    const [possuiCaixa, setPossuiCaixa] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpPost, httpGet } = useHttp();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            valor: 0,
            tipo_movimento: "D",
            data_movimentacao: parseData(caixaMov?.data_abertura),
            caixa_movimento: caixaMov?.id,
            historico: BASE_HISTORICO,
            conta_financeira: caixaMov?.caixa.conta_financeira.id,
            plano_contas: null,
            compensado: false,
            cancelado: false,
            usuario_lancamento: user.id,
            operador: null,
            forma_pagamento: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                valor: Yup.number().required("O campo 'valor' é obrigatório").typeError("Informe um 'valor' válido"),
                data_movimentacao: Yup.date()
                    .required("O campo 'data da movimentação' é obrigatório")
                    .typeError("Informe uma 'data' válida"),
                historico: Yup.string()
                    .max(60, "Quantidade máxima de caracteres atingida: 60")
                    .required("O campo 'histórico' é obrigatório.")
                    .typeError("Informe um 'historico' válido"),
                tipo_movimento: Yup.string()
                    .required("O campo 'tipo' é obrigatório.")
                    .typeError("Informe um 'tipo' válido"),
                forma_pagamento: Yup.number()
                    .required("O campo 'forma' é obrigatório")
                    .typeError("Informe uma 'forma' válido"),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const saldo_caixa =
                parseFloat(caixaMov.dinheiro_inicial) +
                caixaMov.resumos.reduce((total, item) => {
                    if (item.natureza === "D") return total - parseFloat(item.valor);
                    return total + parseFloat(item.valor);
                }, 0);
            if (values.valor <= saldo_caixa) {
                const caixa_transferencia = caixaTr.id;
                const conta_transferencia = caixaTr?.caixa.conta_financeira.id;
                const dados_movimentacao = {
                    valor: values.valor,
                    data_movimentacao: dataToStr(values.data_movimentacao, "yyyy-MM-dd HH:mm:ss"),
                    historico: values.historico,
                    conta_financeira: values.conta_financeira,
                    plano_contas: planoPadrao,
                    usuario_lancamento: values.usuario_lancamento,
                    tipo_movimento: values.tipo_movimento,
                    forma_pagamento: values.forma_pagamento,
                };
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Transferência efetuada com sucesso!",
                            life: 1500,
                        });
                        successCalback();
                    },
                };
                showLoading();
                await httpPost(
                    {
                        url: `/financeiro/caixas/${values.caixa_movimento}/operar/transferir/${caixa_transferencia}`,
                        body: { conta_transferencia, dados_movimentacao },
                    },
                    handlers
                );
                hideLoading();
            } else throw new Error("Desculpe, o caixa não possui saldo suficiente para essa transação.");
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: error.message,
                    life: 3000,
                });
            }
        }
    }

    const aposPesquisarOperador = useCallback(
        (values) => {
            if (values.length === 0) return values;
            return values.filter((item) => item.id !== caixaMov.operador.id);
        },
        [caixaMov]
    );

    const aposPesquisarCaixa = useCallback(
        (values) => {
            if (values.length === 0) return values;
            return values
                .filter((item) => item.id !== caixaMov.caixa.id)
                .map((caixa) => ({ ...caixa, label: `${caixa.id} - ${caixa.nome}` }));
        },
        [caixaMov]
    );

    const validarCaixa = async () => {
        setCaixaTr(null);
        setPossuiCaixa(false);

        if (typeof formik.values.operador !== "object" || typeof formik.values.caixa !== "object") {
            setErrorMessage("Informe o campo 'operador' e o campo 'caixa'!");
        } else {
            const _operador = formik.values.operador?.id;
            const _caixa = formik.values.caixa?.id;
            const handlers = {
                200: ({ data }) => {
                    if (data.results.length > 0) {
                        setErrorMessage(null);
                        setCaixaTr(data.results[0]);
                        setPossuiCaixa(true);
                        showSuccess({
                            summary: "Sucesso :)",
                            detail: "Vincúlo de caixa válido encontrado.",
                            life: 3000,
                        });
                    } else {
                        setErrorMessage("Não foi encontrado nenhum registro de caixa para este operador");
                    }
                },
            };
            showLoading();
            await httpGet(
                {
                    url: `/financeiro/caixas-movimento/?caixa=${_caixa}&operador=${_operador}&datahora_fechamento__isnull=true`,
                },
                handlers
            );
            hideLoading();
        }
    };

    const urlCaixas = useMemo(() => {
        const params = {
            empresa: empresaSelecionadaId,
        };
        return `/financeiro/caixas/?${gerarFiltrosUrl(params)}`;
    }, [empresaSelecionadaId]);

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="operador" label="Operador do caixa" obrigatorio />
                    <MakoInputPerfil
                        id="operador"
                        name="operador"
                        aposPesquisar={aposPesquisarOperador}
                        value={formik.values.operador}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.operador })}
                    />
                    {formik.errors.operador && <small className="p-error">{formik.errors.operador}</small>}
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="caixa" label="Caixa" obrigatorio />
                    <Dropdown
                        id="caixa"
                        name="caixa"
                        url={urlCaixas}
                        value={formik.values.caixa}
                        aposBuscar={aposPesquisarCaixa}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.caixa })}
                    />
                    {formik.errors.caixa && <small className="p-error">{formik.errors.caixa}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Button
                        icon={MAKO_ICONS.PESQUISAR}
                        label="Validar"
                        className="p-button-info p-mt-5"
                        type="button"
                        onClick={validarCaixa}
                    />
                </div>
            </div>
            {errorMessage && (
                <div className="p-mb-2">
                    <span className="p-error">{errorMessage}</span>
                </div>
            )}
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="data_movimentacao" label="Data" obrigatorio />
                    <MakoCalendar
                        id="data_movimentacao"
                        name="data_movimentacao"
                        valueCalendar={formik.values.data_movimentacao}
                        minDate={caixaMov?.data_abertura}
                        onChange={formik.handleChange}
                        disabled={!!!possuiCaixa}
                        className={classNames({ "p-invalid": formik.errors.data_movimentacao })}
                    />
                    {formik.errors.data_movimentacao && (
                        <small className="p-error">{formik.errors.data_movimentacao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="tipo_movimento" label="Tipo" obrigatorio />
                    <Dropdown
                        id="tipo_movimento"
                        name="tipo_movimento"
                        placeholder="Selecione uma conta financeira"
                        options={NATUREZA_PLANO_CONTAS}
                        filter
                        filterBy="label"
                        optionValue="id"
                        optionLabel="label"
                        disabled={!!!possuiCaixa}
                        value={formik.values.tipo_movimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tipo_movimento })}
                    />
                    {formik.errors.tipo_movimento && <small className="p-error">{formik.errors.tipo_movimento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="forma_pagamento" label="Forma" obrigatorio />
                    <Dropdown
                        id="forma_pagamento"
                        name="forma_pagamento"
                        placeholder="Selecione uma forma"
                        options={FORMAS_VALIDAS}
                        optionValue="id"
                        optionLabel="label"
                        value={formik.values.forma_pagamento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                    />
                    {formik.errors.forma_pagamento && (
                        <small className="p-error">{formik.errors.forma_pagamento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="valor" label="Valor" obrigatorio />
                    <MakoInputMoeda
                        id="valor"
                        name="valor"
                        somentePositivo
                        disabled={!!!possuiCaixa}
                        valueMoeda={formik.values.valor}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor })}
                    />
                    {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="historico" label="Histórico" obrigatorio />
                    <InputTextarea
                        id="historico"
                        name="historico"
                        rows={4}
                        maxLength={60}
                        disabled={!!!possuiCaixa}
                        value={formik.values.historico}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.historico })}
                    />
                    {formik.errors.historico && <small className="p-error">{formik.errors.historico}</small>}
                </div>
            </div>
            <MakoActionsButtons>
                <Button
                    label="Gravar"
                    className="p-button-success"
                    type="submit"
                    disabled={!!!possuiCaixa}
                    loading={loading}
                />
                <Button label="Cancelar" className="p-button-danger" onClick={cancelCallback} loading={loading} />
            </MakoActionsButtons>
        </form>
    );
};
