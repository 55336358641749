import React, { useRef, useState } from "react";
import MakoListagem from "@/components/MakoListagem";
import { Dialog } from "primereact/dialog";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { MakoButton as Button } from "@/components/MakoButton";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const RecalcularPrecosModalForm = (props) => {
    const [precosSelecionados, setPrecosSelecionados] = useState([]);
    const [precosTabela, setPrecosTabela] = useState(props?.tabela?.precostabelapreco_set || []);
    const [exibirConfirmacao, setExibirConfirmacao] = useState(false);
    const [percentual, setPercentual] = useState(null);
    const [loading, setLoading] = useState(false);
    const listagemRef = useRef(null);
    const [loadingSubmit, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { httpPut, httpPatch } = useHttp();

    async function processaValores() {
        try {
            setLoading(true);
            let precosSku = [],
                precosValor = [],
                consultaId = "";

            const precos = JSON.parse(JSON.stringify(precosSelecionados));

            precos.forEach((item) => {
                let preco = item.preco_calculado;
                if (percentual) {
                    if (percentual >= 0) {
                        preco = preco * (1 + percentual / 100);
                    } else {
                        preco = preco / (1 + (percentual * -1) / 100);
                    }
                }
                consultaId = `${consultaId}${consultaId ? "," : ""}${item.sku.id}`;
                precosValor.push(parseFloat(preco).toFixed(4));
                precosSku.push(item.sku.id);
            });
            const handlers = {
                200: ({ data }) => {
                    const fields = precos;
                    Object.entries(data.result).forEach(async (valor) => {
                        const index = precosValor?.findIndex(
                            (e) => parseFloat(e) === parseFloat(Object.values(valor)[0])
                        );
                        fields[index]["preco_arredondado"] = await Object.values(valor)[1];
                        fields[index]["preco_calculado"] = await precosValor[index];
                    });
                    setPrecosSelecionados(fields);
                },
                400: () => {
                    showError({
                        severity: "error",
                        summary: "Erro",
                        detail: "Falha ao arrendondar os preços da tabela.",
                        life: 1500,
                    });
                },
            };
            showLoading();
            await httpPut(
                {
                    url: "/vendas/arredondar-precos/",
                    body: {
                        precos: precosValor,
                    },
                },
                handlers
            );
            hideLoading();
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            showError();
        }
    }

    async function handlePersistir() {
        try {
            let body = {};
            precosSelecionados.forEach((preco) => {
                body = {
                    ...body,
                    [preco.id]: { ...preco, sku: preco.sku.id },
                };
            });
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Tabela de preços atualizada com sucesso!",
                        life: 3000,
                    });
                    setPrecosSelecionados([]);
                    setPrecosTabela([]);
                    props.listagem.current?.buscarDados();
                    props.esconderDialog();
                },
            };
            showLoading();
            await httpPatch(
                {
                    url: `/produtos/tabelas-preco/${props.tabela.id}/`,
                    body: {
                        nome: props.tabela.nome,
                        vigencia: props.tabela.vigencia,
                        precostabelapreco_set: { update: body },
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            showError();
        }
    }

    const exibirConfirmacaoPrecos = async () => {
        showLoading();
        setExibirConfirmacao(true);
        processaValores();
        hideLoading();
    };

    const esconderConfirmacaoPrecos = async () => {
        setExibirConfirmacao(false);
        setPrecosSelecionados([]);
    };

    const colunas = !exibirConfirmacao
        ? [
              { selectionMode: "multiple", style: { width: "5%" } },
              { field: "id", header: "Código", style: { minWidth: "12%" } },
              { field: "sku.descricao_reduzida", header: "Item", style: { minWidth: "45%" } },
              { field: "preco_calculado", header: "Calculado", money: true },
              { field: "preco_arredondado", header: "Arredondado", money: true },
          ]
        : [
              { field: "id", header: "Código", style: { minWidth: "12%" } },
              { field: "sku.descricao_reduzida", header: "Item", style: { minWidth: "45%" } },
              { field: "preco_calculado", header: "Calculado", money: true },
              { field: "preco_arredondado", header: "Arredondado", money: true },
          ];

    const cabecalhoTabela = (
        <>
            <div>
                <label htmlFor="nome" className="p-mb-1">
                    Informe o percentual de acréscimo. % negativo para dar desconto
                </label>
                <MakoInputPercent
                    type="decimal"
                    maxFractionDigits={4}
                    minFractionDigits={0}
                    value={percentual}
                    onValueChange={(e) => setPercentual(e.target.value)}
                    suffix={null}
                    min={null}
                />
            </div>
        </>
    );
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <MakoListagem
                            ref={listagemRef}
                            titulo="Itens da tabela de preço"
                            painelEsquerdo={cabecalhoTabela}
                            colunas={colunas}
                            dadosLocal={precosTabela}
                            configTabela={{
                                scrollable: true,
                                scrollHeight: "400px",
                                selection: precosSelecionados,
                                onSelectionChange: (e) => setPrecosSelecionados(e.value),
                                selectionMode: "multiple",
                            }}
                        />
                    </div>
                </div>
                <MakoActionsButtons className="p-mt-5">
                    <Button
                        type="button"
                        icon="pi pi-replay"
                        label="Recalcular"
                        disabled={!(precosSelecionados?.length > 0)}
                        onClick={() => exibirConfirmacaoPrecos()}
                        loading={loadingSubmit}
                    />
                    <Button
                        className="p-button-danger p-button-text"
                        type="reset"
                        icon={MAKO_ICONS.CANCEL}
                        label="Cancelar"
                        onClick={() => props.esconderDialog()}
                        loading={loadingSubmit}
                    />
                </MakoActionsButtons>
            </div>
            <Dialog
                header={"Novo preço"}
                visible={exibirConfirmacao}
                onHide={() => esconderConfirmacaoPrecos()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", display: "block" }}
                closeOnEscape={true}
            >
                <div className="p-col-12">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        dadosLocal={precosSelecionados}
                        configTabela={{
                            editMode: "cell",
                            loading: loading,
                        }}
                    />
                    <MakoActionsButtons className="p-mt-5">
                        <Button
                            type="button"
                            icon="pi pi-download"
                            label="Gravar valores"
                            onClick={() => handlePersistir()}
                            loading={loadingSubmit}
                        />
                        <Button
                            className="p-button-danger p-button-text"
                            type="reset"
                            icon={MAKO_ICONS.VOLTAR}
                            label="Voltar"
                            onClick={() => esconderConfirmacaoPrecos()}
                            loading={loadingSubmit}
                        />
                    </MakoActionsButtons>
                </div>
            </Dialog>
        </div>
    );
};
