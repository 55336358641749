import React, { useRef } from "react";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Tag } from "primereact/tag";
import { ModalAcertoEstoque } from "./modalForm";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import {
    EstoqueFiltroTemplate,
    TextoFiltroTemplate,
    UnidadeMedidaFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

export const AcertoEstoquePage = () => {
    const modalAcertoRef = useRef(null);
    const listagemRef = useRef(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showWarning } = useToast();
    const { httpPut } = useHttp();

    useClearRefs(listagemRef, modalAcertoRef);

    const painelEsquerdo = (
        <>
            <Button
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => modalAcertoRef.current?.abrirModal()}
            />
        </>
    );

    const processarAcertoEstoque = async (acertoEstoque) => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Acerto de estoque processado com sucesso!",
                    life: 1500,
                });
                listagemRef.current?.buscarDados();
            },
            400: ({ err }) =>
                showWarning({
                    summary: "Aviso!",
                    detail: err?.msg || "Não foi possível processar o acerto de estoque.",
                    life: 3000,
                }),
            409: ({ err }) =>
                showWarning({
                    summary: "Aviso!",
                    detail: err?.msg || "Não foi possível processar o acerto de estoque.",
                    life: 3000,
                }),
        };
        showLoading();
        await httpPut({ url: `/produtos/acerto-estoque/${acertoEstoque.id}/processar/` }, handlers);
        hideLoading();
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[
                        permissoes.ESTOQUE_MOVIMENTACOES,
                        permissoes.ESTOQUE_MOVIMENTACOES_ACERTOESTOQUE_EDITAR,
                    ]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => modalAcertoRef.current?.abrirModal(rowData)}
                    tooltip="Editar"
                />
                <MakoControleAcesso
                    permissao={[
                        permissoes.ESTOQUE_MOVIMENTACOES,
                        permissoes.ESTOQUE_MOVIMENTACOES_ACERTOESTOQUE_PROCESSAR,
                    ]}
                    componente={Button}
                    icon="pi pi-bolt"
                    rounded
                    disabled={rowData.processado}
                    onClick={() => processarAcertoEstoque(rowData)}
                    className="p-mr-2 p-mb-1"
                    tooltip="Processar"
                />
                <MakoControleAcesso
                    permissao={[
                        permissoes.ESTOQUE_MOVIMENTACOES,
                        permissoes.ESTOQUE_MOVIMENTACOES_ACERTOESTOQUE_EXCLUIR,
                    ]}
                    componente={BotaoDelete}
                    url="/produtos/acerto-estoque/"
                    icon="pi pi-trash"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo apagar o acerto de estoque <b>{rowData.id}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O acerto de estoque não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                    classNames="p-mr-2 p-mb-1"
                    tooltip="Excluir"
                />
            </div>
        );
    };

    const quantidadeTamplateBody = (rowData) => {
        if (rowData.quantidade < 0) return <span style={{ color: "#ff5252" }}>{rowData.quantidade}</span>;
        return <span>{rowData.quantidade}</span>;
    };

    const situacaoTamplateBody = (rowData) => {
        if (rowData.processado) return <Tag value="PROCESSADO" severity="success" />;
        return <Tag value="NÃO PROCESSADO" severity="warning" />;
    };

    const EstoquesFiltroTemplate = (options) => {
        return <EstoqueFiltroTemplate filtrarPorEmpresa={true} options={options} />;
    };

    const quantidadeFiltroTemplate = (options) => {
        return (
            <InputNumber
                value={options?.value ? options.value : ""}
                onValueChange={(e) => options.filterCallback(e.value)}
                negative={true}
                minFractionDigits={6}
                maxFractionDigits={6}
            />
        );
    };

    const colunas = [
        {
            field: "sku.codigo",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "sku__codigo",
        },
        {
            field: "sku.descricao_derivada",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "sku__descricao_reduzida",
        },
        {
            field: "centro_estocagem.nome",
            header: "Centro de Estocagem",
            style: { minWidth: "150px" },
            filter: true,
            filterElement: EstoquesFiltroTemplate,
            filterField: "centro_estocagem",
        },
        {
            field: "sku.unidade_padrao.nome",
            header: "Unidade",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: UnidadeMedidaFiltroTemplate,
            filterField: "sku__unidade_padrao",
        },
        {
            field: "quantidade",
            header: "Quantidade",
            style: { minWidth: "40px" },
            action: (e) => quantidadeTamplateBody(e),
            filter: true,
            filterElement: quantidadeFiltroTemplate,
        },
        {
            field: "processado",
            header: "Situação",
            action: (e) => situacaoTamplateBody(e),
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { minWidth: "135px" } },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Lançamentos de Acerto de Estoque"
                colunas={colunas}
                painelEsquerdo={painelEsquerdo}
                urlPesquisa="/produtos/acerto-estoque/"
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    sku__codigo: { value: null, matchMode: "equals" },
                    sku__descricao_reduzida: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    centro_estocagem: { value: null, matchMode: "equals" },
                    sku__unidade_padrao: { value: null, matchMode: "equals" },
                    quantidade: { value: null, matchMode: "equals" },
                }}
            />
            <ModalAcertoEstoque ref={modalAcertoRef} aposFechar={() => listagemRef.current?.buscarDados()} />
        </PageBase>
    );
};
