import React from "react";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";

const url = "/produtos/tipos-codigos-sku/";

export const TipoCodigoSkuForm = ({ tipo, closeDialog }) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { state } = useLocation();
    const { httpPost, httpPatch } = useHttp();

    const { resetForm, ...formik } = useFormik({
        initialValues: state
            ? state.tipoCodigo
            : {
                  id: "",
                  descricao: "",
                  requer_fornecedor: false,
                  codigo_similaridade: false,
                  padrao_gtin: false,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de codigo cadastrado com sucesso!",
                            life: 1500,
                        });
                        if (!tipo) {
                            resetForm();
                            history.push("/produto/cadastros/tipos-codigos-sku");
                        } else {
                            resetForm();
                            if (closeDialog) closeDialog();
                        }
                    },
                };

                showLoading();
                await httpPost({ url: url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de codigo alterado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/produto/cadastros/tipos-codigos-sku");
                    },
                };

                showLoading();
                await httpPatch({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        resetForm();
        history.push("/produto/cadastros/tipos-codigos-sku");
    };

    return (
        <PageBase>
            {tipo ? null : <h3>{!formik.values.id ? "Novo tipo de codigo" : "Manutenção de tipo de codigo"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className={tipo ? "p-field-checkbox p-col-12 p-md-12" : "p-field-checkbox p-col-6 p-md-3"}>
                        <br></br>
                        <Checkbox
                            inputId="requer_fornecedor"
                            id="requer_fornecedor"
                            name="requer_fornecedor"
                            onChange={formik.handleChange}
                            checked={formik.values.requer_fornecedor}
                            value={formik.values.requer_fornecedor}
                            className="p-mt-3"
                        />
                        <label htmlFor="requer_fornecedor" className="p-mt-3">
                            <b>Requer fornecedor</b>
                        </label>
                    </div>
                    <div className={tipo ? "p-field-checkbox p-col-12 p-md-12" : "p-field-checkbox p-col-6 p-md-3"}>
                        <br></br>
                        <Checkbox
                            inputId="padrao_gtin"
                            id="padrao_gtin"
                            name="padrao_gtin"
                            onChange={formik.handleChange}
                            checked={formik.values.padrao_gtin}
                            value={formik.values.padrao_gtin}
                            className="p-mt-3"
                        />
                        <label htmlFor="padrao_gtin" className="p-mt-3">
                            <b>Padrão Gtin</b>
                        </label>
                    </div>
                    <div className={tipo ? "p-field-checkbox p-col-12 p-md-12" : "p-field-checkbox p-col-6 p-md-3"}>
                        <br></br>
                        <Checkbox
                            inputId="codigo_similaridade"
                            id="codigo_similaridade"
                            name="codigo_similaridade"
                            onChange={formik.handleChange}
                            checked={formik.values.codigo_similaridade}
                            value={formik.values.codigo_similaridade}
                            className="p-mt-3"
                        />
                        <label htmlFor="codigo_similaridade" className="p-mt-3">
                            <b>Código de similaridade</b>
                        </label>
                    </div>
                </div>
                <br />
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    {tipo ? null : (
                        <Button
                            label="Cancelar"
                            type="reset"
                            className="p-button-danger p-mr-2 p-mb-6"
                            onClick={cancelar}
                        />
                    )}
                </div>
            </form>
        </PageBase>
    );
};
