import { Novo } from "./novo";
import { Editar } from "./editar";
import { Bloqueio } from "./bloqueio";
import { AjustarSaldo } from "./ajustar-saldo";
import { FiltroAvancado } from "./filtro-avancado";

export const Modals = {
    Novo,
    Editar,
    Bloqueio,
    AjustarSaldo,
    FiltroAvancado,
};
