import React, { useCallback, useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import { Label } from "@/components/Label";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [versoesPlanoConta, setVersoesPlanoConta] = useState([]);
    const [loadingSubmit, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpGet, httpPost } = useHttp();

    const formik = useFormik({
        initialValues: {
            id_origem: null,
            id_destino: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                id_origem: Yup.number()
                    .required("O campo 'versão de origem' é obrigatório.")
                    .typeError("Selecione uma versão de origem."),
                id_destino: Yup.number()
                    .required("O campo 'versão de destino' é obrigatório.")
                    .typeError("Selecione uma versão de destino."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Replicação realizada com êxito.",
                        life: 1500,
                    });
                    formik.setFieldValue("id_origem");
                },
            };
            showLoading();
            await httpPost({ url: "/financeiro/importar-versao-plano-conta-financeira/", body: values }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const buscarVersoesPlanoConta = useCallback(async () => {
        const handlers = {
            200: ({ data }) => setVersoesPlanoConta(data),
        };
        setLoading(true);
        await httpGet({ url: "/financeiro/versoes-planos-contas-contabeis/" }, handlers);
        setLoading(false);
    }, [httpGet]);

    const abrirModal = (versaoPlanoContas) => {
        if (versaoPlanoContas) formik.setFieldValue("id_destino", versaoPlanoContas);
        setVisible(true);
    };

    useEffect(() => {
        buscarVersoesPlanoConta();
    }, [buscarVersoesPlanoConta]);

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            header="Replicar plano de contas"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "40vw" }}
            onHide={() => setVisible(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-grid p-fluid p-formgrid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="versao-destino" label="Versão de destino" />
                        <Dropdown
                            id="versao-destino"
                            name="id_destino"
                            options={versoesPlanoConta}
                            placeholder={!loading ? "Selecione" : "Buscando..."}
                            disabled={loading || true}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.id_destino}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="versao-origem" label="Versão de origem" obrigatorio />
                        <Dropdown
                            id="versao-origem"
                            name="id_origem"
                            options={versoesPlanoConta}
                            placeholder={!loading ? "Selecione" : "Buscando..."}
                            disabled={loading}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.id_origem}
                            onChange={formik.handleChange}
                        />
                    </div>
                    {/* CADE O SUBMIT DESSA FEATURE??? ESTÁ DESABILITADO??? 🤔 */}
                </div>
            </form>
        </Dialog>
    );
};

export const ModalReplicarPlanoContas = forwardRef(Modal);
