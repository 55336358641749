import React, { useCallback, useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import { dataToStr } from "@/assets/util/datas";
import { parseMoeda } from "@/assets/util/util";
import { DJANGO_FILTERS_MATCHMODE } from "@/assets/constants/constants";
import { parseNumber } from "@/assets/helpers/number";
import useEmpresa from "@/hooks/useEmpresa";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

// DOC...
// ref: (ref) Referência do componente da árvore DOM.
// colunas: (array) Array de objetos de colunas, pode receber qualquer propriedade do componente Column do PrimeReact
//      -> EXEMPLO: [{ field: "id", header: "Código", action: (e) => console.log(e)}].
// painelEsquerdo: (any) Conjunto de componentes que devem aparecer no cabecalho da tabela.
// botaoExportar: (bool) Se o botão de exportar deve aparecer ou não.
// fieldFiltroEmpresa: (string) Define o campo onde deve ser aplicado o filtro de empresa. Ex: (empresa, perfil, pessoa)
// configTabela: (obj) Todas as configurações do componente DataTable do PrimeReact podem ser passadas nesse objeto.
// filtros: (obj) Objeto contendo as opções de filtro

function adicionarClausulaFiltro(filtros, field, matchMode, value) {
    if (value !== null && value !== undefined && value.toString().length > 0) {
        filtros[`${field}${DJANGO_FILTERS_MATCHMODE[matchMode] || ""}`] = value instanceof Object ? value?.id : value;
    }

    return filtros;
}

const limparFiltroTemplate = (options) => {
    return (
        <Button
            type="button"
            icon={MAKO_ICONS.LIMPAR_INPUT}
            label="Limpar"
            onClick={options.filterClearCallback}
            className="p-button-secondary p-mr-1"
        />
    );
};

const aplicarFiltroTemplate = (options) => {
    return (
        <Button
            type="button"
            icon={MAKO_ICONS.CONFIRMAR}
            label="Aplicar"
            onClick={options.filterApplyCallback}
            className="p-button-success p-ml-1"
        />
    );
};

const getPropObjetoAninhado = (rowData, field) => {
    const path = field.split(".");
    for (const p of path) {
        if (p && rowData[p]) {
            rowData = rowData[p];
        } else {
            rowData = null;
            break;
        }
    }
    return rowData;
};

const Listagem = (
    {
        titulo,
        colunas = [],
        colunasGroup = null,
        limit = 10,
        ordering = null,
        dadosLocal,
        painelEsquerdo,
        painelDireito,
        botaoExportar,
        botaoReload = false,
        urlPesquisa,
        fazerBusca = true,
        filtarPorEmpresa,
        naoBuscarSemEmpresa,
        fieldFiltroEmpresa = "empresa",
        aposPesquisar,
        onReatualizar = {},
        configTabela = {},
        msgTabelaVazia,
        filtros,
        keyFiltrosStorage = null,
        handleExportarCsv = null,
        getDados,
    },
    ref
) => {
    const [lazyParams, setLazyParams] = useLocalFiltro(keyFiltrosStorage, {
        first: 0,
        rows: limit,
        page: 0,
        filters: filtros,
    });
    const [dados, setDados] = useState([]);
    const [quantRegistros, setQuantRegistros] = useState(0);
    const [possuiFiltro, setPossuiFiltro] = useState(false);
    const [loading, setLoading] = useState(false);
    const listagemRef = useRef(null);
    const { empresaSelecionadaId } = useEmpresa();
    const { showError } = useToast();
    const { httpGet } = useHttp();

    useImperativeHandle(ref, () => ({
        buscarDados: popularTabela,
        filter,
        trocarFiltros,
        exportCSV,
        limpar,
        limparFiltros,
    }));

    const limparFiltros = useCallback(() => {
        let _filtros = {};
        for (const [k, v] of Object.entries(filtros)) {
            const { advanced } = v;

            if (!advanced) {
                _filtros[k] = v;
            }
        }

        setPossuiFiltro(false);
        setLazyParams({ ...lazyParams, filters: _filtros });
    }, [lazyParams, filtros, setLazyParams]);

    const trocarFiltros = useCallback(() => {
        setPossuiFiltro(false);
        setLazyParams((prev) => ({ ...prev, filters: filtros }));
    }, [filtros, setLazyParams, setPossuiFiltro]);

    const limpar = () => setDados([]);

    const criarFiltro = useCallback((filtros) => {
        let filtroPronto = {};
        for (let [k, v] of Object.entries(filtros)) {
            let { value, matchMode, constraints } = v;

            if (constraints instanceof Array) {
                let aux = {};
                constraints.forEach((constraint) => {
                    const { matchMode: constraintMatchMode, value: constraintValue } = constraint;
                    aux = adicionarClausulaFiltro(aux, k, constraintMatchMode, constraintValue);
                });

                filtroPronto = { ...filtroPronto, ...aux };
            }

            filtroPronto = adicionarClausulaFiltro(filtroPronto, k, matchMode, value);
        }

        return filtroPronto;
    }, []);

    const buscarDadosBackend = useCallback(async () => {
        if (naoBuscarSemEmpresa && !empresaSelecionadaId) {
            setDados([]);
            return null;
        }
        if (typeof urlPesquisa !== "string" || !fazerBusca) return null;
        const [urlBase, query] = urlPesquisa.split("?");
        const limit = parseInt(lazyParams.rows) || 20;
        const offset = limit * parseInt(lazyParams.page) || 0;
        let params = { limit, offset };
        if (ordering) params.ordering = ordering;
        if (query) {
            const queryParams = query.split("&");

            queryParams.forEach((param) => {
                const [campo, valor] = param.split("=");
                params[campo] = valor;
            });
        }
        if (lazyParams.filters) {
            const _filtro = criarFiltro(lazyParams.filters);

            params = {
                ...params,
                ..._filtro,
            };
        }
        if (filtarPorEmpresa && empresaSelecionadaId) {
            params = {
                ...params,
                [fieldFiltroEmpresa]: empresaSelecionadaId,
            };
        }
        const handlers = {
            200: async ({ data }) => {
                const { results, ...info } = data;
                setQuantRegistros(info.count);
                const dadosProcessados = typeof aposPesquisar === "function" ? await aposPesquisar(results) : results;
                setDados(dadosProcessados);
                if (typeof getDados === "function") getDados(dadosProcessados);
            },
            500: (resp) => {
                showError();
                console.log(resp);
            },
        };
        setLoading(true);
        await httpGet({ url: urlBase, params }, handlers);
        setLoading(false);
    }, [
        httpGet,
        filtarPorEmpresa,
        fieldFiltroEmpresa,
        naoBuscarSemEmpresa,
        empresaSelecionadaId,
        showError,
        lazyParams,
        urlPesquisa,
        fazerBusca,
        aposPesquisar,
        ordering,
        getDados,
        criarFiltro,
    ]);

    const popularTabela = useCallback(async () => {
        if (dadosLocal) {
            if (typeof aposPesquisar === "function") {
                setDados(aposPesquisar(dadosLocal));
            } else {
                setDados(dadosLocal);
            }
        } else if (urlPesquisa) {
            buscarDadosBackend();
        }
    }, [dadosLocal, urlPesquisa, aposPesquisar, buscarDadosBackend]);

    useEffect(() => {
        popularTabela();
    }, [popularTabela]);

    const gerarColunaDinamica = (col, i) => {
        const { field, header, action, money, dateFormat, decimal, showZero = true, ...rest } = col;
        let template = null;
        let align = null;
        if (action) {
            template = action;
        } else if (money) {
            template = (e) => {
                const value = getPropObjetoAninhado(e, field);
                if (showZero) return parseMoeda(value, false);
                return value !== 0 ? parseMoeda(value, false) : "";
            };
            align = "right";
        } else if (dateFormat) {
            template = (e) => dataToStr(getPropObjetoAninhado(e, field), dateFormat);
            align = "center";
        } else if (decimal) {
            template = (e) =>
                new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(
                    parseNumber(e[field])
                );
        }
        if (typeof field === "string" && field.startsWith("action")) align = "center";
        return (
            <Column
                key={field || `col-${i}`}
                field={field}
                header={header}
                body={template}
                align={align}
                filterClear={limparFiltroTemplate}
                filterApply={aplicarFiltroTemplate}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                showFilterOperator={false}
                {...rest}
            />
        );
    };

    const colunasDinamicas = colunas.map((col, i) => gerarColunaDinamica(col, i));

    const colunasGroupDinamicas = !colunasGroup ? null : (
        <ColumnGroup>
            {React.Children.toArray(
                colunasGroup.map((row) => <Row>{row.map((col, i) => gerarColunaDinamica(col, i))}</Row>)
            )}
        </ColumnGroup>
    );

    const templatePaginacao = {
        layout: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];
            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none" }}>
                    Exibindo {options.first} a {options.last} de {options.totalRecords}
                </span>
            );
        },
    };

    const trocarPagina = (event) => {
        let _lazyParams = { ...lazyParams, ...event, filters: { ...lazyParams.filters } };
        setLazyParams(_lazyParams);
    };

    const ordenarRegistros = (event) => {
        let _lazyParams = { ...lazyParams, ...event, filters: { ...lazyParams.filters } };
        setLazyParams(_lazyParams);
    };

    const filtrarRegistros = (event) => {
        let _lazyParams = { ...lazyParams, ...event, first: 0 };
        setLazyParams(_lazyParams);
    };

    const exportCSV = () => {
        if (typeof handleExportarCsv === "function") handleExportarCsv();
        else listagemRef.current.exportCSV();
    };

    function filter(data, field, compare) {
        listagemRef.current.filter(data, field, compare);
    }

    const reatualizar = useCallback(() => {
        buscarDadosBackend();
        if (typeof onReatualizar === "function") onReatualizar();
    }, [buscarDadosBackend, onReatualizar]);

    const BotaoExportar = typeof botaoExportar === "function" ? botaoExportar : Button;

    const headerTabela =
        painelEsquerdo || botaoExportar || painelDireito || botaoReload ? (
            <div className="table-header">
                {filtarPorEmpresa && naoBuscarSemEmpresa && !empresaSelecionadaId ? (
                    <span className="p-error">Selecione uma empresa primeiro.</span>
                ) : painelEsquerdo || painelDireito || botaoExportar || botaoReload ? (
                    <>
                        <div className="p-text-left" style={{ width: "100%" }}>
                            {painelEsquerdo}
                            {botaoExportar ? (
                                <BotaoExportar
                                    label="Exportar"
                                    icon={MAKO_ICONS.DOWNLOAD}
                                    className="p-button-help p-mr-2"
                                    onClick={exportCSV}
                                    disabled={!dados.length}
                                />
                            ) : null}
                            {possuiFiltro && (
                                <Button
                                    label="Limpar filtro"
                                    icon={MAKO_ICONS.LIMPAR_FILTROS}
                                    className="p-button-outlined p-mr-2"
                                    onClick={limparFiltros}
                                />
                            )}
                        </div>
                        {painelDireito}
                        {botaoReload && (
                            <Button
                                icon={MAKO_ICONS.REATUALIZAR}
                                tooltip="Atualizar"
                                tooltipOptions={{ position: "top" }}
                                className="p-button-rounded p-button-secondary"
                                text
                                onClick={reatualizar}
                            />
                        )}
                    </>
                ) : null}
            </div>
        ) : null;

    return (
        <>
            {titulo && <h5>{titulo}</h5>}
            <div className="crud-demo">
                <DataTable
                    ref={listagemRef}
                    value={dados}
                    header={headerTabela}
                    loading={loading}
                    paginatorTemplate={templatePaginacao}
                    totalRecords={quantRegistros}
                    sortField={lazyParams?.sortField}
                    sortOrder={lazyParams?.sortOrder}
                    filters={lazyParams?.filters}
                    first={lazyParams?.first || 0}
                    rows={lazyParams?.rows || 20}
                    onFilter={filtrarRegistros}
                    onPage={trocarPagina}
                    onSort={ordenarRegistros}
                    emptyMessage={msgTabelaVazia || "Nenhum registro encontrado."}
                    className="p-datatable-sm mako-table"
                    filterDisplay="menu"
                    headerColumnGroup={colunasGroupDinamicas}
                    {...configTabela}
                >
                    {colunasDinamicas}
                </DataTable>
            </div>
        </>
    );
};

export const MakoListagem = forwardRef(Listagem);
export default MakoListagem;
