import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Tag } from "primereact/tag";

import { ModalVincularPessoas } from "./ModalVincularPessoas";
import { PageBase } from "@/components/PageBase";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoButton as Button } from "@/components/MakoButton";
import { MAKO_LICENCA_ADICIONAL } from "@/assets/constants/parametros";
import { parseNumber } from "@/assets/helpers/number";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useClearRefs from "@/hooks/useClearRefs";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import { BooleanFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import permissoes from "@/assets/constants/permissoes";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import useHttp from "@/hooks/useHttp";

export const VinculacaoPessoasPage = () => {
    const [loading, setLoading] = useState(false);
    const [empresas, setEmpresas] = useState(0);
    const [formatarDocumento] = useFormatCNPJCPF();

    const { httpPost, httpGet } = useHttp();
    const { showSuccess } = useToast();
    const { getParam } = useParam();

    const modalPessoaSecundariaRef = useRef(null);
    const listagemRef = useRef(null);

    useClearRefs(modalPessoaSecundariaRef, listagemRef);

    const licencasDisponiveis = useMemo(() => {
        const param = getParam(MAKO_LICENCA_ADICIONAL);
        if (param) return parseNumber(param.valor) - empresas;
        return 0;
    }, [getParam, empresas]);

    const buscarPessoasVinculadas = useCallback(
        async (signal) => {
            const handlers = {
                200: ({ data }) => {
                    setEmpresas(data?.count);
                },
            };
            setLoading(true);
            await httpGet({ url: "/pessoas/grupos-perfis/", signal }, handlers);
            setLoading(false);
        },
        [httpGet]
    );

    const alterarEmpresaPrincipal = async (empresaId) => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Empresa principal alterada com sucesso.",
                    life: 1500,
                });
                listagemRef.current?.buscarDados();
            },
        };
        setLoading(true);
        await httpPost({ url: `/pessoas/alterar-empresa-principal/${empresaId}/` }, handlers);
        setLoading(false);
    };

    const confirmarAlteracaoEmpresaPrincipal = (novaEmpresaPrincipal) => {
        confirmDialog({
            message: (
                <span>
                    Deseja tornar a empresa <b>{novaEmpresaPrincipal.nome} a principal do sistema</b>?
                </span>
            ),
            header: "Confirmação",
            icon: "pi pi-exclamation-triangle",
            accept: () => alterarEmpresaPrincipal(novaEmpresaPrincipal.id),
            acceptLabel: "Confirmar",
            rejectLabel: "Cancelar",
        });
    };

    const identificacaoBodyTemplate = (rowData) => {
        return <span>{formatarDocumento(rowData.perfil.identificacao)}</span>;
    };

    const hierarquiaBodyTemplate = (rowData) => {
        if (rowData.principal) return <Tag severity="success" value="PRINCIPAL" />;
        return <Tag style={{ background: "#64748B" }} value="ADICIONAL" />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon="pi pi-star"
                    disabled={rowData.principal}
                    tooltip="Tornar principal"
                    onClick={() => confirmarAlteracaoEmpresaPrincipal(rowData)}
                    className="p-button-rounded p-button-help"
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_EMPRESAS_VINCULACAO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/pessoas/grupos-perfis/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    disabled={rowData.principal}
                    msgConfirmacao={
                        <span>
                            Deseja realmente deletar o vínculo da empresa <b>{rowData.perfil.nome}</b>? (Essa ação pode
                            impactar em algumas áreas do sistema)
                        </span>
                    }
                    msgToastErroExclusao="O vínculo de empresa com o sistema não pode ser removido."
                    tooltip="Excluir"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Empresa removida com sucesso.",
                            life: 1500,
                        });
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        {
            field: "perfil.identificacao",
            header: "CPF / CNPJ",
            style: { width: "25%" },
            action: (e) => identificacaoBodyTemplate(e),
            filter: true,
            filterElemente: TextoFiltroTemplate,
            filterField: "perfil__identificacao",
        },
        {
            field: "perfil.nome",
            header: "Nome / Razão Social",
            filter: true,
            filterElemente: TextoFiltroTemplate,
            filterField: "perfil__perfil_pj__razao_social",
        },
        {
            field: "principal",
            header: "Participação",
            action: (e) => hierarquiaBodyTemplate(e),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        { field: "action", header: "Ações", style: { width: "8%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const painelEsquerdoTabela = (
        <>
            {licencasDisponiveis >= 1 ? (
                <Button
                    icon="pi pi-plus"
                    label={`Vincular pessoas (${licencasDisponiveis} ${
                        licencasDisponiveis === 1 ? "licença disponível" : "licenças disponíveis"
                    })`}
                    disabled={licencasDisponiveis <= 0 || loading}
                    onClick={() => modalPessoaSecundariaRef.current?.abrirModal()}
                    className="p-button-success"
                />
            ) : (
                <span className="p-error">Número máximo de licenças atingido ou não configurado.</span>
            )}
        </>
    );

    useEffect(() => {
        const abortController = new AbortController();
        buscarPessoasVinculadas(abortController.signal);
        return () => {
            abortController.abort();
        };
    }, [buscarPessoasVinculadas]);

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Gerenciamento de entidades gerenciadas pelo SysMako"
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                urlPesquisa={"/pessoas/grupos-perfis/"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    perfil__identificacao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    perfil__perfil_pj__razao_social: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    principal: { value: null, matchMode: "equals" },
                }}
            />
            <ModalVincularPessoas
                ref={modalPessoaSecundariaRef}
                principal={empresas.length === 0}
                onGravar={() => listagemRef.current?.buscarDados()}
            />
            <ConfirmDialog />
        </PageBase>
    );
};
