import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { ResumoDevolucao } from "./ResumoDevolucao";
import { MakoListagem } from "@/components/MakoListagem";
import useDevolucao from "@/hooks/useDevolucao";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "@/components/Dropdown";
import { InputText } from "primereact/inputtext";

export const DocFiscalDevolucaoForm = () => {
    const [informacoes, setInformacoes] = useState("");
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [exibirItens, setExibirItens] = useState(false);
    const [listaItens, setListaItens] = useState([]);
    const [listaOperacoes, setListaOperacoes] = useState([]);
    const { dadosBasicos } = useDevolucao();
    const history = useHistory();
    const listagemRef = useRef();
    const { showSuccess, showWarning } = useToast();
    const { empresaSelecionada, empresaSelecionadaId } = useEmpresa();
    const { httpGet, httpPost } = useHttp();

    async function handleEnviar() {
        try {
            if ((await empresaSelecionada?.enderecoperfil_set?.length) > 0) {
                const { cidade } = await empresaSelecionada?.enderecoperfil_set[0];
                const body = {
                    modelo: "55",
                    operacao_fiscal: itensSelecionados[0].operacao_fiscal,
                    natureza_operacao: null,
                    evento_id: dadosBasicos.id,
                    venda: null,
                    app_label: "vendas",
                    model: "devolucao",
                    tipo_operacao: 0,
                    transferencia: null,
                    dados_basicos: {
                        codigo_uf: cidade.estado?.codigo_uf,
                        emitente: dadosBasicos.empresa.id,
                        destinatario: dadosBasicos.cliente.id || null,
                        requer_identificacao: dadosBasicos.cliente?.id ? true : false,
                        indicador_pagamento: 3,
                        municipio_ocorrencia: cidade.id,
                        transportador: null,
                        indicador_final: 1,
                        formato_impressao: 0,
                        nota_referencia: dadosBasicos.entrada || null,
                        info_adicionais: "",
                    },
                    itens: itensSelecionados.map((item) => {
                        return {
                            ...item,
                            unidade: item.unidade_medida.sigla,
                            sku: item.sku.id,
                            valor_total: item.valor_unitario * item.quantidade,
                            valor_desconto: 0,
                            valor_frete: 0,
                            valor_seguro: 0,
                            quantidade_nf: item.quantidade,
                            outras_despesas: 0,
                            municipio_issqn: cidade.id,
                            municipio_incidencia: cidade.id,
                            app_label: "vendas",
                            model: "itemtrocadevolucao",
                        };
                    }),
                };

                const handlers = {
                    200: () => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Nota fiscal incluida com sucesso!",
                            life: 3000,
                        });
                    },
                    400: ({ err }) =>
                        showWarning({
                            summary: "Falha ao inserir",
                            detail: `Não foi possível inserir a nota fiscal, verifique as informações da operação fiscal e tente novamente (${err.erro}.)`,
                            life: 5000,
                        }),
                };

                await httpPost({ url: "/fiscal/inserir-nota-fiscal/", body }, handlers);
            }
        } catch (e) {
            showWarning({
                summary: "Cadastro Incompleto!",
                detail: "A empresa atual não possui todas as informações registradas, verifique o cadastro e tente novamente.",
                life: 5000,
            });
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status === "C" || rowData.status === "T"}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-saida/emissao-nota",
                            state: { notaFiscal: rowData },
                        })
                    }
                />
            </div>
        );
    };

    const verificaEmissao = (chave) => {
        if (chave) return chave;
        else return "NÃO TRANSMITIDO";
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "-";
    };

    const colunas = [
        {
            field: "numero",
            header: "Número",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "serie",
            header: "Série",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "modelo",
            header: "Modelo",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "chave_nf",
            header: "Chave",
            action: (e) => verificaEmissao(e.chave_nf),
            filter: true,
            align: "left",
            style: { minWidth: "400px" },
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            filter: true,
            style: { minWidth: "200px" },
        },
        {
            field: "valor_total_nf",
            header: "Total Nf",
            filter: true,
            align: "right",
            style: { minWidth: "120px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const painelEsquerdo = (
        <MakoControleAcesso
            permissao={[permissoes.VENDAS_VENDA_DOCFISCALVENDA_INCLUIR]}
            componente={Button}
            type="button"
            label="Gerar Documento Fiscal"
            onClick={() => setExibirItens(true)}
            className="p-mr-2"
        />
    );

    const rowClassNF = (rowData) => {
        return {
            "mako-table-status-inativo": rowData.status === "C",
            "table-recebimentos-effective": rowData.status === "T",
        };
    };

    const aposPesquisar = useCallback((results) => {
        const _list = results.map((item, index) => ({
            ...item,
            aux_quantidade: index + 1,
            quantidade_nf: item.quantidade,
        }));
        setListaItens(_list);
        return _list;
    }, []);

    const fecharDialog = () => {
        setInformacoes(null);
        setExibirItens(false);
    };

    const editarQuantidade = (options, quantidade, aux) => {
        options.editorCallback(quantidade);
        setListaItens(
            listaItens.map((item) => {
                if (item.aux_quantidade === aux) item.quantidade_nf = quantidade;
                return item;
            })
        );
    };

    const textEditor = (options) => {
        return (
            <InputText
                type="text"
                value={options.value}
                onChange={(e) => editarQuantidade(options, e.target.value, options.rowData.aux_quantidade)}
            />
        );
    };

    const editarOperacao = (options, operacao, aux) => {
        options.editorCallback(operacao);
        setListaItens(
            listaItens.map((item) => {
                if (item.id === aux) item.operacao_fiscal = operacao;
                return item;
            })
        );
    };

    const operacaoEditor = (options) => {
        return (
            <Dropdown
                id="operacao_fiscal"
                name="operacao_fiscal"
                options={listaOperacoes}
                optionValue="id"
                optionLabel="descricao"
                style={{ width: "100%" }}
                value={options.value}
                onChange={(e) => editarOperacao(options, e.target.value, options.rowData.id)}
            />
        );
    };

    const colunasItens = [
        { selectionMode: "multiple", headerStyle: { width: "3em" } },
        { field: "sku.codigo", header: "Código", style: { minWidth: "80px" } },
        { field: "sku.descricao_reduzida", header: "Item", style: { minWidth: "250px" } },
        { field: "sku.item.ncm.codigo", header: "NCM" },
        {
            field: "quantidade",
            header: "Quant",
            style: { minWidth: "100px" },
            decimal: true,
        },
        {
            field: "quantidade_nf",
            header: "Qtd. NF",
            decimal: true,
            style: { minWidth: "100px" },
            editor: (options) => textEditor(options),
        },
        {
            field: "operacao_fiscal",
            header: "Operação Fiscal",
            decimal: true,
            style: { minWidth: "300px" },
            editor: (options) => operacaoEditor(options),
            action: ({ operacao_fiscal }) => (
                <Dropdown
                    id="operacao_fiscal"
                    name="operacao_fiscal"
                    options={listaOperacoes}
                    optionValue="id"
                    optionLabel="descricao"
                    style={{ width: "100%" }}
                    value={operacao_fiscal}
                />
            ),
        },
    ];

    const sugerirOperacoesNF = useCallback(async () => {
        const handlers = {
            200: async ({ data }) => {
                let lista = listaItens;
                data.operacoes?.forEach((op, index) => {
                    lista[index]["operacao_fiscal"] = op;
                });
                setItensSelecionados(lista.filter(({ operacao_fiscal }) => operacao_fiscal));
                setListaItens(lista);
                const nao_corresponde = lista.filter(({ operacao_fiscal }) => !operacao_fiscal);
                if (nao_corresponde?.length > 0)
                    showWarning({
                        summary: "Alerta!",
                        detail: `Atenção, alguns itens não possuem operação fiscal correspondente (${nao_corresponde
                            .map((e) => e.sku.descricao_reduzida)
                            .join(", ")}).`,
                        life: 5000,
                    });
            },
        };

        await httpPost(
            {
                url: `/fiscal/sugerir-operacoes-fiscais/${empresaSelecionadaId}/`,
                body: {
                    itens: listaItens,
                    devolucao: true,
                },
            },
            handlers
        );
    }, [listaItens, empresaSelecionadaId, httpPost, showWarning]);

    const listarOperacoes = useCallback(async () => {
        if (!empresaSelecionadaId) return;

        const params = {
            empresa: empresaSelecionadaId,
            tipo_movimento: "E",
            limit: 1000,
            ativo: true,
        };

        const handlers = {
            200: async ({ data }) => setListaOperacoes(data.results),
        };

        await httpGet(
            {
                url: "/fiscal/operacoes-fiscais/",
                params,
            },
            handlers
        );
    }, [empresaSelecionadaId, httpGet]);

    useEffect(() => {
        listarOperacoes();
    }, [listarOperacoes]);

    return (
        <>
            <ResumoDevolucao devolucao={dadosBasicos} />
            <MakoListagem
                ref={listagemRef}
                painelEsquerdo={painelEsquerdo}
                titulo="Documentos fiscais da venda"
                urlPesquisa={`/fiscal/notas-fiscais?evento_id=${dadosBasicos.id}&evento_content_type__model=devolucao`}
                colunas={colunas}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    rowClassName: rowClassNF,
                }}
            />
            <Dialog
                header={"Selecione itens para emissão do documento fiscal:"}
                visible={exibirItens}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", display: "block" }}
                onHide={() => fecharDialog(false)}
            >
                <MakoListagem
                    colunas={colunasItens}
                    urlPesquisa={`/vendas/itens-troca-devolucao/?devolucao=${dadosBasicos?.id}`}
                    aposPesquisar={aposPesquisar}
                    configTabela={{
                        editMode: "cell",
                        selectionMode: "multiple",
                        selection: itensSelecionados,
                        onSelectionChange: (e) => setItensSelecionados(e.value),
                    }}
                />
                <div className="p-fluid p-formgrid p-grid p-mt-3">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="informacoes">Informações adicionais</label>
                        <InputTextarea
                            id="informacoes"
                            name="informacoes"
                            rows={3}
                            value={informacoes}
                            onChange={(e) => setInformacoes(e.target.value)}
                        />
                    </div>
                </div>
                <Button
                    type="button"
                    icon="pi pi-plus"
                    label="Gerar nota"
                    className="p-mt-4"
                    disabled={!itensSelecionados}
                    onClick={() => handleEnviar()}
                />
                <Button
                    type="button"
                    icon="pi pi-question"
                    label="Sugerir operações"
                    className="p-mt-4 p-ml-2 p-button-help"
                    onClick={() => sugerirOperacoesNF()}
                />
            </Dialog>
        </>
    );
};
