import { OrdemCompraPage } from "@/pages/Compras/OrdemCompra/OrdemCompra";
import { TabOrdemCompraContext } from "@/pages/Compras/OrdemCompra/OrdemCompra/form";
import { CategoriaOcPage } from "@/pages/Compras/OrdemCompra/CategoriaOC";
import { CategoriaOcForm } from "@/pages/Compras/OrdemCompra/CategoriaOC/form";
import { CadastroServicosPage } from "@/pages/Compras/Cadastros/Servicos";
import { CadastroServicosForm } from "@/pages/Compras/Cadastros/Servicos/form";
import { ConsultarOrdemCompraPage } from "@/pages/Compras/OrdemCompra/ConsultarOC";
import { TiposEntradaPage } from "@/pages/Compras/Cadastros/TiposEntrada";
import { TiposEntradaForm } from "@/pages/Compras/Cadastros/TiposEntrada/form";
import { DestinacaoEntradaPage } from "@/pages/Compras/Cadastros/DestinacaoEntrada";
import { DestinacaoEntradaForm } from "@/pages/Compras/Cadastros/DestinacaoEntrada/form";
import { EntradaProdutosPage } from "@/pages/Compras/Entrada/EntradaProdutos";
import { TabEntradaProdutosContext } from "@/pages/Compras/Entrada/EntradaProdutos/form";
import { DevolucaoFornecedorPage } from "@/pages/Compras/Entrada/DevolucaoFornecedor";
import { TabDevolucaoFornecedorContext } from "@/pages/Compras/Entrada/DevolucaoFornecedor/form";
import { RegistroNecessidadeCompraPage } from "@/pages/Compras/Cadastros/RegistroNecessidadeCompra";
import { TipoDevolucaoPage } from "@/pages/Compras/Cadastros/TipoDevolucaoFornecedor";
import { TipoDevolucaoForm } from "@/pages/Compras/Cadastros/TipoDevolucaoFornecedor/form";

export const comprasRoutes = [
    { path: "/compras/ordens-compra/ordens-compra", component: OrdemCompraPage },
    { path: "/compras/ordens-compra/ordens-compra/form", component: TabOrdemCompraContext },
    { path: "/compras/ordens-compra/categorias-ordens-compra", component: CategoriaOcPage },
    { path: "/compras/ordens-compra/categorias-ordens-compra/form", component: CategoriaOcForm },
    { path: "/compras/ordens-compra/servicos-ordens-compra", component: CadastroServicosPage },
    { path: "/compras/ordens-compra/servicos-ordens-compra/form", component: CadastroServicosForm },
    { path: "/compras/ordens-compra/consultar-ordens-compra", component: ConsultarOrdemCompraPage },
    { path: "/compras/cadastros/tipos-entrada", component: TiposEntradaPage },
    { path: "/compras/cadastros/tipos-entrada/form", component: TiposEntradaForm },
    { path: "/compras/cadastros/destinacoes", component: DestinacaoEntradaPage },
    { path: "/compras/cadastros/destinacoes/form", component: DestinacaoEntradaForm },
    { path: "/compras/cadastros/tipo-devolucao", component: TipoDevolucaoPage },
    { path: "/compras/cadastros/tipo-devolucao/form", component: TipoDevolucaoForm },
    { path: "/compras/cadastros/registro-necessidade-compra", component: RegistroNecessidadeCompraPage },
    { path: "/compras/entrada/entrada-produtos", component: EntradaProdutosPage },
    { path: "/compras/entrada/entrada-produtos/form", component: TabEntradaProdutosContext },
    { path: "/compras/entrada/devolucao-fornecedor", component: DevolucaoFornecedorPage },
    { path: "/compras/entrada/devolucao-fornecedor/form", component: TabDevolucaoFornecedorContext },
];
