import React, { useRef } from "react";

import { PageBase } from "@/components/PageBase";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoButton as Button } from "@/components/MakoButton";
import { NATUREZA_FINANCEIRA } from "@/assets/constants/financeiro";
import permissoes from "@/assets/constants/permissoes";
import { DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

export const MapeadorTemplateImportacaoExtratoPage = () => {
    const listagemRef = useRef(null);

    const header = (
        <MakoControleAcesso
            permissao={[permissoes.FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_INCLUIR]}
            componente={Button}
            label="Nova"
            icon={MAKO_ICONS.NOVO}
            className="p-button-success"
            to={"/financeiro/cadastros/mapeador-templates-importacao-extratos/form"}
        />
    );

    const naturezaBodyTemplate = (rowData) => {
        const natureza = NATUREZA_FINANCEIRA.find((el) => el.value === rowData.natureza);
        if (natureza) return <span>{natureza.label}</span>;
        return <span>N/A</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    tooltip="Alterar mapeador de importação de extrato"
                    tooltipOptions={{ position: "left" }}
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/financeiro/cadastros/mapeador-templates-importacao-extratos/form",
                        state: rowData,
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/financeiro/templates-importacao-extratos-mapeadores/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente excluir o mapeador de importação de extrato?</span>}
                    msgToastErroExclusao="O mapeador de importação de extrato não pode ser excluído."
                    tooltip="Deletar mapeador de importação de extrato"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const TemplateFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "descricao",
                    url: "/financeiro/templates-importacao-extratos/?limit=300",
                }}
                options={options}
            />
        );
    };

    const PlanoContasFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "descricao",
                    url: "/financeiro/planos-contas-financeiras/?limit=300",
                }}
                options={options}
            />
        );
    };

    const NaturezaFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: NATUREZA_FINANCEIRA,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "template.descricao",
            header: "Template",
            filter: true,
            filterElement: TemplateFiltroTemplate,
            filterField: "template",
        },
        {
            field: "plano_contas.descricao",
            header: "Plano de contas",
            filter: true,
            filterElement: PlanoContasFiltroTemplate,
            filterField: "plano_contas",
        },
        {
            field: "natureza",
            header: "Natureza",
            style: { width: "10%" },
            action: naturezaBodyTemplate,
            filter: true,
            filterElement: NaturezaFiltroTemplate,
        },
        { field: "deve_conter", header: "Deve conter", filter: true, filterElement: TextoFiltroTemplate },
        { field: "nao_deve_conter", header: "Não deve conter", filter: true, filterElement: TextoFiltroTemplate },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: actionBodyTemplate },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Mapeador de importação de extrato"
                urlPesquisa="/financeiro/templates-importacao-extratos-mapeadores/"
                colunas={colunas}
                painelEsquerdo={header}
                configTabela={{ paginator: true, lazy: true }}
                filtros={{
                    template: { value: null, matchMode: "equals" },
                    plano_contas: { value: null, matchMode: "equals" },
                    natureza: { value: null, matchMode: "equals" },
                    nao_deve_conter: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    deve_conter: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
