import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import MakoListagem from "@/components/MakoListagem";

import { InputText } from "primereact/inputtext";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_FINANCEIRO_MOVIMENTACAOCAIXA } from "@/assets/constants/relatorios";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { gerarFileName } from "@/assets/util/util";

const { ExportCSV } = R.Buttons;

const FILTROS_VIEWSET = {
    controle: "caixa_movimento",
    caixa: "caixa_movimento__caixa",
};

const BASE_URL = "/relatorios/resumo-caixa/";

const FILE_NAME = gerarFileName("Resumo caixa");

export const ResumoCaixa = ({ onFinish }) => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const { empresaSelecionadaId } = useEmpresa();
    const { solicitarRelatorio } = useRelatorio();
    const { caixaMov } = useCaixaMovimento();

    const empresaSelecionada = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(empresaSelecionada, formRef, listagemRef, botaoCsv);

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        dadosValidados.caixa = caixaMov.caixa;
        if (dadosValidados.caixa) dadosValidados.caixa = dadosValidados.caixa.id;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "controle",
            label: "Controle",
        },
        {
            key: "caixa",
            label: "Caixa",
            path: "nome",
        },
    ];

    const cellClass = (value, { field }) => {
        return {
            "mako-table-overdue-text": field === "resultado" && value < 0,
            "mako-table-effective-text": field === "resultado" && value > 0,
        };
    };

    async function handleSubmit({ background, emails, corpo_email, ...values } = null) {
        try {
            values.caixa = caixaMov.caixa;

            const formSchema = Yup.object().shape({
                empresa: Yup.number()
                    .integer()
                    .required("O campo 'empresa' é obrigatório.")
                    .typeError("Selecione uma empresa"),
                caixa: Yup.object().required("O campo 'caixa' é obrigatório.").typeError("Selecione uma caixa"),
                controle: Yup.string().required("O campo 'controle' é obrigatório.").typeError("Informe um controle"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });

            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.caixa) dadosValidados.caixa = dadosValidados.caixa.id;

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_FINANCEIRO_MOVIMENTACAOCAIXA,
                    emails,
                    filtros,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
                if (onFinish && typeof onFinish === "function") onFinish();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        if (k !== "empresa") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    const colunas = [
        {
            field: "forma_pgto",
            header: "Forma",
            action: ({ forma_pgto }) =>
                TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.find(({ id }) => id === forma_pgto)?.label || forma_pgto,
        },
        { field: "entrada", header: "Entradas", money: true },
        { field: "saida", header: "Saidas", money: true },
        { field: "resultado", header: "Resultado", money: true },
    ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_FINANCEIRO_MOVIMENTACAOCAIXA}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    return (
        <R.Wrapper titulo={null} pageBase={false}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        caixa: caixaMov?.caixa.nome,
                        empresa: empresaSelecionadaId,
                        controle: caixaMov.id,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa",
                        inputId: "empresa",
                        inputName: "empresa",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        required: true,
                        componentProps: {
                            disabled: true,
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Caixa",
                        inputId: "caixa",
                        inputName: "caixa",
                        required: true,
                        disabled: true,
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                            autoComplete: "off",
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Controle",
                        inputId: "controle",
                        inputName: "controle",
                        required: true,
                        disabled: true,
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                        },
                        fieldSize: 2,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_FINANCEIRO_MOVIMENTACAOCAIXA}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                        cellClassName: cellClass,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
