import React from "react";

import { ProdutoUnidadeMedidaFormBase } from "./formBase";
import { PageBase } from "@/components/PageBase";

export const ProdutoUnidadeMedidaForm = ({ closeDialog = () => {}, unidade = false, location }) => {
    return (
        <PageBase>
            <ProdutoUnidadeMedidaFormBase
                closeDialog={closeDialog}
                unidade={unidade}
                unidadeMedida={location?.state?.unidadeMedida}
            />
        </PageBase>
    );
};
