import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { OrderList } from "primereact/orderlist";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

export const SequenciaEstagioPage = (props) => {
    const [estagioVenda, setEstagioVenda] = useState(null);
    const [sequenciaVenda, setSequenciaVenda] = useState([]);
    const [tiposEstagiosVenda, setTiposEstagiosVenda] = useState([]);
    const [tipoEstagioSelecionado, setTipoEstagioSelecionado] = useState(null);
    const [deleteSequencia, setDeleteSequencia] = useState([]);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { httpGet, httpPut } = useHttp();
    const { showSuccess } = useToast();

    const buscarTiposEstagioVenda = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                setTiposEstagiosVenda(data.results);
            },
        };

        showLoading();
        await httpGet({ url: "/vendas/tipos-estagios-vendas/?ativo=true" }, handlers);
        hideLoading();
    }, [showLoading, hideLoading, httpGet]);

    useEffect(() => {
        if (props.location.state) {
            setEstagioVenda(props.location.state.estagioVenda);

            const sequencia = props.location.state.estagioVenda.sequenciaestagiovenda_set.map((seq) => {
                return {
                    id_sequencia: seq.id,
                    retorna: seq.retorna,
                    obrigatorio: seq.obrigatorio,
                    altera_itens: seq.altera_itens,
                    ...seq.tipo_estagio_venda,
                };
            });

            setSequenciaVenda(sequencia);
            buscarTiposEstagioVenda();
        }
    }, [props.location.state, buscarTiposEstagioVenda]);

    async function handleSubmit() {
        let create = [];
        let update = {};

        sequenciaVenda.forEach((value, index) => {
            if (value.id_sequencia) {
                update = {
                    ...update,
                    [value.id_sequencia]: {
                        sequencia: index + 1,
                        ativo: true,
                        retorna: !!value.retorna,
                        obrigatorio: index + 1 === 1 ? true : !!value.obrigatorio,
                        altera_itens: index + 1 === 1 ? true : !!value.altera_itens,
                        tipo_estagio_venda: value.id,
                    },
                };
            } else {
                create.push({
                    sequencia: index + 1,
                    ativo: true,
                    retorna: !!value.retorna,
                    obrigatorio: index + 1 === 1 ? true : !!value.obrigatorio,
                    altera_itens: index + 1 === 1 ? true : !!value.altera_itens,
                    tipo_estagio_venda: value.id,
                });
            }
        });

        const crud = {
            descricao: estagioVenda.descricao,
            sequenciaestagiovenda_set: {
                create: create,
                update: update,
                remove: deleteSequencia,
            },
        };

        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Sequência alterada com sucesso!",
                    life: 1500,
                });

                setTimeout(() => {
                    setSequenciaVenda([]);
                    setTipoEstagioSelecionado(null);
                    setDeleteSequencia([]);
                    history.push("/vendas/cadastros/estagios-vendas");
                }, 1500);
            },
        };

        showLoading();
        await httpPut({ url: `/vendas/estagios-vendas/${estagioVenda.id}/`, body: crud }, handlers);
        hideLoading();
    }

    const inserirSequenciaTipoEstagio = () => {
        if (sequenciaVenda.length === 0) {
            setSequenciaVenda([tipoEstagioSelecionado]);
        } else {
            let novaSequencia = true;
            for (let x = 0; x < sequenciaVenda.length; x++) {
                if (sequenciaVenda[x].id === tipoEstagioSelecionado.id) {
                    novaSequencia = false;
                    break;
                }
            }

            if (novaSequencia) {
                setSequenciaVenda([
                    ...sequenciaVenda,
                    ...tiposEstagiosVenda.filter((value) => value.id === tipoEstagioSelecionado.id),
                ]);
            }
        }
        setTipoEstagioSelecionado(null);
    };

    const onDeleteSequencia = (item) => {
        setSequenciaVenda(sequenciaVenda.filter((i) => i.id !== item.id));

        if (item.id_sequencia) {
            setDeleteSequencia([...deleteSequencia, item.id_sequencia]);
        }
    };

    const itemTemplate = (item) => {
        const setRetorna = (e) => {
            setSequenciaVenda(
                sequenciaVenda.reduce(
                    (acc, o) => acc.concat(o.id === item.id ? Object.assign(o, { retorna: e }) : o),
                    []
                )
            );
        };

        const setObrigatorio = (e) => {
            setSequenciaVenda(
                sequenciaVenda.reduce(
                    (acc, o) => acc.concat(o.id === item.id ? Object.assign(o, { obrigatorio: e }) : o),
                    []
                )
            );
        };

        const setAlteraItens = (e) => {
            setSequenciaVenda(
                sequenciaVenda.reduce(
                    (acc, o) => acc.concat(o.id === item.id ? Object.assign(o, { altera_itens: e }) : o),
                    []
                )
            );
        };

        return (
            <>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <h5>{item.descricao}</h5>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2">
                        <Checkbox
                            id="retorna"
                            name="retorna"
                            checked={item.retorna}
                            onChange={(e) => setRetorna(e.checked)}
                        />
                        <label htmlFor="retorna">
                            Pode desfazer? <b>{item.retorna ? "Sim" : "Não"}</b>
                        </label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2">
                        <Checkbox
                            id="obrigatorio"
                            name="obrigatorio"
                            checked={item.obrigatorio}
                            onChange={(e) => setObrigatorio(e.checked)}
                        />
                        <label htmlFor="obrigatorio">
                            Obrigatório? <b>{item.obrigatorio ? "Sim" : "Não"}</b>
                        </label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3">
                        <Checkbox
                            id="altera-itens"
                            name="altera_itens"
                            checked={item.altera_itens}
                            onChange={(e) => setAlteraItens(e.checked)}
                        />
                        <label htmlFor="altera-itens">
                            Permite alterar os itens da venda? <b>{item.altera_itens ? "Sim" : "Não"}</b>
                        </label>
                    </div>
                    <div className="p-field p-col-12 p-md-1">
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger p-mr-2"
                            tooltip="Excluir item."
                            tooltipOptions={{ position: "left" }}
                            onClick={() => onDeleteSequencia(item)}
                        />
                    </div>
                </div>
                <div className="p-grid p-mt-2">
                    <div className="p-col">
                        <div className="box">
                            <b>Baixa estoque: </b>
                            {`${item.baixa_estoque ? "Sim" : item.baixa_estoque === false ? "Não" : "Mantém"}`}
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="box">
                            <b>Gera comissão: </b>
                            {`${item.gera_comissao ? "Sim" : item.gera_comissao === false ? "Não" : "Mantém"}`}
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="box">
                            <b>Gera doc. fiscal: </b>
                            {`${
                                item.gera_documento_fiscal
                                    ? "Sim"
                                    : item.gera_documento_fiscal === false
                                    ? "Não"
                                    : "Mantém"
                            }`}
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="box">
                            <b>Gera financeiro: </b>
                            {`${item.gera_financeiro ? "Sim" : item.gera_financeiro === false ? "Não" : "Mantém"}`}
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="box">
                            <b>Reserva estoque: </b>
                            {`${item.reserva_estoque ? "Sim" : item.reserva_estoque === false ? "Não" : "Mantém"}`}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <PageBase>
            <h5>Edição de fluxo de venda</h5>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="tipos-estagios">Tipos de estágios de venda</label>
                    <div className="p-inputgroup">
                        <Dropdown
                            id="tipos-estagios"
                            options={tiposEstagiosVenda}
                            placeholder="Selecione..."
                            optionLabel="descricao"
                            value={tipoEstagioSelecionado}
                            onChange={(e) => setTipoEstagioSelecionado(e.value)}
                        />
                        <Button
                            icon="pi pi-plus"
                            className="p-button-success"
                            type="button"
                            disabled={!tipoEstagioSelecionado}
                            onClick={() => inserirSequenciaTipoEstagio()}
                        />
                    </div>
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <OrderList
                        dataKey="id"
                        value={sequenciaVenda}
                        itemTemplate={itemTemplate}
                        header={estagioVenda?.descricao}
                        onChange={(e) => setSequenciaVenda(e.value)}
                    />
                </div>
            </div>
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <MakoControleAcesso
                        permissao={[permissoes.VENDAS_CADASTRO_ESTAGIOVENDA_SEQUENCIA_MANUTENCAO]}
                        componente={Button}
                        icon="pi pi-check"
                        label="Gravar"
                        className="p-mr-2 p-mb-2"
                        onClick={handleSubmit}
                    />
                    <Button
                        icon="pi pi-step-backward"
                        label="Voltar"
                        className="p-button-warning p-mr-2 p-mb-2"
                        onClick={() => history.push("/vendas/cadastros/estagios-vendas")}
                    />
                </div>
            </div>
        </PageBase>
    );
};
