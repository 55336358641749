import { ConfirmarCrediario } from "./confirmar-crediario";
import { EmitirBoleto } from "./emitir-boleto";
import { ReceberEntrada } from "./receber-entrada";
import { ReceberCartao } from "./receber-cartao";
import { ReceberCrediario } from "./receber-crediario";
import { Pagar } from "./pagar";

export const Buttons = {
    ConfirmarCrediario,
    ReceberEntrada,
    ReceberCartao,
    ReceberCrediario,
    EmitirBoleto,
    Pagar,
};
