import { useContext } from "react";

import CaixaMovimentoContext from "@/contexts/caixaMovimentoContext";

const useCaixaMovimento = () => {
    const {
        caixaMov,
        loadCaixa,
        obterCaixaAbertoMaisRecentePorEmpresa,
        setCaixaUsuario,
        padraoPlanos,
        clearCaixa,
        atualizarResumos,
        possuiPendencia,
        verificarPendencias,
    } = useContext(CaixaMovimentoContext);

    return {
        caixaMov,
        loadCaixa,
        setCaixaUsuario,
        padraoPlanos,
        clearCaixa,
        obterCaixaAbertoMaisRecentePorEmpresa,
        atualizarResumos,
        possuiPendencia,
        verificarPendencias,
    };
};

export default useCaixaMovimento;
