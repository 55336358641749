import React, { useImperativeHandle, useState, forwardRef } from "react";
import XMLViewer from "react-xml-viewer";
import { Dialog } from "primereact/dialog";

import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [xml, setXML] = useState("");
    const { showWarning, showError } = useToast();
    const { httpGet } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    const obterXML = async (xmlId) => {
        const handlers = {
            200: ({ data }) => setXML(data.xml),
            error: ({ status, err }) => {
                if (status !== 500) {
                    showWarning({
                        summary: "Aviso!",
                        detail: err?.msg || err?.detail || "Não foi possível carregar o XML.",
                        life: 3000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Não foi possível carregar o XML.",
                        life: 3000,
                    });
                }
            },
        };
        showLoading();
        await httpGet({ url: `/compras/entradas-xml/${xmlId}/xml` }, handlers);
        hideLoading();
    };

    const abrirModal = (xmlId) => {
        setVisible(true);
        if (xmlId) obterXML(xmlId);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog visible={visible} onHide={() => setVisible(false)} header="Detalhes do XML" style={{ width: "70vw" }}>
            <div className={loading ? "p-text-center" : ""}>
                {loading ? (
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }} />
                ) : (
                    <XMLViewer xml={xml} collapsible />
                )}
            </div>
        </Dialog>
    );
};

export const ModalVerXML = forwardRef(Modal);
