import React, { useCallback } from "react";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { gerarStringAleatoria } from "@/assets/util/util";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const MakoInputCodigoAleatorio = ({
    onGerarCodigo = () => {},
    apenasNumeros = false,
    length = 15,
    disabled = false,
    ...props
}) => {
    const gerarCodigo = useCallback(() => {
        const codigo = gerarStringAleatoria(length, apenasNumeros);
        onGerarCodigo(codigo);
    }, [apenasNumeros, length, onGerarCodigo]);

    return (
        <div className="p-inputgroup">
            <InputText disabled={disabled} {...props} />
            <Button
                type="button"
                icon={MAKO_ICONS.REATUALIZAR}
                className="p-button-info"
                tooltip="Gerar código aleatório"
                disabled={disabled}
                onClick={gerarCodigo}
            />
        </div>
    );
};
