import React, { useRef, useMemo } from "react";

import { PageBase } from "@/components/PageBase";
import { MakoButton as Button } from "@/components/MakoButton";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import useAuth from "@/hooks/useAuth";
import { AtivoFiltroTemplate, DateTimeFiltroTemplate, PerfilFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";

export const TokensIntegracaoPage = () => {
    const listagemRef = useRef(null);
    const { verifyPermission, user } = useAuth();

    const urlPesquisa = useMemo(() => {
        const temPermissao = verifyPermission([permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_CONSULTAR_TERCEIRO]);
        if (temPermissao) {
            return "/integracoes/tokens-usuario/";
        } else {
            return `/integracoes/tokens-usuario/?usuario=${user.id}`;
        }
    }, [verifyPermission, user.id]);

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[
                    permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_INCLUIR,
                    permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_INCLUIR_TERCEIRO,
                ]}
                componente={Button}
                exibirDesabilitadoSemPermissao
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/gestao/usuarios/tokens/form"}
            />
        </>
    );

    const usuarioBodyTemplate = (rowData) => {
        return <span>{`${rowData.usuario.nome} (${rowData.usuario.usuario.username})`}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/gestao/usuarios/tokens/form",
                        state: rowData,
                    }}
                />
                <Button
                    icon="pi pi-copy"
                    className="p-button-rounded p-button-info"
                    onClick={() => navigator.clipboard.writeText(rowData.token)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/integracoes/tokens-usuario/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao="Deseja mesmo realizar a exclusão do token?"
                    msgToastErroExclusao="O token não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        {
            field: "usuario",
            header: "Usuário",
            action: (e) => usuarioBodyTemplate(e),
            filter: true,
            filterElement: PerfilFiltroTemplate,
        },
        {
            field: "datahora_criacao",
            header: "Data / hora criação",
            dateFormat: "dd/MM/yyyy HH:mm:ss",
            filter: true,
            filterElement: DateTimeFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Situação",
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "INATIVO"),
            filter: true,
            filterElement: AtivoFiltroTemplate,
        },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Tokens de integração"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={urlPesquisa}
                configTabela={{
                    lazy: true,
                    paginator: true,
                }}
                filtros={{
                    usuario: { value: "", matchMode: "equals" },
                    datahora_criacao: { value: "", matchMode: "equals" },
                    ativo: { value: "", matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
