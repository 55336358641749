import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";

import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoInputParametro } from "../../MakoInputParametro";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { InputPerfil } from "../Form/sessoes/cadastrar/inputPerfil";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { GRUPO_CHAVE_PARAMETRO, TIPO_CHOICE_PARAMETRO } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

const { OUTROS_PARAMETROS_EDITARORIENTACOES } = permissoes;

const Component = ({ onSuccessCallback = () => {} }, ref) => {
    const [parametro, setParametro] = useState(null);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { verifyPermission } = useAuth();
    const { showSuccess } = useToast();
    const { loadParams } = useParam();
    const { httpPatch } = useHttp();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            chave: null,
            valor: "",
            perfil: null,
            orientacao: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                orientacao: Yup.string()
                    .required("O campo 'orientacao' é obrigatório")
                    .typeError("Informe uma 'orientacao' válida."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const handlers = {
                200: ({ data }) => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Orientação atualizada!",
                        life: 2000,
                    });
                    resetForm();
                    loadParams();
                    onSuccessCallback(data);
                },
            };
            showLoading();
            await httpPatch(
                {
                    url: `/configuracoes/parametros-chaves/${parametro?.id}/`,
                    body: {
                        orientacao: values.orientacao,
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const preencherParametro = useCallback(
        (value) => {
            const { id, chave, perfil, valor, valor_id } = value || {};
            setParametro(chave);
            setValues({
                id,
                chave: chave.id,
                perfil: perfil?.id,
                valor: valor_id > 0 ? valor_id : valor,
                orientacao: chave?.orientacao,
            });
        },
        [setValues]
    );

    const disabledOrientacao = useMemo(() => {
        return !verifyPermission([OUTROS_PARAMETROS_EDITARORIENTACOES]);
    }, [verifyPermission]);

    useImperativeHandle(ref, () => ({ setParametro: preencherParametro }), [preencherParametro]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="descricao" label="Descrição" />
                    <InputText id="descricao" name="descricao" value={parametro?.descricao} disabled />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-8">
                    <Label htmlFor="chave" label="Chave" />
                    <InputText id="chave" name="chave" value={parametro?.chave} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="tipo" label="Tipo" />
                    <Dropdown
                        id="tipo"
                        name="tipo"
                        options={TIPO_CHOICE_PARAMETRO}
                        optionValue="value"
                        optionLabel="label"
                        value={parametro?.tipo}
                        dropdownIcon={" "}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="grupo" label="Grupo" />
                    <Dropdown
                        id="grupo"
                        name="grupo"
                        options={GRUPO_CHAVE_PARAMETRO}
                        placeholder="--"
                        optionValue="value"
                        optionLabel="label"
                        dropdownIcon={" "}
                        value={parametro?.grupo}
                        disabled
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="valor" label="Valor" obrigatorio />
                    <MakoInputParametro
                        id="valor"
                        name="valor"
                        chaveParametro={parametro}
                        disabled
                        value={formik.values.valor}
                        onChangeParametro={(e) => setFieldValue("valor", e)}
                        className={classNames({ "p-invalid": formik.errors.valor })}
                    />
                    {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="perfil" label="Perfil vinculado" obrigatorio={parametro?.requer_perfil} />
                    <InputPerfil
                        id="perfil"
                        name="perfil"
                        disabled
                        value={formik.values.perfil}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.perfil })}
                    />
                    {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="orientacao" label="Orientação" />
                    <Editor
                        id="orientacao"
                        name="orientacao"
                        value={formik.values.orientacao}
                        onTextChange={(e) => setFieldValue("orientacao", e.htmlValue)}
                        style={{ height: "200px" }}
                        readOnly={disabledOrientacao}
                        className={classNames({ "p-invalid": formik.errors.orientacao })}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid p-d-flex p-jc-end">
                <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "4px" }}>
                    <MakoControleAcesso
                        componente={Button}
                        permissao={[OUTROS_PARAMETROS_EDITARORIENTACOES]}
                        label="Gravar orientações"
                        type="submit"
                        icon={MAKO_ICONS.GRAVAR}
                        disabled={!!!parametro}
                        loading={loading}
                        className="p-button-info p-mr-2"
                    />
                </div>
            </div>
        </form>
    );
};

export const Visualizar = forwardRef(Component);
