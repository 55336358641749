import React from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Button } from "primereact/button";

export const Novo = ({ className = "", onClick = () => {} }) => {
    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[permissoes.COMPRAS_CADASTRO_TIPODEVOLUCAO_INCLUIR]}
            label="Novo"
            icon={MAKO_ICONS.NOVO}
            className={`p-button-success ${className}`}
            onClick={onClick}
        />
    );
};
