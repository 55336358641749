import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { InputText } from "primereact/inputtext";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { dataToStr } from "@/assets/util/datas";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_PRODUTOS_RELACAOPISCOFINS } from "@/assets/constants/relatorios";
import { TIPO_CHAVE_DESTINACAO_SKU_CHOICE } from "@/assets/constants/constants";
import { gerarFileName } from "@/assets/util/util";

const { ExportCSV } = R.Buttons;

const BASE_URL = "/relatorios/relacao-pis-cofins/";

const FILE_NAME = gerarFileName("Pis e Cofins");

const FILTROS_VIEWSET = {
    categoria: "categoriasku__categoria__in",
    empresa: "item__empresa",
    descricao: "descricao_complementar__unaccent__icontains",
    data: "data",
    operacao_fiscal: "operacao_fiscal",
    chave: "itemoperacaofiscal__operacao_fiscal__destinacao__chave",
};

export const RelatorioRelacaoPisCofins = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const empresaSelecionada = useRef();
    const categoriaRef = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(categoriaRef, empresaSelecionada, formRef, botaoCsv, listagemRef);

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        const selecionados = categoriaRef.current;
        if (dadosValidados.item__ncm) dadosValidados.item__ncm = dadosValidados.item__ncm.id;
        if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
        if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
        if (dadosValidados.destinacao) dadosValidados.destinacao = dadosValidados.destinacao.id;
        if (dadosValidados.saldo) dadosValidados.saldo = dataToStr(dadosValidados.saldo, "yyyy-MM-dd");
        if (dadosValidados.formula) dadosValidados.formula = dadosValidados.formula.id;
        if (selecionados) dadosValidados.categoria = selecionados.map((c) => c.id);
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const filtrosTemplate = [
        {
            key: "categoria",
            label: "Categoria",
            path: "nome",
            type: TIPOS_FILTROS_TEMPLATE.MULTISELECT,
        },
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "data",
            label: "Data Operação Fiscal",
            type: TIPOS_FILTROS_TEMPLATE.DATE,
        },
        {
            key: "descricao",
            label: "Descrição contém",
        },
        {
            key: "operacao_fiscal",
            label: "Operação Fiscal",
            path: "descricao",
        },
        {
            key: "chave",
            label: "Tipo produto",
            optionLabel: "label",
            optionKey: "value",
            options: TIPO_CHAVE_DESTINACAO_SKU_CHOICE,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                chave: Yup.string()
                    .required("O campo 'tipo produto' é obrigatório")
                    .typeError("Seleciona um 'tipo produto' válido."),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            const selecionados = categoriaRef.current;

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: selecionados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.data) dadosValidados.data = dataToStr(dadosValidados.data, "yyyy-MM-dd");
            if (dadosValidados.operacao_fiscal) dadosValidados.operacao_fiscal = dadosValidados.operacao_fiscal.id;
            if (selecionados) dadosValidados.categoria = selecionados.map((c) => c.id);

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_PRODUTOS_RELACAOPISCOFINS,
                    emails,
                    filtros,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    versao,
                    enviar_fila: background,
                });
                limparFormulario();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "codigo", header: "Referência", style: { width: "8%" } },
        { field: "descricao", header: "Descrição" },
        { field: "unidade", header: "UN", style: { width: "5%" }, align: "center" },
        { field: "ncm", header: "NCM", style: { width: "8%" }, align: "center" },
        { field: "empresa", header: "Empresa" },
        { field: "entrada.st_pis", header: "ST PIS", style: { width: "5%" }, align: "center" },
        { field: "entrada.aliq_pis", header: "Aliq. PIS", style: { width: "5%" }, align: "center" },
        { field: "saida.st_cofins", header: "ST Cofins", style: { width: "5%" }, align: "center" },
        { field: "saida.aliq_cofins", header: "Aliq. Cofins", style: { width: "5%" }, align: "center" },
    ];

    const colunasGroup = [
        [
            { rowSpan: 2, field: "codigo", header: "Referência", style: { width: "8%" } },
            { rowSpan: 2, field: "descricao", header: "Descrição" },
            { rowSpan: 2, field: "unidade", header: "UN", style: { width: "5%" }, align: "center" },
            { rowSpan: 2, field: "ncm", header: "NCM", style: { width: "8%" }, align: "center" },
            { rowSpan: 2, field: "empresa", header: "Empresa" },
            { colSpan: 2, header: "ENTRADA", align: "center" },
            { colSpan: 2, header: "SAIDA", align: "center" },
        ],
        [
            { field: "entrada.st_pis", header: "ST PIS", style: { width: "5%" }, align: "center" },
            { field: "entrada.aliq_pis", header: "Aliq. PIS", style: { width: "5%" }, align: "center" },
            { field: "saida.st_cofins", header: "ST Cofins", style: { width: "5%" }, align: "center" },
            { field: "saida.aliq_cofins", header: "Aliq. Cofins", style: { width: "5%" }, align: "center" },
        ],
    ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_PRODUTOS_RELACAOPISCOFINS}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const getCategoriaCompleta = (e) => {
        categoriaRef.current = e;
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    return (
        <R.Wrapper titulo={"de relação Pis e Cofins"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        categoria: null,
                        empresa: null,
                        data: null,
                        descricao: "",
                        operacao_fiscal: null,
                        chave: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Tipo de produto",
                        inputId: "chave",
                        inputName: "chave",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            placeholder: "Selecione um tipo...",
                            options: TIPO_CHAVE_DESTINACAO_SKU_CHOICE,
                            optionValue: "value",
                            optionLabel: "label",
                            showClear: true,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Empresa",
                        inputId: "empresa",
                        inputName: "empresa",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Operação fiscal",
                        inputId: "operacao_fiscal",
                        inputName: "operacao_fiscal",
                        component: Dropdown,
                        componentProps: {
                            url: "/fiscal/operacoes-fiscais/?limit=200",
                            optionLabel: "descricao",
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Categoria",
                        inputId: "categoria",
                        inputName: "categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: getCategoriaCompleta,
                            showClear: true,
                            selectionMode: "checkbox",
                            metaKeySelection: false,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Data Operação Fiscal",
                        inputId: "data",
                        inputName: "data",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Descrição produto contém",
                        inputId: "descricao",
                        inputName: "descricao",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                        },
                        fieldSize: 2,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_PRODUTOS_RELACAOPISCOFINS}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    colunasGroup={colunasGroup}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
