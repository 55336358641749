import React from "react";
import { Route } from "react-router-dom";

import { PublicLayout } from "@/layouts/PublicLayout";

export const WrapperRoute = (props) => (
    <PublicLayout>
        <Route {...props} exact />
    </PublicLayout>
);
