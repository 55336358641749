import React, { useCallback, useRef, useState } from "react";

import { isEqual, startOfDay } from "date-fns";

import { ConfirmDialog } from "primereact/confirmdialog";

import { MakoCalendar } from "@/components/MakoCalendar/index3";

import { parseData } from "@/assets/util/datas";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

const HOJE = startOfDay(new Date());

export const CalendarDataRecebimento = ({ valueCalendar, competencia = {}, onChange = () => {}, ...rest }) => {
    const [confirmData, setConfirmData] = useState(false);

    const { caixaMov: caixa } = useCaixaMovimento();

    const cachedPreviousData = useRef(parseData(caixa?.data_abertura) || HOJE);
    const cachedNextData = useRef(cachedPreviousData.current);

    const handleChangeDataRecebimento = useCallback(
        ({ value }) => {
            let _value = value;
            if (typeof _value === "string" && _value.length === 10) _value = parseData(_value);
            cachedNextData.current = _value;
            cachedPreviousData.current = valueCalendar;
            const showConfirm = !isEqual(_value, HOJE) && _value instanceof Date;
            onChange(_value);
            setConfirmData(() => showConfirm);
        },
        [valueCalendar, onChange]
    );

    const hide = () => setConfirmData(false);

    const acceptConfirmData = () => {
        onChange(cachedNextData.current);
    };

    const cancelConfirmData = () => {
        onChange(cachedPreviousData.current);
        hide();
    };

    return (
        <>
            <ConfirmDialog
                visible={confirmData}
                header="Confirmação"
                message="Deseja realmente alterar a data deste recebimento?"
                icon="pi pi-exclamation-triangle"
                acceptLabel="Sim"
                rejectClassName="p-button-danger p-button-text"
                acceptClassName="p-button-success p-button"
                rejectLabel="Cancelar"
                accept={acceptConfirmData}
                reject={cancelConfirmData}
                onHide={hide}
            />
            <MakoCalendar
                maxDate={parseData(competencia?.data_fim) || new Date()}
                minDate={parseData(competencia?.data_inicio)}
                onChange={handleChangeDataRecebimento}
                valueCalendar={valueCalendar}
                {...rest}
            />
        </>
    );
};
