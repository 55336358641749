import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { MakoButton as Button } from "@/components/MakoButton";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";

const url = "/plano-operacional/projetos/";

export const OperacionalProjetosPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_PROJETOS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de projeto"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/operacional/plano-operacional/projetos/form",
                        state: { projeto: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_PROJETOS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir o projeto "}
                            <b>{rowData.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O projeto não pode ser excluída."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_PROJETOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/operacional/plano-operacional/projetos/form"}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" } },
        { field: "nome", header: "Nome" },
        { field: "descricao", header: "Descrição" },
        { field: "responsavel.nome", header: "Responsável" },
        {
            field: "action",
            header: "Ações",
            align: "center",
            action: (e) => actionBodyTemplate(e),
            style: { width: "8%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Projetos"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                filtarPorEmpresa
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
