import axios from "axios";

export const extrairUrl = () => {
    const [href] = window.location.href.replace("www.", "").split(".");
    const [protocolo, url] = href.split(":");
    const seguro = protocolo[protocolo.length - 1] === "s";
    if (url === "//localhost") return { seguro, url: "localhost:8000" };
    return { seguro, url: `${url.substring(2)}.api.sysmako.com.br` };
};

export const url = () => {
    const { seguro, url } = extrairUrl();
    if (seguro) return `https://${url}`;
    return `http://${url}`;
};

const api = axios.create({
    baseURL: url(),
});

export default api;
