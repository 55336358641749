import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { SIM_NAO_BOOLEAN, TIPO_PRECO_SERVICO } from "@/assets/constants/constants";
import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";
import { Tag } from "primereact/tag";
import { DropdownFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { Menu } from "primereact/menu";
import useHttp from "@/hooks/useHttp";

const URL = "/vendas/servicos-vendas/";

export const VendasServicosPage = () => {
    const [servico, setServico] = useState(null);
    const listagemRef = useRef(null);
    const menu = useRef(null);
    const history = useHistory();
    const { showSuccess, showError } = useToast();
    const { httpPatch } = useHttp();

    const handleSituacao = async (situacao) => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: situacao ? "Serviço ativado com sucesso!" : "Serviço desativado com sucesso!",
                    life: 1500,
                });
                listagemRef.current?.buscarDados();
            },
            400: () => {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
            },
        };

        await httpPatch(
            {
                url: `${URL}${servico.id}/`,
                body: { ativo: situacao },
            },
            handlers
        );
    };

    const opcoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Ativar",
                    icon: "pi pi-chevron-circle-down",
                    disabled: servico?.ativo,
                    command: () => handleSituacao(true),
                },
                {
                    label: "Desativar",
                    icon: "pi pi-times-circle",
                    disabled: !servico?.ativo,
                    command: () => handleSituacao(false),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_SERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de Serviço"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/cadastros/servicos/form",
                            state: { servico: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_SERVICO_EDITAR]}
                    model={opcoes}
                    componente={Button}
                    className="p-button-rounded p-button-info p-mb-1"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    icon="pi pi-cog"
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setServico(rowData);
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_SERVICO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/vendas/cadastros/servicos/form")}
            />
        </>
    );

    const simOuNao = (valor) => {
        if (valor) return <span>Sim</span>;
        return <span>Não</span>;
    };

    const TipoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_PRECO_SERVICO,
                }}
                options={options}
            />
        );
    };

    const SimOuNaoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: SIM_NAO_BOOLEAN,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "30px" },
            filter: true,
        },
        { field: "nome", header: "Nome", filter: true, style: { minWidth: "150px" } },
        {
            field: "tipo_preco_servico",
            header: "Tipo de preço",
            style: { minWidth: "120px" },
            filter: true,
            filterElement: TipoFiltroTemplate,
            action: ({ tipo_preco_servico }) => {
                if (tipo_preco_servico === "F") return <span>Preço fixo</span>;
                if (tipo_preco_servico === "M") return <span>Conforme medida</span>;
                return <span>Percentual do produto</span>;
            },
        },
        {
            field: "valor",
            header: "Valor",
            action: ({ valor, tipo_preco_servico }) => {
                if (tipo_preco_servico === "P") return <span>{`${valor} %`}</span>;
                return parseNumberToMoneyHTML(valor);
            },
            style: { minWidth: "50px" },
        },
        {
            field: "incorporado_sku",
            header: "Incorporado no produto?",
            filter: true,
            filterElement: (e) => SimOuNaoFiltroTemplate(e),
            action: ({ incorporado_sku }) => simOuNao(incorporado_sku),
            style: { minWidth: "60px" },
        },
        {
            field: "ativo",
            header: "Ativo",
            filter: true,
            filterElement: (e) => SimOuNaoFiltroTemplate(e),
            action: ({ ativo }) => {
                if (ativo) return <Tag severity="success" value="ATIVO" />;
                return <Tag severity="danger" value="DESATIVADO" />;
            },
            style: { minWidth: "35px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "60px" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    return (
        <PageBase>
            <Menu model={opcoes} popup ref={menu} id="popup_menu" />
            <MakoListagem
                ref={listagemRef}
                titulo="Serviços de Vendas"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={URL}
                filtros={{
                    id: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "equals" }],
                    },
                    nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    tipo_preco_servico: { value: null, matchMode: "equals" },
                    incorporado_sku: { value: null, matchMode: "equals" },
                    ativo: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    lazy: true,
                    paginator: true,
                }}
            />
        </PageBase>
    );
};
