import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { Menu } from "primereact/menu";
import { CalculoComissaoForm } from "./modals/calculoComissao";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BotaoDelete } from "@/components/BotaoDelete";
import { CodigoFiltroTemplate, DateFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { ProcessaComissaoForm } from "./modals/processaComissao";

export const ApuracaoComissaoPage = () => {
    const [apuracao, setApuracao] = useState(null);
    const menuRef = useRef(null);
    const toastRef = useRef(null);
    const calculaRef = useRef(null);
    const processaRef = useRef(null);
    const listagemRef = useRef(null);
    const history = useHistory(null);

    const operacoes = [
        {
            label: "Cálculo comissões",
            icon: "pi pi-percentage",
            command: () => calculaRef.current?.setVisible(true),
        },
        {
            label: "Processar comissões",
            icon: "pi pi-sync",
            disabled: apuracao?.status?.codigo === "P",
            command: () => processaRef.current?.setVisible(true),
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_COMISSAO_APURACAOCOMISSAO_CALCULAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    onClick={(e) => {
                        setApuracao(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_COMISSAO_APURACAOCOMISSAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de apuração"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/comissao/apuracao/form",
                            state: { apuracao: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_COMISSAO_APURACAOCOMISSAO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/vendas/apuracao-comissao-venda/"
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a apuração <b>{rowData.id}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A apuração de comissão não pode ser excluída."
                    tooltip="Deletar"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_COMISSAO_APURACAOCOMISSAO_INCLUIR]}
                componente={Button}
                className="p-button-success p-mr-2"
                icon="pi pi-plus"
                label="Novo"
                onClick={() => history.push("/vendas/comissao/apuracao/form")}
                tooltipOptions={{ position: "left" }}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "regra_apuracao.descricao",
            header: "Regra",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            filterField: "regra_apuracao__descricao",
            style: { minWidth: "120px" },
        },
        {
            field: "tipo_apuracao.descricao",
            header: "Tipo",
            style: { minWidth: "70px" },
        },
        {
            field: "status.descricao",
            header: "Situação",
            style: { minWidth: "70px" },
        },
        {
            field: "data_inicial",
            header: "Data inicial",
            filter: true,
            filterElement: DateFiltroTemplate,
            style: { minWidth: "70px" },
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "data_final",
            header: "Data final",
            filter: true,
            filterElement: DateFiltroTemplate,
            style: { minWidth: "70px" },
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "100px" },
        },
    ];

    const rowClass = (apuracao) => {
        return {
            "table-recebimentos-pending": apuracao.status.codigo === "C",
            "table-recebimentos-effective": apuracao.status.codigo === "P",
        };
    };

    const aposSalvar = () => {
        listagemRef.current?.buscarDados();
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <Menu model={operacoes} popup ref={menuRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Apuração de Comissões"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa="/vendas/apuracao-comissao-venda/"
                        filtros={{
                            id: { value: "", matchMode: "equals" },
                            regra_apuracao__descricao: { value: "", matchMode: "icontains" },
                            data_inicial: { value: "", matchMode: "equals" },
                            data_final: { value: "", matchMode: "equals" },
                        }}
                        configTabela={{
                            rowClassName: rowClass,
                            paginator: true,
                            lazy: true,
                            filterDisplay: "menu",
                        }}
                    />
                </div>
            </div>
            <CalculoComissaoForm
                apuracao={{ ...apuracao, status: apuracao?.status?.codigo }}
                ref={calculaRef}
                aposSalvar={aposSalvar}
            />
            <ProcessaComissaoForm apuracao={apuracao} ref={processaRef} aposSalvar={aposSalvar} />
        </div>
    );
};
