import { useContext } from "react";

import EmpresaContext from "@/contexts/empresaContext";

const useEmpresa = () => {
    const { empresaSelecionada, empresaSelecionadaId, alterarEmpresaSelecionada, removerEmpresaSelecionada } =
        useContext(EmpresaContext);

    return { empresaSelecionada, empresaSelecionadaId, alterarEmpresaSelecionada, removerEmpresaSelecionada };
};

export default useEmpresa;
