import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";

export const CategoriaFiltroTemplate = (options) => {
    return (
        <MakoDropdownCategoriasHierarquicas
            categoriaTituloSelecionavel
            value={options.value}
            onChange={(e) => options.filterCallback(e.value)}
        />
    );
};
