import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import { MakoInputPeriodo } from "@/components/MakoInputs/MakoInputPeriodo";
import MakoListagem from "@/components/MakoListagem";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { RELATORIO_PRODUTOS_MOVIMENTACAOPRODUTOS } from "@/assets/constants/relatorios";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";

const { ExportCSV } = R.Buttons;

const BASE_URL = "/relatorios/movimentacao-produtos/";

const FILE_NAME = gerarFileName("Movimentacao Produtos");

const quantidadeTemplate = (value) => {
    return value || "";
};

const cellClassName = (_, { field }) => {
    return {
        "table-relatorio-movimentacoes-saida": ["saida_qtd", "saida_un", "saida_total"].includes(field),
    };
};

export const RelatorioMovimentacaoProdutos = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const categoriaRef = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(formRef, categoriaRef, botaoCsv, listagemRef);

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.id_sku instanceof Object) dadosValidados.id_sku = dadosValidados.id_sku.id;
        if (dadosValidados.centro_estocagem instanceof Object)
            dadosValidados.centro_estocagem = dadosValidados.centro_estocagem.id;
        if (dadosValidados.data_start instanceof Date)
            dadosValidados.data_start = dataToStr(dadosValidados.data_start, "yyyy-MM-dd");
        if (dadosValidados.data_end instanceof Date)
            dadosValidados.data_end = dataToStr(dadosValidados.data_end, "yyyy-MM-dd");
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const filtrosTemplate = [
        {
            key: "id_sku",
            label: "Produto",
            type: TIPOS_FILTROS_TEMPLATE.SKU,
        },
        {
            key: ["data_start", "data_end"],
            label: "Periodo",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "centro_estocagem",
            label: "Centro estocagem",
            path: "descricao",
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                id_sku: Yup.object()
                    .required("O campo 'produto' é obrigatório")
                    .typeError("Informe um 'produto' válido"),
                data_start: Yup.date().nullable().typeError("Informe um 'data  inicial' válido"),
                data_end: Yup.date().when("data_start", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.data_start || new Date(), "O campo 'data final' não pode ser anterior a inicial")
                        .typeError("Informe uma 'data final' válida"),
                    otherwise: Yup.date().nullable(),
                }),
                centro_estocagem: Yup.object()
                    .required("O campo 'centro de estocagem' é obrigatório")
                    .typeError("Informe um 'centro estocagem' válido"),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.id_sku instanceof Object) dadosValidados.id_sku = dadosValidados.id_sku.id;
            if (dadosValidados.centro_estocagem instanceof Object)
                dadosValidados.centro_estocagem = dadosValidados.centro_estocagem.id;
            if (dadosValidados.data_start instanceof Date)
                dadosValidados.data_start = dataToStr(dadosValidados.data_start, "yyyy-MM-dd");
            if (dadosValidados.data_end instanceof Date)
                dadosValidados.data_end = dataToStr(dadosValidados.data_end, "yyyy-MM-dd");

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_PRODUTOS_MOVIMENTACAOPRODUTOS,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") filtros.push(`${k}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_PRODUTOS_MOVIMENTACAOPRODUTOS}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const colunasGroup = [
        [
            { rowSpan: 2, field: "Data", header: "Data", style: { width: "5%" } },
            { rowSpan: 2, field: "historico", header: "Dados da movimentação" },
            { colSpan: 3, header: "Entradas", align: "center" },
            { colSpan: 3, header: "Saídas", align: "center" },
            { colSpan: 3, header: "Saldo", align: "center" },
        ],
        [
            { field: "entrada_qtd", header: "Quantidade", style: { width: "8%" }, align: "center" },
            { field: "entrada_un", header: "Unitário", style: { width: "8%" }, money: true },
            { field: "entrada_total", header: "Total", style: { width: "8%" }, money: true },
            { field: "saida_qtd", header: "Quantidade", style: { width: "8%" }, align: "center" },
            { field: "saida_un", header: "Unitário", style: { width: "8%" }, money: true },
            { field: "saida_total", header: "Total", style: { width: "8%" }, money: true },
            { field: "saldo_un", header: "Quantidade", style: { width: "8%" }, align: "center" },
            { field: "saldo_cm", header: "Custo Méd", style: { width: "8%" }, money: true },
            { field: "saldo_total", header: "Total", style: { width: "8%" }, money: true },
        ],
    ];

    const colunas = [
        { field: "data", header: "Data", style: { width: "8%" }, align: "center" },
        { field: "historico", header: "Dados da movimentação" },
        {
            field: "entrada_qtd",
            header: "Quantidade",
            style: { width: "8%" },
            align: "center",
            action: ({ entrada_qtd }) => quantidadeTemplate(entrada_qtd),
        },
        { field: "entrada_un", header: "Unitário", style: { width: "8%" }, money: true, showZero: false },
        { field: "entrada_total", header: "Total", style: { width: "8%" }, money: true, showZero: false },
        {
            field: "saida_qtd",
            header: "Quantidade",
            style: { width: "8%" },
            align: "center",
            action: ({ saida_qtd }) => quantidadeTemplate(saida_qtd),
        },
        { field: "saida_un", header: "Unitário", style: { width: "8%" }, money: true, showZero: false },
        { field: "saida_total", header: "Total", style: { width: "8%" }, money: true, showZero: false },
        {
            field: "saldo_un",
            header: "Quantidade",
            style: { width: "8%" },
            align: "center",
            action: ({ saldo_un }) => quantidadeTemplate(saldo_un),
        },
        { field: "saldo_cm", header: "Custo Méd", style: { width: "8%" }, money: true, showZero: false },
        { field: "saldo_total", header: "Total", style: { width: "8%" }, money: true, showZero: false },
    ];

    return (
        <R.Wrapper titulo={"de movimentação de produtos"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        data_start: null,
                        data_end: null,
                        id_sku: null,
                        centro_estocagem: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Mercadoria",
                        inputId: "id_sku",
                        inputName: "id_sku",
                        required: true,
                        component: MakoBuscaSkuPersonalizada,
                        componentEspecial: COMPONENTES_ESPECIAIS.SKU,
                        fieldSize: 12,
                    },
                    {
                        label: "Centro estocagem",
                        inputId: "centro_estocagem",
                        inputName: "centro_estocagem",
                        required: true,
                        component: MakoDropdownEstoques,
                        fieldSize: 3,
                    },
                    {
                        inputId: "data_start",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        componentProps: {
                            label: "Periodo",
                            nameInicio: "data_start",
                            nameFinal: "data_end",
                            valueInicio: "data_start",
                            valueFinal: "data_end",
                            errorInicio: "data_start",
                            errorFinal: "data_end",
                        },
                        fieldSize: 5,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_PRODUTOS_MOVIMENTACAOPRODUTOS}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    colunasGroup={colunasGroup}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                        cellClassName,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
