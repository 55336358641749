import React, { useMemo } from "react";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

import { parseData } from "@/assets/util/datas";

export const MakoBaseCalendar = ({ minDate, maxDate, ...props }) => {
    addLocale("br", {
        firstDayOfWeek: 0,
        dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
        dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro",
        ],
        monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
        today: "Hoje",
        clear: "Limpar",
    });

    const min = useMemo(() => {
        if (typeof minDate === "string" && minDate.length > 0) return parseData(minDate);
        if (typeof minDate === "object") return minDate;
        return null;
    }, [minDate]);

    const max = useMemo(() => {
        if (typeof maxDate === "string" && maxDate.length > 0) return parseData(maxDate);
        if (typeof maxDate === "object") return maxDate;
        return null;
    }, [maxDate]);

    const monthNavigatorTemplate = (e) => {
        return (
            <Dropdown
                value={e.value}
                options={e.options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                style={{ lineHeight: 1 }}
            />
        );
    };

    const yearNavigatorTemplate = (e) => {
        return (
            <Dropdown
                value={e.value}
                options={e.options.reverse()}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                className="p-ml-2"
                style={{ lineHeight: 1 }}
            />
        );
    };

    return (
        <Calendar
            showIcon
            showButtonBar
            locale="br"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            showOnFocus={false}
            monthNavigator
            yearNavigator
            monthNavigatorTemplate={monthNavigatorTemplate}
            yearNavigatorTemplate={yearNavigatorTemplate}
            yearRange={`1900:${new Date().getFullYear() + 10}`}
            placeholder="dd/mm/aaaa"
            minDate={min}
            maxDate={max}
            {...props}
        />
    );
};
