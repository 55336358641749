import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import { ParcelaFormas } from "../../../ParcelaFormas";

const Component = ({ onConfirmar = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);
    const [parcela, setParcela] = useState(null);

    const formRef = useRef(null);

    const show = (value) => {
        setParcela(value);
        setVisible(true);
    };

    const onShow = () => {
        formRef.current?.iniciarFormulario({ ...parcela });
    };

    const close = () => {
        setVisible(false);
    };

    const onSuccess = useCallback(
        (...args) => {
            onConfirmar(...args);
            setVisible(false);
        },
        [onConfirmar]
    );

    useImperativeHandle(ref, () => {
        return {
            show,
            close,
        };
    });

    return (
        <Dialog
            visible={visible}
            header="Efetivando recebimento"
            onShow={onShow}
            onHide={() => setVisible(false)}
            style={{ width: "60vw" }}
        >
            <ParcelaFormas ref={formRef} successCalback={onSuccess} />
        </Dialog>
    );
};

export const ModalParcela = forwardRef(Component);
