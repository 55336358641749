import React from "react";
import { Skeleton } from "./skeleton";

const MakoCardDashBoard = ({ levelOverviewClass = 1, icon, title, content, loading = false }) => {
    return (
        <div className={`card no-gutter widget-overview-box widget-overview-box-${levelOverviewClass}`}>
            {!loading ? (
                <>
                    <span className="overview-icon">
                        <i className={icon}></i>
                    </span>
                    <span className="overview-title">{title}</span>
                    <div className="p-grid overview-detail">
                        <div className="p-col-6">
                            <div className="overview-number">{content.left.value}</div>
                            <div className="overview-subtext">{content.left.text}</div>
                        </div>
                        <div className="p-col-6">
                            <div className="overview-number">{content.right.value}</div>
                            <div className="overview-subtext">{content.right.text}</div>
                        </div>
                    </div>
                </>
            ) : (
                <Skeleton />
            )}
        </div>
    );
};

export default MakoCardDashBoard;
