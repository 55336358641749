import React, { useCallback, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import { ModalEntradaSkuManual } from "./ModalEntradaSkuManual";
import { ModalDividirProduto } from "./ModalDividirProduto";
import { ModalErrosValidacaoItem } from "./ModalErrosValidacaoItem";
import { ModalLoteSKU } from "./ModalLoteSKU";
import MakoListagem from "@/components/MakoListagem";
import { Delete } from "@/components/Delete";
import { parseNumber } from "@/assets/helpers/number";
import { parseMoeda } from "@/assets/util/util";
import useClearRefs from "@/hooks/useClearRefs";
import useEntrada from "@/hooks/useEntrada";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

export const MercadoriasForm = () => {
    const [itemEntradaSelecionado, setItemEntradaSelecionado] = useState(null);
    const [produtos, setProdutos] = useState([]);
    const listagemRef = useRef(null);
    const modalSkuRef = useRef(null);
    const modalErrosRef = useRef(null);
    const modalDividirSkuRef = useRef(null);
    const modalLoteSkuRef = useRef(null);
    const menuRef = useRef(null);
    const deleteRef = useRef(null);
    const { dadosBasicos, permiteAlterar, atualizarValoresEntrada } = useEntrada();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpPut } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    useClearRefs(listagemRef, modalSkuRef, modalErrosRef, modalDividirSkuRef, menuRef, deleteRef);

    const validarItemEntrada = async (itemEntrada) => {
        const handlers = {
            200: ({ data }) => {
                if (data?.pendencias) {
                    const pendencias = data.pendencias.map((pendencia) => ({
                        erro: pendencia,
                        item_entrada: itemEntrada.id,
                    }));
                    modalErrosRef.current?.abrirModal(pendencias);
                } else {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Item da entrada conferido com succeso!",
                        life: 1500,
                    });
                    listagemRef.current?.buscarDados();
                }
            },
            error: ({ status, err }) => {
                if (status === 400 || status === 404) {
                    showWarning({
                        summary: "Aviso",
                        detail: err.msg,
                        life: 4000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a requisição.",
                        life: 3000,
                    });
                }
            },
        };
        showLoading();
        await httpPut({ url: `/compras/validar-item-entrada/${itemEntrada.id}/` }, handlers);
        hideLoading();
    };

    const edicaoBloqueada = !permiteAlterar;

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Lançamento Manual"
                icon="pi pi-plus"
                disabled={edicaoBloqueada}
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => modalSkuRef.current?.abrirModal()}
            />
        </>
    );

    const painelDireitoTabela = (
        <div style={{ width: "25vw" }}>
            <div className="p-text-right">
                <span>Total dos produtos: </span>
                <span>{parseMoeda(dadosBasicos?.valor_total_produtos, false)}</span>
            </div>
            <div className="p-text-right">
                <span>Total dos produtos nesta pág: </span>
                <span>
                    {parseMoeda(
                        produtos.reduce((total, prod) => total + parseNumber(prod.valor_total_item), 0),
                        false
                    )}
                </span>
            </div>
            <div className="p-text-right">
                <span>Total validado nesta pág: </span>
                <span>
                    {parseMoeda(
                        produtos
                            .filter((prod) => !prod.pendencias)
                            .reduce((total, prod) => total + parseNumber(prod.valor_total_item), 0),
                        false
                    )}
                </span>
            </div>
        </div>
    );

    const itensMenu = [
        {
            label: "Editar item",
            icon: "pi pi-pencil",
            command: () => modalSkuRef.current?.abrirModal(),
        },
        {
            label: "Dividir item",
            icon: "pi pi-pause",
            disabled: edicaoBloqueada,
            command: () => modalDividirSkuRef.current?.abrirModal(itemEntradaSelecionado),
        },
        {
            label: "Excluir item",
            icon: "pi pi-times",
            disabled:
                edicaoBloqueada || (!!itemEntradaSelecionado?.item_nota_fiscal && !itemEntradaSelecionado?.derivado),
            command: () => deleteRef.current?.handleClick(),
        },
        {
            label: "Lote / Nº de série",
            icon: "pi pi-tags",
            disabled: edicaoBloqueada || itemEntradaSelecionado?.sku.lote_serial === "-",
            command: () => modalLoteSkuRef.current?.abrirModal(itemEntradaSelecionado),
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Menu model={itensMenu} popup ref={menuRef} />
                <Button
                    icon="pi pi-cog"
                    onClick={(e) => {
                        menuRef.current?.toggle(e);
                        setItemEntradaSelecionado(rowData);
                    }}
                    className="p-button-rounded p-button-info p-mr-2 p-mt-1"
                />
                <Button
                    icon="pi pi-check"
                    disabled={edicaoBloqueada || !rowData.pendencias}
                    loading={loading}
                    onClick={() => validarItemEntrada(rowData)}
                    className="p-button-rounded p-button-success p-mt-1"
                />
            </div>
        );
    };

    const rowClass = (rowData) => {
        return {
            "table-recebimentos-overdue": !!rowData.pendencias,
        };
    };

    const colunas = [
        { field: "nf", header: "Nº NF", style: { width: "8%" } },
        { field: "sku.codigo", header: "Código", style: { width: "7%" } },
        { field: "sku.descricao_derivada", header: "Produto" },
        { field: "quantidade", header: "Qtd.", decimal: true, align: "right", style: { width: "7%" } },
        { field: "cubagem", header: "Cubagem", decimal: true, style: { width: "8%" } },
        {
            field: "valor_unitario",
            header: "Valor unitário",
            style: { width: "10%" },
            money: true,
        },
        {
            field: "valor_total_item",
            header: "Valor total item",
            style: { width: "10%" },
            money: true,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    const obterProdutosTabela = useCallback((results) => {
        setProdutos(results);
        return results;
    }, []);

    return (
        <>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                painelDireito={painelDireitoTabela}
                aposPesquisar={obterProdutosTabela}
                urlPesquisa={`/compras/itens-entradas/?entrada=${dadosBasicos?.id}`}
                configTabela={{ paginator: true, lazy: true, rowClassName: rowClass }}
            />
            <ModalErrosValidacaoItem ref={modalErrosRef} />
            <ModalEntradaSkuManual
                ref={modalSkuRef}
                readOnly={edicaoBloqueada}
                itemEntrada={itemEntradaSelecionado}
                onFechar={async () => {
                    await atualizarValoresEntrada();
                    listagemRef.current?.buscarDados();
                }}
            />
            <ModalDividirProduto ref={modalDividirSkuRef} onSuccess={() => listagemRef.current?.buscarDados()} />
            <ModalLoteSKU ref={modalLoteSkuRef} />
            <Delete
                ref={deleteRef}
                url="/compras/itens-entradas/"
                objetoId={itemEntradaSelecionado?.id}
                exigeConfirmacao
                msgConfirmacao={
                    <span>
                        Confirma a exclusão do item <b>{itemEntradaSelecionado?.sku.descricao_derivada}</b>?
                    </span>
                }
                msgToastErroExclusao="O item de entrada não pode ser excluído."
                onDelete={() => listagemRef.current?.buscarDados()}
            />
        </>
    );
};
