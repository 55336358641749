import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { InputText } from "primereact/inputtext";

import { DropdownRef as Dropdown } from "@/components/DropdownRef";
import { MakoButton } from "@/components/MakoButton";

import { ModalHistoricoForm } from "./formHistorico";
import useClearRefs from "@/hooks/useClearRefs";

const BASE_URL_FILTROS = { ativo: "true" };
const BASE_URL_HISTORICO = "/financeiro/historico-padrao/";
const DEFAULT_PROPS_INPUTTEXT_HISTORICO = {
    id: "historico_original",
    name: "historico_original",
    autoComplete: "off",
    maxLength: 140,
};

export const MakoDropdownHistoricoPadrao = ({
    filtros = {},
    handleChangeForm = () => {},
    setFieldValueForm = () => {},
    inputTextProps = {},
    showButtonNew = true,
    selectOnCreate = true,
    ...rest
}) => {
    const [editavel, setEditavel] = useState(false);

    const modalHistoricoRef = useRef(null);
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    useClearRefs(modalHistoricoRef, dropdownRef, inputRef);

    const onChangeHistorico = (e) => {
        const nameInput = inputTextProps?.name || DEFAULT_PROPS_INPUTTEXT_HISTORICO.name;
        setFieldValueForm(nameInput, e.value instanceof Object ? e.value.descricao : "");
        handleChangeForm(e);
    };

    const url = useMemo(() => {
        const filtrosUrl = { ...BASE_URL_FILTROS, ...filtros };
        return `${BASE_URL_HISTORICO}?${Object.keys(filtrosUrl)
            .map((k) => `${k}=${filtrosUrl[k]}`)
            .join("&")}`;
    }, [filtros]);

    const onFinish = useCallback(
        (value = {}) => {
            const { optionValue, name } = rest;
            setFieldValueForm(name, !optionValue ? value : value[optionValue]);
            dropdownRef.current?.fetch();
            modalHistoricoRef.current?.hide();
        },
        [rest, setFieldValueForm]
    );

    useEffect(() => {
        if (editavel) inputRef.current?.focus();
    }, [editavel]);

    return (
        <>
            <div className="p-inputgroup">
                {!editavel ? (
                    <Dropdown
                        ref={dropdownRef}
                        url={url}
                        optionLabel="descricao"
                        onChange={onChangeHistorico}
                        filter
                        filterBy="descricao"
                        {...rest}
                    />
                ) : (
                    <InputText
                        ref={inputRef}
                        onChange={handleChangeForm}
                        {...DEFAULT_PROPS_INPUTTEXT_HISTORICO}
                        {...inputTextProps}
                    />
                )}
                <MakoButton
                    type="button"
                    icon="pi pi-pencil"
                    className="p-button-warning"
                    tooltip={!editavel ? "Alterar descrição histórico selecionado" : "Selecionar histórico"}
                    onClick={() => setEditavel((prev) => !prev)}
                    disabled={!(rest.value instanceof Object)}
                />
                {showButtonNew && (
                    <MakoButton
                        type="button"
                        icon="pi pi-plus"
                        className="p-button-success"
                        tooltip="Adicionar novo histórico"
                        onClick={() => modalHistoricoRef.current?.show()}
                    />
                )}
            </div>
            {showButtonNew && <ModalHistoricoForm ref={modalHistoricoRef} onFinish={onFinish} tipo={filtros?.tipo} />}
        </>
    );
};
