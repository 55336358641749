import React, { useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Tag } from "primereact/tag";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { FiltroAvancadoDevolucao } from "./filtroAvancado";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { SITUACAO_VENDA_DEVOLUCAO } from "@/assets/constants/constants";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useHttp from "@/hooks/useHttp";

const URL_BASE_BACKEND = "/vendas/devolucao/?query={id,cliente,data,valor_total,status}";

export const VendaDevolucaoPage = () => {
    const [url, setUrl] = useState(URL_BASE_BACKEND);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const [devolucao, setDevolucao] = useState(null);
    const listagemRef = useRef(null);
    const menuAcoesRef = useRef(null);
    const modalFiltroAvancadoRef = useRef(null);
    const history = useHistory();
    const [formatDocumento] = useFormatCNPJCPF();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpPatch, httpPut } = useHttp();

    async function processarDevolucao() {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Devolução processada com sucesso!",
                    life: 1500,
                });
                setDevolucao(null);
                listagemRef.current?.buscarDados();
            },
            400: ({ err }) =>
                showError({
                    summary: "Aviso",
                    detail: err.msg || "Desculpe, não foi possível processar sua requisição.",
                    life: 3000,
                }),
            409: ({ err }) => {
                showWarning({
                    summary: "Aviso",
                    detail: err.msg,
                    life: 3000,
                });
            },
        };

        showLoading();
        await httpPut({ url: `/vendas/processar-devolucao/${devolucao?.id}/` }, handlers);
        hideLoading();
    }

    const confirmarProcessamentoDevolucao = () => {
        confirmDialog({
            message: "Confirma o processamento da devolução?",
            header: "Confirmação de processamento",
            icon: "pi pi-exclamation-triangle",
            accept: processarDevolucao,
            acceptLabel: "Confirmar",
            rejectLabel: "Cancelar",
        });
    };

    const cancelarDevolucao = useCallback(async () => {
        const handlers = {
            200: () => {
                listagemRef.current?.buscarDados();
            },
        };

        await httpPatch(
            {
                url: `/vendas/devolucao/${devolucao?.id}/`,
                body: {
                    status: "C",
                },
            },
            handlers
        );
    }, [devolucao, httpPatch]);

    const confirmarCancelamentoDevolucao = () => {
        confirmDialog({
            message: "Deseja realmente cancelar a devolução?",
            header: "Confirmação de cancelamento",
            icon: "pi pi-exclamation-triangle",
            accept: cancelarDevolucao,
            acceptLabel: "Confirmar",
            acceptClassName: "p-button-danger",
            rejectLabel: "Cancelar",
        });
    };

    const botoesMenuDevolucao = [
        {
            label: "Ações",
            items: [
                {
                    label: "Processar",
                    icon: "pi pi-check",
                    disabled: devolucao?.status !== "P",
                    command: confirmarProcessamentoDevolucao,
                },
                {
                    label: "Cancelar",
                    icon: "pi pi-times",
                    disabled: devolucao?.status === "F" || devolucao?.status === "C",
                    command: () => confirmarCancelamentoDevolucao(),
                },
            ],
        },
    ];

    const docClienteBodyTemplate = ({ cliente }) => {
        return <span>{formatDocumento(cliente.identificacao)}</span>;
    };

    const statusBodyTemplate = (rowData) => {
        const status = SITUACAO_VENDA_DEVOLUCAO.find((item) => item.value === rowData.status);
        if (!status) return <Tag value="N/A" />;
        return <Tag severity={status.tag} value={status.label} />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_DEVOLUCAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    disabled={rowData.status === "C"}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/devolucao/form",
                            state: rowData.id,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_DEVOLUCAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    disabled={rowData.status === "C"}
                    onClick={(event) => {
                        setDevolucao(rowData);
                        menuAcoesRef?.current.toggle(event);
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_DEVOLUCAO_INCLUIR]}
                componente={Button}
                label="Realizar devolução"
                icon="pi pi-undo"
                className="p-button-info p-mr-2"
                onClick={() => history.push("/vendas/venda/devolucao/form")}
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                onClick={() => modalFiltroAvancadoRef.current?.abrirModal()}
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
            />
            <Button
                label="Limpar filtros"
                icon="pi pi-trash"
                className="p-button-warning p-mr-2"
                onClick={() => {
                    setUrl(URL_BASE_BACKEND);
                    setTotalizadorFiltros(0);
                }}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Nº devolução", style: { width: "8%" } },
        {
            field: "cliente",
            header: "CPF / CNPJ do cliente",
            style: { width: "15%" },
            action: (e) => docClienteBodyTemplate(e),
        },
        {
            field: "cliente.nome",
            header: "Nome do cliente",
        },
        { field: "data", header: "Data", style: { width: "10%" }, dateFormat: "dd/MM/yyyy" },
        { field: "valor_total", header: "Valor total", style: { width: "10%" }, money: true },
        { field: "status", header: "Situação", style: { width: "6%" }, align: "center", action: statusBodyTemplate },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: actionBodyTemplate },
    ];

    const onConfirmFiltro = (newUrl, contador) => {
        setUrl(newUrl);
        setTotalizadorFiltros(contador);
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Devoluções"
                colunas={colunas}
                urlPesquisa={url}
                painelEsquerdo={cabecalhoTabela}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
            <Menu ref={menuAcoesRef} model={botoesMenuDevolucao} popup id="popup_menu_acoes" />
            <ConfirmDialog />
            <FiltroAvancadoDevolucao
                ref={modalFiltroAvancadoRef}
                urlBase={URL_BASE_BACKEND}
                onConfirm={onConfirmFiltro}
            />
        </PageBase>
    );
};
