import { useRef } from "react";
import { useBetween } from "use-between";

const useMakoSidebarConsultarSaldosRef = () => {
    const sidebarRef = useRef(null);

    const show = () => {
        sidebarRef.current?.show();
    };

    const hide = () => {
        sidebarRef.current?.hide();
    };

    return { sidebarRef, show, hide };
};

const useMakoSidebarConsultarSaldos = () => useBetween(useMakoSidebarConsultarSaldosRef);
export default useMakoSidebarConsultarSaldos;
