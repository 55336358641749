import React, { useMemo } from "react";

import { Dropdown } from "@/components/Dropdown";

import { FINANCEIRO_FINANCEIRO_CAIXA_RECEBERBOLETO } from "@/assets/constants/parametros";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";

import useParam from "@/hooks/useParam";

const BASE_URL_SEARCH = "/financeiro/formas-recebimentos/?limit=100&escopo__in=R,X&tipo__in=";

const BASE_FORMAS_VALIDAS = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.filter(({ id }) => id !== 8 && id !== 9);

const FORMAS_VALIDAS_CAIXA = BASE_FORMAS_VALIDAS.filter(({ id }) => [0, 4].includes(id));
const FORMAS_VALIDAS_BANCARIA = BASE_FORMAS_VALIDAS.filter(({ id }) => [3, 10].includes(id));
const FORMAS_VALIDAS_BANCARIA_CARTAO = [
    ...BASE_FORMAS_VALIDAS.filter(({ id }) => [1, 2].includes(id)),
    ...FORMAS_VALIDAS_BANCARIA,
];
const FORMAS_VALIDAS_POUPANCA = BASE_FORMAS_VALIDAS.filter(({ id }) => [10, 11].includes(id));
const FORMAS_VALIDAS_INVESTIMENTO = BASE_FORMAS_VALIDAS.filter(({ id }) => [11].includes(id));
const FORMA_BOLETO = BASE_FORMAS_VALIDAS.find(({ id }) => id === 5);

const COM_CARTAO = "com_cartao";
const SEM_CARTAO = "sem_cartao";

const FORMAS_TIPO_CONTAS = {
    1: FORMAS_VALIDAS_CAIXA,
    2: {
        [SEM_CARTAO]: FORMAS_VALIDAS_BANCARIA,
        [COM_CARTAO]: FORMAS_VALIDAS_BANCARIA_CARTAO,
    },
    3: FORMAS_VALIDAS_POUPANCA,
    4: FORMAS_VALIDAS_INVESTIMENTO,
};

export const DropdownFormas = ({ contaFinanceira, ...rest }) => {
    const { getParam } = useParam();

    const receberBoleto = useMemo(() => {
        const parametro = getParam(FINANCEIRO_FINANCEIRO_CAIXA_RECEBERBOLETO);
        if (!parametro) return false;
        if (parametro?.valor === "1") return true;
        return false;
    }, [getParam]);

    const formasValidas = useMemo(() => {
        let formas = BASE_FORMAS_VALIDAS;
        if (contaFinanceira) {
            const { tipo_conta, conta_bancaria } = contaFinanceira || {};
            if (tipo_conta?.id === 2 && conta_bancaria) {
                const { recebe_via_cartao } = conta_bancaria;
                formas = FORMAS_TIPO_CONTAS[tipo_conta?.id][recebe_via_cartao ? COM_CARTAO : SEM_CARTAO];
            } else if (FORMAS_TIPO_CONTAS[tipo_conta?.id] instanceof Array) {
                formas = FORMAS_TIPO_CONTAS[tipo_conta?.id];
            }
        }
        if (receberBoleto) formas = [...formas, FORMA_BOLETO];
        return formas.map(({ id }) => id).join(",");
    }, [contaFinanceira, receberBoleto]);

    return (
        <Dropdown
            placeholder="Selecione a forma de recebimento"
            url={`${BASE_URL_SEARCH}${formasValidas}`}
            optionValue="id"
            optionLabel="descricao"
            disabled={!contaFinanceira}
            {...rest}
        />
    );
};
