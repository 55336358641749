import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoButton as Button } from "@/components/MakoButton";
import permissoes from "@/assets/constants/permissoes";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

const url = "/financeiro/planos-recebimentos/";

export const FinanceiroPlanoRecebimentoPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de plano de recebimentos"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/financeiro/plano-recebimentos/form",
                        state: { planRecebimento: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o plano de recebimento <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O plano de recebimento não pode ser excluído."
                    tooltip="Deletar plano de recebimento"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/financeiro/plano-recebimentos/form"}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "10rem" }, filter: true },
        { field: "descricao", header: "Descrição", style: { minWidth: "25rem" }, filter: true },
        { field: "minimo_parcelas", header: "Min. parcelas", style: { width: "10rem" } },
        { field: "maximo_parcelas", header: "Máx. parcelas", style: { width: "10rem" } },
        { field: "vigencia_inicial", header: "Vigência inicial", style: { width: "12rem" }, dateFormat: "dd/MM/yyyy" },
        { field: "vigencia_final", header: "Vigência final", style: { width: "12rem" }, dateFormat: "dd/MM/yyyy" },
        { field: "taxa_minima", header: "Taxa mínima", style: { width: "12rem" } },
        { field: "taxa_alvo", header: "Taxa alvo", style: { width: "130px" } },
        {
            field: "action",
            header: "Ações",
            frozen: true,
            align: "center",
            alignFrozen: "right",
            style: { width: "8rem" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Planos de Recebimentos"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    descricao: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                    frozenWidth: "0.5vw",
                    flexGrow: 1,
                    flexBasis: "12rem",
                }}
            />
        </PageBase>
    );
};
