import React, { useRef } from "react";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";

import MakoListagem from "@/components/MakoListagem";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoButton as Button } from "@/components/MakoButton";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Label } from "@/components/Label";

export const DetalheRegraArredondamentoValorForm = ({ regraArredondamentoValor }) => {
    const listagemRef = useRef(null);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();

    const formik = useFormik({
        initialValues: {
            decimal: 0,
            inteiro: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        const detalhe = {
            regra_arredondamento: regraArredondamentoValor.id,
            inteiro: values.inteiro === 0 ? null : values.inteiro,
            decimal: values.decimal / 100,
        };
        if (!values.id) {
            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Detalhe de arredondamento de preço cadastrado com sucesso!",
                        life: 1500,
                    });
                    formik.resetForm();
                    listagemRef.current?.buscarDados();
                },
            };
            showLoading();
            await httpPost({ url: "/vendas/regra-arredondamento-valor-detalhe/", body: detalhe }, handlers);
            hideLoading();
        } else {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Detalhe de arredondamento de preço alterado com sucesso!",
                        life: 1500,
                    });
                    formik.resetForm();
                    listagemRef.current?.buscarDados();
                },
            };
            showLoading();
            await httpPut({ url: `/vendas/regra-arredondamento-valor-detalhe/${values.id}/`, body: detalhe }, handlers);
            hideLoading();
        }
    }

    const valorInteiroBodyTemplate = (rowData) => {
        if (!rowData.inteiro) return <span>--</span>;
        return parseNumberToMoneyHTML(rowData.inteiro);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    onClick={() => {
                        formik.setValues({
                            ...rowData,
                            inteiro: rowData.inteiro === null ? 0 : rowData.inteiro,
                            decimal: rowData.decimal * 100,
                        });
                    }}
                />
                <BotaoDelete
                    url="/vendas/regra-arredondamento-valor-detalhe/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja mesmo excluir o detalhe de arredondamento?</span>}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        {
            field: "inteiro",
            header: "Parte inteira",
            action: (e) => valorInteiroBodyTemplate(e),
        },
        {
            field: "decimal",
            header: "Decimal",
            money: true,
        },
        {
            field: "actions",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="inteiro" label="Parte inteira" />
                        <InputNumber
                            id="inteiro"
                            name="inteiro"
                            value={formik.values.inteiro}
                            prefix="R$ "
                            onValueChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="decimal" label="Decimal" />
                        <InputNumber
                            id="decimal"
                            name="decimal"
                            value={formik.values.decimal}
                            prefix="R$ 0."
                            max={99}
                            onValueChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-mt-auto p-col-12 p-md-2">
                        <Button icon={MAKO_ICONS.GRAVAR} label="Gravar" className="p-button-info" loading={loading} />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={`/vendas/regra-arredondamento-valor-detalhe/?regra_arredondamento=${regraArredondamentoValor?.id}`}
                configTabela={{ paginator: true, lazy: true }}
            />
            <MakoActionsButtons className="p-mt-3">
                <Button
                    icon={MAKO_ICONS.VOLTAR}
                    label="Voltar"
                    type="button"
                    className="p-button-danger"
                    to={"/vendas/cadastros/arredondamento-valor"}
                />
            </MakoActionsButtons>
        </>
    );
};
