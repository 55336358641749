import React, { useCallback, useEffect, useState } from "react";
import useHttp from "@/hooks/useHttp";

import PIX from "react-qrcode-pix";
import QRCode from "react-qr-code";

const BASE_DADOS = {
    chave: null,
    merchant: "",
    city: null,
    conteudo: null,
};

const BASE_URL = "/financeiro/chaves-pix";

export const MakoQrCodePix = ({ chaveId, valor = 0 }) => {
    const [dados, setDados] = useState(BASE_DADOS);
    const { httpGet } = useHttp();

    const buscarChave = useCallback(async () => {
        if (!chaveId) return;
        const handlers = {
            200: ({ data }) => {
                const chave = data?.tipo_chave.id === "T" ? `+55${data?.chave}` : data?.chave;
                setDados({ chave, merchant: data?.perfil.nome, city: data?.perfil.cidade, conteudo: data?.conteudo });
            },
        };

        await httpGet(
            {
                url: `${BASE_URL}/${chaveId}/`,
            },
            handlers
        );
    }, [chaveId, httpGet]);

    useEffect(() => buscarChave(), [buscarChave]);

    if (!dados?.chave) return null;
    if (dados?.conteudo) return <QRCode value={dados?.conteudo} />;
    return <PIX pixkey={dados.chave} merchant={dados.merchant} city={dados.city} amount={valor} />;
};
