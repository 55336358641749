import React from "react";

export const CamposObrigatorios = ({ singular, className, children }) => {
    const text = children || (singular ? "* Campo obrigatório." : "* Campos obrigatórios.");

    return (
        <p className={className}>
            <b>{text}</b>
        </p>
    );
};
