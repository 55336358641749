import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { WrapperRoute } from "./WrapperRoute";
import { WrapperAuthRoute } from "./WrapperAuthRoute";
import { routes } from "./routes";

export default function Routes() {
    return (
        <Switch>
            {routes.map((route, index) => {
                if (route.public) return <WrapperRoute key={index} exact {...route} />;
                return <WrapperAuthRoute key={index} exact {...route} />;
            })}
            <Redirect exact to="/nao-encontrado" />
        </Switch>
    );
}
