import React, { useEffect, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";
import { FinanceiroPagamentosForm } from "./formCadastro";
import { MultiplasParcelasForm } from "./formParcelas";
import { PagamentoProvider } from "@/contexts/pagamentoContext";
import usePagamento from "@/hooks/usePagamento";
import { PagamentoPrevisaoForm } from "./formPrevisoes";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { MakoErrosPersonalizados } from "@/components/MakoErrosPersonalizados";

const urlvoltar = "/financeiro/financeiro/pagamentos";

const TabPagamentos = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const toastRef = useRef(null);
    const history = useHistory();
    const { pagamento, handlePreencherPagamento, handlePagamentos, pagamentos, hasParcelas } = usePagamento();
    const { showLoading, hideLoading } = useLoading();
    const modalErrosRef = useRef(null);
    const { showSuccess, showError } = useToast();

    async function persistirPagamento() {
        showLoading();
        const resposta = await handlePagamentos();
        hideLoading();

        try{
            if(resposta.length === 0) history.push("/financeiro/financeiro/pagamentos");
            else if(resposta?.length > 0) {
                if (resposta[0].status === 200 || resposta[0].status === 201) {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Pagamentos cadastrados com sucesso.",
                        life: 1500,
                    });
                    history.push("/financeiro/financeiro/pagamentos");
                } else {
                    let lista = [];
                    resposta.forEach(parcela => {
                        let obj = {};
                        const keys = Object.keys(parcela);
                        const values = Object.values(parcela);
                        keys.forEach((key, index) => {
                            obj[key] = values[index];
                        })
                        lista.push(obj)
                    });
                    modalErrosRef.current?.abrirModal(lista);
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                if (resposta.status === 200 || resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Pagamentos cadastrados com sucesso.",
                        life: 1500,
                    });
                    history.push("/financeiro/financeiro/pagamentos");
                } else {
                    const keys = Object.keys(resposta.data);
                    const values = Object.values(resposta.data);
                    modalErrosRef.current?.abrirModal(keys.map((key, index) => 
                       ({erro: `${key}: ${values[index]}`})
                    ));
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar sua requisição.",
                life: 3000,
            });
        }
    }

    const alterarTab = (aba) => {
        if (aba === -1) {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        } else {
            if (activeTab < 6) {
                setActiveTab(activeTab + 1);
            }
        }
    };

    async function carregarPagamento() {
        if (props.props?.location.documento) await handlePreencherPagamento(props.props.location.documento);
    }

    useEffect(() => {
        carregarPagamento();
    }, []);

    const cancelar = () => {
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h5>
                        {pagamento?.id
                            ? `Manutenção de pagamento (Doc: ${pagamento.documento})`
                            : `Cadastro de pagamento ${pagamento ? `(Doc: ${pagamento.documento})` : ""} `}
                    </h5>
                    <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                        <TabPanel header="Cadastro" leftIcon="pi pi-save">
                            <FinanceiroPagamentosForm props={pagamento ? pagamento : null} />
                        </TabPanel>
                        <TabPanel header="Parcelas" leftIcon="pi pi-ellipsis-v" disabled={!pagamento ? pagamento === null : !hasParcelas }>
                            <MultiplasParcelasForm />
                        </TabPanel>
                        <TabPanel header="Previsões" leftIcon="pi pi-clone" disabled={!pagamento?.id}>
                            <PagamentoPrevisaoForm />
                        </TabPanel>
                    </TabView>

                    <div className="p-grid p-fluid p-justify-start">
                        {activeTab > 0 ? (
                            <div className="p-col-6 p-md-3">
                                <Button
                                    label="Anterior"
                                    icon="pi pi-angle-left"
                                    className="p-button-info"
                                    onClick={() => alterarTab(-1)}
                                />
                            </div>
                        ) : null}
                        {activeTab < 2 ? (
                            <div className="p-col-6 p-md-3">
                                <Button
                                    label="Próximo"
                                    icon="pi pi-angle-right"
                                    iconPos="right"
                                    className="p-button-info"
                                    onClick={() => alterarTab(1)}
                                />
                            </div>
                        ) : null}
                        <div className="p-col-12 p-md-3">
                            <Button label="Finalizar" className="p-button-success" onClick={() => persistirPagamento()} disabled={false} />
                        </div>

                        <div className="p-col-12 p-md-3">
                            <Button label="Voltar" className="p-button-danger" onClick={cancelar} />
                        </div>
                    </div>
                </div>
            </div>
            <MakoErrosPersonalizados
                ref={modalErrosRef}
                titulo="Inconsistências encontradas"
                colunas={[{ field: "erro", header: "Descrição da pendência" }]}
            />
        </div>
    );
};

export const TabPagamentosContext = (props) => {
    return (
        <PagamentoProvider>
            <TabPagamentos props={props} />
        </PagamentoProvider>
    );
};
