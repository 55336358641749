import React from "react";
import classNames from "classnames";
import { Panel } from "primereact/panel";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { Sessoes } from "./sessoes";

import { TIPOS_PARCELAS, REPETICAO_DIAS_PARCELAS } from "@/assets/constants/financeiro";

import useToast from "@/hooks/useToast";

export const Parcelas = ({
    parcela,
    parcelas = [],
    submit = () => {},
    handleLancar = () => {},
    handleEditar = () => {},
    handleRemover = () => {},
}) => {
    const { showWarning } = useToast();

    const formik = useFormik({
        initialValues: {
            tipo_parcela: null,
            repeticao: "30",
            quantidade: 2,
            data_primeira_parcela: null,
            paga_comissao_recebimento: false,
            previsao: false,
        },
        onSubmit: handleSubmit,
    });

    function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_parcela: Yup.string()
                    .required("O campo 'tipo de parcela' é obrigatório.")
                    .typeError("Informe um tipo de parcela."),
                repeticao: Yup.string()
                    .required("O campo 'repetição' é obrigatório.")
                    .matches(/^\d+$/, "Para esse campo é válido apenas dígitos (0-9).")
                    .typeError("Selecione uma repetição."),
                quantidade: Yup.number()
                    .required("O campo 'quantidade' é obrigatório.")
                    .min(1, "O valor mínimo para esse campo é 1.")
                    .max(120, "O valor máximo para esse campo é 120."),
                data_primeira_parcela: Yup.date()
                    .required("O campo 'data 1ª parcela' é obrigatório.")
                    .min(parcela.data_emissao, "A data da primeira parcela não pode ser inferior a data da emissão.")
                    .typeError("Informe uma data válida."),
                paga_comissao_recebimento: Yup.boolean().default(false),
                previsao: Yup.boolean().default(false),
            });
            const dadosValidos = formSchema.validateSync(values, { abortEarly: false });
            if (dadosValidos.previsao && !parcela.plano_orcamentario) {
                showWarning({
                    summary: "Aviso!",
                    detail: "Para recebimentos que vão gerar previsão é preciso informar um plano orçamentário.",
                    life: 3000,
                });
                return;
            }
            handleLancar(dadosValidos);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <>
            {parcela.id === 0 && (
                <Panel header="Definir parcelas" className="p-mb-3">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <Label htmlFor="tipo-parcela" label="Tipo de parcela" obrigatorio />
                                <Dropdown
                                    id="tipo-parcela"
                                    name="tipo_parcela"
                                    options={TIPOS_PARCELAS}
                                    value={formik.values.tipo_parcela}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo_parcela })}
                                />
                                {formik.errors.tipo_parcela && (
                                    <small className="p-error">{formik.errors.tipo_parcela}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="repeticao" label="Repetição" obrigatorio />
                                <Dropdown
                                    id="repeticao"
                                    name="repeticao"
                                    options={REPETICAO_DIAS_PARCELAS}
                                    placeholder="Selecione ou digite"
                                    editable
                                    maxLength={4}
                                    value={formik.values.repeticao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.repeticao })}
                                />
                                {formik.errors.repeticao && (
                                    <small className="p-error">{formik.errors.repeticao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-1">
                                <Label htmlFor="quantidade" label="Qtd." obrigatorio />
                                <InputNumber
                                    id="quantidade"
                                    min={1}
                                    max={120}
                                    useGrouping={false}
                                    value={formik.values.quantidade}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.quantidade })}
                                />
                                {formik.errors.quantidade && (
                                    <small className="p-error">{formik.errors.quantidade}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <Label htmlFor="data-1-parcela" label="Data 1ª parcela" obrigatorio />
                                <MakoCalendar
                                    id="data-1-parcela"
                                    name="data_primeira_parcela"
                                    minDate={parcela?.data_emissao}
                                    valueCalendar={formik.values.data_primeira_parcela}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.data_primeira_parcela })}
                                />
                                {formik.errors.data_primeira_parcela && (
                                    <small className="p-error">{formik.errors.data_primeira_parcela}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field-checkbox p-col-12 p-md-2">
                                <Checkbox
                                    id="previsao"
                                    name="previsao"
                                    checked={formik.values.previsao}
                                    onChange={formik.handleChange}
                                />
                                <Label htmlFor="previsao" label="Gerar previsão?" />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <Button type="submit" icon="pi pi-refresh" label="Gerar parcelas" />
                            </div>
                        </div>
                    </form>
                </Panel>
            )}
            <Sessoes.Parcelas
                parcelas={parcelas}
                repeticao={formik.values.repeticao}
                handleEditar={handleEditar}
                handleRemover={handleRemover}
            />
            <Sessoes.Totais valido={submit} parcelas={parcelas} />
        </>
    );
};
