import { useEffect, useRef } from "react";
import React, { useCallback, useState } from "react";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import MakoListagem from "@/components/MakoListagem";
import useToast from "@/hooks/useToast";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import useLoading from "@/hooks/useLoading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { InputNumber } from "primereact/inputnumber";
import { formatarCasasDecimais } from "@/assets/util/util";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";

export const AjustaPrecoFornecedor = () => {
    const listagemRef = useRef();
    const { showLoading, hideLoading } = useLoading();
    const { showWarning, showError, showSuccess } = useToast();
    const history = useHistory();
    const [itensVenda, setItensVenda] = useState([]);
    const toast = useToast();
    const [itensSelecionado, setItensSelecionados] = useState([]);
    const [changePrazoEntrega, setChangePrazoEntrega] = useState(true);
    const [formatarDocumento] = useFormatCNPJCPF();
    const [statusButton, setStatusButton] = useState(false);
    const { httpGet, httpPatch } = useHttp();

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            fornecedor: null,
            percentual: null,
            media_entrega: null,
        },
    });

    const autoCompleteFornecedorTemplate = (fornecedor) => {
        fornecedor.label = `${fornecedor.nome} - ${formatarDocumento(fornecedor.identificacao)}`;
        return <div>{`${fornecedor.nome} - ${formatarDocumento(fornecedor.identificacao)}`}</div>;
    };

    const prioridadeTemplate = (prioridade) => {
        if (prioridade === "B") return "Baixa";
        else if (prioridade === "A") return "Alta";
        else return "Media";
    };

    const colunas = [
        { selectionMode: "multiple", style: { width: "5rem" } },
        {
            field: "sku.codigo",
            header: "Código",
        },
        {
            field: "sku.descricao_derivada",
            header: "Descrição da mercadoria/produto",
            style: { minWidth: "230px" },
        },
        {
            field: "sku.unidade_padrao.nome",
            header: "Unidade",
        },
        {
            field: "prioridade",
            header: "Prioridade",
            action: (e) => prioridadeTemplate(e.prioridade),
        },
        {
            field: "media_entrega",
            header: "Prazo Entrega",
        },
        {
            field: "preco_compra",
            header: "Preço",
            action: (e) => formatarCasasDecimais(e.preco_compra),
        },
    ];

    const confirmaAlteracoes = () => {
        confirmDialog({
            message: "Deseja confirmar alterações?",
            header: "Confirmação",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-success",
            rejectClassName: "p-button-danger",
            acceptLabel: "Sim",
            rejectLabel: "Não",
            accept: ajustarProdutos,
        });
    };

    const validaDados = useCallback(() => {
        if (formik.values.fornecedor !== null && formik.values.percentual !== null && formik.values.fornecedor !== "") {
            setStatusButton(true);
        } else {
            setStatusButton(false);
        }
    }, [formik.values.percentual, formik.values.fornecedor]);

    useEffect(() => {
        validaDados();
    }, [validaDados]);

    const validaFornecedor = (fornecedor) => {
        return fornecedor?.id === undefined;
    };

    async function listaItensVenda() {
        if (!validaFornecedor(formik.values.fornecedor)) {
            const handlers = {
                200: ({ data }) => {
                    setItensVenda(data.results);
                },
                400: () => showError(),
            };

            showLoading();
            await httpGet(
                {
                    url: `/produtos/vinculo-produtos-fornecedor/?fornecedor=${formik.values.fornecedor.id}`,
                },
                handlers
            );
            hideLoading();
        } else {
            showWarning({
                summary: "Aviso",
                detail: "Fornecedor não informado.",
                life: 3000,
            });
        }
    }

    async function ajustarProdutos() {
        if (itensSelecionado.length !== 0) {
            showLoading();
            if (formik.values.percentual !== null)
                if (formik.values.percentual >= 0) {
                    itensSelecionado.map((item) => {
                        return (item.preco_compra =
                            parseFloat(item.preco_compra) + (item.preco_compra * formik.values.percentual) / 100);
                    });
                } else {
                    itensSelecionado.map((item) => {
                        return (item.preco_compra =
                            parseFloat(item.preco_compra) / (1 - formik.values.percentual / 100));
                    });
                }

            if (formik.values.media_entrega !== null)
                itensSelecionado.map((item) => {
                    return (item.media_entrega += formik.values.media_entrega);
                });

            const arrayChanged = itensSelecionado.map((item) => {
                return {
                    id: item.id,
                    preco_compra: formatarCasasDecimais(item.preco_compra),
                    media_entrega: item.media_entrega,
                };
            });

            const resposta = await Promise.all(
                arrayChanged.map(async (item) => {
                    let erro = false;
                    const handlers = {
                        400: () => {
                            showError();
                            erro = true;
                        },
                    };
                    await httpPatch(
                        {
                            url: `/produtos/vinculo-produtos-fornecedor/${item.id}/`,
                            body: item,
                        },
                        handlers
                    );
                    return erro;
                })
            );
            hideLoading();

            if (resposta.filter((e) => e).length > 0) showError();
            else {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Preço processado com sucesso!",
                    life: 1500,
                });
                listaItensVenda();
            }
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <PageBase>
                <h4>Alterar Preço do Fornecedor</h4>
                <div className="p-fluid p-formgrid p-grid p-mb-0">
                    <div className="p-field p-col-12 p-md-10">
                        <label htmlFor="fornecedor">Fornecedor: *</label>
                        <MakoInputFornecedor
                            id="fornecedor"
                            name="fornecedor"
                            key="id"
                            value={formik.values.fornecedor}
                            itemTemplate={autoCompleteFornecedorTemplate}
                            field="label"
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fornecedor })}
                        />
                        {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2 p-mt-5">
                        <Button
                            type="button"
                            icon="pi pi-search"
                            label="Listar Itens"
                            className="p-mr-2 p-mb-2"
                            onClick={listaItensVenda}
                        />
                    </div>
                </div>
                <MakoListagem
                    titulo="Itens vinculados ao Fornecedor"
                    ref={listagemRef}
                    colunas={colunas}
                    dadosLocal={itensVenda}
                    selectionMode={"checkbox"}
                    configTabela={{
                        paginator: true,
                        scrollable: true,
                        scrollHeight: "400px",
                        selectionMode: "multiple",
                        selection: itensSelecionado,
                        onSelectionChange: (e) => setItensSelecionados(e.value),
                    }}
                />
                <div className="p-fluid p-formgrid p-grid p-mt-3">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="percentual">Percentual: </label>
                        <MakoInputPercent
                            id="percentual"
                            name="percentual"
                            casasDecimais={2}
                            value={formik.values.percentual}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.percentual })}
                            min={-999999999999}
                        />
                        {formik.errors.percentual && <small className="p-error">{formik.errors.percentual}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="fornecedor">Alterar o prazo de entrega</label>
                        <span className="p-buttonset">
                            <Button
                                label="Sim"
                                onClick={() => setChangePrazoEntrega(true)}
                                outlined={changePrazoEntrega ? false : true}
                            />
                            <Button
                                label="Não"
                                onClick={() => setChangePrazoEntrega(false)}
                                outlined={!changePrazoEntrega ? false : true}
                            />
                        </span>
                        {formik.errors.percentual && <small className="p-error">{formik.errors.percentual}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="media_entrega">Prazo de entrega</label>
                        <InputNumber
                            id="media_entrega"
                            name="media_entrega"
                            showButtons
                            value={formik.values.media_entrega}
                            label="dias"
                            min={0}
                            disabled={!changePrazoEntrega}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.media_entrega })}
                        />
                        {formik.errors.media_entrega && (
                            <small className="p-error">{formik.errors.media_entrega}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-2">
                        <Button
                            label="Processar"
                            icon="pi pi-check"
                            type="button"
                            className="p-button-success p-mr-2"
                            onClick={confirmaAlteracoes}
                            disabled={!statusButton}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-2">
                        <Button
                            label="Voltar"
                            icon="pi pi-angle-double-left"
                            type="button"
                            className="p-button-danger p-mr-2"
                            onClick={() => history.push("/produto/cadastros/vinculo-fornecedor")}
                        />
                    </div>
                </div>
            </PageBase>
            <ConfirmDialog />
        </>
    );
};
