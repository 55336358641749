import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useClearRefs from "@/hooks/useClearRefs";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";

const url = "/vendas/motivo-devolucao/";

export const MotivoDevolucaoPage = () => {
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_MOTIVODEVOLUCAO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de motivo de devolução"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/vendas/cadastros/motivo-devolucao/form",
                        state: { motivo: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_MOTIVODEVOLUCAO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/vendas/motivo-devolucao/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir o motivo "}
                            <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O tipo de entrada não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_MOTIVODEVOLUCAO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/vendas/cadastros/motivo-devolucao/form"}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "8%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Motivos de Devolução"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
