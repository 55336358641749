import React, { useMemo } from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";

import { dataToStr } from "@/assets/util/datas";
import permissoes from "@/assets/constants/permissoes";

const { COMPRAS_ENTRADA_DEVOLUCAOFORNECEDOR_INCLUIR } = permissoes;

const BASE_URL = "/compras/parcela-devolucao-fornecedor/";

const BASE_PARCELA = {
    id: null,
    data_vencimento: null,
    parcela: null,
    model: null,
    quitado: false,
};

export const ParcelaRemover = ({ parcela = BASE_PARCELA, successCallback = () => {}, disabled = false }) => {
    const mensagemConfirmacao = useMemo(() => {
        const { data_vencimento, parcela: _parcela } = parcela || {};
        return (
            <span>
                Deseja realmente remover a parcela:{" "}
                <b>
                    {_parcela} - {dataToStr(data_vencimento, "dd/MM/yyyy")}
                </b>
                ?
            </span>
        );
    }, [parcela]);

    const isDisabled = useMemo(() => {
        const { model, quitado } = parcela || {};
        return disabled || model === "ent" || quitado;
    }, [disabled, parcela]);

    return (
        <MakoControleAcesso
            permissao={[COMPRAS_ENTRADA_DEVOLUCAOFORNECEDOR_INCLUIR]}
            componente={BotaoDelete}
            url={BASE_URL}
            objetoId={parcela.id}
            msgToastErroExclusao="A parcela não pode ser excluída."
            msgConfirmacao={mensagemConfirmacao}
            tooltip="Remover parcela"
            disabled={isDisabled}
            tooltipOptions={{ position: "left" }}
            onDelete={successCallback}
            exigeConfirmacao
        />
    );
};
