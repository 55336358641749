import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Corrigir as Form } from "../../features";
import { Dialog } from "primereact/dialog";

const Componente = ({ successCallback = () => {} }, ref) => {
    const [recebimento, setRecebimento] = useState({});
    const [visible, setVisible] = useState(false);

    const success = () => {
        setVisible(false);
        successCallback();
    };

    const cancel = () => {
        setVisible(false);
    };

    const show = (value) => {
        setRecebimento(value);
        setVisible(true);
    };

    useImperativeHandle(ref, () => {
        return {
            show,
            hide: () => setVisible(false),
        };
    });

    return (
        <Dialog
            visible={visible}
            header={`Corrigir recebimento N° ${recebimento?.id}`}
            onHide={() => setVisible(false)}
            style={{ width: "80vw" }}
        >
            <Form recebimento={recebimento} successCallback={success} cancelCallback={cancel} />
        </Dialog>
    );
};

export const Corrigir = forwardRef(Componente);
