import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";

import { gerarFiltrosUrl } from "@/assets/util/util";
import { GRUPO_CHAVE_PARAMETRO } from "@/assets/constants/constants";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";

const URL_BACKEND = "/configuracoes/parametros?";

const BASE_TITULO = <span>Listagem de parâmetros</span>;

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    align: "center",
    style: { width: "10%" },
};

const BASE_COLUMN_FILTERS = {
    chave__descricao: {
        operator: "and",
        constraints: [{ value: "", matchMode: "icontains" }],
    },
    chave__grupo: { value: null, matchMode: "equals" },
};

const BASE_FILTROS = {
    integracao: false,
};

const grupoChaveBodyTemplate = (rowData) => {
    const grupo = GRUPO_CHAVE_PARAMETRO.find((el) => el.value === rowData.chave.grupo);
    if (grupo) return <span>{grupo.label}</span>;
    return <span>--</span>;
};

const tipoParametroBody = (rowData) => {
    if (rowData.chave.tipo === "U") {
        return <Tag className="p-mr-2" severity="success" value="USUÁRIO" />;
    }
    return <Tag className="p-mr-2" severity="danger" value="SISTEMA" />;
};

const grupoFiltroTemplate = (options) => {
    return (
        <Dropdown
            options={GRUPO_CHAVE_PARAMETRO}
            value={options.value}
            placeholder="Selecione"
            showClear
            onChange={(e) => options.filterCallback(e.value, options.index)}
            className="p-column-filter"
        />
    );
};

const valorTemplate = (chave = "", valor = "") => {
    if (typeof valor !== "string") return <span>{valor}</span>;
    if (valor.length < 50) return <span>{valor}</span>;
    const target = chave.replace(/\./g, "-");

    return (
        <>
            <Tooltip target={`.${target}`} mouseTrack mouseTrackLeft={10} content={valor} />
            <span className={target}>{valor.slice(0, 50).concat("...")}</span>
        </>
    );
};

const BASE_COLUMNS = [
    {
        field: "chave.descricao",
        header: "Descrição",
        filter: true,
        filterField: "chave__descricao",
    },
    {
        field: "chave.grupo",
        header: "Grupo",
        style: { width: "12%" },
        filter: true,
        filterField: "chave__grupo",
        filterElement: grupoFiltroTemplate,
        action: (e) => grupoChaveBodyTemplate(e),
    },
    {
        field: "valor_custom",
        header: "Valor",
        style: { width: "15%" },
        action: ({ chave, valor_custom }) => valorTemplate(chave?.chave, valor_custom),
    },
    {
        field: "perfil",
        header: "Perfil",
        action: ({ perfil }) => (perfil?.nome_curto ? perfil?.nome_curto : perfil?.nome),
    },
    { field: "tipo", header: "Tipo", style: { width: "5%" }, action: (e) => tipoParametroBody(e) },
];

export const Parametros = ({
    actions,
    aposPesquisar,
    listagemProps = {},
    configTabela = {},
    titulo = BASE_TITULO,
    filtros = BASE_FILTROS,
    listagemRef = null,
}) => {
    const url = useMemo(() => {
        let _url = URL_BACKEND;
        if (typeof filtros === "object") _url = `${_url}&${gerarFiltrosUrl(filtros)}`;
        return _url;
    }, [filtros]);

    const colunas = useMemo(() => {
        if (actions) return [...BASE_COLUMNS, { ...BASE_COLUMN_ACTIONS, action: actions }];
        return BASE_COLUMNS;
    }, [actions]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo={titulo}
            colunas={colunas}
            urlPesquisa={url}
            aposPesquisar={aposPesquisar}
            filtros={BASE_COLUMN_FILTERS}
            botaoExportar
            {...listagemProps}
            configTabela={{
                lazy: true,
                paginator: true,
                ...configTabela,
            }}
        />
    );
};
