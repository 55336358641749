import React, { useMemo } from "react";

import { MakoListagem } from "@/components/MakoListagem";

const BASE_URL = "/financeiro/previsoes-recebimentos/";

export const Previsao = ({ agrupador }) => {
    const colunas = [
        { field: "data_prevista", header: "Data prevista", style: { width: "10%" }, dateFormat: "dd/MM/yyyy" },
        { field: "valor_previsto", header: "Valor previsto", style: { width: "10%" }, money: true },
        { field: "plano_orcamentario.descricao", header: "Plano orçamentário" },
    ];

    const url = useMemo(() => {
        return `${BASE_URL}?recebimento__agrupador=${agrupador}`;
    }, [agrupador]);

    return <MakoListagem urlPesquisa={url} fazerBusca={!!agrupador} colunas={colunas} />;
};
