import React from "react";

import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Label } from "@/components/Label";

const BASE_DEVEDOR = { doc: "", nome: "" };

export const InfoDevedor = ({ devedor = BASE_DEVEDOR }) => {
    return (
        <Panel header="Devedor" className="p-mb-3">
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="doc" label="CPF / CNPJ" />
                    <InputText id="doc" disabled value={devedor.doc} />
                </div>
                <div className="p-field p-col-12 p-md-8">
                    <Label htmlFor="nome-completo" label="Nome completo" />
                    <InputText id="nome-completo" disabled value={devedor.nome} />
                </div>
            </div>
        </Panel>
    );
};
