import React, { useEffect, useRef } from "react";
import { Button } from "primereact/button";

import { ResumoDevolucao } from "./ResumoDevolucao";
import { ModalItem } from "./ModalItem";
import { MakoListagem } from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import useDevolucao from "@/hooks/useDevolucao";
import { BlockUI } from "@/components/BlockUI";

export const ItensDevolucaoForm = () => {
    const listagemRef = useRef(null);
    const modalItemRef = useRef(null);
    const { dadosBasicos, atualizarTotalDevolucao } = useDevolucao();

    useEffect(() => {
        return () => {
            modalItemRef.current = null;
        };
    }, []);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    onClick={() => modalItemRef.current?.abrirModal(rowData)}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                />
                <BotaoDelete
                    url="/vendas/itens-troca-devolucao/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Confirma a exclusão do item <b>{rowData.sku.descricao_derivada}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O item de devolução não pode ser excluído."
                    onDelete={async () => {
                        listagemRef.current?.buscarDados();
                        await atualizarTotalDevolucao();
                    }}
                    classNames="p-mr-2 p-mb-1"
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "sku.codigo",
            header: "Código",
            style: { width: "10%" },
        },
        {
            field: "sku.descricao_derivada",
            header: "Descrição do produto",
        },
        { field: "unidade_medida.sigla", header: "Und", style: { width: "5%" } },
        { field: "quantidade", header: "Qtd.", style: { width: "8%" }, decimal: true },
        { field: "subtotal", header: "Total", style: { width: "10%" }, money: true },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: actionBodyTemplate },
    ];

    const painelEsquerdo = (
        <>
            <Button
                icon="pi pi-plus"
                label="Adicionar item"
                onClick={() => modalItemRef.current?.abrirModal()}
                className="p-button-success p-mr-2"
            />
        </>
    );

    return (
        <>
            <ResumoDevolucao devolucao={dadosBasicos} />
            <BlockUI
                blocked={dadosBasicos?.status === "F"}
                template={<b style={{ fontSize: "3rem" }}>A devolução foi processada.</b>}
            >
                <MakoListagem
                    ref={listagemRef}
                    urlPesquisa={`/vendas/itens-troca-devolucao/?devolucao=${dadosBasicos?.id}`}
                    painelEsquerdo={painelEsquerdo}
                    colunas={colunas}
                />
                <ModalItem
                    ref={modalItemRef}
                    devolucaoId={dadosBasicos?.id}
                    onSalvar={async () => {
                        listagemRef.current?.buscarDados();
                        await atualizarTotalDevolucao();
                    }}
                />
            </BlockUI>
        </>
    );
};
