import React from "react";

import { DevolucaoProvider } from "@/contexts/devolucaoContext";

import { TabDevolucaoMercadorias } from "./tab";

export const DevolucaoVendaForm = (props) => {
    const devolucaoId = props.location.state;

    return (
        <DevolucaoProvider>
            <TabDevolucaoMercadorias devolucaoId={devolucaoId} />
        </DevolucaoProvider>
    );
};
