import React, { useRef } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import MakoListagem from "@/components/MakoListagem";
import { MakoInputTransportador } from "@/components/MakoInputs/MakoInputTransportador";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { BotaoDelete } from "@/components/BotaoDelete";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useOrdemCompra from "@/hooks/useOrdemCompra";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useHttp from "@/hooks/useHttp";

export const TransportadoresOrdemCompraForm = () => {
    const listagemRef = useRef(null);
    const [formatarDocumento] = useFormatCNPJCPF();
    const { dadosBasicos, permiteAlterar, atualizarValoresOC } = useOrdemCompra();
    const { httpPost, httpPut } = useHttp();

    const formik = useFormik({
        initialValues: {
            transportador: null,
            trajeto: "",
            cif: false,
            valor_frete: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                transportador: Yup.object().typeError("Você precisa selecionar um transportador."),
                trajeto: Yup.string().max(60, "O campo 'trajeto' não pode ser maior que 60 caracteres."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const vinculo = {
                ...values,
                ordem_compra: dadosBasicos?.id,
                transportador: values.transportador.id,
            };
            if (!values.id) {
                const handlers = {
                    201: async () => {
                        formik.resetForm();
                        listagemRef.current?.buscarDados();
                        await atualizarValoresOC();
                    },
                };
                await httpPost({ url: "/compras/vinculo-transportador-ordem-compra/", body: vinculo }, handlers);
            } else {
                const handlers = {
                    200: async () => {
                        formik.resetForm();
                        listagemRef.current?.buscarDados();
                        await atualizarValoresOC();
                    },
                };
                await httpPut(
                    { url: `/compras/vinculo-transportador-ordem-compra/${values.id}/`, body: vinculo },
                    handlers
                );
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const formatarCNPJBodyTemplate = (rowData) => {
        return formatarDocumento(rowData.transportador.identificacao);
    };

    const cifFobBodyTemplate = (rowData) => {
        if (rowData.cif) return <span>CIF</span>;
        return <span>FOB</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon={MAKO_ICONS.EDITAR}
                    disabled={!permiteAlterar}
                    className="p-button-rounded p-button-warning"
                    onClick={() => formik.setValues(rowData)}
                />
                <BotaoDelete
                    url={`/compras/vinculo-transportador-ordem-compra/`}
                    objetoId={rowData.id}
                    disabled={!permiteAlterar}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir o transportador <b>{rowData.transportador.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O transportador não pode ser excluído."
                    onDelete={async () => {
                        listagemRef.current?.buscarDados();
                        await atualizarValoresOC();
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunasTransportadores = [
        { field: "transportador.nome", header: "Nome" },
        {
            field: "transportador.identificacao",
            header: "CNPJ",
            style: { width: "15%" },
            action: (e) => formatarCNPJBodyTemplate(e),
        },
        { field: "trajeto", header: "Trajeto" },
        { field: "valor_frete", header: "Valor frete", money: true },
        { field: "cif", header: "CIF / FOB", action: (e) => cifFobBodyTemplate(e) },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="transportador" label="Transportador" obrigatorio />
                        <MakoInputTransportador
                            id="transportador"
                            name="transportador"
                            value={formik.values.transportador}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.transportador })}
                        />
                        {formik.errors.transportador && (
                            <small className="p-error">{formik.errors.transportador}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="trajeto" label="Trajeto" />
                        <InputText
                            id="trajeto"
                            name="trajeto"
                            value={formik.values.trajeto}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.trajeto })}
                        />
                        {formik.errors.trajeto && <small className="p-error">{formik.errors.trajeto}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="valor-frete-previsto" label="Valor do frete previsto" />
                        <MakoInputMoeda
                            id="valor-frete-previsto"
                            name="valor_frete_previsto"
                            disabled
                            valueMoeda={dadosBasicos?.valor_frete_previsto || 0}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="valor-frete" label="Valor do frete" />
                        <MakoInputMoeda
                            id="valor-frete"
                            name="valor_frete"
                            valueMoeda={formik.values.valor_frete}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-5">
                        <Checkbox id="cif" name="cif" checked={formik.values.cif} onChange={formik.handleChange} />
                        <Label htmlFor="cif" label="Frete pago pelo emitente?" />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button type="submit" icon={MAKO_ICONS.NOVO} disabled={!permiteAlterar} label="Adicionar" />
                    <Button
                        label="Limpar"
                        type="reset"
                        disabled={!permiteAlterar}
                        icon={MAKO_ICONS.LIMPAR_FORM}
                        className="p-button-warning"
                        onClick={() => formik.resetForm()}
                    />
                </MakoActionsButtons>
            </form>
            <div className="p-mt-3">
                <MakoListagem
                    ref={listagemRef}
                    urlPesquisa={`/compras/vinculo-transportador-ordem-compra/?ordem_compra=${dadosBasicos?.id}`}
                    colunas={colunasTransportadores}
                    configTabela={{ lazy: true, paginator: true }}
                />
            </div>
        </>
    );
};
