import React, { useCallback, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { dataToStr } from "@/assets/util/datas";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import useHttp from "@/hooks/useHttp";

export const MakoHistoricoCustos = ({ sku, centro_estocagem, tipo = "N", ...config }) => {
    const [custos, setCustos] = useState("Não existem registros");
    const [loading, setLoading] = useState(false);
    const { httpGet } = useHttp();

    const buscaHistorico = useCallback(async () => {
        if (sku) {
            const handlers = {
                200: ({ data }) => {
                    if (data.results?.length > 0) {
                        let tooltip = "";
                        data.results.forEach(({ quantidade, valor, data }) => {
                            tooltip += `${dataToStr(data, "dd/MM/yyyy")} - ${
                                parseNumberToMoneyHTML(Math.abs(valor / quantidade)).props.children
                            }\n`;
                        });
                        setCustos(tooltip);
                    } else setCustos("Não existem registros");
                },
            };

            setLoading(true);
            await httpGet(
                {
                    url: `/produtos/saldos-sku?sku=${sku}&centro_estocagem=${centro_estocagem}&centro_estocagem__tipo=${tipo}${
                        tipo === "C" ? "&consolidado=true" : ""
                    }&valor_exclude=0&limit=5`,
                },
                handlers
            );
            setLoading(false);
        }
    }, [sku, centro_estocagem, tipo, httpGet]);

    useEffect(() => {
        buscaHistorico();
    }, [buscaHistorico]);

    return (
        <>
            <Button
                type="button"
                icon="pi pi-search"
                severity="secondary"
                loading={loading}
                tooltip={custos}
                tooltipOptions={{ position: "left" }}
                {...config}
            />
        </>
    );
};
