import React from "react";
import { Skeleton as SkeletonPR } from "primereact";

export const Skeleton = () => {
    return (
        <>
            <SkeletonPR height="4rem" className="p-mr-2 p-mt-2" />
            <SkeletonPR height="4rem" className="p-mr-2 p-mt-2" />
            <SkeletonPR height="4rem" className="p-mr-2 p-mt-2" />
            <SkeletonPR height="4rem" className="p-mr-2 p-mt-2" />
        </>
    );
};
