import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";

import { Dialog } from "primereact/dialog";

import { COLORS_VALIDADE } from "@/assets/constants/constants_styles";
import { ValidadeOverlay, Visualizar as VisualizarForm } from "../../features";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

const Component = (_, ref) => {
    const [visible, setVisible] = useState(false);
    const [recebimento, setRecebimento] = useState({});

    const [formatDocumento] = useFormatCNPJCPF();

    const handleOpen = (value = {}) => {
        if (recebimento?.id !== value?.id) setRecebimento(value);
        setVisible(true);
    };

    const handleClose = () => setVisible(false);

    useImperativeHandle(ref, () => {
        return {
            show: handleOpen,
        };
    });

    const header = useMemo(() => {
        const { id, devedor, dias_atraso, quitado, cancelado } = recebimento || {};
        let color = COLORS_VALIDADE.PENDENTE;
        if (cancelado) color = COLORS_VALIDADE.CANCELADA;
        else if (!quitado && dias_atraso > 0) color = COLORS_VALIDADE.VENCIDA;
        else if (quitado) color = COLORS_VALIDADE.CONCLUIDA;
        const value = `Recebimento: ${id} - ${devedor?.nome} - ${formatDocumento(devedor?.identificacao)}`;
        return (
            <div className="p-d-flex">
                <ValidadeOverlay color={color} />
                <span style={{ color }}>{value}</span>
            </div>
        );
    }, [recebimento, formatDocumento]);

    return (
        <Dialog visible={visible} header={header} onHide={handleClose} style={{ width: "70vw" }}>
            <VisualizarForm recebimento={recebimento} />
        </Dialog>
    );
};

export const Visualizar = forwardRef(Component);
