import React, { useRef } from "react";

import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoButton as Button } from "@/components/MakoButton";
import useClearRefs from "@/hooks/useClearRefs";
import { BooleanFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";

export const TiposEntradaPage = () => {
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/compras/cadastros/tipos-entrada/form"}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/compras/cadastros/tipos-entrada/form",
                        state: rowData,
                    }}
                />
                <BotaoDelete
                    url="/compras/tipos-entradas/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir o tipo de entrada <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O tipo de entrada não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        { field: "descricao", header: "Descrição", filter: true, filterElemente: TextoFiltroTemplate },
        {
            field: "gera_financeiro",
            header: "Gera financeiro?",
            style: { width: "20%" },
            action: ({ gera_financeiro }) => gerarStatusBooleanTemplate(gera_financeiro),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "requer_ordem_compra",
            header: "Requer ordem de compra?",
            style: { width: "20%" },
            action: ({ requer_ordem_compra }) => gerarStatusBooleanTemplate(requer_ordem_compra),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de tipos de entrada"
                colunas={colunas}
                urlPesquisa="/compras/tipos-entradas/"
                painelEsquerdo={painelEsquerdoTabela}
                configTabela={{
                    lazy: true,
                    paginator: true,
                }}
                filtros={{
                    gera_financeiro: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    requer_ordem_compra: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
