import React, { useRef } from "react";

import { PageBase } from "@/components/PageBase";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";

const url = "/compras/categorias-ordens-compra/";

export const CategoriaOcPage = () => {
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.COMPRAS_ORDEMCOMPRA_CATEGORIAS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de categoria"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/compras/ordens-compra/categorias-ordens-compra/form",
                        state: { categoria: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.COMPRAS_ORDEMCOMPRA_CATEGORIAS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/compras/categorias-ordens-compra/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a categoria <b>{rowData.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A categoria não pode ser excluída."
                    tooltip="Deletar categoria"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.COMPRAS_ORDEMCOMPRA_CATEGORIAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/compras/ordens-compra/categorias-ordens-compra/form"}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "requer_financeiro",
            header: "Requer financeiro?",
            style: { width: "15%" },
            action: ({ requer_financeiro }) => gerarStatusBooleanTemplate(requer_financeiro),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Categorias de ordem de compra"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
