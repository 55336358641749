import React, { useCallback, useMemo } from "react";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Label } from "@/components/Label";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

import permissoes from "@/assets/constants/permissoes";

const { FINANCEIRO_FINANCEIRO_CAIXAMOV_GERARRESUMO } = permissoes;

export const SaldoInfo = ({ caixaMovimento, updateCallback = async () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost } = useHttp();

    const saldo = useMemo(() => {
        const dinheiro_inicial = parseFloat(caixaMovimento.dinheiro_inicial) || 0;
        const resumos = caixaMovimento.resumos || [];
        const saldo =
            dinheiro_inicial +
            resumos.reduce((total, item) => {
                if (item.natureza === "D") return total - parseFloat(item.valor);
                return total + parseFloat(item.valor);
            }, 0);
        return saldo;
    }, [caixaMovimento]);

    const atualizarResumo = useCallback(async () => {
        const handlers = {
            201: async ({ data }) => {
                await updateCallback(data);
            },
        };
        showLoading();
        await httpPost({ url: `/financeiro/caixas/${caixaMovimento.id}/operar/gerar-resumo` }, handlers);
        hideLoading();
    }, [showLoading, hideLoading, caixaMovimento, updateCallback, httpPost]);

    return (
        <div className="p-field p-col-12 p-md-2">
            <Label label="Saldo atual" />
            <div className="p-inputgroup">
                <MakoInputMoeda disabled valueMoeda={saldo} />
                <MakoControleAcesso
                    componente={Button}
                    permissao={[FINANCEIRO_FINANCEIRO_CAIXAMOV_GERARRESUMO]}
                    icon="pi pi-sync"
                    loading={loading}
                    tooltip="Atualizar saldo"
                    className="p-button-info"
                    type="button"
                    onClick={atualizarResumo}
                />
            </div>
        </div>
    );
};
