import React, { useEffect } from "react";

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";

import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoInputPerfil } from "@/components/MakoInputs";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { validaEmail, validarCNPJ, validarCPF } from "@/assets/util/validacoes";
import { TIPO_CHAVE_PIX_CHOICE } from "@/assets/constants/constants";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const FinanceiroChavePixFormBase = ({ chavePix, modal, aposSalvar }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showWarning } = useToast();
    const { httpPost, httpPut } = useHttp();

    const history = useHistory();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            perfil: null,
            descricao: "",
            conta_bancaria: null,
            tipo_chave: null,
            chave: "",
            conteudo: "",
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil: Yup.object()
                    .required("O campo 'perfil' é obrigatório.")
                    .typeError("O campo 'perfil' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                conta_bancaria: Yup.number().nullable(),
                tipo_chave: Yup.string()
                    .required("O campo 'tipo de chave' é obrigatório.")
                    .typeError("Selecione um tipo de chave."),
                chave: Yup.string().required("O campo 'chave' é obrigatório."),
            });

            await formSchema.validate(values, { abortEarly: false });

            let chave_valida = false;
            if (values.tipo_chave === "C") {
                if (validarCNPJ(values.chave) || validarCPF(values.chave)) chave_valida = true;
            } else if (values.tipo_chave === "A" && values.chave.length < 255) chave_valida = true;
            else if (values.tipo_chave === "T" && values.chave.length >= 8 && values.chave.length <= 14)
                chave_valida = true;
            else if (values.tipo_chave === "E" && validaEmail(values.chave)) chave_valida = true;

            if (chave_valida) {
                const chavePix = { ...values, perfil: values.perfil.id };
                if (!values.id) {
                    const handlersPost = {
                        201: () => {
                            showSuccess({
                                summary: "Sucesso",
                                detail: "Chave Pix cadastrada com sucesso!",
                                life: 1500,
                            });
                            if (aposSalvar) {
                                aposSalvar();
                            }
                            history.push("/financeiro/cadastros/chaves-pix");
                        },
                    };
                    showLoading();
                    await httpPost({ url: "/financeiro/chaves-pix/", body: chavePix }, handlersPost);
                    hideLoading();
                } else {
                    const handlersPut = {
                        200: () => {
                            showSuccess({
                                summary: "Sucesso",
                                detail: "Chave Pix alterada com sucesso!",
                                life: 1500,
                            });
                            if (aposSalvar) {
                                aposSalvar();
                            }
                            history.push("/financeiro/cadastros/chaves-pix");
                        },
                    };
                    showLoading();
                    await httpPut({ url: `/financeiro/chaves-pix/${values.id}/`, body: chavePix }, handlersPut);
                    hideLoading();
                }
            } else {
                showWarning({
                    summary: "Chave Inválida",
                    detail: "A chave pix digitada é inválida, verifique o formato e tente novamente.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    useEffect(() => {
        if (chavePix) setValues({ ...chavePix, conta_bancaria: chavePix.conta_bancaria.id });
    }, [setValues, chavePix]);

    const contaBancariaTemplate = (conta) => {
        if (conta)
            return (
                <span>{`${conta.banco.descricao} (${conta.agencia}) ${conta.numero_conta}-${conta.numero_conta_dv}`}</span>
            );
        return <span>Selecione uma conta bancária</span>;
    };

    return (
        <>
            {!modal && <h3>{!formik.values.id ? "Nova chave Pix" : "Manutenção de chave Pix"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="perfil" label="Perfil" obrigatorio />
                        <MakoInputPerfil
                            id="perfil"
                            name="perfil"
                            value={formik.values.perfil}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="conta-bancaria" label="Conta bancária" />
                        <Dropdown
                            id="conta-bancaria"
                            name="conta_bancaria"
                            url={`/financeiro/contas-bancarias/?query={id,numero_conta,agencia,numero_conta_dv,banco}&perfil=${formik.values.perfil?.id}`}
                            buscar={!!formik.values.perfil?.id}
                            optionValue="id"
                            optionLabel="numero_conta"
                            emptyMessage="Nenhum registro encontrado"
                            itemTemplate={contaBancariaTemplate}
                            valueTemplate={contaBancariaTemplate}
                            value={formik.values.conta_bancaria}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="tipo-chave" label="Tipo de chave" obrigatorio />
                        <Dropdown
                            id="tipo-chave"
                            name="tipo_chave"
                            placeholder="Selecione um tipo"
                            options={TIPO_CHAVE_PIX_CHOICE}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.tipo_chave}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_chave })}
                        />
                        {formik.errors.tipo_chave && <small className="p-error">{formik.errors.tipo_chave}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="chave" label="Chave" obrigatorio />
                        <InputText
                            id="chave"
                            name="chave"
                            value={formik.values.chave}
                            maxLength={255}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave })}
                        />
                        {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-7">
                        <Label htmlFor="conteudo" label="Contéudo" obrigatorio={!!!formik.values.chave} />
                        <InputText
                            id="conteudo"
                            name="conteudo"
                            value={formik.values.conteudo}
                            maxLength={255}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.conteudo })}
                        />
                        {formik.errors.conteudo && <small className="p-error">{formik.errors.conteudo}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-1 p-mt-5">
                        <Checkbox
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <Label htmlFor="ativo" label="Ativa?" />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        loading={loading}
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                    />
                    {!modal && (
                        <Button
                            loading={loading}
                            label="Cancelar"
                            icon={MAKO_ICONS.CANCEL}
                            type="button"
                            className="p-button-danger"
                            to={"/financeiro/cadastros/chaves-pix"}
                        />
                    )}
                </MakoActionsButtons>
            </form>
        </>
    );
};
