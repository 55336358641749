import { RequisicaoProvider } from "@/contexts/requisicaoContext";
import React from "react";
import { TabRequisicao } from "./tabs";

export const SolicitarRequisicao = () => {
    return (
        <div className="card">
            <RequisicaoProvider>
                <TabRequisicao />
            </RequisicaoProvider>
        </div>
    );
};
