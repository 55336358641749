import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import useHttp from "@/hooks/useHttp";
import { Label } from "@/components/Label";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useLoadingLocal from "@/hooks/useLoadingLocal";

export const EditarItemModalForm = ({ produto, setEditarProduto, editarProduto, toastRef, listagemRef }) => {
    const { httpPatch, httpPut } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            preco_calculado: 0,
            plano_recebimento: null,
            meses_encargos: 0,
            isento_encargos: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                preco_calculado: Yup.number().required("O campo é obrigatório."),
                plano_recebimento: Yup.number().required("O campo é obrigatório"),
                meses_encargos: Yup.number().required("O campo é obrigatório"),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const handlers = {
                200: async ({ data }) => {
                    const handler = {
                        200: () => {
                            listagemRef.current?.buscarDados();
                            toastRef.current.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Cadastro do item alterado com sucesso!",
                                life: 1500,
                            });
                            esconderEditar();
                        },
                    };
                    await httpPatch(
                        {
                            url: `/produtos/tabela-preco-itens/${produto?.id}/`,
                            body: {
                                ...values,
                                preco_arredondado: data.result[values.preco_calculado],
                            },
                        },
                        handler
                    );
                },
            };
            showLoading();
            await httpPut(
                {
                    url: "/vendas/arredondar-precos/",
                    body: {
                        precos: [values.preco_calculado],
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const esconderEditar = () => {
        resetForm();
        setEditarProduto(false);
    };

    useEffect(() => {
        if (produto)
            setValues({
                meses_encargos: produto.meses_encargos,
                isento_encargos: produto.isento_encargos,
                preco_calculado: produto.preco_calculado,
                plano_recebimento: produto.plano_recebimento?.id || null,
            });
    }, [produto, setValues]);

    return (
        <Dialog
            header={`Editando produto: ${produto?.sku?.codigo} - ${produto?.sku?.descricao_reduzida}`}
            visible={editarProduto}
            onHide={() => esconderEditar()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "45vw" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="preco_calculado" label="Preço " obrigatorio />
                        <InputNumber
                            id="preco_calculado"
                            name="preco_calculado"
                            mode="currency"
                            currency="BRL"
                            locale="br-BR"
                            min={0}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            value={formik.values.preco_calculado}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.preco_calculado })}
                        />
                        {formik.errors.preco_calculado && (
                            <small className="p-error">{formik.errors.preco_calculado}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="plano_recebimento" label="Plano recebimento" />
                        <Dropdown
                            id="plano_recebimento"
                            name="plano_recebimento"
                            url="/financeiro/planos-recebimentos/"
                            placeholder="Selecione"
                            filter
                            filterBy="descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            emptyMessage="Nenhum registro disponível"
                            emptyFilterMessage="Nenhum registro encontrado"
                            value={formik.values.plano_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.plano_recebimento })}
                        />
                        {formik.errors.plano_recebimento && (
                            <small className="p-error">{formik.errors.plano_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="meses_encargos" label="Meses de encargos incluídos" />
                        <InputNumber
                            id="meses_encargos"
                            name="meses_encargos"
                            min={0}
                            minFractionDigits={0}
                            maxFractionDigits={0}
                            value={formik.values.meses_encargos}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.meses_encargos })}
                        />
                        {formik.errors.meses_encargos && (
                            <small className="p-error">{formik.errors.meses_encargos}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="isento_encargos" label="Isento de encargos" />
                        <SelectButton
                            id="isento_encargos"
                            name="isento_encargos"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.isento_encargos}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <MakoActionsButtons className="p-mt-2 p-jc-end">
                    <Button
                        label="Cancelar"
                        type="reset"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        onClick={() => esconderEditar()}
                        loading={loading}
                    />
                    <Button
                        label="Gravar"
                        type="submit"
                        icon={MAKO_ICONS.GRAVAR}
                        className="p-button-info"
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </Dialog>
    );
};
