import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { Dialog } from "primereact/dialog";
import { BotaoDelete } from "@/components/BotaoDelete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

const url = "/crediario/tipo-documento-renda/";

export const TiposDocRendaPage = () => {
    const [exibeOrientacoes, setExibeOrientacoes] = useState(false);
    const [tipoDoc, setTipoDoc] = useState(null);
    const listagemRef = useRef(null);
    const history = useHistory(null);
    const { showSuccess } = useToast();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TIPODOCUMENTORENDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de tipo de documento"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/crediario/tipo-documento-renda/form",
                            state: { tipoDoc: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TIPODOCUMENTORENDA_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-book"
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                    onClick={() => {
                        setTipoDoc(rowData);
                        setExibeOrientacoes(true);
                    }}
                    tooltip="Exibir orientações"
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TIPODOCUMENTORENDA_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir o tipo de documento <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O tipo de documento de renda não pode ser excluído."
                    className="p-mt-1"
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de documento de renda removido com sucesso!",
                            life: 1500,
                        });
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_TIPODOCUMENTORENDA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/venda/crediario/tipo-documento-renda/form")}
            />
        </>
    );

    const ativoBodyTemplate = (rowData) => {
        if (rowData.ativo) {
            return <Tag severity="success" value="ATIVO" />;
        }

        return <Tag severity="danger" value="INATIVO" />;
    };

    const ativoFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={[
                    { value: true, label: "Ativo" },
                    { value: false, label: "Inativo" },
                ]}
                onChange={(e) => options.filterApplyCallback(e.value)}
            />
        );
    };

    const simOuNaoBodyTemplate = (rowData, opcao) => {
        if (opcao === "precisa_aprovar") {
            if (rowData.precisa_aprovar) return <Tag severity="success" value="SIM" />;
        } else if (opcao === "digital") if (rowData.digital) return <Tag severity="success" value="SIM" />;

        return <Tag severity="danger" value="NÃO" />;
    };

    const simOuNaoFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                ]}
                onChange={(e) => options.filterApplyCallback(e.value)}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "100px" },
            filter: true,
        },
        { field: "chave", header: "Chave", filter: true, style: { minWidth: "250px" } },
        { field: "descricao", header: "Descrição", filter: true, style: { minWidth: "300px" } },
        {
            field: "digital",
            header: "Digital?",
            style: { minWidth: "150px" },
            filter: true,
            filterElement: simOuNaoFiltroTemplate,
            action: (e) => simOuNaoBodyTemplate(e, "digital"),
        },
        {
            field: "precisa_aprovar",
            header: "Precisa aprovar?",
            style: { minWidth: "150px" },
            filter: true,
            filterElement: simOuNaoFiltroTemplate,
            action: (e) => simOuNaoBodyTemplate(e, "precisa_aprovar"),
        },
        {
            field: "ativo",
            header: "Situação",
            style: { minWidth: "150px" },
            filter: true,
            filterElement: ativoFiltroTemplate,
            action: (e) => ativoBodyTemplate(e),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            frozen: true,
            alignFrozen: "right",
            style: { minWidth: "150px" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Tipos de Documento de Renda"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                filtros={{
                    id: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    chave: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    digital: { value: null, matchMode: "equals" },
                    precisa_aprovar: { value: null, matchMode: "equals" },
                    ativo: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                    scrollable: true,
                    frozenWidth: "0.5vw",
                    flexGrow: 1,
                    flexBasis: "12rem",
                    resizableColumns: true,
                    columnResizeMode: "expand",
                }}
            />

            <Dialog
                header={tipoDoc ? tipoDoc.descricao : "Orientações"}
                visible={exibeOrientacoes}
                breakpoints={{ "1100px": "80vw" }}
                style={{ width: "50vw" }}
                onHide={() => setExibeOrientacoes(false)}
            >
                <p>{tipoDoc ? tipoDoc.orientacoes : ""}</p>
            </Dialog>
        </PageBase>
    );
};
