import { DropdownFiltroTemplate } from "../DropdownFiltroTemplate";

export const BooleanFiltroTemplate = (options) => {
    return (
        <DropdownFiltroTemplate
            dropdownProps={{
                optionValue: "value",
                optionLabel: "label",
                options: [
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                ],
            }}
            options={options}
        />
    );
};
