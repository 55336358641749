import React from "react";
import { Skeleton as SkeletonPR } from "primereact/skeleton";

export const Skeleton = () => {
    return (
        <>
            <li className="p-mb-3" style={{ width: "100%" }}>
                <div className="p-d-flex" style={{ width: "inherit" }}>
                    <SkeletonPR shape="circle" size="3.2rem" className="p-mr-2" />
                    <div style={{ width: "inherit" }}>
                        <SkeletonPR width="30%" height="2rem" className="p-mb-2" />
                        <SkeletonPR width="45%" />
                    </div>
                </div>
            </li>
            <li className="p-mb-3" style={{ width: "100%" }}>
                <div className="p-d-flex" style={{ width: "inherit" }}>
                    <SkeletonPR shape="circle" size="3.2rem" className="p-mr-2" />
                    <div style={{ width: "inherit" }}>
                        <SkeletonPR width="30%" height="2rem" className="p-mb-2" />
                        <SkeletonPR width="45%" />
                    </div>
                </div>
            </li>
            <li className="p-mb-3" style={{ width: "100%" }}>
                <div className="p-d-flex" style={{ width: "inherit" }}>
                    <SkeletonPR shape="circle" size="3.2rem" className="p-mr-2" />
                    <div style={{ width: "inherit" }}>
                        <SkeletonPR width="30%" height="2rem" className="p-mb-2" />
                        <SkeletonPR width="45%" />
                    </div>
                </div>
            </li>
        </>
    );
};
