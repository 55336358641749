import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { axiosPatch } from "@/services/http";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

import classNames from "classnames";
import * as Yup from "yup";

export const EditarHistorico = ({ movimentacao, handleClose, onUpdate }) => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            historico: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                historico: Yup.string().required("O campo 'histórico' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            showLoading();
            const resposta = await axiosPatch(`/financeiro/movimentacao-contas-corrente/${movimentacao.id}/`, values);
            hideLoading();

            if (resposta.status === 200) {
                showSuccess({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Histórico alterado com sucesso!",
                    life: 2500,
                });
                onUpdate();
                handleClose();
            } else {
                showError({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 2000,
                });
            }
        } catch (error) {
            showError({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    }

    useEffect(() => {
        setFieldValue("historico", movimentacao?.historico);
    }, [setFieldValue, movimentacao?.historico]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <InputTextarea
                                id="historico"
                                name="historico"
                                rows={8}
                                value={formik.values.historico}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.historico })}
                            />
                            {formik.errors.historico && <small className="p-error">{formik.errors.historico}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-justify-end p-mt-1 p-mr-1">
                        <Button
                            className="p-button-danger p-button-text"
                            icon="pi pi-times"
                            type="reset"
                            label="Fechar"
                            onClick={() => handleClose()}
                        />
                        <Button className="p-button-success" icon="pi pi-save" type="submit" label="Gravar" />
                    </div>
                </form>
            </div>
        </div>
    );
};
