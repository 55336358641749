import { useCallback } from "react";

import useLoading from "./useLoading";
import useToast from "./useToast";
import useEmpresa from "./useEmpresa";
import useHttp from "./useHttp";

const useImpressaoTermica = () => {
    const { showSuccess, showWarning } = useToast();
    const { showLoading, hideLoading } = useLoading();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet, httpPost } = useHttp();

    const solicitarImpressao = useCallback(
        async ({ chave, impressora, filtros }) => {
            const body = { chave, impressora, filtros, empresa: empresaSelecionadaId };

            const handlers = {
                200: ({ data }) =>
                    showSuccess({
                        summary: "Sucesso!",
                        detail: data?.msg || "Impressão realizada com sucesso.",
                        life: 1500,
                    }),
                204: ({ data }) =>
                    showSuccess({
                        summary: "Sucesso!",
                        detail: data?.msg || "Impressão realizada com sucesso.",
                        life: 1500,
                    }),
                400: ({ data }) =>
                    showWarning({
                        summary: "Falhou...",
                        detail: data?.msg || "Desculpe, não foi possível gerar a impressão.",
                        life: 3000,
                    }),
                409: ({ data }) =>
                    showWarning({
                        summary: "Falhou...",
                        detail: data?.msg || "Desculpe, não foi possível gerar a impressão.",
                        life: 3000,
                    }),
            };

            showLoading();
            await httpPost({ url: "/relatorios/impressao-termica/imprimir/", body }, handlers);
            hideLoading();
        },
        [showLoading, hideLoading, showSuccess, showWarning, httpPost, empresaSelecionadaId]
    );

    const reimprimir = useCallback(
        async (id) => {
            const handlers = {
                200: ({ data }) =>
                    showSuccess({
                        summary: "Sucesso!",
                        detail: data?.msg || "Reimpressão realizada com sucesso.",
                        life: 1500,
                    }),
                204: ({ data }) =>
                    showSuccess({
                        summary: "Sucesso!",
                        detail: data?.msg || "Reimpressão realizada com sucesso.",
                        life: 1500,
                    }),
                400: ({ data }) =>
                    showWarning({
                        summary: "Falhou...",
                        detail: data.msg,
                        life: 3000,
                    }),
                409: ({ data }) =>
                    showWarning({
                        summary: "Falhou...",
                        detail: data.msg,
                        life: 3000,
                    }),
            };

            showLoading();
            await httpGet({ url: `/relatorios/impressao-termica/imprimir/${id}/` }, handlers);
            hideLoading();
        },
        [showLoading, hideLoading, showSuccess, showWarning, httpGet]
    );

    return { solicitarImpressao, reimprimir };
};

export default useImpressaoTermica;
