import React, { useMemo } from "react";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputPerfil = ({ query, somenteAtivo, empresa, ...props }) => {
    const [formatarDocumento] = useFormatCNPJCPF();

    const url = useMemo(() => {
        const queryString = query ? `query={${query}}&` : "";
        const filtroAtivo = somenteAtivo ? "ativo=true&" : "";
        const filtroEmpresa = empresa ? `empresa=${empresa}&` : "";
        return `/pessoas/perfis?${queryString}${filtroAtivo}${filtroEmpresa}search=`;
    }, [somenteAtivo, empresa, query]);

    const autoCompletePerfilTemplate = (item) => {
        const { nome, tipo_pessoa, identificacao } = item;
        const doc = tipo_pessoa !== "PE" ? formatarDocumento(identificacao) : identificacao;
        return <span>{`${nome} - ${doc} (${tipo_pessoa})`}</span>;
    };

    return (
        <MakoAutoComplete
            urlSearch={url}
            itemTemplate={autoCompletePerfilTemplate}
            minCaracteresBusca={4}
            field="nome"
            placeholder="Comece a digitar o nome ou CPF/CNPJ para buscar... (min 3 caracteres)"
            {...props}
        />
    );
};
