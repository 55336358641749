import React, { useState, useEffect, useCallback } from "react";

import { compareAsc } from "date-fns";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Label } from "@/components/Label";

import useContratoServico from "@/hooks/useContratoServico";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { dataToStr } from "@/assets/util/datas";
import { parseData } from "@/assets/util/datas";

export const ModalEditarParcela = ({ parcela, recebimento = true, onFinish, onClose }) => {
    const [parcelas, setParcelas] = useState([]);

    const [loading, hideLoading, showLoading] = useLoadingLocal();
    const { httpGet, httpPatch } = useHttp();
    const { showSuccess } = useToast();
    const { contrato } = useContratoServico();

    const formik = useFormik({
        initialValues: parcela,
        onSubmit: handleSubmit,
    });

    const validarVencimento = (data, idIndex) => {
        const parcelasOrdenadas = parcelas.sort((a, b) => compareAsc(parseData(a.vencimento), parseData(b.vencimento)));
        const rowIndex = parcelasOrdenadas?.findIndex(({ id }) => id === idIndex);
        if (rowIndex === 0) {
            const prox = parcelas[rowIndex + 1].vencimento;
            const proxVencimento = prox instanceof Date ? prox : parseData(prox);
            return compareAsc(proxVencimento, data) > 0;
        } else if (rowIndex === parcelas.length - 1) {
            const ant = parcelas[rowIndex - 1].vencimento;
            const antVencimento = ant instanceof Date ? ant : parseData(ant);
            return compareAsc(data, antVencimento) > 0;
        } else {
            const prox = parcelas[rowIndex + 1].vencimento;
            const proxVencimento = prox instanceof Date ? prox : parseData(prox);
            const ant = parcelas[rowIndex - 1].vencimento;
            const antVencimento = ant instanceof Date ? ant : parseData(ant);
            return compareAsc(data, antVencimento) > 0 && compareAsc(proxVencimento, data) > 0;
        }
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                vencimento: Yup.date()
                    .required("O campo 'vencimento' é obrigatório.")
                    .test({
                        test: (e) => validarVencimento(e, values.id),
                        message: "A data de vencimento não é válida para esse recebimento.",
                    })
                    .typeError("Informe uma data válida."),
                valor: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .min(0.01, "Esse campo não pode ser ZERO."),
                historico_original: Yup.string().required("O campo 'histórico' é obrigatório."),
            });
            const dadosValidos = formSchema.validateSync(values, { abortEarly: false });
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: `${recebimento ? "Rebimento" : "Pagamento"} alterado com sucesso!`,
                        life: 1500,
                    });
                    onFinish();
                },
            };
            showLoading();
            await httpPatch(
                {
                    url: `/financeiro/${recebimento ? "recebimentos" : "pagamentos"}/${dadosValidos.id}/`,
                    body: {
                        ...dadosValidos,
                        vencimento: dataToStr(dadosValidos.vencimento, "yyyy-MM-dd"),
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const listarRecebimentos = useCallback(
        async (signal) => {
            const handlers = {
                200: ({ data }) => setParcelas(data.results),
            };
            showLoading();
            await httpGet(
                {
                    url: `/financeiro/${
                        recebimento ? "recebimentos" : "pagamentos"
                    }/?cancelado=False&faturamento_contrato__contrato_id=${contrato.id}&${
                        recebimento
                            ? `agrupador=${parcela.agrupador}`
                            : `documento=${parcela.documento}&credor=${parcela.credor}`
                    }`,
                    signal,
                },
                handlers
            );
            hideLoading();
        },
        [showLoading, hideLoading, parcela, recebimento, contrato, httpGet]
    );

    useEffect(() => {
        const controller = new AbortController();
        listarRecebimentos(controller.signal);
        return () => controller.abort();
    }, [listarRecebimentos]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="vencimento" label="Vencimento" />
                    <MakoCalendar
                        id="vencimento"
                        name="vencimento"
                        valueCalendar={formik.values.vencimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.vencimento })}
                    />
                    {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor" label="Valor" />
                    <MakoInputMoeda
                        id="valor"
                        name="valor"
                        min={0}
                        valueMoeda={formik.values.valor}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor })}
                    />
                    {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                </div>
                <div className="p-field p-col-12 p-md-7">
                    <Label htmlFor="historico-original" label="Histórico" />
                    <InputText
                        id="historico-original"
                        name="historico_original"
                        value={formik.values.historico_original}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.historico_original })}
                    />
                    {formik.errors.historico_original && (
                        <small className="p-error">{formik.errors.historico_original}</small>
                    )}
                </div>
            </div>
            <MakoActionsButtons>
                <Button
                    icon={MAKO_ICONS.GRAVAR}
                    label="Gravar"
                    type="submit"
                    className="p-button-info"
                    loading={loading}
                />
                <Button
                    label="Cancelar"
                    icon={MAKO_ICONS.CANCEL}
                    type="button"
                    className="p-button-danger"
                    onClick={onClose}
                    loading={loading}
                />
            </MakoActionsButtons>
        </form>
    );
};
