import React, { useCallback, useEffect, useState, memo, useImperativeHandle, forwardRef } from "react";
import { Dropdown as DropdownPR } from "primereact/dropdown";

import useHttp from "@/hooks/useHttp";

// IMPORTANTE
// aposBuscar -> sempre utilize useMemo ou useCallback nesta função

const MakoDropdown = (
    { url, buscar = true, options, disabled, setObjects, aposBuscar, placeholder = "Selecione", id = "", ...props },
    ref
) => {
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState(false);
    const { httpGet } = useHttp();

    const getObject = (callback) => {
        const obj = dados.find(callback);
        return obj;
    };

    const getObjects = (callback) => {
        if (callback) return dados.filter(callback);
        return dados;
    };

    useImperativeHandle(ref, () => ({ getObject, getObjects, fetchDados }));

    const fetchDados = useCallback(async () => {
        if (!url || !buscar) return null;
        const handlers = {
            200: async ({ data }) => {
                let { results } = data;
                const dadosProcessados = typeof aposBuscar === "function" ? await aposBuscar(results) : results;
                setDados(dadosProcessados);
                if (typeof setObjects === "function") setObjects(dadosProcessados);
            },
        };
        setLoading(true);
        await httpGet({ url }, handlers);
        setLoading(false);
    }, [url, buscar, httpGet, setObjects, aposBuscar]);

    useEffect(() => {
        if (options instanceof Array) setDados(options);
        else fetchDados();
    }, [fetchDados, options]);

    return (
        <DropdownPR
            options={dados}
            disabled={loading || disabled}
            emptyMessage="Nenhum registro disponível"
            emptyFilterMessage="Nenhum registro encontrado"
            showClear
            inputId={id}
            {...props}
            placeholder={
                !loading ? (
                    placeholder
                ) : (
                    <span>
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "1rem", float: "right", marginTop: "3px" }}
                        />{" "}
                        Buscando informações...
                    </span>
                )
            }
        />
    );
};

export const Dropdown = memo(forwardRef(MakoDropdown));
