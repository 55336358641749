import React from "react";
import { InputText } from "primereact/inputtext";

const Component = (props) => {
    return (
        <span className="p-input-icon-left mako-input-search">
            <i className="pi pi-search" />
            <InputText tabIndex={1} style={{ maxWidth: "12.5rem", margin: "5px 0" }} {...props} placeholder="Pesquisar" />
        </span>
    );
};

export const InputFiltro = React.memo(Component);
