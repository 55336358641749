import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { PageBase } from "@/components/PageBase";
import useNotaFiscal from "@/hooks/useNotaFiscal";
import classNames from "classnames";
import * as Yup from "yup";
import MakoListagem from "@/components/MakoListagem";
import useToast from "@/hooks/useToast";
import { Button } from "primereact/button";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dialog } from "primereact/dialog";
import { dataToStr } from "@/assets/util/datas";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_FORMA_PAGAMENTO_CHOICE } from "@/assets/constants/constants";

export const FormaPagamentoCobrancaForm = () => {
    const [dialog, setDialog] = useState(false);
    const { dadosBasicos, handleCobranca } = useNotaFiscal();
    const listagemRef = useRef(null);
    const { showError } = useToast();

    const url = "/fiscal/formas-pagamento-nota-fiscal/";

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            forma_pagamento: 1,
            valor_pagamento: dadosBasicos.fatura_liquida,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                forma_pagamento: Yup.number().required("O campo é obrigatório!"),
                valor_pagamento: Yup.number().required("O campo é obrigatório!"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleCobranca(url, {
                ...values,
                data_vencimento: dataToStr(values.data_vencimento, "yyyy-MM-dd"),
            });

            if (status === 201) {
                listagemRef.current?.buscarDados();
                setDialog(false);
                resetForm();
            } else
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível salvar as informações da cobrança.",
                    life: 1500,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const painelEsquerdo = (
        <>
            <Button
                label="Adicionar"
                icon="pi pi-plus"
                type="button"
                className="p-button-success p-button-outlined"
                onClick={() => setDialog(true)}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EDITAR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente deletar a forma de pagamento?</span>}
                    msgToastErroExclusao="A forma de pagamento não pode ser deletada."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };
    const colunas = [
        { field: "forma_pagamento.label", header: "Forma" },
        { field: "valor_pagamento", header: "Valor", action: (e) => parseNumberToMoneyHTML(e.valor_pagamento) },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "30px" },
        },
    ];

    const esconderDialog = () => {
        resetForm();
        setDialog(false);
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                titulo="Formas de Pagamento"
                painelEsquerdo={painelEsquerdo}
                urlPesquisa={`/fiscal/formas-pagamento-nota-fiscal?nota_fiscal=${dadosBasicos.id}`}
                configTabela={{
                    scrollable: true,
                    scrollHeight: "400px",
                }}
            />
            <Dialog
                header="Forma de pagamento"
                visible={dialog}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "50vw" }}
                onHide={() => esconderDialog()}
            >
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="forma_pagamento">Forma pagamento *</label>
                            <Dropdown
                                id="forma_pagamento"
                                name="forma_pagamento"
                                placeholder="Selecione uma forma"
                                options={TIPO_FORMA_PAGAMENTO_CHOICE}
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.forma_pagamento}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                            />
                            {formik.errors.forma_pagamento && (
                                <small className="p-error">{formik.errors.forma_pagamento}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="valor_pagamento">Valor pagamento *</label>
                            <MakoInputMoeda
                                id="valor_pagamento"
                                name="valor_pagamento"
                                value={formik.values.valor_pagamento}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_pagamento })}
                            />
                            {formik.errors.valor_pagamento && (
                                <small className="p-error">{formik.errors.valor_pagamento}</small>
                            )}
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-mt-2 p-ml-1">
                        <Button
                            label="Inserir"
                            icon="pi pi-plus"
                            type="button"
                            className="p-button-success p-mr-2"
                            onClick={() => handleSubmit(formik.values)}
                        />
                        <Button
                            label="Fechar"
                            icon="pi pi-times"
                            type="reset"
                            className="p-button-danger p-mr-2"
                            onClick={() => esconderDialog()}
                        />
                    </div>
                </form>
            </Dialog>
        </PageBase>
    );
};
