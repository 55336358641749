import React from "react";

import { FormFiltro } from "./form-filtro";
import { Listagem } from "./listagem";

export const Pagar = () => {
    return (
        <>
            <FormFiltro />
            <Listagem />
        </>
    );
};
