import React, { useCallback, useMemo, useRef } from "react";

import { MakoButton as Button } from "@/components/MakoButton";

import { ModalAdicionarParcela as ModalParcelas } from "../../ModalAdicionarParcela";
import { ModalEditarParcela as ModalPagamentos } from "../../ModalEditarParcela";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useClearRefs from "@/hooks/useClearRefs";

const BASE_PARCELA = {
    data: null,
    model: null,
    quitado: null,
};

export const ParcelaEditar = ({ parcela = BASE_PARCELA, successCallback = () => {}, disabled = false }) => {
    const modalPagamentosRef = useRef(null);
    const modalParcelasRef = useRef(null);

    useClearRefs(modalPagamentosRef, modalParcelasRef);

    const onClick = useCallback(() => {
        const { data, model, valor_abater, pagamento_abatido } = parcela || {};
        if (model === "dev") {
            modalParcelasRef.current?.abrirModalEdicao({ ...data, forma_recebimento: data?.forma_recebimento?.id });
        } else {
            if (pagamento_abatido) modalPagamentosRef.current?.abrirModalEdicao(pagamento_abatido, data?.valor);
            else modalPagamentosRef.current?.abrirModal({ ...data, valor_abater });
        }
    }, [parcela]);

    const aposSalvar = useCallback(() => {
        successCallback();
    }, [successCallback]);

    const isDisabled = useMemo(() => {
        const { quitado } = parcela || {};
        return disabled || quitado;
    }, [parcela, disabled]);

    return (
        <>
            <Button icon={MAKO_ICONS.EDITAR} rounded severity="warning" disabled={isDisabled} onClick={onClick} />
            <ModalPagamentos ref={modalPagamentosRef} successCallback={aposSalvar} />
            <ModalParcelas ref={modalParcelasRef} aposSalvar={aposSalvar} />
        </>
    );
};
