import logo from "@/assets/img/sharkdev.png";
import logo_login from "@/assets/img/login_sysmako.png";

export const LOGO_LOGIN_SYSMAKO = logo_login;

export const LOGO_SHARKDEV = logo;

export const LOADING = {
    text: "Só um instante...",
    textColor: "#FFFFFF",
    spinnerColor: "#FFFFFF",
    bgColor: "rgba(0,0,0,0.5)",
    logoSrc: LOGO_SHARKDEV,
};

export const TIPO_PESSOA = [
    { id: "F", label: "Pessoa Física" },
    { id: "J", label: "Pessoa Jurídica" },
    { id: "E", label: "Pessoa Estrangeira" },
];

export const SEXO = [
    { id: "M", label: "Masculino" },
    { id: "F", label: "Feminino" },
    { id: "O", label: "Outro" },
    { id: "N", label: "Não informar" },
];

export const SIM_NAO_BOOLEAN = [
    { id: true, label: "Sim" },
    { id: false, label: "Não" },
];

export const ESTADO_CIVIL = [
    { id: "S", label: "Solteiro" },
    { id: "C", label: "Casado" },
    { id: "D", label: "Divorciado" },
    { id: "V", label: "Viúvo" },
    { id: "A", label: "Amasiado" },
    { id: "N", label: "Não informado" },
];

export const STATUS_ITEM = [
    { id: "P", label: "Pendente", tag: "warning" },
    { id: "B", label: "Baixado", tag: "danger" },
    { id: "F", label: "Finalizado", tag: "success" },
];

export const NATUREZA_PLANO_CONTAS = [
    { id: "D", label: "Débito", value: "D" },
    { id: "C", label: "Crédito", value: "C" },
];

export const PERSONALIZADOS_TIPOS = [
    { value: "t", label: "Text" },
    { value: "a", label: "Large Text Field" },
    { value: "i", label: "Integer" },
    { value: "f", label: "Floating Point Decimal" },
    { value: "b", label: "Boolean (Yes/No)" },
    { value: "m", label: "Dropdown Choices" },
    { value: "d", label: "Date" },
    { value: "h", label: "Date Time" },
];

export const TIPO_PESSOA_APLICAVEL_CHOICE = [
    { value: 1, label: "Apenas Pessoa Física" },
    { value: 2, label: "Apenas Pessoa Jurídica" },
    { value: 3, label: "Pessoa Física e Pessoa Jurídica" },
];

export const CHAVE_PAPEL_CHOICE = [
    { value: "GER", label: "Gerente" },
    { value: "ATD", label: "Atendente" },
    { value: "VND", label: "Vendedor" },
    { value: "SUP", label: "Supervisor" },
    { value: "REG", label: "Regional" },
    { value: "OUT", label: "Outros" },
];

export const TIPO_COMPOSICAO_TEMPLATE_CHOICE = [
    { id: "DG", descricao: "Descrição genérica" },
    { id: "DC", descricao: "Descrição complementar" },
    { id: "MC", descricao: "Marca" },
    { id: "MD", descricao: "Modelo" },
    { id: "CA", descricao: "Código do atributo" },
    { id: "NA", descricao: "Nome atributo" },
    { id: "VA", descricao: "Valor do atributo" },
];

export const TIPO_CONTA_FINANCEIRA_CHOICE = [
    { id: 1, label: "Conta caixa" },
    { id: 2, label: "Conta bancária" },
    { id: 3, label: "Conta poupança" },
    { id: 4, label: "Conta de investimento" },
];

export const TIPO_MOVIMENTACAO_UNIDADE_MEDIDA_CHOICE = [
    { id: "A", descricao: "Todos" },
    { id: "V", descricao: "Venda" },
    { id: "C", descricao: "Compra" },
    { id: "T", descricao: "Transferência" },
];

export const TIPO_MOVIMENTACAO_ESTOQUE_CHOICE = [
    { id: "E", label: "Entrada" },
    { id: "S", label: "Saída" },
];

export const TIPO_TITULARIDADE_CONTA_BANCARIA_CHOICE = [
    { id: 1, label: "Primeiro titular" },
    { id: 2, label: "Segundo titular" },
];

export const TIPO_CHAVE_PIX_CHOICE = [
    { id: "C", descricao: "CPF/CNPJ" },
    { id: "E", descricao: "E-mail" },
    { id: "T", descricao: "Telefone Celular" },
    { id: "A", descricao: "Aleatória" },
];

export const TIPO_PESSOA_PESQUISA = [
    { id: "PF", label: "Pessoa Física" },
    { id: "PJ", label: "Pessoa Jurídica" },
    { id: "PE", label: "Pessoa Estrangeira" },
];

export const TIPO_ESCOPO_COMISSAO_CHOICE = [
    { id: "M", label: "Mercadoria" },
    { id: "S", label: "Serviço" },
    { id: "T", label: "Mercadoria/Serviço" },
];

export const TIPO_STATUS_ORDEM_COMPRA_CHOICE = [
    { id: "PE", label: "Pendente" },
    { id: "VA", label: "Válida" },
    { id: "PA", label: "Parcial" },
    { id: "EN", label: "Encerrada" },
    { id: "BA", label: "Baixada" },
];

export const TIPO_FORMAS_PAGAMENTO_CHOICE = [
    { id: "BO", label: "Boleto" },
    { id: "DE", label: "Depósito" },
    { id: "ES", label: "Em espécie" },
    { id: "CA", label: "Cartão" },
    { id: "PX", label: "Pix" },
];

export const TIPO_SERVICO_API = [
    { id: "EDF", label: "Emissão de Documentos Fiscais" },
    { id: "SPC", label: "Sistema de Proteção ao Crédito" },
    { id: "GED", label: "Gestão Eletrônica de Documentos" },
    { id: "EDD", label: "Enriquecimento de Dados" },
    { id: "VDD", label: "Validação de Dados" },
    { id: "PIX", label: "Transação de PIX" },
    { id: "TEF", label: "Transferência Eletrônica de Fundos" },
    { id: "WAP", label: "Integração com WhatsApp" },
    { id: "IMP", label: "Integração com ferramenta de impressão" },
];

export const TIPO_INDICADOR_PRESENCA_NF_CHOICE = [
    { id: 0, label: "Não se aplica" },
    { id: 1, label: "Operação presencial" },
    { id: 2, label: "Operação não presencial, pela Internet" },
    { id: 3, label: "Operação não presencial, Tele atendimento" },
    { id: 4, label: "NFC-e em operação com entrega a domicílio" },
    { id: 9, label: "Operação não presencial, outros" },
];

export const TIPO_STATUS_NF_CHOICE = [
    { id: "P", label: "Pendente" },
    { id: "T", label: "Transmitida" },
    { id: "C", label: "Cancelada" },
    { id: "I", label: "Inutilizada" },
];

export const TIPO_MOVIMENTACAO_FISCAL_CHOICE = [
    { id: "C", label: "Compra (Entrada)", op: "E" },
    { id: "T", label: "Transferência (Entrada)", op: "E" },
    { id: "D", label: "Devolução (Entrada)", op: "E" },
    { id: "A", label: "Anulação de valores (Entrada)", op: "E" },
    { id: "V", label: "Venda (Saída)", op: "S" },
    { id: "F", label: "Transferência (Saída)", op: "S" },
    { id: "P", label: "Prestação de Valores/Remessa (Saída)", op: "S" },
    { id: "N", label: "Não movimenta", op: "?" },
];

export const TIPO_ESFERA_FISCAL_CHOICE = [
    { id: "D", label: "Dentro do estado" },
    { id: "E", label: "Entre estados" },
    { id: "I", label: "Internacional" },
];

export const TIPO_MOVIMENTO_FISCAL_CHOICE = [
    { id: "E", label: "Entrada" },
    { id: "S", label: "Saída" },
];

export const TIPO_MODALIDADE_ST_CHOICE = [
    { id: 1, label: "Substituto" },
    { id: 2, label: "Substituído" },
];

export const TIPO_INDICADOR_FORMA_PAGAMENTO_NF_CHOICE = [
    { id: 1, label: "Pagamento à vista" },
    { id: 2, label: "Pagamento a prazo" },
    { id: 3, label: "Outros" },
];

export const TIPO_OPERACAO_NF_CHOICE = [
    { id: 0, label: "Entrada" },
    { id: 1, label: "Saída" },
];

export const TIPO_INDICADOR_FINAL_CHOICE = [
    { id: 0, label: "Não" },
    { id: 1, label: "Consumidor final" },
];

export const TIPO_FORMA_EMISSAO_CHOICE = [
    { id: 1, label: "Emissão normal" },
    { id: 2, label: "Contingência FS-IA, com impressão do DANFE em formulário de segurança" },
    { id: 4, label: "Contingência EPEC" },
    { id: 5, label: "Contingência FS-DA, com impressão do DANFE em formulário de segurança" },
    { id: 6, label: "Contingência SVC-AN" },
    { id: 7, label: "Contingência SVC-RS" },
    { id: 9, label: "Contingência off-line da NFC-e" },
];

export const TIPO_PROCESSO_EMISSAO_CHOICE = [
    { id: 0, label: "Emissão de NF-e com aplicativo do contribuinte" },
    { id: 1, label: "Emissão de NF-e avulsa pelo Fisco" },
    { id: 2, label: "Emissão de NF-e avulsa, pelo contribuinte com seu certificado digital, através do site do Fisco" },
    { id: 3, label: "Emissão NF-e pelo contribuinte com aplicativo fornecido pelo Fisco" },
];

export const TIPO_FINALIDADE_NF_CHOICE = [
    { id: 1, label: "NF-e normal" },
    { id: 2, label: "NF-e complementar" },
    { id: 3, label: "NF-e de ajuste" },
    { id: 4, label: "Devolução de mercadoria" },
];

export const TIPO_ORIGEM_NF_CHOICE = [
    { id: 0, label: "0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8" },
    { id: 1, label: "1 - Estrangeira, Importação direta, exceto a indicada no código 6" },
    { id: 2, label: "2 - Estrangeira, Adquirida no mercado interno, exceto a indicada no código 7" },
    {
        id: 3,
        label: "3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%  e inferior ou igual a 70%",
    },
    {
        id: 4,
        label: "4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos",
    },
    { id: 5, label: "5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%" },
    {
        id: 6,
        label: "6 - Estrangeira, Importação direta, sem similar nacional, constante em lista de Resolução CAMEX e gás natural",
    },
    {
        id: 7,
        label: "7 - Estrangeira, Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX e gás natural.",
    },
    { id: 8, label: "8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%" },
];

export const TIPO_FORMA_PAGAMENTO_CHOICE = [
    { id: 1, label: "Dinheiro" },
    { id: 2, label: "Cheque" },
    { id: 3, label: "Cartão de Crédito" },
    { id: 4, label: "Cartão de Débito" },
    { id: 5, label: "Crédito Loja" },
    { id: 10, label: "Vale Alimentação" },
    { id: 11, label: "Vale Refeição" },
    { id: 12, label: "Vale Presente" },
    { id: 13, label: "Vale Combustível" },
    { id: 15, label: "Boleto bancário" },
    { id: 16, label: "Depósito Bancário" },
    { id: 17, label: "PIX" },
    { id: 18, label: "Transferência bancária, Carteira Digital" },
    // { id: 99, label: "Outros" },
];

export const TIPO_INDICA_ITEM_TOTAL_CHOICE = [
    { id: 0, label: "Valor do item (vProd) não compõe o valor total da NF-e" },
    { id: 1, label: "Valor do item (vProd) compõe o valor total da NF-e" },
];

export const TIPO_ITEM_NF_CHOICE = [
    { id: 0, label: "Produtos" },
    { id: 1, label: "Veículos" },
    { id: 2, label: "Medicamentos" },
    { id: 3, label: "Armamentos" },
    { id: 4, label: "Combustível" },
    { id: 5, label: "Serviço" },
];

export const TIPO_DESTAQUE_PRODUTOS_PERIGOSOS_CHOICE = [
    { id: 0, label: "Não destaca" },
    { id: 1, label: "Destaca" },
];

export const TIPO_MODALIDADE_BASE_CALCULO_ICMS_CHOICE = [
    { id: 0, label: "Margem Valor Agregado (%)" },
    { id: 1, label: "Pauta (Valor)" },
    { id: 2, label: "Preço Tabelado Máx (valor)" },
    { id: 3, label: "Valor da operação" },
];

export const TIPO_MODALIDADE_BASE_CALCULO_ST_CHOICE = [
    { id: 0, label: "Preço tabelado ou máximo sugerido" },
    { id: 1, label: "Lista Negativa (valor)" },
    { id: 2, label: "Lista Positiva (valor)" },
    { id: 4, label: "Lista Neutra (valor)" },
    { id: 5, label: "Margem Valor Agregado (%)" },
    { id: 6, label: "Pauta (valor)" },
];

export const TIPO_MOTIVO_DESONERACAO_ICMS_CHOICE = [
    { id: 3, label: "Uso na agropecuária" },
    { id: 9, label: "Outros" },
    { id: 12, label: "Órgão de fomento e desenvolvimento agropecuário" },
];

export const TIPO_SIM_NAO_NUMERICO_CHOICE = [
    { id: 1, label: "Sim" },
    { id: 2, label: "Não" },
];

export const TIPO_SIM_NAO_CHAR_CHOICE = [
    { id: "S", label: "Sim" },
    { id: "N", label: "Não" },
];

export const TIPO_INDICADOR_EXIGIBILIDADE_ISS_CHOICE = [
    { id: 1, label: "Exigível" },
    { id: 2, label: "Não incidência" },
    { id: 3, label: "Isenção" },
    { id: 4, label: "Exportação" },
    { id: 5, label: "Imunidade" },
    { id: 6, label: "Exigibilidade Suspensa por Decisão Judicial" },
    { id: 7, label: "Exigibilidade Suspensa por Processo Administrativo" },
];

export const TIPOS_BUSCA_SKU = [
    { value: 1, label: "Busca automática" },
    { value: 2, label: "Marca" },
    { value: 3, label: "Modelo" },
    { value: 4, label: "Descrição reduzida" },
    { value: 5, label: "Descrição complementar" },
    { value: 6, label: "Código de produto" },
    // { value: 7, label: "Código fornecedor" },
    { value: 8, label: "Características" },
    { value: 9, label: "Código vinculado ao produto" },
];

export const CLASSE_MOVIMENTACAO_ESTOQUE = [
    { value: 1, label: "Acerto" },
    { value: 2, label: "Bonificação" },
    { value: 3, label: "Brinde" },
    { value: 4, label: "Comodato" },
    { value: 5, label: "Compra" },
    { value: 6, label: "Demonstração" },
    { value: 7, label: "Doação" },
    { value: 8, label: "Outros" },
    { value: 9, label: "Remessa" },
    { value: 10, label: "Saldo Inicial" },
    { value: 11, label: "Transferência" },
    { value: 12, label: "Venda" },
    { value: 13, label: "Devolução ao fornecedor" },
    { value: 14, label: "Devolução de cliente" },
    { value: 15, label: "Nota de saída" },
];

export const DJANGO_FILTERS_MATCHMODE = {
    contains: "__contains",
    icontains: "__icontains",
    unaccent_icontains: "__unaccent__icontains",
    gte: "__gte",
    lte: "__lte",
    in: "__in",
    equals: "",
};

export const TIPO_RESTRICAO_SPC = [
    { value: false, label: "Restrito" },
    { value: true, label: "Liberado" },
];

export const MOTIVOS_INVENTARIO_ESTOQUE = [
    { value: 1, label: "No final do período" },
    { value: 2, label: "Na mudança de forma de tributação da mercadoria" },
    { value: 3, label: "Na solicitação da baixa cadastral, paralisação temporária e outras solicitações" },
    { value: 4, label: "Na alteração de regime de pagamento - condição do contribuinte" },
    { value: 5, label: "Por determinação dos fiscos" },
];

export const OPERACAO_NOTA_FISCAL_CHOICE = [
    { value: "5102", label: "5102 - Nota Fiscal de Venda" },
    { value: "5111", label: "5111 - Nota Fiscal Complementar" },
    { value: "5916", label: "5916 - Nota Fiscal de Retorno" },
    { value: "5202", label: "5202 - Nota Fiscal de Devolução" },
    { value: "5949", label: "5949 - Nota Fiscal de Remessa" },
    { value: "5117", label: "5117 - Nota Fiscal de Entrega Futura" },
    { value: "5114", label: "5114 - Nota Fiscal de Venda Consignada" },
];

export const OPCOES_PESQUISA_BOOLEAN = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Ativos" },
    { value: 2, label: "Inativos" },
];

export const TIPO_MODELO_NOTA_FISCAL = [
    { value: "55", label: "NF-e" },
    { value: "65", label: "NFC-e" },
];

export const TIPO_MODELO_NOTA_FISCAL_CHOICE = [
    { value: "nfe", label: "NFe" },
    { value: "nfce", label: "NFCe" },
];

export const TIPO_LOCAL_DESTINO_NOTA_FISCAL = [
    { value: 1, label: "Interna" },
    { value: 2, label: "Interestadual" },
    { value: 3, label: "Exterior" },
];

export const GRUPO_CHAVE_PARAMETRO = [
    { value: "COM", label: "Compras" },
    { value: "CRE", label: "Crediário" },
    { value: "CFI", label: "Campos Personalizados" },
    { value: "ERP", label: "ERP Mako" },
    { value: "FIN", label: "Financeiro" },
    { value: "FIS", label: "Fiscal" },
    { value: "PES", label: "Pessoas" },
    { value: "POP", label: "Plano Operacional" },
    { value: "PRO", label: "Produtos" },
    { value: "REL", label: "Relatórios" },
    { value: "VEN", label: "Vendas" },
];

export const TIPO_CHOICE_PARAMETRO = [
    { value: "U", label: "Usuário" },
    { value: "S", label: "Sistema" },
];

export const TIPO_CENTRO_ESTOCAGEM_CHOICE = [
    { value: "N", label: "Normal" },
    { value: "R", label: "Reserva" },
    { value: "D", label: "Defeito" },
    { value: "E", label: "Exposição" },
    { value: "C", label: "Consolidado" },
];

export const TIPO_PRECO_SERVICO = [
    { id: "F", label: "Preço fixo" },
    { id: "M", label: "Conforme medida" },
    { id: "P", label: "Percentual" },
];

export const TIPO_TAG_CHOICE = [
    { value: 1, label: "Ascendente" },
    { value: 2, label: "Descendente" },
    { value: 3, label: "Cônjuge" },
    { value: 4, label: "Profissional" },
];

export const TIPO_ESCOPO_CHOICE = [
    { value: 1, label: "Vendedor" },
    { value: 2, label: "Analista de crédito" },
    { value: 3, label: "Qualquer" },
];

export const TIPO_ALTERA_RENDA_CHOICE = [
    { value: 1, label: "Aumenta" },
    { value: 2, label: "Diminui" },
    { value: 3, label: "Não altera" },
];

export const TIPO_STATUS_ANALISE_CHOICE = [
    { value: "R", label: "Reprovado" },
    { value: "A", label: "Aprovado" },
];

export const TIPO_CAMPO_PERSONALIZADO_CHOICE = [
    { value: "t", label: "Texto" },
    { value: "a", label: "Campo de texto grande" },
    { value: "i", label: "Inteiro" },
    { value: "f", label: "Decimal" },
    { value: "b", label: "Booleano (Sim/Não)" },
    { value: "m", label: "Opções fixas" },
    { value: "d", label: "Data" },
    { value: "h", label: "Data e tempo" },
];

export const TIPO_STATUS_REQUISICAO = [
    { value: "A", label: "Aberta", badge: "success" },
    { value: "F", label: "Fechada", badge: "warning" },
    { value: "P", label: "Parcial", badge: "warning" },
    { value: "C", label: "Cancelada", badge: "danger" },
    { value: "E", label: "Encerrada", badge: "info" },
];

export const TIPO_PRIORIDADE_VINCULO_FORNECEDORPRODUTO = [
    { value: "B", label: "Baixa", badge: "success" },
    { value: "M", label: "Média", badge: "warning" },
    { value: "A", label: "Alta", badge: "danger" },
];

export const TIPO_OPERACAO_ENTRADA_FISCAL_CHOICE = [
    { value: 1, label: "1 - Compra/Industrialização/Transferência/Serviços" },
    { value: 2, label: "2 - Devolução/Energia elétrica" },
    { value: 3, label: "3 - Serviços de comunicação/Serviços de transporte" },
    { value: 4, label: "4 - Mercadoria com substituição tributária/Integração" },
    { value: 5, label: "5 - Exportação/Ativo imobilizado/Material de uso" },
    { value: 6, label: "6 - Crédito/Ressarcimento do ICMS/Combustíveis" },
    { value: 9, label: "9 - Outras entradas/Amostras grátis/Bonificação/Conserto/Exposição" },
];

export const TIPO_MOMENTO_REGRA_APURACAO_COMISSAO_CHOICE = [
    { value: "A", label: "Ato da venda" },
    { value: "R", label: "Recebimento" },
];

export const TIPO_CALCULO_FAIXA_APURACAO_CHOICE = [
    { value: "P", label: "Percentual" },
    { value: "V", label: "Valor nominal" },
];

export const TIPO_ESCOPO_FAIXA_COMISSAO_CHOICE = [
    { value: "M", label: "Mercadoria" },
    { value: "S", label: "Serviço" },
    { value: "E", label: "Encargos" },
    { value: "T", label: "Mercadoria/Serviço/Encargos" },
];

export const TIPO_APURACAO_COMISSAO_CHOICE = [
    { value: "D", label: "Direta" },
    { value: "I", label: "Indireta" },
];

export const TIPO_STATUS_APURACAO_CHOICE = [
    { value: "A", label: "Aberta" },
    { value: "C", label: "Calculada" },
    { value: "P", label: "Processada" },
];

export const SITUACAO_VENDA_CHOICE = [
    { value: "P", label: "Pendente", tag: "" },
    { value: "F", label: "Finalizada", tag: "success" },
    { value: "C", label: "Cancelada", tag: "danger" },
];

export const TIPO_OPERADORA_TELEFONICA_CHOICE = [
    { value: "VI", label: "Vivo" },
    { value: "OI", label: "Oi" },
    { value: "TI", label: "TIM" },
    { value: "CA", label: "Claro" },
    { value: "NE", label: "Nextel" },
    { value: "VI", label: "Algar" },
    { value: "AM", label: "America.net" },
    { value: "CO", label: "Contel" },
    { value: "SE", label: "Sercomtel" },
    { value: "CO", label: "Contel" },
    { value: "OU", label: "Outra" },
];

export const SITUACAO_VENDA_VOUCHER = [
    { value: "A", label: "Ativo", tag: "succes" },
    { value: "U", label: "Utilizado", tag: "info" },
    { value: "C", label: "Cancelada", tag: "danger" },
];

export const SITUACAO_VENDA_DEVOLUCAO = [
    { value: "P", label: "Pendente", tag: "warning" },
    { value: "V", label: "Validada", tag: "info" },
    { value: "F", label: "Finalizada", tag: "success" },
    { value: "C", label: "Cancelada", tag: "danger" },
];

export const TIPOS_REFERENCIA_CURVA_CHOICE = [
    { value: 1, label: "Faturamento (Valor de venda/orçamento)" },
    { value: 2, label: "Lucratividade (Valor faturado - Custo)" },
    { value: 3, label: "Custo Aquisição" },
];

export const TIPO_CHAVE_DESTINACAO_SKU_CHOICE = [
    { value: "RVD", label: "Revenda" },
    { value: "IMO", label: "Ativo imobilizado" },
    { value: "INS", label: "Insumo" },
    { value: "COA", label: "Consumo almoxarifado" },
    { value: "COI", label: "Consumo imediato" },
];

export const TIPOS_TIPO_DEVOLUCAO_FORNECEDOR = [
    { value: 1, label: "Troca" },
    { value: 2, label: "Conserto" },
    { value: 3, label: "Sem retorno" },
    { value: 4, label: "Consignação" },
    { value: 5, label: "Demonstração" },
    { value: 6, label: "Devolução de Remessa" },
];

export const TIPOS_FORMA_PAGAMENTO_DEVOLUCAO_FORNECEDOR = [
    { value: 0, label: "Frete por conta do Remetente (CIF)" },
    { value: 1, label: "Frete por conta do Destinatário (FOB)" },
    { value: 2, label: "Frete por conta de Terceiros" },
    { value: 3, label: "Transporte Próprio por conta do Remetente" },
    { value: 4, label: "Transporte Próprio por conta do Destinatário" },
    { value: 9, label: "Sem transporte" },
];

export const LINK_SUPORTE_SHARKDEV =
    "https://api.whatsapp.com/send?phone=555535997372351&text=Erro%20ao%20logar%20no%20Mako%20-%20Dom%C3%ADnio%20";

export const TIPOS_ITENS_INVENTARIO = [
    { value: 1, label: "Final do período" },
    { value: 2, label: "Mudança de forma de tributação da mercadoria (ICMS)" },
    { value: 3, label: "Solicitação da baixa cadastral, paralisação e outras situações" },
    { value: 4, label: "Alteração de regime de pagamento – condição do contribuinte" },
    { value: 5, label: "Por determinação dos fiscos" },
];

export const TIPOS_ITENS_FINALIDADE = [
    { value: 1, label: "Original" },
    { value: 2, label: "Substituto" },
];

export const TIPOS_APURACAO_SPED = [
    { value: "F", label: "Fiscal" },
    { value: "C", label: "Contribuições" },
];

export const TIPO_VEICULO_ENTREGA_CHOICE = [
    { value: 1, label: "Passeio" },
    { value: 2, label: "Pick-up" },
    { value: 3, label: "Carroceria" },
];

export const TIPO_CATEGORIA_CARTEIRA_CHOICE = [
    { value: "A", label: "Categoria A" },
    { value: "B", label: "Categoria B" },
    { value: "C", label: "Categoria C" },
    { value: "D", label: "Categoria D" },
    { value: "E", label: "Categoria E" },
];

export const TIPO_CARGO_CHOICE = [
    { value: "E", label: "Estratégico" },
    { value: "T", label: "Tático" },
    { value: "O", label: "Operacional" },
    { value: "A", label: "Assessoria" },
];

export const TIPO_STATUS_TRANSFERENCIA_CHOICE = [
    { value: "P", label: "Pendente" },
    { value: "V", label: "Validada" },
    { value: "F", label: "Finalizada" },
    { value: "C", label: "Cancelada" },
];

export const TIPOS_MESSES_ANO = [
    { value: "Jan", label: "Janeiro" },
    { value: "Feb", label: "Fevereiro" },
    { value: "Mar", label: "Março" },
    { value: "Apr", label: "Abril" },
    { value: "May", label: "Maio" },
    { value: "Jun", label: "Junho" },
    { value: "Jul", label: "Julho" },
    { value: "Aug", label: "Agosto" },
    { value: "Sep", label: "Setembro" },
    { value: "Oct", label: "Outubro" },
    { value: "Nov", label: "Novembro" },
    { value: "Dec", label: "Dezembro" },
];

export const TIPOS_CHOICE_SERVICOS_SERVICO = [
    { value: "R", label: "Recorrente" },
    { value: "U", label: "Único" },
    { value: "V", label: "Vinculado a venda" },
    { value: "P", label: "Vinculado a produto" },
    { value: "S", label: "Vinculado a serviço" },
];

export const TIPO_STATUS_CONTRATO_CHOICE = [
    { value: "P", label: "Pendente", tag: "warning" },
    { value: "F", label: "Firmado", tag: "info" },
    { value: "C", label: "Cancelado", tag: "danger" },
    { value: "E", label: "Encerrado", tag: "success" },
];

export const TIPO_CHOICE_CONTRATOSERVICO__INTERVALOREC = [
    { value: 7, label: "Unico" },
    { value: 1, label: "Semanal" },
    { value: 2, label: "Quinzenal" },
    { value: 3, label: "Mensal" },
    { value: 4, label: "Trimestral" },
    { value: 5, label: "Semestral" },
    { value: 6, label: "Anual" },
];

export const ESCOPO_FORMA_RECEB_PGTO_CHOICE = [
    { value: "P", label: "Pagamento" },
    { value: "R", label: "Recebimento" },
    { value: "X", label: "Pagamento/Recebimento" },
];

export const TIPO_LOCAL_PRESTACAO_CHOICE = [
    { value: 1, label: "No Município Sem Retenção" },
    { value: 2, label: "No Município Com Retenção" },
    { value: 3, label: "Fora do Município Sem Retenção" },
    { value: 4, label: "Fora do Município Com Retenção" },
    { value: 5, label: "Fora do Município com pagamento no local" },
    { value: 6, label: "Outro Município (Exterior)" },
    { value: 7, label: "No Municipio sem retenção Simples Nacional" },
    { value: 8, label: "No Municipio Tributacao Fixa Anual" },
    { value: 9, label: "No Municipio sem recolhimento" },
];

export const TIPO_CHOICE_SKU_CONTROLESALDO = [
    { value: 1, label: "Controla saldo próprio" },
    { value: 2, label: "Não controla saldo" },
    { value: 3, label: "Controla saldo em outro SKU" },
];

export const TIPO_VENCIMENTO_PARCELA_CHOICE = [
    { value: 1, label: "Data Certa" },
    { value: 2, label: "Apresentação" },
    { value: 3, label: "À vista" },
    { value: 4, label: "Outros" },
    { value: 5, label: "À Prazo" },
];

export const TIPO_DIMENSAO_GRADE = [
    { id: "A", label: "ALTURA" },
    { id: "C", label: "COMPRIMENTO" },
    { id: "L", label: "LARGURA" },
];

export const TIPO_CHOICE_STATUS_NFS = [
    { value: 0, label: "Gravada" },
    { value: 1, label: "Normal" },
    { value: 2, label: "Cancelada" },
    { value: 3, label: "Extraviado" },
    { value: 4, label: "Contingência" },
    { value: 5, label: "Inutilização" },
];

export const TIPO_BAIXA_PERFIL_CHOICE = [
    { value: "I", label: "Inatividade" },
    { value: "M", label: "Migração" },
    { value: "R", label: "Re-cadastro" },
    { value: "D", label: "Demissão" },
];

export const TIPO_CALCULO_CST_CHOICE = [
    { value: 1, label: "Percentual" },
    { value: 2, label: "Em valor" },
    { value: 3, label: "Qualquer" },
];

export const TIPO_MODELO_OPCAO_FATURAMENTO = [
    { value: "NFE", label: "55 - NF-e" },
    { value: "NFC", label: "65 -NFC-e" },
    { value: "QQ", label: "55 - NF-e | 65 - NFC-e" },
];

export const TIPO_OPERACAO_FORMULA_SALDO_ESTOQUE_CHOICE = [
    { value: "+", label: "Adição" },
    { value: "-", label: "Subtração" },
];

export const TIPO_CHOICE_MOTIVO_DEVOLUCAO_FORNECEDOR = [
    { value: 1, label: "Devolução" },
    { value: 2, label: "Remessa p/ conserto" },
    { value: 3, label: "Consignação" },
    { value: 4, label: "Demonstração" },
    { value: 5, label: "Assistência técnica" },
];

export const TIPO_CHOICE_TIPO_SKU = [
    { value: "M", label: "Mercadoria" },
    { value: "I", label: "Insumo" },
    { value: "C", label: "Consumo" },
    { value: "A", label: "Ativo" },
    { value: "P ", label: "Produto acabado" },
];

export const TIPO_PERFIL_ARQUIVO_FISCAL = [
    { value: "A", label: "Perfil A" },
    { value: "B", label: "Perfil B" },
    { value: "C", label: "Perfil C" },
];

export const TIPO_INDICADOR_ATIVIDADE = [
    { value: 0, label: "Industrial ou equiparado a industrial" },
    { value: 1, label: "Outros" },
];

export const TIPO_BOOLEAN_RETIRAR_PREVISOESENTREGA = [
    { value: true, label: "Retirada" },
    { value: false, label: "Entrega" },
];

export const TIPO_CHOICE_SITUACAO_CARGA = [
    { value: "A", label: "Aberta" },
    { value: "F", label: "Fechada" },
    { value: "E", label: "Encerrada" },
];

export const TIPO_INDICADOR_CONTA_CONTABIL = [
    { value: "A", label: "Analítica (conta)" },
    { value: "S", label: "Sintética (grupo de contas)" },
];

export const TIPO_INDICADOR_EMITENTE_CHOICE = [
    { value: 0, label: "Emissão Própria" },
    { value: 1, label: "Terceiros" },
];

export const TIPO_FINALIDADE_CTE_CHOICE = [
    { value: 1, label: "CT-e normal" },
    { value: 2, label: "CT-e de substituição" },
    { value: 3, label: "CT-e de anulação de valores" },
    { value: 4, label: "CT-e de complemento de valores" },
];

export const TIPO_SERVICO_CTE_CHOICE = [
    { value: 0, label: "Por conta do emitente" },
    { value: 1, label: "Por conta do destinatário/remetente" },
    { value: 2, label: "Por conta de terceiros" },
    { value: 9, label: "Sem cobrança de frete" },
];

export const TIPO_SITUACAO_DOCUMENTO_CHOICE = [
    { value: "00", label: "Documento regular" },
    { value: "01", label: "Documento regular extemporâneo" },
    { value: "02", label: "Documento cancelado" },
    { value: "03", label: "Documento cancelado extemporâneo" },
    { value: "04", label: "NF-e ou CT-e denegada" },
    { value: "05", label: "NF-e ou CT-e Numeração inutilizada" },
    { value: "06", label: "Documento Fiscal Complementar" },
    { value: "07", label: "Documento Fiscal Complementar extemporâneo" },
    { value: "08", label: "Documento Fiscal emitido com base em Regime Especial ou Norma Específica" },
];

export const TIPO_MODELO_SPED = [
    { value: "ECF", label: "ECF" },
    { value: "EFDC", label: "EFD Contribuições" },
    { value: "EFDI", label: "EFD ICMS IPI" },
    { value: "EFDR", label: "EFD-Reinf" },
];

export const TIPO_TRANSACOES_PENDENCIAS = [
    { value: 0, label: "Máquina" },
    { value: 1, label: "TEF", disabled: true },
    { value: 2, label: "Gateway", disabled: true },
];

export const TIPO_CHOICE_LOTE_SERIAL = [
    { value: "-", label: "Sem lote ou nº de série" },
    { value: "L", label: "Lote sem vencimento" },
    { value: "V", label: "Lote com vencimento" },
    { value: "S", label: "Número de série" },
];

export const TIPO_TRIBUTACAO_ISSQN = [
    { value: 1, label: "Operação tributável" },
    { value: 2, label: "Imunidade" },
    { value: 3, label: "Exportação de serviço" },
    { value: 4, label: "Não Incidência" },
];

export const TIPO_IMUNIDADE_ISSQN = [
    { value: 0, label: "Imunidade (tipo não informado na nota de origem)" },
    { value: 1, label: "Patrimônio, renda ou serviços, uns dos outros (CF88, Art 150, VI, a)" },
    { value: 2, label: "Templos de qualquer culto (CF88, Art 150, VI, b)" },
    {
        value: 3,
        label: "Patrimônio, renda ou serviços dos partidos políticos, inclusive suas fundações, das entidades sindicais dos trabalhadores, das instituições de educação e de assistência social, sem fins lucrativos, atendidos os requisitos da lei (CF88, Art 150, VI, c)",
    },
    { value: 4, label: "Não Livros, jornais, periódicos e o papel destinado a sua impressão (CF88, Art 150, VI, d)" },
];

export const TIPO_RETENCAO_ISSQN = [
    { value: 1, label: "Não Retido" },
    { value: 2, label: "Retido pelo Tomador" },
    { value: 3, label: "Retido pelo Intermediario" },
];

export const TIPO_CST_PIS_COFINS = [
    { value: "00", label: "00 - Nenhum" },
    { value: "01", label: "01 - Operação Tributável com Alíquota Básica" },
    { value: "02", label: "02 - Operação Tributável com Alíquota Diferenciada" },
    { value: "03", label: "03 - Operação Tributável com Alíquota por Unidade de Medida de Produto" },
    { value: "04", label: "04 - Operação Tributável monofásica - Revenda a Alíquota Zero" },
    { value: "05", label: "05 - Operação Tributável por Substituição Tributária" },
    { value: "06", label: "06 - Operação Tributável a Alíquota Zero" },
    { value: "07", label: "07 - Operação Tributável da Contribuição" },
    { value: "08", label: "08 - Operação sem Incidência da Contribuição" },
    { value: "09", label: "09 - Operação com Suspensão da Contribuição" },
];

export const TIPO_BENEFICIO_MUNICIPAL = [
    { value: 1, label: "Alíquota Diferenciada" },
    { value: 2, label: "Redução da BC" },
    { value: 3, label: "Isenção" },
];
