import React, { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import { PermissoesForm } from "@/pages/Gestao/Usuarios/PapeisPerfis/permissoesForm";
import { useHistory } from "react-router-dom";
import { CHAVE_PAPEL_CHOICE, TIPO_PESSOA_APLICAVEL_CHOICE } from "@/assets/constants/constants";

import classNames from "classnames";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Dropdown } from "@/components/Dropdown";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

const url = "/pessoas/papeis-perfis/";
const urlvoltar = "/gestao/usuarios/papeis-perfis";

export const PessoasPapeisPerfisForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();
    const history = useHistory();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            nome: "",
            max_desconto_avista: 0,
            max_desconto_encargos: 0,
            chave: null,
            tipo_pessoa_aplicavel: null,
            comissao_venda: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (props.location.state?.papeisPerfis) {
            setValues(props.location.state.papeisPerfis);
        }
    }, [props.location.state?.papeisPerfis, setValues]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                tipo_pessoa_aplicavel: Yup.number()
                    .required("O campo 'tipo de pessoa aplicável' é obrigatório.")
                    .typeError("Selecione um tipo de pessoa aplicável."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Papel de perfil cadastrado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push({
                            path: "/gestao/usuarios/papeis-perfis/form",
                            state: { papeisPerfis: data },
                        });
                    },
                };
                showLoading();
                await httpPost({ url, body: values }, handlers);
                hideLoading();
            } else {
                values.permissoes = {};
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Papel de perfil alterado com sucesso!",
                            life: 1500,
                        });

                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPut({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    useEffect(() => {
        if (formik.values.tipo_pessoa_aplicavel && formik.values.tipo_pessoa_aplicavel.id) {
            setFieldValue("tipo_pessoa_aplicavel", formik.values.tipo_pessoa_aplicavel.id);
        }

        if (formik.values.chave && formik.values.chave.id) {
            setFieldValue("chave", formik.values.chave.id);
        }
    }, [formik.values.tipo_pessoa_aplicavel, formik.values.chave, setFieldValue]);

    return (
        <PageBase>
            <h3>
                {!formik.values.id ? "Novo papel de perfil" : `Manutenção de papel de perfil: ${formik.values.nome}`}
            </h3>
            <TabView className="tabview-custom">
                <TabPanel header="Papel perfil" leftIcon="pi pi-fw pi-book">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <Label htmlFor="nome" label="Nome" obrigatorio />
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="tipo_pessoa_aplicavel" label="Tipo de pessoa aplicável" obrigatorio />
                                <Dropdown
                                    id="tipo_pessoa_aplicavel"
                                    name="tipo_pessoa_aplicavel"
                                    placeholder="Selecione um tipo"
                                    options={TIPO_PESSOA_APLICAVEL_CHOICE}
                                    optionValue="value"
                                    optionLabel="label"
                                    value={formik.values.tipo_pessoa_aplicavel}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo_pessoa_aplicavel })}
                                />
                                {formik.errors.tipo_pessoa_aplicavel && (
                                    <small className="p-error">{formik.errors.tipo_pessoa_aplicavel}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="chave" label="Chave" />
                                <Dropdown
                                    id="chave"
                                    name="chave"
                                    placeholder="Selecione um chave"
                                    options={CHAVE_PAPEL_CHOICE}
                                    optionValue="value"
                                    optionLabel="label"
                                    value={formik.values.chave}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="max_desconto_avista" label="Max. desconto à vista" />
                                <InputNumber
                                    id="max_desconto_avista"
                                    name="max_desconto_avista"
                                    suffix=" %"
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    min={0}
                                    value={formik.values.max_desconto_avista}
                                    onValueChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="max_desconto_encargos" label="Max. desconto encargos" />
                                <InputNumber
                                    id="max_desconto_encargos"
                                    name="max_desconto_encargos"
                                    suffix=" %"
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    min={0}
                                    value={formik.values.max_desconto_encargos}
                                    onValueChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="comissao_venda" label="Comissão de vendas" />
                                <Dropdown
                                    id="comissao_venda"
                                    name="comissao_venda"
                                    placeholder="Selecione uma comissão"
                                    url={"/vendas/comissoes-vendas?aplica_perfil=true"}
                                    showClear
                                    filter
                                    filterBy="descricao"
                                    optionValue="id"
                                    optionLabel="descricao"
                                    value={formik.values.comissao_venda}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field-checkbox p-mt-5 p-col-12 p-md-3">
                                <Checkbox
                                    inputId="status"
                                    name="ativo"
                                    disabled={formik.values.id ? false : true}
                                    checked={formik.values.ativo}
                                    onChange={formik.handleChange}
                                />
                                <Label htmlFor="status" label={formik.values.ativo ? "Ativo" : "Inativo"} />
                            </div>
                        </div>
                        <CamposObrigatorios />
                        <MakoActionsButtons>
                            <Button
                                label="Gravar"
                                icon={MAKO_ICONS.GRAVAR}
                                type="submit"
                                className="p-button-info"
                                loading={loading}
                            />
                            <Button
                                label="Cancelar"
                                icon={MAKO_ICONS.CANCEL}
                                type="button"
                                className="p-button-danger"
                                onClick={cancelar}
                                loading={loading}
                            />
                        </MakoActionsButtons>
                    </form>
                </TabPanel>
                <TabPanel
                    header="Permissões"
                    disabled={formik.values.id ? false : true}
                    leftIcon="pi pi-fw pi-lock-open"
                >
                    <PermissoesForm state={formik.values} />
                </TabPanel>
            </TabView>
        </PageBase>
    );
};
