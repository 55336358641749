import { Checkbox } from "primereact/checkbox";

export const MakoInputCheckBox = ({
    className = "p-field p-col-12 p-md-2 p-mt-4",
    id = "",
    name = "",
    checked = "",
    label = "",
    onChange,
    disabled = false,
}) => {
    return (
        <div className={className} style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Checkbox
                inputId={id}
                name={name}
                checked={checked}
                value={checked}
                onChange={onChange}
                disabled={disabled}
            />
            <label htmlFor={id} className="p-mt-2">
                {label}
            </label>
        </div>
    );
};
