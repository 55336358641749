import React from "react";

import { MakoButton } from "@/components/MakoButton";

export const Calcular = ({ onClick = () => {}, disabled = false }) => {
    return (
        <MakoButton
            icon="pi pi-calculator"
            tooltip="Calcular"
            type="button"
            className="p-button-rounded p-button-warning"
            onClick={onClick}
            disabled={disabled}
        />
    );
};
