import React, { useCallback, useState } from "react";

import { classNames } from "primereact/utils";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

import useToast, { DEFAULT_ERROR_TOAST } from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";
import useHttp from "@/hooks/useHttp";

const { VENDAS_VENDA_CARGAS_FECHAR } = permissoes;

const BASE_URL = "/vendas/cargas/{id}/";

const BASE_CARGA = {
    id: null,
    situacao: "",
    codigo: "",
};

export const Fechar = ({
    carga = BASE_CARGA,
    successCallback = () => {},
    className = "",
    disabled = false,
    loading = false,
}) => {
    const [visible, setVisible] = useState(false);
    const [fetching, show, hide] = useLoadingLocal();
    const { showError, showSuccess } = useToast();
    const { httpPatch } = useHttp();

    const fechar = useCallback(async () => {
        let sucesso = false;
        show();
        const url = BASE_URL.replace("{id}", carga?.id);
        const handlers = {
            200: ({ data }) => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Carga fechada com sucesso!",
                    life: 1500,
                });
                successCallback(data);
                sucesso = true;
            },
            400: ({ err }) => {
                const { msg } = err;
                showError({
                    ...DEFAULT_ERROR_TOAST,
                    detail: msg || DEFAULT_ERROR_TOAST.detail,
                });
            },
        };

        await httpPatch({ url, body: { situacao: "F" } }, handlers);
        hide();

        return sucesso;
    }, [carga?.id, hide, show, showError, showSuccess, successCallback, httpPatch]);

    return (
        <>
            <MakoControleAcesso
                componente={Button}
                permissao={[VENDAS_VENDA_CARGAS_FECHAR]}
                type="button"
                label="Fechar carga"
                icon={MAKO_ICONS.BLOQUEADO}
                className={classNames("p-button p-button-warning", className)}
                disabled={!carga?.id || disabled || carga?.situacao === "F"}
                onClick={() => setVisible(true)}
                loading={loading || fetching}
            />
            <MakoConfirmDialog
                visible={visible}
                setVisible={setVisible}
                showErrorToast={false}
                message={
                    <span>
                        {"Deseja realmente fechar a carga: "}
                        <b>{carga?.codigo}</b>?
                    </span>
                }
                accept={fechar}
            />
        </>
    );
};
