import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import useEmpresa from "@/hooks/useEmpresa";

export const EstoqueFiltroTemplate = ({ inputProps = {}, filtrarPorEmpresa = true, options }) => {
    const { empresaSelecionadaId } = useEmpresa();

    return (
        <MakoDropdownEstoques
            empresaId={filtrarPorEmpresa ? empresaSelecionadaId : null}
            {...inputProps}
            value={options.value}
            onChange={(e) => options.filterCallback(e.value, options.index)}
        />
    );
};
