import React, { useMemo } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";

const url = "/pessoas/tags-perfis/";

export const PessoasTagsPerfisForm = (props) => {
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();

    const formik = useFormik({
        initialValues: props?.location?.state?.tag || {
            descricao: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tag cadastrada com sucesso!",
                            life: 1500,
                        });
                        if (props.modal) props.esconderDialog(true);
                        else voltarParaListagem();
                    },
                };
                showLoading();
                await httpPost({ url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tag alterada com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };
                showLoading();
                await httpPut({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        history.push("/gestao/cadastros/tags-perfis");
    };

    const Base = useMemo(() => {
        return !props?.modal ? PageBase : React.Fragment;
    }, [props?.modal]);

    return (
        <Base>
            {!props?.modal && <h3>{!formik.values.id ? "Nova tag de perfil" : "Manutenção de tag de perfil"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className={`p-field p-col-12 p-md-${props?.modal ? "12" : "6"}`}>
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons
                    className={props?.modal ? "p-jc-end" : ""}
                    reverse={props?.modal}
                    reverse_base={!props?.modal}
                >
                    <Button
                        icon="pi pi-angle-double-left"
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger"
                        onClick={!props?.modal ? voltarParaListagem : props.esconderDialog}
                        loading={loading}
                    />
                    <Button
                        icon="pi pi-save"
                        label="Gravar"
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </Base>
    );
};
