import React, { useCallback } from "react";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useEmpresa from "@/hooks/useEmpresa";

import { FormFechar as Form } from "../../features";

export const Fechar = ({ fecharModal = () => {}, handleCaixa = () => {} }) => {
    const { empresaSelecionadaId } = useEmpresa();
    const { caixaMov } = useCaixaMovimento();

    const successCalback = useCallback(() => {
        fecharModal();
        handleCaixa();
    }, [fecharModal, handleCaixa]);

    return (
        <Form
            caixaMov={caixaMov}
            empresaId={empresaSelecionadaId}
            successCalback={successCalback}
            cancelCallback={fecharModal}
        />
    );
};
