import React, { useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "primereact/button";

import { ModalListaNotificacoes } from "@/pages/Notificacoes";
import useNotificacao from "@/hooks/useNotificacao";
import { classNames } from "primereact/utils";

export const AppNotificacoes = ({ onClick }) => {
    const modalNotificacoesRef = useRef(null);
    const { notifications, marcarTodasMsgComoLidas, ouvirNotificacoes } = useNotificacao();

    useEffect(() => {
        const { token, ...cancelToken } = axios.CancelToken.source();
        setInterval(() => ouvirNotificacoes(token), 30 * 1000);
        return function clear() {
            cancelToken.cancel();
        };
    }, [ouvirNotificacoes]);

    return (
        <>
            <Button
                type="button"
                icon="pi pi-bell"
                outlined={notifications === 0}
                onClick={onClick}
                badge={notifications || undefined}
                className={classNames("p-px-1 mako-notificacao-button", {
                    "mako-notificacao-button-only-bell": notifications === 0,
                })}
            />
            <ul className="notifications-menu fade-in-up">
                <li role="menuitem" style={{ textAlign: "center" }}>
                    <Button
                        label="Central de notificações"
                        icon="pi pi-bell"
                        className="p-button-secondary p-mb-2"
                        onClick={() => modalNotificacoesRef.current?.abrirModal()}
                    />
                    <Button
                        label="Marcar todas como lida"
                        icon="pi pi-check-square"
                        className="p-button-info p-mb-2"
                        onClick={marcarTodasMsgComoLidas}
                    />
                </li>
            </ul>
            <ModalListaNotificacoes ref={modalNotificacoesRef} />
        </>
    );
};
