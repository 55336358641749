import React, { useRef } from "react";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Tag } from "primereact/tag";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import {
    AtivoFiltroTemplate,
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TIPO_TAG_CHOICE } from "@/assets/constants/constants";
import { BotaoDelete } from "@/components/BotaoDelete";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

export const NomeRelacaoPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();
    const { showSuccess } = useToast();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CREDIARIO_NOMERELACAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de nome de relação"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/crediario/nomes-relacao/form",
                            state: { relacao: { ...rowData, tipo: rowData.tipo.id } },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CREDIARIO_NOMERELACAO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/crediario/nome-relacao/"}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o nome de relação <b>{rowData.relacao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O nome de relação não pode ser excluído."
                    tooltip="Deletar nome de relação"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Nome de relação deletado com sucesso!",
                            life: 3000,
                        });
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CREDIARIO_NOMERELACAO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/venda/crediario/nomes-relacao/form")}
            />
        </>
    );

    const tipoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_TAG_CHOICE,
                }}
                options={options}
            />
        );
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="INATIVO" />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "12%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        { field: "relacao", header: "Nome relação", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "tipo.descricao",
            header: "Tipo",
            style: { width: "15%" },
            filter: true,
            filterElement: tipoFiltroTemplate,
            filterField: "tipo",
        },
        {
            field: "ativo",
            header: "Situação",
            style: { width: "15%" },
            filter: true,
            filterElement: AtivoFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    return (
        <>
            <PageBase>
                <MakoListagem
                    ref={listagemRef}
                    titulo="Nome de Relação de Perfil"
                    colunas={colunas}
                    painelEsquerdo={cabecalhoTabela}
                    urlPesquisa="/crediario/nome-relacao/"
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                    filtros={{
                        id: { value: "", matchMode: "equals" },
                        relacao: { value: "", matchMode: "icontains" },
                        tipo: { value: null, matchMode: "equals" },
                        ativo: { value: null, matchMode: "equals" },
                    }}
                />
            </PageBase>
        </>
    );
};
