import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Form } from "./form";

const Component = ({ successCallback = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);

    const formRef = useRef(null);
    const filtrosRef = useRef(null);

    const show = (value) => {
        filtrosRef.current = value;
        setVisible(true);
    };

    const onShow = useCallback(() => {
        formRef.current?.initForm(filtrosRef.current);
    }, []);

    const onFilter = useCallback(
        (filtros, query) => {
            setVisible(false);
            successCallback({ filtros, query });
        },
        [successCallback]
    );

    const onCancel = useCallback(() => {
        setVisible(false);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            show,
            hide: () => setVisible(false),
        };
    });

    return (
        <Dialog
            visible={visible}
            header={"Filtro Avançado"}
            onHide={() => setVisible(false)}
            style={{ width: "80vw" }}
            onShow={onShow}
        >
            <Form ref={formRef} successCallback={onFilter} cancelCallback={onCancel} />
        </Dialog>
    );
};

export const Modal = forwardRef(Component);
