import React, { createContext, useCallback, useState } from "react";

import { dataToStr } from "@/assets/util/datas";
import useAuth from "@/hooks/useAuth";
import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";

const OrdemCompraContext = createContext({});

export const OrdemCompraProvider = ({ children }) => {
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [fornecedorOC, setFornecedorOC] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [loadingOC, setLoadingOC] = useState(false);
    const [permiteAlterar, setPermiteAlterar] = useState(true);
    const { user } = useAuth();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpDelete, httpGet, httpPost, httpPatch } = useHttp();

    const handleDadosBasicos = useCallback(
        async (values) => {
            const ordemCompra = {
                ...values,
                fornecedor: values.fornecedor.id,
                representante: values.representante?.id,
                data_pedido: dataToStr(values.data_pedido, "yyyy-MM-dd"),
                vencimento_antecipacao: dataToStr(values.vencimento_antecipacao, "yyyy-MM-dd"),
                previsao_padrao: dataToStr(values.previsao_padrao, "yyyy-MM-dd"),
            };
            if (!values.id) {
                const novaOrdemCompra = {
                    ...ordemCompra,
                    status: "PE",
                    empresa: empresaSelecionadaId,
                    usuario_cadastro: user.id,
                    quantidade_parcelas: 0,
                    itemordemcompra_set: {},
                };
                let status = 400;
                let data = null;
                const handlers = {
                    201: ({ data: d }) => {
                        data = d;
                        status = 201;
                        setDadosBasicos(d);
                        setFornecedorOC(d.fornecedor);
                    },
                };
                await httpPost({ url: "/compras/ordens-compra/", body: novaOrdemCompra }, handlers);
                return { status, data };
            } else {
                const ordemCompraEditada = {
                    fornecedor: ordemCompra.fornecedor,
                    representante: ordemCompra.representante,
                    data_pedido: ordemCompra.data_pedido,
                    categoria: ordemCompra.categoria,
                    categoria_item: ordemCompra.categoria_item,
                    valor_antecipado: ordemCompra.valor_antecipado,
                    vencimento_antecipacao: ordemCompra.vencimento_antecipacao,
                    anotacoes: ordemCompra?.anotacoes,
                    forma_pagamento: ordemCompra?.forma_pagamento,
                    previsao_padrao: ordemCompra?.previsao_padrao,
                };
                let diffOrdemCompra = {};
                for (const [k, v] of Object.entries(ordemCompraEditada)) {
                    if (v !== dadosBasicos[k]) {
                        diffOrdemCompra[k] = v;
                    }
                }
                if (Object.keys(diffOrdemCompra).length > 0) {
                    let status = 400;
                    let data = null;
                    const handlers = {
                        200: ({ data: d }) => {
                            data = d;
                            status = 200;
                            setDadosBasicos(d);
                            setFornecedorOC(d.fornecedor);
                        },
                    };
                    await httpPatch({ url: `/compras/ordens-compra/${values.id}/`, body: diffOrdemCompra }, handlers);
                    return { status, data };
                }
                return { status: 204, data: values };
            }
        },
        [empresaSelecionadaId, user, httpPost, dadosBasicos, httpPatch]
    );

    const handleRemoverParcelas = useCallback(async () => {
        let ok = false;
        const handlers = {
            204: () => (ok = true),
        };
        await httpDelete({ url: `/compras/ordens-compra/${dadosBasicos?.id}/deletar-parcelas/` }, handlers);
        return ok;
    }, [dadosBasicos, httpDelete]);

    const handlePreencherOrdemCompra = useCallback(
        async (ordemCompraId) => {
            const handlers = {
                200: ({ data }) => {
                    const {
                        vinculotransportadorordemcompra_set,
                        itemordemcompra_set,
                        parcelaordemcompra_set,
                        status,
                        ...rest
                    } = data;
                    setDadosBasicos(rest);
                    setFornecedorOC(rest.fornecedor);
                    setSubmit(true);
                    setPermiteAlterar(() => {
                        if (rest.vinculado_entrada) return false;
                        return status === "PE";
                    });
                },
            };
            setLoadingOC(true);
            await httpGet({ url: `/compras/ordens-compra/${ordemCompraId}/` }, handlers);
            setLoadingOC(false);
        },
        [httpGet]
    );

    const atualizarValoresOC = useCallback(async () => {
        if (dadosBasicos) {
            const campos = [
                "valor_total_itens",
                "valor_frete_previsto",
                "percentual_frete_emitente",
                "percentual_frete_destinatario",
                "valor_total_parcelas",
                "valor_total_ordem_compra",
                "valor_total_ipi",
                "valor_total_pis",
                "valor_total_cofins",
                "valor_total_icms",
                "valor_total_icms_st",
            ];
            const params = {
                query: `{${campos.join(",")}}`,
            };
            const handlers = {
                200: ({ data }) => setDadosBasicos((old) => ({ ...old, ...data })),
            };
            await httpGet({ url: `/compras/ordens-compra/${dadosBasicos.id}/`, params }, handlers);
        }
    }, [dadosBasicos, httpGet]);

    return (
        <OrdemCompraContext.Provider
            value={{
                submit,
                loadingOC,
                permiteAlterar,
                dadosBasicos,
                fornecedorOC,
                handleRemoverParcelas,
                handlePreencherOrdemCompra,
                handleDadosBasicos,
                atualizarValoresOC,
                setSubmit,
            }}
        >
            {children}
        </OrdemCompraContext.Provider>
    );
};

export default OrdemCompraContext;
