import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { PageBase } from "@/components/PageBase";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";

const url = "/compras/servicos-ordens-compra/";

export const CadastroServicosForm = () => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPut } = useHttp();
    const { state } = useLocation();

    const formik = useFormik({
        initialValues: state?.servico || {
            nome: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço cadastrado com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };

                showLoading();
                await httpPost({ url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço alterada com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };

                showLoading();
                await httpPut({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const voltarParaListagem = () => {
        history.push("/compras/ordens-compra/servicos-ordens-compra");
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo serviço" : "Manutenção de serviço"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="nome">Nome *</label>
                        <InputText
                            id="nome"
                            name="nome"
                            autoComplete="off"
                            autoFocus
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" icon="pi pi-save" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        label="Voltar"
                        icon="pi pi-angle-double-left"
                        type="reset"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
        </PageBase>
    );
};
