import React from "react";
import MakoListagem from "../MakoListagem";
import useAuth from "@/hooks/useAuth";
import { Card } from "primereact/card";

export const MakoUltimosRegistros = () => {
    const { user } = useAuth();

    const colunas = [
        {
            field: "data_hora",
            header: "Data/Hora",
            style: { minWidth: "80px" },
            dateFormat: "dd/MM/yyyy HH:mm:ss",
        },
        {
            field: "detalhes",
            header: "Detalhes",
            style: { minWidth: "450px" },
        },
        { field: "ip", header: "IP", style: { minWidth: "80px" } },
    ];

    return (
        <Card>
            <h5 className="p-mt-0 p-mb-0">Últimas atividades registradas</h5>
            <MakoListagem
                urlPesquisa={`/logs/historico-logs?usuario=${user?.usuario?.id}&limit=10`}
                colunas={colunas}
                msgTabelaVazia="Nenhum atividade registrada"
            />
            <div className="p-grid p-justify-center p-mt-2 p-mb-0">
                <label>
                    Últimos 10 registros do usuário: <b>{user?.usuario?.username}</b>
                </label>
            </div>
        </Card>
    );
};
