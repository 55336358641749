import React from "react";
import { Skeleton as SkeletonPR } from "primereact";

export const Skeleton = () => {
    return (
        <>
            <SkeletonPR height="1rem" width="15rem" className="p-mb-2" />
            <SkeletonPR height="2.5rem" className="p-mb-2" />
            <SkeletonPR height="1rem" width="15rem" className="p-mb-2" />
            <SkeletonPR height="2.5rem" className="p-mb-2" />
            <SkeletonPR height="1rem" width="15rem" className="p-mb-2" />
            <SkeletonPR height="2.5rem" className="p-mb-2" />
            <SkeletonPR height="1rem" width="15rem" className="p-mb-2" />
            <SkeletonPR height="2.5rem" />
        </>
    );
};
