import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Dropdown } from "@/components/Dropdown";
import permissoes from "@/assets/constants/permissoes";
import { url } from "@/services/axios";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

export const TokensIntegracaoForm = (props) => {
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { verifyPermission } = useAuth();
    const { showSuccess, showWarning } = useToast();
    const { httpPost, httpPut } = useHttp();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            usuario: null,
            token: "aaa",
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                usuario: Yup.number().required("O campo 'usuário' é obrigatório."),
            });
            await formSchema.validate(values, { abortEarly: false });
            if (!values.id) {
                const tokenUsuario = {
                    ...values,
                    token: url(),
                };
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Token cadastrado com sucesso!",
                            life: 1500,
                        });
                        voltar();
                    },
                    400: ({ err }) => {
                        showWarning({
                            summary: "Aviso",
                            detail: err?.message,
                            life: 3000,
                        });
                    },
                };
                showLoading();
                await httpPost({ url: "/integracoes/tokens-usuario/", body: tokenUsuario }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Token atualizado com sucesso!",
                            life: 1500,
                        });
                        voltar();
                    },
                };
                showLoading();
                await httpPut({ url: `/integracoes/tokens-usuario/${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const selecionaUsuario = useMemo(() => {
        const temPermissao = verifyPermission([permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_INCLUIR_TERCEIRO]);
        return temPermissao && !!formik.values.id;
    }, [verifyPermission, formik.values.id]);

    const usuarioSelecionadoTemplate = (option, props) => {
        if (option) {
            return <span>{`${option.nome} (${option.usuario.username})`}</span>;
        }
        return <span>{props.placeholder}</span>;
    };

    const opcaoUsuarioTemplate = (option) => {
        return <span>{`${option.nome} (${option.usuario.username})`}</span>;
    };

    useEffect(() => {
        if (props.location.state) {
            setValues({
                ...props.location.state,
                usuario: props.location.state.usuario.id,
            });
        }
    }, [props, setValues]);

    function voltar() {
        history.push("/gestao/usuarios/tokens");
    }

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo token de integração" : "Manutenção de token de integração"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="usuario" label="Usuário" obrigatorio />
                        <Dropdown
                            id="usuario"
                            name="usuario"
                            filter
                            filterBy="nome,usuario.username"
                            url="/pessoas/perfis/?usuario__isnull=False&query={id,usuario,nome,identificacao}&limit=500"
                            disabled={selecionaUsuario}
                            optionValue="id"
                            optionLabel="nome"
                            valueTemplate={usuarioSelecionadoTemplate}
                            itemTemplate={opcaoUsuarioTemplate}
                            value={formik.values.usuario}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.usuario })}
                        />
                        {formik.errors.usuario && <small className="p-error">{formik.errors.usuario}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="token" label="Token" />
                        <InputText id="token" name="token" disabled value={formik.values.token} />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-1 p-mt-5">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <Label htmlFor="ativo" label="Ativo?" />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="button"
                        className="p-button-danger"
                        onClick={voltar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
