import React, { useMemo } from "react";

import { MakoAutoComplete } from "../MakoAutoComplete";

export const MakoInputCFOP = ({ queryUrl, ...props }) => {
    const url = useMemo(() => {
        if (!queryUrl) return "/fiscal/cfop?search=";
        return `/fiscal/cfop?${queryUrl}&search=`;
    }, [queryUrl]);

    const autoCompleteCfopTemplate = (cfop) => {
        return <span>{cfop.descricao_completa}</span>;
    };

    return (
        <MakoAutoComplete
            {...props}
            urlSearch={url}
            field="descricao_completa"
            placeholder="Busque pelo código ou descrição ... (min 2 caracteres)"
            minCaracteresBusca={2}
            itemTemplate={autoCompleteCfopTemplate}
        />
    );
};
