import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import useHttp from "@/hooks/useHttp";

const Modal = ({ requisicoes }, ref) => {
    const [dialog, setDialog] = useState(false);
    const { showError, showSuccess } = useToast();
    const { httpPost } = useHttp();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            requisicao: null,
            codigo: null,
            descricao: "",
            unidade_medida: "",
            quantidade_original: 0,
            nova_quantidade: 0,
            resto_quantidade: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        const resposta = await Promise.all(
            requisicoes.map(async (req) => {
                let status = 400,
                    json = {};

                const handlers = {
                    200: ({ data }) => {
                        status = 200;
                        json = data;
                    },
                };

                await httpPost(
                    {
                        url: `/transferencias/itens-transferencia-mercadoria/`,
                        body: {
                            ...req,
                            transferencia: values.transferencia,
                            sku: req.sku.id,
                            valor_gerencial: 0,
                            valor_fiscal: 0,
                            inf_complementares: req.justificativa,
                            requisicao: req.id,
                        },
                    },
                    handlers
                );
                return { status, data: json };
            })
        );

        resposta.forEach(({ status, data }) => {
            if (status === 201) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: `A requisição ${data.requisicao} foi registrada na transferência com sucesso.`,
                    life: 1500,
                });
            } else
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
        });
        setDialog(false);
    }

    const exibirDialog = () => {
        setDialog(true);
    };

    useImperativeHandle(ref, () => ({ exibirDialog }));

    return (
        <Dialog
            header={`Vinculando requisição(ẽos) a uma transferência`}
            visible={dialog}
            style={{ width: "65vw", display: "block" }}
            onHide={() => setDialog(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                {requisicoes?.length > 0 ? (
                    <div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="transferencia">Número da transferência *</label>
                                <Dropdown
                                    id="transferencia"
                                    name="transferencia"
                                    placeholder="Selecione..."
                                    url={`/transferencias/transferencia-mercadoria?limit=10000&status=P&ce_origem=${requisicoes[0].ce_origem.id}&ce_destino=${requisicoes[0].ce_destino.id}`}
                                    optionLabel="id"
                                    optionValue="id"
                                    filter
                                    filterBy="id"
                                    value={formik.values.transferencia}
                                    showClear={false}
                                    optionDisabled={(e) => e.value === "V"}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="ce_origem">Centro de estocagem de origem</label>
                                <InputText
                                    id="ce_origem"
                                    name="ce_origem"
                                    value={requisicoes[0]?.ce_origem?.nome}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="ce_destino">Centro de estocagem de destino</label>
                                <InputText
                                    id="ce_destino"
                                    name="ce_destino"
                                    value={requisicoes[0]?.ce_destino?.nome}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                ) : null}

                <br />
                <div className="p-grid p-justify-end">
                    <Button type="submit" label="Gravar" className="p-mr-2" disabled={!formik.values.transferencia} />
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => setDialog(false)}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const TransferirModalForm = forwardRef(Modal);
