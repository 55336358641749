import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";

import { TIPO_CARTAO_CHOICE } from "@/assets/constants/financeiro";
import { gerarFiltrosUrl, gerarStatusBooleanTemplate } from "@/assets/util/util";

const BASE_URL = "/financeiro/taxas-administracao-cartao/";

const actionTipoCartao = ({ tipo_cartao }) => {
    const tipo = TIPO_CARTAO_CHOICE.find(({ value }) => value === tipo_cartao);
    return tipo?.label;
};

const actionParcelas = ({ minimo_parcela, maximo_parcela }) => {
    return `${minimo_parcela}-${maximo_parcela}`;
};

export const ListagemTaxa = ({ filtros = {}, buscar = true, listagemRef, actionTemplate = () => {} }) => {
    const colunas = [
        { field: "tipo_cartao", header: "Tipo de Cartão", action: actionTipoCartao, style: { minWidth: "150px" } },
        { field: "bandeira.nome", header: "Bandeira" },
        {
            field: "minimo_parcela",
            header: "Parcelas",
            align: "center",
            style: { minWidth: "60px" },
            action: actionParcelas,
        },
        {
            field: "inicio_vigencia",
            header: "Data inicial",
            dateFormat: "dd/MM/yyyy",
            align: "center",
            style: { miWidth: "60px" },
        },
        {
            field: "final_vigencia",
            header: "Data final",
            dateFormat: "dd/MM/yyyy",
            align: "center",
            style: { miWidth: "60px" },
        },
        {
            field: "ativo",
            header: "Situação",
            align: "center",
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "INATIVO"),
        },
        {
            field: "taxa",
            header: "Taxa (%)",
            align: "center",
            decimal: true,
        },
        {
            field: "recebe_via_cartao",
            header: "Via cartão",
            align: "center",
            decimal: true,
            action: ({ recebe_via_cartao }) => gerarStatusBooleanTemplate(recebe_via_cartao),
        },
        {
            field: "",
            header: "Ações",
            align: "center",
            action: actionTemplate,
            style: { width: "8%" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    const url = useMemo(() => {
        return `${BASE_URL}?${gerarFiltrosUrl(filtros)}`;
    }, [filtros]);

    return (
        <div className="p-my-2">
            <MakoListagem ref={listagemRef} fazerBusca={buscar} urlPesquisa={url} colunas={colunas} />
        </div>
    );
};
