import React, { useRef } from "react";

import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { TIPO_PRIORIDADE_VINCULO_FORNECEDORPRODUTO } from "@/assets/constants/constants";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import {
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    MoedaFiltroTemplate,
    NumeroFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { BotaoDelete } from "@/components/BotaoDelete";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

export const VinculoFornecedor = () => {
    const history = useHistory();
    const listagemRef = useRef();
    const { showSuccess } = useToast();

    useClearRefs(listagemRef);

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/produto/cadastros/vinculo-fornecedor/form")}
            />
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_EDITAR]}
                componente={Button}
                label="Ajustar Preço"
                icon="pi pi-search"
                className="p-button-info p-mr-2"
                onClick={() => history.push("/produto/cadastros/vinculo-fornecedor/ajustar-preco")}
            />
        </>
    );

    const prioridadeTemplate = (value) => {
        const tipo = TIPO_PRIORIDADE_VINCULO_FORNECEDORPRODUTO.filter((item) => item.value === value)[0];
        return <Badge value={tipo.label} severity={tipo.badge} />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/cadastros/vinculo-fornecedor/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/produtos/vinculo-produtos-fornecedor/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir este vínculo? <b>(Código: {rowData.id})</b>
                        </span>
                    }
                    msgToastErroExclusao="O vínculo entre produto e fornecedor não pode ser excluído."
                    tooltip="Deletar vínculo"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso, :)",
                            detail: "Vinculo deletado com sucesso",
                            life: 3000,
                        });
                    }}
                />
            </div>
        );
    };

    const PrioridadeFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_PRIORIDADE_VINCULO_FORNECEDORPRODUTO,
                }}
                options={options}
            />
        );
    };

    const MediaFiltroTemplate = (options) => {
        return <NumeroFiltroTemplate options={options} inputProps={{ showButtons: true }} />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "sku.descricao_reduzida",
            header: "Produto",
            filter: true,
            filterElement: TextoFiltroTemplate,
            style: { minWidth: "200px" },
            filterField: "sku__descricao_reduzida",
        },
        {
            field: "fornecedor.nome",
            header: "Fornecedor",
            filter: true,
            filterElement: TextoFiltroTemplate,
            style: { minWidth: "200px" },
            filterField: "fornecedor__nome",
        },
        {
            field: "preco_compra",
            header: "Preço Compra",
            style: { minWidth: "150px" },
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "media_entrega",
            header: "Média de entrega",
            style: { minWidth: "160px" },
            filter: true,
            filterElement: MediaFiltroTemplate,
        },
        {
            field: "prioridade",
            header: "Prioridade",
            style: { minWidth: "120px" },
            action: ({ prioridade }) => prioridadeTemplate(prioridade),
            filter: true,
            filterElement: PrioridadeFiltroTemplate,
        },
        {
            field: "",
            header: "Ações",
            style: { minWidth: "100px" },
            action: (e) => actionBodyTemplate(e),
            frozen: true,
            alignFrozen: "right",
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Vinculos de Produtos do Fornecedor"
                urlPesquisa="/produtos/vinculo-produtos-fornecedor/"
                painelEsquerdo={cabecalhoTabela}
                colunas={colunas}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    frozenWidth: "0.5vw",
                    scrollable: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    sku__descricao_reduzida: { value: null, matchMode: "unaccent__icontains" },
                    fornecedor__nome: { value: null, matchMode: "unaccent__icontains" },
                    preco_compra: { value: null, matchMode: "equals" },
                    media_entrega: { value: null, matchMode: "equals" },
                    prioridade: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
