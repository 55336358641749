import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import useToast from "@/hooks/useToast";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { dataToStr } from "@/assets/util/datas";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

export const Modal = ({ apuracao, aposSalvar }, ref) => {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [visible, setVisible] = useState(false);
    const [comissoes, setComissoes] = useState([]);
    const [totais, setTotais] = useState([]);
    const { user } = useAuth();
    const { showLoading, hideLoading } = useLoading();
    const listagemRef = useRef(null);
    const { showSuccess, showWarning } = useToast();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { httpGet, httpPut } = useHttp();

    async function handleApurar() {
        const handlers = {
            200: () => {
                if (typeof aposSalvar === "function") aposSalvar();
                showSuccess({
                    summary: "Sucesso",
                    detail: "Comissões calculadas com sucesso!",
                    life: 2500,
                });
                listarComissoesApuradas();
                listagemRef.current?.buscarDados();
            },
            400: ({ err }) =>
                showWarning({
                    summary: "Aviso",
                    detail: err?.msg,
                    life: 6000,
                }),
        };

        showLoading();
        await httpPut(
            {
                url: `/vendas/apurar-comissoes/${apuracao.id}/`,
                body: {
                    usuario: user.id,
                },
            },
            handlers
        );
        hideLoading();
    }

    const listarComissoesApuradas = useCallback(async () => {
        if (apuracao?.id && visible) {
            const handlers = {
                200: ({ data }) => {
                    setComissoes([
                        ...data.results,
                        {
                            escopo: { descricao: "Total" },
                            qtd_vendas: data.results
                                .map((item) => item.qtd_vendas)
                                .reduce((prev, curr) => prev + curr, 0),
                            soma_base: data.results
                                .map((item) => item.soma_base)
                                .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0),
                            valor_comissao: data.results
                                .map((item) => item.valor_comissao)
                                .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0),
                        },
                    ]);

                    setTotais([
                        {
                            escopo: { descricao: "Total" },
                            qtd_vendas: data.results
                                .map((item) => item.qtd_vendas)
                                .reduce((prev, curr) => prev + curr, 0),
                            soma_base: data.results
                                .map((item) => item.soma_base)
                                .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0),
                            valor_comissao: data.results
                                .map((item) => item.valor_comissao)
                                .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0),
                        },
                    ]);
                },
            };

            await httpGet({ url: `/vendas/comissoes-apuradas?apuracao=${apuracao.id}&limit=3000` }, handlers);
        }
    }, [apuracao, visible, httpGet]);

    useEffect(() => {
        listarComissoesApuradas();
    }, [listarComissoesApuradas]);

    const colunas = [
        { field: "id", header: "Código" },
        { field: "comissionado.nome", header: "Comissionado", style: { minWidth: "150px" } },
        {
            field: "comissionado.identificacao",
            header: "CPF",
            action: ({ comissionado }) => formatarDocumento(comissionado?.identificacao),
        },
        { field: "chave.descricao", header: "Chave" },
        { field: "escopo.descricao", header: "Escopo" },
        { field: "qtd_vendas", header: "Qtd. vendas" },
        { field: "soma_base", header: "Base", money: true },
        { field: "valor_comissao", header: "Comissão", money: true },
    ];

    useImperativeHandle(ref, () => {
        return {
            setVisible,
        };
    });

    return (
        <>
            <Dialog
                header={`Cálculo de Comissão (${apuracao.id}) ${dataToStr(
                    apuracao.data_inicial,
                    "dd/MM/yyyy"
                )} a ${dataToStr(apuracao.data_final, "dd/MM/yyyy")}`}
                visible={visible}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "65vw", display: "block" }}
                onHide={() => setVisible(false)}
            >
                <div className="p-col-12">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <MakoListagem
                                ref={listagemRef}
                                colunas={colunas}
                                dadosLocal={comissoes}
                                frozenValue={totais}
                                configTabela={{
                                    scrollable: true,
                                    scrollHeight: "260px",
                                }}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-justify-end p-col-12 p-md-12 p-mb-0 p-mt-2">
                        <Button
                            icon="pi pi-refresh"
                            label={comissoes?.length > 0 ? "Recalcular" : "Calcular"}
                            type="button"
                            severity="help"
                            className="p-button-info p-mr-2"
                            disabled={apuracao?.status === "P"}
                            onClick={() => setConfirmDialog(true)}
                        />
                        <Button
                            icon="pi pi-times"
                            label="Fechar"
                            type="button"
                            severity="danger"
                            className="p-button-info"
                            onClick={() => setVisible(false)}
                        />
                    </div>
                </div>
            </Dialog>
            <ConfirmDialog
                visible={confirmDialog}
                onHide={() => setConfirmDialog(false)}
                header="Confirmação de apuração"
                message={"Deseja realmente realizar o cálculo das comissões?"}
                icon="pi pi-info-circle p-mr-3"
                accept={() => handleApurar()}
                acceptLabel="Sim"
                acceptClassName="p-button-success"
                reject={() => setConfirmDialog(false)}
                rejectLabel="Não"
                rejectClassName="p-button-danger"
            />
        </>
    );
};

export const CalculoComissaoForm = forwardRef(Modal);
