import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Form } from "../../features";

const Component = ({ onSuccessCallback = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);
    const [lote, setLote] = useState(null);

    const show = useCallback((values) => {
        setLote({ ...values, serie: values?.sku?.lote_serial });
        setVisible(true);
    }, []);

    const hide = useCallback(() => {
        setVisible(false);
    }, []);

    const onSuccess = useCallback(
        (...args) => {
            hide();
            onSuccessCallback(...args);
        },
        [hide, onSuccessCallback]
    );

    const onCancel = useCallback(() => {
        hide();
    }, [hide]);

    useImperativeHandle(ref, () => ({ show, hide }), [show, hide]);

    return (
        <Dialog
            header="Editando lote / Serial"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "80vw" }}
            onHide={hide}
        >
            <Form lote={lote} successCallback={onSuccess} cancelCallback={onCancel} />
        </Dialog>
    );
};

export const Editar = forwardRef(Component);
