import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";

export const ClienteFiltroTemplate = (options) => {
    return (
        <MakoInputCliente
            value={options.value}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            className="p-column-filter"
        />
    );
};
