import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";

import MakoListagem from "@/components/MakoListagem";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";

const Modal = ({}, ref) => {
    const [visivel, setVisivel] = useState(false);
    const listagemRef = useRef(null);

    const statusBodyTemplate = (rowData) => {
        if (rowData.validar) return <Tag severity="success" value="SIM" />;
        return <Tag severity="danger" value="NÃO" />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "8%" },
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "25%" },
        },
        {
            field: "dominio",
            header: "Domínio",
        },
        {
            field: "validar",
            header: "Valida?",
            style: { width: "12%" },
            action: (e) => statusBodyTemplate(e),
        },
        {
            field: "regex",
            header: "Regex",
        },
        {
            field: "ordem",
            header: "Ordem",
        },
        {
            field: "mascara",
            header: "Máscara",
        },
    ];

    useImperativeHandle(ref, () => ({ setVisivel }));

    return (
        <Dialog
            header={"Tipos de Dados"}
            visible={visivel}
            breakpoints={{ "1080px": "90vw" }}
            style={{ width: "80vw", display: "block" }}
            onHide={() => setVisivel(false)}
        >
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa="/crediario/tipos-dado/"
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </Dialog>
    );
};

export const TipoDadoModal = forwardRef(Modal);
