import React, { forwardRef, useImperativeHandle, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { Button } from "primereact/button";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { Dropdown } from "@/components/Dropdown";

import { TIPO_APURACAO_COMISSAO_CHOICE } from "@/assets/constants/constants";
import { Dialog } from "primereact/dialog";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Label } from "@/components/Label";

const INITIAL_VALUES = {
    apuracao_comissao__tipo_apuracao: null,
    apuracao_comissao__isnull: null,
    total_comissao__lte: null,
    total_comissao__gte: null,
    item_venda__sku: null,
};

const OPTIONS_APURACAO = [
    { value: false, label: "Apurada" },
    { value: true, label: "Não apurado" },
];

export const Modal = ({ onConfirm = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);
    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                item_venda__sku: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'produto' válido"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtros = {};
            const total = totalizadorFiltrosAplicados(INITIAL_VALUES, dadosValidados);
            if (total) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key] || typeof dadosValidados[key] === "boolean") {
                        if (key === "item_venda__sku") return (filtros[key] = dadosValidados[key].id);
                        return (filtros[key] = dadosValidados[key]);
                    }
                });
                onConfirm({ filtros, total });
            } else onConfirm({ filtros, total });
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            header="Filtro avançado"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw" }}
            onHide={() => setVisible(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="item_venda__sku" label="Produto" />
                        <MakoBuscaSkuPersonalizada
                            id="item_venda__sku"
                            name="item_venda__sku"
                            placeholder="Digite para pesquisar..."
                            skuValue={formik.values.item_venda__sku}
                            skuChange={(e) => setFieldValue("item_venda__sku", e)}
                            skuError={formik.errors.item_venda__sku}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="total_comissao__gte" label="Valor mínimo" />
                        <MakoInputMoeda
                            id="total_comissao__gte"
                            name="total_comissao__gte"
                            valueMoeda={formik.values.total_comissao__gte}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.total_comissao__gte })}
                        />
                        {formik.errors.total_comissao__gte && (
                            <small className="p-error">{formik.errors.total_comissao__gte}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="total_comissao__lte" label="Valor máximo" />
                        <MakoInputMoeda
                            id="total_comissao__lte"
                            name="total_comissao__lte"
                            valueMoeda={formik.values.total_comissao__lte}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.total_comissao__lte })}
                        />
                        {formik.errors.total_comissao__lte && (
                            <small className="p-error">{formik.errors.total_comissao__lte}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="apuracao_comissao__isnull" label="Apuração" />
                        <Dropdown
                            id="apuracao_comissao__isnull"
                            name="apuracao_comissao__isnull"
                            placeholder="Selecione"
                            options={OPTIONS_APURACAO}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.apuracao_comissao__isnull}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.apuracao_comissao__isnull })}
                        />
                        {formik.errors.apuracao_comissao__isnull && (
                            <small className="p-error">{formik.errors.apuracao_comissao__isnull}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="apuracao_comissao__tipo_apuracao" label="Tipo de comissão" />
                        <Dropdown
                            id="apuracao_comissao__tipo_apuracao"
                            name="apuracao_comissao__tipo_apuracao"
                            placeholder="Selecione"
                            options={TIPO_APURACAO_COMISSAO_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.apuracao_comissao__tipo_apuracao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.apuracao_comissao__tipo_apuracao })}
                        />
                        {formik.errors.apuracao_comissao__tipo_apuracao && (
                            <small className="p-error">{formik.errors.apuracao_comissao__tipo_apuracao}</small>
                        )}
                    </div>
                </div>
                <MakoActionsButtons className="p-jc-end">
                    <Button type="submit" icon={MAKO_ICONS.FILTRAR} label="Filtrar" />
                    <Button
                        type="reset"
                        icon={MAKO_ICONS.LIMPAR_FILTROS}
                        label="Limpar"
                        className="p-button-warning"
                        onClick={formik.resetForm}
                    />
                    <Button
                        type="reset"
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        onClick={() => setVisible(false)}
                        className="p-button-danger"
                    />
                </MakoActionsButtons>
            </form>
        </Dialog>
    );
};

export const ModalFiltroAvancado = forwardRef(Modal);
