import { useCallback } from "react";

import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/financeiro/caixas/{caixa}/pendencias/{pendencia}/desprocessar";

const useDesprocessarPendenciaCaixa = (caixa_movimento_id) => {
    const { showError } = useToast();
    const { httpPost } = useHttp();

    if (!caixa_movimento_id) throw new Error("Informe o id do caixa");

    const handleDesprocessar = useCallback(
        async ({ pendencia_id, tipo_chave, extras = {} }) => {
            const url = BASE_URL.replace("{caixa}", caixa_movimento_id).replace("{pendencia}", pendencia_id);
            let dados = null;
            const handlers = {
                200: ({ data }) => (dados = data?.dados),
                400: ({ err }) => {
                    showError({
                        summary: "Alerta!",
                        detail: err?.msg || "Erro ao desprocessar pendência ao caixa",
                        life: 3000,
                    });
                },
            };
            await httpPost({ url, body: { tipo_chave, ...extras } }, handlers);
            return dados;
        },
        [caixa_movimento_id, httpPost, showError]
    );

    return handleDesprocessar;
};

export default useDesprocessarPendenciaCaixa;
