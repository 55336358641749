import React, { useState, useRef } from "react";

import { Dropdown } from "primereact/dropdown";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";

import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/configuracoes/api-servicos/";

const opcoesPesquisa = [
    { id: "todos", label: "Todos", value: "" },
    { id: "ativos", label: "Ativos", value: "true" },
    { id: "inativos", label: "Inativos", value: "false" },
];

export const ApiServicosPage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [filtro, setFiltro] = useState("");

    const [apiServico, setApiServico] = useState(null);
    const { showSuccess } = useToast();
    const { httpDelete } = useHttp();
    const listagemRef = useRef(null);

    const confirmarDelete = (apiServico) => {
        setApiServico(apiServico);
        setDeleteDialog(true);
    };

    const deleteParametro = async () => {
        let ok = false;
        const handlers = {
            204: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "API serviços deletada com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
                ok = true;
            },
        };
        await httpDelete({ url: `${BASE_URL}${apiServico.id}` }, handlers);
        return ok;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.ROOT]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de api de serviços"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/configuracoes/api-servicos/form",
                        state: { apiServico: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.ROOT]}
                    componente={Button}
                    icon={MAKO_ICONS.DELETE}
                    className="p-button-rounded p-button-danger"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar API de serviços"
                    tooltipOptions={{ position: "left" }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const filtroStatus = (status) => {
        listagemRef.current.filter(status.value, "ativo", "contains");
        setFiltro(status.value);
    };

    const valorFixo = (values) => {
        if (values.valor_fixo) return "Sim";
        return "Não";
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.ROOT]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/configuracoes/api-servicos/form"}
            />
            <Dropdown
                id="opcoesPesquisa"
                name="opcoesPesquisa"
                style={{ width: "120px" }}
                value={filtro}
                options={opcoesPesquisa}
                optionLabel="label"
                placeholder={!filtro ? ("" ? filtro : "Todos") : filtro ? "Ativos" : false ? filtro : "Inativos"}
                onChange={filtroStatus}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "8%" } },
        { field: "descricao", header: "Descrição", style: { minWidth: "20%" } },
        { field: "provedor.nome", header: "Provedor", style: { minWidth: "20%" } },
        { field: "tipo_servico.nome", header: "Tipo de serviço", style: { minWidth: "20%" } },
        { field: "data_inicio", header: "Data início", dateFormat: "dd/MM/yyyy", style: { minWidth: "10%" } },
        { field: "valor_fixo", header: "Valor fixo", action: (e) => valorFixo(e), style: { minWidth: "10%" } },
        { field: "valor_mensal", header: "Valor mensal", money: true, style: { minWidth: "10%" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            align: "center",
            style: { minWidth: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="API de Serviços"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={BASE_URL}
                responsiva
                filtarPorEmpresa
                naoBuscarSemEmpresa
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                }}
            />
            <MakoConfirmDialog
                visible={deleteDialog}
                setVisible={setDeleteDialog}
                message={
                    <span>
                        {"Deseja realmente excluir a api de serviços "}
                        <b>{apiServico?.descricao}</b>?
                    </span>
                }
                accept={deleteParametro}
                showErrorToast={false}
            />
        </PageBase>
    );
};
