import React from "react";
import { useHistory } from "react-router-dom";

import { TemplateRateioFormBase } from "./formBase/index";
import { PageBase } from "@/components/PageBase";

export const FinanceiroTemplateRateioForm = (props) => {
    const history = useHistory();

    return (
        <PageBase>
            <TemplateRateioFormBase
                propsButtonCancelar={{
                    icon: "pi pi-angle-double-left",
                    label: "Voltar",
                    onClick: () => history.push("/financeiro/financeiro/templates-rateios"),
                }}
                {...props}
            />
        </PageBase>
    );
};
