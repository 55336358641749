import { Dropdown } from "@/components/Dropdown";

export const DropdownFiltroTemplate = ({ dropdownProps, options }) => {
    return (
        <Dropdown
            {...dropdownProps}
            value={options.value}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            className={`p-column-filter ${dropdownProps?.className}`}
        />
    );
};
