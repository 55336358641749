import React, { useState, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";

import { RegraArredondamentoValorForm } from "./regraArredondamentoValor";
import { DetalheRegraArredondamentoValorForm } from "./detalheRegraArredondamentoValor";
import { PageBase } from "@/components/PageBase";

export const TabRegraArredondamentoValor = (props) => {
    const [regraArredondamentoValor, setRegraArredondamentoValor] = useState(null);

    useEffect(() => {
        if (props.location.state) {
            setRegraArredondamentoValor(props.location.state);
        }
    }, [props]);

    return (
        <PageBase>
            <h3>
                {!regraArredondamentoValor
                    ? "Nova regra de arredondamento de preços"
                    : "Manutenção de regra de arredondamento de preços"}
            </h3>
            <TabView>
                <TabPanel header="Faixa de valores">
                    <RegraArredondamentoValorForm
                        data={regraArredondamentoValor}
                        aposSalvar={(e) => setRegraArredondamentoValor(e)}
                    />
                </TabPanel>
                <TabPanel header="Terminações possíveis" disabled={!!!regraArredondamentoValor}>
                    <DetalheRegraArredondamentoValorForm regraArredondamentoValor={regraArredondamentoValor} />
                </TabPanel>
            </TabView>
        </PageBase>
    );
};
