import React, { useCallback, useState } from "react";

import classNames from "classnames";

import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";

const BASE_VALUES = {
    descontos_percent: 0,
    descontos: 0,
    valor: 0,
};

const TIPOS_DESCONTO = {
    PERCENT: "percent",
    VALOR: "VALOR",
};

export const InputDesconto = ({ values = BASE_VALUES, errors, setFieldValue = () => {} }) => {
    const [tipoDesconto, setTipoDesconto] = useState(TIPOS_DESCONTO.PERCENT);

    const calcularDescontosPercent = useCallback(() => {
        if (values.descontos_percent > 0) {
            // if (formik.values.descontos_percent <= formik.values.percent_descontos) {
            const descontos = values.valor * (values.descontos_percent / 100);
            setFieldValue("descontos", descontos);
            setTipoDesconto(TIPOS_DESCONTO.VALOR);
            // } else {
            //     setFieldError("descontos_percent", "Taxa de desconto não permitida.");
            // }
        }
    }, [
        values.descontos_percent,
        // formik.values.percent_descontos,
        values.valor,
        // setFieldError,
        setFieldValue,
    ]);

    return (
        <div className="p-inputgroup">
            {tipoDesconto === "percent" ? (
                <>
                    <InputNumber
                        inputId="total-descontos"
                        name="descontos_percent"
                        suffix=" %"
                        minFractionDigits={2}
                        value={values.descontos_percent}
                        onChange={(e) => setFieldValue("descontos_percent", e.value)}
                        onBlur={calcularDescontosPercent}
                        className={classNames({ "p-invalid": errors?.descontos_percent })}
                    />
                    <Button
                        icon="pi pi-dollar"
                        type="button"
                        className="p-button-help"
                        tooltip="Conceder desconto por valor"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => setTipoDesconto(TIPOS_DESCONTO.VALOR)}
                    />
                </>
            ) : (
                <>
                    <MakoInputMoeda
                        id="total-descontos"
                        name="descontos"
                        valueMoeda={values.descontos}
                        onChange={(e) => setFieldValue("descontos", e.value)}
                        className={classNames({ "p-invalid": errors?.descontos_percent })}
                    />
                    <Button
                        icon="pi pi-percentage"
                        type="button"
                        tooltip="Conceder desconto por porcentagem"
                        tooltipOptions={{ position: "top" }}
                        className="p-button-help"
                        onClick={() => setTipoDesconto(TIPOS_DESCONTO.PERCENT)}
                    />
                </>
            )}
        </div>
    );
};
