import React from "react";
import { InputText } from "primereact/inputtext";
import { dataToStr } from "@/assets/util/datas";
import { parseMoeda } from "@/assets/util/util";
import { STATUS_DEVOLUCAO_FORNECEDOR } from "@/assets/constants/compras";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";
import { Panel } from "primereact/panel";

export const PainelResumoDevolucao = () => {
    const [formatarDocumento] = useFormatCNPJCPF();
    const { dadosBasicos } = useDevolucaoFornecedor();

    const formatarFornecedor = (fornecedor) => {
        if (!fornecedor) return "-";
        const { nome, identificacao } = fornecedor;
        return `${nome} ${formatarDocumento(identificacao)}`;
    };

    const formatarData = (data) => {
        if (!data) return "-";
        return dataToStr(data, "dd/MM/yyyy");
    };

    const formatarValor = (valor) => {
        if (!valor) return "0.00";
        return parseMoeda(valor);
    };

    const formatarSituacao = (situacao) => {
        if (!situacao) return "N/A";
        const status = STATUS_DEVOLUCAO_FORNECEDOR.find((s) => s.value === situacao);
        if (!status) return "N/A";
        return status.label;
    };

    return (
        <Panel header="Informações da devolução" toggleable className="p-mb-4">
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-1">
                    <label htmlFor="id">Número</label>
                    <InputText id="id" name="id" value={dadosBasicos?.id} disabled />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="fornecedor">Fornecedor</label>
                    <InputText
                        id="fornecedor"
                        name="fornecedor"
                        value={formatarFornecedor(dadosBasicos?.fornecedor)}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-dadosBasicos">Data da devolução</label>
                    <InputText
                        id="data-dadosBasicos"
                        name="data_devolucao"
                        value={formatarData(dadosBasicos?.data_devolucao)}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-dadosBasicos">Valor total</label>
                    <InputText
                        id="valor-dadosBasicos"
                        name="valor_devolucao"
                        value={formatarValor(dadosBasicos?.valor_total)}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <label htmlFor="situacao">Situação</label>
                    <InputText id="situacao" name="situacao" value={formatarSituacao(dadosBasicos?.status)} disabled />
                </div>
            </div>
        </Panel>
    );
};
