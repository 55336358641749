export const TIPO_NOTIFICACAO_CHOICE = [
    { value: "NO", label: "Notificação", _severity: "info" },
    { value: "AP", label: "Requer aprovação", _severity: "warning" },
    { value: "AS", label: "Requer senha", _severity: "danger" },
];

export const STATUS_NOTIFICACAO_CHOICE = [
    { value: "P", label: "Pendente", _severity: "info" },
    { value: "V", label: "Vencida", _severity: "warning" },
    { value: "E", label: "Escalada", _severity: "secondary" },
    { value: "A", label: "Aprovada", _severity: "success" },
    { value: "R", label: "Reprovada", _severity: "danger" },
    { value: "F", label: "Finalizada", _severity: "success" },
];
