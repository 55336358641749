import React, { useCallback } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { PageBase } from "@/components/PageBase";

import { Form } from "../features";

const URL_LISTAGEM = "/compras/cadastros/tipo-devolucao/";

export const TipoDevolucaoForm = () => {
    const { state } = useLocation();
    const history = useHistory();

    const voltar = useCallback(() => {
        history.push(URL_LISTAGEM);
    }, [history]);

    return (
        <PageBase>
            <h5>Cadastro de Tipo Devolução</h5>
            <Form tipoDevolucao={state?.tipo} onSuccessCallback={voltar} />
        </PageBase>
    );
};
