import { useCallback, useMemo, useState } from "react";
import useLocalStorage from "use-local-storage";

export const useLocalFiltro = (key, initialValue) => {
    const useFiltro = !key ? useState : useLocalStorage;
    const [filtros, setValue] = useFiltro(!key ? initialValue : key, !key ? null : initialValue);

    const updateFiltro = useCallback(
        (values) => {
            setValue((prev) => {
                if (typeof values === "function") {
                    return values(prev);
                }
                if (typeof values !== "object") return values;
                let data = {};
                Object.keys(values).forEach((key) => {
                    if (values[key] instanceof Array) {
                        if (values[key]?.length) data[key] = values[key];
                    } else if (values[key] !== null || values[key] !== "" || typeof values[key] === "number")
                        data[key] = values[key];
                });
                return data;
            });
        },
        [setValue]
    );

    const removerFiltro = () => {
        setValue(initialValue);
    };

    const filtroString = useMemo(() => {
        if (!filtros) return "";
        let _filtros = [];
        Object.keys(filtros).map((key) => _filtros.push(`${key}=${filtros[key]}`));
        return _filtros.join("&");
    }, [filtros]);

    return [filtros, updateFiltro, removerFiltro, filtroString];
};
