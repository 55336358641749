import { useContext } from "react";

import AuthContext from "@/contexts/authContext";

const useAuth = () => {
    const { signed, user, signIn, signOut, verifyPermission, reloadPermissions, token } = useContext(AuthContext);

    return { signed, user, signIn, signOut, verifyPermission, reloadPermissions, token };
};

export default useAuth;
