export const TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE = [
    { id: 0, label: "Dinheiro" },
    { id: 1, label: "Cartão de crédito" },
    { id: 2, label: "Cartão de débito" },
    { id: 3, label: "Pix" },
    { id: 4, label: "Cheque" },
    { id: 5, label: "Boleto" },
    { id: 6, label: "DDA" },
    { id: 7, label: "Transferência" },
    { id: 8, label: "Carnê" },
    { id: 9, label: "Voucher" },
    { id: 10, label: "Cred/deb em conta" },
    { id: 11, label: "Depósito" },
];

export const EXTENSOES_ARQUIVOS_IMPORTACAO_EXTRATO = [
    { value: "csv", label: "CSV" },
    { value: "txt", label: "TXT" },
    { value: "ofx", label: "OFX" },
    { value: "ofc", label: "OFC" },
];

export const NATUREZA_FINANCEIRA = [
    { value: "D", label: "Débito" },
    { value: "C", label: "Crédito" },
];

export const NATUREZA_MOV_FINANCEIRA_CHOICE = [
    { value: "S", label: "Saída" },
    { value: "E", label: "Entrada" },
];

export const STATUS_LOTE_BOLETO = [
    { value: "P", label: "Pendente" },
    { value: "G", label: "Gerado" },
    { value: "E", label: "Enviado" },
    { value: "C", label: "Cancelado" },
];

export const ACEITE_BOLETO_BANCARIO = [
    { value: "S", label: "Aceito" },
    { value: "N", label: "Não aceito" },
];

export const TIPO_HISTORICO_PADRAO = [
    { value: "P", label: "Pagamentos" },
    { value: "R", label: "Recebimentos" },
];

export const TIPOS_PARCELAS = [
    { value: "dividir", label: "Dividir valor do lançamento entre as parcelas." },
    { value: "multiplicar", label: "Multiplicar valor do lançamento entre as parcelas." },
];

export const REPETICAO_DIAS_PARCELAS = [
    { value: "7", label: "Semanal" },
    { value: "15", label: "Quinzenal" },
    { value: "30", label: "Mensal" },
    { value: "90", label: "Trimestral" },
    { value: "180", label: "Semestral" },
    { value: "365", label: "Anual" },
];

export const FILTRO_INDICADORES = [
    { value: "hoje", label: "Hoje" },
    { value: "semana", label: "Semana atual" },
    { value: "+semana", label: "Próxima  semana" },
    { value: "-mes", label: "Mês passado" },
    { value: "mes", label: "Mês atual" },
    { value: "+mes", label: "Próximo mês" },
    { value: "todos", label: "Todos" },
];

export const TIPO_DETALHE_RATEIO = [
    { value: 1, label: "Conta Contábil" },
    { value: 2, label: "Conta Financeira" },
    { value: 3, label: "Centro de Resultados" },
    { value: 4, label: "Fase de Projeto" },
];

export const TIPO_CARTAO_CHOICE = [
    { value: "D", label: "Débito" },
    { value: "R", label: "Crédito rotativo" },
    { value: "P", label: "Crédito parcelado" },
];

export const TIPO_CHAVE_PENDENCIAS = {
    VENDA_CREDIARIO: "venda_crediario",
    VENDA_BOLETO: "venda_boleto",
    VENDA_CARTAO: "venda_cartao",
    VENDA_ENTRADA: "venda_entrada",
    RECEBIMENTO_CREDIARIO: "recebimento_crediario",
    PAGAMENTO: "pagamento",
};
