import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { Dropdown } from "@/components/Dropdown";
import { Dialog } from "primereact/dialog";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { classNames } from "primereact/utils";

const INITIAL_VALUES = {
    id: null,
    perfil_titular__nome: null,
    status: null,
};

export const Modal = ({ onConfirm, url, filtros, setFiltro, removerFiltro, setTotalizadorFiltros }, ref) => {
    const [visible, setVisible] = useState(false);
    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: filtros ? { ...INITIAL_VALUES, ...filtros } : INITIAL_VALUES,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil_titular__nome: Yup.object().notRequired().nullable().typeError("Selecione um cliente válido."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const baseUrl = url;
            const params = [];
            let _filtros = {};

            Object.keys(values).map((key) => {
                if (values[key] || typeof values[key] === "boolean") {
                    if (key === "perfil_titular__nome") {
                        if (typeof values[key] === "object") {
                            _filtros[key] = values[key].nome;
                            return params.push(`${key}=${values[key].nome}`);
                        }
                    }
                    _filtros[key] = values[key];
                    return params.push(`${key}=${values[key]}`);
                }
            });

            if (params.length > 0) {
                const _url = baseUrl + "?" + params.join("&");
                setFiltro(_filtros);
                if (typeof onConfirm === "function")
                    onConfirm(_url, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            } else {
                if (typeof onConfirm === "function")
                    onConfirm(baseUrl, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            }
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    const limparFiltrosForm = () => {
        resetForm();
    };

    const limparFiltro = () => {
        removerFiltro();
        limparFiltrosForm();
    };

    useImperativeHandle(ref, () => ({ abrirModal, limparFiltro }));

    useEffect(() => {
        if (filtros) setTotalizadorFiltros(totalizadorFiltrosAplicados(INITIAL_VALUES, filtros));
    }, [filtros, setTotalizadorFiltros]);

    return (
        <Dialog
            header="Histórico de crediário"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw", display: "block" }}
            onHide={() => setVisible(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="perfil_titular__nome">Localizar cliente</label>
                        <MakoInputCliente
                            id="perfil_titular__nome"
                            name="perfil_titular__nome"
                            value={formik.values.perfil_titular__nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.perfil_titular__nome })}
                        />
                        {formik.errors.perfil_titular__nome && (
                            <small className="p-error">{formik.errors.perfil_titular__nome}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="status">Situação do crediário</label>
                        <Dropdown
                            id="status"
                            name="status"
                            placeholder="Todos"
                            options={[
                                { value: "P", label: "Pendente" },
                                { value: "I", label: "Incompleto" },
                                { value: "R", label: "Reprovado" },
                                { value: "A", label: "Aprovado" },
                            ]}
                            optionLabel="label"
                            optionValue="value"
                            showClear
                            value={formik.values.status}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <br />
                <div className="p-grid p-justify-end p-mt-2">
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => setVisible(false)}
                        className="p-button-danger p-mr-2 p-mb-2"
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={() => limparFiltro()}
                        className="p-button-warning p-mr-2 p-mb-2"
                    />
                    <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-3 p-mb-2" />
                </div>
            </form>
        </Dialog>
    );
};

export const FiltroAvancadoCrediario = forwardRef(Modal);
