import React from "react";

import { TabOrdemCompra } from "./tab";
import { OrdemCompraProvider } from "@/contexts/ordemCompraContext";

export const TabOrdemCompraContext = (props) => {
    return (
        <OrdemCompraProvider>
            <TabOrdemCompra ordemCompraId={props.location.state?.ordemCompraId} />
        </OrdemCompraProvider>
    );
};
