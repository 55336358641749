import React from "react";
import { InputNumber } from "primereact/inputnumber";

import { parseNumber } from "@/assets/helpers/number";

export const MakoInputCubagem = ({ value, onValueChange, ...props }) => {
    const MAX_DIGITOS_DECIMAIS = 6;

    return (
        <InputNumber
            minFractionDigits={MAX_DIGITOS_DECIMAIS}
            maxFractionDigits={MAX_DIGITOS_DECIMAIS}
            {...props}
            mode="decimal"
            min={parseNumber(props?.min) > 0 ? props.min : 0}
            value={value}
            onValueChange={onValueChange}
        />
    );
};
