import React from "react";

import { useLocation } from "react-router-dom";

import { TabFormula } from "./tab";

export const TabSaldoEstoqueFormula = () => {
    const { state } = useLocation();
    return <TabFormula formula={state?.formula} possuiCentros={state?.possui_centros} />;
};
