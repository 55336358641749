import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoErrosPersonalizados } from "@/components/MakoErrosPersonalizados";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import useLoadingLocal from "@/hooks/useLoadingLocal";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const Modal = ({ onCreate }, ref) => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { httpPatch } = useHttp();
    const modalErrosValidacaoRef = useRef(null);

    useClearRefs(modalErrosValidacaoRef);

    const { setErrors, ...formik } = useFormik({
        initialValues: {
            perfil: null,
            email: "",
            username: "",
            password: "",
            confirmpass: "",
            empresa_padrao: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil: Yup.object()
                    .typeError("Você precisa selecionar um perfil.")
                    .required("O campo 'perfil' é obrigatório."),
                email: Yup.string().required("O campo 'email' é obrigatório."),
                username: Yup.string()
                    .trim("Não pode ser em branco.")
                    .required("O campo 'nome de usuário' é obrigatório."),
                password: Yup.string()
                    .trim("Não pode ser em branco.")
                    .matches(
                        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})./,
                        "Senha fraca"
                    )
                    .required("O campo 'senha' é obrigatório."),
                confirmpass: Yup.string()
                    .required("O campo 'confirmar senha' é obrigatório.")
                    .oneOf([formik.values.password], "As senhas não são iguais."),
                empresa_padrao: Yup.number().required("O campo 'empresa padrão' é obrigatório."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const usuario = {
                perfil: values.perfil.id,
                email: values.email,
                username: values.username,
                password: values.password,
                empresa_padrao: values.empresa_padrao,
            };
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Usuário cadastrado com sucesso!",
                        life: 1500,
                    });
                    formik.resetForm();
                    setVisible(false);
                    if (typeof onCreate === "function") onCreate();
                },
                400: ({ data }) => {
                    let erros = [];
                    if (typeof data === "object") {
                        for (const [k, v] of Object.entries(data)) {
                            erros.push({ field: k, error: v });
                        }
                    }
                    modalErrosValidacaoRef.current?.abrirModal(erros);
                    showError();
                },
            };
            showLoading();
            await httpPatch({ url: `/pessoas/perfis/${values.perfil.id}/`, body: { usuario } }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const autoCompletePerfilTemplate = (row) => {
        return `${row.nome} - ${row.identificacao}`;
    };

    const abrirModal = () => {
        setVisible(true);
    };

    const listarEmailsPerfilSelecionado = useCallback(() => {
        if (!formik.values.perfil) return;
        const { emailperfil_set } = formik.values.perfil;
        setEmails(emailperfil_set);
    }, [formik.values.perfil]);

    const aposPesquisarUsuario = useCallback(
        (data) => {
            if (!data?.length) {
                setErrors({ perfil: "Usuário inexistente ou ja cadastrado!" });
            }
            return data;
        },
        [setErrors]
    );

    useEffect(() => {
        listarEmailsPerfilSelecionado();
    }, [listarEmailsPerfilSelecionado]);

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <>
            <Dialog
                header="Cadastro de usuário"
                visible={visible}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw" }}
                onHide={() => setVisible(false)}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <Label htmlFor="empresa_padrao" label="Empresa padrão" obrigatorio />
                            <MakoDropdownEmpresas
                                id="empresa_padrao"
                                name="empresa_padrao"
                                showClear={false}
                                value={formik.values.empresa_padrao}
                                onChange={(e) => formik.setFieldValue("empresa_padrao", e.id)}
                                className={classNames({ "p-invalid": formik.errors.empresa_padrao })}
                            />
                            {formik.errors.empresa_padrao && (
                                <small className="p-error">{formik.errors.empresa_padrao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12">
                            <Label htmlFor="perfil" label="Pessoa cadastrado" obrigatorio />
                            <MakoAutoComplete
                                id="perfil"
                                name="perfil"
                                placeholder="Busque pelo nome ou CPF ... (min 4 caracteres)"
                                minCaracteresBusca={3}
                                urlSearch={
                                    "/pessoas/perfis?query={id,nome,identificacao,emailperfil_set}&tipo_pessoa__isnot=PJ&usuario__isnull=true&search="
                                }
                                field="nome"
                                aposPesquisar={aposPesquisarUsuario}
                                value={formik.values.perfil}
                                onChange={formik.handleChange}
                                itemTemplate={autoCompletePerfilTemplate}
                                className={classNames({ "p-invalid": formik.errors.perfil })}
                            />
                            {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <Label htmlFor="email" label="Email" obrigatorio />
                            <Dropdown
                                id="email"
                                name="email"
                                options={emails}
                                disabled={formik.values.perfil ? false : true}
                                optionValue="email"
                                optionLabel="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.email && <small className="p-error">{formik.errors.email}</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <Label htmlFor="username" label="Nome de usuário" obrigatorio />
                            <InputText
                                id="username"
                                name="username"
                                disabled={formik.values.perfil ? false : true}
                                autoComplete="off"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.username })}
                            />
                            {formik.errors.username && <small className="p-error">{formik.errors.username}</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <Label htmlFor="password" label="Senha" obrigatorio />
                            <Password
                                id="password"
                                name="password"
                                toggleMask
                                mediumRegex
                                disabled={formik.values.perfil ? false : true}
                                promptLabel="Informe a senha"
                                weakLabel="Fraca"
                                mediumLabel="Média"
                                strongLabel="Forte"
                                autoComplete="off"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.password })}
                            />
                            {formik.errors.password && <small className="p-error">{formik.errors.password}</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <Label htmlFor="confirmpass" label="Confirmar senha" obrigatorio />
                            <Password
                                id="confirmpass"
                                name="confirmpass"
                                feedback={false}
                                disabled={formik.values.perfil ? false : true}
                                autoComplete="off"
                                value={formik.values.confirmpass}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.confirmpass })}
                            />
                            {formik.errors.confirmpass && (
                                <small className="p-error">{formik.errors.confirmpass}</small>
                            )}
                        </div>
                    </div>
                    <MakoActionsButtons>
                        <Button type="submit" icon={MAKO_ICONS.GRAVAR} label="Gravar" loading={loading} />
                    </MakoActionsButtons>
                </form>
            </Dialog>
            <MakoErrosPersonalizados
                colunas={[
                    { field: "field", header: "Campo" },
                    { field: "error", header: "Mensagem" },
                ]}
                ref={modalErrosValidacaoRef}
            />
        </>
    );
};

export const ModalCadastroUsuario = forwardRef(Modal);
