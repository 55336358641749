import React, { useMemo } from "react";

import { parseNumber } from "@/assets/helpers/number";
import { parseMoeda } from "@/assets/util/util";

export const Totais = ({ valido = false, parcelas = [] }) => {
    const totalParcelas = useMemo(() => {
        return parcelas.reduce((total, receb) => total + parseNumber(receb.valor), 0);
    }, [parcelas]);

    return (
        <div className="p-mt-3">
            <div className={`p-text-right ${!valido && "p-error"}`}>
                <h6>{`Total das parcelas: ${parseMoeda(totalParcelas, true)}`}</h6>
            </div>
            <div className="p-text-right">
                <h6>{`Total esperado: ${parseMoeda(parcelas[0]?.valor_total, true)}`}</h6>
            </div>
        </div>
    );
};
