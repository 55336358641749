import { useContext } from "react";
import ContratoServicoContext from "@/contexts/contratoServicoContext";

const useContratoServico = () => {
    const { contrato, servicos, updateContrato, resetServicos, persistirContrato, updateServicos, atualizarValorContrato } =
        useContext(ContratoServicoContext);

    return { contrato, servicos, updateContrato, resetServicos, persistirContrato, updateServicos, atualizarValorContrato };
};

export default useContratoServico;
