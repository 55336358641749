import React, { useCallback, useMemo, useState } from "react";

import { classNames } from "primereact/utils";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";
import useToast, { DEFAULT_ERROR_TOAST } from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

const { VENDAS_VENDA_ENTREGASREALIZADAS_CANCELAR } = permissoes;

const BASE_URL = "/vendas/previsoes-entregas-itens-vendas/{id}/cancelar";

export const Cancelar = ({ previsaoId, successCallback = () => {}, className = "" }) => {
    const [loading, show, hide] = useLoadingLocal();
    const [visible, setVisible] = useState(false);
    const { httpPut } = useHttp();
    const { showSuccess, showError } = useToast();

    const handler = useCallback(async () => {
        const url = BASE_URL.replace("{id}", previsaoId);
        const handlers = {
            200: ({ data }) => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Entrega cancelada com sucesso!",
                    life: 1500,
                });
                successCallback(data);
            },
            409: ({ err }) =>
                showError({
                    ...DEFAULT_ERROR_TOAST,
                    detail: err.msg,
                }),
        };

        show();
        await httpPut({ url }, handlers);
        hide();
    }, [hide, previsaoId, show, showError, showSuccess, successCallback, httpPut]);

    const message = useMemo(() => {
        return (
            <span>
                Deseja realmente cancelar a entrega <b>{previsaoId}</b> ?
            </span>
        );
    }, [previsaoId]);

    return (
        <>
            <MakoControleAcesso
                componente={Button}
                permissao={[VENDAS_VENDA_ENTREGASREALIZADAS_CANCELAR]}
                tooltip="Cancelar"
                tooltipOptions={{ position: "left" }}
                icon={MAKO_ICONS.DESFAZER}
                className={classNames("p-button-danger p-button-rounded", className)}
                disabled={!previsaoId || loading}
                onClick={() => setVisible(true)}
            />
            <MakoConfirmDialog
                visible={visible}
                setVisible={setVisible}
                showErrorToast={false}
                message={message}
                accept={handler}
            />
        </>
    );
};
