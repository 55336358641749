import React, { forwardRef, useImperativeHandle, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { Button } from "primereact/button";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoInputPeriodo } from "@/components/MakoInputs/MakoInputPeriodo";
import { Dropdown } from "@/components/Dropdown";

import { SITUACAO_VENDA_CHOICE } from "@/assets/constants/constants";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { Dialog } from "primereact/dialog";
import { dataToStr } from "@/assets/util/datas";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";

const INITIAL_VALUES = {
    cliente__id: null,
    data__gte: null,
    data__lte: null,
    situacao: null,
    estagio_venda: null,
    itemvenda__sku: null,
    plano_recebimento: null,
    protocolocrediario__isnull: null,
    notafiscal__isnull: null,
    fluxo_incompleto: null,
    estagio_venda__isnull: null,
    ordering: null,
};

const OPTIONS_ORDENACAO = [
    { value: "data", label: "Data (Crescente)" },
    { value: "-data", label: "Data (Descrescente)" },
    { value: "cliente__nome", label: "Nome cliente (Crescente)" },
    { value: "-cliente__nome", label: "Nome cliente (Descrescente)" },
    { value: "sequencia_estagio_venda__sequencia", label: "Estágio (Crescente)" },
    { value: "-sequencia_estagio_venda__sequencia", label: "Estágio (Descrescente)" },
];

export const Modal = ({ onConfirm, url, filtros, setFiltro, removerFiltro }, ref) => {
    const [visible, setVisible] = useState(false);
    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: filtros ? { ...INITIAL_VALUES, ...filtros } : INITIAL_VALUES,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                cliente__id: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'cliente' válida"),
                data__gte: Yup.date().nullable().typeError("Informe uma 'data inicial' válida"),
                data__lte: Yup.date().nullable().typeError("Informe uma 'data final' válida"),
                situacao: Yup.string().nullable().typeError("Informe uma 'situacao' válida"),
                estagio_venda: Yup.number().nullable().typeError("Informe um 'estágio' válido."),
                itemvenda__sku: Yup.object()
                    .shape({
                        id: Yup.number().nullable(),
                    })
                    .nullable()
                    .typeError("Informe um 'produto' válido"),
                plano_recebimento: Yup.number().nullable().typeError("Informe um 'plano de recebimento' válido."),
                protocolocrediario__isnull: Yup.boolean().nullable().typeError("Informe uma opção válida"),
                notafiscal__isnull: Yup.boolean().nullable().typeError("Informe uma opção válida"),
                fluxo_incompleto: Yup.boolean().nullable().typeError("Informe uma opção válida"),
                estagio_venda__isnull: Yup.boolean().nullable().typeError("Informe uma opção válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            if (dadosValidados.cliente__id) dadosValidados.cliente__id = dadosValidados.cliente__id.id;
            if (dadosValidados.itemvenda__sku) dadosValidados.itemvenda__sku = dadosValidados.itemvenda__sku.id;

            const baseUrl = url;
            const params = [];
            let _filtros = {};

            Object.keys(dadosValidados).forEach((key) => {
                if (dadosValidados[key] || typeof dadosValidados[key] === "boolean") {
                    if (["data__gte", "data__lte"].includes(key)) {
                        const data = dataToStr(dadosValidados[key], "yyyy-MM-dd");
                        _filtros[key] = data;
                        params.push(`${key}=${data}`);
                    } else {
                        _filtros[key] = dadosValidados[key];
                        params.push(`${key}=${dadosValidados[key]}`);
                    }
                }
            });
            if (params.length > 0) {
                const _url = baseUrl + "&" + params.join("&");
                setFiltro(_filtros);
                if (typeof onConfirm === "function")
                    onConfirm(_url, totalizadorFiltrosAplicados(INITIAL_VALUES, dadosValidados));
            } else {
                if (typeof onConfirm === "function")
                    onConfirm(
                        `${baseUrl}&situacao__in=P,F&ocultar_finalizado=true`,
                        totalizadorFiltrosAplicados(INITIAL_VALUES, dadosValidados)
                    );
            }
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    const limparFiltrosForm = () => {
        formik.resetForm();
    };

    const limparFiltro = () => {
        removerFiltro();
        limparFiltrosForm();
    };

    useImperativeHandle(ref, () => ({ abrirModal, limparFiltro }));

    return (
        <Dialog
            header="Filtro avançado"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw" }}
            onHide={() => setVisible(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cliente">Cliente</label>
                        <MakoInputCliente
                            id="cliente"
                            name="cliente__id"
                            value={formik.values.cliente__id}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cliente__id })}
                        />
                        {formik.errors.cliente__id && <small className="p-error">{formik.errors.cliente__id}</small>}
                    </div>
                    <MakoInputPeriodo
                        label="Periodo"
                        nameInicio="data__gte"
                        nameFinal="data__lte"
                        valueInicio={formik.values.data__gte}
                        valueFinal={formik.values.data__lte}
                        errorInicio={formik.errors.data__gte}
                        errorFinal={formik.errors.data__lte}
                        onChangeInicio={formik.handleChange}
                        onChangeFinal={formik.handleChange}
                    />
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="situacao">Situação</label>
                        <Dropdown
                            id="situacao"
                            name="situacao"
                            placeholder="Selecione"
                            options={SITUACAO_VENDA_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.situacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.situacao })}
                        />
                        {formik.errors.situacao && <small className="p-error">{formik.errors.situacao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="estagio_venda">Estágio</label>
                        <Dropdown
                            id="estagio_venda"
                            name="estagio_venda"
                            placeholder="Selecione"
                            url="/vendas/tipos-estagios-vendas/?ativo=true"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.estagio_venda}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.estagio_venda })}
                        />
                        {formik.errors.estagio_venda && (
                            <small className="p-error">{formik.errors.estagio_venda}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="itemvenda__sku">Produto</label>
                        <MakoBuscaSkuPersonalizada
                            id="itemvenda__sku"
                            name="itemvenda__sku"
                            placeholder="Digite para pesquisar..."
                            skuValue={formik.values.itemvenda__sku}
                            skuChange={(e) => setFieldValue("itemvenda__sku", e)}
                            skuError={formik.errors.itemvenda__sku}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="plano_recebimento">Plano de recebimento</label>
                        <Dropdown
                            id="plano_recebimento"
                            name="plano_recebimento"
                            placeholder="Selecione"
                            url="/financeiro/planos-recebimentos/?query={id,descricao}"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.plano_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.plano_recebimento })}
                        />
                        {formik.errors.plano_recebimento && (
                            <small className="p-error">{formik.errors.plano_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="protocolocrediario__isnull">Possui crediário?</label>
                        <Dropdown
                            id="protocolocrediario__isnull"
                            name="protocolocrediario__isnull"
                            placeholder="Selecione"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.protocolocrediario__isnull}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.protocolocrediario__isnull })}
                        />
                        {formik.errors.protocolocrediario__isnull && (
                            <small className="p-error">{formik.errors.protocolocrediario__isnull}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="notafiscal__isnull">Possui documento?</label>
                        <Dropdown
                            id="notafiscal__isnull"
                            name="notafiscal__isnull"
                            placeholder="Selecione"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.notafiscal__isnull}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.notafiscal__isnull })}
                        />
                        {formik.errors.notafiscal__isnull && (
                            <small className="p-error">{formik.errors.notafiscal__isnull}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="fluxo_incompleto">Fluxo incompleto?</label>
                        <Dropdown
                            id="fluxo_incompleto"
                            name="fluxo_incompleto"
                            placeholder="Selecione"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.fluxo_incompleto}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fluxo_incompleto })}
                        />
                        {formik.errors.fluxo_incompleto && (
                            <small className="p-error">{formik.errors.fluxo_incompleto}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="estagio_venda__isnull">Estágio não definido?</label>
                        <Dropdown
                            id="estagio_venda__isnull"
                            name="estagio_venda__isnull"
                            placeholder="Selecione"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.estagio_venda__isnull}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.estagio_venda__isnull })}
                        />
                        {formik.errors.estagio_venda__isnull && (
                            <small className="p-error">{formik.errors.estagio_venda__isnull}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="ordering">Ordenação</label>
                        <Dropdown
                            id="ordering"
                            name="ordering"
                            placeholder="Selecione"
                            options={OPTIONS_ORDENACAO}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.ordering}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.ordering })}
                        />
                        {formik.errors.ordering && <small className="p-error">{formik.errors.ordering}</small>}
                    </div>
                </div>
                <div className="p-grid p-justify-end p-mt-2">
                    <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={limparFiltro}
                        className="p-button-warning p-mr-2"
                    />
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => setVisible(false)}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const FiltroAvancadoVendas = forwardRef(Modal);
