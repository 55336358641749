import React, { useCallback, useRef, useState } from "react";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { TransferenciaExposicaoModalForm } from "./modal/movimentaExposicao";
import { ConfirmDialog } from "primereact/confirmdialog";
import useHttp from "@/hooks/useHttp";

export const TransferenciaExposicaoPage = () => {
    const listagemRef = useRef(null);
    const formRef = useRef(null);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [transferencia, setTransferencia] = useState(false);
    const { showWarning, showSuccess } = useToast();
    const { httpDelete } = useHttp();

    const desativarMovimentacao = useCallback(async () => {
        const handlers = {
            204: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Movimentação de mercadoria de exposição lançada com sucesso!",
                    life: 1500,
                });
                listagemRef.current?.buscarDados();
            },
            409: ({ err }) =>
                showWarning({
                    summary: "Falha",
                    detail: err.msg,
                    life: 1500,
                }),
        };

        await httpDelete({ url: `/transferencias/desativa-transferencia-exposicao/${transferencia?.id}` }, handlers);
    }, [showWarning, showSuccess, httpDelete, transferencia]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_MOVIMENTACOES_TRANSFERENCIAEXPOSICAO_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Visualizar movimentação"
                    tooltipOptions={{ position: "left" }}
                    disabled={!rowData.ativo}
                    onClick={() => formRef.current?.exibirDialog(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_MOVIMENTACOES_TRANSFERENCIAEXPOSICAO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    tooltip="Cancelar movimentação"
                    tooltipOptions={{ position: "left" }}
                    disabled={!rowData.ativo}
                    onClick={() => {
                        setTransferencia(rowData);
                        setConfirmDialog(true);
                    }}
                />
            </div>
        );
    };

    const centroFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "nome",
                    filter: true,
                    filterBy: "nome",
                    url: "/produtos/centros-estocagem?limit=1000",
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Nº",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            style: { minWidth: "60px" },
        },
        {
            field: "item.codigo",
            header: "Código",
            filterElement: CodigoFiltroTemplate,
            filter: true,
            filterField: "item__codigo",
            style: { minWidth: "80px" },
        },
        {
            field: "item.descricao_reduzida",
            header: "Descrição",
            filterElement: TextoFiltroTemplate,
            filter: true,
            filterField: "item__descricao_reduzida",
            style: { minWidth: "180px" },
        },
        {
            field: "datahora_transferencia",
            header: "Data/Hora",
            filter: true,
            filterElement: DateFiltroTemplate,
            style: { minWidth: "60px" },
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "quantidade",
            header: "Quant.",
            style: { minWidth: "60px" },
        },
        {
            field: "unidade_medida.sigla",
            header: "Un. Med.",
            style: { minWidth: "60px" },
        },
        {
            field: "ce_origem.nome",
            header: "Centro de origem",
            filterElement: centroFiltroTemplate,
            filter: true,
            filterField: "ce_origem",
            style: { minWidth: "160px" },
        },
        {
            field: "ce_destino.nome",
            header: "Centro de destino",
            filterElement: centroFiltroTemplate,
            filter: true,
            filterField: "ce_destino",
            style: { minWidth: "160px" },
        },

        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "90px" },
        },
    ];

    const rowClass = (transferencia) => {
        return {
            "table-recebimentos-overdue": !transferencia.ativo,
        };
    };

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => formRef.current?.setVisible(true)}
            />
        </>
    );

    const aposSalvar = () => {
        listagemRef.current?.buscarDados();
    };

    const fechaConfirmacao = () => {
        setConfirmDialog(false);
        setTransferencia(null);
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Mercadorias de Exposição"
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                urlPesquisa="/transferencias/transferencia-exposicao/"
                filtarPorEmpresa
                naoBuscarSemEmpresa
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    carga: { value: null, matchMode: "equals" },
                    datahora_transferencia: { value: null, matchMode: "equals" },
                    item__codigo: { value: null, matchMode: "equals" },
                    item__descricao_reduzida: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    ce_destino: { value: null, matchMode: "equals" },
                    ce_origem: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    rowClassName: rowClass,
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                }}
            />
            <TransferenciaExposicaoModalForm ref={formRef} aposSalvar={aposSalvar} />
            <ConfirmDialog
                visible={confirmDialog}
                onHide={() => fechaConfirmacao()}
                header={"Confirmação"}
                message={
                    transferencia && (
                        <span>
                            Deseja realmente cancelar a movimentação de mercadoria de exposição nº{" "}
                            <b>{transferencia.id}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={() => desativarMovimentacao()}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={() => fechaConfirmacao()}
                rejectLabel="Não"
            />
        </PageBase>
    );
};
