import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import { FiltroAvancado as F } from "../../features";
import useClearRefs from "@/hooks/useClearRefs";

const Component = ({ onSuccessCallback = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);

    const formRef = useRef(null);
    const fitrosRef = useRef({});

    useClearRefs(formRef, fitrosRef);

    const show = useCallback((filtros) => {
        setVisible(true);
        fitrosRef.current = filtros;
    }, []);

    const hide = useCallback(() => {
        setVisible(false);
    }, []);

    const onShow = useCallback(() => {
        formRef.current?.preencher(fitrosRef.current);
    }, []);

    const onSuccess = useCallback(
        (...args) => {
            hide();
            onSuccessCallback(...args);
        },
        [hide, onSuccessCallback]
    );

    const onCancel = useCallback(() => {
        hide();
    }, [hide]);

    useImperativeHandle(ref, () => ({ show, hide }), [show, hide]);

    return (
        <Dialog
            header="Filtro avaçado de lotes e seriais"
            visible={visible}
            breakpoints={{ "960px": "70vw" }}
            style={{ width: "80vw" }}
            onHide={hide}
            onShow={onShow}
        >
            <F.Form ref={formRef} successCallback={onSuccess} cancelCallback={onCancel} />
        </Dialog>
    );
};

export const FiltroAvancado = forwardRef(Component);
