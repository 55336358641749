import React from "react";

import { EntradaProvider } from "@/contexts/entradaContext";
import { TabEntradaProdutos } from "./tabs";

export const TabEntradaProdutosContext = (props) => {
    return (
        <EntradaProvider>
            <TabEntradaProdutos entradaId={props.location.state?.entradaId} />
        </EntradaProvider>
    );
};
