import React from "react";

import { ContratoServicoProvider } from "@/contexts/contratoServicoContext";
import { TabContratoServico } from "./tabs";
import { PageBase } from "@/components/PageBase";

export const ContratoServicoForm = () => {
    return (
        <PageBase>
            <ContratoServicoProvider>
                <TabContratoServico />
            </ContratoServicoProvider>
        </PageBase>
    );
};
