import React, { useMemo } from "react";

import { PESSOAS_TAGPERFIL_PADRAO_MOTORISTA } from "@/assets/constants/parametros";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useParam from "@/hooks/useParam";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputMotorista = ({ disabled, somenteAtivo, ...props }) => {
    const { getParam } = useParam();
    const [formatarDocumento] = useFormatCNPJCPF();

    const tagMotorista = useMemo(() => {
        const tagPerfil = getParam(PESSOAS_TAGPERFIL_PADRAO_MOTORISTA);
        if (tagPerfil) {
            return tagPerfil.valor;
        }
        return null;
    }, [getParam]);

    const url = useMemo(() => {
        const filtroAtivo = somenteAtivo ? "&ativo=true" : "";
        return `/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa}${filtroAtivo}&tags=${tagMotorista}&nome__unaccent__icontains=`;
    }, [somenteAtivo, tagMotorista]);

    const autoCompleteTransportadorTemplate = (item) => {
        return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
    };

    return (
        <>
            <MakoAutoComplete
                disabled={!!!tagMotorista || disabled}
                minCaracteresBusca={4}
                itemTemplate={autoCompleteTransportadorTemplate}
                field="nome"
                urlSearch={url}
                placeholder="Digite o nome do motorista para buscar... (min 4 caracteres)"
                {...props}
            />
            {!!!tagMotorista && <small className="p-error">Falta parametrizar a identificação do motorista</small>}
        </>
    );
};
