import React, { useCallback, useMemo, useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoInputNcm } from "@/components/MakoInputs/MakoInputNcm";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { InputText } from "primereact/inputtext";

import useRelatorio from "@/hooks/useRelatorio";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_PRODUTOS_RELACAOPRODUTOS } from "@/assets/constants/relatorios";
import {
    STATUS_ITEM,
    SIM_NAO_BOOLEAN,
    TIPO_ORIGEM_NF_CHOICE,
    TIPO_CHOICE_TIPO_SKU,
} from "@/assets/constants/constants";
import { gerarFileName } from "@/assets/util/util";

import useEmpresa from "@/hooks/useEmpresa";
import useClearRefs from "@/hooks/useClearRefs";
import { MakoDropdownFormula, MakoDropdownOrdenado } from "@/components/MakoInputs";

const { ExportCSV } = R.Buttons;

const OPTIONS_FRACIONAMENTO = [
    { label: "Permite fracionamento", value: true },
    { label: "Não permite fracionamento", value: false },
    { label: "Qualquer", value: -1 },
];

const OPTIONS_ECOMMERCE = [
    { label: "E-commerce", value: true },
    { label: "Não e-commerce", value: false },
    { label: "Qualquer", value: -1 },
];

const OPTIONS_COMPOSTO = [
    { label: "Composto", value: true },
    { label: "Não composto", value: false },
    { label: "Qualquer", value: -1 },
];

const OPTIONS_PRECO = [
    { label: "Sem preço base", value: true },
    { label: "Com preço base", value: false },
    { label: "Qualquer", value: -1 },
];

const OPTIONS_DESCRICAO = [
    { label: "Dinâmica", value: true },
    { label: "Reduzida", value: false },
];

const OPTIONS_ORDENACAO = [
    { label: "Descrição", value: "descricao" },
    { label: "Código", value: "codigo" },
    { label: "Quantidade", value: "quantidade" },
    { label: "NCM", value: "ncm" },
];

const FILTROS_VIEWSET = {
    marca: "item__marca",
    estoque: "saldosku__estoque_empresa",
    formula: "formula",
    modelo: "modelo",
    categoria: "categoriasku__categoria__in",
    ecommerce: "e_commerce",
    fracionamento: "permite_fracionamento",
    composto: "composto",
    preco: "precosku__isnull",
    descricao_reduzida: "descricao_reduzida__unaccent__icontains",
    nome_produto: "item__nome__icontains",
    ncm: "item__ncm",
    situacao: "item__status",
    saldo__gte: "saldosku__quantidade__gte",
    saldo__lte: "saldosku__quantidade__lte",
    codigo: "codigo",
    exibir_descricao_derivada: "exibir_descricao_derivada",
    exibir_classificacao: "exibir_classificacao",
    ordenacao: "ordering",
    origem: "origem",
    tipo_sku: "tipo_sku",
};

const BASE_URL = "/relatorios/relacao-produtos/";

const FILE_NAME = gerarFileName("Relacao Produtos");

export const RelatorioRelacaoProdutos = () => {
    const [url, setUrl] = useState(null);
    const [estoque, setEstoque] = useState(null);
    const [tipoSKU, setTipoSKU] = useState(null);
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [exibirClassificacao, setExibirClassificacao] = useState(false);

    const { solicitarRelatorio } = useRelatorio();
    const { empresaSelecionadaId } = useEmpresa();

    const botaoCsv = useRef();
    const categoriaRef = useRef();
    const listagemRef = useRef();
    const formRef = useRef();
    const ordenacoesRef = useRef();

    useClearRefs(categoriaRef, listagemRef, formRef, botaoCsv, ordenacoesRef);

    const submit = (e = null, limpar = true) => {
        if (limpar) limparVisualizacao();
        return formRef.current?.handleSubmit(e);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const getOrdenacoes = useCallback((data = []) => {
        ordenacoesRef.current = data;
    }, []);

    const filtrosTemplate = [
        {
            key: "formula",
            label: "Formula de saldo",
            path: "descricao",
        },
        {
            key: "estoque",
            label: "Estoque",
            path: "nome",
        },
        {
            key: "marca",
            label: "Marca",
            path: "nome",
        },
        {
            key: "modelo",
            label: "Modelo",
            path: "nome",
        },
        {
            key: "tipo_sku",
            label: "Tipo de produto",
            optionKey: "value",
            optionLabel: "label",
            options: TIPO_CHOICE_TIPO_SKU,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "categoria",
            label: "Categoria",
            path: "nome",
            type: TIPOS_FILTROS_TEMPLATE.MULTISELECT,
        },
        {
            key: "fracionamento",
            label: "Fracionamento",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_FRACIONAMENTO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "ecommerce",
            label: "Ecommerce",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_ECOMMERCE,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "composto",
            label: "Composto",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_COMPOSTO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "preco",
            label: "Preço",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_PRECO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "descricao_reduzida",
            label: "Descrição reduzida",
        },
        {
            key: "nome_produto",
            label: "Nome produto",
        },
        {
            key: "ncm",
            label: "Ncm",
            path: "codigo",
        },
        {
            key: "situacao",
            label: "Situação",
            optionKey: "id",
            optionLabel: "label",
            options: STATUS_ITEM,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "exibir_descricao_derivada",
            label: "Descrição produto",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_DESCRICAO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: ["saldo_fisico_start", "saldo_fisico_end"],
            label: ["Saldo maior que", "Saldo menor que"],
            type: TIPOS_FILTROS_TEMPLATE.MONEYPERIODO,
        },
        {
            key: "exibir_classificacao",
            label: "Exibir classificação",
            options: SIM_NAO_BOOLEAN,
            optionKey: "id",
            optionLabel: "label",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "origem",
            label: "Origem",
            optionKey: "id",
            optionLabel: "label",
            options: TIPO_ORIGEM_NF_CHOICE,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "ordenacao",
            label: "Ordenação",
            path: "label",
        },
    ];

    const colunas = useMemo(() => {
        return !exibirClassificacao
            ? [
                  { field: "codigo", header: "Código", style: { width: "8%" } },
                  { field: "descricao", header: "Descrição" },
                  { field: "ncm", header: "NCM" },
                  { field: "unidade", header: "UN", style: { width: "5%" }, align: "center" },
                  { field: "quantidade", header: "QTD", style: { width: "5%" }, align: "center" },
              ]
            : [
                  { field: "codigo", header: "Código", style: { width: "8%" } },
                  { field: "descricao", header: "Descrição" },
                  { field: "ncm", header: "NCM" },
                  { field: "categoria", header: "Classificação" },
                  { field: "unidade", header: "UN", style: { width: "5%" }, align: "center" },
                  { field: "quantidade", header: "QTD", style: { width: "5%" }, align: "center" },
              ];
    }, [exibirClassificacao]);

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                formula: Yup.object()
                    .required("O campo 'formula' é obrigatório")
                    .typeError("Informe uma 'formula' válida"),
                categoria: Yup.array().nullable().typeError("Informe uma 'categoria' válido."),
                estoque: Yup.object().required("Informe um 'estoque' válido.").typeError("Informe um 'estoque' válido"),
                marca: Yup.object().nullable().default(null).typeError("Informe uma 'marca' válida"),
                modelo: Yup.number().nullable().default(null).typeError("Informe um 'modelo' válido"),
                descricao_reduzida: Yup.string().nullable().typeError("Informe uma 'descrição reduzida' válido"),
                nome_produto: Yup.string().nullable().typeError("Informe um 'nome de produto' válido"),
                ncm: Yup.object().nullable().default(null).typeError("Informe um 'NCM' válido"),
                situacao: Yup.string().nullable().default(null).typeError("Informe uma 'situacao' válida"),
                saldo__gte: Yup.number().nullable().typeError("Selecione um 'fim de saldo' válido."),
                ordenacao: Yup.string().nullable().default(null).typeError("Informe um tipo de 'ordenação' válido"),
                tipo_sku: Yup.string()
                    .typeError("Informe um tipo de 'produto' válido")
                    .required("Informe um tipo de 'produto' válido"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            const selecionados = categoriaRef.current;
            const ordenacoes = ordenacoesRef.current;

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: selecionados,
                ordenacao: ordenacoes.find(({ value }) => value === dadosValidados.ordenacao),
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            delete dadosValidados.background;
            delete dadosValidados.emails;

            if (dadosValidados.formula) dadosValidados.formula = dadosValidados.formula.id;
            if (dadosValidados.estoque) dadosValidados.estoque = dadosValidados.estoque.id;
            if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
            if (dadosValidados.ncm) dadosValidados.ncm = dadosValidados.ncm.id;
            if (dadosValidados.preco === -1) delete dadosValidados.preco;
            if (dadosValidados.fracionamento === -1) delete dadosValidados.fracionamento;
            if (dadosValidados.ecommerce === -1) delete dadosValidados.ecommerce;
            if (dadosValidados.composto === -1) delete dadosValidados.composto;
            if (dadosValidados.ordenacao === -1) delete dadosValidados.ordenacao;
            if (selecionados) {
                dadosValidados.categoria = selecionados.map((c) => c.id);
            }

            if (!buscarListagem) {
                let filtros = {};
                if (dadosValidados) {
                    Object.keys(dadosValidados).forEach((key) => {
                        if (dadosValidados[key] !== null && dadosValidados[key] !== "")
                            filtros[key] = dadosValidados[key];
                    });
                }
                solicitarRelatorio({
                    chave: RELATORIO_PRODUTOS_RELACAOPRODUTOS,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
            } else {
                let filtros = [];

                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        if (k === "categoria") {
                            filtros.push(`${FILTROS_VIEWSET[k]}=${v.join(",")}`);
                            continue;
                        }
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const selecionarCategoria = (e) => {
        categoriaRef.current = e;
    };

    const handleExibirClassificacao = (e) => {
        setExibirClassificacao(e.value);
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        const selecionados = categoriaRef.current;

        if (dadosValidados.formula) dadosValidados.formula = dadosValidados.formula.id;
        if (dadosValidados.estoque) dadosValidados.estoque = dadosValidados.estoque.id;
        if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
        if (dadosValidados.ncm) dadosValidados.ncm = dadosValidados.ncm.id;
        if (dadosValidados.preco === -1) delete dadosValidados.preco;
        if (dadosValidados.fracionamento === -1) delete dadosValidados.fracionamento;
        if (dadosValidados.ecommerce === -1) delete dadosValidados.ecommerce;
        if (dadosValidados.composto === -1) delete dadosValidados.composto;
        if (dadosValidados.ordenacao === -1) delete dadosValidados.ordenacao.label;
        if (selecionados) {
            dadosValidados.categoria = selecionados.map((c) => c.id);
        }
        let filtros = {};
        if (dadosValidados) {
            Object.keys(dadosValidados).forEach((key) => {
                if (dadosValidados[key] !== null && dadosValidados[key] !== "") filtros[key] = dadosValidados[key];
            });
        }
        return aplicarFiltrosCsv(filtros);
    };

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_PRODUTOS_RELACAOPRODUTOS}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const watchValues = useCallback((data = {}) => {
        const { estoque = null, tipo_sku = null } = data;
        setEstoque(estoque?.id);
        setTipoSKU(tipo_sku);
    }, []);

    return (
        <R.Wrapper titulo={"de relação produtos"}>
            <MakoFormGerador
                ref={formRef}
                watchValues={watchValues}
                formikProps={{
                    initialValues: {
                        marca: null,
                        estoque: null,
                        formula: null,
                        modelo: null,
                        categoria: null,
                        ecommerce: -1,
                        fracionamento: -1,
                        composto: -1,
                        preco: -1,
                        descricao_reduzida: "",
                        nome_produto: "",
                        ncm: null,
                        situacao: "F",
                        saldo__gte: null,
                        saldo__lte: null,
                        exibir_descricao_derivada: true,
                        exibir_classificacao: false,
                        tipo_sku: null,
                        background: true,
                        emails: [],
                        corpo_email: "",
                        ordenacao: "descricao",
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Estoque",
                        inputId: "estoque",
                        inputName: "estoque",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            placeholder: "Selecione um estoque",
                            filter: true,
                            filterBy: "nome",
                            url: `/produtos/estoques-empresas/?empresa=${empresaSelecionadaId}`,
                            optionLabel: "nome",
                            buscar: !!empresaSelecionadaId,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Tipo de produto",
                        inputId: "tipo_sku",
                        inputName: "tipo_sku",
                        required: true,
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: TIPO_CHOICE_TIPO_SKU,
                            optionValue: "value",
                            optionLabel: "label",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Formula de saldo",
                        inputId: "formula",
                        inputName: "formula",
                        component: MakoDropdownFormula,
                        required: true,
                        componentProps: {
                            filtros: {
                                estoque_empresa: estoque,
                                formula_configurada: true,
                                tipo_sku: tipoSKU,
                            },
                            filter: true,
                            filterBy: "descricao",
                            buscar: !!estoque && !!tipoSKU,
                            considerarEmpresa: false,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Marca",
                        inputId: "marca",
                        inputName: "marca",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione uma marca",
                            filter: true,
                            filterBy: "nome",
                            url: "/produtos/marcas/",
                            optionLabel: "nome",
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Nome produto",
                        inputId: "nome_produto",
                        inputName: "nome_produto",
                        component: InputText,
                        componentProps: {
                            placeholder: "Informe um nome do produto",
                            eventChangeKey: "onInput",
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Descrição reduzida",
                        inputId: "descricao_reduzida",
                        inputName: "descricao_reduzida",
                        component: InputText,
                        componentProps: {
                            placeholder: "Informe uma descrição reduzida",
                            eventChangeKey: "onInput",
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "NCM",
                        inputId: "ncm",
                        inputName: "ncm",
                        component: MakoInputNcm,
                        componentEspecial: COMPONENTES_ESPECIAIS.NCM,
                        componentProps: {
                            showClear: true,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Situação",
                        inputId: "situacao",
                        inputName: "situacao",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: STATUS_ITEM,
                            optionValue: "id",
                            optionLabel: "label",
                            buscar: !!empresaSelecionadaId,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Modelo",
                        inputId: "modelo",
                        inputName: "modelo",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um modelo",
                            url: "/produtos/modelos/?query={id,nome}",
                            filter: true,
                            filterBy: "nome",
                            optionValue: "id",
                            optionLabel: "nome",
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Categoria",
                        inputId: "categoria",
                        inputName: "categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: selecionarCategoria,
                            showClear: true,
                            selectionMode: "checkbox",
                            metaKeySelection: false,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Quantidade entre",
                        inputId: "saldo__gte",
                        inputName: "saldo__gte",
                        component: MakoInputQuantidadeSku,
                        componentProps: {
                            eventChangeKey: "onValueChange",
                            permiteQuantidadeNegativa: true,
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "saldo__lte",
                        inputName: "saldo__lte",
                        divClassName: "p-mt-4",
                        divStyle: { paddingTop: "5px" },
                        component: MakoInputQuantidadeSku,
                        componentProps: {
                            eventChangeKey: "onValueChange",
                            permiteQuantidadeNegativa: true,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Fracionamento",
                        inputId: "fracionamento",
                        inputName: "fracionamento",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um tipo de fracionamento",
                            options: OPTIONS_FRACIONAMENTO,
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Composto",
                        inputId: "composto",
                        inputName: "composto",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um tipo de composto",
                            options: OPTIONS_COMPOSTO,
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Preço",
                        inputId: "preco",
                        inputName: "preco",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um tipo de preço",
                            options: OPTIONS_PRECO,
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "E-commerce",
                        inputId: "ecommerce",
                        inputName: "ecommerce",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um tipo de E-commerce",
                            options: OPTIONS_ECOMMERCE,
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Descrição produto",
                        inputId: "exibir_descricao_derivada",
                        inputName: "exibir_descricao_derivada",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um tipo",
                            options: OPTIONS_DESCRICAO,
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Exibir classificação",
                        inputId: "exibir_classificacao",
                        inputName: "exibir_classificacao",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            optionLabel: "label",
                            optionValue: "id",
                            options: SIM_NAO_BOOLEAN,
                            getOnChange: handleExibirClassificacao,
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Origem",
                        inputId: "origem",
                        inputName: "origem",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecion um origem",
                            options: TIPO_ORIGEM_NF_CHOICE,
                            optionValue: "id",
                            optionLabel: "label",
                        },
                        fieldSize: 12,
                    },
                    {
                        label: "Ordenação",
                        inputId: "ordenacao",
                        inputName: "ordenacao",
                        component: MakoDropdownOrdenado,
                        componentProps: {
                            campos: OPTIONS_ORDENACAO,
                            getOptions: getOrdenacoes,
                        },
                        fieldSize: 3,
                    },
                ]}
            >
                <R.Buttons.Wrapper>
                    <R.Buttons.Visualizar onClick={handleVisualizar} />
                    <R.Buttons.GerarPdf
                        chave={RELATORIO_PRODUTOS_RELACAOPRODUTOS}
                        setFieldValue={setFieldValue}
                        handleSubmit={submit}
                        onlyBackground
                    />
                    <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                    <R.Buttons.Limpar onClick={limparFormulario} />
                </R.Buttons.Wrapper>
            </MakoFormGerador>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={url}
                msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                botaoExportar={BotaoExportar}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    exportFilename: FILE_NAME,
                }}
            />
        </R.Wrapper>
    );
};
