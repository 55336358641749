import React, { useMemo } from "react";

import { Dropdown } from "../../Dropdown";

import { gerarFiltrosUrl } from "@/assets/util/util";

const BASE_URL = "/pessoas/veiculo-entrega/";

const KEY_FILTRO_MOTORISTA = "vinculomotoristaveiculo__perfil_motorista";

const BASE_FILTROS = {
    query: "{id,placa}",
    limit: 200,
};

export const MakoDropdownVeiculo = ({
    apenasAtivo = true,
    todasFormulas = false,
    naoBuscarSemEmpresa = true,
    filtros = {},
    disabled = false,
    motorista,
    ...props
}) => {
    const url = useMemo(() => {
        const query = {
            ...BASE_FILTROS,
            ...(filtros || {}),
            ...(motorista ? { [KEY_FILTRO_MOTORISTA]: motorista } : {}),
        };
        return `${BASE_URL}?${gerarFiltrosUrl(query)}`;
    }, [filtros, motorista]);

    const buscar = useMemo(() => {
        if (!motorista) return false;
        return !!motorista;
    }, [motorista]);

    return (
        <Dropdown
            url={url}
            optionValue="id"
            optionLabel="placa"
            placeholder="Selecione um veículo"
            buscar={buscar}
            disabled={!buscar || disabled}
            {...props}
        />
    );
};
