import React from "react";
import { Image } from "primereact/image";

import { MakoFileUpload } from "@/components/MakoFileUpload";
import { Button } from "primereact/button";
import useToast from "@/hooks/useToast";

export const MakoUploadPreviewImage = ({
    uploadConfig,
    imageConfig,
    deleteConfig,
    exibirDeletar,
    ocultarImage = false,
}) => {
    const { showWarning } = useToast();
    return (
        <div>
            {!ocultarImage && <Image {...imageConfig} />}
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-col-12 p-md-12">
                    <div className="p-inputgroup">
                        <MakoFileUpload
                            {...uploadConfig}
                            mode="basic"
                            accept="image/*"
                            onValidationFail={() =>
                                showWarning({
                                    summary: "Limite excedido",
                                    detail: "A imagem possui um tamanho maior do que o permitido.",
                                    life: 3000,
                                })
                            }
                            className="p-text-center"
                        />
                        {exibirDeletar ? (
                            <Button
                                className="p-button-danger p-ml-1"
                                icon="pi pi-trash"
                                tooltip="Remover imagem"
                                {...deleteConfig}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
