import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";

import { Dropdown } from "@/components/Dropdown";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useFetchRelatorio from "@/hooks/useFetchRelatorio";

const OPTIONS = [
    { value: true, label: "Enviar para fila e notificar quando terminar" },
    { value: false, label: "Gerar agora e exibir sem notificação" },
];

const Component = (
    {
        className = "p-button-info p-mr-2",
        setFieldValue = () => {},
        handleSubmit = () => {},
        chave,
        showButton = true,
        label = "Gerar PDF",
        disabled = false,
        onlyBackground = false,
    },
    ref
) => {
    const [confirm, setConfirm] = useState(false);
    const [value, setValue] = useState(null);

    const { relatorio, versao } = useFetchRelatorio({ chave });

    const abrirConfirm = () => {
        setFieldValue("emails", []);
        setConfirm(false);
    };

    const handleAccept = () => {
        setConfirm(false);
        handleSubmit();
    };

    const handleReject = () => {
        setConfirm(false);
        setFieldValue("background", null);
    };

    const handleClick = () => {
        if(!onlyBackground) setConfirm(true)
        else{
            setFieldValue("background", true)
            handleSubmit()
        }
    }

    const msgConfirmacao = useMemo(() => {
        return (
            <div className="p-d-flex p-d-flex-column">
                <Dropdown
                    id="background"
                    name="background"
                    options={OPTIONS}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    className="p-mr-2"
                    showClear={false}
                    style={{ minWidth: "30rem", width: "100%" }}
                />
            </div>
        );
    }, [value]);

    const footer = useMemo(() => {
        if (versao) return null;
        return <div className="p-error p-text-center">Relatório sem versão, impossibilitado de gerar</div>;
    }, [versao]);

    useEffect(() => {
        if (typeof setFieldValue === "function") {
            setFieldValue("versao", versao);
        }
    }, [versao, setFieldValue]);

    useEffect(() => {
        setValue(relatorio?.background);
    }, [relatorio]);

    useEffect(() => {
        if (typeof setFieldValue === "function") {
            setFieldValue("background", value);
        }
    }, [value, setFieldValue]);

    useImperativeHandle(ref, () => ({ show: setConfirm }));

    return (
        <>
            <ConfirmDialog
                visible={confirm}
                onHide={abrirConfirm}
                message={msgConfirmacao}
                header="Como deseja gerar o relatório?"
                accept={handleAccept}
                reject={handleReject}
                acceptLabel="Gerar"
                footer={footer}
                rejectClassName="p-button-danger p-button-text"
                rejectLabel="Cancelar"
            />
            {showButton && (
                <Button
                    label={label}
                    icon={MAKO_ICONS.ARQUIVO_PDF}
                    type="button"
                    onClick={handleClick}
                    className={className}
                    disabled={!versao || disabled}
                />
            )}
        </>
    );
};

export const GerarPdf = forwardRef(Component);
