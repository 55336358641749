import React from "react";
import { Route, Redirect } from "react-router-dom";

import useAuth from "@/hooks/useAuth";
import { PrivateLayout } from "@/layouts/PrivateLayout";

export const WrapperAuthRoute = (props) => {
    const { signed } = useAuth();

    if (!signed) {
        return <Redirect exact to="/login" />;
    }

    return (
        <PrivateLayout>
            <Route {...props} exact />
        </PrivateLayout>
    );
};
