import React, { useCallback, useMemo, useRef, useState } from "react";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { ListagemLotes } from "../../features";
import { Buttons } from "../../buttons";

import useClearRefs from "@/hooks/useClearRefs";

export const Lotes = () => {
    const [filtros, setFiltros] = useState({});

    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const atualizarListagem = useCallback(() => {
        listagemRef.current?.buscarDados();
    }, []);

    const onFilter = useCallback((...args) => {
        setFiltros(args?.at(0) || {});
    }, []);

    const limparFiltros = useCallback(() => {
        setFiltros({});
    }, []);

    const painelEsquerdo = useMemo(() => {
        return (
            <div>
                <Buttons.Novo onSuccessCallback={atualizarListagem} className="p-mr-2" />
                <Buttons.FiltroAvancado onSuccessCallback={onFilter} onClear={limparFiltros} className="p-mr-2" />
            </div>
        );
    }, [atualizarListagem, onFilter, limparFiltros]);

    const actions = useCallback(
        (rowData) => {
            return (
                <MakoActionsButtonsColumn>
                    <Buttons.Editar lote={rowData} onSuccessCallback={atualizarListagem} />
                    <Buttons.Opcoes lote={rowData} onSuccessCallback={atualizarListagem} />
                </MakoActionsButtonsColumn>
            );
        },
        [atualizarListagem]
    );

    return (
        <ListagemLotes filtros={filtros} listagemRef={listagemRef} painelEsquerdo={painelEsquerdo} actions={actions} />
    );
};
