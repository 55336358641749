import React, { useCallback, useEffect, useState } from "react";

import { MakoCardFinanceiro } from "@/components/MakoCards";

import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";

const BASE_INDICADORES = {
    contratos: 0,
    clientes: 0,
    receber: 0,
    vencido: 0,
};

export const CardIndicadoresContratoServico = ({ params = {} }) => {
    const [loading, setLoading] = useState(true);
    const [indicadores, setIndicadores] = useState(BASE_INDICADORES);
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet } = useHttp();

    const listarQuantidadeContratos = useCallback(
        async (signal, params) => {
            let data = 0;
            if (empresaSelecionadaId) {
                const handlers = {
                    200: ({ data: d }) => (data = d.total),
                };
                await httpGet(
                    {
                        url: `/servicos/indicativo-total-contratos/${empresaSelecionadaId}`,
                        params,
                        signal,
                    },
                    handlers
                );
                return { status: 200, data };
            }
            return { status: 400, data };
        },
        [empresaSelecionadaId, httpGet]
    );

    const listarQuantidadeClientes = useCallback(
        async (signal, params) => {
            let data = 0;
            if (empresaSelecionadaId) {
                const handlers = {
                    200: ({ data: d }) => (data = d.total),
                };
                await httpGet(
                    { url: `/servicos/indicativo-total-clientes/${empresaSelecionadaId}`, params, signal },
                    handlers
                );
                return { status: 200, data };
            }
            return { status: 400, data };
        },
        [empresaSelecionadaId, httpGet]
    );

    const listarTotalAReceber = useCallback(
        async (signal, params) => {
            let data = 0;
            if (empresaSelecionadaId) {
                const handlers = {
                    200: ({ data: d }) => (data = d.total),
                };
                await httpGet(
                    { url: `/servicos/indicativo-total-receber/${empresaSelecionadaId}`, params, signal },
                    handlers
                );
                return { status: 200, data };
            }
            return { status: 400, data };
        },
        [empresaSelecionadaId, httpGet]
    );

    const listarTotalVencido = useCallback(
        async (signal, params) => {
            let data = 0;
            if (empresaSelecionadaId) {
                const handlers = {
                    200: ({ data: d }) => (data = d.total),
                };
                await httpGet(
                    { url: `/servicos/indicativo-total-vencidos/${empresaSelecionadaId}`, params, signal },
                    handlers
                );
                return { status: 200, data };
            }
            return { status: 400, data };
        },
        [empresaSelecionadaId, httpGet]
    );

    const fetchIndicadores = useCallback(
        async (cancelToken) => {
            setLoading(true);
            const [clientes, contratos, receber, vencidos] = await Promise.all([
                listarQuantidadeClientes(cancelToken),
                listarQuantidadeContratos(cancelToken, params),
                listarTotalAReceber(cancelToken, params),
                listarTotalVencido(cancelToken, params),
            ]);
            setLoading(false);
            setIndicadores((prev) => {
                prev.clientes = clientes.data;
                prev.contratos = contratos.data;
                prev.receber = receber.data;
                prev.vencido = vencidos.data;
                return { ...prev };
            });
        },
        [
            setIndicadores,
            setLoading,
            listarQuantidadeClientes,
            listarQuantidadeContratos,
            listarTotalAReceber,
            listarTotalVencido,
            params,
        ]
    );

    useEffect(() => {
        const controler = new AbortController();
        fetchIndicadores(controler.signal);
        return () => controler.abort();
    }, [fetchIndicadores]);

    return (
        <>
            <div className="p-fluid p-grid">
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro
                        blocked={loading}
                        title="Quant. de contratos"
                        value={indicadores.contratos}
                        moeda={false}
                    />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro
                        blocked={loading}
                        title="A receber"
                        value={indicadores.receber}
                        levelOverviewClass={3}
                    />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro
                        blocked={loading}
                        title="A receber vencido"
                        value={indicadores.vencido}
                        moeda={false}
                        levelOverviewClass={5}
                    />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro
                        blocked={loading}
                        title="Nº de clientes"
                        value={indicadores.clientes}
                        moeda={false}
                    />
                </div>
            </div>
        </>
    );
};
