import { Operacao } from "./operacao";
import { Abrir } from "./abrir";
import { Bloquear } from "./bloquear";
import { Desbloquear } from "./desbloquear";
import { EditarHistorico } from "./editar-historico";
import { Fechar } from "./fechar";
import { ResumoCaixa } from "./resumo-caixa";
import { Sangria } from "./sangria";
import { Suprimento } from "./suprimento";
import { Transferencia } from "./transferencia";

export const Forms = {
    Operacao,
    Abrir,
    Bloquear,
    Desbloquear,
    EditarHistorico,
    Fechar,
    ResumoCaixa,
    Sangria,
    Suprimento,
    Transferencia,
};
