import React, { useMemo } from "react";
import MakoListagem from "@/components/MakoListagem";
import {
    ContaFinanceiraFiltroTemplate,
    DropdownFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { gerarFiltrosUrl, gerarStatusBooleanTemplate } from "@/assets/util/util";

const URL_BACKEND = "/financeiro/contas-bancarias/";

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { minWidth: "90px" },
    frozen: true,
    alignFrozen: "right",
};

const AgenciaFiltroTemplate = (props) => {
    return <TextoFiltroTemplate keyfilter={/^[0-9]/} maxLength={8} {...props} />;
};

const NumeroContaFiltroTemplate = (props) => {
    return <TextoFiltroTemplate keyfilter={/^[0-9]/} maxLength={1} {...props} />;
};

const VariacaoFiltroTemplate = (props) => {
    return <TextoFiltroTemplate keyfilter={/^[0-9]/} maxLength={3} {...props} />;
};

const BancoFiltroTemplate = (options) => {
    return (
        <DropdownFiltroTemplate
            dropdownProps={{
                optionValue: "id",
                optionLabel: "descricao",
                url: "/financeiro/bancos?limit=300",
            }}
            options={options}
        />
    );
};

const StatusFiltroTemplate = (options) => {
    return (
        <DropdownFiltroTemplate
            dropdownProps={{
                optionValue: "value",
                optionLabel: "label",
                options: [
                    { value: true, label: "Ativo" },
                    { value: false, label: "Desativado" },
                ],
            }}
            options={options}
        />
    );
};

const BASE_COLUMNS = [
    { field: "agencia", header: "Agência", filter: true, filterElement: AgenciaFiltroTemplate },
    {
        field: "numero_conta",
        header: "Número da conta",
        filter: true,
        filterElement: NumeroContaFiltroTemplate,
        style: { minWidth: "120px" },
    },
    { field: "variacao", header: "Variação", filter: true, filterElement: VariacaoFiltroTemplate },
    {
        field: "conta_financeira.descricao",
        header: "Conta financeira",
        filter: true,
        filterElement: ContaFinanceiraFiltroTemplate,
        filterField: "conta_financeira",
        style: { minWidth: "150px" },
    },
    {
        field: "banco.descricao",
        header: "Banco",
        filter: true,
        filterElement: BancoFiltroTemplate,
        filterField: "banco",
        style: { minWidth: "90px" },
    },
    { field: "limite", header: "Limite", filter: true, filterElement: MoedaFiltroTemplate, money: true },
    {
        field: "ativo",
        header: "Situação",
        action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "DESATIVADO"),
        style: { minWidth: "30px" },
        align: "center",
        filter: true,
        filterElement: StatusFiltroTemplate,
    },
];

export const ListagemContaBancaria = ({
    actions,
    titulo = "Contas Bancárias",
    listagemProps = {},
    configTabela = {},
    filtros = {},
    listagemRef = null,
}) => {
    const url = useMemo(() => {
        return `${URL_BACKEND}?${gerarFiltrosUrl(filtros)}`;
    }, [filtros]);

    const colunas = useMemo(() => {
        if (actions) return [...BASE_COLUMNS, { ...BASE_COLUMN_ACTIONS, action: actions }];
        return BASE_COLUMNS;
    }, [actions]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo={titulo}
            colunas={colunas}
            urlPesquisa={url}
            {...listagemProps}
            configTabela={{
                scrollable: true,
                lazy: true,
                ...configTabela,
            }}
            filtros={{
                agencia: { value: null, matchMode: "equals" },
                numero_conta: { value: null, matchMode: "equals" },
                variacao: { value: null, matchMode: "equals" },
                limite: { value: null, matchMode: "equals" },
                conta_financeira: { value: null, matchMode: "equals" },
                banco: { value: null, matchMode: "equals" },
                ativo: { value: null, matchMode: "equals" },
            }}
        />
    );
};
