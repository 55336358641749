import { useContext } from "react";

import CrediarioContext from "@/contexts/crediarioContext";

const useCrediario = () => {
    const crediario = useContext(CrediarioContext);

    return crediario;
};

export default useCrediario;
