import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { DadosBasicosVendaForm } from "./formDadosBasicos";
import { ItensVendaForm } from "./formItens";
import { FinanceiroVendaForm } from "./formFinanceiro";
import { PrevisaoEntregaForm } from "./formPrevisaoEntrega";
import { DocFiscalForm } from "./formDocFiscal";
import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import { parseMoeda } from "@/assets/util/util";
import useVenda from "@/hooks/useVenda";

export const TabVendas = ({ vendaId }) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { dadosBasicos, buscarVendaBancoDeDados } = useVenda();

    const TABS = [
        {
            header: "Dados básicos",
            icon: "pi pi-fw pi-align-left",
            component: <DadosBasicosVendaForm />,
        },
        {
            header: "Itens",
            icon: "fas pi-fw fa-cubes",
            component: <ItensVendaForm />,
            disabled: !!!dadosBasicos,
        },
        {
            header: "Financeiro",
            icon: "pi pi-fw pi-money-bill",
            component: <FinanceiroVendaForm />,
            disabled: !!!dadosBasicos,
        },
        {
            header: "Previsão de entrega",
            icon: "fas pi-fw fa-truck",
            component: <PrevisaoEntregaForm />,
            disabled: !!!dadosBasicos,
        },
        {
            header: "Documento fiscal",
            icon: "pi pi-fw pi-book",
            component: <DocFiscalForm />,
            disabled: !!!dadosBasicos,
        },
    ];

    const carregarVenda = useCallback(async () => {
        if (vendaId) {
            setLoading(true);
            await buscarVendaBancoDeDados(vendaId);
            setLoading(false);
        }
    }, [vendaId, buscarVendaBancoDeDados]);

    useEffect(() => {
        carregarVenda();
    }, [carregarVenda]);

    const voltarParaListagem = () => {
        history.push("/vendas/venda");
    };

    const header = dadosBasicos?.id
        ? `: (${dadosBasicos.id}) - ${dadosBasicos.cliente.nome} - Total venda: ${parseMoeda(
              dadosBasicos.valor_total_ap,
              true
          )}`
        : "";

    return (
        <PageBase blocked={loading}>
            <MakoMultiTabs
                title={`Lançamento de venda${header}`}
                tabs={TABS}
                ocultarButtonFinalizar
                propsButtonAnt={{ disabled: !!!dadosBasicos }}
                propsButtonProx={{ disabled: !!!dadosBasicos }}
                propsButtonCancelar={{ onClick: voltarParaListagem }}
            />
        </PageBase>
    );
};
