import React, { useRef } from "react";

import * as Yup from "yup";

import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_VENDAS_HISTORICOSIMPLESCLIENTE } from "@/assets/constants/relatorios";
import { MakoFormGerador } from "@/components/MakoFormGerador";

export const RelatorioHistoricoSimplesCliente = () => {
    const { solicitarRelatorio } = useRelatorio();

    const listagemRef = useRef();
    const formRef = useRef();

    useClearRefs(formRef, listagemRef);

    const filtrosTemplate = [
        {
            key: "cliente",
            label: "Cliente",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
    ];

    const submit = (e, limpar = true) => {
        // if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                cliente: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'cliente' é obrigatório."),
                    })
                    .typeError("Informe uma 'cliente' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.cliente !== null) {
                dadosValidados.cliente = dadosValidados.cliente.id;
            }

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            solicitarRelatorio({
                chave: RELATORIO_VENDAS_HISTORICOSIMPLESCLIENTE,
                emails,
                corpo_email,
                filtros,
                filtros_template: filtrosTemplateAplicados,
                versao,
                enviar_fila: background,
            });
            limparFormulario();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    return (
        <R.Wrapper titulo={"de histórico de cliente"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        cliente: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Cliente",
                        inputId: "cliente",
                        inputName: "cliente",
                        required: true,
                        component: MakoInputCliente,
                        fieldSize: 6,
                    },
                ]}
            >
                <R.Buttons.GerarPdf
                    chave={RELATORIO_VENDAS_HISTORICOSIMPLESCLIENTE}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
        </R.Wrapper>
    );
};
