import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Button } from "primereact/button";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { BotaoDelete } from "@/components/BotaoDelete";
import useTroca from "@/hooks/useTroca";
import { ItensTrocaModal } from "./modal/itemModal";

export const ItensTrocaForm = () => {
    const { dadosBasicos } = useTroca();
    const listagemRef = useRef(null);
    const modalRef = useRef(null);

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_TROCA_EDITAR]}
                componente={Button}
                label="Inserir"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => modalRef.current?.setVisible(true)}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TROCA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() => modalRef.current?.abrirModal(rowData)}
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TROCA_EDITAR]}
                    componente={BotaoDelete}
                    url="/vendas/itens-troca-devolucao/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja mesmo remover o item de devolução?</span>}
                    msgToastErroExclusao="O item não pode ser removido."
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "100px" } },
        { field: "sku.descricao_reduzida", header: "Descrição reduzida", style: { minWidth: "250px" } },
        { field: "unidade_medida.nome", header: "Unidade medida", style: { minWidth: "150px" } },
        { field: "quantidade", header: "Quantidade", style: { minWidth: "120px" } },
        {
            field: "valor_unitario",
            header: "Valor unitário",
            action: (e) => parseNumberToMoneyHTML(e.valor_unitario),
            style: { minWidth: "120px" },
        },
        {
            field: "subtotal",
            header: "Valor total",
            action: (e) => parseNumberToMoneyHTML(e.subtotal),
            style: { minWidth: "100px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const aposSalvar = () => {
        listagemRef.current?.buscarDados();
    };

    return (
        <>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/vendas/itens-troca-devolucao?troca=${dadosBasicos.id}`}
                configTabela={{ scrollable: true, frozenWidth: "0.5vw" }}
            />
            <ItensTrocaModal ref={modalRef} aposSalvar={aposSalvar} />
        </>
    );
};
