import React from "react";
import { InputText } from "primereact/inputtext";

import { PainelBackgroundCinza } from "@/components/PainelBackgroundCinza";
import { dataToStr } from "@/assets/util/datas";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const PainelAssistenciaTecnica = ({ assistenciaTecnica }) => {
    const [formatarDocumento] = useFormatCNPJCPF();

    const formatarCliente = (cliente) => {
        if (!cliente) return "-";
        const { nome, identificacao } = cliente;
        return `${nome} ${formatarDocumento(identificacao)}`;
    };

    const formatarData = (data) => {
        if (!data) return "-";
        return dataToStr(data, "dd/MM/yyyy");
    };

    return (
        <PainelBackgroundCinza>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="id">Nº assist. técnica</label>
                    <InputText id="id" name="id" value={assistenciaTecnica?.id || "0"} disabled />
                </div>
                <div className="p-field p-col-12 p-md-8">
                    <label htmlFor="cliente">Cliente</label>
                    <InputText
                        id="cliente"
                        name="cliente"
                        value={formatarCliente(assistenciaTecnica?.cliente)}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="vencimento">Vencimento</label>
                    <InputText
                        id="vencimento"
                        name="data_devolucao"
                        value={formatarData(assistenciaTecnica?.vencimento)}
                        disabled
                    />
                </div>
            </div>
        </PainelBackgroundCinza>
    );
};
