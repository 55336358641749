import React, { useRef } from "react";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Button } from "primereact/button";

import { Novo as Modal } from "../../modals";

import useClearRefs from "@/hooks/useClearRefs";

export const Novo = ({ className = "", onSuccessCallback = () => {} }) => {
    const modalRef = useRef(null);

    useClearRefs(modalRef);

    const show = () => {
        modalRef.current?.show();
    };

    return (
        <>
            <Button label="Novo" icon={MAKO_ICONS.NOVO} className={`p-button-success ${className}`} onClick={show} />
            <Modal ref={modalRef} successCallback={onSuccessCallback} />
        </>
    );
};
