import React, { useMemo } from "react";

export const Header = ({ item = {} }) => {
    const header = useMemo(() => {
        const { venda_id, sku, datahora_entrega } = item || {};
        const { codigo, descricao_derivada } = sku || {};
        const status = datahora_entrega ? "(OK)" : "(Pendente)";
        return `Venda ${venda_id} - ${codigo} - ${descricao_derivada} ${status}`;
    }, [item]);

    return <span>{header}</span>;
};
