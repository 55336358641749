import React, { useCallback, useState } from "react";
import { Button } from "primereact/button";
import useHttp from "@/hooks/useHttp";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { dataToStr } from "@/assets/util/datas";
import { Divider } from "primereact/divider";
import { Checkbox } from "primereact/checkbox";

export const MakoBuscaCnpj = ({ cnpj, handleAtualizar = () => {} }) => {
    const [dados, setDados] = useState({});
    const [endereco, setEndereco] = useState(false);
    const [visivel, setVisivel] = useState(false);
    const { httpGet } = useHttp();

    const buscarDados = useCallback(async () => {
        if (cnpj) {
            const handlers = {
                200: ({ data }) => {
                    setDados(data.retorno);
                    setVisivel(true);
                },
            };

            await httpGet(
                {
                    url: `/pessoas/consultar-cnpj/${cnpj.replaceAll(".", "").replace("-", "").replace("/", "")}/`,
                },
                handlers
            );
        }
    }, [cnpj, httpGet]);

    const fecharModal = () => {
        setVisivel(false);
        setDados({});
    };

    return (
        <>
            <Button
                type="button"
                icon="pi pi-search"
                severity="secondary"
                className="p-button-outlined"
                tooltip="Buscar atualização dos dados cadastrais"
                onClick={() => buscarDados()}
            />
            <Dialog
                visible={visivel}
                header={`Informações do CNPJ ${cnpj}`}
                style={{ width: "55vw" }}
                onHide={fecharModal}
            >
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="razao_social">Razão social</label>
                            <InputText id="razao_social" disabled value={dados.razao_social} readOnly />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="nome_fantasia">Nome fantasia</label>
                            <InputText id="nome_fantasia" disabled value={dados.nome_fantasia} readOnly />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="estado">Estado</label>
                            <InputText id="estado" disabled value={dados.endereco?.uf} readOnly />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="data_constituicao">Data constituição</label>
                            <InputText
                                id="data_constituicao"
                                disabled
                                value={dataToStr(dados.data_inicio_atividade, "dd/MM/yyyy")}
                                readOnly
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="data_alteracao_contratual">Última alteração contratual</label>
                            <InputText
                                id="data_alteracao_contratual"
                                disabled
                                value={dataToStr(dados.situacao_cadastral?.data, "dd/MM/yyyy")}
                                readOnly
                            />
                        </div>
                    </div>
                    <Divider align="center">Endereço</Divider>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="logradouro">Logradouro</label>
                            <InputText
                                id="logradouro"
                                disabled
                                value={`${dados.endereco?.tipo_logradouro} ${dados.endereco?.logradouro}`}
                                readOnly
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="numero">Número</label>
                            <InputText id="numero" disabled value={dados.endereco?.numero} readOnly />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="bairro">Bairro</label>
                            <InputText id="bairro" disabled value={dados.endereco?.bairro} readOnly />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="cep">CEP</label>
                            <InputText id="cep" disabled value={dados.endereco?.cep} readOnly />
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="municipio">Município</label>
                            <InputText
                                id="municipio"
                                disabled
                                value={`${dados.endereco?.municipio?.descricao} - ${dados.endereco?.uf}`}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="p-justify-end p-field-checkbox p-mr-3 p-mt-2">
                        <label className="p-mr-2" htmlFor="endereco">
                            Importar endereço?
                        </label>
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={endereco}
                            onChange={({ checked }) => setEndereco(checked)}
                            disabled={!dados.endereco}
                        />
                    </div>
                    <div className="p-grid p-justify-end p-mt-4 p-mr-1">
                        <Button
                            type="submit"
                            label="Atualizar"
                            icon="pi pi-save"
                            className="p-mr-2"
                            autoFocus
                            onClick={() => {
                                handleAtualizar(dados, endereco);
                                setVisivel(false);
                            }}
                        />
                        <Button
                            label="Cancelar"
                            icon="pi pi-times"
                            type="reset"
                            onClick={() => fecharModal()}
                            className="p-button-danger"
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};
