import React, { useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { PageBase } from "@/components/PageBase";
import useNotaFiscal from "@/hooks/useNotaFiscal";
import { useFormik } from "formik";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import useToast from "@/hooks/useToast";

export const InformacoesAdicionaisForm = () => {
    const { handleDadosBasicos, dadosBasicos } = useNotaFiscal();
    const { showError } = useToast();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            informacao_adicional: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const { status } = await handleDadosBasicos(values);
            if (status !== 200)
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível salvar as informações adicionais.",
                    life: 1500,
                });
        } catch (error) {
            showError();
        }
    }

    useEffect(() => {
        if (dadosBasicos?.id)
            setValues({ id: dadosBasicos.id, informacao_adicional: dadosBasicos.informacao_adicional || "" });
    }, [dadosBasicos, setValues]);

    return (
        <PageBase>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="informacao_adicional">Informações Adicionais</label>
                        <InputTextarea
                            id="informacao_adicional"
                            name="informacao_adicional"
                            value={formik.values.informacao_adicional}
                            onChange={formik.handleChange}
                            rows={20}
                            autoComplete="off"
                            autoFocus
                            autoResize
                        />
                    </div>
                </div>
            </form>
            <FormikAutoSave intervalo={1000} autosave={typeof dadosBasicos?.id === "number"} formik={formik} />
        </PageBase>
    );
};
