import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { axiosGet, axiosPatch } from "@/services/http";
import useToast from "@/hooks/useToast";
import useVenda from "@/hooks/useVenda";

const ModalEditarEnderecoEntregaComponente = ({ onSuccess }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingEnderecos, setLoadingEnderecos] = useState(false);
    const [previsaoEntrega, setPrevisaoEntrega] = useState(null);
    const [enderecos, setEnderecos] = useState([]);
    const { dadosBasicos } = useVenda();
    const { showSuccess, showError } = useToast();

    const formik = useFormik({
        initialValues: {
            endereco_entrega: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                endereco_entrega: Yup.number()
                    .required("Você precisa selecionar um endereço.")
                    .typeError("Você precisa selecionar um endereço."),
            });
            const { endereco_entrega } = await formSchema.validate(values, { abortEarly: false });
            setLoading(true);
            const { status } = await axiosPatch(`/vendas/previsoes-entregas-itens-vendas/${previsaoEntrega?.id}/`, {
                endereco_entrega,
            });
            setLoading(false);
            if (status === 200) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Endereço alterado com sucesso.",
                    life: 1500,
                });
                setTimeout(() => {
                    if (typeof onSuccess === "function") onSuccess();
                    fecharModal();
                }, 1500);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar a requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const listarEnderecos = useCallback(async () => {
        if (dadosBasicos) {
            setLoadingEnderecos(true);
            const response = await axiosGet(
                `/pessoas/enderecos-perfis?query={id,identificacao,logradouro,numero,bairro,cep}&perfil__id=${dadosBasicos.cliente.id}`
            );
            setLoadingEnderecos(false);
            if (response.status === 200) {
                setEnderecos(response.data.results);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos listar os endereços.",
                    life: 3000,
                });
            }
        }
    }, [dadosBasicos, showError]);

    useEffect(() => {
        if (visible) listarEnderecos();
    }, [visible, listarEnderecos]);

    const abrirModal = (prevEntrega) => {
        setVisible(true);
        setPrevisaoEntrega(prevEntrega);
    };

    const fecharModal = () => {
        setVisible(false);
        formik.resetForm();
    };

    const enderecoSelecionadoTemplate = (option, props) => {
        if (!option) return <span>{props.placeholder}</span>;
        return (
            <div className="country-item country-item-value">
                <div>{`${option.identificacao} - ${option.logradouro}, ${option.numero} - ${option.bairro} - ${option.cep}`}</div>
            </div>
        );
    };

    const enderecoTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{`${option.identificacao} - ${option.logradouro}, ${option.numero} - ${option.bairro} - ${option.cep}`}</div>
            </div>
        );
    };

    const footerDialog = () => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={fecharModal}
                    className="p-button-text p-button-danger"
                />
                <Button
                    label="Confirmar"
                    type="submit"
                    icon="pi pi-save"
                    loading={loading}
                    onClick={formik.handleSubmit}
                    autoFocus
                />
            </div>
        );
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            visible={visible}
            header="Alterar endereço de entrega"
            footer={footerDialog}
            style={{ width: "40vw" }}
            onHide={fecharModal}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="endereco">Endereço</label>
                        <Dropdown
                            id="endereco"
                            name="endereco_entrega"
                            placeholder={loadingEnderecos ? "Buscando endereços..." : "Selecione"}
                            emptyMessage="Nenhum endereço disponível."
                            options={enderecos}
                            optionLabel="logradouro"
                            optionValue="id"
                            disabled={loadingEnderecos}
                            value={formik.values.endereco_entrega}
                            onChange={formik.handleChange}
                            valueTemplate={enderecoSelecionadoTemplate}
                            itemTemplate={enderecoTemplate}
                            className={classNames({ "p-invalid": formik.errors.endereco_entrega })}
                        />
                        {formik.errors.endereco_entrega && (
                            <small className="p-error">{formik.errors.endereco_entrega}</small>
                        )}
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalEditarEnderecoEntrega = forwardRef(ModalEditarEnderecoEntregaComponente);
