import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Form } from "../../features";

const Component = ({ onSuccessCallback = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);
    const [lote, setLote] = useState(null);

    const show = useCallback((values) => {
        setLote({ ...values, serie: values?.sku?.lote_serial });
        setVisible(true);
    }, []);

    const hide = useCallback(() => {
        setVisible(false);
    }, []);

    const onSuccess = useCallback(
        (...args) => {
            hide();
            onSuccessCallback(...args);
        },
        [hide, onSuccessCallback]
    );

    const onCancel = useCallback(() => {
        hide();
    }, [hide]);

    const header = useMemo(() => {
        const { bloqueado_vender, lote_serial } = lote || {};
        if (bloqueado_vender) return `Desbloqueando venda do lote ${lote_serial}`;
        return `Bloqueando venda do lote ${lote_serial}`;
    }, [lote]);

    useImperativeHandle(ref, () => ({ show, hide }), [show, hide]);

    return (
        <Dialog
            header={header}
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "80vw" }}
            onHide={hide}
        >
            <Form lote={lote} successCallback={onSuccess} cancelCallback={onCancel} controleBloqueio />
        </Dialog>
    );
};

export const Bloqueio = forwardRef(Component);
