import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import MakoListagem from "../MakoListagem";
import { Button } from "primereact/button";
import { FiltroAvancadoLogs } from "./filtros";
import { Badge } from "primereact/badge";

const BASE_URL = "/logs/historico-logs/";

const Modal = ({}, ref) => {
    const [url, setUrl] = useState(BASE_URL);
    const [visible, setVisible] = useState(false);
    const [usuario, setUsuario] = useState(false);
    const [filtros, setFiltros] = useState(0);
    const filtroRef = useRef();
    const listagemRef = useRef();

    const abrirModal = ({ usuario }) => {
        setVisible(true);
        setUsuario(usuario);
    };

    const fecharModal = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const colunas = [
        {
            field: "data_hora",
            header: "Data/Hora",
            style: { minWidth: "80px" },
            dateFormat: "dd/MM/yyyy HH:mm:ss",
        },
        {
            field: "detalhes",
            header: "Detalhes",
            style: { minWidth: "400px" },
        },
        { field: "ip", header: "IP", style: { minWidth: "80px" } },
        {
            field: "usuario.username",
            header: "Usuário",
            style: { minWidth: "100px" },
        },
    ];

    const painelFiltro = (
        <>
            <Button
                icon="pi pi-search"
                severity="help"
                label="Filtro avançado"
                onClick={() => filtroRef.current?.abrirModal()}
            >
                <Badge value={filtros} />
            </Button>
        </>
    );

    const onFilter = (value, _filtros) => {
        setFiltros(_filtros);
        setUrl(value);
        listagemRef.current?.buscarDados();
    };

    return (
        <>
            <FiltroAvancadoLogs ref={filtroRef} onConfirm={onFilter} url={BASE_URL} />
            <Dialog
                header={`Histórico de Logs (ID: ${usuario.id}, Nome de usuário: ${usuario.username})`}
                visible={visible}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "76vw" }}
                onHide={fecharModal}
                maximizable
            >
                <MakoListagem
                    ref={listagemRef}
                    painelEsquerdo={painelFiltro}
                    urlPesquisa={url}
                    colunas={colunas}
                    msgTabelaVazia="Nenhum log registrado"
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            </Dialog>
        </>
    );
};

export const MakoHistoricoLogs = forwardRef(Modal);
