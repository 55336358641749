import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Button } from "primereact/button";

export const Limpar = ({ onClick, type = "reset", className = "p-button-warning p-mr-2" }) => {
    return (
        <Button
            label="Limpar filtros"
            icon={MAKO_ICONS.LIMPAR_FORM}
            type={type}
            className={className}
            onClick={onClick}
        />
    );
};
