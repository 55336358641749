import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { Dialog } from "primereact/dialog";

import { ReceberCartaoCredito as FormReceberCartaoCredito } from "../../receber-cartao-credito";

const Componente = ({ onSuccessCallback = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);
    const [pendencia, setPendencia] = useState(null);

    const show = useCallback(({ id, forma_recebimento, valor_receber, tipo_chave }) => {
        setPendencia({ id, forma_recebimento, valor_receber, tipo_chave });
        setVisible(true);
    }, []);

    const hide = useCallback(() => {
        setVisible(false);
    }, []);

    const successCallback = useCallback(
        (...args) => {
            onSuccessCallback(...args);
            hide();
        },
        [onSuccessCallback, hide]
    );

    useImperativeHandle(ref, () => ({ show, hide }), [show, hide]);

    return (
        <Dialog
            header="Recebendo via cartão de crédito"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "55vw" }}
            breakpoints={{ "960px": "70vw" }}
        >
            <FormReceberCartaoCredito pendencia={pendencia} successCallback={successCallback} />
        </Dialog>
    );
};

export const ReceberCartaoCredito = forwardRef(Componente);
