import React from "react";

import { classNames } from "primereact/utils";

export const MakoActionsButtons = ({ children, reverse = false, reverse_base = false, className = "" }) => {
    return (
        <div
            className={classNames("mako-actions-buttons", className, {
                "mako-actions-buttons-reverse": reverse,
                "mako-actions-buttons-reverse-base": reverse_base,
            })}
        >
            {children}
        </div>
    );
};
