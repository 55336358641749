import React, { useImperativeHandle, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { Dropdown } from "@/components/Dropdown";
import { VENDA_APROVACAOPRECO_MOTIVO } from "@/assets/constants/parametros";
import useParam from "@/hooks/useParam";

export const Modal = ({ setBodyNotificacao, onClick }, ref) => {
    const [motivoSelecionado, setMotivoSelecionado] = useState(null);
    const [visible, setVisible] = useState(false);
    const { getParam } = useParam();

    useImperativeHandle(ref, () => ({ abrirModal: () => setVisible(true) }));

    const obterMotivosAprovacao = () => {
        const param = getParam(VENDA_APROVACAOPRECO_MOTIVO);
        if (param) {
            return param.valor.split("|").map((motivo) => ({ value: motivo, label: motivo }));
        }
        return [];
    };

    const footer = (
        <div>
            <Button
                label="Confirmar"
                icon="pi pi-check"
                onClick={() => {
                    if (typeof onClick === "function") {
                        onClick();
                        setVisible(false);
                    }
                }}
                autoFocus
            />
        </div>
    );

    return (
        <Dialog
            header="Motivo da aprovação de preço"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "30vw" }}
            footer={footer}
        >
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <Dropdown
                        options={obterMotivosAprovacao()}
                        value={motivoSelecionado}
                        filter
                        onChange={(e) => {
                            setMotivoSelecionado(e.value);
                            setBodyNotificacao((prev) => ({ ...prev, custom: { motivo_aprovacao: e.value } }));
                        }}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export const ModalMotivoAprovacaoPreco = forwardRef(Modal);
