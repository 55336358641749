import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Editor } from "primereact/editor";

import { MakoInputParametro } from "../../../../MakoInputParametro";
import { MakoButton as Button } from "@/components/MakoButton";
import { InputPerfil } from "./inputPerfil";
import { Label } from "@/components/Label";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { dataToStr } from "@/assets/util/datas";

const Component = ({ onSuccessCallback = () => {}, edicao = false }, ref) => {
    const [parametro, setParametro] = useState(null);
    const [perfisInvalidos, setPerfisInvalidos] = useState(null);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpGet, httpPost, httpPut } = useHttp();
    const { loadParams } = useParam();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            chave: null,
            valor: "",
            perfil: null,
            orientacao: null,
        },
        onSubmit: handleSubmit,
    });

    const parametroJaExiste = async (chaveId, perfilId) => {
        let url = `/configuracoes/parametros/?chave__id=${chaveId}`;
        let ok = true;
        if (perfilId) url = `${url}&perfil__id=${perfilId}`;
        const handlers = {
            200: ({ data }) => (ok = data.count > 0),
        };
        showLoading();
        await httpGet({ url }, handlers);
        hideLoading();
        return ok;
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                chave: Yup.string().required("O campo 'Chave' é obrigatório").typeError("Informe uma 'chave' válida."),
                valor: Yup.mixed().required("O campo 'Valor' é obrigatório").typeError("Informe um 'valor' válido."),
                perfil: Yup.number()
                    .nullable()
                    .test("Perfil validation", "Esse parâmetro requer um perfil.", (value) => {
                        if (parametro.requer_perfil) return !!value;
                        return true;
                    })
                    .typeError("Informe um 'perfil' válido."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });

            const novoParametro = {
                ...values,
                valor: !parametro.url
                    ? parametro.tipo_valor === "H"
                        ? parametro.tipo_valor === "D"
                            ? dataToStr(values.valor, "dd/MM/yyyy")
                            : dataToStr(values.valor, "HH:mm")
                        : values.valor
                    : null,
                valor_id: parametro.url ? values.valor : 0,
                valor_content_type: parametro.content_type,
            };

            if (!novoParametro.id) {
                const existe = await parametroJaExiste(values.chave, values.perfil);
                if (!existe) {
                    const handlers = {
                        201: ({ data }) => {
                            showSuccess({
                                summary: "Sucesso",
                                detail: "Novo parâmetro cadastrado!",
                                life: 2000,
                            });
                            resetForm();
                            loadParams();
                            onSuccessCallback(data);
                        },
                    };
                    showLoading();
                    await httpPost({ url: "/configuracoes/parametros/", body: novoParametro }, handlers);
                    hideLoading();
                } else {
                    showWarning({
                        summary: "Atenção",
                        detail: "Já existe um parâmetro com essa chave/perfil.",
                        life: 3000,
                    });
                }
            } else {
                const handlers = {
                    200: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Parâmetro alterado!",
                            life: 2000,
                        });
                        resetForm();
                        loadParams();
                        onSuccessCallback(data);
                    },
                };
                showLoading();
                await httpPut({ url: `/configuracoes/parametros/${novoParametro.id}/`, body: novoParametro }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
            if (error instanceof SyntaxError) {
                showError({
                    summary: "Erro :(",
                    detail: "O formato do JSON está incorreto, por favor verifique.",
                    life: 3000,
                });
            }
        }
    }

    const preencherParametro = useCallback(
        (value) => {
            resetForm();
            setPerfisInvalidos(value?.perfis_associados);
            setParametro(value);
            setFieldValue("chave", value?.id);
        },
        [setFieldValue, resetForm]
    );

    const preencherEdicao = useCallback(
        (value) => {
            const { id, chave, perfil, valor, valor_id } = value || {};
            setParametro(chave);
            setPerfisInvalidos(chave?.perfis_associados);
            setValues({
                id,
                chave: chave.id,
                perfil: perfil?.id,
                valor: valor_id > 0 ? valor_id : valor,
                orientacao: chave?.orientacao,
            });
        },
        [setValues]
    );

    const aposBuscarPerfis = useCallback(
        (data = []) => {
            if (!perfisInvalidos) return data;
            if (!edicao) return data.filter(({ id }) => !perfisInvalidos?.includes(id));
            return data.filter(({ id }) => {
                if (id === formik.values.perfil) return true;
                return !perfisInvalidos?.includes(id);
            });
        },
        [edicao, perfisInvalidos, formik.values.perfil]
    );

    useImperativeHandle(ref, () => ({ setParametro: preencherParametro, editar: preencherEdicao }), [
        preencherParametro,
        preencherEdicao,
    ]);

    return (
        <form onSubmit={formik.handleSubmit}>
            {parametro && (
                <>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-5">
                            <Label htmlFor="valor" label="Valor" obrigatorio />
                            <MakoInputParametro
                                id="valor"
                                name="valor"
                                chaveParametro={parametro}
                                value={formik.values.valor}
                                onChangeParametro={(e) => setFieldValue("valor", e)}
                                className={classNames({ "p-invalid": formik.errors.valor })}
                            />
                            {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <Label htmlFor="perfil" label="Perfil vinculado" obrigatorio={parametro?.requer_perfil} />
                            <InputPerfil
                                id="perfil"
                                name="perfil"
                                disabled={!!!parametro?.requer_perfil}
                                value={formik.values.perfil}
                                aposBuscar={aposBuscarPerfis}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.perfil })}
                            />
                            {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "4px" }}>
                            <Button
                                label="Gravar"
                                type="submit"
                                icon={MAKO_ICONS.GRAVAR}
                                disabled={!!!parametro}
                                loading={loading}
                                className="p-button-info p-mr-2"
                            />
                        </div>
                    </div>
                    {edicao && (
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <Label htmlFor="orientacoes" label="Orientações" />
                                <Editor
                                    value={formik.values.orientacao || ""}
                                    showHeader={false}
                                    readOnly
                                    style={{ height: "200px" }}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </form>
    );
};

export const Cadastrar = forwardRef(Component);
