import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { PageBase } from "@/components/PageBase";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import { AtivoFiltroTemplate, DateFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";

export const CompetenciaMovEstoquePage = () => {
    const listagemRef = useRef();
    const history = useHistory();

    useClearRefs(listagemRef);

    const painelEsquerdoTabela = (
        <Button
            label="Nova"
            icon="pi pi-plus"
            className="p-button-success p-mr-2"
            onClick={() => history.push("/estoque/cadastros/competencias-movimentacoes-estoque/form")}
        />
    );

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativa) return <Tag severity="success" value="ATIVA" />;
        return <Tag severity="danger" value="INATIVA" />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_COMPMOVESTOQUE_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/cadastros/competencias-movimentacoes-estoque/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_COMPMOVESTOQUE_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/produtos/competencias-movimentacoes-estoques/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Confirma a exclusão da competência: <b>{rowData.nome}</b>?
                        </span>
                    }
                    classNames="p-mr-2 p-mb-1"
                    msgToastErroExclusao="A competência não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "data_inicio",
            header: "Data de início",
            dateFormat: "dd/MM/yyyy",
            style: { width: "15%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "data_fim",
            header: "Data fim",
            dateFormat: "dd/MM/yyyy",
            style: { width: "15%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "ativa",
            header: "Situação",
            style: { width: "10%" },
            filter: true,
            filterElement: AtivoFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
        },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Competências de movimentação de estoque"
                colunas={colunas}
                urlPesquisa="/produtos/competencias-movimentacoes-estoques/"
                filtarPorEmpresa
                naoBuscarSemEmpresa
                fieldFiltroEmpresa="empresas"
                painelEsquerdo={painelEsquerdoTabela}
                configTabela={{ lazy: true, paginator: true }}
                filtros={{
                    nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    data_inicio: { value: null, matchMode: "equals" },
                    data_fim: { value: null, matchMode: "equals" },
                    ativa: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
