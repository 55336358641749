import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { InputTextarea } from "primereact/inputtextarea";

export const Modal = ({ aposCancelar }, ref) => {
    const [nota, setNota] = useState({});
    const [visivel, setVisivel] = useState(false);
    const [codigo, setCodigo] = useState("");
    const [justificativa, setJustificativa] = useState("");
    const { httpPut } = useHttp();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const toastRef = useRef(null);

    const handleSubmit = useCallback(
        async (nfs = nota) => {
            try {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Documento fiscal cancelado com sucesso.",
                            life: 3000,
                        });
                        if (typeof aposCancelar === "function") aposCancelar();
                        esconder();
                    },
                    409: ({ err }) => {
                        showError({
                            summary: "Erro",
                            detail: err.msg,
                            life: 3000,
                        });
                    },
                };

                await httpPut(
                    {
                        url: `/servicos/cancelar-documento/${nfs?.id}/${empresaSelecionadaId}/`,
                        body: {
                            codigo,
                            justificativa,
                        },
                    },
                    handlers
                );
            } catch (error) {
                showError();
            }
        },
        [httpPut, codigo, empresaSelecionadaId, justificativa, nota, showSuccess, showError]
    );

    const abrirModal = useCallback((id) => {
        setNota(id);
        setVisivel(true);
    }, []);

    useImperativeHandle(ref, () => ({ abrir: abrirModal, cancelar: handleSubmit }), [abrirModal, handleSubmit]);

    function validateForm() {
        return justificativa.length >= 15;
    }

    const esconder = () => {
        setJustificativa("");
        setCodigo("");
        setVisivel(false);
    };

    return (
        <>
            <Toast ref={toastRef} />
            <Dialog
                header="Justificativa de cancelamento"
                visible={visivel}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "40vw" }}
                onHide={() => esconder()}
            >
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <InputText
                                id="codigo"
                                placeholder="Código de cancelamento"
                                tooltip="Código de cancelamento, exigido por algumas prefeituras. Para saber quais valores são aceitos, consulte o manual da prefeitura."
                                value={codigo}
                                onChange={(e) => setCodigo(e.target.value)}
                                autoComplete="off"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <InputTextarea
                                id="justificativa"
                                name="justificativa"
                                placeholder="Digite uma justificativa (min. 15 caracteres)"
                                value={justificativa}
                                onChange={(e) => setJustificativa(e.target.value)}
                                autoResize
                                autoComplete="off"
                                rows={4}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-justify-center p-mt-3">
                        <Button
                            label="Cancelar"
                            type="button"
                            className="p-button-info"
                            disabled={!validateForm()}
                            onClick={handleSubmit}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export const CancelaNFS = forwardRef(Modal);
