import React, { useCallback, useMemo, useRef, useState } from "react";

import classNames from "classnames";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton } from "@/components/MakoButton";
import { Modals } from "../../modals";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

const { PRODUTO_PRODUTO_LOTES_CONSULTAR } = permissoes;

export const FiltroAvancado = ({ className = "", onSuccessCallback = () => {}, onClear = () => {} }) => {
    const [filtros, setFiltros] = useState({});

    const modalRef = useRef(null);

    const show = useCallback(() => {
        modalRef.current?.show(filtros);
    }, [filtros]);

    const handlerClear = useCallback(() => {
        setFiltros({});
        onClear();
    }, [onClear]);

    const onSuccess = useCallback(
        (...args) => {
            setFiltros(args?.at(0) || {});
            onSuccessCallback(...args);
        },
        [onSuccessCallback]
    );

    const totalizador = useMemo(() => {
        if (!filtros) return 0;
        return Object.keys(filtros).length;
    }, [filtros]);

    return (
        <>
            <MakoControleAcesso
                componente={MakoButton}
                permissao={[PRODUTO_PRODUTO_LOTES_CONSULTAR]}
                label="Filtro Avançado"
                icon={MAKO_ICONS.FILTRAR}
                className="p-button p-component p-button-help p-mr-2"
                onClick={show}
                badge={totalizador > 0 ? totalizador : null}
            />
            <MakoButton
                label="Limpar filtros"
                icon={MAKO_ICONS.LIMPAR_FILTROS}
                className={classNames("p-button p-component p-button-warning", className)}
                onClick={handlerClear}
            />
            <Modals.FiltroAvancado ref={modalRef} onSuccessCallback={onSuccess} />
        </>
    );
};
