import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { Form } from "./form";

import useClearRefs from "@/hooks/useClearRefs";

const Component = ({ onSelectCallback = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);

    const formRef = useRef(null);

    useClearRefs(formRef);

    const show = () => setVisible(true);

    const hide = useCallback(() => setVisible(false), []);

    const onHide = useCallback(() => {
        const selected = formRef.current?.getSelected();
        onSelectCallback(selected);
        hide();
    }, [onSelectCallback, hide]);

    const onConfirm = useCallback(() => {
        const selected = formRef.current?.getSelected();
        onSelectCallback(selected);
        hide();
    }, [hide, onSelectCallback]);

    const onCancel = useCallback(() => {
        onSelectCallback(null);
        hide();
    }, [hide, onSelectCallback]);

    const footer = (
        <>
            <Button label="Cancelar" className="p-button p-button-danger" onClick={onCancel} />
            <Button label="Confirmar" className="p-button" onClick={onConfirm} />
        </>
    );

    useImperativeHandle(ref, () => ({ show, hide }));

    return (
        <Dialog
            visible={visible}
            header="Buscar Ordem de Compra"
            style={{ width: "60vw" }}
            onHide={onHide}
            footer={footer}
        >
            <Form ref={formRef} />
        </Dialog>
    );
};

export const Modal = forwardRef(Component);
