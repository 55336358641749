import React, { useRef, useState } from "react";

import * as Yup from "yup";

import {
    // COMPONENTES_ESPECIAIS,
    MakoFormGerador,
} from "@/components/MakoFormGerador";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
// import { FormCustomFields } from "@/components/FormCustomFields";
import { MakoMultiSelect } from "@/components/MakoInputs/MakoMultiSelect";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { InputText } from "primereact/inputtext";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
// import { PESSOAS_PESSOAFISICA, PESSOAS_PESSOAJURIDICA } from "@/assets/constants/contentTypes";
import { ESTADO_CIVIL, SEXO, TIPO_PESSOA_PESQUISA } from "@/assets/constants/constants";
import { RELATORIO_PESSOAS_RELACAOPESSOAS } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";
import { gerarFileName } from "@/assets/util/util";

import useClearRefs from "@/hooks/useClearRefs";
import useRelatorio from "@/hooks/useRelatorio";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { InputNumber } from "primereact/inputnumber";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";

const { ExportCSV } = R.Buttons;

const OPTIONS_ENDERECO = [
    { label: "Sem endereço", value: true },
    { label: "Com endereço", value: false },
    { label: "Qualquer", value: -1 },
];

const OPTIONS_TELEFONE = [
    { label: "Sem telefone", value: true },
    { label: "Com telefone", value: false },
    { label: "Qualquer", value: -1 },
];

const OPTIONS_EMAIL = [
    { label: "Sem email", value: true },
    { label: "Com email", value: false },
    { label: "Qualquer", value: -1 },
];

const OPTIONS_REDESOCIAL = [
    { label: "Sem rede social", value: true },
    { label: "Com rede social", value: false },
    { label: "Qualquer", value: -1 },
];

const OPTIONS_TAG = [
    { label: "Sem tag", value: true },
    { label: "Com tag", value: false },
    { label: "Qualquer", value: -1 },
];

const FILTROS_VIEWSET = {
    tipo_pessoa: "tipo_pessoa",
    perfil_pj__indicador_inscricao_estadual: "perfil_pj__indicador_inscricao_estadual",
    perfil_pf__estado_civil: "perfil_pf__estado_civil",
    perfil_pj__regime_tributario__id: "perfil_pj__regime_tributario",
    nome__unaccent__icontains: "nome__unaccent__icontains",
    tag__in: "vinculoperfiltag__tag__in",
    perfil_pf__data_nascimento__gte: "perfil_pf__data_nascimento__gte",
    perfil_pf__data_nascimento__lte: "perfil_pf__data_nascimento__lte",
    perfil_pf__sexo: "perfil_pf__sexo",
    nome_curto__icontains: "nome_curto__icontains",
    endereco__isnull: "enderecoperfil__isnull",
    telefone__isnull: "telefoneperfil__isnull",
    email__isnull: "email",
    redesocial__isnull: "redesocialperfil__isnull",
    tag__isnull: "vinculoperfiltag__isnull",
    campo_personalizado: "campo_personalizado",
    tag__in__exclude: "tag__in__exclude",
    idade__gte: "idade__gte",
    idade__lte: "idade__lte",
    municipio: "enderecoperfil__cidade__id",
    tag__endereco: "enderecoperfil__tag_endereco__id",
};

const BASE_URL = "/relatorios/relacao-pessoas/";

const FILE_NAME = gerarFileName("Relacao Pessoas");

export const RelatorioPessoasPage = () => {
    const [url, setUrl] = useState(null);
    const [visualizar, setVisualizar] = useState(false);
    const { solicitarRelatorio } = useRelatorio();
    const [formatDocumento] = useFormatCNPJCPF();

    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(formRef, botaoCsv, listagemRef);

    const filtrosTemplate = [
        {
            key: "tipo_pessoa",
            label: "Tipo pessoa",
            optionLabel: "label",
            optionKey: "id",
            options: TIPO_PESSOA_PESQUISA,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "perfil_pj__indicador_inscricao_estadual",
            label: "Indicativo IE",
            path: "nome",
        },
        {
            key: "perfil_pf__estado_civil",
            label: "Estado civil",
            optionLabel: "label",
            optionKey: "id",
            options: ESTADO_CIVIL,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "perfil_pj__regime_tributario__id",
            label: "Regime tributário",
            path: "nome",
        },
        {
            key: "municipio",
            label: "Município",
            path: "nome",
        },
        {
            key: "tag__endereco",
            label: "TAG endereço",
            path: "tag",
        },
        {
            key: "nome__unaccent__icontains",
            label: "Nome",
        },
        {
            key: "tag__in",
            label: "Possui tag",
            path: "descricao",
            type: TIPOS_FILTROS_TEMPLATE.MULTISELECT,
        },
        {
            key: "tag__in__exclude",
            label: "Não possui a tag",
            path: "descricao",
            type: TIPOS_FILTROS_TEMPLATE.MULTISELECT,
        },
        {
            key: ["perfil_pf__data_nascimento__gte", "perfil_pf__data_nascimento__lte"],
            label: "Data nascimento",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
            dateFormat: "dd/MM",
        },
        {
            key: "perfil_pf__sexo",
            label: "Sexo",
            optionLabel: "label",
            optionKey: "id",
            options: SEXO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "nome_curto__icontains",
            label: "Nome curto",
        },
        {
            key: "endereco__isnull",
            label: "Endereço",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_ENDERECO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "telefone__isnull",
            label: "Telefone",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_TELEFONE,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "email__isnull",
            label: "Email",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_EMAIL,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "redesocial__isnull",
            label: "Rede social",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_REDESOCIAL,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "tag__isnull",
            label: "Tag",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_TAG,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "idade__gte",
            label: "Idade mínima",
        },
        {
            key: "idade__lte",
            label: "Idade máxima",
        },
    ];

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const handleVisualizar = (e) => {
        setVisualizar(true);
        submit(e, false);
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setVisualizar(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key] !== undefined && dados[key] !== null && dados[key] !== "") {
                    if (key === "perfil_pf__data_nascimento__gte")
                        return (filtros[key] = dataToStr(dados[key], "MM-dd"));
                    if (key === "perfil_pf__data_nascimento__lte")
                        return (filtros[key] = dataToStr(dados[key], "MM-dd"));
                    if (key === "tag__in" && dados[key]?.length)
                        return (filtros[key] = dados[key].map((item) => item.id));
                    if (key === "tag__in__exclude" && dados[key]?.length)
                        return (filtros[key] = dados[key].map((item) => item.id));
                    if (key === "campo_personalizado") {
                        filtros[key] = [];
                        return (filtros[key] = dados[key].map((item) => ({
                            id: item.value.field,
                            value: item.value.value,
                        })));
                    }
                    return (filtros[key] = dados[key]);
                }
            });
        }
        return filtros;
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({});

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.perfil_pj__regime_tributario__id)
                dadosValidados.perfil_pj__regime_tributario__id = dadosValidados.perfil_pj__regime_tributario__id.id;
            if (dadosValidados.perfil_pj__indicador_inscricao_estadual)
                dadosValidados.perfil_pj__indicador_inscricao_estadual =
                    dadosValidados.perfil_pj__indicador_inscricao_estadual.id;
            if (dadosValidados.municipio) dadosValidados.municipio = dadosValidados.municipio.id;
            if (dadosValidados.tag__endereco) dadosValidados.tag__endereco = dadosValidados.tag__endereco.id;

            if (dadosValidados.endereco__isnull === -1) delete dadosValidados.endereco__isnull;
            if (dadosValidados.telefone__isnull === -1) delete dadosValidados.telefone__isnull;
            if (dadosValidados.email__isnull === -1) delete dadosValidados.email__isnull;
            if (dadosValidados.redesocial__isnull === -1) delete dadosValidados.redesocial__isnull;
            if (dadosValidados.tag__isnull === -1) delete dadosValidados.tag__isnull;

            if (!visualizar) {
                let filtros = {};
                if (dadosValidados) {
                    filtros = gerarFiltrosDadosValidados(dadosValidados);
                }
                solicitarRelatorio({
                    chave: RELATORIO_PESSOAS_RELACAOPESSOAS,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
                limparFormulario();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        if (k === "perfil_pf__data_nascimento__gte" || k === "perfil_pf__data_nascimento__lte") {
                            filtros.push(`${FILTROS_VIEWSET[k]}=${dataToStr(v, "MM-dd")}`);
                            continue;
                        }
                        if (k === "campo_personalizado") {
                            let campos = [];
                            campos = v.map(({ value }) => {
                                return `${value.value}||${value.field}`;
                            });
                            filtros.push(`${FILTROS_VIEWSET[k]}=${campos}`);
                            continue;
                        }
                        if (k === "tag__in") {
                            filtros.push(`${FILTROS_VIEWSET[k]}=${v.map((item) => item.id)}`);
                            continue;
                        }
                        if (k === "tag__in__exclude") {
                            filtros.push(`${FILTROS_VIEWSET[k]}=${v.map((item) => item.id)}`);
                            continue;
                        }
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const handleChangeTipo = (e) => {
        const { value } = e;
        setFieldValue("campo_personalizado", null);
        if (value !== "PF") setFieldValue("perfil_pf__sexo", null);
        if (value === "PJ") setFieldValue("perfil_pf__estado_civil", null);
        if (value !== "PJ") {
            setFieldValue("perfil_pj__indicador_inscricao_estadual", null);
            setFieldValue("perfil_pj__regime_tributario__id", null);
        }
    };

    const handleChangeTag = (e) => {
        const { value } = e;
        if (!!value) setFieldValue("tag__in", null);
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "4%" } },
        { field: "nome", header: "Nome / Razão Social" },
        {
            field: "identificacao",
            header: "Documento",
            action: ({ identificacao }) => formatDocumento(identificacao),
            style: { width: "14%" },
        },
        { field: "telefone", header: "Telefone", style: { width: "14%" } },
        { field: "email", header: "E-mail" },
    ];

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.perfil_pj__regime_tributario__id)
            dadosValidados.perfil_pj__regime_tributario__id = dadosValidados.perfil_pj__regime_tributario__id.id;
        if (dadosValidados.perfil_pj__indicador_inscricao_estadual)
            dadosValidados.perfil_pj__indicador_inscricao_estadual =
                dadosValidados.perfil_pj__indicador_inscricao_estadual.id;
        if (dadosValidados.municipio) dadosValidados.municipio = dadosValidados.municipio.id;
        if (dadosValidados.tag__endereco) dadosValidados.tag__endereco = dadosValidados.tag__endereco.id;

        if (dadosValidados.endereco__isnull === -1) delete dadosValidados.endereco__isnull;
        if (dadosValidados.telefone__isnull === -1) delete dadosValidados.telefone__isnull;
        if (dadosValidados.email__isnull === -1) delete dadosValidados.email__isnull;
        if (dadosValidados.redesocial__isnull === -1) delete dadosValidados.redesocial__isnull;
        if (dadosValidados.tag__isnull === -1) delete dadosValidados.tag__isnull;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_PESSOAS_RELACAOPESSOAS}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    return (
        <R.Wrapper titulo={"de relação de pessoas"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        tipo_pessoa: null,
                        perfil_pj__indicador_inscricao_estadual: null,
                        perfil_pf__estado_civil: null,
                        perfil_pj__regime_tributario__id: null,
                        nome__unaccent__icontains: "",
                        tag__in: null,
                        tag__in__exclude: null,
                        perfil_pf__data_nascimento__gte: null,
                        perfil_pf__data_nascimento__lte: null,
                        perfil_pf__sexo: null,
                        nome_curto__icontains: "",
                        endereco__isnull: -1,
                        telefone__isnull: -1,
                        email__isnull: -1,
                        redesocial__isnull: -1,
                        tag__isnull: -1,
                        campo_personalizado: null,
                        idade__gte: null,
                        idade__lte: null,
                        tag__endereco: null,
                        background: true,
                        emails: [],
                        corpo_email: "",
                        municipio: "",
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Contém no nome",
                        inputId: "nome__unaccent__icontains",
                        inputName: "nome__unaccent__icontains",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                            keyfilter: /^[a-zA-Z ]/,
                            autoComplete: "off",
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Tipo de pessoa",
                        inputId: "tipo_pessoa",
                        inputName: "tipo_pessoa",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Qualquer",
                            options: TIPO_PESSOA_PESQUISA,
                            optionValue: "id",
                            optionLabel: "label",
                            showClear: true,
                            getOnChange: handleChangeTipo,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Contém a TAG",
                        inputId: "tag__in",
                        inputName: "tag__in",
                        component: MakoMultiSelect,
                        handleDisable: ({ tag__isnull }) => tag__isnull === true,
                        componentProps: {
                            placeholder: "Selecione uma tag",
                            url: "/pessoas/tags-perfis?limit=300",
                            optionLabel: "descricao",
                            showClear: true,
                            filter: true,
                            filterBy: "descricao",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Não contém a TAG",
                        inputId: "tag__in__exclude",
                        inputName: "tag__in__exclude",
                        component: MakoMultiSelect,
                        handleDisable: ({ tag__isnull }) => tag__isnull === true,
                        componentProps: {
                            placeholder: "Selecione uma tag",
                            url: "/pessoas/tags-perfis?limit=300",
                            optionLabel: "descricao",
                            showClear: true,
                            filter: true,
                            filterBy: "descricao",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Estado civil",
                        inputId: "perfil_pf__estado_civil",
                        inputName: "perfil_pf__estado_civil",
                        component: Dropdown,
                        handleDisable: ({ tipo_pessoa }) => tipo_pessoa === "PJ",
                        componentProps: {
                            placeholder: "Qualquer",
                            options: ESTADO_CIVIL,
                            optionValue: "id",
                            optionLabel: "label",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Indicativo IE",
                        inputId: "perfil_pj__indicador_inscricao_estadual",
                        inputName: "perfil_pj__indicador_inscricao_estadual",
                        component: Dropdown,
                        handleDisable: ({ tipo_pessoa }) => tipo_pessoa !== "PJ",
                        componentProps: {
                            placeholder: "Qualquer",
                            url: "/pessoas/indicadores-inscricao-estadual/",
                            optionLabel: "descricao",
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Regime tributário",
                        inputId: "perfil_pj__regime_tributario__id",
                        inputName: "perfil_pj__regime_tributario__id",
                        component: Dropdown,
                        handleDisable: ({ tipo_pessoa }) => tipo_pessoa !== "PJ",
                        componentProps: {
                            placeholder: "Qualquer",
                            url: "/pessoas/regime-tributario/",
                            optionLabel: "descricao",
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Dia/mês nasc. inicial",
                        inputId: "perfil_pf__data_nascimento__gte",
                        inputName: "perfil_pf__data_nascimento__gte",
                        component: MakoCalendar,
                        handleDisable: ({ tipo_pessoa }) => tipo_pessoa === "PJ",
                        componentProps: {
                            dateFormat: "dd/mm",
                            mask: "99/99",
                            placeholder: "dd/mm",
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Dia/mês nasc. final",
                        inputId: "perfil_pf__data_nascimento__lte",
                        inputName: "perfil_pf__data_nascimento__lte",
                        component: MakoCalendar,
                        handleDisable: ({ tipo_pessoa }) => tipo_pessoa === "PJ",
                        componentProps: {
                            dateFormat: "dd/mm",
                            mask: "99/99",
                            placeholder: "dd/mm",
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Idade mínima",
                        inputId: "idade__gte",
                        inputName: "idade__gte",
                        component: InputNumber,
                        componentProps: {
                            eventChangeKey: "onValueChange",
                            useGrouping: false,
                            min: 0,
                            autoComplete: "off",
                        },
                        fieldSize: 1,
                    },
                    {
                        label: "Idade máxima",
                        inputId: "idade__lte",
                        inputName: "idade__lte",
                        component: InputNumber,
                        componentProps: {
                            eventChangeKey: "onValueChange",
                            useGrouping: false,
                            min: 0,
                            autoComplete: "off",
                        },
                        fieldSize: 1,
                    },
                    {
                        label: "Contém no nome curto",
                        inputId: "nome_curto__icontains",
                        inputName: "nome_curto__icontains",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                            keyfilter: /^[a-zA-Z ]/,
                            autoComplete: "off",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Município",
                        inputId: "municipio",
                        inputName: "municipio",
                        component: MakoAutoComplete,
                        componentProps: {
                            placeholder: "Digite para pesquisar... (min 4 caracteres)",
                            urlSearch: `/pessoas/cidades/?query={id,nome}&limit=1000&ordering=nome&nome__unaccent__icontains=`,
                            field: "nome",
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "TAG endereço",
                        inputId: "tag__endereco",
                        inputName: "tag__endereco",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Qualquer",
                            url: "/pessoas/tags-enderecos-perfis/?limit=200",
                            optionLabel: "tag",
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Sexo",
                        inputId: "perfil_pf__sexo",
                        inputName: "perfil_pf__sexo",
                        component: Dropdown,
                        handleDisable: ({ tipo_pessoa }) => tipo_pessoa === "PJ",
                        componentProps: {
                            placeholder: "Qualquer",
                            options: SEXO,
                            optionValue: "id",
                            optionLabel: "label",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Endereço",
                        inputId: "endereco__isnull",
                        inputName: "endereco__isnull",
                        component: Dropdown,
                        componentProps: {
                            options: OPTIONS_ENDERECO,
                            optionValue: "value",
                            optionLabel: "label",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Telefone",
                        inputId: "telefone__isnull",
                        inputName: "telefone__isnull",
                        component: Dropdown,
                        componentProps: {
                            options: OPTIONS_TELEFONE,
                            optionValue: "value",
                            optionLabel: "label",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "E-mail",
                        inputId: "email__isnull",
                        inputName: "email__isnull",
                        component: Dropdown,
                        componentProps: {
                            options: OPTIONS_EMAIL,
                            optionValue: "value",
                            optionLabel: "label",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Rede social",
                        inputId: "redesocial__isnull",
                        inputName: "redesocial__isnull",
                        component: Dropdown,
                        componentProps: {
                            options: OPTIONS_REDESOCIAL,
                            optionValue: "value",
                            optionLabel: "label",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Tag",
                        inputId: "tag__isnull",
                        inputName: "tag__isnull",
                        component: Dropdown,
                        componentProps: {
                            options: OPTIONS_TAG,
                            optionValue: "value",
                            optionLabel: "label",
                            getOnChange: handleChangeTag,
                        },
                        fieldSize: 2,
                    },
                    // {
                    //     label: "Campos Personalizados",
                    //     inputId: "campo_personalizado",
                    //     inputName: "campo_personalizado",
                    //     component: FormCustomFields,
                    //     render: ({ tipo_pessoa }) => tipo_pessoa !== null,
                    //     componentProps: {
                    //         instance: null,
                    //         contentType: ({ tipo_pessoa }) =>
                    //             tipo_pessoa === "PF"
                    //                 ? PESSOAS_PESSOAFISICA
                    //                 : tipo_pessoa === "PJ"
                    //                 ? PESSOAS_PESSOAJURIDICA
                    //                 : null,
                    //     },
                    //     componentEspecial: COMPONENTES_ESPECIAIS.CUSTOMFIELD,
                    //     fieldSize: 12,
                    // },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_PESSOAS_RELACAOPESSOAS}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
