import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";

import { InputText } from "primereact/inputtext";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";

import useRelatorio from "@/hooks/useRelatorio";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_FINANCEIRO_CONTASAPAGAR, RELATORIO_FINANCEIRO_CONTASARECEBER } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";

import useClearRefs from "@/hooks/useClearRefs";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import MakoListagem from "@/components/MakoListagem";
import { formatarCasasDecimais } from "@/assets/util/util";

import { Dropdown } from "@/components/Dropdown";
import { MakoInputCliente } from "@/components/MakoInputs";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

const BASE_URL = "/relatorios/a-receber/";

const FILTROS_VIEWSET = {
    empresa: "empresa__id",
    periodo_emissao_start: "data_emissao__gte",
    periodo_emissao_end: "data_emissao__lte",
    periodo_vencimento_start: "vencimento__gte",
    periodo_vencimento_end: "vencimento__lte",
    cliente: "devedor__id",
    faixa_valor_start: "valor__gte",
    faixa_valor_end: "valor__lte",
    forma_recebimento: "forma_recebimento",
    parcela: "numero_parcela",
    orderBy:  "order",
    conta_financeira: "conta_financeira",
    situacao: "situacao",
};

export const RelatorioAReceber = () => {
    const [url, setUrl] = useState(null);
    const formRef = useRef();
    const empresaSelecionada = useRef();
    const listagemRef = useRef();
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [formatarDocumento] = useFormatCNPJCPF()


    useClearRefs(empresaSelecionada);

    const { solicitarRelatorio } = useRelatorio();

    const ORDENACAO_FILTROS = [
        { value: "data_emissao", label: "Emissão (crescente)" },
        { value: "-data_emissao", label: "Emissão (decrescente)" },
        { value: "devedor", label: "Cliente" },
        { value: "forma_recebimento", label: "Forma Recebimento" },
        { value: "numero_parcela", label: "Parcela" },
        { value: "valor", label: "Valor (crescente)" },
        { value: "-valor", label: "Valor (decrescente)" },
        { value: "+vencimento", label: "Vencimento (crescente)" },
        { value: "-vencimento", label: "Vencimento (decrescente)" },
    ];


    const OPTIONS_SITUACAO = [
        {label: "Todas", value: "todas"},
        {label: "Em Aberto", value: "abertas"},
        {label: "Em Atraso", value: "atrasadas"},
        {label: "Recebido", value: "recebido"}
    ]

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["periodo_emissao_start", "periodo_emissao_end"],
            label: "Período emissão",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: ["periodo_vencimento_start", "periodo_vencimento_end"],
            label: "Período vencimento",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "cliente",
            label: "Cliente",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "forma_recebimento",
            label: "Forma Recebimento",
            optionKey: "id",
            optionLabel: "label",
            options: TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "codigo",
            label: "Código",
        },
        {
            key: "parcela",
            label: "Parcela",
        },
        {
            key: ["faixa_valor_start", "faixa_valor_end"],
            label: "Faixa valor",
            type: TIPOS_FILTROS_TEMPLATE.MONEYPERIODO,
        },
        {
            key: "orderBy",
            label: "Ordenado por",
            optionKey: "value",
            optionLabel: "label",
            options: ORDENACAO_FILTROS,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "situacao",
            label: "Situação",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_SITUACAO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE
        }
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                periodo_emissao_start: Yup.date()
                    .nullable()
                    .typeError("Informe um 'periodo de emissão inicial' válido"),
                periodo_emissao_end: Yup.date()
                    .when("periodo_emissao_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.periodo_emissao_start || new Date(),
                                "A 'data de emissao final' não pode ser anterior a inicial"
                            )

                            .typeError("Informe um 'periodo de emissao final' válido")
                            .required("Informe um 'periodo de emissao final' válido"),
                        otherwise: Yup.date().nullable(),
                    }),
                periodo_vencimento_start: Yup.date()
                    .nullable()
                    .typeError("Informe um 'periodo de vencimento inicial' válido"),
                periodo_vencimento_end: Yup.date()
                    .when("periodo_vencimento_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.periodo_vencimento_start || new Date(),
                                "A 'data de vencimento final' não pode ser anterior a inicial"
                            )
                            .typeError("Informe um 'periodo de vencimento final' válido"),
                        otherwise: Yup.date().nullable(),
                    })
                    .nullable()
                    .typeError("Informe um 'periodo de vencimento final' válido"),
                empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório")
                    .typeError("Informe uma 'empresa' válida"),
                cliente: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'fornecedor' válida."),
                faixa_valor_start: Yup.number().nullable().typeError("Informe uma 'faixa de valor inicial' válida"),
                faixa_valor_end: Yup.number()
                    .when("faixa_valor_start", {
                        is: (val) => val != null,
                        then: Yup.number()
                            .test(
                                "valor validation",
                                "A 'faixa de valor final' não pode ser menor que a inicial",
                                (val) => {
                                    if (val == null) return true;

                                    return typeof val === "number" && val >= values.faixa_valor_start;
                                }
                            )
                            .typeError("Informe um 'faixa de valor final' válido"),
                    })
                    .nullable()
                    .typeError("Informe uma 'faixa de valor final' válida"),
                forma_pgto: Yup.string().nullable().typeError("Informe uma 'forma de pagamento' válida"),
                parcela: Yup.string()
                    .nullable()
                    .typeError("Informe uma 'quantidade de parcela' válida"),
                codigo: Yup.string()
                    .nullable()
                    .test("codigo validation", "O 'codigo' deve ser maior que 0", (val) => {
                        if (val?.length >= 1 && parseInt(val) > 0) return true;
                        return !val;
                    })
                    .typeError("Informe um 'código' válido"),
                orderBy: Yup.string().nullable().typeError("Seleciona uma 'ordenação' válida."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
                cliente: dadosValidados?.cliente?.id ? dadosValidados?.cliente : null,
            });

            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.fornec) dadosValidados.fornec = dadosValidados.fornec.id;
            if (dadosValidados.periodo_emissao_start !== null)
                dadosValidados.periodo_emissao_start = dataToStr(dadosValidados.periodo_emissao_start, "yyyy-MM-dd");
            if (dadosValidados.periodo_emissao_end !== null)
                dadosValidados.periodo_emissao_end = dataToStr(dadosValidados.periodo_emissao_end, "yyyy-MM-dd");
            if (dadosValidados.periodo_vencimento_start !== null)
                dadosValidados.periodo_vencimento_start = dataToStr(
                    dadosValidados.periodo_vencimento_start,
                    "yyyy-MM-dd"
                );
            if (dadosValidados.periodo_vencimento_end !== null)
                dadosValidados.periodo_vencimento_end = dataToStr(dadosValidados.periodo_vencimento_end, "yyyy-MM-dd");

            if (dadosValidados.cliente) dadosValidados.cliente = dadosValidados.cliente.id

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            
            if(buscarListagem === false){
                let filtros = {};
                if (dadosValidados) {
                    Object.keys(dadosValidados).forEach((key) => {
                        if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                    });
                    solicitarRelatorio({
                        chave: RELATORIO_FINANCEIRO_CONTASARECEBER,
                        emails,
                        filtros,
                        versao,
                        filtros_template: filtrosTemplateAplicados,
                        corpo_email,
                        enviar_fila: background,
                    });
                }
            }
            else{
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    console.log(k,v)
                    if (v !== null && v !== "") {
                        if ((k === 'faixa_valor_start' && v === 0)) continue
                        if ((k === 'faixa_valor_end' && v === 0)) continue
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                console.log(_url)
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "empresa", header: "Empresa"},
        { field: 'documento', header: "Documento"},
        { field: "devedor", header: "Cliente", action: (e) => {
            return e.devedor + " - " + formatarDocumento(e.documento_identificacao)
        }},
        { field: "parcela", header: "Parcela"},
        { field: "emissao", header: "Emissão", action: (e) => dataToStr(e.emissao, "dd/MM/yyyy")},
        { field: "vencimento", header: "Vencimento", action: (e) => dataToStr(e.vencimento, "dd/MM/yyyy")},
        { field: "forma_recebimento", header: "Forma recebimento"},
        { field: "valor", header: "Valor",align: "right",action: (e) => `${formatarCasasDecimais(e.valor,2, {minimumFractionDigits: 2})}`},
    ]

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setBuscarListagem(false)
        setUrl(null);
    };

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    }

    return (
        <R.Wrapper titulo={"contas a receber"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        periodo_emissao_start: null,
                        periodo_emissao_end: null,
                        periodo_vencimento_start: null,
                        periodo_vencimento_end: null,
                        empresa: null,
                        cliente: null,
                        faixa_valor_start: 0,
                        faixa_valor_end: 0,
                        forma_recebimento: null,
                        parcela: null,
                        codigo: "",
                        orderBy: "devedor",
                        situacao: "todas"
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa *",
                        inputId: "empresa",
                        inputName: "empresa",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            placeholder: "Selecione uma empresa",
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Periodo emissão",
                        inputId: "periodo_emissao_start",
                        inputName: "periodo_emissao_start",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "periodo_emissao_end",
                        inputName: "periodo_emissao_end",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Periodo Vencimento: ",
                        inputId: "periodo_vencimento_start",
                        inputName: "periodo_vencimento_start",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "periodo_vencimento_end",
                        inputName: "periodo_vencimento_end",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Cliente: ",
                        inputId: "cliente",
                        inputName: "cliente",
                        component: MakoInputCliente,
                        fieldSize: 12,
                        componentProps: {
                            optionLabel: "nome",
                            optionValue: "id",
                        },
                    },
                    {
                        label: "Conta financeira:",
                        inputId: "conta_financeira",
                        inputName: "conta_financeira",
                        component: Dropdown,
                        componentProps: {
                            url: "/financeiro/contas-financeiras/",
                            optionLabel: "descricao",
                            optionValue: "id"
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Parcela:",
                        inputId: "parcela",
                        inputName: "parcela",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                            min: 0,
                            // mode: "decimal",
                            // useGrouping: false,
                        },
                    },
                    {
                        label: "Forma de recebimento:",
                        inputId: "forma_recebimento",
                        inputName: "forma_recebimento",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione uma forma de recebimento",
                            filter: true,
                            filterBy: "label",
                            optionValue: "id",
                            optionLabel: "label",
                            options: TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Faixa de valor:",
                        inputId: "faixa_valor_start",
                        inputName: "faixa_valor_start",
                        component: MakoInputMoeda,
                        componentProps: {
                            eventChangeKey: "onChangeMoeda",
                            valueKey: "valueMoeda"
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "faixa_valor_end",
                        inputName: "faixa_valor_end",
                        component: MakoInputMoeda,
                        componentProps: {
                            eventChangeKey: "onChangeMoeda",
                            valueKey: "valueMoeda"
                        },
                        fieldSize: 2,
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                    },
                    {
                        label: "Situação",
                        inputId: "situacao",
                        inputName: "situacao",
                        component: Dropdown,
                        componentProps: {
                            optionLabel: "label",
                            optionValue: "value",
                            options: OPTIONS_SITUACAO,
                            showClear: false,
                        }
                    },
                    {
                        label: "Ordenado por: ",
                        inputId: "orderBy",
                        inputName: "orderBy",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione uma forma de ordenação",
                            filter: true,
                            filterBy: "label",
                            options: ORDENACAO_FILTROS,
                            optionLabel: "label",
                            optionValue: "value",
                            showClear: false,
                        },
                        fieldSize: 4,
                    },
                ]}
            >
                <R.Buttons.Wrapper>
                    <R.Buttons.Visualizar onClick={handleVisualizar} />
                    <R.Buttons.GerarPdf
                        chave={RELATORIO_FINANCEIRO_CONTASAPAGAR}
                        setFieldValue={setFieldValue}
                        handleSubmit={submit}
                    />
                    <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                    <R.Buttons.Limpar onClick={limparFormulario} />
                </R.Buttons.Wrapper>
            </MakoFormGerador>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={url}
                msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}>
                
            </MakoListagem>
        </R.Wrapper>
    );
};