import React, { useCallback, useRef, useState } from "react";

import * as Yup from "yup";

import { InputText } from "primereact/inputtext";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoDropdownOrdenado } from "@/components/MakoInputs";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoInputPeriodo } from "@/components/MakoInputs/MakoInputPeriodo";
import MakoListagem from "@/components/MakoListagem";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_VENDAS_ITENSFATURADOSVENDA } from "@/assets/constants/relatorios";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

const { ExportCSV } = R.Buttons;

const OPTIONS_ORDENACAO_FILTROS = [
    { value: "nota_fiscal__datahora_emissao", label: "Emissão NF" },
    { value: "nota_fiscal__venda__data", label: "Data venda" },
    { value: "nota_fiscal__numero", label: "Número NF" },
    { value: "codigo_item", label: "Código item" },
    { value: "descricao_reduzida", label: "Descrição item" },
];

const BASE_URL = "/relatorios/itens-faturados-venda/";

const FILE_NAME = gerarFileName("Itens faturados venda");

const FILTROS_VIEWSET = {
    nota_fiscal__datahora_emissao__lte: "nota_fiscal__datahora_emissao__lte",
    nota_fiscal__datahora_emissao__gte: "nota_fiscal__datahora_emissao__gte",
    nota_fiscal__venda__data__lte: "nota_fiscal__venda__data__lte",
    nota_fiscal__venda__data__gte: "nota_fiscal__venda__data__gte",
    nota_fiscal__venda__cliente: "nota_fiscal__venda__cliente",
    codigo_item__contains: "codigo_item__contains",
    descricao_reduzida__unaccent__icontains: "descricao_reduzida__unaccent__icontains",
    item__categoriasku__categoria: "item__categoriasku__categoria",
    ordering: "ordering",
};

export const RelatorioItensFaturadosVenda = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const ordenacoesRef = useRef();
    const categoriaRef = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(categoriaRef, formRef, botaoCsv, listagemRef, ordenacoesRef);

    const filtrosTemplate = [
        {
            key: ["nota_fiscal__datahora_emissao__gte", "nota_fiscal__datahora_emissao__lte"],
            label: "Emissão NF",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: ["nota_fiscal__venda__data__gte", "nota_fiscal__venda__data__lte"],
            label: "Data venda",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "nota_fiscal__venda__cliente",
            label: "Cliente",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "codigo_item__contains",
            label: "Código item",
        },
        {
            key: "descricao_reduzida__unaccent__icontains",
            label: "Descricão item",
        },
        {
            key: "item__categoriasku__categoria",
            label: "Categoria",
            path: "descricao",
        },
        {
            key: "ordering",
            label: "Ordenado por",
            path: "label",
        },
    ];

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.nota_fiscal__venda__cliente)
            dadosValidados.nota_fiscal__venda__cliente = dadosValidados.nota_fiscal__venda__cliente.id;
        if (dadosValidados.nota_fiscal__datahora_emissao__gte instanceof Date)
            dadosValidados.nota_fiscal__datahora_emissao__gte = `${dataToStr(
                dadosValidados.nota_fiscal__datahora_emissao__gte,
                "yyyy-MM-dd"
            )} 23:59:59`;
        if (dadosValidados.nota_fiscal__datahora_emissao__lte instanceof Date)
            dadosValidados.nota_fiscal__datahora_emissao__lte = `${dataToStr(
                dadosValidados.nota_fiscal__datahora_emissao__lte,
                "yyyy-MM-dd"
            )} 00:00:00`;
        if (dadosValidados.nota_fiscal__venda__data__gte instanceof Date)
            dadosValidados.nota_fiscal__venda__data__gte = `${dataToStr(
                dadosValidados.nota_fiscal__venda__data__gte,
                "yyyy-MM-dd"
            )} 23:59:59`;
        if (dadosValidados.nota_fiscal__venda__data__lte instanceof Date)
            dadosValidados.nota_fiscal__venda__data__lte = `${dataToStr(
                dadosValidados.nota_fiscal__venda__data__lte,
                "yyyy-MM-dd"
            )} 00:00:00`;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                nota_fiscal__datahora_emissao__gte: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Informe um 'emissão inicial' válido"),
                nota_fiscal__datahora_emissao__lte: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Informe um 'emissão final' válido"),
                nota_fiscal__venda__data__gte: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Informe um 'data venda inicial' válido"),
                nota_fiscal__venda__data__lte: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Informe um 'data venda final' válido"),
                nota_fiscal__venda__cliente: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'cliente' válida"),
                codigo_item__contains: Yup.string().typeError("Informe um 'informe um código' válido"),
                descricao_reduzida__unaccent__icontains: Yup.string().typeError(
                    "Informe um 'informe um código' válido"
                ),
                item__categoriasku__categoria: Yup.number().nullable().typeError("Informe uma 'categoria' válida."),
                ordering: Yup.string().nullable().typeError("Seleciona uma 'ordenação' válida."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            const ordenacoes = ordenacoesRef.current;

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                item__categoriasku__categoria: categoriaRef.current,
                ordering: ordenacoes.find(({ value }) => value === dadosValidados.ordering),
            });

            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.nota_fiscal__venda__cliente)
                dadosValidados.nota_fiscal__venda__cliente = dadosValidados.nota_fiscal__venda__cliente.id;
            if (dadosValidados.nota_fiscal__datahora_emissao__gte instanceof Date)
                dadosValidados.nota_fiscal__datahora_emissao__gte = `${dataToStr(
                    dadosValidados.nota_fiscal__datahora_emissao__gte,
                    "yyyy-MM-dd"
                )} 00:00:00`;
            if (dadosValidados.nota_fiscal__datahora_emissao__lte instanceof Date)
                dadosValidados.nota_fiscal__datahora_emissao__lte = `${dataToStr(
                    dadosValidados.nota_fiscal__datahora_emissao__lte,
                    "yyyy-MM-dd"
                )} 23:59:59`;
            if (dadosValidados.nota_fiscal__venda__data__gte instanceof Date)
                dadosValidados.nota_fiscal__venda__data__gte = dataToStr(
                    dadosValidados.nota_fiscal__venda__data__gte,
                    "yyyy-MM-dd"
                );
            if (dadosValidados.nota_fiscal__venda__data__lte instanceof Date)
                dadosValidados.nota_fiscal__venda__data__lte = dataToStr(
                    dadosValidados.nota_fiscal__venda__data__lte,
                    "yyyy-MM-dd"
                );

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_VENDAS_ITENSFATURADOSVENDA,
                    emails,
                    corpo_email,
                    filtros,
                    filtros_template: filtrosTemplateAplicados,
                    versao,
                    enviar_fila: background,
                });
                limparFormulario();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "orcamento", header: "Orçamento", style: { width: "8%" } },
        {
            field: "numero_nf",
            header: "N°. NF",
            style: { width: "8%" },
            align: "center",
        },
        { field: "data", header: "Emissão", style: { width: "8%" }, dateFormat: "dd/MM/yyyy", align: "center" },
        {
            field: "codigo",
            header: "Cód. ",
            style: { width: "8%" },
            align: "center",
        },
        { field: "descricao", header: "Descrição" },
        {
            field: "cst_icms",
            header: "ICMS",
            style: { width: "8%" },
            align: "center",
        },
        {
            field: "cfop",
            header: "CFOP",
            style: { width: "8%" },
            align: "center",
        },
        {
            field: "quantidade",
            header: "Venda",
            align: "center",
            decimal: true,
        },
        {
            field: "quantidade_nf",
            header: "NF",
            align: "center",
            decimal: true,
        },
        { field: "unitario_vd", header: "Venda", money: true },
        { field: "unitario_nf", header: "NF", money: true },
        { field: "subtotal_vd", header: "Venda", money: true },
        { field: "subtotal_nf", header: "NF", money: true },
    ];

    const colunasGroup = [
        [
            { rowSpan: 2, field: "orcamento", header: "Orçamento", style: { width: "8%" } },
            {
                rowSpan: 2,
                field: "numero_nf",
                header: "N°. NF",
                style: { width: "8%" },
                align: "center",
            },
            {
                rowSpan: 2,
                field: "data",
                header: "Emissão",
                style: { width: "8%" },
                dateFormat: "dd/MM/yyyy",
                align: "center",
            },
            {
                rowSpan: 2,
                field: "codigo",
                header: "Cód. ",
                style: { width: "8%" },
                align: "center",
            },
            { rowSpan: 2, field: "descricao", header: "Descrição" },
            {
                rowSpan: 2,
                field: "cst_icms",
                header: "ICMS",
                style: { width: "8%" },
                align: "center",
            },
            {
                rowSpan: 2,
                field: "cfop",
                header: "CFOP",
                style: { width: "8%" },
                align: "center",
            },
            { colSpan: 2, header: "QTD", align: "center" },
            { colSpan: 2, header: "UNITÀRIO", align: "center" },
            { colSpan: 2, header: "SUB. TOTAL", align: "center" },
        ],
        [
            { field: "quantidade", header: "Venda", align: "center", decimal: true },
            { field: "quantidade_nf", header: "NF", align: "center", decimal: true },
            { field: "unitario_vd", header: "Venda", style: { width: "5%" }, money: true },
            { field: "unitario_nf", header: "NF", style: { width: "5%" }, money: true },
            { field: "subtotal_vd", header: "Venda", style: { width: "5%" }, money: true },
            { field: "subtotal_nf", header: "NF", style: { width: "5%" }, money: true },
        ],
    ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_VENDAS_ITENSFATURADOSVENDA}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const onChangeCategoria = (e) => {
        categoriaRef.current = e;
    };

    const getOrdenacoes = useCallback((data = []) => {
        ordenacoesRef.current = data;
    }, []);

    return (
        <R.Wrapper titulo={"itens faturados venda"} status={R.STATUS.VALIDACAO}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        nota_fiscal__datahora_emissao__lte: null,
                        nota_fiscal__datahora_emissao__gte: null,
                        nota_fiscal__venda__data__lte: null,
                        nota_fiscal__venda__data__gte: null,
                        nota_fiscal__venda__cliente: null,
                        codigo_item__contains: "",
                        descricao_reduzida__unaccent__icontains: "",
                        item__categoriasku__categoria: null,
                        ordering: "",
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        inputId: "emissao",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        componentProps: {
                            label: "Emissão NF",
                            nameInicio: "nota_fiscal__datahora_emissao__gte",
                            nameFinal: "nota_fiscal__datahora_emissao__lte",
                            valueInicio: "nota_fiscal__datahora_emissao__gte",
                            valueFinal: "nota_fiscal__datahora_emissao__lte",
                            errorInicio: "nota_fiscal__datahora_emissao__gte",
                            errorFinal: "nota_fiscal__datahora_emissao__lte",
                        },
                    },
                    {
                        inputId: "data_venda",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        componentProps: {
                            label: "Data venda",
                            nameInicio: "nota_fiscal__venda__data__gte",
                            nameFinal: "nota_fiscal__venda__data__lte",
                            valueInicio: "nota_fiscal__venda__data__gte",
                            valueFinal: "nota_fiscal__venda__data__lte",
                            errorInicio: "nota_fiscal__venda__data__gte",
                            errorFinal: "nota_fiscal__venda__data__lte",
                        },
                    },
                    {
                        label: "Categoria",
                        inputId: "categoria",
                        inputName: "item__categoriasku__categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: onChangeCategoria,
                            showClear: true,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Cliente",
                        inputId: "nota_fiscal__venda__cliente",
                        inputName: "nota_fiscal__venda__cliente",
                        component: MakoInputCliente,
                        fieldSize: 3,
                    },
                    {
                        label: "Descrição item",
                        inputId: "descricao_reduzida__unaccent__icontains",
                        inputName: "descricao_reduzida__unaccent__icontains",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Código item",
                        inputId: "codigo_item__contains",
                        inputName: "codigo_item__contains",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Ordenado por",
                        inputId: "ordering",
                        inputName: "ordering",
                        component: MakoDropdownOrdenado,
                        componentProps: {
                            campos: OPTIONS_ORDENACAO_FILTROS,
                            getOptions: getOrdenacoes,
                        },
                        fieldSize: 4,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_VENDAS_ITENSFATURADOSVENDA}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    colunasGroup={colunasGroup}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
