import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { RELATORIO_FINANCEIRO_EXTRATOCONTA } from "@/assets/constants/relatorios";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";

import useClearRefs from "@/hooks/useClearRefs";
import useRelatorio from "@/hooks/useRelatorio";

const { ExportCSV } = R.Buttons;

const FILTROS_VIEWSET = {
    conta: "conta_financeira",
    data: "data_movimentacao",
};

const BASE_URL = "/relatorios/extrato-conta/";

const FILE_NAME = gerarFileName("Extrato conta");

export const RelatorioExtratoConta = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(formRef, listagemRef, botaoCsv);

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.data !== null) dadosValidados.data = dataToStr(dadosValidados.data, "yyyy-MM-dd");
        if (dadosValidados.conta) dadosValidados.conta = dadosValidados.conta.id;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const filtrosTemplate = [
        {
            key: "conta",
            label: "Conta",
            path: "descricao",
        },
        {
            key: "data",
            label: "Data",
            type: TIPOS_FILTROS_TEMPLATE.DATE,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                conta: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'conta' é obrigatório."),
                    })
                    .default(null)
                    .typeError("Selecione uma 'conta' válida"),
                data: Yup.date()
                    .nullable()
                    .default(null)
                    .notRequired("O campo 'data' é obrigatório")
                    .typeError("Selecione uma 'data' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.data !== null) dadosValidados.data = dataToStr(dadosValidados.data, "yyyy-MM-dd");
            if (dadosValidados.conta) dadosValidados.conta = dadosValidados.conta.id;

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_FINANCEIRO_EXTRATOCONTA,
                    emails,
                    filtros,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
                limparFormulario();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const cellClass = (value, { field }) => {
        return {
            "mako-table-overdue-text": field === "valor" && value < 0,
            "mako-table-effective-text": field === "valor" && value > 0,
        };
    };

    const colunas = [
        { field: "codigo", header: "Lançamento", style: { width: "5%" } },
        { field: "data", header: "Data", dateFormat: "dd/MM/yyyy", align: "center", style: { width: "8%" } },
        { field: "historico", header: "Historico" },
        { field: "forma", header: "D/C" },
        { field: "valor", header: "Valor", money: true },
        { field: "saldo", header: "Saldo", money: true },
    ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_FINANCEIRO_EXTRATOCONTA}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    return (
        <R.Wrapper titulo={"de extrato de conta"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        conta: null,
                        data: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Conta Financeira",
                        inputId: "conta",
                        inputName: "conta",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            placeholder: "Selecione uma conta financeira",
                            filter: true,
                            filterBy: "descricao",
                            url: "/financeiro/contas-financeiras?limit=1000",
                            optionLabel: "descricao",
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Data",
                        inputId: "data",
                        inputName: "data",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_FINANCEIRO_EXTRATOCONTA}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                        cellClassName: cellClass,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
