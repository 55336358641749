import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import { CookiesProvider } from "react-cookie";

import { CaixaMovimentoProvider } from "@/contexts/caixaMovimentoContext";
import { AuthProvider } from "@/contexts/authContext";
import { LoadingProvider } from "@/contexts/loadingContext";
import { EmpresaProvider } from "@/contexts/empresaContext";

import { MakoSidebarConsultarSaldos } from "./components/MakoSidebarConsultarSaldos";
import { MakoPermissaoCookies } from "./components/MakoPermissaoCookies";

import Routes from "@/routes";
import useToast from "./hooks/useToast";
import useMakoPermissaoCookies from "./hooks/useMakoPermissaoCookies";
import useMakoSidebarConsultarSaldos from "./hooks/useMakoSidebarConsultarSaldos";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.scss";

const App = () => {
    const { toastRef, position } = useToast();
    const { sidebarRef } = useMakoPermissaoCookies();
    const { sidebarRef: consultaRef } = useMakoSidebarConsultarSaldos();
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="App">
            <Toast ref={toastRef} position={position} />
            <CookiesProvider defaultSetOptions={{ path: "/" }}>
                <LoadingProvider>
                    <AuthProvider>
                        <>
                            <MakoPermissaoCookies ref={sidebarRef} />
                            <EmpresaProvider>
                                <>
                                    <MakoSidebarConsultarSaldos ref={consultaRef} />
                                    <CaixaMovimentoProvider>
                                        <Routes />
                                    </CaixaMovimentoProvider>
                                </>
                            </EmpresaProvider>
                        </>
                    </AuthProvider>
                </LoadingProvider>
            </CookiesProvider>
        </div>
    );
};

export default App;
