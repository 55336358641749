import React, { useMemo, useState } from "react";
import { Dropdown } from "@/components/Dropdown";

export const CidadeFiltroTemplate = (options) => {
    const [estado, setEstado] = useState(null);

    const url = useMemo(() => {
        return !estado
            ? "/pessoas/cidades/?query={id,nome,codigo}&limit=1000&ordering=nome"
            : `/pessoas/cidades/?query={id,nome,codigo}&limit=1000&ordering=nome&estado=${estado}`;
    }, [estado]);

    return (
        <div>
            <Dropdown
                optionValue="id"
                optionLabel="nome"
                url={"/pessoas/estados?query={id,nome}&limit=100"}
                filter
                showClear
                filterBy="nome"
                placeholder="Selecione um estado"
                value={estado}
                onChange={(e) => setEstado(e.target.value)}
                className="p-mb-3"
            />
            <Dropdown
                optionValue="codigo"
                optionLabel="nome"
                url={url}
                filter
                showClear
                filterBy="nome"
                disabled={!estado}
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder="Selecione uma cidade"
            />
        </div>
    );
};
