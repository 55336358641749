import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoInputPeriodo } from "@/components/MakoInputs/MakoInputPeriodo";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_VENDAS_LISTAGEMSIMPLES } from "@/assets/constants/relatorios";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

const { ExportCSV } = R.Buttons;

const OPTIONS_ORDENACAO_FILTROS = [
    { value: "data", label: "Periodo (crescente)" },
    { value: "-data", label: "Periodo (decrescente)" },
    { value: "cliente", label: "Cliente (crescente)" },
    { value: "-cliente", label: "Cliente (decrescente)" },
];

const BASE_URL = "/relatorios/listagem-simples-vendas/";

const FILE_NAME = gerarFileName("Tabela Produtos Venda");

const FILTROS_VIEWSET = {
    periodo_start: "data__gte",
    periodo_end: "data__lte",
    empresa: "empresa",
    cliente: "cliente",
    estagio_venda: "sequencia_estagio_venda__tipo_estagio_venda",
    fluxo_venda: "estagio_venda",
    orderby: "ordering",
};

export const RelatorioVendasListagemSimples = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();
    const [formatDocumento] = useFormatCNPJCPF();

    const empresaSelecionada = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(empresaSelecionada, formRef, botaoCsv, listagemRef);

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "cliente",
            label: "Cliente",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["periodo_start", "periodo_end"],
            label: "Período emissão",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "estagio_venda",
            label: "Estágio de Venda",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "orderBy",
            label: "Ordenado por",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_ORDENACAO_FILTROS,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "fluxo_venda",
            label: "Fluxo de Venda",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.cliente) dadosValidados.cliente = dadosValidados.cliente.id;
        if (dadosValidados.periodo_start instanceof Date)
            dadosValidados.periodo_start = dataToStr(dadosValidados.periodo_start, "yyyy-MM-dd");
        if (dadosValidados.periodo_end instanceof Date)
            dadosValidados.periodo_end = dataToStr(dadosValidados.periodo_end, "yyyy-MM-dd");
        if (dadosValidados.estagio_venda) dadosValidados.estagio_venda = dadosValidados.estagio_venda.id;
        if (dadosValidados.fluxo_venda) dadosValidados.fluxo_venda = dadosValidados.fluxo_venda.id;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                periodo_start: Yup.date()
                    .required("O campo 'periodo  inicial' é obrigatório")
                    .typeError("Informe um 'periodo  inicial' válido"),
                periodo_end: Yup.date()
                    .when("periodo_start", {
                        is: (val) => val != null,
                        then: Yup.date()
                            .min(
                                values.periodo_start || new Date(),
                                "O 'periodo final' não pode ser anterior a inicial"
                            )
                            .typeError("Informe um 'periodo final' válido"),
                    })
                    .required("O campo 'periodo final' é obrigatório"),
                empresa: Yup.number().nullable().typeError("Informe uma 'empresa' válida"),
                cliente: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'cliente' válida"),
                estagio_venda: Yup.object()
                    .nullable()
                    .typeError("Informe um 'estágio de venda' válido"),
                fluxo_venda: Yup.object()
                    .nullable()
                    .typeError("Informe um 'estágio de venda' válido"),
                orderBy: Yup.string().nullable().typeError("Seleciona uma 'ordenação' válida."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: empresaSelecionada.current,
            });

            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.cliente) dadosValidados.cliente = dadosValidados.cliente.id;
            if (dadosValidados.periodo_start instanceof Date)
                dadosValidados.periodo_start = dataToStr(dadosValidados.periodo_start, "yyyy-MM-dd");
            if (dadosValidados.periodo_end instanceof Date)
                dadosValidados.periodo_end = dataToStr(dadosValidados.periodo_end, "yyyy-MM-dd");
            if (dadosValidados.estagio_venda) dadosValidados.estagio_venda = dadosValidados.estagio_venda.id;
            if (dadosValidados.fluxo_venda) dadosValidados.fluxo_venda = dadosValidados.fluxo_venda.id;

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_VENDAS_LISTAGEMSIMPLES,
                    emails,
                    corpo_email,
                    filtros,
                    filtros_template: filtrosTemplateAplicados,
                    versao,
                    enviar_fila: background,
                });
                limparFormulario();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                console.log(_url)
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "orcamento", header: "Orçamento", style: { width: "8%" } },
        { field: "data", header: "Data", style: { width: "8%" }, dateFormat: "dd/MM/yyyy" },
        {
            field: "cpf_cnpj",
            header: "CPF/CNPJ ",
            style: { width: "8%" },
            action: ({ cpf_cnpj }) => formatDocumento(cpf_cnpj),
        },
        { field: "cliente", header: "Cliente" },
        {
            field: "qtd_parcelas",
            header: "Qtd. Parcelas",
            align: "center",
            action: ({ qtd_parcelas, parcela }) => `${qtd_parcelas}`,
        },
        { field: "estagio", header: "Estágio de Fluxo", align: "end" },
        { field: "v_vista", header: "V. A vista", money: true },
        { field: "v_prazo", header: "V. A prazo", money: true },
    ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_VENDAS_LISTAGEMSIMPLES}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    return (
        <R.Wrapper titulo={"vendas listagem simples"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        periodo_start: null,
                        periodo_end: null,
                        empresa: null,
                        cliente: null,
                        estagio_venda: null,
                        orderby: "",
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa",
                        inputId: "empresa",
                        inputName: "empresa",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 6,
                    },
                    {
                        label: "Cliente",
                        inputId: "cliente",
                        inputName: "cliente",
                        component: MakoInputCliente,
                        fieldSize: 6,
                    },
                    {
                        inputId: "periodo",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        componentProps: {
                            label: "Periodo",
                            nameInicio: "periodo_start",
                            nameFinal: "periodo_end",
                            valueInicio: "periodo_start",
                            valueFinal: "periodo_end",
                            errorInicio: "periodo_start",
                            errorFinal: "periodo_end",
                            obrigatorio: true,
                        },
                        fieldSize: 5,
                    },
                    {
                        label: "Estágio de Fluxo",
                        inputId: "estagio_venda",
                        inputName: "estagio_venda",
                        component: Dropdown,
                        componentProps: {
                            url: "/vendas/tipos-estagios-vendas/",
                            optionLabel: "descricao",
                        }
                    },
                    {
                        label: "Fluxo",
                        inputId: "fluxo_venda",
                        inputName: "fluxo_venda",
                        component: Dropdown,
                        componentProps: {
                            url: "/vendas/estagios-vendas/",
                            optionLabel: "descricao",
                        }
                    },
                    {
                        label: "Ordenado por",
                        inputId: "orderBy",
                        inputName: "orderBy",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione uma forma de ordenação",
                            options: OPTIONS_ORDENACAO_FILTROS,
                            optionValue: "value",
                            optionLabel: "label",
                            showClear: true,
                        },
                        fieldSize: 4,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_VENDAS_LISTAGEMSIMPLES}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
