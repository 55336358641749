import React, { useRef } from "react";

import { useHistory } from "react-router-dom";

import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import permissoes from "@/assets/constants/permissoes";

const BASE_URL = "/produtos/saldo-estoque-formula/";

export const SaldoEstoqueFormulaPage = () => {
    const [formatDocumento] = useFormatCNPJCPF();

    const listagemRef = useRef();
    const history = useHistory();

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/estoque/cadastros/saldo-estoque-formula/form")}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar cadastro de estoque"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/cadastros/saldo-estoque-formula/form",
                            state: {
                                formula: {
                                    id: rowData.id,
                                    descricao: rowData.descricao,
                                    estoque_empresa: rowData.estoque_empresa?.id,
                                    ativa: rowData.ativa,
                                },
                                possui_centros: rowData?.centros_estocagem?.length > 0,
                            },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_EXCLUIR]}
                    componente={BotaoDelete}
                    url={BASE_URL}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a formula <b>{rowData.descricao}</b>?
                        </span>
                    }
                    tooltip="Deletar formula"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="A formula não pode ser excluída."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "5%" } },
        { field: "descricao", header: "Nome" },
        {
            field: "empresa",
            header: "Empresa",
            action: ({ empresa: { nome, identificacao } }) => `${nome} - ${formatDocumento(identificacao)}`,
        },
        {
            field: "ativa",
            header: "Status",
            align: "center",
            action: ({ ativa }) => {
                return <Tag severity={ativa ? "success" : "danger"} value={ativa ? "ATIVO" : "INATIVO"} />;
            },
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Fórmula estoque formula"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={BASE_URL}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                fieldFiltroEmpresa={"empresa"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
