import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MakoInputCubagem } from "@/components/MakoInputs/MakoInputCubagem";
import { MakoInputDimensao } from "@/components/MakoInputs/MakoInputDimensao";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [detalhes, setDetalhes] = useState({});
    const { showWarning } = useToast();
    const { httpGet } = useHttp();

    const obterDetalhesItemVenda = async (itemVendaId) => {
        const handlers = {
            200: ({ data }) => setDetalhes(data),
            400: () =>
                showWarning({
                    summary: "Aviso!",
                    detail: "Não foi possível obter os detalhes do item da venda.",
                    life: 3000,
                }),
        };

        await httpGet(
            {
                url: `/vendas/detalhe-item-venda/${itemVendaId}/`,
            },
            handlers
        );
    };

    const abrirModal = (itemVendaId) => {
        setVisible(true);
        if (itemVendaId) obterDetalhesItemVenda(itemVendaId);
    };

    const fecharModal = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog visible={visible} header="Detalhes do item de venda" style={{ width: "65vw" }} onHide={fecharModal}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="codigo">Código</label>
                    <InputText id="codigo" name="codigo" disabled value={detalhes.sku?.codigo || ""} />
                </div>
                <div className="p-field p-col-12 p-md-10">
                    <label htmlFor="descricao">Descrição</label>
                    <InputText
                        id="descricao"
                        name="descricao"
                        disabled
                        value={detalhes.sku?.descricao_derivada || ""}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="largura">Largura</label>
                    <div className="p-inputgroup">
                        <MakoInputDimensao inputId="largura" name="largura" disabled value={detalhes.largura} />
                        <span className="p-inputgroup-addon">{detalhes.und_largura?.sigla || "-"}</span>
                    </div>
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="altura">Altura</label>
                    <div className="p-inputgroup">
                        <MakoInputDimensao inputId="altura" name="altura" disabled value={detalhes.altura} />
                        <span className="p-inputgroup-addon">{detalhes.und_altura?.sigla || "-"}</span>
                    </div>
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="comprimento">Comprimento</label>
                    <div className="p-inputgroup">
                        <MakoInputDimensao
                            inputId="comprimento"
                            name="comprimento"
                            disabled
                            value={detalhes.comprimento}
                        />
                        <span className="p-inputgroup-addon">{detalhes.und_comprimento?.sigla || "-"}</span>
                    </div>
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="cubagem">Dimensão</label>
                    <div className="p-inputgroup">
                        <MakoInputCubagem inputId="cubagem" name="cubagem" disabled value={detalhes.cubagem} />
                        <span className="p-inputgroup-addon">{detalhes.und_cubagem?.sigla || "-"}</span>
                    </div>
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="preco-base">Preço base</label>
                    <MakoInputMoeda id="preco-base" name="preco_base" disabled valueMoeda={detalhes.preco_base} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor-a-vista">Vlr. à vista</label>
                    <MakoInputMoeda
                        id="valor-a-vista"
                        name="valor_avista"
                        disabled
                        valueMoeda={detalhes.valor_total_av}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor-unit-encargos">Vlr. encargos</label>
                    <MakoInputMoeda
                        id="valor-unit-encargos"
                        name="valor_unit_encargos"
                        disabled
                        valueMoeda={detalhes.valor_encargos}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor-a-prazo">Vlr. à prazo</label>
                    <MakoInputMoeda
                        id="valor-a-prazo"
                        name="valor_aprazo"
                        disabled
                        valueMoeda={detalhes.valor_total_ap}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor-unit-desconto">Vlr. desconto (unitário)</label>
                    <MakoInputMoeda
                        id="valor-unit-desconto"
                        name="valor_unit_desconto"
                        disabled
                        valueMoeda={detalhes.valor_desconto_unitario}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor-servico-agregado">Vlr. serviço agregado</label>
                    <MakoInputMoeda
                        id="valor-servico-agregado"
                        name="valor_servico_agregado"
                        disabled
                        valueMoeda={detalhes.valor_servico_agregado}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="tabela-preco">Tabela de preço utilizada</label>
                    <InputText
                        id="tabela-preco"
                        name="tabela_preco"
                        disabled
                        value={detalhes.preco_tabela_preco?.tabela_preco.nome || ""}
                    />
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="centro-estocagem-saida">Centro de estocagem de saída</label>
                    <InputText id="centro-estocagem-saida" name="centro_estocagem_saida" disabled value={""} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="qtd-devolvida">Qtd. devolvida</label>
                    <InputText
                        id="qtd-devolvida"
                        name="qtd_devolvida"
                        disabled
                        value={detalhes.quantidade_devolvida || ""}
                    />
                </div>
            </div>
            {/* <div className="p-fluid p-formgrid p-grid">
                <div className="p-field-checkbox p-col-12 p-md-4 p-mt-4">
                    <Checkbox inputId="requer-aprovacao-preco" name="requer-aprovacao-preco" disabled checked={true} />
                    <label htmlFor="requer-aprovacao-preco">Requer aprovação de preço?</label>
                </div>
                <div className="p-field-checkbox p-col-12 p-md-4 p-mt-4">
                    <Checkbox inputId="preco-aprovado" name="preco-aprovado" disabled checked={true} />
                    <label htmlFor="preco-aprovado">Preço aprovado?</label>
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="usuario-aprovacao-preco">Preço aprovado por</label>
                    <InputText id="usuario-aprovacao-preco" name="usuario_aprovacao_preco" disabled value={""} />
                </div>
            </div> */}
        </Dialog>
    );
};

export const ModalDetalheItemVenda = forwardRef(Modal);
