import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import { SIM_NAO_BOOLEAN, TIPOS_CHOICE_SERVICOS_SERVICO } from "@/assets/constants/constants";
import { Dialog } from "primereact/dialog";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import * as Yup from "yup";

const Modal = ({ onConfirm, url }, ref) => {
    const [visivel, setVisivel] = useState(false);
    const { empresaSelecionadaId } = useEmpresa();
    const { showError } = useToast();

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            empresa: empresaSelecionadaId,
            codigo: "",
            descricao: "",
            custo: null,
            valor_venda: null,
            tipo: null,
            terceirizado: null,
            comissao_interveniente: null,
            requer_apontamento: null,
            template: true,
            custo_hora: null,
            ativo: true,
            detalhes: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string()
                    .max(10, "Máximo de caracteres atingido: 10")
                    .nullable()
                    .typeError("Informe um 'código' válido"),
                descricao: Yup.string()
                    .max(100, "Máximo de caracteres atingido: 100")
                    .nullable()
                    .typeError("Informe um 'descrição' válido"),
                custo: Yup.number().nullable().typeError("Informe um 'custo' válido"),
                valor_venda: Yup.number().nullable().typeError("Informe um 'valor venda' válido"),
                tipo: Yup.string()
                    .max(1, "Máximo de caracteres atingido: 1")
                    .nullable()
                    .typeError("Informe um 'tipo' válido"),
                detalhes: Yup.string()
                    .max(255, "Máximo de caracteres atingido: 255")
                    .nullable()
                    .typeError("Informe um 'detalhes' válido"),
                comissao_interveniente: Yup.number().nullable().typeError("Informe um 'valor de comissão' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const _URL = url;
            let params = [];

            Object.keys(values).map((key) => {
                if (values[key] !== null && values[key] !== "") params.push(`${key}=${values[key]}`);
            });

            if (params.length > 0) {
                const _url = _URL + "?" + params.join("&");
                if (typeof onConfirm === "function") onConfirm(_url);
            } else if (typeof onConfirm === "function") onConfirm(_URL + "?template=true");
            setVisivel(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    useImperativeHandle(ref, () => ({ setVisivel }));

    return (
        <Dialog
            header="Filtro avançado"
            visible={visivel}
            breakpoints={{ "1080px": "90vw" }}
            style={{ width: "75vw" }}
            onHide={() => setVisivel(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="codigo">Código</label>
                        <InputText
                            id="codigo"
                            name="codigo"
                            onChange={formik.handleChange}
                            value={formik.values.codigo}
                            autoComplete="off"
                            maxLength={12}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="descricao">Descrição</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            onChange={formik.handleChange}
                            value={formik.values.descricao}
                            autoComplete="off"
                            maxLength={100}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo">Tipo</label>
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            options={TIPOS_CHOICE_SERVICOS_SERVICO}
                            optionValue="value"
                            optionLabel="label"
                            onChange={formik.handleChange}
                            value={formik.values.tipo}
                            className={classNames({ "p-invalid": formik.errors.tipo })}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-1">
                        <label htmlFor="custo">Custo</label>
                        <MakoInputMoeda
                            id="custo"
                            name="custo"
                            onChangeMoeda={formik.handleChange}
                            valueMoeda={formik.values.custo}
                            className={classNames({ "p-invalid": formik.errors.custo })}
                        />
                        {formik.errors.custo && <small className="p-error">{formik.errors.custo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-1">
                        <label htmlFor="valor_venda">Preço</label>
                        <MakoInputMoeda
                            id="valor_venda"
                            name="valor_venda"
                            onChangeMoeda={formik.handleChange}
                            valueMoeda={formik.values.valor_venda}
                            className={classNames({ "p-invalid": formik.errors.valor_venda })}
                        />
                        {formik.errors.valor_venda && <small className="p-error">{formik.errors.valor_venda}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="comissao_interveniente">Comissão interveniente</label>
                        <MakoInputPercent
                            id="comissao_interveniente"
                            name="comissao_interveniente"
                            onValueChange={formik.handleChange}
                            value={formik.values.comissao_interveniente}
                            className={classNames({ "p-invalid": formik.errors.comissao_interveniente })}
                        />
                        {formik.errors.comissao_interveniente && (
                            <small className="p-error">{formik.errors.comissao_interveniente}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="custo_hora">Custo hora?</label>
                        <Dropdown
                            id="custo_hora"
                            name="custo_hora"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            showClear
                            onChange={formik.handleChange}
                            value={formik.values.custo_hora}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="terceirizado">Terceirizado?</label>
                        <Dropdown
                            id="terceirizado"
                            name="terceirizado"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            showClear
                            onChange={formik.handleChange}
                            value={formik.values.terceirizado}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="template">Modelo?</label>
                        <Dropdown
                            id="template"
                            name="template"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            showClear
                            onChange={formik.handleChange}
                            value={formik.values.template}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="requer_apontamento">Requer apontamento?</label>
                        <Dropdown
                            id="requer_apontamento"
                            name="requer_apontamento"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            showClear
                            onChange={formik.handleChange}
                            value={formik.values.requer_apontamento}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="ativo">Ativo?</label>
                        <Dropdown
                            id="ativo"
                            name="ativo"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            showClear
                            onChange={formik.handleChange}
                            value={formik.values.ativo}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="detalhes">Detalhes</label>
                        <InputTextarea
                            id="detalhes"
                            name="detalhes"
                            value={formik.values.detalhes}
                            onChange={formik.handleChange}
                            autoResize
                            rows={2}
                            maxLength={255}
                            className={classNames({ "p-invalid": formik.errors.detalhes })}
                        />
                        {formik.errors.detalhes && <small className="p-error">{formik.errors.detalhes}</small>}
                    </div>
                </div>
                <div className="p-grid p-justify-end p-mt-2">
                    <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={resetForm}
                        className="p-button-warning p-mr-2"
                    />
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => setVisivel(false)}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const FiltroAvancadoServicos = forwardRef(Modal);
