import React from "react";
import { CentroEstocagemFormBase } from "./formBase";
import { PageBase } from "@/components/PageBase";

export const CentroEstocagemForm = (props) => {
    const centroEstocagem = props.location.state;

    return (
        <PageBase>
            <h3>{!centroEstocagem?.id ? "Novo centro de estocagem" : "Manutenção de centro de estocagem"}</h3>
            <CentroEstocagemFormBase centroEstocagem={centroEstocagem} estoqueId={props.location.estoqueId} />
        </PageBase>
    );
};
