import React, { createContext, useState, useCallback } from "react";

import * as pd from "@/assets/util/persistenciaDjango";
import { formatarCasasDecimais } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";
import useAuth from "@/hooks/useAuth";
import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const EntradaContext = createContext({});

export const EntradaProvider = ({ children }) => {
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [itensEntrada, setItensEntrada] = useState([]);
    const [ordensCompra, setOrdensCompra] = useState([]);
    const { empresaSelecionadaId } = useEmpresa();
    const { user } = useAuth();
    const { httpGet, httpPost, httpPut, httpPatch } = useHttp();
    const [loadingEntrada, showLoadingEntrada, hideLoadingEntrada] = useLoadingLocal();

    const formatarEntradaApiParaFrontend = (data) => {
        const { categoria_titulo, ...rest } = data;
        const _dadosBasicos = {
            ...rest,
            categoria_titulo: categoria_titulo.id,
        };
        setDadosBasicos(_dadosBasicos);
        if (rest.ordem_compra_principal) {
            setOrdensCompra([rest.ordem_compra_principal]);
        }
        return _dadosBasicos;
    };

    const handleDadosBasicos = useCallback(
        async (values) => {
            const entrada = {
                ...values,
                fornecedor: values.fornecedor.id,
                representante: values.representante?.id || null,
                numero_parcelas: values.intervalo_parcelas.split(",").length,
                ordem_compra_principal: values.ordem_compra_principal?.id || null,
            };
            if (!values.id) {
                const novaEntrada = {
                    ...entrada,
                    usuario_cadastro: user?.id,
                    empresa: empresaSelecionadaId,
                };
                let data = null;
                const handlers = {
                    201: ({ data: dataResponse }) => (data = formatarEntradaApiParaFrontend(dataResponse)),
                };
                await httpPost({ url: "/compras/entradas/", body: novaEntrada }, handlers);
                return { status: 201, data };
            } else {
                const entradaEditada = { ...entrada, status: "P" };
                delete entradaEditada.empresa;
                delete entradaEditada.usuario_cadastro;
                delete entradaEditada.fornecedor;
                let diffEntrada = {};
                for (const [k, v] of Object.entries(entradaEditada)) {
                    if (v !== dadosBasicos[k]) {
                        diffEntrada[k] = v;
                    }
                }
                if (Object.keys(diffEntrada).length > 0) {
                    let data = null;
                    const handlers = {
                        200: ({ data: dataResponse }) => (data = formatarEntradaApiParaFrontend(dataResponse)),
                    };
                    await httpPatch({ url: `/compras/entradas/${values.id}/`, body: diffEntrada }, handlers);
                    return { status: 200, data };
                }
                return { status: 204, data: values };
            }
        },
        [dadosBasicos, empresaSelecionadaId, user, httpPost, httpPatch]
    );

    const atualizarValoresEntrada = useCallback(async () => {
        if (dadosBasicos) {
            const campos = [
                "valor_total_produtos",
                "valor_total_servicos",
                "valor_total_frete",
                "valor_total_seguro",
                "valor_total_outros",
                "valor_custos_adicionais",
                "valor_custos_extras",
                "valor_antecipacao",
                "valor_total_parcelas",
                "valor_total_entrada",
            ];
            const params = {
                query: `{${campos.join(",")}}`,
            };
            const handlers = {
                200: ({ data }) => {
                    setDadosBasicos((old) => ({
                        ...old,
                        valor_total_produtos: parseNumber(data.valor_total_produtos),
                        valor_total_servicos: parseNumber(data.valor_total_servicos),
                        valor_total_frete: parseNumber(data.valor_total_frete),
                        valor_total_seguro: parseNumber(data.valor_total_seguro),
                        valor_total_outros: parseNumber(data.valor_total_outros),
                        valor_custos_adicionais: parseNumber(data.valor_custos_adicionais),
                        valor_custos_extras: parseNumber(data.valor_custos_extras),
                        valor_antecipacao: parseNumber(data.valor_antecipacao),
                        valor_total_parcelas: parseNumber(data.valor_total_parcelas),
                        valor_total_entrada: parseNumber(data.valor_total_entrada),
                    }));
                },
            };
            await httpGet({ url: `/compras/entradas/${dadosBasicos.id}/`, params }, handlers);
        }
    }, [dadosBasicos, httpGet]);

    const handleChecarDocEmitente = useCallback(
        (doc) => {
            const { fornecedor } = dadosBasicos;
            if (doc === fornecedor?.identificacao) {
                return true;
            }
            return false;
        },
        [dadosBasicos]
    );

    // Funções referentes aos Itens da Entrada
    const handleItemEntrada = useCallback(
        async (values, op) => {
            let _itensEntrada = [...itensEntrada];
            const item = {
                ...values,
                valor_total_item: formatarCasasDecimais(values.valor_total_item),
                entrada: dadosBasicos.id,
                sku: values.sku.id,
            };
            let itemAlterado = null;
            const handlers = {
                200: ({ data }) => {
                    const index = _itensEntrada?.findIndex((e) => e.id === values.id);
                    _itensEntrada[index] = data;
                    itemAlterado = data;
                    setItensEntrada(_itensEntrada);
                },
                201: ({ data }) => {
                    _itensEntrada.push(data);
                    itemAlterado = data;
                    setItensEntrada(_itensEntrada);
                },
            };
            if (op === pd.OP_CRUD_DJANGO.novo) {
                await httpPost({ url: "/compras/itens-entradas/", body: item }, handlers);
                return { status: 201, data: itemAlterado };
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                await httpPut({ url: `/compras/itens-entradas/${values.id}/`, body: item }, handlers);
                return { status: 200, data: itemAlterado };
            }
        },
        [itensEntrada, dadosBasicos, httpPost, httpPut]
    );

    // Funções referentes as Ordens de Compra
    const handleAddOrdemCompra = useCallback(
        (ordemCompra) => {
            const oc = ordensCompra.find((e) => e.ordem_compra.id === ordemCompra.id);
            if (!oc) {
                let _ordensCompra = [...ordensCompra];
                _ordensCompra = pd.criarListaProvisoriaParaPersistencia(
                    _ordensCompra,
                    { ordem_compra: ordemCompra },
                    pd.OP_CRUD_DJANGO.novo
                );
                setOrdensCompra(_ordensCompra);
            }
        },
        [ordensCompra]
    );

    const handleRemoverOrdemCompra = useCallback(async () => {
        const handlers = {
            200: () => {
                setOrdensCompra([]);
                setDadosBasicos((prev) => ({ ...prev, ordem_compra_principal: null }));
            },
        };
        const body = { ordem_compra_principal: null };
        showLoadingEntrada();
        await httpPatch({ url: `/compras/entradas/${dadosBasicos?.id}/`, body }, handlers);
        hideLoadingEntrada();
    }, [dadosBasicos, showLoadingEntrada, hideLoadingEntrada, httpPatch]);

    const handleGetEntrada = useCallback(
        async (id) => {
            const handlers = {
                200: ({ data }) => formatarEntradaApiParaFrontend(data),
            };
            showLoadingEntrada();
            await httpGet({ url: `/compras/entradas/${id}/` }, handlers);
            hideLoadingEntrada();
        },
        [showLoadingEntrada, hideLoadingEntrada, httpGet]
    );

    return (
        <EntradaContext.Provider
            value={{
                loadingEntrada,
                dadosBasicos,
                itensEntrada,
                ordensCompra,
                permiteAlterar: dadosBasicos?.status ? ["P", "V"].includes(dadosBasicos.status) : true,
                atualizarValoresEntrada,
                handleDadosBasicos,
                handleChecarDocEmitente,
                handleItemEntrada,
                handleAddOrdemCompra,
                handleRemoverOrdemCompra,
                handleGetEntrada,
            }}
        >
            {children}
        </EntradaContext.Provider>
    );
};

export default EntradaContext;
