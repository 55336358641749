import React from "react";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";

import { ACTIONS_OPERAR_MODAIS_CAIXA } from "../../../reducers/operar-modais-caixa";

const { RELATORIOS_EMITIR_CAIXA_MOVIMENTO_RESUMO } = permissoes;

export const Resumo = ({ className = "", dispatcher = () => {} }) => {
    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[RELATORIOS_EMITIR_CAIXA_MOVIMENTO_RESUMO]}
            label="Resumo"
            className={className}
            onClick={() => {
                dispatcher({
                    type: ACTIONS_OPERAR_MODAIS_CAIXA.RESUMIR,
                });
            }}
        />
    );
};
