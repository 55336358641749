import React, { useCallback, useEffect } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { dataToStr } from "@/assets/util/datas";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Panel } from "primereact/panel";
import { MakoCalendar } from "@/components/MakoCalendar";
import useHttp from "@/hooks/useHttp";

export const InspecionarModalForm = ({ movimentacao, esconderDialog, inspecionar = false }) => {
    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            historico: null,
            valor_lancamento: 0,
            usuario_movimentacao: "",
            plano_lancamento: null,
            movimentacao: null,
        },
    });
    const { httpGet } = useHttp();

    const carregaPlanoLancamento = useCallback(async () => {
        if (movimentacao?.plano_contas) {
            const handlers = {
                200: ({ data }) => {
                    setFieldValue("plano_lancamento", data.descricao);
                },
            };

            await httpGet(
                {
                    url: `/financeiro/planos-contas-financeiras/${movimentacao.plano_contas}/`,
                },
                handlers
            );
        }
    }, [movimentacao.plano_contas, httpGet, setFieldValue]);

    const carregaMovimentacao = useCallback(async () => {
        if (movimentacao?.content_type_movimentacao) {
            const { app_label, model } = movimentacao.content_type_movimentacao;
            const handlers = {
                200: ({ data }) => {
                    setFieldValue("movimentacao", data);
                },
            };

            await httpGet(
                {
                    url: `/${app_label}/${model}s/${movimentacao.id_movimentacao}/`,
                },
                handlers
            );
        }
    }, [setFieldValue, movimentacao?.content_type_movimentacao, movimentacao?.id_movimentacao, httpGet]);

    useEffect(() => {
        if (movimentacao)
            setValues({
                historico: movimentacao.historico,
                usuario_movimentacao: movimentacao.usuario_lancamento.nome,
                valor_lancamento: movimentacao.valor,
            });
    }, [movimentacao, setValues]);

    useEffect(() => {
        if (movimentacao?.id) carregaPlanoLancamento();
    }, [carregaPlanoLancamento, movimentacao?.id]);

    useEffect(() => {
        if (movimentacao?.id_movimentacao) carregaMovimentacao();
    }, [carregaMovimentacao, movimentacao?.id_movimentacao]);

    return (
        <Dialog
            header={`Lançamento N.º ${movimentacao?.id} (${dataToStr(movimentacao?.data_movimentacao, "dd-MM-yyyy")})`}
            visible={inspecionar}
            breakpoints={{ "960px": "80vw" }}
            style={{ width: "65vw" }}
            onHide={() => esconderDialog()}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-10">
                        <label htmlFor="conta_financeira">Histórico</label>
                        <InputText id="historico" name="historico" value={formik.values.historico} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="status">Status</label>
                        <Tag
                            severity={
                                movimentacao?.compensado ? "success" : movimentacao?.cancelado ? "danger" : "info"
                            }
                            value={
                                movimentacao?.cancelado
                                    ? "Cancelado"
                                    : movimentacao?.compensado
                                    ? "Compensado"
                                    : "Pendente"
                            }
                            style={{ width: "100%", height: "50%" }}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="conta_financeira">Responsável lançamento</label>
                        <InputText
                            id="usuario_movimentacao"
                            name="usuario_movimentacao"
                            value={formik.values.usuario_movimentacao}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_lancamento">Valor</label>
                        <MakoInputMoeda
                            id="valor_lancamento"
                            name="valor_lancamento"
                            valueMoeda={formik.values.valor_lancamento}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="plano_lancamento">Plano de contas</label>
                        <InputText
                            id="plano_lancamento"
                            name="plano_lancamento"
                            value={formik.values.plano_lancamento}
                            disabled
                        />
                    </div>
                </div>
                {formik.values?.movimentacao ? (
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <Panel
                                header={`Mais detalhes do ${movimentacao?.content_type_movimentacao?.model} N.º ${formik.values.movimentacao.id}`}
                            >
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="documento">Documento</label>
                                        <InputText
                                            id="documento"
                                            name="documento"
                                            value={formik.values.movimentacao.documento}
                                            disabled
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="credor">Credor</label>
                                        <InputText
                                            id="credor"
                                            name="credor"
                                            value={
                                                formik.values.movimentacao.credor?.nome ||
                                                formik.values.movimentacao.devedor?.nome
                                            }
                                            disabled
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="valor_movimentacao">Valor</label>
                                        <MakoInputMoeda
                                            id="valor_movimentacao"
                                            name="valor_movimentacao"
                                            valueMoeda={formik.values.movimentacao.valor}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="data_lancamento">Lançamento</label>
                                        <MakoCalendar
                                            id="data_lancamento"
                                            name="data_lancamento"
                                            valueCalendar={formik.values.movimentacao.data_lancamento}
                                            disabled
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="data_emissao">Emissão</label>
                                        <MakoCalendar
                                            id="data_emissao"
                                            name="data_emissao"
                                            valueCalendar={formik.values.movimentacao.data_emissao}
                                            disabled
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="vencimento">Vencimento</label>
                                        <MakoCalendar
                                            id="vencimento"
                                            name="vencimento"
                                            valueCalendar={formik.values.movimentacao.vencimento}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    </div>
                ) : null}
                <div className="p-grid p-justify-end p-mt-1 p-mr-1">
                    <Button
                        className="p-button-danger p-button-text"
                        icon="pi pi-times"
                        type="reset"
                        label="Fechar"
                        onClick={() => esconderDialog()}
                    />
                </div>
            </form>
        </Dialog>
    );
};
