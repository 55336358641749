import React, { useMemo } from "react";

import { CodigoFiltroTemplate, MoedaFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import MakoListagem from "@/components/MakoListagem";

import { gerarFiltrosUrl, parseMoeda } from "@/assets/util/util";

const BASE_URL_PENDENCIAS = "/financeiro/caixas/{id}/pendencias/";

const BASE_COLUMS = [
    {
        field: "id",
        header: "Documento",
        style: { width: "8%" },
        action: ({ id, venda_id }) => venda_id || id,
        filter: true,
        filterField: "documento",
        filterElement: CodigoFiltroTemplate,
    },
    {
        field: "descricao",
        header: "Descrição",
        filter: true,
        filterElement: TextoFiltroTemplate,
    },
    {
        field: "valor",
        header: "Valor",
        action: ({ valor, recebido, tipo_chave, pago = 0 }) => {
            if (tipo_chave !== "pagamento") return parseMoeda(parseFloat(valor) - parseFloat(recebido), false);
            return parseMoeda(parseFloat(valor) - parseFloat(pago), false);
        },
        align: "right",
        filter: true,
        filterElement: MoedaFiltroTemplate,
    },
];

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { width: "15%" },
};

const BASE_FILTROS_CABECALHO = {
    documento: { value: null, matchMode: "equals" },
    descricao: { value: null, matchMode: "equals" },
    valor: { value: null, matchMode: "equals" },
};

const BASE_FILTROS = { ordering: "id" };

const rowClassName = ({ grupo, processado }) => {
    return {
        "mako-table-pendencia-grupo-pendente": !!grupo && !processado,
    };
};

export const ListagemPendencias = ({
    caixaMovimento,
    filtros = BASE_FILTROS,
    listagemRef,
    actions,
    painelEsquerdo,
    onReatualizar,
}) => {
    const colunas = useMemo(() => {
        if (!actions) return BASE_COLUMS;
        const actionColum = {
            ...BASE_COLUMN_ACTIONS,
            action: (e) => actions(e),
        };
        return [...BASE_COLUMS, actionColum];
    }, [actions]);

    const url = useMemo(() => {
        const _url = BASE_URL_PENDENCIAS.replace("{id}", caixaMovimento?.id);
        let query = {};
        if (typeof filtros === "object") query = { ...query, ...filtros };
        return `${_url}?${gerarFiltrosUrl(query)}`;
    }, [caixaMovimento, filtros]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo="Pendências do caixa"
            colunas={colunas}
            painelEsquerdo={painelEsquerdo}
            urlPesquisa={url}
            fazerBusca={!!caixaMovimento}
            botaoReload={true}
            filtros={BASE_FILTROS_CABECALHO}
            onReatualizar={onReatualizar}
            configTabela={{
                rowClassName,
                paginator: true,
                lazy: true,
            }}
        />
    );
};
