import React, { useEffect } from "react";
import { Dropdown } from "@/components/Dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import useNotaFiscal from "@/hooks/useNotaFiscal";
import classNames from "classnames";
import * as Yup from "yup";
import { PageBase } from "@/components/PageBase";
import { MakoInputTransportador } from "@/components/MakoInputs/MakoInputTransportador";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import useToast from "@/hooks/useToast";

export const TransporteForm = () => {
    const { dadosBasicos, handleDadosBasicos } = useNotaFiscal();
    const { showError } = useToast();

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            transportador: null,
            veiculo_placa: "",
            veiculo_uf: null,
            veiculo_rntc: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                transportador: Yup.object()
                    .required("O campo é obrigatório!")
                    .typeError("Selecione um transportador válido!"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleDadosBasicos(values);
            if (status !== 200)
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível salvar as informações da nota fiscal.",
                    life: 1500,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    useEffect(() => {
        if (dadosBasicos?.id && dadosBasicos.transportador?.id) {
            setValues({
                id: dadosBasicos.id,
                transportador: dadosBasicos.transportador || null,
                veiculo_placa: dadosBasicos.veiculo_placa,
                veiculo_rntc: dadosBasicos.veiculo_rntc,
                veiculo_uf: dadosBasicos.veiculo_uf,
            });
        }
    }, [dadosBasicos, setValues]);

    return (
        <PageBase>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="transportador">Transportador </label>
                        <div className="p-inputgroup">
                            <MakoInputTransportador
                                id="transportador"
                                name="transportador"
                                value={formik.values.transportador}
                                onChange={(e) => {
                                    setFieldValue("transportador", e.target.value);
                                    if (e.target.value?.id)
                                        handleSubmit({ id: dadosBasicos.id, transportador: e.target.value });
                                }}
                                className={classNames({ "p-invalid": formik.errors.transportador })}
                            />
                            <Button
                                type="button"
                                icon="pi pi-trash"
                                className="p-button-warning"
                                onClick={() => {
                                    handleDadosBasicos({
                                        id: dadosBasicos.id,
                                        transportador: null,
                                        veiculo_placa: "",
                                        veiculo_uf: null,
                                        veiculo_rntc: "",
                                    });
                                    resetForm();
                                }}
                            />
                        </div>
                        {formik.errors.transportador && (
                            <small className="p-error">{formik.errors.transportador}</small>
                        )}
                    </div>
                </div>
                {formik.values.transportador?.id ? (
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="nome">Nome </label>
                            <InputText id="nome" name="nome" value={formik.values.transportador.nome} disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="cpf_cnpj">{formik.values.transportador?.perfil_pf ? `Cpf` : `Cnpj`}</label>
                            <InputText
                                id="cpf_cnpj"
                                name="cpf_cnpj"
                                value={formik.values.transportador.identificacao}
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="telefone">Telefone</label>
                            <InputText
                                id="telefone"
                                name="telefone"
                                value={
                                    formik.values.transportador?.telefoneperfil_set?.length > 0
                                        ? formik.values.transportador.telefoneperfil_set[0].telefone
                                        : null
                                }
                                disabled
                            />
                        </div>
                    </div>
                ) : null}
                {formik.values.transportador?.id ? (
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="email">Email</label>
                            <InputText
                                id="email"
                                name="email"
                                value={
                                    formik.values.transportador?.emailperfil_set?.length > 0
                                        ? formik.values.transportador.emailperfil_set[0].email
                                        : null
                                }
                                disabled
                            />
                        </div>
                    </div>
                ) : null}
                {formik.values.transportador?.id ? (
                    <div>
                        <label>
                            <b>Digite as informações do veículo:</b>
                        </label>
                        <div className="p-fluid p-formgrid p-grid p-mt-3">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="veiculo_placa">Placa veículo</label>
                                <InputText
                                    id="veiculo_placa"
                                    name="veiculo_placa"
                                    onChange={formik.handleChange}
                                    value={formik.values.veiculo_placa}
                                    autoComplete="off"
                                    className={classNames({ "p-invalid": formik.errors.veiculo_placa })}
                                />
                                {formik.errors.veiculo_placa && (
                                    <small className="p-error">{formik.errors.veiculo_placa}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="veiculo_uf">UF</label>
                                <Dropdown
                                    id="veiculo_uf"
                                    name="veiculo_uf"
                                    placeholder="Selecione um estado"
                                    url="/pessoas/estados?query={id, nome, uf}&limit=30"
                                    filter
                                    filterBy="nome"
                                    optionValue="uf"
                                    optionLabel="nome"
                                    value={formik.values.veiculo_uf}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.veiculo_uf })}
                                />
                                {formik.errors.veiculo_uf && (
                                    <small className="p-error">{formik.errors.veiculo_uf}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="veiculo_rntc">RNTC</label>
                                <InputText
                                    id="veiculo_rntc"
                                    name="veiculo_rntc"
                                    onChange={formik.handleChange}
                                    value={formik.values.veiculo_rntc}
                                    autoComplete="off"
                                    className={classNames({ "p-invalid": formik.errors.veiculo_rntc })}
                                />
                                {formik.errors.veiculo_rntc && (
                                    <small className="p-error">{formik.errors.veiculo_rntc}</small>
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}
            </form>
            <FormikAutoSave intervalo={500} autosave={typeof dadosBasicos?.id === "number"} formik={formik} />
        </PageBase>
    );
};
