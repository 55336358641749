import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import {
    ContaBancariaFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { MakoButton } from "@/components/MakoButton";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import { TIPO_CHAVE_PIX_CHOICE } from "@/assets/constants/constants";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

const url = "/financeiro/chaves-pix/";

export const FinanceiroChavePixPage = () => {
    const { showSuccess } = useToast();
    const listagemRef = useRef(null);
    useClearRefs(listagemRef);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CHAVEPIX_EDITAR]}
                    componente={MakoButton}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de chave pix"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/financeiro/cadastros/chaves-pix/form",
                        state: { chavePix: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CHAVEPIX_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    msgToastErroExclusao="A chave pix não pode ser excluída."
                    tooltip="Deletar chave pix"
                    exigeConfirmacao
                    msgConfirmacao={`Deseja realmente excluir a chave pix ${rowData.chave}?`}
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Chave pix deletada com sucesso!",
                            life: 3000,
                        });
                        listagemRef.current?.buscarDados();
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_CHAVEPIX_INCLUIR]}
                componente={MakoButton}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/financeiro/cadastros/chaves-pix/form"}
            />
        </>
    );

    const TipoChaveFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "descricao",
                    options: TIPO_CHAVE_PIX_CHOICE,
                }}
                options={options}
            />
        );
    };

    const StatusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: true, label: "Ativo" },
                        { value: false, label: "Desativado" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "tipo_chave.descricao",
            header: "Tipo de chave",
            filter: true,
            filterElement: TipoChaveFiltroTemplate,
            filterField: "tipo_chave",
        },
        { field: "chave", header: "Chave", filter: true, filterElement: TextoFiltroTemplate },
        { field: "descricao", header: "Descrição" },
        {
            field: "conta_bancaria.numero_conta",
            header: "Conta bancária",
            action: ({ conta_bancaria }) => (!!!conta_bancaria ? "Não informado" : `${conta_bancaria.numero_conta}`),
            filter: true,
            filterElement: ContaBancariaFiltroTemplate,
            filterField: "conta_bancaria",
        },
        {
            field: "ativo",
            header: "Situação",
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "DESATIVADO"),
            style: { minWidth: "30px" },
            filter: true,
            filterElement: StatusFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Chaves Pix"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    tipo_chave: { value: null, matchMode: "equals" },
                    ativo: { value: null, matchMode: "equals" },
                    chave: { value: null, matchMode: "equals" },
                    conta_bancaria: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
