import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import useLoading from "@/hooks/useLoading";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const Modal = ({ aposClonar }, ref) => {
    const { empresaSelecionadaId } = useEmpresa();
    const [operacao, setOperacao] = useState(true);
    const [visible, setVisible] = useState(false);
    const [empresa, setEmpresa] = useState(empresaSelecionadaId);
    const [novaDescricao, setNovaDescricao] = useState("");
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPost } = useHttp();

    async function clonarOperacao() {
        try {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Operação clonada com sucesso!",
                        life: 2500,
                    });
                    if (typeof aposClonar === "function") aposClonar();
                    esconderModal();
                },
                400: ({ err }) => {
                    showError({
                        summary: "Erro",
                        detail: err.msg,
                        life: 3000,
                    });
                },
                409: ({ err }) =>
                    showError({
                        summary: "Erro",
                        detail: err.msg,
                        life: 3000,
                    }),
            };

            showLoading();
            await httpPost(
                {
                    url: `/fiscal/clonar-operacao-fiscal/${operacao?.id}/`,
                    body: {
                        nova_empresa: empresa,
                        descricao: novaDescricao,
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            hideLoading();
            showError();
        }
    }

    const abrirModal = (op) => {
        setOperacao(op);
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const esconderModal = () => {
        setOperacao(null);
        setVisible(false);
    };

    return (
        <Dialog
            header="Clonar Operação Fiscal"
            onHide={() => esconderModal()}
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "45vw", display: "block" }}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="natureza_operacao">Operação fiscal: *</label>
                        <InputText id="tabela" name="tabela" value={operacao?.descricao} readOnly disabled />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="empresa">Selecione uma empresa: *</label>
                        <MakoDropdownEmpresas
                            id="empresa"
                            name="empresa"
                            value={empresa}
                            onChange={(e) => setEmpresa(e.id)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="descricao">Descrição da nova operação *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={novaDescricao}
                            onChange={(e) => setNovaDescricao(e.target.value)}
                            autoComplete="off"
                            autoFocus
                        />
                    </div>
                </div>
                <div className="p-grid p-justify-center p-col-12 p-md-12 p-mt-1">
                    <Button
                        label="Clonar operação"
                        type="button"
                        icon="pi pi-align-justify"
                        className="p-button-info p-mr-2"
                        disabled={!novaDescricao}
                        onClick={() => clonarOperacao()}
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ClonarOperacaoModalForm = forwardRef(Modal);
