import React from "react";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { SelectButton } from "primereact/selectbutton";
import { useFormik } from "formik";

import { PageBase } from "@/components/PageBase";
import { Label } from "@/components/Label";
import { Dropdown } from "@/components/Dropdown";
import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { SITUACAO_ASSISTENCIA_TECNICA, TIPO_INDICADOR_DESTINATARIO_OCORRENCIA } from "@/assets/constants/vendas";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/vendas/tipos-ocorrencia-assistencia-tecnica/";
const BASE_URL_VOLTAR = "/vendas/cadastros/assistencia-tecnica/tipos-ocorrencia";
const INITIAL_VALUES = {
    id: 0,
    descricao: "",
    prazo_dias: 0,
    prazo_ref_abertura: false,
    novo_status: null,
    emitir_nota: false,
    com_movimentacao: false,
    ativo: true,
    indicador_destinatario: 2,
};

const TIPO_PRAZO = [
    { value: true, label: "A partir da abertura" },
    { value: false, label: "Após a última ocorrência" },
];

export const TipoOcorrenciaAssistTecnicaForm = () => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPatch } = useHttp();
    const { state } = useLocation();
    const history = useHistory();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: state ? state : INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    function handleErrors(errors) {
        let errorMessages = {};
        Object.entries(errors).forEach(([k, v]) => {
            errorMessages[k] = v;
        });
        formik.setErrors(errorMessages);
    }

    async function handleSubmit(values) {
        try {
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de ocorrência cadastrado com sucesso.",
                            life: 1500,
                        });
                        history.push(BASE_URL_VOLTAR);
                    },
                    400: (data) => handleErrors(data),
                };

                showLoading();
                await httpPost({ url: BASE_URL, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de ocorrência alterado com sucesso!",
                            life: 1500,
                        });
                        history.push(BASE_URL_VOLTAR);
                    },
                    400: (data) => handleErrors(data),
                };

                showLoading();
                await httpPatch({ url: `${BASE_URL}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            showError({
                summary: "Erro :(",
                detail: `Não foi possível submeter as informações. MOTIVO: ${error}`,
                life: 3000,
            });
        }
    }

    return (
        <PageBase>
            <h5>
                {!formik.values.id
                    ? "Novo tipo de ocorrência de assistência técnica"
                    : "Manutenção tipo de ocorrência de assistência técnica"}
            </h5>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="codigo" label="Código" />
                        <InputText id="codigo" name="id" value={formik.values.id} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            maxLength={30}
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="tipo-prazo" label="Tipo de prazo" obrigatorio />
                        <Dropdown
                            id="tipo-prazo"
                            name="prazo_ref_abertura"
                            options={TIPO_PRAZO}
                            value={formik.values.prazo_ref_abertura}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.prazo_ref_abertura })}
                        />
                        {formik.errors.prazo_ref_abertura && (
                            <small className="p-error">{formik.errors.prazo_ref_abertura}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="prazo-dias" label="Dias de prazo" obrigatorio />
                        <InputNumber
                            id="prazo-dias"
                            name="prazo_dias"
                            useGrouping={false}
                            value={formik.values.prazo_dias}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.prazo_dias })}
                        />
                        {formik.errors.prazo_dias && <small className="p-error">{formik.errors.prazo_dias}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="novo-status" label="Nova situação" />
                        <Dropdown
                            id="novo-status"
                            name="novo_status"
                            options={SITUACAO_ASSISTENCIA_TECNICA}
                            value={formik.values.novo_status}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.novo_status })}
                        />
                        {formik.errors.novo_status && <small className="p-error">{formik.errors.novo_status}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="com-movimentacao" label="Movimentação da mercadoria?" />
                        <SelectButton
                            id="com-movimentacao"
                            name="com_movimentacao"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            value={formik.values.com_movimentacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.com_movimentacao })}
                        />
                        {formik.errors.com_movimentacao && (
                            <small className="p-error">{formik.errors.com_movimentacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="emitir-nf" label="Emitir NF?" />
                        <SelectButton
                            id="emitir-nf"
                            name="emitir_nota"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            value={formik.values.emitir_nota}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.emitir_nota })}
                        />
                        {formik.errors.emitir_nota && <small className="p-error">{formik.errors.emitir_nota}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="indicador-destinatario" label="Indicador destinatário NF" />
                        <Dropdown
                            id="indicador-destinatario"
                            name="indicador_destinatario"
                            options={TIPO_INDICADOR_DESTINATARIO_OCORRENCIA}
                            value={formik.values.indicador_destinatario}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.indicador_destinatario })}
                            tooltipo="A informa se a nota fiscal será destinada a pessoa de origem ou destino."
                            showClear={false}
                        />
                        {formik.errors.indicador_destinatario && (
                            <small className="p-error">{formik.errors.indicador_destinatario}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button
                        type="submit"
                        label="Gravar"
                        icon="pi pi-save"
                        className="p-button-info p-mr-2"
                        loading={loading}
                    />
                    <Button
                        type="reset"
                        label="Cancelar"
                        icon="pi pi-times"
                        to={BASE_URL_VOLTAR}
                        className="p-button-danger p-mr-2"
                        loading={loading}
                    />
                </div>
            </form>
        </PageBase>
    );
};
