import React from "react";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";

import { ACTIONS_OPERAR_MODAIS_CAIXA } from "../../../reducers/operar-modais-caixa";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

const { FINANCEIRO_FINANCEIRO_CAIXAMOV_SUPRIR } = permissoes;

export const Suprimento = ({ className = "", dispatcher = () => {} }) => {
    const { caixaMov, padraoPlanos } = useCaixaMovimento();

    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[FINANCEIRO_FINANCEIRO_CAIXAMOV_SUPRIR]}
            label="Suprimento"
            className={className}
            onClick={() => {
                dispatcher({
                    type: ACTIONS_OPERAR_MODAIS_CAIXA.SUPRIR,
                });
            }}
            disabled={!padraoPlanos?.suprimento || caixaMov.bloqueado}
        />
    );
};
