import React, { forwardRef, useImperativeHandle, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { SITUACAO_VENDA_DEVOLUCAO, TIPOS_FORMA_PAGAMENTO_DEVOLUCAO_FORNECEDOR } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import useToast from "@/hooks/useToast";
import { Dialog } from "primereact/dialog";

const Modal = ({ onConfirm, url }, ref) => {
    const [visible, setVisible] = useState(false);
    const { showError } = useToast();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            data_devolucao: null,
            valor_total_itens: null,
            valor_frete: null,
            outros_valores: null,
            forma_pagamento: null,
            pagante_frete: null,
            gera_financeiro: false,
            sigla_uf_veiculo: "",
            transportador: null,
            placa_veiculo: "",
            status: null,
            tipo: null,
            rntc: "",
            item: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                transportador: Yup.object()
                    .shape({
                        id: Yup.number().typeError("Informe um fornecedor válido"),
                    })
                    .nullable()
                    .notRequired(),
                data_devolucao: Yup.date().notRequired().nullable().typeError("Informe uma 'data' válida"),
                valor_total_itens: Yup.number().notRequired().nullable().typeError("Informe um 'valor total' válido"),
                valor_frete: Yup.number().notRequired().nullable().typeError("Informe um 'valor de frete' válido"),
                outros_valores: Yup.number().notRequired().nullable().typeError("Informe um 'outro valor' válido"),
                sigla_uf_veiculo: Yup.string()
                    .notRequired()
                    .test("len", "Máximo de caracteres atingido: 2", (val) => val.length <= 2)
                    .typeError("Informe um 'uf' válido"),
                placa_veiculo: Yup.string()
                    .notRequired()
                    .test("len", "Máximo de caracteres atingido: 7", (val) => val.length <= 7)
                    .typeError("Informe uma 'placa de veículo válida' válida"),
                rntc: Yup.string()
                    .notRequired()
                    .test("len", "Máximo de caracteres atingido: 20", (val) => val.length <= 20)
                    .typeError("Informe um 'rntc' válida"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const baseUrl = url;
            const params = Object.keys(values).map((key) => {
                if (values[key] !== null) {
                    if (key === "incluido_por") return `${key}=${values[key].id}`;
                    if (key === "data") return `${key}=${dataToStr(values[key], "yyyy-MM-dd")}`;
                    return `${key}=${values[key]}`;
                }
                return null;
            });
            if (params.length > 0) {
                const url = baseUrl + "&" + params.filter(Boolean).join("&");
                if (typeof onConfirm === "function") onConfirm(url);
            } else {
                if (typeof onConfirm === "function") onConfirm(baseUrl);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const autoCompleteTemplate = (cliente) => {
        cliente.label = `${cliente.nome} - ${cliente.identificacao}`;
        return <div>{`${cliente.nome} - ${cliente.identificacao}`}</div>;
    };

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog visible={visible} header="Filtro Avançado" style={{ width: "55vw" }} onHide={fecharModal}>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="item">Categoria item:</label>
                        <Dropdown
                            id="item"
                            name="item"
                            url="/produtos/categorias?limit=1000"
                            optionLabel="nome"
                            optionValue="id"
                            value={formik.values.item}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.item })}
                        />
                        {formik.errors.item && <small className="p-error">{formik.errors.item}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="transportador">Transportador:</label>
                        <MakoAutoComplete
                            id="transportador"
                            name="transportador"
                            value={formik.values.transportador}
                            onChange={formik.handleChange}
                            minCaracteresBusca={1}
                            itemTemplate={autoCompleteTemplate}
                            field="label"
                            urlSearch={`/pessoas/perfis?search=`}
                            placeholder="Comece a digitar informações do transportador para buscar..."
                        />
                        {formik.errors.transportador && (
                            <small className="p-error">{formik.errors.transportador}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="sigla_uf_veiculo">UF veículo:</label>
                        <InputText
                            id="sigla_uf_veiculo"
                            name="sigla_uf_veiculo"
                            value={formik.values.sigla_uf_veiculo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.sigla_uf_veiculo })}
                        />
                        {formik.errors.sigla_uf_veiculo && (
                            <small className="p-error">{formik.errors.sigla_uf_veiculo}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="placa_veiculo">Placa do veículo:</label>
                        <InputText
                            id="placa_veiculo"
                            name="placa_veiculo"
                            value={formik.values.placa_veiculo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.placa_veiculo })}
                        />
                        {formik.errors.placa_veiculo && (
                            <small className="p-error">{formik.errors.placa_veiculo}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="rntc">RNTC:</label>
                        <InputText
                            id="rntc"
                            name="rntc"
                            value={formik.values.rntc}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.rntc })}
                        />
                        {formik.errors.rntc && <small className="p-error">{formik.errors.rntc}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_devolucao">Data devolução:</label>
                        <MakoCalendar
                            id="data_devolucao"
                            name="data_devolucao"
                            valueCalendar={formik.values.data_devolucao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_devolucao })}
                        />
                        {formik.errors.data_devolucao && (
                            <small className="p-error">{formik.errors.data_devolucao}</small>
                        )}
                    </div>

                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="forma_pagamento">Forma de pagamento:</label>
                        <Dropdown
                            id="forma_pagamento"
                            name="forma_pagamento"
                            options={TIPOS_FORMA_PAGAMENTO_DEVOLUCAO_FORNECEDOR}
                            optionLabel="label"
                            optionValue="value"
                            value={formik.values.forma_pagamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                        />
                        {formik.errors.forma_pagamento && (
                            <small className="p-error">{formik.errors.forma_pagamento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="pagante_frete">Forma de pagante frete:</label>
                        <Dropdown
                            id="pagante_frete"
                            name="pagante_frete"
                            options={TIPOS_FORMA_PAGAMENTO_DEVOLUCAO_FORNECEDOR}
                            optionLabel="label"
                            optionValue="value"
                            value={formik.values.pagante_frete}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.pagante_frete })}
                        />
                        {formik.errors.pagante_frete && (
                            <small className="p-error">{formik.errors.pagante_frete}</small>
                        )}
                    </div>
                    <div
                        className="p-field p-col-12 p-md-3 p-mt-2"
                        style={{ display: "flex", alignItems: "center", gap: "5px" }}
                    >
                        <Checkbox
                            id="gera_financeiro"
                            name="gera_financeiro"
                            checked={formik.values.gera_financeiro}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="gera_financeiro" className="p-mt-2">
                            Gera financeiro?
                        </label>
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_total_itens">Valor total itens:</label>
                        <MakoInputMoeda
                            id="valor_total_itens"
                            name="valor_total_itens"
                            value={formik.values.valor_total_itens}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_total_itens })}
                        />
                        {formik.errors.valor_total_itens && (
                            <small className="p-error">{formik.errors.valor_total_itens}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_frete">Valor frete:</label>
                        <MakoInputMoeda
                            id="valor_frete"
                            name="valor_frete"
                            value={formik.values.valor_frete}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_frete })}
                        />
                        {formik.errors.valor_frete && <small className="p-error">{formik.errors.valor_frete}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="outros_valores">Outros valores:</label>
                        <MakoInputMoeda
                            id="outros_valores"
                            name="outros_valores"
                            value={formik.values.outros_valores}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.outros_valores })}
                        />
                        {formik.errors.outros_valores && (
                            <small className="p-error">{formik.errors.outros_valores}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo">Tipo:</label>
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            url="/compras/tipos-devolucoes-fornecedor?limit=1000"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.tipo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo })}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="status">Status:</label>
                        <Dropdown
                            id="status"
                            name="status"
                            options={SITUACAO_VENDA_DEVOLUCAO}
                            optionLabel="label"
                            optionValue="value"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.status })}
                        />
                        {formik.errors.status && <small className="p-error">{formik.errors.status}</small>}
                    </div>
                </div>
                <div className="p-grid p-justify-end p-mt-2">
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => fecharModal()}
                        className="p-button-danger p-mr-2"
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={() => formik.resetForm()}
                        className="p-button-warning p-mr-2"
                    />
                    <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                </div>
            </form>
        </Dialog>
    );
};

export const FiltroAvancadoDevolucao = forwardRef(Modal);
