import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoDropdownFormula } from "@/components/MakoInputs/MakoDropdownFormula";
import { MakoFormGerador } from "@/components/MakoFormGerador";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_ESTOQUE_CUSTOSALDOESTOQUES } from "@/assets/constants/relatorios";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { gerarFileName } from "@/assets/util/util";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";

const { ExportCSV } = R.Buttons;

const OPTIONS_SALDO_NEGATIVO = SIM_NAO_BOOLEAN.concat({ id: -1, label: "Qualquer" });

const FILTROS_VIEWSET = {
    formula_1: "formula_1",
    formula_2: "formula_2",
    categoria: "categoriasku__categoria",
    saldo_negativo: "saldo_negativo",
    data_saldo: "data_saldo"
};

const BASE_URL = "/relatorios/saldo-estoque/";

const FILE_NAME = gerarFileName("Saldo Estoques");

export const RelatorioCustoSaldoEstoques = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const empresaSelecionada = useRef();
    const categoriaRef = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(formRef, categoriaRef, empresaSelecionada, botaoCsv, listagemRef);

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key] !== null && dados[key] !== "") filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.formula_1) dadosValidados.formula_1 = dadosValidados.formula_1.id;
        if (dadosValidados.formula_2) dadosValidados.formula_2 = dadosValidados.formula_2.id;
        if (dadosValidados.saldo_negativo === -1) delete dadosValidados.saldo_negativo;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const filtrosTemplate = [
        {
            key: "formula_1",
            label: "Formula 1",
            path: "descricao",
        },
        {
            key: "formula_2",
            label: "Formula 1",
            path: "descricao",
        },
        {
            key: "categoria",
            label: "Categoria",
            path: "nome",
        },
        {
            key: "saldo_negativo",
            label: "Saldo negativo",
            optionKey: "id",
            optionLabel: "label",
            options: OPTIONS_SALDO_NEGATIVO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                formula_1: Yup.object()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .required("O campo 'formula' é obrigatório")
                    .typeError("Informe uma 'formula' válida"),
                formula_2: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'formula' válida"),
                categoria: Yup.number().nullable().default(null).typeError("Informe um 'grupo' válido"),
                data_saldo: Yup.date()
                    .required("Selecione uma 'data de saldo' válida.")
                    .typeError("Selecione uma 'data de saldo' válida."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: categoriaRef.current,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.formula_1) dadosValidados.formula_1 = dadosValidados.formula_1.id;
            if (dadosValidados.formula_2) dadosValidados.formula_2 = dadosValidados.formula_2.id;
            if (dadosValidados.saldo_negativo === -1) delete dadosValidados.saldo_negativo;

            if (dadosValidados.data_saldo)
                dadosValidados.data_saldo = dataToStr(dadosValidados.data_saldo, "yyyy-MM-dd");


            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);

                solicitarRelatorio({
                    chave: RELATORIO_ESTOQUE_CUSTOSALDOESTOQUES,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
                limparFormulario();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "codigo", header: "Código", style: { width: "8%" } },
        { field: "descricao", header: "Descrição" },
        { field: 'custo_consolidado', header: "Custo consolidado", align: "right", decimal: true},
        {
            field: "qtd_consolidado",
            header: "Qtd Consolidado",
            style: { width: "10%" },
            align: "center",
            decimal: false,
        },
        { field: 'custo_normal', header: "Custo normal", align: "right", decimal: true},
        { field: "qtd_normal", header: "Qtd Normal", style: { width: "10%" }, align: "center", decimal: false },
    ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_ESTOQUE_CUSTOSALDOESTOQUES}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const selecionarCategoria = (e) => {
        categoriaRef.current = e;
    };

    return (
        <R.Wrapper titulo={"de custo e saldo estoques"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        formula_1: null,
                        formula_2: null,
                        categoria: null,
                        saldo_negativo: -1,
                        data_saldo: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Formula 1",
                        inputId: "formula_1",
                        inputName: "formula_1",
                        component: MakoDropdownFormula,
                        required: true,
                        componentProps: {
                            filter: true,
                            filterBy: "descricao",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Formula 2",
                        inputId: "formula_2",
                        inputName: "formula_2",
                        component: MakoDropdownFormula,
                        componentProps: {
                            filter: true,
                            filterBy: "descricao",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Data Saldo",
                        inputId: "data_saldo",
                        inputName: "data_saldo",
                        component: MakoCalendar,
                        componentProps: {
                            
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Grupo",
                        inputId: "categoria",
                        inputName: "categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: selecionarCategoria,
                            categoriaTituloSelecionavel: true,
                            showClear: true,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Saldo negativo",
                        inputId: "saldo_negativo",
                        inputName: "saldo_negativo",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: OPTIONS_SALDO_NEGATIVO,
                            optionValue: "id",
                            optionLabel: "label",
                            showClear: false,
                        },
                        fieldSize: 2,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_ESTOQUE_CUSTOSALDOESTOQUES}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
