import React, { useRef } from "react";
import classNames from "classnames";
import { useLocation, useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Label } from "@/components/Label";
import { Dropdown } from "@/components/Dropdown";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MOTIVO_ASSISTENCIA_TECNICA, TIPO_ASSISTENCIA_TECNICA } from "@/assets/constants/vendas";
import { dataToStr } from "@/assets/util/datas";
import { parseNumber } from "@/assets/helpers/number";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

const URL_API = "/vendas/assistencias-tecnicas/";
const URL_VOLTAR = "/vendas/assistencias-tecnicas";
const INITIAL_VALUES = {
    id: 0,
    empresa: null,
    cliente: null,
    venda: null,
    item_venda: null,
    sku_assistencia: null,
    numero_serie: "",
    tipo_assistencia: null,
    motivo_assistencia: null,
    datahora_solicitacao: new Date(),
    cadastrado_por: null,
    datahora_cadastro: null,
    agrupador: "",
    descricao_problema: "",
    vencimento: null,
    canal_assistencia: null,
    situacao: "A",
};

export const AssistenciaTecnicaForm = () => {
    const dropdownItensVendaRef = useRef(null);
    const { state } = useLocation();
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { user } = useAuth();
    const { httpPatch, httpPost } = useHttp();

    const formik = useFormik({
        initialValues: state ? state : INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    const voltarParaListagem = () => {
        history.push(URL_VOLTAR);
    };

    function handleErrors(errors) {
        let errorMessages = {};
        Object.entries(errors).forEach(([k, v]) => {
            errorMessages[k] = v;
        });
        formik.setErrors(errorMessages);
    }

    const dropdownVendaOpcaoTemplate = (option) => {
        return (
            <span>
                {option.id} - {dataToStr(option.data, "dd/MM/yyyy")}
            </span>
        );
    };

    const dropdownVendaSelecionadaTemplate = (option, props) => {
        if (option) return dropdownVendaOpcaoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const dropdownItemVendaOpcaoTemplate = (option) => {
        return (
            <span>
                {option.sku.codigo} - {option.sku.descricao_derivada} - Quant: {parseNumber(option.quantidade)}
            </span>
        );
    };

    const dropdownItemVendaSelecionadaTemplate = (option, props) => {
        if (option) return dropdownItemVendaOpcaoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    async function handleSubmit(values) {
        try {
            const itemVenda = dropdownItensVendaRef.current?.getObject((e) => e.id === values.item_venda);
            const body = {
                ...values,
                cliente: values.cliente.id,
                vencimento: dataToStr(formik.values.vencimento, "yyyy-MM-dd"),
                sku_assistencia: values.venda ? itemVenda?.sku.id : values.sku_assistencia.id,
                cadastrado_por: values.cadastrado_por?.id || user.id,
            };
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de ocorrência cadastrado com sucesso.",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                    400: (data) => handleErrors(data),
                };

                showLoading();
                await httpPost({ url: URL_API, body }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de ocorrência alterado com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                    400: (data) => handleErrors(data),
                };

                showLoading();
                await httpPatch({ url: `${URL_API}${values.id}/`, body }, handlers);
                hideLoading();
            }
        } catch (error) {
            showError({
                summary: "Erro :(",
                detail: `Não foi possível submeter as informações. MOTIVO: ${error}`,
                life: 3000,
            });
        }
    }

    return (
        <PageBase>
            <h5>Incluindo / editando assistência técnica</h5>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="codigo" label="Código" />
                        <InputText id="codigo" name="id" value={formik.values.id} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="empresa" label="Empresa / filial" obrigatorio />
                        <MakoDropdownEmpresas
                            id="empresa"
                            name="empresa"
                            value={formik.values.empresa}
                            onChange={(e) => formik.setFieldValue("empresa", e.id)}
                            className={classNames({ "p-invalid": formik.errors.empresa })}
                        />
                        {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="cliente" label="Cliente" obrigatorio />
                        <MakoInputCliente
                            id="cliente"
                            name="cliente"
                            value={formik.values.cliente}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cliente })}
                        />
                        {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="venda" label="Venda" />
                        <Dropdown
                            id="venda"
                            name="venda"
                            url={`/vendas/vendas/?cliente__id=${formik.values.cliente?.id}&situacao=F&query={id,data}`}
                            buscar={formik.values.cliente instanceof Object}
                            itemTemplate={dropdownVendaOpcaoTemplate}
                            valueTemplate={dropdownVendaSelecionadaTemplate}
                            optionValue="id"
                            optionLabel="data"
                            value={formik.values.venda}
                            onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldValue("item_venda", null);
                                formik.setFieldValue("sku_assistencia", null);
                            }}
                            className={classNames({ "p-invalid": formik.errors.venda })}
                        />
                        {formik.errors.venda && <small className="p-error">{formik.errors.venda}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-9">
                        <Label htmlFor="item-venda" label="Item da venda assistido" />
                        <Dropdown
                            id="item-venda"
                            name="item_venda"
                            ref={dropdownItensVendaRef}
                            buscar={!!formik.values.venda}
                            url={`vendas/itens-vendas/?venda=${formik.values.venda}`}
                            itemTemplate={dropdownItemVendaOpcaoTemplate}
                            valueTemplate={dropdownItemVendaSelecionadaTemplate}
                            optionValue="id"
                            optionLabel="data"
                            value={formik.values.item_venda}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.item_venda })}
                        />
                        {formik.errors.item_venda && <small className="p-error">{formik.errors.item_venda}</small>}
                    </div>
                </div>
                <MakoBuscaSkuPersonalizada
                    statusItem="F"
                    disabledBusca={!!formik.values.venda}
                    skuValue={formik.values.sku_assistencia}
                    skuChange={(e) => formik.setFieldValue("sku_assistencia", e)}
                    skuError={formik.errors.sku_assistencia}
                />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="num-serie" label="Nº de série" />
                        <InputText
                            id="num-serie"
                            name="numero_serie"
                            value={formik.values.numero_serie}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.numero_serie })}
                        />
                        {formik.errors.numero_serie && <small className="p-error">{formik.errors.numero_serie}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo-assistencia" label="Tipo de assistência" obrigatorio />
                        <Dropdown
                            id="tipo-assistencia"
                            name="tipo_assistencia"
                            options={TIPO_ASSISTENCIA_TECNICA}
                            value={formik.values.tipo_assistencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_assistencia })}
                        />
                        {formik.errors.tipo_assistencia && (
                            <small className="p-error">{formik.errors.tipo_assistencia}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="motivo" label="Motivo" obrigatorio />
                        <Dropdown
                            id="motivo"
                            name="motivo_assistencia"
                            options={MOTIVO_ASSISTENCIA_TECNICA}
                            value={formik.values.motivo_assistencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.motivo_assistencia })}
                        />
                        {formik.errors.motivo_assistencia && (
                            <small className="p-error">{formik.errors.motivo_assistencia}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="canal" label="Canal" obrigatorio />
                        <Dropdown
                            id="canal"
                            name="canal_assistencia"
                            url={
                                formik.values.id
                                    ? "/vendas/canais-assistencia-tecnica/"
                                    : "/vendas/canais-assistencia-tecnica/?ativo=True"
                            }
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.canal_assistencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.canal_assistencia })}
                        />
                        {formik.errors.canal_assistencia && (
                            <small className="p-error">{formik.errors.canal_assistencia}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <Label htmlFor="descricao-problema" label="Descrição do problema" obrigatorio />
                        <InputTextarea
                            id="descricao-problema"
                            name="descricao_problema"
                            rows={3}
                            autoComplete="off"
                            autoResize
                            value={formik.values.descricao_problema}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao_problema })}
                        />
                        {formik.errors.descricao_problema && (
                            <small className="p-error">{formik.errors.descricao_problema}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="agrupador" label="Código agrupador" />
                        <InputText
                            id="agrupador"
                            name="agrupador"
                            value={formik.values.agrupador}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.agrupador })}
                        />
                        {formik.errors.agrupador && <small className="p-error">{formik.errors.agrupador}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="datahora-pedido" label="Data/hora pedido" obrigatorio />
                        <MakoCalendar
                            id="datahora-pedido"
                            name="datahora_solicitacao"
                            valueCalendar={formik.values.datahora_solicitacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.datahora_solicitacao })}
                        />
                        {formik.errors.datahora_solicitacao && (
                            <small className="p-error">{formik.errors.datahora_solicitacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="vencimento" label="Vencimento" />
                        <MakoCalendar
                            id="vencimento"
                            name="vencimento"
                            valueCalendar={formik.values.vencimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vencimento })}
                        />
                        {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="situacao" label="Situação" />
                        <InputText id="situacao" name="situacao" disabled value={formik.values.situacao} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="cadastrado-por" label="Cadastrado por" />
                        <InputText
                            id="cadastrado-por"
                            name="cadastrado-por"
                            value={formik.values.cadastrado_por?.usuario.username || user.usuario.username}
                            disabled
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button
                        type="submit"
                        label="Gravar"
                        icon="pi pi-save"
                        className="p-button-info p-mr-2"
                        loading={loading}
                    />
                    <Button
                        type="button"
                        label="Cancelar"
                        icon="pi pi-times"
                        loading={loading}
                        onClick={voltarParaListagem}
                        className="p-button-danger p-mr-2"
                    />
                </div>
            </form>
        </PageBase>
    );
};
