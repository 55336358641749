import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Form } from "../form";

const HEADER_NEW = "Incluindo carga";
const HEADER_EDIT = "Editando carga";

const Component = ({ successCallback = () => {}, carga }, ref) => {
    const [header, setHeader] = useState(HEADER_NEW);
    const [visible, setVisible] = useState(false);

    const formRef = useRef(null);
    const editRef = useRef(null);

    const show = (value) => {
        if (value) editRef.current = value;
        setHeader(!value ? HEADER_NEW : HEADER_EDIT);
        setVisible(true);
    };

    const onSuccess = useCallback(
        (data) => {
            setVisible(false);
            successCallback(data);
        },
        [successCallback]
    );

    const onHide = () => {
        editRef.current = null;
        setVisible(false);
    };

    const onShow = () => {
        const edit = editRef.current;
        if (edit) formRef.current?.editar({ ...edit, empresa: edit.empresa?.id });
    };

    useImperativeHandle(ref, () => {
        return {
            show,
            hide: () => setVisible(false),
        };
    });

    return (
        <Dialog visible={visible} header={header} onHide={onHide} onShow={onShow} style={{ width: "90vw" }}>
            <Form ref={formRef} successCallback={onSuccess} carga={carga} />
        </Dialog>
    );
};

export const Modal = forwardRef(Component);
