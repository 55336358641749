import React, { useImperativeHandle, useState, forwardRef, useMemo } from "react";

import { getMonth, getYear, setMonth } from "date-fns";
import { parseData } from "@/assets/util/datas";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputNumber, InputText } from "primereact";
import { Dialog } from "primereact/dialog";

import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoButton } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const INITIAL_VALUE = {
    dia: null,
    mes: null,
    ano: null,
    codigo_uf: null,
    codigo_ibge: null,
    descricao: "",
};

const Modal = ({ onSuccess }, ref) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [visible, setVisible] = useState(false);
    const [estados, setEstados] = useState([]);

    const { showSuccess } = useToast();
    const { empresaSelecionada } = useEmpresa();
    const { httpPost, httpPatch } = useHttp();

    const fecharModal = () => {
        setVisible(() => false);
        formik.resetForm();
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: INITIAL_VALUE,
        onSubmit: handleSubmit,
    });

    const abrirModal = (feriado = null) => {
        if (feriado)
            setValues({
                ...feriado,
                mes: feriado.mes ? setMonth(new Date(), feriado.mes) : null,
                ano: feriado.ano ? parseData(`${feriado.ano}-01-01`) : null,
                codigo_uf: feriado.codigo_uf ? feriado.codigo_uf.codigo_uf : null,
            });
        setVisible(() => true);
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                dia: Yup.number().required("O campo 'dia' é obrigatório").typeError("Informe um 'dia' válido"),
                mes: Yup.date().required("O campo 'mês' é obrigatório").typeError("Informe um 'mês' válido"),
                ano: Yup.date().nullable().typeError("Informe um 'ano' válido"),
                codigo_uf: Yup.string().nullable().typeError("Informe um 'estado' válido"),
                codigo_ibge: Yup.object().nullable().typeError("Informe uma 'cidade' válida"),
                descricao: Yup.string()
                    .required("O campo 'descrição' é obrigatório")
                    .typeError("Informe uma 'descrição' válida"),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });
            dadosValidados.mes = getMonth(dadosValidados.mes);
            if (dadosValidados.ano) dadosValidados.ano = getYear(dadosValidados.ano);
            if (dadosValidados.codigo_ibge) dadosValidados.codigo_ibge = dadosValidados.codigo_ibge.codigo;

            if (!values?.id) {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso!",
                            detail: data?.msg || "Feriado cadastrado com sucesso.",
                            life: 2000,
                        });
                        if (typeof onSuccess === "function") onSuccess();
                        fecharModal();
                    },
                };
                showLoading();
                await httpPost({ url: "/configuracoes/feriados/", body: dadosValidados }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso!",
                            detail: data?.msg || "Feriado alterado com sucesso.",
                            life: 2000,
                        });
                        if (typeof onSuccess === "function") onSuccess();
                        fecharModal();
                    },
                };
                showLoading();
                await httpPatch({ url: `/configuracoes/feriados/${values.id}/`, body: dadosValidados }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const urlUF = useMemo(() => {
        let url = "/pessoas/estados?query={id,nome,uf,codigo_uf}&limit=100";
        if (!empresaSelecionada) return url;
        const { enderecoperfil_set } = empresaSelecionada;
        if (!enderecoperfil_set || !enderecoperfil_set?.length) return url;
        const principal = enderecoperfil_set.find((endereco) => endereco.principal && endereco.ativo);
        if (principal) return `${url}&pais__sigla=${principal.cidade.estado.pais.sigla}`;
        const enderecoExistente = enderecoperfil_set.find((endereco) => endereco.ativo);
        return `${url}&pais__sigla=${enderecoExistente.cidade.estado.pais.sigla}`;
    }, [empresaSelecionada]);

    const urlCidade = useMemo(() => {
        let url = "/pessoas/cidades/?query={id,nome,codigo}&limit=1000&ordering=nome";
        const codigo_uf = formik.values.codigo_uf;
        if (!codigo_uf) return `${url}&nome__unaccent__icontains=`;
        const estado = estados.find((e) => e.codigo_uf === codigo_uf);
        if (estado) return `${url}&estado=${estado.id}&nome__unaccent__icontains=`;
        return `${url}&nome__unaccent__icontains=`;
    }, [formik.values.codigo_uf, estados]);

    return (
        <Dialog header="Cadastrar feriado" visible={visible} onHide={fecharModal} style={{ width: "80rem" }}>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onInput={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="dia" label="Dia" obrigatorio />
                        <InputNumber
                            id="dia"
                            name="dia"
                            useGrouping={false}
                            min={1}
                            max={31}
                            maxFractionDigits={0}
                            value={formik.values.dia}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.dia })}
                        />
                        {formik.errors.dia && <small className="p-error">{formik.errors.dia}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="mes" label="Mês" obrigatorio />
                        <MakoCalendar
                            id="mes"
                            name="mes"
                            dateFormat="MM"
                            view="month"
                            mask="MM"
                            placeholder="Selecione"
                            valueCalendar={formik.values.mes}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.mes })}
                        />
                        {formik.errors.mes && <small className="p-error">{formik.errors.mes}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="ano" label="Ano" />
                        <MakoCalendar
                            id="ano"
                            name="ano"
                            dateFormat="yy"
                            view="year"
                            mask="yy"
                            placeholder="Selecione"
                            valueCalendar={formik.values.ano}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.ano })}
                        />
                        {formik.errors.ano && <small className="p-error">{formik.errors.ano}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="codigo_uf" label="Estado / Província" />
                        <Dropdown
                            id="codigo_uf"
                            name="codigo_uf"
                            optionLabel="nome"
                            optionValue="codigo_uf"
                            setObjects={setEstados}
                            url={urlUF}
                            filter
                            showClear
                            filterBy="nome"
                            value={formik.values.codigo_uf}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo_uf })}
                        />
                        {formik.errors.codigo_uf && <small className="p-error">{formik.errors.codigo_uf}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="codigo_ibge" label="Município" />
                        <MakoAutoComplete
                            inputId="codigo_ibge"
                            name="codigo_ibge"
                            field="nome"
                            urlSearch={urlCidade}
                            minCaracteresBusca={2}
                            placeholder="Digite para pesquisar..."
                            value={formik.values.codigo_ibge}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo_ibge })}
                        />
                        {formik.errors.codigo_ibge && <small className="p-error">{formik.errors.codigo_ibge}</small>}
                    </div>
                </div>
                <MakoActionsButtons>
                    <MakoButton
                        loading={loading}
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        type="submit"
                        className="p-button-info"
                    />
                    <MakoButton
                        icon={MAKO_ICONS.LIMPAR_FORM}
                        label="Limpar"
                        type="reset"
                        className="p-button-warning"
                        onClick={formik.resetForm}
                        loading={loading}
                    />
                    <MakoButton
                        loading={loading}
                        icon={MAKO_ICONS.CANCEL}
                        label="Cancelar"
                        type="button"
                        className="p-button-danger"
                        onClick={fecharModal}
                    />
                </MakoActionsButtons>
            </form>
        </Dialog>
    );
};

export const FormFeriadosModal = forwardRef(Modal);
