import React from "react";

import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Label } from "@/components/Label";
import useReceberCrediario from "../../hooks/useReceberCrediario";

export const Info = () => {
    const { info: dados } = useReceberCrediario();

    return (
        <Panel header="Detalhes" className="p-mb-3" toggleable>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="quantidade_parcelas" label="Qtd. Parcelas" />
                    <InputText
                        id="quantidade_parcelas"
                        name="quantidade_parcelas"
                        value={dados.quantidade_parcelas}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="total_original" label="Total original" />
                    <MakoInputMoeda
                        id="total_original"
                        name="total_original"
                        valueMoeda={dados.total_original}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="total_descontos" label="Total descontos" />
                    <MakoInputMoeda
                        id="total_descontos"
                        name="total_descontos"
                        valueMoeda={dados.total_descontos}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="total_acrescimos" label="Total acréscimos" />
                    <MakoInputMoeda
                        id="total_acrescimos"
                        name="total_acrescimos"
                        valueMoeda={dados.total_acrescimos}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="total_receber" label="Total receber" />
                    <MakoInputMoeda id="total_receber" name="total_receber" valueMoeda={dados.total_receber} disabled />
                </div>
            </div>
        </Panel>
    );
};
