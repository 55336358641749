import React from "react";

import { TabPessoas } from "./tabs";
import { PessoaProvider } from "@/contexts/pessoaContext";

export const TabPessoasContext = (props) => {
    return (
        <PessoaProvider>
            <TabPessoas tipoForm={props.location.state.tipo_form} perfilId={props.location.state.idPerfil} />
        </PessoaProvider>
    );
};
