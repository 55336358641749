import React, { forwardRef, useImperativeHandle, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";

import { MakoListagem } from "@/components/MakoListagem";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { axiosPut } from "@/services/http";
import useToast from "@/hooks/useToast";
import { dataToStr } from "@/assets/util/datas";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [centroEstocagem, setCentroEstocagem] = useState({});
    const [dataInicial, setDataInicial] = useState(null);
    const [dataFinal, setDataFinal] = useState(null);
    const [tipoRecalculo, setTipoRecalculo] = useState(false);
    const [skuSelecionado, setSkuSelecionado] = useState(null);
    const [skus, setSkus] = useState([]);
    const inputBuscaSKURef = useRef(null);
    const { showSuccess, showWarning, showError } = useToast();

    async function recalcularSaldoSKU() {
        if (!dataInicial || !dataFinal) {
            showWarning({
                summary: "Ooops",
                detail: "Você precisa informar 'data inicial' e 'data final'.",
                life: 3000,
            });
            return;
        }
        const body = {
            data_inicial: dataToStr(dataInicial, "yyyy-MM-dd"),
            data_final: dataToStr(dataFinal, "yyyy-MM-dd"),
            centro_estocagem: centroEstocagem?.id,
            sku: tipoRecalculo ? skus.map((sku) => sku.id) : [],
        };
        setLoading(true);
        const { status, data } = await axiosPut(`/produtos/reprocessar-saldosku/`, body);
        setLoading(false);
        if (status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "O saldo dos produtos estão sendo reprocessados. Será notificado no final.",
                life: 1500,
            });
            setTimeout(() => fecharModal(), 1500);
        } else if (status < 500) {
            showWarning({
                summary: "Aviso",
                detail: data?.msg || "Não foi possível reprocessar os saldos dos produtos.",
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível recalcular os saldos dos produtos.",
                life: 3000,
            });
        }
    }

    const abrirModal = (centroEstocagem) => {
        if (centroEstocagem) setCentroEstocagem(centroEstocagem);
        setVisible(true);
    };

    const fecharModal = () => {
        setCentroEstocagem({});
        setDataInicial(null);
        setDataFinal(null);
        setTipoRecalculo(false);
        setSkuSelecionado(null);
        setSkus([]);
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const renderFooter = () => {
        return (
            <Button
                type="button"
                label="Recalcular"
                icon="pi pi-calculator"
                loading={loading}
                onClick={recalcularSaldoSKU}
            />
        );
    };

    const opcoes = [
        { label: "Recalcular todos", value: false },
        { label: "Recalcular selecionados", value: true },
    ];

    const selecionarSKU = () => {
        if (skuSelecionado instanceof Object) {
            if (skus?.findIndex((sku) => sku.codigo === skuSelecionado.codigo) === -1) {
                const _skus = [...skus];
                _skus.push(skuSelecionado);
                setSkus(_skus);
            }
            inputBuscaSKURef.current?.limparCampos();
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-trash"
                    onClick={() => {
                        const _skus = skus.filter((sku) => sku.codigo !== rowData.codigo);
                        setSkus(_skus);
                    }}
                    className="p-button-rounded p-button-danger"
                />
            </div>
        );
    };

    const colunas = [
        { field: "codigo", header: "Código", style: { maxWidth: "8%" } },
        { field: "descricao_derivada", header: "Descrição" },
        {
            field: "action",
            header: "Ações",
            style: { maxWidth: "10%" },
            action: actionBodyTemplate,
        },
    ];

    return (
        <Dialog
            visible={visible}
            header={`Recalcular saldo: ${centroEstocagem.descricao}`}
            style={{ width: "65vw" }}
            onHide={fecharModal}
            footer={renderFooter}
        >
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="data-inicial">Data inicial</label>
                    <MakoCalendar
                        id="data-inicial"
                        valueCalendar={dataInicial}
                        onChange={(e) => setDataInicial(e.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="data-final">Data final</label>
                    <MakoCalendar id="data-final" valueCalendar={dataFinal} onChange={(e) => setDataFinal(e.value)} />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="produtos">Produtos</label>
                    <SelectButton options={opcoes} value={tipoRecalculo} onChange={(e) => setTipoRecalculo(e.value)} />
                </div>
            </div>
            {tipoRecalculo && (
                <>
                    <MakoBuscaSkuPersonalizada
                        ref={inputBuscaSKURef}
                        statusItem="F"
                        skuValue={skuSelecionado}
                        skuChange={(e) => setSkuSelecionado(e)}
                        exibirBotaoSelecionar
                        onSelecionar={selecionarSKU}
                    />
                    <MakoListagem dadosLocal={skus} colunas={colunas} configTabela={{ paginator: true }} />
                </>
            )}
        </Dialog>
    );
};

export const ModalRecalcularSaldoSKU = forwardRef(Modal);
