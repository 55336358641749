import { useContext } from "react";

import AuthContext from "@/contexts/authContext";

const useParam = () => {
    const { params, loadParams, getParam } = useContext(AuthContext);
    return { params, loadParams, getParam };
};

export default useParam;
