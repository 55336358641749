import React, { useMemo } from "react";

import { Dropdown } from "../../Dropdown";

import { PRODUTOS_ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_TODAS } from "@/assets/constants/parametros";

import useEmpresa from "@/hooks/useEmpresa";
import useParam from "@/hooks/useParam";

import { gerarFiltrosUrl } from "@/assets/util/util";

const BASE_URL = "/produtos/saldo-estoque-formula/";

const BASE_FILTROS = {
    limit: 200,
    ativa: true,
};

export const MakoDropdownFormula = ({
    apenasAtivo = true,
    todasFormulas = false,
    naoBuscarSemEmpresa = true,
    considerarEmpresa = true,
    filtros = {},
    buscar = true,
    ...props
}) => {
    const { empresaSelecionadaId } = useEmpresa();
    const { getParam } = useParam();

    const url = useMemo(() => {
        const parametro = getParam(PRODUTOS_ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_TODAS);
        let _filtros = { ...BASE_FILTROS, ativa: apenasAtivo, ...filtros };
        if (considerarEmpresa && todasFormulas && empresaSelecionadaId && parametro) {
            if (parametro.valor !== "1") filtros.empresa = empresaSelecionadaId;
        }
        return `${BASE_URL}?${gerarFiltrosUrl(_filtros)}`;
    }, [empresaSelecionadaId, apenasAtivo, todasFormulas, getParam, filtros, considerarEmpresa]);

    const efetuarBusca = useMemo(() => {
        if (considerarEmpresa && naoBuscarSemEmpresa) return !!empresaSelecionadaId;
        return buscar;
    }, [considerarEmpresa, buscar, naoBuscarSemEmpresa, empresaSelecionadaId]);

    return (
        <Dropdown
            url={url}
            optionLabel="descricao"
            placeholder="Selecione uma formula"
            buscar={efetuarBusca}
            {...props}
        />
    );
};
