import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoAutoCompleteUsuario, MakoDropdownOrdenado, MakoInputPeriodo } from "@/components/MakoInputs";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import MakoListagem from "@/components/MakoListagem";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_LOGS_LOGS } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";
import { gerarFileName } from "@/assets/util/util";

const { ExportCSV } = R.Buttons;

const FILTROS_VIEWSET = {
    usuario: "usuario",
    ip: "ip",
    data_hora__lte: "data_hora__lte",
    data_hora__gte: "data_hora__gte",
    detalhes__unaccent__icontains: "detalhes__unaccent__icontains",
    ordering: "ordering",
};

const ORDENACAO_FILTROS = [
    { value: "ip", label: "IP" },
    { value: "data_hora", label: "Data" },
    { value: "detalhes", label: "Detalhes" },
];

const FILTROS_TEMPLATE = [
    {
        key: "usuario",
        label: "Usuario",
        type: TIPOS_FILTROS_TEMPLATE.PESSOA,
    },
    {
        key: "ip",
        label: "Endereço IP",
    },
    {
        key: ["data_hora__lte", "data_hora__gte"],
        label: "Data",
        type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
    },
    {
        key: "detalhes__unaccent__icontains",
        label: "Detalhes da operação",
    },
];

const COLUNAS = [
    { field: "codigo", header: "Código", style: { width: "5%" } },
    { field: "data", header: "Data", style: { width: "10%" }, align: "center" },
    {
        field: "usuario",
        header: "Usuário",
    },
    { field: "nome_curto", header: "Nome curto", style: { width: "8%" } },
    { field: "detalhes", header: "Detalhes da operação" },
    { field: "ip", header: "Endereço IP", style: { width: "7%" }, align: "center" },
];

const BASE_URL = "/relatorios/logs/";

const FILE_NAME = gerarFileName("Logs Emitidos");

export const RelatorioLogs = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(listagemRef, botaoCsv, formRef);

    const { solicitarRelatorio } = useRelatorio();

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                usuario: Yup.object().nullable().typeError("Informe uma 'usuario' válido"),
                ip: Yup.string()
                    .nullable()
                    .test("validacao tipo", "O tamanho minimo: ao menos '10' caracteres", (value = "") => {
                        if (!value) return true;
                        return value?.length === 10;
                    })
                    .typeError("Informe uma 'ip' válido"),
                data_hora__gte: Yup.date().nullable().typeError("Informe um 'data inicial' válida"),
                data_hora__lte: Yup.date().nullable().typeError("Informe um 'data final' válida"),
                detalhes__unaccent__icontains: Yup.string().nullable().typeError("Informe um 'detalhe' válido"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(FILTROS_TEMPLATE, {
                ...dadosValidados,
            });

            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.usuario !== null) dadosValidados.usuario = dadosValidados.usuario?.usuario.id;
            if (dadosValidados.data_hora__gte !== null)
                dadosValidados.data_hora__gte = dataToStr(dadosValidados.data_hora__gte, "yyyy-MM-dd HH:mm:ss");
            if (dadosValidados.data_hora__lte !== null)
                dadosValidados.data_hora__lte = dataToStr(dadosValidados.data_hora__lte, "yyyy-MM-dd HH:mm:ss");
            if (buscarListagem === false) {
                let filtros = {};
                if (dadosValidados) {
                    Object.keys(dadosValidados).forEach((key) => {
                        if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                    });
                    solicitarRelatorio({
                        chave: RELATORIO_LOGS_LOGS,
                        emails,
                        filtros,
                        versao,
                        filtros_template: filtrosTemplateAplicados,
                        corpo_email,
                        enviar_fila: background,
                    });
                }
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                setErrors(errorMessages);
            }
        }
    }

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setBuscarListagem(false);
        setUrl(null);
    };

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;

        if (dadosValidados.usuario !== null) dadosValidados.usuario = dadosValidados.usuario?.usuario.id;
        if (dadosValidados.data_hora__gte !== null)
            dadosValidados.data_hora__gte = dataToStr(dadosValidados.data_hora__gte, "yyyy-MM-dd HH:mm:ss");
        if (dadosValidados.data_hora__lte !== null)
            dadosValidados.data_hora__lte = dataToStr(dadosValidados.data_hora__lte, "yyyy-MM-dd HH:mm:ss");
        let filtros = {};
        if (dadosValidados) {
            Object.keys(dadosValidados).forEach((key) => {
                if (dadosValidados[key] !== null && dadosValidados[key] !== "") filtros[key] = dadosValidados[key];
            });
        }
        return aplicarFiltrosCsv(filtros);
    };

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_LOGS_LOGS}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    return (
        <R.Wrapper titulo={"logs emitidos no sistema"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        usuario: null,
                        ip: "",
                        data_hora__gte: null,
                        data_hora__lte: null,
                        detalhes__unaccent__icontains: "",
                        ordering: "",
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Detalhes",
                        inputId: "detalhes__unaccent__icontains",
                        inputName: "detalhes__unaccent__icontains",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                            autoComplete: "off",
                        },
                        fieldSize: 12,
                    },
                    {
                        label: "Usuário",
                        inputId: "usuario",
                        inputName: "usuario",
                        component: MakoAutoCompleteUsuario,
                        componentEspecial: COMPONENTES_ESPECIAIS.PESSOA,
                        fieldSize: 3,
                    },
                    {
                        inputId: "data_hora__gte",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        componentProps: {
                            label: "Data",
                            nameInicio: "data_hora__gte",
                            nameFinal: "data_hora__lte",
                            valueInicio: "data_hora__gte",
                            valueFinal: "data_hora__lte",
                            errorInicio: "data_hora__gte",
                            errorFinal: "data_hora__lte",
                            withTime: true,
                        },
                    },
                    {
                        label: "Endereço IP",
                        inputId: "ip",
                        inputName: "ip",
                        component: InputMask,
                        componentProps: {
                            autoComplete: "off",
                            maxLength: 10,
                            mask: "999.99.9.9",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Ordenado por",
                        inputId: "ordering",
                        inputName: "ordering",
                        component: MakoDropdownOrdenado,
                        componentProps: {
                            campos: ORDENACAO_FILTROS,
                        },
                        fieldSize: 3,
                    },
                ]}
            >
                <R.Buttons.Wrapper>
                    <R.Buttons.Visualizar onClick={handleVisualizar} />
                    <R.Buttons.GerarPdf
                        chave={RELATORIO_LOGS_LOGS}
                        setFieldValue={setFieldValue}
                        handleSubmit={submit}
                    />
                    <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                    <R.Buttons.Limpar onClick={limparFormulario} />
                </R.Buttons.Wrapper>
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={COLUNAS}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
