import { SEVERITIES } from "./primereact";

export const STATUS_ENTRADA = [
    { value: "P", label: "Pendente", severity: SEVERITIES.warning },
    { value: "C", label: "Cancelada", severity: SEVERITIES.danger },
    { value: "V", label: "Validada", severity: SEVERITIES.info },
    { value: "F", label: "Finalizada", severity: SEVERITIES.success },
];

export const TIPO_FRETE = [
    { value: 0, label: "Contratação do Frete por conta do Remetente (CIF)" },
    { value: 1, label: "Contratação do Frete por conta do Destinatário (FOB)" },
    { value: 2, label: "Contratação do Frete por conta de Terceiros" },
    { value: 3, label: "Transporte Próprio por conta do Remetente" },
    { value: 4, label: "Transporte Próprio por conta do Destinatário" },
    { value: 9, label: "Sem Ocorrência de Transporte" },
];

export const CST_ICMS_FRETE = [
    { value: "00" },
    { value: "10" },
    { value: "20" },
    { value: "30" },
    { value: "40" },
    { value: "41" },
    { value: "50" },
    { value: "51" },
    { value: "60" },
    { value: "70" },
    { value: "90" },
];

export const TIPO_PAGANTE_FRETE = [
    { value: 0, label: "0 - Frete por conta do Remetente (CIF)" },
    { value: 1, label: "1 - Frete por conta do Destinatário (FOB)" },
    { value: 2, label: "2 - Frete por conta de Terceiros" },
    { value: 3, label: "3 - Transporte Próprio por conta do Remetente" },
    { value: 4, label: "4 - Transporte Próprio por conta do Destinatário" },
    { value: 9, label: "9 - Sem transporte" },
];

export const STATUS_DEVOLUCAO_FORNECEDOR = [
    { value: "P", label: "Pendente", severity: SEVERITIES.warning },
    { value: "C", label: "Cancelada", severity: SEVERITIES.danger },
    { value: "V", label: "Validada", severity: SEVERITIES.info },
    { value: "F", label: "Finalizada", severity: SEVERITIES.success },
];

export const TIPO_DEVOLUCAO_FORNECEDOR_CHOICE = [
    { value: 1, label: "Troca" },
    { value: 2, label: "Conserto" },
    { value: 3, label: "Sem retorno" },
    { value: 4, label: "Consignação" },
    { value: 5, label: "Demonstração" },
    { value: 6, label: "Devolução de Remessa" },
];
