import React from "react";
import { InputText } from "primereact/inputtext";

import { MakoCalendar } from "@/components/MakoCalendar";

export const BaseModalAjusteComissao = ({ baseComissao }) => {
    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="venda-devolucao">Venda / devolução</label>
                    <InputText id="venda-devolucao" disabled value={baseComissao.venda || baseComissao.devolucao} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-evento">Data do evento</label>
                    <MakoCalendar id="data-evento" disabled valueCalendar={baseComissao.data_evento} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-comissao">Data da comissão</label>
                    <MakoCalendar id="data-comissao" disabled valueCalendar={baseComissao.data} />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="regra-apuracao">Regra de apuração</label>
                    <InputText
                        id="regra-apuração"
                        disabled
                        value={baseComissao.apuracao_comissao?.regra_apuracao?.descricao || ""}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="cod-produto">Cód. produto</label>
                    <InputText id="cod-produto" disabled value={baseComissao.item_venda.sku.codigo} />
                </div>
                <div className="p-field p-col-12 p-md-7">
                    <label htmlFor="desc-produto">Descrição produto</label>
                    <InputText id="desc-produto" disabled value={baseComissao.item_venda.sku.descricao_derivada} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="vlr-comissao">Vlr. comissão</label>
                    <InputText id="vlr-comissao" disabled value={baseComissao.total_comissao} />
                </div>
            </div>
        </>
    );
};
