import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoDropdownCompetenciaEstoque } from "@/components/MakoInputs/MakoDropdownCompetenciaEstoque";
import { Dropdown } from "@/components/Dropdown";
import { MOTIVOS_INVENTARIO_ESTOQUE } from "@/assets/constants/constants";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import useHttp from "@/hooks/useHttp";
import * as Yup from "yup";

export const LoteInventarioComponente = ({ exibeCabecalho, exibeRodape, loteInventario }) => {
    const [centrosEstocagem, setCentrosEstocagem] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPut } = useHttp();
    const history = useHistory();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            id: 0,
            motivo_inventario: null,
            data_hora: null,
            finalizado: false,
            centro_estocagem: null,
            competencia_estoque: null,
            categoria: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                motivo_inventario: Yup.number()
                    .required("O campo 'motivo de inventário' é obrigatório.")
                    .typeError("Selecione um motivo de inventário."),
                data_hora: Yup.date()
                    .typeError("Informe uma data válida.")
                    .required("O campo 'data / hora' é obrigatório"),
                centro_estocagem: Yup.number()
                    .required("O campo 'centro de estocagem' é obrigatório.")
                    .typeError("Selecione um centro de estocagem."),
                competencia_estoque: Yup.number()
                    .required("O campo 'competência de estoque' é obrigatório.")
                    .typeError("Selecione um competência de estoque."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const { estoque_empresa } = centrosEstocagem.find((ce) => ce.id === values.centro_estocagem);

            const loteInventario = {
                ...values,
                estoque_empresa: estoque_empresa.id,
            };
            if (values.id === 0) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Lote de inventário cadastrado com sucesso.",
                            life: 1500,
                        });
                        history.push("/estoque/inventario/lotes-inventario");
                    },
                };

                showLoading();
                await httpPost({ url: "/produtos/lotes-inventario/", body: loteInventario }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Lote de inventário alterado com sucesso.",
                            life: 1500,
                        });
                        history.push("/estoque/inventario/lotes-inventario");
                    },
                };

                showLoading();
                await httpPut({ url: `/produtos/lotes-inventario/${values.id}/`, body: loteInventario }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    useEffect(() => {
        if (loteInventario) {
            setValues({
                ...loteInventario,
                centro_estocagem: loteInventario.centro_estocagem.id,
                competencia_estoque: loteInventario.competencia_estoque.id,
                motivo_inventario: loteInventario.motivo_inventario.id,
                categoria: loteInventario.categoria?.id,
            });
        }
    }, [loteInventario, setValues]);

    return (
        <>
            {exibeCabecalho && (
                <h3>
                    {formik.values.id === 0
                        ? "Novo lote de inventário físico"
                        : "Manutenção de lote de inventário físico"}
                </h3>
            )}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="numero">Lote</label>
                        <div className="p-inputgroup">
                            <InputText id="numero" name="numero" disabled value={formik.values.id} />
                            <Button
                                label={formik.values.finalizado ? "F" : "P"}
                                type="button"
                                className={formik.values.finalizado ? "p-button-success" : "p-button-warning"}
                                tooltip={!formik.values.finalizado ? "Lote Finalizado" : "Lote Pendente"}
                                tooltipOptions={{ position: "top" }}
                            />
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-md-10">
                        <label htmlFor="motivo">Motivo do inventário *</label>
                        <Dropdown
                            id="motivo"
                            name="motivo_inventario"
                            options={MOTIVOS_INVENTARIO_ESTOQUE}
                            value={formik.values.motivo_inventario}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.motivo_inventario })}
                        />
                        {formik.errors.motivo_inventario && (
                            <small className="p-error">{formik.errors.motivo_inventario}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="centro-estocagem">Centro de estocagem *</label>
                        <Dropdown
                            id="centro-estocagem"
                            name="centro_estocagem"
                            url="/produtos/centros-estocagem?query={id,estoque_empresa,nome,data_saldo_inicial}&limit=100"
                            setObjects={setCentrosEstocagem}
                            optionLabel="nome"
                            optionValue="id"
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="competencia-estoque">Competência de estoque *</label>
                        <MakoDropdownCompetenciaEstoque
                            id="competencia-estoque"
                            name="competencia_estoque"
                            value={formik.values.competencia_estoque}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia_estoque })}
                        />
                        {formik.errors.competencia_estoque && (
                            <small className="p-error">{formik.errors.competencia_estoque}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data-hora">Data / hora *</label>
                        <MakoCalendarTime
                            id="data-hora"
                            name="data_hora"
                            valueCalendar={formik.values.data_hora}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_hora })}
                        />
                        {formik.errors.data_hora && <small className="p-error">{formik.errors.data_hora}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="categoria">Categoria</label>
                        <MakoDropdownCategoriasHierarquicas
                            id="categoria"
                            name="categoria"
                            categoriaTituloSelecionavel
                            showClear
                            value={formik.values.categoria}
                            onChange={(e) => formik.setFieldValue("categoria", e.value)}
                        />
                    </div>
                </div>
                {exibeRodape && (
                    <>
                        <CamposObrigatorios />
                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" icon="pi pi-save" className="p-button-info p-mr-2" />
                            <Button
                                label="Voltar"
                                type="button"
                                icon="pi pi-angle-double-left"
                                className="p-button-danger p-mr-2"
                                onClick={() => history.push("/estoque/inventario/lotes-inventario")}
                            />
                        </div>
                    </>
                )}
            </form>
        </>
    );
};
