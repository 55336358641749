import React, { memo, useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { useFormik } from "formik";
import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";

import usePessoa from "@/hooks/usePessoa";
import * as Yup from "yup";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { Dialog } from "primereact/dialog";
import { PessoasTagsPerfisForm } from "../../Cadastros/TagsPerfis/form";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import { Label } from "@/components/Label";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useHttp from "@/hooks/useHttp";

const TagForm = () => {
    const [listaTags, setListaTags] = useState([]);
    const [exibirCadastro, setExibirCadastro] = useState(false);
    const { tags, handleTag } = usePessoa();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpGet } = useHttp();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            tag: null,
            status: "novo",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tag: Yup.object().typeError("O campo 'tag' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (values.status === OP_CRUD_DJANGO.novo && tags?.findIndex((t) => t.tag.id === values.tag.id) === -1)
                handleTag(values, OP_CRUD_DJANGO.novo);
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const listarTags = useCallback(async () => {
        const handlers = {
            200: ({ data }) => setListaTags(data.results),
        };
        showLoading();
        await httpGet({ url: "/pessoas/tags-perfis?tag_crediario=false&limit=1000&ordering=descricao" }, handlers);
        hideLoading();
    }, [showLoading, hideLoading, httpGet]);

    useEffect(() => {
        const controller = new AbortController();
        listarTags(controller.signal);
        return () => {
            controller.abort();
        };
    }, [listarTags]);

    const escondeCadastro = (reloadTags = false) => {
        setExibirCadastro(false);
        if (reloadTags) listarTags();
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="tag" label="Tag" obrigatorio />
                        <div className="p-inputgroup">
                            <Dropdown
                                id="tag"
                                name="tag"
                                options={listaTags}
                                loading={loading}
                                optionLabel="descricao"
                                filter
                                filterBy="descricao"
                                value={formik.values.tag}
                                onChange={formik.handleChange}
                                placeholder="Selecione uma tag"
                                className={classNames({ "p-invalid": formik.errors.tag })}
                            />
                            <MakoControleAcesso
                                permissao={[permissoes.GESTAO_CADASTRO_TAGSPERFIL_INCLUIR]}
                                componente={Button}
                                type="button"
                                icon={MAKO_ICONS.NOVO}
                                className="p-button-success"
                                tooltip="Cadastrar nova tag"
                                onClick={() => setExibirCadastro(true)}
                            />
                        </div>

                        {formik.errors.tag && <small className="p-error">{formik.errors.tag}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button type="submit" icon={MAKO_ICONS.GRAVAR} label="Gravar" className="p-mb-2" />
                </MakoActionsButtons>
            </form>
            <div className="p-fluid p-formgrid p-grid p-justify-center">
                {tags
                    .sort((a, b) => a.tag.descricao - b.tag.descricao)
                    .map((tag, index) =>
                        tag._status !== "deletar" ? (
                            <SplitButton
                                key={index}
                                label={tag.tag.descricao}
                                model={[
                                    {
                                        label: "Deletar",
                                        icon: "pi pi-times",
                                        command: () => {
                                            handleTag(tag, OP_CRUD_DJANGO.deletar);
                                            showSuccess({
                                                summary: "Sucesso!",
                                                detail: "Tag adicionada a exclusão com sucesso.",
                                                life: 1500,
                                            });
                                        },
                                    },
                                ]}
                                className="p-button-warning p-col-12 p-md-2 p-mr-2 p-mb-2"
                            />
                        ) : null
                    )}
                <Dialog
                    header={"Cadastrar Tag"}
                    visible={exibirCadastro}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "30vw" }}
                    onHide={() => escondeCadastro()}
                >
                    <PessoasTagsPerfisForm modal esconderDialog={escondeCadastro} />
                </Dialog>
            </div>
        </>
    );
};

export default memo(TagForm);
