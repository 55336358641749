import React, { useMemo } from "react";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputPerfilHierarquia = ({
    disabled,
    somenteAtivo = true,
    cargo,
    mostrarDocumento = false,
    empresa,
    ...props
}) => {
    const [formatarDocumento] = useFormatCNPJCPF();

    const autoCompleteFornecedorTemplate = (item) => {
        if (mostrarDocumento)
            return (
                <div>{`${item.nome} - ${formatarDocumento(item.identificacao)} | ${item.cargo.nome} (Nv ${
                    item.nivel
                })`}</div>
            );
        return <div>{`${item.nome} | ${item.cargo.nome} (Nv ${item.nivel})`}</div>;
    };

    const url = useMemo(() => {
        const filtroAtivo = somenteAtivo ? "&ativo=true" : "";
        const filtroCargo = cargo ? `&cargo=${cargo}` : "";
        const filtroEmpresa = empresa ? `&perfil=${empresa}` : "";
        return `/pessoas/hierarquia-funcional/?${filtroAtivo}${filtroCargo}${filtroEmpresa}&pessoa__nome__icontains=`;
    }, [somenteAtivo, cargo, empresa]);

    const aposPesquisar = (data) => {
        return data.map((p) => {
            return {
                ...p.pessoa,
                cargo: p.cargo,
                nivel: p.nivel,
            };
        });
    };

    return (
        <>
            <MakoAutoComplete
                minCaracteresBusca={2}
                itemTemplate={autoCompleteFornecedorTemplate}
                field="nome"
                aposPesquisar={aposPesquisar}
                urlSearch={url}
                placeholder="Comece a digitar o nome para começar a buscar... (min 2 caracteres)"
                {...props}
            />
        </>
    );
};
