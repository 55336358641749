import React, { useRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";

export const MakoAjudaOverlay = ({
    children,
    showCloseIcon = true,
    buttonProps = {},
    icon = "pi pi-question-circle",
}) => {
    const overlayRef = useRef(null);

    return (
        <>
            <button type="button" className="p-link" onClick={(e) => overlayRef.current.toggle(e)} {...buttonProps}>
                <i className={icon} style={{ fontSize: "inherit" }} />
            </button>
            <OverlayPanel ref={overlayRef} showCloseIcon={showCloseIcon}>
                {children}
            </OverlayPanel>
        </>
    );
};
