import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";

import { Accordion, AccordionTab } from "primereact/accordion";
import { Item } from "./item";

const { Content, Header } = Item;

const MemoHeader = Header;
const MemoContent = Content;

const Component = ({ itens = [] }, ref) => {
    const [activeIndex, setActiveIndex] = useState();

    const tabs = useMemo(() => {
        return React.Children.toArray(
            itens.map((item) => (
                <AccordionTab header={<MemoHeader item={item} />}>
                    <MemoContent item={item} />
                </AccordionTab>
            ))
        );
    }, [itens]);

    const getActiveIndexes = useCallback(() => {
        return activeIndex;
    }, [activeIndex]);

    useImperativeHandle(ref, () => ({ getActiveIndexes }), [getActiveIndexes]);

    return (
        <>
            <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {tabs}
            </Accordion>
        </>
    );
};

export const Entregas = forwardRef(Component);
