import React, { useCallback, useMemo, useRef, useState } from "react";

import { PageBase } from "@/components/PageBase";

import { EntregasRealizadas as Listagem } from "./listagens";
import { FiltroAvancado, Visualizar } from "./features";
import { Buttons } from "./buttons";

import useClearRefs from "@/hooks/useClearRefs";
import { MakoDropdownCargas } from "@/components/MakoInputs";

const { Button: ButtonFiltro } = FiltroAvancado;
const { Button: ButtonVisualizar } = Visualizar;
const { Vincular: ButtonVincular, Cancelar: ButtonCancelar } = Buttons;

const MemoButtonFiltro = ButtonFiltro;
const MemoButtonVisualizar = ButtonVisualizar;
const MemoButtonVincular = ButtonVincular;
const MemoButtonCancelar = ButtonCancelar;
const MemoMakoDropdownCargas = MakoDropdownCargas;

export const EntregasRealizadasPage = () => {
    const [carga, setCarga] = useState(null);

    const listagemRef = useRef(null);
    const tabelaRef = useRef(null);

    useClearRefs(tabelaRef, listagemRef);

    const onFilter = useCallback((values) => {
        tabelaRef.current?.filter(values);
    }, []);

    const onSuccessCarga = useCallback((e) => {
        setCarga(e);
        listagemRef.current?.buscarDados();
    }, []);

    const onSuccessVinculo = useCallback((e) => {
        listagemRef.current?.buscarDados();
    }, []);

    const onSuccessCancelarEntrega = useCallback((e) => {
        listagemRef.current?.buscarDados();
    }, []);

    const painelEsquerdo = useMemo(() => {
        return (
            <>
                <MemoButtonFiltro onFilter={onFilter} />
            </>
        );
    }, [onFilter]);

    const painelDireito = useMemo(() => {
        return (
            <>
                <MemoMakoDropdownCargas
                    value={carga}
                    onChange={(e) => setCarga(e.value)}
                    showAdd
                    showEdit
                    onSuccess={onSuccessCarga}
                    style={{ minWidth: "50%" }}
                />
            </>
        );
    }, [carga, onSuccessCarga]);

    const actions = useCallback(
        (rowData) => {
            return (
                <>
                    <MemoButtonVincular
                        carga={carga}
                        previsao={rowData}
                        successCallback={onSuccessVinculo}
                        className="p-mr-2"
                    />
                    <MemoButtonVisualizar previsaoId={rowData?.id} className="p-mr-2" />
                    <MemoButtonCancelar previsaoId={rowData?.id} successCallback={onSuccessCancelarEntrega} />
                </>
            );
        },
        [carga, onSuccessVinculo, onSuccessCancelarEntrega]
    );

    return (
        <PageBase>
            <Listagem
                ref={tabelaRef}
                listagemRef={listagemRef}
                actions={actions}
                listagemProps={{ painelEsquerdo, painelDireito }}
            />
        </PageBase>
    );
};
