import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { ItemRequisicao } from "./ItemRequisicao";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { gerarId, parseMoeda } from "@/assets/util/util";
import useRequisicao from "@/hooks/useRequisicao";
import useToast from "@/hooks/useToast";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";

export const FormItens = ({ ableButtonStep }) => {
    const [items, setItems] = useState([]);
    const { requisicao, isEditing, updateRequisicao } = useRequisicao();
    const { showWarning } = useToast();

    const pStyle = { marginBottom: 0 };

    const { setValues, setFieldValue, handleChange, resetForm, ...formik } = useFormik({
        initialValues: {
            id: null,
            fakeId: null,
            sku: null,
            quantidade: 1,
            unidade_medida: null,
            custo_unitario: 0,
            custo_total: 0,
            data_prevista: null,
            descricao: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'sku' é obrigatório"),
                    })
                    .nullable()
                    .typeError("Informe um 'sku' válida"),
                quantidade: Yup.number()
                    .typeError("Informe uma 'quantidade' válida")
                    .required("O campo 'quantidade' é obrigatório"),
                unidade_medida: Yup.number()
                    .typeError("Informe uma 'unidade de medida' válida")
                    .required("O campo 'unidade de medida' é obrigatório"),
                custo_unitario: Yup.number()
                    .typeError("Informe um 'custo unitário' válido")
                    .required("O campo 'custo unitário' é obrigatório"),
                custo_total: Yup.number()
                    .typeError("Informe um 'custo total' válido")
                    .required("O campo 'custo total' é obrigatório"),
                data_prevista: Yup.date()
                    .typeError("Informe uma 'data prevista' válida")
                    .required("O campo 'data prevista' é obrigatório"),
                descricao: Yup.string()
                    .required("O campo 'descricao' é obrigatório caso 'sku' não for informado")
                    .when("sku", {
                        is: (val) => val === null,
                        then: Yup.string().max(255, "Informe no máximo 255 caracteres"),
                    }),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!isEditing) {
                if (values.fakeId == null) {
                    if (items.find((item) => item.sku.id === values.sku.id))
                        throw new Error("Um item com este Sku ja foi informado");
                    const data = {
                        ...values,
                        tag: "new",
                        fakeId: gerarId(),
                    };

                    updateRequisicao({
                        ...requisicao,
                        itemrequisicao: [...items, data],
                    });

                    setItems((prev) => [...prev, data]);
                } else {
                    const data = items.map((item) => {
                        if (item.fakeId === values.fakeId) return (item = { ...values });
                        return item;
                    });

                    updateRequisicao({
                        ...requisicao,
                        itemrequisicao: data,
                    });

                    setItems((prev) => [...data]);
                }
            } else {
                if (values.id === null && values.fakeId === null) {
                    if (items.find((item) => item.sku.id === values.sku.id))
                        throw new Error("Um item com este Sku ja foi informado");

                    const data = {
                        ...values,
                        tag: "new",
                        fakeId: gerarId(),
                    };

                    updateRequisicao({
                        ...requisicao,
                        itemrequisicao: [...items, data],
                        itemrequisicao_set: [...items, data],
                    });

                    setItems((prev) => [...prev, data]);
                } else {
                    const key = values.id !== null ? "id" : "fakeId";
                    const newItems = items.map((item) => {
                        if (item[key] === values[key]) return (item = { ...values, tag: "update" });
                        return item;
                    });
                    updateRequisicao({
                        ...requisicao,
                        itemrequisicao: newItems,
                        itemrequisicao_set: newItems,
                    });
                }
            }

            resetForm();
            ableButtonStep(true);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showWarning({
                    summary: "Erro :(",
                    detail: error.message,
                    life: 3000,
                });
            }
            ableButtonStep(false);
        }
    }

    const itemTemplateUnidade = (item) => {
        if (item) return `${item.nome}|(${item.sigla})`;
    };

    const handleSomatorio = (items) => {
        return parseMoeda(
            items.reduce((total, item) => {
                if (item.tag !== null && item.tag !== "delete") return (total += item.custo_total);
                return total;
            }, 0)
        );
    };

    const handleReset = useCallback(() => {
        resetForm();
        ableButtonStep(false);
        setItems([]);
        if (requisicao) {
            updateRequisicao({
                ...requisicao,
                itemrequisicao_set: [],
            });
        }
    }, [requisicao, resetForm, ableButtonStep, updateRequisicao]);

    const handleRemove = useCallback(
        (item) => {
            let newList = items;

            if (!isEditing) {
                newList = items.filter((value) => value.fakeId !== item.fakeId);
                updateRequisicao({ ...requisicao, itemrequisicao: newList });
            } else {
                const key = item.id === null ? "fakeId" : "id";

                let itemRemoved = newList.find((value) => value[key] === item[key]);
                if (key === "id") {
                    updateRequisicao({
                        ...requisicao,
                        itemrequisicao: newList.filter((value) => value[key] !== itemRemoved[key]),
                        itemrequisicao_set: newList.map((value) => {
                            if (value[key] === itemRemoved[key]) return (value = { ...itemRemoved, tag: "delete" });
                            return value;
                        }),
                    });
                } else {
                    newList = newList.filter((value) => value[key] !== itemRemoved[key]);

                    updateRequisicao({
                        ...requisicao,
                        itemrequisicao: newList,
                        itemrequisicao_set: newList,
                    });
                }
            }
        },
        [items, requisicao, isEditing, updateRequisicao]
    );

    const handleSumCustoTotal = useCallback(
        (e) => {
            setFieldValue("custo_total", e.target.value * formik.values.quantidade);
            handleChange(e);
        },
        [setFieldValue, handleChange, formik.values.quantidade]
    );

    const handleSumQuantidade = useCallback(
        (e) => {
            setFieldValue("custo_total", e.target.value * formik.values.custo_unitario);
            handleChange(e);
        },
        [setFieldValue, handleChange, formik.values.custo_unitario]
    );

    useEffect(() => {
        if (requisicao) {
            setItems(() => {
                if (isEditing) {
                    return requisicao.itemrequisicao_set != null ? requisicao.itemrequisicao_set : [];
                }
                return requisicao.itemrequisicao != null ? requisicao.itemrequisicao : [];
            });
        }
    }, [requisicao, isEditing]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="sku">Produto: </label>
                        <MakoBuscaSkuPersonalizada
                            id="sku"
                            name="sku"
                            placeholder="Digite para pesquisar..."
                            skuValue={formik.values.sku}
                            skuChange={(e) => setFieldValue("sku", e)}
                            skuError={formik.errors.sku}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade">Quantidade *</label>
                        <InputNumber
                            id="quantidade"
                            name="quantidade"
                            value={formik.values.quantidade}
                            onValueChange={(e) => handleSumQuantidade(e)}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="unidade_medida">Unidade de medida *</label>
                        <Dropdown
                            id="unidade_medida"
                            name="unidade_medida"
                            url="/produtos/unidades-medida?limit=100"
                            itemTemplate={itemTemplateUnidade}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            placeholder="Selecione uma unidade de medida..."
                            value={formik.values.unidade_medida}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.unidade_medida })}
                        />
                        {formik.errors.unidade_medida && (
                            <small className="p-error">{formik.errors.unidade_medida}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="custo_unitario">Custo unitário *</label>
                        <MakoInputMoeda
                            id="custo_unitario"
                            name="custo_unitario"
                            valueMoeda={formik.values.custo_unitario}
                            onChangeMoeda={(e) => handleSumCustoTotal(e)}
                            className={classNames({ "p-invalid": formik.errors.custo_unitario })}
                        />
                        {formik.errors.custo_unitario && (
                            <small className="p-error">{formik.errors.custo_unitario}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="custo_total">Custo total*</label>
                        <MakoInputMoeda
                            id="custo_total"
                            name="custo_total"
                            valueMoeda={formik.values.custo_total}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.custo_total })}
                        />
                        {formik.errors.custo_total && <small className="p-error">{formik.errors.custo_total}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_prevista">Data prevista*</label>
                        <MakoCalendar
                            id="data_prevista"
                            name="data_prevista"
                            valueCalendar={formik.values.data_prevista}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_prevista })}
                        />
                        {formik.errors.data_prevista && (
                            <small className="p-error">{formik.errors.data_prevista}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputTextarea
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoResize
                            rows={3}
                            maxLength={255}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button type="submit" icon="pi pi-check" label="Gravar" className="p-mr-2 p-mb-2" />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={formik.resetForm}
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Excluir todos"
                            className="p-button-danger p-mr-2 p-mb-2"
                            onClick={handleReset}
                        />
                    </div>
                </div>
            </form>
            <div className="card p-grid">
                <div className="p-grid p-col-12" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h5>Items selecionados:</h5>
                    <p style={pStyle}>
                        <b>Somatório total: </b>
                        {items.length > 0 && handleSomatorio(items)}
                    </p>
                </div>
                {items.map((item) => (
                    <React.Fragment key={item.fakeId}>
                        {!isEditing ? (
                            <ItemRequisicao
                                item={item}
                                onEdit={() => setValues({ ...item })}
                                onDelete={() => handleRemove(item)}
                            />
                        ) : (
                            <>
                                {item.tag === null ? (
                                    <ItemRequisicao
                                        item={item}
                                        onEdit={() => setValues({ ...item })}
                                        onDelete={() => handleRemove(item)}
                                    />
                                ) : (
                                    <>
                                        {item.tag !== "delete" && (
                                            <ItemRequisicao
                                                item={item}
                                                onEdit={() => setValues({ ...item })}
                                                onDelete={() => handleRemove(item)}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </>
    );
};
