import React, { useCallback, useRef, useState } from "react";

import { Button } from "primereact/button";

import { BotaoDelete } from "@/components/BotaoDelete";

import { Form, ListagemTaxa } from "../features";
import { PanelInfoConta } from "./panel-info-conta";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const TaxaBancaria = ({ conta }) => {
    const [taxa, setTaxa] = useState(null);
    const listagemRef = useRef();

    const onHandleSuccessTaxa = useCallback(() => {
        listagemRef.current?.buscarDados();
    }, []);

    const actionsTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    onClick={() => setTaxa({ ...rowData, bandeira: rowData.bandeira.id })}
                />
                <BotaoDelete
                    url="/financeiro/taxas-administracao-cartao/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao="Deseja excluir a taxa?"
                    msgToastErroExclusao="A taxa não pode ser excluída."
                    onDelete={onHandleSuccessTaxa}
                    tooltip="Excluir taxa"
                    tooltipOptions={{ position: "bottom" }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    return (
        <>
            <PanelInfoConta conta={conta} />
            <Form.TaxaBancaria conta={conta} taxa={taxa} onSuccess={onHandleSuccessTaxa} />
            <ListagemTaxa
                listagemRef={listagemRef}
                filtros={{ conta_bancaria: conta?.id }}
                buscar={!!conta}
                onSuccess={onHandleSuccessTaxa}
                actionTemplate={actionsTemplate}
            />
        </>
    );
};
