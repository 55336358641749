export const TIPO_CENTRO_ESTOCAGEM = [
    { value: "N", label: "Normal" },
    { value: "E", label: "Exposição" },
    { value: "R", label: "Reserva" },
    { value: "D", label: "Defeito" },
    { value: "C", label: "Consolidado" },
    { value: "T", label: "Terceiro" },
];

export const TIPO_SKU_ESTOCADO = [
    { value: "M", label: "Mercadoria" },
    { value: "I", label: "Insumo" },
    { value: "C", label: "Consumo" },
    { value: "A", label: "Ativo" },
    { value: "P", label: "Produto acabado" },
];

export const TIPO_REQUISICAO_TRANSF = [
    { value: "V", label: "Venda" },
    { value: "C", label: "Campanha" },
    { value: "E", label: "Reposição estoque" },
    { value: "A", label: "Reposição automática" },
];

export const TIPO_LOTE_SKU = [
    { value: "-", label: "Sem lote ou nº de série" },
    { value: "L", label: "Lote sem vencimento" },
    { value: "V", label: "Lote com vencimento" },
    { value: "S", label: "Número de série" },
];
