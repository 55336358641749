import React from "react";

import { MakoAjudaOverlay } from "@/components/MakoAjudaOverlay";
import { Tag } from "primereact/tag";
import { COLORS_VALIDADE } from "@/assets/constants/constants_styles";

export const ValidadeOverlay = ({ color = "black" }) => {
    return (
        <MakoAjudaOverlay
            buttonProps={{
                style: { color, fontSize: "1.5rem", display: "inline-flex", alignItem: "center", textAlign: "center" },
                className: "p-link p-px-2 p-mr-2",
            }}
            icon="pi pi-palette"
            showCloseIcon={false}
        >
            <div>
                <h6>
                    <b>Legenda de cores:</b>
                </h6>
                {Object.keys(COLORS_VALIDADE)
                    .map((k) => (
                        <Tag
                            value={k !== "CONCLUIDA" ? k : "PAGO"}
                            className="p-mr-2"
                            style={{
                                backgroundColor: "white",
                                color: COLORS_VALIDADE[k],
                                width: "min-content",
                                borderColor: COLORS_VALIDADE[k],
                                borderWidth: "1px",
                                borderStyle: "solid",
                            }}
                        />
                    ))
                    .reverse()}
            </div>
        </MakoAjudaOverlay>
    );
};
