import React from "react";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import useToast from "@/hooks/useToast";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import { Label } from "@/components/Label";

const url = "/financeiro/versoes-planos-contas-financeiras/";
const urlvoltar = "/financeiro/versao-plano-contas";

export const FinVersaoPlanoContasForm = () => {
    const { state } = useLocation();
    const { versaoContas } = state || {};
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();

    const formik = useFormik({
        initialValues: versaoContas || {
            codigo: "",
            descricao: "",
            inicio_vigencia: new Date(),
            final_vigencia: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                inicio_vigencia: Yup.date().required("O campo 'vigência inicial' é obrigatório."),
                final_vigencia: Yup.date().when("inicio_vigencia", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            Yup.ref("inicio_vigencia") || new Date(),
                            "O campo 'vigência final' não pode ser anterior a inicial"
                        )
                        .nullable()
                        .typeError("Informe uma 'vigência final' válida"),
                }),
            });
            await formSchema.validate(values, { abortEarly: false });
            values.inicio_vigencia = dataToStr(values.inicio_vigencia, "yyyy-MM-dd");
            values.final_vigencia = dataToStr(values.final_vigencia, "yyyy-MM-dd");
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Versão do plano de contas cadastrado com sucesso!",
                            life: 1500,
                        });
                        cancelar();
                    },
                };
                showLoading();
                await httpPost({ url, body: values }, handlers);
                hideLoading();
            } else {
                delete values.empresas;
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Versão do plano de contas alterado com sucesso!",
                            life: 1500,
                        });
                        cancelar();
                    },
                };
                showLoading();
                await httpPut({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova versão do plano de contas" : "Manutenção de versão do plano de contas"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            autoComplete="off"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="inicio_vigencia" label="Vigência Inicial" obrigatorio />
                        <MakoCalendar
                            id="inicio_vigencia"
                            name="inicio_vigencia"
                            valueCalendar={formik.values.inicio_vigencia}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.inicio_vigencia,
                            })}
                        />
                        {formik.errors.inicio_vigencia && (
                            <small className="p-error">{formik.errors.inicio_vigencia}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="final_vigencia" label="Vigência Final" />
                        <MakoCalendar
                            id="final_vigencia"
                            name="final_vigencia"
                            valueCalendar={formik.values.final_vigencia}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.final_vigencia,
                            })}
                        />
                        {formik.errors.final_vigencia && (
                            <small className="p-error">{formik.errors.final_vigencia}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-1 ">
                        <h5>
                            <Label htmlFor="status" label="Status" />
                        </h5>
                        <InputSwitch
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            disabled={!formik.values.id}
                            value={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button type="submit" icon={MAKO_ICONS.GRAVAR} label="Gravar" loading={loading} />
                    <Button
                        type="button"
                        icon={MAKO_ICONS.VOLTAR}
                        label="Voltar"
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
