import React from "react";

import { Avatar } from "primereact/avatar";

import { formatarCasasDecimais } from "@/assets/util/util";

import { Skeleton } from "./skeleton";

const MakoCardProdutoInfo = ({ title, subtitle, column_1, column_2, items = [], loading = false }) => {
    return (
        <div className="card">
            <h4>{title}</h4>
            <p>{subtitle}</p>
            <ul className="widget-image-list">
                <li>
                    <span>{column_1}</span>
                    <span>{column_2}</span>
                </li>
                {!loading ? (
                    <>
                        {items.length === 0 && <span className="p-ml-3">Não foram encontrados produtos</span>}
                        {items.map((item) => (
                            <li key={`${item.column_1}-${item.column_2}`}>
                                <span>
                                    <Avatar
                                        image={item.src}
                                        alt={item.alt}
                                        size="xlarge"
                                        className="widget-item-image"
                                        icon="pi pi-box"
                                    />
                                    <span>{item.column_1}</span>
                                </span>
                                <span className="listitem-value">{formatarCasasDecimais(item.column_2, 2)}</span>
                            </li>
                        ))}
                    </>
                ) : (
                    <Skeleton />
                )}
            </ul>
        </div>
    );
};

export default MakoCardProdutoInfo;
