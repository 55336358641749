import React, { memo, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileFormModal } from "./modalFormFile";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";

const TabPessoaEstrangeira = () => {
    const [exibirSelecao, setExibirSelecao] = useState(false);
    const { showLoading, hideLoading } = useLoading();

    const { setValues, ...formik } = useFormik({
        initialValues: {},
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({});

            await formSchema.validate(values, {
                abortEarly: false,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    function resetForm() {
        formik.resetForm();
    }

    // const verificarCnpjExiste = async (value) => {
    //     const cnpj = limparDocumento(value);

    //     setLoading(true);
    //     const json = await axiosGet(`/pessoas/perfis/?query={nome,identificacao,ativo}&identificacao=${cnpj}`);
    //     setLoading(false);

    //     if (json.status === 200) {
    //         if (json.data.results.length > 0) {
    //             if (!json.data.results[0].ativo) {
    //                 toastRef.current.show({
    //                     severity: "warn",
    //                     life: 5000,
    //                     summary: "Aviso!",
    //                     detail: "Esse CNPJ já se encontra cadastrado na base de dados, porém está desativado.",
    //                 });
    //             } else {
    //                 toastRef.current.show({
    //                     severity: "warn",
    //                     life: 5000,
    //                     summary: "Aviso!",
    //                     detail: "Esse CNPJ já se encontra cadastrado na base de dados.",
    //                 });
    //             }

    //             // setCnpjDuplicado(true);
    //             // setSubmit(false);
    //         } else {
    //             // setCnpjDuplicado(false);
    //         }
    //     }
    // };

    // const validarIE = (indIeId) => {
    //     formik.setFieldValue("indicador_inscricao_estadual", indIeId);

    //     const index = indicadoresIE?.findIndex((e) => e.id === indIeId);

    //     if (indicadoresIE[index].codigo === "1") {
    //         setComplIeExigido(true);
    //         formik.setFieldValue("estado", "");
    //         formik.setFieldValue("inscricao_estadual", "");
    //     } else if (indicadoresIE[index].codigo === "2") {
    //         setComplIeExigido(false);
    //         formik.setFieldValue("estado", "");
    //         formik.setFieldValue("inscricao_estadual", "");
    //     } else if (indicadoresIE[index].codigo === "3") {
    //         setComplIeExigido(false);
    //         formik.setFieldValue("inscricao_estadual", "ISENTO");
    //     }
    // };

    const OPCOES_COLUNA = [
        { value: "codigo_externo", label: "ID" },
        { value: "passaporte", label: "Identificação" },
        { value: "nome", label: "Nome curto" },
        { value: "estado_civil", label: "Estado Civil" },
        { value: "sexo", label: "Sexo" },
        { value: "data_nascimento", label: "Data nascimento" },
        { value: "nacionalidade_id", label: "Nacionalidade" },
        { value: "nome_mae", label: "Nome mae" },
        { value: "nome_pai", label: "Nome pai" },
    ];

    return (
        <div>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_PESSOAS_IMPORTARPESSOAS_IMPORTAR]}
                componente={Button}
                icon="pi pi-upload"
                className="p-button-info p-mr-2"
                label="Carregar arquivo"
                onClick={() => setExibirSelecao(true)}
            />
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_PESSOAS_IMPORTARPESSOAS_CONSULTAR]}
                componente={Button}
                icon="pi pi-info-circle"
                className="p-button-help"
                label="Importações pendentes"
                // onClick={() => setExibirPendencias(!exibirPendencias)}
            />
            <Dialog
                header="Arquivo para importação"
                visible={exibirSelecao}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "40vw", display: "block" }}
                onHide={() => setExibirSelecao(false)}
            >
                <FileFormModal OPCOES_COLUNA={OPCOES_COLUNA} />
            </Dialog>
        </div>
    );
};

export default memo(TabPessoaEstrangeira);
