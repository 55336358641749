import React, { useMemo } from "react";
import { PageBase } from "../../PageBase";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const STATUS = {
    DESENVOLVIMENTO: "DEV",
    VALIDACAO: "VAL",
};

const STATUS_MESSAGES = {
    DEV: "Este relatório está em desenvolvimento",
    VAL: "Este relatório está em processo de validação",
};

const gerarMensagemStatus = (status) => {
    return (
        <div className="p-error p-mb-2 p-d-flex p-ai-center" style={{ paddingBottom: "5px" }}>
            <i className={`pi ${MAKO_ICONS.ALERTA} p-mr-1`} />
            <span>{STATUS_MESSAGES[status]}</span>
            <i className={`pi ${MAKO_ICONS.ALERTA} p-ml-1`} />
        </div>
    );
};
export const Wrapper = ({ titulo, children, pageBase = true, status }) => {
    const mensagem_status = useMemo(() => {
        return gerarMensagemStatus(status);
    }, [status]);

    const base = (
        <>
            {titulo && <h5>Relatório {titulo}</h5>}
            {status && mensagem_status}
            {children}
        </>
    );

    return <>{pageBase ? <PageBase>{base}</PageBase> : base}</>;
};
