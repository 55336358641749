import React, { useCallback, useEffect, useState } from "react";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Label } from "@/components/Label";

const BASE_TOTAIS = {
    original: 0,
    descontos: 0,
    multa: 0,
    juros: 0,
    acrescimos: 0,
    recebido: 0,
};

export const Totais = ({ parcelas = [] }) => {
    const [totais, setTotais] = useState(BASE_TOTAIS);

    const calcularTotais = useCallback(() => {
        let _original = 0,
            _descontos = 0,
            _juros = 0,
            _multa = 0,
            _recebido = 0;

        parcelas.forEach((parcela) => {
            _original += parcela.valor;
            _descontos += parcela.descontos;
            _multa += parcela.multa;
            _juros += parcela.juros;
            _recebido += parcela.recebido;
        });

        setTotais({
            original: _original,
            descontos: _descontos,
            multa: _multa,
            juros: _juros,
            acrescimos: _multa + _juros,
            recebido: _recebido,
        });
    }, [parcelas]);

    useEffect(() => {
        calcularTotais();
    }, [calcularTotais]);

    return (
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-2">
                <Label htmlFor="original" label="Total valor original" />
                <MakoInputMoeda id="original" disabled valueMoeda={totais.original} />
            </div>
            <div className="p-field p-col-12 p-md-2">
                <Label htmlFor="" label="Total dos descontos" />
                <MakoInputMoeda id="total-descontos" disabled valueMoeda={totais.descontos} />
            </div>
            <div className="p-field p-col-12 p-md-2">
                <Label htmlFor="total-multa" label="Total de multa" />
                <MakoInputMoeda id="total-multa" disabled valueMoeda={totais.multa} />
            </div>
            <div className="p-field p-col-12 p-md-2">
                <Label htmlFor="total-juros" label="Total de juros" />
                <MakoInputMoeda id="total-juros" disabled valueMoeda={totais.juros} />
            </div>
            <div className="p-field p-col-12 p-md-2">
                <Label htmlFor="total-acrescimos" label="Total dos acréscimos" />
                <MakoInputMoeda id="total-acrescimos" disabled valueMoeda={totais.acrescimos} />
            </div>
            <div className="p-field p-col-12 p-md-2">
                <Label htmlFor="total-recebimento" label="Total do recebimento" />
                <MakoInputMoeda id="total-recebimento" disabled valueMoeda={totais.recebido} />
            </div>
        </div>
    );
};
