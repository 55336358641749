import React, { useCallback, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

import { Dropdown } from "@/components/Dropdown";
import { MakoButton as Button } from "@/components/MakoButton";

import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { Label } from "@/components/Label";
import useHttp from "@/hooks/useHttp";

export const CaixaForm = () => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showError, showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();
    const { state } = useLocation();
    const { empresaSelecionadaId } = useEmpresa();

    const history = useHistory();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            empresa: empresaSelecionadaId,
            nome: "",
            conta_financeira: null,
            master: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string()
                    .required("O campo 'nome' é obrigatório.")
                    .max(20, "Esse campo não pode ser maior que 20 caracteres."),
                master: Yup.number().nullable().typeError("Informe um 'caixa master' válido."),
                conta_financeira: Yup.number()
                    .required("O campo 'conta financeira' é obrigatório.")
                    .typeError("Informe uma 'conta financeira' válida."),
            });
            await formSchema.validate(values, { abortEarly: false });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Caixa cadastrado com sucesso!",
                            life: 1500,
                        });
                        history.push("/financeiro/cadastros/caixas");
                    },
                    500: ({ err }) => {
                        if (err && err?.includes("duplicate key value violates unique constraint")) {
                            showError({
                                summary: "Erro :(",
                                detail: "Está conta financeira já possui um caixa associado.",
                                life: 3000,
                            });
                        } else {
                            showError({
                                summary: "Erro :(",
                                detail: "Verifique se está conta financeira está associada a um caixa.",
                                life: 3000,
                            });
                        }
                    },
                };
                showLoading();
                await httpPost({ url: "/financeiro/caixas/", body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Caixa alterado com sucesso!",
                            life: 1500,
                        });
                        history.push("/financeiro/cadastros/caixas");
                    },
                };
                showLoading();
                await httpPut({ url: `/financeiro/caixas/${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const aposBuscarCaixas = useCallback(async (data) => {
        return data.filter((cx) => cx.ativo && cx.master === null);
    }, []);

    const aposBuscarContas = useCallback(async (data) => {
        return data.map((c) => {
            c.label = `${c.codigo} - ${c.descricao}`;
            return c;
        });
    }, []);

    useEffect(() => {
        if (state && state.caixa) {
            setValues({
                id: state.caixa.id,
                nome: state.caixa.nome,
                conta_financeira: state.caixa.conta_financeira.id,
                master: state.caixa.master,
                ativo: state.caixa.ativo,
            });
        }
    }, [state, setValues]);

    return (
        <PageBase>
            <h5>{formik.values.id ? "Editar caixa" : "Cadastrar caixa"}</h5>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="nome" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            autoComplete="off"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="conta_financeira" label="Conta financeira" obrigatorio />
                        <Dropdown
                            id="conta_financeira"
                            name="conta_financeira"
                            url={`/financeiro/contas-financeiras/?ativa-True&tipo_conta=1`}
                            placeholder="Selecione"
                            optionValue="id"
                            optionLabel="label"
                            aposBuscar={aposBuscarContas}
                            value={formik.values.conta_financeira}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                        />
                        {formik.errors.conta_financeira && (
                            <small className="p-error">{formik.errors.conta_financeira}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="master" label="Caixa master" />
                        <Dropdown
                            id="master"
                            name="master"
                            url={`/financeiro/caixas/`}
                            aposBuscar={aposBuscarCaixas}
                            placeholder="Selecione"
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.master}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.master })}
                        />
                        {formik.errors.master && <small className="p-error">{formik.errors.master}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-2">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <Label htmlFor="ativo" label="Ativo?" />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        icon={MAKO_ICONS.LIMPAR_FORM}
                        label="Limpar"
                        type="reset"
                        className="p-button-warning"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        type="button"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        to={"/financeiro/cadastros/caixas"}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
