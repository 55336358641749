import React, { createContext, useCallback, useState } from "react";
import useLoading from "@/hooks/useLoading";
import { dataToStr } from "@/assets/util/datas";
import useHttp from "@/hooks/useHttp";

const TransferenciaContext = createContext({});

export const TransferenciaProvider = ({ children }) => {
    const [transferencia, setTransferencia] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { httpGet, httpPatch, httpPost } = useHttp();

    const handleDadosBasicos = useCallback(
        async (values, tipo, usuario) => {
            let status = 400,
                json = {};
            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        status = 201;
                        json = data;
                        setTransferencia({ ...data, ce_destino: data.ce_destino.id, ce_origem: data.ce_origem.id });
                    },
                };

                await httpPost(
                    {
                        url: "/transferencias/transferencia-mercadoria/",
                        body: {
                            ...values,
                            tipo_transferencia: tipo?.id,
                            usuario_gerou: usuario.id,
                            data_emissao: dataToStr(values.data_emissao, "yyyy-MM-dd"),
                            hora_emissao: dataToStr(values.hora_emissao, "HH:mm:ss") || null,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else {
                const { ce_destino, ce_origem, data_emissao, hora_emissao, carga, orientacoes } = values;
                const body = {
                    ce_destino,
                    ce_origem,
                    data_emissao,
                    hora_emissao,
                    carga,
                    orientacoes,
                };
                let diffProd = {};
                Object.entries(body).forEach(([k, v]) => {
                    if (v !== transferencia[k]) {
                        if (k === "data_emissao") diffProd[k] = dataToStr(v, "yyyy-MM-dd");
                        else if (k === "hora_emissao") diffProd[k] = dataToStr(v, "HH:mm:ss");
                        else diffProd[k] = v;
                    }
                });
                if (Object.keys(diffProd).length > 0) {
                    const handlers = {
                        200: ({ data }) => {
                            status = 200;
                            json = data;
                            setTransferencia({ ...data, ce_destino: data.ce_destino.id, ce_origem: data.ce_origem.id });
                        },
                    };

                    await httpPatch(
                        { url: `/transferencias/transferencia-mercadoria/${values.id}/`, body: diffProd },
                        handlers
                    );

                    return { status, data: json };
                }
                return { status: 200 };
            }
        },
        [transferencia, setTransferencia, httpPatch, httpPost]
    );

    const handleItens = useCallback(
        async (values) => {
            let status = 400,
                json = {};
            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        status = 201;
                        json = data;
                    },
                };

                await httpPost(
                    {
                        url: "/transferencias/itens-transferencia-mercadoria/",
                        body: {
                            ...values,
                            sku: values.sku.id,
                            transferencia: transferencia.id,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else {
                const handlers = {
                    200: ({ data }) => {
                        status = 200;
                        json = data;
                    },
                };

                await httpPatch(
                    {
                        url: `/transferencias/itens-transferencia-mercadoria/${values.id}/`,
                        body: {
                            ...values,
                            sku: values.sku.id,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            }
        },
        [transferencia?.id, httpPatch, httpPost]
    );

    const handlePreencherTransferencia = useCallback(
        async (idTransf) => {
            const handlers = {
                200: ({ data }) => {
                    const { itemtransferenciamercadoria_set, ce_destino, ce_origem, ...rest } = data;
                    setTransferencia({
                        ...rest,
                        ce_destino: ce_destino.id,
                        ce_origem: ce_origem.id,
                    });
                },
            };

            showLoading();
            await httpGet({ url: `/transferencias/transferencia-mercadoria/${idTransf}/` }, handlers);
            hideLoading();
        },
        [showLoading, hideLoading, httpGet]
    );

    return (
        <TransferenciaContext.Provider
            value={{
                transferencia,
                handleDadosBasicos,
                handleItens,
                handlePreencherTransferencia,
            }}
        >
            {children}
        </TransferenciaContext.Provider>
    );
};

export default TransferenciaContext;
