import React, { useCallback } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";

import { MakoDropdownCompetenciaFinanceira } from "@/components/MakoInputs/MakoDropdownCompetenciaFinanceira";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoButton } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";

import { NATUREZA_MOV_FINANCEIRA_CHOICE } from "@/assets/constants/financeiro";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";

export const INITIAL_VALUES = {
    data_emissao__gte: null,
    data_emissao__lte: null,
    data_lancamento__gte: null,
    data_lancamento__lte: null,
    vencimento__gte: null,
    vencimento__lte: null,
    data_recebimento__gte: null,
    data_recebimento__lte: null,
    quantidade_parcelas: null,
    numero_parcela: null,
    valor__gte: null,
    valor__lte: null,
    valor_recebido: null,
    forma_recebimento: null,
    competencia: null,
    conta_financeira: null,
    template_rateio: null,
    natureza: null,
    quitado: false,
    venda: null,
    devolucao: null,
    historico_padrao: null,
    documento__icontains: "",
    boleto__codigo_barras: "",
    trasacao_cartao__autorizacao: null,
    data_cancelamento__gte: null,
    data_cancelamento__lte: null,
};

export const Form = ({ url, filtros, empresaSelecionadaId, cancelCallback = () => {}, successCallback = () => {} }) => {
    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: filtros ? { ...INITIAL_VALUES, ...filtros } : INITIAL_VALUES,
        onSubmit: handleSubmit,
        enableReinitialize: false,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                competencia: Yup.number().nullable().typeError("Informe uma 'competência' válida"),
                conta_financeira: Yup.number().nullable().typeError("Informe uma 'conta financeira' válida"),
                forma_recebimento: Yup.number().nullable().typeError("Informe uma 'forma recebimento' válida"),
                historico_padrao: Yup.number().nullable().typeError("Informe um 'histórico' válido"),
                numero_parcela: Yup.number().nullable().typeError("Informe um 'numero parcela' válido"),
                quantidade_parcelas: Yup.number().nullable().typeError("Informe uma 'quantidade pacela' válida"),
                template_rateio: Yup.number().nullable().typeError("Informe um 'template rateio' válido"),
                valor__gte: Yup.number().nullable().typeError("Informe um 'valor mínimo' válido"),
                valor__lte: Yup.number().nullable().typeError("Informe um 'valor máximo' válido"),
                valor_recebido: Yup.number().nullable().typeError("Informe um 'valor recebido' válido"),
                data_emissao: Yup.date().nullable().typeError("Informe uma 'data emissão' válida"),
                data_lancamento: Yup.date().nullable().typeError("Informe uma 'data lançamento' válida"),
                data_recebimento: Yup.date().nullable().typeError("Informe uma 'data recebimento' válida"),
                vencimento: Yup.date().nullable().typeError("Informe um 'vencimento' válido"),
                documento__icontains: Yup.string().nullable().typeError("Informe um 'documento' válido"),
                boleto__codigo_barras: Yup.string().nullable().typeError("Informe um 'boleto' válido"),
                natureza: Yup.string().nullable().typeError("Informe uma 'natureza' válida"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const baseUrl = url;
            const params = [];
            let _filtros = {};
            Object.keys(values).forEach((key) => {
                if (values[key] !== null && values[key] !== "") {
                    if (
                        key === "data_emissao__lte" ||
                        key === "data_emissao__gte" ||
                        key === "data_lancamento__lte" ||
                        key === "data_lancamento__gte" ||
                        key === "data_recebimento__lte" ||
                        key === "data_recebimento__gte" ||
                        key === "data_cancelamento__lte" ||
                        key === "data_cancelamento__gte" ||
                        key === "vencimento__lte" ||
                        key === "vencimento__gte"
                    ) {
                        _filtros[key] = dataToStr(values[key], "yyyy-MM-dd");
                        return params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd")}`);
                    }
                    _filtros[key] = values[key];
                    return params.push(`${key}=${values[key]}`);
                }
            });

            if (params.length > 0) {
                const _url = baseUrl + "&" + params.join("&");
                successCallback(_url, totalizadorFiltrosAplicados(INITIAL_VALUES, values), _filtros);
            } else {
                successCallback(baseUrl, totalizadorFiltrosAplicados(INITIAL_VALUES, values), _filtros);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const aposBuscarConta = useCallback((data) => {
        return data.map((c) => {
            c.label = `${c.codigo} - ${c.descricao}`;
            return c;
        });
    }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor_recebido">Forma recebimento</label>
                    <Dropdown
                        id="forma-recebimento"
                        name="forma_recebimento"
                        url="/financeiro/formas-recebimentos/?limit=100"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.forma_recebimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.forma_recebimento })}
                    />
                    {formik.errors.forma_recebimento && (
                        <small className="p-error">{formik.errors.forma_recebimento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="natureza">Natureza</label>
                    <Dropdown
                        id="natureza"
                        name="natureza"
                        options={NATUREZA_MOV_FINANCEIRA_CHOICE}
                        optionValue="value"
                        optionLabel="label"
                        value={formik.values.natureza}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.natureza })}
                    />
                    {formik.errors.natureza && <small className="p-error">{formik.errors.natureza}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor__gte">Valor mínimo</label>
                    <MakoInputMoeda
                        inputId="valor__gte"
                        name="valor__gte"
                        valueMoeda={formik.values.valor__gte}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor__gte })}
                    />
                    {formik.errors.valor__gte && <small className="p-error">{formik.errors.valor__gte}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor__lte">Valor máximo</label>
                    <MakoInputMoeda
                        inputId="valor__lte"
                        name="valor__lte"
                        valueMoeda={formik.values.valor__lte}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor__lte })}
                    />
                    {formik.errors.valor__lte && <small className="p-error">{formik.errors.valor__lte}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="historico_padrao">Historico</label>
                    <Dropdown
                        id="historico_padrao"
                        name="historico_padrao"
                        url={`/financeiro/historico-padrao/?ativo=True&tipo=R&limit=1000`}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.historico_padrao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.historico_padrao })}
                    />
                    {formik.errors.historico_padrao && (
                        <small className="p-error">{formik.errors.historico_padrao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="data_emissao__gte">Data emissão (início)</label>
                    <MakoCalendar
                        id="data_emissao__gte"
                        name="data_emissao__gte"
                        valueCalendar={formik.values.data_emissao__gte}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_emissao__gte })}
                    />
                    {formik.errors.data_emissao__gte && (
                        <small className="p-error">{formik.errors.data_emissao__gte}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="data_emissao__lte">Data emissão (fim)</label>
                    <MakoCalendar
                        id="data_emissao__lte"
                        name="data_emissao__lte"
                        valueCalendar={formik.values.data_emissao__lte}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_emissao__lte })}
                    />
                    {formik.errors.data_emissao__lte && (
                        <small className="p-error">{formik.errors.data_emissao__lte}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="vencimento__gte">Data vencimento (início)</label>
                    <MakoCalendar
                        id="vencimento__gte"
                        name="vencimento__gte"
                        valueCalendar={formik.values.vencimento__gte}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.vencimento__gte })}
                    />
                    {formik.errors.vencimento__gte && (
                        <small className="p-error">{formik.errors.vencimento__gte}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="vencimento__lte">Data vencimento (fim)</label>
                    <MakoCalendar
                        id="vencimento__lte"
                        name="vencimento__lte"
                        valueCalendar={formik.values.vencimento__lte}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.vencimento__lte })}
                    />
                    {formik.errors.vencimento__lte && (
                        <small className="p-error">{formik.errors.vencimento__lte}</small>
                    )}
                </div>
            </div>
            <Panel header="Mais opções" collapsed toggleable>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_lancamento__gte">Data lançamento (início)</label>
                        <MakoCalendar
                            id="data_lancamento__gte"
                            name="data_lancamento__gte"
                            valueCalendar={formik.values.data_lancamento__gte}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_lancamento__gte })}
                        />
                        {formik.errors.data_lancamento__gte && (
                            <small className="p-error">{formik.errors.data_lancamento__gte}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_lancamento__lte">Data lançamento (fim)</label>
                        <MakoCalendar
                            id="data_lancamento__lte"
                            name="data_lancamento__lte"
                            valueCalendar={formik.values.data_lancamento__lte}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_lancamento__lte })}
                        />
                        {formik.errors.data_lancamento__lte && (
                            <small className="p-error">{formik.errors.data_lancamento__lte}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_cancelamento__gte">Data cancelamento (início)</label>
                        <MakoCalendar
                            id="data_cancelamento__gte"
                            name="data_cancelamento__gte"
                            valueCalendar={formik.values.data_cancelamento__gte}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_cancelamento__gte })}
                        />
                        {formik.errors.data_cancelamento__gte && (
                            <small className="p-error">{formik.errors.data_cancelamento__gte}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_cancelamento__lte">Data cancelamento (fim)</label>
                        <MakoCalendar
                            id="data_cancelamento__lte"
                            name="data_cancelamento__lte"
                            valueCalendar={formik.values.data_cancelamento__lte}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_cancelamento__lte })}
                        />
                        {formik.errors.data_cancelamento__lte && (
                            <small className="p-error">{formik.errors.data_cancelamento__lte}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_recebimento__gte">Data recebimento (início)</label>
                        <MakoCalendar
                            id="data_recebimento__gte"
                            name="data_recebimento__gte"
                            valueCalendar={formik.values.data_recebimento__gte}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_recebimento__gte })}
                        />
                        {formik.errors.data_recebimento__gte && (
                            <small className="p-error">{formik.errors.data_recebimento__gte}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_recebimento__lte">Data recebimento (fim)</label>
                        <MakoCalendar
                            id="data_recebimento__lte"
                            name="data_recebimento__lte"
                            valueCalendar={formik.values.data_recebimento__lte}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_recebimento__lte })}
                        />
                        {formik.errors.data_recebimento__lte && (
                            <small className="p-error">{formik.errors.data_recebimento__lte}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="quantidade_parcelas">Quantidade de parcelas</label>
                        <InputNumber
                            inputId="quantidade_parcelas"
                            name="quantidade_parcelas"
                            value={formik.values.quantidade_parcelas}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade_parcelas })}
                        />
                        {formik.errors.quantidade_parcelas && (
                            <small className="p-error">{formik.errors.quantidade_parcelas}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="numero_parcela">Número de parcelas</label>
                        <InputNumber
                            inputId="numero_parcela"
                            name="numero_parcela"
                            value={formik.values.numero_parcela}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.numero_parcela })}
                        />
                        {formik.errors.numero_parcela && (
                            <small className="p-error">{formik.errors.numero_parcela}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="competencia">Competência financeira</label>
                        <MakoDropdownCompetenciaFinanceira
                            id="competencia"
                            name="competencia"
                            value={formik.values.competencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia })}
                        />
                        {formik.errors.competencia && <small className="p-error">{formik.errors.competencia}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="boleto__codigo_barras">Código de barras (Boleto)</label>
                        <InputText
                            id="boleto__codigo_barras"
                            name="boleto__codigo_barras"
                            value={formik.values.boleto__codigo_barras}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.boleto__codigo_barras })}
                        />
                        {formik.errors.boleto__codigo_barras && (
                            <small className="p-error">{formik.errors.boleto__codigo_barras}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="venda">Número venda</label>
                        <InputNumber
                            inputId="venda"
                            name="venda"
                            value={formik.values.venda}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.venda })}
                        />
                        {formik.errors.venda && <small className="p-error">{formik.errors.venda}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="devolucao">Número devolução</label>
                        <InputNumber
                            inputId="devolucao"
                            name="devolucao"
                            value={formik.values.devolucao}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.devolucao })}
                        />
                        {formik.errors.devolucao && <small className="p-error">{formik.errors.devolucao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="trasacao_cartao__autorizacao">Autorização transação</label>
                        <InputText
                            id="trasacao_cartao__autorizacao"
                            name="trasacao_cartao__autorizacao"
                            disabled={!!formik.values.id}
                            value={formik.values.trasacao_cartao__autorizacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.trasacao_cartao__autorizacao })}
                        />
                        {formik.errors.trasacao_cartao__autorizacao && (
                            <small className="p-error">{formik.errors.trasacao_cartao__autorizacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="conta_financeira">Conta financeira</label>
                        <Dropdown
                            id="conta_financeira"
                            name="conta_financeira"
                            url="/financeiro/contas-financeiras/?ativa-True&limit=1000"
                            optionValue="id"
                            optionLabel="label"
                            filter
                            filterBy="id,codigo,descricao"
                            value={formik.values.conta_financeira}
                            aposBuscar={aposBuscarConta}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                        />
                        {formik.errors.conta_financeira && (
                            <small className="p-error">{formik.errors.conta_financeira}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="template_rateio">Template de rateio</label>
                        <Dropdown
                            id="template_rateio"
                            name="template_rateio"
                            buscar={!!empresaSelecionadaId}
                            url={`/financeiro/templates-rateios/?query={id,descricao}&empresa=${empresaSelecionadaId}`}
                            disabled={!!formik.values.id}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.template_rateio}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.template_rateio })}
                        />
                        {formik.errors.template_rateio && (
                            <small className="p-error">{formik.errors.template_rateio}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_recebido">Valor recebido</label>
                        <MakoInputMoeda
                            inputId="valor_recebido"
                            name="valor_recebido"
                            valueMoeda={formik.values.valor_recebido}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_recebido })}
                        />
                        {formik.errors.valor_recebido && (
                            <small className="p-error">{formik.errors.valor_recebido}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="quitado">Quitado</label>
                        <Dropdown
                            id="quitado"
                            name="quitado"
                            options={[
                                {
                                    value: true,
                                    label: "Sim",
                                },
                                {
                                    value: false,
                                    label: "Não",
                                },
                            ]}
                            showClear
                            placeholder="Todos"
                            value={formik.values.quitado}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quitado })}
                        />
                        {formik.errors.quitado && <small className="p-error">{formik.errors.quitado}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cancelado">Cancelado</label>
                        <Dropdown
                            id="cancelado"
                            name="cancelado"
                            options={[
                                {
                                    value: true,
                                    label: "Sim",
                                },
                                {
                                    value: false,
                                    label: "Não",
                                },
                            ]}
                            showClear
                            placeholder="Todos"
                            value={formik.values.cancelado}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cancelado })}
                        />
                        {formik.errors.cancelado && <small className="p-error">{formik.errors.cancelado}</small>}
                    </div>
                </div>
            </Panel>
            <br />
            <div className="p-grid p-justify-end p-mt-2">
                <MakoButton type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                <MakoButton
                    type="reset"
                    icon="pi pi-trash"
                    label="Limpar"
                    onClick={() => formik.resetForm({ values: INITIAL_VALUES })}
                    className="p-button-warning p-mr-2"
                />
                <MakoButton type="reset" label="Cancelar" onClick={cancelCallback} className="p-button-danger p-mr-3" />
            </div>
        </form>
    );
};
