import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import { dataToStr } from "@/assets/util/datas";

export const DateTimeFiltroTemplate = (options) => {
    return (
        <MakoCalendarTime
            valueCalendar={options.value}
            onChange={(e) => options.filterCallback(dataToStr(e.value, "yyyy-MM-dd hh:mm:ss"))}
        />
    );
};
