import React, { useCallback, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { Dialog } from "primereact/dialog";
import { ProdutoMarcasForm } from "../Marcas/form";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import useHttp from "@/hooks/useHttp";

const url = "/produtos/marcas-fabricantes/";
const urlvoltar = "/produto/cadastros/marcas-fabricante";

export const ProdutoMarcasFabricanteForm = () => {
    const [cadastrarMarca, setCadastrarMarca] = useState(false);
    const { state } = useLocation();
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPatch, httpPost } = useHttp();
    const dropRef = useRef();

    const { resetForm, ...formik } = useFormik({
        initialValues: state
            ? {
                  ...state.marcasFabricante,
                  perfil_fabricante: {
                      ...state.marcasFabricante.perfil_fabricante,
                      label: `${state.marcasFabricante.perfil_fabricante.identificacao} - ${state.marcasFabricante.perfil_fabricante.nome}`,
                  },
                  marca: state.marcasFabricante.marca.id,
              }
            : {
                  id: "",
                  perfil_fabricante: null,
                  marca: null,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil_fabricante: Yup.object()
                    .required("O campo 'perfil do fabricante' é obrigatório.")
                    .typeError("Informe um 'fabricante' válido."),
                marca: Yup.number().required("O campo 'marca' é obrigatório.").typeError("Informe uma 'marca' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Marca de fabricante cadastrada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push(urlvoltar);
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: {
                            ...values,
                            perfil_fabricante: values.perfil_fabricante.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Marca de fabricante alterada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push(urlvoltar);
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: {
                            ...values,
                            perfil_fabricante: values.perfil_fabricante.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const formataPerfilFabricante = useCallback((results) => {
        return results.map((e) => {
            return { ...e, label: `${e.identificacao} - ${e.nome}` };
        });
    }, []);

    const esconderMarca = () => {
        setCadastrarMarca(false);
        dropRef.current?.fetchDados();
    };

    const cancelar = () => {
        resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h4>{!formik.values.id ? "Nova Marca de Fabricante" : "Manutenção de Marca de Fabricante"}</h4>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="marca">Marca * </label>
                        <div className="p-inputgroup">
                            <Dropdown
                                ref={dropRef}
                                id="marca"
                                name="marca"
                                placeholder="Selecione uma marca"
                                filter
                                filterBy="nome"
                                url="/produtos/marcas?limit=3000"
                                optionValue="id"
                                optionLabel="nome"
                                showClear={false}
                                value={formik.values.marca}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.marca })}
                            />
                            <MakoControleAcesso
                                permissao={[permissoes.PRODUTO_CADASTRO_MARCA_INCLUIR]}
                                componente={Button}
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-success"
                                tooltip="Cadastrar tipo de codigo"
                                tooltipOptions={{ position: "left" }}
                                onClick={() => setCadastrarMarca(true)}
                            />
                        </div>
                        {formik.errors.marca && <small className="p-error">{formik.errors.marca}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="perfil_fabricante">Fabricante *</label>
                        <MakoAutoComplete
                            id="perfil_fabricante"
                            name="perfil_fabricante"
                            placeholder="Busque pelo nome ou identificação... (min 4 caracteres)"
                            minCaracteresBusca={4}
                            value={formik.values.perfil_fabricante}
                            onChange={formik.handleChange}
                            aposPesquisar={formataPerfilFabricante}
                            field="label"
                            urlSearch={"/pessoas/perfis?search="}
                            className={classNames({ "p-invalid": formik.errors.perfil_fabricante })}
                        />
                        {formik.errors.perfil_fabricante && (
                            <small className="p-error">{formik.errors.perfil_fabricante}</small>
                        )}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    <Button
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={cancelar}
                    />
                </div>
            </form>
            <Dialog
                header="Nova marca"
                visible={cadastrarMarca}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "35vw" }}
                onHide={() => esconderMarca()}
            >
                <ProdutoMarcasForm marca dialog={esconderMarca} />
            </Dialog>
        </PageBase>
    );
};
