import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Dropdown } from "@/components/Dropdown";
import { InputText } from "primereact/inputtext";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import * as Yup from "yup";
import classNames from "classnames";
import useTroca from "@/hooks/useTroca";
import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import { Dialog } from "primereact/dialog";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { SelectButton } from "primereact/selectbutton";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { parseNumber } from "@/assets/helpers/number";
import useToast from "@/hooks/useToast";

const Modal = ({ aposSalvar }, ref) => {
    const { handleItemTrocaDevolucao, dadosBasicos } = useTroca();
    const [visible, setVisible] = useState(false);
    const { showSuccess, showError } = useToast();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            id: null,
            sku: null,
            unidade_medida: null,
            item_venda: null,
            quantidade: 0,
            cubagem: 1,
            valor_unitario: 0,
            subtotal: 0,
            encargos: 0,
            motivo_devolucao: null,
            justificativa: "",
            retorno_estoque: true,
            defeito: false,
            estoque_retorno: null,
            sku_movimenta_estoque: null,
            _encargos_venda: 0,
            _unidade: null,
            _cubagem: null,
            _qtd_vendida: null,
            _qtd_devolvida: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                quantidade: Yup.number()
                    .required("O campo 'qtd. desta devolução' é obrigatório.")
                    .min(1, "A quantidade deve ser maior que 0.")
                    .test({
                        message: "A quantidade informada é maior do que o saldo disponível.",
                        test: (value) => value <= values._qtd_vendida - values._qtd_devolvida,
                    }),
                motivo_devolucao: Yup.number()
                    .required("O campo 'motivo da devolução' é obrigatório.")
                    .typeError("Você precisa selecionar um motivo para a devolução."),
                estoque_retorno: Yup.number()
                    .required("O campo 'centro de estocagem de retorno' é obrigatório.")
                    .typeError("Você precisa selecionar um centro de estocagem de retorno."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleItemTrocaDevolucao(values);

            if (status === 200 || status === 201) {
                if (typeof aposSalvar === "function") aposSalvar();
                showSuccess({
                    summary: "Sucesso",
                    detail: "O item foi inserido com sucesso.",
                    life: 3000,
                });
                setVisible(false);
                resetForm();
            } else
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível inserir o item da troca.",
                    life: 3000,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (itemDevolucao) => {
        if (itemDevolucao) {
            const { item_venda, ...rest } = itemDevolucao;
            setValues({
                ...rest,
                sku: item_venda,
                unidade_medida: rest.unidade_medida.id,
                motivo_devolucao: rest.motivo_devolucao.id,
                sku_movimenta_estoque: rest.sku_movimenta_estoque.id,
                _encargos_venda: parseNumber(item_venda.valor_encargos),
                _unidade: item_venda.unidade_venda?.sigla,
                _cubagem: parseNumber(item_venda.cubagem),
                _qtd_vendida: parseNumber(item_venda.quantidade),
                _qtd_devolvida: parseNumber(item_venda.quantidade_devolvida),
            });
        }
        setVisible(true);
    };

    const calcularSubtotalItem = useCallback(() => {
        if (!formik.values.quantidade) setFieldValue("subtotal", 0);
        else {
            const subtotal = formik.values.quantidade * formik.values.valor_unitario + formik.values.encargos;
            setFieldValue("subtotal", subtotal);
        }
    }, [formik.values.quantidade, formik.values.valor_unitario, formik.values.encargos, setFieldValue]);

    useEffect(() => {
        calcularSubtotalItem();
    }, [calcularSubtotalItem]);

    const esconderDialog = () => {
        resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => {
        return {
            setVisible,
            abrirModal,
        };
    });

    const onChangeItemDevolucao = (value) => {
        setFieldValue("sku", value);
        if (value instanceof Object) {
            const { unidade_venda, cubagem, quantidade, quantidade_devolvida, valor_unitario, valor_encargos } = value;
            setFieldValue("valor_unitario", parseNumber(valor_unitario));
            setFieldValue("_encargos_venda", parseNumber(valor_encargos));
            setFieldValue("_unidade", unidade_venda?.sigla);
            setFieldValue("_cubagem", parseNumber(cubagem));
            setFieldValue("_qtd_vendida", parseNumber(quantidade));
            setFieldValue("_qtd_devolvida", parseNumber(quantidade_devolvida));
        }
    };

    return (
        <Dialog
            header="Inserir Item da Devolvido"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "80vw", display: "block" }}
            onHide={() => setVisible(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="item-venda">Item da venda *</label>
                        <Dropdown
                            id="sku"
                            name="sku"
                            url={`/vendas/itens-vendas?venda=${dadosBasicos.venda.id}`}
                            placeholder="Selecione um produto"
                            filter
                            filterBy="sku.id,sku.codigo,sku.descricao_complementar"
                            optionLabel="descricao_reduzida"
                            value={formik.values.sku}
                            showClear={false}
                            onChange={({ value }) => onChangeItemDevolucao(value)}
                            className="p-mr-2"
                        />
                        {formik.errors.sku && <small className="p-error">{formik.errors.sku}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="und">Unidade</label>
                        <InputText id="und" disabled value={formik.values._unidade} />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="qtd-desta-devolucao">Qtd. devolvida</label>
                        <MakoInputQuantidadeSku
                            id="qtd-desta-devolucao"
                            name="_qtd_devolvida"
                            value={formik.values._qtd_devolvida}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="qtd-desta-devolucao">Qtd. devolução *</label>
                        <MakoInputQuantidadeSku
                            id="qtd-desta-devolucao"
                            name="quantidade"
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-unitario">Valor unitário</label>
                        <MakoInputMoeda id="valor-unitario" disabled value={formik.values.valor_unitario} />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-encargos">Valor dos encargos</label>
                        <MakoInputMoeda id="valor-encargos" disabled value={formik.values.encargos} />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="subtotal-devolucao">Subtotal da devolução</label>
                        <MakoInputMoeda
                            id="subtotal-devolucao"
                            name="subtotal"
                            disabled
                            value={formik.values.subtotal}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="motivo-devolucao">Motivo da devolução *</label>
                        <Dropdown
                            id="motivo-devolucao"
                            name="motivo_devolucao"
                            url="/vendas/motivo-devolucao/"
                            optionValue="id"
                            optionLabel="descricao"
                            showClear={false}
                            filter
                            filterBy="descricao"
                            value={formik.values.motivo_devolucao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.motivo_devolucao })}
                        />
                        {formik.errors.motivo_devolucao && (
                            <small className="p-error">{formik.errors.motivo_devolucao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="estoque-retorno">Estoque / centro de estocagem de retorno *</label>
                        <MakoDropdownEstoques
                            id="estoque-retorno"
                            name="estoque_retorno"
                            optionValue="id"
                            showClear={false}
                            value={formik.values.estoque_retorno}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.estoque_retorno })}
                        />
                        {formik.errors.estoque_retorno && (
                            <small className="p-error">{formik.errors.estoque_retorno}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="retorna-estoque">Retorna ao estoque</label>
                        <SelectButton
                            id="retorna-estoque"
                            name="retorno_estoque"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.retorno_estoque}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="produto-com-defeito">Produto com defeito</label>
                        <SelectButton
                            id="produto-com-defeito"
                            name="defeito"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.defeito}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="justificativa">Justificativa</label>
                        <InputText
                            id="justificativa"
                            name="justificativa"
                            value={formik.values.justificativa}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-12 p-justify-end p-mt-1">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-success p-mr-2" />
                    <Button
                        icon="pi pi-times"
                        label="Fechar"
                        type="reset"
                        className="p-button-danger"
                        onClick={() => esconderDialog()}
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ItensTrocaModal = forwardRef(Modal);
