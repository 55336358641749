import React, { useMemo, useState } from "react";

import { useHistory } from "react-router-dom";

import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import { PageBase } from "@/components/PageBase";

import { CentrosEstocagemForm } from "./formCentrosEstocagem";
import { FormulaForm } from "./formFormula";

const urlvoltar = "/estoque/cadastros/saldo-estoque-formula/";

export const TabFormula = ({ formula, possuiCentros: possui }) => {
    const [formulaDados, setFormulaDados] = useState(formula);
    const [possuiCentros, setPossuiCentros] = useState(possui);
    const history = useHistory();

    const voltarParaListagem = () => {
        history.push(urlvoltar);
    };

    const titleTemplate = useMemo(() => {
        return !formula?.id
            ? "Cadastro formula de saldo de estoque"
            : `Manutenção de formula de saldo: ${formula?.descricao}`;
    }, [formula]);

    const tabs = [
        {
            header: "Fórmula",
            icon: "pi pi-fw pi-th-large",
            component: (
                <FormulaForm formula={formulaDados} possuiCentros={possuiCentros} handleFormula={setFormulaDados} />
            ),
        },
        {
            header: "Centros de Estocagem",
            icon: "pi pi-fw pi-box",
            component: <CentrosEstocagemForm formula={formulaDados} handleCentros={setPossuiCentros} />,
        },
    ];

    return (
        <PageBase>
            <MakoMultiTabs
                tabs={tabs}
                title={titleTemplate}
                ocultarButtonFinalizar
                propsButtonCancelar={{ onClick: voltarParaListagem, label: "Fechar" }}
            />
        </PageBase>
    );
};
