import React, { useCallback, useRef } from "react";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Button } from "primereact/button";
import { Visualizar as Modal } from "../../modals";

import useClearRefs from "@/hooks/useClearRefs";

export const Visualizar = ({ className = "", onSuccessCallback = () => {}, parametro }) => {
    const modalRef = useRef(null);

    useClearRefs(modalRef);

    const show = useCallback(() => {
        modalRef.current?.show(parametro);
    }, [parametro]);

    return (
        <>
            <Button
                icon={MAKO_ICONS.VISUALIZAR}
                className={`p-button-rounded p-button-info ${className}`}
                onClick={show}
            />
            <Modal ref={modalRef} successCallback={onSuccessCallback} />
        </>
    );
};
