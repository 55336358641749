import { dataToStr } from "@/assets/util/datas";
import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { TIPOS_ENTREGA_PRODUTO } from "@/assets/constants/vendas";
import { MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { Dropdown } from "@/components/Dropdown";
import { RELATORIO_VENDAS_ENTREGAS_REALIZADAS } from "@/assets/constants/relatorios";
import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";
import MakoListagem from "@/components/MakoListagem";
import { ExportCSV } from "@/components/MakoBaseRelatorio/Buttons/exportCSV";
import { gerarFileName } from "@/assets/util/util";
import { MakoInputEntregador } from "@/components/MakoInputs/MakoInputEntregador";
import { TIPO_CENTRO_ESTOCAGEM } from "@/assets/constants/estoque";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

const BASE_URL = "/relatorios/entrega-vendas-realizadas/";

const FILE_NAME = gerarFileName("Entrega");

const FILTROS_VIEWSET = {
    centro_estocagem: "centro_estocagem",
    estagio_venda: "item_venda__venda__sequencia_estagio_venda",
    data_entrega_start: "datahora_entrega__date__gte",
    data_entrega_end: "datahora_entrega__date__lte",
    tipo_entrega: "tipo_entrega",
    cidade: "endereco_entrega__cidade__id",
    entregador: "entregador",
    fluxo_venda: "item_venda__venda__estagio_venda",
};

export const RelatorioEntregasRealizadas = () => {
    const formRef = useRef();
    const categoriaRef = useRef();
    const { solicitarRelatorio } = useRelatorio();
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);
    const [fluxoVenda, setFluxoVenda] = useState(null);
    const [estagiosVenda, setEstagiosVenda] = useState([]);
    const [formatarDocumento] = useFormatCNPJCPF();

    const { empresaSelecionadaId } = useEmpresa();

    const listagemRef = useRef();
    const botaoCsv = useRef();

    useClearRefs(formRef);

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                tipo_entrega: Yup.string().nullable().typeError("Selecione será será uma entrega ou retirada."),
                centro_estocagem: Yup.object()
                    .shape({
                        id: Yup.number().required("Informe um 'centro estocagem' válido."),
                    })
                    .nullable()
                    .typeError("Informe um 'centro estocagem' válido"),
                estagio_venda: Yup.object().nullable().typeError("Informe um 'estágio de venda' válido"),
                data_entrega_start: Yup.date()
                    .required("Data inicial de entrega é obrigatório")
                    .default(null)
                    .typeError("Selecione um 'inicio de data de entrega' válido."),
                data_entrega_end: Yup.date()
                    .when("data_entrega_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .nullable()
                            .required("Selecione um 'inicio de data de entrega' válido.")
                            .min(
                                values.data_entrega_start || new Date(),
                                "A 'data final de entrega' não pode ser anterior a data inicial de entrega"
                            ),
                        otherwise: Yup.date().nullable(),
                    })
                    .default(null)
                    .typeError("Seleciona um 'final de data de entrega' válido."),
                cidade: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'cidade' válida"),
                entregador: Yup.object()
                    .shape({
                        id: Yup.number()
                            .nullable("O campo 'entregador' é obrigatório.")
                            .typeError("Informe um 'entregador' válido."),
                    })
                    .nullable()
                    .typeError("Informe um 'entregador' válido"),
                fluxo_venda: Yup.object().nullable().typeError("Informe um 'estágio de venda' válido"),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: dadosValidados.categoria ? categoriaRef.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.centro_estocagem) dadosValidados.centro_estocagem = dadosValidados.centro_estocagem.id;
            // if (dadosValidados.tipo_entrega) dadosValidados.tipo_entrega = dadosValidados.tipo_entrega.nome;
            if (dadosValidados.estagio_venda) dadosValidados.estagio_venda = dadosValidados.estagio_venda.id;
            if (dadosValidados.data_entrega_start)
                dadosValidados.data_entrega_start = dataToStr(dadosValidados.data_entrega_start, "yyyy-MM-dd");
            if (dadosValidados.data_entrega_end)
                dadosValidados.data_entrega_end = dataToStr(dadosValidados.data_entrega_end, "yyyy-MM-dd");
            if (dadosValidados.entregador) dadosValidados.entregador = dadosValidados.entregador.id;
            if (dadosValidados.cidade) dadosValidados.cidade = dadosValidados.cidade.id;
            if (dadosValidados.fluxo_venda?.id) dadosValidados.fluxo_venda = dadosValidados.fluxo_venda.id;

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                if (!filtros.hasOwnProperty("centro_estocagem")) {
                    if (empresaSelecionadaId) filtros.empresa = empresaSelecionadaId;
                }
                solicitarRelatorio({
                    chave: RELATORIO_VENDAS_ENTREGAS_REALIZADAS,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
                limparFormulario();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "" && v !== undefined) {
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const hasCentroEstocagem = filtros.filter((element) => element.split("=")[0] === "centro_estocagem");
                if (hasCentroEstocagem.length === 0 && empresaSelecionadaId) {
                    filtros.push(`item_venda__venda__empresa=${empresaSelecionadaId}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                console.log(_url);
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.centro_estocagem) dadosValidados.centro_estocagem = dadosValidados.centro_estocagem.id;
        // if (dadosValidados.tipo_entrega) dadosValidados.tipo_entrega = dadosValidados.tipo_entrega.nome;
        if (dadosValidados.estagio_venda) dadosValidados.estagio_venda = dadosValidados.estagio_venda.id;
        if (dadosValidados.data_entrega_start)
            dadosValidados.data_entrega_start = dataToStr(dadosValidados.data_entrega_start, "yyyy-MM-dd");
        if (dadosValidados.data_entrega_end)
            dadosValidados.data_entrega_end = dataToStr(dadosValidados.data_entrega_end, "yyyy-MM-dd");

        if (dadosValidados.fluxo_venda?.id) dadosValidados.fluxo_venda = dadosValidados.fluxo_venda.id;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);

        if (!filtros.hasOwnProperty("centro_estocagem")) {
            if (empresaSelecionadaId) filtros.empresa = empresaSelecionadaId;
        }
        return aplicarFiltrosCsv(filtros);
    };

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_VENDAS_ENTREGAS_REALIZADAS}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const filtrosTemplate = [
        {
            key: ["data_entrega_start", "data_entrega_end"],
            label: "Intervalo de data de entrega",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "tipo_entrega",
            label: "Tipo de entrega",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            options: TIPOS_ENTREGA_PRODUTO,
        },
        {
            key: "estagio_venda",
            label: "Estágio de Venda",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "centro_estocagem",
            label: "Centro de estocagem",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            optionLabel: "descricao",
            optionValue: "id",
        },
        {
            key: "cidade",
            label: "Cidade",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            optionValue: "id",
            optionLabel: "nome",
        },
        {
            key: "cidade",
            label: "Cidade",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "fluxo_venda",
            label: "Fluxo de Venda",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    const colunas = [
        { field: "numero_entrega", header: "Ṇ° Entrega" },
        { field: "venda", header: "Venda" },
        { field: "data_entrega", header: "Data entrega" },
        { field: "hora_entrega", header: "Hora entrega" },
        { field: "cliente", header: "Cliente", action: (e) => `${e.cliente} - ${formatarDocumento(e.cpf_cnpj)}` },
        { field: "codigo", header: "Código" },
        { field: "descricao", header: "Descrição" },
        { field: "quantidade_entrega", header: "Qtd Entrega" },
        { field: "tipo", header: "Tipo" },
        { field: "endereco", header: "Endereço" },
        { field: "complemento", header: "Complemento" },
        { field: "bairro", header: "Bairro" },
        { field: "cidade", header: "Cidade" },
        { field: "estagio", header: "Estágio" },
        { field: "nome_entregador", header: "Entregador" },
        { field: "nome_recebedor", header: "Recebedor" },
    ];

    const centroEstocagemTemplate = (option) => {
        const tipoCE = TIPO_CENTRO_ESTOCAGEM.find((el) => el.value === option.tipo);
        return <span>{`${option.nome} [${tipoCE ? tipoCE.label : "Desconhecido"}]`}</span>;
    };

    const centroEstocagemSelecionadoTemplate = (option, props) => {
        if (option) return centroEstocagemTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const onChangeFluxoVenda = (e) => {
        if (e.value?.id) {
            setFluxoVenda(e.value?.id);
            setEstagiosVenda(e.value?.sequenciaestagiovenda_set);
        } else {
            setEstagiosVenda([]);
        }
    };

    return (
        <>
            <R.Wrapper titulo={"de entregas realizadas"}>
                <MakoFormGerador
                    ref={formRef}
                    formikProps={{
                        initialValues: {
                            tipo_entrega: null,
                            data_entrega_start: null,
                            data_entrega_end: null,
                            centro_estocagem: null,
                            estagio_venda: null,
                            entregador: null,
                        },
                        onSubmit: handleSubmit,
                    }}
                    camposFormularios={[
                        {
                            label: "Centro estocagem",
                            inputId: "centro_estocagem",
                            inputName: "centro_estocagem",
                            required: false,
                            component: Dropdown,
                            componentProps: {
                                empresaId: empresaSelecionadaId,
                                url: `/produtos/centros-estocagem/?empresa__id=${empresaSelecionadaId}&limit=100`,
                                optionLabel: "nome",
                                buscar: !!empresaSelecionadaId,
                                itemTemplate: centroEstocagemTemplate,
                                valueTemplate: centroEstocagemSelecionadoTemplate,
                            },
                            fieldSize: 4,
                        },
                        {
                            label: "Data Entrega Inicial",
                            inputId: "data_entrega_start",
                            inputName: "data_entrega_start",
                            component: MakoCalendar,
                            componentProps: {},
                            fieldSize: 2,
                        },
                        {
                            label: "Data Entrega Final",
                            inputId: "data_entrega_end",
                            inputName: "data_entrega_end",
                            component: MakoCalendar,
                            componentProps: {},
                            fieldSize: 2,
                        },
                        {
                            label: "Fluxo",
                            inputId: "fluxo_venda",
                            inputName: "fluxo_venda",
                            component: Dropdown,
                            componentProps: {
                                url: "/vendas/estagios-vendas/",
                                optionLabel: "descricao",
                                getOnChange: onChangeFluxoVenda,
                            },
                        },
                        {
                            label: "Estágio de Fluxo",
                            inputId: "estagio_venda",
                            inputName: "estagio_venda",
                            component: Dropdown,
                            componentProps: {
                                optionLabel: "tipo_estagio_venda.descricao",
                                buscar: !!!fluxoVenda,
                                options: estagiosVenda,
                            },
                        },
                        {
                            label: "Tipo de Entrega",
                            inputId: "tipo_entrega",
                            inputName: "tipo_entrega",
                            component: Dropdown,
                            componentProps: {
                                options: TIPOS_ENTREGA_PRODUTO,
                                optionLabel: "label",
                                optionValue: "value",
                            },
                            fieldSize: 2,
                        },
                        {
                            label: "Cidade",
                            inputId: "cidade",
                            inputName: "cidade",
                            component: MakoAutoComplete,
                            componentProps: {
                                urlSearch: "/pessoas/cidades?limit=20&query={id,nome}&nome__unaccent__icontains=",
                                field: "nome",
                                minCaracteresBusca: 2,
                                placeholder: "Digite o nome da cidade para buscar...",
                            },
                        },
                        {
                            label: "Entregador",
                            inputId: "entregador",
                            inputName: "entregador",
                            component: MakoInputEntregador,
                            componentProps: {
                                optionValue: "id",
                                // valueTemplate: exibicaoEntregador
                            },
                        },
                    ]}
                >
                    <R.Buttons.Visualizar onClick={handleVisualizar} />
                    <R.Buttons.GerarPdf
                        chave={RELATORIO_VENDAS_ENTREGAS_REALIZADAS}
                        setFieldValue={setFieldValue}
                        handleSubmit={submit}
                    />
                    <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                    <R.Buttons.Limpar onClick={limparFormulario} />
                </MakoFormGerador>
                <div className="p-mt-2">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        urlPesquisa={url}
                        msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                        botaoExportar={BotaoExportar}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            exportFilename: FILE_NAME,
                        }}
                    />
                </div>
            </R.Wrapper>
        </>
    );
};
