import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoInputRepresentante } from "@/components/MakoInputs/MakoInputRepresentante";
import { Dropdown } from "@/components/Dropdown";
import { MakoDropdownCompetenciaFinanceira } from "@/components/MakoInputs/MakoDropdownCompetenciaFinanceira";
import { MakoDropdownCompetenciaEstoque } from "@/components/MakoInputs/MakoDropdownCompetenciaEstoque";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import {
    MakoInputIntervaloParcelas,
    validarProgressaoIntervaloParcelas,
} from "@/components/MakoInputs/MakoInputIntervaloParcelas";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import { ModalErrosValidacao } from "@/components/ModalErrosValidacao";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { COMPRAS_ENTRADA_GRUPO_CATEGORIA_CLASSIFICATORIA } from "@/assets/constants/parametros";
import { dataToStr } from "@/assets/util/datas";
import { parseMoeda } from "@/assets/util/util";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import useEntrada from "@/hooks/useEntrada";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

export const DadosBasicosEntradaForm = () => {
    const [tiposEntradas, setTiposEntradas] = useState([]);
    const [ordensCompras, setOrdensCompras] = useState([]);
    const modalErrosValidacaoRef = useRef(null);
    const { getParam } = useParam();
    const { showError } = useToast();
    const { dadosBasicos, permiteAlterar, handleDadosBasicos } = useEntrada();
    const { httpGet } = useHttp();
    const [loadingOC, showLoadingOC, hideLoadingOC] = useLoadingLocal();

    const grupoCategoriaClassificatoria = useMemo(() => {
        const param = getParam(COMPRAS_ENTRADA_GRUPO_CATEGORIA_CLASSIFICATORIA);
        return param?.valor;
    }, [getParam]);

    const { setValues, ...formik } = useFormik({
        initialValues: dadosBasicos || {
            id: 0,
            empresa: null,
            competencia_financeira: null,
            competencia_estoque: null,
            datahora_entrada: new Date(),
            categoria_titulo: null,
            tipo_entrada: null,
            fornecedor: null,
            representante: null,
            ordem_compra_principal: null,
            valor_total_produtos: 0,
            valor_total_servicos: 0,
            valor_total_frete: 0,
            valor_total_seguro: 0,
            valor_total_outros: 0,
            valor_custos_adicionais: 0,
            valor_custos_extras: 0,
            valor_antecipacao: 0,
            valor_total_parcelas: 0,
            valor_total_entrada: 0,
            status: "P",
            numero_parcelas: 0,
            intervalo_parcelas: "",
            usuario_cadastro: null,
            usuario_validacao: null,
            datahora_validacao: null,
            usuario_finalizacao: null,
            datahora_finalizacao: null,
            anotacoes: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                tipo_entrada: Yup.number()
                    .required("O campo 'tipo de entrada' é obrigatório")
                    .typeError("Selecione um tipo de entrada."),
                fornecedor: Yup.object()
                    .shape({
                        id: Yup.string().required("O campo 'fornecedor' é obrigatório."),
                    })
                    .typeError("Selecione um fornecedor"),
                ordem_compra_principal: Yup.mixed().when("tipo_entrada", {
                    is: (val) => tiposEntradas.find((e) => e.id === val)?.requer_ordem_compra,
                    then: Yup.object()
                        .shape({
                            id: Yup.number().required("O tipo de entrada selecionado requer uma ordem de compra."),
                        })
                        .typeError("O tipo de entrada selecionado requer uma ordem de compra."),
                    otherwise: Yup.mixed().nullable(),
                }),
                categoria_titulo: Yup.number()
                    .required("O campo 'categoria' é obrigatório")
                    .typeError("Selecione uma categoria."),
                intervalo_parcelas: Yup.string()
                    .required("O campo 'intervalo de parcelas' é obrigatório.")
                    .test("Validation_2", "A progressão do intervalo não está correta.", (value) =>
                        validarProgressaoIntervaloParcelas(value)
                    ),
                competencia_estoque: Yup.number()
                    .required("O campo 'competência de estoque' é obrigatório")
                    .typeError("Selecione uma competência de estoque."),
                competencia_financeira: Yup.number()
                    .required("O campo 'competência financeira' é obrigatório")
                    .typeError("Selecione uma competência financeira."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const { status, data } = await handleDadosBasicos(values);
            if (status === 200 || status === 201) {
                formikHelpers.resetForm({ values: data });
            } else if (status === 400) {
                modalErrosValidacaoRef.current?.abrirModal(data);
            } else if (status === 500) {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar sua requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            }
        } finally {
            formikHelpers.setSubmitting(false);
        }
    }

    const buscarOrdensCompra = useCallback(async () => {
        if (formik.values.fornecedor instanceof Object && formik.values.tipo_entrada) {
            const params = {
                query: "{id,representante,data_pedido,categoria_item,vencimento_inicial,prazo_medio_ordem_compra,vencimento_antecipacao,valor_antecipado,intervalo_parcelas,valor_total_ordem_compra,itemordemcompra_set,vinculado_entrada}",
                fornecedor: formik.values.fornecedor.id,
                status: "VA",
            };
            const handlers = {
                200: ({ data }) => setOrdensCompras(data.results.filter((oc) => !oc.vinculado_entrada)),
            };
            showLoadingOC();
            await httpGet({ url: "/compras/ordens-compra/", params }, handlers);
            hideLoadingOC();
        }
    }, [formik.values.fornecedor, formik.values.tipo_entrada, showLoadingOC, hideLoadingOC, httpGet]);

    useEffect(() => {
        buscarOrdensCompra();
    }, [buscarOrdensCompra]);

    const onChangeOrdemCompra = (value, setFieldValue) => {
        setFieldValue("ordem_compra_principal", value);
        setFieldValue("categoria_titulo", value.categoria_item);
        setFieldValue("intervalo_parcelas", value.intervalo_parcelas);
        setFieldValue("representante", value.representante);
    };

    const ordemCompraOptionTemplate = (option) => {
        return (
            <span>{`OC: ${option.id} - ${dataToStr(option.data_pedido, "dd/MM/yyyy")} - Total: ${parseMoeda(
                option.valor_total_ordem_compra
            )}`}</span>
        );
    };

    const ordemCompraSelecionadaTemplate = (option, props) => {
        if (option) return ordemCompraOptionTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    useEffect(() => {
        if (dadosBasicos) setValues(dadosBasicos);
    }, [dadosBasicos, setValues]);

    const edicaoBloqueada = !permiteAlterar;

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="n-entrada">Nº Entrada</label>
                        <InputNumber id="n-entrada" name="id" disabled value={formik.values.id} placeholder="Nova" />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="datahora-entrada">Data da entrada</label>
                        <MakoCalendar
                            id="datahora-entrada"
                            name="datahora_entrada"
                            disabled={edicaoBloqueada}
                            maxDate={new Date()}
                            valueCalendar={formik.values.datahora_entrada}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="tipo-entrada">Tipo de entrada *</label>
                        <Dropdown
                            id="tipo-entrada"
                            name="tipo_entrada"
                            disabled={edicaoBloqueada}
                            url="/compras/tipos-entradas/?limit=100"
                            setObjects={setTiposEntradas}
                            optionValue="id"
                            optionLabel="descricao"
                            filter
                            filterBy="descricao"
                            value={formik.values.tipo_entrada}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.tipo_entrada,
                            })}
                        />
                        {formik.errors.tipo_entrada && <small className="p-error">{formik.errors.tipo_entrada}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="fornecedor">Fornecedor *</label>
                        <MakoInputFornecedor
                            id="fornecedor"
                            name="fornecedor"
                            disabled={!!formik.values.id || edicaoBloqueada}
                            value={formik.values.fornecedor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fornecedor })}
                        />
                        {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="ordem-compra">Ordem de compra</label>
                        <Dropdown
                            id="ordem-compra"
                            name="ordem_compra_principal"
                            disabled={loadingOC || edicaoBloqueada}
                            options={ordensCompras}
                            optionLabel="id"
                            itemTemplate={ordemCompraOptionTemplate}
                            valueTemplate={ordemCompraSelecionadaTemplate}
                            placeholder={!loadingOC ? "Selecione" : "Buscando..."}
                            value={formik.values.ordem_compra_principal}
                            onChange={(e) => onChangeOrdemCompra(e.value, formik.setFieldValue)}
                            className={classNames({ "p-invalid": formik.errors.ordem_compra_principal })}
                        />
                        {formik.errors.ordem_compra_principal && (
                            <small className="p-error">{formik.errors.ordem_compra_principal}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="representante">Representante</label>
                        <MakoInputRepresentante
                            id="representante"
                            name="representante"
                            disabled={!!formik.values.id || edicaoBloqueada}
                            value={formik.values.representante}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.representante })}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="categoria-titulo">Categoria *</label>
                        <MakoDropdownCategoriasHierarquicas
                            id="categoria-titulo"
                            name="categoria_titulo"
                            disabled={!!formik.values.ordem_compra_principal || edicaoBloqueada}
                            grupoId={grupoCategoriaClassificatoria}
                            categoriaTituloSelecionavel
                            apenasTitulo
                            value={formik.values.categoria_titulo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.categoria_titulo })}
                        />
                        {formik.errors.categoria_titulo && (
                            <small className="p-error">{formik.errors.categoria_titulo}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="intervalo-parcelas">Dias entre as parcelas *</label>
                        <MakoInputIntervaloParcelas
                            id="intervalo-parcelas"
                            name="intervalo_parcelas"
                            disabled={edicaoBloqueada}
                            value={formik.values.intervalo_parcelas}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.intervalo_parcelas })}
                        />
                        {formik.errors.intervalo_parcelas && (
                            <small className="p-error">{formik.errors.intervalo_parcelas}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="competencia-estoque">Competência de estoque *</label>
                        <MakoDropdownCompetenciaEstoque
                            id="competencia-estoque"
                            name="competencia_estoque"
                            disabled={edicaoBloqueada}
                            value={formik.values.competencia_estoque}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia_estoque })}
                        />
                        {formik.errors.competencia_estoque && (
                            <small className="p-error">{formik.errors.competencia_estoque}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="competencia-financeira">Competência financeira *</label>
                        <MakoDropdownCompetenciaFinanceira
                            id="competencia-financeira"
                            name="competencia_financeira"
                            disabled={edicaoBloqueada}
                            value={formik.values.competencia_financeira}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia_financeira })}
                        />
                        {formik.errors.competencia_financeira && (
                            <small className="p-error">{formik.errors.competencia_financeira}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-antecipacao">Valor da antecipação</label>
                        <MakoInputMoeda
                            id="valor-antecipacao"
                            name="valor_antecipacao"
                            valueMoeda={formik.values.valor_antecipacao}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-total-produtos">Valor total dos produtos</label>
                        <MakoInputMoeda
                            id="valor-total-produtos"
                            name="valor_total_produtos"
                            disabled
                            valueMoeda={formik.values.valor_total_produtos}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-total-servicos">Valor total dos serviços</label>
                        <MakoInputMoeda
                            id="valor-total-servicos"
                            name="valor_total_servicos"
                            disabled
                            valueMoeda={formik.values.valor_total_servicos}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-total-frete">Valor total do frete</label>
                        <MakoInputMoeda
                            id="valor-total-frete"
                            name="valor_total_frete"
                            disabled
                            valueMoeda={formik.values.valor_total_frete}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-total-seguro">Valor total do seguro</label>
                        <MakoInputMoeda
                            id="valor-total-seguro"
                            name="valor_total_seguro"
                            disabled
                            valueMoeda={formik.values.valor_total_seguro}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-total-outros">Valor total (Outros)</label>
                        <MakoInputMoeda
                            id="valor-total-outros"
                            name="valor_total_outros"
                            disabled
                            valueMoeda={formik.values.valor_total_outros}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-custos-adicionais">Custos adicionais</label>
                        <MakoInputMoeda
                            id="valor-custos-adicionais"
                            name="valor_custos_adicionais"
                            disabled
                            valueMoeda={formik.values.valor_custos_adicionais}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-custos-extras">Custos extras</label>
                        <MakoInputMoeda
                            id="valor-custos-extras"
                            name="valor_custos_extras"
                            disabled={dadosBasicos?.possui_nf || edicaoBloqueada}
                            valueMoeda={formik.values.valor_custos_extras}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-total-entrada">Valor total da entrada</label>
                        <MakoInputMoeda
                            id="valor-total-entrada"
                            name="valor_total_entrada"
                            disabled
                            valueMoeda={formik.values.valor_total_entrada}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-total-parcelas">Valor total das parcelas</label>
                        <MakoInputMoeda
                            id="valor-total-parcelas"
                            name="valor_total_parcelas"
                            disabled
                            valueMoeda={formik.values.valor_total_parcelas}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="usuario-cadastro">Usuário que cadastrou</label>
                        <InputText
                            id="usuario-cadastro"
                            name="usuario_cadastro"
                            disabled
                            value={formik.values.usuario_cadastro?.usuario.username || ""}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="usuario-validacao">Usuário que validou</label>
                        <InputText
                            id="usuario-validacao"
                            name="usuario_validacao"
                            disabled
                            value={formik.values.usuario_validacao?.usuario.username || ""}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="datahora-validacao">Data/hora validação</label>
                        <MakoCalendar
                            id="datahora-validacao"
                            name="datahora_validacao"
                            disabled
                            valueCalendar={formik.values.datahora_validacao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="usuario-finalizacao">Usuário que finalizou</label>
                        <InputText
                            id="usuario-finalizacao"
                            name="usuario_finalizacao"
                            disabled
                            value={formik.values.usuario_finalizacao?.usuario.username || ""}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="datahora-finalizacao">Data/hora finalização</label>
                        <MakoCalendar
                            id="datahora-finalizacao"
                            name="datahora_finalizacao"
                            disabled
                            valueCalendar={formik.values.datahora_finalizacao}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="anotacoes">Anotações</label>
                        <InputText
                            id="anotacoes"
                            name="anotacoes"
                            disabled={edicaoBloqueada}
                            value={formik.values.anotacoes}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                {formik.values.id === 0 && (
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <Button
                                type="submit"
                                icon="pi pi-save"
                                label="Incluir dados básicos"
                                className="p-mr-2 p-mb-2"
                            />
                        </div>
                    </div>
                )}
                <FormikAutoSave intervalo={500} autosave={formik.values.id > 0 && !edicaoBloqueada} formik={formik} />
            </form>
            <ModalErrosValidacao ref={modalErrosValidacaoRef} context="entrada" />
        </>
    );
};
