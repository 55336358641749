import React from "react";

import { PageBase } from "@/components/PageBase";

import { Listagens } from "./listagens";

export const LotesPage = () => {
    return (
        <PageBase>
            <Listagens.Lotes />
        </PageBase>
    );
};
