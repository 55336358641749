import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoTime } from "@/components/MakoTime";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { dataToStr, horaToStr } from "@/assets/util/datas";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { TIPO_RESTRICAO_SPC } from "@/assets/constants/constants";
import useEmpresa from "@/hooks/useEmpresa";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

const url = "/vendas/consultas-spc/";

export const VendasConsultaSpcForm = (props) => {
    const { empresaSelecionadaId } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const history = useHistory();
    const { httpPost, httpPatch } = useHttp();
    const { showSuccess, showError } = useToast();

    const { resetForm, ...formik } = useFormik({
        initialValues:
            props.location && props.location.state
                ? props.location.state.consulta
                : {
                      id: "",
                      data: "",
                      hora: "",
                      identificador: "",
                      indicador_restricao: false,
                      informacoes_consulta: "",
                      cliente: null,
                      perfil: null,
                  },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                identificador: Yup.string().required("O campo 'identificador' é obrigatório."),
                indicador_restricao: Yup.boolean().required("O campo 'indicador de restrição' é obrigatório."),
                cliente: Yup.object()
                    .required("O campo 'indicador de restrição' é obrigatório.")
                    .typeError("Informe um 'cliente' válido."),
                informacoes_consulta: Yup.string().required("O campo 'informações da consulta' é obrigatório."),
                data: Yup.date().required("O campo 'data' é obrigatório.").typeError("Informe uma 'data' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Consulta SPC registrada com sucesso!",
                            life: 1500,
                        });
                        if (props?.venda) resetForm();
                        else
                            setTimeout(() => {
                                resetForm();
                                history.push("/vendas/venda/consultas-spc");
                            }, 2000);
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: {
                            ...values,
                            cliente: values.cliente.id,
                            perfil: empresaSelecionadaId,
                            data: dataToStr(values.data, "yyyy-MM-dd"),
                            hora: horaToStr(values.hora, "HH:mm:ss"),
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Consulta SPC alterada com sucesso!",
                            life: 1500,
                        });
                        setTimeout(() => {
                            resetForm();
                            history.push("/vendas/venda/consultas-spc");
                        }, 2000);
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: {
                            ...values,
                            cliente: values.cliente.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    const autoCompleteTemplate = (valor) => {
        valor.label = `${valor.nome} - ${valor.identificacao}`;
        return `${valor.nome} - ${valor.identificacao}`;
    };

    const cancelar = () => {
        resetForm();
        history.push("/vendas/venda/consultas-spc");
    };

    return (
        <PageBase>
            <Toast ref={toastRef} />
            <h3>
                {!formik.values.id
                    ? "Registrar Consulta SPC"
                    : `Consulta SPC - Cliente: ${formik.values.cliente.nome} - ${formik.values.cliente.identificacao}`}
            </h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="identificador">Protocolo *</label>
                        <InputText
                            id="identificador"
                            name="identificador"
                            value={formik.values.identificador}
                            onChange={formik.handleChange}
                            keyfilter={/^[0-9]/}
                            className={classNames({ "p-invalid": formik.errors.identificador })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.identificador && (
                            <small className="p-error">{formik.errors.identificador}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data">Data *</label>
                        <MakoCalendar
                            id="data"
                            name="data"
                            valueCalendar={formik.values.data}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data })}
                        />
                        {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="hora">Hora</label>
                        <MakoTime id="hora" name="hora" valueTime={formik.values.hora} onChange={formik.handleChange} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="indicador_restricao">Situação *</label>
                        <Dropdown
                            id="indicador_restricao"
                            name="indicador_restricao"
                            options={TIPO_RESTRICAO_SPC}
                            value={formik.values.indicador_restricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.indicador_restricao })}
                        />
                        {formik.errors.indicador_restricao && (
                            <small className="p-error">{formik.errors.indicador_restricao}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid ">
                    {!!!formik.values.id && (
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="cliente">Pessoa consultada *</label>
                            <MakoAutoComplete
                                id="cliente"
                                name="cliente"
                                placeholder="Busque pelo nome ou cpf/cnpj ... (min 4 caractéres)"
                                minCaracteresBusca={4}
                                value={formik.values.cliente}
                                onChange={formik.handleChange}
                                itemTemplate={autoCompleteTemplate}
                                field="label"
                                urlSearch={"/pessoas/perfis?search="}
                                className={classNames({ "p-invalid": formik.errors.cliente })}
                            />
                            {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                        </div>
                    )}
                </div>
                <div className="p-fluid p-formgrid p-grid ">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="informacoes_consulta">informações da consulta *</label>
                        <InputTextarea
                            id="informacoes_consulta"
                            name="informacoes_consulta"
                            value={formik.values.informacoes_consulta}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.informacoes_consulta })}
                            rows={5}
                        />
                        {formik.errors.informacoes_consulta && (
                            <small className="p-error">{formik.errors.informacoes_consulta}</small>
                        )}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    <Button
                        label="Voltar"
                        type="submit"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={() => cancelar()}
                    />
                </div>
            </form>
        </PageBase>
    );
};
