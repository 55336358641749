import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import { ModalAlterarEstagio } from "./ModalAlterarEstagio";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoCalendar } from "@/components/MakoCalendar";
import { ModalImpressoras } from "@/components/ModalImpressoras";
import permissoes from "@/assets/constants/permissoes";
import * as chavesRelatorios from "@/assets/constants/relatorios";
import { axiosPut } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";
import useToast from "@/hooks/useToast";
import useRelatorio from "@/hooks/useRelatorio";
import useLoading from "@/hooks/useLoading";
import useFormatCnpjCpf from "@/hooks/useFomatCNPJCPF";
import useAuth from "@/hooks/useAuth";
import { FiltroAvancadoVendas } from "./filtroAvancado";
import useClearRefs from "@/hooks/useClearRefs";
import { key_filtros } from "@/assets/constants/filtros";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { GerarPdf } from "@/components/MakoBaseRelatorio/Buttons/gerarPdf";
import { MoedaFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const VendasPage = () => {
    const { verifyPermission, user } = useAuth();
    const FIELDS_VENDA = [
        "id",
        "data",
        "empresa",
        "cliente",
        "vendedor",
        "supervisor",
        "plano_recebimento",
        "estagio_venda",
        "situacao",
        "sequencia_estagio_venda",
        "situacao_desconto_itens",
        "situacao_desconto_encargos",
        "situacao_crediario",
        "valor_total_ap",
        "possui_nf",
    ];
    const BASE_URL = `/vendas/vendas/?query={${FIELDS_VENDA}}${
        !verifyPermission([permissoes.VENDAS_VENDA_REALIZARVENDA_CONSULTARTODOS]) ? `&vendedor__id=${user.id}` : ""
    }`;
    const [filtros, setFiltro, removerFiltro] = useLocalFiltro(key_filtros.VENDAS_VENDA_AVANCADO);
    const [url, setUrl] = useState(`${BASE_URL}&situacao__in=P,F&ocultar_finalizado=true`);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const [relatorio, setRelatorio] = useState({ chave: chavesRelatorios.RELATORIO_VENDAS_CONTRATOVENDA, filtros: {} });
    const [vendaSelecionadaPossuiCarne, setVendaSelecionadaPossuiCarne] = useState(false);

    const { showSuccess, showWarning, showError } = useToast();
    const { showLoading, hideLoading } = useLoading();
    const { solicitarRelatorio } = useRelatorio();
    const [formatDocumento] = useFormatCnpjCpf();
    const pdfRef = useRef({ emails: [], background: false, versao: "1" });
    const modalAlterarEstagioRef = useRef(null);
    const modalFiltroAvancado = useRef(null);
    const menuAcoesVendaRef = useRef(null);
    const vendaSelecionada = useRef(null);
    const botaoPdfRef = useRef(null);
    const listagemRef = useRef(null);
    const modalImpressorasRef = useRef(null);
    const history = useHistory(null);

    useClearRefs(
        modalAlterarEstagioRef,
        modalFiltroAvancado,
        menuAcoesVendaRef,
        vendaSelecionada,
        botaoPdfRef,
        modalImpressorasRef
    );

    const handleChangePdf = (key, value) => {
        pdfRef.current[key] = value;
    };

    const gerarPdf = () => {
        const { emails, background, versao } = pdfRef.current;
        const { chave, filtros } = relatorio;
        solicitarRelatorio({
            emails,
            versao,
            chave,
            enviar_fila: background,
            filtros,
        });
    };

    const gerarRelatorio = useCallback(
        (chave, ids, filtros = {}) => {
            solicitarRelatorio({ chave, filtros: ids, enviar_fila: false });
        },
        [solicitarRelatorio]
    );

    const commandRelatorio = (chave = "", filtros = {}) => {
        setRelatorio({ chave, filtros });
        botaoPdfRef.current?.show(true);
    };

    const painelEsquerdoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_REALIZARVENDA_INCLUIR]}
                componente={Button}
                label="Nova"
                icon="pi pi-plus"
                onClick={() => history.push("/vendas/venda/form")}
                className="p-button-success p-mr-2"
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => modalFiltroAvancado.current?.abrirModal()}
            />
        </>
    );

    const orcamentoFiltroTemplate = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.filterCallback(e.value)} />;
    };

    const dataFiltroTemplate = (options) => {
        return (
            <MakoCalendar
                valueCalendar={options.value}
                onChange={(e) => options.filterCallback(dataToStr(e.value, "yyyy-MM-dd"))}
            />
        );
    };

    const empresaBodyTemplate = (rowData) => {
        const { empresa } = rowData;
        if (empresa?.nome_curto?.trim().length > 0) return <span>{empresa.nome_curto}</span>;
        return <span>{formatDocumento(empresa.identificacao)}</span>;
    };

    const clienteBodyTemplate = (cliente) => {
        return <span>{`${cliente.nome} - ${formatDocumento(cliente.identificacao)}`}</span>;
    };

    const fluxoVendaBodyTemplate = (rowData) => {
        const { sequencia_estagio_venda } = rowData;
        if (!sequencia_estagio_venda) return null;
        return <span>{`${sequencia_estagio_venda.id} - ${sequencia_estagio_venda.tipo_estagio_venda.descricao}`}</span>;
    };

    const situacaoCrediarioBodyTemplate = (rowData) => {
        switch (rowData.situacao_crediario) {
            case "R":
            case "P":
                return <span>Aguardando</span>;
            case "A":
                return <span>Aprovado</span>;
            default:
                return <span>Não requer</span>;
        }
    };

    const confirmarFinalizacao = () => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma a finalização da venda <strong>{vendaSelecionada.current?.id}</strong>?
                </p>
            ),
            icon: "pi pi-info-circle",
            acceptLabel: "Confirmar",
            rejectLabel: "Cancelar",
            accept: () => finalizarVenda(vendaSelecionada.current?.id),
        });
    };

    const confirmarCancelamento = () => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma o <strong style={{ color: "#D32F2F" }}>CANCELAMENTO</strong> da venda{" "}
                    <strong style={{ color: "#D32F2F" }}>{vendaSelecionada.current?.id}</strong>?
                </p>
            ),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Confirmar",
            rejectLabel: "Fechar",
            accept: () => cancelarVenda(vendaSelecionada.current?.id),
        });
    };

    const itensMenuAcoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Finalizar venda",
                    disabled: !verifyPermission([permissoes.VENDAS_VENDA_REALIZARVENDA_FINALIZAR]),
                    command: confirmarFinalizacao,
                },
                {
                    label: "Cancelar venda",
                    disabled: !verifyPermission([permissoes.VENDAS_VENDA_REALIZARVENDA_CANCELAR]),
                    command: confirmarCancelamento,
                },
                {
                    label: "Alterar fluxo de venda",
                    command: () => modalAlterarEstagioRef.current.abrirModal(vendaSelecionada.current),
                },
            ],
        },
        {
            label: "Emitir Documentos",
            items: [
                {
                    label: "Contrato",
                    command: () =>
                        commandRelatorio(chavesRelatorios.RELATORIO_VENDAS_CONTRATOVENDA, {
                            orcamento: vendaSelecionada.current?.id,
                        }),
                },
                {
                    label: "Recibo",
                    command: () =>
                        commandRelatorio(chavesRelatorios.RELATORIO_VENDAS_RECIBO_VENDAS, {
                            id: vendaSelecionada.current?.id,
                        }),
                },
                {
                    label: "Orçamento (pdf)",
                    command: () =>
                        commandRelatorio(chavesRelatorios.RELATORIO_VENDAS_ORCAMENTO, {
                            id: vendaSelecionada.current?.id,
                        }),
                },
                {
                    label: "Orçamento (térmica)",
                    command: () =>
                        modalImpressorasRef.current?.abrirModal({
                            chave: "venda.orcamento",
                            filtros: {
                                venda: vendaSelecionada.current?.id,
                            },
                        }),
                },
                {
                    label: "Controle entrega",
                    disabled: !verifyPermission([permissoes.RELATORIOS_EMITIR_VENDAS_CONTROLEENTREGA]),
                    command: () =>
                        gerarRelatorio(chavesRelatorios.RELATORIO_VENDAS_TABELACONTROLEENTREGA, {
                            vendas: [vendaSelecionada.current?.id],
                        }),
                },
                {
                    label: "Carnê",
                    disabled:
                        !verifyPermission([permissoes.RELATORIOS_EMITIR_VENDAS_CARNEPARCELASVENDA]) ||
                        !vendaSelecionadaPossuiCarne,
                    command: () => {
                        commandRelatorio(chavesRelatorios.RELATORIO_VENDAS_CARNEPARCELASVENDA, {
                            venda: vendaSelecionada.current?.id,
                        });
                    },
                },
            ],
        },
    ];

    const cancelarVenda = async (vendaId) => {
        showLoading();
        const resposta = await axiosPut(`/vendas/cancelar-venda/${vendaId}/`);
        hideLoading();
        if (resposta.status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Venda cancelada com sucesso!",
                life: 1500,
            });
            setTimeout(() => {
                listagemRef.current?.buscarDados();
            }, 2000);
        } else if (resposta.status < 500) {
            showWarning({
                summary: "Aviso!",
                detail: `Não foi possível cancelar a venda: ${resposta.data?.msg}`,
                life: 10000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a requisição.",
                life: 3000,
            });
        }
    };

    const finalizarVenda = async (vendaId) => {
        showLoading();
        const resposta = await axiosPut(`/vendas/finalizar-venda/${vendaId}/`);
        hideLoading();
        if (resposta.status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Venda finalizada com sucesso!",
                life: 1500,
            });
            setTimeout(() => {
                listagemRef.current?.buscarDados();
            }, 2000);
        } else if (resposta.status < 500) {
            showWarning({
                summary: "Aviso!",
                detail: `Não foi possível finalizar a venda: ${resposta.data.msg}`,
                life: 10000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a requisição.",
                life: 3000,
            });
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_REALIZARVENDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/form",
                            state: {
                                idVenda: rowData.id,
                            },
                        })
                    }
                />
                <Menu model={itensMenuAcoes} popup ref={menuAcoesVendaRef} id="popup_menu_acoes" />
                <Button
                    icon="pi pi-cog"
                    aria-haspopup
                    aria-controls="popup_menu_acoes"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    onClick={(e) => {
                        setVendaSelecionadaPossuiCarne(rowData.possui_carne);
                        vendaSelecionada.current = rowData;
                        menuAcoesVendaRef.current?.toggle(e);
                    }}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Orçamento",
            style: { width: "8%" },
            filter: true,
            filterElement: orcamentoFiltroTemplate,
        },
        {
            field: "empresa.nome",
            header: "Loja",
            action: (e) => empresaBodyTemplate(e),
        },
        {
            field: "cliente",
            header: "Cliente",
            filter: true,
            action: (e) => clienteBodyTemplate(e.cliente),
        },
        {
            field: "data",
            header: "Data",
            style: { width: "8%" },
            filter: true,
            dateFormat: "dd/MM/yyyy",
            filterElement: dataFiltroTemplate,
        },
        {
            field: "valor_total_ap",
            header: "Valor",
            style: { width: "10%" },
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "situacao_crediario",
            header: "Crediário",
            style: { width: "10%" },
            action: (e) => situacaoCrediarioBodyTemplate(e),
        },
        {
            field: "sequencia_estagio_venda.tipo_estagio_venda.descricao",
            header: "Estágio de venda",
            style: { width: "12%" },
            action: (e) => fluxoVendaBodyTemplate(e),
        },

        { field: "action", header: "Ações", style: { width: "8%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const rowClass = (rowData) => {
        return {
            "p-text-bold": rowData.possui_nf,
            "table-recebimentos-overdue": rowData.situacao === "P",
            "table-recebimentos-effective": rowData.situacao === "F",
            "mako-table-status-pendente": rowData.situacao === "C",
        };
    };

    const onConfirmFiltro = (newUrl, contador) => {
        setUrl(newUrl);
        setTotalizadorFiltros(contador);
    };

    const removerFiltros = () => {
        removerFiltro();
        setTotalizadorFiltros(0);
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Vendas"
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                urlPesquisa={url}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                keyFiltrosStorage={key_filtros.VENDAS_VENDA_TABELA}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    cliente: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "equals" }],
                    },
                    data: { value: "", matchMode: "equals" },
                    situacao: { value: "", matchMode: "equals" },
                    valor_total_ap: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    rowClassName: rowClass,
                }}
            />
            <GerarPdf
                ref={botaoPdfRef}
                chave={relatorio.chave}
                setFieldValue={handleChangePdf}
                handleSubmit={gerarPdf}
                showButton={false}
            />
            <ModalAlterarEstagio ref={modalAlterarEstagioRef} onSuccess={() => listagemRef.current?.buscarDados()} />
            <FiltroAvancadoVendas
                ref={modalFiltroAvancado}
                onConfirm={onConfirmFiltro}
                url={BASE_URL}
                filtros={filtros}
                setFiltro={setFiltro}
                removerFiltro={removerFiltros}
            />
            <ConfirmDialog />
            <ModalImpressoras ref={modalImpressorasRef} apenasTermicas />
        </PageBase>
    );
};
