import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import MakoListagem from "@/components/MakoListagem";
import { gerarParcelas } from "@/assets/util/calcFinanceiros";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useToast from "@/hooks/useToast";
import { Label } from "@/components/Label";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

export const FracionamentoPagamentoPage = (props) => {
    const [credor, setCredor] = useState({ nome: "", doc: "" });
    const [pagamento, setPagamento] = useState(null);
    const [fracionamentos, setFracionamentos] = useState([]);
    const [qtdFracionamentos, setQtdFracionamentos] = useState(2);
    const [valorPrimeira, setValorPrimeira] = useState(0);
    const history = useHistory();
    const [formatarDocumento] = useFormatCNPJCPF();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { httpPost } = useHttp();

    async function handleSubmit() {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Fracionamento realizado com sucesso!",
                    life: 1500,
                });
                history.push("/financeiro/financeiro/pagamentos");
            },
            400: ({ err }) => {
                showError({
                    summary: "Falha no fracionamento",
                    detail: err?.msg,
                    life: 3000,
                });
            },
        };
        showLoading();
        await httpPost(
            {
                url: "/financeiro/fracionamento-pagamento/",
                body: {
                    fracionamentos: fracionamentos.map((frac) => ({
                        pagamento_id: pagamento.id,
                        documento: frac.documento,
                        valor: frac.valor,
                    })),
                },
            },
            handlers
        );
        hideLoading();
    }

    async function fracionarPagamento() {
        if (valorPrimeira > 0) {
            let _fracionamentos = [
                {
                    documento: `${pagamento.documento} FRA-1`,
                    vencimento: pagamento.vencimento,
                    valor: valorPrimeira,
                },
            ];
            const fracionamentos = gerarParcelas(pagamento.valor - valorPrimeira, qtdFracionamentos - 1);
            setFracionamentos([
                ..._fracionamentos,
                ...fracionamentos.map((fracionamento, index) => ({
                    documento: `${pagamento.documento} FRA-${index + 2}`,
                    vencimento: pagamento.vencimento,
                    valor: fracionamento,
                })),
            ]);
        } else {
            const fracionamentos = gerarParcelas(pagamento.valor, qtdFracionamentos);
            setFracionamentos(
                fracionamentos.map((fracionamento, index) => ({
                    documento: `${pagamento.documento} FRA-${index + 1}`,
                    vencimento: pagamento.vencimento,
                    valor: fracionamento,
                }))
            );
        }
    }

    useEffect(() => {
        if (props.location.state) {
            const { credor, quantidade_parcelas, numero_parcela } = props.location.state;
            setCredor({
                nome: credor.nome,
                doc: formatarDocumento(credor.identificacao),
            });
            setPagamento({
                ...props.location.state,
                parcela: numero_parcela === 0 ? "ENTRADA" : `${numero_parcela}/${quantidade_parcelas}`,
            });
        }
    }, [props.location.state, formatarDocumento]);

    const colunas = [
        { field: "documento", header: "Documento" },
        { field: "vencimento", header: "Vencimento", dateFormat: "dd/MM/yyyy" },
        { field: "valor", header: "Valor", money: true },
    ];

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Fracionar parcela</h5>
                    <Panel header="credor" className="p-mb-3">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <Label htmlFor="doc" label="CPF / CNPJ" />
                                <InputText id="doc" disabled value={credor.doc} />
                            </div>
                            <div className="p-field p-col-12 p-md-8">
                                <Label htmlFor="nome-completo" label="Nome completo" />
                                <InputText id="nome-completo" disabled value={credor.nome} />
                            </div>
                        </div>
                    </Panel>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="documento" label="Documento" />
                            <InputText id="documento" name="documento" disabled value={pagamento?.documento || ""} />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="parcela" label="Parcela" />
                            <InputText id="parcela" name="parcela" disabled value={pagamento?.parcela || ""} />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="valor" label="Valor" />
                            <MakoInputMoeda id="valor" name="valor" disabled valueMoeda={pagamento?.valor} />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="qtd-fracionamentos" label="Qtd. fracionamentos" obrigatorio />
                            <InputNumber
                                id="qtd-fracionamentos"
                                min={2}
                                value={qtdFracionamentos}
                                onValueChange={(e) => setQtdFracionamentos(e.value)}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="valor-primeira" label="Valor da primeira" obrigatorio />
                            <MakoInputMoeda
                                id="valor-primeira"
                                min={0}
                                valueMoeda={valorPrimeira}
                                tooltip="Deixe 0 para fracionar igualmente."
                                tooltipOptions={{ position: "bottom" }}
                                onChangeMoeda={(e) => setValorPrimeira(e.value)}
                            />
                        </div>
                    </div>
                    <MakoActionsButtons className="p-mb-3">
                        <Button
                            type="submit"
                            label="Fracionar"
                            icon={MAKO_ICONS.FRACIONAR}
                            className="p-button-secondary"
                            onClick={fracionarPagamento}
                        />
                    </MakoActionsButtons>
                    <MakoListagem colunas={colunas} dadosLocal={fracionamentos} configTabela={{ paginator: true }} />
                    <MakoActionsButtons className="p-mt-3">
                        <Button icon={MAKO_ICONS.GRAVAR} label="Gravar" onClick={handleSubmit} loading={loading} />
                        <Button
                            label="Cancelar"
                            icon={MAKO_ICONS.CANCEL}
                            className="p-button-danger"
                            to={"/financeiro/financeiro/pagamentos"}
                            loading={loading}
                        />
                    </MakoActionsButtons>
                </div>
            </div>
        </div>
    );
};
