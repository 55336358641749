import { Wrapper } from "./wrapper";
import { Visualizar } from "./visualizar";
import { GerarPdf } from "./gerarPdf";
import { EnviarEmail } from "./enviarEmail";
import { Limpar } from "./limpar";
import { ExportCSV } from "./exportCSV";

export const Buttons = {
    Wrapper,
    Limpar,
    GerarPdf,
    Visualizar,
    EnviarEmail,
    ExportCSV,
};
