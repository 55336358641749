import React from "react";

import { BlockUI } from "../BlockUI";

export const PageBase = ({ children, blocked }) => {
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <BlockUI loading={blocked}>{children}</BlockUI>
                </div>
            </div>
        </div>
    );
};
