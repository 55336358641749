import React, { createContext, useCallback, useEffect, useState } from "react";
import { add } from "date-fns";

import { dataToStr, horaToStr, parseData } from "@/assets/util/datas";
import * as pd from "@/assets/util/persistenciaDjango";
import { formatarCasasDecimais, maxDiasEmUmMes } from "@/assets/util/util";
import { VENDA_TIPOMOVIMENTACAO_PADRAO } from "@/assets/constants/parametros";
import { gerarParcelas } from "@/assets/util/calcFinanceiros";
import { parseNumber } from "@/assets/helpers/number";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useParam from "@/hooks/useParam";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const VendaContext = createContext({});

export const VendaProvider = ({ children }) => {
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [parcelasVenda, setParcelasVenda] = useState([]);
    const [vendedor, setVendedor] = useState(null);
    const { getParam } = useParam();
    const { empresaSelecionadaId } = useEmpresa();
    const { showWarning, showError } = useToast();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { httpDelete, httpGet, httpPost, httpPatch } = useHttp();
    const [loadingSalvar, showLoadingSalvar, hideLoadingSalvar] = useLoadingLocal();

    const carregarPapelPerfilVendedor = useCallback(async () => {
        if (!dadosBasicos?.vendedor) return;
        const handlers = {
            200: ({ data }) => {
                const papelVendedor = data.vinculoperfilpapel_set?.find(
                    (vinculo) => vinculo.papel.chave.id === "VND" && vinculo.ativo
                );
                if (papelVendedor) {
                    setVendedor(papelVendedor);
                } else {
                    showWarning({
                        summary: "Aviso!",
                        detail: "Papel perfil de vendedor não encontrado.",
                        life: 3000,
                    });
                }
            },
            error: () => {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos buscar os papéis perfis do vendedor.",
                    life: 3000,
                });
            },
        };
        await httpGet({ url: `/pessoas/perfis/${dadosBasicos?.vendedor}/?query={vinculoperfilpapel_set}` }, handlers);
    }, [dadosBasicos, httpGet, showWarning, showError]);

    useEffect(() => {
        carregarPapelPerfilVendedor();
    }, [carregarPapelPerfilVendedor]);

    const handleDadosBasicos = useCallback(
        async (values) => {
            const tipoMovEstoquePadrao = getParam(VENDA_TIPOMOVIMENTACAO_PADRAO);
            if (!values.id) {
                const novaVenda = {
                    ...values,
                    cliente: values.cliente.id,
                    data: dataToStr(values.data, "yyyy-MM-dd"),
                    hora: horaToStr(values.hora, "HH:mm:ss"),
                    empresa: empresaSelecionadaId,
                    tipo_movimentacao: tipoMovEstoquePadrao.valor,
                    quantidade_parcelas: 0,
                };
                let venda = {};
                const handlers = {
                    201: ({ data }) => {
                        venda = {
                            ...data,
                            cliente: {
                                ...data.cliente,
                                label: `${data.cliente.nome} - ${formatarDocumento(data.cliente.identificacao)}`,
                            },
                            vendedor: data.vendedor.id,
                            supervisor: data.supervisor?.id,
                            plano_recebimento: data.plano_recebimento.id,
                        };
                        setDadosBasicos(venda);
                    },
                };
                await httpPost({ url: "/vendas/vendas/", body: novaVenda }, handlers);
                return { status: 201, data: venda };
            } else if (dadosBasicos?.situacao === "P") {
                const { vendedor, supervisor, observacoes, plano_recebimento } = values;
                const vendaEditada = {
                    data: dataToStr(values.data, "yyyy-MM-dd"),
                    hora: horaToStr(values.hora, "HH:mm:ss"),
                    vendedor,
                    supervisor,
                    plano_recebimento,
                    observacoes,
                };
                let diffVenda = {};
                Object.entries(vendaEditada).forEach(([k, v]) => {
                    if (v !== dadosBasicos[k]) {
                        diffVenda[k] = v;
                    }
                });
                if (Object.keys(diffVenda).length > 0) {
                    let venda = {};
                    let status = 200;
                    const handlers = {
                        200: ({ data }) => {
                            venda = {
                                ...data,
                                cliente: {
                                    ...data.cliente,
                                    label: `${data.cliente.nome} - ${formatarDocumento(data.cliente.identificacao)}`,
                                },
                                vendedor: data.vendedor.id,
                                supervisor: data.supervisor?.id,
                                plano_recebimento: data.plano_recebimento.id,
                            };
                            setDadosBasicos(venda);
                        },
                        error: ({ status: statusCode }) => (status = statusCode),
                    };
                    showLoadingSalvar();
                    await httpPatch({ url: `/vendas/vendas/${values.id}/`, body: diffVenda }, handlers);
                    hideLoadingSalvar();
                    return { status, data: venda };
                }
            }
            return { status: 204, data: values };
        },
        [
            dadosBasicos,
            empresaSelecionadaId,
            formatarDocumento,
            getParam,
            hideLoadingSalvar,
            httpPost,
            httpPatch,
            showLoadingSalvar,
        ]
    );

    const handleItemVenda = useCallback(
        async (values, op) => {
            const item = {
                ...values,
                venda: dadosBasicos.id,
                sku: values.sku.id,
                sku_movimenta_estoque: values.sku.sku_controle_saldo.id,
                cubagem: formatarCasasDecimais(values.cubagem, 5),
                desconto: formatarCasasDecimais(values.desconto),
            };
            if (op === pd.OP_CRUD_DJANGO.novo) {
                const handlers = {
                    error: () => {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não foi possível inserir o item na venda.",
                            life: 3000,
                        });
                    },
                };
                await httpPost({ url: "/vendas/itens-vendas/", body: item }, handlers);
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                const handlers = {
                    error: () => {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não foi possível alterar o item da venda.",
                            life: 3000,
                        });
                    },
                };
                await httpPatch({ url: `/vendas/itens-vendas/${values.id}/`, body: item }, handlers);
            } else if (op === pd.OP_CRUD_DJANGO.deletar) {
                const handlers = {
                    error: () => {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não foi possível remover o item da venda.",
                            life: 3000,
                        });
                    },
                };
                await httpDelete({ url: `/vendas/itens-vendas/${values.id}/` }, handlers);
            }
        },
        [dadosBasicos, httpPost, httpPatch, httpDelete, showError]
    );

    const handleLimparParcelas = useCallback(
        async (onSuccess) => {
            const handlers = {
                204: async () => {
                    if (typeof onSuccess === "function") await onSuccess();
                },
                error: () => {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível deletar as parcelas anteriores.",
                        life: 3000,
                    });
                },
            };
            await httpDelete({ url: `/vendas/${dadosBasicos.id}/deletar-parcelas/` }, handlers);
        },
        [dadosBasicos, httpDelete, showError]
    );

    const fixarDiaVencimentoParcela = (vencOriginal, diaVencDesejado) => {
        const dateAux = parseData(vencOriginal);
        const mesVencParcela = dateAux.getMonth() + 1;
        const anoVencParcela = dateAux.getFullYear();
        const maxDiaMesParcela = maxDiasEmUmMes(mesVencParcela, anoVencParcela);
        if (diaVencDesejado > maxDiaMesParcela) diaVencDesejado = maxDiaMesParcela;
        return new Date(anoVencParcela, mesVencParcela - 1, diaVencDesejado);
    };

    const handleParcelasVenda = useCallback((parcela) => {
        const {
            plano_recebimento,
            entrada,
            quantidade_parcelas: qtdParcelasFinanciadas,
            requer_aprovacao_desconto,
            valor_financiado,
            data_vencimento_inicial,
            forma_recebimento,
            taxa,
        } = parcela;
        const _parcelas = [];
        const intervaloPlano = plano_recebimento.intervalo_parcelas.split(",").map((i) => parseNumber(i));
        const planoPreveEntrada = intervaloPlano[0] === 0;
        const possuiEntrada = entrada.valor || (planoPreveEntrada && entrada.valor > 0);
        const quantidade_parcelas = possuiEntrada ? qtdParcelasFinanciadas + 1 : qtdParcelasFinanciadas;
        if (possuiEntrada) {
            _parcelas.push({
                numero_parcela: 0,
                quantidade_parcelas,
                data_vencimento: entrada.data_vencimento,
                valor_parcela: entrada.valor,
                forma_recebimento: entrada.forma_recebimento,
                voucher: entrada.voucher,
                valor_voucher: entrada.valor_voucher,
                requer_aprovacao_desconto,
                taxa,
                _editada: true,
            });
        }
        if (valor_financiado > 0) {
            const valoresParcelas = gerarParcelas(valor_financiado, qtdParcelasFinanciadas);
            const ignorarIntervalo = possuiEntrada && planoPreveEntrada ? 1 : 0;
            const intervaloParcelas = intervaloPlano.slice(ignorarIntervalo);
            const subIndex = possuiEntrada || (!possuiEntrada && !planoPreveEntrada) ? 1 : 0;
            valoresParcelas.forEach((valor, index) => {
                const dias = index + 1 <= ignorarIntervalo ? 0 : intervaloParcelas[index - subIndex];
                let data_vencimento = add(data_vencimento_inicial, { days: dias });
                if (plano_recebimento.dia_vencimento) {
                    const diaVencParcela = plano_recebimento.dia_vencimento;
                    data_vencimento = fixarDiaVencimentoParcela(data_vencimento, diaVencParcela);
                }
                _parcelas.push({
                    numero_parcela: index + 1,
                    quantidade_parcelas,
                    valor_entrada: entrada.valor || 0,
                    data_vencimento,
                    valor_parcela: valor,
                    valor_voucher: 0,
                    forma_recebimento,
                    requer_aprovacao_desconto,
                    taxa,
                    _editada: true,
                });
            });
        }
        return _parcelas;
    }, []);

    const atualizarValoresVenda = useCallback(async () => {
        if (dadosBasicos) {
            const campos = [
                "valor_entrada",
                "valor_desconto",
                "valor_frete",
                "valor_encargos",
                "valor_total_av",
                "valor_total_ap",
                "taxa_media_parcelas",
            ];
            const params = {
                query: `{${campos.join(",")}}`,
            };
            const handlers = {
                200: ({ data }) => {
                    setDadosBasicos((old) => ({
                        ...old,
                        valor_entrada: parseNumber(data.valor_entrada),
                        valor_desconto: parseNumber(data.valor_desconto),
                        valor_frete: parseNumber(data.valor_frete),
                        valor_encargos: parseNumber(data.valor_encargos),
                        valor_total_av: parseNumber(data.valor_total_av),
                        valor_total_ap: parseNumber(data.valor_total_ap),
                        taxa_media_parcelas: parseNumber(data.taxa_media_parcelas),
                    }));
                },
            };
            await httpGet({ url: `/vendas/vendas/${dadosBasicos.id}/`, params }, handlers);
        }
    }, [dadosBasicos, httpGet]);

    const buscarVendaBancoDeDados = useCallback(
        async (vendaId) => {
            const handlers = {
                200: ({ data }) => {
                    setDadosBasicos({
                        ...data,
                        cliente: {
                            ...data.cliente,
                            label: `${data.cliente.nome} - ${formatarDocumento(data.cliente.identificacao)}`,
                        },
                        vendedor: data.vendedor.id,
                        supervisor: data.supervisor?.id,
                        plano_recebimento: data.plano_recebimento.id,
                    });
                },
                error: () => {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível localizar a venda.",
                        life: 3000,
                    });
                },
            };
            await httpGet({ url: `/vendas/vendas/${vendaId}/` }, handlers);
        },
        [showError, httpGet, formatarDocumento]
    );

    return (
        <VendaContext.Provider
            value={{
                dadosBasicos,
                vendedor,
                vendaFinalizada: !!(dadosBasicos?.situacao === "F"),
                bloqueiaItens: !!(dadosBasicos?.sequencia_estagio_venda?.altera_itens === false),
                parcelasVenda,
                loadingSalvar,
                setParcelasVenda,
                handleDadosBasicos,
                handleItemVenda,
                handleParcelasVenda,
                atualizarValoresVenda,
                handleLimparParcelas,
                buscarVendaBancoDeDados,
                fixarDiaVencimentoParcela,
            }}
        >
            {children}
        </VendaContext.Provider>
    );
};

export default VendaContext;
