import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { VisualizarPendentes as Listagem } from "../../features";

const Component = ({ selecionados, getSelecionados = () => {}, handleSelectionAll = () => {} }, ref) => {
    const [disableConfirm, setDisableConfirm] = useState(true);
    const [vendaId, setVendaId] = useState(null);
    const [visible, setVisible] = useState(false);

    const listagemRef = useRef(null);

    const show = (id) => {
        setVendaId(id);
        setVisible(true);
    };

    const hide = () => setVisible(false);

    const handleDisableConfirm = useCallback((values) => {
        setDisableConfirm(!values?.length);
    }, []);

    const handleCancel = useCallback(() => {
        handleSelectionAll([], vendaId);
        listagemRef.current?.setSelecionados(null);
        setVisible(false);
    }, [vendaId, handleSelectionAll]);

    const handleConfirm = useCallback(() => {
        const items = listagemRef.current?.getSelecionados();
        handleSelectionAll(items, vendaId);
        setVisible(false);
    }, [handleSelectionAll, vendaId]);

    const header = useMemo(() => {
        return `Entrega de orçamento: ${vendaId}`;
    }, [vendaId]);

    const footer = useMemo(() => {
        return (
            <>
                <Button type="button" label="Cancelar" onClick={handleCancel} className="p-button p-button-danger" />
                <Button
                    type="button"
                    label="Confirmar"
                    onClick={handleConfirm}
                    disabled={disableConfirm}
                    className="p-button p-button-success"
                />
            </>
        );
    }, [handleCancel, handleConfirm, disableConfirm]);

    useImperativeHandle(ref, () => ({ show }), []);

    return (
        <Dialog
            header={header}
            footer={footer}
            visible={visible}
            style={{ width: "70vw" }}
            onHide={hide}
            closable={false}
        >
            <Listagem
                ref={listagemRef}
                venda_id={vendaId}
                selecionados={selecionados}
                getSelecionados={getSelecionados}
                onSelecionadosChange={handleDisableConfirm}
            />
        </Dialog>
    );
};

export const VisualizarPendentes = forwardRef(Component);
