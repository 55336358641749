import { ParametrosPage } from "@/pages/Configuracoes/Parametros";
import { ApiServicosPage } from "@/pages/Configuracoes/ApiServicos";
import { ApiServicosForm } from "@/pages/Configuracoes/ApiServicos/form";
import { PermissoesPerfil } from "@/pages/Configuracoes/PermissoesPerfil";
import { PermissoesPerfilForm } from "@/pages/Configuracoes/PermissoesPerfil/form";
import { CamposPersonalizadosPage } from "@/pages/Configuracoes/CamposPersonalizados";
import { CamposPersonalizadosForm } from "@/pages/Configuracoes/CamposPersonalizados/form";
import { FeriadosPage } from "@/pages/Configuracoes/Feriados";
import { RelatorioPermissoesUsuarios } from "@/pages/Relatorios/Configurações/PermissoesUsuarios";
import { ImpressoraTermicaPage } from "@/pages/Configuracoes/ImpressorasTermicas";
import { ImpressoraTermicaForm } from "@/pages/Configuracoes/ImpressorasTermicas/form";

export const configuracoesRoutes = [
    { path: "/configuracoes/parametros", component: ParametrosPage },
    { path: "/configuracoes/campos-personalizados", component: CamposPersonalizadosPage },
    { path: "/configuracoes/campos-personalizados/form", component: CamposPersonalizadosForm },
    { path: "/configuracoes/api-servicos", component: ApiServicosPage },
    { path: "/configuracoes/api-servicos/form", component: ApiServicosForm },
    { path: "/configuracoes/permissoes-perfis", component: PermissoesPerfil },
    { path: "/configuracoes/permissoes-perfis/form", component: PermissoesPerfilForm },
    { path: "/configuracoes/feriados", component: FeriadosPage },
    { path: "/configuracoes/relatorios/permissoes-usuarios", component: RelatorioPermissoesUsuarios },
    { path: "/configuracoes/dispositivos/impressoras-termicas", component: ImpressoraTermicaPage },
    { path: "/configuracoes/dispositivos/impressoras-termicas/form", component: ImpressoraTermicaForm },
];
