import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";

import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoButton as Button } from "@/components/MakoButton";
import { PageBase } from "@/components/PageBase";

const url = "/financeiro/planos-orcamentarios/";

export const FinanceiroPlanosOrcamentariosPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANOORCAMENTARIO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de plano orçamentário"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/financeiro/financeiro/planos-orcamentarios/form",
                        state: { planoOrcamentario: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANOORCAMENTARIO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir o plano orçamentário "}
                            <b>{rowData.descricao}</b>?
                        </span>
                    }
                    tooltip="Deletar plano orçamentário"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O plano orçamentário não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANOORCAMENTARIO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/financeiro/financeiro/planos-orcamentarios/form"}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", filter: true, filterElement: CodigoFiltroTemplate, style: { width: "8%" } },
        { field: "descricao", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "action",
            header: "Ações",
            align: "center",
            action: (e) => actionBodyTemplate(e),
            style: { width: "8%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Planos Orçamentários"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                filtarPorEmpresa
                naoBuscarSemEmpresa
                fieldFiltroEmpresa={"perfil"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
