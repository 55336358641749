import { useContext } from "react";

import DevolucaoContext from "@/contexts/devolucaoContext";

const useDevolucao = () => {
    const { handleDadosBasicos, buscarDevolucao, atualizarTotalDevolucao, dadosBasicos, submit } =
        useContext(DevolucaoContext);

    return { handleDadosBasicos, buscarDevolucao, atualizarTotalDevolucao, dadosBasicos, submit };
};

export default useDevolucao;
