/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

export const FormikAutoSave = ({ intervalo = 300, autosave = true, formik }) => {
    const debouncedSubmitCaller = useDebouncedCallback((ctx) => {
        if (ctx.isValid && autosave) {
            ctx.submitForm();
        }
    }, intervalo);

    useEffect(() => {
        if (formik.isValid && formik.dirty && !formik.isSubmitting && autosave) {
            debouncedSubmitCaller(formik);
        }
    }, [debouncedSubmitCaller, formik.values, autosave]);

    return null;
};
