import React, { useCallback, useRef } from "react";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { Parametros as Listagem } from "./listagens/Parametros";
import { PageBase } from "@/components/PageBase";
import { Buttons } from "./buttons";

import useClearRefs from "@/hooks/useClearRefs";

export const ParametrosPage = () => {
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const buscarDados = useCallback(() => {
        listagemRef.current?.buscarDados();
    }, []);

    const formataValor = (valor, tipo) => {
        if (tipo === "D") {
            const dt = new Date();
            const date = valor.split("/");
            dt.setDate(date[0]);
            dt.setMonth(date[1]);
            dt.setFullYear(date[2]);
            return dt;
        } else if (tipo === "H") {
            const dt = new Date();
            const time = valor.split(":");
            dt.setHours(time[0]);
            dt.setMinutes(time[1]);
            return dt;
        }
        return valor;
    };

    const actions = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Buttons.Visualizar
                    onSuccessCallback={buscarDados}
                    parametro={{
                        ...rowData,
                        valor: formataValor(rowData.valor, rowData.chave.tipo_valor),
                    }}
                />
                <Buttons.Editar
                    onSuccessCallback={buscarDados}
                    parametro={{
                        ...rowData,
                        valor: formataValor(rowData.valor, rowData.chave.tipo_valor),
                    }}
                />
                <Buttons.Remover
                    parametro={{ id: rowData.id, chave: rowData.chave.chave }}
                    onSuccessCallback={buscarDados}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const painelEsquerdo = (
        <>
            <Buttons.Novo className="p-mr-2" onSuccessCallback={buscarDados} />
        </>
    );

    return (
        <PageBase>
            <Listagem
                listagemRef={listagemRef}
                listagemProps={{
                    painelEsquerdo,
                }}
                actions={actions}
            />
        </PageBase>
    );
};
