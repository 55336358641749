import React, { useCallback, useEffect } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { formatDocumento, parseMoeda } from "@/assets/util/util";

const INITIAL_VALUES = {
    id: null,
    devolucao: null,
    valor: 0,
};

export const InformarDevolucao = ({ recebimento, successCallback = () => {}, cancelCallback = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost } = useHttp();

    const { values, errors, setValues, setFieldValue, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                devolucao: Yup.number()
                    .required("O campo 'devolução' é obrigatório.")
                    .typeError("Selecione uma 'devolução'."),
                valor: Yup.number()
                    .min(0.01, "Informe uma 'valor' maior que 0")
                    .required("O campo 'valor' é obrigatório")
                    .typeError("Informe um 'valor' válido"),
            });
            formSchema.validateSync(values, { abortEarly: false });

            const { id, valor, devolucao } = values;

            const body = {
                id,
                valor,
                devolucao,
            };

            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Devolução informada com sucesso",
                        life: 3000,
                    });
                    successCallback();
                },
            };
            showLoading();
            await httpPost({ url: `/financeiro/recebimentos/informar-devolucao/${values.id}`, body }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const aposBuscarDevolucao = useCallback((data = []) => {
        return data.map((value) => ({
            ...value,
            label: `${value.id}: ${value.cliente.nome} - ${formatDocumento(value.cliente.identificacao)}, ${parseMoeda(
                value.valor_total
            )}`,
        }));
    }, []);

    useEffect(() => {
        if (recebimento) {
            const { id, valor } = recebimento || {};
            setValues({
                ...INITIAL_VALUES,
                id,
                valor,
            });
        }
    }, [recebimento, setValues]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="devolucao" label="Devolução" obrigatorio />
                    <Dropdown
                        id="devolucao"
                        name="devolucao"
                        value={values.devolucao}
                        onChange={formik.handleChange}
                        url="/vendas/devolucao/?query={id,cliente,valor_total,status}"
                        aposBuscar={aposBuscarDevolucao}
                        optionValue="id"
                        optionLabel="label"
                        className={classNames({ "p-invalid": errors.devolucao })}
                    />
                    {errors.devolucao && <small className="p-error">{errors.devolucao}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="valor" label="Valor" obrigatorio />
                    <MakoInputMoeda
                        id="valor"
                        name="valor"
                        valueMoeda={values.valor}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": errors.valor })}
                    />
                    {errors.valor && <small className="p-error">{errors.valor}</small>}
                </div>
            </div>
            <MakoActionsButtons className="p-jc-end">
                <Button
                    label="Cancelar"
                    icon={MAKO_ICONS.CANCEL}
                    className="p-button-danger"
                    onClick={cancelCallback}
                    loading={loading}
                />
                <Button
                    label="Gravar"
                    icon={MAKO_ICONS.GRAVAR}
                    className="p-button-info"
                    type="submit"
                    loading={loading}
                />
            </MakoActionsButtons>
        </form>
    );
};
