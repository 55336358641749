import React from "react";

import { FinanceiroContaBancariaFormBase } from "./base";
import { PageBase } from "@/components/PageBase";

export const FinanceiroContaBancariaForm = (props) => {
    return (
        <PageBase>
            <FinanceiroContaBancariaFormBase contaBancaria={props.location.state?.contaBancaria} />
        </PageBase>
    );
};
