import React, { memo, useCallback, useEffect, useState } from "react";

import { InputText } from "primereact/inputtext";

import { MakoInputCubagem, MakoInputQuantidadeSku } from "@/components/MakoInputs";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Label } from "@/components/Label";

import { Skeleton } from "./skeleton";
import { Buttons } from "./buttons";

import { TIPO_BOOLEAN_RETIRAR_PREVISOESENTREGA } from "@/assets/constants/constants";
import { formatDocumento, formatarEndereco } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";
import { axiosGet } from "@/services/http";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

const MemoButtonRomaneio = memo(Buttons.Romaneio);
const MemoButtonControleEntrega = memo(Buttons.ControleEntrega);

const BASE_DATA = {
    id: null,
    venda_id: null,
    item_venda_id: null,
    empresa: "",
    endereco_empresa: "",
    cliente_identificacao: "",
    cliente_nome: "",
    retirar: "",
    datahora_entrega: null,
    endereco_entrega: "",
    observacoes_orcamento: "",
    item_codigo: "",
    item_descricao: "",
    item_unidade: "",
    item_cubagem: "",
    item_quantidade: "",
    item_valor: "",
    item_anotacoes: "",
    nota_fiscal: null,
    carga: "",
    rota: "",
    motorista: "",
};

const BASE_URL = "/vendas/previsoes-entregas-itens-vendas/{id}/detalhes";

export const Form = ({ previsaoId }) => {
    const [data, setData] = useState(BASE_DATA);

    const [fetching, show, hide] = useLoadingLocal();
    const { showError } = useToast();

    const fetch = useCallback(
        async (signal) => {
            show();
            const url = BASE_URL.replace("{id}", previsaoId);
            const { status, data } = await axiosGet(url, { signal });
            hide();
            if (status === 200) {
                const {
                    id,
                    venda_id,
                    empresa,
                    endereco_empresa,
                    cliente,
                    retirar,
                    datahora_entrega,
                    endereco_entrega,
                    observacoes,
                    item,
                    nota_fiscal,
                    carga,
                    rota_entrega,
                    item_venda_id,
                } = data.data;
                setData({
                    id,
                    venda_id,
                    item_venda_id,
                    empresa: `${empresa.nome} - ${formatDocumento(empresa.identificacao)}`,
                    endereco_empresa: formatarEndereco(endereco_empresa, true),
                    cliente_identificacao: formatDocumento(cliente.identificacao),
                    cliente_nome: cliente.nome,
                    retirar: TIPO_BOOLEAN_RETIRAR_PREVISOESENTREGA.find(({ value }) => value === retirar).label,
                    datahora_entrega: dataToStr(datahora_entrega, "dd/MM/yyyy HH:mm"),
                    endereco_entrega: formatarEndereco(endereco_entrega, true),
                    observacoes_orcamento: observacoes,
                    item_codigo: item.codigo,
                    item_descricao: item.descricao,
                    item_unidade: item.unidade.sigla,
                    item_cubagem: item.cubagem,
                    item_quantidade: item.quantidade,
                    item_valor: item.valor,
                    item_anotacoes: item.anotacoes,
                    nota_fiscal: nota_fiscal?.numero,
                    carga: carga?.codigo,
                    rota: rota_entrega?.descricao,
                    motorista: rota_entrega?.motorista?.nome,
                });
            } else showError();
        },
        [hide, previsaoId, show, showError]
    );

    useEffect(() => {
        const controler = new AbortController();
        fetch(controler.signal);
        return () => controler.abort();
    }, [fetch]);

    return (
        <Skeleton loading={fetching}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="empresa" label="Empresa / Filial" />
                    <InputText id="empresa" name="empresa" disabled value={data.empresa} />
                </div>
                <div className="p-field p-col-12 p-md-7">
                    <Label htmlFor="endereco_empresa" label="Endereço da empresa / filial" />
                    <InputText id="endereco_empresa" name="endereco_empresa" disabled value={data.endereco_empresa} />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="cliente_identificacao" label="CPF / CNPJ do cliente" />
                    <InputText
                        id="cliente_identificacao"
                        name="cliente_identificacao"
                        disabled
                        value={data.cliente_identificacao}
                    />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="cliente_nome" label="Nome / Razão social do cliente" />
                    <InputText id="cliente_nome" name="cliente_nome" disabled value={data.cliente_nome} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="retirar" label="Tipo" />
                    <InputText id="retirar" name="retirar" disabled value={data.retirar} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="datahora_entrega" label="Data/Hora entrega" />
                    <InputText id="datahora_entrega" name="datahora_entrega" disabled value={data.datahora_entrega} />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-8">
                    <Label htmlFor="endereco_entrega" label="Local de entrega" />
                    <InputText id="endereco_entrega" name="endereco_entrega" disabled value={data.endereco_entrega} />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="observacoes_orcamento" label="Observações do orçamento" />
                    <InputText
                        id="observacoes_orcamento"
                        name="observacoes_orcamento"
                        disabled
                        value={data.observacoes_orcamento}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="item_codigo" label="Código produto" />
                    <InputText id="item_codigo" name="item_codigo" disabled value={data.item_codigo} />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="item_descricao" label="Descrição do produto / mercadoria" />
                    <InputText id="item_descricao" name="item_descricao" disabled value={data.item_descricao} />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="item_unidade" label="Unidade" />
                    <InputText id="item_unidade" name="item_unidade" disabled value={data.item_unidade} />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="item_unidade" label="Cubagem" />
                    <MakoInputCubagem
                        inputId="item_cubagem"
                        name="item_cubagem"
                        mode="decimal"
                        disabled
                        minFractionDigits={3}
                        maxFractionDigits={6}
                        value={data.item_cubagem}
                    />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="item_quantidade" label="Quantidade" />
                    <MakoInputQuantidadeSku
                        inputId="item_quantidade"
                        name="item_quantidade"
                        value={data.item_quantidade}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="item_valor" label="Valor" />
                    <MakoInputMoeda id="item_valor" name="item_valor" valueMoeda={data.item_valor} disabled />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="item_anotacoes" label="Anotações sobre o item do orçamento" />
                    <InputText id="item_anotacoes" name="item_anotacoes" disabled value={data.item_anotacoes} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="nota_fiscal" label="N° da nota fiscal" />
                    <InputText id="nota_fiscal" name="nota_fiscal" disabled value={data.nota_fiscal} />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="carga" label="Carga" />
                    <InputText id="carga" name="carga" disabled value={data.carga} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="rota" label="Rota" />
                    <InputText id="rota" name="rota" disabled value={data.rota} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="motorista" label="Motorista" />
                    <InputText id="motorista" name="motorista" disabled value={data.motorista} />
                </div>
            </div>
            <MakoActionsButtons className="p-mt-2">
                <MemoButtonControleEntrega vendaId={data.venda_id} itemId={data.id} />
                <MemoButtonRomaneio previsaoId={data.id} />
            </MakoActionsButtons>
        </Skeleton>
    );
};
