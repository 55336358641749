import React, { useMemo } from "react";

import { PESSOAS_TAGPERFIL_PADRAO_REPRESENTANTE } from "@/assets/constants/parametros";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useParam from "@/hooks/useParam";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputRepresentante = ({ disabled, somenteAtivo, fornecedorId, ...props }) => {
    const { getParam } = useParam();
    const [formatarDocumento] = useFormatCNPJCPF();

    const tagRepresentante = useMemo(() => {
        const tagPerfil = getParam(PESSOAS_TAGPERFIL_PADRAO_REPRESENTANTE);
        if (tagPerfil) return tagPerfil.valor;
        return null;
    }, [getParam]);

    const url = useMemo(() => {
        const filtroAtivo = somenteAtivo ? "&ativo=true" : "";
        const filtroFornec = fornecedorId ? `&vinculo_representante__fornecedor__id=${fornecedorId}` : "";
        return `/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,enderecoperfil_set}${filtroAtivo}&tags=${tagRepresentante}${filtroFornec}&nome__unaccent__icontains=`;
    }, [somenteAtivo, fornecedorId, tagRepresentante]);

    const autoCompleteRepresentanteTemplate = (item) => {
        return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
    };

    return (
        <>
            <MakoAutoComplete
                disabled={!!!tagRepresentante || disabled}
                minCaracteresBusca={4}
                itemTemplate={autoCompleteRepresentanteTemplate}
                field="nome"
                urlSearch={url}
                placeholder="Digite o nome do representante para buscar... (min 4 caracteres)"
                {...props}
            />
            {!!!tagRepresentante && (
                <small className="p-error">Falta parametrizar a identificação do representante</small>
            )}
        </>
    );
};
