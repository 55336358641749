import React, { useMemo } from "react";

import { CodigoFiltroTemplate, DateFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import MakoListagem from "@/components/MakoListagem";

import { gerarFiltrosUrl } from "@/assets/util/util";
import { QuantidadeFiltroTemplate } from "@/components/MakoFiltrosCabecalho/QuantidadeFiltroTemplate";

const BASE_URL_MOVIMENTACAO = "/produtos/lotes-sku/";

const BASE_FILTROS_CABECALHO = {
    id: { value: null, matchMode: "equals" },
    lote_serial: { value: null, matchMode: "equals" },
    sku__codigo__icontains: { value: null, matchMode: "equals" },
    sku__descricao_reduzida__unaccent__icontains: { value: null, matchMode: "equals" },
    saldo: { value: null, matchMode: "equals" },
    fabricacao: { value: null, matchMode: "equals" },
    vencimento: { value: null, matchMode: "equals" },
};

const BASE_COLUMS = [
    {
        field: "id",
        header: "Seq",
        style: { width: "8%" },
        filter: true,
        filterElement: CodigoFiltroTemplate,
    },
    {
        field: "lote_serial",
        header: "Lote / Serial",
        style: { width: "8%" },
        filter: true,
        filterElement: TextoFiltroTemplate,
    },
    {
        field: "sku.codigo",
        header: "Código",
        style: { width: "8%" },
        filter: true,
        filterElement: TextoFiltroTemplate,
        filterField: "sku__codigo__icontains",
    },
    {
        field: "sku.descricao_reduzida",
        header: "Produto / Mercadoria",
        filter: true,
        filterElement: TextoFiltroTemplate,
        filterField: "sku__descricao_reduzida__unaccent__icontains",
    },
    {
        field: "saldo",
        header: "Saldo",
        decimal: true,
        align: "center",
        style: { width: "10%" },
        filter: true,
        filterElement: QuantidadeFiltroTemplate,
    },
    {
        field: "fabricacao",
        header: "Fabricação",
        align: "center",
        dateFormat: "dd/MM/yyyy",
        style: { width: "8%" },
        filter: true,
        filterElement: DateFiltroTemplate,
    },
    {
        field: "vencimento",
        header: "Vencimento",
        align: "center",
        dateFormat: "dd/MM/yyyy",
        style: { width: "8%" },
        filter: true,
        filterElement: DateFiltroTemplate,
    },
];

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    align: "center",
    style: { width: "10%" },
};

const rowClassName = ({ bloqueado_vender }) => {
    return { "mako-table-lotes-bloqueadovenda": bloqueado_vender };
};

export const ListagemLotes = ({
    titulo = "Lotes de produtos",
    filtros = {},
    listagemRef,
    actions,
    aposPesquisar,
    configTabela = {},
    painelEsquerdo,
}) => {
    const colunas = useMemo(() => {
        if (!actions) return BASE_COLUMS;
        const actionColum = {
            ...BASE_COLUMN_ACTIONS,
            action: (e) => actions(e),
        };
        return [...BASE_COLUMS, actionColum];
    }, [actions]);

    const url = useMemo(() => {
        let query = { ordering: "-id" };
        if (typeof filtros === "object") query = { ...query, ...filtros };
        query = gerarFiltrosUrl(query);
        return `${BASE_URL_MOVIMENTACAO}?${query}`;
    }, [filtros]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo={titulo}
            aposPesquisar={aposPesquisar}
            colunas={colunas}
            urlPesquisa={url}
            painelEsquerdo={painelEsquerdo}
            configTabela={{
                paginator: true,
                lazy: true,
                rowClassName,
                ...configTabela,
            }}
            filtros={BASE_FILTROS_CABECALHO}
        />
    );
};
