import React, { useCallback, useMemo, useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useRelatorio from "@/hooks/useRelatorio";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_FINANCEIRO_EXTRATOCOMISSAOANALITiCO } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

const BASE_URL_COMISSIONADOS = "/vendas/comissionados-venda/";
const MENSAGEM_REQUIRED_PADRAO = "Informe pelo menos um dos campo: 'empresa', 'apuraçao' ou 'comissionado'";

export const RelatorioExtratoComissao = () => {
    const [dataInicial, setDataInicial] = useState(null);
    const [dataFinal, setDataFinal] = useState(null);
    const [urlComissionados, setUrlComissionados] = useState(BASE_URL_COMISSIONADOS);

    const { solicitarRelatorio } = useRelatorio();
    const [formatarDocumento] = useFormatCNPJCPF();

    const empresaSelecionada = useRef();
    const formRef = useRef();

    const itemTemplateApuracao = (e) => {
        return `${e.id} - ${e.tipo_apuracao.descricao} - ${dataToStr(e.data_inicial, "dd/MM/yyyy")} a ${dataToStr(
            e.data_final,
            "dd/MM/yyyy"
        )}`;
    };

    const valueTemplateApuracao = (e) => {
        if (e === null) return "Selecione uma apuração";
        return `${e.id} - ${e.tipo_apuracao.descricao} - ${dataToStr(e.data_inicial, "dd/MM/yyyy")} a ${dataToStr(
            e.data_final,
            "dd/MM/yyyy"
        )}`;
    };

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["apuracao_comissao__data_inicial", "apuracao_comissao__data_final"],
            label: "Período emissão",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "apuracao_comissao",
            label: "Lote",
            formatter: itemTemplateApuracao,
        },
        {
            key: "comissionado",
            label: "Comissionado",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                empresa:
                    !values.apuracao_comissao && !values.comissionado
                        ? Yup.number().required(MENSAGEM_REQUIRED_PADRAO).typeError(MENSAGEM_REQUIRED_PADRAO)
                        : Yup.number().nullable().typeError("Informe uma 'empresa' válida"),
                apuracao_comissao:
                    !values.empresa && !values.comissionado
                        ? Yup.object().required(MENSAGEM_REQUIRED_PADRAO).typeError(MENSAGEM_REQUIRED_PADRAO)
                        : Yup.object().nullable().default(null).typeError("Seleciona um 'apuração' válido."),
                apuracao_comissao__data_inicial: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Selecione uma 'data inicial' válida."),
                apuracao_comissao__data_final: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Selecione uma 'data final' válida."),
                comissionado:
                    !values.empresa && !values.apuracao_comissao
                        ? Yup.object().required(MENSAGEM_REQUIRED_PADRAO).typeError(MENSAGEM_REQUIRED_PADRAO)
                        : Yup.object().nullable().default(null).typeError("Seleciona um 'comissionado' válido."),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);
            if (dadosValidados.comissionado) dadosValidados.comissionado = dadosValidados.comissionado.id;
            if (dadosValidados.apuracao_comissao)
                dadosValidados.apuracao_comissao = dadosValidados.apuracao_comissao.id;
            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            solicitarRelatorio({
                chave: RELATORIO_FINANCEIRO_EXTRATOCOMISSAOANALITiCO,
                emails,
                filtros,
                versao,
                filtros_template: filtrosTemplateAplicados,
                corpo_email,
                enviar_fila: background,
            });
            limparFormulario();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formRef.current?.setStatus(errorMessages);
            }
        }
    }

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const submit = (e) => {
        formRef.current?.handleSubmit(e);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    const urlApuracao = useMemo(() => {
        const url = "/vendas/apuracao-comissao-venda/";
        if (!dataInicial || !dataFinal) return url;
        return `${url}?data_final__lte=${dataToStr(dataFinal, "dd/MM/yyyy")}&data_inicial__gte=${dataToStr(
            dataInicial,
            "dd/MM/yyyy"
        )}`;
    }, [dataInicial, dataFinal]);

    const onChangeData = (e) => {
        const tipo = e.target.name.split("__")[1];
        tipo === "data_inicial" ? setDataInicial(e.value) : setDataFinal(e.value);
        setFieldValue("apuracao_comissao", null);
    };

    const autoCompletePerfilTemplate = (item) => {
        return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
    };

    const watchValues = useCallback((data = {}) => {
        const {
            apuracao_comissao__data_inicial = null,
            apuracao_comissao__data_final = null,
            empresa = null,
            apuracao_comissao = null,
        } = data;
        let filtros = [];
        if (apuracao_comissao__data_inicial && apuracao_comissao__data_final) {
            filtros.push(
                `apuracao_comissao__data_inicial__gte=${dataToStr(apuracao_comissao__data_inicial, "dd/MM/yyyy")}`
            );
            filtros.push(
                `apuracao_comissao__data_final__lte=${dataToStr(apuracao_comissao__data_final, "dd/MM/yyyy")}`
            );
        }
        if (empresa) filtros.push(`venda__empresa=${empresa}`);
        if (apuracao_comissao) filtros.push(`apuracao_comissao=${apuracao_comissao?.id}`);
        setUrlComissionados(`${BASE_URL_COMISSIONADOS}?${filtros.join("&")}`);
    }, []);

    return (
        <R.Wrapper titulo={"Comissão analítica"}>
            <MakoFormGerador
                ref={formRef}
                watchValues={watchValues}
                formikProps={{
                    initialValues: {
                        apuracao_comissao__data_inicial: null,
                        apuracao_comissao__data_final: null,
                        empresa: null,
                        apuracao_comissao: null,
                        comissionado: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa ",
                        inputId: "empresa",
                        inputName: "empresa",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            placeholder: "Selecione uma empresa",
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Periodo final apuração",
                        inputId: "apuracao_comissao__data_inicial",
                        inputName: "apuracao_comissao__data_inicial",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                            getOnChange: onChangeData,
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "apuracao_comissao__data_final",
                        inputName: "apuracao_comissao__data_final",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                            getOnChange: onChangeData,
                        },
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "N° apuração",
                        inputId: "apuracao_comissao",
                        inputName: "apuracao_comissao",
                        component: Dropdown,
                        fieldSize: 2,
                        componentProps: {
                            url: urlApuracao,
                            optionLabel: "regra_apuracao.descricao",
                            valueTemplate: valueTemplateApuracao,
                            itemTemplate: itemTemplateApuracao,
                        },
                    },
                    {
                        label: "Comissionado",
                        inputId: "comissionado",
                        inputName: "comissionado",
                        component: Dropdown,
                        componentProps: {
                            url: urlComissionados,
                            optionLabel: "nome",
                            itemTemplate: autoCompletePerfilTemplate,
                            showClear: true,
                        },
                        fieldSize: 3,
                    },
                ]}
            >
                <R.Buttons.Wrapper>
                    <R.Buttons.GerarPdf
                        chave={RELATORIO_FINANCEIRO_EXTRATOCOMISSAOANALITiCO}
                        setFieldValue={setFieldValue}
                        handleSubmit={submit}
                    />
                    <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                    <R.Buttons.Limpar onClick={limparFormulario} />
                </R.Buttons.Wrapper>
            </MakoFormGerador>
        </R.Wrapper>
    );
};
