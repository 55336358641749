import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Dropdown } from "@/components/Dropdown";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

export const ClonarTabelaForm = (props) => {
    const [tabela, setTabela] = useState(null);
    const [novoNome, setNovoNome] = useState("");
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpGet, httpPost } = useHttp();
    const { showSuccess, showError } = useToast();

    async function clonarTabela() {
        try {
            let precoItens = [];
            let empresasVinculadas = [];
            let data;
            const handlers = {
                200: ({ data: d }) => {
                    d?.precostabelapreco_set?.forEach((preco) => {
                        precoItens.push({
                            sku: preco.sku.id,
                            preco_calculado: preco.preco_calculado,
                            preco_arredondado: preco.preco_arredondado,
                            plano_recebimento: preco.plano_recebimento.id,
                        });
                    });
                    d?.empresastabelapreco_set?.forEach((empresa) => {
                        empresasVinculadas.push({ unidade_venda: empresa.unidade_venda.id });
                    });
                    data = d;
                },
            };
            showLoading();
            await httpGet({ url: `/produtos/tabelas-preco/${tabela}/` }, handlers);
            hideLoading();
            const body = {
                ...data,
                nome: novoNome,
                precostabelapreco_set: { create: precoItens },
                empresastabelapreco_set: { create: empresasVinculadas },
            };
            const handlersPost = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Tabela clonada com sucesso!",
                        life: 2500,
                    });
                    props.callBack();
                },
            };
            showLoading();
            await httpPost({ url: "/produtos/tabelas-preco/", body }, handlersPost);
            hideLoading();
        } catch (error) {
            showError();
        }
    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form>
                    <Label
                        htmlFor="natureza_operacao"
                        label="Selecione uma tabela para realizar a clonagem"
                        obrigatorio
                    />
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <Dropdown
                                id="tabela"
                                name="tabela"
                                url="/produtos/tabelas-preco?query={id,nome}"
                                optionValue="id"
                                optionLabel="nome"
                                filter
                                filterBy="nome"
                                showClear
                                placeholder="Selecione uma tabela"
                                value={tabela}
                                onChange={(e) => setTabela(e.target.value)}
                            />
                        </div>
                    </div>
                    {tabela && (
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <Label htmlFor="nome" label="Nome tabela" obrigatorio />
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={novoNome}
                                    onChange={(e) => setNovoNome(e.target.value)}
                                    autoComplete="off"
                                    autoFocus
                                />
                            </div>
                        </div>
                    )}
                    <MakoActionsButtons className="p-mt-5 p-jc-center">
                        <Button
                            label="Clonar tabela"
                            type="button"
                            icon="pi pi-align-justify"
                            className="p-button-info"
                            disabled={!(tabela && novoNome)}
                            onClick={() => clonarTabela()}
                            loading={loading}
                        />
                    </MakoActionsButtons>
                </form>
            </div>
        </div>
    );
};
