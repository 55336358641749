import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoButton as Button } from "@/components/MakoButton";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { PageBase } from "@/components/PageBase";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

const url = "/produtos/vinculo-fornecedor-representante/";

export const VinculoRepresentanteFornecedorPage = () => {
    const listagemRef = useRef(null);
    const [formatarDocumento] = useFormatCNPJCPF();

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_REPRESENTANTEFORNECEDOR_VINCULO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar vinculo"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/gestao/cadastros/vinculo-representante/form",
                        state: { vinculo: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_REPRESENTANTEFORNECEDOR_VINCULO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>{"Deseja realmente excluir o vinculo?"}</span>}
                    msgToastErroExclusao="O vínculo não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_REPRESENTANTEFORNECEDOR_VINCULO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/gestao/cadastros/vinculo-representante/form"}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            filter: true,
            filterField: "id",
            style: { width: "6%" },
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "representante.nome",
            header: "Representante",
            style: { width: "20%" },
            filter: true,
            filterField: "representante__nome",
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "representante.identificacao",
            header: "CPF/CNPJ",
            filter: true,
            filterField: "representante__identificacao",
            filterElement: TextoFiltroTemplate,
            action: ({ representante }) => formatarDocumento(representante.identificacao),
        },
        {
            field: "fornecedor.nome",
            header: "Fornecedor",
            style: { width: "20%" },
            filter: true,
            filterField: "fornecedor__nome",
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "fornecedor.identificacao",
            header: "CPF/CNPJ",
            filter: true,
            filterField: "fornecedor__identificacao",
            filterElement: TextoFiltroTemplate,
            action: ({ fornecedor }) => formatarDocumento(fornecedor.identificacao),
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "8%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Vínculos representante a fornecedor"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    representante__nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    representante__identificacao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    fornecedor__nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    fornecedor__identificacao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
