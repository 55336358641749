import React, { useCallback, useEffect, useRef, useState } from "react";
import { Chart } from "primereact/chart";
import { InputSwitch } from "primereact/inputswitch";
import { Skeleton } from "./skeleton";
import useClearRefs from "@/hooks/useClearRefs";

const MakoCardLineChart = ({
    title,
    tabs,
    ordersChart,
    ordersChartOptions,
    idChart = 1,
    menuItems,
    showAll,
    loading = false,
}) => {
    const [dataOptions, setDataOptions] = useState({
        labels: [],
        datasets: [],
    });
    const [checked, setChecked] = useState(false);

    const chartRef = useRef(null);
    useClearRefs(chartRef);

    const refreshDataset = (event) => {
        chartRef.current.refresh(event);
    };

    const changeDataset = useCallback(
        (event) => {
            setDataOptions((prev) => {
                prev.datasets[0].data =
                    ordersChart.datasets[parseInt(event.currentTarget.getAttribute("data-index"))].data;
                prev.datasets[0].label = event.currentTarget.getAttribute("data-label");
                prev.datasets[0].borderColor = event.currentTarget.getAttribute("data-stroke");
                prev.datasets[0].backgroundColor = event.currentTarget.getAttribute("data-fill");
                return prev;
            });
        },
        [ordersChart]
    );

    const onOrderTabClick = (event) => {
        changeDataset(event);
        refreshDataset(event);
    };

    const handleCheck = (value) => {
        setDataOptions((prev) => {
            prev.datasets = value ? ordersChart.datasets : [ordersChart.datasets[ordersChart.indexDefault]];
            return { ...prev };
        });
        setChecked(() => value);
    };

    useEffect(
        () =>
            setDataOptions({
                labels: ordersChart.labels,
                datasets: [ordersChart.datasets[ordersChart.indexDefault]],
            }),
        [ordersChart]
    );

    return (
        <div className="card no-gutter orders">
            <div className="card-header">
                <h4>{title}</h4>
            </div>

            {!loading ? (
                <div className="p-grid">
                    <div className="p-col-12">
                        <div id="order-tabs-container" className="p-grid order-tabs">
                            {tabs.map((tab, index) => (
                                <div
                                    key={tab.data_label}
                                    className="order-tab order-tab-new p-col-6 p-md-3"
                                    onClick={onOrderTabClick}
                                    data-label={tab.data_label}
                                    data-index={index}
                                    data-stroke={tab.data_stroke}
                                    data-fill={tab.data_fill}
                                    style={{ borderBottom: `3px solid ${tab.data_stroke}` }}
                                >
                                    <i className={tab.icon}></i>
                                    <span className="order-label">{tab.text}</span>
                                    {/* <i className="stat-detail-icon icon-arrow-right-circle"></i> */}
                                    {/* <img src={tab.src_graph} alt="order" /> */}
                                </div>
                            ))}
                        </div>
                    </div>
                    {showAll && (
                        <div className="p-col-12" style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                            <span>Mostrar todos</span>
                            <InputSwitch checked={checked} onChange={(e) => handleCheck(e.value)} />
                        </div>
                    )}
                    <div className="p-col-12">
                        <div className="overview-chart">
                            <Chart
                                ref={chartRef}
                                type="line"
                                data={dataOptions}
                                options={ordersChartOptions}
                                id={idChart}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <Skeleton />
            )}
        </div>
    );
};

export default MakoCardLineChart;
