import axios from "./axios";

/**
 * @deprecated use httpGet do hook useHttp em vez disso
 */
export async function axiosGet(url, options, api = axios) {
    try {
        const { data, status, headers } = await api.get(url, options);

        return { data, status, headers };
    } catch (error) {
        let status = 500;
        let data = {
            msg: "Não conseguimos processar a requisição.",
        };

        if (error.response) {
            status = error.response.status;
            data = error.response.data;
        }

        return { data, status };
    }
}

/**
 * @deprecated use httpPost do hook useHttp em vez disso
 */
export async function axiosPost(url, body, options, api = axios) {
    try {
        const { data, status, headers } = await api.post(url, body, options);

        return { data, status, headers };
    } catch (error) {
        let status = 500;
        let data = {
            msg: "Não conseguimos processar a requisição.",
        };

        if (error.response) {
            status = error.response.status;
            data = error.response.data;
        }

        return { data, status };
    }
}

/**
 * @deprecated use httpPut do hook useHttp em vez disso
 */
export async function axiosPut(url, body, api = axios) {
    try {
        const { data, status } = await api.put(url, body);

        return { data, status };
    } catch (error) {
        let status = 500;
        let data = {
            msg: "Não conseguimos processar a requisição.",
        };

        if (error.response) {
            status = error.response.status;
            data = error.response.data;
        }

        return { data, status };
    }
}

/**
 * @deprecated use httpPatch do hook useHttp em vez disso
 */
export async function axiosPatch(url, body, api = axios) {
    try {
        const { data, status } = await api.patch(url, body);

        return { data, status };
    } catch (error) {
        let status = 500;
        let data = {
            msg: "Não conseguimos processar a requisição.",
        };

        if (error.response) {
            status = error.response.status;
            data = error.response.data;
        }

        return { data, status };
    }
}

/**
 * @deprecated use httpDelete do hook useHttp em vez disso
 */
export async function axiosDelete(url, api = axios) {
    try {
        const { data, status } = await api.delete(url);

        return { data, status };
    } catch (error) {
        let status = 500;
        let data = {
            msg: "Não conseguimos processar a requisição.",
        };

        if (error.response) {
            status = error.response.status;
            data = error.response.data;
        }

        return { data, status };
    }
}
