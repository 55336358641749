import React from "react";

import { useHistory, useLocation } from "react-router-dom";

import { PageBase } from "@/components/PageBase";
import { Efetivar } from "../Recebimentos/features";

const Form = Efetivar.Efetivar;

const URL_RECEBIMENTOS = "/financeiro/financeiro/recebimentos";

export const EfetivarRecebimentoPage = () => {
    const { state = [] } = useLocation();
    const history = useHistory();

    const cancelCallback = () => {
        history.push(URL_RECEBIMENTOS);
    };

    return (
        <PageBase>
            <h5>Recebendo parcelas</h5>
            <Form recebimentos={state} cancelCallback={cancelCallback} />
        </PageBase>
    );
};
