import React, { createContext, useCallback, useState } from "react";
import useLoading from "@/hooks/useLoading";
import useHttp from "@/hooks/useHttp";

const NotaServicoContext = createContext({});

export const NotaServicoProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [servico, setServico] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const { showLoading, hideLoading } = useLoading();
    const { httpGet, httpPost, httpPatch } = useHttp();

    const handleDadosGerais = useCallback(
        async (values) => {
            if (!values.id) {
                let status = 400,
                    json = {};

                const handlers = {
                    201: ({ data }) => {
                        const { empresa, natureza_operacao, ...rest } = data;

                        setSubmit(true);
                        setDadosBasicos({
                            ...rest,
                            empresa: empresa.id,
                            identificacao: empresa.identificacao,
                            natureza_operacao: {
                                ...natureza_operacao,
                                label: `${natureza_operacao.codigo} - ${natureza_operacao.descricao}`,
                            },
                        });
                        json = data;
                    },
                    400: ({ err }) => {
                        status = 400;
                        json = err;
                    },
                };

                await httpPost(
                    {
                        url: "/servicos/notas-fiscais-servicos/",
                        body: {
                            ...values,
                            natureza_operacao: values.natureza_operacao.id,
                            municipio_prestador: values.municipio_prestador.id,
                            municipio_tomador: values.municipio_tomador.id,
                            tomador: values.tomador.id,
                            endereco_prestador: values.endereco_prestador.id,
                            endereco_tomador: values.endereco_tomador.id,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else {
                let diffProd = {};
                Object.entries(values).forEach(([k, v]) => {
                    if (v !== dadosBasicos[k]) {
                        diffProd[k] = v;
                    }
                });

                if (diffProd.natureza_operacao)
                    diffProd = {
                        ...diffProd,
                        natureza_operacao: diffProd.natureza_operacao?.id,
                    };
                if (diffProd.tomador?.id)
                    diffProd = {
                        ...diffProd,
                        tomador: diffProd.tomador?.id,
                    };
                if (diffProd.empresa?.id)
                    diffProd = {
                        ...diffProd,
                        empresa: diffProd.empresa?.id,
                    };
                if (diffProd.municipio_prestador?.id)
                    diffProd = {
                        ...diffProd,
                        municipio_prestador: diffProd.municipio_prestador?.id,
                    };
                if (diffProd.municipio_tomador?.id)
                    diffProd = {
                        ...diffProd,
                        municipio_tomador: diffProd.municipio_tomador?.id,
                    };
                if (diffProd.endereco_prestador?.id)
                    diffProd = {
                        ...diffProd,
                        endereco_prestador: diffProd.endereco_prestador?.id,
                    };
                if (diffProd.endereco_tomador?.id)
                    diffProd = {
                        ...diffProd,
                        endereco_tomador: diffProd.endereco_tomador?.id,
                    };

                if (Object.keys(diffProd).length > 0) {
                    let status = 400,
                        json = {};

                    const handlers = {
                        200: ({ data }) => {
                            const { empresa, natureza_operacao, ...rest } = data;
                            status = 200;
                            json = values;

                            setDadosBasicos({
                                ...rest,
                                empresa: empresa.id,
                                identificacao: empresa.identificacao,
                                natureza_operacao: {
                                    ...natureza_operacao,
                                    label: `${natureza_operacao.codigo} - ${natureza_operacao.descricao}`,
                                },
                            });
                        },
                        400: ({ err }) => {
                            status = 400;
                            json = err;
                        },
                    };

                    await httpPatch(
                        {
                            url: `/servicos/notas-fiscais-servicos/${values.id}/`,
                            body: diffProd,
                        },
                        handlers
                    );

                    return { status, data: json };
                }
            }
        },
        [dadosBasicos, httpPatch, httpPost]
    );

    const handleServicos = useCallback(
        async (values) => {
            let status = 400,
                json = {};

            if (!values?.id) {
                const handlers = {
                    201: async ({ data }) => {
                        status = 201;
                        json = data;
                        await httpPatch(
                            {
                                url: `/servicos/notas-fiscais-servicos/${dadosBasicos.id}/`,
                                body: { servico: data.id },
                            },
                            {}
                        );
                        setServico(data);
                    },
                    400: ({ err }) => {
                        status = 400;
                        json = err;
                    },
                };

                await httpPost(
                    {
                        url: "/servicos/servico-nota-fiscal-servico/",
                        body: {
                            ...values,
                            servico: values.servico.id,
                            municipio_prestacao: values.municipio_prestacao.id,
                            endereco_prestacao: values.endereco_prestacao.id,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else {
                let diffProd = {};
                Object.entries(values).forEach(([k, v]) => {
                    if (v !== servico[k]) {
                        diffProd[k] = v;
                    }
                });

                if (diffProd.servico?.id)
                    diffProd = {
                        ...diffProd,
                        servico: diffProd.servico?.id,
                    };
                if (diffProd.municipio_prestacao?.id)
                    diffProd = {
                        ...diffProd,
                        municipio_prestacao: diffProd.municipio_prestacao?.id,
                    };
                if (diffProd.endereco_prestacao?.id)
                    diffProd = {
                        ...diffProd,
                        endereco_prestacao: diffProd.endereco_prestacao?.id,
                    };

                if (Object.keys(diffProd).length > 0) {
                    let status = 400,
                        json = {};

                    const handlers = {
                        200: ({ data }) => {
                            status = 200;
                            json = values;
                            setServico({ ...data, valor_total: parseFloat(data.valor_total) });
                        },
                        400: ({ err }) => {
                            status = 400;
                            json = err;
                        },
                    };

                    await httpPatch(
                        {
                            url: `/servicos/servico-nota-fiscal-servico/${values.id}/`,
                            body: diffProd,
                        },
                        handlers
                    );

                    return { status, data: json };
                }
            }
        },
        [dadosBasicos?.id, httpPatch, httpPost, servico]
    );

    const handlePreencherServico = useCallback(
        async (idServico) => {
            const handlers = {
                200: ({ data }) => {
                    const { natureza_operacao, e, empresa, servico, ...rest } = data;
                    setDadosBasicos({
                        ...rest,
                        empresa: empresa.id,
                        identificacao: empresa.identificacao,
                        natureza_operacao: {
                            ...natureza_operacao,
                            label: `${natureza_operacao.codigo} - ${natureza_operacao.descricao}`,
                        },
                    });
                    setServico(servico);
                    setSubmit(true);
                },
            };

            showLoading();
            await httpGet({ url: `/servicos/notas-fiscais-servicos/${idServico}/` }, handlers);
            hideLoading();
        },
        [showLoading, hideLoading, httpGet]
    );

    return (
        <NotaServicoContext.Provider
            value={{
                submit,
                dadosBasicos,
                servico,
                activeTab,
                setActiveTab,
                setSubmit,
                handleDadosGerais,
                handleServicos,
                handlePreencherServico,
            }}
        >
            {children}
        </NotaServicoContext.Provider>
    );
};

export default NotaServicoContext;
