import React, { createContext, useCallback, useState } from "react";
import useLoading from "@/hooks/useLoading";
import { formatarCasasDecimais } from "@/assets/util/util";
import useHttp from "@/hooks/useHttp";

const TrocaContext = createContext({});

export const TrocaProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { httpGet, httpPatch, httpPost } = useHttp();

    const handleDadosTroca = useCallback(
        async (values) => {
            let status = 400,
                json = {};
            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        status = 201;
                        json = data;
                        setDadosBasicos(data);
                    },
                };

                await httpPost(
                    {
                        url: "/vendas/troca/",
                        body: {
                            ...values,
                            venda: values.venda.id,
                            cliente: values.cliente.id,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else {
                let diffProd = {};
                Object.entries(values).forEach(([k, v]) => {
                    if (v !== dadosBasicos[k]) {
                        diffProd[k] = v;
                    }
                });
                if (Object.keys(diffProd).length > 0) {
                    const handlers = {
                        200: ({ data }) => {
                            status = 200;
                            json = data;
                            setDadosBasicos(data);
                        },
                    };

                    await httpPatch(
                        {
                            url: `/vendas/troca/${values.id}/`,
                            body: diffProd,
                        },
                        handlers
                    );

                    return { status, data: json };
                }
                return { status: 200, data: values };
            }
        },
        [dadosBasicos, setDadosBasicos, httpPatch, httpPost]
    );

    const handleItemTrocaDevolucao = useCallback(
        async (values) => {
            let status = 400,
                json = {};
            if (!values.id) {
                const { sku, ...rest } = values;

                const handlers = {
                    201: ({ data }) => {
                        status = 201;
                        json = data;
                    },
                };

                await httpPost(
                    {
                        url: "/vendas/itens-troca-devolucao/",
                        body: {
                            ...rest,
                            item_venda: sku.id,
                            sku: sku.sku.id,
                            sku_movimenta_estoque: sku.sku_movimenta_estoque.id,
                            unidade_medida: sku.unidade_venda.id,
                            troca: dadosBasicos.id,
                            encargos: formatarCasasDecimais(rest.encargos),
                            subtotal: formatarCasasDecimais(rest.subtotal),
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else {
                const handlers = {
                    200: ({ data }) => {
                        status = 200;
                        json = data;
                    },
                };

                await httpPatch(
                    {
                        url: `/vendas/itens-troca-devolucao/${values.id}/`,
                        body: values,
                    },
                    handlers
                );

                return { status, data: json };
            }
        },
        [dadosBasicos?.id, httpPost, httpPatch]
    );

    const handleItemSaida = useCallback(
        async (values) => {
            let status = 400,
                json = {};

            if (!values.id) {
                const { sku, ...rest } = values;

                const handlers = {
                    201: ({ data }) => {
                        status = 201;
                        json = data;
                    },
                };

                await httpPost(
                    {
                        url: "/vendas/itens-troca-saida/",
                        body: {
                            ...rest,
                            sku: sku.id,
                            troca: dadosBasicos.id,
                            encargos: formatarCasasDecimais(rest.encargos),
                            subtotal: formatarCasasDecimais(rest.subtotal),
                            desconto: formatarCasasDecimais(rest.desconto),
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else {
                const handlers = {
                    200: ({ data }) => {
                        status = 200;
                        json = data;
                    },
                };

                await httpPatch(
                    {
                        url: `/vendas/itens-troca-saida/${values.id}/`,
                        body: values,
                    },
                    handlers
                );

                return { status, data: json };
            }
        },
        [dadosBasicos?.id, httpPatch, httpPost]
    );

    const handlePreencherTroca = useCallback(
        async (idTroca) => {
            const handlers = {
                200: ({ data }) => {
                    setDadosBasicos(data);
                    setSubmit(true);
                },
            };

            showLoading();
            await httpGet({ url: `/vendas/troca/${idTroca}/` }, handlers);
            hideLoading();
        },
        [showLoading, hideLoading, httpGet]
    );

    return (
        <TrocaContext.Provider
            value={{
                submit,
                dadosBasicos,
                setSubmit,
                handleDadosTroca,
                handleItemTrocaDevolucao,
                handleItemSaida,
                handlePreencherTroca,
            }}
        >
            {children}
        </TrocaContext.Provider>
    );
};

export default TrocaContext;
