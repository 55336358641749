import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { Dialog } from "primereact/dialog";

import { Form } from "../../features";

const Componente = ({ successCallback = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);

    const success = useCallback(() => {
        setVisible(false);
        successCallback();
    }, [successCallback]);

    const show = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => {
        return {
            show,
            hide: () => setVisible(false),
        };
    });

    return (
        <Dialog
            visible={visible}
            header={"Definindo parâmetro"}
            onHide={() => setVisible(false)}
            style={{ width: "80vw" }}
        >
            <Form onSuccessCallback={success} />
        </Dialog>
    );
};

export const Novo = forwardRef(Componente);
