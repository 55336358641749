import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { RELATORIOS_PRODUTOS_PRECOPORFORNECEDOR } from "@/assets/constants/relatorios";
import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";
import MakoListagem from "@/components/MakoListagem";
import { ExportCSV } from "@/components/MakoBaseRelatorio/Buttons/exportCSV";
import { gerarFileName } from "@/assets/util/util";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoInputFornecedor } from "@/components/MakoInputs";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { Dropdown } from "@/components/Dropdown";

const BASE_URL = "/relatorios/preco-por-fornecedor/";

const FILE_NAME = gerarFileName("Produtos Por Fornecedor");

const FILTROS_VIEWSET = {
    sku: "sku",
    categoria: "categorias",
    ordenacao: "ordenacao",
    fornecedor: "fornecedor",
};

const OPTIONS_ORDENACAO = [
    { label: "Código", value: "codigo" },
    { label: "Descrição", value: "descricao" },
];

export const RelatorioPrecoProdutosFornecedor = () => {
    const formRef = useRef();
    const categoriaRef = useRef();
    const { solicitarRelatorio } = useRelatorio();
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const [formatarDocumento] = useFormatCNPJCPF();

    const listagemRef = useRef();
    const botaoCsv = useRef();

    useClearRefs(formRef);

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object().nullable().typeError("Você precisa buscar e selecionar um produto."),
                categoria: Yup.array().nullable().typeError("Informe 'categorias' válidas"),
                fornecedor: Yup.object().nullable().default(null).typeError("Informe um 'fornecedor' válida."),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            const selecionados = categoriaRef.current;

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: selecionados,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.sku) dadosValidados.sku = dadosValidados.sku.id;
            if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
            if (selecionados) dadosValidados.categoria = selecionados.map((c) => c.id);

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIOS_PRODUTOS_PRECOPORFORNECEDOR,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "" && v !== undefined) {
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        const selecionados = categoriaRef.current;
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
        if (selecionados) dadosValidados.categoria = selecionados.map((c) => c.id);
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIOS_PRODUTOS_PRECOPORFORNECEDOR}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const filtrosTemplate = [
        {
            key: ["data_entrega_start", "data_entrega_end"],
            label: "Intervalo de data de entrega",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "sku",
            label: "Produto",
            type: TIPOS_FILTROS_TEMPLATE.SKU,
        },
        {
            key: "categoria",
            label: "Categoria",
            path: "nome",
            type: TIPOS_FILTROS_TEMPLATE.MULTISELECT,
        },
        {
            key: "fornecedor",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "ordenacao",
            label: "Ordenação",
            options: OPTIONS_ORDENACAO,
            optionKey: "value",
            optionLabel: "label",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    const colunas = [
        { field: "codigo", header: "Código" },
        { field: "descricao", header: "Descrição" },
        { field: "unidade", header: "Unidade" },
        {
            field: "fornecedor",
            header: "Fornecedor",
            action: (e) => {
                return e.nome_fornecedor + " - " + formatarDocumento(e.identificacao_fornecedor);
            },
        },
        { field: "preco_compra", header: "Preço de compra", money: true },
        { field: "media_entrega", header: "Média entrega", align: "center" },
        { field: "prioridade", header: "Prioridade" },
        { field: "unidade_compra", header: "Unidade de compra", align: "center" },
    ];

    const getCategoriaCompleta = (e) => {
        categoriaRef.current = e;
    };

    return (
        <>
            <R.Wrapper titulo={"de preço por fornecedor"}>
                <MakoFormGerador
                    ref={formRef}
                    formikProps={{
                        initialValues: {
                            data_entrega_start: null,
                            data_entrega_end: null,
                            sku: null,
                            categorias: [],
                            ordenacao: "descricao",
                        },
                        onSubmit: handleSubmit,
                    }}
                    camposFormularios={[
                        {
                            label: "Produto",
                            inputId: "sku",
                            inputName: "sku",
                            required: false,
                            component: MakoBuscaSkuPersonalizada,
                            componentEspecial: COMPONENTES_ESPECIAIS.SKU,
                            fieldSize: 12,
                        },
                        {
                            label: "Fornecedor",
                            inputId: "fornecedor",
                            inputName: "fornecedor",
                            component: MakoInputFornecedor,
                            fieldSize: 3,
                        },
                        {
                            label: "Categoria",
                            inputId: "categoria",
                            inputName: "categoria",
                            component: MakoDropdownCategoriasHierarquicas,
                            componentProps: {
                                getCategoriaCompleta: getCategoriaCompleta,
                                showClear: true,
                                selectionMode: "checkbox",
                                metaKeySelection: false,
                            },
                            fieldSize: 4,
                        },
                        {
                            label: "Ordenação",
                            inputId: "ordenacao",
                            inputName: "ordenacao",
                            component: Dropdown,
                            componentProps: {
                                placeholder: "Selecione",
                                options: OPTIONS_ORDENACAO,
                                optionLabel: "label",
                                optionValue: "value",
                                showClear: false,
                            },
                            fieldSize: 3,
                        },
                    ]}
                >
                    <R.Buttons.Visualizar onClick={handleVisualizar} />
                    <R.Buttons.GerarPdf
                        chave={RELATORIOS_PRODUTOS_PRECOPORFORNECEDOR}
                        setFieldValue={setFieldValue}
                        handleSubmit={submit}
                    />
                    <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                    <R.Buttons.Limpar onClick={limparFormulario} />
                </MakoFormGerador>
                <div className="p-mt-2">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        urlPesquisa={url}
                        msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                        botaoExportar={BotaoExportar}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            exportFilename: FILE_NAME,
                        }}
                    />
                </div>
            </R.Wrapper>
        </>
    );
};
