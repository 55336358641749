import React from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";

import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoCalendar } from "@/components/MakoCalendar";
import { PageBase } from "@/components/PageBase";
import { Label } from "@/components/Label";
import { TIPO_SERVICO_API } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";

const BASE_URL = "/configuracoes/api-servicos/";
const BASE_URL_VOLTAR = "/configuracoes/api-servicos";
const INITIAL_VALUES = {
    api_id: "",
    provedor: null,
    tipo_servico: null,
    valor_mensal: 0,
    descricao: "",
    data_inicio: null,
    parametros_acesso: "",
    valor_fixo: false,
    url_integracao: "",
    contrato_geral: false,
    ativo: true,
    cnpj_integrado: "",
    chave_acesso: "",
};

const autoCompleteProvedorTemplate = (provedor) => {
    provedor.label = `${provedor.identificacao} - ${provedor.nome}`;
    return <div>{`${provedor.identificacao} - ${provedor.nome}`}</div>;
};

export const ApiServicosForm = () => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionada } = useEmpresa();
    const { httpPatch, httpPost } = useHttp();
    const { state } = useLocation();

    const history = useHistory();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: state
            ? {
                  ...state.apiServico,
                  tipo_servico: state.apiServico.tipo_servico.id,
                  parametros_acesso: state.apiServico.parametros_acesso || "",
              }
            : INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            if (values.provedor?.id) values.provedor = values.provedor.id;
            const formSchema = Yup.object().shape({
                api_id: Yup.string().required("O campo 'identificação da API' é obrigatório."),
                provedor: Yup.number().required("O campo 'provedor' é obrigatório."),
                tipo_servico: Yup.string().required("O campo 'provedor' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                valor_mensal: Yup.number().required("O campo 'valor mensal' é obrigatório."),
                data_inicio: Yup.date().required("O campo 'data início' é obrigatório."),
                url_integracao: Yup.string().required("O campo 'url integração' é obrigatório."),
                cnpj_integrado: Yup.string()
                    .required("O campo 'cnpj de integração' é obrigatório.")
                    .max(20, "O máximo de caractéres é 20."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });

            const body = {
                ...values,
                data_inicio: dataToStr(values.data_inicio, "yyyy-MM-dd"),
            };

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "API de serviços cadastrada com sucesso!",
                            life: 1500,
                        });
                        history.push(BASE_URL_VOLTAR);
                    },
                };
                showLoading();
                await httpPost({ url: `${BASE_URL}`, body: { empresa: empresaSelecionada.id, ...body } }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "API de serviços alterada com sucesso!",
                            life: 1500,
                        });
                        history.push(BASE_URL_VOLTAR);
                    },
                    500: ({ data }) => {
                        if (data?.includes("violates foreign key constraint")) {
                            showError({
                                summary: "Erro",
                                detail: "O campo 'Identificação da API' não pode ser alterado pois está vinculado a outra informação no sistema!",
                                life: 3000,
                            });
                        } else showError();
                    },
                };
                showLoading();
                await httpPatch({ url: `${BASE_URL}${values.id}/`, body: body }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <PageBase>
            <h5>{!formik.values.id ? "Nova API de serviços" : "Manutenção de API de serviços"}</h5>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="api_id" label="Identificação da API" obrigatorio />
                        <InputText
                            id="api_id"
                            name="api_id"
                            value={formik.values.api_id}
                            onInput={formik.handleChange}
                            maxLength={30}
                            className={classNames({ "p-invalid": formik.errors.api_id })}
                            autoComplete="off"
                        />
                        {formik.errors.api_id && <small className="p-error">{formik.errors.api_id}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onInput={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="provedor" label="Provedor" obrigatorio />
                        <MakoAutoComplete
                            id="provedor"
                            name="provedor"
                            placeholder="Digite para buscar... (min 4 caracteres)"
                            minCaracteresBusca={4}
                            value={formik.values.provedor}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompleteProvedorTemplate}
                            field="nome"
                            urlSearch={`/pessoas/perfis?search=`}
                            className={classNames({ "p-invalid": formik.errors.provedor })}
                        />
                        {formik.errors.provedor && <small className="p-error">{formik.errors.provedor}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="tipo_servico" label="Tipo de serviço" obrigatorio />
                        <Dropdown
                            id="tipo_servico"
                            name="tipo_servico"
                            placeholder="Selecione um tipo de serviço"
                            options={TIPO_SERVICO_API}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.tipo_servico}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_servico })}
                        />
                        {formik.errors.tipo_servico && <small className="p-error">{formik.errors.tipo_servico}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="valor_mensal" label="Valor Mensal" />
                        <InputNumber
                            id="valor_mensal"
                            name="valor_mensal"
                            mode="currency"
                            minFractionDigits={2}
                            maxFractionDigits={4}
                            currency="BRL"
                            locale="pt-BR"
                            value={formik.values.valor_mensal}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_mensal })}
                        />
                        {formik.errors.valor_mensal && <small className="p-error">{formik.errors.valor_mensal}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_inicio" label="Data início" obrigatorio />
                        <MakoCalendar
                            id="data_inicio"
                            name="data_inicio"
                            valueCalendar={formik.values.data_inicio}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_inicio,
                            })}
                        />
                        {formik.errors.data_inicio && <small className="p-error">{formik.errors.data_inicio}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2">
                        <br></br>
                        <Checkbox
                            inputId="valor_fixo"
                            id="valor_fixo"
                            name="valor_fixo"
                            onChange={formik.handleChange}
                            checked={formik.values.valor_fixo}
                            value={formik.values.valor_fixo}
                            className="p-mt-5"
                        />
                        <Label htmlFor="valor_fixo" label="Valor fixo" className="p-mt-5" />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-2 p-ml-1">
                        <br></br>
                        <Checkbox
                            inputId="contrato_geral"
                            id="contrato_geral"
                            name="contrato_geral"
                            onChange={formik.handleChange}
                            checked={formik.values.contrato_geral}
                            value={formik.values.contrato_geral}
                            className="p-mt-5"
                        />
                        <Label htmlFor="contrato_geral" label="Contrato geral" className="p-mt-5" />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="url_integracao" label="Url Integração" obrigatorio />
                        <InputText
                            id="url_integracao"
                            name="url_integracao"
                            value={formik.values.url_integracao}
                            onInput={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.url_integracao })}
                            autoComplete="off"
                        />
                        {formik.errors.url_integracao && (
                            <small className="p-error">{formik.errors.url_integracao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="cnpj_integrado" label="Cnpj Integrado" obrigatorio />
                        <InputText
                            id="cnpj_integrado"
                            name="cnpj_integrado"
                            value={formik.values.cnpj_integrado}
                            onInput={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cnpj_integrado })}
                            autoComplete="off"
                        />
                        {formik.errors.cnpj_integrado && (
                            <small className="p-error">{formik.errors.cnpj_integrado}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-7">
                        <Label htmlFor="chave_acesso" label="Chave de acesso" />
                        <InputText
                            id="chave_acesso"
                            name="chave_acesso"
                            value={formik.values.chave_acesso}
                            onInput={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave_acesso })}
                            autoComplete="off"
                        />
                        {formik.errors.chave_acesso && <small className="p-error">{formik.errors.chave_acesso}</small>}
                    </div>
                </div>

                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-7">
                        <Label htmlFor="parametros_acesso" label="Parâmetros de acesso" />
                        <InputTextarea
                            id="parametros_acesso"
                            name="parametros_acesso"
                            value={formik.values.parametros_acesso}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.parametros_acesso })}
                            rows={30}
                            autoComplete="off"
                            autoResize
                        />
                        {formik.errors.parametros_acesso && (
                            <small className="p-error">{formik.errors.parametros_acesso}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-1 ">
                        <h5>
                            <Label htmlFor="status" label="Status" />
                        </h5>
                        <InputSwitch
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            disabled={formik.values.id ? false : true}
                            value={formik.values.ativo}
                            onChange={(e) => setFieldValue("ativo", e.value)}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        type="reset"
                        icon={MAKO_ICONS.CANCEL}
                        to={BASE_URL_VOLTAR}
                        className="p-button-danger"
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
