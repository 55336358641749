import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { TreeSelect } from "primereact/treeselect";
import { useFormik } from "formik";
import { NATUREZA_PLANO_CONTAS } from "@/assets/constants/constants";

import classNames from "classnames";
import * as Yup from "yup";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoButton as Button } from "@/components/MakoButton";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { PageBase } from "@/components/PageBase";
import { Label } from "@/components/Label";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { MakoInputCodigoAleatorio } from "@/components/MakoInputs";

export const FinanceiroPlanoContasForm = () => {
    const [contaPai, setContaPai] = useState(null);
    const [planosConta, setPlanosConta] = useState([]);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost, httpPatch, httpGet } = useHttp();
    const { showSuccess } = useToast();
    const { state } = useLocation();
    const history = useHistory();

    const { versaoId, contaPaiId, planContas } = state || {};

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: planContas
            ? { ...planContas.data, versao: planContas.data.versao.id }
            : {
                  codigo: "",
                  descricao: "",
                  natureza: null,
                  estrutura: "",
                  conta_titulo: false,
                  plano_conta_financeira: null,
                  ativo: true,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                natureza: Yup.string()
                    .required("O campo 'natureza' é obrigatório.")
                    .typeError("Informe uma 'natureza' válida."),
                estrutura: Yup.string().required("O campo 'estrutura' é obrigatório."),
                plano_conta_financeira: Yup.number()
                    .when("conta_titulo", {
                        is: (val) => !val,
                        then: Yup.number()
                            .required("Necessário selecionar uma conta pai.")
                            .typeError("Informe uma 'conta' válida."),
                    })
                    .nullable(),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const planoConta = {
                ...values,
                versao: versaoId,
                nivel: contaPai ? contaPai.nivel + 1 : 1,
            };
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Plano de conta cadastrado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push({
                            pathname: "/financeiro/plano-contas",
                            state: {
                                versaoId: versaoId,
                            },
                        });
                    },
                };
                showLoading();
                await httpPost({ url: "/financeiro/planos-contas-financeiras/", body: planoConta }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Plano de conta alterado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push({
                            pathname: "/financeiro/plano-contas",
                            state: {
                                versaoId: versaoId,
                            },
                        });
                    },
                };
                showLoading();
                await httpPatch(
                    { url: `/financeiro/planos-contas-financeiras/${values.id}/`, body: planoConta },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const buscarContaPai = useCallback(
        async (contaPaiId) => {
            if (contaPaiId) {
                const handlers = {
                    200: ({ data }) => {
                        setContaPai(data);
                        setFieldValue("plano_conta_financeira", contaPaiId);
                        setFieldValue("natureza", data.natureza);
                    },
                };
                showLoading();
                await httpGet({ url: `/financeiro/planos-contas-financeiras/${contaPaiId}/` }, handlers);
                hideLoading();
            }
        },
        [showLoading, hideLoading, setFieldValue, httpGet]
    );

    const listarPlanoContasTitulo = useCallback(
        async (versaoId) => {
            const handlers = {
                200: ({ data }) => setPlanosConta(data),
            };
            showLoading();
            await httpGet(
                {
                    url: `/financeiro/planos-contas-financeiras-estruturas/?id_versao=${versaoId}&apenas_titulo=True`,
                },
                handlers
            );
            hideLoading();
        },
        [showLoading, hideLoading, httpGet]
    );

    const onGerarCodigo = useCallback((codigo) => setFieldValue("codigo", codigo), [setFieldValue]);

    useEffect(() => {
        if (versaoId) listarPlanoContasTitulo(versaoId);
    }, [versaoId, listarPlanoContasTitulo]);

    useEffect(() => {
        if (contaPaiId) buscarContaPai(contaPaiId);
    }, [contaPaiId, buscarContaPai]);

    useEffect(() => {
        if (planContas?.data) {
            setValues(planContas.data);
            buscarContaPai(planContas.data.plano_conta_financeira);
        }
    }, [planContas, setValues, buscarContaPai]);

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Inserir plano de conta" : "Manutenção de plano de conta"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="natureza" label="Natureza" obrigatorio />
                        <Dropdown
                            id="natureza"
                            name="natureza"
                            placeholder="D/C"
                            options={NATUREZA_PLANO_CONTAS}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.natureza}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.natureza })}
                        />
                        {formik.errors.natureza && <small className="p-error">{formik.errors.natureza}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="plano_conta_financeira" label="Conta pai" obrigatorio />
                        <TreeSelect
                            id="plano_conta_financeira"
                            name="plano_conta_financeira"
                            options={planosConta}
                            filter
                            filterBy="data.descricao"
                            emptyMessage="Nenhum registro encontrado."
                            placeholder="Selecione uma conta pai"
                            value={formik.values.plano_conta_financeira}
                            onChange={(e) => buscarContaPai(e.value)}
                            className={classNames({ "p-invalid": formik.errors.plano_conta_financeira })}
                        />
                        {formik.errors.plano_conta_financeira && (
                            <small className="p-error">{formik.errors.plano_conta_financeira}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-mt-4 p-col-12 p-md-3">
                        <Checkbox
                            inputId="conta-titulo"
                            name="conta_titulo"
                            onChange={formik.handleChange}
                            checked={formik.values.conta_titulo}
                            value={formik.values.conta_titulo}
                        />
                        <Label htmlFor="conta-titulo" label="Conta título" />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="codigo" label="Código" obrigatorio />
                        <MakoInputCodigoAleatorio
                            id="codigo"
                            name="codigo"
                            value={formik.values.codigo}
                            onInput={formik.handleChange}
                            onGerarCodigo={onGerarCodigo}
                            length={15}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="estrutura" label="Estrutura" obrigatorio />
                        <InputText
                            id="estrutura"
                            name="estrutura"
                            value={formik.values.estrutura}
                            keyfilter={/[.0-9]/}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.estrutura })}
                        />
                        {formik.errors.estrutura && <small className="p-error">{formik.errors.estrutura}</small>}
                    </div>
                    <div className="p-field-checkbox p-mt-4 p-col-12 p-md-2">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            onChange={formik.handleChange}
                            checked={formik.values.ativo}
                            value={formik.values.ativo}
                        />
                        <Label htmlFor="ativo" label={formik.values.ativo ? "Ativo" : "Inativo"} />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        type="reset"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        to={{
                            pathname: "/financeiro/plano-contas",
                            state: {
                                versaoId: versaoId,
                            },
                        }}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
