import React, { useImperativeHandle, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";

import { CentroEstocagemFormBase } from "@/pages/Estoque/Cadastros/CentroEstocagem/formBase";

const Componente = ({ onClose }, ref) => {
    const [visible, setVisible] = useState(false);
    const [estoqueId, setEstoqueId] = useState(null);
    const [centroEstocagem, setCentroEstocagem] = useState(null);

    const abrirModal = (estoqueId, centro) => {
        setVisible(true);
        if (estoqueId) setEstoqueId(estoqueId);
        if (centro) setCentroEstocagem(centro);
    };

    const fecharModal = () => {
        setVisible(false);
        if (typeof onClose === "function") onClose();
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog visible={visible} header="Novo centro de estocagem" onHide={fecharModal}>
            <CentroEstocagemFormBase estoqueId={estoqueId} onCancelar={fecharModal} centroEstocagem={centroEstocagem} />
        </Dialog>
    );
};

export const ModalCentroEstocagem = forwardRef(Componente);
