import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";

import { differenceInDays } from "date-fns";
import { useHistory } from "react-router-dom";

import { ConfirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Panel } from "primereact/panel";
// import { ModalPagamentoEfetivado } from "./ModalPagamentoEfetivado";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { ModalPagamentoEfetivado } from "./modals/pagamentoEfetivadoModalForm";
import { ModalTemplateRateio } from "../../TemplateRateio/ModalTemplateRateio";

import { formatarCasasDecimais } from "@/assets/util/util";
import { parseData, dataToStr } from "@/assets/util/datas";
import { parseNumber } from "@/assets/helpers/number";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import { Label } from "@/components/Label";

export const EfetivacaoPagamentoPage = (props) => {
    const [credor, setCredor] = useState({ doc: "", nome: "", empresa: null });
    const [templateRateioSelecionado, setTemplateRateioSelecionado] = useState(null);
    const [permitirPagamentos, setPermitirPagamentos] = useState(true);
    const [linhasExpandidas, setLinhasExpandidas] = useState(null);
    const [dataPagamento, setDataPagamento] = useState(new Date());
    const [templatesRateio, setTemplatesRateio] = useState([]);
    const [resultadoRateio, setResultadoRateio] = useState([]);
    const [totalOriginal, setTotalOriginal] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [totalPago, setTotalPago] = useState(0);
    const [confirm, setConfirm] = useState(false);
    const [parcelas, setParcelas] = useState([]);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showWarning } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { httpPost } = useHttp();

    const modalTemplateRateioRef = useRef(null);
    const modalRef = useRef(null);
    const history = useHistory();

    async function registrarPagamentos() {
        setSubmitted(true);

        if (dataPagamento) {
            const pagamentos = parcelas.map((parcela) => {
                const formas_pagamento = parcela.forma_pagamento
                    .filter((e) => e?.id)
                    .map((forma) => {
                        return {
                            forma_pagamento: forma.id,
                            valor: formatarCasasDecimais(forma.valor),
                        };
                    });

                if (formas_pagamento.length === 0)
                    return showWarning({
                        summary: "Aviso",
                        detail: "Informe o valor pago antes de registrar o pagamento.",
                        life: 6000,
                    });

                return {
                    id: parcela.id,
                    formas_pagamento,
                    documento: parcela.documento,
                    vencimento: parcela.vencimento,
                    data_pagamento: dataToStr(dataPagamento, "yyyy-MM-dd"),
                    template_rateio: parcela.template_rateio,
                    quantidade_parcelas: parcela.quantidade_parcelas,
                    conta_financeira: parcela.conta_financeira,
                    numero_parcela: parcela.numero_parcela,
                    valor: formatarCasasDecimais(parcela.valor),
                    em_aberto: formatarCasasDecimais(parcela.valor),
                    pago: formatarCasasDecimais(parcela.pago),
                };
            });
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Pagamentos registrados com sucesso!",
                        life: 1500,
                    });
                    setSubmitted(false);
                    history.push("/financeiro/financeiro/pagamentos");
                },
            };
            showLoading();
            await httpPost({ url: "/financeiro/efetivar-pagamentos/", body: { pagamentos } }, handlers);
            hideLoading();
        }
    }

    const calcularPagamentos = useCallback(() => {
        if (props.location.state) {
            setParcelas(
                props.location.state.map((pagamento) => {
                    const { vencimento, valor } = pagamento;
                    const saldo = parseFloat(valor);
                    const diasAtraso = differenceInDays(dataPagamento, parseData(vencimento)) - 1;

                    return {
                        ...pagamento,
                        forma_pagamento: pagamento.forma_pagamento
                            ? [{ ...pagamento.forma_pagamento, valor: saldo }]
                            : [],
                        dias: diasAtraso,
                        valor: saldo,
                    };
                })
            );
            setCredor({
                nome: props.location.state[0].credor.nome,
                doc: formatarDocumento(props.location.state[0].credor.identificacao),
                empresa: props.location.state[0].empresa,
                conta_financeira: props.location.state[0].conta_financeira,
            });
        }
    }, [props.location.state, formatarDocumento, dataPagamento]);

    const habilitarPagamentos = useCallback(() => {
        if (!empresaSelecionadaId) {
            setPermitirPagamentos(false);
            showWarning({
                summary: "Aviso!",
                detail: "Você não possui uma empresa selecionada.",
                life: 3000,
            });

            return null;
        }
    }, [empresaSelecionadaId, showWarning]);

    const calcularTotais = useCallback(() => {
        let _original = 0,
            _pago = 0;

        parcelas.forEach((parcela) => {
            _original += parcela.valor;
            _pago += parcela.valor_pago | 0;
        });

        setTotalOriginal(_original);

        setTotalPago(_pago);
    }, [parcelas]);

    const editarPagamento = (novoPagamento) => {
        const index = parcelas?.findIndex((p) => p.id === novoPagamento.id);
        let _parcelas = [...parcelas];
        _parcelas[index] = novoPagamento;
        if (permitirRegistrar) setConfirm(true);
        setParcelas(_parcelas);
    };

    const numeroParcelaBodyTemplate = (rowData) => {
        if (rowData.numero_parcela === 0) {
            return <span>ENTRADA</span>;
        }

        return <span>{`${rowData.numero_parcela}/${rowData.quantidade_parcelas}`}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon="pi pi-dollar"
                    className="p-button-rounded p-button-success"
                    onClick={() => modalRef.current?.abrirModal(rowData)}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const calcularValoresRateados = useCallback(() => {
        const templatesId = parcelas.map((parcela) => parcela.template_rateio);
        if (templatesId.length >= 1 && [...new Set(templatesId)].length === 1 && templatesRateio.length > 0) {
            const template_rateio = templatesRateio.find(
                (tr) => tr.id === (templateRateioSelecionado || templatesId[0])
            );

            if (template_rateio) {
                const template = {
                    descricao: template_rateio.descricao,
                    percentual_plano_conta_financeira: template_rateio.percentual_plano_conta_financeira,
                    percentual_plano_conta_contabil: template_rateio.percentual_plano_conta_contabil,
                    percentual_fase_projeto: template_rateio.percentual_fase_projeto,
                    percentual_centro_resultado: template_rateio.percentual_centro_resultado,
                };
                let data = template_rateio.templatedetalherateio_set.map((detalhe) => {
                    const percentual = parseNumber(detalhe.percentual);
                    const TIPOS_DETALHES = {
                        1: "plano_conta_contabil",
                        2: "plano_conta_financeira",
                        3: "centro_resultado",
                        4: "fase_projeto",
                    };
                    return {
                        tipo_detalhe: detalhe.tipo_detalhe.descricao,
                        detalhe: detalhe[TIPOS_DETALHES[detalhe.tipo_detalhe.id]].descricao,
                        percentual,
                        valor: 0,
                    };
                });
                parcelas.forEach((parcela) => {
                    data.forEach((detalhe) => {
                        detalhe.valor += (parcela.recebido * detalhe.percentual) / 100;
                    });
                });
                setResultadoRateio([{ ...template, detalhe: data }]);
            }
        }
    }, [parcelas, templateRateioSelecionado, templatesRateio]);

    const colunas = [
        { field: "documento", header: "Documento" },
        {
            field: "numero_parcela",
            header: "Parcela",
            style: { width: "8%" },
            action: (e) => numeroParcelaBodyTemplate(e),
        },
        { field: "data_emissao", header: "Emissão", style: { width: "9%" }, dateFormat: "dd/MM/yyyy" },
        { field: "vencimento", header: "Vencimento", style: { width: "9%" }, dateFormat: "dd/MM/yyyy" },
        { field: "dias", header: "Dias", style: { width: "5%" } },
        { field: "valor", header: "Valor", money: true },
        { field: "pago", header: "Pago", money: true },
        {
            field: "actions",
            header: "Ações",
            style: { width: "6%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const colunasTemplateRateio = [
        { expander: true, style: { width: "5rem" } },
        { field: "descricao", header: "Regra de rateio" },
        { field: "percentual_plano_conta_financeira", header: "% conta financeira", decimal: true },
        { field: "percentual_plano_conta_contabil", header: "% conta contábil", decimal: true },
        { field: "percentual_fase_projeto", header: "% fase de projeto", decimal: true },
        { field: "percentual_centro_resultado", header: "% centro de resultado", decimal: true },
    ];

    const rowClass = (rowData) => {
        return {
            "table-pagamentos-overdue": differenceInDays(dataPagamento, parseData(rowData.vencimento)) > 0,
            "table-pagamentos-pending": differenceInDays(dataPagamento, parseData(rowData.vencimento)) < 0,
        };
    };

    const rowExpansionTemplate = (data) => {
        const colunas = [
            { field: "tipo_detalhe", header: "Tipo de detalhe" },
            { field: "detalhe", header: "Detalhe" },
            {
                field: "percentual",
                header: "Percentual",
                decimal: true,
                action: (e) => <span>{`${e.percentual} %`}</span>,
            },
            { field: "valor", header: "Valor do rateio", money: true },
        ];
        return (
            <div className="p-p-3">
                <MakoListagem colunas={colunas} dadosLocal={data.detalhe} />
            </div>
        );
    };

    const permitirRegistrar = useMemo(() => {
        return parcelas?.length === 1;
    }, [parcelas]);

    useEffect(() => {
        calcularValoresRateados();
    }, [calcularValoresRateados]);

    useEffect(() => {
        calcularTotais();
    }, [calcularTotais]);

    useEffect(() => {
        habilitarPagamentos();
    }, [habilitarPagamentos]);

    useEffect(() => {
        calcularPagamentos();
    }, [calcularPagamentos]);

    return (
        <div className="p-grid">
            <ConfirmDialog
                visible={confirm}
                header="Confirmação"
                message="Deseja ja registrar o pagamento?"
                icon="pi pi-exclamation-triangle"
                acceptLabel="Sim"
                rejectClassName="p-button-danger p-button-text"
                acceptClassName="p-button-success p-button"
                rejectLabel="Cancelar"
                accept={registrarPagamentos}
                onHide={() => setConfirm(false)}
            />
            <div className="p-col-12">
                <div className="card">
                    <ModalPagamentoEfetivado ref={modalRef} onConfirmar={(e) => editarPagamento(e)} />
                    <ModalTemplateRateio
                        ref={modalTemplateRateioRef}
                        formProps={{
                            derivar: true,
                            location: { state: templatesRateio.find((tr) => tr.id === templateRateioSelecionado) },
                        }}
                    />
                    <h5>Pagando parcelas</h5>
                    <Panel header="Credor">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <Label htmlFor="doc" label="CPF / CNPJ" />
                                <InputText id="doc" disabled value={credor.doc} />
                            </div>
                            <div className="p-field p-col-12 p-md-7">
                                <Label htmlFor="nome-completo" label="Nome completo" />
                                <InputText id="nome-completo" disabled value={credor.nome} />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Label htmlFor="data-pagamento" label="Data do pagamento" obrigatorio />
                                <MakoCalendar
                                    id="data-pagamento"
                                    valueCalendar={dataPagamento}
                                    onChange={(e) => setDataPagamento(e.value)}
                                />
                                {submitted && !dataPagamento && (
                                    <small className="p-error">O campo 'data do pagamento' é obrigatório.</small>
                                )}
                            </div>
                        </div>
                    </Panel>
                    <Panel header="Resultado do rateio" toggleable collapsed className="p-mt-3">
                        <div className="p-inputgroup p-mb-2">
                            <Dropdown
                                url="/financeiro/templates-rateios/"
                                setObjects={setTemplatesRateio}
                                optionValue="id"
                                optionLabel="descricao"
                                showClear={false}
                                value={templateRateioSelecionado}
                                onChange={(e) => setTemplateRateioSelecionado(e.value)}
                            />
                            <Button
                                type="button"
                                label="Derivar"
                                icon="pi pi-copy"
                                onClick={() => modalTemplateRateioRef.current?.abrirModal()}
                            />
                        </div>
                        <MakoListagem
                            colunas={colunasTemplateRateio}
                            dadosLocal={resultadoRateio}
                            configTabela={{
                                expandedRows: linhasExpandidas,
                                onRowToggle: (e) => setLinhasExpandidas(e.data),
                                rowExpansionTemplate,
                            }}
                        />
                    </Panel>
                    <MakoListagem
                        titulo="Parcelas à pagar"
                        colunas={colunas}
                        dadosLocal={parcelas}
                        configTabela={{
                            paginator: true,
                            rowClassName: rowClass,
                        }}
                    />
                    <Divider align="center">
                        <b>Totais</b>
                    </Divider>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="original">Total valor original</label>
                            <MakoInputMoeda id="original" disabled valueMoeda={totalOriginal} />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="total-pagamento">Total do pagamento</label>
                            <MakoInputMoeda id="total-pagamento" disabled valueMoeda={totalPago} />
                        </div>
                    </div>
                    <MakoActionsButtons>
                        <Button
                            label="Registrar pagamentos"
                            icon="pi pi-dollar"
                            disabled={!permitirPagamentos}
                            onClick={registrarPagamentos}
                            loading={loading}
                        />
                        <Button
                            label="Cancelar"
                            icon={MAKO_ICONS.CANCEL}
                            className="p-button-danger"
                            to={"/financeiro/financeiro/pagamentos"}
                            loading={loading}
                        />
                    </MakoActionsButtons>
                </div>
            </div>
        </div>
    );
};
