import { ProdutoProdutosPage } from "@/pages/Produto/Produto/Produtos";
import { TabProdutoContext } from "@/pages/Produto/Produto/Produtos/tabProduto";
import { ProdutoAtributosPage } from "@/pages/Produto/Cadastros/Atributos";
import { ProdutoGradeAtributosPage } from "@/pages/Produto/Cadastros/GradeAtributos";
import { ProdutoGradeAtributosForm } from "@/pages/Produto/Cadastros/GradeAtributos/form";
import { ProdutoAtributosForm } from "@/pages/Produto/Cadastros/Atributos/form";
import { CategoriasPage } from "@/pages/Produto/Produto/Categorias";
import { CategoriasForm } from "@/pages/Produto/Produto/Categorias/form";
import { GrupoCategoriasPage } from "@/pages/Produto/Produto/GrupoCategoria";
import { GrupoCategoriasForm } from "@/pages/Produto/Produto/GrupoCategoria/form";
import { ProdutoMarcasPage } from "@/pages/Produto/Cadastros/Marcas";
import { ProdutoMarcasForm } from "@/pages/Produto/Cadastros/Marcas/form";
import { TipoCodigoSkuPage } from "@/pages/Produto/Cadastros/TipoCodigoSku";
import { TipoCodigoSkuForm } from "@/pages/Produto/Cadastros/TipoCodigoSku/form";
import { ProdutoModelosPage } from "@/pages/Produto/Cadastros/Modelo";
import { ProdutoModelosForm } from "@/pages/Produto/Cadastros/Modelo/form";
import { ProdutoMarcasFabricantePage } from "@/pages/Produto/Cadastros/MarcasFabricante";
import { ProdutoMarcasFabricanteForm } from "@/pages/Produto/Cadastros/MarcasFabricante/form";
import { ProdutoTagsImgPage } from "@/pages/Produto/Cadastros/TagsImgSku";
import { ProdutoTagsImgForm } from "@/pages/Produto/Cadastros/TagsImgSku/form";
import { ProdutoUnidadeMedidaPage } from "@/pages/Produto/Cadastros/UnidadeMedida";
import { ProdutoUnidadeMedidaForm } from "@/pages/Produto/Cadastros/UnidadeMedida/form";
import { ProdutoFatorConversaoForm } from "@/pages/Produto/Cadastros/UnidadeMedida/formFatorConversao";
import { ProdutoGaleriaImgSkuPage } from "@/pages/Produto/Cadastros/GaleriaImgSku";
import { ProdutoGaleriaImgSkuForm } from "@/pages/Produto/Cadastros/GaleriaImgSku/form";
import { VinculoFornecedorForm } from "@/pages/Produto/Cadastros/VinculoFornecedor/form";
import { VinculoFornecedor } from "@/pages/Produto/Cadastros/VinculoFornecedor";
import { VinculoCentroEstocagemResultado } from "@/pages/Produto/Cadastros/VinculoCentroEstocagemResultado";
import { VinculoCentroEstocagemResultadoForm } from "@/pages/Produto/Cadastros/VinculoCentroEstocagemResultado/form";
import { ProdutoDescricaoPadraoForm } from "@/pages/Produto/Cadastros/DescricaoPadrao/form";
import { RelatorioInventarioProdutos } from "@/pages/Relatorios/Produtos/InventarioProdutos";
import { RelatorioMovimentacaoProdutos } from "@/pages/Relatorios/Produtos/MovimentacaoProdutos";
import { PadraoTemplatePage } from "@/pages/Produto/Cadastros/DescricaoPadronizada";
import { PadraoTemplateForm } from "@/pages/Produto/Cadastros/DescricaoPadronizada/form";
import { RelatorioRelacaoProdutos } from "@/pages/Relatorios/Produtos/RelacaoProdutos";
import { RelatorioRelacaoPisCofins } from "@/pages/Relatorios/Produtos/RelacaoPisCofins";
import { AjustaPrecoFornecedor } from "@/pages/Produto/Cadastros/VinculoFornecedor/ajustarPreco";
import { RelatorioPrecoProdutosFornecedor } from "@/pages/Relatorios/Produtos/PrecoProdutosFornecedor";
import { ImportarProdutosPage } from "@/pages/Produto/ImportarProdutos";
import { ConsultarSaldosPage } from "@/pages/Produto/ConsultarSaldos";
import { LotesPage } from "@/pages/Produto/Produto/Lotes";
import { RelatorioLotes } from "@/pages/Relatorios/Produtos/Lotes";

export const produtosRoutes = [
    { path: "/produto/produto/importar-produtos", component: ImportarProdutosPage },
    { path: "/produto/produto/consultar-saldos", component: ConsultarSaldosPage },
    { path: "/produto/produto/produtos", component: ProdutoProdutosPage },
    { path: "/produto/produto/produtos/form", component: TabProdutoContext },
    { path: "/produto/produto/unidade-medida", component: ProdutoUnidadeMedidaPage },
    { path: "/produto/produto/unidade-medida/form", component: ProdutoUnidadeMedidaForm },
    { path: "/produto/produto/unidade-medida-fc/form", component: ProdutoFatorConversaoForm },
    { path: "/produto/produto/categorias", component: CategoriasPage },
    { path: "/produto/produto/lotes", component: LotesPage },
    { path: "/produto/produto/categorias/form", component: CategoriasForm },
    { path: "/produto/produto/grupos-categorias", component: GrupoCategoriasPage },
    { path: "/produto/produto/grupos-categorias/form", component: GrupoCategoriasForm },
    { path: "/produto/cadastros/padrao-nome", component: PadraoTemplatePage },
    { path: "/produto/cadastros/padrao-nome/form", component: PadraoTemplateForm },
    { path: "/produto/cadastros/modelos", component: ProdutoModelosPage },
    { path: "/produto/cadastros/modelos/form", component: ProdutoModelosForm },
    { path: "/produto/cadastros/marcas", component: ProdutoMarcasPage },
    { path: "/produto/cadastros/marcas/form", component: ProdutoMarcasForm },
    { path: "/produto/cadastros/marcas-fabricante", component: ProdutoMarcasFabricantePage },
    { path: "/produto/cadastros/marcas-fabricante/form", component: ProdutoMarcasFabricanteForm },
    { path: "/produto/cadastros/galerias-imagens", component: ProdutoGaleriaImgSkuPage },
    { path: "/produto/cadastros/galerias-imagens/form", component: ProdutoGaleriaImgSkuForm },
    { path: "/produto/cadastros/tags-imagens", component: ProdutoTagsImgPage },
    { path: "/produto/cadastros/tags-imagens/form", component: ProdutoTagsImgForm },
    { path: "/produto/cadastros/atributos", component: ProdutoAtributosPage },
    { path: "/produto/cadastros/atributos/form", component: ProdutoAtributosForm },
    { path: "/produto/cadastros/grade-atributos", component: ProdutoGradeAtributosPage },
    { path: "/produto/cadastros/grade-atributos/form", component: ProdutoGradeAtributosForm },
    { path: "/produto/cadastros/tipos-codigos-sku", component: TipoCodigoSkuPage },
    { path: "/produto/cadastros/tipos-codigos-sku/form", component: TipoCodigoSkuForm },
    { path: "/produto/cadastros/descricao-padrao", component: ProdutoDescricaoPadraoForm },
    { path: "/produto/cadastros/vinculo-fornecedor", component: VinculoFornecedor },
    { path: "/produto/cadastros/vinculo-fornecedor/form", component: VinculoFornecedorForm },
    { path: "/produto/cadastros/vinculo-fornecedor/ajustar-preco", component: AjustaPrecoFornecedor },
    { path: "/produto/cadastros/vinculo-estocagem-resultado", component: VinculoCentroEstocagemResultado },
    { path: "/produto/cadastros/vinculo-estocagem-resultado/form", component: VinculoCentroEstocagemResultadoForm },
    { path: "/produto/relatorios/inventario-produtos", component: RelatorioInventarioProdutos },
    { path: "/produto/relatorios/movimentacao-produtos", component: RelatorioMovimentacaoProdutos },
    { path: "/produto/relatorios/relacao-produtos", component: RelatorioRelacaoProdutos },
    { path: "/produto/relatorios/relacao-pis-cofins", component: RelatorioRelacaoPisCofins },
    { path: "/produto/relatorios/preco-por-fornecedor", component: RelatorioPrecoProdutosFornecedor },
    { path: "/produto/relatorios/lotes", component: RelatorioLotes },
];
