import React, { useCallback, useMemo, useRef } from "react";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { Modal as ModalSelecionar } from "./modal";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useClearRefs from "@/hooks/useClearRefs";

export const MakoInputOrdemCompra = ({
    id = "",
    name = "",
    value,
    onChange = () => {},
    disabled = false,
    className = "",
}) => {
    const [formatDocumento] = useFormatCNPJCPF();

    const modalRef = useRef(null);

    const show = () => modalRef.current?.show();

    useClearRefs(modalRef);

    const onSelect = useCallback(
        (value) => {
            onChange(value);
        },
        [onChange]
    );

    const formatedValue = useMemo(() => {
        if (value instanceof Object) {
            const { id, fornecedor } = value;
            return `${id}: ${fornecedor.nome} - ${formatDocumento(fornecedor.identificacao)}`;
        }
        return value || "";
    }, [value, formatDocumento]);

    return (
        <div className="p-inputgroup">
            <InputText id={id} name={name} value={formatedValue} disabled className={className} />
            <Button type="button" icon={MAKO_ICONS.PESQUISAR} tooltip="Pesquisar" onClick={show} disabled={disabled} />
            <ModalSelecionar ref={modalRef} onSelectCallback={onSelect} />
        </div>
    );
};
