import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const Modal = ({ aposSalvar }, ref) => {
    const [dialog, setDialog] = useState(false);
    const { showError } = useToast();
    const { httpPost } = useHttp();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            requisicao: null,
            codigo: null,
            descricao: "",
            unidade_medida: "",
            quantidade_original: 0,
            nova_quantidade: 0,
            resto_quantidade: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nova_quantidade: Yup.number()
                    .required("O campo 'nova quantidade' é obrigatório.")
                    .min(1, "A nova quantidade precisa ser maior que 0."),
            });

            await formSchema.validate(values, { abortEarly: false });

            let success = false;
            const handlers = {
                200: () => {
                    success = true;
                    if (typeof aposSalvar === "function") aposSalvar();
                    setDialog(false);
                },
            };

            await httpPost(
                {
                    url: `/transferencias/dividir-requisicao-transferencia/`,
                    body: values,
                },
                handlers
            );

            if (!success)
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const exibirDialog = ({ id, quantidade, sku }) => {
        setValues({
            requisicao: id,
            codigo: sku.codigo,
            descricao: sku.descricao_reduzida,
            unidade_medida: sku.unidade_padrao.nome,
            quantidade_original: parseFloat(quantidade),
            nova_quantidade: 0,
            resto_quantidade: parseFloat(quantidade),
        });
        setDialog(true);
    };

    const onChangeQuantidade = (value) => {
        setFieldValue("nova_quantidade", value);
        setFieldValue("resto_quantidade", formik.values.quantidade_original - value);
    };

    useImperativeHandle(ref, () => ({ exibirDialog }));

    return (
        <Dialog
            header={`Dividindo item da requisição de transferência (${formik.values.id})`}
            visible={dialog}
            style={{ width: "65vw", display: "block" }}
            onHide={() => setDialog(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="codigo">Código</label>
                        <InputText
                            id="codigo"
                            name="codigo"
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="descricao">Descrição</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="unidade_medida">Unidade de Medida</label>
                        <InputText
                            id="unidade_medida"
                            name="unidade_medida"
                            value={formik.values.unidade_medida}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="quantidade_original">Qtd. original</label>
                        <InputNumber
                            id="quantidade_original"
                            name="quantidade_original"
                            mode="decimal"
                            value={formik.values.quantidade_original}
                            onValueChange={formik.handleChange}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="nova_quantidade">Nova quantidade *</label>
                        <InputNumber
                            id="nova_quantidade"
                            name="nova_quantidade"
                            mode="decimal"
                            min={0}
                            max={formik.values?.quantidade_original || 1}
                            value={formik.values.nova_quantidade}
                            onValueChange={(e) => onChangeQuantidade(e.target.value)}
                            className={classNames({ "p-invalid": formik.errors.nova_quantidade })}
                        />
                        {formik.errors.nova_quantidade && (
                            <small className="p-error">{formik.errors.nova_quantidade}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="resto_quantidade">Resto</label>
                        <InputNumber
                            id="resto_quantidade"
                            name="resto_quantidade"
                            mode="decimal"
                            value={formik.values.resto_quantidade}
                            onValueChange={formik.handleChange}
                            disabled
                        />
                    </div>
                </div>

                <br />
                <div className="p-grid p-justify-end">
                    <Button type="submit" icon="pi pi-pause" label="Dividir" className="p-mr-2" />
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => setDialog(false)}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const DividirRequisicaoModalForm = forwardRef(Modal);
