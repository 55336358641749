import React, { useCallback, useRef } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";

import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputCliente } from "@/components/MakoInputs";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { ListagemFracionamentos } from "./listagemFracionamentos";
import { Modals } from "./modals";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useClearRefs from "@/hooks/useClearRefs";

const INITIAL_VALUES = {
    id: null,
    cliente: null,
    valor: 0,
    valor_nao_recebido: 0,
    valor_receber: 0,
    forma_recebimento: null,
};

export const ReceberEntrada = ({ pendencia }) => {
    const { caixaMov } = useCaixaMovimento();

    const listagemRef = useRef(null);
    const modalCreditoRef = useRef(null);
    const modalDebitoRef = useRef(null);
    const modalPixRef = useRef(null);
    const modalDinheiroRef = useRef(null);
    const formasRef = useRef([]);

    useClearRefs(listagemRef, modalCreditoRef, modalDebitoRef, modalPixRef, modalDinheiroRef);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: !pendencia
            ? INITIAL_VALUES
            : {
                  ...INITIAL_VALUES,
                  id: pendencia.id,
                  cliente: pendencia.devedor,
                  valor: pendencia.valor,
                  valor_nao_recebido: pendencia.para_receber,
                  forma_recebimento: pendencia.forma_recebimento.id,
                  valor_receber: pendencia.para_receber,
                  tipo_chave: pendencia.tipo_chave,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit({ id, forma_recebimento, valor_receber, tipo_chave }, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                forma_recebimento: Yup.number()
                    .required("O campo 'forma recebimento' é obrigatório.")
                    .typeError("O campo 'valor para abater' é obrigatório."),
                valor_receber: Yup.number()
                    .required("O campo 'valor do recebimento' é obrigatório")
                    .min(0.01, "Informe um valor maior que '0'")
                    .typeError("O campo 'valor para abater' é obrigatório."),
            });

            await formSchema.validate({ forma_recebimento, valor_receber }, { abortEarly: false });

            const { tipo } = formasRef.current?.find(({ id }) => id === forma_recebimento) || {};

            const data = { id, forma_recebimento, valor_receber, tipo_chave };

            if (tipo?.id === 3) modalPixRef.current?.show(data);
            else if (tipo?.id === 1) modalCreditoRef.current?.show(data);
            else if (tipo?.id === 2) modalDebitoRef.current?.show(data);
            else if (tipo?.id === 0) modalDinheiroRef.current?.show(data);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            }
        }
    }

    const atualizarListagem = useCallback(
        ({ pendencia }) => {
            const { para_receber = 0 } = pendencia || {};
            setFieldValue("valor_nao_recebido", para_receber);
            setFieldValue("valor_receber", para_receber);
            listagemRef.current?.buscarDados();
        },
        [setFieldValue]
    );

    const aposBuscarFormasRecebimento = useCallback((data) => {
        formasRef.current = data;
        return data;
    }, []);

    const actions = useCallback(
        (rowData) => {
            return (
                <div className="actions">
                    <Button
                        icon="fa pi-fw fa-reply"
                        tooltip="Estornar"
                        className="p-button-rounded p-button-danger"
                        type="button"
                        disabled={true || caixaMov?.bloqueado}
                    />
                </div>
            );
        },
        [caixaMov]
    );

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="id" label="N° orcamento" />
                        <InputText id="id" name="id" value={formik.values.id} onChange={formik.handleChange} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-7">
                        <Label htmlFor="cliente" label="Nome do cliente" />
                        <MakoInputCliente
                            id="cliente"
                            name="cliente"
                            value={formik.values.cliente}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="valor" label="Valor da entrada" />
                        <MakoInputMoeda
                            id="valor"
                            name="valor"
                            valueMoeda={formik.values.valor}
                            onChangeMoeda={formik.handleChange}
                            disabled
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="valor_nao_recebido" label="Não recebido" />
                        <MakoInputMoeda
                            id="valor_nao_recebido"
                            name="valor_nao_recebido"
                            valueMoeda={formik.values.valor_nao_recebido}
                            onChangeMoeda={formik.handleChange}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-7">
                        <Label htmlFor="forma_recebimento" label="Forma recebimento" obrigatorio />
                        <Dropdown
                            id="forma_recebimento"
                            name="forma_recebimento"
                            url="/financeiro/formas-recebimentos/?ativo=true&viacaixa=true&utilizar_entrada=true"
                            optionValue="id"
                            optionLabel="descricao"
                            showClear={false}
                            aposBuscar={aposBuscarFormasRecebimento}
                            value={formik.values.forma_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_recebimento })}
                        />
                        {formik.errors.forma_recebimento && (
                            <small className="p-error">{formik.errors.forma_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="valor_receber" label="Valor recebido" obrigatorio />
                        <MakoInputMoeda
                            id="valor_receber"
                            name="valor_receber"
                            valueMoeda={formik.values.valor_receber}
                            onChangeMoeda={formik.handleChange}
                            max={formik.values.valor_nao_recebido}
                            className={classNames({ "p-invalid": formik.errors.valor_receber })}
                        />
                        {formik.errors.valor_receber && (
                            <small className="p-error">{formik.errors.valor_receber}</small>
                        )}
                    </div>
                </div>
                <MakoActionsButtons className="p-jc-end">
                    <Button
                        label="Receber"
                        icon={MAKO_ICONS.CONFIRMAR}
                        className="p-button p-button-success"
                        type="submit"
                        disabled={parseInt(formik.values.valor_nao_recebido) === 0}
                    />
                </MakoActionsButtons>
            </form>
            <div className="p-mt-3">
                <ListagemFracionamentos listagemRef={listagemRef} recebimentoId={formik.values.id} actions={actions} />
            </div>
            <Modals.ReceberPix ref={modalPixRef} onSuccessCallback={atualizarListagem} />
            <Modals.ReceberCartaoCredito ref={modalCreditoRef} onSuccessCallback={atualizarListagem} />
            <Modals.ReceberCartaoDebito ref={modalDebitoRef} onSuccessCallback={atualizarListagem} />
            <Modals.ReceberDinheiro ref={modalDinheiroRef} onSuccessCallback={atualizarListagem} />
        </>
    );
};
