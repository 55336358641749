import React from "react";

import { AprovacaoPreco } from "./AprovacaoPreco";

export const FactoryResumo = ({ notificacao }) => {
    const componente = () => {
        const { evento_content_type } = notificacao;
        if (evento_content_type.app_label === "vendas" && evento_content_type.model === "itemvenda") {
            return <AprovacaoPreco resumo={notificacao.resumo} />;
        }
        return null;
    };

    return componente();
};
