import React, { useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import useHttp from "@/hooks/useHttp";

export const MakoAutoComplete = ({
    urlSearch,
    setTotalRegistros,
    minCaracteresBusca = 1,
    aposPesquisar,
    disabled,
    ...props
}) => {
    const [filtered, setFiltered] = useState([]);
    const [loading, setLoading] = useState(false);
    const { httpGet } = useHttp();

    async function pesquisar(event) {
        let _filtered = [];
        if (urlSearch && event.query.length >= minCaracteresBusca) {
            const handlers = {
                200: ({ data }) => {
                    const { count, results } = data;
                    if (setTotalRegistros) setTotalRegistros(count);

                    if (aposPesquisar) _filtered = aposPesquisar(results);
                    else {
                        if (results.length > 0) {
                            _filtered = results;
                        }
                    }
                },
            };

            setLoading(true);
            await httpGet({ url: urlSearch + event.query }, handlers);
            setLoading(false);
        }
        setFiltered(_filtered);
    }

    return (
        <AutoComplete
            suggestions={filtered}
            completeMethod={pesquisar}
            disabled={loading || disabled}
            delay={1500}
            {...props}
        />
    );
};
