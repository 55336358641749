import { useContext } from "react";

import OrdemCompraContext from "@/contexts/ordemCompraContext";

const useOrdemCompra = () => {
    const ordemCompra = useContext(OrdemCompraContext);

    return ordemCompra;
};

export default useOrdemCompra;
