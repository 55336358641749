import React, { useCallback, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { FiltroAvancadoDevolucao } from "./filtroAvancado";
import useToast from "@/hooks/useToast";
import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/compras/devolucao-fornecedor/";

const MESSAGE_BACKEND_SEMNF = "A devolução ao fornecedor não possui nota fiscal vinculada";

export const DevolucaoFornecedorPage = () => {
    const [url, setUrl] = useState(BASE_URL);
    const [devolucao, setDevolucao] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const history = useHistory();
    const menuRef = useRef(null);
    const listagemRef = useRef(null);
    const filtroRef = useRef(null);
    const { showWarning, showSuccess, showError } = useToast();
    const { httpPost, httpPut } = useHttp();

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/compras/entrada/devolucao-fornecedor/form")}
            />
            <Button
                label="Filtro avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                onClick={() => filtroRef.current?.abrirModal()}
            />
            <Button label="Limpar filtros" icon="pi pi-trash" className="p-button-warning p-mr-2" />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.COMPRAS_ENTRADA_DEVOLUCAOFORNECEDOR_INCLUIR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    disabled={rowData.status === "C"}
                    onClick={() =>
                        history.push({
                            pathname: "/compras/entrada/devolucao-fornecedor/form",
                            state: { devolucaoId: rowData.id },
                        })
                    }
                />
                <Button
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info p-mb-1"
                    aria-controls="popup_menu"
                    aria-haspopup
                    disabled={rowData.status === "C"}
                    onClick={(e) => {
                        menuRef.current?.toggle(e);
                        setDevolucao(rowData);
                    }}
                />
            </div>
        );
    };

    const handleFinalizar = useCallback(
        async (ignorar_nf = false) => {
            setConfirmDialog(false);
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: `A devolução ao fornecedor foi finalizada com sucesso.`,
                        life: 10000,
                    });
                    listagemRef.current?.buscarDados();
                },
                400: () =>
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível alterar a situação da devolução ao fornecedor.",
                        life: 3000,
                    }),
                409: ({ err }) => {
                    showWarning({
                        summary: err.msg === MESSAGE_BACKEND_SEMNF ? "Atenção" : "Falha",
                        detail: err.msg,
                        life: 9000,
                    });
                    if (err.msg === MESSAGE_BACKEND_SEMNF) setConfirmDialog(true);
                },
            };

            await httpPost(
                {
                    url: `/compras/finalizar-devolucao-fornecedor/${devolucao.id}/`,
                    body: {
                        ignorar_nf,
                    },
                },
                handlers
            );
        },
        [showError, showSuccess, showWarning, devolucao?.id, httpPost]
    );

    const handleSituacao = useCallback(
        async (situacao) => {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: `A devolução ao fornecedor foi ${
                            situacao === "C" ? "cancelada" : "validada"
                        } com sucesso.`,
                        life: 10000,
                    });
                    listagemRef.current?.buscarDados();
                },
                400: () =>
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível alterar a situação da devolução ao fornecedor.",
                        life: 3000,
                    }),
                409: ({ err }) => {
                    showWarning({
                        summary: "Falha",
                        detail: err.msg,
                        life: 9000,
                    });
                },
            };

            await httpPut(
                {
                    url: `/compras/alterar-status-devolucao/${devolucao.id}/`,
                    body: {
                        status: situacao,
                    },
                },
                handlers
            );
        },
        [showError, showSuccess, showWarning, devolucao?.id, httpPut]
    );

    const itensMenu = [
        {
            label: "Validar",
            icon: "pi pi-check",
            command: () => handleSituacao("V"),
        },
        {
            label: "Finalizar",
            icon: "pi pi-calculator",
            command: () => handleFinalizar(),
        },
        {
            label: "Cancelar",
            icon: "pi pi-times",
            command: () => handleSituacao("C"),
        },
    ];

    const colunas = [
        { field: "id", header: "Devolução", filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "data_devolucao",
            dateFormat: "dd/MM/yyyy",
            header: "Data",
            filter: true,
            align: "center",
            filterElement: DateFiltroTemplate,
        },
        {
            field: "tipo.descricao",
            header: "Tipo devolução",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "tipo__descricao",
        },
        {
            field: "fornecedor.nome",
            header: "Fornecedor",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "fornecedor__nome",
        },
        { field: "entrada", header: "Entrada", align: "center", filter: true, filterElement: CodigoFiltroTemplate },
        { field: "valor_frete", header: "Frete", money: true, filter: true, filterElement: MoedaFiltroTemplate },
        {
            field: "valor_total",
            header: "Total devolução",
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        { field: "actions", header: "Ações", action: actionBodyTemplate },
    ];

    const onFilter = (data) => {
        setUrl(data);
    };

    const rowClass = (transferencia) => {
        return {
            "table-recebimentos-pending": transferencia.status === "V",
            "table-recebimentos-overdue": transferencia.status === "C",
            "mako-table-status-ativo ": transferencia.status === "F",
            "mako-table-status-pendente": transferencia.status === "P",
        };
    };

    return (
        <PageBase>
            <Menu model={itensMenu} popup ref={menuRef} id="popup_menu" />
            <MakoListagem
                ref={listagemRef}
                titulo="Devoluções ao fornecedor"
                urlPesquisa={url}
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    data_devolucao: { value: "", matchMode: "equals" },
                    tipo__descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    fornecedor__nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    entrada: { value: "", matchMode: "equals" },
                    valor_frete: { value: null, matchMode: "equals" },
                    valor_total: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    rowClassName: rowClass,
                    lazy: true,
                    paginator: true,
                }}
            />
            <FiltroAvancadoDevolucao ref={filtroRef} onConfirm={onFilter} url={BASE_URL} />
            <MakoConfirmDialog
                visible={confirmDialog}
                setVisible={setConfirmDialog}
                message={"Tem certeza que esta devolução não requer emissão de documento fiscal?"}
                accept={() => handleFinalizar(true)}
                showErrorToast={false}
            />
        </PageBase>
    );
};
