import React, { useState, useCallback, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Dialog } from "primereact/dialog";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import Cropper from "react-easy-crop";
import getCroppedImg from "@/assets/util/imagemCrop";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { Dropdown } from "@/components/Dropdown";

const urlGaleria = "/produtos/galerias-imagens-sku/";
const urlTag = "/produtos/tags-imagens-sku/";

export const ModalFotosSku = ({ sku, exibirDialog }) => {
    const [galeriaValue, setGaleriaValue] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [cropSize, setCropSize] = useState({ width: 200, height: 200 });
    const [tagValue, setTagValue] = useState(null);
    const [imagem, setImagem] = useState(null);
    const [focoPixels, setFocoPixels] = useState(null);
    const [descricaoImg, setDescricaoImg] = useState("");
    const [exibirGaleria, setExibirGaleria] = useState(false);
    const [tagCad, setTagCad] = useState("");
    const [exibirTagImg, setExibirTagImg] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpPost } = useHttp();
    const galeriaRef = useRef();
    const tagRef = useRef();

    const formik = useFormik({
        initialValues: {
            nome: "",
            largura: 0,
            altura: 0,
        },
        onSubmit: handleSubmit,
    });

    const onCropCompleto = useCallback((croppedArea, croppedAreaPixels) => {
        setFocoPixels(croppedAreaPixels);
    }, []);

    async function uploadImagem() {
        try {
            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Imagem gravada no produto com sucesso!",
                        life: 1500,
                    });
                    exibirDialog();
                },
                400: () => showError(),
            };

            showLoading();
            await httpPost(
                {
                    url: "/produtos/produto-upload-foto/",
                    body: {
                        descricao: descricaoImg,
                        galeria: galeriaValue.id,
                        tag: tagValue,
                        imagem: await getCroppedImg(imagem, focoPixels),
                        sku: sku,
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            showError();
            hideLoading();
        }
    }

    async function handleSubmit(values) {
        try {
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Galeria de imagem sku cadastrada com sucesso!",
                            life: 1500,
                        });
                        galeriaRef.current?.fetchDados();
                        formik.resetForm();
                        esconderInserirGaleria();
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPost(
                    {
                        url: urlGaleria,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Galeria de imagem sku alterada com sucesso!",
                            life: 1500,
                        });
                        galeriaRef.current?.fetchDados();
                        formik.resetForm();
                        esconderInserirGaleria();
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPost(
                    {
                        url: `${urlGaleria}${values.id}/`,
                        body: {
                            ...values,
                            sku: sku.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                showError();
                hideLoading();
            }
        }
    }

    async function handleTagImg() {
        try {
            if (tagCad !== "") {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Galeria de imagem sku cadastrada com sucesso!",
                            life: 1500,
                        });
                        tagRef.current.fetchDados();
                        setTagCad("");
                        esconderInserirTag();
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPost(
                    {
                        url: urlTag,
                        body: {
                            tag: tagCad,
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                showError({
                    summary: "Erro",
                    detail: "Preencha o campo obrigatório",
                    life: 1500,
                });
            }
        } catch (error) {
            showError();
            hideLoading();
        }
    }

    const galeriaSkuValue = (galeria) => {
        setGaleriaValue(galeria.value);
        if (galeria.value && galeria.value.largura > 0)
            setCropSize({ width: galeria.value.largura, height: galeria.value.altura });
        else setCropSize({ width: 200, height: 200 });
    };

    const esconderInserirGaleria = () => {
        setExibirGaleria(false);
        formik.resetForm();
    };

    const esconderInserirTag = () => {
        setExibirTagImg(false);
        setTagCad("");
    };

    function validateForm() {
        if (tagCad !== "") return false;
        return true;
    }

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }

    const onFileChange = async (e) => {
        if (e.target.files?.length > 0) {
            const file = e.target.files[0];
            if (file.size < 1000000) {
                let imageDataUrl = await readFile(file);
                setImagem(imageDataUrl);
            } else {
                showWarning({
                    summary: "Falha",
                    detail: "A imagem ultrapassa o limite de permitido (1MB).",
                    life: 1500,
                });
                e.target.value = "";
            }
        }
    };

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="descricaoImg">Descrição *</label>
                    <InputText
                        id="descricaoImg"
                        name="descricaoImg"
                        placeholder="Informe a descrição como, por exemplo: 'imagem completa'"
                        value={descricaoImg}
                        onChange={(e) => setDescricaoImg(e.target.value)}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="tag">Tag</label>
                    <div className="p-inputgroup">
                        <Dropdown
                            ref={tagRef}
                            id="sku"
                            name="sku"
                            value={tagValue}
                            placeholder="Selecione uma tag"
                            optionLabel="tag"
                            url="/produtos/tags-imagens-sku/"
                            onChange={(e) => setTagValue(e.value)}
                        />
                        <MakoControleAcesso
                            permissao={[permissoes.PRODUTO_CADASTRO_TAGSIMAGEMSKU_INCLUIR]}
                            componente={Button}
                            icon="pi pi-plus"
                            className="p-button-success"
                            onClick={() => setExibirTagImg(true)}
                        />
                    </div>
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="galeria">Galeria *</label>
                    <div className="p-inputgroup">
                        <Dropdown
                            ref={galeriaRef}
                            id="galeria"
                            name="galeria"
                            value={galeriaValue}
                            placeholder="Selecione uma galeria"
                            optionLabel="nome"
                            url="/produtos/galerias-imagens-sku/?sku__id=${sku}&limit=1000"
                            showClear
                            onChange={galeriaSkuValue}
                        />
                        <MakoControleAcesso
                            permissao={[permissoes.PRODUTO_CADASTRO_GALERIAIMAGEMSKU_INCLUIR]}
                            componente={Button}
                            icon="pi pi-plus"
                            className="p-button-success"
                            onClick={() => setExibirGaleria(true)}
                        />
                    </div>
                    {galeriaValue ? (
                        <label htmlFor="galeria">
                            Dimensões da galeria:
                            {galeriaValue
                                ? galeriaValue.altura === 0
                                    ? " LIVRE"
                                    : galeriaValue.largura === 0
                                    ? " LIVRE"
                                    : ` ALTURA: ${galeriaValue.altura}px - LARGURA: ${galeriaValue.largura}px`
                                : " LIVRE"}
                        </label>
                    ) : null}
                </div>
                <p className="p-ml-3 p-mb-3">
                    <b>* Campos obrigatórios.</b>
                </p>
            </div>
            {imagem ? (
                <div className="crop-app">
                    <div className="crop-container">
                        <Cropper
                            image={imagem}
                            crop={crop}
                            zoom={zoom}
                            cropSize={cropSize}
                            onCropChange={setCrop}
                            style={{ width: "120px" }}
                            onCropSizeChange={setCropSize}
                            onCropComplete={onCropCompleto}
                            onZoomChange={setZoom}
                            minZoom={0.6}
                            zoomSpeed={0.2}
                        />
                    </div>
                    <div className="crop-controls">
                        <div className="p-fluid p-formgrid p-grid ">
                            <div className="p-field p-col-12 p-md-2">
                                <label>
                                    <b>Zoom</b>
                                </label>
                                <input
                                    type="range"
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e) => {
                                        setZoom(e.target.value);
                                    }}
                                    className="zoom-range"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="crop-complete">
                        <Button
                            style={{ marginLeft: "2px" }}
                            type="button"
                            icon="pi pi-times"
                            label="Voltar"
                            className="p-button-danger"
                            onClick={() => setImagem(null)}
                        />
                        <Button
                            style={{ marginRight: "2px" }}
                            type="button"
                            icon="pi pi-plus"
                            label="Salvar"
                            className="p-button-success"
                            onClick={() => uploadImagem()}
                        />
                    </div>
                </div>
            ) : (
                <input
                    disabled={!(descricaoImg && galeriaValue)}
                    type="file"
                    onChange={onFileChange}
                    accept="image/*"
                />
            )}
            <Dialog
                header="Cadastrar galeria de imagem"
                visible={exibirGaleria}
                style={{ width: "40vw", display: "block" }}
                onHide={() => esconderInserirGaleria()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="nome">Nome *</label>
                            <InputText
                                id="nome"
                                name="nome"
                                value={formik.values.nome}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.nome })}
                                autoComplete="off"
                                autoFocus
                            />
                            {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="altura">Altura </label>
                            <InputNumber
                                id="altura"
                                name="altura"
                                value={formik.values.altura}
                                onValueChange={formik.handleChange}
                                min={0}
                                showButtons
                                className={classNames({ "p-invalid": formik.errors.altura })}
                                autoComplete="off"
                                autoFocus
                            />
                            {formik.errors.altura && <small className="p-error">{formik.errors.altura}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="largura">Largura </label>
                            <InputNumber
                                id="largura"
                                name="largura"
                                value={formik.values.largura}
                                onValueChange={formik.handleChange}
                                min={0}
                                showButtons
                                className={classNames({ "p-invalid": formik.errors.largura })}
                                autoComplete="off"
                                autoFocus
                            />
                            {formik.errors.largura && <small className="p-error">{formik.errors.largura}</small>}
                        </div>
                    </div>

                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <br />
                    <div className="p-grid p-justify-end" style={{ paddingRight: "2%" }}>
                        <Button type="submit" icon="pi pi-check-circle" label="Salvar" />
                    </div>
                </form>
            </Dialog>
            <Dialog
                header="Cadastrar tag"
                visible={exibirTagImg}
                style={{ width: "20vw", display: "block" }}
                onHide={() => esconderInserirTag()}
            >
                <form>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="tag">Tag *</label>
                            <InputText
                                id="tag"
                                name="tag"
                                value={tagCad}
                                onChange={(e) => setTagCad(e.target.value)}
                                disabled={!validateForm}
                                autoComplete="off"
                                autoFocus
                            />
                        </div>
                    </div>

                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <br />
                    <div className="p-grid p-justify-end" style={{ paddingRight: "2%" }}>
                        <Button
                            type="button"
                            icon="pi pi-check-circle"
                            label="Inserir"
                            onClick={() => handleTagImg()}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};
