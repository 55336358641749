import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";

import { Sessoes } from "./sessoes";

import useClearRefs from "@/hooks/useClearRefs";

const Component = ({ onSuccessCallback = () => {}, edicao = false }, ref) => {
    const selecionarRef = useRef(null);
    const cadatrarRef = useRef(null);

    useClearRefs(selecionarRef, cadatrarRef);

    const buscarParametros = useCallback((filtros) => {
        selecionarRef.current?.buscar(filtros);
    }, []);

    const onSelect = useCallback((parametro) => {
        cadatrarRef.current?.setParametro(parametro);
    }, []);

    const prepararEdicao = useCallback((parametro) => {
        cadatrarRef.current?.editar(parametro);
    }, []);

    useImperativeHandle(ref, () => ({ editar: prepararEdicao }), [prepararEdicao]);

    return (
        <div>
            {!edicao && <Sessoes.Buscar onSuccessCallback={buscarParametros} />}
            <Sessoes.Cadastrar ref={cadatrarRef} onSuccessCallback={onSuccessCallback} edicao={edicao} />
            {!edicao && <Sessoes.Selecionar ref={selecionarRef} onSelect={onSelect} />}
        </div>
    );
};

export const Form = forwardRef(Component);
