import React from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { SEXO } from "@/assets/constants/constants";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const AprovacaoPreco = ({ resumo }) => {
    const [formatarDocumento] = useFormatCNPJCPF();

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="cmv">CMV</label>
                    <InputText id="cmv" disabled value={resumo.cmv} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="num-venda">Num venda</label>
                    <InputText id="cmv" disabled value={resumo.venda} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="qtd-itens-venda">Qtd. itens na venda</label>
                    <InputText id="qtd-itens-venda" disabled value={resumo.num_itens_venda} />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="plano-recebimento">Plano de recebimento</label>
                    <InputText id="plano-recebimento" disabled value={resumo.plano_recebimento} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total-venda">Valor total da venda</label>
                    <MakoInputMoeda id="valor-total-venda" disabled value={resumo.valor_total_venda} />
                </div>
            </div>

            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="doc">CPF / CNPJ</label>
                    <InputText id="doc" disabled value={formatarDocumento(resumo.cliente.doc)} />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="cliente">Cliente</label>
                    <InputText id="cliente" disabled value={resumo.cliente.nome} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="sexo">Sexo</label>
                    <InputText
                        id="sexo"
                        disabled
                        value={SEXO.find((el) => el.id === resumo.cliente.sexo)?.label || "Não informado"}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-nascimento">Data de nascimento</label>
                    <div className="p-inputgroup">
                        <InputText id="data-nascimento" disabled value={resumo.cliente.data_nascimento} />
                        <Button icon="pi pi-calendar" disabled />
                    </div>
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="qtd-vendas">Vendas do cliente</label>
                    <InputText id="qtd-vendas" disabled value={resumo.cliente.resumo.qtd_vendas} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="ticket-medio">Ticket médio</label>
                    <MakoInputMoeda id="ticket-medio" disabled valueMoeda={resumo.cliente.resumo.ticket_medio} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-primeira-venda">Data 1ª venda</label>
                    <div className="p-inputgroup">
                        <InputText
                            id="data-primeira-venda"
                            disabled
                            value={resumo.cliente.resumo.data_primeira_venda}
                        />
                        <Button icon="pi pi-calendar" disabled />
                    </div>
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="codigo-sku">Código</label>
                    <InputText id="codigo-sku" disabled value={resumo.sku?.codigo} />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="descricao-sku">Descrição do produto</label>
                    <InputText id="descricao-sku" disabled value={resumo.sku?.descricao_derivada} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="quantidade-vendida">Qtd. vendida</label>
                    <InputNumber id="quantidade-vendida" disabled value={resumo.sku?.quantidade} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total-item">Valor total do item</label>
                    <MakoInputMoeda id="valor-total-item" disabled valueMoeda={resumo.valor_total_item} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total-pretendido">Valor total pretendido</label>
                    <MakoInputMoeda id="valor-total-pretendido" disabled valueMoeda={resumo.valor_total_pretendido} />
                </div>
            </div>
        </>
    );
};
