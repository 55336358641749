import { Bloqueio } from "./bloqueio";
import { Desbloquear } from "./desbloquear";
import { Fechamento } from "./fechamento";
import { Resumo } from "./resumo";
import { Sangria } from "./sangria";
import { Suprimento } from "./suprimento";
import { Transferencia } from "./transferencia";
import { IncluirOperacao } from "./incluir-operacao";
import { Analitico } from "./analitico";

export const Buttons = {
    Fechamento,
    Bloqueio,
    Sangria,
    Transferencia,
    Desbloquear,
    Suprimento,
    Resumo,
    IncluirOperacao,
    Analitico,
};
