import React, { useCallback, useMemo, useRef, useState } from "react";

import { MakoButton as Button } from "@/components/MakoButton";

import { ListagemPendencias } from "../../features";
import { Pendencias } from "../../features/Pendencias";

import { TIPO_CHAVE_PENDENCIAS } from "@/assets/constants/financeiro";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

const { Buttons } = Pendencias;

export const PendenciasCaixa = () => {
    const [filtros, setFiltros] = useState(null);
    const { caixaMov, possuiPendencia, verificarPendencias } = useCaixaMovimento();
    const listagemRef = useRef(null);

    const atualizarListagem = useCallback(() => {
        listagemRef.current?.buscarDados();
    }, []);

    const actions = (rowData) => {
        const { tipo_chave } = rowData || {};
        let action = <></>;
        switch (tipo_chave) {
            case TIPO_CHAVE_PENDENCIAS.PAGAMENTO:
                action = <Buttons.Pagar />;
                break;
            case TIPO_CHAVE_PENDENCIAS.RECEBIMENTO_CREDIARIO:
                action = <Buttons.ReceberCrediario pendencia={rowData} onCloseModal={atualizarListagem} />;
                break;
            case TIPO_CHAVE_PENDENCIAS.VENDA_CARTAO:
                action = <Buttons.ReceberCartao />;
                break;
            case TIPO_CHAVE_PENDENCIAS.VENDA_ENTRADA:
                action = <Buttons.ReceberEntrada pendencia={rowData} onCloseModal={atualizarListagem} />;
                break;
            case TIPO_CHAVE_PENDENCIAS.VENDA_BOLETO:
                action = <Buttons.EmitirBoleto />;
                break;
            case TIPO_CHAVE_PENDENCIAS.VENDA_CREDIARIO:
                action = <Buttons.ConfirmarCrediario pendencia={rowData} onConfirm={atualizarListagem} />;
                break;
            default:
                break;
        }
        return <div className="actions actions-pendencias">{action}</div>;
    };

    const onReatualizar = useCallback(() => {
        setFiltros(null);
        verificarPendencias();
    }, [verificarPendencias]);

    const painelEsquerdo = useMemo(() => {
        if (!possuiPendencia) return null;
        const onClick = () => setFiltros({ recebimentos: true, multiplos_pendentes: true });
        return (
            <div className="p-d-flex p-ai-center">
                <span className="p-error">Existe recebimento múltiplo pendente de finalização:</span>
                <Button
                    label="Exibir"
                    onClick={onClick}
                    text
                    className="p-button-danger p-p-0 p-ml-1"
                    style={{ textDecoration: "underline" }}
                />
            </div>
        );
    }, [possuiPendencia]);

    return (
        <>
            <ListagemPendencias
                painelEsquerdo={painelEsquerdo}
                listagemRef={listagemRef}
                caixaMovimento={caixaMov}
                actions={actions}
                filtros={filtros}
                onReatualizar={onReatualizar}
            />
        </>
    );
};
