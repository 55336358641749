import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { gerarStatusBooleanTemplate, parseNumberToMoneyHTML } from "@/assets/util/util";
import {
    BooleanFiltroTemplate,
    CodigoFiltroTemplate,
    TextoFiltroTemplate,
    TimeFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";

export const RotaEntregaPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_ROTAENTREGA_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de rotas"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/vendas/cadastros/rota-entrega/form",
                        state: { rotas: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_ROTAENTREGA_EXCLUIR]}
                    componente={BotaoDelete}
                    objetoId={rowData.id}
                    url="/vendas/rotas-entrega/"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir a rota "}
                            <b>{rowData.descricao}</b>?
                        </span>
                    }
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_ROTAENTREGA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/vendas/cadastros/rota-entrega/form"}
            />
        </>
    );

    const TimesFiltroTemplate = (options) => {
        return <TimeFiltroTemplate inputProps={{ showSeconds: true, placeholder: "HH:mm:ss" }} {...options} />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { minWidth: "220px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "hora_inicial",
            header: "Hora inicial",
            style: { minWidth: "120px" },
            filter: true,
            filterElement: TimesFiltroTemplate,
            action: (e) => `${formatStringTime(e.hora_inicial)}`,
        },
        {
            field: "hora_final",
            header: "Hora final",
            style: { minWidth: "120px" },
            filter: true,
            filterElement: TimesFiltroTemplate,
            action: (e) => `${formatStringTime(e.hora_final)}`,
        },
        {
            field: "valor_frete",
            header: "Valor frete",
            style: { minWidth: "120px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_frete),
        },
        {
            field: "segunda",
            header: "Seg.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => gerarStatusBooleanTemplate(e.segunda),
        },
        {
            field: "terca",
            header: "Ter.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => gerarStatusBooleanTemplate(e.terca),
        },
        {
            field: "quarta",
            header: "Qua.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => gerarStatusBooleanTemplate(e.quarta),
        },
        {
            field: "quinta",
            header: "Qui.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => gerarStatusBooleanTemplate(e.quinta),
        },
        {
            field: "sexta",
            header: "Sex.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => gerarStatusBooleanTemplate(e.sexta),
        },
        {
            field: "sabado",
            header: "Sáb.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => gerarStatusBooleanTemplate(e.sabado),
        },
        {
            field: "domingo",
            header: "Dom.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => gerarStatusBooleanTemplate(e.domingo),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "120px" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    const formatStringTime = (time) => {
        const splitedTime = time.split(":");
        return splitedTime[0] + ":" + splitedTime[1];
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Rotas de Entrega"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={"/vendas/rotas-entrega/"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                    scrollHeight: "400px",
                    frozenWidth: "0.5vw",
                    flexGrow: 1,
                    flexBasis: "12rem",
                }}
                filter={{
                    id: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "contains" }],
                    },
                    hora_inicial: { value: null, matchMode: "equals" },
                    hora_final: { value: null, matchMode: "equals" },
                    valor_frete: { value: null, matchMode: "equals" },
                    segunda: { value: null, matchMode: "equals" },
                    terca: { value: null, matchMode: "equals" },
                    quarta: { value: null, matchMode: "equals" },
                    quinta: { value: null, matchMode: "equals" },
                    sexta: { value: null, matchMode: "equals" },
                    sabado: { value: null, matchMode: "equals" },
                    domingo: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
