import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import { MakoCalendar } from "@/components/MakoCalendar";
import { NATUREZA_PLANO_CONTAS } from "@/assets/constants/constants";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { dataToStr } from "@/assets/util/datas";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useHttp from "@/hooks/useHttp";
import useAuth from "@/hooks/useAuth";

export const LancarMovimentacaoModalForm = ({
    toast,
    listagem,
    esconderDialog,
    lancarMovimentacao = false,
    setLancarMovimentacao,
    movimentacao,
    carregaIndicadores,
}) => {
    const { showLoading, hideLoading } = useLoading();
    const { httpPost } = useHttp();
    const { user } = useAuth();

    const { setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            id: null,
            tipo_movimento: "C",
            data_movimentacao: new Date(),
            valor: 0,
            conta_financeira: null,
            caixa_movimento: null,
            plano_contas: null,
            compensado: false,
            cancelado: false,
            historico: "",
            forma_pagamento: null,
            usuario_lancamento: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_movimento: Yup.string().required("O campo 'tipo' é obrigatório."),
                data_movimentacao: Yup.date().required("O campo 'data da movimentação' é obrigatório."),
                valor: Yup.number()
                    .min(0.01, "Informe um valor maior do que 0.")
                    .required("O campo 'valor' é obrigatório."),
                conta_financeira: Yup.number().required("O campo 'conta financeira' é obrigatório."),
                historico: Yup.string().max(60).required("O campo 'histórico' é obrigatório."),
                plano_contas: Yup.number().required("O campo 'plano de contas' é obrigatório."),
                forma_pagamento: Yup.number()
                    .required("O campo 'forma' é obrigatório")
                    .typeError("Informe uma 'forma' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let success = false;
            const handlers = {
                201: () => {
                    success = true;
                    toast.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Lançamento cadastrado com sucesso!",
                        life: 3000,
                    });
                    resetForm();
                    carregaIndicadores();
                    listagem.current?.buscarDados();
                    esconderDialog(false);
                },
            };

            showLoading();
            await httpPost(
                {
                    url: "/financeiro/movimentacao-contas-corrente/",
                    body: {
                        ...values,
                        data_movimentacao: dataToStr(values.data_movimentacao, "yyyy-MM-dd hh:mm:ss"),
                        usuario_lancamento: user?.id,
                    },
                },
                handlers
            );
            hideLoading();

            if (!success)
                toast.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 2000,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toast.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
            }
        }
    }

    useEffect(() => {
        setFieldValue("historico", movimentacao?.historico);
    }, [setFieldValue, movimentacao?.historico]);

    return (
        <Dialog
            header={`Lançamento de Movimentação`}
            visible={lancarMovimentacao}
            breakpoints={{ "960px": "80vw" }}
            style={{ width: "70vw" }}
            onHide={() => setLancarMovimentacao(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo_movimento">Tipo *</label>
                        <Dropdown
                            id="tipo_movimento"
                            name="tipo_movimento"
                            placeholder="Selecione uma conta financeira"
                            options={NATUREZA_PLANO_CONTAS}
                            filter
                            filterBy="label"
                            optionValue="id"
                            optionLabel="label"
                            showClear={false}
                            value={formik.values.tipo_movimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_movimento })}
                        />
                        {formik.errors.tipo_movimento && (
                            <small className="p-error">{formik.errors.tipo_movimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="conta_financeira">Conta Financeira *</label>
                        <Dropdown
                            id="conta_financeira"
                            name="conta_financeira"
                            placeholder="Selecione uma conta financeira"
                            url="/financeiro/contas-financeiras/"
                            filter
                            filterBy="descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.conta_financeira}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                        />
                        {formik.errors.conta_financeira && (
                            <small className="p-error">{formik.errors.conta_financeira}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_movimentacao">Data movimentação *</label>
                        <MakoCalendar
                            id="data_movimentacao"
                            name="data_movimentacao"
                            valueCalendar={formik.values.data_movimentacao}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_movimentacao,
                            })}
                        />
                        {formik.errors.data_movimentacao && (
                            <small className="p-error">{formik.errors.data_movimentacao}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="forma_pagamento">Forma *</label>
                        <Dropdown
                            id="forma_pagamento"
                            name="forma_pagamento"
                            placeholder="Selecione uma forma"
                            options={TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.forma_pagamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                        />
                        {formik.errors.forma_pagamento && (
                            <small className="p-error">{formik.errors.forma_pagamento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="plano_contas">Plano de contas *</label>
                        <Dropdown
                            id="plano_contas"
                            name="plano_contas"
                            placeholder="Selecione uma conta financeira"
                            url="/financeiro/planos-contas-financeiras?limit=10000"
                            filter
                            filterBy="descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.plano_contas}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.plano_contas })}
                        />
                        {formik.errors.plano_contas && <small className="p-error">{formik.errors.plano_contas}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor">Valor *</label>
                        <MakoInputMoeda
                            id="valor"
                            name="valor"
                            valueMoeda={formik.values.valor}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor })}
                        />
                        {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="caixa_movimento">Histórico *</label>
                        <InputTextarea
                            id="historico"
                            name="historico"
                            rows={8}
                            value={formik.values.historico}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.historico })}
                        />
                        {formik.errors.historico && <small className="p-error">{formik.errors.historico}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-12 p-justify-end">
                        <br></br>
                        <Checkbox
                            inputId="compensado"
                            id="compensado"
                            name="compensado"
                            onChange={formik.handleChange}
                            checked={formik.values.compensado}
                            value={formik.values.compensado}
                        />
                        <label htmlFor="compensado">
                            <b>Lançar compensado?</b>
                        </label>
                    </div>
                </div>
                <div className="p-grid p-justify-end p-mt-1 p-mr-1">
                    <Button
                        className="p-button-danger p-button-text"
                        icon="pi pi-times"
                        type="reset"
                        label="Fechar"
                        onClick={() => esconderDialog()}
                    />
                    <Button className="p-button-success" icon="pi pi-save" type="submit" label="Gravar" />
                </div>
            </form>
        </Dialog>
    );
};
