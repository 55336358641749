import React, { useCallback, useMemo, useRef } from "react";

import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { RELATORIO_VENDAS_TABELACONTROLEENTREGA, RELATORIO_VENDAS_TABELAROMANEIO } from "@/assets/constants/relatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

import useClearRefs from "@/hooks/useClearRefs";
import useRelatorio from "@/hooks/useRelatorio";
import useAuth from "@/hooks/useAuth";

const { RELATORIOS_EMITIR_VENDAS_ROMANEIO, RELATORIOS_EMITIR_VENDAS_CONTROLEENTREGA } = permissoes;

export const Documento = ({ selecionados = [], className = "", disabled = false }) => {
    const { solicitarRelatorio } = useRelatorio();
    const { verifyPermission } = useAuth();

    const menuRef = useRef(null);

    const gerarRelatorio = useCallback(
        (chave, ids, filtros = {}) => {
            const filtered = ids.filter((item, index) => ids.indexOf(item) === index);
            if (chave === RELATORIO_VENDAS_TABELACONTROLEENTREGA)
                solicitarRelatorio({ chave, filtros: { vendas: filtered, ...filtros } });
            else solicitarRelatorio({ chave, filtros: { items_previsoes: filtered }, enviar_fila: false });
        },
        [solicitarRelatorio]
    );

    useClearRefs(menuRef);

    const items = useMemo(() => {
        return [
            {
                label: "Selecione um tipo de documento para gerar",
                items: [
                    {
                        label: "Romaneio",
                        command: () =>
                            gerarRelatorio(
                                RELATORIO_VENDAS_TABELAROMANEIO,
                                selecionados?.map((item) => item.id)
                            ),
                        disabled: !verifyPermission([RELATORIOS_EMITIR_VENDAS_ROMANEIO]),
                    },
                    {
                        label: "Controle de entrega",
                        command: () =>
                            gerarRelatorio(
                                RELATORIO_VENDAS_TABELACONTROLEENTREGA,
                                selecionados?.map((item) => item.venda_id),
                                { items_vendas: selecionados?.map((item) => item.id) }
                            ),
                        disabled: !verifyPermission([RELATORIOS_EMITIR_VENDAS_CONTROLEENTREGA]),
                    },
                ],
            },
        ];
    }, [gerarRelatorio, selecionados, verifyPermission]);

    const onClick = useCallback((e) => menuRef.current.toggle(e), []);

    return (
        <>
            <MakoControleAcesso
                componente={Button}
                permissao={[RELATORIOS_EMITIR_VENDAS_ROMANEIO, RELATORIOS_EMITIR_VENDAS_CONTROLEENTREGA]}
                label="Gerar documento"
                icon={MAKO_ICONS.PESQUISAR}
                className={`p-button-info ${className}`}
                aria-controls="popup_menu"
                aria-haspopup
                disabled={!selecionados?.length || disabled}
                onClick={onClick}
            />
            <Menu model={items} popup ref={menuRef} id="popup_menu" />
        </>
    );
};
