import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLoading from "@/hooks/useLoading";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";

const url = "/produtos/tags-imagens-sku/";

export const ProdutoTagsImgForm = () => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { state } = useLocation();
    const { httpPost, httpPatch } = useHttp();

    const { resetForm, ...formik } = useFormik({
        initialValues: state
            ? state.tagImg
            : {
                  id: "",
                  tag: "",
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tag: Yup.string().required("O campo 'tag' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tag de imagem cadastrada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/produto/cadastros/tags-imagens");
                    },
                };

                showLoading();
                await httpPost({ url: url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Tag de imagem alterada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/produto/cadastros/tags-imagens");
                    },
                };

                showLoading();
                await httpPatch({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        resetForm();
        history.push("/produto/cadastros/tags-imagens");
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova Tag de Imagem do Produto" : "Manutenção de Tag de Imagem do Produto"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="tag">Tag *</label>
                        <InputText
                            id="tag"
                            name="tag"
                            value={formik.values.tag}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tag })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.tag && <small className="p-error">{formik.errors.tag}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    <Button
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
