import React, { useCallback, useRef } from "react";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

import { Sessoes } from "./sessoes";
import { Buttons } from "./buttons";

import useReceberCrediario from "../../../hooks/useReceberCrediario";

export const Parcelas = () => {
    const formDadosRef = useRef();

    const {
        listagemParcelasRef,
        atualizarListagemParcelas,
        verificarNecessidadeCalcular,
        atualizarInfo,
        handlerOnRemoverParcela,
    } = useReceberCrediario();

    const handleCalcular = useCallback(({ recebimento }) => {
        const {
            id,
            venda,
            devedor,
            vencimento,
            dias_atraso,
            numero_parcela,
            quantidade_parcelas,
            descontos = 0,
            multa = 0,
            juros = 0,
            valor = 0,
            valor_recebido = 0,
            template_rateio,
        } = recebimento || {};
        formDadosRef.current?.prepareEdit({
            id,
            venda,
            vencimento,
            descontos,
            multa,
            juros,
            cliente: devedor,
            atraso: dias_atraso,
            parcela: `${numero_parcela}/${quantidade_parcelas}`,
            valor: parseFloat(valor) - parseFloat(valor_recebido),
            template_rateio: template_rateio?.id,
        });
    }, []);

    const onSuccessCallbackCancelar = useCallback(
        (values) => {
            atualizarListagemParcelas();
            handlerOnRemoverParcela(values);
        },
        [atualizarListagemParcelas, handlerOnRemoverParcela]
    );

    const actions = useCallback(
        (rowData) => {
            const { valor_recebido, recebimento } = rowData;
            const disabledCalcular = valor_recebido > 0;
            const onClickCalcular = () => handleCalcular(rowData);
            return (
                <MakoActionsButtonsColumn>
                    <Buttons.Calcular onClick={onClickCalcular} disabled={disabledCalcular} />
                    <Buttons.Cancelar pendenciaId={recebimento?.id} onSuccessCallback={onSuccessCallbackCancelar} />
                </MakoActionsButtonsColumn>
            );
        },
        [handleCalcular, onSuccessCallbackCancelar]
    );

    const dadosSuccessCallback = useCallback(() => {
        verificarNecessidadeCalcular();
        atualizarListagemParcelas();
        atualizarInfo();
    }, [atualizarListagemParcelas, verificarNecessidadeCalcular, atualizarInfo]);

    const buscaSuccessCallback = useCallback(() => {
        verificarNecessidadeCalcular();
        atualizarListagemParcelas();
        atualizarInfo();
    }, [atualizarListagemParcelas, verificarNecessidadeCalcular, atualizarInfo]);

    return (
        <>
            <Sessoes.Dados ref={formDadosRef} successCallback={dadosSuccessCallback} />
            <Sessoes.BuscarParcela onSuccessCallback={buscaSuccessCallback} />
            <Sessoes.Listagem listagemRef={listagemParcelasRef} actions={actions} />
        </>
    );
};
