import React from "react";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

const { VENDAS_VENDA_CARGAS_EDITAR } = permissoes;

export const Editar = ({ onClick = () => {}, disabled = false }) => {
    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[VENDAS_VENDA_CARGAS_EDITAR]}
            tooltip="Editar carga"
            type="button"
            tooltipOptions={{ position: "left" }}
            icon={MAKO_ICONS.EDITAR}
            className={classNames("p-button p-button-warning")}
            onClick={onClick}
            disabled={disabled}
        />
    );
};
