import { useMemo } from "react";
import useFetchRelatorio from "./useFetchRelatorio";
import useRelatorio from "./useRelatorio";

const useRelatorioCSV = ({ chave = "" }) => {
    const { versoes } = useFetchRelatorio({ chave });
    const { solicitarRelatorio } = useRelatorio();

    const versoesCsv = useMemo(() => {
        return versoes ? versoes.filter((v) => v.formato_saida === "csv") : [];
    }, [versoes]);

    return { versoes: versoesCsv, solicitarRelatorio };
};

export default useRelatorioCSV;
