// Compras
export const RELATORIO_COMPRAS_ORDEMCOMPRA = "compras.ordemcompra";
export const RELATORIO_COMPRAS_CARNEPARCELAOC = "compras.carneparcelasoc";

// Estoque
export const RELATORIO_ESTOQUE_SALDOINICIAL = "estoque.saldoinicial";
export const RELATORIO_ESTOQUE_SALDOOC = "estoque.saldooc";
export const RELATORIO_ESTOQUE_NECESSIDADECOMPRA = "estoque.necessidadecompra";
export const RELATORIO_ESTOQUE_SALDOPRODUTOS = "estoque.saldoprodutos";
export const RELATORIO_ESTOQUE_TRANSFERENCIAFILIAL = "estoque.transferenciafilial";
export const RELATORIO_ESTOQUE_ROMANEIOTRANSFERENCIA = "estoque.romaneiotransferencia";
export const RELATORIO_ESTOQUE_CUSTOSALDOESTOQUES = "estoque.custosaldoestoques";
export const RELATORIO_ESTOQUE_SALDOGERAL = "estoque.saldogeral";
export const RELATORIO_ESTOQUE_SALDOESTOQUE = "estoque.saldoestoques";

// Financeiro
export const RELATORIO_FINANCEIRO_CONTASAPAGAR = "financeiro.contasapagar";
export const RELATORIO_FINANCEIRO_CONTASARECEBER = "financeiro.contasareceber";
export const RELATORIO_FINANCEIRO_MOVIMENTACAOCAIXA = "financeiro.movimentacaocaixa";
export const RELATORIO_FINANCEIRO_EXTRATOCONTA = "financeiro.extratoconta";
export const RELATORIO_FINANCEIRO_MOVIMENTACAOCONTAFINANCEIRA = "financeiro.movimentacaocontafinanceira";
export const RELATORIOS_EMITIR_FINANCEIRO_CARNERECEBIMENTOS = "relatorios.emitir.financeiro.carnerecebimetos";
export const RELATORIOS_EMITIR_FINANCEIRO_RECIBOQUITACAO = "financeiro.reciboquitacao";
export const RELATORIOS_EMITIR_FINANCEIRO_CONTASRECEBIDAS = "financeiro.contasrecebidas";
export const RELATORIOS_EMITIR_FINANCEIRO_CONTASPAGAS = "financeiro.contaspagas";

// Pessoas
export const RELATORIO_PESSOAS_RELACAOPESSOAS = "pessoas.relacaopessoas";

// Produtos
export const RELATORIO_PRODUTOS_TABELAPRECO = "produtos.tabelapreco";
export const RELATORIO_PRODUTOS_INVENTARIOPRODUTO = "produtos.inventarioproduto";
export const RELATORIO_PRODUTOS_MOVIMENTACAOPRODUTOS = "produtos.movimentacaoprodutos";
export const RELATORIO_PRODUTOS_RELACAOPRODUTOS = "produtos.relacaoprodutos";
export const RELATORIO_PRODUTOS_RELACAOPISCOFINS = "produtos.relacaopiscofins";
export const RELATORIO_PRODUTOS_SALDOESTOQUES = "produtos.saldoestoques";
export const RELATORIOS_PRODUTOS_PRECOPORFORNECEDOR = "produtos.precoporfornecedor";
export const RELATORIOS_PRODUTOS_LOTES = "produtos.lotes";

// Materias
export const RELATORIO_MATERIAIS_RECIBOSAIDA = "materiais.recibosaida";

// Vendas
export const RELATORIO_VENDAS_CONTRATOVENDA = "vendas.contratovenda";
export const RELATORIO_VENDAS_FICHACREDIARIO = "vendas.fichacrediario";
export const RELATORIO_VENDAS_TABELACONTROLEENTREGA = "vendas.controleentrega";
export const RELATORIO_VENDAS_TABELAROMANEIO = "vendas.tabelaromaneio";
export const RELATORIO_VENDAS_TABELAPRODUTOSVENDA = "vendas.tabelaprodutosvenda";
export const RELATORIO_VENDAS_RECIBO_VENDAS = "vendas.recibovenda";
export const RELATORIO_VENDAS_CURVAABCPDF = "vendas.curvaabcgrafico";
export const RELATORIO_VENDAS_ORCAMENTORAPIDO = "vendas.orcamentorapido";
export const RELATORIO_VENDAS_EXTRATOSINTETICOCOMISSAO = "vendas.extratosinteticocomissao";
export const RELATORIO_VENDAS_LISTAGEMSIMPLES = "vendas.listagemsimples";
export const RELATORIO_FINANCEIRO_EXTRATOCOMISSAOANALITiCO = "vendas.extratocomissaoanalitico";
export const RELATORIO_VENDAS_HISTORICOSIMPLESCLIENTE = "vendas.historicosimplescliente";
export const RELATORIO_VENDAS_ORCAMENTO = "vendas.orcamento";
export const RELATORIO_VENDAS_ENTREGA = "vendas.entrega";
export const RELATORIO_VENDAS_ENTREGAS_REALIZADAS = "vendas.entregas.realizadas";
export const RELATORIO_VENDAS_CARNEPARCELASVENDA = "vendas.carneparcelasvenda";
export const RELATORIO_VENDAS_PRODUTOSVENDIDOS = "vendas.produtosvendidos";
export const RELATORIO_VENDAS_ITENSFATURADOSVENDA = "vendas.itensfaturadosvenda";
export const RELATORIO_VENDAS_ASSISTENCIATECNICA = "vendas.assistenciatecnica";

// Servicos
export const RELATORIO_SERVICOS_CONTRATOSSERVICO = "servicos.contratosservicosatrasos";
export const RELATORIOS_EMITIR_SERVICOS_CONTRATOSSERVICOSATRASOS =
    "relatorios.emitir.servicos.contratosservicosatrasos";

// Fiscal
export const RELATORIO_FISCAL_TRANSFERENCIAPRODUTOS = "fiscal.transferenciaprodutos";

// Logs
export const RELATORIO_LOGS_LOGS = "logs.logs";

// Configuracoes
export const RELATORIO_CONFIGURACOES_PERMISSOESUSUARIOS = "configuracoes.permissoesusuarios";
