import React from "react";

import { VendaProvider } from "@/contexts/vendaContext";
import { TabVendas } from "./tabs";

export const TabVendasContext = (props) => {
    const idVenda = props.location.state?.idVenda;

    return (
        <VendaProvider>
            <TabVendas vendaId={idVenda} />
        </VendaProvider>
    );
};
