import React, { useRef } from "react";
import { Button } from "primereact/button";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { BotaoDelete } from "@/components/BotaoDelete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";

const url = "/produtos/marcas-fabricantes/";

export const ProdutoMarcasFabricantePage = () => {
    const listagemRef = useRef(null);
    const { showSuccess } = useToast();
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_MARCAFABRICANTE_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar cadastro de marca de fabricante"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/cadastros/marcas-fabricante/form",
                            state: { marcasFabricante: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_MARCAFABRICANTE_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente excluir o vinculo entrega marca e fabricante?</span>}
                    msgToastErroExclusao="A marca do fabricante não pode ser excluída."
                    tooltip="Deletar marca do fabricante"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Marca do fabricante removida com sucesso.",
                            life: 1500,
                        });
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_MARCAFABRICANTE_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/produto/cadastros/marcas-fabricante/form")}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", filter: true, filterElement: CodigoFiltroTemplate, style: { width: "12%" } },
        { field: "marca.nome", header: "Marca", filter: true, filterField: "marca__nome", style: { width: "15%" } },
        {
            field: "perfil_fabricante.nome",
            header: "Fabricante",
            filter: true,
            filterElemente: TextoFiltroTemplate,
            filterField: "perfil_fabricante__nome",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { paddingLeft: "2%", width: "20%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Marcas de Fabricantes"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    perfil_fabricante__nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    marca__nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
