import React, { useEffect } from "react";

import { Button as B } from "primereact/button";

import { INITIAL_VALUES } from "./form";

import { totalizadorFiltrosAplicados } from "@/assets/util/util";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const Button = ({ filtros, totalizadorFiltros, setTotalizadorFiltros = () => {}, className = "", ...rest }) => {
    useEffect(() => {
        if (filtros) setTotalizadorFiltros(totalizadorFiltrosAplicados(INITIAL_VALUES, filtros));
    }, [filtros, setTotalizadorFiltros]);

    return (
        <B
            label="Filtro avançado"
            icon={MAKO_ICONS.FILTRAR}
            className={`p-button-help ${className}`}
            badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
            {...rest}
        />
    );
};
