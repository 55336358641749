import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";

export const PerfilFiltroTemplate = (options) => {
    return (
        <MakoInputPerfil
            value={options.value}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            className="p-column-filter"
        />
    );
};
