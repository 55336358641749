import React, { createContext, useCallback, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import useLocalStorage from "use-local-storage";

import {
    FINANCEIRO_PLANODECONTAS_PADRAO_SANGRIA,
    FINANCEIRO_PLANODECONTAS_PADRAO_SUPRIMENTO,
    FINANCEIRO_PLANODECONTAS_PADRAO_TRANSFERENCIA,
} from "@/assets/constants/parametros";
import storageKeys from "@/assets/constants/storage";

import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

const URL_VERIFICAR_PENDENCIAS = "/financeiro/caixas/{id}/pendencias/verificar-pendencias/";

const CaixaMovimentoContext = createContext({});

const PADRAO_PLANO = {
    sangria: null,
    suprimento: null,
    transferencia: null,
};

export const CaixaMovimentoProvider = ({ children }) => {
    const [storageCaixa, setStorageCaixa] = useLocalStorage(storageKeys.CAIXA_KEY);
    const [padraoPlanos, setPadraoPlanos] = useState(PADRAO_PLANO);
    const [possuiPendencia, setPossuiPendencia] = useState(false);
    const { pathname } = useLocation();

    const { httpGet } = useHttp();
    const { user } = useAuth();

    const { showWarning, showError } = useToast();
    const { getParam } = useParam();

    const setCaixaUsuario = useCallback(
        (cx, clear = false) => {
            setStorageCaixa(cx);
        },
        [setStorageCaixa]
    );

    const atualizarResumos = useCallback(
        (resumos = []) => {
            setStorageCaixa((prev) => ({ ...prev, resumos }));
        },
        [setStorageCaixa]
    );

    const loadCaixa = useCallback(async () => {
        if (user) {
            let _caixa = null;
            const handlers = {
                200: ({ data }) => {
                    if (data.results?.length > 0) _caixa = data.results[0];
                },
            };
            await httpGet(
                { url: `/financeiro/caixas-movimento/?operador=${user.id}&datahora_fechamento__isnull=True` },
                handlers
            );
            setCaixaUsuario(_caixa);
            return _caixa;
        }
    }, [setCaixaUsuario, user, httpGet]);

    const clearCaixa = useCallback(() => {
        setStorageCaixa(null);
    }, [setStorageCaixa]);

    const obterCaixaAbertoMaisRecentePorEmpresa = useCallback(
        async (empresaId) => {
            let _dados = [];
            const handlers = {
                200: ({ data }) => {
                    _dados = data;
                },
                400: () =>
                    showError({
                        summary: "Erro :(",
                        detail: "Não foi possível obter o caixa aberto mais recente.",
                        life: 3000,
                    }),
            };

            await httpGet({ url: `/financeiro/caixas/caixa-aberto/?empresa=${empresaId}` }, handlers);

            if (Object.keys(_dados).length === 0) return null;
            return _dados;
        },
        [httpGet, showError]
    );

    const verifyParams = useCallback(() => {
        if (pathname?.includes("gerenciar-caixa")) {
            let _padrao = PADRAO_PLANO;
            let _params = getParam(FINANCEIRO_PLANODECONTAS_PADRAO_SANGRIA);
            if (_params) {
                _padrao.sangria = _params.valor;
            }

            _params = getParam(FINANCEIRO_PLANODECONTAS_PADRAO_SUPRIMENTO);
            if (_params) {
                _padrao.suprimento = _params.valor;
            }

            _params = getParam(FINANCEIRO_PLANODECONTAS_PADRAO_TRANSFERENCIA);
            if (_params) {
                _padrao.transferencia = _params.valor;
            }

            let message = "Você precisa definir o plano de contas padrão para o parâmetro:";
            let hasNull = false;

            Object.keys(_padrao).forEach((key) => {
                if (_padrao[key] === null) {
                    hasNull = true;
                    message = `${message} ${key},`;
                }
            });

            if (hasNull) {
                showWarning({
                    summary: "Alerta :(",
                    detail: message.slice(0, message.length - 1),
                    life: 5000,
                });
            } else {
                setPadraoPlanos(_padrao);
            }
        }
    }, [getParam, pathname, showWarning]);

    const verificarPendencias = useCallback(
        async (signal) => {
            if (storageCaixa?.id && pathname?.includes("gerenciar-caixa")) {
                const handlers = {
                    200: ({ data }) => {
                        const { possui_pendencia } = data;
                        setPossuiPendencia(possui_pendencia);
                    },
                };
                const url = URL_VERIFICAR_PENDENCIAS.replace("{id}", storageCaixa?.id);
                await httpGet({ url, signal }, handlers);
            }
        },
        [httpGet, pathname, storageCaixa?.id]
    );

    useEffect(() => {
        verifyParams();
    }, [verifyParams]);

    useEffect(() => {
        const abortController = new AbortController();
        verificarPendencias(abortController.signal);
        return function clear() {
            abortController.abort();
        };
    }, [verificarPendencias]);

    useEffect(() => loadCaixa(), [loadCaixa]);

    return (
        <CaixaMovimentoContext.Provider
            value={{
                loadCaixa,
                clearCaixa,
                obterCaixaAbertoMaisRecentePorEmpresa,
                padraoPlanos,
                caixaMov: storageCaixa,
                setCaixaUsuario,
                atualizarResumos,
                possuiPendencia,
                verificarPendencias,
            }}
        >
            {children}
        </CaixaMovimentoContext.Provider>
    );
};

export default CaixaMovimentoContext;
