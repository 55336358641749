import { DropdownFiltroTemplate } from "../DropdownFiltroTemplate";

export const AtivoFiltroTemplate = (options) => {
    return (
        <DropdownFiltroTemplate
            dropdownProps={{
                optionValue: "value",
                optionLabel: "label",
                options: [
                    { value: true, label: "Ativo" },
                    { value: false, label: "Inativo" },
                ],
            }}
            options={options}
        />
    );
};
