import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosGet, axiosPatch, axiosPost, axiosPut } from "@/services/http";
import { EnvioEmailFormModal } from "./modal/formEmailModal";
import { CancelaNotaFormModal } from "./modal/formCancelaModal";
import { CartaCorrecaoFormModal } from "./modal/formCartaCorrecao";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useClearRefs from "@/hooks/useClearRefs";
import { ModalFiltroAvancadoNf } from "./modal/formFiltroAvancado";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { key_filtros } from "@/assets/constants/filtros";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import { ContingenciaFormModal } from "./modal/formContingenciaModal";
import { parseData } from "@/assets/util/datas";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useAuth from "@/hooks/useAuth";
import { ModalImpressoras } from "@/components/ModalImpressoras";
import { PageBase } from "@/components/PageBase";
import { MakoSituacaoSefaz } from "@/components/MakoSituacaoSefaz";

export const ListaNotaFiscalPage = () => {
    const [filtros, setFiltro, removerFiltro, filtroString] = useLocalFiltro(key_filtros.FISCAL_NOTA_FISCAL);

    const [corpoNota, setCorpoNota] = useState(null);
    const [notaFiscal, setNotaFiscal] = useState(null);
    const [apiServico, setApiServico] = useState(null);
    const [cancelDialog, setCancelDialog] = useState(false);
    const [emissaoDialog, setEmissaoDialog] = useState(false);
    const [notaSelecionada, setNotaSelecionada] = useState(null);
    const [carregandoNota, setCarregandoNota] = useState(false);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const [formatarDocumento] = useFormatCNPJCPF();
    const { empresaSelecionadaId } = useEmpresa();
    const { verifyPermission } = useAuth();
    const modalImpressorasRef = useRef(null);
    const BASE_URL = `/fiscal/notas-fiscais?query={id, destinatario, numero, serie, modelo, status, valor_total_nf, chave_nf, protocolo, datahora_emissao}`;

    const [url, setUrl] = useState(() => {
        if (filtros) return `${BASE_URL}&${filtroString}`;
        return BASE_URL;
    });

    const { showLoading, hideLoading } = useLoading();
    const { showWarning, showError, showSuccess } = useToast();

    const menu = useRef(null);
    const listagemRef = useRef(null);
    const modalFiltroAvancadoRef = useRef(null);
    const history = useHistory();
    const emailRef = useRef(null);
    const cancelaRef = useRef(null);
    const cartaRef = useRef(null);
    const contingenciaRef = useRef(null);

    useClearRefs(listagemRef, modalFiltroAvancadoRef, menu);

    const carregarNotaFiscal = useCallback(
        async (nota) => {
            setCarregandoNota(true);
            const resposta = await axiosGet(`/fiscal/notas-fiscais?id=${nota.id}`);
            if (resposta.status === 200) {
                setNotaFiscal(resposta.data.results[0]);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Falha ao carregar a nota!",
                    life: 3000,
                });
            }
            setCarregandoNota(false);
        },
        [showError]
    );

    const montaNota = useCallback(async () => {
        if (notaFiscal) {
            if (notaFiscal.versao_processo === "MigrateInvoiCy") {
                const resposta = await axiosPost(`/fiscal/nota-fiscal-invoicy/`, notaFiscal);
                if (resposta.status === 200) setCorpoNota(resposta.data.nota);
                else
                    showWarning({
                        summary: "Informações incompletas",
                        detail: `Verifique as informações no formulário da nota e tente novamente. (${resposta.data?.erro})`,
                        life: 3000,
                    });
            }
        }
    }, [notaFiscal, showWarning]);

    useEffect(() => {
        montaNota();
    }, [notaFiscal, montaNota]);

    async function enviarNota(operacao) {
        try {
            const resposta = await axios.post(
                `${process.env.REACT_APP_INTEGRACAO_FISCAL}/documents/${apiServico.chave_acesso}/${
                    apiServico.cnpj_integrado
                }/${notaFiscal.modelo === 55 ? "nfe" : "nfce"}/${operacao}`,
                corpoNota
            );

            if (resposta.data?.length > 0)
                if (resposta.data[0].Codigo === 100) {
                    if (
                        resposta.data[0].Documentos[0].Situacao.SitDescricao === "Autorizado o uso da NF-e" ||
                        operacao === "pre-visualizar"
                    )
                        showSuccess({
                            summary: "Sucesso!",
                            detail: `${resposta.data[0].Descricao}!
                                \n
                                Código: ${resposta.data[0].Documentos[0].Situacao.SitCodigo}
                                \n
                                ${
                                    operacao === "emitir"
                                        ? `${
                                              resposta.data[0].Documentos
                                                  ? resposta.data[0].Documentos[0].Situacao.SitDescricao
                                                  : ""
                                          }`
                                        : ""
                                }`,
                            life: 10000,
                        });
                    else
                        showWarning({
                            summary: "Aviso!",
                            detail: `${resposta.data[0].Descricao}!
                                \n
                                Código: ${resposta.data[0].Codigo}
                                \n
                                ${
                                    operacao === "emitir"
                                        ? `${
                                              resposta.data[0].Documentos
                                                  ? resposta.data[0].Documentos[0].Situacao.SitDescricao
                                                  : ""
                                          }`
                                        : ""
                                }`,
                            life: 10000,
                        });

                    if (
                        resposta.data[0].Documentos[0].Situacao.SitDescricao === "Autorizado o uso da NF-e" ||
                        resposta.data[0].Documentos[0].Situacao.SitCodigo === 105 ||
                        operacao === "pre-visualizar"
                    ) {
                        if (resposta.data[0].Documentos[0].DocPDFBase64) {
                            let link = document.createElement("a");
                            link.innerHTML = "Download PDF file";
                            link.download = "file.pdf";
                            link.href = resposta.data[0].Documentos[0].DocPDFDownload;
                            document.body.appendChild(link);
                            window.open(document.body.appendChild(link));
                        }
                    }

                    if (operacao === "emitir") {
                        showLoading();
                        const json = await axiosPut(`/fiscal/transmitir-nota-fiscal/${notaFiscal.id}/`, {
                            situacao: resposta.data[0].Documentos[0].Situacao.SitCodigo,
                            chave_nf: resposta.data[0].Documentos[0].DocChaAcesso,
                            protocolo: resposta.data[0].Documentos[0].DocProtocolo,
                        });
                        hideLoading();
                        if (json.status !== 200) {
                            showError({
                                summary: "Erro",
                                detail: "Falha ao persistir chave e protocolo da nota! Por favor, verifique com a assistência.",
                                life: 6000,
                            });
                        } else if (json.status === 409)
                            showError({
                                summary: "Erro",
                                detail: json.data.msg,
                                life: 6000,
                            });
                        else listagemRef.current?.buscarDados();
                    }
                } else {
                    showWarning({
                        summary: "Mensagem",
                        detail: `${resposta.data[0].Descricao} \n
                            Código: ${resposta.data[0].Codigo}`,
                        life: 5000,
                    });
                }
            else if (resposta.data?.Descricao)
                showWarning({
                    summary: "Mensagem",
                    detail: `${resposta.data.Descricao} \n
                        Código: ${resposta.data.Codigo}`,
                    life: 5000,
                });
            else
                showError({
                    summary: "Erro",
                    detail: "Não foi possível realizar essa ação, verifique o layout e tente novamente.",
                    life: 5000,
                });
        } catch (error) {
            showError({
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    const downloadNota = useCallback(
        async (nota, arquivo) => {
            try {
                const body = {
                    chave_nf: nota.chave_nf,
                    //numero: nota.numero,
                    inicio: nota.numero,
                    fim: nota.numero,
                    serie: nota.serie,
                };

                showLoading();
                const resposta = await axiosPost(
                    `${process.env.REACT_APP_INTEGRACAO_FISCAL}/documents/${apiServico.chave_acesso}/${
                        apiServico.cnpj_integrado
                    }/${nota.modelo === "55" ? "nfe" : "nfce"}/consultar`,
                    body
                );
                hideLoading();

                if (resposta.data[0].Codigo === 100 && resposta.data[0].Documentos[0]) {
                    window.open(
                        arquivo === "xml"
                            ? resposta.data[0].Documentos[0].DocXMLLink
                            : resposta.data[0].Documentos[0].DocPDFLink
                    );

                    showSuccess({
                        summary: "Sucesso!",
                        detail: `Download do ${arquivo.toUpperCase()} ${nota.chave_nf} realizado com sucesso!`,
                        life: 3000,
                    });
                } else
                    showWarning({
                        summary: "Falha",
                        detail: `Ocorreu uma falha ao realizar o download, por favor tente novamente mais tarde.`,
                        life: 3000,
                    });
            } catch (error) {
                showError({
                    summary: "Erro",
                    detail: `Falha ao realizar o download do ${arquivo.toUpperCase()}, por favor tente novamente mais tarde.`,
                    life: 3000,
                });
            }
        },
        [showLoading, hideLoading, apiServico, showSuccess, showError, showWarning]
    );

    const verificaEmissao = (chave) => {
        if (chave) return `${chave.slice(0, 4)}...${chave.slice(40, 44)}`;
        else return "Não processado";
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "Não transmitido";
    };

    const botoesEmissao = [
        {
            label: "Operações NF",
            items: [
                {
                    label: "Enviar e-mail",
                    icon: "pi pi-envelope",
                    disabled: carregandoNota || !notaSelecionada?.protocolo || notaFiscal?.status === "C",
                    command: () => emailRef.current?.setVisible(true),
                },
                {
                    label: "Copiar chave de acesso",
                    icon: "pi pi-copy",
                    disabled: !notaSelecionada?.chave_nf,
                    command: () =>
                        window.prompt(
                            "Copie para área de transferência: Ctrl+C e tecle Enter",
                            notaSelecionada.chave_nf
                        ),
                },
                {
                    label: "Pré-visualizar",
                    icon: "pi pi-file",
                    disabled:
                        carregandoNota ||
                        notaSelecionada?.protocolo ||
                        notaSelecionada?.modelo === "65" ||
                        notaFiscal?.status === "T" ||
                        notaFiscal?.status === "C",
                    command: () => enviarNota("pre-visualizar"),
                },
                {
                    label: "Transmitir",
                    icon: "pi pi-arrow-up",
                    disabled:
                        carregandoNota ||
                        notaSelecionada?.protocolo ||
                        notaFiscal?.status === "C" ||
                        notaFiscal?.status === "T",
                    command: () => setEmissaoDialog(true),
                },
                {
                    label: "Carta de correção",
                    icon: "pi pi-book",
                    disabled: carregandoNota || !notaSelecionada?.protocolo,
                    command: () => cartaRef.current?.setVisible(true),
                },
                {
                    label: "Cancelar",
                    icon: "pi pi-times",
                    disabled: carregandoNota || notaFiscal?.status === "C",
                    command: () => setCancelDialog(true),
                },
                {
                    label: "Baixar XML",
                    icon: "pi pi-download",
                    disabled: !verifyPermission([permissoes.FISCAL_NOTASAIDA_EMITIR]) || !notaSelecionada?.protocolo,
                    command: () => downloadNota(notaFiscal, "xml"),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de nota fiscal"
                    tooltipOptions={{ position: "left" }}
                    disabled={
                        rowData.protocolo ||
                        !(notaSelecionada && rowData.id === notaSelecionada.id) ||
                        rowData.status === "C"
                    }
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-saida/emissao-nota",
                            state: { notaFiscal: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EMITIR]}
                    model={botoesEmissao}
                    componente={Button}
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Operações NF"
                    tooltipOptions={{ position: "left" }}
                    disabled={!(notaSelecionada && rowData.id === notaSelecionada.id)}
                    icon="pi pi-book"
                    loading={carregandoNota}
                    onClick={(event) => {
                        menu.current.toggle(event);
                        carregarNotaFiscal(rowData);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-print"
                    className="p-button-rounded p-mr-2 p-mb-1"
                    onClick={() =>
                        modalImpressorasRef.current?.abrirModal({
                            chave: "fiscal.notafiscal.nfce",
                            filtros: {
                                id_nfce: rowData.id,
                            },
                        })
                    }
                    tooltip="Imprimir cupom"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.modelo !== "65" || rowData.status !== "T"}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-file-pdf"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => downloadNota(rowData, "pdf")}
                    tooltip={`Imprimir ${rowData.modelo === "55" ? "NFe" : "NFCe"}`}
                    tooltipOptions={{ position: "left" }}
                    disabled={
                        rowData.protocolo ? (notaSelecionada && rowData.id === notaSelecionada.id ? false : true) : true
                    }
                />
            </div>
        );
    };

    const painelEsquerdo = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_NOTASAIDA_INCLUIR]}
                componente={Button}
                label="Incluir Nota"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/fiscal/nota-saida/emissao-nota")}
            />
            <Button
                label="Verificar Contingências"
                icon="pi pi-search"
                className="p-button-secondary p-mr-2"
                onClick={() => contingenciaRef.current?.setVisible(true)}
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => modalFiltroAvancadoRef.current?.abrirModal()}
            />
            <Button
                label="Limpar filtro"
                icon="pi pi-filter-slash"
                className="p-button-warning p-mr-2"
                onClick={() => {
                    removerFiltro();
                    setTotalizadorFiltros(0);
                    setUrl(BASE_URL);
                }}
            />
        </>
    );

    const colunas = [
        {
            field: "numero",
            header: "Número",
            style: { width: "80px" },
        },
        {
            field: "serie",
            header: "Série",
            style: { width: "50px" },
        },
        {
            field: "modelo",
            header: "Modelo",
            style: { width: "50px" },
        },
        {
            field: "destinatario.nome",
            header: "Destinatário",
            style: { minWidth: "200px" },
        },
        {
            field: "chave_nf",
            header: "Chave",
            action: (e) => verificaEmissao(e.chave_nf),
            align: "left",
            style: { minWidth: "150px" },
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            style: { minWidth: "140px" },
        },
        {
            field: "datahora_emissao",
            header: "Data emissão",
            dateFormat: "dd/MM/yyyy HH:mm",
            style: { minWidth: "120px" },
        },
        {
            field: "valor_total_nf",
            header: "Total",
            style: { minWidth: "80px" },
            money: true,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            alignFrozen: "right",
            style: { minWidth: "180px" },
        },
    ];

    const cancelNf = async () => {
        if (notaFiscal.protocolo) {
            setCancelDialog(false);
            cancelaRef.current?.setVisible(true);
        } else {
            const resposta = await axiosPatch(`/fiscal/notas-fiscais/${notaFiscal.id}/`, { status: "C" });

            if (resposta.status === 200) {
                listagemRef.current?.buscarDados();
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Nota cancelada com sucesso.",
                    life: 3000,
                });
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível cancelar a nota fiscal.",
                    life: 3000,
                });
            }
        }
    };

    const esconderCancelDialog = () => {
        setCancelDialog(false);
    };

    const onFilter = (e, contador) => {
        setUrl(e);
        setTotalizadorFiltros(contador);
    };

    const carregaApiServico = useCallback(async () => {
        if (empresaSelecionadaId) {
            const resposta = await axiosGet(
                `/configuracoes/api-servicos?empresa=${empresaSelecionadaId}&tipo_servico=EDF`
            );
            if (resposta.status === 200)
                if (resposta.data?.results?.length === 0) {
                    showWarning({
                        summary: "API não encontrada!",
                        detail: "Não existe API de Serviço cadastrada.",
                        sticky: true,
                    });
                } else setApiServico(resposta.data?.results[0]);
        }
    }, [empresaSelecionadaId, showWarning]);

    useEffect(() => {
        carregaApiServico();
    }, [carregaApiServico]);

    const rowClass = (rowData) => {
        return {
            "mako-table-status-inativo": rowData.status === "C",
            "table-recebimentos-effective": rowData.status === "T",
        };
    };

    const verificaTabelaIBPT = useCallback(async () => {
        const resposta = await axiosGet("/fiscal/tabela-ibpt?limit=1&order_by=-id");
        if (resposta.status === 200)
            if (resposta.data?.results?.length === 0) {
                showWarning({
                    summary: "Tabela IBPT não informada",
                    detail: "Não existe tabela IBPT informada. Por favor, importe a tabela mais recente ou entre em contato com o suporte para mais informações.",
                    sticky: true,
                });
            } else {
                const [dia, mes, ano] = resposta.data?.results[0].vigenciafim.split("/");
                const vigencia = parseData(`${ano}-${mes}-${dia}`);
                if (vigencia < new Date())
                    showWarning({
                        summary: "Tabela IBPT desatualizada",
                        detail: "A tabela IBPT está desatualizada! Por favor, importe a versão mais recente.",
                        sticky: true,
                    });
            }
    }, [showWarning]);

    useEffect(() => {
        verificaTabelaIBPT();
    }, [verificaTabelaIBPT]);

    return (
        <PageBase>
            <Menu model={botoesEmissao} popup ref={menu} id="popup_menu" />
            <MakoListagem
                ref={listagemRef}
                titulo={
                    <div className="p-d-flex p-justify-between">
                        <span>Notas Fiscais</span>
                        {<MakoSituacaoSefaz tipoInicial="nfe" />}
                    </div>
                }
                colunas={colunas}
                painelEsquerdo={painelEsquerdo}
                urlPesquisa={url}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                fieldFiltroEmpresa="emitente__id"
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                    selection: notaSelecionada,
                    onSelectionChange: (e) => setNotaSelecionada(e.value),
                    selectionMode: "single",
                    scrollable: true,
                    rowClassName: rowClass,
                }}
            />
            <EnvioEmailFormModal nota={notaFiscal} ref={emailRef} />
            <CancelaNotaFormModal
                nota={notaFiscal}
                apiServico={apiServico}
                onCancel={() => {
                    listagemRef.current?.buscarDados();
                }}
                ref={cancelaRef}
            />
            <CartaCorrecaoFormModal nota={notaFiscal} apiServico={apiServico} ref={cartaRef} />
            <ContingenciaFormModal apiServico={apiServico} ref={contingenciaRef} />
            <ModalFiltroAvancadoNf
                ref={modalFiltroAvancadoRef}
                onFilter={onFilter}
                baseUrl={BASE_URL}
                filtros={filtros}
                setFiltro={setFiltro}
                removerFiltro={removerFiltro}
            />
            <ConfirmDialog
                visible={cancelDialog}
                onHide={esconderCancelDialog}
                header="Confirmação de Cancelamento"
                message={
                    notaFiscal && (
                        <span>
                            {"Deseja realmente cancelar a nota número "}
                            <b>{notaFiscal.numero}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={cancelNf}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderCancelDialog}
                rejectLabel="Não"
            />
            <ConfirmDialog
                visible={emissaoDialog}
                onHide={() => setEmissaoDialog(false)}
                header="Confirmação de Emissão de Documento Fiscal"
                message={
                    notaFiscal && (
                        <span>
                            {"Atenção! Deseja realmente transmitir a nota fiscal número "}
                            <b>{notaFiscal.numero}</b> {" de modelo "}
                            <b>{notaFiscal.modelo}</b>
                            {"\n para o destinatário "} <b>{notaFiscal.destinatario?.nome}</b>
                            {" de documento número "}
                            <b>{formatarDocumento(notaFiscal.destinatario?.identificacao)}</b> {" no valor de "}
                            <b>{parseNumberToMoneyHTML(notaFiscal.valor_total_nf)}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={() => enviarNota("emitir")}
                acceptLabel="Sim"
                acceptClassName="p-button-success"
                reject={() => setEmissaoDialog(false)}
                rejectLabel="Não"
            />
            <ModalImpressoras ref={modalImpressorasRef} apenasTermicas />
        </PageBase>
    );
};
