import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";
import useToast from "@/hooks/useToast";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { Dropdown } from "@/components/Dropdown";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useEmpresa from "@/hooks/useEmpresa";
import { SelectButton } from "primereact/selectbutton";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import useAuth from "@/hooks/useAuth";
import { InputText } from "primereact/inputtext";
import useHttp from "@/hooks/useHttp";

const Modal = ({ aposSalvar }, ref) => {
    const [visible, setVisible] = useState(false);
    const { showError, showWarning, showSuccess } = useToast();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { empresaSelecionadaId } = useEmpresa();
    const { user } = useAuth();
    const { httpPost } = useHttp();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            para_exposicao: true,
            ce_origem: null,
            ce_destino: null,
            datahora_transferencia: new Date(),
            item: null,
            unidade_medida: null,
            quantidade: 1,
            cubagem: 1,
            usuario_transferiu: user?.id,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                ce_origem: Yup.number().required("O campo é obrigatório."),
                ce_destino: Yup.number().required("O campo é obrigatório."),
                unidade_medida: Yup.number().required("O campo é obrigatório."),
                para_exposicao: Yup.boolean().required("O campo é obrigatório.").typeError("Informe um valor válido."),
                quantidade: Yup.number()
                    .min(1, "O campo deve ser igual ou superior a 1.")
                    .required("O campo é obrigatório."),
                cubagem: Yup.number()
                    .min(1, "O campo deve ser igual ou superior a 1.")
                    .required("O campo é obrigatório."),
                datahora_transferencia: Yup.date().required("O campo é obrigatório."),
                item: Yup.object().required("O campo é obrigatório."),
            });

            await formSchema.validate(values, { abortEarly: false });

            const handlers = {
                201: () => {
                    if (typeof aposSalvar === "function") aposSalvar();
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Movimentação de mercadoria de exposição lançada com sucesso!",
                        life: 1500,
                    });
                    resetForm();
                    setVisible(false);
                },
                409: ({ err }) =>
                    showWarning({
                        summary: "Falha",
                        detail: err.msg,
                        life: 1500,
                    }),
            };

            await httpPost(
                {
                    url: `/transferencias/insere-transferencia-exposicao/`,
                    body: {
                        ...values,
                        empresa: empresaSelecionadaId,
                    },
                },
                handlers
            );
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const exibirDialog = (values) => {
        setValues({
            ...values,
            ce_destino: values.ce_destino.id,
            ce_origem: values.ce_origem.id,
            unidade_medida: values.unidade_medida.id,
        });
        setVisible(true);
    };

    const fecharDialog = () => {
        resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ exibirDialog, setVisible }));

    const onChangeDimensao = useCallback(
        (e) => {
            setFieldValue("cubagem", e);
        },
        [setFieldValue]
    );

    const aposPesquisarUnid = useCallback(
        (results) => {
            if (results?.length === 1) setFieldValue("unidade_medida", results[0].unidade.id);
            return results.map((e) => e.unidade);
        },
        [setFieldValue]
    );

    const itemTemplate = (centro) => {
        if (centro)
            return `${centro.estoque_empresa.descricao} - ${centro.nome} (${
                centro.estoque_empresa.empresa.nome
            }:${formatarDocumento(centro.estoque_empresa.empresa.identificacao)})`;
        return "---";
    };

    const valueTemplate = (centro) => {
        if (centro)
            return `${centro.estoque_empresa.descricao} - ${centro.nome} (${
                centro.estoque_empresa.empresa.nome
            }:${formatarDocumento(centro.estoque_empresa.empresa.identificacao)})`;
    };

    return (
        <Dialog
            header={`Incluindo movimentação de exposição`}
            visible={visible}
            style={{ width: "70vw", display: "block" }}
            onHide={() => fecharDialog()}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid p-mb-0">
                    <div className="p-field p-col-12 p-md-12">
                        <MakoBuscaSkuPersonalizada
                            id="item"
                            name="item"
                            skuValue={formik.values.item}
                            skuChange={(e) => setFieldValue("item", e)}
                            skuError={formik.errors.item}
                            exibeDimensoes
                            alturaValue={formik.values.altura}
                            disabledBusca={formik.values.id}
                            onChangeAltura={(e) => setFieldValue("altura", e)}
                            larguraValue={formik.values.largura}
                            onChangeLargura={(e) => setFieldValue("largura", e)}
                            comprimentoValue={formik.values.comprimento}
                            onChangeComprimento={(e) => setFieldValue("comprimento", e)}
                            dimensaoValue={formik.values.cubagem}
                            onChangeDimensao={onChangeDimensao}
                            exibirVisualizacaoSku
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="para_exposicao">Para exposição?</label>
                        <SelectButton
                            id="para_exposicao"
                            name="para_exposicao"
                            value={formik.values.para_exposicao}
                            onChange={formik.handleChange}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                            disabled={formik.values.id}
                            className={classNames({ "p-invalid": formik.errors.para_exposicao })}
                        />
                        {formik.errors.para_exposicao && (
                            <small className="p-error">{formik.errors.para_exposicao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="ce_origem">Centro de Estocagem Origem</label>
                        <Dropdown
                            id="ce_origem"
                            name="ce_origem"
                            placeholder={"Selecione um centro de estocagem"}
                            url={`/produtos/centros-estocagem?limit=1000&estoque_empresa__empresa__id=${empresaSelecionadaId}&tipo=${
                                formik.values.para_exposicao ? "C" : "E"
                            }`}
                            style={{ height: "58%" }}
                            showClear={false}
                            filter
                            filterBy="id,nome"
                            optionValue="id"
                            optionLabel="nome"
                            itemTemplate={itemTemplate}
                            valueTemplate={valueTemplate}
                            value={formik.values.ce_origem}
                            onChange={(e) => setFieldValue("ce_origem", e.target.value)}
                            className={classNames({ "p-invalid": formik.errors.ce_origem })}
                            disabled={formik.values.id}
                        />
                        {formik.errors.ce_origem && <small className="p-error">{formik.errors.ce_origem}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="ce_destino">Centro de Estocagem Destino</label>
                        <Dropdown
                            id="ce_destino"
                            name="ce_destino"
                            placeholder={"Selecione um centro de estocagem"}
                            url={`/produtos/centros-estocagem?limit=1000&estoque_empresa__empresa__id=${empresaSelecionadaId}&tipo=${
                                formik.values.para_exposicao ? "E" : "C"
                            }`}
                            showClear={false}
                            filter
                            filterBy="id,nome"
                            optionValue="id"
                            optionLabel="nome"
                            style={{ height: "58%" }}
                            itemTemplate={itemTemplate}
                            valueTemplate={valueTemplate}
                            value={formik.values.ce_destino}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.ce_destino })}
                            disabled={formik.values.id}
                        />
                        {formik.errors.ce_destino && <small className="p-error">{formik.errors.ce_destino}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="unidade_medida">Unidade de medida *</label>
                        <Dropdown
                            id="unidade_medida"
                            name="unidade_medida"
                            url={`/produtos/unidades-medida-sku?sku__id=${formik.values.item?.id}&tipo_mov_und_medida=T`}
                            optionValue="id"
                            optionLabel="nome"
                            buscar={formik.values.item && typeof formik.values.item === "object"}
                            aposBuscar={aposPesquisarUnid}
                            showClear={false}
                            value={formik.values.unidade_medida}
                            onChange={formik.handleChange}
                            disabled={!formik.values.item?.id || formik.values.id}
                            className={classNames({ "p-invalid": formik.errors.unidade_medida })}
                        />
                        {formik.errors.unidade_medida && (
                            <small className="p-error">{formik.errors.unidade_medida}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cubagem">Cubagem *</label>
                        <InputNumber
                            id="cubagem"
                            name="cubagem"
                            mode="decimal"
                            min={0}
                            value={formik.values.cubagem}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cubagem })}
                            disabled={formik.values.id}
                        />
                        {formik.errors.cubagem && <small className="p-error">{formik.errors.cubagem}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="quantidade">Quantidade *</label>
                        <InputNumber
                            id="quantidade"
                            name="quantidade"
                            mode="decimal"
                            min={0}
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                            disabled={formik.values.id}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    {formik.values.id && (
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="busca">Usuário que transferiu:</label>
                            <InputText
                                id="pesquisa"
                                name="pesquisa"
                                value={`${formik.values.usuario_transferiu?.nome} - ${formik.values.usuario_transferiu?.identificacao}`}
                                disabled
                            />
                        </div>
                    )}
                </div>
                <br />
                <div className="p-grid p-justify-end">
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => fecharDialog()}
                        className="p-button-danger p-mr-3"
                    />
                    {formik.values.id ? null : (
                        <Button type="submit" icon="pi pi-save" label="Movimentar" className="p-mr-2" />
                    )}
                </div>
            </form>
        </Dialog>
    );
};

export const TransferenciaExposicaoModalForm = forwardRef(Modal);
