import React, { useCallback, useEffect, useImperativeHandle, useState, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { Dropdown } from "@/components/Dropdown";
import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { formatarCasasDecimais } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";
import useToast from "@/hooks/useToast";
import useDevolucao from "@/hooks/useDevolucao";
import { InputText } from "primereact/inputtext";
import useHttp from "@/hooks/useHttp";

const Modal = ({ devolucaoId, onSalvar }, ref) => {
    const [visible, setVisible] = useState(false);
    const { showSuccess, showError } = useToast();
    const { dadosBasicos } = useDevolucao();
    const { httpPatch, httpPost } = useHttp();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            sku: null,
            unidade_medida: null,
            item_venda: null,
            quantidade: 0,
            cubagem: 0,
            valor_unitario: 0,
            subtotal: 0,
            encargos: 0,
            motivo_devolucao: null,
            justificativa: "",
            retorno_estoque: true,
            defeito: false,
            estoque_retorno: null,
            sku_movimenta_estoque: null,
            _encargos_venda: 0,
            _unidade: null,
            _cubagem: null,
            _qtd_vendida: null,
            _qtd_devolvida: null,
        },
        onSubmit: handleSubmit,
    });

    const abrirModal = (itemDevolucao) => {
        if (itemDevolucao) {
            const { item_venda, ...rest } = itemDevolucao;
            setValues({
                ...rest,
                sku: item_venda,
                unidade_medida: rest.unidade_medida.id,
                motivo_devolucao: rest.motivo_devolucao.id,
                sku_movimenta_estoque: rest.sku_movimenta_estoque.id,
                _encargos_venda: parseNumber(item_venda.valor_encargos),
                _unidade: item_venda.unidade_venda?.sigla,
                _cubagem: parseNumber(item_venda.cubagem),
                _qtd_vendida: parseNumber(item_venda.quantidade),
                _qtd_devolvida: parseNumber(item_venda.quantidade_devolvida),
            });
        }
        setVisible(true);
    };

    const fecharModal = () => {
        resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                quantidade: Yup.number()
                    .required("O campo 'qtd. desta devolução' é obrigatório.")
                    .min(1, "A quantidade deve ser maior que 0.")
                    .test({
                        message: "A quantidade informada é maior do que o saldo disponível.",
                        test: (value) => value <= values._qtd_vendida - values._qtd_devolvida,
                    }),
                valor_unitario: Yup.number()
                    .required("O campo 'valor unitário' é obrigatório.")
                    .min(1, "A quantidade deve ser maior que 0."),
                motivo_devolucao: Yup.number()
                    .required("O campo 'motivo da devolução' é obrigatório.")
                    .typeError("Você precisa selecionar um motivo para a devolução."),
                estoque_retorno: Yup.number()
                    .required("O campo 'centro de estocagem de retorno' é obrigatório.")
                    .typeError("Você precisa selecionar um centro de estocagem de retorno."),
            });
            formSchema.validateSync(values, { abortEarly: false });

            if (!values.id) {
                const { sku, ...rest } = values;

                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Item para devolução cadastrado com sucesso!",
                            life: 1500,
                        });
                        if (typeof onSalvar === "function") onSalvar();
                        fecharModal();
                    },
                };

                await httpPost(
                    {
                        url: "/vendas/itens-troca-devolucao/",
                        body: {
                            ...rest,
                            item_venda: sku.id,
                            sku: sku.sku.id,
                            sku_movimenta_estoque: sku.sku_movimenta_estoque.id,
                            unidade_medida: sku.unidade_venda.id,
                            devolucao: devolucaoId,
                            valor_unitario: formatarCasasDecimais(rest.valor_unitario),
                            encargos: formatarCasasDecimais(rest.encargos),
                            subtotal: formatarCasasDecimais(rest.subtotal),
                        },
                    },
                    handlers
                );
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Item para devolução alterado com sucesso!",
                            life: 1500,
                        });
                        if (typeof onSalvar === "function") onSalvar();
                        fecharModal();
                    },
                };

                await httpPatch(
                    {
                        url: `/vendas/itens-troca-devolucao/${values.id}/`,
                        body: {
                            ...values,
                            valor_unitario: formatarCasasDecimais(values.valor_unitario),
                        },
                    },
                    handlers
                );
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const calcularEncargosItemDevolucao = useCallback(() => {
        if (!formik.values.quantidade) setFieldValue("encargos", 0);
        else {
            const encargos =
                (formik.values._encargos_venda * formik.values.quantidade) / parseNumber(formik.values._qtd_vendida);
            setFieldValue("encargos", encargos);
        }
    }, [formik.values.quantidade, formik.values._encargos_venda, formik.values._qtd_vendida, setFieldValue]);

    useEffect(() => {
        calcularEncargosItemDevolucao();
    }, [calcularEncargosItemDevolucao]);

    const calcularSubtotalItemDevolucao = useCallback(() => {
        if (!formik.values.quantidade) setFieldValue("subtotal", 0);
        else {
            const subtotal = formik.values.quantidade * formik.values.valor_unitario + formik.values.encargos;
            setFieldValue("subtotal", subtotal);
        }
    }, [formik.values.quantidade, formik.values.valor_unitario, formik.values.encargos, setFieldValue]);

    useEffect(() => {
        calcularSubtotalItemDevolucao();
    }, [calcularSubtotalItemDevolucao]);

    const onChangeItemDevolucao = (value) => {
        setFieldValue("sku", value);
        if (value instanceof Object) {
            const { unidade_venda, cubagem, quantidade, quantidade_devolvida, valor_unitario, valor_encargos } = value;
            setFieldValue("valor_unitario", parseNumber(valor_unitario));
            setFieldValue("_encargos_venda", parseNumber(valor_encargos));
            setFieldValue("_unidade", unidade_venda?.sigla);
            setFieldValue("_cubagem", parseNumber(cubagem));
            setFieldValue("_qtd_vendida", parseNumber(quantidade));
            setFieldValue("_qtd_devolvida", parseNumber(quantidade_devolvida));
        }
    };

    return (
        <Dialog visible={visible} header="Item da Devolução" style={{ width: "65vw" }} onHide={fecharModal}>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="item-venda">Item da venda *</label>
                        <Dropdown
                            id="sku"
                            name="sku"
                            url={`/vendas/itens-vendas?venda=${dadosBasicos.venda.id}`}
                            placeholder="Selecione um produto"
                            filter
                            filterBy="sku.id,sku.codigo,sku.descricao_complementar"
                            optionLabel="descricao_reduzida"
                            value={formik.values.sku}
                            showClear={false}
                            onChange={({ value }) => onChangeItemDevolucao(value)}
                            className="p-mr-2"
                        />
                        {formik.errors.sku && <small className="p-error">{formik.errors.sku}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="und">Unidade</label>
                        <InputText id="und" disabled value={formik.values._unidade} readOnly />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="qtd-desta-devolucao">Qtd. devolvida</label>
                        <MakoInputQuantidadeSku
                            id="qtd-desta-devolucao"
                            name="_qtd_devolvida"
                            value={formik.values._qtd_devolvida}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="qtd-desta-devolucao">Qtd. devolução *</label>
                        <MakoInputQuantidadeSku
                            id="qtd-desta-devolucao"
                            name="quantidade"
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-unitario">Valor unitário</label>
                        <MakoInputMoeda
                            id="valor_unitario"
                            name="valor_unitario"
                            value={formik.values.valor_unitario}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                        />
                        {formik.errors.valor_unitario && (
                            <small className="p-error">{formik.errors.valor_unitario}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-encargos">Valor dos encargos</label>
                        <MakoInputMoeda id="valor-encargos" disabled value={formik.values.encargos} readOnly />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="subtotal-devolucao">Subtotal da devolução</label>
                        <MakoInputMoeda id="subtotal-devolucao" disabled value={formik.values.subtotal} readOnly />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="motivo-devolucao">Motivo da devolução *</label>
                        <Dropdown
                            id="motivo-devolucao"
                            name="motivo_devolucao"
                            url="/vendas/motivo-devolucao/"
                            optionValue="id"
                            optionLabel="descricao"
                            showClear={false}
                            filter
                            filterBy="descricao"
                            value={formik.values.motivo_devolucao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.motivo_devolucao })}
                        />
                        {formik.errors.motivo_devolucao && (
                            <small className="p-error">{formik.errors.motivo_devolucao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="estoque-retorno">Estoque / centro de estocagem de retorno *</label>
                        <MakoDropdownEstoques
                            id="estoque-retorno"
                            name="estoque_retorno"
                            optionValue="id"
                            showClear={false}
                            value={formik.values.estoque_retorno}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.estoque_retorno })}
                        />
                        {formik.errors.estoque_retorno && (
                            <small className="p-error">{formik.errors.estoque_retorno}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="retorna-estoque">Retorna ao estoque</label>
                        <SelectButton
                            id="retorna-estoque"
                            name="retorno_estoque"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.retorno_estoque}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="produto-com-defeito">Produto com defeito</label>
                        <SelectButton
                            id="produto-com-defeito"
                            name="defeito"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.defeito}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-grid p-justify-end p-mt-4 p-mr-1">
                    <Button type="submit" label="Gravar" icon="pi pi-save" className="p-mr-2" autoFocus />
                    <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        type="reset"
                        onClick={fecharModal}
                        className="p-button-danger"
                    />
                </div>
                <CamposObrigatorios />
            </form>
        </Dialog>
    );
};

export const ModalItem = forwardRef(Modal);
