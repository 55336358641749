import React, { useCallback } from "react";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

import { Listagem } from "./listagem";
import { Buttons } from "./buttons";
import { Form } from "./form";

import useReceberCrediario from "../../../hooks/useReceberCrediario";

export const Recebimentos = () => {
    const { listagemRecebimentosRef, atualizarListagemRecebimentos, atualizarInfo } = useReceberCrediario();

    const successCallback = useCallback(() => {
        atualizarListagemRecebimentos();
        atualizarInfo();
    }, [atualizarInfo, atualizarListagemRecebimentos]);

    const actions = useCallback(
        (rowData) => {
            return (
                <MakoActionsButtonsColumn>
                    <Buttons.Cancelar formaEfetivadaId={rowData.id} onSuccessCallback={successCallback} />
                </MakoActionsButtonsColumn>
            );
        },
        [successCallback]
    );

    return (
        <>
            <Form successCallback={successCallback} />
            <div className="p-mt-3">
                <Listagem listagemRef={listagemRecebimentosRef} actions={actions} />
            </div>
        </>
    );
};
