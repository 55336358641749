import React, { forwardRef, useCallback, useImperativeHandle } from "react";

import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import * as Yup from "yup";

import { Dropdown } from "@/components/Dropdown";
import { MakoButton } from "@/components/MakoButton";

import { dataToStr } from "@/assets/util/datas";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Label } from "@/components/Label";
import { InputNumber } from "primereact/inputnumber";
import { MakoInputCliente, MakoInputPeriodo } from "@/components/MakoInputs";
import { MakoDropdownCargas } from "@/components/MakoInputs/MakoDropdownCargas";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { TIPO_CHOICE_SITUACAO_CARGA } from "@/assets/constants/constants";

export const INITIAL_VALUES = {
    item_venda__venda: null,
    item_venda__venda__cliente_id: null,
    carga: null,
    carga__situacao: null,
    rota_entrega: null,
    item_venda__sku: null,
    data_finalizacao__lte: null,
    data_finalizacao__gte: null,
};

const Component = ({ successCallback = () => {}, cancelCallback = () => {} }, ref) => {
    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
        enableReinitialize: false,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                item_venda__venda: Yup.number().nullable().default(null).typeError("Informe um 'orçamento' válido."),
                item_venda__venda__cliente_id: Yup.object()
                    .nullable()
                    .default(null)
                    .typeError("Informe um 'cliente' válido."),
                carga: Yup.number().nullable().default(null).typeError("Informe uma 'carga' válida."),
                rota_entrega: Yup.number().nullable().default(null).typeError("Informe uma 'rota de entrega' válida."),
                item_venda__sku: Yup.object().nullable().default(null).typeError("Informe um 'produto' válido."),
                data_finalizacao__lte: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Informe uma 'data inicial' válida."),
                data_finalizacao__gte: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Informe uma 'data final' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let query = {};
            let filtros = {};
            Object.keys(values).forEach((key) => {
                if (values[key] !== null && values[key] !== "") {
                    if (["data_finalizacao__lte", "data_finalizacao__gte"].includes(key)) {
                        query[key] = dataToStr(values[key], "yyyy-MM-dd");
                    } else if (["item_venda__sku", "item_venda__venda__cliente_id"].includes(key)) {
                        query[key] = values[key].id;
                    } else {
                        query[key] = values[key];
                    }
                    filtros[key] = values[key];
                }
            });
            successCallback(filtros, query);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const initForm = useCallback(
        (values = {}) => {
            setValues({
                ...INITIAL_VALUES,
                ...(values || {}),
            });
        },
        [setValues]
    );

    useImperativeHandle(ref, () => ({ initForm }), [initForm]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="item_venda__venda" label="Orçamento" />
                    <InputNumber
                        inputId="item_venda__venda"
                        name="item_venda__venda"
                        useGrouping={false}
                        value={formik.values.item_venda__venda}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.item_venda__venda })}
                    />
                    {formik.errors.item_venda__venda && (
                        <small className="p-error">{formik.errors.item_venda__venda}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="item_venda__venda__cliente_id" label="Cliente" />
                    <MakoInputCliente
                        inputId="item_venda__venda__cliente_id"
                        name="item_venda__venda__cliente_id"
                        value={formik.values.item_venda__venda__cliente_id}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.item_venda__venda__cliente_id })}
                    />
                    {formik.errors.item_venda__venda__cliente_id && (
                        <small className="p-error">{formik.errors.item_venda__venda__cliente_id}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="carga" label="N° da carga" />
                    <MakoDropdownCargas
                        id="carga"
                        name="carga"
                        optionValue="id"
                        value={formik.values.carga}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.carga })}
                    />
                    {formik.errors.carga && <small className="p-error">{formik.errors.carga}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="rota_entrega" label="Rota entrega" />
                    <Dropdown
                        id="rota_entrega"
                        name="rota_entrega"
                        url="/vendas/rotas-entrega/?limit=200"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.rota_entrega}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.rota_entrega })}
                    />
                    {formik.errors.rota_entrega && <small className="p-error">{formik.errors.rota_entrega}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="item_venda__sku" label="Produto" />
                    <MakoBuscaSkuPersonalizada
                        id="item_venda__sku"
                        name="item_venda__sku"
                        skuValue={formik.values.item_venda__sku}
                        skuChange={(e) => setFieldValue("item_venda__sku", e)}
                        skuError={formik.errors.item_venda__sku}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <MakoInputPeriodo
                    label="Periodo de entrega"
                    nameInicio="data_finalizacao__gte"
                    nameFinal="data_finalizacao__lte"
                    valueInicio={formik.values.data_finalizacao__gte}
                    valueFinal={formik.values.data_finalizacao__lte}
                    errorInicio={formik.errors.data_finalizacao__gte}
                    errorFinal={formik.errors.data_finalizacao__lte}
                    onChangeInicio={formik.handleChange}
                    onChangeFinal={formik.handleChange}
                />
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="carga__situacao" label="Situação" />
                    <Dropdown
                        id="carga__situacao"
                        name="carga__situacao"
                        options={TIPO_CHOICE_SITUACAO_CARGA}
                        optionValue="value"
                        optionLabel="label"
                        value={formik.values.carga__situacao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.carga__situacao })}
                    />
                    {formik.errors.carga__situacao && (
                        <small className="p-error">{formik.errors.carga__situacao}</small>
                    )}
                </div>
            </div>
            <MakoActionsButtons className="p-justify-end p-mt-2">
                <MakoButton type="submit" icon="pi pi-filter" label="Filtrar" />
                <MakoButton
                    type="reset"
                    icon="pi pi-trash"
                    label="Limpar"
                    onClick={() => formik.resetForm({ values: INITIAL_VALUES })}
                    className="p-button-warning"
                />
                <MakoButton type="reset" label="Cancelar" onClick={cancelCallback} className="p-button-danger" />
            </MakoActionsButtons>
        </form>
    );
};

export const Form = forwardRef(Component);
