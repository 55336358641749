import React, { useCallback } from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";

import useDesprocessarPendenciaCaixa from "@/pages/Financeiro/Financeiro/GestaoCaixa/features/Pendencias/hooks/useDesprocessarPendencia";
import useReceberCrediario from "../../../../../hooks/useReceberCrediario";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";

const { FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_DESPROCESSAR } = permissoes;

export const Cancelar = ({ formaEfetivadaId, onSuccessCallback = () => {} }) => {
    const { pendencia } = useReceberCrediario();
    const { caixaMov } = useCaixaMovimento();
    const handlerDesprocessar = useDesprocessarPendenciaCaixa(caixaMov?.id);

    const handler = useCallback(async () => {
        if (pendencia && formaEfetivadaId) {
            const { tipo_chave } = pendencia || {};
            const data = await handlerDesprocessar({
                pendencia_id: pendencia?.id,
                extras: { forma_efetivada: formaEfetivadaId },
                tipo_chave,
            });
            onSuccessCallback(data);
        }
    }, [pendencia, formaEfetivadaId, handlerDesprocessar, onSuccessCallback]);

    return (
        <MakoControleAcesso
            componente={MakoButton}
            permissao={[FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_DESPROCESSAR]}
            icon="pi pi-sign-out"
            tooltip="Cancelar"
            type="button"
            className="p-button-rounded p-button-danger"
            onClick={handler}
        />
    );
};
