import React, { useCallback, useMemo, useRef } from "react";

import { DropdownRef as Dropdown } from "@/components/DropdownRef";

import { Buttons } from "./buttons";

import { TIPO_CHOICE_SITUACAO_CARGA } from "@/assets/constants/constants";
import { gerarFiltrosUrl } from "@/assets/util/util";

import useEmpresa from "@/hooks/useEmpresa";
import { Modal } from "./modal";

const BASE_URL = "/vendas/cargas/";

const BASE_FILTRO = {
    limit: 200,
};

const gerarLabel = ({ codigo, nome, situacao }) => {
    const label = TIPO_CHOICE_SITUACAO_CARGA.find(({ value }) => value === situacao).label;
    return `${codigo} - ${nome} (${label})`;
};

const itemTemplate = (value) => gerarLabel(value);

const valueTemplate = (option, props) => {
    if (option) return gerarLabel(option);
    return <span>{props.placeholder}</span>;
};

export const MakoDropdownCargas = ({
    filtros = {},
    filtrarPorEmpresa = true,
    optionLabel = "nome",
    disabled = false,
    showAdd = false,
    showEdit = true,
    onSuccess = () => {},
    ...rest
}) => {
    const { empresaSelecionadaId } = useEmpresa();
    const { value } = rest;

    const dropdownRef = useRef(null);
    const modalRef = useRef(null);

    const onClick = useCallback(
        (type = "new") => {
            modalRef.current?.show(type === "new" ? undefined : value);
        },
        [value]
    );

    const successCallback = useCallback(
        (data) => {
            dropdownRef.current?.fetch();
            onSuccess(data);
        },
        [onSuccess]
    );

    const url = useMemo(() => {
        const query = {
            ...BASE_FILTRO,
            ...(filtros || {}),
            ...(filtrarPorEmpresa ? { empresa: empresaSelecionadaId } : {}),
        };
        return `${BASE_URL}?${gerarFiltrosUrl(query)}`;
    }, [filtros, filtrarPorEmpresa, empresaSelecionadaId]);

    const buscar = useMemo(() => {
        if (!filtrarPorEmpresa) return true;
        return !!empresaSelecionadaId;
    }, [empresaSelecionadaId, filtrarPorEmpresa]);

    return (
        <div className="p-inputgroup">
            <Dropdown
                ref={dropdownRef}
                url={url}
                optionLabel={optionLabel}
                itemTemplate={itemTemplate}
                valueTemplate={valueTemplate}
                buscar={buscar}
                disabled={!buscar || disabled}
                placeholder="Selecione uma carga"
                {...rest}
            />
            {showAdd && <Buttons.Novo onClick={() => onClick()} />}
            {showEdit && <Buttons.Editar onClick={() => onClick("edit")} disabled={!!!value} />}
            <Modal ref={modalRef} successCallback={successCallback} carga={value} />
        </div>
    );
};
