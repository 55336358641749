import React, { useCallback, useState } from "react";

import classNames from "classnames";

import { Button } from "primereact/button";

import { MakoInputPercent } from "../MakoInputPercent";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";

const TIPOS_DESCONTO = {
    PERCENT: "PERCENT",
    VALOR: "VALOR",
};

export const MakoInputPercentMoeda = ({
    values = {},
    errors,
    setFieldValue = () => {},
    idMoney = "descontos",
    nameMoney = "descontos",
    idPercent = "descontos_percent",
    namePercent = "descontos_percent",
    nameValor = "valor",
    getDesconto = () => {},
    disabled = false,
}) => {
    const [tipoDesconto, setTipoDesconto] = useState(TIPOS_DESCONTO.PERCENT);

    const calcularDescontos = useCallback(() => {
        const descontos = values[nameValor] * (values[namePercent] / 100);
        setFieldValue(nameMoney, descontos);
        if (getDesconto) getDesconto(descontos);
    }, [values, nameValor, namePercent, setFieldValue, nameMoney, getDesconto]);

    const calcularDescontosPercent = useCallback(() => {
        const descontos = (values[nameMoney] / values[nameValor]) * 100;
        setFieldValue(namePercent, descontos);
        if (getDesconto) getDesconto(values[nameMoney]);
    }, [values, nameMoney, nameValor, setFieldValue, namePercent, getDesconto]);

    return (
        <div className="p-inputgroup">
            {tipoDesconto === TIPOS_DESCONTO.PERCENT ? (
                <>
                    <MakoInputPercent
                        inputId={idPercent}
                        name={namePercent}
                        value={values[namePercent]}
                        onChange={(e) => setFieldValue(namePercent, e.value)}
                        onBlur={calcularDescontos}
                        casasDecimais={2}
                        disabled={disabled}
                        className={classNames({ "p-invalid": errors[namePercent] })}
                    />
                    <Button
                        icon="pi pi-dollar"
                        type="button"
                        className="p-button-help"
                        tooltip="Conceder desconto por valor"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => setTipoDesconto(TIPOS_DESCONTO.VALOR)}
                    />
                </>
            ) : (
                <>
                    <MakoInputMoeda
                        id={idMoney}
                        name={nameMoney}
                        valueMoeda={values[nameMoney]}
                        onChange={(e) => setFieldValue(nameMoney, e.value)}
                        onBlur={calcularDescontosPercent}
                        disabled={disabled}
                        className={classNames({ "p-invalid": errors[namePercent] })}
                    />
                    <Button
                        icon="pi pi-percentage"
                        type="button"
                        tooltip="Conceder desconto por porcentagem"
                        tooltipOptions={{ position: "top" }}
                        className="p-button-help"
                        onClick={() => setTipoDesconto(TIPOS_DESCONTO.PERCENT)}
                    />
                </>
            )}
        </div>
    );
};
