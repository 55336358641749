import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { Dialog } from "primereact/dialog";

import { FiltroAvancado as Filtro, INITIAL_VALUES } from "../../features";

import { totalizadorFiltrosAplicados } from "@/assets/util/util";
import useEmpresa from "@/hooks/useEmpresa";

const FiltroAvancadoRecebimentos = ({ filtros, onSuccess = () => {}, url, setTotalizadorFiltros }, ref) => {
    const [visible, setVisible] = useState(false);
    const { empresaSelecionadaId } = useEmpresa();

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => {
        return {
            abrirModal,
        };
    });

    const successCallback = (...args) => {
        onSuccess(...args);
        fecharModal();
    };

    const fecharModal = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (filtros) setTotalizadorFiltros(totalizadorFiltrosAplicados(INITIAL_VALUES, filtros));
    }, [filtros, setTotalizadorFiltros]);

    return (
        <Dialog
            style={{ width: "75vw" }}
            breakpoints={{ "960px": "75vw" }}
            header="Filtro avançado de parcelas"
            visible={visible}
            onHide={fecharModal}
        >
            <Filtro.Form
                empresaSelecionadaId={empresaSelecionadaId}
                filtros={filtros}
                setTotalizadorFiltros={setTotalizadorFiltros}
                url={url}
                successCallback={successCallback}
                cancelCallback={fecharModal}
            />
        </Dialog>
    );
};

export const FiltroAvancado = forwardRef(FiltroAvancadoRecebimentos);
