import React, { useCallback, useRef, useState } from "react";

import { Button as B } from "primereact/button";
import { classNames } from "primereact/utils";

import { Modal } from "./modal";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useClearRefs from "@/hooks/useClearRefs";

export const Button = ({ previsaoId, onClick, className = "" }) => {
    const [id, setId] = useState(previsaoId);

    const modalRef = useRef(null);

    useClearRefs(modalRef);

    const click = useCallback(
        (e) => {
            if (typeof onClick === "function") onClick(e);
            else {
                setId(previsaoId);
                modalRef.current?.show();
            }
        },
        [previsaoId, onClick]
    );

    return (
        <>
            <B
                tooltip="Visualizar"
                icon={MAKO_ICONS.VISUALIZAR}
                className={classNames("p-button p-button-rounded", className)}
                onClick={click}
                tooltipOptions={{ position: "left" }}
            />
            <Modal ref={modalRef} previsaoId={id} />
        </>
    );
};
