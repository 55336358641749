import React, { useImperativeHandle, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";

import MakoListagem from "../MakoListagem";

const BaseComponente = ({ titulo, colunas }, ref) => {
    const [visible, setVisible] = useState(false);
    const [erros, setErros] = useState([]);

    const abrirModal = (dados) => {
        setVisible(true);

        if (dados) {
            setErros(dados);
        }
    };

    useImperativeHandle(ref, () => {
        return {
            abrirModal,
        };
    });

    return (
        <Dialog visible={visible} style={{ width: "60vw" }} header={titulo || "Erros"} onHide={() => setVisible(false)}>
            <MakoListagem dadosLocal={erros} colunas={colunas || []} />
        </Dialog>
    );
};

export const MakoErrosPersonalizados = forwardRef(BaseComponente);
