import React, { useCallback, useMemo } from "react";

import { Dropdown } from "@/components/Dropdown";

import { gerarFiltrosUrl } from "@/assets/util/util";

const BASE_URL = "/financeiro/chaves-pix/";

export const DropdownChavesPix = ({ contaFinanceiraId, ...props }) => {
    const aposBuscar = useCallback((data = []) => {
        return data.map((v) => ({ ...v, label: `${v.descricao}: ${v.chave}` }));
    }, []);

    const url = useMemo(() => {
        const params = {
            limit: 200,
            ativo: true,
            query: "{id,descricao,chave,perfil,conteudo}",
            conta_bancaria__conta_financeira: contaFinanceiraId,
        };
        return BASE_URL + "?" + gerarFiltrosUrl(params);
    }, [contaFinanceiraId]);

    return <Dropdown url={url} optionLabel="label" aposBuscar={aposBuscar} buscar={!!contaFinanceiraId} {...props} />;
};
