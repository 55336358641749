import React from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

import classNames from "classnames";
import * as Yup from "yup";
import { MakoButton } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Label } from "@/components/Label";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

const url = "/pessoas/permissoes-perfis/";

export const PermissoesPerfilForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPut } = useHttp();
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            descricao: props?.location.state.permissao.descricao,
            registro: props?.location.state.permissao.registro ? props.location.state.permissao.registro : "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (values.registro === "") values.registro = null;
            values.chave = props?.location.state.permissao.chave;
            const id = props?.location.state.permissao.id;
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Permissao atualizada com sucesso!",
                        life: 1500,
                    });
                    formik.resetForm();
                    history.push("/configuracoes/permissoes-perfis/");
                },
            };
            showLoading();
            await httpPut({ url: `${url}${id}/`, body: values }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push("/configuracoes/permissoes-perfis/");
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h3>Alterar permissão</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-8 p-md-4">
                                <Label htmlFor="descricao" label="Descricao" obrigatorio />
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-4 p-md-4">
                                <Label htmlFor="registro" label="Registro" />
                                <InputText
                                    id="registro"
                                    name="registro"
                                    disabled={!props?.location.state?.permissao.content_type}
                                    value={formik.values.registro}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.registro })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.registro && <small className="p-error">{formik.errors.registro}</small>}
                            </div>
                        </div>
                        <MakoActionsButtons>
                            <MakoButton
                                label="Gravar"
                                icon={MAKO_ICONS.GRAVAR}
                                type="submit"
                                className="p-button-info"
                                loading={loading}
                            />
                            <MakoButton
                                label="Cancelar"
                                icon={MAKO_ICONS.CANCEL}
                                type="reset"
                                className="p-button-danger"
                                onClick={cancelar}
                                loading={loading}
                            />
                        </MakoActionsButtons>
                    </form>
                </div>
            </div>
        </div>
    );
};
