import React from "react";

import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";

import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { TIPO_STATUS_CONTRATO_CHOICE } from "@/assets/constants/constants";

import useContratoServico from "@/hooks/useContratoServico";

export const DadosCabecalho = () => {
    const { contrato } = useContratoServico();

    return (
        <Panel header="Informações do contrato" toggleable>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="numero" label="N° do contrato" />
                    <InputText id="numero" name="numero" value={contrato?.numero} disabled />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="cliente" label="Cliente" />
                    <MakoInputCliente id="cliente" name="cliente" value={contrato?.cliente} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor_total" label="Total do contrato" />
                    <MakoInputMoeda id="valor_total" name="valor_total" valueMoeda={contrato?.valor_total} disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="status" label="Status" />
                    <Dropdown
                        id="status"
                        disabled
                        name="status"
                        options={TIPO_STATUS_CONTRATO_CHOICE}
                        value={contrato?.status}
                    />
                </div>
            </div>
        </Panel>
    );
};
