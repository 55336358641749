import React, { useEffect, useRef } from "react";
import { Button } from "primereact/button";

import { ModalEntradaComplementoNfManual } from "./ModalEntradaComplementoNfManual";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import useEntrada from "@/hooks/useEntrada";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";

export const DocumentosFiscaisForm = () => {
    const listagemRef = useRef(null);
    const modalNfRef = useRef(null);
    const { dadosBasicos, permiteAlterar, atualizarValoresEntrada } = useEntrada();
    const { showInfo } = useToast();

    useClearRefs(listagemRef, modalNfRef);

    const edicaoBloqueada = !permiteAlterar;

    useEffect(() => {
        if (dadosBasicos?.xmls_pendentes > 0) {
            const xmlsPendentes = dadosBasicos.xmls_pendentes;
            showInfo({
                summary: "Aviso",
                detail:
                    xmlsPendentes === 1
                        ? "Você possui 1 xml pendente."
                        : `Você possui ${xmlsPendentes} xml's pendentes`,
                life: 3000,
            });
        }
    }, [dadosBasicos, showInfo]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_ENTRADA_ENTRADA_EDITAR]}
                    componente={Button}
                    disabled={edicaoBloqueada}
                    icon="pi pi-pencil"
                    onClick={() => modalNfRef.current?.abrirModal(rowData)}
                    className="p-button-rounded p-button-warning p-mr-2 p-mt-1"
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_ENTRADA_ENTRADA_EDITAR]}
                    componente={BotaoDelete}
                    disabled={edicaoBloqueada}
                    url={`/compras/entradas/${dadosBasicos?.id}/nota-fiscal/`}
                    objetoId={rowData.nota_fiscal.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir a nota fiscal <b>{rowData.nota_fiscal.numero}</b> dessa entrada?
                        </span>
                    }
                    msgToastErroExclusao="A nota fiscal não pode ser excluída."
                    className="p-mt-1"
                    onDelete={async () => {
                        await atualizarValoresEntrada();
                        listagemRef.current?.buscarDados();
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "nota_fiscal.numero", header: "Documento", style: { width: "8%" } },
        { field: "nota_fiscal.modelo", header: "Modelo", style: { width: "10%" } },
        {
            field: "nota_fiscal.datahora_emissao",
            header: "Emissão",
            style: { width: "15%" },
            dateFormat: "dd/MM/yyyy HH:mm:ss",
        },
        {
            field: "nota_fiscal.valor_total_nf",
            header: "Valor",
            money: true,
            style: { width: "15%" },
        },
        { field: "nota_fiscal.chave_nf", header: "Chave" },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "10%" } },
    ];

    return (
        <>
            <ModalEntradaComplementoNfManual
                ref={modalNfRef}
                onSave={async () => {
                    await atualizarValoresEntrada();
                    listagemRef.current?.buscarDados();
                }}
            />
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={`/compras/notafiscal-entradas/?entrada=${dadosBasicos.id}`}
                configTabela={{
                    lazy: true,
                    paginator: true,
                }}
            />
        </>
    );
};
