import React from 'react';

import { Chart } from "primereact/chart";

const MakoCardGraficoPizza = ({
    title,
    subtitle,
    chartProps
}) => {
    return (
        <div className="card revenue">
            <h4>{title}</h4>
            <p>{subtitle}</p>
            <div className="revenue-chart-container">
                <Chart type="pie" {...chartProps}></Chart>
            </div>
        </div>
    );
}

export default MakoCardGraficoPizza;