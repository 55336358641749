import React, { useCallback, useState } from "react";

import { MakoButtonAtivar } from "@/components/MakoButtonAtivar";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";

import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const BASE_DEVOLUCAO = { id: 0, ativo: true };
const URL_BACKEND = "/compras/tipos-devolucoes-fornecedor";

export const Status = ({ tipoDevolucao = BASE_DEVOLUCAO, onSuccessCallback = () => {} }) => {
    const [loading, setLoading] = useState(false);

    const { showSuccess } = useToast();
    const { httpPatch } = useHttp();

    const handleStatus = useCallback(async () => {
        setLoading(true);
        const { id, ativo } = tipoDevolucao;
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: `${!ativo ? "Ativado" : "Desativado"} com sucesso!`,
                    life: 3000,
                });
                onSuccessCallback();
            },
        };
        await httpPatch({ url: `${URL_BACKEND}/${id}/`, body: { ativo: !ativo } }, handlers);
        setLoading(false);
    }, [onSuccessCallback, showSuccess, tipoDevolucao, httpPatch]);

    return (
        <MakoControleAcesso
            componente={MakoButtonAtivar}
            permissao={[permissoes.COMPRAS_CADASTRO_TIPODEVOLUCAO_ALTERARDESATIVAR]}
            ativo={tipoDevolucao.ativo}
            loading={loading}
            onClick={handleStatus}
        />
    );
};
