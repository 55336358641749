import React, { useState, useRef, useCallback, useEffect } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useToast from "@/hooks/useToast";
import { Menu } from "primereact/menu";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { FiltroRequisicoesModalForm } from "./ModalFiltroAvancado";
import { DividirRequisicaoModalForm } from "./ModalDividirRequisicao";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { key_filtros } from "@/assets/constants/filtros";
import { TransferirModalForm } from "./ModalTransferencia";
import { TIPO_REQUISICAO_TRANSF } from "@/assets/constants/estoque";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

export const RequisicoesTransferenciaPage = () => {
    const BASE_URL = "/transferencias/requisicao-transferencia";
    const [filtros, setFiltro, removerFiltro, filtroString] = useLocalFiltro(
        key_filtros.MOVIMENTACOES_REQUISICAO_TRANSFERENCIA
    );
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const [centro, setCentro] = useState(null);
    const [requisicao, setRequisicao] = useState(false);
    const [bloqueiaTransferencia, setBloqueiaTransferencia] = useState(true);
    const [requisicoesSelecionadas, setRequisicoesSelecionadas] = useState([]);
    const [url, setUrl] = useState(() => {
        if (filtros) return `${BASE_URL}?${filtroString}`;
        return BASE_URL;
    });
    const listagemRef = useRef(null);
    const menuRef = useRef(null);
    const filtro = useRef(null);
    const dividir = useRef(null);
    const transferir = useRef(null);
    const history = useHistory();
    const { showSuccess } = useToast();
    const { verifyPermission } = useAuth();
    const { httpPatch } = useHttp();

    const handleCancelar = async () => {
        const handlers = {
            200: () => {
                listagemRef.current?.buscarDados();
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Requisição de transferência cancelada com sucesso.",
                    life: 1500,
                });
            },
        };

        await httpPatch(
            {
                url: `/transferencias/requisicao-transferencia/${requisicao.id}/`,
                body: {
                    status: "C",
                },
            },
            handlers
        );
    };

    const items = [
        {
            label: "Transferir",
            icon: "pi pi-sort-alt",
            disabled: !verifyPermission([permissoes.ESTOQUE_MOVIMENTACOES_TRANSFERENCIAMERCADORIA_INCLUIR]),
            command: () => {
                setRequisicoesSelecionadas([requisicao]);
                transferir.current?.exibirDialog();
            },
        },
        {
            label: "Cancelar",
            icon: "pi pi-times",
            command: () => handleCancelar(),
            disabled: !verifyPermission([permissoes.ESTOQUE_MOVIMENTACOES_REQUISICAOTRANSFERENCIA_CANCELAR]),
        },
        {
            label: "Visualizar",
            icon: "pi pi-eye",
            command: () =>
                history.push({
                    pathname: "/estoque/movimentacoes/requisicoes-transferencia/form",
                    visualizar: true,
                    state: {
                        ...requisicao,
                        tipo_requisicao: requisicao.tipo_requisicao.id,
                        ce_destino: requisicao.ce_destino.id,
                        ce_origem: requisicao.ce_origem.id,
                    },
                }),
        },
        {
            label: "Dividir",
            icon: "pi pi-percentage",
            command: () => dividir.current?.exibirDialog(requisicao),
            disabled: !verifyPermission([permissoes.ESTOQUE_MOVIMENTACOES_REQUISICAOTRANSFERENCIA_DIVIDIR]),
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_MOVIMENTACOES_REQUISICAOTRANSFERENCIA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    disabled={rowData.status === "C"}
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/movimentacoes/requisicoes-transferencia/form",
                            state: {
                                ...rowData,
                                tipo_requisicao: rowData.tipo_requisicao.id,
                                ce_destino: rowData.ce_destino.id,
                                ce_origem: rowData.ce_origem.id,
                            },
                        })
                    }
                />
                <Menu model={items} popup ref={menuRef} />
                <Button
                    icon="pi pi-cog"
                    tooltip="Alterar cadastro de requisição"
                    tooltipOptions={{ position: "left" }}
                    className="p-button-rounded p-button-info"
                    disabled={rowData.status === "C"}
                    onClick={(e) => {
                        setRequisicao(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
            </div>
        );
    };

    const verificaBloqueiaTransf = useCallback(() => {
        if (requisicoesSelecionadas?.length > 0) {
            const destino = requisicoesSelecionadas
                .map((requisicao) => requisicao.status !== "C" && requisicao.ce_destino?.id)
                .filter(Boolean);
            const origem = requisicoesSelecionadas
                .map((requisicao) => {
                    if (requisicao.status === "C") return true;
                    return requisicao.ce_origem?.id;
                })
                .filter(Boolean);

            if (origem.filter((e) => e.status === "C") > 0 || destino.filter((e) => e.status === "C") > 0)
                return setBloqueiaTransferencia(true);

            return setBloqueiaTransferencia(
                [...new Set(destino)].length === 1 && [...new Set(origem)].length === 1 ? false : true
            );
        }
        return setBloqueiaTransferencia(true);
    }, [requisicoesSelecionadas]);

    useEffect(() => {
        verificaBloqueiaTransf();
    }, [verificaBloqueiaTransf]);

    const painelEsquerdo = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_PAGAMENTOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/estoque/movimentacoes/requisicoes-transferencia/form")}
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2 p-mb-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => filtro.current?.exibirDialog()}
            />
            <Button
                label="Limpar filtro"
                icon="pi pi-filter-slash"
                className="p-button-warning p-mr-2 p-mb-2"
                disabled={totalizadorFiltros === 0}
                onClick={() => {
                    removerFiltro();
                    setUrl(BASE_URL);
                    setTotalizadorFiltros(0);
                }}
            />
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_PAGAMENTOEFETIVADO_INCLUIR]}
                componente={Button}
                disabled={bloqueiaTransferencia}
                label="Transferir"
                icon="pi pi-sort-alt"
                className="p-button-info p-mr-2 p-mb-2"
                onClick={() => transferir.current?.exibirDialog()}
            />
        </>
    );

    const painelDireito = (
        <>
            <Dropdown
                id="centro-estocagem"
                name="centro_estocagem"
                url="/produtos/centros-estocagem/"
                optionLabel="descricao"
                optionValue="id"
                style={{ width: "350px" }}
                placeholder="Selecione um centro de destino"
                className="p-mb-2"
                value={centro}
                onChange={({ value }) => setCentro(value)}
            />
        </>
    );

    const tipoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_REQUISICAO_TRANSF,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { selectionMode: "multiple", style: { width: "5%" } },
        {
            field: "id",
            header: "Nº requisição",
            minWidth: { width: "12%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "tipo_requisicao.descricao",
            header: "Tipo requisição",
            filter: true,
            filterElement: tipoFiltroTemplate,
            filterField: "tipo_requisicao",
            minWidth: { width: "10%" },
        },
        {
            field: "sku.codigo",
            header: "Código",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "sku__codigo",
            minWidth: { width: "10%" },
        },
        {
            field: "sku.descricao_reduzida",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "sku__descricao_reduzida",
        },
        {
            field: "quantidade",
            header: "Quantidade",
            minWidth: { width: "15%" },
        },
        {
            field: "ce_destino.nome",
            header: "C.Estocagem destino",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "ce_destino__nome",
            minWidth: { width: "10%" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            minWidth: { width: "6%" },
        },
    ];

    const rowClass = (requisicao) => {
        return {
            "table-recebimentos-effective": requisicao.status === "F",
            "table-recebimentos-overdue": requisicao.status === "C",
        };
    };

    const aposSalvar = () => {
        listagemRef.current?.buscarDados();
        showSuccess({
            summary: "Sucesso!",
            detail: "Requisição de transferência divida com sucesso.",
            life: 1500,
        });
    };

    const onFinish = (url, contador, filtros) => {
        setCentro(null);
        setUrl(url);
        setTotalizadorFiltros(contador);
        setFiltro(filtros);
    };

    return (
        <>
            <PageBase>
                <MakoListagem
                    ref={listagemRef}
                    titulo="Requisições de Transferências"
                    colunas={colunas}
                    painelEsquerdo={painelEsquerdo}
                    painelDireito={painelDireito}
                    urlPesquisa={`${url}${centro ? `?ce_destino=${centro}` : ""}`}
                    configTabela={{
                        selection: requisicoesSelecionadas,
                        onSelectionChange: (e) => setRequisicoesSelecionadas(e.value),
                        selectionMode: "multiple",
                        rowClassName: rowClass,
                        paginator: true,
                        lazy: true,
                        scrollable: true,
                    }}
                    filtros={{
                        id: { value: null, matchMode: "equals" },
                        tipo_requisicao: { value: null, matchMode: "equals" },
                        quantidade: { value: null, matchMode: "equals" },
                        sku__codigo: { value: null, matchMode: "equals" },
                        sku__descricao_reduzida: { value: null, matchMode: "icontains" },
                        ce_destino__nome: { value: null, matchMode: "icontains" },
                    }}
                />
                <FiltroRequisicoesModalForm
                    url={BASE_URL}
                    ref={filtro}
                    onSuccess={onFinish}
                    filtros={filtros}
                    setFiltros={setFiltro}
                    removerFiltro={removerFiltro}
                    setTotalizadorFiltros={setTotalizadorFiltros}
                />
                <DividirRequisicaoModalForm aposSalvar={aposSalvar} ref={dividir} />
                <TransferirModalForm requisicoes={requisicoesSelecionadas} ref={transferir} />
            </PageBase>
        </>
    );
};
