import React from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton } from "@/components/MakoButton";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

export const Editar = ({ className = "", to, onClick }) => {
    return (
        <MakoControleAcesso
            componente={MakoButton}
            permissao={[permissoes.COMPRAS_CADASTRO_TIPODEVOLUCAO_EDITAR]}
            icon={MAKO_ICONS.EDITAR}
            className={`p-button-warning p-button-rounded ${className}`}
            to={to}
            onClick={onClick}
        />
    );
};
