import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import useHttp from "@/hooks/useHttp";

const url = "/produtos/unidades-medida/";
const urlvoltar = "/produto/produto/unidade-medida";

export const ProdutoUnidadeMedidaFormBase = ({ unidadeMedida, unidade, aposSalvar, closeDialog, onSubmit }) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPatch } = useHttp();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            sigla: "",
            nome: "",
            unidade_equivalente: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sigla: Yup.string()
                    .max(4, "O campo 'sigla' não pode ser maior que 4 caracteres.")
                    .required("O campo 'sigla' é obrigatório."),
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Unidade de medida cadastrada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        if (typeof aposSalvar === "function") aposSalvar();
                        if (!unidade) history.push(urlvoltar);
                        else {
                            if (typeof onSubmit === "function") onSubmit(data);
                            if (typeof closeDialog === "function") closeDialog(data);
                        }
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Unidade de medida alterada com sucesso!",
                            life: 1500,
                        });
                        if (typeof aposSalvar === "function") aposSalvar();
                        if (!unidade) history.push(urlvoltar);
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
        }
    }

    useEffect(() => {
        if (unidadeMedida) setValues(unidadeMedida);
    }, [unidadeMedida, setValues]);

    return (
        <>
            {!!!unidade && <h3>{!formik.values.id ? "Nova unidade de medida" : "Manutenção de unidade de medida"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className={!unidade ? "p-field p-col-12 p-md-2" : "p-field p-col-12 p-md-12"}>
                        <label htmlFor="sigla">Sigla *</label>
                        <InputText
                            id="sigla"
                            name="sigla"
                            keyfilter={/[A-Za-z0-9]/}
                            tooltip="Tamanho de 1 a 4 caracteres"
                            value={formik.values.sigla}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.sigla })}
                        />
                        {formik.errors.sigla && <small className="p-error">{formik.errors.sigla}</small>}
                    </div>
                    <div className={!unidade ? "p-field p-col-12 p-md-6" : "p-field p-col-12 p-md-12"}>
                        <label htmlFor="nome">Nome *</label>
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className={!unidade ? "p-field p-col-12 p-md-4" : "p-field p-col-12 p-md-12"}>
                        <label htmlFor="unidade-equivalente">Unidade equivalente</label>
                        <Dropdown
                            id="unidade-equivalente"
                            name="unidade_equivalente"
                            url={"/produtos/unidades-medida?limit=1000"}
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.unidade_equivalente}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" icon="pi pi-save" className="p-button-info p-mr-2" />
                    {!unidade && (
                        <Button
                            label="Voltar"
                            icon="pi pi-angle-double-left"
                            type="button"
                            className="p-button-danger p-mr-2"
                            onClick={() => history.push(urlvoltar)}
                        />
                    )}
                </div>
            </form>
        </>
    );
};
