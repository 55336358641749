import React from "react";

import { Button } from "primereact/button";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const Cancelar = ({ className = "", onClick = () => {}, disabled = false }) => {
    return (
        <Button
            label="Cancelar seleção"
            icon={MAKO_ICONS.LIMPAR_FORM}
            className={`p-button p-component p-button-warning ${className}`}
            disabled={disabled}
            onClick={onClick}
        />
    );
};
