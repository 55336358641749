import React from "react";

import { Recebimentos, COLUNAS_EXISTENTES as COLUNA } from "../../../../../Recebimentos/listagens";

const APENAS_COLUNAS = [
    { chave: COLUNA.VENDA, config: { filter: false } },
    { chave: COLUNA.DEVEDOR, config: { filter: false } },
    { chave: COLUNA.NUMERO_PARCELA, config: { filter: false, style: { width: "10%" } } },
    { chave: COLUNA.VENCIMENTO, config: { filter: false, style: { width: "12%" } } },
    { chave: COLUNA.VALOR, config: { filter: false, style: { width: "12%" } } },
    { chave: COLUNA.DIASATRASO, config: { filter: false, header: "Atraso" } },
];

export const Listagem = () => {
    return (
        <div className="p-mt-2">
            <Recebimentos titulo={null} apenasColunas={APENAS_COLUNAS} exibirOverlay={false} />
        </div>
    );
};
