import React, { createContext, useCallback, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { dataToStr, parseData } from "@/assets/util/datas";
import useHttp from "@/hooks/useHttp";

const RequisicaoContext = createContext({});

export const RequisicaoProvider = ({ children }) => {
    const [requisicao, setRequisicao] = useState(null);
    const [isEditing, setIdEditing] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const { showWarning, showSuccess } = useToast();
    const { httpGet, httpPatch, httpPost } = useHttp();
    const location = useLocation();

    const PERSISTIR_TYPES_REQUISICAO = {
        NEW: "new",
        UPDATE: "update",
    };

    const fetchRequisicao = useCallback(
        async (id) => {
            const handlers = {
                200: ({ data }) => {
                    setRequisicao(data);
                },
            };

            showLoading();
            await httpGet({ url: `/materiais/requisicoes/${id}/` }, handlers);
            hideLoading();
        },
        [showLoading, hideLoading, httpGet]
    );

    const updateRequisicao = useCallback(
        (values) => {
            setRequisicao(values);
        },
        [setRequisicao]
    );

    const handleRequisicao = useCallback(() => {
        let edit = false;
        if (location?.state.requisicao && location.state.requisicao) {
            let copy = { ...location.state.requisicao };
            setRequisicao({
                ...copy,
                centro_estocagem: copy.centro_estocagem.id,
                centro_resultados: copy.centro_resultados.id,
                grupo_categoria: copy.grupo_categoria.id,
                setor: copy.setor.id,
                data_hora: parseData(copy.data_hora),
                itemrequisicao_set: copy.itemrequisicao_set.map((item) => ({
                    ...item,
                    unidade_medida: item.unidade_medida.id,
                    data_prevista: parseData(item.data_prevista),
                })),
            });
            edit = true;
        }
        setIdEditing(edit);
    }, [setIdEditing, location]);

    const postRequisicao = useCallback(async () => {
        let sucesso = false;
        let copy = { ...requisicao };
        let dataFormated = {
            create: [],
        };
        copy.itemrequisicao.map((item) => {
            delete item.id;
            delete item.fakeId;
            item.sku = item.sku.id;
            item.data_prevista = dataToStr(item.data_prevista, "yyyy-MM-dd");
            dataFormated.create.push(item);
        });
        if (copy.solicitante != null) copy.solicitante = copy.solicitante.id;

        delete copy.itemrequisicao;

        copy.itemrequisicao_set = dataFormated;
        copy.status = "A";

        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso :)",
                    detail: "Requisição cadastrada.",
                    life: 3000,
                });
                sucesso = true;
            },
        };

        await httpPost({ url: "/materiais/requisicoes/", body: copy }, handlers);

        return false;
    }, [requisicao, showSuccess, httpPost]);

    const patchRequisicao = useCallback(async () => {
        let sucesso = false;
        let copy = { ...requisicao };
        let dataFormated = {
            create: [],
            update: [],
            remove: [],
        };

        if (copy.solicitante != null) copy.solicitante = copy.solicitante.id;

        copy.itemrequisicao_set.map((item) => {
            item.sku = item.sku.id;
            item.data_prevista = dataToStr(item.data_prevista, "yyyy-MM-dd");
            switch (item.tag) {
                case "new":
                    delete item.id;
                    delete item.fakeId;
                    delete item.tag;
                    dataFormated.create.push(item);
                    break;

                case "update":
                    delete item.tag;
                    dataFormated.update.push(item);
                    break;

                case "delete":
                    delete item.tag;
                    dataFormated.remove.push(item.id);
                    break;

                default:
                    break;
            }
        });

        delete copy.itemrequisicao;

        if (dataFormated.create.length === 0) delete dataFormated.create;
        if (dataFormated.update.length === 0) delete dataFormated.update;
        if (dataFormated.remove.length === 0) delete dataFormated.remove;

        copy.itemrequisicao_set = dataFormated;

        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso :)",
                    detail: "Requisição atualizada.",
                    life: 3000,
                });
                sucesso = true;
            },
        };

        await httpPatch({ url: `/materiais/requisicoes/${requisicao.id}/`, body: copy }, handlers);

        return sucesso;
    }, [requisicao, showSuccess, httpPatch]);

    const persistirRequisicao = useCallback(
        async (type) => {
            showLoading();
            let success = false;
            switch (type) {
                case "new":
                    success = await postRequisicao();
                    break;

                case "update":
                    success = await patchRequisicao();
                    break;

                default:
                    showWarning({
                        summary: "Erro, tipo de operação inválida :(",
                        detail: "Informe um tipo de persistência válido.",
                        life: 3000,
                    });
                    break;
            }
            hideLoading();

            return success;
        },
        [showLoading, showWarning, hideLoading, patchRequisicao, postRequisicao]
    );

    useEffect(() => handleRequisicao(), [handleRequisicao]);

    return (
        <RequisicaoContext.Provider
            value={{
                isEditing,
                requisicao,
                PERSISTIR_TYPES_REQUISICAO,
                fetchRequisicao,
                updateRequisicao,
                persistirRequisicao,
            }}
        >
            {children}
        </RequisicaoContext.Provider>
    );
};

export default RequisicaoContext;
