import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import MakoListagem from "@/components/MakoListagem";
import { useHistory } from "react-router-dom";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { Dialog } from "primereact/dialog";
import { ProdutoGradeAtributosFormModal } from "./formModal";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Dropdown } from "@/components/Dropdown";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { useFormik } from "formik";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

export const ProdutoGradeAtributosPage = () => {
    const BASE_URL = `/produtos/grades-atributos`;
    const history = useHistory();
    const [atributo, setAtributo] = useState(null);
    const [gradeDialog, setGradeDialog] = useState(false);
    const [gradeAtributo, setGradeAtributo] = useState(null);
    const [filtroAvancado, setFiltroAvancado] = useState(false);
    const [url, setUrl] = useState(BASE_URL);
    const listagemRef = useRef(null);
    const { showError } = useToast();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            valor_numerico: null,
            calcula_medida: null,
            usa_preco_medida_servico: null,
        },
        onSubmit: handleSearch,
        enableReinitialize: true,
    });

    const onFilter = (e) => {
        setUrl(e);
    };

    async function handleSearch(values) {
        try {
            if (typeof onFilter === "function") {
                let params = [];
                Object.keys(values).forEach((key) => {
                    if ((values[key] && values[key] !== "") || typeof values[key] === "boolean") {
                        params.push(`${key}=${values[key]}`);
                    }
                });
                if (params.length > 0) {
                    onFilter(BASE_URL + `?${params.join("&")}`);
                } else setUrl(BASE_URL);
            }
            setFiltroAvancado(false);
        } catch (error) {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listar suas tags de perfis.",
                life: 3000,
            });
        }
    }

    const actionService = (rowData) => {
        let service;
        if (rowData.servico_venda !== null) {
            service = rowData.servico_venda.nome;
        } else {
            service = "";
        }

        return (
            <>
                <div className="p-text-normal">{service}</div>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GRADEATRIBUTO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de Atributo"
                    tooltipOptions={{ position: "left" }}
                    type="button"
                    onClick={() => exibirGradeDialog(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GRADEATRIBUTO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={`/produtos/grades-atributos/`}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    type="button"
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir a grade atributo código: "}
                            <b>{rowData.codigo}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar grade de atributos"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="A grade de atributos não pode ser excluída."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const ServicoVendaFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "nome",
                    url: "/vendas/servicos-vendas/?limit=1000",
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "100px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "atributo.nome",
            header: "Atributo",
            style: { minWidth: "180px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "atributo__nome",
        },
        {
            field: "codigo",
            header: "Grade",
            style: { minWidth: "200px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "opcoes_valor",
            header: "Opções",
            style: { minWidth: "300px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "servico_venda",
            header: "Serviço",
            action: (e) => actionService(e),
            style: { minWidth: "200px" },
            filter: true,
            filterElement: ServicoVendaFiltroTemplate,
            filterField: "servico_venda",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "120px" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    const esconderGradeDialog = () => {
        setGradeDialog(false);
        setGradeAtributo(null);
        listagemRef.current?.buscarDados();
    };

    const exibirGradeDialog = (grade) => {
        setAtributo(grade.atributo);
        setGradeAtributo(grade);
        setGradeDialog(true);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_GRADEATRIBUTO_INCLUIR]}
                componente={Button}
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                type="button"
                onClick={() => history.push("/produto/cadastros/grade-atributos/form")}
                label="Novo"
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                onClick={() => setFiltroAvancado(true)}
            />
        </>
    );

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Grade Atributos"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    atributo__nome: { value: null, matchMode: "icontains" },
                    codigo: { value: null, matchMode: "icontains" },
                    opcoes_valor: { value: null, matchMode: "icontains" },
                    valor_numerico: { value: null, matchMode: "equals" },
                    calcula_medida: { value: null, matchMode: "equals" },
                    servico_venda: { value: null, matchMode: "equals" },
                    usa_preco_medida_servico: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    scrollable: true,
                    frozenWidth: "200px",
                    paginator: true,
                    lazy: true,
                }}
            />
            <Dialog
                header={`Adicionar Grade Atributo (${atributo?.nome})`}
                visible={gradeDialog}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "65vw" }}
                onHide={() => setGradeDialog(false)}
            >
                <ProdutoGradeAtributosFormModal
                    listagemRef={listagemRef}
                    atributo={atributo?.id || null}
                    esconderDialog={esconderGradeDialog}
                    gradeAtributo={gradeAtributo}
                />
            </Dialog>
            <Dialog
                header="Filtro avançado"
                visible={filtroAvancado}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "55vw" }}
                onHide={() => setFiltroAvancado(false)}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="valor_numerico">Valor numérico</label>
                            <Dropdown
                                id="valor_numerico"
                                name="valor_numerico"
                                options={SIM_NAO_BOOLEAN}
                                optionLabel="label"
                                optionValue="id"
                                showClear
                                placeholder="Todos"
                                value={formik.values.valor_numerico}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="calcula_medida">Calcula medida</label>
                            <Dropdown
                                id="calcula_medida"
                                name="calcula_medida"
                                options={SIM_NAO_BOOLEAN}
                                optionLabel="label"
                                optionValue="id"
                                showClear
                                placeholder="Todos"
                                value={formik.values.calcula_medida}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="usa_preco_medida_servico">Vlr. serviço conforme medida</label>
                            <Dropdown
                                id="usa_preco_medida_servico"
                                name="usa_preco_medida_servico"
                                options={SIM_NAO_BOOLEAN}
                                optionLabel="label"
                                optionValue="id"
                                showClear
                                placeholder="Todos"
                                value={formik.values.usa_preco_medida_servico}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-justify-end p-mt-4">
                        <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2"
                            onClick={() => resetForm()}
                        />
                        <Button
                            type="button"
                            label="Cancelar"
                            onClick={() => setFiltroAvancado(false)}
                            className="p-button-danger p-mr-3"
                        />
                    </div>
                </form>
            </Dialog>
        </PageBase>
    );
};
