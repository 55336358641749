import React, { useRef, useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { useHistory } from "react-router-dom";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { TIPO_COMPOSICAO_TEMPLATE_CHOICE } from "@/assets/constants/constants";
import { gerarId } from "@/assets/util/util";
import { Dropdown } from "@/components/Dropdown";
import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { Checkbox } from "primereact/checkbox";

const url = "/produtos/templates-descricoes-skus/";

export const PadraoTemplateForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const [showItemTemp, setShowItemTemp] = useState(false);
    const [listaItens, setListaItens] = useState([]);
    const [remocaoItemTemplate, setRemocaoItemTemplate] = useState([]);
    const [inserindoItemTemplate, setInserindoItemTemplate] = useState([]);
    const [tipo, setTipo] = useState(null);
    const [sequencia, setSequencia] = useState(null);
    const [tamanho, setTamanho] = useState(null);
    const [atributo, setAtributo] = useState(null);
    const listagemRef = useRef(null);
    const history = useHistory();
    const { showSuccess, showError, showWarning } = useToast();
    const { httpPost, httpPatch } = useHttp();

    const { resetForm, ...formik } = useFormik({
        initialValues: props.location.state
            ? props.location.state.templateSku
            : {
                  separador: " ",
                  descricao: "",
                  ativo: true,
                  composicaotemplatedescricaosku_set: { create: [] },
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descricao' é obrigatório."),
                separador: Yup.string().max(1),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Template cadastrado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/produto/cadastros/padrao-nome");
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: {
                            ...values,
                            composicaotemplatedescricaosku_set: { create: inserindoItemTemplate },
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Template alterado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/produto/cadastros/padrao-nome");
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: {
                            ...values,
                            composicaotemplatedescricaosku_set: {
                                remove: remocaoItemTemplate,
                                create: inserindoItemTemplate,
                            },
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        resetForm();
        history.push("/produto/cadastros/padrao-nome");
    };

    const onInsere = () => {
        const _ID = gerarId();
        if (listaItens.filter((valor) => valor.sequencia === sequencia).length === 0) {
            setInserindoItemTemplate([
                ...inserindoItemTemplate,
                {
                    id: _ID,
                    sequencia: sequencia,
                    tipo: tipo.id,
                    atributo: atributo && atributo.id ? atributo.id : null,
                    tamanho: tamanho,
                },
            ]);
            setListaItens([
                ...listaItens,
                {
                    id: _ID,
                    sequencia: sequencia,
                    tipo: tipo,
                    atributo: atributo && atributo.id ? atributo.id : null,
                    tamanho: tamanho,
                },
            ]);

            showSuccess({
                summary: "Sucesso",
                detail: "Inserido com sucesso!",
                life: 1500,
            });
            setSequencia(null);
            setTamanho(null);
            setAtributo(null);
            setTipo(null);
        } else {
            showWarning({
                severity: "warn",
                summary: "Sequência duplicada!",
                detail: "A sequência informada já existe, por favor corriga a informação e tente novamente.",
                life: 3000,
            });
        }
    };

    useEffect(() => {
        if (props.location.state?.templateSku?.composicaotemplatedescricaosku_set?.length > 0)
            setListaItens(props.location.state.templateSku.composicaotemplatedescricaosku_set.map((value) => value));
    }, [props.location.state?.templateSku?.composicaotemplatedescricaosku_set]);

    useEffect(() => {
        listagemRef.current?.buscarDados();
    }, [listaItens]);

    const removeItemTemplate = (item) => {
        const lista = listaItens.filter((value) => {
            if (item.id !== value.id) return value;
        });
        setListaItens(lista);
        setInserindoItemTemplate(lista);
        if (typeof item.id !== "string") setRemocaoItemTemplate([...remocaoItemTemplate, item.id]);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_COMPOSICAOTEMPLATEDESCRICAOSKU_EXCLUIR]}
                    componente={Button}
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => removeItemTemplate(rowData)}
                    tooltip="Deletar centro de estoque"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_COMPOSICAOTEMPLATEDESCRICAOSKU_INCLUIR]}
                componente={Button}
                label="Item"
                icon="pi pi-plus"
                type="button"
                className="p-button-success p-mr-2"
                onClick={() => setShowItemTemp(true)}
            />
        </>
    );

    const colunas = [
        { field: "sequencia", header: "Sequencia" },
        { field: "tamanho", header: "Tamanho" },
        { field: "tipo.descricao", header: "Tipo" },
        { field: "atributo.nome", header: "Atributo" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "20%" },
        },
    ];

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova Descrição Dinâmica" : "Manutenção de Descrição Dinâmica"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="separador">Separador *</label>
                        <InputText
                            id="separador"
                            name="separador"
                            value={formik.values.separador}
                            onChange={formik.handleChange}
                            placeholder="Caso vazio: [ESPAÇO]"
                            tooltip="No caso desse campo não ser preenchido, será considerado um 'ESPAÇO'."
                            className={classNames({ "p-invalid": formik.errors.separador })}
                        />
                        {formik.errors.separador && <small className="p-error">{formik.errors.separador}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativo?</label>
                    </div>
                </div>
                {listaItens && (
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        dadosLocal={listaItens}
                        configTabela={{
                            lazy: true,
                            paginator: true,
                        }}
                    />
                )}
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    <Button
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={cancelar}
                    />
                </div>
            </form>

            <Dialog
                header={"Cadastro item do template"}
                visible={showItemTemp}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "45vw", display: "block" }}
                onHide={() => setShowItemTemp(false)}
            >
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="tipo">Tipo *</label>
                            <Dropdown
                                id="tipo"
                                placeholder="Selecione um tipo"
                                name="tipo"
                                filter
                                filterBy="descricao"
                                onChange={(e) => setTipo(e.target.value)}
                                value={tipo}
                                optionLabel="descricao"
                                showClear={false}
                                options={TIPO_COMPOSICAO_TEMPLATE_CHOICE}
                                autoComplete="off"
                                autoFocus
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="atributo">Atributo</label>
                            <Dropdown
                                id="atributo"
                                placeholder="Selecione um atributo"
                                name="atributo"
                                filter
                                filterBy="nome"
                                value={atributo}
                                onChange={({ value }) => setAtributo(value)}
                                url="/produtos/atributos?limit=300"
                                optionLabel="nome"
                                disabled={tipo?.id ? (["CA", "NA", "VA"].includes(tipo.id) ? false : true) : true}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="sequencia">Sequencia *</label>
                            <InputNumber
                                id="sequencia"
                                name="sequencia"
                                min={0}
                                showButtons
                                onValueChange={(e) => setSequencia(e.target.value)}
                                value={sequencia}
                                autoComplete="off"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="tamanho">Tamanho *</label>
                            <InputNumber
                                id="tamanho"
                                name="tamanho"
                                min={1}
                                showButtons
                                onValueChange={(e) => setTamanho(e.target.value)}
                                value={tamanho}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-justify-end p-mr-1">
                        <Button
                            label="Inserir"
                            type="button"
                            className="p-button-success"
                            disabled={!(tipo !== null && sequencia !== null && tamanho !== null)}
                            onClick={() => onInsere()}
                        />
                    </div>
                </form>
            </Dialog>
        </PageBase>
    );
};
