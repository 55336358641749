import React, { useCallback } from "react";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { RELATORIO_VENDAS_TABELAROMANEIO } from "@/assets/constants/relatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

import useFetchRelatorio from "@/hooks/useFetchRelatorio";
import useRelatorio from "@/hooks/useRelatorio";

const { RELATORIOS_EMITIR_VENDAS_ROMANEIO } = permissoes;

const CHAVE = RELATORIO_VENDAS_TABELAROMANEIO;

export const Romaneio = ({ previsaoId, className = "" }) => {
    const { solicitarRelatorio } = useRelatorio();
    const { versao } = useFetchRelatorio({ chave: CHAVE });

    const solicitar = useCallback(() => {
        solicitarRelatorio({ chave: CHAVE, filtros: { items_previsoes: [previsaoId] }, enviar_fila: false, versao });
    }, [solicitarRelatorio, previsaoId, versao]);

    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[RELATORIOS_EMITIR_VENDAS_ROMANEIO]}
            label="2° via romaneio"
            icon={MAKO_ICONS.ARQUIVO_PDF}
            className={classNames("p-button-info", className)}
            onClick={solicitar}
            disabled={!versao}
        />
    );
};
