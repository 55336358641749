import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { formatarPercentual, parseNumberToMoneyHTML } from "@/assets/util/util";
import { CodigoFiltroTemplate, MoedaFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { PorcentagemFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { BotaoDelete } from "@/components/BotaoDelete";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

export const RegraCrediarioPage = () => {
    const { showSuccess } = useToast();
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_PARAMETRIZACAOCREDIARIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar cadastro do parâmetro"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/crediario/regra-crediario/form",
                            regra: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_PARAMETRIZACAOCREDIARIO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/crediario/regra-crediario/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a regra de crediário <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A regra de crediário não pode ser excluída."
                    tooltip="Deletar regra"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Regra deletada com sucesso!",
                            life: 3000,
                        });
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/vendas/venda/crediario/regra-crediario/form")}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { with: "25%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "percentual_limite",
            header: "Percentual Limite",
            action: (e) => formatarPercentual(e.percentual_limite),
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "valor_minimo",
            header: "Valor Mínimo",
            action: (e) => parseNumberToMoneyHTML(e.valor_minimo),
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "valor_maximo",
            header: "Valor Máximo",
            action: (e) => parseNumberToMoneyHTML(e.valor_maximo),
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "natureza_ocupacao.descricao",
            header: "Natureza Ocupação",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "natureza_ocupacao__descricao",
        },
        { field: "action", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Regras de Crediário"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/crediario/regra-crediario/"
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    descricao: { value: null, matchMode: "icontains" },
                    percentual_limite: { value: null, matchMode: "equals" },
                    valor_minimo: { value: null, matchMode: "equals" },
                    valor_maximo: { value: null, matchMode: "equals" },
                    natureza_ocupacao__descricao: { value: null, matchMode: "icontains" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
