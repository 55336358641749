import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";

export const SkuFiltroTemplate = ({ inputProps = {}, options }) => {
    return (
        <MakoBuscaSkuPersonalizada
            placeholder="Digite para pesquisar..."
            {...inputProps}
            skuValue={options.value}
            skuChange={(e) => options.filterCallback(e)}
        />
    );
};
