/* eslint-disable jsx-a11y/anchor-has-content */
import { LINK_SUPORTE_SHARKDEV } from "@/assets/constants/constants";
import { Divider } from "primereact/divider";
import React from "react";

export const MakoPoliticaPrivacidade = () => {
    return (
        <div>
            <p>
                <Divider align="center">
                    <h5 className="p-m-0">Política de Privacidade</h5>
                </Divider>
                <b>Introdução</b>
                <br />
                Bem-vindo ao nosso website! Respeitamos a sua privacidade e estamos comprometidos em proteger os seus
                dados pessoais de acordo com a Lei Geral de Proteção de Dados (LGPD). Esta Política de Privacidade
                explica como coletamos, usamos e protegemos suas informações ao utilizar nosso website.
                <Divider />
                <b>Coleta de Dados Pessoais</b>
                <br />
                Coletamos dados pessoais somente quando você nos fornece explicitamente quando preenche formulários
                dentro sistema. Os tipos de dados que podemos coletar incluem seu nome, endereço de e-mail, número de
                telefone e outras informações necessárias para fornecer nossos serviços.
                <Divider />
                <b>Finalidades da Coleta de Dados</b>
                <br />
                Utilizamos seus dados pessoais apenas para os fins para os quais foram coletados, que são o de prestar
                os serviços contrados em nossa plataforma e para que processos internos desta funcionem. Não
                compartilhamos suas informações com terceiros sem o seu consentimento, a menos que seja exigido por lei.
                <Divider />
                <b>Segurança dos Dados?</b>
                <br />
                Mantemos medidas de segurança técnicas e organizacionais para proteger seus dados pessoais contra acesso
                não autorizado, divulgação, alteração ou destruição.
                <Divider />
                <b>Seus Direitos</b>
                <br />
                De acordo com a LGPD, você tem o direito de acessar, retificar, excluir ou portar seus dados pessoais.
                Se desejar exercer esses direitos ou tiver outras preocupações sobre a privacidade,{" "}
                <a href={LINK_SUPORTE_SHARKDEV} target="blank">
                    entre em contato conosco{" "}
                </a>
                . Responderemos às suas solicitações de maneira oportuna e de acordo com a lei.
                <Divider />
                <b>Alterações na Política de Privacidade</b>
                <br />
                Podemos atualizar esta Política de Privacidade periodicamente para refletir alterações em nossas
                práticas de privacidade. Quaisquer alterações entrarão em vigor imediatamente após a publicação da
                versão revisada em nosso website.
            </p>
            <p>
                <Divider align="center">
                    <h5 className="p-m-0">Política de Cookies</h5>
                </Divider>
                <b>Introdução</b>
                <br />
                Esta Política de Cookies descreve como utilizamos cookies em nosso website e visa fornecer informações
                claras e transparentes sobre o que são cookies, como os utilizamos e como os visitantes do nosso website
                podem gerenciar suas preferências de cookies.
                <Divider />
                <b>O que são cookies?</b>
                <br />
                Cookies são pequenos arquivos de texto que são armazenados no seu dispositivo (computador, smartphone,
                tablet) quando você visita um website. Eles são amplamente usados para fazer com que os websites
                funcionem de maneira mais eficiente e fornecer informações aos proprietários do website.
                <Divider />
                <b>Cookies Necessários para o Funcionamento do Sistema</b>
                <br />
                Utilizamos cookies necessários para o funcionamento do sistema, que são essenciais para o funcionamento
                adequado do nosso website. Esses cookies apenas coletam informações pessoais necessárias informadas em
                cadastros no sistema e não podem ser desativados, pois são estritamente necessários para fornecer os
                serviços que você solicita, como autenticação, segurança e navegação.
                <Divider />
                <b>Como você pode gerenciar seus cookies?</b>
                <br />A maioria dos navegadores da web permite que você controle o uso de cookies através das
                configurações do navegador. Você pode configurar seu navegador para bloquear cookies ou alertá-lo quando
                os cookies estão sendo enviados. No entanto, observe que, ao desativar os cookies necessários para o
                funcionamento do sistema, algumas partes do nosso website podem não funcionar corretamente.
                <Divider />
                <b>Alterações na Política de Cookies</b>
                <br />
                Podemos atualizar esta Política de Cookies periodicamente para refletir mudanças na legislação e nas
                práticas de cookies. Quaisquer alterações entrarão em vigor imediatamente após a publicação da versão
                revisada em nosso website.
            </p>
        </div>
    );
};
