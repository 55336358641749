export const ROOT = "root";

const permissoes = {
    //ROOT
    ROOT,

    // -> Permissão mínima
    DASHBOARD: "dashboard",
    DASHBOARD_INDICATIVO_VENDAS_VENDASRECENTES: "dashboard.indicativo.vendas.vendasrecentes",
    DASHBOARD_INDICATIVO_PESSOAS_LISTARATIVOS: "dashboard.indicativo.pessoas.listarativos",
    DASHBOARD_INDICATIVO_FINANCEIRO_CARDRECEITAS: "dashboard.indicativo.financeiro.cardreceitas",
    DASHBOARD_INDICATIVO_VENDAS_CARDVENDASTABELA: "dashboard.indicativo.vendas.cardvendastabela",
    DASHBOARD_INDICATIVO_PESSOAS_CARDCLIENTES: "dashboard.indicativo.pessoas.cardclientes",
    DASHBOARD_INDICATIVO_VENDAS_CARDVENDASTOTALIZADOR: "dashboard.indicativo.vendas.cardvendastotalizador",
    DASHBOARD_INDICATIVO_VENDAS_PEDIDOSTOTALIZADOR: "dashboard.indicativo.vendas.pedidostotalizador",
    DASHBOARD_INDICATIVO_GERAL_TAREFASTOTALIZADOR: "dashboard.indicativo.geral.tarefastotalizador",
    DASHBOARD_INDICATIVO_VENDAS_PRODUTOSMAISVENDIDOS: "dashboard.indicativo.vendas.produtosmaisvendidos",
    DASHBOARD_LOGS_ULTIMOS_REGISTROS: "dashboard.logs.ultimos.registros",

    // -> Gestão -> Pessoas -> Pessoas
    GESTAO_PESSOAS_PESSOAS_CONSULTAR: "gestao.pessoas.pessoas.consultar",
    GESTAO_PESSOAS_PESSOAS_INCLUIR: "gestao.pessoas.pessoas.incluir",
    GESTAO_PESSOAS_PESSOAS_EDITAR: "gestao.pessoas.pessoas.editar",
    GESTAO_PESSOAS_PESSOAS_EXCLUIR: "gestao.pessoas.pessoas.excluir",
    GESTAO_PESSOAS_PESSOAS_ATIVAR: "gestao.pessoas.pessoas.ativar",
    GESTAO_PESSOAS_PESSOAS_DESATIVAR: "gestao.pessoas.pessoas.desativar",

    // -> Gestão -> Importar Pessoas

    GESTAO_PESSOAS_IMPORTARPESSOAS_IMPORTAR: "gestao.pessoas.importarpessoas.importar",
    GESTAO_PESSOAS_IMPORTARPESSOAS_CONSULTAR: "gestao.pessoas.importarpessoas.consultar",

    // Gestão -> Pessoas -> Classificar Pessoas
    GESTAO_PESSOAS_CLASSIFICACAO_CONSULTAR: "gestao.pessoas.classificacao.consultar",
    GESTAO_PESSOAS_CLASSIFICACAO_INCLUIR: "gestao.pessoas.classificacao.incluir",
    GESTAO_PESSOAS_CLASSIFICACAO_EDITAR: "gestao.pessoas.classificacao.editar",
    GESTAO_PESSOAS_CLASSIFICACAO_EXCLUIR: "gestao.pessoas.classificacao.excluir",

    // Gestão -> Usuários -> Usuários
    GESTAO_USUARIOS_USUARIOS_CONSULTAR: "gestao.usuarios.usuarios.consultar",

    // Gestão -> Usuários -> Papeis Perfis
    GESTAO_USUARIOS_PAPEISPERFIS_CONSULTAR: "gestao.usuarios.papeisperfis.consultar",
    GESTAO_USUARIOS_PAPEISPERFIS_INCLUIR: "gestao.usuarios.papeisperfis.incluir",
    GESTAO_USUARIOS_PAPEISPERFIS_EDITAR: "gestao.usuarios.papeisperfis.editar",
    GESTAO_USUARIOS_PAPEISPERFIS_EXCLUIR: "gestao.usuarios.papeisperfis.excluir",

    // Gestão -> Usuários -> Permissões Perfis
    GESTAO_USUARIOS_PERMISSOESPERFIS_CONSULTAR: "gestao.usuarios.permissoesperfis.consultar",
    GESTAO_USUARIOS_PERMISSOESPERFIS_INCLUIR: "gestao.usuarios.permissoesperfis.incluir",
    GESTAO_USUARIOS_PERMISSOESPERFIS_EDITAR: "gestao.usuarios.permissoesperfis.editar",
    GESTAO_USUARIOS_PERMISSOESPERFIS_EXCLUIR: "gestao.usuarios.permissoesperfis.excluir",

    // Gestão -> Usuários -> Gerenciar Usuarios
    GESTAO_USUARIOS_GERENCIARUSUARIO_CONSULTAR: "gestao.usuarios.gerenciarusuario.consultar",
    GESTAO_USUARIOS_GERENCIARUSUARIO_INCLUIR: "gestao.usuarios.gerenciarusuario.incluir",
    GESTAO_USUARIOS_GERENCIARUSUARIO_EDITAR: "gestao.usuarios.gerenciarusuario.editar",
    GESTAO_USUARIOS_GERENCIARUSUARIO_EXCLUIR: "gestao.usuarios.gerenciarusuario.excluir",
    GESTAO_USUARIOS_GERENCIARUSUARIO_DESATIVAR: "gestao.usuarios.gerenciarusuario.desativar",
    GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPERMISSAOAVULSA_INCLUIR:
        "gestao.usuarios.gerenciausuario.vincularpermissaoavulsa.incluir",
    GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPERMISSAOAVULSA_EXCLUIR:
        "gestao.usuarios.gerenciausuario.vincularpermissaoavulsa.excluir",
    GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPAPEL_INCLUIR: "gestao.usuarios.gerenciausuario.vincularpapel.incluir",
    GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPAPEL_EXCLUIR: "gestao.usuarios.gerenciausuario.vincularpapel.excluir",

    // Gestão -> Usuários -> Gerenciar Usuarios
    GESTAO_USUARIOS_TOKENINTEGRACAO_CONSULTAR: "gestao.usuarios.tokenintegracao.consultar",
    GESTAO_USUARIOS_TOKENINTEGRACAO_CONSULTAR_TERCEIRO: "gestao.usuarios.tokenintegracao.consultar.terceiro",
    GESTAO_USUARIOS_TOKENINTEGRACAO_INCLUIR: "gestao.usuarios.tokenintegracao.incluir",
    GESTAO_USUARIOS_TOKENINTEGRACAO_INCLUIR_TERCEIRO: "gestao.usuarios.tokenintegracao.incluir.terceiro",
    GESTAO_USUARIOS_TOKENINTEGRACAO_EDITAR: "gestao.usuarios.tokenintegracao.editar",
    GESTAO_USUARIOS_TOKENINTEGRACAO_EXCLUIR: "gestao.usuarios.tokenintegracao.excluir",

    // Gestão -> Empresas -> Vinculação de Pessoas
    GESTAO_EMPRESAS_VINCULACAO_CONSULTAR: "gestao.empresas.vinculacao.consultar",
    GESTAO_EMPRESAS_VINCULACAO_INCLUIR: "gestao.empresas.vinculacao.incluir",
    GESTAO_EMPRESAS_VINCULACAO_EDITAR: "gestao.empresas.vinculacao.editar",
    GESTAO_EMPRESAS_VINCULACAO_EXCLUIR: "gestao.empresas.vinculacao.excluir",

    // Gestão -> Empresas -> Papéis Empresas
    GESTAO_EMPRESAS_PAPELEMPRESA_CONSULTAR: "gestao.empresas.papelempresa.consultar",
    GESTAO_EMPRESAS_PAPELEMPRESA_INCLUIR: "gestao.empresas.papelempresa.incluir",
    GESTAO_EMPRESAS_PAPELEMPRESA_EDITAR: "gestao.empresas.papelempresa.editar",
    GESTAO_EMPRESAS_PAPELEMPRESA_EXCLUIR: "gestao.empresas.papelempresa.excluir",

    // Gestão -> Cadastros -> Tags Perfis
    GESTAO_CADASTRO_TAGSPERFIL_CONSULTAR: "gestao.cadastro.tagsperfil.consultar",
    GESTAO_CADASTRO_TAGSPERFIL_INCLUIR: "gestao.cadastro.tagsperfil.incluir",
    GESTAO_CADASTRO_TAGSPERFIL_EDITAR: "gestao.cadastro.tagsperfil.editar",
    GESTAO_CADASTRO_TAGSPERFIL_EXCLUIR: "gestao.cadastro.tagsperfil.excluir",

    // Gestão -> Cadastros -> Tags Endereço
    GESTAO_CADASTRO_TAGSENDERECO_CONSULTAR: "gestao.cadastro.tagsendereco.consultar",
    GESTAO_CADASTRO_TAGSENDERECO_INCLUIR: "gestao.cadastro.tagsendereco.incluir",
    GESTAO_CADASTRO_TAGSENDERECO_EDITAR: "gestao.cadastro.tagsendereco.editar",
    GESTAO_CADASTRO_TAGSENDERECO_EXCLUIR: "gestao.cadastro.tagsendereco.excluir",

    // Gestão -> Cadastros -> Veiculo Entrega
    GESTAO_CADASTRO_VEICULOENTREGA_CONSULTAR: "gestao.cadastro.veiculoentrega.consultar",
    GESTAO_CADASTRO_VEICULOENTREGA_INCLUIR: "gestao.cadastro.veiculoentrega.incluir",
    GESTAO_CADASTRO_VEICULOENTREGA_EDITAR: "gestao.cadastro.veiculoentrega.editar",
    GESTAO_CADASTRO_VEICULOENTREGA_EXCLUIR: "gestao.cadastro.veiculoentrega.excluir",

    // Gestão -> Cadastros -> Vinculo Motorista Veiculo
    GESTAO_CADASTRO_VINCULOMOTORISTAVEICULO_CONSULTAR: "gestao.cadastro.vinculomotoristaveiculo.consultar",
    GESTAO_CADASTRO_VINCULOMOTORISTAVEICULO_INCLUIR: "gestao.cadastro.vinculomotoristaveiculo.incluir",
    GESTAO_CADASTRO_VINCULOMOTORISTAVEICULO_EDITAR: "gestao.cadastro.vinculomotoristaveiculo.editar",
    GESTAO_CADASTRO_VINCULOMOTORISTAVEICULO_EXCLUIR: "gestao.cadastro.vinculomotoristaveiculo.excluir",

    // Gestão -> Cadastros -> Redes Sociais
    GESTAO_CADASTRO_REDESOCIAL_CONSULTAR: "gestao.cadastro.redesocial.consultar",
    GESTAO_CADASTRO_REDESOCIAL_INCLUIR: "gestao.cadastro.redesocial.incluir",
    GESTAO_CADASTRO_REDESOCIAL_EDITAR: "gestao.cadastro.redesocial.editar",
    GESTAO_CADASTRO_REDESOCIAL_EXCLUIR: "gestao.cadastro.redesocial.excluir",

    //Gestão -> Cadastros -> Vinculo representante a fornecedor
    GESTAO_CADASTRO_REPRESENTANTEFORNECEDOR_VINCULO_CONSULTAR:
        "gestao.cadastro.representantefornecedor.vinculo.consultar",
    GESTAO_CADASTRO_REPRESENTANTEFORNECEDOR_VINCULO_INCLUIR: "gestao.cadastro.representantefornecedor.vinculo.incluir",
    GESTAO_CADASTRO_REPRESENTANTEFORNECEDOR_VINCULO_EDITAR: "gestao.cadastro.representantefornecedor.vinculo.editar",
    GESTAO_CADASTRO_REPRESENTANTEFORNECEDOR_VINCULO_EXCLUIR: "gestao.cadastro.representantefornecedor.vinculo.excluir",

    // Gestão -> Cadastros -> Hierarquia Funcional
    GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_CONSULTAR: "gestao.cadastro.hierarquiafuncional.consultar",
    GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_INCLUIR: "gestao.cadastro.hierarquiafuncional.incluir",
    GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_EDITAR: "gestao.cadastro.hierarquiafuncional.editar",
    GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_EXCLUIR: "gestao.cadastro.hierarquiafuncional.excluir",
    GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_MANUTENCAO: "gestao.cadastro.hierarquiafuncional.manutencao",

    // Gestão -> Cadastros -> Cargo
    GESTAO_CADASTRO_CARGOS_CONSULTAR: "gestao.cadastro.cargo.consultar",
    GESTAO_CADASTRO_CARGOS_INCLUIR: "gestao.cadastro.cargo.incluir",
    GESTAO_CADASTRO_CARGOS_EDITAR: "gestao.cadastro.cargo.editar",
    GESTAO_CADASTRO_CARGOS_EXCLUIR: "gestao.cadastro.cargo.excluir",
    GESTAO_CADASTRO_CARGOS_MANUTENCAO: "gestao.cadastro.cargo.manutencao",

    // Produto -> Produto -> Produtos
    PRODUTO_PRODUTO_PRODUTOS_CONSULTAR: "produto.produto.produtos.consultar",
    PRODUTO_PRODUTO_PRODUTOS_INCLUIR: "produto.produto.produtos.incluir",
    PRODUTO_PRODUTO_PRODUTOS_EDITAR: "produto.produto.produtos.editar",
    PRODUTO_PRODUTO_PRODUTOS_EXCLUIR: "produto.produto.produtos.excluir",
    PRODUTO_PRODUTO_PRODUTOS_FINALIZAR: "produto.produto.produtos.finalizar",

    // Produto -> Produto -> Unidade de Medida
    PRODUTO_PRODUTO_UNIDADEMEDIDA_CONSULTAR: "produto.produto.unidademedida.consultar",
    PRODUTO_PRODUTO_UNIDADEMEDIDA_INCLUIR: "produto.produto.unidademedida.incluir",
    PRODUTO_PRODUTO_UNIDADEMEDIDA_EDITAR: "produto.produto.unidademedida.editar",
    PRODUTO_PRODUTO_UNIDADEMEDIDA_EXCLUIR: "produto.produto.unidademedida.excluir",

    // Produto -> Produto -> Unidade de Medida FC
    PRODUTO_PRODUTO_UNIDADEMEDIDAFC_CONSULTAR: "produto.produto.unidademedidafc.consultar",
    PRODUTO_PRODUTO_UNIDADEMEDIDAFC_INCLUIR: "produto.produto.unidademedidafc.incluir",
    PRODUTO_PRODUTO_UNIDADEMEDIDAFC_EDITAR: "produto.produto.unidademedidafc.editar",
    PRODUTO_PRODUTO_UNIDADEMEDIDAFC_EXCLUIR: "produto.produto.unidademedidafc.excluir",

    // Produto -> Produto -> Grade Atributo SKU
    PRODUTO_PRODUTO_GRADEATRIBUTOSKU_CONSULTAR: "produto.produto.gradeatributosku.consultar",
    PRODUTO_PRODUTO_GRADEATRIBUTOSKU_INCLUIR: "produto.produto.gradeatributosku.incluir",
    PRODUTO_PRODUTO_GRADEATRIBUTOSKU_EDITAR: "produto.produto.gradeatributosku.editar",
    PRODUTO_PRODUTO_GRADEATRIBUTOSKU_EXCLUIR: "produto.produto.gradeatributosku.excluir",

    // Produto -> Produto -> Imagens SKU
    PRODUTO_PRODUTO_IMAGENSSKU_CONSULTAR: "produto.produto.imagenssku.consultar",
    PRODUTO_PRODUTO_IMAGENSSKU_INCLUIR: "produto.produto.imagenssku.incluir",
    PRODUTO_PRODUTO_IMAGENSSKU_EDITAR: "produto.produto.imagenssku.editar",
    PRODUTO_PRODUTO_IMAGENSSKU_EXCLUIR: "produto.produto.imagenssku.excluir",

    // Produto -> Produto -> Categorias
    PRODUTO_PRODUTO_CATEGORIAS_CONSULTAR: "produto.produto.categorias.consultar",
    PRODUTO_PRODUTO_CATEGORIAS_INCLUIR: "produto.produto.categorias.incluir",
    PRODUTO_PRODUTO_CATEGORIAS_EDITAR: "produto.produto.categorias.editar",
    PRODUTO_PRODUTO_CATEGORIAS_EXCLUIR: "produto.produto.categorias.excluir",

    // Produto -> Produto -> Grupos de Categorias
    PRODUTO_PRODUTO_GRUPOSCATEGORIAS_CONSULTAR: "produto.produto.gruposcategorias.consultar",
    PRODUTO_PRODUTO_GRUPOSCATEGORIAS_INCLUIR: "produto.produto.gruposcategorias.incluir",
    PRODUTO_PRODUTO_GRUPOSCATEGORIAS_EDITAR: "produto.produto.gruposcategorias.editar",
    PRODUTO_PRODUTO_GRUPOSCATEGORIAS_EXCLUIR: "produto.produto.gruposcategorias.excluir",

    // Produto -> Produto -> Consultar saldos
    PRODUTO_PRODUTO_CONSULTARSALDOS: "produto.produto.consultarsaldos",

    // Produto -> Entrada -> Entradas
    PRODUTO_ENTRADA_ENTRADA_CONSULTAR: "produto.entrada.entrada.consultar",
    PRODUTO_ENTRADA_ENTRADA_INCLUIR: "produto.entrada.entrada.incluir",
    PRODUTO_ENTRADA_ENTRADA_EDITAR: "produto.entrada.entrada.editar",
    PRODUTO_ENTRADA_ENTRADA_EXCLUIR: "produto.entrada.entrada.excluir",

    // Produto -> Cadastro -> Atributo
    PRODUTO_CADASTRO_ATRIBUTO_CONSULTAR: "produto.cadastro.atributo.consultar",
    PRODUTO_CADASTRO_ATRIBUTO_INCLUIR: "produto.cadastro.atributo.incluir",
    PRODUTO_CADASTRO_ATRIBUTO_EDITAR: "produto.cadastro.atributo.editar",
    PRODUTO_CADASTRO_ATRIBUTO_EXCLUIR: "produto.cadastro.atributo.excluir",
    PRODUTO_CADASTRO_ATRIBUTO_CAMPO_PERSONALIZADO: "produto.cadastro.atributo.campo_personalizado",

    // Produto -> Cadastro -> Grade Atributo
    PRODUTO_CADASTRO_GRADEATRIBUTO_CONSULTAR: "produto.cadastro.gradeatributo.consultar",
    PRODUTO_CADASTRO_GRADEATRIBUTO_INCLUIR: "produto.cadastro.gradeatributo.incluir",
    PRODUTO_CADASTRO_GRADEATRIBUTO_EDITAR: "produto.cadastro.gradeatributo.editar",
    PRODUTO_CADASTRO_GRADEATRIBUTO_EXCLUIR: "produto.cadastro.gradeatributo.excluir",

    // Produto -> Cadastro -> Modelos
    PRODUTO_CADASTRO_MODELO_CONSULTAR: "produto.cadastro.modelo.consultar",
    PRODUTO_CADASTRO_MODELO_INCLUIR: "produto.cadastro.modelo.incluir",
    PRODUTO_CADASTRO_MODELO_EDITAR: "produto.cadastro.modelo.editar",
    PRODUTO_CADASTRO_MODELO_EXCLUIR: "produto.cadastro.modelo.excluir",

    // Produto -> Cadastro -> Galeria Img. SKU
    PRODUTO_CADASTRO_GALERIAIMAGEMSKU_CONSULTAR: "produto.cadastro.galeriaimagemsku.consultar",
    PRODUTO_CADASTRO_GALERIAIMAGEMSKU_INCLUIR: "produto.cadastro.galeriaimagemsku.incluir",
    PRODUTO_CADASTRO_GALERIAIMAGEMSKU_EDITAR: "produto.cadastro.galeriaimagemsku.editar",
    PRODUTO_CADASTRO_GALERIAIMAGEMSKU_EXCLUIR: "produto.cadastro.galeriaimagemsku.excluir",

    // Produto -> Cadastro -> Tags Img. SKU
    PRODUTO_CADASTRO_TAGSIMAGEMSKU_CONSULTAR: "produto.cadastro.tagsimagemsku.consultar",
    PRODUTO_CADASTRO_TAGSIMAGEMSKU_INCLUIR: "produto.cadastro.tagsimagemsku.incluir",
    PRODUTO_CADASTRO_TAGSIMAGEMSKU_EDITAR: "produto.cadastro.tagsimagemsku.editar",
    PRODUTO_CADASTRO_TAGSIMAGEMSKU_EXCLUIR: "produto.cadastro.tagsimagemsku.excluir",

    // Produto -> Cadastro -> Marcas
    PRODUTO_CADASTRO_MARCA_CONSULTAR: "produto.cadastro.marca.consultar",
    PRODUTO_CADASTRO_MARCA_INCLUIR: "produto.cadastro.marca.incluir",
    PRODUTO_CADASTRO_MARCA_EDITAR: "produto.cadastro.marca.editar",
    PRODUTO_CADASTRO_MARCA_EXCLUIR: "produto.cadastro.marca.excluir",

    // Produto -> Cadastro -> Marcas Fabricantes
    PRODUTO_CADASTRO_MARCAFABRICANTE_CONSULTAR: "produto.cadastro.marcafabricante.consultar",
    PRODUTO_CADASTRO_MARCAFABRICANTE_INCLUIR: "produto.cadastro.marcafabricante.incluir",
    PRODUTO_CADASTRO_MARCAFABRICANTE_EDITAR: "produto.cadastro.marcafabricante.editar",
    PRODUTO_CADASTRO_MARCAFABRICANTE_EXCLUIR: "produto.cadastro.marcafabricante.excluir",

    // Produto -> Cadastro -> Templade de Descrição SKU
    PRODUTO_CADASTRO_TEMPLATEDESCRICAOSKU_CONSULTAR: "produto.cadastro.templatedescricaosku.consultar",
    PRODUTO_CADASTRO_TEMPLATEDESCRICAOSKU_INCLUIR: "produto.cadastro.templatedescricaosku.incluir",
    PRODUTO_CADASTRO_TEMPLATEDESCRICAOSKU_EDITAR: "produto.cadastro.templatedescricaosku.editar",
    PRODUTO_CADASTRO_TEMPLATEDESCRICAOSKU_EXCLUIR: "produto.cadastro.templatedescricaosku.excluir",

    // Produto -> Cadastro -> Composição Templade de Descrição SKU
    PRODUTO_CADASTRO_COMPOSICAOTEMPLATEDESCRICAOSKU_CONSULTAR: "produto.cadastro.templatedescricaosku.consultar",
    PRODUTO_CADASTRO_COMPOSICAOTEMPLATEDESCRICAOSKU_INCLUIR: "produto.cadastro.templatedescricaosku.incluir",
    PRODUTO_CADASTRO_COMPOSICAOTEMPLATEDESCRICAOSKU_EDITAR: "produto.cadastro.templatedescricaosku.editar",
    PRODUTO_CADASTRO_COMPOSICAOTEMPLATEDESCRICAOSKU_EXCLUIR: "produto.cadastro.templatedescricaosku.excluir",

    // Produto -> Cadastro -> Tipos de Códigos SKU
    PRODUTO_CADASTRO_TIPOCODIGOSKU_CONSULTAR: "produto.cadastro.tipocodigosku.consultar",
    PRODUTO_CADASTRO_TIPOCODIGOSKU_INCLUIR: "produto.cadastro.tipocodigosku.incluir",
    PRODUTO_CADASTRO_TIPOCODIGOSKU_EDITAR: "produto.cadastro.tipocodigosku.editar",
    PRODUTO_CADASTRO_TIPOCODIGOSKU_EXCLUIR: "produto.cadastro.tipocodigosku.excluir",

    // Produto -> Cadastro -> Tipos de Códigos SKU
    PRODUTO_CADASTRO_VIGENCIAPRECO_CONSULTAR: "produto.cadastro.vigenciapreco.consultar",
    PRODUTO_CADASTRO_VIGENCIAPRECO_INCLUIR: "produto.cadastro.vigenciapreco.incluir",
    PRODUTO_CADASTRO_VIGENCIAPRECO_EDITAR: "produto.cadastro.vigenciapreco.editar",
    PRODUTO_CADASTRO_VIGENCIAPRECO_EXCLUIR: "produto.cadastro.vigenciapreco.excluir",

    // Produto -> Cadastro -> Descrição Padrão
    PRODUTO_CADASTRO_DESCRICAOPADRAO_CONSULTAR: "produto.cadastro.descricaopadrao.consultar",
    PRODUTO_CADASTRO_DESCRICAOPADRAO_INCLUIR: "produto.cadastro.descricaopadrao.incluir",
    PRODUTO_CADASTRO_DESCRICAOPADRAO_EDITAR: "produto.cadastro.descricaopadrao.editar",
    PRODUTO_CADASTRO_DESCRICAOPADRAO_EXCLUIR: "produto.cadastro.descricaopadrao.excluir",

    // Produto -> Cadastro -> Nível Descrição Padrão
    PRODUTO_CADASTRO_NIVELDESCRICAOPADRAO_CONSULTAR: "produto.cadastro.nivelpadraodescricao.consultar",
    PRODUTO_CADASTRO_NIVELDESCRICAOPADRAO_INCLUIR: "produto.cadastro.nivelpadraodescricao.incluir",
    PRODUTO_CADASTRO_NIVELDESCRICAOPADRAO_EDITAR: "produto.cadastro.nivelpadraodescricao.editar",
    PRODUTO_CADASTRO_NIVELDESCRICAOPADRAO_EXCLUIR: "produto.cadastro.nivelpadraodescricao.excluir",

    // Produto -> Cadastro -> Vinculo Fornecedor
    PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_INCLUIR:
        "produto.cadastro.produtofornecedor.vincularfornecedor.incluir",
    PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_EDITAR:
        "produto.cadastro.produtofornecedor.vincularfornecedor.editar",
    PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_EXCLUIR:
        "produto.cadastro.produtofornecedor.vincularfornecedor.excluir",
    PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_CONSULTAR:
        "produto.cadastro.produtofornecedor.vincularfornecedor.consultar",

    // Produto -> Cadastro -> Vinculo Centro estocagem a Centro resultado
    PRODUTO_CADASTRO_CENTROESTOCAGEM_CENTRORESULTADO_INCLUIR:
        "produto.cadastro.centroestocagem.centroresultado.incluir",
    PRODUTO_CADASTRO_CENTROESTOCAGEM_CENTRORESULTADO_EDITAR: "produto.cadastro.centroestocagem.centroresultado.editar",
    PRODUTO_CADASTRO_CENTROESTOCAGEM_CENTRORESULTADO_EXCLUIR:
        "produto.cadastro.centroestocagem.centroresultado.excluir",
    PRODUTO_CADASTRO_CENTROESTOCAGEM_CENTRORESULTADO_CONSULTAR:
        "produto.cadastro.centroestocagem.centroresultado.consultar",

    // Produto -> Importar Produtos
    PRODUTO_IMPORTARPRODUTOS_CONSULTAR: "produto.importarprodutos.consultar",
    PRODUTO_IMPORTARPRODUTOS_IMPORTAR: "produto.importarprodutos.importar",

    // Produto -> Produtos -> Lote
    PRODUTO_PRODUTO_LOTES_CONSULTAR: "produto.produto.lotes.consultar",
    PRODUTO_PRODUTO_LOTES_INCLUIR: "produto.produto.lotes.incluir",
    PRODUTO_PRODUTO_LOTES_EDITAR: "produto.produto.lotes.editar",
    PRODUTO_PRODUTO_LOTES_EXCLUIR: "produto.produto.lotes.excluir",
    PRODUTO_PRODUTO_LOTES_BLOQUEARVENDA: "produto.produto.lotes.bloquearvenda",
    PRODUTO_PRODUTO_LOTES_DESBLOQUEARVENDA: "produto.produto.lotes.desbloquearvenda",
    PRODUTO_PRODUTO_LOTES_AJUSTARSALDO: "produto.produto.lotes.ajustarsaldo",

    //Materiais
    //Materiais -> Requisições
    MATERIAIS_REQUISICAO_SOLICITAR: "materiais.requisicao.solicitar",
    MATERIAIS_REQUISICAO_VISUALIZAR: "materiais.requisicao.visualizar",
    MATERIAIS_REQUISICAO_EDITAR: "materiais.requisicao.editar",

    // Compras -> Ordem de Compra -> Ordem de Compra
    COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_CONSULTAR: "compras.ordemcompra.ordemcompra.consultar",
    COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_INCLUIR: "compras.ordemcompra.ordemcompra.incluir",
    COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_EDITAR: "compras.ordemcompra.ordemcompra.editar",
    COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_EXCLUIR: "compras.ordemcompra.ordemcompra.excluir",
    COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_EMAIL: "compras.ordemcompra.ordemcompra.email",
    COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_IMPRIMIR: "compras.ordemcompra.ordemcompra.imprimir",
    COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_ITEM: "compras.ordemcompra.ordemcompra.item",
    COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_PARCELA: "compras.ordemcompra.ordemcompra.parcela",
    COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_VALIDAR: "compras.ordemcompra.ordemcompra.validar",
    COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_REABRIR: "compras.ordemcompra.ordemcompra.reabrir",

    // Compras -> Ordem de Compra -> Cadastro de Serviços
    COMPRAS_ORDEMCOMPRA_CADSERVICOS_CONSULTAR: "compras.ordemcompra.cadservicos.consultar",
    COMPRAS_ORDEMCOMPRA_CADSERVICOS_INCLUIR: "compras.ordemcompra.cadservicos.incluir",
    COMPRAS_ORDEMCOMPRA_CADSERVICOS_EDITAR: "compras.ordemcompra.cadservicos.editar",
    COMPRAS_ORDEMCOMPRA_CADSERVICOS_EXCLUIR: "compras.ordemcompra.cadservicos.excluir",

    // Compras -> Ordem de Compra -> Serviço
    COMPRAS_ORDEMCOMPRA_SERVICO_CONSULTAR: "compras.ordemcompra.servico.consultar",
    COMPRAS_ORDEMCOMPRA_SERVICO_INCLUIR: "compras.ordemcompra.servico.incluir",
    COMPRAS_ORDEMCOMPRA_SERVICO_EDITAR: "compras.ordemcompra.servico.editar",
    COMPRAS_ORDEMCOMPRA_SERVICO_EXCLUIR: "compras.ordemcompra.servico.excluir",

    // Compras -> Ordem de Compra -> Categorias
    COMPRAS_ORDEMCOMPRA_CATEGORIAS_CONSULTAR: "compras.ordemcompra.categorias.consultar",
    COMPRAS_ORDEMCOMPRA_CATEGORIAS_INCLUIR: "compras.ordemcompra.categorias.incluir",
    COMPRAS_ORDEMCOMPRA_CATEGORIAS_EDITAR: "compras.ordemcompra.categorias.editar",
    COMPRAS_ORDEMCOMPRA_CATEGORIAS_EXCLUIR: "compras.ordemcompra.categorias.excluir",

    // Compras -> Cadastro -> Tipo de Entrada
    COMPRAS_CADASTRO_TIPOENTRADA_CONSULTAR: "compras.cadastro.categorias.consultar",
    COMPRAS_CADASTRO_TIPOENTRADA_INCLUIR: "compras.cadastro.categorias.incluir",
    COMPRAS_CADASTRO_TIPOENTRADA_EDITAR: "compras.cadastro.categorias.editar",
    COMPRAS_CADASTRO_TIPOENTRADA_EXCLUIR: "compras.cadastro.categorias.excluir",

    // Compras -> Entrada -> Devolução ao Fornecedor
    COMPRAS_ENTRADA_DEVOLUCAOFORNECEDOR_CONSULTAR: "compras.entrada.devolucaofornecedor.consultar",
    COMPRAS_ENTRADA_DEVOLUCAOFORNECEDOR_INCLUIR: "compras.entrada.devolucaofornecedor.incluir",
    COMPRAS_ENTRADA_DEVOLUCAOFORNECEDOR_VALIDAR: "compras.entrada.devolucaofornecedor.validar",
    COMPRAS_ENTRADA_DEVOLUCAOFORNECEDOR_CANCELAR: "compras.entrada.devolucaofornecedor.cancelar",
    COMPRAS_ENTRADA_DEVOLUCAOFORNECEDOR_FINALIZAR: "compras.entrada.devolucaofornecedor.finalizar",

    // Compras -> Cadastro -> Destinação
    COMPRAS_CADASTRO_DESTINACAO_CONSULTAR: "compras.cadastro.destinacao.consultar",
    COMPRAS_CADASTRO_DESTINACAO_INCLUIR: "compras.cadastro.destinacao.incluir",
    COMPRAS_CADASTRO_DESTINACAO_EDITAR: "compras.cadastro.destinacao.editar",
    COMPRAS_CADASTRO_DESTINACAO_EXCLUIR: "compras.cadastro.destinacao.excluir",

    // Compras -> Cadastro -> Tipo devolução
    COMPRAS_CADASTRO_TIPODEVOLUCAO_CONSULTAR: "compras.cadastro.tipodevolucao.consultar",
    COMPRAS_CADASTRO_TIPODEVOLUCAO_INCLUIR: "compras.cadastro.tipodevolucao.incluir",
    COMPRAS_CADASTRO_TIPODEVOLUCAO_EDITAR: "compras.cadastro.tipodevolucao.editar",
    COMPRAS_CADASTRO_TIPODEVOLUCAO_ALTERARDESATIVAR: "compras.cadastro.tipodevolucao.ativardesativar",

    // Compras -> Cadastro -> Registro Necessidade Compra
    COMPRAS_CADASTRO_REGISTRONECESSIDADECOMPRA_CONSULTAR: "compras.cadastro.registronecessidadecompra.consulta",
    COMPRAS_CADASTRO_REGISTRONECESSIDADECOMPRA_INCLUIR: "compras.cadastro.registronecessidadecompra.incluir",
    COMPRAS_CADASTRO_REGISTRONECESSIDADECOMPRA_EDITAR: "compras.cadastro.registronecessidadecompra.editar",
    COMPRAS_CADASTRO_REGISTRONECESSIDADECOMPRA_EXCLUIR: "compras.cadastro.registronecessidadecompra.excluir",
    COMPRAS_CADASTRO_REGISTRONECESSIDADECOMPRA_MANUTENCAO: "compras.cadastro.registronecessidadecompra.manutencao",

    // Vendas -> Venda -> Realizar Venda
    VENDAS_VENDA_REALIZARVENDA_CONSULTAR: "vendas.venda.realizarvenda.consultar",
    VENDAS_VENDA_REALIZARVENDA_CONSULTARTODOS: "vendas.venda.realizarvenda.consultartodos",
    VENDAS_VENDA_REALIZARVENDA_INCLUIR: "vendas.venda.realizarvenda.incluir",
    VENDAS_VENDA_REALIZARVENDA_EDITAR: "vendas.venda.realizarvenda.editar",
    VENDAS_VENDA_REALIZARVENDA_EXCLUIR: "vendas.venda.realizarvenda.excluir",
    VENDAS_VENDA_REALIZARVENDA_FINALIZAR: "vendas.venda.realizarvenda.finalizar",
    VENDAS_VENDA_REALIZARVENDA_CANCELAR: "vendas.venda.realizarvenda.cancelar",
    VENDAS_VENDA_REALIZARVENDA_ALTERARCLIENTE: "vendas.venda.realizarvenda.alterarcliente",
    VENDAS_VENDA_REALIZARVENDA_ALTERARVENDEDOR: "vendas.venda.realizarvenda.alterarvendedor",
    VENDAS_VENDA_REALIZARVENDA_ALTERARSUPERVISOR: "vendas.venda.realizarvenda.alterarsupervisor",
    VENDAS_VENDA_REALIZARVENDA_BAIXARESTOQUE: "vendas.venda.realizarvenda.baixarestoque",
    VENDAS_VENDA_REALIZARVENDA_GERARFINANCEIRO: "vendas.venda.realizarvenda.gerarfinanceiro",
    VENDAS_VENDA_REALIZARVENDA_GERARDOCFISCAL: "vendas.venda.realizarvenda.gerardocfiscal",
    VENDAS_VENDA_REALIZARVENDA_RESERVARESTOQUE: "vendas.venda.realizarvenda.reservarestoque",
    VENDAS_VENDA_REALIZARVENDA_GERARCOMISSAO: "vendas.venda.realizarvenda.gerarcomissao",

    //Vendas -> Venda -> Entregas
    VENDAS_VENDA_ENTREGAS_CONSULTAR: "vendas.venda.entregas.consultar",

    // Vendas -> Venda -> Entregas Realizadas
    VENDAS_VENDA_ENTREGASREALIZADAS_CONSULTAR: "vendas.venda.entregasrealizadas.consultar",
    VENDAS_VENDA_ENTREGASREALIZADAS_CANCELAR: "vendas.venda.entregasrealizadas.cancelar",

    //Vendas -> Venda -> Devolução
    VENDAS_VENDA_DEVOLUCAO_CONSULTAR: "vendas.venda.devolucao.consultar",
    VENDAS_VENDA_DEVOLUCAO_INCLUIR: "vendas.venda.devolucao.incluir",
    VENDAS_VENDA_DEVOLUCAO_CANCELAR: "vendas.venda.devolucao.cancelar",
    VENDAS_VENDA_DEVOLUCAO_EDITAR: "vendas.venda.devolucao.editar",
    VENDAS_VENDA_DEVOLUCAO_FINALIZAR: "vendas.venda.devolucao.finalizar",

    //Vendas -> Venda -> Tabela Preco
    VENDAS_VENDA_CONSULTAPRECO_CONSULTAR: "vendas.vendas.consultapreco.consultar",

    VENDAS_CADASTRO_TIPOESTAGIO_CONSULTAR: "vendas.cadastro.tipoestagio.consultar",
    VENDAS_CADASTRO_TIPOESTAGIO_INCLUIR: "vendas.cadastro.tipoestagio.incluir",
    VENDAS_CADASTRO_TIPOESTAGIO_EDITAR: "vendas.cadastro.tipoestagio.editar",
    VENDAS_CADASTRO_TIPOESTAGIO_EXCLUIR: "vendas.cadastro.tipoestagio.excluir",

    // Vendas -> Cadastro -> Rota Entrega
    VENDAS_CADASTRO_ROTAENTREGA_CONSULTAR: "vendas.cadastro.rotaentrega.consultar",
    VENDAS_CADASTRO_ROTAENTREGA_INCLUIR: "vendas.cadastro.rotaentrega.incluir",
    VENDAS_CADASTRO_ROTAENTREGA_EDITAR: "vendas.cadastro.rotaentrega.editar",
    VENDAS_CADASTRO_ROTAENTREGA_EXCLUIR: "vendas.cadastro.rotaentrega.excluir",

    // Vendas -> Cadastro -> Tabela de Preços
    VENDAS_CADASTRO_TABELAPRECO_CONSULTAR: "vendas.cadastro.tabelapreco.consultar",
    VENDAS_CADASTRO_TABELAPRECO_INCLUIR: "vendas.cadastro.tabelapreco.incluir",
    VENDAS_CADASTRO_TABELAPRECO_EDITAR: "vendas.cadastro.tabelapreco.editar",
    VENDAS_CADASTRO_TABELAPRECO_EXCLUIR: "vendas.cadastro.tabelapreco.excluir",
    VENDAS_CADASTRO_TABELAPRECO_VINCULAREMPRESA: "vendas.cadastro.tabelapreco.vincularempresa",

    // Vendas -> Venda -> Crediário
    VENDAS_VENDA_CREDIARIO_CONSULTAR: "vendas.venda.crediario.consultar",
    VENDAS_VENDA_CREDIARIO_INCLUIR: "vendas.venda.crediario.incluir",
    VENDAS_VENDA_CREDIARIO_EDITAR: "vendas.venda.crediario.editar",
    VENDAS_VENDA_CREDIARIO_EXCLUIR: "vendas.venda.crediario.excluir",
    VENDAS_VENDA_CREDIARIO_VALIDARCREDIARIO: "vendas.venda.crediario.validarcrediario",

    // Vendas -> Venda -> Crediário Ocupação
    VENDAS_VENDA_CREDIARIOOCUPACAO_CONSULTAR: "vendas.venda.crediarioocupacao.consultar",
    VENDAS_VENDA_CREDIARIOOCUPACAO_INCLUIR: "vendas.venda.crediarioocupacao.incluir",
    VENDAS_VENDA_CREDIARIOOCUPACAO_EDITAR: "vendas.venda.crediarioocupacao.editar",
    VENDAS_VENDA_CREDIARIOOCUPACAO_EXCLUIR: "vendas.venda.crediarioocupacao.excluir",

    // Vendas -> Venda -> Troca
    VENDAS_VENDA_TROCA_CONSULTAR: "vendas.venda.troca.consultar",
    VENDAS_VENDA_TROCA_INCLUIR: "vendas.venda.troca.incluir",
    VENDAS_VENDA_TROCA_EDITAR: "vendas.venda.troca.editar",
    VENDAS_VENDA_TROCA_EXCLUIR: "vendas.venda.troca.excluir",

    // Vendas -> Venda -> Orçamento rápido
    VENDAS_VENDA_ORCAMENTORAPIDO_CONSULTAR: "vendas.venda.orcamentorapido.consultar",
    VENDAS_VENDA_ORCAMENTORAPIDO_INCLUIR: "vendas.venda.orcamentorapido.incluir",
    VENDAS_VENDA_ORCAMENTORAPIDO_EDITAR: "vendas.venda.orcamentorapido.editar",
    VENDAS_VENDA_ORCAMENTORAPIDO_EXCLUIR: "vendas.venda.orcamentorapido.excluir",

    // Vendas -> Venda -> Doc. Fiscal Venda
    VENDAS_VENDA_DOCFISCALVENDA_CONSULTAR: "vendas.venda.docfiscalvenda.consultar",
    VENDAS_VENDA_DOCFISCALVENDA_INCLUIR: "vendas.venda.docfiscalvenda.incluir",
    VENDAS_VENDA_DOCFISCALVENDA_EDITAR: "vendas.venda.docfiscalvenda.editar",
    VENDAS_VENDA_DOCFISCALVENDA_EXCLUIR: "vendas.venda.docfiscalvenda.excluir",

    // Vendas -> Venda -> Cargas
    VENDAS_VENDA_CARGAS_INCLUIR: "vendas.venda.cargas.incluir",
    VENDAS_VENDA_CARGAS_EDITAR: "vendas.venda.cargas.editar",
    VENDAS_VENDA_CARGAS_FECHAR: "vendas.venda.cargas.fechar",
    VENDAS_VENDA_CARGAS_VINCULARPREVISAO: "vendas.venda.cargas.vincularprevisao",

    // Vendas -> Crediário -> Nome Relação
    VENDAS_CREDIARIO_NOMERELACAO_CONSULTAR: "vendas.crediario.nomerelacao.consultar",
    VENDAS_CREDIARIO_NOMERELACAO_INCLUIR: "vendas.crediario.nomerelacao.incluir",
    VENDAS_CREDIARIO_NOMERELACAO_EDITAR: "vendas.crediario.nomerelacao.editar",
    VENDAS_CREDIARIO_NOMERELACAO_EXCLUIR: "vendas.crediario.nomerelacao.excluir",

    // Vendas -> Crediário -> Tipo Renda
    VENDAS_CREDIARIO_TIPORENDA_CONSULTAR: "vendas.crediario.tiporenda.consultar",
    VENDAS_CREDIARIO_TIPORENDA_INCLUIR: "vendas.crediario.tiporenda.incluir",
    VENDAS_CREDIARIO_TIPORENDA_EDITAR: "vendas.crediario.tiporenda.editar",
    VENDAS_CREDIARIO_TIPORENDA_EXCLUIR: "vendas.crediario.tiporenda.excluir",

    // Vendas -> Venda -> Consulta SPC
    VENDAS_VENDA_CONSULTASPC_CONSULTAR: "vendas.venda.consultaspc.consultar",
    VENDAS_VENDA_CONSULTASPC_INCLUIR: "vendas.venda.consultaspc.incluir",
    VENDAS_VENDA_CONSULTASPC_EDITAR: "vendas.venda.consultaspc.editar",
    VENDAS_VENDA_CONSULTASPC_EXCLUIR: "vendas.venda.consultaspc.excluir",

    // Vendas -> Venda -> Parametrizar Crediário
    VENDAS_VENDA_PARAMETRIZACAOCREDIARIO_CONSULTAR: "vendas.venda.parametrizacaocrediario.consultar",
    VENDAS_VENDA_PARAMETRIZACAOCREDIARIO_INCLUIR: "vendas.venda.parametrizacaocrediario.incluir",
    VENDAS_VENDA_PARAMETRIZACAOCREDIARIO_EDITAR: "vendas.venda.parametrizacaocrediario.editar",
    VENDAS_VENDA_PARAMETRIZACAOCREDIARIO_EXCLUIR: "vendas.venda.parametrizacaocrediario.excluir",

    // Vendas -> Venda -> Tipo Documento Renda
    VENDAS_VENDA_TIPODOCUMENTORENDA_CONSULTAR: "vendas.venda.tipodocumentorenda.consultar",
    VENDAS_VENDA_TIPODOCUMENTORENDA_INCLUIR: "vendas.venda.tipodocumentorenda.incluir",
    VENDAS_VENDA_TIPODOCUMENTORENDA_EDITAR: "vendas.venda.tipodocumentorenda.editar",
    VENDAS_VENDA_TIPODOCUMENTORENDA_EXCLUIR: "vendas.venda.tipodocumentorenda.excluir",

    // Vendas -> Venda -> Natureza Ocupação
    VENDAS_VENDA_NATUREZAOCUPACAO_CONSULTAR: "vendas.venda.naturezaocupacao.consultar",
    VENDAS_VENDA_NATUREZAOCUPACAO_INCLUIR: "vendas.venda.naturezaocupacao.incluir",
    VENDAS_VENDA_NATUREZAOCUPACAO_EDITAR: "vendas.venda.naturezaocupacao.editar",
    VENDAS_VENDA_NATUREZAOCUPACAO_EXCLUIR: "vendas.venda.naturezaocupacao.excluir",

    // Vendas -> Cadastro -> Estágios de Venda
    VENDAS_CADASTRO_ESTAGIOVENDA_CONSULTAR: "vendas.cadastro.estagiovenda.consultar",
    VENDAS_CADASTRO_ESTAGIOVENDA_INCLUIR: "vendas.cadastro.estagiovenda.incluir",
    VENDAS_CADASTRO_ESTAGIOVENDA_EDITAR: "vendas.cadastro.estagiovenda.editar",
    VENDAS_CADASTRO_ESTAGIOVENDA_EXCLUIR: "vendas.cadastro.estagiovenda.excluir",
    VENDAS_CADASTRO_ESTAGIOVENDA_SEQUENCIA_MANUTENCAO: "vendas.cadastro.estagiovenda.sequencia.manutencao",

    // Vendas -> Cadastro -> Comissao Vendas
    VENDAS_CADASTRO_COMISSAOVENDAS_CONSULTAR: "vendas.cadastro.comissaovendas.consultar",
    VENDAS_CADASTRO_COMISSAOVENDAS_INCLUIR: "vendas.cadastro.comissaovendas.incluir",
    VENDAS_CADASTRO_COMISSAOVENDAS_EDITAR: "vendas.cadastro.comissaovendas.editar",
    VENDAS_CADASTRO_COMISSAOVENDAS_EXCLUIR: "vendas.cadastro.comissaovendas.excluir",

    // Vendas -> Cadastro -> Formacao Precos
    VENDAS_CADASTRO_FORMACAOPRECO_CONSULTAR: "vendas.cadastro.formacaopreco.consultar",
    VENDAS_CADASTRO_FORMACAOPRECO_INCLUIR: "vendas.cadastro.formacaopreco.incluir",
    VENDAS_CADASTRO_FORMACAOPRECO_EDITAR: "vendas.cadastro.formacaopreco.editar",
    VENDAS_CADASTRO_FORMACAOPRECO_EXCLUIR: "vendas.cadastro.formacaopreco.excluir",

    // Vendas -> Cadastro -> Formacao Precos
    VENDAS_CADASTRO_FAIXACOMISSAO_CONSULTAR: "vendas.cadastro.faixacomissao.consultar",
    VENDAS_CADASTRO_FAIXACOMISSAO_INCLUIR: "vendas.cadastro.faixacomissao.incluir",
    VENDAS_CADASTRO_FAIXACOMISSAO_EDITAR: "vendas.cadastro.faixacomissao.editar",
    VENDAS_CADASTRO_FAIXACOMISSAO_EXCLUIR: "vendas.cadastro.faixacomissao.excluir",

    // Vendas -> Cadastro -> Formacao Precos
    VENDAS_CADASTRO_REGRAAPURACAO_CONSULTAR: "vendas.cadastro.regraapuracao.consultar",
    VENDAS_CADASTRO_REGRAAPURACAO_INCLUIR: "vendas.cadastro.regraapuracao.incluir",
    VENDAS_CADASTRO_REGRAAPURACAO_EDITAR: "vendas.cadastro.regraapuracao.editar",
    VENDAS_CADASTRO_REGRAAPURACAO_EXCLUIR: "vendas.cadastro.regraapuracao.excluir",

    // Vendas -> Cadastro -> Arredondamento de valor
    VENDAS_CADASTRO_ARREDONDAMENTOVALOR_CONSULTAR: "produto.cadastro.arredondamentovalor.consultar",
    VENDAS_CADASTRO_ARREDONDAMENTOVALOR_INCLUIR: "produto.cadastro.arredondamentovalor.incluir",
    VENDAS_CADASTRO_ARREDONDAMENTOVALOR_EDITAR: "produto.cadastro.arredondamentovalor.editar",
    VENDAS_CADASTRO_ARREDONDAMENTOVALOR_EXCLUIR: "produto.cadastro.arredondamentovalor.excluir",

    // Vendas -> Cadastro -> Serviço
    VENDAS_CADASTRO_SERVICO_CONSULTAR: "vendas.cadastro.servico.consultar",
    VENDAS_CADASTRO_SERVICO_INCLUIR: "vendas.cadastro.servico.incluir",
    VENDAS_CADASTRO_SERVICO_EDITAR: "vendas.cadastro.servico.editar",
    VENDAS_CADASTRO_SERVICO_EXCLUIR: "vendas.cadastro.servico.excluir",

    // Vendas -> Cadastro -> Carteira de Clientes
    VENDAS_CADASTRO_CARTEIRACLIENTE_CONSULTAR: "vendas.cadastro.carteiracliente.consultar",
    VENDAS_CADASTRO_CARTEIRACLIENTE_INCLUIR: "vendas.cadastro.carteiracliente.incluir",
    VENDAS_CADASTRO_CARTEIRACLIENTE_EDITAR: "vendas.cadastro.carteiracliente.editar",
    VENDAS_CADASTRO_CARTEIRACLIENTE_EXCLUIR: "vendas.cadastro.carteiracliente.excluir",

    // Vendas -> Cadastro -> Voucher
    VENDAS_CADASTRO_VOUCHER_CONSULTAR: "vendas.cadastro.voucher.consultar",
    VENDAS_CADASTRO_VOUCHER_INCLUIR: "vendas.cadastro.voucher.incluir",
    VENDAS_CADASTRO_VOUCHER_CANCELAR: "vendas.cadastro.voucher.cancelar",
    VENDAS_CADASTRO_VOUCHER_UTILIZAR: "vendas.cadastro.voucher.utilizar",

    // Vendas -> Comissao -> Apuração de Comissão
    VENDAS_COMISSAO_APURACAOCOMISSAO_CONSULTAR: "vendas.comissao.apuracaocomissao.consultar",
    VENDAS_COMISSAO_APURACAOCOMISSAO_INCLUIR: "vendas.comissao.apuracaocomissao.incluir",
    VENDAS_COMISSAO_APURACAOCOMISSAO_EDITAR: "vendas.comissao.apuracaocomissao.editar",
    VENDAS_COMISSAO_APURACAOCOMISSAO_EXCLUIR: "vendas.comissao.apuracaocomissao.excluir",
    VENDAS_COMISSAO_APURACAOCOMISSAO_CALCULAR: "vendas.comissao.apuracaocomissao.calcular",
    VENDAS_COMISSAO_APURACAOCOMISSAO_PROCESSAR: "vendas.comissao.apuracaocomissao.processar",
    VENDAS_COMISSAO_AJUSTE_CONSULTA: "vendas.comissao.ajuste.consulta",
    VENDAS_COMISSAO_AJUSTE_TROCARCOMISSIONADO: "vendas.comissao.ajuste.trocarcomissionado",
    VENDAS_COMISSAO_AJUSTE_EDITARVALOR: "vendas.comissao.ajuste.editarvalor",
    VENDAS_COMISSAO_AJUSTE_EXCLUIR: "vendas.comissao.ajuste.excluir",

    VENDAS_VENDA_ASSISTENCIATECNICA_CONSULTAR: "vendas.venda.assistenciatecnica.consultar",
    VENDAS_VENDA_ASSISTENCIATECNICA_INCLUIR: "vendas.venda.assistenciatecnica.incluir",
    VENDAS_VENDA_ASSISTENCIATECNICA_EDITAR: "vendas.venda.assistenciatecnica.editar",
    VENDAS_VENDA_ASSISTENCIATECNICA_LANCAROCORRENCIA: "vendas.venda.assistenciatecnica.lancarocorrencia",
    VENDAS_VENDA_ASSISTENCIATECNICA_CANCELAR: "vendas.venda.assistenciatecnica.cancelar",
    VENDAS_VENDA_ASSISTENCIATECNICA_IMPRIMIR: "vendas.venda.assistenciatecnica.imprimir",
    VENDAS_VENDA_ASSISTENCIATECNICA_LANCARPECAS: "vendas.venda.assistenciatecnica.lancarpecas",

    VENDAS_CADASTRO_TIPOOCORRENCIAASSITTECNICA_CONSULTAR: "vendas.cadastro.tipoocorrenciaassistenciatecnica.consultar",
    VENDAS_CADASTRO_TIPOOCORRENCIAASSITTECNICA_INCLUIR: "vendas.cadastro.tipoocorrenciaassistenciatecnica.incluir",
    VENDAS_CADASTRO_TIPOOCORRENCIAASSITTECNICA_EDITAR: "vendas.cadastro.tipoocorrenciaassistenciatecnica.editar",
    VENDAS_CADASTRO_TIPOOCORRENCIAASSITTECNICA_EXCLUIR: "vendas.cadastro.tipoocorrenciaassistenciatecnica.excluir",

    VENDAS_CADASTRO_MOTIVODEVOLUCAO_CONSULTAR: "vendas.cadastro.motivodevolucao.consultar",
    VENDAS_CADASTRO_MOTIVODEVOLUCAO_INCLUIR: "vendas.cadastro.motivodevolucao.incluir",
    VENDAS_CADASTRO_MOTIVODEVOLUCAO_EDITAR: "vendas.cadastro.motivodevolucao.editar",
    VENDAS_CADASTRO_MOTIVODEVOLUCAO_EXCLUIR: "vendas.cadastro.motivodevolucao.excluir",

    // Estoque

    // Estoque -> Cadastro -> Estoques
    ESTOQUE_CADASTRO_ESTOQUES_CONSULTAR: "estoque.cadastro.estoques.consultar",
    ESTOQUE_CADASTRO_ESTOQUES_INCLUIR: "estoque.cadastro.estoques.incluir",
    ESTOQUE_CADASTRO_ESTOQUES_EDITAR: "estoque.cadastro.estoques.editar",
    ESTOQUE_CADASTRO_ESTOQUES_EXCLUIR: "estoque.cadastro.estoques.excluir",

    // Estoque -> Cadastro -> Centro de Estocagem
    ESTOQUE_CADASTRO_CENTROESTOCAGEM_CONSULTAR: "estoque.cadastro.centroestocagem.consultar",
    ESTOQUE_CADASTRO_CENTROESTOCAGEM_INCLUIR: "estoque.cadastro.centroestocagem.incluir",
    ESTOQUE_CADASTRO_CENTROESTOCAGEM_EDITAR: "estoque.cadastro.centroestocagem.editar",
    ESTOQUE_CADASTRO_CENTROESTOCAGEM_EXCLUIR: "estoque.cadastro.centroestocagem.excluir",
    ESTOQUE_CADASTRO_CENTROESTOCAGEM_RECALCULARSALDO: "estoque.cadastro.centroestocagem.recalcularsaldo",

    // Estoque -> Cadastro -> Tipo de Entrada
    ESTOQUE_CADASTRO_TIPOENTRADA_CONSULTAR: "estoque.cadastro.tipoentrada.consultar",
    ESTOQUE_CADASTRO_TIPOENTRADA_INCLUIR: "estoque.cadastro.tipoentrada.incluir",
    ESTOQUE_CADASTRO_TIPOENTRADA_EDITAR: "estoque.cadastro.tipoentrada.editar",
    ESTOQUE_CADASTRO_TIPOENTRADA_EXCLUIR: "estoque.cadastro.tipoentrada.excluir",

    // Estoque -> Cadastro -> Competência de movimentação de estoque
    ESTOQUE_CADASTRO_COMPMOVESTOQUE_CONSULTAR: "estoque.cadastro.compmovestoque.consultar",
    ESTOQUE_CADASTRO_COMPMOVESTOQUE_INCLUIR: "estoque.cadastro.compmovestoque.incluir",
    ESTOQUE_CADASTRO_COMPMOVESTOQUE_EDITAR: "estoque.cadastro.compmovestoque.editar",
    ESTOQUE_CADASTRO_COMPMOVESTOQUE_EXCLUIR: "estoque.cadastro.compmovestoque.excluir",

    // Estoque -> Cadastro -> Competência de Carga
    ESTOQUE_CADASTRO_CARGA_CONSULTAR: "estoque.cadastro.carga.consultar",
    ESTOQUE_CADASTRO_CARGA_INCLUIR: "estoque.cadastro.carga.incluir",
    ESTOQUE_CADASTRO_CARGA_EDITAR: "estoque.cadastro.carga.editar",
    ESTOQUE_CADASTRO_CARGA_EXCLUIR: "estoque.cadastro.carga.excluir",

    // Estoque -> Cadastro -> Competência de Tipo de Transferencia
    ESTOQUE_CADASTRO_TIPOTRANSFERENCIA_CONSULTAR: "estoque.cadastro.tipotransferencia.consultar",
    ESTOQUE_CADASTRO_TIPOTRANSFERENCIA_INCLUIR: "estoque.cadastro.tipotransferencia.incluir",
    ESTOQUE_CADASTRO_TIPOTRANSFERENCIA_EDITAR: "estoque.cadastro.tipotransferencia.editar",
    ESTOQUE_CADASTRO_TIPOTRANSFERENCIA_EXCLUIR: "estoque.cadastro.tipotransferencia.excluir",

    // Estoque -> Cadastro -> Saldo Estoque Formula
    ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_CONSULTAR: "estoque.cadastro.saldoestoqueformula.consultar",
    ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_INCLUIR: "estoque.cadastro.saldoestoqueformula.incluir",
    ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_EDITAR: "estoque.cadastro.saldoestoqueformula.editar",
    ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_EXCLUIR: "estoque.cadastro.saldoestoqueformula.excluir",

    // Estoque -> Movimentações -> Inventário
    ESTOQUE_MOVIMENTACOES_INVENTARIO_CONSULTAR: "estoque.movimentacoes.inventario.consultar",
    ESTOQUE_MOVIMENTACOES_INVENTARIO_INCLUIR: "estoque.movimentacoes.inventario.incluir",
    ESTOQUE_MOVIMENTACOES_INVENTARIO_EDITAR: "estoque.movimentacoes.inventario.editar",
    ESTOQUE_MOVIMENTACOES_INVENTARIO_EXCLUIR: "estoque.movimentacoes.inventario.excluir",

    // Estoque -> Movimentações -> Requisição Transferência
    ESTOQUE_MOVIMENTACOES_REQUISICAOTRANSFERENCIA_CONSULTAR: "estoque.movimentacoes.requisicaotransferencia.consultar",
    ESTOQUE_MOVIMENTACOES_REQUISICAOTRANSFERENCIA_INCLUIR: "estoque.movimentacoes.requisicaotransferencia.incluir",
    ESTOQUE_MOVIMENTACOES_REQUISICAOTRANSFERENCIA_EDITAR: "estoque.movimentacoes.requisicaotransferencia.editar",
    ESTOQUE_MOVIMENTACOES_REQUISICAOTRANSFERENCIA_EXCLUIR: "estoque.movimentacoes.requisicaotransferencia.excluir",
    ESTOQUE_MOVIMENTACOES_REQUISICAOTRANSFERENCIA_CANCELAR: "estoque.movimentacoes.requisicaotransferencia.cancelar",
    ESTOQUE_MOVIMENTACOES_REQUISICAOTRANSFERENCIA_DIVIDIR: "estoque.movimentacoes.requisicaotransferencia.dividir",

    // Estoque -> Movimentações -> Tipo de Movimentação
    ESTOQUE_MOVIMENTACOES_TIPOMOVIMENTACAO_CONSULTAR: "estoque.movimentacoes.tipomovimentacao.consultar",
    ESTOQUE_MOVIMENTACOES_TIPOMOVIMENTACAO_INCLUIR: "estoque.movimentacoes.tipomovimentacao.incluir",
    ESTOQUE_MOVIMENTACOES_TIPOMOVIMENTACAO_EDITAR: "estoque.movimentacoes.tipomovimentacao.editar",
    ESTOQUE_MOVIMENTACOES_TIPOMOVIMENTACAO_EXCLUIR: "estoque.movimentacoes.tipomovimentacao.excluir",

    // Estoque -> Movimentações -> Consultar Movimentações
    ESTOQUE_MOVIMENTACOES_CONSULTARMOVIMENTACOES_CONSULTAR: "estoque.movimentacoes.consultarmovimentacoes.consultar",
    ESTOQUE_MOVIMENTACOES_CONSULTARMOVIMENTACOES_INCLUIR: "estoque.movimentacoes.consultarmovimentacoes.incluir",
    ESTOQUE_MOVIMENTACOES_CONSULTARMOVIMENTACOES_EDITAR: "estoque.movimentacoes.consultarmovimentacoes.editar",
    ESTOQUE_MOVIMENTACOES_CONSULTARMOVIMENTACOES_EXCLUIR: "estoque.movimentacoes.consultarmovimentacoes.excluir",

    // Estoque -> Movimentações -> Saldo Inicial
    ESTOQUE_MOVIMENTACOES_SALDOINICIAL_CONSULTAR: "estoque.movimentacoes.saldoinicial.consultar",
    ESTOQUE_MOVIMENTACOES_SALDOINICIAL_INCLUIR: "estoque.movimentacoes.saldoinicial.incluir",
    ESTOQUE_MOVIMENTACOES_SALDOINICIAL_EDITAR: "estoque.movimentacoes.saldoinicial.editar",
    ESTOQUE_MOVIMENTACOES_SALDOINICIAL_EXCLUIR: "estoque.movimentacoes.saldoinicial.excluir",

    // Estoque -> Movimentações -> Acerto de Estoque
    ESTOQUE_MOVIMENTACOES_ACERTOESTOQUE_CONSULTAR: "estoque.movimentacoes.acertoestoque.consultar",
    ESTOQUE_MOVIMENTACOES_ACERTOESTOQUE_INCLUIR: "estoque.movimentacoes.acertoestoque.incluir",
    ESTOQUE_MOVIMENTACOES_ACERTOESTOQUE_EDITAR: "estoque.movimentacoes.acertoestoque.editar",
    ESTOQUE_MOVIMENTACOES_ACERTOESTOQUE_EXCLUIR: "estoque.movimentacoes.acertoestoque.excluir",
    ESTOQUE_MOVIMENTACOES_ACERTOESTOQUE_PROCESSAR: "estoque.movimentacoes.acertoestoque.processar",

    // Estoque -> Movimentações -> Transferência de Mercadoria
    ESTOQUE_MOVIMENTACOES_TRANSFERENCIAMERCADORIA_CONSULTAR: "estoque.movimentacoes.transferenciamercadoria.consultar",
    ESTOQUE_MOVIMENTACOES_TRANSFERENCIAMERCADORIA_INCLUIR: "estoque.movimentacoes.transferenciamercadoria.incluir",
    ESTOQUE_MOVIMENTACOES_TRANSFERENCIAMERCADORIA_EDITAR: "estoque.movimentacoes.transferenciamercadoria.editar",
    ESTOQUE_MOVIMENTACOES_TRANSFERENCIAMERCADORIA_EXCLUIR: "estoque.movimentacoes.transferenciamercadoria.excluir",

    // Estoque -> Movimentações -> Mercadoria de Exposição
    ESTOQUE_MOVIMENTACOES_TRANSFERENCIAEXPOSICAO_CONSULTAR: "estoque.movimentacoes.transferenciaexposicao.consultar",
    ESTOQUE_MOVIMENTACOES_TRANSFERENCIAEXPOSICAO_INCLUIR: "estoque.movimentacoes.transferenciaexposicao.incluir",
    ESTOQUE_MOVIMENTACOES_TRANSFERENCIAEXPOSICAO_EXCLUIR: "estoque.movimentacoes.transferenciaexposicao.excluir",

    // Financeiro
    // Financeiro -> Financeiro -> Lançamentos
    FINANCEIRO_FINANCEIRO_LANCAMENTOS_CONSULTAR: "financeiro.financeiro.lancamentos.consultar",
    FINANCEIRO_FINANCEIRO_LANCAMENTOS_EDITAR: "financeiro.financeiro.lancamentos.editar",
    FINANCEIRO_FINANCEIRO_LANCAMENTOS_COMPENSARMOVIMENTACAO: "financeiro.financeiro.lancamentos.compensarmovimentacao",
    FINANCEIRO_FINANCEIRO_LANCAMENTOS_DESCOMPENSARMOVIMENTACAO:
        "financeiro.financeiro.lancamentos.descompensarmovimentacao",
    FINANCEIRO_FINANCEIRO_LANCAMENTOS_CANCELARMOVIMENTACAO: "financeiro.financeiro.lancamentos.cancelarmovimentacao",
    FINANCEIRO_FINANCEIRO_LANCAMENTOS_ESTORNARMOVIMENTACAO: "financeiro.financeiro.lancamentos.estornarmovimentacao",

    // Financeiro -> Financeiro -> Caixa
    FINANCEIRO_FINANCEIRO_CAIXA_MANUTENCAO: "financeiro.financeiro.caixa.manutencao",
    FINANCEIRO_FINANCEIRO_CAIXA_INCLUIR: "financeiro.financeiro.caixa.incluir",
    FINANCEIRO_FINANCEIRO_CAIXA_EXCLUIR: "financeiro.financeiro.caixa.excluir",
    FINANCEIRO_FINANCEIRO_CAIXA_EDITAR: "financeiro.financeiro.caixa.editar",
    FINANCEIRO_FINANCEIRO_CAIXA_MOVIMENTAR: "financeiro.financeiro.caixa.movimentar",
    FINANCEIRO_FINANCEIRO_CAIXA_CONSULTAR: "financeiro.financeiro.caixa.consultar",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_MOVIMENTAR: "financeiro.financeiro.caixamov.movimentar",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_ESTORNARMOVIMENTACAO: "financeiro.financeiro.caixamov.estornarmovimentacao",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_ABRIR: "financeiro.financeiro.caixamov.abrir",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_FECHAR: "financeiro.financeiro.caixamov.fechar",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_EXCLUIR: "financeiro.financeiro.caixamov.excluir",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_BLOQUEAR: "financeiro.financeiro.caixamov.bloquear",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_BLOQUEAR_TERCEIRO: "financeiro.financeiro.caixamov.bloquear.terceiro",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR: "financeiro.financeiro.caixamov.desbloquear",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR_TERCEIRO: "financeiro.financeiro.caixamov.desbloquear.terceiro",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_SUPRIR: "financeiro.financeiro.caixamov.suprir",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_TRANSFERIR: "financeiro.financeiro.caixamov.transferir",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_SANGRAR: "financeiro.financeiro.caixamov.sangrar",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_GERARRESUMO: "financeiro.financeiro.caixamov.gerarresumo",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_PROCESSAR: "financeiro.financeiro.caixamov.pendencias.processar",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_DESPROCESSAR: "financeiro.financeiro.caixamov.pendencias.desprocessar",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_CONSULTAR: "financeiro.financeiro.caixamov.pendencias.consultar",
    FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_ATRIBUIR: "financeiro.financeiro.caixamov.pendencias.atribuir",

    // Financeiro -> Financeiro -> Fracionamentos
    FINANCEIRO_FINANCEIRO_FRACIONAMENTOS_CONSULTAR: "financeiro.financeiro.fracionamentos.consultar",
    FINANCEIRO_FINANCEIRO_FRACIONAMENTOS_INCLUIR: "financeiro.financeiro.fracionamentos.incluir",
    FINANCEIRO_FINANCEIRO_FRACIONAMENTOS_EDITAR: "financeiro.financeiro.fracionamentos.editar",
    FINANCEIRO_FINANCEIRO_FRACIONAMENTOS_EXCLUIR: "financeiro.financeiro.fracionamentos.excluir",

    // Financeiro -> Financeiro -> Plano de Contas
    FINANCEIRO_FINANCEIRO_PLANOCONTAS_CONSULTAR: "financeiro.financeiro.planocontas.consultar",
    FINANCEIRO_FINANCEIRO_PLANOCONTAS_INCLUIR: "financeiro.financeiro.planocontas.incluir",
    FINANCEIRO_FINANCEIRO_PLANOCONTAS_EDITAR: "financeiro.financeiro.planocontas.editar",
    FINANCEIRO_FINANCEIRO_PLANOCONTAS_EXCLUIR: "financeiro.financeiro.planocontas.excluir",

    // Financeiro -> Financeiro -> Pagamentos
    FINANCEIRO_FINANCEIRO_PAGAMENTOS_CONSULTAR: "financeiro.financeiro.pagamentos.consultar",
    FINANCEIRO_FINANCEIRO_PAGAMENTOS_INCLUIR: "financeiro.financeiro.pagamentos.incluir",
    FINANCEIRO_FINANCEIRO_PAGAMENTOS_EDITAR: "financeiro.financeiro.pagamentos.editar",
    FINANCEIRO_FINANCEIRO_PAGAMENTOS_EXCLUIR: "financeiro.financeiro.pagamentos.excluir",
    FINANCEIRO_FINANCEIRO_PAGAMENTOS_FRACIONAR: "financeiro.financeiro.pagamentos.fracionar",

    // Financeiro -> Financeiro -> Rateios
    FINANCEIRO_FINANCEIRO_RATEIOS_CONSULTAR: "financeiro.financeiro.rateios.consultar",
    FINANCEIRO_FINANCEIRO_RATEIOS_INCLUIR: "financeiro.financeiro.rateios.incluir",
    FINANCEIRO_FINANCEIRO_RATEIOS_EDITAR: "financeiro.financeiro.rateios.editar",
    FINANCEIRO_FINANCEIRO_RATEIOS_EXCLUIR: "financeiro.financeiro.rateios.excluir",

    // Financeiro -> Financeiro -> Recebimentos
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS_CONSULTAR: "financeiro.financeiro.recebimentos.consultar",
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS_INCLUIR: "financeiro.financeiro.recebimentos.incluir",
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EDITAR: "financeiro.financeiro.recebimentos.editar",
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EXCLUIR: "financeiro.financeiro.recebimentos.excluir",
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS_INCLUIRRATEIO: "financeiro.financeiro.recebimentos.incluirrateio",
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EXCLUIRRATEIO: "financeiro.financeiro.recebimentos.excluirrateio",
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EFETIVAR: "financeiro.financeiro.recebimentos.efetivar",
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EDITAR_ACRESCIMOS: "financeiro.financeiro.recebimentos.editar.acrescimos",
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS_CARCELARVENDA: "financeiro.financeiro.recebimentos.cancelarvenda",
    FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EFETIVADO_CORRIGIR: "financeiro.financeiro.recebimentos.efetivado.corrigir",

    // Financeiro -> Financeiro -> Template Rateio

    FINANCEIRO_FINANCEIRO_TEMPLATERATEIO_CONSULTAR: "financeiro.financeiro.templaterateio.consultar",
    FINANCEIRO_FINANCEIRO_TEMPLATERATEIO_INCLUIR: "financeiro.financeiro.templaterateio.incluir",
    FINANCEIRO_FINANCEIRO_TEMPLATERATEIO_EDITAR: "financeiro.financeiro.templaterateio.editar",
    FINANCEIRO_FINANCEIRO_TEMPLATERATEIO_EXCLUIR: "financeiro.financeiro.templaterateio.excluir",

    // Financeiro -> Financeiro -> Template Detalhe Rateio

    FINANCEIRO_FINANCEIRO_TEMPLATEDETALHERATEIO_CONSULTAR: "financeiro.financeiro.templatedetalherateio.consultar",
    FINANCEIRO_FINANCEIRO_TEMPLATEDETALHERATEIO_INCLUIR: "financeiro.financeiro.templatedetalherateio.incluir",
    FINANCEIRO_FINANCEIRO_TEMPLATEDETALHERATEIO_EDITAR: "financeiro.financeiro.templatedetalherateio.editar",
    FINANCEIRO_FINANCEIRO_TEMPLATEDETALHERATEIO_EXCLUIR: "financeiro.financeiro.templatedetalherateio.excluir",

    // Financeiro -> Financeiro -> Versão do Plano de Contas
    FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_CONSULTAR: "financeiro.financeiro.versaoplanocontas.consultar",
    FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_INCLUIR: "financeiro.financeiro.versaoplanocontas.incluir",
    FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_EDITAR: "financeiro.financeiro.versaoplanocontas.editar",
    FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_EXCLUIR: "financeiro.financeiro.versaoplanocontas.excluir",

    // Financeiro -> Financeiro -> Plano de Contas

    FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_CONSULTAR: "financeiro.financeiro.planorecebimento.consultar",
    FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_INCLUIR: "financeiro.financeiro.planorecebimento.incluir",
    FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_EDITAR: "financeiro.financeiro.planorecebimento.editar",
    FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_EXCLUIR: "financeiro.financeiro.planorecebimento.excluir",

    // Financeiro -> Financeiro -> Pagamento efetivado

    FINANCEIRO_FINANCEIRO_PAGAMENTOEFETIVADO_CONSULTAR: "financeiro.financeiro.pagamentoefetivado.consultar",
    FINANCEIRO_FINANCEIRO_PAGAMENTOEFETIVADO_INCLUIR: "financeiro.financeiro.pagamentoefetivado.incluir",
    FINANCEIRO_FINANCEIRO_PAGAMENTOEFETIVADO_EDITAR: "financeiro.financeiro.pagamentoefetivado.editar",
    FINANCEIRO_FINANCEIRO_PAGAMENTOEFETIVADO_EXCLUIR: "financeiro.financeiro.pagamentoefetivado.excluir",

    // Financeiro -> Financeiro -> Previsao de pagamento

    FINANCEIRO_FINANCEIRO_PREVISAOPAGAMENTO_CONSULTAR: "financeiro.financeiro.previsaopagamento.consultar",
    FINANCEIRO_FINANCEIRO_PREVISAOPAGAMENTO_INCLUIR: "financeiro.financeiro.previsaopagamento.incluir",
    FINANCEIRO_FINANCEIRO_PREVISAOPAGAMENTO_EDITAR: "financeiro.financeiro.previsaopagamento.editar",
    FINANCEIRO_FINANCEIRO_PREVISAOPAGAMENTO_EXCLUIR: "financeiro.financeiro.previsaopagamento.excluir",

    // Financeiro -> Financeiro -> Plano Orçamentario

    FINANCEIRO_FINANCEIRO_PLANOORCAMENTARIO_CONSULTAR: "financeiro.financeiro.planoorcamentario.consultar",
    FINANCEIRO_FINANCEIRO_PLANOORCAMENTARIO_INCLUIR: "financeiro.financeiro.planoorcamentario.incluir",
    FINANCEIRO_FINANCEIRO_PLANOORCAMENTARIO_EDITAR: "financeiro.financeiro.planoorcamentario.editar",
    FINANCEIRO_FINANCEIRO_PLANOORCAMENTARIO_EXCLUIR: "financeiro.financeiro.planoorcamentario.excluir",

    FINANCEIRO_CADASTRO_BANCO_CONSULTAR: "financeiro.cadastro.banco.consultar",
    FINANCEIRO_CADASTRO_BANCO_INCLUIR: "financeiro.cadastro.banco.incluir",
    FINANCEIRO_CADASTRO_BANCO_EDITAR: "financeiro.cadastro.banco.editar",
    FINANCEIRO_CADASTRO_BANCO_EXCLUIR: "financeiro.cadastro.banco.excluir",

    // Financeiro -> Cadastro -> Titular Conta Bancária

    FINANCEIRO_CADASTRO_TITULARCONTABANCARIA_CONSULTAR: "financeiro.cadastro.titularcontabancaria.consultar",
    FINANCEIRO_CADASTRO_TITULARCONTABANCARIA_INCLUIR: "financeiro.cadastro.titularcontabancaria.incluir",
    FINANCEIRO_CADASTRO_TITULARCONTABANCARIA_EDITAR: "financeiro.cadastro.titularcontabancaria.editar",
    FINANCEIRO_CADASTRO_TITULARCONTABANCARIA_EXCLUIR: "financeiro.cadastro.titularcontabancaria.excluir",

    // Financeiro -> Cadastro -> Forma Recebimento

    FINANCEIRO_CADASTRO_FORMARECEBIMENTOS_CONSULTAR: "financeiro.cadastro.formarecebimentos.consultar",
    FINANCEIRO_CADASTRO_FORMARECEBIMENTOS_INCLUIR: "financeiro.cadastro.formarecebimentos.incluir",
    FINANCEIRO_CADASTRO_FORMARECEBIMENTOS_EDITAR: "financeiro.cadastro.formarecebimentos.editar",
    FINANCEIRO_CADASTRO_FORMARECEBIMENTOS_EXCLUIR: "financeiro.cadastro.formarecebimentos.excluir",

    // Financeiro -> Cadastro -> Chave Pix

    FINANCEIRO_CADASTRO_CHAVEPIX_CONSULTAR: "financeiro.cadastro.chavepix.consultar",
    FINANCEIRO_CADASTRO_CHAVEPIX_INCLUIR: "financeiro.cadastro.chavepix.incluir",
    FINANCEIRO_CADASTRO_CHAVEPIX_EDITAR: "financeiro.cadastro.chavepix.editar",
    FINANCEIRO_CADASTRO_CHAVEPIX_EXCLUIR: "financeiro.cadastro.chavepix.excluir",

    // Financeiro -> Cadastro -> Histórico Padrão

    FINANCEIRO_CADASTROS_HISTORICOPADRAO_CONSULTAR: "financeiro.cadastros.historicopadrao.consultar",
    FINANCEIRO_CADASTROS_HISTORICOPADRAO_INCLUIR: "financeiro.cadastros.historicopadrao.incluir",
    FINANCEIRO_CADASTROS_HISTORICOPADRAO_EDITAR: "financeiro.cadastros.historicopadrao.editar",
    FINANCEIRO_CADASTROS_HISTORICOPADRAO_EXCLUIR: "financeiro.cadastros.historicopadrao.excluir",

    // Financeiro -> Cadastro -> Template importação de extrato

    FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_CONSULTAR: "financeiro.cadastro.template.importacao.extrato.consultar",
    FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_INCLUIR: "financeiro.cadastro.template.importacao.extrato.incluir",
    FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_EDITAR: "financeiro.cadastro.template.importacao.extrato.editar",
    FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_EXCLUIR: "financeiro.cadastro.template.importacao.extrato.excluir",

    // Financeiro -> Cadastro -> Carteira Conta Financeira

    FINANCEIRO_CADASTRO_CARTEIRACONTAFINANCEIRA_CONSULTAR: "financeiro.cadastro.carteiracontafinanceira.consultar",
    FINANCEIRO_CADASTRO_CARTEIRACONTAFINANCEIRA_INCLUIR: "financeiro.cadastro.carteiracontafinanceira.incluir",
    FINANCEIRO_CADASTRO_CARTEIRACONTAFINANCEIRA_EDITAR: "financeiro.cadastro.carteiracontafinanceira.editar",
    FINANCEIRO_CADASTRO_CARTEIRACONTAFINANCEIRA_EXCLUIR: "financeiro.cadastro.carteiracontafinanceira.excluir",

    // Financeiro -> Cadastro -> Conta Financeira

    FINANCEIRO_CADASTRO_CONTAFINANCEIRA_CONSULTAR: "financeiro.cadastro.contafinanceira.consultar",
    FINANCEIRO_CADASTRO_CONTAFINANCEIRA_INCLUIR: "financeiro.cadastro.contafinanceira.incluir",
    FINANCEIRO_CADASTRO_CONTAFINANCEIRA_EDITAR: "financeiro.cadastro.contafinanceira.editar",
    FINANCEIRO_CADASTRO_CONTAFINANCEIRA_EXCLUIR: "financeiro.cadastro.contafinanceira.excluir",

    // Financeiro -> Cadastro -> Conta Bancaria

    FINANCEIRO_CADASTRO_CONTABANCARIA_CONSULTAR: "financeiro.cadastro.contabancaria.consultar",
    FINANCEIRO_CADASTRO_CONTABANCARIA_INCLUIR: "financeiro.cadastro.contabancaria.incluir",
    FINANCEIRO_CADASTRO_CONTABANCARIA_EDITAR: "financeiro.cadastro.contabancaria.editar",
    FINANCEIRO_CADASTRO_CONTABANCARIA_EXCLUIR: "financeiro.cadastro.contabancaria.excluir",

    // Financeiro -> Cadastro -> Competencia

    FINANCEIRO_CADASTRO_COMPETENCIA_CONSULTAR: "financeiro.cadastro.competencia.consultar",
    FINANCEIRO_CADASTRO_COMPETENCIA_INCLUIR: "financeiro.cadastro.competencia.incluir",
    FINANCEIRO_CADASTRO_COMPETENCIA_EDITAR: "financeiro.cadastro.competencia.editar",
    FINANCEIRO_CADASTRO_COMPETENCIA_EXCLUIR: "financeiro.cadastro.competencia.excluir",

    // Financeiro -> Cadastro -> Administradora Cartão

    FINANCEIRO_CADASTRO_ADMINISTRADORACARTAO_CONSULTAR: "financeiro.cadastro.administradoracartao.consultar",

    // Financeiro -> Cadastro -> Bandeira Cartão

    FINANCEIRO_CADASTRO_BANDEIRACARTAO_CONSULTAR: "financeiro.cadastro.bandeiracartao.consultar",

    // Financeiro -> Cadastro -> Taxa Administração cartão

    FINANCEIRO_CADASTRO_TAXAADMINISTRACAOCARTAO_CONSULTAR: "financeiro.cadastro.taxaadministracaocartao.consultar",
    FINANCEIRO_CADASTRO_TAXAADMINISTRACAOCARTAO_INCLUIR: "financeiro.cadastro.taxaadministracaocartao.incluir",
    FINANCEIRO_CADASTRO_TAXAADMINISTRACAOCARTAO_EDITAR: "financeiro.cadastro.taxaadministracaocartao.editar",
    FINANCEIRO_CADASTRO_TAXAADMINISTRACAOCARTAO_EXCLUIR: "financeiro.cadastro.taxaadministracaocartao.excluir",

    // Fiscal

    // Fiscal -> Fiscal
    FISCAL_NCM_CEST_MANUTENCAO: "fiscal.ncm.cest.manutencao",

    // Fiscal -> Opçõs Faturamento
    FISCAL_FISCAL_OPCOESFATURAMENTO_EDITAR: "fiscal.fiscal.opcoesfaturamento.editar",

    // Fiscal -> Nota de Saída
    FISCAL_NOTASAIDA_CONSULTAR: "fiscal.notasaida.consultar",
    FISCAL_NOTASAIDA_EDITAR: "fiscal.notasaida.editar",
    FISCAL_NOTASAIDA_INCLUIR: "fiscal.notasaida.incluir",
    FISCAL_NOTASAIDA_EMITIR: "fiscal.notasaida.emitir",
    FISCAL_NOTASAIDA_CORRIGIR: "fiscal.notasaida.corrigir",
    FISCAL_NOTASAIDA_CANCELAR: "fiscal.notasaida.cancelar",
    FISCAL_NOTASAIDA_INUTILIZAR: "fiscal.notasaida.inutilizar",
    FISCAL_NOTASAIDA_MODELO_ALTERAR: "fiscal.notasaida.modelo.alterar",

    // Fiscal -> Nota de Serviço
    FISCAL_NOTASERVICO_CONSULTAR: "fiscal.notaservico.consultar",
    FISCAL_NOTASERVICO_EDITAR: "fiscal.notaservico.editar",
    FISCAL_NOTASERVICO_INCLUIR: "fiscal.notaservico.incluir",
    FISCAL_NOTASERVICO_EMITIR: "fiscal.notaservico.emitir",
    FISCAL_NOTASERVICO_CORRIGIR: "fiscal.notaservico.corrigir",
    FISCAL_NOTASERVICO_CANCELAR: "fiscal.notaservico.cancelar",

    // Fiscal -> Sped Tipo
    FISCAL_SPEDTIPO_INCLUIR: "fiscal.spedtipo.incluir",
    FISCAL_SPEDTIPO_EDITAR: "fiscal.spedtipo.editar",
    FISCAL_SPEDTIPO_CONSULTAR: "fiscal.spedtipo.consultar",
    FISCAL_SPEDTIPO_EXCLUIR: "fiscal.spedtipo.excluir",

    // Fiscal -> Nota de Conhecimento de Transporte
    FISCAL_NOTATRANSPORTE_CONSULTAR: "fiscal.notatransporte.consultar",
    FISCAL_NOTATRANSPORTE_EDITAR: "fiscal.notatransporte.editar",
    FISCAL_NOTATRANSPORTE_INCLUIR: "fiscal.notatransporte.incluir",
    FISCAL_NOTATRANSPORTE_EXCLUIR: "fiscal.notatransporte.cancelar",

    // Fiscal -> NCM
    FISCAL_FISCAL_NCM_INCLUIR: "fiscal.fiscal.ncm.incluir",
    FISCAL_FISCAL_NCM_EDITAR: "fiscal.fiscal.ncm.editar",
    FISCAL_FISCAL_NCM_CONSULTAR: "fiscal.fiscal.ncm.consultar",
    FISCAL_FISCAL_NCM_EXCLUIR: "fiscal.fiscal.ncm.excluir",

    // Fiscal -> IPI
    FISCAL_FISCAL_IPI_INCLUIR: "fiscal.fiscal.ipi.incluir",
    FISCAL_FISCAL_IPI_EDITAR: "fiscal.fiscal.ipi.editar",
    FISCAL_FISCAL_IPI_CONSULTAR: "fiscal.fiscal.ipi.consultar",
    FISCAL_FISCAL_IPI_EXCLUIR: "fiscal.fiscal.ipi.excluir",

    // Fiscal -> IPI
    FISCAL_FISCAL_ICMS_INCLUIR: "fiscal.fiscal.icms.incluir",
    FISCAL_FISCAL_ICMS_EDITAR: "fiscal.fiscal.icms.editar",
    FISCAL_FISCAL_ICMS_CONSULTAR: "fiscal.fiscal.icms.consultar",
    FISCAL_FISCAL_ICMS_EXCLUIR: "fiscal.fiscal.icms.excluir",

    // Fiscal -> CFOP
    FISCAL_FISCAL_CFOP_INCLUIR: "fiscal.fiscal.cfop.incluir",
    FISCAL_FISCAL_CFOP_EDITAR: "fiscal.fiscal.cfop.editar",
    FISCAL_FISCAL_CFOP_CONSULTAR: "fiscal.fiscal.cfop.consultar",
    FISCAL_FISCAL_CFOP_EXCLUIR: "fiscal.fiscal.cfop.excluir",

    // Fiscal -> CEST
    FISCAL_FISCAL_CEST_INCLUIR: "fiscal.fiscal.cest.incluir",
    FISCAL_FISCAL_CEST_EDITAR: "fiscal.fiscal.cest.editar",
    FISCAL_FISCAL_CEST_CONSULTAR: "fiscal.fiscal.cest.consultar",
    FISCAL_FISCAL_CEST_EXCLUIR: "fiscal.fiscal.cest.excluir",

    // Fiscal -> PIS
    FISCAL_FISCAL_PIS_INCLUIR: "fiscal.fiscal.pis.incluir",
    FISCAL_FISCAL_PIS_EDITAR: "fiscal.fiscal.pis.editar",
    FISCAL_FISCAL_PIS_CONSULTAR: "fiscal.fiscal.pis.consultar",
    FISCAL_FISCAL_PIS_EXCLUIR: "fiscal.fiscal.pis.excluir",

    // Fiscal -> Cofins
    FISCAL_FISCAL_COFINS_INCLUIR: "fiscal.fiscal.cofins.incluir",
    FISCAL_FISCAL_COFINS_EDITAR: "fiscal.fiscal.cofins.editar",
    FISCAL_FISCAL_COFINS_CONSULTAR: "fiscal.fiscal.cofins.consultar",
    FISCAL_FISCAL_COFINS_EXCLUIR: "fiscal.fiscal.cofins.excluir",

    // Fiscal -> Operação Fiscal
    FISCAL_CADASTRO_OPERACAOFISCAL_INCLUIR: "fiscal.cadastro.operacaofiscal.incluir",
    FISCAL_CADASTRO_OPERACAOFISCAL_EDITAR: "fiscal.cadastro.operacaofiscal.editar",
    FISCAL_CADASTRO_OPERACAOFISCAL_CONSULTAR: "fiscal.cadastro.operacaofiscal.consultar",
    FISCAL_CADASTRO_OPERACAOFISCAL_EXCLUIR: "fiscal.cadastro.operacaofiscal.excluir",

    // Fiscal -> Operação Fiscal de Serviços
    FISCAL_CADASTRO_OPERACAOFISCALSERVICO_INCLUIR: "fiscal.cadastro.operacaofiscalservico.incluir",
    FISCAL_CADASTRO_OPERACAOFISCALSERVICO_EDITAR: "fiscal.cadastro.operacaofiscalservico.editar",
    FISCAL_CADASTRO_OPERACAOFISCALSERVICO_CONSULTAR: "fiscal.cadastro.operacaofiscalservico.consultar",
    FISCAL_CADASTRO_OPERACAOFISCALSERVICO_EXCLUIR: "fiscal.cadastro.operacaofiscalservico.excluir",
    FISCAL_CADASTRO_OPERACAOFISCALSERVICO_CLONAR: "fiscal.cadastro.operacaofiscalservico.clonar",

    // Fiscal -> Sped Sintegra
    FISCAL_FISCAL_SPEDFISCAL_CONSULTAR: "fiscal.fiscal.spedfiscal.consultar",

    // Contábil

    // Contábil -> Contábil -> Plano de Contas
    CONTABIL_CONTABIL_PLANOCONTAS_CONSULTAR: "contabil.contabil.planocontas.consultar",
    CONTABIL_CONTABIL_PLANOCONTAS_INCLUIR: "contabil.contabil.planocontas.incluir",
    CONTABIL_CONTABIL_PLANOCONTAS_EDITAR: "contabil.contabil.planocontas.editar",
    CONTABIL_CONTABIL_PLANOCONTAS_EXCLUIR: "contabil.contabil.planocontas.excluir",

    // Contábil -> Contábil -> Versão do Plano de Contas
    CONTABIL_CONTABIL_VERSAOPLANOCONTAS_CONSULTAR: "contabil.contabil.versaoplanocontas.consultar",
    CONTABIL_CONTABIL_VERSAOPLANOCONTAS_INCLUIR: "contabil.contabil.versaoplanocontas.incluir",
    CONTABIL_CONTABIL_VERSAOPLANOCONTAS_EDITAR: "contabil.contabil.versaoplanocontas.editar",
    CONTABIL_CONTABIL_VERSAOPLANOCONTAS_EXCLUIR: "contabil.contabil.versaoplanocontas.excluir",

    // Contábil -> Contábil -> Versão do Plano de Contas
    CONTABIL_CONTABIL_VINCULARPLANOS_CONSULTAR: "contabil.contabil.vincularplanos.consultar",
    CONTABIL_CONTABIL_VINCULARPLANOS_INCLUIR: "contabil.contabil.vincularplanos.incluir",
    CONTABIL_CONTABIL_VINCULARPLANOS_EXCLUIR: "contabil.contabil.vincularplanos.excluir",

    // Operacional

    // Operacional -> Plano Operacional -> Centro de Resultados
    OPERACIONAL_PLANOOPERACIONAL_CENTRORESULTADOS_CONSULTAR: "operacional.planooperacional.centroresultados.consultar",
    OPERACIONAL_PLANOOPERACIONAL_CENTRORESULTADOS_INCLUIR: "operacional.planooperacional.centroresultados.incluir",
    OPERACIONAL_PLANOOPERACIONAL_CENTRORESULTADOS_EDITAR: "operacional.planooperacional.centroresultados.editar",
    OPERACIONAL_PLANOOPERACIONAL_CENTRORESULTADOS_EXCLUIR: "operacional.planooperacional.centroresultados.excluir",

    // Operacional -> Plano Operacional -> Projetos
    OPERACIONAL_PLANOOPERACIONAL_PROJETOS_CONSULTAR: "operacional.planooperacional.projetos.consultar",
    OPERACIONAL_PLANOOPERACIONAL_PROJETOS_INCLUIR: "operacional.planooperacional.projetos.incluir",
    OPERACIONAL_PLANOOPERACIONAL_PROJETOS_EDITAR: "operacional.planooperacional.projetos.editar",
    OPERACIONAL_PLANOOPERACIONAL_PROJETOS_EXCLUIR: "operacional.planooperacional.projetos.excluir",

    // Operacional -> Plano Operacional -> Fases de Projetos
    OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_CONSULTAR: "operacional.planooperacional.fasesprojetos.consultar",
    OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_INCLUIR: "operacional.planooperacional.fasesprojetos.incluir",
    OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_EDITAR: "operacional.planooperacional.fasesprojetos.editar",
    OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_EXCLUIR: "operacional.planooperacional.fasesprojetos.excluir",

    // Operacional -> Plano Operacional -> Setores
    OPERACIONAL_PLANOOPERACIONAL_SETORES_CONSULTAR: "operacional.planooperacional.setores.consultar",
    OPERACIONAL_PLANOOPERACIONAL_SETORES_INCLUIR: "operacional.planooperacional.setores.incluir",
    OPERACIONAL_PLANOOPERACIONAL_SETORES_EDITAR: "operacional.planooperacional.setores.editar",
    OPERACIONAL_PLANOOPERACIONAL_SETORES_EXCLUIR: "operacional.planooperacional.setores.excluir",

    // Servicos -> Cadastros -> Partes
    SERVICOS_CADASTROS_PARTES_CONSULTAR: "servicos.cadastros.partes.consultar",
    SERVICOS_CADASTROS_PARTES_EDITAR: "servicos.cadastros.partes.editar",

    // Servicos -> Servicos -> Servico
    SERVICOS_SERVICOS_SERVICO_CONSULTAR: "servicos.servicos.servico.consultar",
    SERVICOS_SERVICOS_SERVICO_INCLUIR: "servicos.servicos.servico.incluir",
    SERVICOS_SERVICOS_SERVICO_EDITAR: "servicos.servicos.servico.editar",
    SERVICOS_SERVICOS_SERVICO_EXCLUIR: "servicos.servicos.servico.excluir",

    // Servicos -> Servicos -> Servico Município
    SERVICOS_SERVICOS_SERVICOMUNICIPIO_CONSULTAR: "servicos.servicomunicipio.servico.consultar",
    SERVICOS_SERVICOS_SERVICOMUNICIPIO_INCLUIR: "servicos.servicomunicipio.servico.incluir",
    SERVICOS_SERVICOS_SERVICOMUNICIPIO_EDITAR: "servicos.servicomunicipio.servico.editar",
    SERVICOS_SERVICOS_SERVICOMUNICIPIO_EXCLUIR: "servicos.servicomunicipio.servico.excluir",

    // Servicos -> Servicos -> Contrato de Servico
    SERVICOS_SERVICOS_CONTRATOSERVICO_CONSULTAR: "servicos.servicos.contratoservico.consultar",
    SERVICOS_SERVICOS_CONTRATOSERVICO_INCLUIR: "servicos.servicos.contratoservico.incluir",
    SERVICOS_SERVICOS_CONTRATOSERVICO_EDITAR: "servicos.servicos.contratoservico.editar",
    SERVICOS_SERVICOS_CONTRATOSERVICO_EXCLUIR: "servicos.servicos.contratoservico.excluir",

    // Configrações -> Orientações
    CONFIGURACOES_ORIENTACOES_CRIAR: "configuracoes.orientacao.criar",
    CONFIGURACOES_ORIENTACOES_REVISAR: "configuracoes.orientacao.revisar",
    CONFIGURACOES_ORIENTACOES_PUBLICAR: "configuracoes.orientacao.publicar",

    // Servicos -> Servicos -> Contrato de Servico
    CONFIGURACOES_FERIADOS_CONSULTAR: "configuracoes.feriados.consultar",
    CONFIGURACOES_FERIADOS_INCLUIR: "configuracoes.feriados.incluir",
    CONFIGURACOES_FERIADOS_EDITAR: "configuracoes.feriados.editar",
    CONFIGURACOES_FERIADOS_EXCLUIR: "configuracoes.feriados.excluir",

    // Relatórios -> Relatórios Emitidos
    RELATORIOS_RELATORIOSEMITIDOS_CONSULTAR: "relatorios.relatoriosemitidos.consultar",

    //Relatórios -> Emitir
    RELATORIOS_EMITIR_PESSOAS_RELACAOPESSOAS: "relatorios.emitir.pessoas.relacaopessoas",
    RELATORIOS_EMITIR_CONTAS_APAGAR: "relatorios.emitir.contas.apagar",
    RELATORIOS_EMITIR_CONTAS_RECEBER: "relatorios.emitir.contas.receber",
    RELATORIOS_EMITIR_TABELA_PRECOS: "relatorios.emitir.tabela.precos",
    RELATORIOS_EMITIR_CAIXA_MOVIMENTO_RESUMO: "relatorios.emitir.caixa.movimento.resumo",
    RELATORIOS_EMITIR_VENDAS_TABELAPRECOSVENDAS: "relatorios.emitir.vendas.tabelaprecosvendas",
    RELATORIOS_EMITIR_ESTOQUE_SALDOOC: "relatorios.emitir.estoque.saldooc",
    RELATORIOS_EMITIR_ESTOQUE_SALDOINICIAL: "relatorios.emitir.estoque.saldoinicial",
    RELATORIOS_EMITIR_ESTOQUE_SALDOPRODUTOS: "relatorios.emitir.estoque.saldoprodutos",
    RELATORIOS_EMITIR_VENDAS_ROMANEIO: "relatorios.emitir.vendas.romaneio",
    RELATORIOS_EMITIR_VENDAS_CONTROLEENTREGA: "relatorios.emitir.vendas.controleentrega",
    RELATORIOS_EMITIR_ESTOQUE_NECESSIDADECOMPRA: "relatorios.emitir.estoque.necessidadecompra",
    RELATORIOS_EMITIR_VENDAS_CURVAABCPDF: "relatorios.emitir.vendas.curvaabcpdf",
    RELATORIOS_EMITIR_PRODUTOS_INVENTARIOPRODUTOS: "relatorios.emitir.produtos.inventarioprodutos",
    RELATORIOS_EMITIR_PRODUTOS_RELACOESPRODUTOS: "relatorios.emitir.produtos.relacoesprodutos",
    RELATORIOS_EMITIR_VENDAS_EXTRATOCOMISSAOANALITCO: "relatorios.emitir.vendas.extratocomissaoanalitico",
    RELATORIOS_EMITIR_FINANCEIRO_EXTRATOCONTA: "relatorios.emitir.financeiro.extratoconta",
    RELATORIOS_EMITIR_FINANCEIRO_MOVIMENTACAOCONTAFINANCEIRA:
        "relatorios.emitir.financeiro.movimentacaocontafinanceira",
    RELATORIOS_EMITIR_VENDAS_ORCAMENTORAPIDO: "relatorios.emitir.vendas.orcamentorapido",
    RELATORIOS_EMITIR_VENDAS_EXTRATOCOMISSAOSINTETICO: "relatorios.emitir.vendas.extratocomissaosintetico",
    RELATORIOS_EMITIR_VENDAS_HISTORICOSIMPLESCLIENTE: "relatorios.emitir.vendas.historicosimplescliente",
    RELATORIOS_EMITIR_PRODUTOS_MOVIMENTACAOPRODUTOS: "relatorios.emitir.produtos.movimentacaoprodutos",
    RELATORIOS_EMITIR_VENDAS_LISTAGEMSIMPLES: "relatorios.emitir.vendas.listagemsimples",
    RELATORIOS_EMITIR_ESTOQUE_TRANSFERENCIAFILIAL: "relatorios.emitir.estoque.transferenciafilial",
    RELATORIOS_EMITIR_ESTOQUE_ROMANEIOTRANSFERENCIA: "relatorios.emitir.estoque.romaneiotransferencia",
    RELATORIOS_EMITIR_SERVICOS_CONTRATOSSERVICOS: "relatorios.emitir.servicos.contratosservicos",
    RELATORIOS_EMITIR_SERVICOS_CONTRATOSSERVICOSATRASOS: "relatorios.emitir.servicos.contratosservicosatrasos",
    RELATORIOS_EMITIR_PRODUTOS_RELACAOPRODUTOS: "relatorios.emitir.produtos.relacaoprodutos",
    RELATORIOS_EMITIR_PRODUTOS_RELACAOPISCOFINS: "relatorios.emitir.produtos.relacaopiscofins",
    RELATORIOS_EMITIR_ESTOQUE_SALDOESTOQUES: "relatorios.emitir.estoque.saldoestoques",
    RELATORIOS_EMITIR_VENDAS_ENTREGAS: "relatorios.emitir.vendas.entregas",
    RELATORIOS_EMITIR_ESTOQUE_CUSTOSALDOESTOQUES: "relatorios.emitir.estoque.custosaldoestoques",
    RELATORIOS_EMITIR_VENDAS_ENTREGAS_REALIZADAS: "relatorios.emitir.vendas.entregas.realizadas",
    RELATORIOS_EMITIR_VENDAS_CARNEPARCELASVENDA: "relatorios.emitir.vendas.carneparcelasvenda",
    RELATORIOS_EMITIR_FISCAL_TRANSFERENCIAPRODUTO: "relatorios.emitir.fiscal.transferenciaprodutos",
    RELATORIOS_EMITIR_VENDAS_PRODUTOSVENDIDOS: "relatorios.emitir.vendas.produtosvendidos",
    RELATORIOS_EMITIR_VENDAS_ITENSFATURADOSVENDA: "relatorios.emitir.vendas.itensfaturadosvenda",
    RELATORIOS_EMITIR_ESTOQUE_SALDOGERAL: "relatorios.emitir.estoque.saldogeral",
    RELATORIOS_EMITIR_CONTAS_RECEBIDAS: "relatorios.emitir.financeiro.contasrecebidas",
    RELATORIOS_EMITIR_CONTAS_PAGAS: "relatorios.emitir.financeiro.contaspagas",
    RELATORIOS_EMITIR_PRECO_POR_FORNECEDOR: "relatorios.emitir.preco_por_fornecedor",
    RELATORIOS_EMITIR_LOGS_LOGS: "relatorios.emitir.logs.logs",
    RELATORIOS_EMITIR_CONFIGURACOES_PERMISSOESUSUARIOS: "relatorios.emitir.configuracoes.permissoesusuarios",
    RELATORIOS_IMPRESSORATERMICA_IMPRESSORA_LOCAL: "relatorios.impressoratermica.impressora.local",
    RELATORIOS_IMPRESSORATERMICA_IMPRESSORA_QUALQUER: "relatorios.impressoratermica.impressora.qualquer",
    RELATORIOS_EMITIR_VENDAS_ASSISTENCIATECNICA: "relatorios.emitir.vendas.assitenciatecnica",
    RELATORIOS_EMITIR_FINANCEIRO_RECIBOQUITACAO: "relatorios.emitir.financeiro.reciboquitacao",
    RELATORIOS_EMITIR_COMPRAS_ORDEMCOMPRA: "relatorios.emitir.compras.ordemcompra",
    RELATORIOS_EMITIR_VENDAS_RECIBOVENDA: "relatorios.emitir.vendas.recibovenda",
    RELATORIOS_EMITIR_VENDAS_CONTRATOVENDA: "relatorios.emitir.vendas.contratovenda",
    RELATORIOS_EMITIR_MATERIAIS_RECIBOSAIDA: "relatorios.emitir.materiais.recibosaida",
    RELATORIOS_EMITIR_VENDAS_FICHACREDIARIO: "relatorios.emitir.vendas.fichacrediario",
    RELATORIOS_EMITIR_FINANCEIRO_CARNERECEBIMENTOS: "relatorios.emitir.financeiro.carnerecebimentos",
    RELATORIOS_EMITIR_VENDAS_ORCAMENTO: "relatorios.emitir.vendas.orcamento",
    RELATORIOS_EMITIR_COMPRAS_CARNEPARCELASOC: "relatorios.emitir.compras.carneparcelasoc",
    RELATORIOS_EMITIR_PRODUTOS_LOTES: "relatorios.emitir.produtos.lotes",
    // Outros

    // Outros -> Parâmetros
    OUTROS_PARAMETROS_CONSULTAR: "outros.parametros.consultar",
    OUTROS_PARAMETROS_MANUTENCAO: "outros.parametros.manutencao",
    OUTROS_PARAMETROS_EDITARVALOR: "outros.parametros.editarvalor",
    OUTROS_PARAMETROS_PERMISSOES_PERFIL_EDITAR: "outros.parametros.permissoes.perfil.editar",
    OUTROS_PARAMETROS_EDITARORIENTACOES: "outros.parametros.editarorientacoes",

    // Outros -> Campos Personalizados
    OUTROS_CAMPOSPERSONALIZADOS_CONSULTAR: "outros.campospersonalizados.consultar",
    OUTROS_CAMPOSPERSONALIZADOS_INCLUIR: "outros.campospersonalizados.incluir",
    OUTROS_CAMPOSPERSONALIZADOS_EDITAR: "outros.campospersonalizados.editar",
    OUTROS_CAMPOSPERSONALIZADOS_EXCLUIR: "outros.campospersonalizados.excluir",

    // Outros -> Impressoras térmicas
    OUTROS_IMPRESSORATERMICA_CONSULTAR: "outros.impressoratermica.consultar",
    OUTROS_IMPRESSORATERMICA_INCLUIR: "outros.impressoratermica.incluir",
    OUTROS_IMPRESSORATERMICA_EDITAR: "outros.impressoratermica.editar",
    OUTROS_IMPRESSORATERMICA_EXCLUIR: "outros.impressoratermica.excluir",
};

export default permissoes;
