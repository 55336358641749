import MakoListagem from '@/components/MakoListagem';
import React, { useMemo } from 'react';

export const VisualizarPermissoes = ({ papel }) => {
    const colunas = [
        { field: "descricao", header: "Descrição" },
        { field: "chave", header: "Chave" },
    ]

    const aposPesquisar = useMemo(() => {
        return (data) => {
            return data[0].permissoes
        }
    }, [])

    return (
        <MakoListagem 
            colunas={colunas}
            fazerBusca={!!papel}
            aposPesquisar={aposPesquisar}
            urlPesquisa={`/pessoas/papeis-perfis/?id=${papel?.id}`}
        />
    )
}