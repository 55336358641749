import React from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { Button } from "primereact/button";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import useHttp from "@/hooks/useHttp";

export const VinculoCentroEstocagemResultadoForm = () => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPatch } = useHttp();
    const history = useHistory();
    const { state } = useLocation();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: state
            ? {
                  id: state.id,
                  centro_estocagem: state.centro_estocagem.id,
                  centro_resultado: state.centro_resultado.id,
              }
            : {
                  id: null,
                  centro_estocagem: null,
                  centro_resultado: null,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                centro_estocagem: Yup.number()
                    .required("O campo 'centro de estocagem' é obrigatório.")
                    .typeError("Informe um 'centro de estocagem' válido"),
                centro_resultado: Yup.number()
                    .required("O campo 'centro de estocagem' é obrigatório.")
                    .typeError("Informe um 'centro de estocagem' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (values.id === null) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Vinculo criado com sucesso",
                            life: 3000,
                        });
                        history.push("/produto/cadastros/vinculo-estocagem-resultado");
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPost(
                    {
                        url: "/produtos/vinculo-centros-estocagem-resultados/",
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Vinculo atualizado com sucesso",
                            life: 3000,
                        });
                        history.push("/produto/cadastros/vinculo-estocagem-resultado");
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPatch(
                    {
                        url: `/produtos/vinculo-centros-estocagem-resultados/${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <div className="card">
            <h5>Vincular produto centro de resultado a um centro de estocagem:</h5>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6">
                        <label htmlFor="centro_estocagem">Centro de estocagem: *</label>
                        <Dropdown
                            id="centro_estocagem"
                            name="centro_estocagem"
                            url="/produtos/centros-estocagem/"
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            placeholder="Selecione um centro de estocagem..."
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-6">
                        <label htmlFor="centro_resultado">Centro de resultado: *</label>
                        <Dropdown
                            id="centro_resultado"
                            name="centro_resultado"
                            url="/plano-operacional/centros-resultados/"
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            placeholder="Selecione um centro de resultado..."
                            value={formik.values.centro_resultado}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_resultado })}
                        />
                        {formik.errors.centro_resultado && (
                            <small className="p-error">{formik.errors.centro_resultado}</small>
                        )}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button type="submit" icon="pi pi-save" label="Gravar" className="p-mr-2 p-mb-2" />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={() => resetForm()}
                        />
                        <Button
                            type="reset"
                            label="Cancelar"
                            className="p-button-danger p-mb-2"
                            onClick={() => history.goBack()}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};
