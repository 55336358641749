import React, { useCallback } from "react";

import { Button } from "primereact/button";

import { Buttons } from "./buttons";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

export const Acoes = ({ dispatch, fecharModal, pendencias, setPendentes }) => {
    const { loadCaixa: handleCaixa } = useCaixaMovimento();

    const dispatcher = useCallback(
        ({ type = "", payload = {}, reloadCaixa = false }) => {
            let { formProps, ...rest } = payload;
            formProps = { ...formProps, fecharModal };
            if (reloadCaixa) formProps = { ...formProps, handleCaixa };
            dispatch({ type, payload: { ...rest, formProps } });
        },
        [dispatch, fecharModal, handleCaixa]
    );

    return (
        <div className="p-mb-2">
            <div className="mako-actions-caixa">
                <Buttons.Fechamento dispatcher={dispatcher} />
                <Buttons.Bloqueio dispatcher={dispatcher} />
                <Buttons.Sangria dispatcher={dispatcher} />
                <Buttons.Transferencia dispatcher={dispatcher} />
                <Buttons.Resumo dispatcher={dispatcher} />
            </div>
            <div className="mako-actions-caixa">
                <Button
                    label={pendencias ? "Exibir movimentações" : "Exibir pendências"}
                    onClick={() => setPendentes((prev) => !prev)}
                />
                <Buttons.Desbloquear dispatcher={dispatcher} />
                <Buttons.Suprimento dispatcher={dispatcher} />
                <Buttons.IncluirOperacao dispatcher={dispatcher} />
                <Buttons.Analitico dispatcher={dispatcher} />
            </div>
        </div>
    );
};
