import React, { useState } from "react";
import { Button } from "primereact/button";

import useToast from "@/hooks/useToast";
import useNotificacao from "@/hooks/useNotificacao";
import useHttp from "@/hooks/useHttp";

export const BotaoEscalarNotificacao = ({ notificacao }) => {
    const [loading, setLoading] = useState(false);
    const { showSuccess, showWarning, showError } = useToast();
    const { marcarMsgComoLida } = useNotificacao();
    const { httpPut } = useHttp();

    async function escalarNotificacao() {
        const handlers = {
            200: ({ data }) => {
                marcarMsgComoLida();
                showSuccess({
                    summary: "Sucesso!",
                    detail: data.msg,
                    life: 2000,
                });
            },
            400: ({ err }) =>
                showWarning({
                    summary: "Falhou...",
                    detail: err.msg,
                    life: 3000,
                }),
            404: ({ err }) =>
                showWarning({
                    summary: "Falhou...",
                    detail: err.msg,
                    life: 3000,
                }),
        };

        setLoading(true);
        await httpPut({ url: `/pessoas/escalar-notificacao/${notificacao.id}/` }, handlers);
        setLoading(false);
    }

    const handleClick = () => {
        escalarNotificacao();
    };

    return (
        <Button
            icon="pi pi-arrow-up"
            label="Escalar"
            loading={loading}
            disabled={notificacao.status !== "P"}
            className="p-button-secondary"
            onClick={handleClick}
        />
    );
};
