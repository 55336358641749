import { useCallback } from "react";

const useFormatCNPJCPF = () => {
    const limparDocumento = useCallback((documento) => {
        if (!documento) return "";
        return documento.replace(/[^\d]+/g, "");
    }, []);

    const formatDocumento = useCallback(
        (documento) => {
            documento = limparDocumento(documento);
            if (documento.length !== undefined && documento.length === 11) {
                return documento.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            }

            if (documento.length !== undefined && documento.length === 14) {
                return documento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            }

            return documento;
        },
        [limparDocumento]
    );

    return [formatDocumento, limparDocumento];
};

export default useFormatCNPJCPF;
