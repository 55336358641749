import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import classNames from "classnames";

import { Divider } from "primereact/divider";
import { Button } from "primereact/button";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";
import MakoListagem from "@/components/MakoListagem";

import { DropdownFormas } from "../../inputs";

const BASE_VALUES = {
    conta_financeira: null,
    recebido: 0,
};

const BASE_ERRORS = {
    conta_financeira: null,
};

const Component = (
    {
        values = BASE_VALUES,
        errors = BASE_ERRORS,
        formasRecebimentoRateio = [],
        handleChange = () => {},
        setFieldError = () => {},
        setFormasRecebimentoRateio = () => {},
    },
    ref
) => {
    const [valor, setValor] = useState();
    const [formaRecebimento, setFormaRecebimento] = useState(null);
    const [errorFormaRecebRateio, setErrorFormaRecebRateio] = useState(null);
    const [contaFinanceira, setContaFinanceira] = useState(null);
    const [contasFinanceiras, setContasFinanceiras] = useState([]);

    const formasRecebimentoRef = useRef(null);

    const actionBodyTemplate = (rowData, rowInfo) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => excluirRateioFormaRecebimento(rowInfo.rowIndex)}
                />
            </div>
        );
    };

    const colunas = [
        { field: "descricao", header: "Forma de recebimento" },
        { field: "valor", header: "Valor", style: { width: "30%" }, money: true },
        {
            field: "actions",
            header: "Ações",
            style: { width: "10%" },
            action: (e, i) => actionBodyTemplate(e, i),
        },
    ];

    const incluirRateioFormaRecebimento = () => {
        if (values.conta_financeira) {
            const total = formasRecebimentoRateio.reduce((total, atual) => total + atual.valor, 0);
            if (total + valor <= values.recebido) {
                let rateios = [...formasRecebimentoRateio];
                const _formaRecebimento = formasRecebimentoRef.current?.find((fr) => fr.id === formaRecebimento);
                rateios.push({ ..._formaRecebimento, valor, conta_financeira: values.conta_financeira });
                setFormasRecebimentoRateio(rateios);
                setErrorFormaRecebRateio(null);
            } else {
                setErrorFormaRecebRateio("A soma total ultrapassa o valor a ser recebido.");
            }
        } else setFieldError("conta_financeira", "O campo 'conta financeira' é obrigatório");
    };

    const excluirRateioFormaRecebimento = (index) => {
        const rateios = [...formasRecebimentoRateio];
        rateios.splice(index, 1);
        setFormasRecebimentoRateio(rateios);
    };

    const handleChangeContaFinanceira = (e) => {
        handleChange(e);
        setFormaRecebimento(null);
    };

    const aposBuscarContasBancarias = useCallback((data) => {
        setContasFinanceiras(data);
        return data;
    }, []);

    const calcularValorFaltante = useCallback(() => {
        const valorInformado = formasRecebimentoRateio.reduce((total, p) => total + p.valor, 0);
        return values.recebido - valorInformado;
    }, [values.recebido, formasRecebimentoRateio]);

    const desabilitarFormas = useMemo(() => {
        const _valor = calcularValorFaltante();
        return _valor === 0;
    }, [calcularValorFaltante]);

    useEffect(() => {
        const _valor = calcularValorFaltante();
        setValor(_valor);
    }, [calcularValorFaltante, setValor]);

    useEffect(() => {
        const matcher = !values.conta_financeira?.id ? values.conta_financeira : values.conta_financeira?.id;
        const conta = contasFinanceiras.find(({ id }) => id === matcher);
        setContaFinanceira(conta);
    }, [values.conta_financeira, contasFinanceiras]);

    useImperativeHandle(ref, () => {
        return {
            setFormaRecebimento,
            setErrorFormaRecebRateio,
            setValor,
        };
    });

    return (
        <>
            <Divider align="center">
                <b>Formas de recebimento</b>
            </Divider>
            {errorFormaRecebRateio && <small className="p-error">{errorFormaRecebRateio}</small>}
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="conta_financeira" label="Conta Financeira" obrigatorio />
                    <Dropdown
                        id="conta_financeira"
                        name="conta_financeira"
                        url={`/financeiro/contas-financeiras/?query={id,descricao}&limit=50&tipo_conta__in=1,2,3`}
                        filter
                        filterBy="descricao"
                        optionValue="id"
                        optionLabel="descricao"
                        aposBuscar={aposBuscarContasBancarias}
                        value={values.conta_financeira}
                        onChange={handleChangeContaFinanceira}
                        className={classNames({ "p-invalid": errors.conta_financeira })}
                    />
                    {errors.conta_financeira && <small className="p-error">{errors.conta_financeira}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4 p-mt-4" style={{ paddingTop: "5px" }}>
                    <DropdownFormas
                        id="forma-recebimento"
                        setObjects={(e) => (formasRecebimentoRef.current = e)}
                        contaFinanceira={contaFinanceira}
                        value={formaRecebimento}
                        onChange={(e) => setFormaRecebimento(e.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "5px" }}>
                    <div className="p-inputgroup">
                        <MakoInputMoeda id="valor" valueMoeda={valor} onChangeMoeda={(e) => setValor(e.value)} />
                        <Button
                            icon="pi pi-plus"
                            className="p-button-success"
                            onClick={() => incluirRateioFormaRecebimento()}
                            disabled={desabilitarFormas}
                        />
                    </div>
                </div>
            </div>
            <MakoListagem dadosLocal={formasRecebimentoRateio} colunas={colunas} />
        </>
    );
};

export const Formas = forwardRef(Component);
