import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoButton as Button } from "@/components/MakoButton";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import { Dialog } from "primereact/dialog";
import { VisualizarPermissoes } from "./visualizarPermissoes";
import {
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    PorcentagemFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { CHAVE_PAPEL_CHOICE } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { PageBase } from "@/components/PageBase";

export const PessoasPapeisPerfisPage = () => {
    const [url, setUrl] = useState("/pessoas/papeis-perfis/");
    const [papelPerfil, setPapelPerfil] = useState(false);
    const [show, setShow] = useState(false);
    const [filtro, setFiltro] = useState(1);
    const { showSuccess } = useToast();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const opcoesPesquisa = [
        { label: "Todos", value: 0 },
        { label: "Ativos", value: 1 },
        { label: "Inativos", value: 2 },
    ];

    const deletePapelPerfil = () => {
        showSuccess({
            summary: "Sucesso",
            detail: "Papel de perfil deletado com sucesso!",
            life: 3000,
        });
        listagemRef.current?.buscarDados();
    };

    const abrirModal = (perfil) => {
        setPapelPerfil(perfil);
        setShow(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon={MAKO_ICONS.VISUALIZAR}
                    className="p-button-rounded p-button-info"
                    onClick={() => abrirModal(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_PAPEISPERFIS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/gestao/usuarios/papeis-perfis/form",
                        state: { papeisPerfis: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_PAPEISPERFIS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/pessoas/papeis-perfis/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o papel de perfil <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O papel de perfil não pode ser excluído, verifique se
                     o mesmo está vinculado a um usuário e tente novamente."
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => deletePapelPerfil()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const filtroStatus = (status) => {
        setFiltro(status.value);

        if (status.value === 0) {
            setUrl("/pessoas/papeis-perfis/");
        } else if (status.value === 1) {
            setUrl("/pessoas/papeis-perfis/?ativo=True");
        } else {
            setUrl("/pessoas/papeis-perfis/?ativo=False");
        }
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_USUARIOS_PAPEISPERFIS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button p-button-success p-mr-2"
                to={"/gestao/usuarios/papeis-perfis/form"}
            />
            <Dropdown value={filtro} options={opcoesPesquisa} placeholder="Selecione" onChange={filtroStatus} />
        </>
    );

    const chaveFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: CHAVE_PAPEL_CHOICE,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "chave.nome",
            header: "Função",
            style: { width: "10%" },
            filter: true,
            filterElement: chaveFiltroTemplate,
            filterField: "chave",
        },
        {
            field: "max_desconto_avista",
            header: "Max. desc. avista",
            style: { width: "15%" },
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "max_desconto_encargos",
            header: "Max. desc. encargos",
            style: { width: "15%" },
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    const fecharModal = () => {
        setShow(false);
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Papéis de Perfis"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    chave: { value: "", matchMode: "equals" },
                    max_desconto_avista: { value: "", matchMode: "equals" },
                    max_desconto_encargos: { value: "", matchMode: "equals" },
                }}
            />

            <Dialog
                header={`Permissões do papel: ${papelPerfil?.nome}`}
                visible={show}
                onHide={fecharModal}
                style={{ minWidth: "60rem" }}
            >
                <VisualizarPermissoes papel={papelPerfil} />
            </Dialog>
        </PageBase>
    );
};
