import React from "react";

import { FinanceiroChavePixFormBase } from "./formBase";
import { PageBase } from "@/components/PageBase";

export const FinanceiroChavePixForm = (props) => {
    return (
        <PageBase>
            <FinanceiroChavePixFormBase
                chavePix={
                    props.location.state?.chavePix
                        ? {
                              ...props.location.state.chavePix,
                              tipo_chave: props.location.state?.chavePix.tipo_chave.id,
                          }
                        : null
                }
            />
        </PageBase>
    );
};
