import React, { createContext, useCallback, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import {
    criarListaProvisoriaParaPersistencia,
    montarObjetoParaPersistenciaDjango,
} from "@/assets/util/persistenciaDjango";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { dataToStr } from "@/assets/util/datas";
import useHttp from "@/hooks/useHttp";

const ContratoServicoContext = createContext({});

export const ContratoServicoProvider = ({ children }) => {
    const [contrato, setContrato] = useState(null);
    const [servicos, setServicos] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess } = useToast();
    const { httpGet, httpPatch, httpPost } = useHttp();

    const { state } = useLocation();

    const persistirContrato = useCallback(
        async (obj) => {
            const copy = obj ? { ...obj } : { ...contrato };
            const _contrato = {
                ...copy,
                data_finalizacao: copy.data_finalizacao
                    ? dataToStr(copy.data_finalizacao, "yyyy-MM-dd")
                    : copy.data_finalizacao,
                data_iniciacao: copy.data_iniciacao
                    ? dataToStr(copy.data_iniciacao, "yyyy-MM-dd")
                    : copy.data_iniciacao,
                data_negociacao: copy.data_negociacao
                    ? dataToStr(copy.data_negociacao, "yyyy-MM-dd")
                    : copy.data_negociacao,
                primeiro_vencimento: copy.primeiro_vencimento
                    ? dataToStr(copy.primeiro_vencimento, "yyyy-MM-dd")
                    : copy.primeiro_vencimento,
                cliente: copy.cliente?.id ? copy.cliente?.id : copy.cliente,
                servicoscontrato_set: {},
            };

            if (servicos?.length) {
                _contrato.servicoscontrato_set = montarObjetoParaPersistenciaDjango(servicos);
            }
            if (!_contrato?.id) {
                const handlers = {
                    201: ({ data }) => {
                        const { servicoscontrato_set, ...rest } = data;
                        setServicos(data.servicoscontrato_set);
                        setContrato({
                            ...rest,
                            template_rateio: rest.template_rateio.id,
                        });
                        showSuccess({
                            summary: "Sucesso, :)",
                            detail: "Sucesso ao cadastrar.",
                            life: 3000,
                        });
                    },
                };

                showLoading();
                await httpPost({ url: `/servicos/contrato-servico/`, body: _contrato }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: ({ data }) => {
                        const { servicoscontrato_set, ...rest } = data;
                        setServicos(data.servicoscontrato_set);
                        setContrato({
                            ...rest,
                            template_rateio: rest.template_rateio.id,
                        });
                        showSuccess({
                            summary: "Sucesso, :)",
                            detail: "Sucesso ao editar.",
                            life: 3000,
                        });
                    },
                };

                showLoading();
                await httpPatch({ url: `/servicos/contrato-servico/${_contrato.id}/`, body: _contrato }, handlers);
                hideLoading();
            }
        },
        [contrato, servicos, showLoading, hideLoading, httpPatch, httpPost, showSuccess]
    );

    const updateContrato = useCallback(
        (obj, persistir = true) => {
            if (persistir) {
                persistirContrato(obj);
            } else {
                setContrato((prev) => ({ ...prev, ...obj }));
            }
        },
        [persistirContrato, setContrato]
    );

    const updateServicos = useCallback(
        (objeto, operacao) => {
            const _data = criarListaProvisoriaParaPersistencia(servicos, objeto, operacao);
            setServicos(_data);
        },
        [servicos, setServicos]
    );

    const resetServicos = useCallback(() => {
        setServicos([]);
    }, [setServicos]);

    const atualizarValorContrato = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                setContrato({ ...contrato, valor_total: data.valor });
            },
        };

        showLoading();
        await httpGet({ url: `/servicos/recalcular-valor-contrato/${contrato.id}` }, handlers);
        hideLoading();
    }, [showLoading, hideLoading, setContrato, contrato, httpGet]);

    const prepararEdicao = useCallback(() => {
        if (state?.contrato) {
            const { servicoscontrato_set, ...data } = state.contrato;
            setServicos(data.servicoscontrato_set);
            setContrato({
                ...data,
                template_rateio: data.template_rateio.id,
            });
        }
    }, [state, setServicos, setContrato]);

    useEffect(() => {
        prepararEdicao();
    }, [prepararEdicao]);

    return (
        <ContratoServicoContext.Provider
            value={{
                contrato,
                servicos,
                updateContrato,
                resetServicos,
                persistirContrato,
                updateServicos,
                atualizarValorContrato,
            }}
        >
            {children}
        </ContratoServicoContext.Provider>
    );
};

export default ContratoServicoContext;
