import { OperacionalCentroResultadosPage } from "@/pages/Operacional/PlanoOperacional/CentroResultados";
import { OperacionalCentroResultadosForm } from "@/pages/Operacional/PlanoOperacional/CentroResultados/form";
import { OperacionalProjetosPage } from "@/pages/Operacional/PlanoOperacional/Projetos";
import { OperacionalProjetosForm } from "@/pages/Operacional/PlanoOperacional/Projetos/form";
import { OperacionalFasesProjetosForm } from "@/pages/Operacional/PlanoOperacional/FasesProjetos/form";
import { OperacionalFasesProjetosPage } from "@/pages/Operacional/PlanoOperacional/FasesProjetos";
import { OperacionalSetoresPage } from "@/pages/Operacional/PlanoOperacional/Setores";
import { OperacionalSetoresForm } from "@/pages/Operacional/PlanoOperacional/Setores/form";

export const planoOperacionalRoutes = [
    { path: "/operacional/plano-operacional/centros-resultados", component: OperacionalCentroResultadosPage },
    { path: "/operacional/plano-operacional/centros-resultados/form", component: OperacionalCentroResultadosForm },
    { path: "/operacional/plano-operacional/projetos", component: OperacionalProjetosPage },
    { path: "/operacional/plano-operacional/projetos/form", component: OperacionalProjetosForm },
    { path: "/operacional/plano-operacional/fases-projetos", component: OperacionalFasesProjetosPage },
    { path: "/operacional/plano-operacional/fases-projetos/form", component: OperacionalFasesProjetosForm },
    { path: "/operacional/plano-operacional/setores", component: OperacionalSetoresPage },
    { path: "/operacional/plano-operacional/setores/form", component: OperacionalSetoresForm },
];
