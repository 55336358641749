import React, { useRef, useState, useCallback } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import permissoes from "@/assets/constants/permissoes";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoMultiSelect } from "@/components/MakoInputs";
import useHttp from "@/hooks/useHttp";

const url = "/pessoas/papeis-perfis/";

export const PermissoesForm = ({ state: permissaoPerfil }) => {
    const [permissoesSelecionadas, setPermissoesSelecionadas] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [listaPermissoes, setListaPermissoes] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpGet, httpPatch } = useHttp();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const listarPermissoesUsuario = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                setListaPermissoes(data.results[0].permissoes);
            },
        };
        showLoading();
        await httpGet({ url: `/pessoas/papeis-perfis/?limit=10000&id=${permissaoPerfil.id}` }, handlers);
        hideLoading();
    }, [showLoading, hideLoading, permissaoPerfil, httpGet]);

    async function handleAdicionar() {
        try {
            const body = {
                ...permissaoPerfil,
                permissoes: { add: permissoesSelecionadas },
            };

            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Permissão adicionada com sucesso!",
                        life: 1500,
                    });

                    setPermissoesSelecionadas(null);
                    listarPermissoesUsuario();
                    listagemRef.current?.buscarDados();
                },
            };
            showLoading();
            await httpPatch({ url: `${url}${permissaoPerfil.id}/`, body }, handlers);
            hideLoading();
        } catch (error) {
            showError();
            hideLoading();
        }
    }

    const removePermissao = async (permissoes) => {
        const body = {
            ...permissaoPerfil,
            permissoes: { remove: permissoes },
        };
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Permissão removida com sucesso!",
                    life: 3000,
                });
                setPermissoesSelecionadas(null);
                listarPermissoesUsuario();
                listagemRef.current?.buscarDados();
            },
        };
        showLoading();
        await httpPatch({ url: `${url}${permissaoPerfil.id}/`, body }, handlers);
        hideLoading();
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_PAPEISPERFIS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.DELETE}
                    className="p-button-rounded p-button-danger"
                    onClick={() => removePermissao([rowData.id])}
                    tooltip="Remover permissão"
                    tooltipOptions={{ position: "left" }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const confirmarDelete = () => {
        setPermissoesSelecionadas(listaPermissoes.map((e) => e.id));
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const colunas = [
        { field: "chave", header: "Chave" },
        { field: "descricao", header: "Descrição" },
        {
            field: "action",
            header: "Ações",
            className: "p-text-center",
            action: (e) => actionBodyTemplate(e),
            style: { width: "6%" },
        },
    ];

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-fluid p-formgrid p-grid p-formgroup-inline">
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="permissao" label="Permissão" />
                        <MakoMultiSelect
                            id="permissao"
                            name="permissao"
                            placeholder="Selecione uma permissão"
                            url="/pessoas/permissoes-perfis/?limit=10000&query={id, descricao}"
                            onChange={(e) => setPermissoesSelecionadas(e.value)}
                            value={permissoesSelecionadas}
                            filter
                            filterBy="descricao"
                            showClear
                            optionLabel="descricao"
                            optionValue="id"
                            autoComplete="off"
                        />
                    </div>
                </div>
                <MakoActionsButtons className="p-mb-2">
                    <Button
                        label="Adicionar"
                        icon={MAKO_ICONS.NOVO}
                        className="p-button-success"
                        onClick={() => handleAdicionar()}
                        disabled={permissoes?.length === 0}
                    />
                    <Button
                        label="Excluir permissões"
                        icon={MAKO_ICONS.DELETE}
                        className="p-button-danger"
                        onClick={() => confirmarDelete()}
                    />
                </MakoActionsButtons>
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    dadosLocal={listaPermissoes}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
                <ConfirmDialog
                    visible={deleteDialog}
                    onHide={esconderDeleteDialog}
                    header="Confirmação"
                    message={
                        <span>
                            Deseja realmente remover <b>TODAS</b> as permissão?
                        </span>
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={() => removePermissao(permissoesSelecionadas)}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={esconderDeleteDialog}
                    rejectLabel="Não"
                />
            </div>
        </div>
    );
};
