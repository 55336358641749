import React, { useCallback } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from "classnames";

import { InputText } from "primereact/inputtext";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoButton } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { TIPO_CHOICE_LOTE_SERIAL } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { dataToStr } from "@/assets/util/datas";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/produtos/lotes-sku/";

const INITIAL_VALUES = {
    lote_serial: "",
    item_entrada: null,
    sku: null,
    fabricacao: null,
    vencimento: null,
    quantidade: 0,
    enderecamento: "",
    unico: false,
    complemento: "",
    bloqueado_vender: false,
};

export const Form = ({ lote, cancelCallback = () => {}, successCallback = () => {}, controleBloqueio = false }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost, httpPatch, httpGet } = useHttp();
    const { showSuccess, showError } = useToast();

    const { setFieldValue, setValues, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: !lote ? INITIAL_VALUES : { ...INITIAL_VALUES, ...(lote || {}) },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values, helpers) {
        try {
            showLoading();
            const formSchema = Yup.object().shape({
                sku: Yup.object().required("O campo 'produto' é obrigtório").typeError("Informe um 'produto' válido"),
                lote_serial: Yup.string()
                    .max(100, "Quantidade máxima de caracteres atingida: 100")
                    .test({
                        test: async (e) => await verificarDuplicidade(e),
                        message: "O lote informado já existe",
                    })
                    .typeError("Informe uma 'lote serial' válido"),
                enderecamento: Yup.string()
                    .max(100, "Quantidade máxima de caracteres atingida: 20")
                    .typeError("Informe uma 'endereçamento' válido"),
                complemento: Yup.string()
                    .max(100, "Quantidade máxima de caracteres atingida: 20")
                    .typeError("Informe uma 'complemento' válido"),
                vencimento: Yup.date().nullable().default(null).typeError("Informe um 'vencimento' válido"),
                fabricacao: Yup.date().when("vencimento", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .nullable()
                        .default(null)
                        .max(
                            values.vencimento || new Date(),
                            "O campo 'fabricação' não pode ser posterior ao vencimento"
                        )
                        .typeError("Informe uma 'fabricação' válida"),
                    otherwise: Yup.date().nullable(),
                }),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values?.id) {
                const handlers = {
                    201: ({ data }) => {
                        successCallback(data);
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Lote cadastrado com sucesso.",
                            life: 1500,
                        });
                    },
                };

                await httpPost(
                    {
                        url: BASE_URL,
                        body: {
                            ...values,
                            sku: values.sku?.id || values.sku,
                            vencimento: values?.vencimento && dataToStr(values.vencimento, "yyyy-MM-dd"),
                            fabricacao: values?.fabricacao && dataToStr(values.fabricacao, "yyyy-MM-dd"),
                        },
                    },
                    handlers
                );
            } else {
                const handlers = {
                    200: ({ data }) => {
                        successCallback(data);
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Lote alterado com sucesso.",
                            life: 1500,
                        });
                    },
                };
                await httpPatch(
                    {
                        url: `${BASE_URL}${values.id}/`,
                        body: {
                            ...values,
                            sku: values.sku?.id || values.sku,
                            vencimento: values?.vencimento && dataToStr(values.vencimento, "yyyy-MM-dd"),
                            fabricacao: values?.fabricacao && dataToStr(values.fabricacao, "yyyy-MM-dd"),
                        },
                    },
                    handlers
                );
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                helpers.setErrors(errorMessages);
            } else showError();
        } finally {
            hideLoading();
        }
    }

    const verificarDuplicidade = useCallback(
        async (lote) => {
            let ok = false;
            if (!controleBloqueio) {
                const handlers = {
                    200: ({ data }) => {
                        ok = data.results.length === 0;
                    },
                };
                await httpGet({ url: `${BASE_URL}?lote_serial=${lote}&limit=1` }, handlers);
            } else {
                ok = true;
            }
            return ok;
        },
        [httpGet, controleBloqueio]
    );

    const handleBloqueio = useCallback(() => {
        setFieldValue("bloqueado_vender", !formik.values.bloqueado_vender);
        formik.handleSubmit();
    }, [formik, setFieldValue]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid ">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="sku" label="Produto" obrigatorio />
                    <MakoBuscaSkuPersonalizada
                        skuValue={formik.values.sku}
                        skuChange={(e) => {
                            setFieldValue("sku", e);
                            setFieldValue("serie", e?.lote_serial);
                        }}
                        skuError={formik.errors.sku}
                        obrigatorio
                        apenasComLote
                        exibirVisualizacaoSku
                        className={classNames({ "p-invalid": formik.errors.sku })}
                        disabledBusca={controleBloqueio || !!lote?.id}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="serie" label="Tipo de lote / serial" />
                    <Dropdown
                        id="serie"
                        name="serie"
                        value={formik.values.serie}
                        options={TIPO_CHOICE_LOTE_SERIAL}
                        placeholder=""
                        dropdownIcon=" "
                        optionValue="value"
                        optionLabel="label"
                        disabled
                        className={classNames({ "p-invalid": formik.errors.serie })}
                    />
                    {formik.errors.serie && <small className="p-error">{formik.errors.serie}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="lote_serial" label="N° do lote / N° de série" obrigatorio />
                    <InputText
                        id="lote_serial"
                        name="lote_serial"
                        value={formik.values.lote_serial}
                        onInput={formik.handleChange}
                        disabled={controleBloqueio}
                        className={classNames({ "p-invalid": formik.errors.lote_serial })}
                    />
                    {formik.errors.lote_serial && <small className="p-error">{formik.errors.lote_serial}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="vencimento" label="Data do vencimento" />
                    <MakoCalendar
                        id="vencimento"
                        name="vencimento"
                        valueCalendar={formik.values.vencimento}
                        onChange={formik.handleChange}
                        disabled={controleBloqueio}
                        className={classNames({ "p-invalid": formik.errors.vencimento })}
                    />
                    {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="fabricacao" label="Data da fabricação" />
                    <MakoCalendar
                        id="fabricacao"
                        name="fabricacao"
                        valueCalendar={formik.values.fabricacao}
                        onChange={formik.handleChange}
                        disabled={controleBloqueio}
                        maxDate={formik.values.vencimento}
                        className={classNames({ "p-invalid": formik.errors.fabricacao })}
                    />
                    {formik.errors.fabricacao && <small className="p-error">{formik.errors.fabricacao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="enderecamento" label="Endereçamento" />
                    <InputText
                        id="enderecamento"
                        name="enderecamento"
                        value={formik.values.enderecamento}
                        onInput={formik.handleChange}
                        maxLength={20}
                        disabled={controleBloqueio}
                        className={classNames({ "p-invalid": formik.errors.enderecamento })}
                    />
                    {formik.errors.enderecamento && <small className="p-error">{formik.errors.enderecamento}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="complemento" label="Complemento" />
                    <InputText
                        id="complemento"
                        name="complemento"
                        value={formik.values.complemento}
                        onInput={formik.handleChange}
                        maxLength={20}
                        disabled={controleBloqueio}
                        className={classNames({ "p-invalid": formik.errors.complemento })}
                    />
                    {formik.errors.complemento && <small className="p-error">{formik.errors.complemento}</small>}
                </div>
            </div>
            <CamposObrigatorios />
            <MakoActionsButtons className="p-jc-end">
                <MakoButton
                    icon={MAKO_ICONS.CANCEL}
                    label="Cancelar"
                    className="p-button p-button-danger"
                    type="reset"
                    loading={loading}
                    onClick={cancelCallback}
                />
                {!controleBloqueio ? (
                    <MakoButton
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        className="p-button p-button-info"
                        type="submit"
                        loading={loading}
                    />
                ) : (
                    <MakoButton
                        icon={lote?.bloqueado_vender ? MAKO_ICONS.ABERTO : MAKO_ICONS.BLOQUEADO}
                        label={lote?.bloqueado_vender ? "Desbloquear venda" : "Bloquear venda"}
                        className={classNames("p-button", {
                            "p-button-info": !lote?.bloqueado_vender,
                            "p-button-success": lote?.bloqueado_vender,
                        })}
                        type="button"
                        onClick={handleBloqueio}
                        loading={loading}
                    />
                )}
            </MakoActionsButtons>
        </form>
    );
};
