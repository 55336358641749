import React, { useState, useCallback, useEffect, useRef, memo } from "react";
import { TreeSelect } from "primereact/treeselect";
import { Button } from "primereact/button";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const Componente = ({ versaoId, showClear, setObjects, filtros = {}, tituloSelecionavel = false, ...props }) => {
    const [planos, setPlanos] = useState([]);
    const { showWarning } = useToast();
    const { httpGet } = useHttp();

    const percorreListaSelecionavel = useCallback(
        (lista) => {
            lista.forEach((l) => {
                let label = `${l.data.codigo} - ${l.data.descricao}`;
                if (l.children?.length > 0) l.selectable = false;
                else l.selectable = true;
                if (!tituloSelecionavel && l.data?.conta_titulo) {
                    l.selectable = false;
                    label = <b>{label}</b>;
                }
                l.label = label;
                if (l.children) percorreListaSelecionavel(l.children);
            });

            return lista;
        },
        [tituloSelecionavel]
    );

    const localizaPlano = useCallback((lista, key) => {
        let plano = null;
        for (let x = 0; x < lista.length; x++) {
            if (lista[x].key === key) return lista[x].data;
            if (lista[x].children) plano = localizaPlano(lista[x].children, key);
            if (plano) break;
        }
        return plano;
    }, []);

    const listarPlanosHierarquicos = useCallback(async () => {
        const with_results = typeof setObjects === "function";
        let url = "";
        let _filtros = [`with_results=${with_results}`];
        if (versaoId) url = `/financeiro/planos-contas-contabeis-estruturas/?id_versao=${versaoId}`;
        if (url) {
            for (const [k, v] of Object.entries(filtros)) {
                _filtros.push(`${k}=${v}`);
            }
            url = `${url}&${_filtros.join("&")}`;

            const handlers = {
                200: ({ data }) => {
                    setPlanos(percorreListaSelecionavel(!with_results ? data : data.hierarquico));
                    if (with_results) setObjects(data.results);
                },
            };

            await httpGet({ url }, handlers);
        } else {
            showWarning({
                summary: "Versão não informada",
                detail: "Por favor, informe um versão antes de buscar os planos.",
                life: 3000,
            });
        }
    }, [versaoId, setObjects, filtros, percorreListaSelecionavel, showWarning, httpGet]);

    useEffect(() => {
        listarPlanosHierarquicos();
    }, [listarPlanosHierarquicos]);

    const limparSelecao = (e) => {
        if (props?.onChange) {
            if (props?.descricao && props?.id) {
                props.onChange(
                    {
                        ...e,
                        target: {
                            descricao: props?.descricao,
                            codigo: props?.codigo,
                            id: props?.id,
                        },
                    },
                    null
                );
            } else {
                props.onChange(e, null);
            }
        }
    };

    const onChangePlano = (e) => {
        if (props.onChange) props.onChange(e);
    };

    return (
        <>
            <div className="p-inputgroup">
                <TreeSelect
                    placeholder={props?.placeholder || "Selecione um plano de contas"}
                    filter
                    filterBy="data.nome"
                    emptyMessage="Nenhuma plano de contas encontrado."
                    options={planos}
                    {...props}
                    onChange={onChangePlano}
                />
                {showClear && (
                    <Button
                        type="button"
                        icon="pi pi-times"
                        onClick={(e) => limparSelecao(e)}
                        className="p-button-secondary"
                    />
                )}
            </div>
        </>
    );
};

export const MakoDropdownPlanosContasContabeis = memo(Componente);
