import React, { useCallback, useImperativeHandle, useState, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { SIM_NAO_BOOLEAN, TIPO_MODALIDADE_ST_CHOICE } from "@/assets/constants/constants";
import useEmpresa from "@/hooks/useEmpresa";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { Divider, SelectButton } from "primereact";
import useHttp from "@/hooks/useHttp";

const Modal = ({ operacaoFiscal, onGravar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [sku, setSku] = useState(null);
    const { empresaSelecionadaId } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPatch, httpPost } = useHttp();

    const formikBr = useFormik({
        initialValues: {
            define_ipi: false,
            aliquota_ipi: 0,
            reducao_base_ipi: 0,
            define_pis: false,
            aliquota_pis: 0,
            aliquota_cofins: 0,
            reducao_base_pis: 0,
            define_cofins: false,
            reducao_base_cofins: 0,
            operacao_fiscal: null,
            cst_ipi: null,
            cst_pis: null,
            cst_cofins: null,
            enquadramento_ipi: null,
        },
    });

    const formikUf = useFormik({
        initialValues: {
            aliquota_icms_origem: 0,
            aliquota_icms_destino: 0,
            aliquota_fcp_origem: 0,
            aliquota_fcp_destino: 0,
            reducao_base_icms: 0,
            diferimento_icms: 0,
            reducao_base_st: 0,
            tributado_por_st: false,
            quantidade_recebida: 0,
            modalidade_contribuinte: null,
            cst_icms: null,
            mva: null,
            uf_origem: null,
            uf_destino: null,
            define_fcp: false,
            fcp: false,
            percentual_fcp: 0,
            fcp_diferimento: false,
            percentual_diferimento: 0,
            fcp_subst: false,
            percentual_sub_st: 0,
            fcp_retido_st: false,
            percentual_retido_st: 0,
            fcp_consumidor_final: false,
            aliq_consumidor_final: 0,
        },
    });

    async function handleSubmit() {
        try {
            const { id } = operacaoFiscal;

            const formSchema = Yup.object().shape({
                cst_icms: Yup.number().required("O campo é obrigatório."),
            });

            await formSchema.validate(
                {
                    ...formikUf.values,
                    ...formikBr.values,
                },
                { abortEarly: false }
            );

            const _BR = {
                ...formikBr.values,
                operacao_fiscal: id,
            };

            const _UF = {
                ...formikUf.values,
                operacao_fiscal: id,
            };

            if (!(formikBr.values.id && formikUf.values.id)) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Item adicionado com sucesso!",
                            life: 1500,
                        });
                        if (typeof onGravar === "function") onGravar();
                        fecharModal();
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: "/fiscal/itens-operacoes-fiscais/",
                        body: {
                            sku: sku?.id,
                            operacao_fiscal: id,
                            skuoperacaofiscalbr_set: {
                                create: [_BR],
                            },
                            skuoperacaofiscaluf_set: {
                                create: [_UF],
                            },
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Item alterado com sucesso!",
                            life: 1500,
                        });
                        if (typeof onGravar === "function") onGravar();
                        fecharModal();
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `/fiscal/itens-operacoes-fiscais/${formikBr.values.id}/`,
                        body: {
                            sku: sku?.id,
                            skuoperacaofiscalbr_set: {
                                update: { [formikBr.values.id]: _BR },
                            },
                            skuoperacaofiscaluf_set: {
                                update: { [formikUf.values.id]: _UF },
                            },
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikUf.setErrors(errorMessages);
                formikBr.setErrors(errorMessages);
            } else showError();
        }
    }

    const abrirModal = (sku, br, uf) => {
        if (sku) setSku(sku);
        if (br) formikBr.setValues(br);
        if (uf) formikUf.setValues(uf);
        setVisible(true);
    };

    const fecharModal = () => {
        formikBr.resetForm();
        formikUf.resetForm();
        setSku(null);
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const opcaoCSTTemplate = (option) => {
        return <span>{`${option.codigo} - ${option.descricao}`}</span>;
    };

    const cstSelecionadoTemplate = (option, props) => {
        if (option) return opcaoCSTTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const formatarMVA = useCallback((results) => {
        return results.map((mva) => ({
            value: mva.id,
            label: `${mva.descricao} ${mva.valor}% ${mva.ajustado ? "(ajustado)" : ""}`,
        }));
    }, []);

    const formatarEnquadramento = useCallback((results) => {
        return results.map((ipi) => {
            return {
                ...ipi,
                label: `${ipi.codigo} - ${ipi.descricao.slice(0, 60)}${ipi.descricao.length > 60 ? "..." : ""}`,
            };
        });
    }, []);

    const skuSelecionado = sku instanceof Object;

    return (
        <Dialog
            header={
                sku?.descricao_reduzida ? `${sku.codigo} - ${sku.descricao_reduzida}` : "Adicionar produto / mercadoria"
            }
            visible={visible}
            onHide={fecharModal}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", display: "block" }}
        >
            {!(formikBr.values?.id && formikUf.values?.id) && (
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <MakoBuscaSkuPersonalizada skuValue={sku} skuChange={(e) => setSku(e)} />
                    </div>
                </div>
            )}
            {skuSelecionado && (
                <div>
                    <Divider align="center">
                        <b>Define</b>
                    </Divider>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="define_pis">CST PIS</label>
                            <SelectButton
                                id="define_pis"
                                name="define_pis"
                                value={formikBr.values.define_pis}
                                onChange={formikBr.handleChange}
                                optionValue="id"
                                optionLabel="label"
                                options={SIM_NAO_BOOLEAN}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="define_cofins">CST Cofins</label>
                            <SelectButton
                                id="define_cofins"
                                name="define_cofins"
                                value={formikBr.values.define_cofins}
                                onChange={formikBr.handleChange}
                                optionValue="id"
                                optionLabel="label"
                                options={SIM_NAO_BOOLEAN}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="define_ipi">CST IPI</label>
                            <SelectButton
                                id="define_ipi"
                                name="define_ipi"
                                value={formikBr.values.define_ipi}
                                onChange={formikBr.handleChange}
                                optionValue="id"
                                optionLabel="label"
                                options={SIM_NAO_BOOLEAN}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="define_fcp">CST FCP</label>
                            <SelectButton
                                id="define_fcp"
                                name="define_fcp"
                                value={formikUf.values.define_fcp}
                                onChange={formikUf.handleChange}
                                optionValue="id"
                                optionLabel="label"
                                options={SIM_NAO_BOOLEAN}
                            />
                        </div>
                    </div>
                    <Divider align="center">
                        <b>Tributos</b>
                    </Divider>
                </div>
            )}
            {skuSelecionado && (
                <TabView className="tabview-custom">
                    <TabPanel header="Imposto Estadual" leftIcon="pi pi-fw pi-book">
                        <form onSubmit={formikUf.handleSubmit}>
                            <TabView className="tabview-custom">
                                <TabPanel header="ICMS">
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-12">
                                            <label htmlFor="cst_icms">CST ICMS</label>
                                            <Dropdown
                                                id="cst_icms"
                                                name="cst_icms"
                                                url={`/fiscal/cst-icms?limit=10000&empresa=${empresaSelecionadaId}&query={id,codigo,descricao}`}
                                                placeholder="Selecione um CST ICMS"
                                                filter
                                                filterBy="label"
                                                optionValue="id"
                                                optionLabel="descricao"
                                                valueTemplate={cstSelecionadoTemplate}
                                                itemTemplate={opcaoCSTTemplate}
                                                value={formikUf.values.cst_icms}
                                                onChange={formikUf.handleChange}
                                                className={classNames({ "p-invalid": formikUf.errors.cst_icms })}
                                            />
                                            {formikUf.errors.cst_icms && (
                                                <small className="p-error">{formikUf.errors.cst_icms}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="aliquota_icms_origem">Alíq. ICMS origem</label>
                                            <MakoInputPercent
                                                id="aliquota_icms_origem"
                                                name="aliquota_icms_origem"
                                                casasDecimais={2}
                                                value={formikUf.values.aliquota_icms_origem}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikUf.errors.aliquota_icms_origem,
                                                })}
                                            />
                                            {formikUf.errors.aliquota_icms_origem && (
                                                <small className="p-error">
                                                    {formikUf.errors.aliquota_icms_origem}
                                                </small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="aliquota_icms_destino">Alíq. ICMS destino</label>
                                            <MakoInputPercent
                                                id="aliquota_icms_destino"
                                                name="aliquota_icms_destino"
                                                casasDecimais={2}
                                                value={formikUf.values.aliquota_icms_destino}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikUf.errors.aliquota_icms_destino,
                                                })}
                                            />
                                            {formikUf.errors.aliquota_icms_destino && (
                                                <small className="p-error">
                                                    {formikUf.errors.aliquota_icms_destino}
                                                </small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="reducao_base_icms">Redução de base ICMS</label>
                                            <MakoInputPercent
                                                id="reducao_base_icms"
                                                name="reducao_base_icms"
                                                casasDecimais={2}
                                                value={formikUf.values.reducao_base_icms}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikUf.errors.reducao_base_icms,
                                                })}
                                            />
                                            {formikUf.errors.reducao_base_icms && (
                                                <small className="p-error">{formikUf.errors.reducao_base_icms}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="reducao_base_st">Redução base ST.</label>
                                            <MakoInputPercent
                                                id="reducao_base_st"
                                                name="reducao_base_st"
                                                casasDecimais={2}
                                                value={formikUf.values.reducao_base_st}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({ "p-invalid": formikUf.errors.reducao_base_st })}
                                            />
                                            {formikUf.errors.reducao_base_st && (
                                                <small className="p-error">{formikUf.errors.reducao_base_st}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="diferimento_icms">Diferimento ICMS</label>
                                            <MakoInputPercent
                                                id="diferimento_icms"
                                                name="diferimento_icms"
                                                casasDecimais={2}
                                                value={formikUf.values.diferimento_icms}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikUf.errors.diferimento_icms,
                                                })}
                                            />
                                            {formikUf.errors.diferimento_icms && (
                                                <small className="p-error">{formikUf.errors.diferimento_icms}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-5">
                                            <label htmlFor="modalidade_contribuinte">Modalidade do contribuinte</label>
                                            <Dropdown
                                                id="modalidade_contribuinte"
                                                name="modalidade_contribuinte"
                                                placeholder="Selecione uma modalidade"
                                                showClear
                                                options={TIPO_MODALIDADE_ST_CHOICE}
                                                filter
                                                filterBy="label"
                                                optionValue="id"
                                                optionLabel="label"
                                                value={formikUf.values.modalidade_contribuinte}
                                                onChange={formikUf.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikUf.errors.modalidade_contribuinte,
                                                })}
                                            />
                                            {formikUf.errors.modalidade_contribuinte && (
                                                <small className="p-error">
                                                    {formikUf.errors.modalidade_contribuinte}
                                                </small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4">
                                            <label htmlFor="mva">MVA</label>
                                            <Dropdown
                                                id="mva"
                                                name="mva"
                                                buscar={!!formikUf.values.ncm}
                                                url={`/fiscal/mva?limit=100&sku_id=${sku?.id}`}
                                                aposBuscar={formatarMVA}
                                                filter
                                                filterBy="label"
                                                value={formikUf.values.mva}
                                                onChange={formikUf.handleChange}
                                                className={classNames({ "p-invalid": formikUf.errors.mva })}
                                            />
                                            {formikUf.errors.mva && (
                                                <small className="p-error">{formikUf.errors.mva}</small>
                                            )}
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="FCP" disabled={!formikUf.values.define_fcp}>
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="aliquota_fcp_origem">Alíquota FCP origem</label>
                                            <MakoInputPercent
                                                id="aliquota_fcp_origem"
                                                name="aliquota_fcp_origem"
                                                casasDecimais={2}
                                                value={formikUf.values.aliquota_fcp_origem}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikUf.errors.aliquota_fcp_origem,
                                                })}
                                            />
                                            {formikUf.errors.aliquota_fcp_origem && (
                                                <small className="p-error">{formikUf.errors.aliquota_fcp_origem}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="aliquota_fcp_destino">Alíquota FCP destino</label>
                                            <MakoInputPercent
                                                id="aliquota_fcp_destino"
                                                name="aliquota_fcp_destino"
                                                casasDecimais={2}
                                                value={formikUf.values.aliquota_fcp_destino}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikUf.errors.aliquota_fcp_destino,
                                                })}
                                            />
                                            {formikUf.errors.aliquota_fcp_destino && (
                                                <small className="p-error">
                                                    {formikUf.errors.aliquota_fcp_destino}
                                                </small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="percentual_fcp">% FCP</label>
                                            <MakoInputPercent
                                                id="percentual_fcp"
                                                name="percentual_fcp"
                                                casasDecimais={2}
                                                value={formikUf.values.percentual_fcp}
                                                disabled={!formikUf.values.fcp}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({ "p-invalid": formikUf.errors.percentual_fcp })}
                                            />
                                            {formikUf.errors.percentual_fcp && (
                                                <small className="p-error">{formikUf.errors.percentual_fcp}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="fcp">FCP</label>
                                            <SelectButton
                                                id="fcp"
                                                name="fcp"
                                                value={formikUf.values.fcp}
                                                onChange={formikUf.handleChange}
                                                optionValue="id"
                                                optionLabel="label"
                                                options={SIM_NAO_BOOLEAN}
                                            />
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="percentual_diferimento">% FCP Diferimento</label>
                                            <MakoInputPercent
                                                id="percentual_diferimento"
                                                name="percentual_diferimento"
                                                casasDecimais={2}
                                                value={formikUf.values.percentual_diferimento}
                                                disabled={!formikUf.values.fcp_diferimento}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikUf.errors.percentual_diferimento,
                                                })}
                                            />
                                            {formikUf.errors.percentual_diferimento && (
                                                <small className="p-error">
                                                    {formikUf.errors.percentual_diferimento}
                                                </small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="fcp_diferimento">FCP Diferimento</label>
                                            <SelectButton
                                                id="fcp_diferimento"
                                                name="fcp_diferimento"
                                                value={formikUf.values.fcp_diferimento}
                                                onChange={formikUf.handleChange}
                                                optionValue="id"
                                                optionLabel="label"
                                                options={SIM_NAO_BOOLEAN}
                                            />
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="percentual_sub_st">% FCP Sub. ST</label>
                                            <MakoInputPercent
                                                id="percentual_sub_st"
                                                name="percentual_sub_st"
                                                casasDecimais={2}
                                                value={formikUf.values.percentual_sub_st}
                                                disabled={!formikUf.values.fcp_subst}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikUf.errors.percentual_sub_st,
                                                })}
                                            />
                                            {formikUf.errors.percentual_sub_st && (
                                                <small className="p-error">{formikUf.errors.percentual_sub_st}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="fcp_subst">FCP Sub. ST</label>
                                            <SelectButton
                                                id="fcp_subst"
                                                name="fcp_subst"
                                                value={formikUf.values.fcp_subst}
                                                onChange={formikUf.handleChange}
                                                optionValue="id"
                                                optionLabel="label"
                                                options={SIM_NAO_BOOLEAN}
                                            />
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="percentual_retido_st">% FCP Retido ST</label>
                                            <MakoInputPercent
                                                id="percentual_retido_st"
                                                name="percentual_retido_st"
                                                casasDecimais={2}
                                                value={formikUf.values.percentual_retido_st}
                                                disabled={!formikUf.values.fcp_retido_st}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikUf.errors.percentual_retido_st,
                                                })}
                                            />
                                            {formikUf.errors.percentual_retido_st && (
                                                <small className="p-error">
                                                    {formikUf.errors.percentual_retido_st}
                                                </small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="fcp_retido_st">FCP Retido ST</label>
                                            <SelectButton
                                                id="fcp_retido_st"
                                                name="fcp_retido_st"
                                                value={formikUf.values.fcp_retido_st}
                                                onChange={formikUf.handleChange}
                                                optionValue="id"
                                                optionLabel="label"
                                                options={SIM_NAO_BOOLEAN}
                                            />
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="aliq_consumidor_final">% FCP Consumidor final</label>
                                            <MakoInputPercent
                                                id="aliq_consumidor_final"
                                                name="aliq_consumidor_final"
                                                casasDecimais={2}
                                                value={formikUf.values.aliq_consumidor_final}
                                                disabled={!formikUf.values.fcp_consumidor_final}
                                                onValueChange={formikUf.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikUf.errors.aliq_consumidor_final,
                                                })}
                                            />
                                            {formikUf.errors.aliq_consumidor_final && (
                                                <small className="p-error">
                                                    {formikUf.errors.aliq_consumidor_final}
                                                </small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="fcp_consumidor_final">FCP Consumidor final</label>
                                            <SelectButton
                                                id="fcp_consumidor_final"
                                                name="fcp_consumidor_final"
                                                value={formikUf.values.fcp_consumidor_final}
                                                onChange={formikUf.handleChange}
                                                optionValue="id"
                                                optionLabel="label"
                                                options={SIM_NAO_BOOLEAN}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>
                        </form>
                    </TabPanel>
                    <TabPanel header="Imposto Federal" leftIcon="pi pi-fw pi-book">
                        <form onSubmit={formikBr.handleSubmit}>
                            <TabView className="tabview-custom">
                                <TabPanel header="PIS" disabled={!formikBr.values.define_pis}>
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-12">
                                            <label htmlFor="cst_pis">CST PIS</label>
                                            <Dropdown
                                                id="cst_pis"
                                                name="cst_pis"
                                                url={`/fiscal/cst-pis?limit=10000&empresa=${empresaSelecionadaId}&query={id,codigo,descricao}`}
                                                placeholder="Selecione um CST PIS"
                                                filter
                                                filterBy="codigo,descricao"
                                                optionValue="id"
                                                optionLabel="descricao"
                                                valueTemplate={cstSelecionadoTemplate}
                                                itemTemplate={opcaoCSTTemplate}
                                                value={formikBr.values.cst_pis}
                                                onChange={formikBr.handleChange}
                                                className={classNames({ "p-invalid": formikBr.errors.cst_pis })}
                                            />
                                            {formikBr.errors.cst_pis && (
                                                <small className="p-error">{formikBr.errors.cst_pis}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-4">
                                            <label htmlFor="aliquota_pis">Alíquota PIS</label>
                                            <MakoInputPercent
                                                id="aliquota_pis"
                                                name="aliquota_pis"
                                                casasDecimais={2}
                                                value={formikBr.values.aliquota_pis}
                                                onValueChange={formikBr.handleChange}
                                                className={classNames({ "p-invalid": formikBr.errors.aliquota_pis })}
                                            />
                                            {formikBr.errors.aliquota_pis && (
                                                <small className="p-error">{formikBr.errors.aliquota_pis}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4">
                                            <label htmlFor="reducao_base_pis">Redução base PIS</label>
                                            <MakoInputPercent
                                                id="reducao_base_pis"
                                                name="reducao_base_pis"
                                                casasDecimais={2}
                                                value={formikBr.values.reducao_base_pis}
                                                onValueChange={formikBr.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikBr.errors.reducao_base_pis,
                                                })}
                                            />
                                            {formikBr.errors.reducao_base_pis && (
                                                <small className="p-error">{formikBr.errors.reducao_base_pis}</small>
                                            )}
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Cofins" disabled={!formikBr.values.define_cofins}>
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-12">
                                            <label htmlFor="cst_cofins">CST Cofins</label>
                                            <Dropdown
                                                id="cst_cofins"
                                                name="cst_cofins"
                                                url={`/fiscal/cst-cofins?limit=10000&empresa=${empresaSelecionadaId}&query={id,codigo,descricao}`}
                                                placeholder="Selecione um CST Cofins"
                                                filter
                                                filterBy="codigo,descricao"
                                                optionValue="id"
                                                optionLabel="descricao"
                                                valueTemplate={cstSelecionadoTemplate}
                                                itemTemplate={opcaoCSTTemplate}
                                                value={formikBr.values.cst_cofins}
                                                onChange={formikBr.handleChange}
                                                className={classNames({ "p-invalid": formikBr.errors.cst_cofins })}
                                            />
                                            {formikBr.errors.cst_cofins && (
                                                <small className="p-error">{formikBr.errors.cst_cofins}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4">
                                            <label htmlFor="aliquota_cofins">Alíquota Cofins</label>
                                            <MakoInputPercent
                                                id="aliquota_cofins"
                                                name="aliquota_cofins"
                                                casasDecimais={2}
                                                value={formikBr.values.aliquota_cofins}
                                                onValueChange={formikBr.handleChange}
                                                className={classNames({ "p-invalid": formikBr.errors.aliquota_cofins })}
                                            />
                                            {formikBr.errors.aliquota_cofins && (
                                                <small className="p-error">{formikBr.errors.aliquota_cofins}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4">
                                            <label htmlFor="reducao_base_cofins">Redução base Cofins</label>
                                            <MakoInputPercent
                                                id="reducao_base_cofins"
                                                name="reducao_base_cofins"
                                                casasDecimais={2}
                                                value={formikBr.values.reducao_base_cofins}
                                                onValueChange={formikBr.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikBr.errors.reducao_base_cofins,
                                                })}
                                            />
                                            {formikBr.errors.reducao_base_cofins && (
                                                <small className="p-error">{formikBr.errors.reducao_base_cofins}</small>
                                            )}
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="IPI" disabled={!formikBr.values.define_ipi}>
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-12">
                                            <label htmlFor="cst_ipi">CST IPI</label>
                                            <Dropdown
                                                id="cst_ipi"
                                                name="cst_ipi"
                                                url={`/fiscal/cst-ipi?limit=100&operacao=${operacaoFiscal?.tipo_movimento}&empresa=${empresaSelecionadaId}&query={id,codigo,descricao}`}
                                                placeholder="Selecione um CST IPI"
                                                filter
                                                filterBy="codigo,descricao"
                                                optionValue="id"
                                                optionLabel="descricao"
                                                valueTemplate={cstSelecionadoTemplate}
                                                itemTemplate={opcaoCSTTemplate}
                                                value={formikBr.values.cst_ipi}
                                                onChange={formikBr.handleChange}
                                                className={classNames({ "p-invalid": formikBr.errors.cst_ipi })}
                                            />
                                            {formikBr.errors.cst_ipi && (
                                                <small className="p-error">{formikBr.errors.cst_ipi}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="aliquota_ipi">Alíquota IPI</label>
                                            <MakoInputPercent
                                                id="aliquota_ipi"
                                                name="aliquota_ipi"
                                                casasDecimais={2}
                                                value={formikBr.values.aliquota_ipi}
                                                onValueChange={formikBr.handleChange}
                                                className={classNames({ "p-invalid": formikBr.errors.aliquota_ipi })}
                                            />
                                            {formikBr.errors.aliquota_ipi && (
                                                <small className="p-error">{formikBr.errors.aliquota_ipi}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="reducao_base_ipi">Redução base IPI</label>
                                            <MakoInputPercent
                                                id="reducao_base_ipi"
                                                name="reducao_base_ipi"
                                                casasDecimais={2}
                                                value={formikBr.values.reducao_base_ipi}
                                                onValueChange={formikBr.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikBr.errors.reducao_base_ipi,
                                                })}
                                            />
                                            {formikBr.errors.reducao_base_ipi && (
                                                <small className="p-error">{formikBr.errors.reducao_base_ipi}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="enquadramento_ipi">Enquadramento de IPI *</label>
                                            <Dropdown
                                                id="enquadramento_ipi"
                                                name="enquadramento_ipi"
                                                url="/fiscal/enquadramento-ipi?limit=10000"
                                                filter
                                                filterBy="codigo,descricao"
                                                optionValue="id"
                                                optionLabel="label"
                                                aposBuscar={formatarEnquadramento}
                                                showClear={false}
                                                value={formikBr.values.enquadramento_ipi}
                                                onChange={formikBr.handleChange}
                                                className={classNames({
                                                    "p-invalid": formikBr.errors.enquadramento_ipi,
                                                })}
                                            />
                                            {formikBr.errors.enquadramento_ipi && (
                                                <small className="p-error">{formikBr.errors.enquadramento_ipi}</small>
                                            )}
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>
                        </form>
                    </TabPanel>
                </TabView>
            )}
            <div className="p-grid p-justify-end p-mr-3 p-mt-3">
                <Button
                    icon="pi pi-times"
                    type="reset"
                    className="p-button-danger p-button-outlined p-mr-2"
                    label="Fechar"
                    onClick={() => fecharModal()}
                />
                <Button
                    icon="pi pi-save"
                    type="submit"
                    className="p-button-success"
                    label="Gravar"
                    onClick={handleSubmit}
                    disabled={!skuSelecionado}
                />
            </div>
        </Dialog>
    );
};

export const ModalRegraOperacaoFiscalSKU = forwardRef(Modal);
