import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Sessoes } from "./sessoes";

import { formatarCasasDecimais } from "@/assets/util/util";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useClearRefs from "@/hooks/useClearRefs";

const Component = ({ successCalback = () => {} }, ref) => {
    const [formasRecebimentoRateio, setFormasRecebimentoRateio] = useState([]);
    const formasFormRef = useRef();
    const { caixaMov: caixa } = useCaixaMovimento();

    useClearRefs(formasFormRef);

    const { setValues, setFieldError, setFieldValue, ...formik } = useFormik({
        initialValues: {
            documento: "",
            parcela: "",
            data_emissao: null,
            vencimento: null,
            dias: 0,
            valor: 0,
            descontos: 0,
            descontos_percent: 0,
            multa: 0,
            juros: 0,
            recebido: 0,
            conta_financeira: caixa?.caixa?.conta_financeira || null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const totalRateio = formasRecebimentoRateio.reduce((total, atual) => total + atual.valor, 0);
            if (totalRateio > values.recebido) {
                throw new Yup.ValidationError(
                    "A soma dos valores está maior que o total a ser recebido.",
                    null,
                    "rateio"
                );
            } else if (totalRateio < values.recebido) {
                throw new Yup.ValidationError(
                    "A soma dos valores está menor que o total a ser recebido.",
                    null,
                    "rateio"
                );
            }
            successCalback({
                ...values,
                forma_recebimento: formasRecebimentoRateio,
                _edited: true,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                    if (err.path === "rateio") {
                        formasFormRef.current?.setErrorFormaRecebRateio(err.message);
                    }
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const iniciarFormulario = useCallback(
        async (parcela) => {
            if (parcela) {
                const { numero_parcela, quantidade_parcelas } = parcela;
                formasFormRef.current?.setFormaRecebimento(parcela.forma_recebimento[0]?.id);
                const formas = !formik.values.conta_financeira
                    ? []
                    : parcela.forma_recebimento.filter((item) => !!item?.id);
                await setValues(
                    {
                        ...parcela,
                        forma_recebimento: formas,
                        parcela: numero_parcela === 0 ? "ENTRADA" : `${numero_parcela}/${quantidade_parcelas}`,
                        descontos_percent: 0,
                    },
                    true
                );
            }
            if (!formik.values.conta_financeira) {
                setFieldError("conta_financeira", "O campo 'conta financeira' é obrigatório");
            }
        },
        [formik.values.conta_financeira, setFieldError, setValues]
    );

    const somarTotalRecebimento = useCallback(() => {
        const total = formik.values.valor + formik.values.multa + formik.values.juros - formik.values.descontos;
        setFieldValue("recebido", formatarCasasDecimais(total));
        formasFormRef.current?.setValor(total);
        setFormasRecebimentoRateio([]);
    }, [formik.values.descontos, formik.values.juros, formik.values.multa, formik.values.valor, setFieldValue]);

    useEffect(() => somarTotalRecebimento(), [somarTotalRecebimento]);

    useEffect(() => {
        if (formik.values.forma_recebimento) {
            setFormasRecebimentoRateio(formik.values.forma_recebimento);
        }
    }, [formik.values.forma_recebimento]);

    useImperativeHandle(ref, () => {
        return {
            setFieldError,
            setValues,
            setFieldValue,
            iniciarFormulario,
        };
    });

    return (
        <>
            <Sessoes.Parcela
                values={formik.values}
                errors={formik.errors}
                handleSubmit={formik.handleSubmit}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
            />
            <Sessoes.Formas
                ref={formasFormRef}
                values={formik.values}
                errors={formik.errors}
                formasRecebimentoRateio={formasRecebimentoRateio}
                handleChange={formik.handleChange}
                setFieldError={setFieldError}
                setFormasRecebimentoRateio={setFormasRecebimentoRateio}
            />

            <MakoActionsButtons className="p-justify-end p-mt-4">
                <Button
                    type="submit"
                    label="Confirmar"
                    icon="pi pi-save"
                    onClick={formik.handleSubmit}
                    disabled={!formasRecebimentoRateio.some((item) => item?.id)}
                    autoFocus
                />
            </MakoActionsButtons>
        </>
    );
};

export const ParcelaFormas = forwardRef(Component);
