import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";

import { TIPO_STATUS_ORDEM_COMPRA_CHOICE } from "@/assets/constants/constants";
import { gerarFiltrosUrl } from "@/assets/util/util";

const URL_BACKEND = "/compras/ordens-compra/?";

const statusBodyTemplate = (rowData) => {
    const status = TIPO_STATUS_ORDEM_COMPRA_CHOICE.find((st) => st.id === rowData.status);
    return <span>{status?.label || "N/A"}</span>;
};

const COLUMNS = [
    {
        field: "id",
        header: "Código",
        style: { width: "8%" },
    },
    {
        field: "fornecedor.nome",
        header: "Fornecedor",
    },
    {
        field: "data_pedido",
        header: "Data Pedido",
        dateFormat: "dd/MM/yyyy",
        style: { width: "10%" },
        align: "center",
    },
    {
        field: "status",
        header: "Situação",
        style: { width: "8%" },
        align: "center",
        action: (e) => statusBodyTemplate(e),
    },
    { field: "valor_total_ordem_compra", header: "Valor Total", style: { width: "10%" }, money: true },
];

export const Listagem = ({ listagemRef, filtros = null, configTabela = {} }) => {
    const url = useMemo(() => {
        let _url = URL_BACKEND;
        if (filtros instanceof Object) _url = `${_url}&${gerarFiltrosUrl(filtros)}`;
        return _url;
    }, [filtros]);

    const buscar = useMemo(() => {
        return Object.keys(filtros || {}).length > 0;
    }, [filtros]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo="Selecione uma opção"
            colunas={COLUMNS}
            urlPesquisa={url}
            fazerBusca={buscar}
            configTabela={{
                lazy: true,
                paginator: true,
                ...configTabela,
            }}
        />
    );
};
