import React, { useState, useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { Tag } from "primereact/tag";
import useToast from "@/hooks/useToast";
import {
    CodigoFiltroTemplate,
    ContaFinanceiraFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { PageBase } from "@/components/PageBase";
import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";
import useHttp from "@/hooks/useHttp";

const url = "/financeiro/caixas/";

export const CaixaPage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [caixa, setcaixa] = useState(null);

    const { showSuccess } = useToast();
    const { httpPatch } = useHttp();

    const listagemRef = useRef(null);

    const confirmarDelete = (caixa) => {
        setcaixa(caixa);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const desativarCaixa = async (status) => {
        let ok = false;
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: `Caixa ${!status ? "desativado" : "ativado"} com sucesso!`,
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
                ok = true;
            },
        };
        await httpPatch({ url: `${url}${caixa.id}/`, body: { ativo: status } }, handlers);
        return ok;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXA_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/financeiro/cadastros/caixas/form",
                        state: { caixa: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXA_MANUTENCAO]}
                    componente={Button}
                    icon={rowData.ativo ? "pi pi-unlock" : "pi pi-lock"}
                    className={`p-button-rounded p-button-${rowData.ativo ? "danger" : "success"}`}
                    onClick={() => confirmarDelete(rowData)}
                    tooltip={`${rowData.ativo ? "Desativar" : "Ativar"} caixa`}
                    tooltipOptions={{ position: "left" }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/financeiro/cadastros/caixas/form"}
            />
        </>
    );

    const STATUS = [
        { value: true, label: "Ativo", tag: "success" },
        { value: false, label: "Desativado", tag: "danger" },
    ];

    const statusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: STATUS,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "6%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "conta_financeira",
            header: "Conta financeira",
            action: ({ conta_financeira }) => `${conta_financeira.codigo} - ${conta_financeira.descricao}`,
            filter: true,
            filterElement: ContaFinanceiraFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Ativo",
            align: "center",
            style: { width: "10%" },
            action: ({ ativo }) => {
                const _tag = STATUS.find((item) => item.value === ativo);
                return <Tag severity={_tag.tag} value={_tag.label.toLocaleUpperCase()} />;
            },
            filter: true,
            filterElement: statusFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            align: "center",
            style: { width: "8%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Caixas"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    ativo: { value: null, matchMode: "equals" },
                    conta_financeira: { value: null, matchMode: "equals" },
                }}
            />
            <MakoConfirmDialog
                visible={deleteDialog}
                setVisible={esconderDeleteDialog}
                message={
                    caixa && (
                        <span>
                            {`Deseja realmente ${caixa.status ? "ativar" : "desativar"} o caixa: `}
                            <b>{caixa.nome}</b>?
                        </span>
                    )
                }
                accept={() => desativarCaixa(!caixa.ativo)}
                showErrorToast={false}
            />
        </PageBase>
    );
};
