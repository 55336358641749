import React, { forwardRef, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import useToast from "@/hooks/useToast";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

const Modal = ({ isRoot }, ref) => {
    const [visible, setVisible] = useState(false);
    const [usuario, setUsuario] = useState(null);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpPost, httpPut } = useHttp();

    const formik = useFormik({
        initialValues: {
            isRoot,
            new_password: "",
            current_password: "",
            confirmpass: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                current_password: Yup.string().when("isRoot", {
                    is: (val) => val === false,
                    then: Yup.string().trim("Não pode ser em branco.").required("O campo 'senha atual' é obrigatório."),
                    otherwise: Yup.string().notRequired().default(""),
                }),
                new_password: Yup.string()
                    .trim("Não pode ser em branco.")
                    // .matches(
                    //     /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})./,
                    //     "Senha fraca"
                    // )
                    .required("O campo 'nova senha' é obrigatório."),
                confirmpass: Yup.string()
                    .required("O campo 'confirmar senha' é obrigatório.")
                    .oneOf([formik.values.new_password], "As senhas não são iguais."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const { new_password, current_password } = values;
            if (!isRoot) {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Senha alterada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        setUsuario(null);
                        setVisible(false);
                    },
                    400: ({ data }) => {
                        if (data?.current_password) {
                            showWarning({
                                summary: data?.current_password,
                                detail: "Senha atual informada é inválida, por favor verifique e tente novamente.",
                                life: 5000,
                            });
                        } else showError();
                    },
                };
                showLoading();
                await httpPost(
                    {
                        url: `/auth/users/set_password/`,
                        body: {
                            new_password,
                            current_password,
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const body = {
                    username: usuario.username,
                    password: new_password,
                };
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Senha alterada com sucesso!",
                            life: 1500,
                        });
                        setVisible(false);
                    },
                };
                showLoading();
                await httpPut({ url: `/pessoas/usuario/${usuario.id}/`, body }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (usuario) => {
        setVisible(true);
        setUsuario(usuario);
    };

    const fecharModal = () => {
        setVisible(false);
        formik.resetForm();
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            header="Alterar senha de usuário"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "30vw" }}
            onHide={fecharModal}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid ">
                    {(!isRoot || usuario?.username === "admin") && (
                        <div className="p-field p-col-12">
                            <Label htmlFor="senha-atual" label="Senha atual" obrigatorio />
                            <Password
                                id="senha-atual"
                                name="current_password"
                                toggleMask
                                mediumRegex
                                feedback={false}
                                autoComplete="off"
                                value={formik.values.current_password}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.current_password })}
                            />
                            {formik.errors.current_password && (
                                <small className="p-error">{formik.errors.current_password}</small>
                            )}
                        </div>
                    )}
                    <div className="p-field p-col-12">
                        <Label htmlFor="nova-senha" label="Nova senha" obrigatorio />
                        <Password
                            id="nova-senha"
                            name="new_password"
                            toggleMask
                            mediumRegex
                            promptLabel="Informe a senha"
                            weakLabel="Fraca"
                            mediumLabel="Média"
                            strongLabel="Forte"
                            autoComplete="off"
                            value={formik.values.new_password}
                            onInput={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.new_password })}
                        />
                        {formik.errors.new_password && <small className="p-error">{formik.errors.new_password}</small>}
                    </div>
                    <div className="p-field p-col-12">
                        <Label htmlFor="confirmpass" label="Confirmar senha" obrigatorio />
                        <Password
                            id="confirmpass"
                            name="confirmpass"
                            feedback={false}
                            autoComplete="off"
                            value={formik.values.confirmpass}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.confirmpass })}
                        />
                        {formik.errors.confirmpass && <small className="p-error">{formik.errors.confirmpass}</small>}
                    </div>
                </div>
                <MakoActionsButtons>
                    <Button type="submit" icon={MAKO_ICONS.GRAVAR} label="Gravar" loading={loading} />
                </MakoActionsButtons>
            </form>
        </Dialog>
    );
};

export const ModalAlterarSenhaUsuario = forwardRef(Modal);
