import React, { useEffect } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLoading from "@/hooks/useLoading";
import { useHistory } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import { Label } from "@/components/Label";
import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";

export const RedesSociaisForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            nome: "",
            url_base: "",
            icone: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (props.location.state) {
            setValues(props.location.state);
        }
    }, [setValues, props]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Rede social cadastrada com sucesso!",
                            life: 1500,
                        });
                        history.push("/gestao/cadastros/redes-sociais");
                    },
                };
                showLoading();
                await httpPost({ url: "/pessoas/redes-sociais/", body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Rede social alterada com sucesso!",
                            life: 1500,
                        });
                        history.push("/gestao/cadastros/redes-sociais");
                    },
                };
                showLoading();
                await httpPut({ url: `/pessoas/redes-sociais/${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <PageBase>
            {<h3>{!formik.values.id ? "Nova rede social" : "Manutenção de rede social"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="nome" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            autoFocus
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="url-base" label="Url" />
                        <InputText
                            id="url-base"
                            name="url_base"
                            value={formik.values.url_base}
                            onChange={formik.handleChange}
                            autoComplete="off"
                        />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <Label htmlFor="ativo" label="Ativa?" />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button icon={MAKO_ICONS.GRAVAR} label="Gravar" type="submit" className="p-button-info" />
                    <Button
                        icon={MAKO_ICONS.CANCEL}
                        label="Cancelar"
                        type="button"
                        className="p-button-danger"
                        to={"/gestao/cadastros/redes-sociais"}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
