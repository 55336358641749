import React, { useCallback } from "react";

import { FormAbrir as Form } from "../../features";

import useAuth from "@/hooks/useAuth";

export const Abrir = ({ fecharModal = () => {}, handleCaixa = () => {} }) => {
    const { user } = useAuth();

    const successCalback = useCallback(() => {
        fecharModal();
        handleCaixa();
    }, [fecharModal, handleCaixa]);

    return <Form user={user} successCalback={successCalback} cancelCallback={fecharModal} />;
};
