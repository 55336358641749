import { Novo } from "./novo";
import { Editar } from "./editar";
import { Remover } from "./remover";
import { Visualizar } from "./visualizar";

export const Buttons = {
    Novo,
    Editar,
    Remover,
    Visualizar,
};
