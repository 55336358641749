import React from "react";

import { useFormik } from "formik";
import classNames from "classnames";
// import * as Yup from "yup";

import { InputText } from "primereact/inputtext";

import { MakoInputCliente, MakoInputPeriodo } from "@/components/MakoInputs";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoButton } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const FormFiltro = () => {
    const { ...formik } = useFormik({
        initialValues: {},
        onSubmit: () => {},
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="venda" label="Compra" />
                    <InputText
                        id="venda"
                        name="venda"
                        valueCalendar={formik.values.venda}
                        onInput={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.venda })}
                    />
                    {formik.errors.venda && <small className="p-error">{formik.errors.venda}</small>}
                </div>
                <MakoInputPeriodo
                    label="Periodo de emissao"
                    nameInicio="emissao"
                    nameFinal="emissao"
                    valueInicio={formik.values.emissao}
                    valueFinal={formik.values.emissao}
                    errorInicio={formik.errors.emissao}
                    errorFinal={formik.errors.emissao}
                    onChangeInicio={formik.handleChange}
                    onChangeFinal={formik.handleChange}
                />
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="forma_recebimento" label="Forma de recebimento" />
                    <Dropdown
                        id="forma_recebimento"
                        name="forma_recebimento"
                        url="/financeiro/formas-recebimentos/?query={id,descricao}&limit=50&escopo__in=R,X"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.forma_recebimento}
                        className={classNames({ "p-invalid": formik.errors.forma_recebimento })}
                    />
                    {formik.errors.forma_recebimento && (
                        <small className="p-error">{formik.errors.forma_recebimento}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <MakoInputPeriodo
                    label="Periodo de vencimento"
                    nameInicio="vencimento"
                    nameFinal="vencimento"
                    valueInicio={formik.values.vencimento}
                    valueFinal={formik.values.vencimento}
                    errorInicio={formik.errors.vencimento}
                    errorFinal={formik.errors.vencimento}
                    onChangeInicio={formik.handleChange}
                    onChangeFinal={formik.handleChange}
                />
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="valor__gte" label="Valor mínimo" />
                    <MakoInputMoeda
                        inputId="valor__gte"
                        name="valor__gte"
                        valueMoeda={formik.values.valor__gte}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor__gte })}
                    />
                    {formik.errors.valor__gte && <small className="p-error">{formik.errors.valor__gte}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="valor__lte" label="Valor máximo" />
                    <MakoInputMoeda
                        inputId="valor__lte"
                        name="valor__lte"
                        valueMoeda={formik.values.valor__lte}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor__lte })}
                    />
                    {formik.errors.valor__lte && <small className="p-error">{formik.errors.valor__lte}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-10">
                    <Label htmlFor="cliente" label="Credor" />
                    <MakoInputCliente
                        id="cliente"
                        name="cliente"
                        value={formik.values.cliente}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.cliente })}
                    />
                    {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="parcela" label="Parcela" />
                    <InputText
                        id="parcela"
                        name="parcela"
                        valueCalendar={formik.values.parcela}
                        onInput={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.parcela })}
                    />
                    {formik.errors.parcela && <small className="p-error">{formik.errors.parcela}</small>}
                </div>
            </div>
            <MakoActionsButtons className="p-jc-end">
                <MakoButton label="Filtrar" icon={MAKO_ICONS.FILTRAR} className="p-button-info" type="submit" />
            </MakoActionsButtons>
        </form>
    );
};
