import React, { useState } from "react";
import { Button } from "primereact/button";
import useCrediario from "@/hooks/useCrediario";
import { Dropdown } from "@/components/Dropdown";

export const RegraCrediarioModalForm = (props) => {
    const [regra, setRegra] = useState(null);
    const { handleDadosCrediario } = useCrediario();

    const selecionaRegra = (regra) => {
        props.selecionaRegra(regra.id);
        props.modal(false);
        handleDadosCrediario({ regra: regra });
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <br />
                <h5>Selecione uma regra de crediário:</h5>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Dropdown
                            id="regra"
                            name="regra"
                            placeholder="Selecione uma regra"
                            url={`/crediario/regra-crediario?limit=300&valor_minimo__lte=${props.orcamento}&valor_maximo__gte=${props.orcamento}`}
                            filter
                            filterBy="descricao"
                            optionLabel="descricao"
                            value={regra}
                            onChange={(e) => setRegra(e.target.value)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid p-justify-center">
                    <div className="p-field p-col-12 p-md-4 p-mb-0">
                        <Button
                            type="submit"
                            icon="pi pi-check-square"
                            label="Confirmar"
                            className="p-button-primary p-mr-2"
                            disabled={!regra}
                            onClick={() => selecionaRegra(regra)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
