import React, { useImperativeHandle, useState, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_INDICADOR_ATIVIDADE, TIPO_PERFIL_ARQUIVO_FISCAL } from "@/assets/constants/constants";
import useAuth from "@/hooks/useAuth";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useHttp from "@/hooks/useHttp";

const Modal = ({ principal, onGravar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const { showSuccess } = useToast();
    const { httpPost } = useHttp();
    const { user } = useAuth();

    const formik = useFormik({
        initialValues: {
            perfil: null,
            empresa_origem_hierarquia: null,
            empresa_tributo: null,
            perfil_arquivo: "B",
            indicador_atividade: 1,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil: Yup.object()
                    .typeError("Selecione uma pessoa física / jurídica.")
                    .required("O campo 'nova pessoa física / jurídica' é obrigatório."),
                empresa_origem_hierarquia: Yup.number().nullable().notRequired(),
                empresa_tributo: Yup.number().nullable().notRequired(),
                perfil_arquivo: Yup.string()
                    .required("Informe o perfil de apresentação do arquivo fiscal.")
                    .typeError("Informe um valor válido."),
                indicador_atividade: Yup.number()
                    .required("Informe o indicador do tipo de atividade.")
                    .typeError("Informe um valor válido."),
            });
            const { perfil, ...rest } = await formSchema.validate(values, { abortEarly: false });
            const body = {
                ...rest,
                perfil: perfil.id,
                principal,
                perfil_parametro: {
                    perfil_arquivo: values.perfil_arquivo,
                    indicador_atividade: values.indicador_atividade,
                },
                usuario_criou: user?.id,
            };
            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Pessoa vinculada com sucesso!",
                        life: 1500,
                    });
                    onGravar();
                    fecharModal();
                },
            };
            setLoading(true);
            await httpPost({ url: "/pessoas/grupos-perfis/", body }, handlers);
            setLoading(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const fecharModal = () => {
        formik.resetForm();
        setVisible(false);
    };

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const footer = (
        <MakoActionsButtons className="p-jc-end">
            <Button
                type="submit"
                label="Vincular"
                icon={MAKO_ICONS.GRAVAR}
                loading={loading}
                onClick={formik.handleSubmit}
            />
        </MakoActionsButtons>
    );

    return (
        <Dialog
            header="Incluindo pessoa física / jurídica ao gerenciamento do sistema"
            visible={visible}
            onHide={fecharModal}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "80vw" }}
            footer={footer}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <Label htmlFor="pessoa" label="Nova pessoa física / jurídica" />
                        <MakoInputPerfil
                            id="pessoa"
                            name="perfil"
                            value={formik.values.perfil}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.perfil })}
                        />
                        {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <Label htmlFor="tributos" label="Importar cadastro de tributos de" />
                        <MakoDropdownEmpresas
                            id="tributos"
                            name="empresa_tributo"
                            disabled={principal}
                            value={formik.values.empresa_tributo}
                            onChange={(e) => formik.setFieldValue("empresa_tributo", e.id)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <Label htmlFor="hierarquia-funcional" label="Utilizar hierarquia funcional de" />
                        <MakoDropdownEmpresas
                            id="hierarquia-funcional"
                            name="empresa_origem_hierarquia"
                            disabled={principal}
                            value={formik.values.empresa_origem_hierarquia}
                            onChange={(e) => formik.setFieldValue("empresa_origem_hierarquia", e.id)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="perfil_arquivo" label="Tipo apresentação arquivo fiscal" />
                        <Dropdown
                            id="perfil_arquivo"
                            name="perfil_arquivo"
                            placeholder="Selecione..."
                            options={TIPO_PERFIL_ARQUIVO_FISCAL}
                            optionValue="value"
                            optionLabel="label"
                            onChange={formik.handleChange}
                            value={formik.values.perfil_arquivo}
                            showClear={false}
                            className={classNames({ "p-invalid": formik.errors.perfil_arquivo })}
                        />
                        {formik.errors.perfil_arquivo && (
                            <small className="p-error">{formik.errors.perfil_arquivo}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="indicador_atividade" label="Indicador do tipo de atividade" />
                        <Dropdown
                            id="indicador_atividade"
                            name="indicador_atividade"
                            placeholder="Selecione..."
                            options={TIPO_INDICADOR_ATIVIDADE}
                            optionValue="value"
                            optionLabel="label"
                            onChange={formik.handleChange}
                            value={formik.values.indicador_atividade}
                            showClear={false}
                            className={classNames({ "p-invalid": formik.errors.indicador_atividade })}
                        />
                        {formik.errors.indicador_atividade && (
                            <small className="p-error">{formik.errors.indicador_atividade}</small>
                        )}
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalVincularPessoas = forwardRef(Modal);
