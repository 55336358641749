import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import AppSubmenu from "../AppSubmenu";
import { InputFiltro } from "./inputFiltro";

import useAuth from "@/hooks/useAuth";

const AppMenu = (props) => {
    const [menu, setMenu] = useState([]);
    const [search, setSearch] = useState("");
    const { verifyPermission, user } = useAuth();

    const verificarMenu = useCallback(
        (array) => {
            let i = array.length;
            while (i--) {
                array[i].items && verificarMenu(array[i].items);

                if (array[i].permissions) {
                    let remover = verifyPermission(array[i].permissions);

                    if (array[i].permissions && !remover) {
                        array.splice(i, 1);
                        continue;
                    }
                }
            }

            return array;
        },
        [verifyPermission]
    );

    const formatarMenu = useCallback((array) => {
        let i = array.length;
        while (i--) {
            array[i].items && formatarMenu(array[i].items);
            if (array[i].items && array[i].items.length === 0) {
                array.splice(i, 2);
                continue;
            }
        }

        return array;
    }, []);

    const filtredMenu = useMemo(() => {
        const verificarSearch = (value, pesquisa) => {
            if (!value.to) return value.label.toLowerCase().includes(pesquisa);
            return value.label.toLowerCase().includes(pesquisa) || value.to.includes(pesquisa);
        };
        // abordagem desagrupada ao buscar
        // const pesquisar = (items, filtred = []) => {
        //     items.forEach(item => {
        //         if(!item.to && item.items) return pesquisar(item.items, filtred);
        //         if(item.label && verificarSearch(item, search.toLowerCase())) filtred.push(item)
        //     })
        //     return filtred
        // }

        function pesquisar(menu) {
            const result = [];

            function buscarDentro(menu) {
                if (menu.separator) return false;
                if (verificarSearch(menu, search.toLowerCase())) return true;
                if (menu.items) {
                    return menu.items.some((item) => {
                        if (verificarSearch(item, search.toLowerCase())) return true;
                        if (item.items) return buscarDentro(item);
                        return false;
                    });
                }
            }
            menu.forEach((item) => {
                if (buscarDentro(item)) {
                    result.push(item);
                }
            });
            return result;
        }
        if (search.length > 2) {
            const data = pesquisar(menu);
            // return [
            //     {
            //         label: "Resultados",
            //         items: data,
            //     },
            // ];
            return data;
        }
        return menu;
    }, [menu, search]);

    useEffect(() => {
        if (props.model) {
            let menuProvisorio = verificarMenu(props.model);
            menuProvisorio = formatarMenu(menuProvisorio);

            setMenu(menuProvisorio);
        }
    }, [props.model, verificarMenu, formatarMenu, user]);

    return (
        <div className="layout-sidebar" onClick={props.onMenuClick}>
            <Link to="/" className="logo">
                <img
                    id="app-logo"
                    className="logo-image"
                    src={`${process.env.PUBLIC_URL}/assets/layout/images/logos-mako/logo_sysmako_ROSA_BRANCO.png`}
                    style={{ width: "120px", height: "80px" }}
                    alt="mako layout"
                />
            </Link>
            <div className="layout-menu-container">
                <InputFiltro value={search} onChange={({ target }) => setSearch(target.value)} />
                <AppSubmenu
                    items={filtredMenu}
                    menuMode={props.menuMode}
                    parentMenuItemActive
                    menuActive={props.active}
                    mobileMenuActive={props.mobileMenuActive}
                    root
                    onMenuitemClick={props.onMenuitemClick}
                    onRootMenuitemClick={props.onRootMenuitemClick}
                />
            </div>
        </div>
    );
};

export default AppMenu;
