import React, { useCallback } from "react";

import { FormSuprimento as Form } from "../../features";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useAuth from "@/hooks/useAuth";

export const Suprimento = ({ fecharModal = () => {} }) => {
    const { caixaMov, padraoPlanos } = useCaixaMovimento();
    const { user } = useAuth();

    const successCalback = useCallback(() => {
        fecharModal(true);
    }, [fecharModal]);

    return (
        <Form
            caixaMov={caixaMov}
            user={user}
            planoPadrao={padraoPlanos["suprimento"] || null}
            successCalback={successCalback}
            cancelCallback={fecharModal}
        />
    );
};
