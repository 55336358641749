import React, { useCallback, useRef } from "react";

import classNames from "classnames";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton } from "@/components/MakoButton";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";
import { Modals } from "../../modals";

const { PRODUTO_PRODUTO_LOTES_EDITAR } = permissoes;

export const Editar = ({ lote, className = "", onSuccessCallback = () => {} }) => {
    const modalRef = useRef(null);

    const show = useCallback(() => {
        modalRef.current?.show(lote);
    }, [lote]);

    return (
        <>
            <MakoControleAcesso
                componente={MakoButton}
                permissao={[PRODUTO_PRODUTO_LOTES_EDITAR]}
                tooltip="Editar"
                icon={MAKO_ICONS.EDITAR}
                className={classNames("p-button p-button-rounded p-component p-button-warning", className)}
                onClick={show}
            />
            <Modals.Editar ref={modalRef} onSuccessCallback={onSuccessCallback} />
        </>
    );
};
