import React, { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { useFormik } from "formik";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import classNames from "classnames";
import * as Yup from "yup";

export const ProdutoGradeAtributosFormModal = ({ gradeAtributo, atributo, esconderDialog, listagemRef }) => {
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPatch } = useHttp();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: gradeAtributo?.id
            ? {
                  ...gradeAtributo,
                  id: gradeAtributo.id,
                  atributo: gradeAtributo.atributo.id,
              }
            : {
                  atributo: null,
                  codigo: "",
                  opcoes_valor: "",
                  valor_numerico: false,
                  servico_venda: null,
                  usa_preco_medida_servico: false,
                  calcula_medida: false,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit() {
        try {
            const { values } = formik;

            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                servico_venda: Yup.object().nullable().typeError("Serviço de venda inválido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Atributo cadastrado com sucesso!",
                            life: 2000,
                        });
                        resetForm();
                        esconderDialog();
                        listagemRef.current?.buscarDados();
                    },
                };

                await httpPost(
                    {
                        url: `/produtos/grades-atributos/`,
                        body: {
                            ...values,
                            atributo: atributo,
                            servico_venda: values.servico_venda ? values.servico_venda.id : null,
                        },
                    },
                    handlers
                );
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Atributo alterado com sucesso!",
                            life: 2000,
                        });
                        resetForm();
                        esconderDialog();
                        listagemRef.current?.buscarDados();
                    },
                };

                await httpPatch(
                    {
                        url: `/produtos/grades-atributos/${values.id}/`,
                        body: {
                            ...values,
                            servico_venda: values.servico_venda ? values.servico_venda.id : null,
                        },
                    },
                    handlers
                );
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    useEffect(() => {
        setFieldValue("opcoes_valor", "");
    }, [formik.values.valor_numerico, setFieldValue]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form onSubmit={handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="codigo">Grade *</label>
                            <InputText
                                id="codigo"
                                name="codigo"
                                value={formik.values.codigo}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.codigo })}
                                tooltip="Definir um código para grade atributo"
                                autoComplete="off"
                            />
                            {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="valor_numerico">Valor númerico? *</label>
                            <Dropdown
                                id="valor_numerico"
                                value={formik.values.valor_numerico}
                                options={SIM_NAO_BOOLEAN}
                                placeholder="Selecione..."
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_numerico })}
                                optionLabel="label"
                                optionValue="id"
                                tooltip="Definir tooltip aqui."
                            />
                            {formik.errors.valor_numerico && (
                                <small className="p-error">{formik.errors.valor_numerico}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="calcula_medida ">Calcula medida?</label>
                            <Dropdown
                                id="calcula_medida"
                                value={formik.values.calcula_medida}
                                options={SIM_NAO_BOOLEAN}
                                placeholder="Selecione..."
                                onChange={formik.handleChange}
                                optionLabel="label"
                                optionValue="id"
                                tooltip="Definir tooltip aqui."
                                disabled={!formik.values.valor_numerico}
                            />
                            {formik.errors.calcula_medida && (
                                <small className="p-error">{formik.errors.calcula_medida}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="usa_preco_medida_servico">Vlr. serviço conforme medida?</label>
                            <Dropdown
                                id="usa_preco_medida_servico"
                                value={formik.values.usa_preco_medida_servico}
                                options={SIM_NAO_BOOLEAN}
                                placeholder="Selecione..."
                                onChange={formik.handleChange}
                                optionLabel="label"
                                optionValue="id"
                                tooltip="Definir tooltip aqui."
                            />
                            {formik.errors.usa_preco_medida_servico && (
                                <small className="p-error">{formik.errors.usa_preco_medida_servico}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-8">
                            <label htmlFor="codigo">Serviço de venda</label>
                            <MakoAutoComplete
                                id="servico_venda"
                                name="servico_venda"
                                placeholder="Comece a digitar dados de serviços para pesquisar..."
                                minCaracteresBusca={4}
                                value={formik.values.servico_venda}
                                onChange={formik.handleChange}
                                tooltip="Escolher um serviço, após perquisar
                                clique em uma atributo para selecionar."
                                field="nome"
                                urlSearch={
                                    formik.values.usa_preco_medida_servico
                                        ? "vendas/servicos-vendas/?limit=100&preco_simples=0.00&search="
                                        : "vendas/servicos-vendas/?limit=100&preco_medida=0.00&search="
                                }
                                className={classNames({ "p-invalid": formik.errors.servico_venda })}
                            />
                            {formik.errors.servico_venda && (
                                <small className="p-error">{formik.errors.servico_venda}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="opcoes_valor">Opções</label>
                            <InputText
                                id="opcoes_valor"
                                value={formik.values.opcoes_valor}
                                onChange={formik.handleChange}
                                tooltip="Defina as opções que serão disponibilizadas para atribuição ao produto (importante separa-las por '|')."
                                disabled={formik.values.valor_numerico}
                            />
                            <p className="p-mt-2">
                                Cada opção deve ser delimitada pelo separador '|', exemplo: "opcao_1|opcao_2|opcao_3
                            </p>
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-col-12 p-md-6">
                        <Button
                            label="Gravar"
                            type="button"
                            onClick={() => handleSubmit()}
                            className="p-button-info p-mr-2"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
