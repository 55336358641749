import React, { useRef } from "react";
import { Button } from "primereact/button";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import useToast from "@/hooks/useToast";
import { BotaoDelete } from "@/components/BotaoDelete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

const url = "/produtos/tipos-codigos-sku/";

export const TipoCodigoSkuPage = () => {
    const { showSuccess } = useToast();
    const history = useHistory();
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_TIPOCODIGOSKU_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar cadastro"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/cadastros/tipos-codigos-sku/form",
                            state: { tipoCodigo: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_TIPOCODIGOSKU_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir o tipo de código "}
                            <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A nota fiscal não pode ser excluída."
                    tooltip="Deletar"
                    onDelete={() => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de código do produto deletado com sucesso!",
                            life: 3000,
                        });
                        listagemRef.current?.buscarDados();
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_TIPOCODIGOSKU_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/produto/cadastros/tipos-codigos-sku/form")}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "15%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Tipos de Códigos do Produto"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
