import {
    IconBoleto,
    IconCartaoCredito,
    IconDeposito,
    IconDinheiro,
    IconPix,
    IconVoucher,
    IconCarneCrediario,
} from "@/components/MakoIcons";

export const TIPOS_FORMAS_RECEBIMENTOS_ICONS = [0, 1, 2, 3, 5, 8, 9, 10, 11];

export function formaRecebimentoPossuiIcone(tipo) {
    return TIPOS_FORMAS_RECEBIMENTOS_ICONS.includes(tipo);
}

export function gerarIconeFormaRecebimento({ tipo, iconProps = {}, placeholder = "" }) {
    switch (tipo) {
        case 0:
            return <IconDinheiro {...iconProps} />;
        case 1:
            return <IconCartaoCredito {...iconProps} />;
        case 2:
            return <IconCartaoCredito {...iconProps} />;
        case 3:
            return <IconPix {...iconProps} />;
        case 5:
            return <IconBoleto {...iconProps} />;
        case 8:
            return <IconCarneCrediario {...iconProps} />;
        case 9:
            return <IconVoucher {...iconProps} />;
        case 10:
            return <IconCartaoCredito {...iconProps} />;
        case 11:
            return <IconDeposito {...iconProps} />;
        default:
            return <>{placeholder}</>;
    }
}
