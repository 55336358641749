import React from "react";
import classNames from "classnames";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoCalendar } from "@/components/MakoCalendar";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";

export const ConsultarMovimentacoesPage = () => {
    const { showError } = useToast();

    const formik = useFormik({
        initialValues: {
            sku: null,
            centro_estocagem: null,
            data_inicial: null,
            data_final: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                centro_estocagem: Yup.number()
                    .typeError("Selecione um centro de estocagem.")
                    .required("Selecione um centro de estocagem."),
                data_inicial: Yup.date()
                    .required("O campo 'data inicial' é obrigatório.")
                    .typeError("Informe uma data válida."),
                data_final: Yup.date()
                    .required("O campo 'data final' é obrigatório.")
                    .min(values.data_inicial || new Date(), "A data final não pode ser inferior a data inicial")
                    .typeError("Informe uma data válida."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    return (
        <PageBase>
            <h3>Consultar movimentação de estoque por item</h3>
            <form onSubmit={formik.handleSubmit}>
                <MakoBuscaSkuPersonalizada
                    skuValue={formik.values.sku}
                    skuChange={(e) => formik.setFieldValue("sku", e)}
                    skuError={formik.errors.sku}
                    className={classNames({ "p-invalid": formik.errors.sku })}
                />
                {formik.errors.sku && <small className="p-error">{formik.errors.sku}</small>}
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="centro-estocagem">Centro de estocagem *</label>
                        <Dropdown
                            id="centro-estrocagem"
                            name="centro_estocagem"
                            url="/produtos/centros-estocagem?query={id,nome}&limit=100"
                            optionValue="id"
                            optionLabel="nome"
                            placeholder="Selecione"
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="data-inicial">Data inicial *</label>
                        <MakoCalendar
                            id="data-inicial"
                            name="data_inicial"
                            valueCalendar={formik.values.data_inicial}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_inicial })}
                        />
                        {formik.errors.data_inicial && <small className="p-error">{formik.errors.data_inicial}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="data-final">Data final *</label>
                        <MakoCalendar
                            id="data-final"
                            name="data_final"
                            valueCalendar={formik.values.data_final}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_final })}
                        />
                        {formik.errors.data_final && <small className="p-error">{formik.errors.data_final}</small>}
                    </div>
                </div>
                <p>* Campos obrigatórios.</p>
                <div className="p-grid p-col-12">
                    <Button
                        icon="pi pi-search"
                        label="Pesquisar"
                        disabled
                        type="submit"
                        className="p-button-info p-mr-2"
                    />
                    <Button
                        icon="pi pi-trash"
                        label="Limpar"
                        type="reset"
                        className="p-button-warning p-mr-2"
                        onClick={() => formik.resetForm()}
                    />
                </div>
            </form>
        </PageBase>
    );
};
