import React, { useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoInputPeriodo } from "@/components/MakoInputs/MakoInputPeriodo";

import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_SERVICOS_CONTRATOSSERVICO } from "@/assets/constants/relatorios";
import { TIPO_STATUS_CONTRATO_CHOICE } from "@/assets/constants/constants";

import { dataToStr } from "@/assets/util/datas";

export const ContratosServico = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const { solicitarRelatorio } = useRelatorio();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            iniciacao_inicio: null,
            iniciacao_fim: null,
            finalizacao_inicio: null,
            finalizacao_fim: null,
            status: null,
        },
        onSubmit: handleSubmit,
    });

    const filtrosTemplate = [
        {
            key: ["iniciacao_inicio", "iniciacao_fim"],
            label: "Periodo inicial",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: ["finalizacao_inicio", "finalizacao_fim"],
            label: "Periodo final",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "status",
            label: "Status",
            optionLabel: "label",
            optionKey: "value",
            options: TIPO_STATUS_CONTRATO_CHOICE,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                iniciacao_inicio: Yup.date()
                    .required("O campo 'inicio' é obrigatório")
                    .typeError("Informe um 'inicio' válido"),
                iniciacao_fim: Yup.date()
                    .when("iniciacao_inicio", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(values.iniciacao_inicio || new Date(), "O 'final' não pode ser anterior a inicial")
                            .typeError("Informe um 'final' válido"),
                        otherwise: Yup.date().nullable(),
                    })
                    .required("O campo 'final' é obrigatório"),
                finalizacao_inicio: Yup.date()
                    .required("Este campo é obrigatório")
                    .typeError("Informe um 'inicio' válido"),
                finalizacao_fim: Yup.date()
                    .when("finalizacao_inicio", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(values.finalizacao_inicio || new Date(), "O 'final' não pode ser anterior a inicial")
                            .typeError("Informe um 'final' válido"),
                    })
                    .typeError("Informe um 'final' válido"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.iniciacao_fim !== null)
                dadosValidados.iniciacao_fim = dataToStr(dadosValidados.iniciacao_fim, "yyyy-MM-dd");
            if (dadosValidados.iniciacao_inicio !== null)
                dadosValidados.iniciacao_inicio = dataToStr(dadosValidados.iniciacao_inicio, "yyyy-MM-dd");
            if (dadosValidados.finalizacao_inicio !== null)
                dadosValidados.finalizacao_inicio = dataToStr(dadosValidados.finalizacao_inicio, "yyyy-MM-dd");
            if (dadosValidados.finalizacao_fim !== null)
                dadosValidados.finalizacao_fim = dataToStr(dadosValidados.finalizacao_fim, "yyyy-MM-dd");

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }

            solicitarRelatorio({
                chave: RELATORIO_SERVICOS_CONTRATOSSERVICO,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de contratos de servicos</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="status">Status:</label>
                            <Dropdown
                                id="status"
                                name="status"
                                placeholder="Selecione um status"
                                optionLabel="label"
                                optionValue="value"
                                options={TIPO_STATUS_CONTRATO_CHOICE}
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.status })}
                            />
                            {formik.errors.status && <small className="p-error">{formik.errors.status}</small>}
                        </div>
                        <MakoInputPeriodo
                            label="Periodo inicial"
                            nameInicio="iniciacao_inicio"
                            nameFinal="iniciacao_fim"
                            valueInicio={formik.values.iniciacao_inicio}
                            valueFinal={formik.values.iniciacao_fim}
                            errorInicio={formik.errors.iniciacao_inicio}
                            errorFinal={formik.errors.iniciacao_fim}
                            onChangeInicio={formik.handleChange}
                            onChangeFinal={formik.handleChange}
                        />
                        <MakoInputPeriodo
                            label="Periodo final"
                            nameInicio="finalizacao_inicio"
                            nameFinal="finalizacao_fim"
                            valueInicio={formik.values.finalizacao_inicio}
                            valueFinal={formik.values.finalizacao_fim}
                            errorInicio={formik.errors.finalizacao_inicio}
                            errorFinal={formik.errors.finalizacao_fim}
                            onChangeInicio={formik.handleChange}
                            onChangeFinal={formik.handleChange}
                        />
                    </div>
                    <div className="p-grid p-col-12 p-md-6 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={handlePreSubmit}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={limparFiltro}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
