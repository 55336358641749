import React, { useCallback, useMemo, useRef, useState } from "react";
import * as Yup from "yup";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoMultiSelect } from "@/components/MakoInputs/MakoMultiSelect";
import { MakoFormGerador } from "@/components/MakoFormGerador";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { calcularJurosComposto, calcularJurosSimples } from "@/assets/util/calcFinanceiros";
import { RELATORIO_PRODUTOS_TABELAPRECO } from "@/assets/constants/relatorios";
import { formatarCasasDecimais, gerarFileName } from "@/assets/util/util";
import { SIM_NAO_BOOLEAN, STATUS_ITEM } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

const { ExportCSV } = R.Buttons;

const FILTROS_VIEWSET = {
    vigencia: "tabela__vigencia",
    categoria: "sku__categoriasku__categoria__id",
    tags: "tabela__tag_cliente",
    sku_ativo: "sku_ativo",
    ordenacao: "order",
    sku__item__status: "sku__item__status",
    tabela__nome: "tabela__nome",
    sku__ativo: "sku__ativo",
    plano_recebimento: "plano_recebimento",
};

const OPTIONS_STATUS_ITEM = STATUS_ITEM.concat([{ id: "-1", label: "Qualquer", tag: null }]);

const OPTIONS_STATUS_SKU = [
    { id: true, label: "Ativo" },
    { id: false, label: "Inativo" },
    { id: "-1", label: "Qualquer" },
];

const OPTIONS_ORDENACAO = [
    { label: "Classificação + Descrição", value: "descricao_dinamica" },
    {
        label: "Classificação + Código",
        value: "codigo",
    },
];

const BASE_URL = "/relatorios/tabela-precos/";

const FILE_NAME = gerarFileName("Tabela Precos");

export const RelatorioTabelaPrecos = () => {
    const [nomeColunaPrazo, setNomeColunaPrazo] = useState("A prazo");
    const [plano_recebimento, setPlanoRecebimento] = useState(null);
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [optionsParcelas, setOptionsParcelas] = useState([]);
    const [exibirMarkup, setExibirMarkup] = useState(false);
    const [exibirPreco, setExibirPreco] = useState(false);
    const [tabelaPreco, setTabelaPreco] = useState(null);
    const [vigencia, setVigencia] = useState(null);
    const [taxaAlvo, setTaxaAlvo] = useState(0);
    const [parcela, setParcela] = useState(null);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const categoriaRef = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(categoriaRef, formRef, listagemRef, botaoCsv);

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    function formatNumberWithTwoDecimals(number) {
        const roundedNumber = number.toFixed(2);
        const formattedNumber = roundedNumber.toString().replace(".", ",");

        const parts = formattedNumber.split(",");

        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        const formattedResult = integerPart + (parts[1] ? "," + parts[1] : ",00");

        return formattedResult;
    }

    const vigenciaItemTemplate = (item) => {
        return (
            <div>
                <p>
                    <small>Descrição: {item.descricao}</small>
                </p>
                <p>
                    {dataToStr(item.data_inicio, "dd/MM/yyyy")} |{" "}
                    {item.data_fim !== null ? dataToStr(item.data_fim, "dd/MM/yyyy") : "Sem data de fim"}
                </p>
            </div>
        );
    };

    const filtrosTemplate = useMemo(() => {
        return [
            {
                key: "tabela__nome",
                label: "Nome tabela",
                path: "nome",
            },
            {
                key: "plano_recebimento",
                label: "Plano de recebimento",
                path: "descricao",
            },
            {
                key: "vigencia",
                label: "Vigência",
                formatter: (item) => {
                    return `${dataToStr(item.data_inicio, "dd/MM/yyyy")} | ${
                        item.data_fim ? dataToStr(item.data_fim, "dd/MM/yyyy") : "Sem data de fim"
                    }`;
                },
            },
            {
                key: "categoria",
                label: "Categoria",
                path: "nome",
                type: TIPOS_FILTROS_TEMPLATE.MULTISELECT,
            },
            {
                key: "tags",
                label: "Tags",
                path: "descricao",
                type: TIPOS_FILTROS_TEMPLATE.MULTISELECT,
            },
            {
                key: "sku__item__status",
                label: "Situação agrupador",
                options: OPTIONS_STATUS_ITEM,
                optionKey: "id",
                optionLabel: "label",
                type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            },
            {
                key: "sku__ativo",
                label: "Status produto",
                options: OPTIONS_STATUS_SKU,
                optionKey: "id",
                optionLabel: "label",
                type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            },
            {
                key: "ordenacao",
                label: "Ordenação",
                options: OPTIONS_ORDENACAO,
                optionKey: "value",
                optionLabel: "label",
                type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            },
            {
                key: "exibir_markup",
                label: "Exibir Markup?",
                options: SIM_NAO_BOOLEAN,
                optionLabel: "label",
                optionKey: "id",
                type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            },
            {
                key: "exibir_preco",
                label: "Exibir preco?",
                options: SIM_NAO_BOOLEAN,
                optionLabel: "label",
                optionKey: "id",
                type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            },
            {
                key: "parcelas",
                label: "Qtd. Parcelas",
                optionKey: "value",
                optionLabel: "label",
                options: optionsParcelas,
                type: TIPOS_FILTROS_TEMPLATE.CHOICE,
            },
        ];
    }, [optionsParcelas]);

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        const selecionados = categoriaRef.current;
        if (dadosValidados.tabela__nome) dadosValidados.tabela__nome = dadosValidados.tabela__nome.nome;
        if (dadosValidados.plano_recebimento) dadosValidados.plano_recebimento = dadosValidados.plano_recebimento.id;
        if (dadosValidados.vigencia) dadosValidados.vigencia = dadosValidados.vigencia.id;
        if (dadosValidados.tags?.length) dadosValidados.tags = dadosValidados.tags.map((t) => t.id);
        if (dadosValidados.sku__item__status === "-1") delete dadosValidados.sku__item__status;
        if (dadosValidados.sku__ativo === "-1") delete dadosValidados.sku__ativo;
        if (selecionados) dadosValidados.categoria = selecionados.map((c) => c.id);
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const validaParcelas = (val) => {
        if (!("maximo_parcelas" in val)) return false;
        if (val.maximo_parcelas === 1) return false;
        return true;
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                tabela__nome: Yup.object()
                    .required("Informe um 'nome de tabela' válido")
                    .default(null)
                    .typeError("Informe um 'nome' válido"),
                categoria: Yup.array().nullable().typeError("Informe 'categorias' válidas"),
                plano_recebimento: Yup.object()
                    .required("Informe um 'plano recebimento' válido")
                    .default(null)
                    .typeError("Informe um 'plano recebimento' válido"),
                vigencia: Yup.object()
                    .nullable()
                    .default(null)
                    .required("O campo 'vigência' é obrigatório")
                    .typeError("Informe uma 'vigência' válida"),
                tags: Yup.array().nullable().default(null).typeError("Informe 'tags' válidas"),
                sku__item__status: Yup.string().default("-1").typeError("Informe uma 'situação' válida"),
                sku__ativo: Yup.string().default("-1").typeError("Informe uma 'situação' válida"),
                ordenacao: Yup.string().nullable().default(null).typeError("Informe um tipo de 'ordenação' válido"),
                parcelas: Yup.number().when("plano_recebimento", {
                    is: (val) => validaParcelas(val),
                    then: Yup.number().required("Parcela requirida").typeError("Informe uma 'parcela válida'"),
                    otherwise: Yup.number().nullable(),
                }),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            const selecionados = categoriaRef.current;

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: selecionados,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.plano_recebimento)
                dadosValidados.plano_recebimento = dadosValidados.plano_recebimento.id;
            if (dadosValidados.tabela__nome) dadosValidados.tabela__nome = dadosValidados.tabela__nome.nome;
            if (dadosValidados.vigencia) dadosValidados.vigencia = dadosValidados.vigencia.id;
            if (dadosValidados.tags?.length) dadosValidados.tags = dadosValidados.tags.map((t) => t.id);
            if (dadosValidados.sku__item__status === "-1") delete dadosValidados.sku__item__status;
            if (dadosValidados.sku__ativo === "-1") delete dadosValidados.sku__ativo;
            if (selecionados) dadosValidados.categoria = selecionados.map((c) => c.id);

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_PRODUTOS_TABELAPRECO,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                console.log(_url);
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const getCategoriaCompleta = (e) => {
        categoriaRef.current = e;
    };

    const calculaVisualizacaoColuna = useCallback(
        (e, juros_compostos = false) => {
            const calculaJuros = juros_compostos ? calcularJurosComposto : calcularJurosSimples;
            if (parcela !== null) {
                const valor = formatarCasasDecimais(calculaJuros(e.a_prazo, taxaAlvo, parcela));
                const parcelas = `(${parcela} x R$ ${formatNumberWithTwoDecimals(
                    formatarCasasDecimais(valor / parcela)
                )})`.replace(".", ",");
                return `${formatNumberWithTwoDecimals(valor)} ${parcelas}`;
            }
            return `${formatNumberWithTwoDecimals(calculaJuros(e.a_prazo, taxaAlvo, parcela))}`.replace(".", ",");
        },
        [parcela, taxaAlvo]
    );

    const ajustePrecos = useCallback(
        (e) => {
            const calculaJuros = e.plano_recebimento.juros_compostos ? calcularJurosComposto : calcularJurosSimples;
            if (plano_recebimento.nome !== nomeColunaPrazo) {
                if (plano_recebimento?.maximo_parcelas === 1) {
                    return formatNumberWithTwoDecimals(calculaJuros(e.a_vista, plano_recebimento.taxa_alvo, 1));
                } else {
                    return calculaVisualizacaoColuna(e, plano_recebimento.juros_compostos);
                }
            } else {
                if (parcela === 1) {
                    return `${formatNumberWithTwoDecimals(e.a_vista)}`;
                } else {
                    const parcelas = `(${parcela} x R$ ${formatNumberWithTwoDecimals(
                        formatarCasasDecimais(e.a_vista / parcela)
                    )})`;
                    return `${formatNumberWithTwoDecimals(e.a_vista)} ${parcelas}`;
                }
            }
        },
        [plano_recebimento, calculaVisualizacaoColuna, nomeColunaPrazo, parcela]
    );

    const colunas = useMemo(() => {
        let colunasAux = [
            { field: "referencia", header: "Código" },
            { field: "descricao", header: "Descrição" },
            { field: "unidade_venda", header: "Unidade" },
            { field: "categoria", header: "Classificação" },
        ];
        if (exibirPreco)
            colunasAux.push({
                field: "preco",
                header: "R$ Custo",
                money: true,
                action: (e) => e.preco.toFixed(2).toString().replace(".", ","),
            });
        if (exibirMarkup) colunasAux.push({ field: "markup", header: "Markup", action: (e) => `${e.markup}` });

        colunasAux.push({
            field: "a_vista",
            header: "Preço de tabela",
            money: true,
            action: (e) => formatNumberWithTwoDecimals(e.a_vista),
        });
        colunasAux.push({
            field: "a_prazo",
            header: `${nomeColunaPrazo}`,
            money: true,
            action: (e) => ajustePrecos(e),
        });

        return colunasAux;
    }, [exibirPreco, exibirMarkup, nomeColunaPrazo, ajustePrecos]);

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_PRODUTOS_TABELAPRECO}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const handleChangeVigencia = (e) => {
        setVigencia(e.value);
        setFieldValue("tabela__nome", null);
        setFieldValue("plano_recebimento", null);
        setFieldValue("parcelas", null);
    };

    const handleChangeTabelaPreco = (e) => {
        setTabelaPreco(e.value);
        setFieldValue("plano_recebimento", null);
        setFieldValue("parcelas", null);
    };

    const handlePlanoRecebimento = (e) => {
        const { descricao, taxa_alvo, maximo_parcelas, minimo_parcelas } = e.value;
        setNomeColunaPrazo(descricao);
        setTaxaAlvo(taxa_alvo);
        setPlanoRecebimento(e.value);
        const sem_parcelas = maximo_parcelas === 0 && minimo_parcelas === 0;
        if (!sem_parcelas) {
            let options = [];
            for (let i = minimo_parcelas; i <= maximo_parcelas; i++) {
                options.push({
                    value: i,
                    label: `${i}`,
                });
            }
            setFieldValue("parcela", options.length === 1 ? options[0].value : null);
            setOptionsParcelas(options);
        }
    };

    const handleDisableParcelas = useCallback(() => {
        return optionsParcelas.length === 0;
    }, [optionsParcelas]);

    const handleExibirPreco = (e) => {
        setExibirPreco(e.value);
    };

    const handleExibirMarkup = (e) => {
        setExibirMarkup(e.value);
    };

    const changeParcela = (e) => {
        setParcela(e.value);
    };

    return (
        <R.Wrapper titulo={"de tabela de preços"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        tabela__nome: "",
                        vigencia: null,
                        tags: null,
                        plano_recebimento: null,
                        categoria: null,
                        sku_item_status: "-1",
                        sku__ativo: "-1",
                        ordenacao: "descricao_dinamica",
                        exibir_preco: false,
                        exibir_markup: false,
                        parcelas: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Vigência",
                        inputId: "vigencia",
                        inputName: "vigencia",
                        required: true,
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione uma vigência",
                            filter: true,
                            filterBy: "descricao",
                            url: "/produtos/precos-vigencia/",
                            getOnChange: handleChangeVigencia,
                            optionLabel: "descricao",
                            itemTemplate: vigenciaItemTemplate,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Nome tabela",
                        inputId: "tabela__nome",
                        inputName: "tabela__nome",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            placeholder: "Selecione uma 'tabela'",
                            filter: true,
                            filterBy: "nome",
                            url: `/produtos/tabelas-preco?vigencia=${vigencia?.id}`,
                            buscar: !!vigencia,
                            optionLabel: "nome",
                            getOnChange: handleChangeTabelaPreco,
                            lazy: true,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Plano de recebimento",
                        inputId: "plano_recebimento",
                        inputName: "plano_recebimento",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            filter: true,
                            filterBy: "descricao",
                            url: `/financeiro/planos-recebimentos/?tabelapreco=${tabelaPreco?.id}`,
                            buscar: !!tabelaPreco,
                            optionLabel: "descricao",
                            getOnChange: handlePlanoRecebimento,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Parcela",
                        inputId: "parcelas",
                        inputName: "parcelas",
                        component: Dropdown,
                        required: optionsParcelas.length !== 0,
                        handleDisable: handleDisableParcelas,
                        componentProps: {
                            placeholder: "Selecione",
                            options: optionsParcelas,
                            showClear: false,
                            optionValue: "value",
                            optionLabel: "label",
                            getOnChange: changeParcela,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Tags Perfil",
                        inputId: "tags",
                        inputName: "tags",
                        component: MakoMultiSelect,
                        componentProps: {
                            placeholder: "Selecione",
                            filter: true,
                            filterBy: "descricao",
                            url: "/pessoas/tags-perfis/",
                            optionLabel: "descricao",
                            showClear: true,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Categoria",
                        inputId: "categoria",
                        inputName: "categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: getCategoriaCompleta,
                            showClear: true,
                            selectionMode: "checkbox",
                            metaKeySelection: false,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Situação agrupador",
                        inputId: "sku__item__status",
                        inputName: "sku__item__status",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: OPTIONS_STATUS_ITEM,
                            optionValue: "id",
                            optionLabel: "label",
                            showClear: false,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Situação produto",
                        inputId: "sku__ativo",
                        inputName: "sku__ativo",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: OPTIONS_STATUS_SKU,
                            optionValue: "id",
                            optionLabel: "label",
                            showClear: false,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Ordenação",
                        inputId: "ordenacao",
                        inputName: "ordenacao",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: OPTIONS_ORDENACAO,
                            showClear: false,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Exibir custo?",
                        inputId: "exibir_preco",
                        inputName: "exibir_preco",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: SIM_NAO_BOOLEAN,
                            showClear: false,
                            optionLabel: "label",
                            optionValue: "id",
                            getOnChange: handleExibirPreco,
                        },
                    },
                    {
                        label: "Exibir markup?",
                        inputId: "exibir_markup",
                        inputName: "exibir_markup",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: SIM_NAO_BOOLEAN,
                            showClear: false,
                            optionLabel: "label",
                            optionValue: "id",
                            getOnChange: handleExibirMarkup,
                        },
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_PRODUTOS_TABELAPRECO}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
