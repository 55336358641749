import React, { forwardRef, useImperativeHandle, useState } from "react";
import { add } from "date-fns";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Dropdown } from "@/components/Dropdown";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { parseData } from "@/assets/util/datas";
import { parseNumber } from "@/assets/helpers/number";
import { VENDAS_VENCIMENTO_LIMITE_SEGUNDAPARCELA } from "@/assets/constants/parametros";
import { axiosPost } from "@/services/http";
import useVenda from "@/hooks/useVenda";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";

const Modal = (
    { formasRecebimento, planoRecebimento, dataMinVencimento, parcelas, taxaMax, taxaMin, onSuccess },
    ref
) => {
    const [visible, setVisible] = useState(false);
    const [aplicar, setAplicar] = useState(true);
    const { dadosBasicos } = useVenda();
    const { getParam } = useParam();
    const { showWarning, showError } = useToast();

    const formik = useFormik({
        initialValues: {
            numero_parcela: "",
            data_vencimento: null,
            valor_parcela: 0,
            total_parcelas: 0,
            taxa: 0,
            forma_recebimento: null,
            fixa_dia_vencimento: false,
            repetir_demais_parcelas: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const index = values.rowIndex > 0 ? values.rowIndex - 1 : 0;
            const vencParcelaAnterior =
                values.rowIndex === 0 ? parseData(dataMinVencimento) : parcelas[index].data_vencimento;
            const minVencimento =
                vencParcelaAnterior instanceof Date ? vencParcelaAnterior : parseData(vencParcelaAnterior);
            const param = getParam(VENDAS_VENCIMENTO_LIMITE_SEGUNDAPARCELA);
            const maxDiasVencSegundaParcela = param ? parseNumber(param.valor) : 31;
            const maxVencimento = add(parseData(dadosBasicos.data), { days: maxDiasVencSegundaParcela });
            const formSchema = Yup.object().shape({
                data_vencimento: Yup.date()
                    .required("O campo 'vencimento' é obrigatório.")
                    .min(minVencimento, "O vencimento não pode ser inferior ao vencimento da parcela anterior.")
                    .max(
                        maxVencimento,
                        `O vencimento não pode ser superior a ${maxDiasVencSegundaParcela} dias após a data da venda.`
                    )
                    .typeError("Informe uma data válida."),
                valor_parcela: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .min(0.01, "O campo 'valor' não pode ser ZERO."),
                forma_recebimento: Yup.number()
                    .required("O campo 'forma de recebimento' é obrigatório.")
                    .typeError("Escolha uma forma de recebimento."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const formaRecebimento = formasRecebimento.find((fr) => fr.id === values.forma_recebimento);
            const parcela = {
                ...values,
                forma_recebimento: formaRecebimento,
                _editada: true,
            };
            if (typeof onSuccess === "function") onSuccess(parcela);
            fecharModal();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    async function simularEncargos(values, modo = "taxa") {
        const { quantidade_parcelas, valor_parcela, valor_para_parcelar, valor_entrada, taxa } = values;
        const qtdParcelas = valor_entrada ? quantidade_parcelas - 1 : quantidade_parcelas;
        const valorFreteParcelado = formik.values.valor_frete / qtdParcelas;
        const valorParcela = valor_parcela - valorFreteParcelado;
        if (valorParcela * qtdParcelas < valor_para_parcelar) {
            showWarning({
                summary: "Aviso!",
                detail: "O valor de parcela é insuficiente para realizar o cálculo da taxa.",
                life: 2500,
            });
            return;
        }
        const body = {
            taxa: modo === "parcela" ? taxa : null,
            quantidade_parcelas: qtdParcelas,
            entrada: valor_entrada,
            valor_parcela: modo === "taxa" ? valorParcela : null,
        };
        const { status, data } = await axiosPost(`/vendas/vendas/${dadosBasicos?.id}/simular-parcelas/`, body);
        if (status === 200) {
            const { total_pedido, valor_parcela, taxa: taxa_calculada } = data;
            if (taxa_calculada > taxaMax) {
                await simularEncargos({ ...values, taxa: taxaMax }, "parcela");
            } else if (taxa_calculada < taxaMin) {
                await simularEncargos({ ...values, taxa: taxaMin }, "parcela");
            } else {
                const valorParcela = valor_parcela + valorFreteParcelado;
                formik.setFieldValue("valor_parcela", valorParcela);
                formik.setFieldValue("taxa", taxa_calculada);
                formik.setFieldValue("total_parcelas", total_pedido + formik.values.valor_frete);
                formik.setFieldValue("repetir_demais_parcelas", true);
                setAplicar(true);
            }
        } else if (status < 500) {
            showWarning({
                summary: "Aviso.",
                detail: data?.msg || "Não foi possível simular os encargos da venda.",
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Não foi possível simular os encargos da venda.",
                life: 3000,
            });
        }
    }

    const abrirModal = (parcela) => {
        formik.setValues({
            ...parcela,
            total_parcelas: parcelas.reduce((total, p) => total + parseNumber(p.valor_parcela), 0),
            forma_recebimento: parcela.forma_recebimento.id,
            fixa_dia_vencimento: false,
            repetir_demais_parcelas: false,
        });
        setVisible(true);
    };

    const fecharModal = () => {
        formik.resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const itens = [
        { label: "Sim", value: true },
        { label: "Não", value: false },
    ];

    const footer = (
        <div>
            <Button
                label="Calcular taxa"
                icon="pi pi-calculator"
                severity="help"
                onClick={() => simularEncargos(formik.values)}
            />
            <Button label="Cancelar" icon="pi pi-times" outlined severity="danger" onClick={fecharModal} />
            <Button
                type="submit"
                label="Aplicar"
                icon="pi pi-check"
                disabled={!aplicar}
                onClick={formik.handleSubmit}
                autoFocus
            />
        </div>
    );

    return (
        <Dialog
            visible={visible}
            header="Ajustando parcela"
            footer={footer}
            style={{ width: "60vw" }}
            onHide={fecharModal}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="num-parcela">Nº parcela</label>
                        <InputText
                            id="num-parcela"
                            name="numero_parcela"
                            disabled
                            value={formik.values.numero_parcela}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="vencimento">Vencimento *</label>
                        <MakoCalendar
                            id="vencimento"
                            name="data_vencimento"
                            valueCalendar={formik.values.data_vencimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_vencimento })}
                        />
                        {formik.errors.data_vencimento && (
                            <small className="p-error">{formik.errors.data_vencimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="valor-parcela">Valor da parcela</label>
                        <MakoInputMoeda
                            id="valor-parcela"
                            name="valor_parcela"
                            valueMoeda={formik.values.valor_parcela}
                            onChangeMoeda={(e) => {
                                formik.handleChange(e);
                                setAplicar(false);
                            }}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="soma-parcelas">Soma das parcelas</label>
                        <MakoInputMoeda
                            id="soma-parcelas"
                            name="total_parcelas"
                            disabled
                            valueMoeda={formik.values.total_parcelas}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="taxa">Taxa</label>
                        <InputText id="taxa" name="taxa" disabled value={formik.values.taxa} />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="forma-recebimento">Forma de recebimento *</label>
                        <Dropdown
                            id="forma-recebimento"
                            name="forma_recebimento"
                            options={formasRecebimento}
                            optionDisabled={(op) => !op.ativo}
                            disabled={!planoRecebimento?.altera_forma_parcela}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.forma_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_recebimento })}
                        />
                        {formik.errors.forma_recebimento && (
                            <small className="p-error">{formik.errors.forma_recebimento}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="fixa-dia-vencimento">Fixar o dia do vencimento</label>
                        <SelectButton
                            id="fixa-dia-vencimento"
                            name="fixa_dia_vencimento"
                            options={itens}
                            value={formik.values.fixa_dia_vencimento}
                            onChange={(e) => {
                                formik.setFieldValue("fixa_dia_vencimento", e.value);
                                formik.setFieldValue("repetir_demais_parcelas", true);
                            }}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="repetir-demais-parcelas">Repetir para as demais</label>
                        <SelectButton
                            id="repetir-demais-parcelas"
                            name="repetir_demais_parcelas"
                            options={itens}
                            value={formik.values.repetir_demais_parcelas}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
            </form>
        </Dialog>
    );
};

export const ModalEditarParcela = forwardRef(Modal);
