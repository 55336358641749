import { dataToStr } from "./datas";
import { formatDocumento, getObjetoValue, parseMoeda } from "./util";

export const TIPOS_FILTROS_TEMPLATE = {
    SKU: "sku",
    PESSOA: "pessoa",
    DATE: "date",
    DATEPERIOD: "dateperiod",
    CHOICE: "choice",
    MONEY: "money",
    MONEYPERIODO: "moneyperiod",
    BOOLEAN: "boolean",
    MULTISELECT: "multiselect",
};

export const montarFiltroTemplate = (template, valuesForm) => {
    let filtrosTemplateAplicados = [];
    Object.keys(valuesForm).forEach((_key) => {
        if (valuesForm[_key]) {
            let _filtro = template.find(({ key }) => {
                if (key instanceof Array) return key.some((value) => value === _key);
                return key === _key;
            });
            if (!_filtro) return;
            if (
                _filtro.type === TIPOS_FILTROS_TEMPLATE.DATEPERIOD ||
                _filtro.type === TIPOS_FILTROS_TEMPLATE.MONEYPERIODO
            ) {
                _filtro = { ..._filtro, start: valuesForm[_filtro.key[0]], end: valuesForm[_filtro.key[1]] };
            } else {
                _filtro = { ..._filtro, data: valuesForm[_key] };
            }
            if (!filtrosTemplateAplicados.find(({ label }) => label === _filtro.label))
                filtrosTemplateAplicados.push(_filtro);
        }
    });
    return filtrosTemplateAplicados;
};

export const gerarFiltroTemplate = (filtros = []) => {
    let _filtros = [];
    filtros.forEach((filtro) => {
        try {
            const { label = "", type = "", data, dateFormat = "dd/MM/yyyy", ...rest } = filtro;
            const { path = "", formatter } = rest;
            let { start, end } = rest;
            let _filtro = "";
            switch (type) {
                case TIPOS_FILTROS_TEMPLATE.PESSOA:
                    _filtros.push(`${label}: ${data.nome} - ${formatDocumento(data.identificacao)}`);
                    break;
                case TIPOS_FILTROS_TEMPLATE.DATE:
                    _filtros.push(`${label}: ${dataToStr(data, dateFormat)}`);
                    break;
                case TIPOS_FILTROS_TEMPLATE.DATEPERIOD:
                    if (start && end) {
                        _filtro = `${label}: ${dataToStr(start, dateFormat)}-${dataToStr(end, dateFormat)}`;
                        if (!_filtros.find((f) => f === _filtro)) _filtros.push(_filtro);
                    }
                    break;
                case TIPOS_FILTROS_TEMPLATE.CHOICE:
                    let { optionKey, optionLabel, options } = rest;
                    const option = options.find((op) => {
                        if (typeof data !== "object") return op[optionKey] === data;
                        return op[optionKey] === data[optionKey];
                    });
                    if (option) _filtros.push(`${label}: ${option[optionLabel]}`);
                    break;
                case TIPOS_FILTROS_TEMPLATE.MONEY:
                    _filtros.push(`${label}: ${parseMoeda(data)}`);
                    break;
                case TIPOS_FILTROS_TEMPLATE.MONEYPERIODO:
                    if (start && end) {
                        _filtro = `${label}: ${parseMoeda(start)}/${parseMoeda(end)}`;
                        if (!_filtros.find((f) => f === _filtro)) _filtros.push(_filtro);
                    }
                    break;
                case TIPOS_FILTROS_TEMPLATE.BOOLEAN:
                    _filtros.push(`${label}: ${data ? "Sim" : "Não"}`);
                    break;
                case TIPOS_FILTROS_TEMPLATE.MULTISELECT:
                    if (data instanceof Array) {
                        _filtro = `${label}: `;
                        _filtro =
                            _filtro +
                            data
                                .map((item) => {
                                    let value = typeof item === "object" && path ? getObjetoValue(item, path) : item;
                                    return !formatter ? value : formatter(value);
                                })
                                .join(", ");
                        _filtros.push(_filtro);
                    }
                    break;
                case TIPOS_FILTROS_TEMPLATE.SKU:
                    if (data instanceof Object) {
                        let value = "codigo" in data ? `(${data.codigo})` : "";
                        value = `${value} ${"descricao_reduzida" in data ? data.descricao_reduzida : ""}`;
                        _filtros.push(`${label}: ${value}`);
                    }
                    break;
                default:
                    let value = typeof data === "object" && path ? getObjetoValue(data, path) : data;
                    if (typeof formatter === "function") value = formatter(value);
                    _filtros.push(`${label}: ${value}`);
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    });
    return _filtros;
};
