import React, { createContext, useCallback, useState } from "react";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import * as pd from "@/assets/util/persistenciaDjango";
import useHttp from "@/hooks/useHttp";

const ProdutoContext = createContext({});

export const ProdutoProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [submitSku, setSubmitSku] = useState(false);
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [descricaoPadrao, setDescricaoPadrao] = useState([]);
    const [customFields, setCustomFields] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showError, showWarning } = useToast();
    const { httpDelete, httpGet, httpPatch, httpPost } = useHttp();

    const handlePosicaoDescricao = useCallback(
        (posicao, texto) => {
            let lista = descricaoPadrao;
            lista[posicao] = texto;
            setDescricaoPadrao(lista);
        },
        [descricaoPadrao]
    );

    const handleDadosBasicos = useCallback(
        async (values) => {
            if (!values.id) {
                const handlers = {
                    201: ({ data }) =>
                        setDadosBasicos({ ...data, cest: data.cest?.id || null, marca: data.marca?.id || null }),
                };

                await httpPost(
                    {
                        url: "/produtos/itens/",
                        body: {
                            ...values,
                            ncm: values.ncm.id,
                        },
                    },
                    handlers
                );
            } else {
                let diffProd = {};
                Object.entries(values).forEach(([k, v]) => {
                    if (v !== dadosBasicos[k]) {
                        if (k === "empresa") {
                            // não remover, necessário para gerar log backend
                            diffProd["empresa_anterior"] = dadosBasicos[k];
                        }
                        diffProd[k] = v;
                    }
                });
                if (Object.keys(diffProd).length > 0) {
                    if (diffProd.ncm) diffProd = { ...diffProd, ncm: diffProd.ncm?.id || diffProd.ncm?.value };
                    const handlers = {
                        200: ({ data }) =>
                            setDadosBasicos({ ...data, marca: data.marca?.id || null, cest: data.cest?.id || null }),
                    };

                    await httpPatch(
                        {
                            url: `/produtos/itens/${values.id}/`,
                            body: diffProd,
                        },
                        handlers
                    );
                }
                return { status: 204, data: values };
            }
        },
        [dadosBasicos, httpPost, httpPatch]
    );

    const handleValidar = async () => {
        const handlers = {
            200: ({ data }) => setDadosBasicos({ ...data, marca: data.marca?.id || null, cest: data.cest?.id || null }),
            400: ({ err }) => {
                if (err?.cadastro_validado?.length > 0 && err?.cadastro_validado[0] === "False") {
                    let msgErro = "";
                    err.erros.forEach((erro) => {
                        msgErro = `${msgErro} ${msgErro !== "" ? "-" : ""} ${erro}`;
                    });
                    if (msgErro !== "")
                        msgErro = `O cadastro não pode ser validado, por favor verifique as informações. (${msgErro} )`;
                    else msgErro = "O cadastro não pode ser validado, por favor verifique as informações.";

                    showWarning({
                        summary: "Informações pendentes",
                        detail: msgErro,
                        life: 10000,
                    });
                } else {
                    showError({
                        summary: "Falha ao validar",
                        detail: "Desculpe, não foi possível validar o status do produto.",
                        life: 10000,
                    });
                }
            },
        };

        showLoading();
        await httpPatch(
            {
                url: `/produtos/itens/${dadosBasicos.id}/`,
                body: {
                    status: "F",
                },
            },
            handlers
        );
        hideLoading();
    };

    const handleDetalhe = useCallback(
        async (values, op) => {
            let status = 400,
                json = {};

            if (op === pd.OP_CRUD_DJANGO.novo) {
                const handlers = {
                    201: ({ data }) => {
                        status = 201;
                        json = data;
                    },
                    400: (err) => (json = err),
                };

                await httpPost(
                    {
                        url: "/produtos/sku/",
                        body: {
                            ...values,
                            item: dadosBasicos.id,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                const handlers = {
                    200: ({ data }) => {
                        status = 200;
                        json = data;
                    },
                    400: (err) => (json = err),
                };

                await httpPatch(
                    {
                        url: `/produtos/sku/${values.id}/`,
                        body: {
                            ...values,
                            item: values.item.id,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else if (op === pd.OP_CRUD_DJANGO.deletar) {
                const handlers = {
                    204: () => (status = 204),
                    400: (err) => {
                        json = err;
                        showError({
                            summary: "Erro",
                            detail: "Desculpe, não foi possível remover o produto.",
                            life: 3000,
                        });
                    },
                };

                await httpDelete(
                    {
                        url: `/produtos/sku/${values.id}/`,
                    },
                    handlers
                );

                return { status, data: json };
            }
        },
        [showError, dadosBasicos?.id, httpPost, httpPatch, httpDelete]
    );

    const handleInfo = useCallback(
        async (url, values, op) => {
            let status = 400,
                json = {};

            if (op === pd.OP_CRUD_DJANGO.novo) {
                const handlers = {
                    201: ({ data }) => {
                        status = 201;
                        json = data;
                    },
                    400: (err) => {
                        json = err;
                        showError({
                            summary: "Erro",
                            detail: "Desculpe, não foi possível inserir.",
                            life: 3000,
                        });
                    },
                };

                await httpPost(
                    {
                        url,
                        body: values,
                    },
                    handlers
                );

                return { status, data: json };
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                const handlers = {
                    200: ({ data }) => {
                        status = 200;
                        json = data;
                    },
                    400: (err) => {
                        json = err;
                        showError({
                            summary: "Erro",
                            detail: "Desculpe, não foi possível editar.",
                            life: 3000,
                        });
                    },
                };

                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                return { status, data: json };
            }
        },
        [showError, httpPost, httpPatch]
    );

    const handleUnidade = useCallback(
        async (values, op) => {
            let status = 400,
                json = {};

            if (op === pd.OP_CRUD_DJANGO.novo) {
                if (values.tipo_mov_und_medida === "A") {
                    return await Promise.all(
                        ["V", "C", "T"].map(async (tipo) => {
                            const handlers = {
                                201: ({ data }) => {
                                    status = 201;
                                    json = data;
                                },
                            };

                            await httpPost(
                                {
                                    url: "/produtos/unidades-medida-sku/",
                                    body: {
                                        ...values,
                                        tipo_mov_und_medida: tipo,
                                    },
                                },
                                handlers
                            );

                            return { status, data: json };
                        })
                    );
                } else {
                    const handlers = {
                        201: ({ data }) => {
                            status = 201;
                            json = data;
                        },
                        400: ({ err }) => {
                            json = err;
                            showError({
                                summary: "Erro",
                                detail: "Desculpe, não foi possível inserir.",
                                life: 3000,
                            });
                        },
                    };

                    await httpPost(
                        {
                            url: "/produtos/unidades-medida-sku/",
                            body: values,
                        },
                        handlers
                    );

                    return { status, data: json };
                }
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                const handlers = {
                    200: ({ data }) => {
                        status = 200;
                        json = data;
                    },
                    400: ({ err }) => {
                        json = err;
                        showError({
                            summary: "Erro",
                            detail: "Desculpe, não foi possível editar.",
                            life: 3000,
                        });
                    },
                };

                await httpPatch(
                    {
                        url: `/produtos/unidades-medida-sku/${values.id}/`,
                        body: values,
                    },
                    handlers
                );

                return { status, data: json };
            }
        },
        [showError, httpPatch, httpPost]
    );

    const handleCustomFields = useCallback(
        async (values) => {
            let status = 400,
                json = {};
            try {
                showLoading();
                const promisses = values?.map(async (custom, index) => {
                    const dataJson = {
                        ...custom.value,
                        field: custom.value.field.id ? custom.value.field.id : custom.value.field,
                        object_id: custom.value.object_id ? custom.value.object_id : dadosBasicos?.id,
                    };

                    if (custom.value?.id) {
                        const handlers = {
                            201: ({ data }) => {
                                status = 201;
                                json = data;
                                values[index].value = data;
                            },
                        };

                        await httpPost(
                            {
                                url: "/custom-fields/values/",
                                body: dataJson,
                            },
                            handlers
                        );
                    } else {
                        const handlers = {
                            200: ({ data }) => {
                                status = 200;
                                json = data;
                                values[index].value = data;
                            },
                        };

                        await httpPatch(
                            {
                                url: `/custom-fields/values/${custom.value.id}/`,
                                body: dataJson,
                            },
                            handlers
                        );
                    }

                    return { status, data: json };
                });
                await Promise.all(promisses);
            } finally {
                setCustomFields(values);
                hideLoading();
            }
        },
        [showLoading, hideLoading, dadosBasicos?.id, httpPatch, httpPost]
    );

    const handlePreencherProduto = useCallback(
        async (idProduto) => {
            const handlers = {
                200: ({ data }) => {
                    setDadosBasicos({ ...data, marca: data.marca?.id || null, cest: data.cest?.id || null });
                },
            };

            showLoading();
            await httpGet({ url: `/produtos/itens/${idProduto}/` }, handlers);
            hideLoading();
        },
        [showLoading, hideLoading, httpGet]
    );

    const verificarCadastroGenerico = useCallback(async () => {
        let verificacao = false;
        if (!dadosBasicos?.id) return verificacao;

        const handlers = {
            200: ({ data }) => {
                verificacao = !data.results?.length;
            },
        };

        showLoading();
        await httpGet({ url: `/produtos/sku?limit=1&item__id=${dadosBasicos?.id}` }, handlers);
        hideLoading();

        return verificacao;
    }, [showLoading, hideLoading, dadosBasicos, httpGet]);

    return (
        <ProdutoContext.Provider
            value={{
                submit,
                submitSku,
                dadosBasicos,
                descricaoPadrao,
                customFields,
                setDescricaoPadrao,
                setSubmit,
                setSubmitSku,
                handleDadosBasicos,
                handleDetalhe,
                handleValidar,
                handleInfo,
                handleUnidade,
                handlePreencherProduto,
                handlePosicaoDescricao,
                handleCustomFields,
                verificarCadastroGenerico,
            }}
        >
            {children}
        </ProdutoContext.Provider>
    );
};

export default ProdutoContext;
