import { VisualizarLancamento } from "../../features";
import { Forms } from "../../forms";

const BASE_STYLE = { width: "55vw" };

export const INITIAL_STATE_OPERAR_MODAIS = {
    visible: false,
    header: "",
    style: BASE_STYLE,
    content: "",
    lastAction: "",
};

export const ACTIONS_OPERAR_MODAIS_CAIXA = {
    ABRIR: "ABRIR",
    FECHAR: "FECHAR",
    BLOQUEAR: "BLOQUEAR",
    DESBLOQUEAR: "DESBLOQUEAR",
    SUPRIR: "SUPRIR",
    SANGRAR: "SANGRAR",
    TRANSFERIR: "TRANSFERIR",
    RESUMIR: "RESUMIR",
    EDITARHISTORICO: "EDITARHISTORICO",
    VISUALIZARLANCAMENTO: "VISUALIZARLANCAMENTO",
    ESCONDERMODAL: "ESCONDERMODAL",
    ESTORNAR: "ESTORNAR",
    INCLUIROPERACAO: "INCLUIROPERACAO",
    ANALITICO: "ANALITICO",
};

export const operarModaisCaixaReducer = (state, action) => {
    const { type, payload } = action;
    const { formProps } = payload || {};
    let newState = {
        ...state,
        visible: true,
        lastAction: type,
    };

    if (type === state.lastAction && type !== ACTIONS_OPERAR_MODAIS_CAIXA.ESCONDERMODAL) {
        return { ...state, visible: true };
    }

    switch (type) {
        case ACTIONS_OPERAR_MODAIS_CAIXA.ABRIR:
            return {
                ...newState,
                style: { width: "80vw" },
                header: "Abrir caixa",
                content: <Forms.Abrir {...formProps} />,
            };
        case ACTIONS_OPERAR_MODAIS_CAIXA.FECHAR:
            return {
                ...newState,
                style: { width: "80vw" },
                header: "Fechar caixa",
                content: <Forms.Fechar {...formProps} />,
            };
        case ACTIONS_OPERAR_MODAIS_CAIXA.BLOQUEAR:
            return {
                ...newState,
                style: { width: "80vw" },
                header: `Bloquear o caixa '${payload.nomeCaixa}'`,
                content: <Forms.Bloquear {...formProps} />,
            };
        case ACTIONS_OPERAR_MODAIS_CAIXA.DESBLOQUEAR:
            return {
                ...newState,
                style: { width: "55vw" },
                header: `Desbloquear o caixa '${payload.nome}'`,
                content: <Forms.Desbloquear {...formProps} />,
            };
        case ACTIONS_OPERAR_MODAIS_CAIXA.SUPRIR:
            return {
                ...newState,
                header: "Efetuar suprimento",
                content: <Forms.Suprimento {...formProps} />,
            };
        case ACTIONS_OPERAR_MODAIS_CAIXA.SANGRAR:
            return {
                ...newState,
                header: "Efetuar sangria",
                content: <Forms.Sangria {...formProps} />,
            };
        case ACTIONS_OPERAR_MODAIS_CAIXA.TRANSFERIR:
            return {
                ...newState,
                style: { width: "60vw" },
                header: "Efetuar transferência",
                content: <Forms.Transferencia {...formProps} />,
            };
        case ACTIONS_OPERAR_MODAIS_CAIXA.RESUMIR:
            return {
                ...newState,
                style: { width: "60vw" },
                header: "Emissão de relatório de resumo de movimentação de caixa",
                content: <Forms.ResumoCaixa {...formProps} />,
            };
        case ACTIONS_OPERAR_MODAIS_CAIXA.VISUALIZARLANCAMENTO:
            return {
                ...newState,
                style: { width: "60vw" },
                header: `Lançamento N.°${payload.lancamentoId}`,
                content: <VisualizarLancamento {...formProps} />,
            };
        case ACTIONS_OPERAR_MODAIS_CAIXA.EDITARHISTORICO:
            return {
                ...newState,
                header: `Editar histórico - Lançamento N.°${payload.lancamentoId}`,
                content: <Forms.EditarHistorico {...formProps} />,
            };
        case ACTIONS_OPERAR_MODAIS_CAIXA.INCLUIROPERACAO:
            return {
                ...newState,
                style: { width: "65vw" },
                header: "Selecionando conta para pagar ou para receber",
                content: <Forms.Operacao />,
            };
        case ACTIONS_OPERAR_MODAIS_CAIXA.ESCONDERMODAL:
            return { ...state, visible: false };
        default:
            return state;
    }
};
