import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ModalOrcRapido } from "./ModalOrcamentoRapido";
import { ModalDetalheItemVenda } from "./ModalDetalheItemVenda";
import { ModalLoteSKU } from "./ModalLoteSKU";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { formatarCasasDecimais, parseMoeda } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";
import * as pd from "@/assets/util/persistenciaDjango";
import { PESSOAS_TAGPERFIL_PADRAO_CLIENTE, VENDA_TABELAPRECO_OBRIGATORIA } from "@/assets/constants/parametros";
import useVenda from "@/hooks/useVenda";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";

export const ItensVendaForm = () => {
    const [precoSKU, setPrecoSKU] = useState(null);
    const [unidadesVenda, setUnidadesVenda] = useState([]);
    const [qtdMinimaTabelaPreco, setQtdMinimaTabelaPreco] = useState(0);
    const [loading, setLoading] = useState(false);
    const [itemVendaSelecionado, setItemVendaSelecionado] = useState(null);
    const { dadosBasicos, bloqueiaItens, vendedor, handleItemVenda, atualizarValoresVenda } = useVenda();
    const listagemRef = useRef(null);
    const modalDetalheItemRef = useRef(null);
    const modalOrcamentoRef = useRef(null);
    const dropTabelaPrecoRef = useRef(null);
    const menuRef = useRef(null);
    const modalLoteSkuRef = useRef(null);
    const { showWarning } = useToast();
    const { getParam } = useParam();

    const eTroca = false;

    const tabelaPrecoObrigatoria = useMemo(() => {
        const param = getParam(VENDA_TABELAPRECO_OBRIGATORIA);
        return !!(param?.valor === "1");
    }, [getParam]);

    const { setFieldValue, resetForm, setValues, ...formik } = useFormik({
        initialValues: {
            sku: null,
            preco_tabela_preco: null,
            preco_base: 0,
            quantidade: 0,
            centro_estocagem: null,
            valor_unitario: 0,
            valor_desconto_unitario: 0,
            valor_total: 0,
            requer_aprovacao_preco: false,
            largura: 1,
            und_largura: null,
            comprimento: 1,
            und_comprimento: null,
            altura: 1,
            und_altura: null,
            cubagem: 1,
            und_cubagem: 1,
            unidade_venda: null,
            meses_encargos_incluidos: 0,
            observacoes: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                quantidade: Yup.number()
                    .when("sku", {
                        is: (val) => val && val.permite_fracionamento === true,
                        then: Yup.number().min(0.001, "O campo 'quantidade' não pode ser ZERO."),
                        otherwise: Yup.number().min(1, "O campo 'quantidade' não pode ser ZERO."),
                    })
                    .required("O campo 'quantidade' não pode ser ZERO."),
                preco_tabela_preco: tabelaPrecoObrigatoria
                    ? Yup.number()
                          .required("O campo 'tabela de preço' é obrigatório.")
                          .typeError("O campo 'tabela de preço' é obrigatório.")
                    : Yup.number().nullable(),
                unidade_venda: Yup.number()
                    .required("Selecione uma unidade de medida.")
                    .typeError("Selecione uma unidade de medida."),
                valor_unitario: Yup.number()
                    .required("O campo 'valor unitário' é obrigatório.")
                    .min(0.01, "Esse campo não pode ser 0 (ZERO)."),
                centro_estocagem: Yup.number()
                    .nullable()
                    .when("requer_aprovacao_preco", {
                        is: true,
                        then: Yup.number()
                            .required(
                                "Esse item precisará ser submetido a aprovação e para isso informe o centro de estocagem."
                            )
                            .typeError(
                                "Esse item precisará ser submetido a aprovação e para isso informe o centro de estocagem."
                            ),
                    })
                    .when("sku", {
                        is: (val) => val && val.lote_serial !== "-",
                        then: Yup.number()
                            .required("Esse item requer lote e para isso informe o centro de estocagem.")
                            .typeError("Esse item requer lote e para isso informe o centro de estocagem."),
                    }),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const precoOriginal = parseNumber(precoSKU.preco_base);
            const precoMinimo = parseNumber(precoSKU.preco_minimo);
            let precoApurado = values.valor_unitario;
            if (precoOriginal > values.valor_unitario) {
                precoApurado = values.valor_unitario;
            } else if (values.valor_desconto_unitario) {
                precoApurado = precoOriginal - values.valor_desconto_unitario;
            }
            if (precoApurado < precoMinimo) {
                showWarning({
                    summary: "Aviso",
                    detail: `O preço unitário mínimo para esse produto é de ${parseMoeda(precoMinimo)}.`,
                    life: 3000,
                });
                return;
            }
            const { valor_total, ...rest } = values;
            const item = {
                ...rest,
                desconto: rest.quantidade * rest.valor_desconto_unitario,
                valor_total_av: valor_total,
                valor_total_ap: valor_total,
            };
            const op = !item.id ? pd.OP_CRUD_DJANGO.novo : pd.OP_CRUD_DJANGO.editar;
            setLoading(true);
            await handleItemVenda(item, op);
            await atualizarValoresVenda();
            setLoading(false);
            listagemRef.current?.buscarDados();
            resetForm();
            setQtdMinimaTabelaPreco(0);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const obterPrecoTabela = useCallback(() => {
        if (formik.values.preco_tabela_preco && formik.values.sku) {
            const preco = dropTabelaPrecoRef.current?.getObject(
                (tp) => tp.preco_tabela_preco === formik.values.preco_tabela_preco
            );
            if (preco) {
                setFieldValue("valor_unitario", parseNumber(preco.preco_arredondado));
                setFieldValue("preco_base", parseNumber(preco.preco_arredondado));
                setFieldValue("meses_encargos_incluidos", preco.meses_encargos);
                const qtdMinima = parseNumber(preco.qtd_minima_tag);
                setQtdMinimaTabelaPreco(qtdMinima);
                setFieldValue("quantidade", qtdMinima);
            } else {
                showWarning({
                    summary: "Aviso!",
                    detail: "O produto não possui nenhum preço, portanto não pode ser vendido.",
                    life: 5000,
                });
            }
        }
    }, [formik.values.preco_tabela_preco, formik.values.sku, setFieldValue, showWarning]);

    const itensMenu = [
        {
            label: "Remover",
            icon: "pi pi-trash",
            command: async () => {
                await handleItemVenda(itemVendaSelecionado, pd.OP_CRUD_DJANGO.deletar);
                listagemRef.current?.buscarDados();
            },
        },
        {
            label: "Lote / Nº de série",
            icon: "pi pi-tags",
            disabled: bloqueiaItens || eTroca || itemVendaSelecionado?.sku.lote_serial === "-",
            command: () => modalLoteSkuRef.current?.abrirModal(itemVendaSelecionado),
        },
    ];

    const quantidadeBodyTemplate = (rowData) => {
        const formatarNumero = (num) => {
            const options = { currency: "BRL", minimumFractionDigits: 2 };
            return new Intl.NumberFormat("pt-BR", options).format(parseNumber(num));
        };
        const quantidades = [formatarNumero(rowData.quantidade)];
        if (rowData.sku.lote_serial !== "-") quantidades.push(formatarNumero(rowData.quantidade_lote_lancada));
        return <span>{quantidades.join(" / ")}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Menu model={itensMenu} popup ref={menuRef} />
                <Button
                    icon="pi pi-pencil"
                    disabled={bloqueiaItens || eTroca}
                    rounded
                    severity="warning"
                    onClick={() => {
                        setValues({
                            ...rowData,
                            preco_tabela_preco: rowData.preco_tabela_preco?.id,
                            unidade_venda: rowData.unidade_venda?.id,
                        });
                    }}
                    className="p-mr-2"
                />
                <Button
                    icon="pi pi-eye"
                    rounded
                    severity="help"
                    className="p-mr-2"
                    onClick={() => modalDetalheItemRef.current?.abrirModal(rowData.id)}
                />
                <Button
                    icon="pi pi-cog"
                    disabled={bloqueiaItens || eTroca}
                    rounded
                    onClick={(e) => {
                        menuRef.current?.toggle(e);
                        setItemVendaSelecionado(rowData);
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "sku.codigo", header: "Código", style: { maxWidth: "8%" } },
        { field: "sku.descricao_derivada", header: "Descrição" },
        {
            field: "quantidade",
            header: "Qtd. / Qtd lote",
            style: { maxWidth: "10%" },
            action: quantidadeBodyTemplate,
        },
        {
            field: "cubagem",
            header: "Cubagem",
            style: { maxWidth: "8%" },
            decimal: true,
        },
        {
            field: "preco_base",
            header: "Preço base",
            style: { maxWidth: "13%" },
            money: true,
        },
        {
            field: "valor_desconto_unitario",
            header: "Valor descontos",
            style: { maxWidth: "13%" },
            money: true,
        },
        {
            field: "valor_total_av",
            header: "Valor total",
            style: { maxWidth: "13%" },
            money: true,
        },
        {
            field: "action",
            header: "Ações",
            className: "p-text-center",
            style: { maxWidth: "10%" },
            align: "center",
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const onChangeDimensao = useCallback(
        (e) => {
            setFieldValue("cubagem", e);
        },
        [setFieldValue]
    );

    const setInformacoesItem = useCallback(() => {
        if (formik.values.sku instanceof Object) {
            const {
                codigo,
                descricao_derivada,
                descricao_reduzida,
                precosku_set,
                unidademedidasku_set,
                unidade_padrao,
            } = formik.values.sku;
            if (precosku_set.length === 0) {
                showWarning({
                    summary: "Aviso!",
                    detail: "O produto não possui nenhum preço cadastrado, portanto não pode ser vendido.",
                    life: 5000,
                });
                resetForm();
                return;
            }
            const unidades = unidademedidasku_set
                .filter((und) => und.ativo && und.tipo_mov_und_medida.id === "V")
                .map((und) => ({
                    id: und.unidade.id,
                    nome: `${und.unidade.nome} (${und.unidade.sigla})`,
                }));
            if (unidades.length === 0) {
                showWarning({
                    summary: "Aviso!",
                    detail: "O produto não possui nenhuma unidade de medida cadastrada, portanto não pode ser vendido.",
                    life: 5000,
                });
                resetForm();
                return;
            }
            setUnidadesVenda(unidades);
            setFieldValue("codigo", codigo);
            setFieldValue("descricao", descricao_derivada);
            setFieldValue("unidade_venda", unidade_padrao.id);
            setFieldValue("descricao_reduzida", descricao_reduzida);
            if (!formik.values.preco_tabela_preco) {
                const [preco] = precosku_set;
                setPrecoSKU(preco);
                setFieldValue("preco_base", parseNumber(preco.preco_base));
                setFieldValue("valor_unitario", parseNumber(preco.preco_base));
            }
        }
    }, [formik.values.sku, formik.values.preco_tabela_preco, resetForm, setFieldValue, showWarning]);

    useEffect(() => {
        setInformacoesItem();
    }, [setInformacoesItem]);

    const calcularValorTotalItem = useCallback(() => {
        if (formik.values.quantidade > 0 && formik.values.valor_unitario > 0) {
            const valorItem = formik.values.valor_unitario - formik.values.valor_desconto_unitario;
            const valorTotal = formik.values.cubagem * formik.values.quantidade * valorItem;
            setFieldValue("valor_total", formatarCasasDecimais(valorTotal));
        }
    }, [
        formik.values.cubagem,
        formik.values.quantidade,
        formik.values.valor_desconto_unitario,
        formik.values.valor_unitario,
        setFieldValue,
    ]);

    useEffect(() => {
        calcularValorTotalItem();
    }, [calcularValorTotalItem]);

    const urlBuscaTabelaPreco = useMemo(() => {
        if (!!dadosBasicos && formik.values.sku instanceof Object) {
            const param = getParam(PESSOAS_TAGPERFIL_PADRAO_CLIENTE);
            if (!param) return "";
            const sku = formik.values.sku?.id;
            const tagCliente = param.valor;
            const planoRecebimentoId = dadosBasicos?.plano_recebimento;
            return `/vendas/buscar-tabela-preco-sku/${sku}/${planoRecebimentoId}/${tagCliente}/`;
        }
        return "";
    }, [dadosBasicos, formik.values.sku, getParam]);

    const validarPercentualDesconto = useCallback(async () => {
        if (formik.values.valor_desconto_unitario > 0) {
            const percentDescontoConcedido = (formik.values.valor_desconto_unitario / formik.values.preco_base) * 100;
            if (percentDescontoConcedido > parseNumber(vendedor.max_desconto_avista)) {
                setFieldValue("requer_aprovacao_preco", true);
            }
        } else {
            setFieldValue("requer_aprovacao_preco", false);
        }
    }, [formik.values.valor_desconto_unitario, formik.values.preco_base, vendedor, setFieldValue]);

    useEffect(() => {
        validarPercentualDesconto();
    }, [validarPercentualDesconto]);

    const rowClass = (rowData) => {
        return {
            "table-recebimentos-overdue": rowData.requer_aprovacao_preco,
        };
    };

    const obterTabelasPrecoVigente = useCallback((data) => {
        return data.filter((tb) => tb.vigente);
    }, []);

    const onChangeTabela = ({ value }) => {
        setFieldValue("preco_tabela_preco", value);
        obterPrecoTabela();
    };

    return (
        <>
            {bloqueiaItens && <h5 className="p-error p-mt-0">O estágio atual não permite alterar os itens</h5>}
            <form onSubmit={formik.handleSubmit}>
                <MakoBuscaSkuPersonalizada
                    statusItem="F"
                    skuValue={formik.values.sku}
                    skuChange={(e) => setFieldValue("sku", e)}
                    skuError={formik.errors.sku}
                    disabledBusca={bloqueiaItens || formik.values.id}
                    exibeDimensoes
                    alturaValue={formik.values.altura}
                    onChangeAltura={(e) => setFieldValue("altura", e)}
                    larguraValue={formik.values.largura}
                    onChangeLargura={(e) => setFieldValue("largura", e)}
                    comprimentoValue={formik.values.comprimento}
                    onChangeComprimento={(e) => setFieldValue("comprimento", e)}
                    dimensaoValue={formik.values.cubagem}
                    onChangeDimensao={onChangeDimensao}
                    exibirVisualizacaoSku
                />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="tabela-preco">{`Tabela de preço ${tabelaPrecoObrigatoria ? "*" : ""}`}</label>
                        <Dropdown
                            ref={dropTabelaPrecoRef}
                            id="tabela-preco"
                            buscar={!!urlBuscaTabelaPreco}
                            url={urlBuscaTabelaPreco}
                            aposBuscar={obterTabelasPrecoVigente}
                            name="preco_tabela_preco"
                            optionValue="preco_tabela_preco"
                            optionLabel="descricao"
                            value={formik.values.preco_tabela_preco}
                            onChange={(e) => onChangeTabela(e)}
                            className={classNames({ "p-invalid": formik.errors.preco_tabela_preco })}
                        />
                        {formik.errors.preco_tabela_preco && (
                            <small className="p-error">{formik.errors.preco_tabela_preco}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade">Quantidade *</label>
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            min={qtdMinimaTabelaPreco}
                            permiteFracionario={formik.values.sku?.movimenta_cubagem}
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="und-venda">Unidade de venda *</label>
                        <Dropdown
                            id="und-venda"
                            name="unidade_venda"
                            options={unidadesVenda}
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.unidade_venda}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.unidade_venda })}
                        />
                        {formik.errors.unidade_venda && (
                            <small className="p-error">{formik.errors.unidade_venda}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-unitario">Valor unitário *</label>
                        <MakoInputMoeda
                            id="valor-unitario"
                            name="valor_unitario"
                            valueMoeda={formik.values.valor_unitario}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                        />
                        {formik.errors.valor_unitario && (
                            <small className="p-error">{formik.errors.valor_unitario}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="descontos">Desconto unitário</label>
                        <MakoInputMoeda
                            id="valor_desconto_unitario"
                            name="valor_desconto_unitario"
                            min={0}
                            max={parseNumber(formik.values.preco_base)}
                            valueMoeda={formik.values.valor_desconto_unitario}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="total-item">Total do item</label>
                        <MakoInputMoeda id="total-item" name="valor_total" disabled value={formik.values.valor_total} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="estoque">Centro de estocagem</label>
                        <MakoDropdownEstoques
                            id="estoque"
                            name="centro_estocagem"
                            empresaId={dadosBasicos?.empresa.id}
                            tipoCentroEstocagem={["C", "D"]}
                            tipoSKU="M"
                            optionValue="id"
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="obs">Observações</label>
                        <InputText
                            id="obs"
                            name="observacoes"
                            value={formik.values.observacoes}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-plus"
                            label="Gravar item"
                            loading={loading}
                            disabled={bloqueiaItens || eTroca}
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            label="Limpar"
                            icon="pi pi-trash"
                            disabled={bloqueiaItens || eTroca}
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={() => resetForm()}
                        />
                        <Button
                            label="Itens do orçamento rápido"
                            icon="pi pi-book"
                            type="button"
                            className="p-button-help p-mr-2 p-mb-2"
                            disabled={bloqueiaItens || eTroca}
                            onClick={() => modalOrcamentoRef.current?.abrirModal()}
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={`/vendas/itens-vendas/?venda=${dadosBasicos?.id}`}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    rowClassName: rowClass,
                }}
            />
            <ModalOrcRapido ref={modalOrcamentoRef} />
            <ModalDetalheItemVenda ref={modalDetalheItemRef} />
            <ModalLoteSKU ref={modalLoteSkuRef} onFechar={() => listagemRef.current?.buscarDados()} />
        </>
    );
};
