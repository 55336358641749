import React, { useCallback, useMemo, useRef, useState } from "react";

import { MakoButton as Button } from "@/components/MakoButton";
import { Sessoes } from "./sessoes";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

import { dataToStr, parseData } from "@/assets/util/datas";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { axiosPost } from "@/services/http";
import { isBefore } from "date-fns";

export const FormConfirmar = ({ previsoes = [], onCancel, onFinish }) => {
    const [previsoesEditadas, setPrevisoesEditadas] = useState(previsoes);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [permitirSubmit, setPermitirSubmit] = useState(false);

    const { showSuccess, showWarning, showError } = useToast();

    const entregasRef = useRef(null);

    const onSuccessCallback = useCallback((value) => {
        const { replicar, rota_entrega = null, ...rest } = value;
        const ativas = entregasRef.current?.getActiveIndexes() || [];
        setPrevisoesEditadas((prev) => {
            let newList = [...prev];
            if (!replicar && ativas.length) {
                ativas.forEach((index) => {
                    newList[index] = { ...newList[index], ...rest, rota_entrega };
                });
            } else {
                newList = newList.map((item) => ({ ...item, ...rest, rota_entrega }));
            }
            setPermitirSubmit(!newList.some((item) => !item?.datahora_entrega));
            return [...newList];
        });
    }, []);

    const confirmarEntregas = useCallback(async () => {
        let previsoes = [];
        const vendaId = previsoesEditadas.at(0).venda_id;
        previsoesEditadas.forEach((previsao) => {
            previsoes.push({
                id: previsao.id,
                datahora_entrega: dataToStr(previsao.datahora_entrega, "yyyy-MM-dd HH:mm:ss"),
                nome_recebedor: previsao.nome_recebedor,
                entregador_id: previsao.entregador_id.id,
                rota_entrega: previsao?.rota_entrega?.id || null,
            });
        });
        showLoading();
        const { status, data } = await axiosPost(`vendas/vendas/${vendaId}/finalizar-previsoes-entrega/`, {
            previsoes,
        });
        hideLoading();
        if (status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Entregas confirmadas com sucesso!.",
                life: 1500,
            });
            onFinish();
        } else if (status === 500) {
            showWarning({
                summary: "Aviso!",
                detail: data?.msg || "Não foi possivel finalizar as entregas.",
                life: 3000,
            });
        } else if (status === 409) {
            showError({
                summary: "Erro!",
                detail: data?.msg || "Não foi possivel finalizar as entregas.",
                life: 5000,
            });
        } else showError();
    }, [previsoesEditadas, showLoading, hideLoading, showSuccess, showWarning, showError, onFinish]);

    const fecharModal = () => {
        onCancel();
    };

    const previsaoRecente = useMemo(() => {
        const data = previsoes
            ?.map((p) => parseData(p.data_previsao))
            .toSorted((a, b) => {
                if (isBefore(a, b)) return 1;
                return -1;
            })
            .at(0);
        return data;
    }, [previsoes]);

    return (
        <div>
            <Sessoes.Form onSuccessCallback={onSuccessCallback} previsaoRecente={previsaoRecente} />
            <Sessoes.Entregas ref={entregasRef} itens={previsoesEditadas} />
            <div className="p-grid p-mt-2">
                <div className="p-col-12 p-md-6">
                    <Button
                        loading={loading}
                        type="button"
                        icon={MAKO_ICONS.GRAVAR}
                        label="Gravar"
                        className="p-mr-2 p-mb-2 p-button"
                        disabled={!permitirSubmit || loading}
                        onClick={confirmarEntregas}
                    />
                    <Button
                        type="button"
                        label="Cancelar"
                        className="p-button-danger p-mb-2"
                        onClick={fecharModal}
                        disabled={loading}
                    />
                </div>
            </div>
        </div>
    );
};
