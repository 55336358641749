import React, { useCallback, useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import useCrediario from "@/hooks/useCrediario";
import useHttp from "@/hooks/useHttp";

export const MakoComponenteRegra = ({ aba, regra, dados, setValores, campoPai }) => {
    const [criterios, setCriterios] = useState([]);
    const [valorComponentes, setValorComponentes] = useState([]);
    const { crediario } = useCrediario();
    const { httpGet } = useHttp();

    const listarOpcoes = useCallback(
        async (url) => {
            let lista = [];
            const handlers = {
                200: ({ data }) => {
                    lista = data.results;
                },
            };
            await httpGet({ url: `${url}&limit=10000` }, handlers);
            return lista;
        },
        [httpGet]
    );

    const listarCriterios = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                let componentes = [];
                data.results.forEach(async (criterio) => {
                    let opcoes = [];
                    if (criterio.padrao_criterio.tipo_dado.id === 4) {
                        opcoes = await listarOpcoes(criterio.padrao_criterio.url);
                    } else if (criterio.padrao_criterio.tipo_dado.id === 7) {
                        let valores = criterio.padrao_criterio.valores_possiveis.split("|");
                        valores.forEach((valor) => {
                            opcoes.push({ value: valor, label: valor });
                        });
                    }

                    componentes.push({
                        id: null,
                        criterio: criterio.id,
                        label: criterio.descricao,
                        nome: criterio.padrao_criterio.chave,
                        valor: criterio.padrao_criterio.tipo_dado.id === 6 ? false : null,
                        tipo: criterio.padrao_criterio.tipo_dado.id,
                        registro: null,
                        opcoes: await opcoes,
                        regra: crediario.regra,
                        op: "novo",
                    });
                });

                if (aba === "Protocolo") {
                    if (dados?.length > 0) {
                        componentes.forEach((campo) => {
                            const index = dados?.findIndex((e) => e.criterio === campo.criterio);
                            if (index !== -1) {
                                campo.valor = dados[index]["valor"];
                                campo.op = "editar";
                                campo.id = dados[index]["id"];
                            }
                        });
                        setValorComponentes(componentes);
                    } else setValorComponentes(componentes);
                }
                setCriterios(data.results);
            },
        };

        await httpGet(
            {
                url: `/crediario/criterio-crediario?padrao_criterio__aba=${aba}&regra=${regra?.id}&padrao_criterio__gera_componente=true&limit=3000`,
            },
            handlers
        );
    }, [dados, regra, aba, crediario.regra, listarOpcoes, httpGet]);

    useEffect(() => {
        listarCriterios();
    }, [listarCriterios]);

    const onChangeValor = (valor, index) => {
        if (valorComponentes.length > 0) {
            const fields = valorComponentes;
            fields[index]["valor"] = valor;
            setValorComponentes(fields);
            setValores(campoPai, fields);
        }
    };

    return valorComponentes
        ? criterios.map((criterio, index) => {
              const { id, padrao_criterio, descricao } = criterio;
              //INTEIRO POSITIVO -> 1 OU QUALQUER -> 3
              if ([1, 3].includes(padrao_criterio.tipo_dado.id))
                  return (
                      <div className="p-field p-col-12 p-md-3" key={id}>
                          <label htmlFor={padrao_criterio.chave}>{descricao} *</label>
                          <InputNumber
                              id={padrao_criterio.chave}
                              name={padrao_criterio.chave}
                              showButtons
                              locale="pt-BR"
                              min={padrao_criterio.tipo_dado.id === 1 ? 0 : null}
                              tooltip={padrao_criterio.descricao}
                              value={valorComponentes[index]?.valor}
                              onValueChange={(e) => onChangeValor(e.target.value, index)}
                          />
                      </div>
                  );
              //DATA DO PASSADO -> 2, FUTURO -> 4 OU QUALQUER -> 10
              else if ([2, 4, 10].includes(padrao_criterio.tipo_dado.id))
                  return (
                      <div className="p-field p-col-12 p-md-3" key={id}>
                          <label htmlFor={padrao_criterio.chave}>{descricao} *</label>
                          <MakoCalendar
                              id={padrao_criterio.chave}
                              name={padrao_criterio.chave}
                              maxDate={padrao_criterio.tipo_dado.id === 2 ? new Date() : null}
                              minDate={padrao_criterio.tipo_dado.id === 4 ? new Date() : null}
                              tooltip={padrao_criterio.descricao}
                              valueCalendar={valorComponentes[index]?.valor}
                              onChange={(e) => onChangeValor(e.target.value, index)}
                          />
                      </div>
                  );
              //DECIMAL
              else if (padrao_criterio.tipo_dado.id === 5)
                  return (
                      <div className="p-field p-col-12 p-md-3" key={id}>
                          <label htmlFor={padrao_criterio.chave}>{descricao} *</label>
                          <InputNumber
                              id={padrao_criterio.chave}
                              name={padrao_criterio.chave}
                              mode="decimal"
                              locale="pt-BR"
                              value={valorComponentes[index]?.valor}
                              onValueChange={(e) => onChangeValor(e.target.value, index)}
                          />
                      </div>
                  );
              //SIM OU NÃO (BOOLEAN)
              else if (padrao_criterio.tipo_dado.id === 6)
                  return (
                      <div className="p-field-checkbox p-col-12 p-md-3" key={id}>
                          <br></br>
                          <Checkbox
                              inputId={padrao_criterio.chave}
                              id={padrao_criterio.chave}
                              name={padrao_criterio.chave}
                              onChange={(e) => onChangeValor(e.checked, index)}
                              checked={valorComponentes[index]?.valor}
                              value={valorComponentes[index]?.valor}
                              className="p-mt-5"
                          />
                          <label htmlFor={padrao_criterio.chave} className="p-mt-5">
                              {descricao}
                          </label>
                      </div>
                  );
              //CONJUNTO DE VALORES
              else if (padrao_criterio.tipo_dado.id === 7)
                  return (
                      <div className="p-field p-col-12 p-md-4" key={id}>
                          <label htmlFor={padrao_criterio.chave}>{descricao} *</label>
                          <Dropdown
                              id={padrao_criterio.chave}
                              name={padrao_criterio.chave}
                              placeholder="Selecione"
                              options={valorComponentes[index]?.opcoes ? valorComponentes[index]?.opcoes : []}
                              filter
                              filterBy="label"
                              optionValue="value"
                              optionLabel="label"
                              tooltip={padrao_criterio.descricao}
                              value={valorComponentes[index]?.valor}
                              onChange={(e) => onChangeValor(e.target.value, index)}
                          />
                      </div>
                  );
              //DADO ARMAZENADO NO SISTEMA
              else if (padrao_criterio.tipo_dado.id === 8)
                  return (
                      <div className="p-field p-col-12 p-md-4" key={id}>
                          <label htmlFor={padrao_criterio.chave}>{descricao} *</label>
                          <Dropdown
                              id={padrao_criterio.chave}
                              name={padrao_criterio.chave}
                              placeholder="Selecione"
                              options={valorComponentes[index]?.opcoes ? valorComponentes[index]?.opcoes : []}
                              filter
                              filterBy="email"
                              optionValue="id"
                              optionLabel="email"
                              tooltip={padrao_criterio.descricao}
                              value={valorComponentes[index]?.valor}
                              onChange={(e) => onChangeValor(e.target.value, index)}
                          />
                      </div>
                  );
              //TEXTO SIMPLES
              else if (padrao_criterio.tipo_dado.id === 9)
                  return (
                      <div className="p-field p-col-12 p-md-3" key={id}>
                          <label htmlFor={padrao_criterio.chave}>{descricao} *</label>
                          <InputText
                              id={padrao_criterio.chave}
                              name={padrao_criterio.chave}
                              tooltip={padrao_criterio.descricao}
                              value={valorComponentes[index]?.valor}
                              onChange={(e) => onChangeValor(e.target.value, index)}
                              autoComplete="off"
                          />
                      </div>
                  );
              return null;
          })
        : null;
};
