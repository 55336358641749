import React, { useRef } from "react";
import { Button } from "primereact/button";

import { ModalLancamentoFrete } from "./ModalLancamentoFrete";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import { parseNumber } from "@/assets/helpers/number";
import { TIPO_FRETE } from "@/assets/constants/compras";
import permissoes from "@/assets/constants/permissoes";
import useEntrada from "@/hooks/useEntrada";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import { MakoFileUpload } from "@/components/MakoFileUpload";
import { url } from "@/services/axios";

export const FretesForm = () => {
    const listagemRef = useRef(null);
    const modalLancFreteRef = useRef(null);
    const { dadosBasicos, permiteAlterar, atualizarValoresEntrada } = useEntrada();
    const { showSuccess, showWarning, showError } = useToast();

    useClearRefs(listagemRef, modalLancFreteRef);

    const edicaoBloqueada = !permiteAlterar;

    const recalcular = async () => {
        await atualizarValoresEntrada();
        listagemRef.current?.buscarDados();
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_ENTRADA_ENTRADA_EDITAR]}
                    componente={Button}
                    disabled={edicaoBloqueada}
                    icon="pi pi-pencil"
                    onClick={() => modalLancFreteRef.current?.abrirModal(rowData)}
                    className="p-button-rounded p-button-warning p-mr-2 p-mt-1"
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_ENTRADA_ENTRADA_EDITAR]}
                    componente={BotaoDelete}
                    disabled={edicaoBloqueada}
                    url={`/compras/entrada-fretes/`}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja mesmo excluir o frete?</span>}
                    msgToastErroExclusao="O frete não pode ser excluído."
                    className="p-mt-1"
                    onDelete={recalcular}
                />
            </div>
        );
    };

    const numeroNfBodyTemplate = (rowData) => {
        if (!rowData.chave_nf) return <span>--</span>;
        return <span>{parseNumber(rowData.chave_nf.substr(25, 9))}</span>;
    };

    const tipoFreteBodyTemplate = (rowData) => {
        const tipoFrete = TIPO_FRETE.find((tf) => tf.value === rowData.tipo_frete);
        if (!tipoFrete) return <span>N/A</span>;
        return <span>{tipoFrete.label}</span>;
    };

    const colunas = [
        { field: "numero_nf", header: "Nº NF", style: { width: "8%" }, action: numeroNfBodyTemplate },
        { field: "chave", header: "Chave CTe" },
        { field: "tipo_frete", header: "Tipo do frete", style: { width: "28%" }, action: tipoFreteBodyTemplate },
        {
            field: "valor_frete",
            header: "Valor do frete",
            money: true,
            style: { width: "15%" },
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "10%" } },
    ];

    const onUploadXml = () => {
        showSuccess({
            summary: "Sucesso",
            detail: "XML enviado com sucesso!",
            life: 3000,
        });
        listagemRef.current?.buscarDados();
    };

    const onErrorXml = (e) => {
        const { xhr } = e;
        if (xhr.status === 400 || xhr.status === 404) {
            const { msg } = JSON.parse(xhr.response);
            showWarning({
                summary: "XML não importado",
                detail: msg,
                life: 5000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível importar o XML.",
                life: 3000,
            });
        }
    };

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Lançar frete"
                icon="pi pi-plus"
                disabled={edicaoBloqueada}
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => modalLancFreteRef.current?.abrirModal()}
            />
            <MakoFileUpload
                mode="basic"
                name="file"
                url={`${url()}/compras/upload-xml-entrada-cte/${dadosBasicos?.id}/`}
                accept=".xml"
                chooseLabel="Carregar XML"
                className="p-d-inline-block p-mb-2"
                chooseOptions={{ className: "p-button-help" }}
                invalidFileSizeMessageSummary="{0}: Tamanho do arquivo inválido, "
                invalidFileSizeMessageDetail="tamanho máximo permitido: {0}."
                maxFileSize={100000}
                onUpload={onUploadXml}
                onError={onErrorXml}
            />
        </>
    );

    return (
        <>
            <MakoListagem
                ref={listagemRef}
                painelEsquerdo={painelEsquerdoTabela}
                colunas={colunas}
                urlPesquisa={`/compras/entrada-fretes/?entrada=${dadosBasicos?.id}`}
                configTabela={{
                    lazy: true,
                    paginator: true,
                }}
            />
            <ModalLancamentoFrete ref={modalLancFreteRef} onSave={recalcular} />
        </>
    );
};
