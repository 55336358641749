import { useContext } from "react";

import RecebimentoContext from "@/contexts/recebimentoContext";

const useRecebimento = () => {
    const recebimento = useContext(RecebimentoContext);

    return recebimento;
};

export default useRecebimento;
