import React, { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";

import { parseData } from "@/assets/util/datas";

export const MakoTime = ({ valueTime, ...props }) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (typeof valueTime === "string" && valueTime.length > 0) {
            setValue(parseData(`1970-01-01T${valueTime}`));
        } else if (typeof valueTime === "object") {
            setValue(valueTime);
        }
    }, [valueTime]);

    return (
        <Calendar value={value} showIcon icon="pi pi-clock" placeholder="hh:mm" timeOnly hourFormat="24" {...props} />
    );
};
