import React, { useCallback, useEffect, useState } from "react";
import { Button } from "primereact/button";
import useLoading from "@/hooks/useLoading";
import { parseData } from "@/assets/util/datas";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

export const ImportarTabelaIbptPage = () => {
    const [versao, setVersao] = useState(null);
    const [vigencia, setVigencia] = useState(null);
    const [arquivo, setArquivo] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpGet } = useHttp();

    async function importaDados() {
        let csv = arquivo.split("\n");
        csv = csv.map((linha) => {
            return linha.split(";");
        });
        csv.shift();

        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Tabela IBPT atualizada para a versão mais recente!",
                    life: 3000,
                });
                carregaTabelaRegistrada();
            },
            400: () =>
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível atualizar a tabela IBPT. Tente novamente mais tarde ou contate o suporte para mais informações.",
                    sticky: 3000,
                }),
        };

        showLoading();
        await httpPost(
            {
                url: "/fiscal/importar-tabela-ibpt/",
                body: { arquivo: csv },
            },
            handlers
        );
        hideLoading();
    }

    const carregaTabelaRegistrada = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                if (data?.results?.length > 0) {
                    const [dia, mes, ano] = data?.results[0].vigenciafim.split("/");
                    setVigencia(parseData(`${ano}-${mes}-${dia}`));
                    setVersao(data.results[0]);
                }
            },
        };

        await httpGet(
            {
                url: "/fiscal/tabela-ibpt?limit=1&order_by=-id",
            },
            handlers
        );
    }, [httpGet]);

    useEffect(() => {
        carregaTabelaRegistrada();
    }, [carregaTabelaRegistrada]);

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsText(file);
        });
    }

    const onFileChange = async (e) => {
        if (e.target.files?.length > 0) {
            const file = e.target.files[0];
            const imageDataUrl = await readFile(file);
            setArquivo(imageDataUrl);
        }
    };

    return (
        <PageBase>
            <h5 className="p-fluid p-formgrid p-grid p-justify-center">
                <b>Tabela IBPT</b>
            </h5>
            <div className="p-fluid p-formgrid p-grid p-justify-center">
                <div className="p-field p-col-12 p-md-2">
                    <input
                        type="file"
                        onChange={onFileChange}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid p-justify-center">
                <div className="p-field p-col-12 p-md-2">
                    <Button
                        icon="pi pi-download"
                        label="Atualizar"
                        type="button"
                        onClick={() => importaDados()}
                        className="p-button-info"
                        disabled={!arquivo}
                    />
                </div>
            </div>
            <h5 style={{ textAlign: "center" }}>
                {versao ? (
                    <b
                        style={parseData(vigencia) >= new Date() ? { color: "green" } : { color: "red" }}
                    >{`Versão - ${versao.versao} | Vigência final: ${versao.vigenciafim}`}</b>
                ) : (
                    <b style={{ color: "#FF6347" }}>Não existem registros.</b>
                )}
            </h5>
        </PageBase>
    );
};
