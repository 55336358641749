import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import { Dropdown } from "@/components/Dropdown";

import { Forms } from "../../forms";

import { gerarFiltrosUrl } from "@/assets/util/util";
import { Dialog } from "primereact/dialog";

import useToast from "@/hooks/useToast";

const BASE_URL = "/financeiro/formas-recebimentos/";
const BASE_QUERY = {
    ativo: true,
    viacaixa: true,
};

export const Component = (
    {
        conta_financeira,
        filtros = {},
        value,
        onChange = () => {},
        successCallback = () => {},
        aposBuscar,
        formsProps,
        ...props
    },
    ref
) => {
    const [modal, setModal] = useState({
        visible: false,
        header: "Recebendo via PIX",
        width: "55vw",
        content: <div></div>,
    });

    const { showWarning } = useToast();

    const formasRef = useRef([]);
    const valueRef = useRef(value);

    const FORM_MATCHER = useMemo(() => {
        return {
            0: { header: "Recebendo em dinheiro", width: "55vw", Form: Forms?.ReceberDinheiro },
            1: { header: "Recebendo via cartão de crédito", width: "55vw", Form: Forms?.ReceberCartaoCredito },
            2: { header: "Recebendo via cartão de débito", width: "55vw", Form: Forms?.ReceberCartaoDebito },
            3: { header: "Recebendo via Pix", width: "55vw", Form: Forms?.ReceberPix },
        };
    }, []);

    const aposBuscarFormas = useCallback(
        (data) => {
            formasRef.current = data;
            if (typeof aposBuscar === "function") return aposBuscar(data);
            return data;
        },
        [aposBuscar]
    );

    const handleChange = useCallback(
        (e) => {
            const { tipo } = formasRef.current?.find(({ id }) => id === e.value) || {};
            valueRef.current = tipo?.id;
            onChange(e);
        },
        [onChange]
    );

    const onSuccess = useCallback(
        (...args) => {
            setModal((prev) => ({ ...prev, visible: false }));
            successCallback(...args);
        },
        [successCallback]
    );

    const show = useCallback(
        (values) => {
            const formulario = FORM_MATCHER[valueRef.current];
            if (!formulario) {
                showWarning({
                    summary: "Alerta!",
                    detail: "Processamento para a forma selecionada indisponível!",
                    life: 5000,
                });
            } else {
                const { header, width, Form } = formulario;
                let _props = {};
                if (typeof formsProps === "object") _props = formsProps[valueRef.current] || {};
                setModal({
                    visible: true,
                    header,
                    width,
                    content: <Form pendencia={values} {..._props} successCallback={onSuccess} />,
                });
            }
        },
        [showWarning, onSuccess, FORM_MATCHER, formsProps]
    );

    useImperativeHandle(ref, () => ({ show }), [show]);

    const url = useMemo(() => {
        const params = gerarFiltrosUrl({ ...BASE_QUERY, filtros });
        return `${BASE_URL}?${params}`;
    }, [filtros]);

    return (
        <>
            <Dropdown
                optionValue="id"
                optionLabel="descricao"
                {...props}
                url={url}
                value={value}
                onChange={handleChange}
                aposBuscar={aposBuscarFormas}
            />
            <Dialog
                header={modal.header}
                visible={modal.visible}
                onHide={() => setModal((prev) => ({ ...prev, visible: false }))}
                style={{ width: "55vw" }}
                breakpoints={{ "960px": "70vw" }}
            >
                {modal.content}
            </Dialog>
        </>
    );
};

export const DropdownFormasRecebimentos = forwardRef(Component);
