import React, { memo } from "react";

import { FormCustomFields } from "@/components/FormCustomFields";
import { PESSOAS_PESSOAFISICA, PESSOAS_PESSOAJURIDICA } from "@/assets/constants/contentTypes";
import usePessoa from "@/hooks/usePessoa";

const CustomFieldsForm = () => {
    const { pessoa, customFields, handleCustomFields } = usePessoa();

    const contentType =
        pessoa?.tipo_pessoa === "PF"
            ? PESSOAS_PESSOAFISICA
            : pessoa?.tipo_pessoa === "PJ"
            ? PESSOAS_PESSOAJURIDICA
            : null;
    const dadosPessoa =
        pessoa?.tipo_pessoa === "PF" ? pessoa?.perfil_pf : pessoa?.tipo_pessoa === "PJ" ? pessoa?.perfil_pj : null;

    return (
        <FormCustomFields
            localValues={customFields}
            contentType={contentType}
            instance={dadosPessoa?.id}
            onSubmit={(e) => handleCustomFields(e)}
        />
    );
};

export default memo(CustomFieldsForm);
