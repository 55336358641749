import React, { useCallback, useEffect, useState } from "react";

import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";

import { FINANCEIRO_CAIXAMOVIMENTO_TIPOS_MOTIVO } from "@/assets/constants/parametros";

import useParam from "@/hooks/useParam";

export const MakoInputMotivoBloqueio = ({ id, onChange = () => {}, ...props }) => {
    const [tipos, setTipos] = useState([]);
    const [options, setOptions] = useState([]);

    const { getParam } = useParam();

    const searchItems = useCallback(
        (event) => {
            let query = event.query;
            let _filteredItems = [];

            if (query?.length === 0) return setOptions(tipos);

            for (let i = 0; i < tipos.length; i++) {
                let item = tipos[i];
                if (item.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                    _filteredItems.push(item);
                }
            }
            setOptions(_filteredItems);
            return;
        },
        [tipos]
    );

    useEffect(() => {
        const parametro = getParam(FINANCEIRO_CAIXAMOVIMENTO_TIPOS_MOTIVO);
        if (parametro) {
            const tiposEnd = parametro.valor.split("|");
            return setTipos(tiposEnd.map((tipo) => ({ id: tipo, label: tipo })));
        }
        return setTipos([]);
    }, [getParam]);

    if (!tipos.length) {
        return <InputText id={id} onInput={onChange} {...props} />;
    }

    return (
        <AutoComplete
            inputId={id}
            suggestions={options}
            completeMethod={searchItems}
            field="label"
            dropdown
            onChange={onChange}
            maxLength={255}
            {...props}
        />
    );
};
