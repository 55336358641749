import { InputText } from "primereact/inputtext";

export const TextoFiltroTemplate = ({ keyFilter = null, maxLength = null, ...options }) => {
    return (
        <InputText
            value={options?.value ? options?.value : ""}
            onInput={(e) => options.filterCallback(e.target.value, options.index)}
            keyfilter={keyFilter}
            maxLength={maxLength}
        />
    );
};
