import useEmpresa from "@/hooks/useEmpresa";
import { DropdownFiltroTemplate } from "../DropdownFiltroTemplate";
import classNames from "classnames";

export const ContaFinanceiraFiltroTemplate = ({ filtrarPorEmpresa = true, ...options }) => {
    const { empresaSelecionadaId } = useEmpresa();

    const aposBuscar = (data) => {
        return data.map((item) => {
            return { ...item, label: `${item.codigo} - ${item.descricao}` };
        });
    };

    return (
        <DropdownFiltroTemplate
            dropdownProps={{
                url: !filtrarPorEmpresa
                    ? "/financeiro/contas-financeiras/"
                    : `/financeiro/contas-financeiras/?perfil=${empresaSelecionadaId}`,
                optionValue: "id",
                optionLabel: "label",
                aposBuscar: aposBuscar,
                buscar: filtrarPorEmpresa && !!empresaSelecionadaId,
                disabled: filtrarPorEmpresa && !empresaSelecionadaId,
                className: classNames({ "p-invalid": filtrarPorEmpresa && !empresaSelecionadaId }),
                placeholder: filtrarPorEmpresa && !empresaSelecionadaId ? "Selecione uma empresa!" : "Selecione",
            }}
            options={options}
        />
    );
};
