import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Form } from "./form";

const Component = ({ previsaoId }, ref) => {
    const [visible, setVisible] = useState(false);

    const show = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => {
        return {
            show,
            hide: () => setVisible(false),
        };
    });

    return (
        <Dialog
            visible={visible}
            header={"Detalhes da entrega / retirada"}
            onHide={() => setVisible(false)}
            style={{ width: "90vw" }}
        >
            <Form previsaoId={previsaoId} />
        </Dialog>
    );
};

export const Modal = forwardRef(Component);
