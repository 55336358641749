import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import { PageBase } from "@/components/PageBase";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import {
    ClienteFiltroTemplate,
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    MoedaFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

export const LancamentoManualBoletosPage = () => {
    const history = useHistory();

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_REDESOCIAL_INCLUIR]}
                componente={Button}
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/financeiro/boletos/lancamento-manual/form")}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_BANCO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    // onClick={() =>
                    //     setRedirecionar({
                    //         pathname: "/financeiro/cadastros/bancos/form",
                    //         state: { bancos: rowData },
                    //     })
                    // }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_BANCO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    // onClick={() => confirmarDelete(rowData)}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "pagador",
            header: "Pagador",
            filter: true,
            filterElement: ClienteFiltroTemplate,
            filterField: "pagador",
        },
        { field: "lote", header: "Lote", style: { width: "10%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "data_emissao",
            header: "Emissão",
            dateFormat: "dd/MM/yyyy",
            style: { width: "10%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "vencimento",
            header: "Vencimento",
            dateFormat: "dd/MM/yyyy",
            style: { width: "10%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "valor",
            header: "Valor",
            money: true,
            style: { width: "15%" },
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "valor_pago",
            header: "Valor pago",
            money: true,
            style: { width: "15%" },
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: actionBodyTemplate },
    ];

    return (
        <PageBase>
            <MakoListagem
                titulo="Boletos bancários"
                urlPesquisa="/financeiro/boletos-bancarios/?order_by=-vencimento"
                painelEsquerdo={cabecalhoTabela}
                filtarPorEmpresa
                fieldFiltroEmpresa="beneficiario"
                naoBuscarSemEmpresa
                colunas={colunas}
                configTabela={{ paginator: true, lazy: true }}
                filtros={{
                    pagador: { value: null, matchMode: "equals" },
                    lote: { value: null, matchMode: "equals" },
                    data_emissao: { value: null, matchMode: "equals" },
                    vencimento: { value: null, matchMode: "equals" },
                    valor: { value: null, matchMode: "equals" },
                    valor_pago: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
