import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";

import { Visualizar as Form } from "../../features";

import useClearRefs from "@/hooks/useClearRefs";

const Componente = ({ successCallback = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);

    const parametroRef = useRef(null);
    const formRef = useRef(null);

    useClearRefs(formRef, parametroRef);

    const success = useCallback(() => {
        setVisible(false);
        successCallback();
    }, [successCallback]);

    const show = (parametro) => {
        parametroRef.current = parametro;
        setVisible(true);
    };

    const onShow = useCallback(() => {
        formRef.current?.setParametro(parametroRef.current);
    }, []);

    const hide = () => setVisible(false);

    useImperativeHandle(ref, () => {
        return {
            show,
            hide,
        };
    });

    return (
        <Dialog
            visible={visible}
            header="Exibindo detalhes do parâmetro"
            onHide={hide}
            onShow={onShow}
            style={{ width: "80vw" }}
        >
            <Form ref={formRef} onSuccessCallback={success} />
        </Dialog>
    );
};

export const Visualizar = forwardRef(Componente);
