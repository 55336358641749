import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { MakoListagemHierarquica } from "@/components/MakoListagemHierarquica";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { dataToStr } from "@/assets/util/datas";
import { useHistory, useLocation } from "react-router-dom";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";

export const CategoriasPage = () => {
    const [categoriaSelecionada, setCategoriaSelecionada] = useState({ key: null, data: null });
    const listagemRef = useRef(null);
    const history = useHistory();
    const { state } = useLocation();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_CATEGORIAS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de categoria"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/produto/categorias/form",
                            categoria: rowData.data,
                            type: "edit",
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_CATEGORIAS_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/produtos/categorias/"
                    objetoId={rowData.data.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a categoria <b>{rowData.data.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A categoria não pode ser excluída."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_CATEGORIAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() =>
                    history.push({
                        pathname: "/produto/produto/categorias/form",
                        categoria: categoriaSelecionada.data,
                        type: "new",
                    })
                }
            />
        </>
    );

    const rowClass = (node) => {
        return {
            "p-text-bold": node.data.tipo === "T",
        };
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "120px" },
            filter: true,
            expander: true,
        },
        {
            field: "nome",
            header: "Nome",
            filter: true,
            style: { width: "220px" },
        },
        {
            field: "grupo.descricao",
            header: "Grupo",
            filter: true,
            style: { width: "220px" },
        },
        {
            field: "vigencia_inicial",
            header: "Vigência Inicial",
            style: { width: "140px" },
            filter: true,
            action: (e) => dataToStr(e.data.vigencia_inicial, "dd/MM/yyyy"),
        },
        {
            field: "vigencia_final",
            header: "Vigência Final",
            style: { width: "120px" },
            filter: true,
            action: (e) => dataToStr(e.data.vigencia_final, "dd/MM/yyyy"),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "100px" },
        },
    ];

    useEffect(() => {
        const { selecionada, key } = state || {};
        setCategoriaSelecionada({ key, data: selecionada });
    }, [state]);

    return (
        <PageBase>
            <MakoListagemHierarquica
                ref={listagemRef}
                urlPesquisa={"/produtos/categorias-menu/"}
                titulo="Categorias"
                colunas={colunas}
                cabecalho={cabecalhoTabela}
                tabelaConfig={{
                    selectionMode: "single",
                    selectionKeys: categoriaSelecionada.key,
                    selection: categoriaSelecionada.key,
                    onSelectionChange: ({ value }) => {
                        setCategoriaSelecionada((prev) => ({ ...prev, key: value }));
                    },
                    onSelect: ({ node }) => {
                        setCategoriaSelecionada((prev) => ({ ...prev, data: node.data }));
                    },
                    rowClassName: rowClass,
                    scrollable: true,
                }}
            />
        </PageBase>
    );
};
