import React, { useState } from "react";

import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const EnviarEmail = ({ className = "p-button-info p-mr-2", setFieldValue, handleSubmit, buttonConfig }) => {
    const [visible, setVisible] = useState(false);

    const fecharModal = () => {
        setVisible(() => false);
    };

    const handleConfirm = (emails, corpo) => {
        fecharModal();
        if (emails && emails.length > 0) {
            setFieldValue("emails", emails);
            setFieldValue("corpo_email", corpo);
            setFieldValue("listagem", false);
            handleSubmit(emails);
        }
    };

    return (
        <>
            <Dialog visible={visible} onHide={fecharModal} style={{ width: "60vw" }} header="Informar e-mails">
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
            <Button
                label="Enviar por e-mail"
                icon={MAKO_ICONS.EMAIL}
                type="button"
                onClick={() => setVisible(true)}
                className={className}
                {...buttonConfig}
            />
        </>
    );
};
