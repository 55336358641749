import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoInputPeriodo } from "@/components/MakoInputs/MakoInputPeriodo";
import { Dropdown } from "@/components/Dropdown";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_VENDAS_TABELAPRODUTOSVENDA } from "@/assets/constants/relatorios";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";
import MakoListagem from "@/components/MakoListagem";
import { InputText } from "primereact/inputtext";

const { ExportCSV } = R.Buttons;

const OPTIOONS_ORDENACAO_FILTROS = [
    { value: "venda__data", label: "Periodo (crescente)" },
    { value: "-venda__data", label: "Periodo (decrescente)" },
];

const BASE_URL = "/relatorios/vendas-faturadas/";

const FILE_NAME = gerarFileName("Tabela Produtos Venda");

const FILTROS_VIEWSET = {
    periodo_start: "venda__data__gte",
    periodo_end: "venda__data__lte",
    empresa: "venda__empresa",
    cliente: "venda__cliente",
    estagio_venda: "venda__sequencia_estagio_venda__tipo_estagio_venda", 
    codigo: "sku__codigo",
    descricao: "sku__descricao_derivada",
    fracionamento: "sku__permite_fracionamento",
    vendas_finalizadas: "vendas__situacao",
    fluxo_venda: "venda__estagio_venda",
    plano_recebimento: "venda__plano_recebimento__id",
    faturamento: "faturamento"
};

const OPTIONS_FRACIONAMENTO = [
    { label: "Permite fracionamento", value: true },
    { label: "Não permite fracionamento", value: false },
    { label: "Qualquer", value: -1 },
];

const OPTIONS_VENDAS = [
    { label: "Vendas finalizadas", value: true},
    { label: "Quaisquer vendas", value: false},
]

const OPTIONS_FATURAMENTO = [
    { label: "Totalmente faturado", value: "totalmente_faturado"},
    { label: "Faturamento menor que venda", value: "faturamento_menor_que_venda"},
    { label: "Sem faturamento", value: "sem_faturamento"},
    { label: "Qualquer", value: "qualquer"}
]

export const RelatorioTabelaProdutosVendas = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();
    const [formatDocumento] = useFormatCNPJCPF();

    const empresaSelecionada = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(empresaSelecionada, formRef, listagemRef, botaoCsv);

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "cliente",
            label: "Cliente",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["periodo_start", "periodo_end"],
            label: "Período emissão",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "estagio_venda",
            label: "Estágio de Venda",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "fluxo_venda",
            label: "Fluxo de Venda",
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "orderBy",
            label: "Ordenado por",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIOONS_ORDENACAO_FILTROS,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "codigo",
            label: "Código",
        },
        {
            key: "descricao",
            label: "Descrição",
        },
        {
            key: "fracionamento",
            label: "Fracionamento",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_FRACIONAMENTO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "vendas_finalizadas",
            label: "Vendas Finalizadas",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_VENDAS,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key] !== null && dados[key] !== undefined) {
                    if (key === "periodo_start" || key === "periodo_end")
                        return (filtros[key] = dataToStr(dados[key], "yyyy-MM-dd"));
                    return (filtros[key] = dados[key]);
                }
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.cliente) dadosValidados.cliente = dadosValidados.cliente.id;
        if (dadosValidados.estagio_venda) dadosValidados.estagio_venda = dadosValidados.estagio_venda.id;
        if (dadosValidados.fluxo_venda) dadosValidados.fluxo_venda = dadosValidados.fluxo_venda.id;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                periodo_start: Yup.date()
                    .nullable()
                    .typeError("Informe um 'periodo inicial' válido"),
                periodo_end: Yup.date()
                    .when("periodo_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.periodo_start || new Date(),
                                "O 'periodo final' não pode ser anterior a inicial"
                            )
                            .typeError("Informe um 'periodo final' válido"),
                    })
                    .nullable(),
                empresa: Yup.number()
                    .nullable()
                    .required("O campo 'empresa' é obrigatório")
                    .typeError("Informe uma 'empresa' válida"),
                cliente: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'cliente' válida"),
                estagio_venda: Yup.object()
                    .nullable()
                    .typeError("Informe um 'estágio de venda' válido"),
                fluxo_venda: Yup.object()
                    .nullable()
                    .typeError("Informe um 'estágio de venda' válido"),
                orderBy: Yup.string().nullable().typeError("Seleciona uma 'ordenação' válida."),
                codigo: Yup.string()
                    .nullable()
                    .test("codigo validation", "O 'codigo' deve ser maior que 0", (val) => {
                        if (val?.length >= 1 && parseInt(val) > 0) return true;
                        return !val;
                    })
                    .typeError("Informe um 'código' válido"),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.cliente) dadosValidados.cliente = dadosValidados.cliente.id;
            if (dadosValidados.estagio_venda) dadosValidados.estagio_venda = dadosValidados.estagio_venda.id;
            if (dadosValidados.fluxo_venda) dadosValidados.fluxo_venda = dadosValidados.fluxo_venda.id;
            if (dadosValidados.fracionamento === -1) delete dadosValidados.fracionamento;

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_VENDAS_TABELAPRODUTOSVENDA,
                    emails,
                    corpo_email,
                    filtros,
                    filtros_template: filtrosTemplateAplicados,
                    versao,
                    enviar_fila: background,
                });
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        if (k === "periodo_start" || k === "periodo_end") {
                            filtros.push(`${FILTROS_VIEWSET[k]}=${dataToStr(v, "yyyy-MM-dd")}`);
                        } else {
                            filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                        }
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                console.log(_url)
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "orcamento", header: "Orçamento", style: { width: "8%" } },
        { field: "data", header: "Data", style: { width: "8%" }, dateFormat: "dd/MM/yyyy" },
        { field: "cliente", header: "Cliente", action: (e) => `${e.cliente} - ${formatDocumento(e.cpf_cnpj)}`},
        { field: "plano_recebimento", header: "Plano Recebimento", align: "end" },
        {
            field: "qtd_parcelas",
            header: "Parcelas",
            align: "center",
            action: ({ qtd_parcelas, parcela }) => `${qtd_parcelas}`,
        },
        { field: "valor_total", header: "Total venda", money: true },
        { field: "total_faturado", header: "Total faturado", money: true },
    ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_VENDAS_TABELAPRODUTOSVENDA}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    return (
        <R.Wrapper titulo={"de vendas para faturar e vendas faturadas"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        periodo_start: null,
                        periodo_end: null,
                        empresa: null,
                        cliente: null,
                        estagio: null,
                        codigo: null,
                        orderby: "",
                        faturamento: "qualquer"
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa",
                        inputId: "empresa",
                        inputName: "empresa",
                        required: true,
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 6,
                    },
                    {
                        label: "Cliente",
                        inputId: "cliente",
                        inputName: "cliente",
                        component: MakoInputCliente,
                        fieldSize: 6,
                    },
                    {
                        inputId: "periodo",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        componentProps: {
                            label: "Periodo de emissão",
                            nameInicio: "periodo_start",
                            nameFinal: "periodo_end",
                            valueInicio: "periodo_start",
                            valueFinal: "periodo_end",
                            errorInicio: "periodo_start",
                            errorFinal: "periodo_end",
                        },
                        fieldSize: 5,
                    },
                    {
                        label: "Estágio de Fluxo",
                        inputId: "estagio_venda",
                        inputName: "estagio_venda",
                        component: Dropdown,
                        componentProps: {
                            url: "/vendas/tipos-estagios-vendas/",
                            optionLabel: "descricao",
                        }
                    },
                    {
                        label: "Fluxo",
                        inputId: "fluxo_venda",
                        inputName: "fluxo_venda",
                        component: Dropdown,
                        componentProps: {
                            url: "/vendas/estagios-vendas/",
                            optionLabel: "descricao",
                        }
                    },
                    {
                        label: "Código do produto:",
                        inputId: "codigo",
                        inputName: "codigo",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                            autoComplete: "off",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Status da Venda",
                        inputId: "vendas_finalizadas",
                        inputName: "vendas_finalizadas",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: OPTIONS_VENDAS,
                            showClear: true,
                        }
                    },
                    {
                        label: "Plano de recebimento",
                        inputId: "plano_recebimento",
                        inputName: "plano_recebimento",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um plano de recebimento",
                            url: "/financeiro/planos-recebimentos/",
                            optionLabel: "descricao",
                            optionValue: "id",
                            showClear: true,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Faturamento",
                        inputId: "faturamento",
                        inputName: "faturamento",
                        component: Dropdown,
                        componentProps: {
                            options: OPTIONS_FATURAMENTO,
                            optionValue: "value",
                            optionLabel: "label",
                            showClear: false,
                        }
                    }
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_VENDAS_TABELAPRODUTOSVENDA}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
