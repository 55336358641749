import { gerarId } from "./util";

export const OP_CRUD_DJANGO = {
    novo: "novo",
    editar: "editar",
    deletar: "deletar",
};

export function criarListaProvisoriaParaPersistencia(lista, objeto, operacao) {
    function novo(_lista, _objeto) {
        _lista.push({ ...objeto, _id: gerarId(), _status: OP_CRUD_DJANGO.novo });

        return _lista;
    }

    function editar(_lista, _objeto) {
        const keyId = _objeto._id ? "_id" : "id";
        const index = _lista?.findIndex((e) => e[keyId] === _objeto[keyId]);
        if (index > -1)
            _lista[index] = { ..._objeto, _status: keyId === "_id" ? OP_CRUD_DJANGO.novo : OP_CRUD_DJANGO.editar };

        return _lista;
    }

    function deletar(_lista, _objeto) {
        if (_objeto._id && lista.length > 0) {
            const index = _lista?.findIndex((e) => e._id === _objeto._id);
            if (index > -1) _lista.splice(index, 1);
        } else if (_objeto.id && _lista.length > 0) {
            const index = _lista?.findIndex((e) => e.id === _objeto.id);
            if (index > -1) _lista[index] = { ..._objeto, _status: OP_CRUD_DJANGO.deletar };
        }

        return _lista;
    }

    if (operacao === OP_CRUD_DJANGO.novo) {
        lista = novo(lista, objeto);
    }

    if (operacao === OP_CRUD_DJANGO.editar) {
        lista = editar(lista, objeto);
    }

    if (operacao === OP_CRUD_DJANGO.deletar) {
        lista = deletar(lista, objeto);
    }

    return lista;
}

export function montarObjetoParaPersistenciaDjango(itens) {
    let crud = {};

    itens.forEach((item) => {
        const { _status = OP_CRUD_DJANGO.editar } = item;

        if (_status === OP_CRUD_DJANGO.novo) {
            if (!crud.create) crud = { ...crud, create: [] };

            crud.create.push(item);
        }

        if (_status === OP_CRUD_DJANGO.editar) {
            if (!crud.update) crud = { ...crud, update: {} };

            crud.update[item.id] = item;
        }

        if (_status === OP_CRUD_DJANGO.deletar) {
            if (!crud.remove) crud = { ...crud, remove: [] };

            crud.remove.push(item.id);
        }
    });

    return crud;
}
