import { useContext } from "react";

import ProdutoContext from "@/contexts/produtoContext";

const useProduto = () => {
    const produto = useContext(ProdutoContext);

    return produto;
};

export default useProduto;
