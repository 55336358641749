import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Editor } from "primereact/editor";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BlockUI } from "@/components/BlockUI";
import { MakoCalendar } from "@/components/MakoCalendar";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import permissoes from "@/assets/constants/permissoes";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";
import { dataToStr } from "@/assets/util/datas";
import useHttp from "@/hooks/useHttp";

export const OrientacoesForm = ({ orientacao, pathname, onSubmit }) => {
    const [loading, setLoading] = useState(false);
    const { showSuccess, showError } = useToast();
    const { user, verifyPermission } = useAuth();
    const { httpPost, httpPatch, httpPut } = useHttp();

    const formik = useFormik({
        initialValues: orientacao || {
            titulo: "",
            descricao: "",
            ativo: true,
            revisor: null,
            ultimo_editor: null,
            publicador: null,
            publicado: false,
            data_revisao: null,
            data_publicacao: new Date(),
            ultima_edicao: null,
        },
        onSubmit: handleSubmit,
    });

    const podeRevisar = useMemo(() => {
        return verifyPermission([permissoes.CONFIGURACOES_ORIENTACOES_REVISAR]);
    }, [verifyPermission]);

    const podePublicar = useMemo(() => {
        return verifyPermission([permissoes.CONFIGURACOES_ORIENTACOES_PUBLICAR]);
    }, [verifyPermission]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                titulo: Yup.string().required("O campo 'título' é obrigatório."),
                descricao: Yup.string().required("O campo 'orientações' é obrigatório."),
            });
            formSchema.validateSync(values, { abortEarly: false });
            const orientacoes = {
                ...values,
                data_revisao: dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss"),
                endpoint: pathname,
                revisor: !values.id ? user.id : values.revisor.id,
                ultimo_editor: user.id,
                publicador: values.publicado ? user.id : null,
                data_publicacao: values.publicado ? dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss") : null,
            };

            if (!values.id) {
                const handlers = {
                    201: async ({ data }) => {
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Orientação cadastrada com sucesso.",
                            life: 1500,
                        });
                        formik.resetForm({ values: data });
                        if (typeof onSubmit === "function") onSubmit(data);
                    },
                };

                setLoading(true);
                await httpPost({ url: "/configuracoes/orientacoes/", body: orientacoes }, handlers);
                setLoading(false);
            } else {
                const handlers = {
                    200: async ({ data }) => {
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Orientação alterada com sucesso.",
                            life: 1500,
                        });
                        formik.resetForm({ values: data });
                        if (typeof onSubmit === "function") onSubmit(data);
                    },
                };

                setLoading(true);
                await httpPut({ url: `/configuracoes/orientacoes/${orientacoes.id}/`, body: orientacoes }, handlers);
                setLoading(false);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    async function publicarOrientacao(values, publicar = true) {
        try {
            const formSchema = Yup.object().shape({
                titulo: Yup.string().required("O campo 'título' é obrigatório."),
                descricao: Yup.string().required("O campo 'orientações' é obrigatório."),
            });
            formSchema.validateSync(values, { abortEarly: false });
            const { id, titulo, descricao } = values;
            const orientacoes = {
                titulo,
                descricao,
                publicado: publicar,
                publicador: publicar ? user.id : null,
                data_publicacao: publicar ? dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss") : null,
            };

            const handlers = {
                200: async ({ data }) => {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: `Orientação ${publicar ? "publicada" : "despublicada"} com sucesso.`,
                        life: 1500,
                    });
                    formik.resetForm({ values: data });
                    if (typeof onSubmit === "function") onSubmit(data);
                },
            };

            setLoading(true);
            await httpPatch({ url: `/configuracoes/orientacoes/${id}/`, body: orientacoes }, handlers);
            setLoading(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    return (
        <BlockUI loading={loading}>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="titulo">Título *</label>
                        <InputText
                            id="titulo"
                            name="titulo"
                            value={formik.values.titulo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.titulo })}
                        />
                        {formik.errors.titulo && <small className="p-error">{formik.errors.titulo}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="orientacoes">Orientações *</label>
                        <Editor
                            id="orientacoes"
                            name="descricao"
                            value={formik.values.descricao}
                            onTextChange={(e) => formik.setFieldValue("descricao", e.htmlValue)}
                            style={{ height: "200px" }}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="revisor">Criado por</label>
                        <InputText
                            id="revisor"
                            name="revisor"
                            disabled
                            value={formik.values.revisor?.usuario.username || ""}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="publicador">Publicador</label>
                        <InputText
                            id="publicador"
                            name="publicador"
                            disabled
                            value={formik.values.publicador?.usuario.username || ""}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data-publicacao">Data da publicação</label>
                        <MakoCalendar
                            id="data-publicacao"
                            name="data_publicacao"
                            disabled
                            valueCalendar={formik.values.data_publicacao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data-revisao">Data da revisão</label>
                        <MakoCalendar
                            id="data-revisao"
                            name="data_revisao"
                            disabled
                            valueCalendar={formik.values.data_revisao}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="editor">Último editor</label>
                        <InputText
                            id="editor"
                            name="ultimo_editor"
                            disabled
                            value={formik.values.ultimo_editor?.usuario.username || ""}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data-edicao">Data da edição</label>
                        <MakoCalendar
                            id="data-edicao"
                            name="ultima_edicao"
                            disabled
                            valueCalendar={formik.values.ultima_edicao}
                        />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-5">
                        <Checkbox
                            inputId="publicado"
                            name="publicado"
                            checked={formik.values.publicado}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="publicado">Publicar?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-5">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativo?</label>
                    </div>
                </div>
                <CamposObrigatorios />
                <Button
                    label="Gravar"
                    icon="pi pi-save"
                    type="submit"
                    disabled={!podeRevisar}
                    className="p-button-info p-mr-2"
                />
                <Button
                    label="Publicar"
                    icon="pi pi-megaphone"
                    type="button"
                    disabled={!formik.values.id || !(podeRevisar || podePublicar)}
                    className="p-button-help p-mr-2"
                    onClick={() => publicarOrientacao(formik.values)}
                />
                <Button
                    label="Despublicar"
                    icon="pi pi-times"
                    type="button"
                    disabled={!formik.values.id || !(podeRevisar || podePublicar)}
                    className="p-button-danger"
                    onClick={() => publicarOrientacao(formik.values, false)}
                />
            </form>
        </BlockUI>
    );
};
