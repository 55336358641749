import React from "react";
import { Skeleton as SkeletonPR } from "primereact";

export const Skeleton = ({ children, loading }) => {
    if (loading)
        return (
            <>
                <div className="p-d-flex p-mb-3">
                    <SkeletonPR width="40%" height="2.5rem" className="p-mr-2" />
                    <SkeletonPR width="40%" height="2.5rem" className="p-mr-2" />
                    <SkeletonPR width="40%" height="2.5rem" className="p-mr-2" />
                </div>
                <div className="p-d-flex p-mb-3">
                    <SkeletonPR width="20%" height="2.5rem" className="p-mr-2" />
                    <SkeletonPR width="100%" height="2.5rem" className="p-mr-2" />
                </div>
                <div className="p-d-flex p-mb-3">
                    <SkeletonPR width="50%" height="2.5rem" className="p-mr-2" />
                    <SkeletonPR width="50%" height="2.5rem" className="p-mr-2" />
                </div>
                <div className="p-d-flex p-mb-3">
                    <SkeletonPR width="50%" height="2.5rem" className="p-mr-2" />
                    <SkeletonPR width="25%" height="2.5rem" className="p-mr-2" />
                    <SkeletonPR width="25%" height="2.5rem" className="p-mr-2" />
                </div>
                <div className="p-d-flex p-mb-3">
                    <SkeletonPR width="100%" height="2.5rem" className="p-mr-2" />
                </div>
                <div className="p-d-flex p-mb-3">
                    <SkeletonPR width="100%" height="6.5rem" className="p-mr-2" />
                </div>
                <div className="p-d-flex p-mb-3">
                    <SkeletonPR width="25%" height="2.5rem" className="p-mr-2" />
                    <SkeletonPR width="25%" height="2.5rem" className="p-mr-2" />
                    <SkeletonPR width="25%" height="2.5rem" className="p-mr-2" />
                    <SkeletonPR width="25%" height="2.5rem" className="p-mr-2" />
                </div>
                <div className="p-d-flex p-mb-3">
                    <SkeletonPR width="50%" height="2.5rem" className="p-mr-2" />
                    <SkeletonPR width="50%" height="2.5rem" className="p-mr-2" />
                </div>
            </>
        );

    return children;
};
