import React, { useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { CodigoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import { PageBase } from "@/components/PageBase";

export const RegraApuracaoComissaoPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_REGRAAPURACAO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar regra de apuração"
                    to={{
                        pathname: "/vendas/cadastros/regra-apuracao-comissao/form",
                        state: { regra: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_REGRAAPURACAO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/vendas/regra-apuracao-comissao-venda/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir a regra de apuração "}
                            <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O tipo de entrada não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_REGRAAPURACAO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/vendas/cadastros/regra-apuracao-comissao/form"}
            />
        </>
    );

    const STATUS_HIERARQUIA = [
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
    ];

    const hierarquiaFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={STATUS_HIERARQUIA}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const STATUS_REGRA = [
        { value: true, label: "Ativo" },
        { value: false, label: "Desativado" },
    ];

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={STATUS_REGRA}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "8%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "descricao", header: "Descrição", filter: true },
        {
            field: "ativo",
            header: "Ativo",
            filter: true,
            align: "center",
            filterElement: statusFiltroTemplate,
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "INATIVO"),
            style: { width: "10%" },
        },
        {
            field: "usa_hierarquia",
            header: "Usa hierarquia?",
            filter: true,
            align: "center",
            filterElement: hierarquiaFiltroTemplate,
            action: ({ usa_hierarquia }) => gerarStatusBooleanTemplate(usa_hierarquia),
            style: { width: "10%" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Regras de Comissão"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={"/vendas/regra-apuracao-comissao-venda/"}
                responsiva
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    usa_hierarquia: { value: "", matchMode: "equals" },
                    ativo: { value: true, matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                }}
            />
        </PageBase>
    );
};
