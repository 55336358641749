import React, { useCallback, useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { ServicosForm } from "./formServicos";
import { DadosNotaForm } from "./formDadosNota";
import { NotaServicoProvider } from "@/contexts/notaServicoContext";
import useNotaServico from "@/hooks/useNotaServico";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const TabNotaServico = () => {
    const [activeTab, setActiveTab] = useState(0);
    const { handleServico, handlePreencherServico, submit } = useNotaServico();
    const { showError } = useToast();
    const { notaServico } = useLocation();
    const history = useHistory();

    const carregarNotaFiscal = useCallback(async () => {
        if (notaServico) await handlePreencherServico(notaServico);
    }, [handlePreencherServico, notaServico]);

    useEffect(() => {
        carregarNotaFiscal();
    }, [carregarNotaFiscal]);

    async function persisteNFS() {
        try {
            const { status } = await handleServico();
            if (status === 201 || status === 200) history.push("/fiscal/nota-servico/lista-notas");
            else
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
        } catch {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
        }
    }

    const alterarTab = (aba) => {
        if (aba === -1) {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        } else {
            if (activeTab < 3) {
                setActiveTab(activeTab + 1);
            }
        }
    };

    return (
        <PageBase>
            <h5>Nota Fiscal de Serviço</h5>
            <TabView className="tabview-custom" activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header="Dados Gerais" leftIcon="pi pi-book">
                    <DadosNotaForm />
                </TabPanel>
                <TabPanel header="Serviços" disabled={!submit} leftIcon="pi pi-cog">
                    <ServicosForm />
                </TabPanel>
            </TabView>
            <div className="p-grid p-fluid">
                {activeTab > 0 && activeTab !== 4 ? (
                    <div className="p-col-6 p-md-3">
                        <Button
                            label="Anterior"
                            icon="pi pi-angle-left"
                            className="p-button-info"
                            onClick={() => alterarTab(-1)}
                        />
                    </div>
                ) : null}
                {activeTab < 2 ? (
                    <div className="p-col-6 p-md-3">
                        <Button
                            label="Próximo"
                            icon="pi pi-angle-right"
                            iconPos="right"
                            className="p-button-info"
                            onClick={() => alterarTab(1)}
                            disabled={!!!submit}
                        />
                    </div>
                ) : null}
                <div className="p-col-6 p-md-3">
                    <Button
                        label="Sair"
                        className="p-button-danger"
                        onClick={() => history.push("/fiscal/nota-servico/lista-notas")}
                    />
                </div>
            </div>
        </PageBase>
    );
};

export const TabNotaServicoContext = (props) => {
    return (
        <NotaServicoProvider>
            <TabNotaServico props={props} />
        </NotaServicoProvider>
    );
};
