import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { MakoDialogConfirmarSenha } from "@/components/MakoDialogConfirmarSenha";
import useToast from "@/hooks/useToast";
import useNotificacao from "@/hooks/useNotificacao";
import useHttp from "@/hooks/useHttp";

export const BotaoReprovarNotificacao = ({ notificacao, comentario }) => {
    const [loading, setLoading] = useState(false);
    const modalSenhaRef = useRef(null);
    const { showSuccess, showWarning } = useToast();
    const { marcarMsgComoLida } = useNotificacao();
    const { httpPut } = useHttp();

    async function reprovarNotificacao() {
        const body = {
            notificacao: notificacao.id,
            comentario: comentario || "",
        };

        const handlers = {
            200: ({ data }) => {
                marcarMsgComoLida();
                showSuccess({
                    summary: "Sucesso!",
                    detail: data.msg,
                    life: 2000,
                });
            },
            400: ({ err }) =>
                showWarning({
                    summary: "Falhou...",
                    detail: err.msg,
                    life: 3000,
                }),
            404: ({ err }) =>
                showWarning({
                    summary: "Falhou...",
                    detail: err.msg,
                    life: 3000,
                }),
        };

        setLoading(true);
        await httpPut({ url: "/pessoas/reprovar-notificacao/", body }, handlers);
        setLoading(false);
    }

    const handleClick = () => {
        if (notificacao.tipo === "AS") {
            modalSenhaRef.current?.abrirModal();
        } else {
            reprovarNotificacao();
        }
    };

    return (
        <>
            <Button
                icon="pi pi-times"
                label="Reprovar"
                loading={loading}
                disabled={notificacao.status !== "P"}
                className="p-button-danger"
                onClick={handleClick}
            />
            <MakoDialogConfirmarSenha ref={modalSenhaRef} onConfirm={() => reprovarNotificacao()} />
        </>
    );
};
