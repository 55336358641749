import React, { useEffect, useImperativeHandle, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "@/components/Dropdown";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const Modal = ({ operacaoFiscalId }, ref) => {
    const [visible, setVisible] = useState(false);
    const [raiz, setRaiz] = useState(null);
    const [alvo, setAlvo] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpGet, httpPost } = useHttp();

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    async function importarTributos() {
        try {
            const handlers = {
                200: async ({ data }) => {
                    const skuBr = data.skuoperacaofiscalbr_set[0];
                    const skuUf = data.skuoperacaofiscalbr_set[0];

                    const handlers = {
                        201: () => {
                            showSuccess({
                                summary: "Sucesso",
                                detail: "Tributos importados com sucesso!",
                                life: 1500,
                            });
                            setVisible(false);
                        },
                    };

                    showLoading();
                    await httpPost(
                        {
                            url: `/fiscal/itens-operacoes-fiscais/${alvo.id}/`,
                            body: {
                                skuoperacaofiscalbr_set: {
                                    update: {
                                        [alvo.skuoperacaofiscalbr_set[0].id]: {
                                            ...skuBr,
                                            id: null,
                                            cst_cofins: skuBr.cst_cofins?.id,
                                            cst_ipi: skuBr.cst_ipi?.id,
                                            cst_pis: skuBr.cst_pis?.id,
                                            enquadramento_ipi: skuBr.enquadramento_ipi?.id,
                                        },
                                    },
                                },
                                skuoperacaofiscaluf_set: {
                                    update: {
                                        [alvo.skuoperacaofiscalbr_set[0].id]: { ...skuUf, id: null },
                                    },
                                },
                            },
                        },
                        handlers
                    );
                    hideLoading();
                },
            };

            showLoading();
            await httpGet(
                {
                    url: `/fiscal/itens-operacoes-fiscais/${raiz}/`,
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            hideLoading();
            showError();
        }
    }

    function optionValidate(value) {
        if (value.id === alvo?.id) return true;
        return false;
    }

    useEffect(() => {
        setRaiz(null);
    }, [alvo]);

    return (
        <Dialog
            header="Importar Tributos do Produto/Mercadoria"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "45vw", display: "block" }}
            onHide={() => setVisible(false)}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="item">Produto/mercadoria alvo: </label>
                        <Dropdown
                            id="item"
                            name="item"
                            value={alvo}
                            placeholder="Selecione..."
                            optionLabel="sku.descricao_reduzida"
                            url={`/fiscal/itens-operacoes-fiscais?operacao_fiscal=${operacaoFiscalId}&limit=10000`}
                            onChange={(e) => setAlvo(e.target.value)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="importar">Importar dados de:</label>
                        <Dropdown
                            id="importar"
                            name="importar"
                            value={raiz}
                            placeholder="Selecione..."
                            optionLabel="sku.descricao_reduzida"
                            optionValue="id"
                            className="p-mr-2"
                            optionDisabled={optionValidate}
                            url={`/fiscal/itens-operacoes-fiscais?operacao_fiscal=${operacaoFiscalId}&limit=10000`}
                            disabled={!alvo}
                            onChange={(e) => setRaiz(e.target.value)}
                        />
                    </div>
                </div>
                <div className="p-grid p-justify-center p-col-12 p-md-12">
                    <Button
                        label="Importar"
                        type="button"
                        icon="pi pi-align-justify"
                        className="p-button-info p-mr-2"
                        onClick={() => importarTributos()}
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalImportarTributos = forwardRef(Modal);
