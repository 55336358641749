import React, { useCallback, useEffect, useState } from "react";

import useAuth from "@/hooks/useAuth";

// DOC...
// permissao: (list) Lista com todas as permissões possíveis para acessar o componente
// componente: (Component) Componente que será renderizado de acordo com a permissão
// exibirDesabilitadoSemPermissao: (boolean) Quando o usuário não possui a permissão, exibe o componente porém desabilitado
// disabled: (boolean) Ignora as permissões e desabilita o componente
// props: (any) Todas as outras propriedades do componente raiz.

export const MakoControleAcesso = ({
    permissao,
    componente,
    exibirDesabilitadoSemPermissao = false,
    disabled,
    ...props
}) => {
    const [autorizado, setAutorizado] = useState(false);
    const [desabilitado, setDesabilitado] = useState(disabled || exibirDesabilitadoSemPermissao);
    const { verifyPermission } = useAuth();

    const Componente = componente;

    const renderizarComponente = useCallback(() => {
        if (disabled) {
            setDesabilitado(true);
            setAutorizado(true);
        } else {
            const permitido = verifyPermission(permissao);
            setAutorizado(permitido);
            setDesabilitado(false);

            if (!permitido) {
                if (exibirDesabilitadoSemPermissao) {
                    setDesabilitado(true);
                    setAutorizado(true);
                }
            }
        }
    }, [permissao, exibirDesabilitadoSemPermissao, disabled, verifyPermission]);

    useEffect(() => {
        renderizarComponente();
    }, [renderizarComponente]);

    return autorizado ? <Componente disabled={desabilitado} {...props} /> : null;
};
