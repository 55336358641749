import React, { useCallback, useMemo, useRef } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { dataToStr } from "@/assets/util/datas";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";

const url = "/produtos/categorias/";
const urlvoltar = "/produto/produto/categorias";

const TIPOCATEGORIA = [
    { id: "T", label: "Título" },
    { id: "C", label: "Categoria" },
];

const INITIAL_VALUES = {
    nome: "",
    descricao: "",
    vigencia_inicial: new Date(),
    vigencia_final: null,
    comissao_venda: null,
    categoria: null,
    grupo: null,
    tipo: null,
    exclusiva: false,
    nivel: 0,
};

export const CategoriasForm = () => {
    const { showError, showSuccess } = useToast();
    const { categoria = {}, type = "new" } = useLocation();
    const { showLoading, hideLoading } = useLoading();
    const inputCategoriaRef = useRef(null);
    const categoriaRef = useRef(null);
    const history = useHistory();
    const { httpPatch, httpPost } = useHttp();

    const handleNivel = (type, original) => {
        if (type === "edit") return original.nivel;
        if (original?.tipo === "T") return original.nivel + 1;
        else return original?.categoria?.nivel + 1 || 0;
    };

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: categoria?.id
            ? {
                  ...(type === "new" ? INITIAL_VALUES : categoria || {}),
                  categoria: categoria?.tipo === "T" ? categoria?.id : categoria?.categoria?.id,
                  exclusiva: categoria?.exclusiva,
                  grupo: categoria?.grupo?.id || null,
                  nivel: handleNivel(type, categoria),
              }
            : {
                  ...INITIAL_VALUES,
                  categoria: categoria || null,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                tipo: Yup.string().required("O campo 'tipo' é obrigatório."),
                vigencia_inicial: Yup.date().required("O campo 'vigência inicial' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const body = {
                ...values,
                categoria: values.categoria instanceof Object ? null : values?.categoria,
                vigencia_inicial: dataToStr(values.vigencia_inicial, "yyyy-MM-dd"),
                vigencia_final: dataToStr(values.vigencia_final, "yyyy-MM-dd"),
            };

            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Categoria cadastrada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        voltar(data);
                    },
                    400: () => {
                        showError();
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: body,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Categoria alterada com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        voltar();
                    },
                    400: () => {
                        showError();
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: body,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const voltar = (newValue = null) => {
        history.push({
            pathname: urlvoltar,
            state: { selecionada: newValue || categoria, key: newValue?.id || categoria?.id },
        });
    };

    const cancelar = () => {
        resetForm();
        voltar();
    };

    const onChangeCategoriaMae = (e) => {
        if (e.value) {
            const { nivel, grupo } = inputCategoriaRef.current?.getCategoria(e.value);
            setFieldValue("nivel", nivel + 1);
            setFieldValue("grupo", grupo?.id);
        }
        formik.handleChange(e);
    };

    const getCategoriaCompleta = useCallback((data) => {
        categoriaRef.current = data;
    }, []);

    const disabledExclusiva = useMemo(() => {
        let value = formik.values.categoria;
        if (!value) return false;
        value = categoriaRef.current;
        if (!value && categoria) return categoria.exclusiva;
        return value?.exclusiva;
    }, [formik.values.categoria, categoria]);

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova Categoria" : "Edição de cadastro de categoria"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="nome" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="descricao" label="Descrição" />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            autoComplete="off"
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="comissao_venda" label="Comissão de vendas" />
                        <Dropdown
                            id="comissao_venda"
                            name="comissao_venda"
                            placeholder="Selecione"
                            url="/vendas/comissoes-vendas/"
                            filter
                            filterBy="descricao"
                            showClear
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.comissao_venda}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.comissao_venda })}
                        />
                        {formik.errors.comissao_venda && (
                            <small className="p-error">{formik.errors.comissao_venda}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo" label="Tipo" obrigatorio />
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            placeholder="Selecione um tipo"
                            options={TIPOCATEGORIA}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.tipo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo })}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="categoria" label="Categoria mãe" />
                        <MakoDropdownCategoriasHierarquicas
                            ref={inputCategoriaRef}
                            id="categoria"
                            name="categoria"
                            placeholder="Selecione"
                            getCategoriaCompleta={getCategoriaCompleta}
                            categoriaTituloSelecionavel
                            apenasTitulo
                            showClear
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.categoria}
                            onChange={onChangeCategoriaMae}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="grupo" label="Grupo" />
                        <Dropdown
                            id="grupo"
                            name="grupo"
                            placeholder="Selecione"
                            url="/produtos/categorias-grupos/"
                            filter
                            filterBy="descricao"
                            showClear
                            optionValue="id"
                            optionLabel="descricao"
                            disabled={formik.values.tipo === "C"}
                            value={formik.values.grupo}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="vigencia_inicial" label="Vigência inicial" obrigatorio />
                        <MakoCalendar
                            id="vigencia_inicial"
                            name="vigencia_inicial"
                            valueCalendar={formik.values.vigencia_inicial}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vigencia_inicial })}
                        />
                        {formik.errors.vigencia_inicial && (
                            <small className="p-error">{formik.errors.vigencia_inicial}</small>
                        )}
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Label htmlFor="vigencia_final" label="Vigência final" />
                        <MakoCalendar
                            id="vigencia_final"
                            name="vigencia_final"
                            valueCalendar={formik.values.vigencia_final}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-ml-2 p-mb-3">
                        <Checkbox
                            inputId="exclusiva"
                            id="exclusiva"
                            name="exclusiva"
                            onChange={formik.handleChange}
                            checked={formik.values.exclusiva}
                            value={formik.values.exclusiva}
                            disabled={disabledExclusiva}
                            className="p-mt-5"
                        />
                        <Label htmlFor="exclusiva" label="Categoria exclusiva" className="p-mt-5" />
                    </div>
                </div>
                <br></br>
                <p>* Campos obrigatórios.</p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button label="Cancelar" type="reset" className="p-button-danger p-mr-2" onClick={cancelar} />
                </div>
            </form>
        </PageBase>
    );
};
