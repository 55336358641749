import React, { useMemo } from "react";

import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";

import MakoListagem from "@/components/MakoListagem";

import { gerarFiltrosUrl, gerarStatusBooleanTemplate, gerarStringAleatoria } from "@/assets/util/util";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";

const freteTemplate = ({ data, model }) => {
    if (model === "ent") return gerarStatusBooleanTemplate(false);
    const { frete } = data || {};
    return gerarStatusBooleanTemplate(frete);
};

const parcelaTemplate = ({ data, parcela, model }) => {
    const target = `${gerarStringAleatoria(20, false).replace(/\d|,/g, "")}${model}`;
    const { label } = SIM_NAO_BOOLEAN.find(({ id }) => id === data?.frete) || {};
    let content = `${model === "dev" ? "Parcela devolução fornecedor" : "Parcela entrada"}`;
    if (model === "dev") content = `${content}\nReferente ao frete: ${label}`;
    return (
        <>
            <Tooltip target={`.${target}`} mouseTrack mouseTrackLeft={10} content={content} />
            <span className={target}>{parcela}</span>
        </>
    );
};

const situacaoTemplate = ({ data, model }) => {
    const { pagamento } = data;
    if (model === "ent") {
        const { quitado } = data;
        return <Tag severity={quitado ? "success" : "warning"} value={quitado ? "QUITADO" : "PENDENTE"} />;
    }
    const { natureza } = pagamento || {};
    if (natureza) return <Tag severity="info" value={natureza === "E" ? "CRÈDITO" : "SAÍDA"} />;
    return <Tag severity="info" value="CRÉDITO" />;
};

const rowClassName = ({ data, model }) => {
    const { quitado } = data;
    return {
        "table-parcelas-devfornecedor-devolucao": model === "dev",
        "table-parcelas-devfornecedor-devolucao-quitado": model === "ent" && quitado,
    };
};

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { width: "10%" },
};

const BASE_COLUMNS = [
    { field: "parcela", header: "Parcela", style: { width: "4%" }, align: "center", action: parcelaTemplate },
    { field: "data", header: "Frete", style: { width: "4%" }, align: "center", action: freteTemplate },
    {
        field: "data_vencimento",
        header: "Vencimento",
        dateFormat: "dd/MM/yyyy",
        style: { width: "6%" },
        align: "center",
    },
    { field: "forma.descricao", header: "Forma de pgto" },
    { field: "", header: "Situação", style: { width: "6%" }, align: "center", action: situacaoTemplate },
    { field: "valor", header: "Valor", money: true, style: { width: "8%" } },
    { field: "valor_abater", header: "Para abater", money: true, style: { width: "8%" } },
    { field: "valor_receber", header: "Para receber", money: true, style: { width: "8%" } },
];

const BASE_URL = "/compras/parcelas-devolucao-fornecedor-com-entrada/{id}/";

export const Parcelas = ({
    listagemRef,
    devolucaoFornecedorId,
    actions,
    configTabela = {},
    filtros = {},
    titulo,
    fazerBusca = true,
}) => {
    const colunas = useMemo(() => {
        if (actions) return [...BASE_COLUMNS, { ...BASE_COLUMN_ACTIONS, action: actions }];
        return BASE_COLUMNS;
    }, [actions]);

    const url = useMemo(() => {
        const _url = BASE_URL.replace("{id}", devolucaoFornecedorId);
        const params = gerarFiltrosUrl(filtros);
        return `${_url}?${params}`;
    }, [devolucaoFornecedorId, filtros]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo={titulo}
            urlPesquisa={url}
            colunas={colunas}
            fazerBusca={!!devolucaoFornecedorId && fazerBusca}
            configTabela={{ lazy: true, paginator: true, rowClassName, ...configTabela }}
        />
    );
};
