import { formatarCasasDecimais } from "./util";

export function gerarParcelas(valorTotal, numParcelas) {
    let _parcelas = [];
    valorTotal = parseFloat(valorTotal);
    numParcelas = parseInt(numParcelas);

    const valorParcela = parseFloat((valorTotal / numParcelas).toFixed(2));

    for (let x = 0; x < numParcelas; x++) {
        _parcelas.push(valorParcela);
    }

    const totalParcelas = _parcelas.reduce((total, p) => total + p, 0);

    if (totalParcelas !== valorTotal) {
        const residuoParcela = valorTotal - totalParcelas;

        _parcelas[0] = parseFloat((valorParcela + residuoParcela).toFixed(2));
    }

    return _parcelas;
}

export function calcularJurosComposto(valor, taxa, periodos, retornarTotal = true) {
    const total = valor * Math.pow(1 + taxa / 100, periodos);
    if (retornarTotal) return total;
    return total - valor;
}

export function calcularJurosSimples(valor, taxa, periodos, retornarTotal = true) {
    const taxaMensal = taxa / 100;
    const total = valor + valor * taxaMensal * periodos;
    if (retornarTotal) return total;
    return total - valor;
}

export function somarValorMonetario(values, prop) {
    const total = values.reduce((total, item) => total + parseFloat(item[prop]), 0);

    return formatarCasasDecimais(total);
}

export function calcularMarkup(preco_venda = 0, preco_custo = 0) {
    preco_venda = parseFloat(preco_venda);
    preco_custo = parseFloat(preco_custo);
    return (preco_venda - preco_custo) / preco_custo + 1;
}
