// Compras
export const COMPRAS_ENTRADA_CATEGORIA_CLASSIFICATORIA = "compras.entrada.categoria.classificatoria";
export const COMPRAS_ENTRADA_GRUPO_CATEGORIA_CLASSIFICATORIA = "compras.entrada.grupo_categoria.classificacao";
export const COMPRAS_ENTRADA_REALIZAENTRADAORDEMCOMPRA = "compras.entrada.realizarentradaordemcompra";
export const COMPRAS_REGISTRONECESSIDADECOMPRA_CADASTRO_PRECOVENDA =
    "compras.registronecessidadecompra.cadastro.precovenda";
export const COMPRAS_REGISTRONECESSIDADECOMPRA_VINCULO_SKU = "compras.registronecessidadecompra.vinculo.sku";
export const COMPRAS_ENTRADA_NOTAFISCAL_DEFINEESCRITURACAO = "compras.entrada.notafiscal.defineescrituracao";

// Empresa
export const EMPRESA_NFE_SERIE = "empresa.nfe.serie";
export const EMPRESA_NFCE_SERIE = "empresa.nfce.serie";

// Fiscal
export const FISCAL_NFCE_SEMIDENTIFICACAO = "fiscal.nfce.semidentificacao";
export const FISCAL_CONTADOR_PERFIL = "fiscal.contador.perfil";
export const FISCAL_CONTADOR_CRC = "fiscal.contador.crc";

// Financeiro
export const FINANCEIRO_PAGAMENTO_EXIGEPREVISAO = "financeiro.pagamento.exigeprevisao";
export const FINANCEIRO_OBRIGAORCAMENTO = "financeiro.obrigaorcamento";
export const FINANCEIRO_PAGAMENTO_PERMITEFRACIONAMENTO = "financeiro.pagamento.permitefracionamento";
export const FINANCEIRO_RECEBIMENTO_ACRESCIMO_PERCENTUAL = "financeiro.recebimento.juros.percentual";
export const FINANCEIRO_RECEBIMENTO_ACRESCIMO_CARENCIA = "financeiro.recebimento.juros.carencia";
export const FINANCEIRO_RECEBIMENTO_DESCONTO_PERCENTUAL = "financeiro.recebimento.desconto.percentual";
export const FINANCEIRO_RECEBIMENTO_ANTECIPACAO_PERCENTUAL = "financeiro.recebimento.antecipacao.percentual";
export const FINANCEIRO_RECEBIMENTO_ANTECIPACAO_DIAS = "financeiro.recebimento.antecipacao.dias";
export const FINANCEIRO_RECEBIMENTO_MULTA_PERCENTUAL = "financeiro.recebimento.multa.percentual";
export const FINANCEIRO_RECEBIMENTO_ACRESCIMO_DESCONTO_PERCENTUAL_MAX =
    "financeiro.recebimento.acrescimo.desconto.percentual.max";
export const FINANCEIRO_RECEBIMENTO_MULTA_DESCONTO_PERCENTUAL_MAX =
    "financeiro.recebimento.multa.desconto.percentual.max";
export const FINANCEIRO_RECEBIMENTO_TEMPLATERATEIO_PADRAO = "financeiro.recebimento.templaterateio.padrao";
export const FINANCEIRO_MOVIMENTACAO_CAIXA = "financeiro.movimentacao.caixa";
export const FINANCEIRO_PADRAORECEBIMENTO_ESTAGIOVENDA = "financeiro.padraorecebimento.estagiovenda";
export const FINANCEIRO_PLANODECONTAS_PADRAO_SANGRIA = "financeiro.planodecontas.padrao.sangria";
export const FINANCEIRO_PLANODECONTAS_PADRAO_SUPRIMENTO = "financeiro.planodecontas.padrao.suprimento";
export const FINANCEIRO_PLANODECONTAS_PADRAO_TRANSFERENCIA = "financeiro.planodecontas.padrao.transferencia";
export const FINANCEIRO_RECEBIMENTO_RECEBER_JUROSMULTAS = "financeiro.recebimento.receber.jurosmultas";
export const FINANCEIRO_FINANCEIRO_CAIXA_RECEBERBOLETO = "financeiro.financeiro.caixa.receberboleto";
export const FINANCEIRO_CAIXAMOVIMENTO_TIPOS_MOTIVO = "financeiro.caixamovimento.tipos.motivo";
export const FINANCEIRO_CAIXA_PENDENCIAS_PERMITEPARCELAR = "financeiro.caixa.pendencias.permiteparcelar";

// Pessoas
export const PESSOAS_TAGPERFIL_PADRAO_FORNECEDOR = "pessoas.tagperfil.padrao.fornecedor";
export const PESSOAS_TAGPERFIL_PADRAO_TRANSPORTADOR = "pessoas.tagperfil.padrao.transportador";
export const PESSOAS_TAGPERFIL_PADRAO_REPRESENTANTE = "pessoas.tagperfil.padrao.representante";
export const PESSOAS_TAGPERFIL_PADRAO_ENTREGADOR = "pessoas.tagperfil.padrao.entregador";
export const PESSOAS_PERFILPF_RG_OPCIONAL = "pessoas.perfilpf.rg.opcional";
export const PESSOAS_ENDERECOS_TIPOS_IDENTIFICACAO = "pessoas.enderecos.tipos.identificacao";
export const PESSOAS_TELEFONES_TIPOS_IDENTIFICACAO = "pessoas.telefones.tipos.identificacao";
export const PESSOAS_EMAILS_TIPOS_IDENTIFICACAO = "pessoas.emails.tipos.identificacao";
export const PESSOAS_TAGPERFIL_PADRAO_CLIENTE = "pessoas.tagperfil.padrao.cliente";
export const PESSOAS_TAGPERFIL_PADRAO_MOTORISTA = "pessoas.tagperfil.padrao.motorista";
export const PESSOAS_PERFIL_MAXIMO_DIAS_INATIVO = "pessoas.perfil.maximo.dias.inativo";
export const PESSOAS_PERFIL_CADASTRO_PESSOASJURIDICA_OBRIGATORIEDADE =
    "pessoas.perfil.cadastro.pessoasjuridica.obrigatoriedade";
export const PESSOAS_PERFIL_CADASTRO_PESSOASFISICA_OBRIGATORIEDADE =
    "pessoas.perfil.cadastro.pessoasfisica.obrigatoriedade";
export const PESSOAS_PERFIL_CADASTRO_EMAIL_OBRIGATORIEDADE = "pessoas.perfil.cadastro.email.obrigatoriedade";
export const PESSOAS_PERFIL_CADASTRO_PAIS_OBRIGATORIEDADE = "pessoas.perfil.cadastro.pais.obrigatoriedade";
export const PESSOAS_PERFIL_CADASTRO_TELEFONE_OBRIGATORIEDADE = "pessoas.perfil.cadastro.telefone.obrigatoriedade";
export const PESSOAS_PERFIL_CADASTRO_REDESOCIAL_OBRIGATORIEDADE = "pessoas.perfil.cadastro.redesocial.obrigatoriedade";
export const PESSOAS_PERFIL_CADASTRO_ENDERECO_OBRIGATORIEDADE = "pessoas.perfil.cadastro.endereco.obrigatoriedade";

// Produtos
export const PRODUTOS_SKU_UNICO = "produtos.sku.unico";
export const PRODUTOS_GRUPOCATEGORIA_DESTINACAO = "produtos.grupocategoria.destinacao";
export const PRODUTOS_CATEGORIA_VENDA = "produtos.categoria.venda";
export const PRODUTOS_CATEGORIA_VENDAATIVOIMOBILIZADO = "produtos.categoria.vendaativoimobilizado";
export const PRODUTOS_SKU_GAVETA = "produtos.sku.gaveta";
export const PRODUTOS_ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_TODAS = "produto.estoque.cadastro.saldoestoqueformula.todas";
export const PRODUTOS_ESTOQUE_LOTE_FIFO = "estoque.lote.fifo";

//Transferência
export const PRODUTOS_ESTOQUE_MOVIMENTACAO_TIPOMOVIMENTACAO_ORIGEM =
    "produtos.estoque.movimentacao.tipomovimentacao.origem";
export const PRODUTOS_ESTOQUE_MOVIMENTACAO_TIPOMOVIMENTACAO_DESTINO =
    "produtos.estoque.movimentacao.tipomovimentacao.destino";
export const ESTOQUE_TRANSFERENCIA_FORMULASALDO_FATURAMENTO = "estoque.transferencia.formulasaldo.faturamento";
export const ESTOQUE_TRANSFERENCIA_FORMULASALDO_FISICO = "estoque.transferencia.formulasaldo.fisico";

//Exposição
export const PRODUTOS_ESTOQUE_MOVIMENTACAO_EXPOSICAO_MOVIMENTACAOSAIDA =
    "produtos.estoque.movimentacao.exposicao.movimentacaosaida";
export const PRODUTOS_ESTOQUE_MOVIMENTACAO_EXPOSICAO_MOVIMENTACAOENTRADA =
    "produtos.estoque.movimentacao.exposicao.movimentacaoentrada";

// Vendas
export const VENDA_ITEM_PRECO_PADRAO = "venda.item.preco.padrao";
export const VENDA_COMISSAO_PADRAO = "venda.comissao.padrao";
export const VENDA_GRUPO_CATEGORIA_COMISSAO = "venda.grupo.categoria.comissao";
export const VENDA_COMPETENCIA_FINANCEIRA_CORRENTE = "venda.competencia.financeira.corrente";
export const VENDA_FINANCEIRO_MAXIMO_DIAS_AVISTA = "venda.financeiro.maximo.dias.avista";
export const VENDA_COMISSAO_PAGAR_RECEBIMENTO = "venda.comissao.pagar.recebimento";
export const VENDA_PLANO_RECEBIMENTO_PADRAO = "venda.plano.recebimento.padrao";
export const VENDA_TIPO_BUSCA_PRODUTO_PADRAO = "venda.tipo.busca.produto.padrao";
export const VENDA_COMPETENCIA_ESTOQUE_CORRENTE = "venda.competencia.estoque.corrente";
export const VENDA_TEMPLATERATEIO_PADRAO = "venda.templaterateio.padrao";
export const VENDA_TIPOMOVIMENTACAO_PADRAO = "venda.tipomovimentacao.padrao";
export const VENDA_EXIGEDOCUMENTO_NF_55 = "venda.exigedocumento.nf.55";
export const VENDA_ENTREGA_HORAINICIAL = "venda.entrega.horainicial";
export const VENDA_ENTREGA_HORAFINAL = "venda.entrega.horafinal";
export const VENDAS_ORCAMENTORAPIDO_DESCRICAO_SIMILARIDADE = "vendas.orcamentorapido.descricao.similaridade";
export const VENDAS_ORCAMENTORAPIDO_CATEGORIA_SIMILARIDADE = "vendas.orcamentorapido.categoria.similaridade";
export const VENDA_CREDIARIO_VALORMAXIMO = "venda.crediario.valormaximo";
export const VENDA_CREDIARIO_PRIMEIRAVENDA_VALORMAXIMO = "venda.crediario.primeiravenda.valormaximo";
export const VENDA_TABELAPRECO_OBRIGATORIA = "venda.tabelapreco.obrigatoria";
export const VENDA_APROVACAOPRECO_MOTIVO = "venda.aprovacaopreco.motivo";
export const VENDA_TAG_NAOVENDER = "venda.tag.naovender";
export const VENDA_CONTAFINANCEIRA_PADRAO = "venda.contafinanceira.padrao";
export const VENDA_MESMADATA_CAIXA = "venda.mesmadata.caixa";
export const VENDAS_ENCARGOS_TAXA_MAXIMA = "vendas.encargos.taxa.maxima";
export const VENDAS_VENCIMENTO_LIMITE_SEGUNDAPARCELA = "vendas.vencimento.limite.segundaparcela";

// Relatórios
export const RELATORIO_EMAIL_POLITICAS = "relatorio.email.politicas";
export const RELATORIO_IMPRESSORATERMICA_PROPRIETARIO_TOKEN = "relatorios.impressoratermica.proprietario.token";

// Sistema
export const MAKO_LICENCA_ADICIONAL = "mako.licenca.adicional";
export const MAKO_MAXIMO_USUARIOS = "mako.maximo.usuarios";
