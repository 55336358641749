import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";

import { Form } from "../../features";

import useClearRefs from "@/hooks/useClearRefs";

const Componente = ({ successCallback = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);
    const [parametro, setParametro] = useState(null);

    const formRef = useRef(null);

    useClearRefs(formRef);

    const success = useCallback(() => {
        setVisible(false);
        successCallback();
    }, [successCallback]);

    const show = (parametro) => {
        setParametro(parametro);
        setVisible(true);
    };

    const onShow = useCallback(() => {
        formRef.current?.editar(parametro);
    }, [parametro]);

    const hide = () => setVisible(false);

    useImperativeHandle(ref, () => {
        return {
            show,
            hide,
        };
    });

    return (
        <Dialog
            visible={visible}
            header={`Parâmetro: ${parametro?.chave?.descricao}`}
            onHide={hide}
            onShow={onShow}
            style={{ width: "80vw" }}
        >
            <Form ref={formRef} onSuccessCallback={success} edicao />
        </Dialog>
    );
};

export const Editar = forwardRef(Componente);
