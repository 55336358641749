import React, { useCallback } from "react";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";

import { formatarCasasDecimais } from "@/assets/util/util";

const BASE_VALUES_CALCULO = {
    multa: 0,
    valor: 0,
    percent_multa: 0,
    percent_multa_desc_max: 0,
};

export const InputMulta = ({ valuesCalculo = BASE_VALUES_CALCULO, setFieldError = () => {}, ...rest }) => {
    const calcularMulta = useCallback(() => {
        if (valuesCalculo.multa > 0) {
            const multaOriginal = formatarCasasDecimais(valuesCalculo.valor * valuesCalculo.percent_multa);
            const pMulta = 100 - (100 * valuesCalculo.multa) / multaOriginal;
            if (pMulta > valuesCalculo.percent_multa_desc_max) {
                setFieldError("multa", "O valor aplicado de multa não é permitido.");
            } else {
                setFieldError("multa", null);
            }
        }
    }, [
        valuesCalculo.multa,
        valuesCalculo.valor,
        valuesCalculo.percent_multa,
        valuesCalculo.percent_multa_desc_max,
        setFieldError,
    ]);

    return <MakoInputMoeda onBlur={calcularMulta} {...rest} />;
};
