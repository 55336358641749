import React, { useState, useRef } from "react";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import MakoListagem from "@/components/MakoListagem";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { TIPO_CONTA_FINANCEIRA_CHOICE } from "@/assets/constants/constants";
import { PageBase } from "@/components/PageBase";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";
import useHttp from "@/hooks/useHttp";

const url = "/financeiro/contas-financeiras/";

export const FinanceiroContaFinanceiraPage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [contaFinanceira, setContaFinanceira] = useState(null);
    const listagemRef = useRef(null);
    const { showSuccess } = useToast();
    const { httpDelete } = useHttp();

    useClearRefs(listagemRef);

    const confirmarDelete = (contaFinanceira) => {
        setContaFinanceira(contaFinanceira);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const tipoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "label",
                    options: TIPO_CONTA_FINANCEIRA_CHOICE,
                }}
                options={options}
            />
        );
    };

    const removerConta = async () => {
        let ok = false;
        const handlers = {
            204: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Conta financeira deletada com sucesso!",
                    life: 5000,
                });
                listagemRef.current?.buscarDados();
                ok = true;
            },
        };
        await httpDelete({ url: `${url}${contaFinanceira.id}` }, handlers);
        return ok;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CONTAFINANCEIRA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/financeiro/cadastros/contas-financeiras/form",
                        state: { contaFinanceira: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CONTAFINANCEIRA_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => confirmarDelete(rowData)}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_CONTAFINANCEIRA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/financeiro/cadastros/contas-financeiras/form"}
            />
        </>
    );

    const colunas = [
        {
            field: "tipo_conta.descricao",
            header: "Tipo conta",
            style: { width: "13%" },
            filter: true,
            filterElement: tipoFiltroTemplate,
            filterField: "tipo_conta",
        },
        {
            field: "codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "data_abertura",
            header: "Data abertura",
            style: { width: "12%" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "saldo_inicial",
            header: "Saldo inicial",
            style: { width: "10%" },
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "saldo_atual",
            header: "Saldo atual",
            style: { width: "10%" },
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "12%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Contas Financeiras"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    tipo_conta: { value: null, matchMode: "equals" },
                    codigo: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    data_abertura: { value: null, matchMode: "equals" },
                    saldo_inicial: { value: null, matchMode: "equals" },
                    saldo_atual: { value: null, matchMode: "equals" },
                }}
            />
            <MakoConfirmDialog
                visible={deleteDialog}
                setVisible={esconderDeleteDialog}
                message={
                    contaFinanceira && (
                        <span>
                            {"Deseja realmente excluir a conta financeira "}
                            <b>{contaFinanceira.descricao}</b>?
                        </span>
                    )
                }
                accept={removerConta}
                showErrorToast={false}
            />
        </PageBase>
    );
};
