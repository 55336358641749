import React, { useMemo, useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";

import { ModalAlteraComissionado, ModalAlterarComissao, ModalFiltroAvancado, ModalDetalhesComissao } from "./modals";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { Delete } from "@/components/Delete";
import permissoes from "@/assets/constants/permissoes";
import { parseNumber } from "@/assets/helpers/number";
import useAuth from "@/hooks/useAuth";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    PerfilFiltroTemplate,
    BooleanFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useClearRefs from "@/hooks/useClearRefs";
import { useCallback } from "react";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { gerarFiltrosUrl } from "@/assets/util/util";

const URL_API = "/vendas/bases-comissoes-vendas/";

export const AjusteComissaoPage = () => {
    const [baseComissaoSelecionada, setBaseComissaoSelecionada] = useState(null);
    const [filtros, setFiltro] = useLocalFiltro();
    const [aplicados, setAplicados] = useState(0);
    const listagemRef = useRef(null);
    const menuRef = useRef();
    const modalAlterarComissionadoRef = useRef(null);
    const modalAlterarComissaoRef = useRef(null);
    const modalDetalhesComissaoRef = useRef(null);
    const modalFiltroAvancado = useRef(null);
    const deleteRef = useRef(null);
    const { verifyPermission } = useAuth();

    useClearRefs(modalFiltroAvancado);

    const itensMenu = [
        {
            label: "Alterar comissionado",
            disabled: !verifyPermission(permissoes.VENDAS_COMISSAO_AJUSTE_TROCARCOMISSIONADO),
            command: () => modalAlterarComissionadoRef.current?.abrirModal(baseComissaoSelecionada),
        },
        {
            label: "Alterar valor",
            disabled: !verifyPermission(permissoes.VENDAS_COMISSAO_AJUSTE_EDITARVALOR),
            command: () => modalAlterarComissaoRef.current?.abrirModal(baseComissaoSelecionada),
        },
        {
            label: "Excluir comissão",
            disabled: !verifyPermission(permissoes.VENDAS_COMISSAO_AJUSTE_EXCLUIR),
            command: () => deleteRef.current?.handleClick(),
        },
    ];

    const itemVendaBodyTemplate = (rowData) => {
        const { sku } = rowData.item_venda;
        return (
            <>
                <Tooltip target={`.custom-target${rowData.id}`} content={sku.descricao_derivada} position="top" />
                <span className={`custom-target${rowData.id}`}>{sku.codigo}</span>
            </>
        );
    };

    const comissaoPagaBodyTemplate = (rowData) => {
        const { apuracao_comissao } = rowData;
        if (apuracao_comissao?.status === "P") return <span>Sim</span>;
        return <span>Não</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Menu model={itensMenu} popup ref={menuRef} />
                <Button
                    icon="pi pi-eye"
                    rounded
                    severity="help"
                    className="p-mr-2 p-mb-1"
                    onClick={() => modalDetalhesComissaoRef.current?.abrirModal(rowData)}
                />
                <Button
                    icon="pi pi-cog"
                    rounded
                    className="p-mr-2 p-mb-1"
                    onClick={(e) => {
                        setBaseComissaoSelecionada(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "venda",
            header: "Venda",
            style: { width: "6%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "devolucao",
            header: "Devolução",
            style: { width: "6%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "data",
            header: "Data",
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "item_venda.sku.codigo",
            header: "Produto",
            style: { width: "10%" },
            action: itemVendaBodyTemplate,
        },
        {
            field: "comissionado.nome",
            header: "Comissionado (a)",
            filter: true,
            filterElement: PerfilFiltroTemplate,
            filterField: "comissionado",
        },
        { field: "apuracao_comissao.regra_apuracao.descricao", header: "Regra" },
        {
            field: "base_comissao",
            header: "Base comissão",
            money: true,
            style: { width: "10%" },
        },
        {
            field: "total_comissao",
            header: "Valor",
            money: true,
            style: { width: "10%" },
        },
        {
            field: "esta_pago",
            header: "Pago",
            style: { width: "6%" },
            action: comissaoPagaBodyTemplate,
            filter: true,
            filterElement: BooleanFiltroTemplate,
            filterField: "pago",
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const cabecalhoTabela = (
        <>
            <Button
                icon={MAKO_ICONS.FILTRAR}
                label="Filtro Avançado"
                className="p-button p-button-help"
                badge={aplicados > 0 ? aplicados : null}
                onClick={() => modalFiltroAvancado.current?.abrirModal()}
            />
        </>
    );

    const rowClass = (rowData) => {
        const { apuracao_comissao } = rowData;
        const paga = apuracao_comissao?.status === "P";
        return {
            "table-recebimentos-overdue": parseNumber(rowData.total_comissao) < 0,
            "table-recebimentos-pending": paga,
        };
    };

    const onConfirmFiltro = useCallback(
        ({ filtros, total }) => {
            setAplicados(total);
            setFiltro(filtros);
        },
        [setFiltro]
    );

    const url = useMemo(() => {
        return `${URL_API}?${gerarFiltrosUrl(filtros)}`;
    }, [filtros]);

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                urlPesquisa={url}
                titulo="Ajustes de comissão"
                painelEsquerdo={cabecalhoTabela}
                colunas={colunas}
                filtros={{
                    venda: { value: null, matchMode: "equals" },
                    devolucao: { value: null, matchMode: "equals" },
                    data: { value: null, matchMode: "equals" },
                    comissionado: { value: null, matchMode: "equals" },
                    pago: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    rowClassName: rowClass,
                }}
            />
            <ModalAlteraComissionado
                ref={modalAlterarComissionadoRef}
                onFechar={() => listagemRef.current?.buscarDados()}
            />
            <ModalAlterarComissao ref={modalAlterarComissaoRef} onFechar={() => listagemRef.current?.buscarDados()} />
            <ModalDetalhesComissao ref={modalDetalhesComissaoRef} />
            <Delete
                ref={deleteRef}
                url={URL_API}
                objetoId={baseComissaoSelecionada?.id}
                exigeConfirmacao
                msgConfirmacao="Confirma a exclusão da comissão?"
                onDelete={() => listagemRef.current?.buscarDados()}
            />
            <ModalFiltroAvancado ref={modalFiltroAvancado} onConfirm={onConfirmFiltro} />
        </PageBase>
    );
};
