import { useContext } from "react";

import PessoaContext from "@/contexts/pessoaContext";

const usePessoa = () => {
    const pessoa = useContext(PessoaContext);

    return pessoa;
};

export default usePessoa;
