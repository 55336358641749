import React, { useRef } from "react";
import { Button } from "primereact/button";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";

const url = "/produtos/galerias-imagens-sku/";

export const ProdutoGaleriaImgSkuPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GALERIAIMAGEMSKU_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de galeria de imagem"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/cadastros/galerias-imagens/form",
                            state: { GaleriaImg: rowData },
                        })
                    }
                />

                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GALERIAIMAGEMSKU_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a galeria de imagem <b>{rowData.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A galeria não pode ser excluída."
                    tooltip="Deletar galeria"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_GALERIAIMAGEMSKU_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/produto/cadastros/galerias-imagens/form")}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" } },
        { field: "nome", header: "Nome", style: { width: "30%" } },
        { field: "altura", header: "Altura", style: { width: "10%" } },
        { field: "largura", header: "Largura", style: { width: "15%" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { paddingLeft: "2%", width: "20%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Galerias de Imagens"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
