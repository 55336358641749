import React, { memo, forwardRef, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import classNames from "classnames";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";
import { Divider } from "primereact/divider";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useNotaServico from "@/hooks/useNotaServico";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import { InputTextarea } from "primereact/inputtextarea";
import useHttp from "@/hooks/useHttp";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import { Button } from "primereact/button";
import {
    SIM_NAO_BOOLEAN,
    TIPO_BENEFICIO_MUNICIPAL,
    TIPO_CST_PIS_COFINS,
    TIPO_IMUNIDADE_ISSQN,
    TIPO_RETENCAO_ISSQN,
    TIPO_TRIBUTACAO_ISSQN,
} from "@/assets/constants/constants";

export const ServicosForm = () => {
    const { handleServicos, dadosBasicos, servico } = useNotaServico();
    const { showError, showWarning } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet } = useHttp();

    const { setFieldValue, resetForm, setValues, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            servico: null,
            operacao_servico: null,
            codigo: "",
            descricao: "",
            cnae: "",
            discriminacao: "",
            endereco_prestacao: null,
            logradouro_prestacao: "",
            bairro_prestacao: "",
            numero_end_prestacao: "",
            cep_prestacao: "",
            municipio_prestacao: null,
            valor_servico: 0,
            desconto_incondicionado: 0,
            desconto_condicionado: 0,
            percentual_deducao: null,
            valor_deducao: 0,
            tributacao_issqn: 4,
            codigo_issqn: "",
            imunidade_issqn: null,
            aliquota_issqn: 0,
            base_issqn: 0,
            valor_issqn: 0,
            retencao_issqn: 1,
            cst_pis_cofins: "00",
            aliquota_pis: 0,
            base_pis: 0,
            valor_pis: 0,
            aliquota_cofins: 0,
            base_cofins: 0,
            valor_cofins: 0,
            retem_pis_cofins: false,
            valor_cp: 0,
            valor_irrf: 0,
            valor_csll: 0,
            per_tributos_federais: 0,
            valor_tributos_federais: 0,
            per_tributos_estaduais: 0,
            valor_tributos_estaduais: 0,
            per_tributos_municipais: 0,
            valor_tributos_municipais: 0,
            per_total_tributos: 0,
            tipo_beneficio: null,
            identificador_beneficio: "",
            reducao_issqn: 0,
            valor_reducao_issqn: 0,
            valor_total: 0,
            info_complementar: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                operacao_servico: Yup.number()
                    .required("O campo é obrigatório!")
                    .typeError("Selecione uma operação fiscal válida."),
                servico: Yup.object().required("O campo é obrigatório!").typeError("Selecione um serviço válido."),
                codigo: Yup.string().required("O campo é obrigatório!"),
                codigo_issqn: Yup.string().required("O campo é obrigatório!"),
                discriminacao: Yup.string().required("O campo é obrigatório!"),
                descricao: Yup.string().required("O campo é obrigatório!"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const resposta = await handleServicos(values);
            if (resposta?.status === 400) showError();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const autoCompleteTemplate = (servico) => {
        return `${servico.codigo} - ${servico.descricao}`;
    };

    const buscarInformacoesTributarias = useCallback(
        async (serv) => {
            if (formik.values.operacao_servico) {
                const handlers = {
                    200: ({ data }) => {
                        const infoTributaria = data.results;
                        if (!formik.values?.id)
                            if (infoTributaria) {
                                setFieldValue("pis_retido", infoTributaria.retem_pis ? 1 : 2);
                                setFieldValue("aliquota_pis", infoTributaria.aliquota_pis || 0);
                                setFieldValue("iss_retido", infoTributaria.retem_iss ? 1 : 2);
                                setFieldValue("aliquota_iss", infoTributaria.aliquota_iss || 0);
                                setFieldValue("cofins_retido", infoTributaria.retem_cofins ? 1 : 2);
                                setFieldValue("aliquota_cofins", infoTributaria.aliquota_cofins || 0);
                                setFieldValue("ipi_retido", infoTributaria.retem_ipi ? 1 : 2);
                                setFieldValue("aliquota_ipi", infoTributaria.aliquota_ipi || 0);
                                setFieldValue("ir_retido", infoTributaria.retem_ir ? 1 : 2);
                                setFieldValue("aliquota_ir", infoTributaria.aliquota_ir || 0);
                                setFieldValue("csll_retido", infoTributaria.retem_csll ? 1 : 2);
                                setFieldValue("aliquota_csll", infoTributaria.aliquota_csll || 0);
                                setFieldValue("cpp_retido", infoTributaria.retem_cpp ? 1 : 2);
                                setFieldValue("aliquota_cpp", infoTributaria.aliquota_cpp || 0);
                                setFieldValue("inss_retido", infoTributaria.retem_inss ? 1 : 2);
                                setFieldValue("aliquota_inss", infoTributaria.aliquota_inss || 0);
                                setFieldValue(
                                    "aliquota_outras_retencoes",
                                    infoTributaria.aliquota_outras_retencoes || 0
                                );
                                setFieldValue("aliquota_total_tributos", infoTributaria.aliquota_total_tributos || 0);
                                setFieldValue("aliquota_iss_municip", infoTributaria.aliquota_iss_municip || 0);
                            }
                    },
                    400: ({ err }) =>
                        showWarning({
                            summary: "Falha",
                            detail: err.msg,
                            life: 4000,
                        }),
                    404: ({ data }) =>
                        showWarning({
                            summary: "Falha",
                            detail: data.msg,
                            life: 4000,
                        }),
                    500: () =>
                        showError({
                            summary: "Erro",
                            detail: "Desculpe, não conseguimos listar as informações tributárias do produto.",
                            life: 3000,
                        }),
                };

                await httpGet(
                    {
                        url: `/servicos/buscar-informacoes-tributarias/${serv.id}/${formik.values.operacao_servico}/`,
                    },
                    handlers
                );
            } else {
                setFieldValue("codigo", "");
                setFieldValue("descricao", "");
                setFieldValue("cnae", "");
            }
        },
        [formik.values.id, formik.values.operacao_servico, setFieldValue, showWarning, showError, httpGet]
    );

    const onChangeServico = (servico) => {
        setFieldValue("servico", servico);
        setFieldValue("cnae", servico.cnae);
        setFieldValue("codigo", servico.codigo);
        setFieldValue("descricao", servico.descricao);
        if (typeof servico === "object") buscarInformacoesTributarias(servico);
    };

    const onChangeEndereco = (endereco) => {
        setFieldValue("bairro_prestacao", endereco.bairro);
        setFieldValue("logradouro_prestacao", endereco.logradouro);
        setFieldValue("endereco_prestacao", endereco);
        setFieldValue("numero_end_prestacao", parseInt(endereco.numero));
        setFieldValue("cep_prestacao", endereco.cep);
        setFieldValue("municipio_prestacao", endereco.cidade);
    };

    const calcularTotal = useCallback(async () => {
        if (formik.values.valor_servico > 0) {
            const _total =
                parseFloat(formik.values.valor_servico) +
                parseFloat(formik.values.valor_deducoes || 0) +
                parseFloat(formik.values.valor_acrescimos || 0) -
                parseFloat(formik.values.desconto_condicional || 0) -
                parseFloat(formik.values.desconto_incondicional || 0);
            if (_total !== formik.values.valor_total) setFieldValue("valor_total", _total);
            if (_total !== formik.values.base_issqn) setFieldValue("base_issqn", _total);
        }
    }, [
        formik.values.base_issqn,
        formik.values.valor_total,
        formik.values.valor_servico,
        formik.values.valor_deducoes,
        formik.values.valor_acrescimos,
        formik.values.desconto_condicional,
        formik.values.desconto_incondicional,
        setFieldValue,
    ]);

    useEffect(() => {
        calcularTotal();
    }, [calcularTotal]);

    useEffect(() => {
        if (servico) setValues(servico);
    }, [servico, setValues]);

    return (
        <form onSubmit={formik.handleSubmit}>
            {!!!servico && (
                <div className="p-grid p-col-12 p-md-6">
                    <Button
                        label="Incluir dados do serviço"
                        icon="pi pi-save"
                        type="submit"
                        className="p-mr-2 p-button-info"
                    />
                </div>
            )}
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label className="html">Operação fiscal * </label>
                    <Dropdown
                        id="operacao_servico"
                        name="operacao_servico"
                        placeholder="Selecione uma operação fiscal"
                        url={`/servicos/operacoes-fiscais-servicos?empresa=${empresaSelecionadaId}&limit=1000&ativo=true&query={id,descricao}`}
                        filter
                        filterBy="id,descricao"
                        optionValue="id"
                        optionLabel="descricao"
                        showClear={false}
                        value={formik.values.operacao_servico}
                        onChange={(e) => {
                            resetForm();
                            formik.handleChange(e);
                        }}
                        className={classNames({ "p-invalid": formik.errors.operacao_servico })}
                    />
                    {formik.errors.operacao_servico && (
                        <small className="p-error">{formik.errors.operacao_servico}</small>
                    )}
                </div>
            </div>
            <Divider align="center">
                <b>Dados do Serviço</b>
            </Divider>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="servico">Serviço do município *</label>
                    <MakoAutoComplete
                        id="servico"
                        name="servico"
                        placeholder="Busque pelo código ou descrição ... (min 4 caracteres)"
                        minCaracteresBusca={4}
                        value={formik.values.servico}
                        onChange={(e) => onChangeServico(e.target.value)}
                        itemTemplate={autoCompleteTemplate}
                        field="descricao"
                        urlSearch={"/servicos/servicos-municipio?limit=60&search="}
                        className={classNames({ "p-invalid": formik.errors.servico })}
                    />
                    {formik.errors.servico && <small className="p-error">{formik.errors.servico}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="codigo">Código *</label>
                    <InputText
                        id="codigo"
                        name="codigo"
                        value={formik.values.codigo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.codigo })}
                    />
                    {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-8">
                    <label htmlFor="descricao">Descrição *</label>
                    <InputText
                        id="descricao"
                        name="descricao"
                        value={formik.values.descricao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.descricao })}
                    />
                    {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="cnae">Cnae</label>
                    <InputText
                        id="cnae"
                        name="cnae"
                        value={formik.values.cnae}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.cnae })}
                    />
                    {formik.errors.cnae && <small className="p-error">{formik.errors.cnae}</small>}
                </div>
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="discriminacao">Discriminação *</label>
                    <InputTextarea
                        id="discriminacao"
                        name="discriminacao"
                        value={formik.values.discriminacao}
                        onChange={formik.handleChange}
                        tooltip="Descrição completa do serviço prestado."
                        tooltipOptions={{ position: "bottom" }}
                        rows={3}
                        className={classNames({ "p-invalid": formik.errors.discriminacao })}
                    />
                    {formik.errors.discriminacao && <small className="p-error">{formik.errors.discriminacao}</small>}
                </div>
            </div>
            <Divider align="center">
                <b>Endereço da Prestação</b>
            </Divider>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="endereco_prestacao">Endereço</label>
                    <Dropdown
                        id="endereco_prestacao"
                        name="endereco_prestacao"
                        placeholder="Selecione..."
                        url={`/pessoas/enderecos-perfis?perfil_id=${
                            dadosBasicos?.empresa?.id || dadosBasicos?.empresa
                        }`}
                        buscar={dadosBasicos?.empresa}
                        optionLabel="logradouro"
                        showClear={false}
                        value={formik.values.endereco_prestacao}
                        onChange={(e) => onChangeEndereco(e.target.value)}
                        className={classNames({ "p-invalid": formik.errors.endereco_prestacao })}
                    />
                    {formik.errors.endereco_prestacao && (
                        <small className="p-error">{formik.errors.endereco_prestacao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="bairro_prestacao">Bairro *</label>
                    <InputText
                        id="bairro_prestacao"
                        name="bairro_prestacao"
                        value={formik.values.bairro_prestacao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.bairro_prestacao })}
                    />
                    {formik.errors.bairro_prestacao && (
                        <small className="p-error">{formik.errors.bairro_prestacao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="numero_end_prestacao">Número *</label>
                    <InputNumber
                        id="numero_end_prestacao"
                        name="numero_end_prestacao"
                        value={formik.values.numero_end_prestacao}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.numero_end_prestacao })}
                    />
                    {formik.errors.numero_end_prestacao && (
                        <small className="p-error">{formik.errors.numero_end_prestacao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="cep_prestacao">CEP *</label>
                    <InputText
                        id="cep_prestacao"
                        name="cep_prestacao"
                        value={formik.values.cep_prestacao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.cep_prestacao })}
                    />
                    {formik.errors.cep_prestacao && <small className="p-error">{formik.errors.cep_prestacao}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="municipio_prestacao">Município *</label>
                    <InputText
                        id="municipio_prestacao"
                        name="municipio_prestacao"
                        value={formik.values.municipio_prestacao?.nome}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.municipio_prestacao })}
                        disabled
                    />
                    {formik.errors.municipio_prestacao && (
                        <small className="p-error">{formik.errors.municipio_prestacao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="uf_prestacao">UF *</label>
                    <InputText
                        id="uf_prestacao"
                        name="uf_prestacao"
                        value={formik.values.municipio_prestacao?.estado?.nome}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.uf_prestacao })}
                        disabled
                    />
                    {formik.errors.uf_prestacao && <small className="p-error">{formik.errors.uf_prestacao}</small>}
                </div>
            </div>
            <Divider align="center">
                <b>Valores e Tributações</b>
            </Divider>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor_servico">Valor serviço *</label>
                    <MakoInputMoeda
                        id="valor_servico"
                        name="valor_servico"
                        valueMoeda={formik.values.valor_servico}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_servico })}
                        somentePositivo
                    />
                    {formik.errors.valor_servico && <small className="p-error">{formik.errors.valor_servico}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="desconto_incondicionado">Desc. incondicionado</label>
                    <MakoInputMoeda
                        id="desconto_incondicionado"
                        name="desconto_incondicionado"
                        valueMoeda={formik.values.desconto_incondicionado}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.desconto_incondicionado })}
                        somentePositivo
                    />
                    {formik.errors.desconto_incondicionado && (
                        <small className="p-error">{formik.errors.desconto_incondicionado}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="desconto_condicionado">Desc. condicionado</label>
                    <MakoInputMoeda
                        id="desconto_condicionado"
                        name="desconto_condicionado"
                        valueMoeda={formik.values.desconto_condicionado}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.desconto_condicionado })}
                        somentePositivo
                    />
                    {formik.errors.desconto_condicionado && (
                        <small className="p-error">{formik.errors.desconto_condicionado}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="percentual_deducao">Alíq. dedução</label>
                    <InputNumber
                        id="percentual_deducao"
                        name="percentual_deducao"
                        prefix="%"
                        min={0}
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.percentual_deducao}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.percentual_deducao })}
                    />
                    {formik.errors.percentual_deducao && (
                        <small className="p-error">{formik.errors.percentual_deducao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor_deducao">Valor dedução</label>
                    <MakoInputMoeda
                        id="valor_deducao"
                        name="valor_deducao"
                        valueMoeda={formik.values.valor_deducao}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_deducao })}
                        somentePositivo
                    />
                    {formik.errors.valor_deducao && <small className="p-error">{formik.errors.valor_deducao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="tributacao_issqn">Tributação ISSQN *</label>
                    <Dropdown
                        id="tributacao_issqn"
                        name="tributacao_issqn"
                        placeholder="Selecione..."
                        options={TIPO_TRIBUTACAO_ISSQN}
                        optionValue="value"
                        optionLabel="label"
                        showClear={false}
                        value={formik.values.tributacao_issqn}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tributacao_issqn })}
                    />
                    {formik.errors.tributacao_issqn && (
                        <small className="p-error">{formik.errors.tributacao_issqn}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="imunidade_issqn">Tipo imunidade ISSQN</label>
                    <Dropdown
                        id="imunidade_issqn"
                        name="imunidade_issqn"
                        placeholder="Selecione..."
                        options={TIPO_IMUNIDADE_ISSQN}
                        optionValue="value"
                        optionLabel="label"
                        showClear={false}
                        value={formik.values.imunidade_issqn}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.imunidade_issqn })}
                    />
                    {formik.errors.imunidade_issqn && (
                        <small className="p-error">{formik.errors.imunidade_issqn}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="codigo_issqn">Código ISSQN *</label>
                    <InputText
                        id="codigo_issqn"
                        name="codigo_issqn"
                        value={formik.values.codigo_issqn}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.codigo_issqn })}
                        autoComplete="off"
                        autoFocus
                    />
                    {formik.errors.codigo_issqn && <small className="p-error">{formik.errors.codigo_issqn}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="aliquota_issqn">Alíq. ISSQN</label>
                    <InputNumber
                        id="aliquota_issqn"
                        name="aliquota_issqn"
                        prefix="%"
                        min={0}
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.aliquota_issqn}
                        onValueChange={(e) => {
                            setFieldValue("valor_issqn", ((e.value / 100) * formik.values.base_issqn).toFixed(2));
                            formik.handleChange(e);
                        }}
                        className={classNames({ "p-invalid": formik.errors.aliquota_issqn })}
                    />
                    {formik.errors.aliquota_issqn && <small className="p-error">{formik.errors.aliquota_issqn}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="base_issqn">Base ISSQN</label>
                    <MakoInputMoeda
                        id="base_issqn"
                        name="base_issqn"
                        valueMoeda={formik.values.base_issqn}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.base_issqn })}
                        somentePositivo
                    />
                    {formik.errors.base_issqn && <small className="p-error">{formik.errors.base_issqn}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor_issqn">Valor ISSQN</label>
                    <MakoInputMoeda
                        id="valor_issqn"
                        name="valor_issqn"
                        valueMoeda={formik.values.valor_issqn}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_issqn })}
                        somentePositivo
                    />
                    {formik.errors.valor_issqn && <small className="p-error">{formik.errors.valor_issqn}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="retencao_issqn">Tipo retenção ISSQN *w</label>
                    <Dropdown
                        id="retencao_issqn"
                        name="retencao_issqn"
                        placeholder="Selecione..."
                        options={TIPO_RETENCAO_ISSQN}
                        optionValue="value"
                        optionLabel="label"
                        showClear={false}
                        value={formik.values.retencao_issqn}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.retencao_issqn })}
                    />
                    {formik.errors.retencao_issqn && <small className="p-error">{formik.errors.retencao_issqn}</small>}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="cst_pis_cofins">CST PIS/Cofins</label>
                    <Dropdown
                        id="cst_pis_cofins"
                        name="cst_pis_cofins"
                        placeholder="Selecione..."
                        options={TIPO_CST_PIS_COFINS}
                        optionValue="value"
                        optionLabel="label"
                        showClear={false}
                        value={formik.values.cst_pis_cofins}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.cst_pis_cofins })}
                    />
                    {formik.errors.cst_pis_cofins && <small className="p-error">{formik.errors.cst_pis_cofins}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="base_pis">Base PIS</label>
                    <MakoInputMoeda
                        id="base_pis"
                        name="base_pis"
                        valueMoeda={formik.values.base_pis}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.base_pis })}
                        somentePositivo
                    />
                    {formik.errors.base_pis && <small className="p-error">{formik.errors.base_pis}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="aliquota_pis">Alíq. PIS</label>
                    <InputNumber
                        id="aliquota_pis"
                        name="aliquota_pis"
                        prefix="%"
                        min={0}
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.aliquota_pis}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.aliquota_pis })}
                    />
                    {formik.errors.aliquota_pis && <small className="p-error">{formik.errors.aliquota_pis}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor_pis">Valor PIS </label>
                    <MakoInputMoeda
                        id="valor_pis"
                        name="valor_pis"
                        valueMoeda={formik.values.valor_pis}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_pis })}
                        somentePositivo
                    />
                    {formik.errors.valor_pis && <small className="p-error">{formik.errors.valor_pis}</small>}
                </div>

                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="base_cofins">Base Cofins </label>
                    <MakoInputMoeda
                        id="base_cofins"
                        name="base_cofins"
                        valueMoeda={formik.values.base_cofins}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.base_cofins })}
                        somentePositivo
                    />
                    {formik.errors.base_cofins && <small className="p-error">{formik.errors.base_cofins}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="aliquota_cofins">Alíq. Cofins</label>
                    <InputNumber
                        id="aliquota_cofins"
                        name="aliquota_cofins"
                        prefix="%"
                        min={0}
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.aliquota_cofins}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.aliquota_cofins })}
                    />
                    {formik.errors.aliquota_cofins && (
                        <small className="p-error">{formik.errors.aliquota_cofins}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor_cofins">Valor Cofins *</label>
                    <MakoInputMoeda
                        id="valor_cofins"
                        name="valor_cofins"
                        valueMoeda={formik.values.valor_cofins}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_cofins })}
                        somentePositivo
                    />
                    {formik.errors.valor_cofins && <small className="p-error">{formik.errors.valor_cofins}</small>}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="retem_pis_cofins">Retém PIS/Cofins</label>
                    <Dropdown
                        id="retem_pis_cofins"
                        name="retem_pis_cofins"
                        placeholder="Selecione..."
                        options={SIM_NAO_BOOLEAN}
                        optionValue="id"
                        optionLabel="label"
                        showClear={false}
                        value={formik.values.retem_pis_cofins}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.retem_pis_cofins })}
                    />
                    {formik.errors.retem_pis_cofins && (
                        <small className="p-error">{formik.errors.retem_pis_cofins}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="per_tributos_federais">% Tributos Federais</label>
                    <InputNumber
                        id="per_tributos_federais"
                        name="per_tributos_federais"
                        prefix="%"
                        min={0}
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.per_tributos_federais}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.per_tributos_federais })}
                    />
                    {formik.errors.per_tributos_federais && (
                        <small className="p-error">{formik.errors.per_tributos_federais}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="per_tributos_estaduais">% Tributos Estaduais</label>
                    <InputNumber
                        id="per_tributos_estaduais"
                        name="per_tributos_estaduais"
                        prefix="%"
                        min={0}
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.per_tributos_estaduais}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.per_tributos_estaduais })}
                    />
                    {formik.errors.per_tributos_estaduais && (
                        <small className="p-error">{formik.errors.per_tributos_estaduais}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="per_tributos_municipais">% Tributos Municipais</label>
                    <InputNumber
                        id="per_tributos_municipais"
                        name="per_tributos_municipais"
                        prefix="%"
                        min={0}
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.per_tributos_municipais}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.per_tributos_municipais })}
                    />
                    {formik.errors.per_tributos_municipais && (
                        <small className="p-error">{formik.errors.per_tributos_municipais}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="aliquota_total_simples">Alíq. Simples</label>
                    <InputNumber
                        id="aliquota_total_simples"
                        name="aliquota_total_simples"
                        prefix="%"
                        min={0}
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.aliquota_total_simples}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.aliquota_total_simples })}
                    />
                    {formik.errors.aliquota_total_simples && (
                        <small className="p-error">{formik.errors.aliquota_total_simples}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="tipo_beneficio">Benefício</label>
                    <Dropdown
                        id="tipo_beneficio"
                        name="tipo_beneficio"
                        placeholder="Selecione..."
                        options={TIPO_BENEFICIO_MUNICIPAL}
                        optionValue="value"
                        optionLabel="label"
                        showClear={false}
                        value={formik.values.tipo_beneficio}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tipo_beneficio })}
                    />
                    {formik.errors.tipo_beneficio && <small className="p-error">{formik.errors.tipo_beneficio}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="identificador_beneficio">Identificador benefício</label>
                    <InputText
                        id="descricao"
                        name="identificador_beneficio"
                        value={formik.values.identificador_beneficio}
                        onChange={formik.handleChange}
                        maxLength={20}
                        className={classNames({ "p-invalid": formik.errors.identificador_beneficio })}
                        autoComplete="off"
                        autoFocus
                    />
                    {formik.errors.identificador_beneficio && (
                        <small className="p-error">{formik.errors.identificador_beneficio}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor_irrf">Valor IRRF *</label>
                    <MakoInputMoeda
                        id="valor_irrf"
                        name="valor_irrf"
                        valueMoeda={formik.values.valor_irrf}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_irrf })}
                        somentePositivo
                    />
                    {formik.errors.valor_irrf && <small className="p-error">{formik.errors.valor_irrf}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor_csll">Valor Csll</label>
                    <MakoInputMoeda
                        id="valor_csll"
                        name="valor_csll"
                        valueMoeda={formik.values.valor_csll}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_csll })}
                        somentePositivo
                    />
                    {formik.errors.valor_csll && <small className="p-error">{formik.errors.valor_csll}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor_cp">Valor CP</label>
                    <MakoInputMoeda
                        id="valor_cp"
                        name="valor_cp"
                        valueMoeda={formik.values.valor_cp}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_cp })}
                        somentePositivo
                    />
                    {formik.errors.valor_cp && <small className="p-error">{formik.errors.valor_cp}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor_total">
                        <b>Vlr. total</b>
                    </label>
                    <MakoInputMoeda
                        id="valor_total"
                        name="valor_total"
                        valueMoeda={formik.values.valor_total}
                        readOnly
                        disabled
                        somentePositivo
                    />
                </div>
            </div>
            <Divider align="center">
                <b>Informações Complementares</b>
            </Divider>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <InputTextarea
                        id="info_complementar"
                        name="info_complementar"
                        value={formik.values.info_complementar}
                        onChange={formik.handleChange}
                        tooltip="Descrição completa do serviço prestado."
                        tooltipOptions={{ position: "bottom" }}
                        rows={3}
                        className={classNames({ "p-invalid": formik.errors.info_complementar })}
                    />
                    {formik.errors.info_complementar && (
                        <small className="p-error">{formik.errors.info_complementar}</small>
                    )}
                </div>
            </div>
            {!!!servico && (
                <div className="p-grid p-col-12 p-md-6">
                    <Button
                        label="Incluir dados do serviço"
                        icon="pi pi-save"
                        type="submit"
                        className="p-mr-2 p-button-info"
                    />
                </div>
            )}
            <FormikAutoSave
                intervalo={700}
                autosave={
                    !!(servico?.id && formik.values.operacao_servico && typeof formik.values.servico === "object")
                }
                formik={formik}
            />
        </form>
    );
};

export default memo(forwardRef(ServicosForm));
