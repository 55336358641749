import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";

import { useFormik } from "formik";

import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";

import {
    MakoDropdownCompetenciaFinanceira,
    MakoDropdownHistoricoPadrao,
    MakoInputPerfil,
} from "@/components/MakoInputs";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { COLORS_VALIDADE } from "@/assets/constants/constants_styles";
import { ValidadeOverlay } from "./validadeOverlay";

const Component = (_, ref) => {
    const [visible, setVisible] = useState(false);

    const [formatDocumento] = useFormatCNPJCPF();

    const { values, setValues, setFieldValue } = useFormik({
        initialValues: {},
    });

    const handleOpen = (pagamento = {}) => {
        if (values?.id !== pagamento?.id)
            setValues({
                ...pagamento,
                empresa: pagamento.empresa?.id,
                para_receber: parseFloat(pagamento.valor) - parseFloat(pagamento.valor_recebido),
                template_rateio: pagamento.template_rateio?.id,
                forma_pagamento: pagamento.forma_recebimento?.id,
            });
        setVisible(true);
    };

    const handleClose = () => setVisible(false);

    useImperativeHandle(ref, () => {
        return {
            show: handleOpen,
        };
    });

    const header = useMemo(() => {
        const { id, credor, dias_atraso, quitado, cancelado } = values;
        let color = COLORS_VALIDADE.PENDENTE;
        if (cancelado) color = COLORS_VALIDADE.CANCELADA;
        else if (!quitado && dias_atraso > 0) color = COLORS_VALIDADE.VENCIDA;
        else if (quitado) color = COLORS_VALIDADE.CONCLUIDA;
        const value = `Pagamento: ${id} - ${credor?.nome} - ${formatDocumento(credor?.identificacao)}`;
        return (
            <div className="p-d-flex">
                <ValidadeOverlay color={color} />
                <span style={{ color }}>{value}</span>
            </div>
        );
    }, [values, formatDocumento]);

    return (
        <Dialog visible={visible} header={header} onHide={handleClose} style={{ width: "70vw" }}>
            <div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="empresa" label="Empresa" />
                        <MakoDropdownEmpresas id="empresa" name="empresa" value={values.empresa} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="credor" label="Credor" />
                        <MakoInputPerfil
                            id="credor"
                            name="devedor"
                            disabled
                            somenteAtivo
                            query="id,nome,identificacao,tipo_pessoa"
                            value={values.credor}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="conta_financeira" label="Conta financeira" />
                        <Dropdown
                            id="conta_financeira"
                            name="conta_financeira"
                            buscar={!!values.empresa}
                            url={`/financeiro/contas-financeiras/?query={id,descricao}&tipo_conta__in=2,3&perfil=${values.empresa}`}
                            optionValue="id"
                            optionLabel="descricao"
                            disabled
                            value={values.conta_financeira}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="venda" label="Número da venda" />
                        <InputText id="venda" disabled value={values.venda} />
                    </div>
                    {/* <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="forma_pagamento" label="Forma" />
                        <Dropdown
                            id="forma_pagamento"
                            name="forma_pagamento"
                            url="/financeiro/formas-recebimentos/?query={id,descricao}&ativo=true&limit=50"
                            disabled
                            optionValue="id"
                            optionLabel="descricao"
                            value={values.forma_pagamento}
                        />
                    </div> */}
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="documento" label="Documento" />
                        <InputText id="documento" name="documento" disabled value={values.documento} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="historico" label="Histórico" />
                        <MakoDropdownHistoricoPadrao
                            filtros={{ tipo: "R" }}
                            id="historico"
                            name="historico_padrao"
                            disabled
                            value={values.historico_padrao}
                            setFieldValueForm={setFieldValue}
                            showButtonNew={false}
                            inputTextProps={{
                                value: values.historico_original,
                                disabled: true,
                            }}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="competencia" label="Competência financeira" />
                        <MakoDropdownCompetenciaFinanceira
                            id="competencia"
                            name="competencia"
                            disabled={!!values.id}
                            value={values.competencia}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data-emissao" label="Data de emissão" />
                        <MakoCalendar
                            id="data-emissao"
                            name="data_emissao"
                            disabled
                            valueCalendar={values.data_emissao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data-lancamento" label="Data de lançamento" />
                        <MakoCalendar
                            id="data-lancamento"
                            name="data_lancamento"
                            valueCalendar={values.data_lancamento}
                            disabled
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="vencimento" label="Data vencimento" />
                        <MakoCalendar id="vencimento" name="vencimento" valueCalendar={values.vencimento} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data_pagamento" label="Data pagamento" />
                        <MakoCalendar
                            id="data_pagamento"
                            name="data_pagamento"
                            valueCalendar={values.data_liquidacao}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="template-rateio" label="Template de rateio" />
                        <Dropdown
                            id="template-rateio"
                            name="template_rateio"
                            buscar={!!values.empresa}
                            url={`/financeiro/templates-rateios/?query={id,descricao}&empresa=${values.empresa}`}
                            disabled
                            optionValue="id"
                            optionLabel="descricao"
                            value={values.template_rateio}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="origem-fracionamento" label="Origem fracionamento" />
                        <InputText
                            id="origem-fracionamento"
                            name="origem_fracionamento"
                            disabled
                            value={values.origem_fracionamento}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="plano-orcamentario" label="Plano orçamentário" />
                        <Dropdown
                            id="plano-orcamentario"
                            name="plano_orcamentario"
                            buscar={!!values.empresa}
                            disabled
                            url={`/financeiro/planos-orcamentarios/?query={id,descricao}&perfil=${values.empresa}`}
                            optionValue="id"
                            optionLabel="descricao"
                            value={values.plano_orcamentario}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="valor" label="Valor" />
                        <MakoInputMoeda id="valor" name="valor" disabled valueMoeda={values.valor} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="valor_recebido" label="Pago" />
                        <MakoInputMoeda
                            id="valor_recebido"
                            name="valor_recebido"
                            disabled
                            valueMoeda={values.valor_pago}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="para_pagar" label="A pagar" />
                        <MakoInputMoeda
                            id="para_pagar"
                            name="para_pagar"
                            disabled
                            valueMoeda={values.valor - values.valor_pago}
                        />
                    </div>
                    {/* <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="juros" label="Juros" />
                        <MakoInputMoeda id="juros" name="juros" disabled valueMoeda={values.juros} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="multa" label="Multa" />
                        <MakoInputMoeda id="multa" name="multa" disabled valueMoeda={values.multa} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="dias_atraso" label="Dias" />
                        <InputText id="dias_atraso" name="dias_atraso" disabled value={values.dias_atraso} />
                    </div> */}
                </div>
            </div>
        </Dialog>
    );
};

export const ModalVisualizarPagamento = forwardRef(Component);
