import React from "react";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";

import { ACTIONS_OPERAR_MODAIS_CAIXA } from "../../../reducers/operar-modais-caixa";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

const { FINANCEIRO_FINANCEIRO_CAIXAMOV_BLOQUEAR, FINANCEIRO_FINANCEIRO_CAIXAMOV_BLOQUEAR_TERCEIRO } = permissoes;

export const Bloqueio = ({ className = "", dispatcher = () => {} }) => {
    const { caixaMov } = useCaixaMovimento();

    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[FINANCEIRO_FINANCEIRO_CAIXAMOV_BLOQUEAR, FINANCEIRO_FINANCEIRO_CAIXAMOV_BLOQUEAR_TERCEIRO]}
            label="Bloqueio"
            className={className}
            exibirDesabilitadoSemPermissao={true}
            onClick={() => {
                dispatcher({
                    type: ACTIONS_OPERAR_MODAIS_CAIXA.BLOQUEAR,
                    payload: {
                        nomeCaixa: caixaMov.caixa.nome,
                    },
                    reloadCaixa: true,
                });
            }}
            disabled={caixaMov?.bloqueado}
        />
    );
};
