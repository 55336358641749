import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";

import { gerarFiltrosUrl, gerarStatusBooleanTemplate } from "@/assets/util/util";
import { TIPO_CHOICE_MOTIVO_DEVOLUCAO_FORNECEDOR } from "@/assets/constants/constants";

const URL_BACKEND = "/compras/tipos-devolucoes-fornecedor/?";

const BASE_TITULO = <span>Tipos de Devolução</span>;

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    align: "center",
    style: { width: "10%" },
};

const actionTemplateMotivo = ({ motivo }) => {
    return TIPO_CHOICE_MOTIVO_DEVOLUCAO_FORNECEDOR.find(({ value }) => value === motivo)?.label;
};

const actionTemplateGeraFinanceira = ({ gera_financeiro }) => {
    return gerarStatusBooleanTemplate(gera_financeiro);
};

const BASE_COLUMNS = [
    { field: "id", header: "Código", align: "center", style: { width: "6%" } },
    { field: "descricao", header: "Descrição" },
    { field: "motivo", header: "Motivo", action: actionTemplateMotivo },
    {
        field: "gera_financeiro ",
        header: "Gera financeiro",
        align: "center",
        action: actionTemplateGeraFinanceira,
        style: { width: "8%" },
    },
];

export const TiposDevolucao = ({
    actions,
    aposPesquisar,
    listagemProps = {},
    configTabela = {},
    titulo = BASE_TITULO,
    filtros = {},
    listagemRef = null,
}) => {
    const url = useMemo(() => {
        let _url = URL_BACKEND;
        if (typeof filtros === "object") _url = `${_url}&${gerarFiltrosUrl(filtros)}`;
        return _url;
    }, [filtros]);

    const colunas = useMemo(() => {
        if (actions) return [...BASE_COLUMNS, { ...BASE_COLUMN_ACTIONS, action: actions }];
        return BASE_COLUMNS;
    }, [actions]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo={titulo}
            colunas={colunas}
            urlPesquisa={url}
            aposPesquisar={aposPesquisar}
            {...listagemProps}
            configTabela={{
                lazy: true,
                paginator: true,
                ...configTabela,
            }}
        />
    );
};
