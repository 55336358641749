import React from "react";

export const IconPix = ({ color = "black", ...props }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_4451_294)">
                <path d="M6 0H0V6H6V0ZM5 5H1V1H5V5Z" fill={color} />
                <path d="M2 2H4V4H2V2ZM0 16H6V10H0V16ZM1 11H5V15H1V11Z" fill={color} />
                <path d="M2 12H4V14H2V12ZM10 0V6H16V0H10ZM15 5H11V1H15V5Z" fill={color} />
                <path
                    d="M12 2H14V4H12V2ZM2 7H0V9H3V8H2V7ZM7 9H9V11H7V9ZM3 7H5V8H3V7ZM9 12H7V13H8V14H9V13V12ZM6 7V8H5V9H7V7H6ZM8 4H9V6H8V4ZM9 8V9H11V7H8V8H9ZM7 6H8V7H7V6ZM9 14H11V16H9V14ZM7 14H8V16H7V14ZM9 11H10V12H9V11ZM9 3V1H8V0H7V4H8V3H9ZM12 14H13V16H12V14ZM12 12H14V13H12V12ZM11 13H12V14H11V13ZM10 12H11V13H10V12ZM14 10V11H15V12H16V10H15H14ZM15 13H14V16H16V14H15V13ZM10 10V11H13V9H11V10H10ZM12 7V8H14V9H16V7H14H12Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_4451_294">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
