import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";

import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { Tag } from "primereact/tag";

export const TipoTransferenciaPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.ESTOQUE_CADASTRO_TIPOTRANSFERENCIA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/estoque/cadastros/tipo-transferencia/form")}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_TIPOTRANSFERENCIA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/cadastros/tipo-transferencia/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_TIPOTRANSFERENCIA_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/vendas/estagios-vendas/"
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo apagar o tipo de transferência <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O tipo de transferência não pode ser excluído."
                    tooltip="Deletar tipo de transferência"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const simNaoBodyTemplate = (valor) => {
        if (valor) return <Tag severity="success" value="SIM" style={{ width: "70px" }} />;
        return <Tag severity="danger" value="NÃO" style={{ width: "70px" }} />;
    };

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "80px" } },
        { field: "descricao", header: "Descrição", style: { minWidth: "220px" } },
        { field: "tipo_sku_origem.descricao", header: "Tipo do produto de origem", style: { minWidth: "220px" } },
        { field: "tipo_sku_destino.descricao", header: "Tipo do produto de destino", style: { minWidth: "220px" } },
        {
            field: "mesmo_estoque",
            header: "Mesmo estoque?",
            style: { minWidth: "150px" },
            action: (e) => simNaoBodyTemplate(e.mesmo_estoque),
        },
        {
            field: "mesmo_centro_estocagem",
            header: "Mesmo centro de estocagem?",
            style: { minWidth: "220px" },
            action: (e) => simNaoBodyTemplate(e.mesmo_centro_estocagem),
        },
        {
            field: "tipo_ce_origem.descricao",
            header: "Tipo do centro de estocagem de origem",
            style: { minWidth: "280px" },
        },
        {
            field: "tipo_ce_destino.descricao",
            header: "Tipo do centro de estocagem de destino",
            style: { minWidth: "280px" },
        },
        {
            field: "mesmo_cnpj_cpf",
            header: "Mesmo Cnpj/CPF?",
            style: { minWidth: "150px" },
            action: (e) => simNaoBodyTemplate(e.mesmo_cnpj_cpf),
        },
        {
            field: "mesma_raiz_cnpj",
            header: "Mesma raiz Cnpj?",
            style: { minWidth: "150px" },
            action: (e) => simNaoBodyTemplate(e.mesma_raiz_cnpj),
        },
        {
            field: "para_matriz",
            header: "Para matriz?",
            style: { minWidth: "120px" },
            action: (e) => simNaoBodyTemplate(e.para_matriz),
        },
        {
            field: "para_filial",
            header: "Para filial?",
            style: { minWidth: "120px" },
            action: (e) => simNaoBodyTemplate(e.para_filial),
        },
        { field: "ativo", header: "Ativo", style: { minWidth: "120px" }, action: (e) => simNaoBodyTemplate(e.ativo) },
        {
            field: "action",
            header: "Ações",
            style: { minWidth: "120px" },
            action: (e) => actionBodyTemplate(e),
            frozen: true,
            alignFrozen: "right",
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Tipo de Transferência"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/transferencias/tipo-transferencia/"
                filtarPorEmpresa
                naoBuscarSemEmpresa
                configTabela={{
                    lazy: true,
                    paginator: true,
                    scrollable: true,
                    frozenWidth: "0.5vw",
                }}
            />
        </PageBase>
    );
};
