import React, { useCallback } from "react";

import { parseData } from "@/assets/util/datas";
import { MakoBaseCalendar } from "./baseCalendar";

export const MakoCalendar = ({ valueCalendar, minDate, maxDate, onChange = () => {}, ...props }) => {
    const handleChange = useCallback(
        (e) => {
            let _value = e.value;
            if (typeof _value === "string" && _value.length > 0) _value = parseData(_value);
            onChange({ ...e, value: _value });
        },
        [onChange]
    );

    return (
        <MakoBaseCalendar
            value={valueCalendar}
            onChange={handleChange}
            minDate={minDate}
            maxDate={maxDate}
            {...props}
        />
    );
};
