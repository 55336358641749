import React from "react";
import { Skeleton as SkeletonPR } from "primereact";

export const Skeleton = ({ loading, children }) => {
    if (!loading) return children;

    return (
        <>
            <div className="p-d-flex p-mb-3">
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" />
            </div>
            <div className="p-d-flex p-mb-3">
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" />
            </div>
            <div className="p-d-flex p-mb-3">
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" />
            </div>
            <div className="p-d-flex p-mb-3">
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" />
                <SkeletonPR height="2.5rem" />
            </div>
            <div className="p-d-flex p-mb-3">
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" />
                <SkeletonPR height="2.5rem" />
            </div>
            <div className="p-d-flex p-mb-3">
                <SkeletonPR height="2.5rem" className="p-mr-2" />
                <SkeletonPR height="2.5rem" />
            </div>
        </>
    );
};
