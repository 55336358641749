import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { TIPO_RESTRICAO_SPC } from "@/assets/constants/constants";
import { PageBase } from "@/components/PageBase";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const VendasConsultaSpcPage = (props) => {
    const [cliente, setCliente] = useState(null);
    const [url, setUrl] = useState("/vendas/consultas-spc/");
    const history = useHistory();

    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_CONSULTASPC_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de consulta"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/consultas-spc/form",
                            state: { consulta: rowData },
                        })
                    }
                />
            </div>
        );
    };

    const autoCompleteTemplate = (valor) => {
        valor.label = `${valor.nome} - ${valor.identificacao}`;
        return `${valor.nome} - ${valor.identificacao}`;
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_CONSULTASPC_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/venda/consultas-spc/form")}
            />
        </>
    );

    const statusBodyTemplate = (indicador_restricao) => {
        if (indicador_restricao) return <Tag severity="success" value="LIBERADO" />;
        return <Tag severity="danger" value="RESTRITO" />;
    };

    const RestricaoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_RESTRICAO_SPC,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "identificador",
            header: "Identificador",
            filter: true,
            filterField: "identificador",
            filterElement: CodigoFiltroTemplate,
            style: { minWidth: "12%" },
        },
        {
            field: "cliente.nome",
            header: "Cliente",
            filter: true,
            filterField: "cliente__nome",
            filterElement: TextoFiltroTemplate,
        },
        { field: "data", header: "Data", style: { minWidth: "10%" }, filter: true, filterElement: DateFiltroTemplate },
        { field: "hora", header: "Hora", style: { minWidth: "7%" } },
        {
            header: "Restrição",
            field: "indicador_restricao",
            style: { minWidth: "12%" },
            filter: true,
            filterField: "indicador_restricao",
            filterElement: RestricaoFiltroTemplate,
            action: (e) => statusBodyTemplate(e.indicador_restricao),
        },

        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "7%" },
        },
    ];

    const selecionaCliente = (usuario) => {
        setCliente(usuario);
        if (typeof usuario === "object") {
            setUrl(`/vendas/consultas-spc?cliente__id=${usuario.id}`);
        } else setUrl(`/vendas/consultas-spc/`);
    };

    const limparFiltro = () => {
        setCliente("");
        setUrl(`/vendas/consultas-spc/`);
    };

    return (
        <PageBase>
            <Toast ref={toastRef} />
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-ml-3">
                    {cliente ? (
                        <Button
                            icon="pi pi-times"
                            className="p-button-text p-button-danger p-button-outlined"
                            onClick={() => limparFiltro()}
                        />
                    ) : null}
                </div>
                <div className={`p-field p-col-12 ${cliente ? "p-md-11" : "p-md-12"}`}>
                    <MakoAutoComplete
                        id="destinatario"
                        name="destinatario"
                        placeholder="Busque pelo nome ou cpf/cnpj ... (min 4 caractéres)"
                        minCaracteresBusca={4}
                        value={cliente}
                        onChange={(e) => selecionaCliente(e.target.value)}
                        itemTemplate={autoCompleteTemplate}
                        field="label"
                        urlSearch={"/pessoas/perfis?query={id, nome, identificacao}&search="}
                    />
                </div>
            </div>
            <MakoListagem
                titulo={
                    props && props.venda
                        ? null
                        : cliente && typeof cliente === "object"
                        ? `Histórico de Consultas SPC: ${cliente.label}`
                        : "Histórico de Consultas SPC"
                }
                painelEsquerdo={props && props.venda ? null : cabecalhoTabela}
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={url}
                filtros={{
                    identificador: { value: null, matchMode: "equals" },
                    cliente__nome: { value: null, matchMode: "unaccent_icontains" },
                    indicador_restricao: { value: null, matchMode: "equals" },
                    data: { value: null, matchMode: "equals" },
                    hora: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
