import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoButton as Button } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { DJANGO_FILTERS_MATCHMODE, GRUPO_CHAVE_PARAMETRO } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const { unaccent_icontains, icontains } = DJANGO_FILTERS_MATCHMODE;

const VALUES_MATCHER = {
    descricao: `descricao${unaccent_icontains}`,
    chave: `chave${icontains}`,
    grupo: "grupo",
};

const INITIAL_VALUES = {
    descricao: "",
    chave: "",
    grupo: "",
};

export const Buscar = ({ onSuccessCallback = () => {} }) => {
    const { setValues, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().default("").typeError("Informe uma 'descrição' válida."),
                chave: Yup.string().default("").typeError("Informe uma 'chave' válida."),
                grupo: Yup.string().default("").typeError("Informe um 'grupo' válido."),
            });

            let dadosValidados = await formSchema.validate(values, { abortEarly: false });
            let data = {};
            for (const [k, v] of Object.entries(dadosValidados)) {
                if (v) data[VALUES_MATCHER[k]] = v;
            }
            onSuccessCallback(data);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="descricao" label="Descrição" />
                        <InputText
                            id="descricao"
                            name="descricao"
                            maxLength={60}
                            placeholder="Digite a descrição para busca"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="chave" label="Chave" />
                        <InputText
                            id="chave"
                            name="chave"
                            maxLength={255}
                            placeholder="Digite a chave para busca"
                            value={formik.values.chave}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave })}
                        />
                        {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="grupo" label="Grupo" />
                        <Dropdown
                            id="grupo"
                            name="grupo"
                            options={GRUPO_CHAVE_PARAMETRO}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.grupo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.grupo })}
                        />
                        {formik.errors.grupo && <small className="p-error">{formik.errors.grupo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "4px" }}>
                        <Button
                            label="Localizar"
                            icon={MAKO_ICONS.PESQUISAR}
                            type="button"
                            onClick={formik.handleSubmit}
                            className="p-button p-button-success"
                        />
                    </div>
                </div>
            </form>
        </>
    );
};
