import { useRef } from "react";
import { useBetween } from "use-between";

const useMakoPermissaoCookiesRef = () => {
    const sidebarRef = useRef(null);

    const abrirModalCookies = () => {
        sidebarRef.current?.abrirModal();
    };

    const fecharModalCookies = () => {
        sidebarRef.current?.fecharModal();
    };

    return { sidebarRef, abrirModalCookies, fecharModalCookies };
};

const useMakoPermissaoCookies = () => useBetween(useMakoPermissaoCookiesRef);
export default useMakoPermissaoCookies;
