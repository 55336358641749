import React, { useCallback } from "react";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";

import { formatarCasasDecimais } from "@/assets/util/util";

const BASE_VALUES_CALCULO = {
    juros: 0,
    valor: 0,
    percent_juros: 0,
    percent_juros_desc_max: 0,
};

export const InputJuros = ({ valuesCalculo = BASE_VALUES_CALCULO, setFieldError = () => {}, ...rest }) => {
    const calcularJuros = useCallback(() => {
        if (valuesCalculo.juros > 0) {
            const jurosOriginal = formatarCasasDecimais(valuesCalculo.valor * valuesCalculo.percent_juros);
            const pJuros = 100 - (100 * valuesCalculo.juros) / jurosOriginal;
            if (pJuros > valuesCalculo.percent_juros_desc_max) {
                setFieldError("juros", "O valor aplicado de juros não é permitido.");
            } else {
                setFieldError("juros", null);
            }
        }
    }, [
        valuesCalculo.juros,
        valuesCalculo.valor,
        valuesCalculo.percent_juros,
        valuesCalculo.percent_juros_desc_max,
        setFieldError,
    ]);

    return <MakoInputMoeda onBlur={calcularJuros} {...rest} />;
};
