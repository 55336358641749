import React, { useCallback, useEffect, useState } from "react";
import { MakoAjudaOverlay } from "@/components/MakoAjudaOverlay";
import useHttp from "@/hooks/useHttp";
import useEmpresa from "@/hooks/useEmpresa";
import { dataToStr } from "@/assets/util/datas";
import { SelectButton } from "primereact/selectbutton";

export const MakoSituacaoSefaz = ({ tipoInicial }) => {
    const [situacao, setSituacao] = useState();
    const [tipo, setTipo] = useState(tipoInicial);
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet } = useHttp();

    const buscaSituacao = useCallback(async () => {
        if (tipo) {
            const handlers = {
                200: ({ data }) => setSituacao(data.situacao),
            };
            await httpGet({ url: `/fiscal/situacao-sefaz/${tipo}/${empresaSelecionadaId}/` }, handlers);
        }
    }, [httpGet, empresaSelecionadaId, tipo]);

    useEffect(() => {
        buscaSituacao();
    }, [buscaSituacao]);

    return (
        <MakoAjudaOverlay
            buttonProps={{
                style: {
                    color: situacao?.codigo_status === 107 ? "green" : "red",
                    fontSize: "1.5rem",
                    display: "inline-flex",
                    alignItem: "center",
                    textAlign: "center",
                },
                tooltip: "Situação SEFAZ",
                className: "p-link p-px-2 p-mr-2",
            }}
            icon={`pi pi-check-circle`}
            showCloseIcon={false}
        >
            <SelectButton
                value={tipo}
                onChange={(e) => setTipo(e.value)}
                optionLabel="name"
                options={[
                    { name: "NF-e", value: "nfe" },
                    { name: "NFC-e", value: "nfce" },
                ]}
            />
            <div className="p-justify-center p-col-12 p-md-12">
                <b>{situacao?.codigo_status === 107 ? "Serviço em Operação" : "Problema no Serviço"}</b>
                <br />
                <label>Data/Hora da consulta</label>
                <br />
                <label>{dataToStr(situacao?.data_hora_consulta, "yyyy-MM-dd HH:mm:ss")}</label>
            </div>
        </MakoAjudaOverlay>
    );
};
