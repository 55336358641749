import React, { forwardRef, useImperativeHandle, useState } from "react";

import { ConfirmDialog } from "primereact/confirmdialog";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { MakoPoliticaPrivacidade } from "../MakoPoliticaPrivacidade";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useCookiePermitir from "@/hooks/cookies/useCookiePermitir";
import useAuth from "@/hooks/useAuth";

const DIALOG_RECUSAR = {
    message: "Ao recusar a utilização de cookies não sera possível utilizar o sistema.",
    header: "Deseja realmente recusar os cookies?",
    icon: "pi pi-exclamation-triangle p-error",
    acceptClassName: "p-button-success",
    rejectClassName: "p-button-danger p-button-outlined",
    acceptLabel: "Sim",
    rejectLabel: "Não",
};

const Component = (props, ref) => {
    const [cookiePermitido, setCookiePermitido] = useCookiePermitir();
    const [confirm, setConfirm] = useState(false);
    const [dialog, setDialog] = useState(false);
    const { user, signOut } = useAuth();
    const [show, setShow] = useState(() => {
        return !cookiePermitido;
    });

    const onHide = () => {
        if (user !== null && !cookiePermitido) reject(true);
        setShow(false);
    };

    const onAccept = () => {
        setCookiePermitido(true);
        setShow(false);
    };

    const onReject = () => {
        reject(user !== null);
    };

    const acceptConfirm = () => {
        signOut();
        reject();
    };

    const reject = (confirm = false) => {
        if (confirm) {
            setConfirm(true);
        } else {
            setCookiePermitido(false);
            setShow(false);
        }
    };

    const abrirModal = () => {
        setShow(true);
    };

    const fecharModal = () => {
        setShow(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal, fecharModal }));

    return (
        <Sidebar visible={show} position="bottom" onHide={onHide} showCloseIcon={false}>
            <div className="p-grid container-cookies">
                <p className="p-col-12 p-xl-8 p-text-left p-m-0">
                    Este site utiliza cookies estritamente necessários para que o site funcione corretamente. Esses
                    cookies não coletam informações pessoais e não podem ser desativados. Ao continuar a usar este site,
                    você concorda com o uso de cookies necessários.
                    <br />
                    Para mais informações sobre cookies e como os utilizamos, consulte a nossa{" "}
                    <Button
                        type="button"
                        label="Política de Privacidade"
                        className="p-m-0 p-p-0"
                        text
                        onClick={() => setDialog(true)}
                    />
                    . Se você não concorda com o uso de cookies, recomendamos que saia deste site ou ajuste as
                    configurações do seu navegador para bloquear os cookies.
                </p>
                <div className="container-button-cookies p-mx-3">
                    <Button
                        type="button"
                        label="Rejeitar cookies"
                        className="button-cookies p-button-danger"
                        icon={MAKO_ICONS.CANCEL}
                        onClick={onReject}
                        outlined
                    />
                    <Button
                        icon={MAKO_ICONS.CONFIRMAR}
                        label="Aceitar cookies"
                        className="button-cookies p-button-success"
                        onClick={onAccept}
                        disabled={cookiePermitido}
                    />
                </div>
            </div>
            <ConfirmDialog
                visible={confirm}
                {...DIALOG_RECUSAR}
                accept={acceptConfirm}
                reject={() => setConfirm(false)}
            />
            <Dialog
                header="Nossas Políticas"
                visible={dialog}
                onHide={() => setDialog(false)}
                style={{ width: "60vw" }}
                breakpoints={{ "960px": "75vw" }}
            >
                <MakoPoliticaPrivacidade />
            </Dialog>
        </Sidebar>
    );
};

export const MakoPermissaoCookies = forwardRef(Component);
