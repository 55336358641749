import React, { useCallback } from "react";

import { FormBloquear as Form } from "../../features";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

export const Bloquear = ({ fecharModal }) => {
    const { caixaMov, setCaixaUsuario: updateCaixa } = useCaixaMovimento();

    const successCalback = useCallback(
        (value) => {
            fecharModal();
            updateCaixa(value);
        },
        [fecharModal, updateCaixa]
    );

    return <Form caixaMov={caixaMov} successCalback={successCalback} cancelCallback={fecharModal} />;
};
