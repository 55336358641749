import { ContabilVersaoPlanoContasForm } from "@/pages/Contabil/Contabil/VersaoPlanoContas/form";
import { ContabilVersaoPlanoContasPage } from "@/pages/Contabil/Contabil/VersaoPlanoContas";
import { ContabilPlanoContasPage } from "@/pages/Contabil/Contabil/PlanoContas";
import { ContabilPlanoContasForm } from "@/pages/Contabil/Contabil/PlanoContas/form";
import { VincularPlanosPage } from "@/pages/Contabil/VincularPlanos";

export const contabilRoutes = [
    { path: "/contabil/versao-plano-contas", component: ContabilVersaoPlanoContasPage },
    { path: "/contabil/versao-plano-contas/form", component: ContabilVersaoPlanoContasForm },
    { path: "/contabil/plano-contas", component: ContabilPlanoContasPage },
    { path: "/contabil/plano-contas/form", component: ContabilPlanoContasForm },
    { path: "/contabil/vincular-planos", component: VincularPlanosPage },
];
