import React from "react";

import { DevolucaoFornecedorProvider } from "@/contexts/devolucaoFornecedorContext";
import { TabDevolucaoFornecedor } from "./tabs";

export const TabDevolucaoFornecedorContext = (props) => {
    return (
        <DevolucaoFornecedorProvider>
            <TabDevolucaoFornecedor devolucaoId={props.location.state?.devolucaoId} />
        </DevolucaoFornecedorProvider>
    );
};
