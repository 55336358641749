import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { dataToStr } from "@/assets/util/datas";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { ConfirmDialog } from "primereact/confirmdialog";
import { gerarId, parseNumberToMoneyHTML } from "@/assets/util/util";
import { MakoComponenteRegra } from "../MakoComponenteRegra";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useCrediario from "@/hooks/useCrediario";
import classNames from "classnames";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { RendaRelacaoModalForm } from "./modal/rendaRelacaoModalForm";

export const DadosProfissionaisForm = () => {
    const [registroAtual, setRegistroAtual] = useState(gerarId());
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [relacao, setRelacao] = useState(null);
    const { crediario, handleRelacao, handleDadosCrediario } = useCrediario();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);
    const rendaRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    type="button"
                    permissao={[permissoes.VENDAS_VENDA_CREDIARIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-dollar"
                    className="p-button-rounded p-button-success p-mr-2 p-mb-1"
                    tooltip="Renda da relação"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        setRelacao(rowData);
                        rendaRef.current?.abrir(rowData);
                    }}
                />
                <MakoControleAcesso
                    type="button"
                    permissao={[permissoes.VENDAS_VENDA_CREDIARIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    tooltip="Remover relação profissional"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        setRelacao({ ...rowData, op: "deletar" });
                        setDeleteDialog(true);
                    }}
                />
            </div>
        );
    };

    const [colunas, setColunas] = useState([
        { field: "id", header: "Código", style: { width: "30px" } },
        { field: "perfil_titular.nome", header: "Empregado", style: { width: "250px" } },
        { field: "perfil_vinculado.nome", header: "Empregador", style: { width: "250px" } },
        { field: "nome_relacao.relacao", header: "Relação", style: { width: "200px" } },
        { header: "Renda", action: (e) => verificaRenda(e), style: { width: "80px" } },
        { field: "data_inicio", dateFormat: "dd/MM/yyyy", header: "Início", style: { width: "100px" } },
        { field: "data_fim", dateFormat: "dd/MM/yyyy", header: "Fim", style: { width: "100px" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "120px" },
        },
    ]);

    const { setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            perfil_titular: crediario?.perfil_titular,
            perfil_vinculado: null,
            nome_relacao: null,
            cargo: "",
            data_fim: null,
            data_inicio: null,
            gera_renda: true,
            registro: null,
            op: "novo",
            dadosprofissionais_dyn: [],
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil_vinculado: Yup.object().required("O campo 'perfil vinculado' é obrigatório."),
                nome_relacao: Yup.object().required("O campo 'relação' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleRelacao(values.op, {
                ...values,
                registro: registroAtual,
                data_fim: dataToStr(values.data_fim, "yyyy-MM-dd"),
                data_inicio: dataToStr(values.data_inicio, "yyyy-MM-dd"),
            });

            values.dadosprofissionais_dyn.forEach((valor) => {
                valor["registro"] = registroAtual;
            });

            handleDadosCrediario({
                dadosprofissionais_dyn: crediario.dadosprofissionais_dyn.concat(values.dadosprofissionais_dyn),
            });

            setRegistroAtual(gerarId());
            setFieldValue("registro", gerarId());
            resetForm();
            showSuccess({
                summary: "Sucesso",
                detail: "Relação adicionada com sucesso!",
                life: 2500,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    async function deletaRelacao() {
        try {
            handleRelacao("deletar", relacao);
            aposInserir();
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
        }
    }

    const autoCompleteTemplate = (valor) => {
        valor.label = `${valor.nome} - ${valor.identificacao}`;
        return `${valor.nome} - ${valor.identificacao}`;
    };

    const esconderConfirmacao = () => {
        setRelacao(null);
        setDeleteDialog(false);
    };

    const verificaRenda = (relacao) => {
        if (!relacao.gera_renda) return "NÃO GERA";
        else if (relacao.gera_renda && relacao.renda_perfil) return parseNumberToMoneyHTML(relacao.renda_perfil?.renda);
        return "Pendente";
    };

    const colunasDinamicas = useCallback(async () => {
        if (formik.values.dadosprofissionais_dyn?.length > 0) {
            let lista = [
                { field: "id", header: "Código", style: { width: "30px" } },
                { field: "perfil_titular.nome", header: "Empregado", style: { width: "250px" } },
                { field: "perfil_vinculado.nome", header: "Empregador", style: { width: "250px" } },
                { field: "nome_relacao.relacao", header: "Relação", style: { width: "250px" } },
                { header: "Renda", action: (e) => verificaRenda(e), style: { width: "80px" } },
                { field: "data_inicio", dateFormat: "dd/MM/yyyy", header: "Início", style: { width: "100px" } },
                { field: "data_fim", dateFormat: "dd/MM/yyyy", header: "Fim", style: { width: "100px" } },
            ];

            let fields = [];
            formik.values.dadosprofissionais_dyn.forEach((valor) => {
                lista.push({
                    field: valor.nome,
                    header: valor.label,
                    style: { width: "300px" },
                });
                fields.push({ chave: valor.nome });
            });

            lista.push({
                field: "action",
                header: "Ações",
                action: (e) => actionBodyTemplate(e),
                style: { width: "120px" },
            });

            setColunas(lista);
        }
    }, [formik.values.dadosprofissionais_dyn]);

    useEffect(() => {
        colunasDinamicas();
    }, [colunasDinamicas]);

    const aposInserir = () => {
        listagemRef.current?.buscarDados();
    };

    return (
        <PageBase>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="perfil_titular">Empregado *</label>
                        <Dropdown
                            id="perfil_titular"
                            name="perfil_titular"
                            placeholder="Selecione um perfil"
                            options={[
                                ...[crediario.perfil_titular],
                                ...crediario.relacaoperfil_set
                                    .map(({ perfil_vinculado, ...relacao }) => {
                                        if (relacao.nome_relacao.tipo.id !== 4 && relacao.op !== "deletar") {
                                            return perfil_vinculado;
                                        }
                                    })
                                    .filter((e) => e !== undefined),
                            ]}
                            filter
                            filterBy="nome"
                            optionLabel="nome"
                            value={formik.values.perfil_titular}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.perfil_titular })}
                        />
                        {formik.errors.perfil_titular && (
                            <small className="p-error">{formik.errors.perfil_titular}</small>
                        )}
                        {formik.values.perfil_titular?.id === crediario.perfil_titular?.id ? (
                            <b>(Renda Própria)</b>
                        ) : null}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="perfil_vinculado">Fonte de renda *</label>
                        <MakoAutoComplete
                            id="perfil_vinculado"
                            name="perfil_vinculado"
                            placeholder="Busque pelo nome ou cpf ... (min 4 caractéres)"
                            minCaracteresBusca={4}
                            value={formik.values.perfil_vinculado}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompleteTemplate}
                            field="label"
                            urlSearch={"/pessoas/perfis?&query={id, nome, identificacao}&search="}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="nome_relacao">Relação *</label>
                        <Dropdown
                            id="nome_relacao"
                            name="nome_relacao"
                            placeholder="Selecione uma relação"
                            url="/crediario/nome-relacao?query={id, relacao, tipo}&limit=300&tipo=4"
                            filter
                            filterBy="relacao"
                            optionLabel="relacao"
                            value={formik.values.nome_relacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome_relacao })}
                        />
                        {formik.errors.nome_relacao && <small className="p-error">{formik.errors.nome_relacao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_inicio">Data de início</label>
                        <MakoCalendar
                            id="data_inicio"
                            name="data_inicio"
                            valueCalendar={formik.values.data_inicio}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_inicio,
                            })}
                        />
                        {formik.errors.data_inicio && <small className="p-error">{formik.errors.data_inicio}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_fim">Data final</label>
                        <MakoCalendar
                            id="data_fim"
                            name="data_fim"
                            valueCalendar={formik.values.data_fim}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_fim,
                            })}
                        />
                        {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="cargo">Cargo</label>
                        <InputText
                            id="cargo"
                            name="cargo"
                            value={formik.values.cargo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cargo })}
                            autoComplete="off"
                        />
                        {formik.errors.cargo && <small className="p-error">{formik.errors.cargo}</small>}
                    </div>
                </div>
                {registroAtual ? (
                    <div className="p-fluid p-formgrid p-grid">
                        <MakoComponenteRegra
                            dados={crediario?.dadosprofissionais_dyn ? crediario.dadosprofissionais_dyn : null}
                            registro={registroAtual}
                            aba={"DadosProfissionais"}
                            campoPai="dadosprofissionais_dyn"
                            regra={crediario?.regra}
                            setValores={setFieldValue}
                        />
                    </div>
                ) : null}
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-plus"
                            label="Adicionar"
                            className="p-button-primary p-button-success p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={() => resetForm()}
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                titulo="Relações Profissionais"
                colunas={colunas}
                dadosLocal={crediario.relacaoperfil_set.filter(
                    (e) => e.nome_relacao.tipo.id === 4 && e.op !== "deletar"
                )}
                configTabela={{ scrollable: true, frozenWidth: "0.5vw" }}
            />
            <RendaRelacaoModalForm relacao={relacao} aposInserir={aposInserir} ref={rendaRef} />
            <ConfirmDialog
                visible={deleteDialog}
                onHide={esconderConfirmacao}
                header="Confirmação"
                message={<span>{"Deseja realmente remover essa relação?"}</span>}
                icon="pi pi-info-circle p-mr-3"
                accept={deletaRelacao}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderConfirmacao}
                rejectLabel="Não"
            />
        </PageBase>
    );
};
