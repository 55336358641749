import React, { useCallback, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Corrigir, FiltroAvancado, InformarDevolucao, Visualizar } from "./modals";
import { FiltroAvancado as Filtro, Indicadores } from "./features";
import { Dropdown } from "@/components/Dropdown";
import { PageBase } from "@/components/PageBase";
import { ModalImpressoras } from "@/components/ModalImpressoras";
import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { Recebimentos as Listagem } from "./listagens";
import { Buttons } from "./buttons";
import { FILTRO_INDICADORES } from "@/assets/constants/financeiro";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import * as chavesRelatorios from "@/assets/constants/relatorios";
import permissoes from "@/assets/constants/permissoes";
import { key_filtros } from "@/assets/constants/filtros";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import useClearRefs from "@/hooks/useClearRefs";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

const URL_BACKEND = "/financeiro/recebimentos/?cancelado=false&quitado=false";

export const FinanceiroRecebimentosPage = () => {
    const [filtros, setFiltro, removerFiltro] = useLocalFiltro(key_filtros.FINANCEIRO_FINANCEIRO_RECEBIMENTOS);
    const [parcelasSelecionadas, setParcelasSelecionadas] = useState(null);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [recebimento, setRecebimento] = useState(null);
    const { httpPatch } = useHttp();

    const [filtroIndicadores, setFiltroIndicadores] = useLocalFiltro(
        key_filtros.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_PERIODO,
        "mes"
    );

    const { verifyPermission } = useAuth();
    const history = useHistory();

    const modalFiltroAvancadoRef = useRef(null);
    const visualizarModalRef = useRef(null);
    const modalImpressorasRef = useRef(null);
    const listagemRef = useRef(null);
    const modalRef = useRef(null);
    const menuRef = useRef(null);
    const corrigirRef = useRef(null);
    const informarDevolucaoRef = useRef(null);

    useClearRefs(
        listagemRef,
        modalRef,
        modalImpressorasRef,
        menuRef,
        modalFiltroAvancadoRef,
        visualizarModalRef,
        corrigirRef,
        informarDevolucaoRef
    );

    const painelEsquerdoTabela = (
        <>
            <Buttons.Novo onClick={() => history.push("/financeiro/financeiro/recebimentos/form")} />
            <Buttons.Receber
                recebimentos={parcelasSelecionadas}
                onClick={() =>
                    history.push({
                        pathname: "/financeiro/financeiro/recebimentos/efetivados/form",
                        state: parcelasSelecionadas,
                    })
                }
            />
            <Filtro.Button
                className="p-mr-2 p-mb-2"
                filtros={filtros}
                totalizadorFiltros={totalizadorFiltros}
                setTotalizadorFiltros={setTotalizadorFiltros}
                onClick={() => modalFiltroAvancadoRef.current?.abrirModal()}
            />
            <Button
                label="Limpar filtro"
                icon={MAKO_ICONS.LIMPAR_FILTROS}
                className="p-button-warning p-mr-2 p-mb-2"
                onClick={() => {
                    removerFiltro();
                    setTotalizadorFiltros(0);
                }}
            />
        </>
    );

    const painelDireito = useMemo(() => {
        return (
            <Dropdown
                options={FILTRO_INDICADORES}
                placeholder="Filtro de indicadores"
                value={filtroIndicadores}
                showClear={false}
                onChange={(e) => setFiltroIndicadores(e.value)}
            />
        );
    }, [filtroIndicadores, setFiltroIndicadores]);

    const actionBodyTemplate = (rowData) => {
        const items = [
            {
                label: "Editar",
                icon: MAKO_ICONS.EDITAR,
                disabled: recebimento?.quitado,
                visible: verifyPermission([permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EDITAR]),
                command: () =>
                    history.push({
                        pathname: "/financeiro/financeiro/recebimentos/form",
                        state: recebimento?.agrupador,
                    }),
            },
            {
                label: "Informar devolução",
                icon: MAKO_ICONS.EDITAR,
                disabled: recebimento?.quitado || !!recebimento?.devolucao,
                visible: verifyPermission([permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EDITAR]),
                command: () => informarDevolucaoRef.current?.show(recebimento),
            },
            {
                label: "Corrigir",
                icon: MAKO_ICONS.EDITAR,
                disabled: !recebimento?.quitado,
                visible: verifyPermission([permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EFETIVADO_CORRIGIR]),
                command: () => corrigirRef.current?.show(recebimento),
            },
            {
                label: "Fracionar",
                icon: "fas fa-chart-pie",
                disabled: recebimento?.quitado,
                command: () =>
                    history.push({
                        pathname: "/financeiro/financeiro/recebimentos/fracionar",
                        state: recebimento,
                    }),
            },
            {
                label: "Cancelar",
                icon: MAKO_ICONS.CANCEL,
                disabled:
                    recebimento?.quitado ||
                    !verifyPermission([permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_CARCELARVENDA]) ||
                    recebimento?.cancelado,
                command: () => setConfirmDialog(true),
            },
            {
                label: "Imprimir recibo",
                icon: MAKO_ICONS.ARQUIVO_PDF,
                disabled: !recebimento?.quitado,
                command: () =>
                    modalImpressorasRef.current?.abrirModal({
                        chave: chavesRelatorios.RELATORIOS_EMITIR_FINANCEIRO_RECIBOQUITACAO,
                        enviar_fila: false,
                        filtros: {
                            recebimento: recebimento.id,
                        },
                    }),
            },
            {
                label: "Visualizar",
                icon: MAKO_ICONS.VISUALIZAR,
                command: () => visualizarModalRef.current?.show(recebimento),
            },
        ];

        return (
            <MakoActionsButtonsColumn>
                <Menu model={items} popup ref={menuRef} />
                <Buttons.Editar
                    onClick={(e) => {
                        setRecebimento(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const onFinish = (url, contador, filtros) => {
        setTotalizadorFiltros(contador);
        setFiltro(filtros);
    };

    const successCallbackCorrecao = () => {
        listagemRef.current?.buscarDados();
    };

    const successCallbackInformarDevolucao = () => {
        listagemRef.current?.buscarDados();
    };

    const cancelarRecebimento = useCallback(async () => {
        let sucesso = false;
        const handlers = {
            200: () => {
                listagemRef.current?.buscarDados();
                sucesso = true;
            },
        };

        await httpPatch(
            {
                url: `/financeiro/recebimentos/${recebimento?.id}/`,
                body: {
                    cancelado: true,
                    previsao: recebimento.previsao,
                },
            },
            handlers
        );
        return sucesso;
    }, [recebimento, httpPatch]);

    const indicadores = useMemo(() => {
        return <Indicadores filtros={{ filtro: filtroIndicadores }} />;
    }, [filtroIndicadores]);

    return (
        <>
            {indicadores}
            <PageBase>
                <Listagem
                    listagemRef={listagemRef}
                    titulo={<span>Recebimentos</span>}
                    actions={actionBodyTemplate}
                    filtros={{
                        filtro_periodo: filtroIndicadores,
                    }}
                    listagemProps={{
                        painelEsquerdo: painelEsquerdoTabela,
                        painelDireito: painelDireito,
                    }}
                    configTabela={{
                        selection: parcelasSelecionadas,
                        onSelectionChange: (e) => setParcelasSelecionadas(e.value),
                    }}
                />
            </PageBase>
            <Visualizar ref={visualizarModalRef} />
            <Corrigir ref={corrigirRef} successCallback={successCallbackCorrecao} />
            <InformarDevolucao ref={informarDevolucaoRef} successCallback={successCallbackInformarDevolucao} />
            <FiltroAvancado
                ref={modalFiltroAvancadoRef}
                url={URL_BACKEND}
                onSuccess={onFinish}
                filtros={filtros}
                setFiltros={setFiltro}
                removerFiltro={removerFiltro}
                setTotalizadorFiltros={setTotalizadorFiltros}
            />
            <MakoConfirmDialog
                visible={confirmDialog}
                setVisible={setConfirmDialog}
                message={
                    recebimento && (
                        <span>
                            {"Deseja realmente cancelar o recebimento: "}
                            <b>{recebimento.id}</b>?
                        </span>
                    )
                }
                accept={cancelarRecebimento}
            />
            <ModalImpressoras ref={modalImpressorasRef} />
        </>
    );
};
