import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Button } from "primereact/button";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { ItensSaidaModal } from "./modal/saidaModal";
import useTroca from "@/hooks/useTroca";
import { BotaoDelete } from "@/components/BotaoDelete";
import useToast from "@/hooks/useToast";

export const ItensSaidaForm = () => {
    const { dadosBasicos } = useTroca(null);
    const listagemRef = useRef(null);
    const modalRef = useRef(null);
    const { showSuccess } = useToast();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TROCA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar informações do item"
                    onClick={() => modalRef.current?.abrirModal(rowData)}
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TROCA_EDITAR]}
                    componente={BotaoDelete}
                    url="/vendas/itens-troca-saida/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja mesmo remover o item de saída?</span>}
                    msgToastErroExclusao="O item não pode ser removido."
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "O item foi inserido com sucesso.",
                            life: 3000,
                        });
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_SERVICO_INCLUIR]}
                componente={Button}
                label="Inserir"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => modalRef.current?.setVisible(true)}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "100px" } },
        { field: "descricao_reduzida", header: "Descrição reduzida", style: { minWidth: "250px" } },
        { field: "unidade_venda.nome", header: "Unidade venda", style: { minWidth: "150px" } },
        { field: "quantidade", header: "Quantidade", style: { minWidth: "150px" } },
        {
            field: "valor_unitario",
            header: "Valor unitário",
            action: (e) => parseNumberToMoneyHTML(e.valor_unitario),
            style: { minWidth: "150px" },
        },
        {
            field: "subtotal",
            header: "Valor total",
            action: (e) => parseNumberToMoneyHTML(e.subtotal),
            style: { minWidth: "100px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const aposSalvar = () => {
        listagemRef.current?.buscarDados();
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/vendas/itens-troca-saida?troca=${dadosBasicos.id}`}
            />
            <ItensSaidaModal ref={modalRef} aposSalvar={aposSalvar} />
        </PageBase>
    );
};
