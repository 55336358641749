import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

import { Fracionar } from "../Recebimentos/features";
import { PageBase } from "@/components/PageBase";

export const FracionamentoRecebimentoPage = () => {
    const [formatarDocumento] = useFormatCNPJCPF();
    const { state } = useLocation();
    const history = useHistory();

    const recebimento = useMemo(() => {
        const { quantidade_parcelas, numero_parcela } = state || {};
        return {
            ...state,
            parcela: numero_parcela === 0 ? "ENTRADA" : `${numero_parcela}/${quantidade_parcelas}`,
        };
    }, [state]);

    const successCallback = () => {
        history.push("/financeiro/financeiro/recebimentos");
    };

    const cancelCallback = () => {
        history.push("/financeiro/financeiro/recebimentos");
    };

    const devedor = useMemo(() => {
        const { devedor } = state || {};
        return {
            nome: devedor.nome,
            doc: formatarDocumento(devedor.identificacao),
        };
    }, [state, formatarDocumento]);

    return (
        <PageBase>
            <h5>Fracionar parcela</h5>
            <Fracionar.InfoDevedor devedor={devedor} />
            <Fracionar.Form
                recebimento={recebimento}
                successCallback={successCallback}
                cancelCallback={cancelCallback}
            />
        </PageBase>
    );
};
