import React, { useCallback } from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";

import useDesprocessarPendenciaCaixa from "@/pages/Financeiro/Financeiro/GestaoCaixa/features/Pendencias/hooks/useDesprocessarPendencia";
import useReceberCrediario from "../../../../../hooks/useReceberCrediario";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useToast from "@/hooks/useToast";

const { FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_DESPROCESSAR } = permissoes;

export const Cancelar = ({ pendenciaId, onSuccessCallback = () => {} }) => {
    const { pendencia, possuiRecebimento } = useReceberCrediario();
    const { caixaMov } = useCaixaMovimento();
    const { showWarning } = useToast();
    const handlerDesprocessar = useDesprocessarPendenciaCaixa(caixaMov?.id);

    const handler = useCallback(async () => {
        if (pendencia && pendenciaId) {
            if (possuiRecebimento) {
                showWarning({
                    summary: "Alerta!",
                    detail: "Cancele as formas de recebimentos existentes para poder executar está ação.",
                    life: 5000,
                });
                return;
            }
            const { tipo_chave } = pendencia || {};
            const data = await handlerDesprocessar({ pendencia_id: pendenciaId, tipo_chave });
            onSuccessCallback(data);
        }
    }, [pendencia, pendenciaId, possuiRecebimento, handlerDesprocessar, onSuccessCallback, showWarning]);

    return (
        <MakoControleAcesso
            componente={MakoButton}
            permissao={[FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_DESPROCESSAR]}
            icon="pi pi-sign-out"
            tooltip="Cancelar"
            type="button"
            className="p-button-rounded p-button-danger"
            onClick={handler}
        />
    );
};
