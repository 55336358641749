import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";

import {
    ClienteFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { MakoDropdownCargas } from "@/components/MakoInputs/MakoDropdownCargas";
import MakoListagem from "@/components/MakoListagem";

import { TIPO_BOOLEAN_RETIRAR_PREVISOESENTREGA } from "@/assets/constants/constants";
import { formatDocumento, gerarFiltrosUrl } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";
import { MakoDropdownOrdenado } from "@/components/MakoInputs";

const BASE_TITULO = <span>Listagem entregas realizadas</span>;

const BASE_URL = "/vendas/previsoes-entregas-itens-vendas/";

const BASE_FILTROS = {
    entregue: true,
};

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { width: "10%" },
};

const BASE_FILTROS_CABECALHO = {
    item_venda__venda: { value: null, matchMode: "exact" },
    item_venda__venda__cliente_id: { value: null, matchMode: "exact" },
    item_venda__sku__codigo: { value: null, matchMode: "contains" },
    retirar: { value: null, matchMode: "exact" },
    data_finalizacao: { value: null, matchMode: "exact" },
    carga: { value: null, matchMode: "exact" },
    item_venda__sku__descricao_reduzida: {
        operator: "and",
        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
    },
};

const retirarFiltroTemplate = (options) => {
    return (
        <DropdownFiltroTemplate
            dropdownProps={{
                optionValue: "value",
                optionLabel: "label",
                options: TIPO_BOOLEAN_RETIRAR_PREVISOESENTREGA,
            }}
            options={options}
        />
    );
};

const cargaFiltroTemplate = (options) => {
    return (
        <MakoDropdownCargas
            value={options.value}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            className={"p-column-filter"}
        />
    );
};

const BASE_COLUMNS = [
    {
        field: "carga.codigo",
        header: "Carga",
        align: "center",
        style: { width: "8%" },
        filter: true,
        filterElement: cargaFiltroTemplate,
        filterField: "carga",
    },
    {
        field: "venda_id",
        header: "Orçamento",
        align: "center",
        style: { width: "6%" },
        filter: true,
        filterElement: TextoFiltroTemplate,
        filterField: "item_venda__venda",
    },
    {
        field: "cliente",
        header: "Cliente",
        action: ({ cliente }) => `${cliente.nome} - ${formatDocumento(cliente.identificacao)}`,
        filter: true,
        filterElement: ClienteFiltroTemplate,
        filterField: "item_venda__venda__cliente_id",
    },
    {
        field: "sku.codigo",
        style: { width: "6%" },
        header: "Código",
        filter: true,
        filterElement: TextoFiltroTemplate,
        filterField: "item_venda__sku__codigo",
    },
    {
        field: "sku.descricao_reduzida",
        header: "Descrição",
        filter: true,
        filterElement: TextoFiltroTemplate,
        filterField: "item_venda__sku__descricao_reduzida",
    },
    {
        field: "retirar",
        header: "Tipo",
        align: "center",
        style: { width: "6%" },
        action: ({ retirar }) => {
            return TIPO_BOOLEAN_RETIRAR_PREVISOESENTREGA.find(({ value }) => value === retirar).label;
        },
        filter: true,
        filterElement: retirarFiltroTemplate,
    },
    {
        field: "datahora_entrega",
        header: "Entregue em",
        align: "center",
        style: { width: "8%" },
        action: ({ retirar, datahora_entrega, retirado_em }) => {
            if (!retirar) return dataToStr(datahora_entrega, "dd/MM/yyyy");
            return dataToStr(retirado_em, "dd/MM/yyyy");
        },
        filter: true,
        filterField: "data_finalizacao",
        filterElement: DateFiltroTemplate,
    },
];

const rowClass = (rowData) => {
    return {
        "table-entregasrealiazadas-effective": !!rowData.carga,
    };
};

const Component = (
    { actions, listagemProps = {}, configTabela = {}, titulo = BASE_TITULO, filtros = {}, listagemRef = null },
    ref
) => {
    const [query, setQuery] = useState(filtros);
    const [ordering, setOrdering] = useState("-id");
    const { painelEsquerdo: esquerdo, ...restListagemProps } = listagemProps;

    const painelEsquerdo = useMemo(() => {
        return (
            <div className="mako-painel-esquerdo">
                {esquerdo}
                <MakoDropdownOrdenado
                    utilizarPadraoRecente
                    value={ordering}
                    onChange={({ value }) => setOrdering(value)}
                    showClear={false}
                />
            </div>
        );
    }, [esquerdo, ordering]);

    const colunas = useMemo(() => {
        if (actions) return [...BASE_COLUMNS, { ...BASE_COLUMN_ACTIONS, action: actions }];
        return BASE_COLUMNS;
    }, [actions]);

    const filter = useCallback((values) => {
        setQuery({ ...(values || {}) });
    }, []);

    const url = useMemo(() => {
        const _query = { ...(query || {}), ...BASE_FILTROS };
        return `${BASE_URL}?${gerarFiltrosUrl(_query)}`;
    }, [query]);

    useImperativeHandle(ref, () => ({ filter }), [filter]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo={titulo}
            colunas={colunas}
            urlPesquisa={url}
            filtarPorEmpresa
            naoBuscarSemEmpresa
            fieldFiltroEmpresa="item_venda__venda__empresa_id"
            ordering={ordering}
            painelEsquerdo={painelEsquerdo}
            {...restListagemProps}
            configTabela={{
                lazy: true,
                paginator: true,
                rowClassName: rowClass,
                ...configTabela,
            }}
            filtros={BASE_FILTROS_CABECALHO}
        />
    );
};

export const EntregasRealizadas = forwardRef(Component);
