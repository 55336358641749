import React from "react";

import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { TIPO_CARGO_CHOICE } from "@/assets/constants/constants";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const url = "/pessoas/cargos/";

export const CargosForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();

    const history = useHistory();

    const formik = useFormik({
        initialValues: props.location.state?.tag || {
            nome: "",
            nivel: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                nivel: Yup.string().nullable().required("O campo 'nivel' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Cargo cadastrado com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };
                showLoading();
                await httpPost({ url: url, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Cargo alterada com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };
                showLoading();
                await httpPut({ url: `${url}${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        history.push("/gestao/cadastros/cargos");
    };

    return (
        <PageBase>
            {<h3>{!formik.values.id ? "Nova cargo" : "Manutenção de cargo"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="nome" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="nivel" label="Nível" obrigatorio />
                        <Dropdown
                            id="nivel"
                            name="nivel"
                            options={TIPO_CARGO_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.nivel}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nivel })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.nivel && <small className="p-error">{formik.errors.nivel}</small>}
                    </div>
                    <div className="p-field-checkbox p-mt-4 p-col-12 p-md-3">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <Label htmlFor="ativo" label="Ativo?" />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        icon="pi pi-save"
                        label="Gravar"
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        icon="pi pi-angle-double-left"
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
