import React from "react";

import MakoListagem from "@/components/MakoListagem";

import { parseMoeda } from "@/assets/util/util";

const URL_BACKEND = "/financeiro/pagamentos/?";

export const Listagem = () => {
    const numeroParcelaBodyTemplate = (rowData) => {
        return <span>{`${rowData.numero_parcela}/${rowData.quantidade_parcelas}`}</span>;
    };

    const colunas = [
        {
            field: "entrada",
            header: "Compra",
            style: { width: "8%" },
        },
        {
            field: "credor.nome",
            header: "Credor",
        },
        {
            field: "numero_parcela",
            header: "Parcela",
            style: { width: "10%" },
            align: "center",
            action: (e) => numeroParcelaBodyTemplate(e),
        },
        {
            field: "vencimento",
            header: "Vencimento",
            style: { width: "12%" },
            dateFormat: "dd/MM/yyyy",
            align: "center",
        },
        {
            field: "valor",
            header: "Valor",
            money: true,
            style: { width: "12%" },
            align: "right",
            action: ({ valor, valor_pago }) => {
                if (parseFloat(valor_pago) === 0) return parseMoeda(valor, false);
                return parseMoeda(valor_pago, false);
            },
        },
        {
            field: "dias_atraso",
            header: "Atraso",
            style: { width: "5%" },
            align: "center",
        },
    ];

    const rowClass = (pagamento) => {
        const dias_atraso = pagamento.dias_atraso;
        if (pagamento.cancelado) return "table-pagamentos-overdue";
        else if (pagamento.quitado) return "table-pagamentos-effective";
        else if (!pagamento.quitado && dias_atraso > 0) return "table-pagamentos-maturity";
        else return "table-pagamentos-pending";
    };

    return (
        <div className="p-mt-4">
            <MakoListagem
                colunas={colunas}
                urlPesquisa={URL_BACKEND}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                configTabela={{
                    selectionMode: "multiple",
                    rowClassName: rowClass,
                    paginator: true,
                    lazy: true,
                }}
            />
        </div>
    );
};
