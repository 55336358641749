import React, { useRef } from "react";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import useClearRefs from "@/hooks/useClearRefs";
import { DropdownFiltroTemplate, MoedaFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

export const RegraArredondamentoValorPage = () => {
    const listagemRef = useRef(null);
    useClearRefs(listagemRef);

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Nova"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/vendas/cadastros/arredondamento-valor/form"}
            />
        </>
    );

    const acimaBodyTemplate = (rowData) => {
        if (rowData.acima) return <span>Para cima</span>;
        return <span>Para baixo</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_ARREDONDAMENTOVALOR_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/vendas/cadastros/arredondamento-valor/form",
                        state: rowData,
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_ARREDONDAMENTOVALOR_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/compras/tipos-entradas/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir a regra de arredondamento <b>{rowData.descricao}</b>?
                        </span>
                    }
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const DirecaoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: true, label: "Para cima" },
                        { value: false, label: "Para baixo" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "minimo",
            header: "Valor mínimo",
            money: true,
            style: { width: "12%" },
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "maximo",
            header: "Valor máximo",
            money: true,
            style: { width: "12%" },
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "acima",
            header: "Direção do arredondamento",
            style: { width: "20%" },
            action: (e) => acimaBodyTemplate(e),
            filter: true,
            filterElement: DirecaoFiltroTemplate,
        },
        {
            field: "actions",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];
    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de regras de arredondamento de preços"
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                urlPesquisa="/vendas/regra-arredondamento-valor/"
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    minimo: { value: null, matchMode: "equals" },
                    maximo: { value: null, matchMode: "equals" },
                    acima: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
