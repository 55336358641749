import { InputNumber } from "primereact/inputnumber";

export const PorcentagemFiltroTemplate = (options) => {
    return (
        <InputNumber
            suffix=" %"
            minFractionDigits={2}
            maxFractionDigits={2}
            value={options.value}
            onValueChange={(e) => options.filterCallback(e.value)}
        />
    );
};
