import React from "react";
import { Link } from "react-router-dom";

export const NotFound = () => {
    return (
        <div className="exception-body notfound">
            <img src="assets/layout/images/logo-white.svg" alt="diamond-layout" className="logo" />

            <div className="exception-content">
                <div className="exception-title">NÃO ENCONTRADO</div>
                <div className="exception-detail">Recurso solicitado não está disponível.</div>
                <Link to="/">Voltar para o Dashboard</Link>
            </div>
        </div>
    );
};
