export const PATHS_VALIDACAO = {
    perfil_pj: {
        root: "Pessoa Jurídica",
        cnpj: "CNPJ",
        razao_social: "Razão social",
        nome_fantasia: "Nome fantasia",
        inscricao_estadual: "Inscrição estadual",
        inscricao_municipal: "Inscrição municipal",
        inscricao_suframa: "Inscrição suframa",
        data_constituicao: "Data de constituição",
        data_alteracao_contratual: "Data de alteração contratual",
        responsavel_legal: "Responsável legal",
        indicador_inscricao_estadual: "Indicador de inscrição estadual",
        regime_tributario: "Regime tributário",
        estado: "Estado da inscrição estadual",
        optante_simples: "Optante pelo Simples Nacional",
        substituto_issqn: "Substituto tributário ISSQN",
        ativo: "Ativo",
    },
    enderecoperfil_set: {
        root: "Endereços",
        identificacao: "Identificação",
        cep: "CEP",
        logradouro: "Logradouro",
        complemento: "Complemento",
        numero: "Número",
        bairro: "Bairro",
        ponto_referencia: "Ponto de referência",
        cidade: "Cidade",
        principal: "Principal",
        ativo: "Ativo",
        data_alteracao: "Data de alteração",
        usuario_alteracao: "Usuário de alteração",
        tag_endereco: "Tag do endereço",
    },
    telefoneperfil_set: {
        root: "Telefones",
        identificacao: "Identificação",
        tags: "Tags do telefone",
        telefone: "Telefone",
        contato: "Contato",
        operadora: "Operadora",
        principal: "Principal",
        e_whatsapp: "É WhatsApp?",
        ativo: "Ativo",
        data_alteracao: "Data de alteração",
        usuario_alteracao: "Usuário de alteração",
    },
    emailperfil_set: {
        root: "E-mail's",
        identificacao: "Identificação",
        tags: "Tags do email",
        email: "E-mail",
        data_hora_validacao: "Data/hora de validação",
        data_hora_confirmacao: "Data/hora de confirmação",
        principal: "Principal",
        ativo: "Ativo",
        data_alteracao: "Data de alteração",
        usuario_alteracao: "Usuário de alteração",
    },
    entrada: {
        root: "Entrada",
        empresa: "Empresa",
        competencia_estoque: "Competência de estoque",
        competencia_financeira: "Competência financeira",
        datahora_entrada: "Data/hora entrada",
        ordem_compra_principal: "Ordem de compra principal",
        categoria_titulo: "Categoria título",
        tipo_entrada: "Tipo de entrada",
        fornecedor: "Fornecedor",
        representante: "Representante",
        valor_total_produtos: "Valor total dos produtos",
        valor_total_servicos: "Valor total dos serviços",
        valor_total_frete: "Valor total do frete",
        valor_total_seguro: "Valor total do seguro",
        valor_total_outros: "Valor total (outros)",
        valor_custos_adicionais: "Custos adicionais",
        valor_custos_extras: "Custos extras",
        valor_antecipacao: "Valor da antecipação",
        valor_total_parcelas: "Valor total das parcelas",
        valor_total_entrada: "Valor total da entrada",
        status: "Status",
        custo_calculado: "Custo foi calculado?",
        numero_parcelas: "Quantidade de parcelas",
        intervalo_parcelas: "Dias entre as parcelas",
        usuario_cadastro: "Usuário que cadastrou",
        usuario_validacao: "Usuário que validou",
        datahora_validacao: "Data/hora validação",
        usuario_finalizacao: "Usuário que finalizou",
        datahora_finalizacao: "Data/hora finalização",
        anotacoes: "Anotações",
    },
};
