import React, { useCallback, useState } from "react";

import { Button } from "primereact/button";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { Label } from "@/components/Label";

import { formatDocumento, gerarFiltrosUrl, parseMoeda } from "@/assets/util/util";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useAtribuirPendenciaCaixa from "@/pages/Financeiro/Financeiro/GestaoCaixa/features/Pendencias/hooks/useAtribuirPendencia";
import useReceberCrediario from "../../../../../hooks/useReceberCrediario";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const BASE_URL = "/financeiro/recebimentos/";

const BASE_FILTROS = {
    venda__isnull: false,
    cancelado: false,
    forma_recebimento__tipo: 8,
    venda__caixa_movimento__isnull: false,
    caixa__isnull: true,
    possui_grupo: false,
    search: "",
};

const URL = `${BASE_URL}?${gerarFiltrosUrl(BASE_FILTROS)}`;

const aposPesquisar = (data = []) => {
    return data.map(({ venda, devedor, valor, valor_recebido, numero_parcela, quantidade_parcelas, ...rest }) => {
        const quitar = parseMoeda(parseFloat(valor) - parseFloat(valor_recebido));
        const cliente = `${devedor?.nome} - ${formatDocumento(devedor?.identificacao)}`;
        const parcela = `${numero_parcela}/${quantidade_parcelas}`;
        return { ...rest, label: `Venda ${venda}: ${cliente} - ${parcela} - ${quitar}` };
    });
};

export const BuscarParcela = ({ onSuccessCallback = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [value, setValue] = useState(null);

    const { pendencia } = useReceberCrediario();
    const { caixaMov } = useCaixaMovimento();
    const handler = useAtribuirPendenciaCaixa(caixaMov?.id);

    const { grupo } = pendencia || {};

    const onClick = useCallback(async () => {
        const extraData = { grupo: grupo?.id };
        showLoading();
        const data = await handler({ pendencia_id: value.id, tipo_chave: "recebimento_crediario", extraData });
        hideLoading();
        if (data) {
            setValue(null);
            onSuccessCallback();
        }
    }, [grupo, showLoading, handler, value, hideLoading, onSuccessCallback]);

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-10">
                    <Label htmlFor="parcela" label="Adicionar outra parcela" />
                    <MakoAutoComplete
                        id="parcela"
                        name="parcela"
                        urlSearch={URL}
                        field="label"
                        aposPesquisar={aposPesquisar}
                        value={value}
                        disabled={loading}
                        onChange={(e) => setValue(e.value)}
                        placeholder="Digite o nome do cliente ou número da venda"
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Button
                        icon={MAKO_ICONS.NOVO}
                        type="button"
                        className="p-button-success"
                        label="Adicionar"
                        style={{ top: "1.9rem", width: "100%" }}
                        disabled={!value?.id}
                        loading={loading}
                        onClick={onClick}
                    />
                </div>
            </div>
        </>
    );
};
