import { useContext } from "react";
import ReceberCrediarioContext from "../contexts/receberCrediarioContext";

const useReceberCrediario = () => {
    const {
        atualizarListagemParcelas,
        listagemParcelasRef,
        atualizarListagemRecebimentos,
        listagemRecebimentosRef,
        necessitaCalcular,
        verificarNecessidadeCalcular,
        pendencia,
        info,
        atualizarInfo,
        handlerOnRemoverParcela,
        verificarRecebimentos,
        possuiRecebimento,
    } = useContext(ReceberCrediarioContext);

    return {
        atualizarListagemParcelas,
        listagemParcelasRef,
        atualizarListagemRecebimentos,
        listagemRecebimentosRef,
        necessitaCalcular,
        verificarNecessidadeCalcular,
        pendencia,
        info,
        atualizarInfo,
        handlerOnRemoverParcela,
        verificarRecebimentos,
        possuiRecebimento,
    };
};

export default useReceberCrediario;
