import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";

export const FornecedorFiltroTemplate = (options) => {
    return (
        <MakoInputFornecedor
            value={options.value}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            className="p-column-filter"
        />
    );
};
