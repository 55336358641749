import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { calcularJurosComposto } from "@/assets/util/calcFinanceiros";
import { formatarCasasDecimais } from "@/assets/util/util";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Label } from "@/components/Label";

import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";

const TIPOS_VALIDOS_FORMA_RECEBIMENTO_QUALQUER = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.filter(
    ({ id }) => ![8, 7, 5].includes(id)
).map(({ id }) => id);

const TIPOS_VALIDOS_FORMA_RECEBIMENTO_CONTA_BANCARIA = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.filter(
    ({ id }) => ![8, 5].includes(id)
).map(({ id }) => id);

const PagamentoEfetivadoModalForm = ({ onConfirmar }, ref) => {
    const [errorFormaRecebRateio, setErrorFormaRecebRateio] = useState(null);
    const [formasPagamentoRateio, setFormasPagamentoRateio] = useState([]);
    const [formasPagamento, setFormasPagamento] = useState([]);
    const [formaPagamento, setFormaPagamento] = useState(null);
    const [tipoDesconto, setTipoDesconto] = useState("percent");
    const [visible, setVisible] = useState(false);
    const [contas, setContas] = useState([]);
    const [valor, setValor] = useState(0);

    const { showWarning } = useToast();
    const { empresaSelecionadaId } = useEmpresa();

    const { setValues, setFieldError, setFieldValue, ...formik } = useFormik({
        initialValues: {
            documento: "",
            parcela: "",
            data_emissao: null,
            vencimento: null,
            dias: 0,
            descontos: 0.0,
            acrescimo: 0,
            valor: 0,
            multa: 0,
            juros: 0,
            recebido: 0,
            conta_financeira: null,
        },
        onSubmit: handleSubmit,
    });

    const validarMulta = (values) => {
        if (values.multa > 0) {
            const multaOriginal = formatarCasasDecimais(values.valor * values.percent_multa);
            const multaDescMax = formatarCasasDecimais((multaOriginal * values.percent_multa_desc_max) / 100);
            return !(values.percent_multa < multaOriginal - multaDescMax);
        }
        return true;
    };

    const validarJuros = (values) => {
        if (values.juros > 0) {
            const { valor, percent_juros, dias, percent_juros_desc_max } = values;
            const jurosOriginal = formatarCasasDecimais(calcularJurosComposto(valor, percent_juros, dias, false));
            const jurosDescMax = formatarCasasDecimais((jurosOriginal * percent_juros_desc_max) / 100);
            return !(values.percent_juros < jurosOriginal - jurosDescMax);
        }
        return true;
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                multa: Yup.number()
                    .nullable()
                    .test("multa validation", "O valor aplicado de multa não é permitido.", () => validarMulta(values)),
                juros: Yup.number()
                    .nullable()
                    .test("juros validation", "O valor aplicado de juros não é permitido.", () => validarJuros(values)),
                conta_financeira: Yup.number().required("O campo 'conta financeira' é obrigatório"),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const totalRateio = formasPagamentoRateio.reduce((total, atual) => total + atual.valor, 0);

            if (totalRateio > values.recebido)
                return showWarning({
                    summary: "Alerta!",
                    detail: "A soma dos valores está maior que o total a ser pago.",
                    life: 5000,
                });
            else if (totalRateio < values.recebido)
                return showWarning({
                    summary: "Alerta!",
                    detail: "A soma dos valores está menor que o total a ser pago.",
                    life: 5000,
                });
            if (onConfirmar)
                onConfirmar({
                    ...values,
                    pago: values.recebido,
                    forma_pagamento: formasPagamentoRateio,
                });
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;

                    if (err.path === "rateio") {
                        setErrorFormaRecebRateio(err.message);
                    }
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = async (parcela) => {
        setVisible(true);
        if (parcela) {
            const { numero_parcela, quantidade_parcelas } = parcela;
            await setValues(
                {
                    ...parcela,
                    parcela: numero_parcela === 0 ? "ENTRADA" : `${numero_parcela}/${quantidade_parcelas}`,
                    descontos: 0,
                    multa: !parcela.multa ? 0 : parcela.multa,
                    juros: !parcela.juros ? 0 : parcela.juros,
                    recebido: 0,
                },
                true
            );
        }
        if (!formik.values.conta_financeira)
            setFieldError("conta_financeira", "O campo 'conta financeira' é obrigatório");
    };

    useImperativeHandle(ref, () => {
        return {
            abrirModal,
        };
    });

    const incluirRateioFormaPagamento = () => {
        let rateios = [...formasPagamentoRateio];
        const _formaPagamento = formasPagamento.find((fr) => fr.id === formaPagamento);
        rateios.push({ ..._formaPagamento, valor });
        const total = rateios.reduce((total, atual) => total + atual.valor, 0);
        if (total <= formik.values.valor) {
            setFormasPagamentoRateio(rateios);
            setErrorFormaRecebRateio(null);
        } else {
            setErrorFormaRecebRateio("A soma total ultrapassa o valor a ser pago.");
        }
    };

    const excluirRateioFormaPagamento = (index) => {
        const rateios = [...formasPagamentoRateio];
        rateios.splice(index, 1);
        setFormasPagamentoRateio(rateios);
    };

    const actionBodyTemplate = (rowData, rowInfo) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => excluirRateioFormaPagamento(rowInfo.rowIndex)}
                />
            </div>
        );
    };

    const colunas = [
        { field: "descricao", header: "Forma de pagamento" },
        { field: "valor", header: "Valor", style: { width: "30%" }, money: true },
        {
            field: "actions",
            header: "Ações",
            style: { width: "10%" },
            action: (e, i) => actionBodyTemplate(e, i),
        },
    ];

    const rodape = () => {
        return (
            <MakoActionsButtons className="p-jc-end">
                <Button
                    type="submit"
                    label="Confirmar"
                    icon={MAKO_ICONS.GRAVAR}
                    onClick={formik.handleSubmit}
                    autoFocus
                />
            </MakoActionsButtons>
        );
    };

    const calcularMulta = useCallback(() => {
        if (formik.values.multa > 0) {
            const multaOriginal = formatarCasasDecimais(formik.values.valor * formik.values.percent_multa);
            const pMulta = 100 - (100 * formik.values.multa) / multaOriginal;
            if (pMulta > formik.values.percent_multa_desc_max) {
                setFieldError("multa", "O valor aplicado de multa não é permitido.");
            } else {
                setFieldError("multa", null);
            }
        }
    }, [
        formik.values.multa,
        formik.values.valor,
        formik.values.percent_multa,
        formik.values.percent_multa_desc_max,
        setFieldError,
    ]);

    const calcularJuros = useCallback(() => {
        if (formik.values.juros > 0) {
            const jurosOriginal = formatarCasasDecimais(formik.values.valor * formik.values.percent_juros);
            const pJuros = 100 - (100 * formik.values.juros) / jurosOriginal;
            if (pJuros > formik.values.percent_juros_desc_max) {
                setFieldError("juros", "O valor aplicado de juros não é permitido.");
            } else {
                setFieldError("juros", null);
            }
        }
    }, [
        formik.values.juros,
        formik.values.valor,
        formik.values.percent_juros,
        formik.values.percent_juros_desc_max,
        setFieldError,
    ]);

    const calcularDescontosPercent = useCallback(() => {
        if (formik.values.descontos_percent > 0) {
            const descontos = formik.values.valor * (formik.values.descontos_percent / 100);
            setFieldValue("descontos", descontos);
            setTipoDesconto("valor");
        }
    }, [formik.values.descontos_percent, formik.values.valor, setFieldValue]);

    const calcularValorFaltante = useCallback(() => {
        const valorInformado = formasPagamentoRateio.reduce((total, p) => total + p.valor, 0);
        return formik.values.recebido - valorInformado;
    }, [formik.values.recebido, formasPagamentoRateio]);

    const somarTotalRecebimento = useCallback(() => {
        let total = formik.values.valor + formik.values.multa + formik.values.juros - formik.values.descontos;
        setFieldValue("recebido", formatarCasasDecimais(total));
        setValor(total);
        setFormasPagamentoRateio([]);
    }, [formik.values.valor, formik.values.multa, formik.values.juros, formik.values.descontos, setFieldValue]);

    const aposBuscarFormas = useCallback((data) => {
        setFormasPagamento(data);
        return data;
    }, []);

    const tiposFormas = useMemo(() => {
        const _conta = contas.find(({ id }) => id === formik.values.conta_financeira);
        const tipo = _conta?.tipo_conta?.id;
        const tipos =
            tipo === 2 ? TIPOS_VALIDOS_FORMA_RECEBIMENTO_CONTA_BANCARIA : TIPOS_VALIDOS_FORMA_RECEBIMENTO_QUALQUER;
        return tipos.join(",");
    }, [contas, formik.values.conta_financeira]);

    const aposBuscar = useCallback((data) => {
        setContas(data);
        return data;
    }, []);

    const desabilitarFormas = useMemo(() => {
        const _valor = calcularValorFaltante();
        return _valor === 0 || !formik.values.conta_financeira;
    }, [calcularValorFaltante, formik.values.conta_financeira]);

    useEffect(() => {
        somarTotalRecebimento();
    }, [somarTotalRecebimento]);

    useEffect(() => {
        const _valor = calcularValorFaltante();
        setValor(_valor);
    }, [calcularValorFaltante]);

    useEffect(() => {
        if (formik.values.forma_pagamento) setFormasPagamentoRateio(formik.values.forma_pagamento);
    }, [formik.values.forma_pagamento]);

    return (
        <Dialog
            visible={visible}
            header="Forma de pagamento"
            footer={rodape}
            onHide={() => setVisible(false)}
            breakpoints={{ "960px": "80vw" }}
            style={{ width: "70vw" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="conta_financeira" label="Conta financeira" obrigatorio />
                        <Dropdown
                            id="conta_financeira"
                            name="conta_financeira"
                            url={`/financeiro/contas-financeiras/?query={id,descricao}&perfil=${empresaSelecionadaId}`}
                            placeholder="Selecione uma conta financeira"
                            emptyMessage="Não existem contas cadastradas."
                            aposBuscar={aposBuscar}
                            showClear
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.conta_financeira}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                        />
                        {formik.errors.conta_financeira && (
                            <small className="p-error">{formik.errors.conta_financeira}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="documento" label="Documento" />
                        <InputText id="documento" name="documento" disabled value={formik.values.documento} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="parcela" label="Parcela" />
                        <InputText id="parcela" name="parcela" disabled value={formik.values.parcela} />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data-emissao" label="Emissão" />
                        <MakoCalendar
                            id="data-emissao"
                            name="data_emissao"
                            disabled
                            valueCalendar={formik.values.data_emissao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data-vencimento" label="Vencimento" />
                        <MakoCalendar
                            id="data-vencimento"
                            name="vencimento"
                            disabled
                            valueCalendar={formik.values.vencimento}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="dias" label="Dias de atraso" />
                        <InputText id="dias" name="dias" disabled value={formik.values.dias} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="total-pagamento" label="Total a pagar" />
                        <MakoInputMoeda id="total-pagamento" disabled valueMoeda={formik.values.valor} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="total-descontos" label="Descontos" />
                        <div className="p-inputgroup">
                            {tipoDesconto === "percent" ? (
                                <>
                                    <InputNumber
                                        inputId="total-descontos"
                                        name="descontos_percent"
                                        suffix=" %"
                                        minFractionDigits={2}
                                        value={formik.values.descontos_percent}
                                        onChange={(e) => setFieldValue("descontos_percent", e.value)}
                                        onBlur={calcularDescontosPercent}
                                        className={classNames({ "p-invalid": formik.errors.descontos_percent })}
                                    />
                                    <Button
                                        icon="pi pi-dollar"
                                        type="button"
                                        className="p-button-help"
                                        tooltip="Conceder desconto por valor"
                                        tooltipOptions={{ position: "top" }}
                                        onClick={() => setTipoDesconto("valor")}
                                    />
                                </>
                            ) : (
                                <>
                                    <MakoInputMoeda
                                        id="total-descontos"
                                        name="descontos"
                                        valueMoeda={formik.values.descontos}
                                        onChange={(e) => setFieldValue("descontos", e.value)}
                                        // onBlur={calcularDescontos}
                                        className={classNames({ "p-invalid": formik.errors.descontos_percent })}
                                    />
                                    <Button
                                        icon="pi pi-percentage"
                                        type="button"
                                        tooltip="Conceder desconto por porcentagem"
                                        tooltipOptions={{ position: "top" }}
                                        className="p-button-help"
                                        onClick={() => setTipoDesconto("percent")}
                                    />
                                </>
                            )}
                        </div>
                        {formik.errors.descontos_percent && (
                            <small className="p-error">{formik.errors.descontos_percent}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="total-multa" label="Valor da multa" />
                        <MakoInputMoeda
                            id="total-multa"
                            valueMoeda={formik.values.multa}
                            onChange={(e) => {
                                setFieldValue("multa", e.value);
                            }}
                            onBlur={calcularMulta}
                            className={classNames({ "p-invalid": formik.errors.multa })}
                        />
                        {formik.errors.multa && <small className="p-error">{formik.errors.multa}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="total-juros" label="Valor dos juros" />
                        <MakoInputMoeda
                            id="total-juros"
                            valueMoeda={formik.values.juros}
                            onChange={(e) => setFieldValue("juros", e.value)}
                            onBlur={calcularJuros}
                            className={classNames({ "p-invalid": formik.errors.juros })}
                        />
                        {formik.errors.juros && <small className="p-error">{formik.errors.juros}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="total-recebimento" label="Total a receber" />
                        <MakoInputMoeda id="total-recebimento" disabled valueMoeda={formik.values.recebido} />
                    </div>
                </div>
            </form>
            <Divider align="center">
                <b>Formas de pagamento</b>
            </Divider>
            {errorFormaRecebRateio && <small className="p-error">{errorFormaRecebRateio}</small>}
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Dropdown
                        id="forma-pagamento"
                        placeholder="Selecione a forma de pagamento"
                        url={`/financeiro/formas-recebimentos/?limit=300&escopo__in=X,P&tipo__in=${tiposFormas}`}
                        aposBuscar={aposBuscarFormas}
                        filter
                        filterBy="id,descricao"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formaPagamento}
                        onChange={(e) => setFormaPagamento(e.value)}
                    />
                </div>
                <div className="p-field p-col-10 p-md-6">
                    <div className="p-inputgroup">
                        <MakoInputMoeda id="valor" valueMoeda={valor} onChangeMoeda={(e) => setValor(e.value)} />
                        <Button
                            icon="pi pi-plus"
                            className="p-button-success"
                            onClick={() => incluirRateioFormaPagamento()}
                            disabled={!formaPagamento || desabilitarFormas}
                        />
                    </div>
                </div>
            </div>
            <MakoListagem
                dadosLocal={formasPagamentoRateio}
                colunas={colunas}
                configTabela={{
                    paginator: true,
                }}
            />
        </Dialog>
    );
};

export const ModalPagamentoEfetivado = forwardRef(PagamentoEfetivadoModalForm);
