import React, { memo, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoUploadPreviewImage } from "@/components/MakoUploadPreviewImage";
import { dataToStr } from "@/assets/util/datas";
import { SEXO, ESTADO_CIVIL } from "@/assets/constants/constants";
import usePessoa from "@/hooks/usePessoa";
import { url } from "@/services/axios";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Label } from "@/components/Label";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import useHttp from "@/hooks/useHttp";

const IdentificacaoPEForm = () => {
    const [loading, setLoading] = useState(false);
    const [passaporteDuplicado, setPassaporteDuplicado] = useState(false);
    const { handlePessoaEstrangeira, handleDeletaImagem, handleFotoPerfil, pessoa, setSubmit } = usePessoa();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpGet } = useHttp();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            nome: "",
            sobrenome: "",
            nacionalidade: "",
            passaporte: "",
            estado_civil: "",
            sexo: "",
            data_nascimento: null,
            nome_curto: "",
            nome_pai: "",
            nome_mae: "",
            obs: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                sobrenome: Yup.string().required("O campo 'sobrenome' é obrigatório."),
                nacionalidade: Yup.string().required("O campo 'nacionalidade' é obrigatório."),
                passaporte: Yup.string().required("O campo 'passaporte' é obrigatório."),
                estado_civil: Yup.string().required("O campo 'estado civil' é obrigatório."),
                nome_curto: Yup.string().max(25, "Informe no máximo 25 caracteres."),
                sexo: Yup.string().required("O campo 'sexo' é obrigatório."),
                data_nascimento: Yup.date()
                    .max(new Date(), "A data de nascimento não pode ser posterior ao dia de hoje.")
                    .typeError("Informe uma 'data' válida"),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const perfil_pe = {
                ...values,
                data_nascimento: dataToStr(values.data_nascimento, "yyyy-MM-dd"),
            };
            handlePessoaEstrangeira(perfil_pe);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    function resetForm() {
        formik.resetForm();
        handlePessoaEstrangeira(null);
        setSubmit(false);
    }

    const verificarPassaporteExiste = async (value) => {
        const passaporte = value;
        if (passaporte.length > 0) {
            const handlers = {
                200: ({ data }) => {
                    if (data.results.length > 0) {
                        if (data.results[0].ativo) {
                            showWarning({
                                life: 5000,
                                summary: "Aviso!",
                                detail: "Esse passaporte já se encontra cadastrado na base de dados, porém está desativado.",
                            });
                        } else {
                            showWarning({
                                life: 5000,
                                summary: "Aviso!",
                                detail: "Esse passaporte já se encontra cadastrado na base de dados.",
                            });
                        }
                        setPassaporteDuplicado(true);
                        setSubmit(false);
                    } else {
                        setPassaporteDuplicado(false);
                    }
                },
            };
            setLoading(true);
            await httpGet(
                { url: `/pessoas/perfis/?query={nome,identificacao,ativo}&identificacao=${passaporte}` },
                handlers
            );
            setLoading(false);
        }
    };

    const onUploadFotoPerfil = (e) => {
        const { xhr } = e;
        const { data } = JSON.parse(xhr.response);
        handleFotoPerfil(data.foto);
        showSuccess({
            summary: "Sucesso",
            detail: "Foto de perfil enviada com sucesso!",
            life: 1500,
        });
    };

    const onErrorFotoPerfil = (e) => {
        const { xhr } = e;
        if (xhr.status === 400) {
            const { msg } = JSON.parse(xhr.response);
            showWarning({
                summary: "Falha",
                detail: msg,
                life: 3000,
            });
        } else showError();
    };

    useEffect(() => {
        if (pessoa.perfil_pe) setValues(pessoa.perfil_pe);
    }, [pessoa.perfil_pe, setValues]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-grid">
                <div className="p-col-12 p-md-2">
                    <div className="p-fluid p-formgrid p-grid p-jc-center">
                        <MakoUploadPreviewImage
                            ocultarImage={!!!pessoa?.foto}
                            exibirDeletar
                            imageConfig={{
                                src: pessoa.foto,
                                alt: "Foto de perfil",
                                width: "140rem",
                            }}
                            uploadConfig={{
                                url: `${url()}/pessoas/perfis-upload-foto/${pessoa?.id}/`,
                                name: "foto",
                                disabled: !!!pessoa?.id,
                                chooseLabel: "Selecionar foto",
                                maxFileSize: 1000000,
                                auto: true,
                                invalidFileSizeMessageSummary: "{0}: Tamanho do arquivo inválido, ",
                                invalidFileSizeMessageDetail: "tamanho máximo permitido: {0}.",
                                onUpload: onUploadFotoPerfil,
                                onError: onErrorFotoPerfil,
                            }}
                            deleteConfig={{
                                onClick: () => handleDeletaImagem(),
                            }}
                        />
                        {!!pessoa?.id && !pessoa?.foto ? (
                            <small className="p-error" style={{ color: "#FF0000", alignItems: "center" }}>
                                * Tamanho da imagem permitido: <b>1MB</b> (Dimensões <b>recomendáveis</b>: 180x420). *
                            </small>
                        ) : null}
                        {!!!pessoa?.id && (
                            <small className="p-error">
                                ** Poderá selecionar uma foto para o perfil após finalizar o cadastro. **
                            </small>
                        )}
                    </div>
                </div>
                <div className="p-col-12 p-md-10">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="nome" label="Nome" obrigatorio />
                            <InputText
                                id="nome"
                                name="nome"
                                value={formik.values.nome}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.nome })}
                            />
                            {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="sobrenome" label="Sobrenome" obrigatorio />
                            <InputText
                                id="sobrenome"
                                name="sobrenome"
                                value={formik.values.sobrenome}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.sobrenome,
                                })}
                            />
                            {formik.errors.sobrenome && <small className="p-error">{formik.errors.sobrenome}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="nome_curto" label="Nome curto" />
                            <InputText
                                id="nome_curto"
                                name="nome_curto"
                                value={formik.values.nome_curto}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.nome_curto && <small className="p-error">{formik.errors.nome_curto}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="nacionalidade" label="Nacionalidade" obrigatorio />
                            <Dropdown
                                id="nacionalidade"
                                name="nacionalidade"
                                url="/pessoas/paises/?limit=300"
                                optionValue="id"
                                optionLabel="nome"
                                placeholder="Selecione..."
                                filter
                                showClear
                                filterBy="nome"
                                value={formik.values.nacionalidade}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.nacionalidade,
                                })}
                            />
                            {formik.errors.nacionalidade && (
                                <small className="p-error">{formik.errors.nacionalidade}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="passaporte" label="Número do passaporte" obrigatorio />
                            <span className="p-input-icon-right">
                                {loading && <i className="pi pi-spin pi-spinner" />}
                                <InputText
                                    id="passaporte"
                                    name="passaporte"
                                    keyfilter="alphanum"
                                    value={formik.values.passaporte}
                                    onBlur={(e) => verificarPassaporteExiste(e.target.value)}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.passaporte })}
                                />
                                {formik.errors.passaporte && (
                                    <small className="p-error">{formik.errors.passaporte}</small>
                                )}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="estado-civil" label="Estado Civil" obrigatorio />
                            <Dropdown
                                id="estado-civil"
                                name="estado_civil"
                                options={ESTADO_CIVIL}
                                placeholder="Selecione..."
                                optionValue="id"
                                value={formik.values.estado_civil}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.estado_civil,
                                })}
                            />
                            {formik.errors.estado_civil && (
                                <small className="p-error">{formik.errors.estado_civil}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="sexo" label="Sexo" obrigatorio />
                            <Dropdown
                                id="sexo"
                                name="sexo"
                                options={SEXO}
                                placeholder="Selecione..."
                                optionValue="id"
                                value={formik.values.sexo}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.sexo })}
                            />
                            {formik.errors.sexo && <small className="p-error">{formik.errors.sexo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="data-nascimento" label="Data de Nascimento" />
                            <MakoCalendar
                                id="data-nascimento"
                                name="data_nascimento"
                                maxDate={new Date()}
                                valueCalendar={formik.values.data_nascimento}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_nascimento })}
                            />
                            {formik.errors.data_nascimento && (
                                <small className="p-error">{formik.errors.data_nascimento}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <Label htmlFor="nome_pai" label="Nome do pai" />
                            <InputText
                                id="nome_pai"
                                name="nome_pai"
                                value={formik.values.nome_pai}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <Label htmlFor="nome_mae" label="Nome da mãe" />
                            <InputText
                                id="nome_mae"
                                name="nome_mae"
                                value={formik.values.nome_mae}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <Label htmlFor="obs" label="Observação" />
                            <InputText id="obs" name="obs" value={formik.values.obs} onChange={formik.handleChange} />
                        </div>
                    </div>
                </div>
            </div>
            <CamposObrigatorios />
            <p className="p-error">* Lembre-se de gravar os dados antes de prosseguir ou finalizar</p>
            <MakoActionsButtons>
                <Button
                    type="submit"
                    icon={`pi ${!passaporteDuplicado ? MAKO_ICONS.GRAVAR : "pi-times"}`}
                    label={!passaporteDuplicado ? "Gravar" : "Passaporte já cadastrado"}
                    className={`${passaporteDuplicado ? "p-button-danger" : ""}`}
                    disabled={passaporteDuplicado}
                />
                <Button
                    type="reset"
                    icon={MAKO_ICONS.LIMPAR_FORM}
                    label="Limpar"
                    className="p-button-warning"
                    onClick={() => resetForm()}
                />
            </MakoActionsButtons>
        </form>
    );
};

export default memo(IdentificacaoPEForm);
