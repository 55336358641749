export const BASE_KEY = "@SharkDev";

const keys = {
    USER_KEY: `${BASE_KEY}: user`,
    TOKEN_KEY: `${BASE_KEY}: userToken`,
    GRUPOPERFIL_KEY: `${BASE_KEY}: grupoPerfil`,
    EMPRESA_KEY: `${BASE_KEY}: empresaSelecionada`,
    PARAMS_KEY: `${BASE_KEY}: params`,
    CAIXA_KEY: `${BASE_KEY}: caixa`,
    NOTIFICACOES_KEY: `${BASE_KEY}: notificacao`,
    TABELA: `${BASE_KEY}: tabela`,
};

export default keys;
