import React, { useMemo } from "react";

import { PESSOAS_TAGPERFIL_PADRAO_CLIENTE } from "@/assets/constants/parametros";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useParam from "@/hooks/useParam";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputCliente = ({ disabled, somenteAtivo, trazerTags = false, ...props }) => {
    const { getParam } = useParam();
    const [formatarDocumento] = useFormatCNPJCPF();

    const tagCliente = useMemo(() => {
        const tagPerfil = getParam(PESSOAS_TAGPERFIL_PADRAO_CLIENTE);
        return tagPerfil ? tagPerfil.valor : null;
    }, [getParam]);

    const url = useMemo(() => {
        const filtroAtivo = somenteAtivo ? "&ativo=true" : "";
        return `/pessoas/perfis?query={id,nome,identificacao,descricao,tipo_pessoa,telefoneperfil_set${
            trazerTags ? ",vinculoperfiltag_set" : ""
        }}${filtroAtivo}&tags=${tagCliente}&nome__unaccent__icontains=`;
    }, [somenteAtivo, trazerTags, tagCliente]);

    const autoCompleteClienteTemplate = (item) => {
        if (item.tipo_pessoa === "PF" || item.tipo_pessoa === "PJ") {
            return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
        }
        return <span>{`${item.nome} - ${item.identificacao}`}</span>;
    };

    return (
        <>
            <MakoAutoComplete
                disabled={!!!tagCliente || disabled}
                minCaracteresBusca={4}
                itemTemplate={autoCompleteClienteTemplate}
                field="nome"
                urlSearch={url}
                placeholder="Digite o nome do cliente para buscar... (min 4 caracteres)"
                {...props}
            />
            {!!!tagCliente && <small className="p-error">Falta parametrizar a identificação do cliente</small>}
        </>
    );
};
