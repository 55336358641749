import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { TIPO_ALTERA_RENDA_CHOICE } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";

const url = "/crediario/tipo-documento-renda/";

export const TiposDocRendaForm = () => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { state } = useLocation();
    const { httpPost, httpPatch } = useHttp();

    const { resetForm, ...formik } = useFormik({
        initialValues: state
            ? state.tipoDoc
            : {
                  id: "",
                  dias_validade: null,
                  descricao: "",
                  chave: "",
                  orientacoes: "",
                  altera_renda: 1,
                  digital: true,
                  precisa_aprovar: false,
                  ativo: true,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                chave: Yup.string().max(60).required("O campo 'chave' é obrigatório."),
                descricao: Yup.string().max(60).required("O campo 'descrição' é obrigatório."),
                dias_validade: Yup.number()
                    .required("O campo 'dias de validade' é obrigatório.")
                    .typeError("Informe uma quantidade de 'dias' válido."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de documento de renda cadastrado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push("/vendas/venda/crediario/tipo-documento-renda");
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de documento de renda alterado com sucesso!",
                            life: 1500,
                        });

                        resetForm();
                        history.push("/vendas/venda/crediario/tipo-documento-renda");
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        resetForm();
        history.push("/vendas/venda/crediario/tipo-documento-renda");
    };

    return (
        <PageBase>
            {
                <h3>
                    {!formik.values.id ? "Novo Tipo de Documento de Renda" : "Manutenção de Tipo de Documento de Renda"}
                </h3>
            }
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="chave">Chave *</label>
                        <Dropdown
                            id="chave"
                            name="chave"
                            placeholder="Selecione..."
                            url="/crediario/criterio-padrao?requer_upload=true"
                            optionValue="chave"
                            optionLabel="descricao"
                            value={formik.values.chave}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave })}
                        />
                        {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="dias_validade">Dias de validade *</label>
                        <InputNumber
                            id="dias_validade"
                            name="dias_validade"
                            showButtons
                            min={1}
                            onValueChange={formik.handleChange}
                            value={formik.values.dias_validade}
                            className={classNames({ "p-invalid": formik.errors.dias_validade })}
                            autoComplete="off"
                        />
                        {formik.errors.dias_validade && (
                            <small className="p-error">{formik.errors.dias_validade}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="altera_renda">Altera renda? *</label>
                        <Dropdown
                            id="altera_renda"
                            name="altera_renda"
                            placeholder="Selecione"
                            options={TIPO_ALTERA_RENDA_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.altera_renda}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.altera_renda })}
                        />
                        {formik.errors.altera_renda && <small className="p-error">{formik.errors.altera_renda}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2">
                        <br></br>
                        <Checkbox
                            inputId="precisa_aprovar"
                            id="precisa_aprovar"
                            name="precisa_aprovar"
                            onChange={formik.handleChange}
                            checked={formik.values.precisa_aprovar}
                            value={formik.values.precisa_aprovar}
                            className="p-mt-5 p-ml-5"
                        />
                        <label htmlFor="precisa_aprovar" className="p-mt-5">
                            <b>Precisa aprovar?</b>
                        </label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2">
                        <br></br>
                        <Checkbox
                            inputId="digital"
                            id="digital"
                            name="digital"
                            onChange={formik.handleChange}
                            checked={formik.values.digital}
                            value={formik.values.digital}
                            className="p-mt-5"
                        />
                        <label htmlFor="digital" className="p-mt-5">
                            <b>Digital?</b>
                        </label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="orientacoes">Orientações</label>
                        <InputTextarea
                            id="orientacoes"
                            name="orientacoes"
                            value={formik.values.orientacoes}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.orientacoes })}
                            rows={5}
                            autoComplete="off"
                            autoResize
                        />
                        {formik.errors.orientacoes && <small className="p-error">{formik.errors.orientacoes}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-3">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            disabled={!formik.values.id}
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                            className="p-ml-2"
                        />
                        <label htmlFor="ativo">Ativo</label>
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    <Button
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
