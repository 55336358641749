import React, { useRef } from "react";

import { Button } from "primereact/button";

import { Confirmar as Modal } from "../../modals";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useClearRefs from "@/hooks/useClearRefs";

export const Confirmar = ({
    selecionados,
    className = "",
    disabled = false,
    onHideCallback = () => {},
    onCancelCallback = () => {},
}) => {
    const modalRef = useRef(null);

    useClearRefs(modalRef);

    const show = () => modalRef.current?.show();

    return (
        <>
            <Button
                label="Confirmar entregas"
                icon={MAKO_ICONS.CONFIRMAR}
                className={`p-button p-component p-button-success ${className}`}
                disabled={!selecionados?.length || disabled}
                onClick={show}
            />
            <Modal ref={modalRef} previsoes={selecionados} onHide={onHideCallback} onCancel={onCancelCallback} />
        </>
    );
};
