import React from "react";
import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const MakoButton = ({ loading = false, icon = "", disabled = false, to, label = "", ...props }) => {
    if (!to)
        return (
            <Button
                icon={icon || undefined}
                label={label}
                loadingIcon={MAKO_ICONS.LOADING}
                loading={loading}
                disabled={loading || disabled}
                {...props}
            />
        );
    return (
        <NavLink to={to} exact>
            <Button
                icon={icon || undefined}
                label={label}
                loadingIcon={MAKO_ICONS.LOADING}
                loading={loading}
                disabled={loading || disabled}
                {...props}
            />
        </NavLink>
    );
};
