import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import useProduto from "@/hooks/useProduto";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import classNames from "classnames";
import * as Yup from "yup";
import { TipoCodigoSkuForm } from "../../Cadastros/TipoCodigoSku/form";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import * as pd from "@/assets/util/persistenciaDjango";
import { Tag } from "primereact/tag";
import useToast from "@/hooks/useToast";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Menu } from "primereact/menu";

const CodigosForm = () => {
    const [produto, setProduto] = useState(null);
    const [codigo, setCodigo] = useState(null);
    const [inserirCodigo, setInserirCodigo] = useState(false);
    const [bloqueiaFornecedor, setBloqueiaFornecedor] = useState(true);
    const [cadastroTipo, setCadastroTipo] = useState(false);
    const { handleInfo, dadosBasicos } = useProduto();
    const menu = useRef(null);
    const listagemRef = useRef(null);
    const tipoRef = useRef(null);
    const { showSuccess, showError } = useToast();

    const { setFieldValue, resetForm, setValues, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            codigo: "",
            tipo: null,
            fornecedor: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'codigo' é obrigatório."),
                tipo: Yup.object().required("O campo 'tipo' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleInfo(
                "/produtos/codigos-sku/",
                {
                    ...values,
                    tipo: values.tipo.id,
                    sku: values.sku?.id || produto,
                    fornecedor: values.fornecedor?.id || null,
                },
                values.id ? pd.OP_CRUD_DJANGO.editar : pd.OP_CRUD_DJANGO.novo
            );

            if (status === 200 || status === 201) {
                esconderFormulario();
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível realizar essa ação.",
                    life: 3000,
                });
            }
        }
    }

    const handleSituacao = async (situacao) => {
        const { status } = await handleInfo(
            "/produtos/codigos-sku/",
            { id: codigo.id, ativo: situacao },
            pd.OP_CRUD_DJANGO.editar
        );
        if (status === 200) listagemRef.current?.buscarDados();
    };

    const editarCodigo = (codigo) => {
        setValues(codigo);
        setBloqueiaFornecedor(codigo.tipo.requer_fornecedor);
        setInserirCodigo(true);
    };

    const opcoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Editar",
                    icon: "pi pi-pencil",
                    disabled: !codigo?.ativo,
                    command: () => editarCodigo(codigo),
                },
                {
                    label: "Ativar",
                    icon: "pi pi-chevron-circle-down",
                    disabled: codigo?.ativo,
                    command: () => handleSituacao(true),
                },
                {
                    label: "Desativar",
                    icon: "pi pi-times-circle",
                    disabled: !codigo?.ativo,
                    command: () => handleSituacao(false),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={BotaoDelete}
                    url="/produtos/codigos-sku/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente remover o código <b>{rowData.codigo}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="A código do produto não pode ser removido."
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Código removido com sucesso.",
                            life: 3000,
                        });
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    model={opcoes}
                    componente={Button}
                    className="p-button-rounded p-button-info p-ml-2 p-mb-1"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    icon="pi pi-cog"
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setCodigo(rowData);
                    }}
                />
            </div>
        );
    };

    const esconderFormulario = () => {
        setInserirCodigo(false);
        setBloqueiaFornecedor(false);
        resetForm();
    };

    const verificaItens = (results) => {
        if (results.length === 1) setProduto(results[0].id);
        return results;
    };

    const valueTemplate = (sku) => {
        if (sku) return `${sku.codigo} - ${sku.descricao_complementar}`;
        return "Selecione...";
    };

    const itemTemplate = (sku) => {
        return `${sku.codigo} - ${sku.descricao_complementar}`;
    };

    const cabecalhoTabela = (
        <>
            <Dropdown
                id="sku"
                name="sku"
                url={`/produtos/sku?item__id=${dadosBasicos.id}&limit=1000`}
                style={{ width: "300px", paddingLeft: "2%" }}
                value={produto}
                placeholder="Selecione um produto"
                filter
                filterBy="id,codigo,descricao_complementar"
                optionValue="id"
                optionLabel="descricao_complementar"
                aposBuscar={verificaItens}
                itemTemplate={itemTemplate}
                valueTemplate={valueTemplate}
                className="p-mr-2"
                showClear={false}
                onChange={(e) => setProduto(e.target.value)}
            />
            <Button
                label="Inserir"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setInserirCodigo(true)}
                disabled={!produto}
            />
        </>
    );

    const statusBodyTemplate = (status) => {
        if (status) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const colunas = [
        { field: "sku.codigo", header: "Produto", style: { minWidth: "10%" } },
        { field: "tipo.descricao", header: "Tipo de código" },
        { field: "codigo", header: "Código" },
        { field: "fornecedor.nome", header: "Fornecedor" },
        {
            field: "ativo",
            header: "Situação",
            action: (e) => statusBodyTemplate(e.ativo),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "10%" },
        },
    ];

    const onChangeTipo = (tipo) => {
        setBloqueiaFornecedor(tipo.requer_fornecedor);
        setFieldValue("tipo", tipo);
    };

    return (
        <>
            <Menu model={opcoes} popup ref={menu} id="popup_menu" />
            <MakoListagem
                ref={listagemRef}
                urlPesquisa={`/produtos/codigos-sku?sku__id=${produto ? `${produto}` : "0"}`}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                configTabela={{ paginator: true, lazy: true }}
            />
            <Dialog
                header="Inserir codigo"
                visible={inserirCodigo}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "35vw", display: "block" }}
                onHide={() => esconderFormulario()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="codigo">Código *</label>
                            <InputText
                                id="codigo"
                                name="codigo"
                                onChange={formik.handleChange}
                                value={formik.values.codigo}
                                autoComplete="off"
                                autoFocus
                                className={classNames({ "p-invalid": formik.errors.codigo })}
                            />
                            {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-10 p-md-12">
                            <label htmlFor="tipo">Tipo de código *</label>
                            <div className="p-inputgroup">
                                <Dropdown
                                    ref={tipoRef}
                                    id="tipo"
                                    placeholder="Selecione um tipo de código"
                                    name="tipo"
                                    url="/produtos/tipos-codigos-sku?limit=3000"
                                    optionLabel="descricao"
                                    filter
                                    filterBy="descricao"
                                    showClear={false}
                                    onChange={(e) => onChangeTipo(e.target.value)}
                                    value={formik.values.tipo}
                                    className={classNames({ "p-invalid": formik.errors.tipo })}
                                />
                                <MakoControleAcesso
                                    permissao={[permissoes.PRODUTO_CADASTRO_TIPOCODIGOSKU_INCLUIR]}
                                    componente={Button}
                                    type="button"
                                    icon="pi pi-plus"
                                    className="p-button-success"
                                    tooltip="Cadastrar tipo de codigo"
                                    tooltipOptions={{ position: "left" }}
                                    onClick={() => setCadastroTipo(true)}
                                />
                            </div>
                            {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="fornecedor">Fornecedor:</label>
                            <MakoInputFornecedor
                                id="fornecedor"
                                name="fornecedor"
                                value={formik.values.fornecedor}
                                onChange={formik.handleChange}
                                disabled={!bloqueiaFornecedor}
                                className={classNames({ "p-invalid": formik.errors.fornecedor })}
                            />
                            {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-justify-end p-mr-1">
                        <Button type="submit" icon="pi pi-check-circle" label="Inserir" />
                        <Button
                            className="p-button-danger p-button-text"
                            type="reset"
                            icon="pi pi-times"
                            label="Fechar"
                            onClick={() => esconderFormulario()}
                        />
                    </div>
                </form>
            </Dialog>
            <Dialog
                header={"Cadastrar tipo de código"}
                visible={cadastroTipo}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "35vw", display: "block" }}
                onHide={() => setCadastroTipo(false)}
            >
                <TipoCodigoSkuForm
                    tipo
                    closeDialog={() => {
                        setCadastroTipo(false);
                        tipoRef.current?.fetchDados()();
                    }}
                />
            </Dialog>
        </>
    );
};

export default CodigosForm;
