import React from "react";
import { Button } from "primereact/button";

import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";

const BASE_URL_API = "/produtos/lotes-sku/";

export const ListagemModal = ({ itemEntradaId, onClickBotaoEditar, listagemRef, onDeleteLoteSKU }) => {
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => {
                        if (typeof onClickBotaoEditar === "function") onClickBotaoEditar(rowData);
                    }}
                />
                <BotaoDelete
                    url={BASE_URL_API}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir o lote <b>{rowData.lote_serial}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O lote de produto não pode ser excluído."
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        if (typeof onDeleteLoteSKU === "function") onDeleteLoteSKU();
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "lote_serial", header: "Nº do lote / Série" },
        { field: "fabricacao", header: "Fabricação", dateFormat: "dd/MM/yyyy", style: { width: "10%" } },
        { field: "vencimento", header: "Vencimento", dateFormat: "dd/MM/yyyy", style: { width: "10%" } },
        { field: "quantidade", header: "Quantidade", decimal: true, style: { width: "10%" } },
        { field: "enderecamento", header: "Endereçamento" },
        { field: "complemento", header: "Complemento" },
        {
            field: "action",
            header: "Ações",
            style: { width: "12%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <MakoListagem
            ref={listagemRef}
            colunas={colunas}
            fazerBusca={!!itemEntradaId}
            urlPesquisa={`${BASE_URL_API}?item_entrada=${itemEntradaId}`}
            limit={5}
            configTabela={{
                lazy: true,
                paginator: true,
            }}
        />
    );
};
