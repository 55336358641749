import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PrimeReact from "primereact/api";
import LoadingScreen from "react-loading-screen";

import { LOADING } from "@/assets/constants/constants";
import AppTopBar from "@/components/AppTopbar";
import AppFooter from "@/components/AppFooter";
import AppConfig from "@/components/AppConfig";
import AppMenu from "@/components/AppMenu";
import AppRightMenu from "@/components/AppRightMenu";
import menu from "@/assets/constants/menu";
import useLoading from "@/hooks/useLoading";

export const PrivateLayout = ({ children }) => {
    const { loading } = useLoading();

    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState("static");
    const [colorScheme, setColorScheme] = useState("light");
    const [menuTheme, setMenuTheme] = useState("layout-sidebar-darkgray");
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
    const [topbarCaixaMenuActive, setTopbarCaixaMenuActive] = useState(false);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(false);
    const [logoColor, setLogoColor] = useState("white");
    const [componentTheme, setComponentTheme] = useState("blue");
    const [logoUrl, setLogoUrl] = useState("assets/layout/images/logo-dark.svg");

    let menuClick = false;
    let searchClick = false;
    let userMenuClick = false;
    let notificationMenuClick = false;
    let caixaMenuClick = false;
    let rightMenuClick = false;
    let configClick = false;

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        } else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        changeStyleSheetUrl("layout-css", "layout-" + colorScheme + ".css", 1);
        changeStyleSheetUrl("theme-css", "theme-" + colorScheme + ".css", 1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const changeMenuTheme = (name, logoColor, componentTheme) => {
        onMenuThemeChange(name);
        changeStyleSheetUrl("theme-css", componentTheme, 2);
        setComponentTheme(componentTheme);

        const appLogoLink = document.getElementById("app-logo");
        const appLogoUrl = `assets/layout/images/logo-${logoColor === "dark" ? "dark" : "white"}.svg`;

        if (appLogoLink) {
            appLogoLink.src = appLogoUrl;
        }
        setLogoColor(logoColor);
    };

    const changeComponentTheme = (theme) => {
        setComponentTheme(theme);
        changeStyleSheetUrl("theme-css", theme, 3);
    };

    const changeColorScheme = (e) => {
        setColorScheme(e.value);

        const scheme = e.value;
        changeStyleSheetUrl("layout-css", "layout-" + scheme + ".css", 1);
        changeStyleSheetUrl("theme-css", "theme-" + scheme + ".css", 1);
        changeLogo(scheme);
    };

    const changeStyleSheetUrl = (id, value, from) => {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute("href").split("/");

        if (from === 1) {
            // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {
            // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join("/");

        replaceLink(element, newURL);
    };

    const changeLogo = (scheme) => {
        const appLogoLink = document.getElementById("app-logo");
        const mobileLogoLink = document.getElementById("logo-mobile");
        const invoiceLogoLink = document.getElementById("invoice-logo");
        const footerLogoLink = document.getElementById("footer-logo");
        setLogoUrl(`assets/layout/images/logo-${scheme === "light" ? "dark" : "white"}.svg`);

        if (appLogoLink) {
            appLogoLink.src = `assets/layout/images/logo-${scheme === "light" ? logoColor : "white"}.svg`;
        }

        if (mobileLogoLink) {
            mobileLogoLink.src = logoUrl;
        }

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }

        if (footerLogoLink) {
            footerLogoLink.src = logoUrl;
        }
    };

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute("href", href);
        } else {
            const id = linkElement.getAttribute("id");
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute("href", href);
            cloneLinkElement.setAttribute("id", id + "-clone");

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove();
                cloneLinkElement.setAttribute("id", id);
            });
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        if (!notificationMenuClick) {
            setTopbarNotificationMenuActive(false);
        }

        if (!caixaMenuClick) {
            setTopbarCaixaMenuActive(false);
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (!menuClick) {
            if (isSlim()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        searchClick = false;
        configClick = false;
        userMenuClick = false;
        rightMenuClick = false;
        notificationMenuClick = false;
        caixaMenuClick = false;
        menuClick = false;
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setTopbarNotificationMenuActive(false);
        setRightMenuActive(false);

        if (isOverlay()) {
            setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
        } else {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const onMenuThemeChange = (name) => {
        setMenuTheme("layout-sidebar-" + name);
    };

    const onMenuModeChange = (e) => {
        setMenuMode(e.value);
        if (e.value === "static") {
            setStaticMenuDesktopInactive(false);
        }
    };

    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive((prevTopbarUserMenuActive) => !prevTopbarUserMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarNotificationMenuButtonClick = (event) => {
        notificationMenuClick = true;
        setTopbarNotificationMenuActive((prevTopbarNotificationMenuActive) => !prevTopbarNotificationMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarCaixaMenuButtonClick = (event) => {
        caixaMenuClick = true;
        setTopbarCaixaMenuActive((prevTopbarCaixaMenuActive) => !prevTopbarCaixaMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const toggleSearch = () => {
        setSearchActive((prevSearchActive) => !prevSearchActive);
        searchClick = true;
    };

    // const onSearchClick = () => {
    //     searchClick = true;
    // };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuButtonClick = (event) => {
        rightMenuClick = true;
        setRightMenuActive((prevRightMenuActive) => !prevRightMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive((prevConfigActive) => !prevConfigActive);
        configClick = true;
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add("blocked-scroll");
        } else {
            document.body.className += " blocked-scroll";
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove("blocked-scroll");
        } else {
            document.body.className = document.body.className.replace(
                new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"),
                " "
            );
        }
    };

    const isSlim = () => {
        return menuMode === "slim";
    };

    const isOverlay = () => {
        return menuMode === "overlay";
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const containerClassName = classNames(
        "layout-wrapper",
        {
            "layout-overlay": menuMode === "overlay",
            "layout-static": menuMode === "static",
            "layout-slim": menuMode === "slim",
            "layout-sidebar-dim": colorScheme === "dim",
            "layout-sidebar-dark": colorScheme === "dark",
            "layout-overlay-active": overlayMenuActive,
            "layout-mobile-active": staticMenuMobileActive,
            "layout-static-inactive": staticMenuDesktopInactive && menuMode === "static",
            "p-input-filled": inputStyle === "filled",
            "p-ripple-disabled": !ripple,
        },
        colorScheme === "light" ? menuTheme : ""
    );

    return (
        <LoadingScreen
            loading={loading}
            bgColor={LOADING.bgColor}
            spinnerColor={LOADING.spinnerColor}
            textColor={LOADING.textColor}
            logoSrc={LOADING.logoSrc}
            text={LOADING.text}
        >
            <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
                <div className="layout-content-wrapper">
                    <AppTopBar
                        topbarNotificationMenuActive={topbarNotificationMenuActive}
                        topbarUserMenuActive={topbarUserMenuActive}
                        topbarCaixaMenuActive={topbarCaixaMenuActive}
                        onMenuButtonClick={onMenuButtonClick}
                        onSearchClick={toggleSearch}
                        onTopbarNotification={onTopbarNotificationMenuButtonClick}
                        onTopbarUserMenu={onTopbarUserMenuButtonClick}
                        onTopbarCaixa={onTopbarCaixaMenuButtonClick}
                        onRightMenuClick={onRightMenuButtonClick}
                        onRightMenuButtonClick={onRightMenuButtonClick}
                    />

                    <div className="layout-content">{children}</div>

                    <AppFooter />
                </div>

                <AppMenu
                    model={menu}
                    menuMode={menuMode}
                    active={menuActive}
                    mobileMenuActive={staticMenuMobileActive}
                    onMenuClick={onMenuClick}
                    onMenuitemClick={onMenuitemClick}
                    onRootMenuitemClick={onRootMenuitemClick}
                />

                <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick} />

                {1 === 2 ? (
                    <AppConfig
                        configActive={configActive}
                        menuMode={menuMode}
                        onMenuModeChange={onMenuModeChange}
                        colorScheme={colorScheme}
                        changeColorScheme={changeColorScheme}
                        menuTheme={menuTheme}
                        changeMenuTheme={changeMenuTheme}
                        componentTheme={componentTheme}
                        changeComponentTheme={changeComponentTheme}
                        onConfigClick={onConfigClick}
                        onConfigButtonClick={onConfigButtonClick}
                        rippleActive={ripple}
                        onRippleChange={onRippleChange}
                        inputStyle={inputStyle}
                        onInputStyleChange={onInputStyleChange}
                    />
                ) : null}
            </div>
        </LoadingScreen>
    );
};
