import React, { useMemo, useState } from "react";

import { endOfWeek, startOfWeek, subDays } from "date-fns";

import { DateFiltroTemplate, DropdownFiltroTemplate, MoedaFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MakoCardListagemTabela } from "@/components/MakoCards";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Tag } from "primereact/tag";

import { SITUACAO_VENDA_CHOICE } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import permissoes from "@/assets/constants/permissoes";

import useEmpresa from "@/hooks/useEmpresa";
import useAuth from "@/hooks/useAuth";

const baseUrlVendas = "/dashboard/vendas-recentes/";
const startSemana = startOfWeek(new Date(), { weekStartsOn: 0 });
const endSemana = endOfWeek(new Date(), { weekStartsOn: 0 });
const orderWeek = [
    { name: "Essa semana", code: "0" },
    { name: "Semana passada", code: "1" },
];

export const IndicadorVendasRecentes = () => {
    const [urlVendas, setUrlVendas] = useState(() => {
        return (
            baseUrlVendas +
            `?data__gte=${dataToStr(startSemana, "yyyy-MM-dd")}&data__lte=${dataToStr(endSemana, "yyyy-MM-dd")}`
        );
    });
    const [selectedOrderWeek, setSelectedOrderWeek] = useState(orderWeek[0]);

    const { empresaSelecionadaId } = useEmpresa();
    const { user } = useAuth();

    const situacaoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    options: SITUACAO_VENDA_CHOICE,
                }}
                options={options}
            />
        );
    };

    const recentSales = (event) => {
        const key = event.value.code;
        const semanaLimiters = {
            0: {
                start: dataToStr(startSemana, "yyyy-MM-dd"),
                end: dataToStr(endSemana, "yyyy-MM-dd"),
            },
            1: {
                start: dataToStr(subDays(startSemana, 7), "yyyy-MM-dd"),
                end: dataToStr(subDays(startSemana, 1), "yyyy-MM-dd"),
            },
        };
        setUrlVendas(baseUrlVendas + `?data__gte=${semanaLimiters[key].start}&data__lte=${semanaLimiters[key].end}`);
        setSelectedOrderWeek(event.value);
    };

    const dropdownVendasProps = {
        options: orderWeek,
        value: selectedOrderWeek,
        optionLabel: "name",
        onChange: recentSales,
        className: "dashbard-demo-dropdown",
    };

    const statusBodyTemplate = (data) => {
        const status = SITUACAO_VENDA_CHOICE.find((item) => item.value === data);
        return <Tag severity={status.tag} value={status.label.toUpperCase()} />;
    };

    const tableVendas = useMemo(() => {
        return {
            titulo:
                empresaSelecionadaId === null ? (
                    <span className="p-error">Selecione uma empresa para exibirmos a venda</span>
                ) : null,
            colunas: [
                {
                    field: "id",
                    header: "Orçamento",
                    style: { width: "8%" },
                },
                {
                    field: "data",
                    header: "Data",
                    filter: true,
                    filterElement: DateFiltroTemplate,
                    dateFormat: "dd/MM/yyyy",
                    style: { width: "10%" },
                },
                {
                    field: "cliente.nome",
                    header: "Cliente",
                },
                {
                    field: "valor_total_ap",
                    header: "Valor",
                    filter: true,
                    filterElement: MoedaFiltroTemplate,
                    money: true,
                    style: { width: "8%" },
                },
                {
                    field: "situacao",
                    header: "Situação",
                    align: "center",
                    filter: true,
                    filterElement: situacaoFiltroTemplate,
                    action: ({ situacao }) => statusBodyTemplate(situacao),
                    style: { width: "15%" },
                },
            ],
            urlPesquisa: `${urlVendas}&empresa=${empresaSelecionadaId}&vendedor__id=${user.id}`,
            naoBuscarSemEmpresa: true,
            configTabela: {
                paginator: true,
                lazy: true,
            },
            filtros: {
                data: { value: "", matchMode: "equals" },
                situacao: { value: "", matchMode: "equals" },
                valor_total_ap: { value: null, matchMode: "equals" },
            },
        };
    }, [urlVendas, empresaSelecionadaId, user]);

    return (
        <MakoControleAcesso
            permissao={[permissoes.DASHBOARD_INDICATIVO_VENDAS_VENDASRECENTES]}
            componente={MakoCardListagemTabela}
            title="Vendas Recentes"
            subtitle="Sua atividade de vendas ao longo do tempo."
            tableProps={tableVendas}
            dropdownProps={dropdownVendasProps}
        />
    );
};
