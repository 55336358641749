import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef } from "react";

import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoButton as Button } from "@/components/MakoButton";

import { Form as F } from "../../../../pages/Vendas/Cargas/features";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useLoadingLocal from "@/hooks/useLoadingLocal";

const MemoButtonFechar = memo(F.Buttons.Fechar);

const Component = ({ successCallback = () => {}, carga }, ref) => {
    const [loading, show, hide] = useLoadingLocal();

    const formRef = useRef(null);

    const editar = useCallback((values) => {
        formRef.current?.editar(values);
    }, []);

    const submit = useCallback(
        async (e) => {
            show();
            formRef.current?.handleSubmit(e);
        },
        [show]
    );

    const onSuccess = useCallback(
        (data) => {
            successCallback(data);
            hide();
        },
        [hide, successCallback]
    );

    const onError = useCallback(() => hide(), [hide]);

    useImperativeHandle(ref, () => ({ editar }), [editar]);

    return (
        <F.Form ref={formRef} successCallback={successCallback} errorCallback={onError} showActions={false}>
            <MakoActionsButtons className="p-justify-end p-mt-2">
                <MemoButtonFechar carga={carga} successCallback={onSuccess} loading={loading} />
                <Button
                    icon={MAKO_ICONS.GRAVAR}
                    label="Gravar carga"
                    type="button"
                    className="p-button-info"
                    onClick={submit}
                    loading={loading}
                />
            </MakoActionsButtons>
        </F.Form>
    );
};

export const Form = forwardRef(Component);
