import React, { useEffect, useRef, useState, useCallback } from "react";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import { useLocation } from "react-router-dom";
import permissoes from "@/assets/constants/permissoes";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { ClonarPermissoesForm } from "./modal/ClonarPermissoes/formClonarPermissoes";
import useToast from "@/hooks/useToast";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { PageBase } from "@/components/PageBase";
import { Divider } from "primereact/divider";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact";
import { BotaoDelete } from "@/components/BotaoDelete";
import useAuth from "@/hooks/useAuth";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";

export const GestaoGerenciarUsuariosForm = () => {
    const [clonarPermissoes, setClonarPermissoes] = useState(false);
    const [papel, setPapel] = useState(null);
    const [addPermissao, setAddPermissao] = useState([]);
    const [optionsPapeis, setOptionsPapeis] = useState([]);
    const [optionsPermissoes, setOptionsPermissoes] = useState([]);
    const [dataFinalPapel, setDataFinalPapel] = useState(null);
    const [dataFinalPermissao, setDataFinalPermissao] = useState(null);
    const [empresaPapel, setEmpresaPapel] = useState(null);
    const [empresaPerfil, setEmpresaPerfil] = useState(null);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showError, showSuccess } = useToast();
    const { httpGet, httpPost } = useHttp();
    const { state } = useLocation();
    const { user } = useAuth();

    const listagemPapelRef = useRef(null);
    const listagemPermissaoRef = useRef(null);

    const formik = useFormik({
        initialValues: state.gerenciarUsuario,
    });

    const titulo = formik.values.id
        ? `Edição do usuário: (${formik.values.id}) ${formik.values?.nome}`
        : "Gerenciamento de usuário";

    const getPermissoes = useCallback(
        async (signal) => {
            let status, data;
            const handlers = {
                200: ({ data: dt }) => {
                    status = 200;
                    data = dt;
                },
            };
            await httpGet({ url: "/pessoas/permissoes-perfis/?limit=10000&query={id, descricao}", signal }, handlers);
            return { status, data: data?.results };
        },
        [httpGet]
    );

    const getPapeis = useCallback(
        async (signal) => {
            let status, data;
            const handlers = {
                200: ({ data: dt }) => {
                    status = 200;
                    data = dt;
                },
            };
            await httpGet({ url: "/pessoas/papeis-perfis/?limit=10000&query={id, nome}", signal }, handlers);
            return { status, data: data?.results };
        },
        [httpGet]
    );

    const fetchDados = useCallback(
        async (signal) => {
            showLoading();
            const [respostaPapeis, respostaPermissoes] = await Promise.all([getPapeis(signal), getPermissoes(signal)]);
            hideLoading();
            if (respostaPapeis.status === 200) setOptionsPapeis(respostaPapeis.data);
            if (respostaPermissoes.status === 200) setOptionsPermissoes(respostaPermissoes.data);
        },
        [showLoading, hideLoading, getPapeis, getPermissoes]
    );

    async function handlePapel() {
        try {
            showLoading();
            const data_final = dataToStr(dataFinalPapel, "yyyy-MM-dd");
            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Papel(is) adicionado(s) com sucesso!",
                        life: 1500,
                    });
                    setPapel(null);
                    setDataFinalPapel(null);
                    listagemPapelRef.current?.buscarDados();
                },
            };
            await httpPost(
                {
                    url: `/pessoas/vinculo-perfil-papel/`,
                    body: {
                        perfil: formik.values.id,
                        papel: papel,
                        vigencia_final: data_final,
                        empresa: empresaPapel,
                        usuario_registrou: user?.id,
                    },
                },
                handlers
            );
        } catch (error) {
            showError();
        } finally {
            hideLoading();
        }
    }

    async function handleAddPermissao() {
        try {
            showLoading();
            const data_final = dataToStr(dataFinalPermissao, "yyyy-MM-dd");
            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Permissão(ões) adicionada(s) com sucesso!",
                        life: 1500,
                    });
                    setAddPermissao([]);
                    listagemPermissaoRef.current?.buscarDados();
                    setDataFinalPermissao(null);
                },
            };
            await httpPost(
                {
                    url: `/pessoas/vinculo-perfil-permissao/`,
                    body: addPermissao.map((id) => {
                        return {
                            perfil: formik.values.id,
                            permissao: id,
                            vigencia_final: data_final,
                            empresa: empresaPerfil,
                            usuario_registrou: user?.id,
                        };
                    }),
                },
                handlers
            );
        } catch (error) {
            showError();
        } finally {
            hideLoading();
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchDados(controller.signal);
        return () => {
            controller.abort();
        };
    }, [fetchDados]);

    const esconderClonar = () => {
        setClonarPermissoes(false);
    };

    const actionBodyPapelTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPAPEL_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/pessoas/vinculo-perfil-papel/"}
                    objetoId={rowData.id}
                    hideLoad
                    msgToastErroExclusao="O vínculo com o papel de perfil não pode ser excluído."
                    tooltip="Remover papel de perfil"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemPapelRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const actionBodyPermissaoTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPERMISSAOAVULSA_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/pessoas/vinculo-perfil-permissao/"}
                    objetoId={rowData.id}
                    hideLoad
                    msgToastErroExclusao="O vínculo com a permissão não pode ser excluído."
                    tooltip="Remover permissão"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemPermissaoRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const exibeEmpresa = (empresa) => {
        if (!empresa) return "";
        return `${empresa?.nome} - ${empresa?.identificacao}`;
    };

    const colunaPapel = [
        { field: "id", header: "Código", style: { width: "6%" } },
        { field: "papel.nome", header: "Papel" },
        { field: "empresa.nome", header: "Empresa", action: ({ empresa }) => exibeEmpresa(empresa) },
        {
            field: "vigencia_final",
            header: "Vigência final",
            style: { width: "15%" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyPapelTemplate(e),
            style: { width: "6%" },
        },
    ];

    const colunaPermissao = [
        { field: "id", header: "Código", style: { width: "6%" } },
        { field: "permissao.descricao", header: "Permissão" },
        { field: "empresa.nome", header: "Empresa", action: ({ empresa }) => exibeEmpresa(empresa) },
        {
            field: "vigencia_final",
            header: "Vigência final",
            style: { width: "15%" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyPermissaoTemplate(e),
            style: { width: "6%" },
        },
    ];

    const template = (options) => {
        const className = `${options.className} justify-content-start`;
        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <Button severity="success" icon={MAKO_ICONS.NOVO} className="p-button-success p-button-outlined" />
                    <Ripple />
                </button>
                <span className="text-primary" style={{ fontSize: "1.25rem" }} onClick={options.onTogglerClick}>
                    <Button label={<u>{options.props.header}</u>} className="p-button-text p-button-success" />
                </span>
            </div>
        );
    };

    return (
        <PageBase>
            <h5>
                <b>{titulo.toUpperCase()}</b>
            </h5>
            <Divider align="center" className="p-mt-6">
                <b>Papeis do perfil</b>
            </Divider>
            <Panel header="Incluir papel de perfil" headerTemplate={template} toggleable collapsed>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Dropdown
                            id="papel"
                            name="papel"
                            placeholder={!loading ? "Selecione um papel" : "Buscando informações..."}
                            onChange={(e) => setPapel(e.value)}
                            value={papel}
                            filter
                            filterBy="nome"
                            optionLabel="nome"
                            optionValue="id"
                            showClear
                            disabled={loading}
                            options={optionsPapeis}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <MakoDropdownEmpresas
                            id="empresa"
                            name="empresa"
                            nome_curto={false}
                            value={empresaPapel}
                            placeholder="Selecione uma empresa"
                            onChange={(e) => setEmpresaPapel(e.id)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <MakoCalendar
                            id="data_final_papel"
                            name="data_final_papel"
                            valueCalendar={dataFinalPapel}
                            onChange={(e) => setDataFinalPapel(e.target.value)}
                            tooltip="Vigência final"
                            tooltipOptions={{
                                position: "top",
                            }}
                        />
                    </div>
                </div>
                <MakoActionsButtons>
                    <Button
                        label="Adicionar"
                        icon={MAKO_ICONS.NOVO}
                        type="button"
                        className="p-button-success"
                        onClick={() => handlePapel("ADD")}
                        disabled={!papel || !empresaPapel}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </Panel>
            <MakoListagem
                ref={listagemPapelRef}
                colunas={colunaPapel}
                urlPesquisa={`/pessoas/vinculo-perfil-papel?perfil=${formik.values?.id}`}
                configTabela={{ paginator: true, lazy: true }}
            />
            <div>
                <Divider align="center" className="p-mt-6">
                    <b>Permissões do perfil</b>
                </Divider>
                <Panel header="Incluir permissão" headerTemplate={template} toggleable collapsed>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <MultiSelect
                                id="permissao"
                                name="permissao"
                                placeholder={!loading ? "Selecione uma ou mais permissões" : "Buscando informações..."}
                                disabled={loading}
                                onChange={(e) => setAddPermissao(e.value)}
                                value={addPermissao}
                                filter
                                filterBy="descricao"
                                optionLabel="descricao"
                                optionValue="id"
                                options={optionsPermissoes}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <MakoDropdownEmpresas
                                id="empresa"
                                name="empresa"
                                nome_curto={false}
                                value={empresaPerfil}
                                placeholder="Selecione uma empresa"
                                onChange={(e) => setEmpresaPerfil(e.id)}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <MakoCalendar
                                id="data_final_permissao"
                                name="data_final_permissao"
                                valueCalendar={dataFinalPermissao}
                                onChange={(e) => setDataFinalPermissao(e.target.value)}
                            />
                        </div>
                    </div>
                    <MakoActionsButtons>
                        <MakoControleAcesso
                            permissao={[
                                permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_EDITAR,
                                permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPERMISSAOAVULSA_INCLUIR,
                            ]}
                            componente={Button}
                            label="Adicionar"
                            icon={MAKO_ICONS.NOVO}
                            type="button"
                            className="p-button-success"
                            onClick={() => handleAddPermissao()}
                            disabled={!addPermissao.length || !empresaPerfil}
                            loading={loading}
                        />
                        <MakoControleAcesso
                            permissao={[
                                permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_EDITAR,
                                permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPERMISSAOAVULSA_INCLUIR,
                            ]}
                            componente={Button}
                            label="Clonar"
                            icon="pi pi-users"
                            className="p-button-info"
                            onClick={() => setClonarPermissoes(true)}
                            loading={loading}
                        />
                        <MakoControleAcesso
                            permissao={[permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPERMISSAOAVULSA_EXCLUIR]}
                            componente={BotaoDelete}
                            url={"/pessoas/remover-permissoes-perfil/"}
                            objetoId={formik.values.id}
                            classNames="p-button-warning"
                            exigeConfirmacao
                            label="Limpar permissões"
                            rounded={false}
                            danger={false}
                            msgConfirmacao={
                                <span>
                                    Deseja realmente excluir <b>TODAS</b> as permissões do usuário?
                                </span>
                            }
                            msgToastErroExclusao="As permissões não podem ser excluídas."
                            tooltip="Limpar permissões"
                            tooltipOptions={{ position: "left" }}
                            onDelete={() => listagemPermissaoRef.current?.buscarDados()}
                            loading={loading}
                        />
                    </MakoActionsButtons>
                </Panel>
                <MakoListagem
                    ref={listagemPermissaoRef}
                    colunas={colunaPermissao}
                    urlPesquisa={`/pessoas/vinculo-perfil-permissao?perfil=${formik.values?.id}`}
                    configTabela={{ paginator: true, lazy: true }}
                />
            </div>
            <MakoActionsButtons className="p-mt-4">
                <Button
                    label="Voltar"
                    icon={MAKO_ICONS.VOLTAR}
                    type="reset"
                    className="p-button-danger"
                    to={"/gestao/usuarios/gerenciar-usuarios"}
                    loading={loading}
                />
            </MakoActionsButtons>
            <Dialog
                header="Clonar Permissões de Usuário"
                visible={clonarPermissoes}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw", display: "block" }}
                onHide={() => setClonarPermissoes(false)}
            >
                <ClonarPermissoesForm
                    perfil={formik.values?.id}
                    esconderClonar={esconderClonar}
                    listagemRef={listagemPermissaoRef}
                />
            </Dialog>
        </PageBase>
    );
};
