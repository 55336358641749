import React from "react";

import { FormTransferencia as Form } from "../../features";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useAuth from "@/hooks/useAuth";

export const Transferencia = ({ fecharModal }) => {
    const { caixaMov, padraoPlanos } = useCaixaMovimento();
    const { user } = useAuth();

    return (
        <Form
            caixaMov={caixaMov}
            user={user}
            planoPadrao={padraoPlanos["transferencia"] || null}
            successCalback={fecharModal}
            cancelCallback={fecharModal}
        />
    );
};
