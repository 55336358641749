import React from "react";

import { Panel } from "primereact/panel";
import { InfoConta } from "../features";

export const PanelInfoConta = ({ conta = {} }) => {
    return (
        <Panel header="Conta Bancária" toggleable className="p-mt-3 p-mb-3">
            <InfoConta conta={conta} />
        </Panel>
    );
};
