import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoUploadPreviewImage } from "@/components/MakoUploadPreviewImage";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { dataToStr, parseData } from "@/assets/util/datas";
import { validarCNPJ } from "@/assets/util/validacoes";
import usePessoa from "@/hooks/usePessoa";
import useFormatCnpjCpf from "@/hooks/useFomatCNPJCPF";
import { url } from "@/services/axios";
import { SIM_NAO_BOOLEAN, TIPO_SIM_NAO_CHAR_CHOICE } from "@/assets/constants/constants";
import { PESSOAS_PERFIL_CADASTRO_PESSOASJURIDICA_OBRIGATORIEDADE } from "@/assets/constants/parametros";
import { Label } from "@/components/Label";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { Dropdown } from "@/components/Dropdown";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoBuscaCnpj } from "@/components/MakoBuscaCnpj";

const IdentificacaoPJForm = () => {
    const [loading, setLoading] = useState(false);
    const [cnpjDuplicado, setCnpjDuplicado] = useState(false);
    const [estados, setEstados] = useState([]);
    const [indicadoresIE, setIndicadoresIE] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const { handlePessoaJuridica, handleFotoPerfil, handleDeletaImagem, pessoa, setSubmit, enderecos, handleEndereco } =
        usePessoa();
    const [loadingCampos, showLoading, hideLoading] = useLoadingLocal();
    const [, limparDocumento] = useFormatCnpjCpf();
    const { showWarning, showError, showSuccess } = useToast();
    const { getParam } = useParam();
    const { httpGet } = useHttp();

    const aposBuscarIE = useCallback(async (data) => {
        setIndicadoresIE(data);
    }, []);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            cnpj: "",
            indicador_inscricao_estadual: null,
            estado: null,
            inscricao_estadual: "",
            inscricao_municipal: "",
            razao_social: "",
            nome_fantasia: "",
            regime_tributario: null,
            data_constituicao: null,
            data_alteracao_contratual: null,
            optante_simples: null,
            substituto_issqn: null,
            nome_curto: "",
            obs: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const indexIE = indicadoresIE?.findIndex((e) => e.id === values.indicador_inscricao_estadual);
            const formSchema = Yup.object().shape({
                cnpj: Yup.string()
                    .required("O campo 'cnpj' é obrigatório.")
                    .test("CNPJ validation", "O 'cnpj' é inválido.", (value) => validarCNPJ(value) === true),
                inscricao_estadual:
                    indicadoresIE[indexIE]?.codigo === "1"
                        ? Yup.string()
                              .required("O campo 'inscrição estadual' é obrigatório.")
                              .max(14, "Informe no máximo 14 caracteres.")
                        : Yup.string().max(14, "Informe no máximo 14 caracteres."),
                inscricao_municipal: Yup.string().max(11, "Informe no máximo 11 caracteres.").nullable(),
                nome_curto: Yup.string()
                    .when({
                        is: () => camposBasicosObrigatorios,
                        then: Yup.string()
                            .required("O campo 'nome curto' é obrigatório.")
                            .typeError("Informe um 'nome curto' válido."),
                        otherwise: Yup.string().nullable().typeError("Informe um 'nome curto' válido."),
                    })
                    .max(25, "Informe no máximo 25 caracteres."),
                razao_social: Yup.string().required("O campo 'razão social' é obrigatório."),
                nome_fantasia: Yup.string().required("O campo 'nome fantasia' é obrigatório."),
                regime_tributario: Yup.object().nullable(),
                data_constituicao: Yup.date().when({
                    is: () => camposBasicosObrigatorios,
                    then: Yup.date()
                        .max(new Date(), "A data de constituição não pode ser posterior ao dia de hoje.")
                        .typeError("Informe uma 'data' válida."),
                    otherwise: Yup.date().nullable().typeError("Informe uma 'data' válida"),
                }),
                data_alteracao_contratual: Yup.date()
                    .nullable()
                    .when("data_constituicao", {
                        is: (val) => val !== null,
                        then: Yup.date()
                            .min(
                                Yup.ref("data_constituicao") || new Date(),
                                "A data da última alteração contratual não pode ser menor que a data de constituição."
                            )
                            .max(
                                new Date(),
                                "A data da última alteração contratual não pode ser posterior ao dia de hoje."
                            )
                            .typeError("Informe uma data válida."),
                        otherwise: Yup.date().nullable(),
                    }),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const perfil_pj = {
                ...values,
                optante_simples: typeof values.optante_simples === "boolean" ? values.optante_simples : null,
                indicador_inscricao_estadual: values.indicador_inscricao_estadual
                    ? values.indicador_inscricao_estadual
                    : null,
                substituto_issqn: typeof values.substituto_issqn === "boolean" ? values.substituto_issqn : null,
                cnpj: limparDocumento(values.cnpj),
                inscricao_estadual:
                    values.indicador_inscricao_estadual === 1
                        ? limparDocumento(values.inscricao_estadual)
                        : values.inscricao_estadual,
                data_constituicao: dataToStr(values.data_constituicao, "yyyy-MM-dd"),
                data_alteracao_contratual: dataToStr(values.data_alteracao_contratual, "yyyy-MM-dd"),
            };

            handlePessoaJuridica(perfil_pj);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    function resetForm() {
        formik.resetForm();
        handlePessoaJuridica(null);
        setSubmit(false);
    }

    const verificarCnpjExiste = async (value) => {
        const cnpj = limparDocumento(value);
        const handlers = {
            200: ({ data }) => {
                if (data.results.length > 0) {
                    if (data.results[0].ativo) {
                        showWarning({
                            life: 5000,
                            summary: "Aviso!",
                            detail: "Esse CNPJ já se encontra cadastrado na base de dados, porém está desativado.",
                        });
                    } else {
                        showWarning({
                            life: 5000,
                            summary: "Aviso!",
                            detail: "Esse CNPJ já se encontra cadastrado na base de dados.",
                        });
                    }
                    setCnpjDuplicado(true);
                    setSubmit(false);
                } else {
                    setCnpjDuplicado(false);
                    setConfirmDialog(true);
                }
            },
        };
        setLoading(true);
        await httpGet({ url: `/pessoas/perfis/?query={nome,identificacao,ativo}&identificacao=${cnpj}` }, handlers);
        setLoading(false);
    };

    const validarIE = (indIeId) => {
        setFieldValue("indicador_inscricao_estadual", indIeId);
        const index = indicadoresIE?.findIndex((e) => e.id === indIeId);
        if (indicadoresIE?.length > 0) {
            if (indicadoresIE[index]?.codigo === "1") {
                setFieldValue("estado", "");
                setFieldValue("inscricao_estadual", "");
            } else if (indicadoresIE[index]?.codigo === "2" || indicadoresIE[index]?.codigo === "9") {
                setFieldValue("estado", "");
                setFieldValue("inscricao_estadual", "");
            }
        }
    };

    const desabilitaIE = (indIeId) => {
        const index = indicadoresIE?.findIndex((e) => e.id === indIeId);
        return indicadoresIE[index]?.codigo === "9" || indicadoresIE[index]?.codigo === "2";
    };

    const onUploadFotoPerfil = (e) => {
        const { xhr } = e;
        const { data } = JSON.parse(xhr.response);
        handleFotoPerfil(data.foto);
        showSuccess({
            summary: "Sucesso",
            detail: "Foto de perfil enviada com sucesso!",
            life: 1500,
        });
    };

    const onErrorFotoPerfil = (e) => {
        const { xhr } = e;
        if (xhr.status === 400) {
            const { msg } = JSON.parse(xhr.response);
            showWarning({
                summary: "Falha",
                detail: msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível enviar a foto de perfil.",
                life: 3000,
            });
        }
    };

    const autoPreencherCNPJ = useCallback(async () => {
        const cnpj = limparDocumento(formik.values.cnpj);
        const handlers = {
            200: ({ data }) => {
                const { retorno } = data;
                setFieldValue("nome_fantasia", retorno.nome_fantasia);
                setFieldValue("razao_social", retorno.razao_social);
                setFieldValue("data_constituicao", parseData(retorno.data_inicio_atividade));
                setFieldValue(
                    "data_alteracao_contratual",
                    retorno.situacao_cadastral ? parseData(retorno.situacao_cadastral.data) : null
                );
                setConfirmDialog(false);
            },
        };
        showLoading();
        await httpGet({ url: `/pessoas/consultar-cnpj/${cnpj}/` }, handlers);
        hideLoading();
    }, [showLoading, hideLoading, formik.values.cnpj, setFieldValue, limparDocumento, httpGet]);

    const selecionaRegime = (regime) => {
        if (regime.regime === "S") setFieldValue("optante_simples", "S");
        else setFieldValue("optante_simples", "N");
        setFieldValue("regime_tributario", regime);
    };

    const camposBasicosObrigatorios = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_PESSOASJURIDICA_OBRIGATORIEDADE);
        if (!param) return false;
        return param?.valor === "1";
    }, [getParam]);

    useEffect(() => {
        if (pessoa.perfil_pj) setValues(pessoa.perfil_pj);
    }, [pessoa.perfil_pj, setValues]);

    const atualizarDados = async (dados, endereco = false) => {
        if (dados.nome_fantasia) setFieldValue("nome_fantasia", dados.nome_fantasia);
        setFieldValue("razao_social", dados.razao_social);
        setFieldValue("data_constituicao", parseData(dados.data_inicio_atividade));
        setFieldValue(
            "data_alteracao_contratual",
            dados.situacao_cadastral ? parseData(dados.situacao_cadastral.data) : null
        );

        if (endereco) {
            if (!enderecos.find((e) => e.cep === dados.endereco?.cep && e.numero === dados.endereco?.numero)) {
                let municipio = null,
                    estado = estados.find(({ uf }) => uf === dados.endereco.uf);

                const handlers = {
                    200: ({ data }) => (municipio = data.results[0]),
                };

                await httpGet(
                    {
                        url: `/pessoas/cidades/?codigo=${dados.endereco?.municipio?.codigo_ibge}&query={id, nome}&limit=1`,
                    },
                    handlers
                );

                handleEndereco(
                    {
                        identificacao: "Profissional",
                        pais: "BR",
                        cep: dados.endereco.cep,
                        logradouro: `${dados.endereco?.tipo_logradouro} ${dados.endereco?.logradouro}`,
                        numero: dados.endereco.numero,
                        complemento: "",
                        bairro: dados.endereco.bairro,
                        estado: estado.id,
                        cidade: municipio.id,
                        localidade: { ...municipio, estado },
                        ponto_referencia: "",
                        principal: true,
                        data_alteracao: new Date(),
                        ativo: true,
                        _status: "novo",
                    },
                    "novo"
                );
            }
        }
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-grid">
                <div className="p-col-12 p-md-2">
                    <div className="p-fluid p-formgrid p-grid p-jc-center">
                        <MakoUploadPreviewImage
                            ocultarImage={!!!pessoa?.foto}
                            exibirDeletar
                            imageConfig={{
                                src: pessoa.foto,
                                alt: "Foto de perfil",
                                width: "140rem",
                            }}
                            uploadConfig={{
                                url: `${url()}/pessoas/perfis-upload-foto/${pessoa?.id}/`,
                                name: "foto",
                                disabled: !!!pessoa?.id,
                                chooseLabel: "Selecionar logo",
                                maxFileSize: 1000000,
                                auto: true,
                                invalidFileSizeMessageSummary: "{0}: Tamanho do arquivo inválido, ",
                                invalidFileSizeMessageDetail: "tamanho máximo permitido: {0}.",
                                onUpload: onUploadFotoPerfil,
                                onError: onErrorFotoPerfil,
                            }}
                            deleteConfig={{
                                onClick: () => handleDeletaImagem(),
                            }}
                        />
                        {!!pessoa?.id && !pessoa?.foto ? (
                            <small className="p-error" style={{ color: "#FF0000", alignItems: "center" }}>
                                * Tamanho da imagem permitido: <b>1MB</b> (Dimensões <b>recomendáveis</b>: 180x420). *
                            </small>
                        ) : null}
                        {!!!pessoa?.id && (
                            <small className="p-error">
                                ** Poderá selecionar uma foto para o perfil após finalizar o cadastro. **
                            </small>
                        )}
                    </div>
                </div>
                <div className="p-col-12 p-md-10">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="cnpj" label="CNPJ" obrigatorio />
                            <span className="p-input-icon-right">
                                {loading && <i className="pi pi-spin pi-spinner" />}
                                <div className="p-inputgroup">
                                    <InputMask
                                        id="cnpj"
                                        name="cnpj"
                                        mask="99.999.999/9999-99"
                                        autoClear={false}
                                        onComplete={(e) => verificarCnpjExiste(e.value)}
                                        value={formik.values.cnpj}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.cnpj })}
                                    />
                                    <MakoBuscaCnpj cnpj={formik.values.cnpj} handleAtualizar={atualizarDados} />
                                </div>
                                {formik.errors.cnpj && <small className="p-error">{formik.errors.cnpj}</small>}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="indicador-ie" label="Indicativo IE" />
                            <Dropdown
                                id="indicador-ie"
                                name="indicador_inscricao_estadual"
                                url="/pessoas/indicadores-inscricao-estadual/"
                                aposBuscar={aposBuscarIE}
                                optionLabel="nome"
                                showClear
                                optionValue="id"
                                placeholder="Selecione..."
                                disabled={loading}
                                value={formik.values.indicador_inscricao_estadual}
                                onChange={(e) => validarIE(e.value)}
                                className={classNames({
                                    "p-invalid": formik.errors.indicador_inscricao_estadual,
                                })}
                            />
                            {formik.errors.indicador_inscricao_estadual && (
                                <small className="p-error">{formik.errors.indicador_inscricao_estadual}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="estado-ie" label="Estado IE" />
                            <Dropdown
                                id="estado-ie"
                                name="estado"
                                url={"/pessoas/estados?query={id,nome,uf,codigo_uf,pais}&pais__sigla=BR&limit=100"}
                                optionLabel="nome"
                                optionValue="id"
                                placeholder="Selecione..."
                                filter
                                showClear
                                filterBy="nome"
                                setObjects={setEstados}
                                disabled={desabilitaIE(formik.values.indicador_inscricao_estadual)}
                                value={formik.values.estado}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.estado,
                                })}
                            />
                            {formik.errors.estado && <small className="p-error">{formik.errors.estado}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="inscricao-estadual" label="Inscrição Estadual" />
                            <InputText
                                id="inscricao-estadual"
                                name="inscricao_estadual"
                                value={formik.values.inscricao_estadual}
                                onChange={formik.handleChange}
                                disabled={desabilitaIE(formik.values.indicador_inscricao_estadual)}
                                className={classNames({
                                    "p-invalid": formik.errors.inscricao_estadual,
                                })}
                            />
                            {formik.errors.inscricao_estadual && (
                                <small className="p-error">{formik.errors.inscricao_estadual}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="razao-social" label="Razão Social " />
                            <InputText
                                id="razao-social"
                                name="razao_social"
                                value={formik.values.razao_social}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.razao_social })}
                            />
                            {formik.errors.razao_social && (
                                <small className="p-error">{formik.errors.razao_social}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="nome-fantasia" label="Nome de fantasia " obrigatorio />
                            <InputText
                                id="nome-fantasia"
                                name="nome_fantasia"
                                value={formik.values.nome_fantasia}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.nome_fantasia,
                                })}
                            />
                            {formik.errors.nome_fantasia && (
                                <small className="p-error">{formik.errors.nome_fantasia}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="nome_curto" label="Nome curto" obrigatorio={camposBasicosObrigatorios} />
                            <InputText
                                id="nome_curto"
                                name="nome_curto"
                                value={formik.values.nome_curto}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.nome_curto && <small className="p-error">{formik.errors.nome_curto}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="inscricao-municipal" label="Inscrição Municipal" />
                            <InputText
                                id="inscricao-municipal"
                                name="inscricao_municipal"
                                value={formik.values.inscricao_municipal}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.inscricao_municipal,
                                })}
                            />
                            {formik.errors.inscricao_municipal && (
                                <small className="p-error">{formik.errors.inscricao_municipal}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="regime-tributario" label="Regime tributário" />
                            <Dropdown
                                id="regime-tributario"
                                name="regime_tributario"
                                url="/pessoas/regime-tributario?query={id,nome,crt,regime}"
                                placeholder="Selecione..."
                                optionLabel="nome"
                                value={formik.values.regime_tributario}
                                onChange={(e) => selecionaRegime(e.target.value)}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="optante_simples" label="Optante pelo Simples Nacional" />
                            <Dropdown
                                id="optante_simples"
                                name="optante_simples"
                                options={TIPO_SIM_NAO_CHAR_CHOICE}
                                placeholder="Selecione..."
                                optionValue="id"
                                optionLabel="label"
                                showClear
                                value={formik.values.optante_simples}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.optante_simples })}
                            />
                            {formik.errors.optante_simples && (
                                <small className="p-error">{formik.errors.optante_simples}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="substituto_issqn" label="Substituto tributário ISSQN" />
                            <Dropdown
                                id="substituto_issqn"
                                name="substituto_issqn"
                                options={SIM_NAO_BOOLEAN}
                                showClear
                                placeholder="Selecione..."
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.substituto_issqn}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <Label
                                htmlFor="data-constituicao"
                                label="Data de constituição"
                                obrigatorio={camposBasicosObrigatorios}
                            />
                            <MakoCalendar
                                id="data-constituicao"
                                name="data_constituicao"
                                maxDate={new Date()}
                                valueCalendar={formik.values.data_constituicao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_constituicao })}
                            />
                            {formik.errors.data_constituicao && (
                                <small className="p-error">{formik.errors.data_constituicao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Label htmlFor="ult-alteracao_contratual" label="Última alteração contratual" />
                            <MakoCalendar
                                id="ult-alteracao_contratual"
                                name="data_alteracao_contratual"
                                maxDate={new Date()}
                                valueCalendar={formik.values.data_alteracao_contratual}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_alteracao_contratual })}
                            />
                            {formik.errors.data_alteracao_contratual && (
                                <small className="p-error">{formik.errors.data_alteracao_contratual}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <Label htmlFor="obs" label="Observação" />
                            <InputText id="obs" name="obs" value={formik.values.obs} onChange={formik.handleChange} />
                        </div>
                    </div>
                </div>
            </div>
            <CamposObrigatorios />
            <p className="p-error">* Lembre-se de gravar os dados antes de prosseguir ou finalizar</p>
            <MakoActionsButtons>
                <Button
                    type="submit"
                    icon={`pi ${!cnpjDuplicado ? MAKO_ICONS.GRAVAR : "pi-times"}`}
                    label={!cnpjDuplicado ? "Gravar" : "CNPJ já cadastrado"}
                    className={`${cnpjDuplicado ? "p-button-danger" : ""}`}
                    disabled={cnpjDuplicado}
                    loading={loadingCampos}
                />
                <Button
                    type="reset"
                    icon={MAKO_ICONS.LIMPAR_FORM}
                    label="Limpar"
                    className="p-button-warning"
                    onClick={() => resetForm()}
                    loading={loadingCampos}
                />
            </MakoActionsButtons>
            <ConfirmDialog
                visible={confirmDialog}
                onHide={() => setConfirmDialog(false)}
                header="Confirmação"
                message="Deseja consultar os dados para preenchimento do formulário?"
                icon="pi pi-info-circle p-mr-3"
                accept={autoPreencherCNPJ}
                acceptLabel="Sim"
                acceptClassName="p-button-success"
                reject={() => setConfirmDialog(false)}
                rejectLabel="Não"
            />
        </form>
    );
};

export default memo(IdentificacaoPJForm);
