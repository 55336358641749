import React, { useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { Tag } from "primereact/tag";

import { CardIndicadoresContratoServico } from "./cardIndicadores";
import { FiltroAvancadoContratoServico } from "./filtroAvancado";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";

import { TIPO_STATUS_CONTRATO_CHOICE } from "@/assets/constants/constants";
import { formatarCasasDecimais } from "@/assets/util/util";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/servicos/contrato-servico/";

export const ContratoServicoPage = () => {
    const [cancelarParcelas, setCancelarParcelas] = useState(false);
    const [paramsIndicadores, setParamsIndicadores] = useState({});
    const [filtroAvancado, setFiltroAvancado] = useState(false);
    const [cancelarDialog, setCancelarDialog] = useState(false);
    const [contrato, setContrato] = useState(null);
    const [url, setUrl] = useState(BASE_URL);

    const { showSuccess } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpPatch } = useHttp();
    const menuAcoesRef = useRef(null);
    const listagemRef = useRef();
    const history = useHistory();

    useClearRefs(menuAcoesRef, listagemRef);

    const botoesMenu = [
        {
            label: "Ações",
            items: [
                {
                    label: "Firmar",
                    command: () => editarStatus({ status: "F" }),
                    disabled: !contrato?.servicoscontrato_set.length || contrato?.status === "C",
                },
                {
                    label: "Apontar",
                    command: () => {
                        history.push({
                            pathname: "/servicos/servicos/contrato-servico/form",
                            state: { contrato, tabAtiva: 2 },
                        });
                    },
                    disabled: contrato?.status !== "F",
                },
                {
                    label: "Gerar Parcelas",
                    command: () => {
                        history.push({
                            pathname: "/servicos/servicos/contrato-servico/form",
                            state: { contrato, tabAtiva: 4 },
                        });
                    },
                    disabled: contrato?.status !== "F",
                },
                {
                    label: "Cancelar",
                    command: () => {
                        setCancelarDialog(true);
                    },
                    disabled: contrato?.status === "C",
                },
                {
                    label: "Baixar DOC",
                    disabled: !contrato?.link_documento,
                    command: () => {
                        window.open(contrato?.link_documento, "_blank");
                    },
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.SERVICOS_SERVICOS_CONTRATOSERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/servicos/servicos/contrato-servico/form",
                        state: { contrato: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.SERVICOS_SERVICOS_CONTRATOSERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info"
                    model={botoesMenu}
                    onClick={(event) => {
                        menuAcoesRef.current?.toggle(event);
                        setContrato(rowData);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.SERVICOS_SERVICOS_CONTRATOSERVICO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={BASE_URL}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o contrato <b>{rowData.numero}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O serviço não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const limparFiltros = () => {
        setUrl(BASE_URL);
        setParamsIndicadores({});
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.SERVICOS_SERVICOS_SERVICO_INCLUIR]}
                componente={Button}
                label="Novo"
                disabled={!empresaSelecionadaId}
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/servicos/servicos/contrato-servico/form"}
            />
            <Button
                label="Filtro Avançado"
                icon={MAKO_ICONS.FILTRAR}
                className="p-button-help p-mr-2"
                onClick={() => setFiltroAvancado(true)}
            />
            <Button
                label="Limpar filtros"
                icon={MAKO_ICONS.LIMPAR_FILTROS}
                disabled={url === BASE_URL}
                className="p-button-warning p-mr-2"
                onClick={limparFiltros}
            />
        </>
    );

    const colunas = [
        { field: "numero", header: "Código", style: { width: "8%" } },
        { field: "cliente.nome", header: "Cliente" },
        { field: "nome_curto", header: "Nome curto" },
        {
            field: "status",
            header: "Status",
            align: "center",
            style: { width: "8%" },
            action: ({ status }) => {
                const _status = TIPO_STATUS_CONTRATO_CHOICE.find(({ value }) => value === status);
                return <Tag severity={_status.tag} value={_status.label.toLocaleUpperCase()} />;
            },
        },
        {
            field: "data_iniciacao",
            header: "Data inicio",
            dateFormat: "dd/MM/yyy",
            style: { width: "8%" },
        },
        {
            field: "data_finalizacao",
            header: "Previsão encerramento",
            dateFormat: "dd/MM/yyy",
            style: { width: "10%" },
        },
        {
            field: "dia_vencimento",
            header: "Dia venc.",
            align: "center",
            style: { width: "8%" },
            action: ({ dia_vencimento }) =>
                !!parseInt(dia_vencimento) ? formatarCasasDecimais(dia_vencimento, 0) : "",
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const fecharFiltro = () => {
        setFiltroAvancado(() => false);
    };

    const onConfirmFiltro = (newUrl, filtros) => {
        setUrl(newUrl);
        if (filtros) setParamsIndicadores(filtros);
        setFiltroAvancado(false);
    };

    const editarStatus = async ({ status = "C" }) => {
        let ok = false;
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Status alterado com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
                ok = true;
            },
        };
        await httpPatch(
            {
                url: `/servicos/contrato-servico/${contrato.id}/`,
                body: !cancelarParcelas ? { status } : { status, cancelar_parcelas: cancelarParcelas },
            },
            handlers
        );
        return ok;
    };

    return (
        <>
            <CardIndicadoresContratoServico params={paramsIndicadores} />
            <PageBase>
                <Menu model={botoesMenu} popup ref={menuAcoesRef} id="popup_menu_acoes" />
                <MakoListagem
                    ref={listagemRef}
                    titulo="Cadastro de contrato de prestação de serviços"
                    colunas={colunas}
                    painelEsquerdo={cabecalhoTabela}
                    urlPesquisa={url}
                    filtarPorEmpresa
                    fieldFiltroEmpresa="empresa"
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
                <Dialog
                    header="Filtro avançado"
                    visible={filtroAvancado}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "75vw" }}
                    onHide={fecharFiltro}
                >
                    <FiltroAvancadoContratoServico onConfirm={onConfirmFiltro} onCancel={fecharFiltro} url={BASE_URL} />
                </Dialog>
                <MakoConfirmDialog
                    visible={cancelarDialog}
                    setVisible={setCancelarDialog}
                    message={
                        <>
                            <span>
                                {"Deseja realmente cancelar o contrato de código "}
                                <b>{contrato?.codigo}</b>?
                            </span>
                            <div
                                className="p-flex-column p-mt-2"
                                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
                            >
                                <label>Cancelar também parcelas caso possua?</label>
                                <InputSwitch
                                    checked={cancelarParcelas}
                                    onChange={(e) => setCancelarParcelas(e.value)}
                                />
                            </div>
                        </>
                    }
                    accept={() => editarStatus({ status: "P" })}
                    showErrorToast={false}
                />
            </PageBase>
        </>
    );
};
