import React, { memo, useCallback, useMemo, useState } from "react";

import { Tooltip } from "primereact/tooltip";

import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    FormaRecebimentoFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import MakoListagem from "@/components/MakoListagem";

import { ValidadeOverlay } from "../../features";

import {
    FINANCEIRO_RECEBIMENTO_ANTECIPACAO_DIAS,
    FINANCEIRO_RECEBIMENTO_ANTECIPACAO_PERCENTUAL,
} from "@/assets/constants/parametros";
import {
    TIPOS_FORMAS_RECEBIMENTOS_ICONS,
    gerarIconeFormaRecebimento,
} from "@/assets/helpers/gerarIconeFormaRecebimento";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { gerarStringAleatoria, parseMoeda } from "@/assets/util/util";
import { COLORS_VALIDADE } from "@/assets/constants/constants_styles";
import { key_filtros } from "@/assets/constants/filtros";

import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useParam from "@/hooks/useParam";

const URL_BACKEND = "/financeiro/recebimentos/?cancelado=false&caixa__isnull=true";

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { width: "5%" },
};

const BASE_FILTROS_CABECALHO = {
    id: { value: null, matchMode: "equals" },
    documento: { value: null, matchMode: "equals" },
    numero_parcela: { value: null, matchMode: "equals" },
    forma_recebimento: { value: null, matchMode: "equals" },
    data_emissao: { value: null, matchMode: "equals" },
    vencimento: { value: null, matchMode: "equals" },
    valor: { value: null, matchMode: "equals" },
    devedor: { value: null, matchMode: "equals" },
    data_recebimento: { value: null, matchMode: "equals" },
};

const BASE_TITULO = <span>Recebimentos</span>;

const MemoValidadeOverlay = memo(ValidadeOverlay);

const numeroParcelaBodyTemplate = (rowData) => {
    if (rowData.numero_parcela === 0) return <span>ENTRADA</span>;
    return <span>{`${rowData.numero_parcela}/${rowData.quantidade_parcelas}`}</span>;
};

const formaRecebimentoTemplate = (values) => {
    const { dias_atraso, quitado, cancelado, forma_recebimento: forma } = values;
    const tipo = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.find(({ id }) => id === forma?.tipo?.id)?.id;
    let color = COLORS_VALIDADE.PENDENTE;
    if (cancelado) color = COLORS_VALIDADE.CANCELADA;
    else if (!quitado && dias_atraso > 0) color = COLORS_VALIDADE.VENCIDA;
    else if (quitado) color = COLORS_VALIDADE.CONCLUIDA;
    const target = gerarStringAleatoria(10, false).replace(/\d|,/g, "");
    const icon_props = {
        style: { fontSize: "1.5rem" },
        className: target,
    };
    if (TIPOS_FORMAS_RECEBIMENTOS_ICONS.includes(tipo)) {
        const hasColorProps = [2, 3, 5, 8, 11].includes(tipo);
        let icon = gerarIconeFormaRecebimento({
            tipo,
            placeholder: forma?.descricao,
            iconProps: !hasColorProps ? icon_props : { ...icon_props, color },
        });
        return (
            <>
                <Tooltip
                    target={`.${target}`}
                    content={forma?.descricao}
                    mouseTrack
                    mouseTrackRight={10}
                    position="left"
                />
                {icon}
            </>
        );
    }
    return forma?.descricao;
};

const rowClass = (rowData) => {
    return {
        "table-recebimentos-maturity": !rowData.quitado && !rowData.cancelado && rowData.dias_atraso > 0,
        "table-recebimentos-pending": !rowData.quitado && !rowData.cancelado && rowData.dias_atraso <= 0,
        "table-recebimentos-effective": rowData.quitado,
        "table-recebimentos-overdue": rowData.cancelado,
    };
};

export const COLUNAS_EXISTENTES = {
    SELECTION: "SELECTION",
    ID: "ID",
    DOCUMENTO: "DOCUMENTO",
    NUMERO_PARCELA: "NUMERO_PARCELA",
    DEVEDOR: "DEVEDOR",
    VENCIMENTO: "VENCIMENTO",
    DATARECEBIMENTO: "DATARECEBIMENTO",
    DIASATRASO: "DIASATRASO",
    FORMARECEBIMENTO: "FORMARECEBIMENTO",
    VALOR: "VALOR",
    VENDA: "VENDA",
};

const BASE_APENAS_COLUNAS = [
    { chave: COLUNAS_EXISTENTES.SELECTION },
    { chave: COLUNAS_EXISTENTES.ID },
    { chave: COLUNAS_EXISTENTES.DOCUMENTO },
    { chave: COLUNAS_EXISTENTES.NUMERO_PARCELA },
    { chave: COLUNAS_EXISTENTES.DEVEDOR },
    { chave: COLUNAS_EXISTENTES.VENCIMENTO },
    { chave: COLUNAS_EXISTENTES.DATARECEBIMENTO },
    { chave: COLUNAS_EXISTENTES.DIASATRASO },
    { chave: COLUNAS_EXISTENTES.FORMARECEBIMENTO },
    { chave: COLUNAS_EXISTENTES.VALOR },
];

export const Recebimentos = ({
    actions,
    aposPesquisar,
    listagemProps = {},
    configTabela = {},
    titulo = BASE_TITULO,
    exibirOverlay = true,
    filtros = {},
    apenasColunas = BASE_APENAS_COLUNAS,
    listagemRef = null,
}) => {
    // eslint-disable-next-line no-unused-vars
    const [filtross, setFiltro, removerFiltro, filtroString] = useLocalFiltro(
        key_filtros.FINANCEIRO_FINANCEIRO_RECEBIMENTOS
    );

    const [colorOverlay, setColorOverlay] = useState(COLORS_VALIDADE.PENDENTE);

    const [formatDocumento] = useFormatCNPJCPF();
    const { getParam } = useParam();

    const gerarColunasValor = useCallback(
        (rowData) => {
            const { empresa, valor, dias_atraso, quitado, valor_recebido } = rowData;
            const carenciaAntecipacao =
                parseInt(getParam(FINANCEIRO_RECEBIMENTO_ANTECIPACAO_DIAS, empresa.id)?.valor) || 0;
            const percentAntecipacao =
                parseFloat(getParam(FINANCEIRO_RECEBIMENTO_ANTECIPACAO_PERCENTUAL, empresa.id)?.valor) || 0;
            const descontos =
                dias_atraso < 0 && carenciaAntecipacao > dias_atraso
                    ? (parseFloat(valor) * percentAntecipacao) / 100
                    : 0;
            const target = gerarStringAleatoria(10, false).replace(/\d|,/g, "");
            let _valor = parseFloat(valor);
            if (!quitado) _valor = parseMoeda(_valor - parseFloat(valor_recebido), false);
            else _valor = parseMoeda(parseFloat(valor_recebido), false, false);
            const content = (
                <div>
                    <label>
                        <i className="pi pi-dollar p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                        <b style={{ marginRight: "10px" }}>Descontos: </b>
                        <span style={{ float: "right" }}>{parseMoeda(descontos)}</span>
                    </label>
                    <br />
                    <label>
                        <i className="pi pi-dollar p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                        <b>Juros: </b>
                        <span style={{ float: "right" }}>{parseMoeda(rowData.juros)}</span>
                    </label>
                    <br />
                    <label>
                        <i className="pi pi-dollar p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                        <b>Multa: </b>
                        <span style={{ float: "right" }}>{parseMoeda(rowData.multa)}</span>
                    </label>
                    <br />
                </div>
            );
            return (
                <>
                    <Tooltip target={`.${target}`} mouseTrack mouseTrackRight={10} position="left">
                        {content}
                    </Tooltip>
                    <span className={target}>{_valor}</span>
                </>
            );
        },
        [getParam]
    );

    const colunas = useMemo(() => {
        const BASE_COLUMNS = [
            { chave_coluna: COLUNAS_EXISTENTES.SELECTION, selectionMode: "multiple", style: { width: "5%" } },
            {
                chave_coluna: COLUNAS_EXISTENTES.ID,
                field: "id",
                header: "N°",
                filter: true,
                filterElement: CodigoFiltroTemplate,
                style: { width: "6%" },
            },
            {
                chave_coluna: COLUNAS_EXISTENTES.DOCUMENTO,
                field: "documento",
                header: "Documento",
                style: { width: "12%" },
                filter: true,
                filterElement: TextoFiltroTemplate,
            },
            {
                chave_coluna: COLUNAS_EXISTENTES.NUMERO_PARCELA,
                field: "numero_parcela",
                header: "Parcela",
                align: "center",
                filter: true,
                filterElement: CodigoFiltroTemplate,
                style: { width: "6%" },
                action: (e) => numeroParcelaBodyTemplate(e),
            },
            {
                chave_coluna: COLUNAS_EXISTENTES.DEVEDOR,
                field: "devedor.nome",
                header: "Cliente",
                filter: true,
                filterElement: TextoFiltroTemplate,
                filterField: "devedor",
                action: ({ devedor }) => `${devedor?.nome} - ${formatDocumento(devedor?.identificacao)}`,
            },
            {
                chave_coluna: COLUNAS_EXISTENTES.VENCIMENTO,
                field: "vencimento",
                header: "Vencimento",
                style: { width: "9%" },
                dateFormat: "dd/MM/yyyy",
                filter: true,
                filterElement: DateFiltroTemplate,
            },
            {
                chave_coluna: COLUNAS_EXISTENTES.DATARECEBIMENTO,
                field: "data_recebimento",
                header: "Recebimento",
                style: { width: "9%" },
                dateFormat: "dd/MM/yyyy",
                filter: true,
                filterElement: DateFiltroTemplate,
            },
            {
                chave_coluna: COLUNAS_EXISTENTES.DIASATRASO,
                field: "dias_atraso",
                header: "Dias",
                align: "center",
                style: { width: "5%" },
            },
            {
                chave_coluna: COLUNAS_EXISTENTES.FORMARECEBIMENTO,
                field: "forma_recebimento.descricao",
                header: "Forma",
                filter: true,
                filterElement: FormaRecebimentoFiltroTemplate,
                filterField: "forma_recebimento",
                align: "center",
                action: formaRecebimentoTemplate,
                style: { width: "8%" },
            },
            {
                chave_coluna: COLUNAS_EXISTENTES.VALOR,
                field: "valor",
                header: "Valor",
                align: "right",
                style: { width: "8%" },
                filter: true,
                filterElement: MoedaFiltroTemplate,
                action: gerarColunasValor,
            },
            {
                chave_coluna: COLUNAS_EXISTENTES.VENDA,
                field: "venda",
                header: "Venda",
                style: { width: "8%" },
            },
        ];
        let _colunas = BASE_COLUMNS;
        if (apenasColunas instanceof Array && apenasColunas) {
            const colunasSelecionadas = [];
            apenasColunas.forEach(({ chave, config = {} }) => {
                const col = _colunas.find(({ chave_coluna: c }) => c === chave);
                if (col) colunasSelecionadas.push({ ...col, ...config });
            });
            _colunas = colunasSelecionadas;
        }
        if (actions) _colunas = [..._colunas, { ...BASE_COLUMN_ACTIONS, action: actions }];
        return _colunas;
    }, [formatDocumento, gerarColunasValor, actions, apenasColunas]);

    const aposBuscar = useCallback(
        (data = []) => {
            let somatorios = [];
            Object.values(COLORS_VALIDADE).forEach((k) => somatorios.push({ key: k, qtd: 0 }));
            data.forEach((values) => {
                const { dias_atraso, quitado, cancelado } = values;
                let _key = COLORS_VALIDADE.PENDENTE;
                if (cancelado) _key = COLORS_VALIDADE.CANCELADA;
                else if (!quitado && dias_atraso > 0) _key = COLORS_VALIDADE.VENCIDA;
                else if (quitado) _key = COLORS_VALIDADE.CONCLUIDA;
                somatorios[somatorios?.findIndex(({ key }) => key === _key)].qtd++;
            });
            somatorios = somatorios.toSorted((a, b) => b.qtd - a.qtd);
            setColorOverlay(() => somatorios[0].key);
            if (typeof aposPesquisar === "function") return aposPesquisar(data);
            return data;
        },
        [aposPesquisar]
    );

    const url = useMemo(() => {
        let _url = URL_BACKEND;
        if (filtroString) {
            _url = `${URL_BACKEND}&${filtroString}`;
        }
        if (typeof filtros === "object") {
            let _filtros = [];
            for (const [k, v] of Object.entries(filtros)) {
                if (v !== null || undefined) _filtros.push(`${k}=${v}`);
            }
            _filtros = _filtros.join("&");
            _url = `${_url}&${_filtros}`;
        }
        return _url;
    }, [filtroString, filtros]);

    const overlay = useMemo(() => {
        if (exibirOverlay) return <MemoValidadeOverlay color={colorOverlay} />;
        return null;
    }, [colorOverlay, exibirOverlay]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo={
                <div className="p-d-flex p-justify-between">
                    {titulo}
                    {overlay}
                </div>
            }
            colunas={colunas}
            urlPesquisa={url}
            filtarPorEmpresa
            naoBuscarSemEmpresa
            aposPesquisar={aposBuscar}
            {...listagemProps}
            configTabela={{
                lazy: true,
                paginator: true,
                rowClassName: rowClass,
                ...configTabela,
            }}
            filtros={BASE_FILTROS_CABECALHO}
        />
    );
};
