import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoInputOrdemCompra } from "@/components/MakoInputs";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputNcm } from "@/components/MakoInputs/MakoInputNcm";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { InputText } from "primereact/inputtext";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_ESTOQUE_SALDOOC } from "@/assets/constants/relatorios";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";

const { ExportCSV } = R.Buttons;

const OPTIONS_TIPO_SKU = [
    { value: "M", label: "Mercadoria" },
    { value: "I", label: "Insumo" },
    { value: "C", label: "Consumo" },
    { value: "A", label: "Ativo" },
    { value: "p", label: "Produto acabado" },
];

const OPTIONS_ORDENACAO = [
    { value: "descricao", label: "Descrição (crescente)" },
    { value: "-descricao", label: "Descrição (decrescente)" },
    { value: "saldo_fisico", label: "Saldo fisico (crescente)" },
    { value: "-saldo_fisico", label: "Saldo fisico (decrescente)" },
    { value: "saldo_oc", label: "Saldo OC (crescente)" },
    { value: "-saldo_oc", label: "Saldo OC (decrescente)" },
    {
        value: "tipo",
        label: "Tipo produto (crescente)",
    },
    {
        value: "-tipo",
        label: "Tipo produto (decrescente)",
    },
];

const FILTROS_VIEWSET = {
    marca: "item__marca",
    saldo: "saldosku__data",
    destinacao: "categoriasku__categoria__destinacaoentrada__id",
    tipo_sku: "saldosku__centro_estocagem__saldoestoqueformulacentroestocagem__centro_estocagem__tipo_sku",
    categoria: "categoriasku__categoria",
    fornecedor: "fornecedor",
    item__ncm: "item__ncm",
    formula: "formula",
    ativo: "ativo",
    numero_oc: "itemordemcompra__ordem_compra",
    // item__empresa: "item__empresa",
    descricao_derivada: "descricao_derivada",
    orderBy: "ordering",
};

const BASE_URL = "/relatorios/saldo-ordem-compra/";

const FILE_NAME = gerarFileName("Saldo OC");

export const RelatorioSaldoOC = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();
    const { empresaSelecionadaId } = useEmpresa();

    const categoriaRef = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(formRef, categoriaRef, botaoCsv, listagemRef);

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.item__ncm) dadosValidados.item__ncm = dadosValidados.item__ncm.id;
        if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
        if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
        if (dadosValidados.destinacao) dadosValidados.destinacao = dadosValidados.destinacao.id;
        if (dadosValidados.saldo) dadosValidados.saldo = dataToStr(dadosValidados.saldo, "yyyy-MM-dd");
        if (dadosValidados.formula) dadosValidados.formula = dadosValidados.formula.id;
        if (dadosValidados.numero_oc) dadosValidados.numero_oc = dadosValidados.numero_oc.id;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const filtrosTemplate = [
        {
            key: "marca",
            label: "Grupo",
            path: "descricao",
        },
        {
            key: "formula",
            label: "Formula",
            path: "descricao",
        },
        {
            key: "saldo",
            label: "Saldo em",
            type: TIPOS_FILTROS_TEMPLATE.DATE,
        },
        {
            key: "categoria",
            label: "Grupo",
            path: "nome",
        },
        {
            key: "fornecedor",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "numero_oc",
            label: "N° OC",
        },
        {
            key: "destinacao",
            label: "Destinação",
            path: "descricao",
        },
        {
            key: "tipo_sku",
            label: "Tipo de produto",
            optionLabel: "label",
            optionKey: "value",
            options: OPTIONS_TIPO_SKU,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "item__ncm",
            label: "NCM",
            path: "codigo",
        },
        {
            key: "orderBy",
            label: "Ordenado por",
            optionLabel: "label",
            optionKey: "value",
            options: OPTIONS_ORDENACAO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                marca: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'marca' válida"),
                formula: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .required("O campo 'formula' é obrigatório")
                    .typeError("Informe uma 'formula' válida"),
                saldo: Yup.date()
                    .required("O campo 'saldo em' é obrigatório")
                    .typeError("Selecione um 'saldo em' válido"),
                categoria: Yup.number().nullable().default(null).typeError("Informe um 'grupo' válido"),
                numero_oc: Yup.object().nullable().typeError("Informe uma 'numero de oc' válido"),
                destinacao: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'destinação' válido"),
                tipo_sku: Yup.string()
                    .default("")
                    .required("O campo 'tipo produto' é obrigatório")
                    .typeError("Informe um 'tipo de produto' válido"),
                fornecedor: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'fornecedor' válida"),
                descricao: Yup.string().typeError("Informe uma 'descrição' válida"),
                item__ncm: Yup.object()
                    .nullable()
                    .default(null)
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'ncm' válida"),
                orderBy: Yup.string().typeError("Seleciona uma 'ordenação' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: categoriaRef.current,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.item__ncm) dadosValidados.item__ncm = dadosValidados.item__ncm.id;
            if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
            if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
            if (dadosValidados.destinacao) dadosValidados.destinacao = dadosValidados.destinacao.id;
            if (dadosValidados.saldo) dadosValidados.saldo = dataToStr(dadosValidados.saldo, "yyyy-MM-dd");
            if (dadosValidados.formula) dadosValidados.formula = dadosValidados.formula.id;
            if (dadosValidados.numero_oc) dadosValidados.numero_oc = dadosValidados.numero_oc.id;

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_ESTOQUE_SALDOOC,
                    emails,
                    filtros,
                    versao,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    enviar_fila: background,
                });
                limparFormulario();
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "codigo", header: "Código", style: { width: "8%" } },
        { field: "descricao", header: "Descrição" },
        { field: "unidade_medida", header: "UN", style: { width: "5%" }, align: "center" },
        { field: "saldo_fisico", header: "Saldo físico", style: { width: "6%" }, align: "center" },
        { field: "saldo_oc", header: "Saldo OC", style: { width: "6%" }, align: "center" },
        { field: "saldo_total", header: "Saldo total", style: { width: "6%" }, align: "center" },
        { field: "tipo_produto", header: "Tipo produto", style: { width: "10%" }, align: "center" },
    ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_ESTOQUE_SALDOOC}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const selecionarCategoria = (e) => {
        categoriaRef.current = e;
    };

    return (
        <R.Wrapper titulo={"de saldo de produtos - OC"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        marca: null,
                        saldo: null,
                        formula: null,
                        categoria: null,
                        numero_oc: "",
                        destinacao: null,
                        tipo_sku: "",
                        fornecedor: null,
                        descricao: "",
                        item__ncm: null,
                        orderBy: "",
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Formula saldo",
                        inputId: "formula",
                        inputName: "formula",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            placeholder: "Selecione uma formula...",
                            url: `/produtos/saldo-estoque-formula/?limit=1000&ativa=true&empresa=${empresaSelecionadaId}`,
                            optionLabel: "descricao",
                            filter: true,
                            filterBy: "descricao",
                            buscar: !!empresaSelecionadaId,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Tipo de produto",
                        inputId: "tipo_sku",
                        inputName: "tipo_sku",
                        component: Dropdown,
                        required: true,
                        componentProps: {
                            placeholder: "Selecione um tipo...",
                            options: OPTIONS_TIPO_SKU,
                            optionValue: "value",
                            optionLabel: "label",
                            showClear: true,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Saldo em",
                        inputId: "saldo",
                        inputName: "saldo",
                        required: true,
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Nº de OC",
                        inputId: "numero_oc",
                        inputName: "numero_oc",
                        component: MakoInputOrdemCompra,
                        componentEspecial: COMPONENTES_ESPECIAIS.ORDEMCOMPRA,
                        fieldSize: 3,
                    },
                    {
                        label: "Fornecedor",
                        inputId: "fornecedor",
                        inputName: "fornecedor",
                        component: MakoInputFornecedor,
                        fieldSize: 3,
                    },
                    {
                        label: "Marca",
                        inputId: "marca",
                        inputName: "marca",
                        component: MakoAutoComplete,
                        componentProps: {
                            placeholder: "Digite para pesquisar... (min 4 caracteres)",
                            urlSearch: "/produtos/marcas/?search=",
                            key: "id",
                            field: "nome",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "NCM",
                        inputId: "item__ncm",
                        inputName: "item__ncm",
                        component: MakoInputNcm,
                        componentEspecial: COMPONENTES_ESPECIAIS.NCM,
                        componentProps: {
                            showClear: true,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Grupo",
                        inputId: "categoria",
                        inputName: "categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: selecionarCategoria,
                            categoriaTituloSelecionavel: true,
                            showClear: true,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Destinação",
                        inputId: "destinacao",
                        inputName: "destinacao",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione um tipo...",
                            url: "/compras/destinacoes-entradas/",
                            optionLabel: "nome",
                            filter: true,
                            filterBy: "nome",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Ordenado por",
                        inputId: "orderBy",
                        inputName: "orderBy",
                        component: Dropdown,
                        componentProps: {
                            options: OPTIONS_ORDENACAO,
                            optionValue: "value",
                            optionLabel: "label",
                            showClear: true,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Descrição contém",
                        inputId: "descricao_derivada",
                        inputName: "descricao_derivada",
                        component: InputText,
                        componentProps: {
                            eventChangeKey: "onInput",
                            maxLength: 100,
                        },
                        fieldSize: 12,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_ESTOQUE_SALDOOC}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
