import React, { useState, useRef } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Dialog } from "primereact/dialog";
import { ClonarTabelaForm } from "./modals/clonarTabelaModalForm";
import { RecalcularPrecosModalForm } from "./modals/recalcularPrecosModalForm";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useToast from "@/hooks/useToast";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { QuantidadeFiltroTemplate } from "@/components/MakoFiltrosCabecalho/QuantidadeFiltroTemplate";
import useClearRefs from "@/hooks/useClearRefs";
import useAuth from "@/hooks/useAuth";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Menu } from "primereact/menu";
import { OPCOES_PESQUISA_BOOLEAN } from "@/assets/constants/constants";
import { Dropdown } from "@/components/Dropdown";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/produtos/tabelas-preco/?";

export const ProdutoTabelaPrecosPage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [clonarTabela, setClonarTabela] = useState(false);
    const [recalculaPrecos, setRecalculaPrecos] = useState(false);
    const [tabelaPreco, setTabelaPreco] = useState(null);
    const [filtro, setFiltro] = useState(1);
    const [url, setUrl] = useState(`${BASE_URL}ativo=true&ordering=-id`);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpDelete, httpPatch } = useHttp();
    const { showSuccess } = useToast();
    const { verifyPermission } = useAuth();

    const listagemRef = useRef(null);
    const menu = useRef(null);

    useClearRefs(listagemRef, menu);

    const confirmarDelete = (tabelaPreco) => {
        setTabelaPreco(tabelaPreco);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
        setTabelaPreco(null);
    };

    const deletaTabela = async () => {
        const handlers = {
            204: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Tabela de preços deletada com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
            },
        };
        showLoading();
        await httpDelete({ url: `/produtos/tabelas-preco/${tabelaPreco.id}/` }, handlers);
        hideLoading();
        setDeleteDialog(false);
    };

    const atualizarAtivoTabela = async () => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Tabela de preços atualiza com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
            },
        };
        showLoading();
        await httpPatch(
            { url: `/produtos/tabelas-preco/${tabelaPreco.id}/`, body: { ativo: !tabelaPreco.ativo } },
            handlers
        );
        hideLoading();
        setDeleteDialog(false);
    };

    const exibeRecalcular = (tabelaPreco) => {
        setTabelaPreco(tabelaPreco);
        setRecalculaPrecos(true);
    };

    const escondeRecalcular = () => {
        setTabelaPreco(null);
        setRecalculaPrecos(false);
    };

    const filtroStatus = (status) => {
        setFiltro(status.value);
        const _url = `${BASE_URL}&`;

        if (status.value === 0) {
            setUrl(`${_url}ordering=-id`);
        } else if (status.value === 1) {
            setUrl(`${_url}ativo=True&ordering=-id`);
        } else {
            setUrl(`${_url}ativo=False&ordering=-id`);
        }
    };

    const opcoesMenu = [
        {
            label: "Opções",
            items: [
                {
                    label: "Recalcular preços",
                    icon: "fa fa-solid fa-check-double",
                    disabled: !verifyPermission([permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]),
                    command: () => exibeRecalcular({ ...tabelaPreco, vigencia: tabelaPreco?.vigencia?.id }),
                },
                {
                    label: !tabelaPreco?.ativo ? "Ativar" : "Desativar",
                    icon: !tabelaPreco?.ativo ? MAKO_ICONS.ATIVO : MAKO_ICONS.INATIVO,
                    disabled: !verifyPermission([permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]),
                    command: () => atualizarAtivoTabela(),
                },
                {
                    label: "Excluir",
                    icon: MAKO_ICONS.DELETE,
                    disabled: !verifyPermission([permissoes.VENDAS_CADASTRO_TABELAPRECO_EXCLUIR]),
                    command: () => confirmarDelete({ ...tabelaPreco, vigencia: tabelaPreco?.vigencia?.id }),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de tabela de preços"
                    tooltipOptions={{ position: "left" }}
                    loading={loading}
                    to={{
                        pathname: "/vendas/cadastros/tabela-precos/form",
                        state: {
                            tabelaPreco: { ...rowData, vigencia: rowData.vigencia?.id },
                            apenasVisualizar: !rowData.ativo,
                        },
                    }}
                />
                <Button
                    className="p-button-rounded p-button-info"
                    tooltip={MAKO_ICONS.OPCOES}
                    tooltipOptions={{ position: "left" }}
                    icon={MAKO_ICONS.OPCOES}
                    loading={loading}
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setTabelaPreco(rowData);
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/vendas/cadastros/tabela-precos/form"}
            />
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_INCLUIR]}
                componente={Button}
                label="Clonar tabela"
                icon="pi pi-clone"
                className="p-button-info p-mr-2"
                onClick={() => setClonarTabela(true)}
            />
            <Dropdown
                value={filtro}
                options={OPCOES_PESQUISA_BOOLEAN}
                placeholder="Selecione"
                onChange={filtroStatus}
                showClear={false}
            />
        </>
    );

    const carregaClone = () => {
        setClonarTabela(false);
        listagemRef.current?.buscarDados();
    };

    const VigenciaFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    url: "/produtos/precos-vigencia?query={id,descricao}&limit=300",
                    optionValue: "id",
                    optionLabel: "descricao",
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "8%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "qtd_minima_tag",
            header: "Qtd. Minima",
            filter: true,
            filterElement: QuantidadeFiltroTemplate,
            decimal: true,
        },
        {
            field: "vigencia.descricao",
            header: "Vigência",
            filter: true,
            filterElement: VigenciaFiltroTemplate,
            filterField: "vigencia",
        },
        {
            field: "vigencia.data_inicio",
            header: "Inicial",
            align: "center",
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "vigencia.data_fim",
            header: "Final",
            align: "center",
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const rowClass = (rowData) => {
        return {
            "mako-table-status-inativo": !rowData.ativo,
        };
    };

    return (
        <>
            <Menu model={opcoesMenu} popup ref={menu} id="popup_menu" />
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <MakoListagem
                            ref={listagemRef}
                            titulo="Tabela de Preços"
                            colunas={colunas}
                            painelEsquerdo={cabecalhoTabela}
                            urlPesquisa={url}
                            configTabela={{
                                paginator: true,
                                lazy: true,
                                rowClassName: rowClass,
                            }}
                            filtros={{
                                id: { value: null, matchMode: "equals" },
                                nome: {
                                    operator: "and",
                                    constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                                },
                                qtd_minima_tag: { value: null, matchMode: "equals" },
                                vigencia: { value: null, matchMode: "equals" },
                            }}
                        />
                        <ConfirmDialog
                            visible={deleteDialog}
                            onHide={esconderDeleteDialog}
                            header="Confirmação"
                            message={tabelaPreco && <span>{"Deseja realmente excluir a tabela de preços?"}</span>}
                            icon="pi pi-info-circle p-mr-3"
                            accept={deletaTabela}
                            acceptLabel="Sim"
                            acceptClassName="p-button-danger"
                            reject={esconderDeleteDialog}
                            rejectLabel="Não"
                        />
                        <Dialog
                            header={"Recalcular Preços da Tabela"}
                            visible={recalculaPrecos}
                            onHide={() => escondeRecalcular()}
                            breakpoints={{ "960px": "75vw" }}
                            style={{ width: "50vw", display: "block" }}
                        >
                            <RecalcularPrecosModalForm
                                tabela={tabelaPreco}
                                esconderDialog={escondeRecalcular}
                                listagem={listagemRef}
                            />
                        </Dialog>
                        <Dialog
                            header={"Clonar Tabela de Preços"}
                            visible={clonarTabela}
                            onHide={() => setClonarTabela(false)}
                            breakpoints={{ "960px": "75vw" }}
                            style={{ width: "40vw", display: "block" }}
                            closeOnEscape={true}
                        >
                            <ClonarTabelaForm callBack={carregaClone} />
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};
