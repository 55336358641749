import { useRef, useState } from "react";
import { useBetween } from "use-between";

export const DEFAULT_ERROR_TOAST = {
    summary: "Erro :(",
    detail: "Desculpe, não conseguimos processar sua requisição.",
    life: 3000,
};

const useToastRef = () => {
    const [position, setPosition] = useState(null);
    const toastRef = useRef(null);

    const showToast = (severity, props, position) => {
        setPosition(position || "top-right");

        toastRef.current?.show({
            ...props,
            severity,
        });
    };

    const showCustom = (props) => {
        toastRef.current?.show(props);
    };

    const showSuccess = (props, position) => {
        showToast("success", props, position);
    };

    const showInfo = (props, position) => {
        showToast("info", props, position);
    };

    const showWarning = (props, position) => {
        showToast("warn", props, position);
    };

    const showError = (props = DEFAULT_ERROR_TOAST, position) => {
        showToast("error", props, position);
    };

    const clear = () => {
        toastRef.current?.clear();
    };

    return { position, toastRef, showCustom, showSuccess, showInfo, showWarning, showError, clear };
};

const useToast = () => useBetween(useToastRef);

export default useToast;
