import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import { Tag } from "primereact/tag";
import { DropdownFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

const STATUS_PERFIL = [
    { value: true, label: "Ativo" },
    { value: false, label: "Desativado" },
];

export const ModeloSpedPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_SPEDTIPO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/modelo-sped/form",
                            state: { modelo: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_SPEDTIPO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/fiscal/sped-tipo"}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o modelo <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O modelo não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <MakoControleAcesso
            permissao={[permissoes.FISCAL_SPEDTIPO_INCLUIR]}
            componente={Button}
            label="Novo"
            icon="pi pi-plus"
            className="p-button-success p-mr-2"
            onClick={() => history.push("/fiscal/modelo-sped/form")}
        />
    );

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const StatusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: STATUS_PERFIL,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "10%" },
            filter: true,
        },
        { field: "nome", header: "Nome", filter: true },
        { field: "modelo", header: "Modelo", filter: true },
        {
            field: "ativo",
            header: "Situação",
            style: { width: "10%" },
            filter: true,
            filterElement: StatusFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Modelos Sped"
                urlPesquisa={"/fiscal/sped-tipo"}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "equals" }],
                    },
                    nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    modelo: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "equals" }],
                    },
                    id: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "ativo" }],
                    },
                }}
            />
        </PageBase>
    );
};
