import { gerarFiltrosUrl } from "@/assets/util/util";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";

const BASE_URL = "/pessoas/perfis";
const QUERY_PARAMS = {
    query: "{id,nome,identificacao,usuario}",
    usuario__isnull: false,
    search: "",
};

const URL = `${BASE_URL}?${gerarFiltrosUrl(QUERY_PARAMS)}`;

const autoCompleteTemplate = (row) => {
    return `${row.nome} - ${row.identificacao}`;
};

export const MakoAutoCompleteUsuario = (props) => {
    return (
        <MakoAutoComplete
            placeholder="Busque pelo nome ou CPF ... (min 4 caracteres)"
            minCaracteresBusca={3}
            urlSearch={URL}
            field="nome"
            itemTemplate={autoCompleteTemplate}
            {...props}
        />
    );
};
