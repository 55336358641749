import React, { useCallback } from "react";

import { FormDesbloquear as Form } from "../../features";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useAuth from "@/hooks/useAuth";

export const Desbloquear = ({ fecharModal, handleCaixa }) => {
    const { caixaMov, setCaixaUsuario: updateCaixa } = useCaixaMovimento();
    const { user } = useAuth();

    const successCalback = useCallback(
        (value, operador) => {
            updateCaixa(value);
            if (operador?.new) handleCaixa();
            fecharModal();
        },
        [fecharModal, handleCaixa, updateCaixa]
    );

    return <Form caixaMov={caixaMov} user={user} successCallback={successCalback} cancelCallback={fecharModal} />;
};
