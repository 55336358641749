import React from "react";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

const { VENDAS_VENDA_CARGAS_INCLUIR } = permissoes;

export const Novo = ({ onClick = () => {} }) => {
    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[VENDAS_VENDA_CARGAS_INCLUIR]}
            tooltip="Cadastrar carga"
            type="button"
            tooltipOptions={{ position: "left" }}
            icon={MAKO_ICONS.NOVO}
            className={classNames("p-button p-button-success")}
            onClick={onClick}
        />
    );
};
