import React, { useImperativeHandle, useState, forwardRef, useRef } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { LerCodigoBarras as Form } from "../../features";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const Modal = ({ onLer = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);

    const formRef = useRef(null);

    const resetForm = () => {
        formRef.current?.resetForm();
    };

    const handleSubmit = () => {
        formRef.current?.handleSubmit();
    };

    const successCallback = (...args) => {
        onLer(...args);
        resetForm();
        setVisible(false);
    };

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const footer = (
        <div>
            <Button
                type="reset"
                label="Limpar"
                icon={MAKO_ICONS.LIMPAR_FORM}
                className="p-button-warning"
                onClick={resetForm}
            />
            <Button type="submit" label="Confirmar" icon={MAKO_ICONS.CONFIRMAR} onClick={handleSubmit} />
        </div>
    );

    return (
        <Dialog
            header="Digite ou leia um código de barras"
            visible={visible}
            onHide={fecharModal}
            style={{ width: "40vw" }}
            footer={footer}
            blockScroll
        >
            <Form ref={formRef} successCallback={successCallback} />
        </Dialog>
    );
};

export const LerCodigoBarras = forwardRef(Modal);
