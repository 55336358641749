import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import { dataToStr } from "@/assets/util/datas";
import useToast from "@/hooks/useToast";
import { MakoFileUpload } from "@/components/MakoFileUpload";
import { url } from "@/services/axios";
import useEmpresa from "@/hooks/useEmpresa";

export const NotaTransportePage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();
    const { showSuccess, showWarning, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTATRANSPORTE_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-transporte/form",
                            state: { cte: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTATRANSPORTE_EXCLUIR]}
                    componente={BotaoDelete}
                    url={`/fiscal/notas-conhecimento-transporte/`}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a CTe número <b>{rowData.numero}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A nota de conhecimento de transporte não pode ser excluída."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const onUploadXml = () => {
        showSuccess({
            summary: "Sucesso",
            detail: "XML enviado com sucesso!",
            life: 3000,
        });
        listagemRef.current?.buscarDados();
    };

    const onErrorXml = (e) => {
        const { xhr } = e;
        if (xhr.status === 400 || xhr.status === 404) {
            const { msg } = JSON.parse(xhr.response);
            showWarning({
                summary: "XML não importado",
                detail: msg,
                life: 5000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível importar o XML.",
                life: 3000,
            });
        }
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_NOTATRANSPORTE_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/fiscal/nota-transporte/form")}
            />
            <MakoFileUpload
                mode="basic"
                name="file"
                url={`${url()}/fiscal/upload-xml-cte/${empresaSelecionadaId}/`}
                accept=".xml"
                chooseLabel="Importar XML"
                className="p-d-inline-block p-mr-2"
                chooseOptions={{ className: "p-button-help" }}
                invalidFileSizeMessageSummary="{0}: Tamanho do arquivo inválido, "
                invalidFileSizeMessageDetail="tamanho máximo permitido: {0}."
                maxFileSize={100000}
                onUpload={onUploadXml}
                onError={onErrorXml}
            />
        </>
    );

    const colunas = [
        {
            field: "numero",
            header: "Número",
            style: { minWidth: "50px" },
            filter: true,
        },
        { field: "remetente.nome", header: "Remetente", filter: true, style: { minWidth: "200px" } },
        { field: "destinatario.nome", header: "Destinatario", filter: true, style: { minWidth: "200px" } },
        { field: "chave_cte", header: "Chave", filter: true, style: { minWidth: "200px" } },
        { field: "protocolo", header: "Protocolo", filter: true },
        {
            field: "data_emissao",
            header: "Data emissão",
            filter: true,
            action: ({ data_emissao }) => dataToStr(data_emissao, "yyyy-MM-dd hh:mm:ss"),
            style: { minWidth: "160px" },
        },
        { field: "valor_documento", header: "Valor", money: true },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "90px" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Notas de Conhecimento de Transporte (CT-e)"
                urlPesquisa="/fiscal/notas-conhecimento-transporte"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                naoBuscarSemEmpresa
                filtarPorEmpresa
                filtros={{
                    codigo: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "equals" }],
                    },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                    frozenWidth: "0.5vw",
                }}
            />
        </PageBase>
    );
};
