import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import useHttp from "@/hooks/useHttp";

const URL_CALCULAR = "/financeiro/caixas/pendencias/crediario-verificar-calculo/grupo/{grupo}";
const URL_SOMATORIOS = "/financeiro/grupo-recebimentos/{id}/somatorios";
const URL_RECEBIMENTOS = "/financeiro/formas-recebimentos-efetivados/?grupo_recebimento={grupo}&limit=1";

const BASE_INFO = {
    quantidade_parcelas: 0,
    total_original: 0,
    total_descontos: 0,
    total_acrescimos: 0,
    total_receber: 0,
};

const ReceberCrediarioContext = createContext({});

export const ReceberCrediarioProvider = ({ children, pendencia, onRemoverParcelaCallback = () => {} }) => {
    const [necessitaCalcular, setNecessitaCalcular] = useState(true);
    const [possuiRecebimento, setPossuiRecebimento] = useState(true);
    const [info, setInfo] = useState(BASE_INFO);
    const { httpGet } = useHttp();

    const listagemParcelasRef = useRef();
    const listagemRecebimentosRef = useRef();

    const atualizarListagemParcelas = useCallback(() => {
        listagemParcelasRef.current?.buscarDados();
    }, []);

    const atualizarListagemRecebimentos = useCallback(() => {
        listagemRecebimentosRef.current?.buscarDados();
    }, []);

    const verificarNecessidadeCalcular = useCallback(async () => {
        setNecessitaCalcular(true);
        if (pendencia) {
            const { grupo } = pendencia || {};
            const handlers = {
                200: ({ data }) => {
                    const { necessita_calcular } = data;
                    setNecessitaCalcular(necessita_calcular);
                },
            };
            await httpGet({ url: URL_CALCULAR.replace("{grupo}", grupo?.id) }, handlers);
        }
    }, [pendencia, httpGet]);

    const verificarRecebimentos = useCallback(async () => {
        setPossuiRecebimento(true);
        if (pendencia) {
            const { grupo } = pendencia || {};
            if (!grupo) {
                setPossuiRecebimento(false);
                return;
            }
            const handlers = {
                200: ({ data }) => {
                    setPossuiRecebimento(data.results.length > 0);
                },
            };
            await httpGet({ url: URL_RECEBIMENTOS.replace("{grupo}", grupo?.id) }, handlers);
        }
    }, [pendencia, httpGet]);

    const atualizarInfo = useCallback(
        async (signal) => {
            const { grupo } = pendencia || {};
            if (grupo) {
                const url = URL_SOMATORIOS.replace("{id}", grupo?.id);
                const handlers = {
                    200: ({ data }) => {
                        const { parcelas, acrescimos, descontos, valor_original, valor_receber } = data.somatorios;
                        setInfo({
                            quantidade_parcelas: parcelas,
                            total_acrescimos: acrescimos,
                            total_descontos: descontos,
                            total_original: valor_original,
                            total_receber: valor_receber,
                        });
                    },
                };
                await httpGet({ url, signal }, handlers);
            }
        },
        [httpGet, pendencia]
    );

    const handlerOnRemoverParcela = useCallback(
        (values) => {
            onRemoverParcelaCallback(values);
        },
        [onRemoverParcelaCallback]
    );

    useEffect(() => {
        const abortController = new AbortController();
        atualizarInfo(abortController.signal);
        return function clear() {
            abortController.abort();
        };
    }, [atualizarInfo]);

    useEffect(() => verificarNecessidadeCalcular(), [verificarNecessidadeCalcular]);

    useEffect(() => verificarRecebimentos(), [verificarRecebimentos]);

    return (
        <ReceberCrediarioContext.Provider
            value={{
                info,
                pendencia,
                necessitaCalcular,
                possuiRecebimento,
                listagemParcelasRef,
                listagemRecebimentosRef,
                atualizarInfo,
                atualizarListagemParcelas,
                atualizarListagemRecebimentos,
                verificarNecessidadeCalcular,
                handlerOnRemoverParcela,
                verificarRecebimentos,
            }}
        >
            {children}
        </ReceberCrediarioContext.Provider>
    );
};

export default ReceberCrediarioContext;
