import React from "react";

import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Operacao as Form } from "../../features";

export const Operacao = () => {
    const tabs = [
        {
            header: "Receber",
            icon: `${MAKO_ICONS.NOVO} p-mr-2`,
            component: <Form.Receber />,
        },
        {
            header: "Pagar",
            icon: "pi pi-minus p-mr-2",
            component: <Form.Pagar />,
        },
    ];

    return (
        <MakoMultiTabs
            tabs={tabs}
            title={null}
            ocultarButtonFinalizar
            ocultarButtonCancelar
            ocultarButtonAnt
            ocultarButtonProx
        />
    );
};
