import React, { useMemo } from "react";

import { Dropdown } from "@/components/Dropdown";

import { FINANCEIRO_CAIXA_PENDENCIAS_PERMITEPARCELAR } from "@/assets/constants/parametros";

import useParam from "@/hooks/useParam";

const OPTIONS_PARCELAS = Array.from({ length: 18 }, (_, i) => i + 1).map((n) => ({
    value: n,
    label: n === 1 ? "1 (Crédito rotativo)" : n,
}));

export const DropdownParcelas = (props) => {
    const { getParam } = useParam();

    const permiteParcelar = useMemo(() => {
        const parametro = getParam(FINANCEIRO_CAIXA_PENDENCIAS_PERMITEPARCELAR);
        return parametro ? parametro.valor === "1" : false;
    }, [getParam]);

    return (
        <Dropdown
            {...props}
            disabled={!permiteParcelar}
            options={OPTIONS_PARCELAS}
            optionValue="value"
            optionLabel="label"
        />
    );
};
