import React, { memo } from "react";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";

import { Label } from "@/components/Label";

const Componente = ({ fieldOptions, valueOptions, instance, onChange, error }) => {
    const { id: name, label, is_required, field_type, default_value, content_type } = fieldOptions;

    const parseNumber = (num) => {
        const parsed = Number(num);

        if (isNaN(parsed)) return 0;

        return parsed;
    };

    const valueField = {
        id: valueOptions?.id,
        field: name,
        value: parseNumber(valueOptions?.value || default_value),
        object_id: instance,
        content_type,
    };

    const onChangeValue = (e) => {
        onChange({ ...valueField, value: e.target.value });
    };

    return (
        <>
            {label && <Label htmlFor={name} label={label} obrigatorio={is_required} />}
            <InputNumber
                id={name}
                name={name}
                minFractionDigits={field_type === "f" ? 1 : 0}
                maxFractionDigits={field_type === "f" ? 5 : 0}
                useGrouping={false}
                value={valueField.value}
                onChange={onChangeValue}
                className={classNames({ "p-invalid": error })}
            />
            {error && <small className="p-error">{error}</small>}
        </>
    );
};

export const CustomFieldInputNumber = memo(Componente);
