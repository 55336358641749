import React from "react";
import { Skeleton } from "./skeleton";

const MakoCardItemProgresso = ({ title, subtitle, items, loading = false }) => {
    const handleLevelBackground = () => {
        return Math.floor(Math.random() * 5 + 1);
    };

    const handleProgress = (value, max) => {
        if (value === max) return "100%";
        return (value / max) * 100 + "%";
    };

    return (
        <div className="card widget-tasks">
            <h4>{title}</h4>
            <p>{subtitle}</p>
            <div>
                {!loading ? (
                    <>
                        {items.map((item) => (
                            <div
                                key={item.text}
                                className={`task task-${
                                    item.level_background ? item.level_background : handleLevelBackground()
                                }`}
                            >
                                <div className="task-name">
                                    <span>{`${item.value} ${item.text}`}</span>
                                    {item.type_text}
                                </div>
                                <div className="task-progress">
                                    <div style={{ width: handleProgress(item.value, item.max_value) }}></div>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <Skeleton />
                )}
            </div>
        </div>
    );
};

export default MakoCardItemProgresso;
