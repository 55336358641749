import React from "react";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";

export const Editar = ({ onClick = () => {} }) => {
    return (
        <MakoControleAcesso
            permissao={[permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EDITAR]}
            componente={Button}
            icon={MAKO_ICONS.OPCOES}
            className="p-button-rounded p-button-info"
            onClick={onClick}
        />
    );
};
