import { Novo } from "./novo";
import { FiltroAvancado } from "./filtro-avançado";
import { Editar } from "./editar";
import { Opcoes } from "./opcoes";

export const Buttons = {
    Novo,
    FiltroAvancado,
    Editar,
    Opcoes,
};
