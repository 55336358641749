import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { Dialog } from "primereact/dialog";
import { FormConfirmar } from "../../features";

const Component = ({ onHide = () => {}, onCancel = () => {}, previsoes }, ref) => {
    const [visible, setVisible] = useState(false);

    const show = () => setVisible(true);

    const hide = () => {
        setVisible(false);
    };

    const onFinish = useCallback(() => {
        setVisible(false);
        onHide(true);
    }, [onHide]);

    const cancel = useCallback(() => {
        onCancel();
        setVisible(false);
    }, [onCancel]);

    useImperativeHandle(ref, () => ({ show }), []);

    return (
        <Dialog header="Confirmar entregas" visible={visible} style={{ width: "95vw" }} onHide={hide}>
            <FormConfirmar previsoes={previsoes} onFinish={onFinish} onCancel={cancel} />
        </Dialog>
    );
};

export const Confirmar = forwardRef(Component);
