import React, { useRef, useState } from "react";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import permissoes from "@/assets/constants/permissoes";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { useLocation } from "react-router-dom";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { Label } from "@/components/Label";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const VincularEmpresaForm = (props) => {
    const [empresa, setEmpresa] = useState(null);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost } = useHttp();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);
    const { state } = useLocation();

    async function handleVinculo() {
        try {
            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Empresa vinculada com sucesso!",
                        life: 1500,
                    });
                    setEmpresa(null);
                    listagemRef.current?.buscarDados();
                },
            };
            showLoading();
            await httpPost(
                {
                    url: "/produtos/tabela-preco-empresas/",
                    body: {
                        tabela: props.tabela,
                        unidade_venda: empresa,
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            showError();
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                    componente={BotaoDelete}
                    url={`/produtos/tabela-preco-empresas/`}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente remover a empresa <b>{rowData.unidade_venda.nome}</b>?
                        </span>
                    }
                    tooltip="Deletar plano de contas"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="A empresa não pode ser removida."
                    onDelete={() => listagemRef.current?.buscarDados()}
                    disabled={state?.apenasVisualizar}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "18%" } },
        { field: "unidade_venda.identificacao", header: "Identificação", style: { width: "20%" } },
        { field: "unidade_venda.nome", header: "Empresa" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "8%" },
        },
    ];

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="natureza_operacao" label="Selecione uma empresa" obrigatorio />
                    <div className="p-inputgroup">
                        <MakoDropdownEmpresas
                            placeholder="Selecione uma empresa"
                            value={empresa}
                            onChange={(e) => setEmpresa(e.id)}
                            disabled={state?.apenasVisualizar}
                        />
                        <Button
                            label="Adicionar"
                            icon={MAKO_ICONS.NOVO}
                            type="button"
                            disabled={!empresa || state?.apenasVisualizar}
                            loading={loading}
                            onClick={() => handleVinculo()}
                            className="p-button-success p-ml-2"
                        />
                    </div>
                </div>
            </div>
            {props?.tabela ? (
                <MakoListagem
                    ref={listagemRef}
                    titulo="Empresas Vinculadas"
                    colunas={colunas}
                    urlPesquisa={`/produtos/tabela-preco-empresas?tabela=${props.tabela}`}
                    responsiva
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            ) : null}
        </>
    );
};
