import React, { useImperativeHandle, useRef, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { MakoListagem } from "@/components/MakoListagem";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { Dropdown } from "@/components/Dropdown";
import useEntrada from "@/hooks/useEntrada";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [destinacaoSelecionada, setDestinacaoSelecionada] = useState(null);
    const itensOrdemCompra = useRef([]);
    const { dadosBasicos } = useEntrada();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpPost } = useHttp();

    useClearRefs(itensOrdemCompra);

    async function handleSubmit() {
        const itensReplicados = itensOrdemCompra.current
            .map((item) => {
                if (item.qtd_replicacao) {
                    return {
                        id: item.id,
                        quantidade: item.qtd_replicacao,
                    };
                }
                return null;
            })
            .filter(Boolean);
        const body = {
            destinacao: destinacaoSelecionada,
            itens: itensReplicados,
        };
        const handlers = {
            201: () => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Itens replicados para a entrada com sucesso.",
                    life: 1500,
                });
                setTimeout(() => {
                    itensOrdemCompra.current = [];
                    setDestinacaoSelecionada(null);
                    setVisible(false);
                }, 2000);
            },
            error: ({ status, err }) => {
                if (status !== 500 && err.msg) {
                    showWarning({
                        summary: "Falhou...",
                        detail: err.msg,
                        life: 3000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível replicar os itens para a entrada.",
                        life: 3000,
                    });
                }
            },
        };
        await httpPost({ url: `/compras/entradas/${dadosBasicos?.id}/replicar-itens-oc/`, body }, handlers);
    }

    const abrirModal = (itensOC) => {
        setVisible(true);
        if (itensOC) itensOrdemCompra.current = itensOC;
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const onCellEditComplete = (e) => {
        const { rowData, newValue, field, originalEvent: event } = e;
        if (field === "qtd_replicacao") {
            rowData[field] = newValue;
        } else {
            event.preventDefault();
        }
    };

    const quantidadeReplicacaoEditor = (options) => {
        return (
            <MakoInputQuantidadeSku
                permiteFracionario={options.rowData.sku.movimenta_cubagem}
                max={options.rowData.quantidade}
                value={options.value}
                onValueChange={(e) => options.editorCallback(e.value)}
            />
        );
    };

    const colunas = [
        { field: "sku.codigo", header: "Código" },
        { field: "sku.descricao_derivada", header: "Descrição" },
        { field: "valor_unitario", header: "Valor unitário", money: true },
        { field: "quantidade", header: "Qtd OC", decimal: true },
        {
            field: "qtd_replicacao",
            header: "Qtd recebida",
            decimal: true,
            align: "end",
            editor: quantidadeReplicacaoEditor,
            onCellEditComplete,
        },
    ];

    const footer = (
        <div>
            <Button label="Replicar" icon="pi pi-copy" onClick={handleSubmit} />
        </div>
    );

    return (
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header="Replicar itens da OC para entrada"
            footer={footer}
            style={{ width: "50vw" }}
        >
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="destinacao">Destinação de entrada</label>
                    <Dropdown
                        id="destinacao"
                        url="/compras/destinacoes-entradas/?query={id,nome}"
                        optionValue="id"
                        optionLabel="nome"
                        value={destinacaoSelecionada}
                        onChange={(e) => setDestinacaoSelecionada(e.value)}
                    />
                </div>
            </div>
            <MakoListagem
                colunas={colunas}
                dadosLocal={itensOrdemCompra.current}
                configTabela={{
                    editMode: "cell",
                }}
            />
        </Dialog>
    );
};

export const ModalReplicarItensOC = forwardRef(Modal);
