import { useContext } from "react";

import BoletoContext from "@/contexts/boletoContext";

const useBoleto = () => {
    const {
        submitDadosBasicos,
        submitDadosValores,
        handleDadosBasicos,
        handleValores,
        handleAntecipacao,
        handlePagamento,
        handleBoleto,
    } = useContext(BoletoContext);

    return {
        submitDadosBasicos,
        submitDadosValores,
        handleDadosBasicos,
        handleValores,
        handleAntecipacao,
        handlePagamento,
        handleBoleto,
    };
};

export default useBoleto;
