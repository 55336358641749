import React from "react";

import { Lancar } from "../features/Lancar";

import useRecebimento from "@/hooks/useRecebimento";

export const RecebimentoLancamentoForm = () => {
    const { submit, recebimento, handleSubmitRecebimento, parcelar, setParcelar, setRecebimentos, setSubmitParcelas } =
        useRecebimento();

    return (
        <Lancar.Lancamento
            parcelar={parcelar}
            recebimento={recebimento}
            submit={submit}
            handleSubmitRecebimento={handleSubmitRecebimento}
            setParcelar={setParcelar}
            setRecebimentos={setRecebimentos}
            setSubmitParcelas={setSubmitParcelas}
        />
    );
};
