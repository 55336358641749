import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useRelatorioCSV from "@/hooks/useRelatorioCSV";
import { Button } from "primereact/button";
import { forwardRef, useImperativeHandle } from "react";

const Component = (
    {
        filtersOnClick,
        type = "button",
        chave_relatorio = "",
        className = "p-button-info p-mr-2",
        disabled = false,
        fileName,
    },
    ref
) => {
    const { versoes, solicitarRelatorio } = useRelatorioCSV({ chave: chave_relatorio });
    const handleClick = async () => {
        let filtros = {};
        if (filtersOnClick) {
            filtros = await filtersOnClick();
        }
        solicitarRelatorio({
            chave: chave_relatorio,
            filtros,
            versao: versoes[0]?.versao,
            enviar_fila: false,
            custom_name: fileName,
        });
    };

    const filtrosCSV = (filters) => {
        return filters;
    };

    useImperativeHandle(ref, () => ({ filtrosCSV }));

    return (
        <Button
            label="Exportar"
            icon={MAKO_ICONS.DOWNLOAD}
            type={type}
            onClick={handleClick}
            className={className}
            disabled={disabled || !versoes}
        />
    );
};

export const ExportCSV = forwardRef(Component);
