import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoButton as Button } from "@/components/MakoButton";
import permissoes from "@/assets/constants/permissoes";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

const url = "/pessoas/tags-enderecos-perfis/";

export const PessoasTagsEnderecoPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_TAGSENDERECO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar Cadastro de tag"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/gestao/cadastros/tags-enderecos/form",
                        state: { tag: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_TAGSENDERECO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a tag <b>{rowData.tag}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A tag de endereço não pode ser excluída."
                    tooltip="Deletar tag de endereço"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_TAGSENDERECO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                to={"/gestao/cadastros/tags-enderecos/form"}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "6%" },
            filter: true,
            filterField: "id",
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "tag",
            header: "Descrição",
            filter: true,
            filterField: "tag",
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Tags de Endereço"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    tag: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
