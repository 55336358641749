import { useContext } from "react";

import DevolucaoFornecedorContext from "@/contexts/devolucaoFornecedorContext";

const useDevolucaoFornecedor = () => {
    const context = useContext(DevolucaoFornecedorContext);
    return context;
};

export default useDevolucaoFornecedor;
