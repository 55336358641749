import React from "react";
import { FileUpload } from "primereact/fileupload";

import useAuth from "@/hooks/useAuth";

export const MakoFileUpload = ({ onBeforeSend, ...props }) => {
    const { token } = useAuth();

    const onBeforeSendFile = (e) => {
        e.xhr.setRequestHeader("Authorization", `token ${token}`);
        if (onBeforeSend) onBeforeSend(e);
    };

    return <FileUpload {...props} onBeforeSend={onBeforeSendFile} />;
};
