import React from "react";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";

import { ACTIONS_OPERAR_MODAIS_CAIXA } from "../../../reducers/operar-modais-caixa";

const { ROOT } = permissoes;

export const Analitico = ({ className = "", dispatcher = () => {} }) => {
    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[ROOT]}
            label="Analítico"
            className={className}
            onClick={() => {
                dispatcher({
                    type: ACTIONS_OPERAR_MODAIS_CAIXA.ANALITICO,
                });
            }}
        />
    );
};
