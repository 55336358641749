import React, { useState, useRef } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { Dialog } from "primereact/dialog";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoButton as Button } from "@/components/MakoButton";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

const url = "/financeiro/vinculos-planos-financeiro-contabil/";

export const VincularPlanosPage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [adicionarVinculo, setAdicionarVinculo] = useState(false);
    const [vinculo, setVinculo] = useState(null);
    const [planoContabil, setPlanoContabil] = useState(null);
    const [planoFinanceiro, setPlanoFinanceiro] = useState(null);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost, httpDelete } = useHttp();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);

    async function handleVinculo() {
        try {
            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Vínculo de plano de contas adicionado com sucesso!",
                        life: 3000,
                    });
                    listagemRef.current?.buscarDados();
                    setPlanoContabil(null);
                    setPlanoFinanceiro(null);
                },
            };
            showLoading();
            await httpPost(
                {
                    url,
                    body: {
                        plano_contabil: planoContabil,
                        plano_financeiro: planoFinanceiro,
                    },
                },
                handlers
            );
            hideLoading();
        } catch {
            showError();
        }
    }

    const deleteCfop = async () => {
        const handlers = {
            204: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Vínculo removido com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
            },
        };
        showLoading();
        await httpDelete({ url: `${url}${vinculo.id}` }, handlers);
        hideLoading();
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.CONTABIL_CONTABIL_VINCULARPLANOS_EXCLUIR]}
                    componente={Button}
                    icon={MAKO_ICONS.DELETE}
                    className="p-button-rounded p-button-danger"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Remover vínculo"
                    tooltipOptions={{ position: "left" }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.CONTABIL_CONTABIL_VINCULARPLANOS_INCLUIR]}
                componente={Button}
                label="Adicionar vínculo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                onClick={() => setAdicionarVinculo(true)}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código Vinc.", style: { width: "8%" } },
        { field: "plano_financeiro.descricao", header: "Plano Financeiro" },
        { field: "plano_contabil.descricao", header: "Plano Contábil" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            align: "center",
            style: { width: "8%" },
        },
    ];

    const cancelarAdicionar = () => {
        setAdicionarVinculo(false);
        setPlanoContabil(null);
        setPlanoFinanceiro(null);
    };

    const confirmarDelete = (vinculo) => {
        setVinculo(vinculo);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Vínculos de Planos de Contas Financeiros e Contábeis"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            vinculo && (
                                <span>
                                    {"Deseja realmente remover o vínculo código "}
                                    <b>{vinculo.id}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteCfop}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
                <Dialog
                    header="Adicionar Vínculo de Planos de Contas"
                    visible={adicionarVinculo}
                    style={{ width: "40vw" }}
                    onHide={() => cancelarAdicionar()}
                >
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <Label htmlFor="plano_financeiro" label="Plano de Contas Financeiro" obrigatorio />
                            <Dropdown
                                id="plano_financeiro"
                                name="plano_financeiro"
                                url="/financeiro/planos-contas-financeiras?limit=100"
                                onChange={(e) => setPlanoFinanceiro(e.target.value)}
                                filter
                                filterBy="descricao"
                                placeholder="Selecione um plano de contas"
                                optionLabel="descricao"
                                optionValue="id"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <Label htmlFor="plano_financeiro" label="Plano de Contas Contábil" obrigatorio />
                            <Dropdown
                                id="plano_contabil"
                                name="plano_contabil"
                                url="/financeiro/planos-contas-contabeis?limit=100"
                                onChange={(e) => setPlanoContabil(e.target.value)}
                                filter
                                filterBy="descricao"
                                placeholder="Selecione um plano de contas"
                                optionLabel="descricao"
                                optionValue="id"
                            />
                        </div>
                    </div>
                    <MakoActionsButtons className="p-jc-end" reverse>
                        <Button
                            label="Fechar"
                            type="reset"
                            icon={MAKO_ICONS.CANCEL}
                            className="p-button-danger"
                            onClick={cancelarAdicionar}
                            loading={loading}
                        />
                        <Button
                            label="Adicionar"
                            type="button"
                            className="p-button-info"
                            icon={MAKO_ICONS.GRAVAR}
                            onClick={() => handleVinculo()}
                            disabled={!planoContabil || !planoFinanceiro}
                            loading={loading}
                        />
                    </MakoActionsButtons>
                </Dialog>
            </div>
        </div>
    );
};
