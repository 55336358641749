import { Confirmar } from "./confirmar";
import { Cancelar } from "./cancelar";
import { Documento } from "./documento";
import { Pendentes } from "./pendentes";

export const Buttons = {
    Confirmar,
    Cancelar,
    Documento,
    Pendentes,
};
