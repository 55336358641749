import React, { useCallback } from "react";
import { Dropdown } from "@/components/Dropdown";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoDropdownSupervisores = ({ aposBuscar, ...props }) => {
    const [formatarDocumento] = useFormatCNPJCPF();

    const aposBuscarSupervisores = useCallback(
        (data = []) => {
            let supervisores = [];
            data.forEach((perfil) => {
                perfil.papeis_vigentes.forEach((papel) => {
                    if (papel.chave?.id === "SUP") {
                        supervisores.push({
                            id: perfil.id,
                            nome: perfil.perfil_pf.nome_completo,
                            identificacao: perfil.perfil_pf.cpf,
                        });
                    }
                });
            });
            if (typeof aposBuscar === "function") supervisores = aposBuscar(supervisores);
            return supervisores;
        },
        [aposBuscar]
    );

    const autoCompletePerfilTemplate = (item) => {
        return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
    };

    return (
        <Dropdown
            url="/pessoas/perfis/?usuario__isnull=false&query={id,perfil_pf,papeis_vigentes}&limit=200"
            optionLabel="nome"
            itemTemplate={autoCompletePerfilTemplate}
            aposBuscar={aposBuscarSupervisores}
            {...props}
        />
    );
};
