import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";

import { gerarFiltrosUrl } from "@/assets/util/util";

const BASE_URL_MOVIMENTACAO = "/financeiro/caixa-movimento-detalhes/?ordering=-id";

const rowClass = (movimentacao) => {
    return {
        "mako-movimentacao-credito": movimentacao.origem.tipo_movimento === "C",
        "mako-movimentacao-debito": movimentacao.origem.tipo_movimento === "D",
    };
};

const BASE_COLUMS = [
    {
        field: "evento_id",
        header: "Lançamento",
        style: { width: "8%" },
    },
    {
        field: "descricao",
        header: "Histórico",
    },
    {
        field: "origem.credor.nome",
        header: "Credor / Sacado",
    },
    {
        field: "origem.forma.descricao",
        header: "Forma Pgto/Rebto",
        align: "center",
        style: { width: "9%" },
    },
    {
        field: "origem.valor",
        header: "Valor",
        money: true,
        style: { width: "10%" },
    },
];

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    align: "center",
    style: { width: "10%" },
};

const BASE_FILTROS = { cancelado: false };

export const ListagemMovimentacoes = ({ caixaMovimento, filtros = BASE_FILTROS, listagemRef, actions }) => {
    const colunas = useMemo(() => {
        if (!actions) return BASE_COLUMS;
        const actionColum = {
            ...BASE_COLUMN_ACTIONS,
            action: (e) => actions(e),
        };
        return [...BASE_COLUMS, actionColum];
    }, [actions]);

    const url = useMemo(() => {
        let query = {
            caixa_movimento: caixaMovimento?.id,
        };
        if (typeof filtros === "object") query = { ...query, ...filtros };
        query = gerarFiltrosUrl(query);
        return `${BASE_URL_MOVIMENTACAO}&${query}`;
    }, [caixaMovimento, filtros]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo="Movimentações de caixa"
            botaoReload
            colunas={colunas}
            responsiva
            urlPesquisa={url}
            fazerBusca={!!caixaMovimento}
            configTabela={{
                rowClassName: rowClass,
                paginator: true,
                lazy: true,
            }}
        />
    );
};
