import React, { useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoInputPeriodo } from "@/components/MakoInputs/MakoInputPeriodo";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_VENDAS_PRODUTOSVENDIDOS } from "@/assets/constants/relatorios";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";
import { MakoDropdownVendedores } from "@/components/MakoInputs/MakoDropdownVendedores";
import useEmpresa from "@/hooks/useEmpresa";
import { MakoCalendar } from "@/components/MakoCalendar";

const { ExportCSV } = R.Buttons;

const OPTIOONS_ORDENACAO_FILTROS = [
    { value: "venda__data", label: "Periodo (crescente)" },
    { value: "-venda__data", label: "Periodo (decrescente)" },
    { value: "quantidade", label: "Quantidade (crescente)" },
    { value: "-quantidade", label: "Quantidade (decrescente)" },
];

const BASE_URL = "/relatorios/tabela-produtos/";

const FILE_NAME = gerarFileName("Produtos Vendidos");

const FILTROS_VIEWSET = {
    venda__empresa: "venda__empresa",
    venda__data__lte: "venda__data__lte",
    venda__data__gte: "venda__data__gte",
    venda__vendedor: "venda__vendedor",
    sku__categoriasku__categoria: "sku__categoriasku__categoria",
    sku: "sku",
    ordering: "ordering",
    formula_saldo: "formula_saldo",
    data_saldo: "data_saldo",
};

export const RelatorioTabelaProdutosVendidos = () => {
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);
    const [isFormulaSelected, setIsFormulaSelected] = useState(false);

    const { solicitarRelatorio } = useRelatorio();

    const { empresaSelecionadaId } = useEmpresa();

    const empresaSelecionada = useRef();
    const categoriaRef = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(empresaSelecionada, formRef, listagemRef, botaoCsv, categoriaRef);

    const filtrosTemplate = [
        {
            key: "venda__empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "venda__vendedor",
            label: "Vendedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["venda__data__gte", "venda__data__lte"],
            label: "Período",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "sku__categoriasku__categoria",
            label: "Categoria",
            path: "descricao",
        },
        {
            key: "sku",
            label: "Produto",
            type: TIPOS_FILTROS_TEMPLATE.SKU,
        },
        {
            key: "ordering",
            label: "Ordenado por",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIOONS_ORDENACAO_FILTROS,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "formula_saldo",
            label: "Fórmula",
            optionKey: "id",
            optionLabel: "descricao",
        },
        {
            key: "data_saldo",
            label: "Data do saldo",
            type: TIPOS_FILTROS_TEMPLATE.DATE,
        },
    ];

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key]) {
                    if (key === "venda__data__gte" || key === "venda__data__lte")
                        return (filtros[key] = dataToStr(dados[key], "yyyy-MM-dd"));
                    return (filtros[key] = dados[key]);
                }
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.sku) dadosValidados.sku = dadosValidados.sku.id;
        if (dadosValidados.venda__vendedor) dadosValidados.venda__vendedor = dadosValidados.venda__vendedor.id;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                venda__data__gte: Yup.date().nullable().typeError("Informe um 'periodo inicial' válido"),
                venda__data__lte: Yup.date().when("venda__data__gte", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.venda__data__gte || new Date(), "O 'periodo final' não pode ser anterior a inicial")
                        .typeError("Informe um 'periodo final' válido"),
                    otherwise: Yup.date().nullable(),
                }),
                venda__empresa: Yup.number().nullable().typeError("Informe uma 'empresa' válida"),
                sku__categoriasku__categoria: Yup.number().nullable().typeError("Informe uma 'categoria' válida."),
                sku: Yup.object().nullable().typeError("Você precisa buscar e selecionar um produto."),
                ordering: Yup.string().nullable().typeError("Seleciona uma 'ordenação' válida."),
                formula_saldo: Yup.number().nullable().typeError("Selecione uma 'fórmula de saldo' válido"),
                data_saldo: Yup.date().when("formula_saldo", {
                    is: (val) => !!val,
                    then: Yup.date().required("O campo 'data de saldo' é é obrigatório"),
                }),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                venda__empresa: empresaSelecionada.current,
                sku__categoriasku__categoria: categoriaRef.current,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.sku) dadosValidados.sku = dadosValidados.sku.id;
            if (dadosValidados.venda__vendedor) dadosValidados.venda__vendedor = dadosValidados.venda__vendedor.id;

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIO_VENDAS_PRODUTOSVENDIDOS,
                    emails,
                    corpo_email,
                    filtros,
                    filtros_template: filtrosTemplateAplicados,
                    versao,
                    enviar_fila: background,
                });
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        if (k === "venda__data__gte" || k === "venda__data__lte" || k === "data_saldo") {
                            filtros.push(`${FILTROS_VIEWSET[k]}=${dataToStr(v, "yyyy-MM-dd")}`);
                        } else {
                            filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                        }
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas =
        isFormulaSelected === false
            ? [
                  { field: "orcamento", header: "Orçamento", style: { width: "5%" } },
                  { field: "data", header: "Data", style: { width: "8%" }, dateFormat: "dd/MM/yyyy", align: "center" },
                  {
                      field: "nf",
                      header: "N°. NF",
                      style: { width: "8%" },
                      align: "center",
                  },
                  {
                      field: "codigo",
                      header: "Cód. ",
                      style: { width: "8%" },
                      align: "center",
                  },
                  { field: "descricao", header: "Descrição" },
                  {
                      field: "unidade",
                      header: "UN",
                      align: "center",
                      style: { width: "8%" },
                  },
                  { field: "quantidade", header: "QTD", align: "center", decimal: true, style: { width: "5%" } },
                  { field: "unitario", header: "Unitário", money: true, style: { width: "8%" } },
                  { field: "comissao", header: "Comissão", money: true, style: { width: "8%" } },
                  { field: "subtotal", header: "Sub. Total", money: true, style: { width: "8%" } },
              ]
            : [
                  { field: "orcamento", header: "Orçamento", style: { width: "5%" } },
                  { field: "data", header: "Data", style: { width: "8%" }, dateFormat: "dd/MM/yyyy", align: "center" },
                  {
                      field: "nf",
                      header: "N°. NF",
                      style: { width: "8%" },
                      align: "center",
                  },
                  {
                      field: "codigo",
                      header: "Cód. ",
                      style: { width: "8%" },
                      align: "center",
                  },
                  { field: "descricao", header: "Descrição" },
                  {
                      field: "unidade",
                      header: "UN",
                      align: "center",
                      style: { width: "8%" },
                  },
                  { field: "quantidade", header: "QTD", align: "center", decimal: true, style: { width: "5%" } },
                  { field: "unitario", header: "Unitário", money: true, style: { width: "8%" } },
                  { field: "comissao", header: "Comissão", money: true, style: { width: "8%" } },
                  { field: "subtotal", header: "Sub. Total", money: true, style: { width: "8%" } },
                  { field: "saldo", header: "Saldo", money: true, style: { width: "8%" } },
              ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIO_VENDAS_PRODUTOSVENDIDOS}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    const selecionarCategoria = (e) => {
        categoriaRef.current = e;
    };

    const selecionarFormula = (e) => {
        if (e.value !== undefined) {
            setFieldValue("formula_saldo", e.value);
            setFieldValue("data_saldo", new Date());
            setIsFormulaSelected(true);
        } else {
            setIsFormulaSelected(false);
        }
    };

    return (
        <R.Wrapper titulo={"produtos vendidos"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        venda__empresa: null,
                        venda__data__lte: null,
                        venda__data__gte: null,
                        venda__vendedor: null,
                        sku__categoriasku__categoria: null,
                        sku: null,
                        ordering: null,
                        formula_saldo: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa",
                        inputId: "empresa",
                        inputName: "venda__empresa",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Vendedor",
                        inputId: "venda__vendedor",
                        inputName: "venda__vendedor",
                        component: MakoDropdownVendedores,
                        componentProps: {
                            showClear: true,
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Fórmula de Saldo",
                        inputId: "formula_saldo",
                        inputName: "formula_saldo",
                        component: Dropdown,
                        componentProps: {
                            url: `/produtos/saldo-estoque-formula/?empresa=${empresaSelecionadaId}`,
                            optionLabel: "descricao",
                            optionValue: "id",
                            buscar: !!empresaSelecionadaId,
                            getOnChange: selecionarFormula,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Data de Saldo",
                        inputId: "data_saldo",
                        inputName: "data_saldo",
                        component: MakoCalendar,
                        disabled: !isFormulaSelected,
                        required: isFormulaSelected,
                    },
                    {
                        inputId: "periodo",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        componentProps: {
                            label: "Periodo",
                            nameInicio: "venda__data__gte",
                            nameFinal: "venda__data__lte",
                            valueInicio: "venda__data__gte",
                            valueFinal: "venda__data__lte",
                            errorInicio: "venda__data__gte",
                            errorFinal: "venda__data__lte",
                        },
                        fieldSize: 5,
                    },
                    {
                        label: "Categoria",
                        inputId: "categoria",
                        inputName: "sku__categoriasku__categoria",
                        component: MakoDropdownCategoriasHierarquicas,
                        componentProps: {
                            getCategoriaCompleta: selecionarCategoria,
                            categoriaTituloSelecionavel: true,
                            showClear: true,
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Produto",
                        inputId: "sku",
                        inputName: "sku",
                        component: MakoBuscaSkuPersonalizada,
                        componentEspecial: COMPONENTES_ESPECIAIS.SKU,
                        fieldSize: 12,
                    },
                    {
                        label: "Ordenado por",
                        inputId: "ordering",
                        inputName: "ordering",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione uma forma de ordenação",
                            options: OPTIOONS_ORDENACAO_FILTROS,
                            optionValue: "value",
                            optionLabel: "label",
                            showClear: true,
                        },
                        fieldSize: 4,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIO_VENDAS_PRODUTOSVENDIDOS}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                    onlyBackground={true}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
