import React from "react";

import { MakoButton as Button } from "@/components/MakoButton";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";

export const ParcelaFrete = ({ onClick = () => {}, disabled = false, className = "" }) => {
    const { dadosBasicos } = useDevolucaoFornecedor();

    return (
        <Button
            type="button"
            icon={MAKO_ICONS.NOVO}
            label="Parcela do frete"
            severity="success"
            disabled={parseFloat(dadosBasicos?.valor_frete) === 0 || disabled}
            className={className}
            onClick={onClick}
        />
    );
};
