import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { Button } from "primereact/button";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputFornecedor } from "../MakoInputFornecedor";
import { MakoInputPeriodo } from "../MakoInputPeriodo";
import { Label } from "@/components/Label";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { dataToStr } from "@/assets/util/datas";
import { Listagem } from "./listagem";

const INITIAL_VALUES = {
    data_pedido__gte: null,
    data_pedido__lte: null,
    fornecedor: null,
    itemordemcompra__sku: null,
};

const ListagemMemo = memo(Listagem);

const Component = (_, ref) => {
    const [selected, setSelected] = useState(null);
    const [filtros, setFiltros] = useState(null);

    const { setFieldValue, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_pedido__gte: Yup.date().nullable().typeError("Informe um 'periodo inicial' válido."),
                data_pedido__lte: Yup.date().when("data_pedido__gte", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            Yup.ref("data_pedido__gte") || new Date(),
                            "O campo 'periodo final' não pode ser anterior a inicial"
                        )
                        .nullable()
                        .typeError("Informe um 'periodo final' válida"),
                    otherwise: Yup.date().nullable(),
                }),
                fornecedor: Yup.object().nullable().typeError("Informe um 'fornecedor' válido"),
                itemordemcompra__sku: Yup.object().nullable().typeError("Informe um 'produto' válido"),
            });
            const dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let _filtros = {};
            for (let [k, v] of Object.entries(dadosValidados)) {
                if (v) {
                    if (["data_pedido__lte", "data_pedido__gte"].includes(k)) v = dataToStr(v, "yyyy-MM-dd");
                    _filtros[k] = v?.id || v;
                }
            }
            _filtros = Object.keys(_filtros).length ? _filtros : null;
            setFiltros(_filtros);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const listagem = useMemo(() => {
        return (
            <ListagemMemo
                filtros={filtros}
                configTabela={{
                    selectionMode: "single",
                    selection: selected,
                    onSelectionChange: ({ value }) => setSelected(value),
                }}
            />
        );
    }, [filtros, selected]);

    const getSelected = useCallback(() => {
        return selected;
    }, [selected]);

    useImperativeHandle(ref, () => ({ getSelected }), [getSelected]);

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <MakoInputPeriodo
                        label="Periodo"
                        nameInicio="data_pedido__gte"
                        nameFinal="data_pedido__lte"
                        valueInicio={formik.values.data_pedido__gte}
                        valueFinal={formik.values.data_pedido__lte}
                        errorInicio={formik.errors.data_pedido__gte}
                        errorFinal={formik.errors.data_pedido__lte}
                        onChangeInicio={formik.handleChange}
                        onChangeFinal={formik.handleChange}
                    />
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="fornecedor" label="Fornecedor" />
                        <MakoInputFornecedor
                            id="fornecedor"
                            name="fornecedor"
                            value={formik.values.fornecedor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fornecedor })}
                        />
                        {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="itemordemcompra__sku" label="Produto" />
                        <MakoBuscaSkuPersonalizada
                            statusItem="F"
                            skuValue={formik.values.itemordemcompra__sku}
                            skuChange={(e) => setFieldValue("itemordemcompra__sku", e)}
                            skuError={formik.errors.itemordemcompra__sku}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Button type="submit" icon={MAKO_ICONS.PESQUISAR} label="Pesquisar" />
                    </div>
                </div>
            </form>
            <div className="p-mt-2">{listagem}</div>
        </div>
    );
};

export const Form = forwardRef(Component);
