import { useCallback, useEffect, useState } from "react";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/financeiro/recebimentos/{id}/calcular-encargos";

const BASE_TAXAS = {
    percent_juros: 0,
    percent_juros_desc_max: 0,
    carencia_juros: 0,
    carencia_antecipacao: 0,
    percent_antecipacao: 0,
    percent_descontos: 0,
    percent_multa: 0,
    percent_multa_desc_max: 0,
    dias: 0,
    valor: 0,
    descontos: 0,
    recebido: 0,
    juros: 0,
    multa: 0,
};

const useTaxasRecebimentos = ({ recebimentoId }) => {
    const [taxas, setTaxas] = useState(BASE_TAXAS);
    const { httpGet, abort } = useHttp();

    const fetch = useCallback(async () => {
        if (recebimentoId) {
            const url = BASE_URL.replace("{id}", recebimentoId);
            const handlers = {
                200: ({ data: { encargos } }) => {
                    const {
                        carencia_antecipacao,
                        carencia_juros,
                        descontos,
                        dias_atraso,
                        juros,
                        multa,
                        percent_antecipacao,
                        percent_descontos,
                        percent_juros_desc_max,
                        percent_multa,
                        percent_multa_desc_max,
                        percentual_juros,
                        recebido,
                        valor,
                    } = encargos || {};

                    setTaxas({
                        percent_juros: percentual_juros,
                        percent_juros_desc_max: percent_juros_desc_max,
                        carencia_juros: carencia_juros,
                        carencia_antecipacao: carencia_antecipacao,
                        percent_antecipacao: percent_antecipacao,
                        percent_descontos: percent_descontos,
                        percent_multa: percent_multa,
                        percent_multa_desc_max: percent_multa_desc_max,
                        dias: dias_atraso,
                        valor,
                        descontos,
                        recebido,
                        juros,
                        multa,
                    });
                },
            };
            await httpGet({ url }, handlers);
        }
    }, [httpGet, recebimentoId]);

    useEffect(() => {
        fetch();
        return () => abort();
    }, [fetch, abort]);

    return { taxas };
};

export default useTaxasRecebimentos;
