import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";

export const QuantidadeFiltroTemplate = (options) => {
    return (
        <MakoInputQuantidadeSku
            value={options?.value ? options.value : ""}
            onValueChange={(e) => options.filterCallback(e.value)}
            permiteFracionario
        />
    );
};
