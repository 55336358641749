import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { TIPO_ESCOPO_FAIXA_COMISSAO_CHOICE } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";

export const FaixaComissaoPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_FAIXACOMISSAO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar faixa de comissão"
                    to={{
                        pathname: "/vendas/cadastros/faixa-comissao/form",
                        state: { faixa: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_FAIXACOMISSAO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/vendas/faixa-comissao/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir a faixa de comissão "}
                            <b>{rowData.descricao}</b>?
                        </span>
                    }
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_FAIXACOMISSAO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/vendas/cadastros/faixa-comissao/form"}
            />
        </>
    );

    const TIPO_CALCULO = [
        { value: "P", label: "Percentual" },
        { value: "V", label: "Valor fixo" },
    ];

    const tipoBodyTemplate = (rowData) => {
        if (rowData.tipo_calculo === "P") {
            return <Tag severity="info" value="Percentual" />;
        }
        return <Tag severity="info" value="Valor fixo" />;
    };

    const tipoFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={TIPO_CALCULO}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const escopoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_ESCOPO_FAIXA_COMISSAO_CHOICE,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "#ID", filter: true, filterElement: CodigoFiltroTemplate },
        { field: "codigo_faixa", header: "Código", filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "regra_apuracao.descricao",
            header: "Regra Apuração",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "regra_apuracao__descricao",
        },
        {
            field: "escopo.descricao",
            header: "Escopo",
            filter: true,
            filterElement: escopoFiltroTemplate,
            filterField: "escopo",
        },
        {
            field: "tipo_calculo",
            header: "Tipo cálculo",
            filter: true,
            filterElement: tipoFiltroTemplate,
            action: (e) => tipoBodyTemplate(e),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Faixas de Comissão"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={"/vendas/faixa-comissao/"}
                responsiva
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    codigo_faixa: { value: "", matchMode: "equals" },
                    tipo_calculo: { value: "", matchMode: "equals" },
                    regra_apuracao__descricao: {
                        operator: "and",
                        constraints: [{ value: null, matchMode: "unaccent_icontains" }],
                    },
                    escopo: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                }}
            />
        </PageBase>
    );
};
