import React, { useCallback, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import useToast from "@/hooks/useToast";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

export const ClonarPermissoesForm = ({ perfil, esconderClonar, listagemRef }) => {
    const [usuarioClonado, setUsuarioClonado] = useState(null);
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError, showWarning } = useToast();
    const { httpPost, httpGet } = useHttp();

    async function clonarPermissoes() {
        try {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Usuário cadastrado com sucesso!",
                        life: 1500,
                    });
                    esconderClonar();
                    listagemRef.current?.buscarDados();
                },
                409: ({ data }) => {
                    showWarning({
                        summary: "Falha",
                        detail: data.msg,
                        life: 3000,
                    });
                },
            };
            showLoading();
            await httpPost(
                {
                    url: `/pessoas/clonar-permissoes-usuario/`,
                    body: {
                        usuario_alvo: perfil,
                        usuario_raiz: usuarioClonado,
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            showError();
            hideLoading();
        }
    }

    const listarUsuarios = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                let lista = [];
                data.results.forEach((e) => {
                    if (e.usuario.is_active && perfil !== e.id) lista.push(e);
                });
                setListaUsuarios(lista);
            },
        };
        await httpGet({ url: "/pessoas/perfis?query={id,usuario,nome}&usuario__isnull=false&limit=1000" }, handlers);
    }, [perfil, httpGet]);

    useEffect(() => {
        listarUsuarios();
    }, [listarUsuarios]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <Label
                                htmlFor="natureza_operacao"
                                label="Selecione o usuário que será clonado"
                                obrigatorio
                            />
                            <Dropdown
                                id="usuario-raiz"
                                name="usuario-raiz"
                                options={listaUsuarios}
                                optionValue="id"
                                optionLabel="usuario.username"
                                filter
                                filterBy="nome,usuario.username"
                                placeholder="Selecione um usuário"
                                value={usuarioClonado}
                                onChange={(e) => setUsuarioClonado(e.target.value)}
                            />
                        </div>
                    </div>
                    <MakoActionsButtons className="p-justify-center">
                        <Button
                            label="Clonar permissões"
                            type="button"
                            icon="pi pi-users"
                            className="p-button-info"
                            disabled={!usuarioClonado}
                            onClick={() => clonarPermissoes()}
                            loading={loading}
                        />
                    </MakoActionsButtons>
                </form>
            </div>
        </div>
    );
};
