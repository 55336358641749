import React, { memo, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { MakoButton } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import * as Yup from "yup";

const INITIAL_VALUES = {
    id: null,
    descricao: "",
    estoque_empresa: null,
    ativa: true,
};

const Component = ({ formula, possuiCentros, handleFormula }) => {
    const [loading, setLoading] = useState(false);
    const { httpPost, httpPatch } = useHttp();
    const { empresaSelecionadaId } = useEmpresa();
    const { showSuccess, showError } = useToast();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: !formula ? { ...INITIAL_VALUES, empresa: empresaSelecionadaId } : formula,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                estoque_empresa: Yup.number().required("O campo 'descrição' é obrigatório."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!dadosValidados?.id) {
                setLoading(true);
                delete dadosValidados.id;

                const handlers = {
                    201: ({ data }) => {
                        let _data = {
                            id: data.id,
                            descricao: data.descricao,
                            estoque_empresa: data.estoque_empresa.id,
                            ativa: data.ativa,
                        };
                        setValues({ ..._data });

                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Formula de saldo cadastrada com sucesso.",
                            life: 3000,
                        });
                        if (typeof handleFormula === "function") handleFormula(_data);
                    },
                };

                await httpPost({ url: "/produtos/saldo-estoque-formula/", body: dadosValidados }, handlers);
                setLoading(false);
            } else {
                const handlers = {
                    200: ({ data }) => {
                        let _data = {
                            id: data.id,
                            descricao: data.descricao,
                            estoque_empresa: data.estoque_empresa.id,
                            ativa: data.ativa,
                        };
                        setValues({ ..._data });
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Formula de saldo alterada com sucesso.",
                            life: 3000,
                        });
                        if (typeof handleFormula === "function") handleFormula(_data);
                    },
                };

                setLoading(true);
                await httpPatch(
                    { url: `/produtos/saldo-estoque-formula/${dadosValidados.id}/`, body: dadosValidados },
                    handlers
                );
                setLoading(false);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="descricao" obrigatorio={true} label="Nome da formula" />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="estoque_empresa" obrigatorio={true} label="Selecione a unidade / estoque" />
                        <Dropdown
                            id="estoque_empresa"
                            name="estoque_empresa"
                            buscar={!!empresaSelecionadaId}
                            url={`/produtos/estoques-empresas/?empresa__id=${empresaSelecionadaId}&limit=100`}
                            optionValue="id"
                            optionLabel="nome"
                            showClear
                            value={formik.values.estoque_empresa}
                            disabled={possuiCentros}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.estoque_empresa })}
                        />
                        {formik.errors.estoque_empresa && (
                            <small className="p-error">{formik.errors.estoque_empresa}</small>
                        )}
                    </div>
                </div>
                <p>* Campos obrigatórios</p>
                <div className="p-grid p-col-12 p-md-6">
                    <MakoButton
                        loading={loading}
                        label="Gravar"
                        type="submit"
                        icon="pi pi-save"
                        className="p-button-info"
                    />
                </div>
            </form>
        </>
    );
};

export const FormulaForm = memo(Component);
