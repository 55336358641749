import React, { useCallback, useMemo, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { BotaoDelete } from "@/components/BotaoDelete";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { ModalGerarParcelas } from "./modalGerarParcelas";
import { DadosCabecalho } from "./dadosCabecalho";

import useContratoServico from "@/hooks/useContratoServico";
import useClearRefs from "@/hooks/useClearRefs";
import { ModalEditarParcela } from "./modalEditarParcela";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";

const FormFinanceiro = () => {
    const initialModal = {
        visible: false,
        title: "",
        content: null,
    };
    const [modal, setModal] = useState(initialModal);
    const [tipoMovimentacao, setTipoMovimentacao] = useState({ tipo: 0, processada: false });
    const { contrato } = useContratoServico();

    const listagemRef = useRef(null);
    useClearRefs(listagemRef);

    const fecharModal = () => {
        setModal((prev) => ({ ...prev, visible: false }));
    };

    const onFinish = useCallback(() => {
        fecharModal();
        listagemRef?.current.buscarDados();
    }, []);

    const confirmarDelete = () => {
        listagemRef?.current.buscarDados();
    };

    const actionBodyTemplate = useCallback(
        (rowData) => {
            return (
                <MakoActionsButtonsColumn>
                    <Button
                        icon={MAKO_ICONS.EDITAR}
                        className="p-button-rounded p-button-warning"
                        onClick={() => {
                            setModal({
                                visible: true,
                                title: !tipoMovimentacao.tipo ? "Editar recebimento" : "Editar pagamento",
                                content: (
                                    <ModalEditarParcela
                                        onFinish={() => onFinish()}
                                        onClose={fecharModal}
                                        recebimento={!tipoMovimentacao.tipo}
                                        parcela={
                                            !tipoMovimentacao.tipo
                                                ? {
                                                      id: rowData.id,
                                                      valor: rowData.valor,
                                                      vencimento: rowData.vencimento,
                                                      agrupador: rowData.agrupador,
                                                      historico_original: rowData.historico_original,
                                                  }
                                                : {
                                                      id: rowData.id,
                                                      valor: rowData.valor,
                                                      vencimento: rowData.vencimento,
                                                      documento: rowData.documento,
                                                      historico_original: rowData.historico_original,
                                                      credor: rowData.credor.id,
                                                  }
                                        }
                                    />
                                ),
                            });
                        }}
                        disabled={rowData.quitado || rowData.valor_recebido > 0}
                    />
                    <BotaoDelete
                        url={`/financeiro/${!tipoMovimentacao.tipo ? "recebimentos" : "pagamentos"}/`}
                        objetoId={rowData.id}
                        exigeConfirmacao
                        msgConfirmacao={
                            <span>
                                Deseja mesmo excluir a parcela <b>{rowData.id}</b>?
                            </span>
                        }
                        msgToastErroExclusao="A parcela não pode ser excluído."
                        onDelete={confirmarDelete}
                        tooltipOptions={{ position: "bottom" }}
                        disabled={rowData.quitado || rowData.valor_recebido > 0}
                    />
                </MakoActionsButtonsColumn>
            );
        },
        [onFinish, tipoMovimentacao.tipo]
    );

    const url = useMemo(() => {
        return `/financeiro/${!tipoMovimentacao.tipo ? "recebimentos" : "pagamentos"}/?cancelado=False&quitado=${
            tipoMovimentacao.processada
        }&faturamento_contrato__contrato_id=${contrato.id}`;
    }, [tipoMovimentacao, contrato]);

    const tituloListagem = useMemo(() => {
        return !tipoMovimentacao.tipo ? "Recebimentos" : "Pagamentos";
    }, [tipoMovimentacao.tipo]);

    const colunas = useMemo(() => {
        const BASE_COLUMNS = [
            {
                field: "",
                header: "Parcela",
                style: { width: "5%" },
                action: ({ numero_parcela, quantidade_parcelas }) => `${numero_parcela}/${quantidade_parcelas}`,
            },
            {
                field: "faturamento_contrato",
                header: "Faturamento",
                style: { width: "8%" },
            },
            {
                field: "template_rateio.descricao",
                header: "Rateio",
            },
            {
                field: "data_emissao",
                header: "Emissão",
                dateFormat: "dd/MM/yyy",
                style: { width: "10%" },
            },
            {
                field: "vencimento",
                header: "Vencimento",
                dateFormat: "dd/MM/yyy",
                style: { width: "10%" },
            },
            {
                field: "valor",
                header: "Valor",
                money: true,
            },
            {
                field: "action",
                header: "Ações",
                align: "center",
                style: { width: "10%" },
                action: (e) => actionBodyTemplate(e),
            },
        ];
        return !tipoMovimentacao.tipo
            ? BASE_COLUMNS
            : [
                  ...BASE_COLUMNS.splice(0, 2),
                  {
                      field: "credor.nome",
                      header: "Credor",
                  },
                  ...BASE_COLUMNS.slice(1),
              ];
    }, [actionBodyTemplate, tipoMovimentacao.tipo]);

    return (
        <div>
            <DadosCabecalho />
            <div className="p-fluid p-formgrid p-grid p-mt-3">
                <div className="p-field p-col-12 p-md-3">
                    <Dropdown
                        optionValue="value"
                        optionLabel="label"
                        options={[
                            { value: 0, label: "Exibir parcelas a receber" },
                            { value: 1, label: "Exibir parcelas a pagar" },
                        ]}
                        value={tipoMovimentacao.tipo}
                        onChange={(e) => setTipoMovimentacao((prev) => ({ ...prev, tipo: e.value }))}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Dropdown
                        placeholder="Exibir apenas parcelas não processadas"
                        optionValue="value"
                        optionLabel="label"
                        options={[
                            { value: false, label: "Parcelas não quitadas" },
                            { value: true, label: "Parcelas quitadas" },
                        ]}
                        value={tipoMovimentacao.processada}
                        onChange={(e) => setTipoMovimentacao((prev) => ({ ...prev, processada: e.value }))}
                    />
                </div>
            </div>
            <MakoActionsButtons className="p-mt-2">
                <Button
                    type="button"
                    className="p-button-success"
                    label="Gerar recebimentos"
                    onClick={() => {
                        setModal({
                            visible: true,
                            title: "Gerar recebimentos",
                            content: (
                                <ModalGerarParcelas
                                    onFinish={() => onFinish()}
                                    onClose={fecharModal}
                                    url={`/servicos/gerar-parcelas-recebimento-contratoservico/${contrato.id}`}
                                />
                            ),
                        });
                    }}
                />
                <Button
                    type="button"
                    className="p-button-success"
                    label="Gerar pagamentos"
                    onClick={() => {
                        setModal({
                            visible: true,
                            title: "Gerar pagamentos",
                            content: (
                                <ModalGerarParcelas
                                    onFinish={() => onFinish()}
                                    onClose={fecharModal}
                                    url={`/servicos/gerar-parcelas-pagamento-contratoservico/${contrato.id}`}
                                    pagamento
                                />
                            ),
                        });
                    }}
                />
            </MakoActionsButtons>
            <div className="p-my-4">
                <MakoListagem
                    ref={listagemRef}
                    titulo={tituloListagem}
                    colunas={colunas}
                    urlPesquisa={url}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            </div>
            <Dialog
                visible={modal.visible}
                header={modal.title}
                style={{ width: "60vw" }}
                onHide={() => setModal((prev) => ({ ...prev, visible: false }))}
            >
                {modal.content}
            </Dialog>
        </div>
    );
};

export default React.memo(FormFinanceiro);
