import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const ConfirmarSenha = ({ onConfirm }, ref) => {
    const [visible, setVisible] = useState(false);
    const [senha, setSenha] = useState("");
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();
    const { showWarning } = useToast();
    const { httpPost } = useHttp();

    const confirmarSenha = async () => {
        const userData = {
            username: user.usuario.username,
            password: senha,
        };

        const handlers = {
            200: () => {
                if (onConfirm) onConfirm();
                fecharModal();
            },
            400: () =>
                showWarning({
                    summary: "Senha inválida!",
                    detail: "A senha fornecida não está correta.",
                    life: 3000,
                }),
        };

        setLoading(true);
        await httpPost({ url: "/auth/token/login", body: userData }, handlers);
        setLoading(false);
    };

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const footer = (
        <div>
            <Button label="Confirmar senha" icon="pi pi-check" onClick={confirmarSenha} autoFocus loading={loading} />
        </div>
    );

    return (
        <Dialog
            header="Confirmar senha"
            visible={visible}
            onHide={fecharModal}
            position="top"
            footer={footer}
            style={{ width: "20vw" }}
        >
            <div className="p-fluid">
                <Password
                    value={senha}
                    placeholder="Digite sua senha"
                    onChange={(e) => setSenha(e.target.value)}
                    feedback={false}
                />
            </div>
        </Dialog>
    );
};

export const MakoDialogConfirmarSenha = forwardRef(ConfirmarSenha);
