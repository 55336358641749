import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

import { InputText } from "primereact/inputtext";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoInputPerfil } from "@/components/MakoInputs";
import { Label } from "@/components/Label";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

import permissoes from "@/assets/constants/permissoes";
import useHttp from "@/hooks/useHttp";

export const FormDesbloquear = ({ caixaMov, user, successCallback = () => {}, cancelCallback = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost } = useHttp();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            operador_bloqueou: user.nome,
            operador_novo: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                operador_bloqueou: Yup.string()
                    .required("O campo 'operador' é obrigatório.")
                    .typeError("Informe um operador"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let data = {};
            if (values.operador_novo) data = { operador: values.operador_novo.id };

            const handlers = {
                200: ({ data }) => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Caixa desbloqueado com sucesso!",
                        life: 1500,
                    });

                    let newOperador = { new: false, operador: null };
                    if (values.operador_novo) newOperador = { new: true, operador: values.operador_novo };
                    formik.resetForm();
                    successCallback(data.caixa, newOperador);
                },
            };

            showLoading();
            await httpPost(
                {
                    url: `/financeiro/caixas/${caixaMov.id}/operar/desbloquear`,
                    body: data,
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="operador_bloqueou" label="Operador que bloqueou o caixa" obrigatorio />
                    <InputText
                        id="operador_bloqueou"
                        name="operador_bloqueou"
                        value={formik.values.operador_bloqueou}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.operador_bloqueou })}
                        disabled
                    />
                    {formik.errors.operador_bloqueou && (
                        <small className="p-error">{formik.errors.operador_bloqueou}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-7">
                    <Label htmlFor="operador_novo" label="Novo Operador" obrigatorio />
                    <MakoControleAcesso
                        componente={MakoInputPerfil}
                        permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR_TERCEIRO]}
                        exibirDesabilitadoSemPermissao={true}
                        id="operador_novo"
                        name="operador_novo"
                        value={formik.values.operador_novo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.operador_novo })}
                    />
                    {formik.errors.operador_novo && <small className="p-error">{formik.errors.operador_novo}</small>}
                </div>
            </div>
            <MakoActionsButtons>
                <Button label="Desbloquear" className="p-button-success" type="submit" loading={loading} />
                <Button label="Cancelar" className="p-button-danger" onClick={cancelCallback} loading={loading} />
            </MakoActionsButtons>
        </form>
    );
};
