import React, { useEffect } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { dataToStr } from "@/assets/util/datas";
import { TIPO_CENTRO_ESTOCAGEM, TIPO_SKU_ESTOCADO } from "@/assets/constants/estoque";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useHttp from "@/hooks/useHttp";
import * as Yup from "yup";

const url = "/produtos/centros-estocagem/";

export const CentroEstocagemFormBase = ({ onCancelar, estoqueId, centroEstocagem }) => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPut } = useHttp();
    const history = useHistory();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            estoque_empresa: estoqueId,
            nome: "",
            descricao: "",
            tipo: null,
            tipo_sku: null,
            centro_estocagem_principal: null,
            data_saldo_inicial: new Date(),
            inventario: true,
            formula_venda: null,
            formula_faturamento: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                tipo: Yup.string()
                    .required("Escolha um tipo de centro de estocagem.")
                    .typeError("Informe um 'tipo' válido."),
                tipo_sku: Yup.string()
                    .required("Escolha o tipo de produto que será estocado.")
                    .typeError("Informe um 'tipo de produto' válido."),
                centro_estocagem_principal: Yup.string()
                    .nullable()
                    .when("tipo", {
                        is: (val) => val && val !== "N" && val !== "C",
                        then: Yup.string()
                            .typeError("Escolha um centro de estocagem do tipo 'NORMAL'.")
                            .required("Escolha um centro de estocagem do tipo 'NORMAL'."),
                    })
                    .when("tipo", {
                        is: (val) => val && val === "N",
                        then: Yup.string()
                            .typeError("Escolha um centro de estocagem do tipo 'CONSOLIDADO'.")
                            .required("Escolha um centro de estocagem do tipo 'CONSOLIDADO'."),
                    }),
                data_saldo_inicial: Yup.date()
                    .required("O campo 'data de saldo inicial' é obrigatório.")
                    .typeError("Informe uma data válida."),
                formula_venda: Yup.number()
                    .required("O campo 'fórmula para venda' é obrigatório.")
                    .typeError("Selecione uma fórmula."),
                formula_faturamento: Yup.number()
                    .required("O campo 'fórmula para faturamento' é obrigatório.")
                    .typeError("Selecione uma fórmula."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const centroEstocagem = {
                ...values,
                data_saldo_inicial: dataToStr(values.data_saldo_inicial, "yyyy-MM-dd"),
                centro_estocagem_principal: !values.tipo !== "C" ? values.centro_estocagem_principal : null,
            };
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Centro de estocagem cadastrado com sucesso!",
                            life: 1500,
                        });
                        cancelar();
                    },
                };

                showLoading();
                await httpPost({ url, body: centroEstocagem }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Centro de estocagem alterado com sucesso!",
                            life: 1500,
                        });
                        cancelar();
                    },
                };

                showLoading();
                await httpPut(
                    {
                        url: `${url}${values.id}/`,
                        body: {
                            ...values,
                            data_saldo_inicial: dataToStr(values.data_saldo_inicial, "yyyy-MM-dd"),
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        formik.resetForm();
        if (typeof onCancelar === "function") onCancelar();
        else history.push({ pathname: "/estoque/cadastros/centro-estocagem", estoque: formik.values?.estoque_empresa });
    };

    useEffect(() => {
        if (centroEstocagem)
            setValues({
                ...centroEstocagem,
                estoque_empresa: centroEstocagem.estoque_empresa.id,
            });
    }, [centroEstocagem, setValues, setFieldValue]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="nome">Nome *</label>
                    <InputText
                        id="nome"
                        name="nome"
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.nome })}
                    />
                    {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="descricao">Descrição *</label>
                    <InputText
                        id="descricao"
                        name="descricao"
                        value={formik.values.descricao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.descricao })}
                    />
                    {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="tipo">Tipo de centro de estocagem *</label>
                    <Dropdown
                        id="tipo"
                        name="tipo"
                        options={TIPO_CENTRO_ESTOCAGEM}
                        placeholder="Selecione"
                        value={formik.values.tipo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tipo })}
                    />
                    {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="tipo-sku">Tipo de produto estocado *</label>
                    <Dropdown
                        id="tipo-sku"
                        name="tipo_sku"
                        options={TIPO_SKU_ESTOCADO}
                        placeholder="Selecione"
                        value={formik.values.tipo_sku}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tipo_sku })}
                    />
                    {formik.errors.tipo_sku && <small className="p-error">{formik.errors.tipo_sku}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="centro-estocagem-principal">C. estocagem principal</label>
                    <Dropdown
                        id="centro-estocagem-principal"
                        name="centro_estocagem_principal"
                        url={"/produtos/centros-estocagem/?tipo__in=C&query={id,nome,tipo}"}
                        optionValue="id"
                        optionLabel="nome"
                        disabled={formik.values?.tipo === "C"}
                        filter
                        showClear
                        filterBy="nome"
                        value={formik.values.centro_estocagem_principal}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.centro_estocagem_principal })}
                    />
                    {formik.errors.centro_estocagem_principal && (
                        <small className="p-error">{formik.errors.centro_estocagem_principal}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-saldo-inicial">Data do saldo inicial *</label>
                    <MakoCalendar
                        id="data-saldo-inicial"
                        name="data_saldo_inicial"
                        valueCalendar={formik.values.data_saldo_inicial}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_saldo_inicial })}
                    />
                    {formik.errors.data_saldo_inicial && (
                        <small className="p-error">{formik.errors.data_saldo_inicial}</small>
                    )}
                </div>
                <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                    <Checkbox
                        inputId="e-inventario"
                        name="inventario"
                        checked={formik.values.inventario}
                        onChange={formik.handleChange}
                    />
                    <label htmlFor="e-inventario">Faz parte do inventário?</label>
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="formula-venda">Fórmula para venda *</label>
                    <Dropdown
                        id="formula-venda"
                        name="formula_venda"
                        url="/produtos/saldo-estoque-formula/?limit=100"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.formula_venda}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.formula_venda })}
                    />
                    {formik.errors.formula_venda && <small className="p-error">{formik.errors.formula_venda}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="formula-faturamento">Fórmula para faturamento *</label>
                    <Dropdown
                        id="formula-faturamento"
                        name="formula_faturamento"
                        url="/produtos/saldo-estoque-formula/?limit=100"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.formula_faturamento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.formula_faturamento })}
                    />
                    {formik.errors.formula_faturamento && (
                        <small className="p-error">{formik.errors.formula_faturamento}</small>
                    )}
                </div>
            </div>
            <CamposObrigatorios />
            <div className="p-grid p-col-12 p-md-6">
                <Button label="Gravar" type="submit" icon="pi pi-save" className="p-button-info p-mr-2" />
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    type="button"
                    className="p-button-danger p-mr-2"
                    onClick={cancelar}
                />
            </div>
        </form>
    );
};
