import React from "react";
import { Skeleton as SkeletonPR } from "primereact";

export const Skeleton = () => {
    return (
        <>
            <div className="p-d-flex p-justify-around p-mb-3" style={{ width: "inherit" }}>
                <SkeletonPR height="6rem" className="p-mr-2" />
                <SkeletonPR height="6rem" className="p-mr-2" />
                <SkeletonPR height="6rem" className="p-mr-2" />
                <SkeletonPR height="6rem" />
            </div>
            <div className="p-mt-3">
                <SkeletonPR shape="rectangle" height="20rem" />
            </div>
        </>
    );
};
