import React from "react";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { ACTIONS_OPERAR_MODAIS_CAIXA } from "../../../reducers/operar-modais-caixa";
import permissoes from "@/assets/constants/permissoes";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";

const {
    FINANCEIRO_FINANCEIRO_CAIXAMOV_FECHAR,
    FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR,
    FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR_TERCEIRO,
} = permissoes;

export const Fechamento = ({ className = "", dispatcher = () => {} }) => {
    const { caixaMov } = useCaixaMovimento();

    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[
                FINANCEIRO_FINANCEIRO_CAIXAMOV_FECHAR,
                FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR,
                FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR_TERCEIRO,
            ]}
            label="Fechamento"
            className={className}
            onClick={() => {
                dispatcher({
                    type: ACTIONS_OPERAR_MODAIS_CAIXA.FECHAR,
                    reloadCaixa: true,
                });
            }}
            disabled={caixaMov?.bloqueado}
        />
    );
};
