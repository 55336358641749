import React, { memo } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";

import { Label } from "@/components/Label";

const Componente = ({ fieldOptions, valueOptions, instance, onChange, error }) => {
    const { id: name, label, is_required, default_value, content_type } = fieldOptions;

    const valueField = valueOptions || {
        id: null,
        field: name,
        value: default_value || "",
        object_id: instance,
        content_type,
    };

    const onChangeValue = (e) => {
        onChange({ ...valueField, value: e.target.value });
    };

    return (
        <>
            {label && <Label htmlFor={name} label={label} obrigatorio={is_required} />}
            <InputText
                id={name}
                name={name}
                value={valueField.value}
                onChange={onChangeValue}
                className={classNames({ "p-invalid": error })}
            />
            {error && <small className="p-error">{error}</small>}
        </>
    );
};

export const CustomFieldInputText = memo(Componente);
