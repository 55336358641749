import React, { useMemo } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { TIPO_CHOICE_CONTRATOSERVICO__INTERVALOREC, TIPO_STATUS_CONTRATO_CHOICE } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useEmpresa from "@/hooks/useEmpresa";

export const FiltroAvancadoContratoServico = ({ onConfirm, onCancel, url }) => {
    const { empresaSelecionadaId } = useEmpresa();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            empresa: empresaSelecionadaId,
            numero: "",
            nome_curto: "",
            descricao: "",
            cliente: null,
            data_negociacao: null,
            data_iniciacao: null,
            data_finalizacao: null,
            vendedor: null,
            percentual_comissao: 0,
            primeiro_vencimento: null,
            intervalo_recebimento: null,
            link_documento: "",
            template_rateio: null,
            status: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                numero: Yup.string()
                    .max(12, "Máximo de caracteres atingido: 10")
                    .nullable()
                    .typeError("Informe um 'numero' válido"),
                nome_curto: Yup.string()
                    .max(30, "Máximo de caracteres atingido: 10")
                    .nullable()
                    .typeError("Informe um 'numero' válido"),
                descricao: Yup.string().nullable().typeError("Informe um 'descricao' válido"),
                cliente: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'cliente' válida"),
                data_negociacao: Yup.date().nullable().typeError("Informe uma 'negociação' válida"),
                data_iniciacao: Yup.date().nullable().typeError("Informe uma 'data iniciação' válida"),
                data_finalizacao: Yup.date()
                    .nullable()
                    .when("data_iniciacao", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .nullable()
                            .min(
                                values.data_iniciacao || new Date(),
                                "O campo 'data finalização' não pode ser anterior a inicial"
                            )
                            .typeError("Informe uma 'data finalização' válida"),
                    }),
                vendedor: Yup.number().nullable().typeError("Informe um 'vendedor' válido."),
                percentual_comissao: Yup.number().nullable().typeError("Informe um 'percentual comissão' válido"),
                primeiro_vencimento: Yup.date().nullable().typeError("Informe uma 'primeiro vencimento' válida"),
                intervalo_recebimento: Yup.number()
                    .nullable()
                    .typeError("Informe um 'intervalo de recebimento' válido"),
                template_rateio: Yup.number().nullable().typeError("Informe uma 'template de ratio' válida."),
                link_documento: Yup.string().nullable().typeError("Informe um 'descricao' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });
            const baseUrl = url;
            const params = [];
            const paramsIndicadores = {};
            Object.keys(values).forEach((key) => {
                if (values[key] !== null && values[key] !== "") {
                    params.push(`${key}=${values[key]}`);
                    paramsIndicadores[key] = values[key];
                }
            });
            if (params.length > 0) {
                const _url = baseUrl + "?" + params.join("&");
                if (typeof onConfirm === "function") onConfirm(_url, paramsIndicadores);
            } else {
                if (typeof onConfirm === "function") onConfirm(baseUrl);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const buscarVendedores = useMemo(() => {
        const _ = (data) => {
            const _vendedores = [];
            data.forEach((perfil) => {
                perfil.papeis_vigentes.forEach((papel) => {
                    if (papel.chave?.id === "VND" || papel.chave?.id === "ATD") {
                        _vendedores.push({
                            id: perfil.id,
                            nome: perfil.perfil_pf.nome_completo,
                        });
                    }
                });
            });
            return _vendedores;
        };
        return _;
    }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="numero" label="N° do contrato" />
                    <InputText
                        id="numero"
                        name="numero"
                        onChange={formik.handleChange}
                        value={formik.values.numero}
                        autoComplete="off"
                        maxLength={12}
                        className={classNames({ "p-invalid": formik.errors.numero })}
                    />
                    {formik.errors.numero && <small className="p-error">{formik.errors.numero}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="nome_curto" label="Nome curto" />
                    <InputText
                        id="nome_curto"
                        name="nome_curto"
                        maxLength={30}
                        value={formik.values.nome_curto}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.nome_curto })}
                    />
                    {formik.errors.nome_curto && <small className="p-error">{formik.errors.nome_curto}</small>}
                </div>
                <div className="p-field p-col-12 p-md-8">
                    <Label htmlFor="descricao" label="Descrição do contrato(Titulo)" />
                    <InputText
                        id="descricao"
                        name="descricao"
                        value={formik.values.descricao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.descricao })}
                    />
                    {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="cliente" label="Cliente" />
                    <MakoInputCliente
                        id="cliente"
                        name="cliente"
                        value={formik.values.cliente}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.cliente })}
                    />
                    {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="template_rateio" label="Regra de rateio" />
                    <Dropdown
                        id="template_rateio"
                        name="template_rateio"
                        url={`/financeiro/templates-rateios/?query={id,descricao}`}
                        optionValue="id"
                        optionLabel="descricao"
                        emptyMessage="Não existem registros cadastrados"
                        value={formik.values.template_rateio}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.template_rateio })}
                    />
                    {formik.errors.template_rateio && (
                        <small className="p-error">{formik.errors.template_rateio}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data_negociacao" label="Data negociação" />
                    <MakoCalendar
                        id="data_negociacao"
                        name="data_negociacao"
                        valueCalendar={formik.values.data_negociacao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_negociacao })}
                    />
                    {formik.errors.data_negociacao && (
                        <small className="p-error">{formik.errors.data_negociacao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data_iniciacao" label="Data Inicio" />
                    <MakoCalendar
                        id="data_iniciacao"
                        name="data_iniciacao"
                        valueCalendar={formik.values.data_iniciacao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_iniciacao })}
                    />
                    {formik.errors.data_iniciacao && <small className="p-error">{formik.errors.data_iniciacao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data_finalizacao" label="Previsão encerramento" />
                    <MakoCalendar
                        id="data_finalizacao"
                        name="data_finalizacao"
                        valueCalendar={formik.values.data_finalizacao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_finalizacao })}
                    />
                    {formik.errors.data_finalizacao && (
                        <small className="p-error">{formik.errors.data_finalizacao}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="vendedor" label="Vendedor" />
                    <Dropdown
                        id="vendedor"
                        name="vendedor"
                        placeholder="Selecione"
                        url="/pessoas/perfis/?query={id,perfil_pf,papeis_vigentes}&usuario__isnull=false&limit=200"
                        aposBuscar={buscarVendedores}
                        optionValue="id"
                        optionLabel="nome"
                        filter
                        filterBy="nome"
                        value={formik.values.vendedor}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.vendedor })}
                    />
                    {formik.errors.vendedor && <small className="p-error">{formik.errors.vendedor}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="percentual_comissao" label="% comissão" />
                    <MakoInputPercent
                        id="percentual_comissao"
                        name="percentual_comissao"
                        onValueChange={formik.handleChange}
                        value={formik.values.percentual_comissao}
                        className={classNames({ "p-invalid": formik.errors.percentual_comissao })}
                    />
                    {formik.errors.percentual_comissao && (
                        <small className="p-error">{formik.errors.percentual_comissao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="intervalo_recebimento" label="Intervalo de recebimento do contrato" />
                    <Dropdown
                        id="intervalo_recebimento"
                        name="intervalo_recebimento"
                        placeholder="Selecione"
                        options={TIPO_CHOICE_CONTRATOSERVICO__INTERVALOREC}
                        optionValue="value"
                        optionLabel="label"
                        value={formik.values.intervalo_recebimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.intervalo_recebimento })}
                    />
                    {formik.errors.intervalo_recebimento && (
                        <small className="p-error">{formik.errors.intervalo_recebimento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="primeiro_vencimento" label="1° vencimento" />
                    <MakoCalendar
                        id="primeiro_vencimento"
                        name="primeiro_vencimento"
                        valueCalendar={formik.values.primeiro_vencimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.primeiro_vencimento })}
                    />
                    {formik.errors.primeiro_vencimento && (
                        <small className="p-error">{formik.errors.primeiro_vencimento}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-10">
                    <Label htmlFor="link_documento" label="Link do documento do contrato" />
                    <InputText
                        id="link_documento"
                        name="link_documento"
                        value={formik.values.link_documento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.link_documento })}
                    />
                    {formik.errors.link_documento && <small className="p-error">{formik.errors.link_documento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="status" label="Status" />
                    <Dropdown
                        id="status"
                        name="status"
                        placeholder="Selecione"
                        options={TIPO_STATUS_CONTRATO_CHOICE}
                        optionValue="value"
                        optionLabel="label"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.status })}
                    />
                    {formik.errors.status && <small className="p-error">{formik.errors.status}</small>}
                </div>
            </div>
            <MakoActionsButtons className="p-jc-end">
                <Button type="submit" icon={MAKO_ICONS.FILTRAR} label="Filtrar" />
                <Button
                    type="reset"
                    icon={MAKO_ICONS.LIMPAR_FILTROS}
                    label="Limpar"
                    onClick={formik.resetForm}
                    className="p-button-warning"
                />
                <Button
                    type="reset"
                    label="Cancelar"
                    icon={MAKO_ICONS.CANCEL}
                    onClick={onCancel}
                    className="p-button-danger"
                />
            </MakoActionsButtons>
        </form>
    );
};
