import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { PainelResumoDevolucao } from "./components";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import MakoListagem from "@/components/MakoListagem";
import * as Yup from "yup";
import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import { classNames } from "primereact/utils";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import { gerarFiltrosUrl } from "@/assets/util/util";

const itemEntradaOpcaoTemplate = (option) => {
    if (option) return <span>{`${option?.sku?.codigo} - ${option?.sku?.descricao_reduzida}`}</span>;
};

const itemEntradaSelecionadaTemplate = (option, props) => {
    if (option) return <span>{`${option?.sku?.codigo} - ${option?.sku?.descricao_reduzida}`}</span>;
    return <span>{props?.placeholder}</span>;
};

export const DevolucaoFornecedorFormItens = () => {
    const [quantidadeMaxima, setQuantidadeMaxima] = useState(null);
    const { handleItens, dadosBasicos, editavel, handlePreencherTroca } = useDevolucaoFornecedor();
    const { showError, showSuccess } = useToast();
    const listagemRef = useRef(null);
    const itensEntradaRef = useRef([]);

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            sku: null,
            unidade_medida: null,
            quantidade: 1,
            valor_unitario: 0,
            valor_total: 0,
            largura: 1,
            comprimento: 1,
            altura: 1,
            cubagem: 1,
            numero_volumes_item: 1,
            valor_frete: 0,
            valor_seguro: 0,
            outras_despesas: 0,
            subtotal: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object().required("O campo é obrigatório.").typeError("Informe um 'produto' válido"),
                quantidade: Yup.number()
                    .required("O campo é obrigatório.")
                    .typeError("Informe uma 'quantidade' válida.")
                    .min(0.01, "Informe um valor maior que 0."),
                unidade_medida: Yup.number()
                    .required("O campo é obrigatório.")
                    .typeError("Informe uma 'unidade medida' válida"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleItens(values);

            if (status !== 200 && status !== 201)
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível processar sua requisição.",
                    life: 3000,
                });
            else {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "O item foi cadastrado com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
                resetForm();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.COMPRAS_ENTRADA_DEVOLUCAOFORNECEDOR_INCLUIR]}
                    componente={BotaoDelete}
                    url={"/compras/item-devolucao-fornecedor/"}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    msgToastErroExclusao="O item não pode ser excluído."
                    tooltip="Deletar item"
                    tooltipOptions={{ position: "left" }}
                    disabled={!editavel}
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        handlePreencherTroca(dadosBasicos.id);
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "sku.codigo", header: "Código" },
        { field: "sku.descricao_reduzida", header: "Descrição" },
        { field: "unidade_medida.sigla", header: "UN" },
        { field: "item_entrada", header: "Item Entrada" },
        { field: "quantidade", header: "Quant" },
        { field: "valor_unitario", header: "Unitário", money: true },
        { field: "valor_frete", header: "Frete", money: true },
        { field: "subtotal", header: "Subtotal", money: true },
        { field: "actions", header: "Ações", action: actionBodyTemplate },
    ];

    const formatarUnidades = useCallback(
        (results) => {
            if (results?.length === 1) setFieldValue("unidade_medida", results[0].unidade?.id || results[0].unidade);
            return results.map(({ unidade }) => unidade);
        },
        [setFieldValue]
    );

    const formatarUnidadesItemEntrada = useCallback(
        (results) => {
            itensEntradaRef.current = results;
            if (results?.length === 1)
                setFieldValue("unidade_medida", results[0].unidade_medida?.id || results[0].unidade_medida);
            return results;
        },
        [setFieldValue]
    );

    const calcularSubtotal = useCallback(async () => {
        setFieldValue(
            "subtotal",
            formik.values.quantidade * formik.values.valor_unitario +
                formik.values.outras_despesas +
                formik.values.valor_frete +
                formik.values.valor_seguro
        );
        setFieldValue("valor_total", formik.values.quantidade * formik.values.valor_unitario);
    }, [
        setFieldValue,
        formik.values.quantidade,
        formik.values.valor_unitario,
        formik.values.outras_despesas,
        formik.values.valor_frete,
        formik.values.valor_seguro,
    ]);

    const onChangeItemEntrada = useCallback(
        (e) => {
            const { unidade_medida, valor_unitario, sku, quantidade, largura, altura, comprimento, cubagem } =
                itensEntradaRef.current?.find(({ id }) => id === e.value) || {};

            setFieldValue("largura", largura);
            setFieldValue("altura", altura);
            setFieldValue("comprimento", comprimento);
            setFieldValue("cubagem", cubagem);
            setFieldValue("unidade_medida", unidade_medida?.id);
            setFieldValue("valor_unitario", valor_unitario);
            setFieldValue("sku", sku);
            setFieldValue("quantidade", formik.values.quantidade <= quantidade ? formik.values.quantidade : 1);
            setQuantidadeMaxima(quantidade);
            formik.handleChange(e);
        },
        [formik, setFieldValue]
    );

    const urlUnidadeMedidas = useMemo(() => {
        const BASE_URL = "/produtos/unidades-medida-sku?";
        let filtros = "";
        if (formik.values.item_entrada) {
            const { sku } = itensEntradaRef.current?.find(({ id }) => id === formik.values.item_entrada) || {};
            filtros = gerarFiltrosUrl({ sku__id: sku?.id, tipo_mov_und_medida: "C" });
        } else {
            filtros = gerarFiltrosUrl({ sku__id: formik.values.sku?.id, tipo_mov_und_medida: "C" });
        }
        return `${BASE_URL}${filtros}`;
    }, [formik.values.item_entrada, formik.values.sku?.id]);

    const buscarUnidadesMedidas = useMemo(() => {
        return !!formik.values.item_entrada || !!formik.values.sku?.id;
    }, [formik.values.item_entrada, formik.values.sku?.id]);

    useEffect(() => {
        calcularSubtotal();
    }, [calcularSubtotal]);

    const onChangeDimensao = useCallback(
        (e) => {
            setFieldValue("cubagem", e);
        },
        [setFieldValue]
    );

    return (
        <>
            <PainelResumoDevolucao />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="item_entrada">Item entrada</label>
                        <Dropdown
                            id="item_entrada"
                            name="item_entrada"
                            url={`/compras/itens-entradas/?entrada=${dadosBasicos.entrada}`}
                            optionValue="id"
                            optionLabel="sku.descricao_reduzida"
                            buscar={dadosBasicos.entrada}
                            aposBuscar={formatarUnidadesItemEntrada}
                            valueTemplate={itemEntradaSelecionadaTemplate}
                            itemTemplate={itemEntradaOpcaoTemplate}
                            value={formik.values.item_entrada}
                            onChange={onChangeItemEntrada}
                            className={classNames({ "p-invalid": formik.errors.item_entrada })}
                        />
                        {formik.errors.item_entrada && <small className="p-error">{formik.errors.item_entrada}</small>}
                    </div>
                </div>
                <MakoBuscaSkuPersonalizada
                    skuValue={formik.values.sku}
                    disabledBusca={formik.values.item_entrada}
                    skuChange={(e) => {
                        setFieldValue("sku", e);
                    }}
                    skuError={formik.errors.sku}
                    alturaValue={formik.values.altura}
                    onChangeAltura={(e) => setFieldValue("altura", e)}
                    larguraValue={formik.values.largura}
                    onChangeLargura={(e) => setFieldValue("largura", e)}
                    comprimentoValue={formik.values.comprimento}
                    onChangeComprimento={(e) => setFieldValue("comprimento", e)}
                    dimensaoValue={formik.values.cubagem}
                    onChangeDimensao={onChangeDimensao}
                />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="unidade_medida">Unidade de medida *</label>
                        <Dropdown
                            id="unidade_medida"
                            name="unidade_medida"
                            url={urlUnidadeMedidas}
                            optionValue="id"
                            optionLabel="nome"
                            aposBuscar={formatarUnidades}
                            buscar={buscarUnidadesMedidas}
                            value={formik.values.unidade_medida}
                            onChange={formik.handleChange}
                            disabled={!formik.values.sku?.id}
                            className={classNames({ "p-invalid": formik.errors.unidade_medida })}
                        />
                        {formik.errors.unidade_medida && (
                            <small className="p-error">{formik.errors.unidade_medida}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade">Quantidade</label>
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            value={formik.values.quantidade}
                            permiteFracionario={formik.values.sku?.permite_fracionamento}
                            onValueChange={formik.handleChange}
                            max={quantidadeMaxima}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="numero_volumes_item">Número volumes</label>
                        <MakoInputQuantidadeSku
                            id="numero_volumes_item"
                            name="numero_volumes_item"
                            value={formik.values.numero_volumes_item}
                            tooltip="Número de volumes por item."
                            onValueChange={formik.handleChange}
                            max={quantidadeMaxima}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="vlr-unitario">Unitário</label>
                        <MakoInputMoeda
                            id="vlr-unitario"
                            name="valor_unitario"
                            valueMoeda={formik.values.valor_unitario}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor_frete">Valor frete</label>
                        <MakoInputMoeda
                            id="valor_frete"
                            name="valor_frete"
                            valueMoeda={formik.values.valor_frete}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor_seguro">Valor seguro</label>
                        <MakoInputMoeda
                            id="valor_seguro"
                            name="valor_seguro"
                            valueMoeda={formik.values.valor_seguro}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="outras-despesas">Outros valores</label>
                        <MakoInputMoeda
                            id="outras-despesas"
                            name="outras_despesas"
                            valueMoeda={formik.values.outras_despesas}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="subtotal">Subtotal</label>
                        <MakoInputMoeda id="subtotal" name="subtotal" disabled valueMoeda={formik.values.subtotal} />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-save"
                            label="Gravar item"
                            disabled={!editavel}
                            className="p-mr-2 p-mb-2"
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                urlPesquisa={`/compras/item-devolucao-fornecedor?devolucao_fornecedor=${dadosBasicos?.id}`}
                colunas={colunas}
                configTabela={{ lazy: true, paginator: true }}
            />
        </>
    );
};
