import React from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { InputNumber } from "primereact/inputnumber";

export const ModalNovoServico = ({ onFinish, onClose }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { httpPost } = useHttp();

    const _INITIAL_VALUES = {
        servico: null,
        descricao: "",
        cnae: "",
        municipio: null,
        aliquota: 0,
        ativo: true,
    };

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: _INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                municipio: Yup.object().required("O campo 'município' é obrigatório."),
                servico: Yup.object()
                    .required("O campo 'serviço' é obrigatório.")
                    .typeError("Informe um serviço válido."),
                descricao: Yup.string().max(100).required("O campo 'descrição' é obrigatório."),
                cnae: Yup.string().max(10).nullable().default(""),
                aliquota: Yup.number().required("O campo 'alíquota' é obrigatório."),
            });

            await formSchema.validate(values, { abortEarly: false });

            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Serviço do Município cadastrado com sucesso!",
                        life: 1500,
                    });
                    if (typeof onFinish === "function") onFinish();
                },
            };

            showLoading();
            await httpPost(
                {
                    url: "/servicos/servicos-municipio/",
                    body: {
                        ...values,
                        municipio: values.municipio.id,
                        servico: values.servico.id,
                    },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const autoCompleteCidadeTemplate = (municipio) => {
        return `${municipio.codigo} - ${municipio.nome}`;
    };

    const autoCompleteServicoTemplate = (serv) => {
        return `${serv.codigo} - ${serv.descricao}`;
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="servico">Serviço *</label>
                    <MakoAutoComplete
                        id="servico"
                        name="servico"
                        placeholder="Busque pelo descrição ou código do município ... (min 4 caracteres)"
                        minCaracteresBusca={3}
                        value={formik.values.servico}
                        onChange={(e) => {
                            setFieldValue("codigo", e.value.codigo);
                            setFieldValue("descricao", e.value.descricao);
                            formik.handleChange(e);
                        }}
                        itemTemplate={autoCompleteServicoTemplate}
                        field="descricao"
                        urlSearch={"/servicos/servicos?query={id, codigo, descricao}&search="}
                        className={classNames({ "p-invalid": formik.errors.servico })}
                    />
                    {formik.errors.servico && <small className="p-error">{formik.errors.servico}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="codigo">Código município *</label>
                    <InputText
                        id="codigo"
                        name="codigo"
                        value={formik.values.codigo}
                        tooltip="Código de tributação municipal do ISSQN."
                        tooltipOptions={{ position: "bottom" }}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.codigo })}
                    />
                    {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-9">
                    <label htmlFor="descricao">Descrição município *</label>
                    <InputText
                        id="descricao"
                        name="descricao"
                        value={formik.values.descricao}
                        tooltip="Descrição do serviço de acordo com o município."
                        tooltipOptions={{ position: "bottom" }}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.descricao })}
                    />
                    {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="municipio">Município *</label>
                    <MakoAutoComplete
                        id="municipio"
                        name="municipio"
                        placeholder="Busque pelo nome ou código do município ... (min 4 caracteres)"
                        minCaracteresBusca={3}
                        value={formik.values.municipio}
                        onChange={formik.handleChange}
                        itemTemplate={autoCompleteCidadeTemplate}
                        field="nome"
                        urlSearch={"/pessoas/cidades?query={id, codigo, nome}&search="}
                        className={classNames({ "p-invalid": formik.errors.municipio })}
                    />
                    {formik.errors.municipio && <small className="p-error">{formik.errors.municipio}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="cnae">CNAE</label>
                    <InputText
                        id="cnae"
                        name="cnae"
                        keyfilter={/^[0-9.]/}
                        value={formik.values.cnae}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.cnae })}
                    />
                    {formik.errors.cnae && <small className="p-error">{formik.errors.cnae}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="aliquota">Alíquota redução *</label>
                    <InputNumber
                        id="aliquota"
                        name="aliquota"
                        prefix="%"
                        min={0}
                        max={100}
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={formik.values.aliquota}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.aliquota })}
                    />
                    {formik.errors.aliquota && <small className="p-error">{formik.errors.aliquota}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field-checkbox p-col-12 p-md-3">
                    <Checkbox id="ativo" name="ativo" checked={formik.values.ativo} onChange={formik.handleChange} />
                    <label htmlFor="ativo">Ativo?</label>
                </div>
            </div>
            <MakoActionsButtons>
                <Button
                    icon={MAKO_ICONS.NOVO}
                    label="Inserir"
                    type="submit"
                    className="p-button-info"
                    loading={loading}
                />
                <Button
                    label="Cancelar"
                    type="button"
                    icon={MAKO_ICONS.CANCEL}
                    className="p-button-danger"
                    onClick={onClose}
                    loading={loading}
                />
            </MakoActionsButtons>
        </form>
    );
};
