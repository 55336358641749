import React, { useCallback, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";

import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import { TaxaBancaria } from "./taxa-bancaria";
import { Form } from "../features";

export const FinanceiroContaBancariaFormBase = ({ contaBancaria }) => {
    const [conta, setConta] = useState(contaBancaria);
    const { state } = useLocation();

    const titleTemplate = useMemo(() => {
        return !conta?.id ? "Cadastro de conta bancária" : `Manutenção de conta bancária`;
    }, [conta]);

    const aposSalvarConta = useCallback((value) => {
        console.log(value);
        setConta(value);
    }, []);

    const tabs = [
        {
            header: "Dados Básicos",
            icon: "pi pi-fw pi-bookmark",
            component: <Form.DadosBasicos contaBancaria={conta} aposSalvar={aposSalvarConta} />,
        },
        {
            header: "Taxa de cartão",
            icon: "pi pi-fw pi-flag",
            component: <TaxaBancaria conta={conta} />,
            visible: !!conta && !!conta?.recebe_via_cartao,
        },
    ];

    return (
        <MakoMultiTabs
            tabs={tabs}
            title={titleTemplate}
            propsButtonProx={{ disabled: !conta }}
            ocultarButtonFinalizar
            propsButtonCancelar={{ to: "/financeiro/cadastros/contas-bancarias", label: "Fechar" }}
            tabAtiva={state?.tabAtiva ? state?.tabAtiva : 0}
        />
    );
};
