import React, { forwardRef, useImperativeHandle, useState } from "react";

import { useFormik } from "formik";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";

import { Dropdown } from "@/components/Dropdown";

import {
    SIM_NAO_BOOLEAN,
    TIPO_CHOICE_LOTE_SERIAL,
    TIPO_CHOICE_SKU_CONTROLESALDO,
    TIPO_ORIGEM_NF_CHOICE,
} from "@/assets/constants/constants";

import useToast from "@/hooks/useToast";
import { Dialog } from "primereact/dialog";

const INITIAL_VALUES = {
    descricao_reduzida__icontains: "",
    descricao_complementar__contains: "",
    codigo__icontains: "",
    ncm: "",
    unidade_padrao__id: null,
    categoriasku__categoria: null,
    ativo: null,
    origem: null,
    permite_fracionamento: null,
    e_commerce: null,
    composto: null,
    formacao_preco: null,
    tipo_controle_saldo: null,
    crescente: null,
    decrescente: null,
    lote_serial: null,
};
const urlSkuLimpa = `/produtos/sku?query={id,item,descricao_derivada,descricao_reduzida,unidade_padrao,ativo,codigo}&`;

export const Modal = ({ filtros, setFiltro, removerFiltro, setUrl }, ref) => {
    const [visible, setVisible] = useState(false);

    const { showError } = useToast();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: filtros ? { ...INITIAL_VALUES, ...filtros } : INITIAL_VALUES,
        onSubmit: handleSearch,
        enableReinitialize: true,
    });

    async function handleSearch(values) {
        try {
            const { crescente, decrescente, ...filtros } = values;
            const params = [];
            let _filtros = { ordering: crescente ? crescente : decrescente ? `-${decrescente}` : "" };
            Object.keys(filtros).forEach((key) => {
                if (filtros[key]) {
                    _filtros[key] = filtros[key];
                    params.push(`${key}=${filtros[key]}`);
                }
            });
            const ordering = crescente ? `&ordering=${crescente}` : decrescente ? `&ordering=-${decrescente}` : "";
            const url = `${urlSkuLimpa}${params.join("&")}${ordering}`;
            setFiltro(_filtros);
            setUrl(url);
            setVisible(false);
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    }

    const limparFiltrosForm = () => {
        formik.resetForm();
    };

    const limparFiltro = () => {
        removerFiltro();
        limparFiltrosForm();
    };

    const template = (options) => {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
                <span className={titleClassName}>Ordem</span>
            </div>
        );
    };

    const selecionaOrdem = (e, field = "crescente") => {
        const keyToClean = field === "crescente" ? "decrescente" : "crescente";
        setFieldValue(keyToClean, null);
        formik.handleChange(e);
    };

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal, limparFiltro }));

    return (
        <Dialog
            header="Filtrar Produtos Desagrupados"
            visible={visible}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw" }}
            onHide={() => setVisible(false)}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="codigo__icontains">Codigo</label>
                        <InputText
                            id="codigo__icontains"
                            name="codigo__icontains"
                            placeholder="Digite um código"
                            value={formik.values.codigo__icontains}
                            onInput={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="categoriasku__categoria">Categoria</label>
                        <Dropdown
                            id="categoriasku__categoria"
                            name="categoriasku__categoria"
                            placeholder="Qualquer"
                            url={"/produtos/categorias?query={id, nome}"}
                            optionLabel="nome"
                            optionValue="id"
                            showClear
                            filter
                            filterBy="nome"
                            value={formik.values.categoriasku__categoria}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="descricao_reduzida__icontains">Descrição reduzida</label>
                        <InputText
                            id="descricao_reduzida__icontains"
                            name="descricao_reduzida__icontains"
                            placeholder="Digite uma descrição reduzida"
                            value={formik.values.descricao_reduzida__icontains}
                            onInput={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="ncm">Código NCM</label>
                        <InputText
                            id="ncm"
                            name="ncm"
                            placeholder="Digite um código de ncm"
                            value={formik.values.ncm}
                            onInput={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="unidade_padrao__id">Unidade de medida</label>
                        <Dropdown
                            id="unidade_padrao__id"
                            name="unidade_padrao__id"
                            placeholder="Selecione uma unidade"
                            url={"/produtos/unidades-medida?query={id, nome}"}
                            optionLabel="nome"
                            optionValue="id"
                            showClear
                            filter
                            filterBy="nome"
                            value={formik.values.unidade_padrao__id}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="ativo">Ativo</label>
                        <Dropdown
                            id="ativo"
                            name="ativo"
                            placeholder="Todos"
                            options={[
                                { value: null, label: "Todos" },
                                { value: "true", label: "Ativos" },
                                { value: "false", label: "Desativados" },
                            ]}
                            optionLabel="label"
                            optionValue="value"
                            showClear
                            value={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="descricao_complementar__contains">Descrição complementar</label>
                        <InputText
                            id="descricao_complementar__contains"
                            name="descricao_complementar__contains"
                            placeholder="Digite uma descrição complementar"
                            value={formik.values.descricao_complementar__contains}
                            onInput={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="origem">Origem</label>
                        <Dropdown
                            id="origem"
                            name="origem"
                            options={TIPO_ORIGEM_NF_CHOICE}
                            optionLabel="label"
                            optionValue="id"
                            showClear
                            filter
                            placeholder="Selecione..."
                            filterBy="nome"
                            value={formik.values.origem}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="permite_fracionamento">Permite fracionamento</label>
                        <Dropdown
                            id="permite_fracionamento"
                            name="permite_fracionamento"
                            options={SIM_NAO_BOOLEAN}
                            optionLabel="label"
                            optionValue="id"
                            placeholder="Selecione..."
                            showClear
                            value={formik.values.permite_fracionamento}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="e_commerce">E-commerce</label>
                        <Dropdown
                            id="e_commerce"
                            name="e_commerce"
                            options={SIM_NAO_BOOLEAN}
                            optionLabel="label"
                            optionValue="id"
                            placeholder="Selecione..."
                            showClear
                            value={formik.values.e_commerce}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="composto">Composto</label>
                        <Dropdown
                            id="composto"
                            name="composto"
                            options={SIM_NAO_BOOLEAN}
                            optionLabel="label"
                            optionValue="id"
                            placeholder="Selecione..."
                            showClear
                            value={formik.values.composto}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="formacao_preco">Formação de preço</label>
                        <Dropdown
                            id="formacao_preco"
                            name="formacao_preco"
                            url={"/produtos/formacoes-precos?limit=300"}
                            optionValue="id"
                            optionLabel="descricao"
                            placeholder="Selecione..."
                            showClear
                            value={formik.values.formacao_preco}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="tipo_controle_saldo">Controle de saldo</label>
                        <Dropdown
                            id="tipo_controle_saldo"
                            name="tipo_controle_saldo"
                            options={TIPO_CHOICE_SKU_CONTROLESALDO}
                            optionValue="value"
                            optionLabel="label"
                            placeholder="Selecione..."
                            showClear
                            value={formik.values.tipo_controle_saldo}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="lote_serial">Tipo de lote</label>
                        <Dropdown
                            id="lote_serial"
                            name="lote_serial"
                            options={TIPO_CHOICE_LOTE_SERIAL}
                            optionValue="value"
                            optionLabel="label"
                            placeholder="Selecione..."
                            showClear
                            value={formik.values.lote_serial}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <Panel headerTemplate={template} toggleable collapsed={true}>
                    <b>Ordenar</b>
                    <br />
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="crescente">Crescente</label>
                            <Dropdown
                                id="crescente"
                                name="crescente"
                                placeholder="Selecione um campo"
                                options={[
                                    { field: "id", header: "ID" },
                                    { field: "codigo", header: "Código" },
                                    { field: "descricao_derivada", header: "Descrição" },
                                ]}
                                optionLabel="header"
                                optionValue="field"
                                value={formik.values.crescente}
                                onChange={selecionaOrdem}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="decrescente">Decrescente</label>
                            <Dropdown
                                id="decrescente"
                                name="decrescente"
                                placeholder="Selecione um campo"
                                options={[
                                    { field: "id", header: "ID" },
                                    { field: "codigo", header: "Código" },
                                    { field: "descricao_derivada", header: "Descrição" },
                                ]}
                                optionLabel="header"
                                optionValue="field"
                                value={formik.values.decrescente}
                                onChange={(e) => selecionaOrdem(e, "decrescente")}
                            />
                        </div>
                    </div>
                </Panel>
                <div className="p-grid p-justify-end p-mt-4">
                    <Button
                        type="submit"
                        icon="pi pi-filter"
                        label="Filtrar"
                        className="p-mr-2"
                        onClick={formik.handleSubmit}
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={limparFiltro}
                        className="p-button-warning p-mr-2"
                    />
                    <Button
                        type="button"
                        label="Cancelar"
                        onClick={() => setVisible(false)}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalFiltroSku = forwardRef(Modal);
