import { parseISO, format as formatDateFns } from "date-fns";
import { ptBR } from "date-fns/locale";

export function parseData(date) {
    if (!date) return null;
    if (date instanceof Date) return date;
    return parseISO(date);
}

function format(date, fformat) {
    const formattedDate = formatDateFns(date, fformat, { locale: ptBR });
    return formattedDate;
}

export function dataToStr(date, fformat) {
    if (date) {
        if (typeof date === "string") {
            date = parseData(date);
        }
        return format(date, fformat);
    }
    return null;
}

export function horaToStr(hora, fformat) {
    if (hora) {
        if (typeof hora === "string") {
            hora = parseData(`1970-01-01T${hora}`);
        }
        return format(hora, fformat);
    }
    return null;
}

export const isDate = (date) => {
    const regexDateTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}(Z|(\+|-)\d{2}:\d{2})?$/;
    const datetime = regexDateTime.test(date);
    if (datetime) return true;
    const regexDate = /^\d{4}-\d{2}-\d{2}?$/;
    return regexDate.test(date);
};
