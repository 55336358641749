import React, { useImperativeHandle, useMemo, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { Slider } from "primereact/slider";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { axiosPost } from "@/services/http";
import useVenda from "@/hooks/useVenda";
import useToast from "@/hooks/useToast";
import { parseNumber } from "@/assets/helpers/number";

const Modal = ({ taxas, opcoesParcelas, onConfirmar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [simular, setSimular] = useState(false);
    const [aplicar, setAplicar] = useState(false);
    const [simulacao, setSimulacao] = useState(null);
    const [realizaCalculo, setRealizaCalculo] = useState(false);
    const { dadosBasicos, vendedor } = useVenda();
    const { showWarning, showError } = useToast();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            valor_original: 0,
            valor_frete: 0,
            valor_para_parcelar: 0,
            quantidade_parcelas: null,
            taxa: taxas?.alvo,
            entrada: 0,
            valor_encargos: 0,
            valor_parcela: 0,
            valor_total: 0,
        },
    });

    const abrirModal = (valorOriginal = 0, valorEntrada = 0, valorFrete = 0) => {
        setFieldValue("valor_original", valorOriginal);
        setFieldValue("valor_frete", valorFrete);
        setFieldValue("entrada", valorEntrada);
        setFieldValue("valor_para_parcelar", valorOriginal);
        setFieldValue("taxa", taxas?.alvo);
        setRealizaCalculo(true);
        setVisible(true);
    };

    const fecharModal = () => {
        setRealizaCalculo(false);
        formik.resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    async function simularEncargos(values, modo = "taxa") {
        if (!realizaCalculo) return;
        const { quantidade_parcelas, taxa, valor_parcela, valor_para_parcelar, entrada, valor_frete } = values;
        if (!quantidade_parcelas) {
            showWarning({
                summary: "Aviso!",
                detail: "A quantidade de parcelas precisa ser informada.",
                life: 2500,
            });
            return;
        }
        const valorFreteRestante = Math.max(0, valor_frete - entrada);
        const valorEntradaSemFrete = Math.max(0, entrada - valor_frete);
        const body = {
            quantidade_parcelas: quantidade_parcelas,
            entrada: valorEntradaSemFrete,
        };
        if (modo === "parcela") body.taxa = taxa;
        else {
            if (valor_parcela * quantidade_parcelas < valor_para_parcelar) {
                showWarning({
                    summary: "Aviso!",
                    detail: "O valor de parcela é insuficiente para realizar o cálculo da taxa.",
                    life: 2500,
                });
                return;
            } else {
                const valorParcela = valor_parcela - valorFreteRestante / quantidade_parcelas;
                body.valor_parcela = valorParcela;
            }
        }
        setSimular(true);
        const { status, data } = await axiosPost(`/vendas/vendas/${dadosBasicos?.id}/simular-parcelas/`, body);
        setSimular(false);
        if (status === 200) {
            const { total_encargos, total_pedido, valor_parcela, taxa: taxa_calculada } = data;
            if (taxa_calculada > taxas?.max) {
                await simularEncargos({ ...values, taxa: taxas?.max }, "parcela");
            } else if (taxa_calculada < taxa?.min) {
                await simularEncargos({ ...values, taxa: taxas?.min }, "parcela");
            } else {
                setSimulacao({ ...data, quantidade_parcelas: body.quantidade_parcelas, entrada });
                setFieldValue("valor_encargos", total_encargos);
                setFieldValue("taxa", taxa_calculada);
                const valorParcela = valor_parcela + valorFreteRestante / quantidade_parcelas;
                setFieldValue("valor_parcela", valorParcela);
                setFieldValue("valor_total", total_pedido + valorFreteRestante);
                setAplicar(true);
            }
        } else if (status < 500) {
            showWarning({
                summary: "Aviso.",
                detail: data?.msg || "Não foi possível simular os encargos da venda.",
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Não foi possível simular os encargos da venda.",
                life: 3000,
            });
        }
    }

    const styleLabelTaxa = useMemo(() => {
        const style = { display: "flex", justifyContent: "space-between", color: "#377D1E" };
        const maxDescontoPermitido = taxas?.alvo - (taxas?.alvo * parseNumber(vendedor.max_desconto_encargos)) / 100;
        if (formik.values.taxa > taxas?.alvo) style.color = "#2745AE";
        else if (formik.values.taxa < taxas?.alvo && formik.values.taxa >= maxDescontoPermitido)
            style.color = "#FBC02D";
        else if (formik.values.taxa < taxas?.alvo) style.color = "#d32f2f";
        return style;
    }, [formik.values.taxa, vendedor, taxas]);

    const footer = (
        <div>
            <Button
                label="Calcular parcela"
                icon="pi pi-calculator"
                severity="help"
                loading={simular}
                onClick={() => simularEncargos(formik.values, "parcela")}
            />
            <Button
                label="Calcular taxa"
                icon="pi pi-calculator"
                severity="help"
                loading={simular}
                onClick={() => simularEncargos(formik.values)}
            />
            <Button
                label="Aplicar"
                icon="pi pi-check"
                disabled={!aplicar}
                onClick={() => {
                    if (typeof onConfirmar === "function") {
                        onConfirmar(simulacao);
                        fecharModal();
                    }
                }}
            />
        </div>
    );

    const onChangeValue = (field, value) => {
        setAplicar(false);
        setFieldValue(field, value);
    };

    return (
        <Dialog
            header="Simulador de parcelamento com juros"
            visible={visible}
            footer={footer}
            style={{ width: "50vw" }}
            onHide={fecharModal}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="valor-frete">Valor do frete</label>
                        <MakoInputMoeda
                            id="valor-frete"
                            name="valor_frete"
                            disabled
                            valueMoeda={formik.values.valor_frete}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="valor-entrada">Valor recebido / entrada</label>
                        <MakoInputMoeda
                            id="valor-entrada"
                            name="entrada"
                            valueMoeda={formik.values.entrada}
                            onChangeMoeda={(e) => {
                                onChangeValue("entrada", e.value);
                                const valorEntradaSemFrete = Math.max(0, e.value - formik.values.valor_frete);
                                const valorParaParcelar = formik.values.valor_original - valorEntradaSemFrete;
                                setFieldValue("valor_para_parcelar", valorParaParcelar);
                            }}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="valor-para-parcelar">Valor para parcelar</label>
                        <MakoInputMoeda
                            id="valor-para-parcelar"
                            name="valor_para_parcelar"
                            disabled
                            valueMoeda={formik.values.valor_para_parcelar}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="qtd-parcelas">Quantidade de parcelas</label>
                        <Dropdown
                            id="qtd-parcelas"
                            name="quantidade_parcelas"
                            options={opcoesParcelas}
                            value={formik.values.quantidade_parcelas}
                            onChange={(e) => onChangeValue("quantidade_parcelas", e.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="taxa" style={styleLabelTaxa}>
                            <span>{`Mín: ${taxas?.min}`}</span> <strong>{`Alvo: ${taxas?.alvo}`}</strong>{" "}
                            <span>{`Máx: ${taxas?.max}`}</span>
                        </label>
                        <MakoInputPercent
                            id="taxa"
                            name="taxa"
                            min={taxas?.min}
                            max={taxas?.max}
                            casasDecimais={3}
                            value={formik.values.taxa}
                            onValueChange={(e) => onChangeValue("taxa", e.value)}
                        />
                        <Slider
                            id="taxa-slider"
                            name="taxa"
                            min={taxas?.min}
                            max={taxas?.max}
                            step={0.01}
                            value={formik.values.taxa}
                            onChange={(e) => onChangeValue("taxa", e.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="valor-encargos">Valor dos encargos</label>
                        <MakoInputMoeda
                            id="valor-encargos"
                            name="valor_encargos"
                            disabled
                            valueMoeda={formik.values.valor_encargos}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="valor-parcela">Valor da parcela</label>
                        <MakoInputMoeda
                            id="valor-parcela"
                            name="valor_parcela"
                            valueMoeda={formik.values.valor_parcela}
                            onChangeMoeda={(e) => onChangeValue("valor_parcela", e.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="valor-total">Valor total</label>
                        <MakoInputMoeda
                            id="valor-total"
                            name="valor_total"
                            disabled
                            valueMoeda={formik.values.valor_total}
                        />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalSimuladorParcelas = forwardRef(Modal);
