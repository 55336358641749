import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Dialog } from "primereact/dialog";

import { MakoListagem } from "@/components/MakoListagem";
import { PATHS_VALIDACAO } from "@/assets/constants/pathsValidacao";

const Modal = ({ context }, ref) => {
    const [visible, setVisible] = useState(false);
    const [dados, setDados] = useState([]);

    function montarMensagem(path) {
        let root = null;
        let field = { ...PATHS_VALIDACAO };
        for (const p of path) {
            if (!root) root = p;
            if (p && field[p]) {
                field = field[p];
            } else {
                field = `Desconhecido (${p})`;
                break;
            }
        }
        return `${PATHS_VALIDACAO[root]?.root} - ${field}`;
    }

    function tratarErros(erros, path = []) {
        let tratativas = [];
        for (const [k, v] of Object.entries(erros)) {
            if (context && !path.includes(context)) path.push(context);
            if (!path.includes(k) && !["create", "update", "remove"].includes(k)) {
                path.push(k);
            }
            if (erros[k] instanceof Object && !(erros[k] instanceof Array)) {
                const _tratativas = tratarErros(erros[k], path);
                tratativas = [...tratativas, ..._tratativas];
            } else if (erros[k] instanceof Array) {
                for (const err of v) {
                    if (typeof err === "string") {
                        const header = montarMensagem(path);
                        let message = err;
                        if (tratativas[header]) {
                            const aux = tratativas[header];
                            if (typeof aux === "string") {
                                message = [aux, v[0]];
                            } else {
                                message.push(v[0]);
                            }
                        }
                        tratativas.push({
                            header,
                            message: typeof message === "string" ? message : message.join(";"),
                        });
                        path.length = 0;
                    } else {
                        const _tratativas = tratarErros(err, path);
                        tratativas = [...tratativas, ..._tratativas];
                    }
                }
            }
        }
        return tratativas;
    }

    const abrirModal = (erros) => {
        if (erros) setDados(tratarErros(erros));
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const colunas = [
        { field: "header", header: "Origem" },
        { field: "message", header: "Inconsistência" },
    ];

    return (
        <Dialog
            visible={visible}
            header="Inconsistências de validação do cadastro"
            style={{ width: "60vw" }}
            onHide={() => setVisible(false)}
        >
            <MakoListagem colunas={colunas} dadosLocal={dados} />
        </Dialog>
    );
};

export const ModalErrosValidacao = forwardRef(Modal);
