import React from "react";
import { BlockUI as BlockUIPR } from "primereact/blockui";

export const BlockUI = ({ children, blocked, loading, template }) => {
    return (
        <BlockUIPR
            blocked={blocked || loading}
            template={loading ? <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }} /> : template}
        >
            {children}
        </BlockUIPR>
    );
};
