import { MAKO_COOKIES } from "@/assets/constants/cookies";
import { useCookies } from "react-cookie";

const useCookiePermitir = () => {
    const [cookies, setCookie, removeCookie] = useCookies([MAKO_COOKIES.PERMISSAO_COOKIES], false);

    const updateCookie = (value) => {
        setCookie(MAKO_COOKIES.PERMISSAO_COOKIES, value);
    };

    return [cookies[MAKO_COOKIES.PERMISSAO_COOKIES], updateCookie, removeCookie];
};

export default useCookiePermitir;
