import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "@/components/Dropdown";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

export const MakoDropdownVersaoRelatorio = ({ chave, ...rest }) => {
    const [versoes, setVersoes] = useState([]);
    const { loading, showLoading, hideLoading } = useLoadingLocal();
    const { httpGet } = useHttp();

    const buscarVersoesRelatorios = useCallback(async () => {
        if (chave) {
            const handlers = {
                200: ({ data }) => {
                    setVersoes(data.results);
                },
            };

            showLoading();
            await httpGet({ url: `/relatorios/relatorios-versao/?relatorio__chave=${chave}` }, handlers);
            hideLoading();
        }
    }, [showLoading, hideLoading, chave, httpGet]);

    useEffect(() => {
        buscarVersoesRelatorios();
    }, [buscarVersoesRelatorios]);

    return (
        <>
            <Dropdown
                options={versoes}
                optionValue="versao"
                optionLabel="descricao"
                placeholder="Selecione"
                loading={loading}
                disabled={!!!chave || versoes.length <= 1}
                {...rest}
            />
            {!!!chave && <small className="p-error">Falta informar a chave do relatório</small>}
        </>
    );
};
