import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { formatarCasasDecimais, parseNumberToMoneyHTML } from "@/assets/util/util";
import useProduto from "@/hooks/useProduto";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import classNames from "classnames";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import * as pd from "@/assets/util/persistenciaDjango";
import { Tag } from "primereact/tag";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import { Menu } from "primereact/menu";

const PrecosForm = () => {
    const [inserirPreco, setInserirPreco] = useState(false);
    const [produto, setProduto] = useState(null);
    const [preco, setPreco] = useState(null);
    const { handleInfo, dadosBasicos } = useProduto();
    const { showSuccess } = useToast();
    const menu = useRef(null);
    const listagemRef = useRef(null);

    const { setValues, resetForm, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            preco_base: null,
            preco_minimo: 0,
            preco_calculado: 0,
            custo_base: 0,
            ultimo_calculo: null,
            formacao_origem: dadosBasicos.formacao_preco || null,
            vigencia: null,
            centro_estocagem: null,
            tag_cliente: null,
            qtd_minima_tag: 1,
            status: "novo",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                preco_base: Yup.number()
                    .required("O campo 'preço base' é obrigatório.")
                    .typeError("Informe um 'preço' válido."),
                vigencia: Yup.number()
                    .required("O campo 'vigência' é obrigatório.")
                    .typeError("Informe uma 'vigência' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleInfo(
                "/produtos/precos-sku/",
                { ...values, sku: values.sku?.id || produto },
                values.id ? pd.OP_CRUD_DJANGO.editar : pd.OP_CRUD_DJANGO.novo
            );

            if (status === 200 || status === 201) {
                esconderPreco();
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const editarPreco = (preco) => {
        setValues({ ...preco, vigencia: preco.vigencia.id, formacao_origem: preco.formacao_origem?.id || null });
        setInserirPreco(true);
    };

    const handleSituacao = async (situacao) => {
        const { status } = await handleInfo(
            "/produtos/precos-sku/",
            { id: preco.id, ativo: situacao },
            pd.OP_CRUD_DJANGO.editar
        );
        if (status === 200) listagemRef.current?.buscarDados();
    };

    const opcoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Editar",
                    icon: "pi pi-pencil",
                    disabled: !preco?.ativo,
                    command: () => editarPreco(preco),
                },
                {
                    label: "Ativar",
                    icon: "pi pi-chevron-circle-down",
                    disabled: preco?.ativo,
                    command: () => handleSituacao(true),
                },
                {
                    label: "Desativar",
                    icon: "pi pi-times-circle",
                    disabled: !preco?.ativo,
                    command: () => handleSituacao(false),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={BotaoDelete}
                    url="/produtos/precos-sku/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente remover o preço?</span>}
                    classNames="p-mb-1"
                    tooltip="Deletar"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O preço não pode ser removido."
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Preço removido com sucesso.",
                            life: 3000,
                        });
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    model={opcoes}
                    componente={Button}
                    className="p-button-rounded p-button-info p-ml-2 p-mb-1"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    icon="pi pi-cog"
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setPreco(rowData);
                    }}
                />
            </div>
        );
    };

    const verificaItens = (results) => {
        if (results.length === 1) setProduto(results[0].id);
        return results;
    };

    const itemTemplate = (sku) => {
        return `${sku.codigo} - ${sku.descricao_complementar}`;
    };

    const valueTemplate = (sku) => {
        if (sku) return `${sku.codigo} - ${sku.descricao_complementar}`;
        return "Selecione...";
    };

    const cabecalhoTabela = (
        <>
            <Dropdown
                id="produto"
                name="produto"
                url={`/produtos/sku?limit=1000&item__id=${dadosBasicos.id}`}
                style={{ width: "300px", paddingLeft: "2%" }}
                placeholder="Selecione um produto"
                filter
                filterBy="id,codigo,descricao_complementar"
                itemTemplate={itemTemplate}
                valueTemplate={valueTemplate}
                optionValue="id"
                optionLabel="descricao_complementar"
                aposBuscar={verificaItens}
                value={produto}
                showClear={false}
                onChange={(e) => setProduto(e.target.value)}
                className="p-mr-2"
            />
            <Button
                label="Inserir"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setInserirPreco(true)}
                disabled={!produto}
            />
        </>
    );

    const statusBodyTemplate = (status) => {
        if (status) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const colunas = [
        { field: "sku.codigo", header: "Cód. Produto", style: { minWidth: "5%" } },
        { field: "sku.descricao_reduzida", header: "Descrição produto", style: { minWidth: "20%" } },
        {
            field: "preco_base",
            header: "Preço base",
            style: { width: "15%" },
            action: (e) => parseNumberToMoneyHTML(e.preco_base),
        },
        {
            field: "qtd_minima_tag",
            header: "Quantidade mínima",
            action: ({ qtd_minima_tag }) => formatarCasasDecimais(qtd_minima_tag),
        },
        {
            field: "ativo",
            header: "Situação",
            action: (e) => statusBodyTemplate(e.ativo),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "10%" },
        },
    ];

    const esconderPreco = () => {
        setInserirPreco(false);
        resetForm();
    };

    return (
        <>
            <Menu model={opcoes} popup ref={menu} id="popup_menu" />
            <MakoListagem
                ref={listagemRef}
                urlPesquisa={`/produtos/precos-sku?sku__id=${produto}`}
                fazerBusca={!!produto}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                configTabela={{ paginator: true, lazy: true }}
            />
            <Dialog
                header={!formik.values.id ? "Inserir preço" : "Editar preço"}
                visible={inserirPreco}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", display: "block" }}
                onHide={() => esconderPreco()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="formacao_origem">Formação de preço</label>
                            <Dropdown
                                id="formacao_origem"
                                name="formacao_origem"
                                url="/produtos/formacoes-precos?limit=1000"
                                optionValue="id"
                                optionLabel="descricao"
                                placeholder="Selecione uma formação de preço"
                                emptyMessage="Não existem formações"
                                value={formik.values.formacao_origem}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="custo_base">Custo base *</label>
                            <InputNumber
                                id="custo_base"
                                name="custo_base"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                min={0}
                                value={formik.values.custo_base}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.custo_base })}
                            />
                            {formik.errors.custo_base && <small className="p-error">{formik.errors.custo_base}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="preco_calculado">Preço calculado *</label>
                            <InputNumber
                                id="preco_calculado"
                                name="preco_calculado"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                min={0}
                                disabled
                                value={formik.values.preco_calculado}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="ultimo_calculo">Último cálculo</label>
                            <MakoCalendarTime
                                id="ultimo_calculo"
                                name="ultimo_calculo"
                                value={formik.values.ultimo_calculo}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="preco_base">Preço base *</label>
                            <InputNumber
                                id="preco_base"
                                name="preco_base"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                min={0}
                                value={formik.values.preco_base}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.preco_base })}
                            />
                            {formik.errors.preco_base && <small className="p-error">{formik.errors.preco_base}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="margem">Margem</label>
                            <InputNumber
                                id="margem"
                                name="margem"
                                mode="decimal"
                                prefix="%"
                                locale="pt-BR"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                min={0}
                                value={
                                    formik.values.preco_base && formik.values.custo_base
                                        ? (formik.values.preco_base / formik.values.custo_base - 1) * 100
                                        : 0
                                }
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="qtd_minima_tag">Quantidade mínima</label>
                            <InputNumber
                                id="qtd_minima_tag"
                                name="qtd_minima_tag"
                                min={1}
                                locale="pt-BR"
                                showButtons
                                onValueChange={formik.handleChange}
                                value={formik.values.qtd_minima_tag}
                                className={classNames({ "p-invalid": formik.errors.qtd_minima_tag })}
                                autoComplete="off"
                                autoFocus
                            />
                            {formik.errors.qtd_minima_tag && (
                                <small className="p-error">{formik.errors.qtd_minima_tag}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="preco_minimo">Preço mínimo</label>
                            <InputNumber
                                id="preco_minimo"
                                name="preco_minimo"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                min={0}
                                value={formik.values.preco_minimo}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.preco_minimo })}
                            />
                            {formik.errors.preco_minimo && (
                                <small className="p-error">{formik.errors.preco_minimo}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="tag_cliente">Tag de cliente</label>
                            <Dropdown
                                id="tag_cliente"
                                name="tag_cliente"
                                url="/pessoas/tags-perfis/"
                                optionValue="id"
                                optionLabel="descricao"
                                filter
                                filterBy="descricao"
                                showFilterClear
                                showClear
                                placeholder="Selecione"
                                value={formik.values.tag_cliente}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.tag_cliente && (
                                <small className="p-error">{formik.errors.tag_cliente}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="vigencia">Vigência *</label>
                            <Dropdown
                                id="vigencia"
                                name="vigencia"
                                url="/produtos/precos-vigencia/"
                                optionValue="id"
                                optionLabel="descricao"
                                showClear={false}
                                filter
                                filterBy="descricao"
                                placeholder="Selecione"
                                value={formik.values.vigencia}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.vigencia })}
                            />
                            {formik.errors.vigencia && <small className="p-error">{formik.errors.vigencia}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="centro_estocagem">Centro de estocagem</label>
                            <Dropdown
                                id="centro_estocagem"
                                name="centro_estocagem"
                                url="/produtos/centros-estocagem/"
                                optionValue="id"
                                optionLabel="nome"
                                filter
                                filterBy="nome"
                                showFilterClear
                                showClear
                                placeholder="Selecione"
                                value={formik.values.centro_estocagem}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.centro_estocagem && (
                                <small className="p-error">{formik.errors.centro_estocagem}</small>
                            )}
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-justify-end p-mr-2">
                        <Button
                            type="submit"
                            icon={formik.values.id ? "pi pi-save" : "pi pi-check-circle"}
                            label={formik.values.id ? "Salvar" : "Inserir"}
                        />
                        <Button
                            className="p-button-danger p-button-text"
                            type="reset"
                            icon="pi pi-times"
                            label="Fechar"
                            onClick={() => esconderPreco()}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default PrecosForm;
