import React from "react";

import { Panel } from "primereact/panel";

import { InformacoesCaixa } from "../../features";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

export const Info = () => {
    const { caixaMov } = useCaixaMovimento();

    return (
        <div className="p-mb-3">
            <Panel header="Detalhes" toggleable>
                <InformacoesCaixa caixaMov={caixaMov} />
            </Panel>
        </div>
    );
};
