import React, { useState, useEffect, useCallback } from "react";
import { Dialog } from "primereact/dialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoListagemHierarquica } from "@/components/MakoListagemHierarquica";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoButton as Button } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { Dropdown } from "@/components/Dropdown";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

const url = "/financeiro/planos-contas-financeiras/";
const urlVerPlan = "/financeiro/versoes-planos-contas-financeiras/";
const urlImportar = "/financeiro/importar-versao-plano-conta-financeira/";

export const FinanceiroPlanoContasPage = (props) => {
    const [vincularEmpresa, setVincularEmpresa] = useState(false);
    const [planoSelecionado, setPlanoSelecionado] = useState(null);
    const [dados, setDados] = useState([]);
    const [value, setValue] = useState("0");
    const [origem, setOrigem] = useState("");
    const [botaoAtivo, setBotaoAtivo] = useState(false);
    const [versoes, setVersoes] = useState([]);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpGet, httpPost } = useHttp();
    const { showSuccess } = useToast();

    const handleDados = useCallback(async () => {
        if (value) {
            const handlers = {
                200: ({ data }) => setDados(data.length > 0 ? data : []),
            };
            showLoading();
            await httpGet({ url: `/financeiro/planos-contas-financeiras-estruturas/?id_versao=${value}` }, handlers);
            hideLoading();
        } else setDados([]);
    }, [showLoading, hideLoading, value, httpGet]);

    async function importarDados() {
        const body = {
            id_origem: origem,
            id_destino: value,
        };
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Importação realizada com êxito.",
                    life: 3000,
                });
            },
        };
        showLoading();
        await httpPost({ url: urlImportar, body }, handlers);
        hideLoading();
    }

    const getVersaoPlanoContas = useCallback(async () => {
        const handlers = {
            200: ({ data }) => setVersoes(data.results),
        };
        showLoading();
        await httpGet({ url: urlVerPlan }, handlers);
        hideLoading();
    }, [showLoading, hideLoading, httpGet]);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANOCONTAS_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de contas"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/financeiro/plano-contas/form",
                        state: { planContas: rowData, versaoId: value },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANOCONTAS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.data.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o plano de contas <b>{rowData.data.descricao}</b>?
                        </span>
                    }
                    tooltip="Deletar plano de contas"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O plano de contas não pode ser excluído."
                    onDelete={() => handleDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const valueId = (id) => {
        handleDados(id.value);
        setValue(id.value);
        setBotaoAtivo(true);
    };

    const setOrigemId = (id) => {
        setOrigem(id.value);
    };

    const aposBuscarVersoes = useCallback((data) => {
        setVersoes(data);
        return data;
    }, []);

    const cabecalhoTabela = (
        <>
            <Dropdown
                id="versoes"
                name="versoes"
                style={{ width: "380px" }}
                url={urlVerPlan}
                aposBuscar={aposBuscarVersoes}
                placeholder="Selecione uma versão"
                optionValue="id"
                optionLabel="descricao"
                className="p-mr-2"
                value={value}
                onChange={valueId}
            />
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANOCONTAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                disabled={botaoAtivo ? false : true}
                className="p-button-success p-mr-2"
                to={{
                    pathname: "/financeiro/plano-contas/form",
                    state: {
                        versaoId: value,
                        contaPaiId: planoSelecionado,
                    },
                }}
            />
            <Button
                label="Importar"
                icon="pi pi-download"
                disabled={botaoAtivo ? false : true}
                className="p-button-help p-mr-2"
                onClick={() => exibirImportarContas()}
            />
        </>
    );

    const esconderImportarContas = () => {
        setVincularEmpresa(false);
    };

    const exibirImportarContas = () => {
        setVincularEmpresa(true);
    };

    const verificaTitulo = (titulo, info) => {
        if (titulo) return <b>{info}</b>;
        return info;
    };

    const colunas = [
        {
            field: "codigo",
            header: "Código",
            expander: true,
            style: { width: "150px" },
            action: (e) => verificaTitulo(e.data.conta_titulo, e.data.codigo),
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "200px" },
            action: (e) => verificaTitulo(e.data.conta_titulo, e.data.descricao),
        },
        { field: "natureza", header: "Natureza", style: { width: "70px" } },
        { field: "estrutura", header: "Estrutura", style: { width: "70px" } },
        { field: "nivel", header: "Nível", style: { width: "70px" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "120px" },
        },
    ];

    const rowClass = (node) => {
        return {
            "p-text-bold": node.data.conta_titulo === true,
        };
    };

    useEffect(() => {
        if (props.location.state?.versaoId) {
            setValue(props.location.state.versaoId);
            setBotaoAtivo(true);
        }
        handleDados();
        getVersaoPlanoContas();
    }, [props.location.state?.versaoId, getVersaoPlanoContas, handleDados]);

    return (
        <PageBase>
            {!loading ? (
                <MakoListagemHierarquica
                    dados={dados}
                    colunas={colunas}
                    cabecalho={cabecalhoTabela}
                    titulo="Plano de Contas"
                    tabelaConfig={{
                        selectionMode: "single",
                        selectionKeys: planoSelecionado,
                        selection: planoSelecionado,
                        onSelectionChange: (e) => setPlanoSelecionado(e.value),
                        scrollable: true,
                        rowClassName: rowClass,
                    }}
                    mensagemTabelaVazia={`Nenhum registro selecionado.`}
                />
            ) : (
                "Buscando informações..."
            )}
            <Dialog
                header="Importar contas"
                visible={vincularEmpresa}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw" }}
                onHide={() => esconderImportarContas()}
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="versoes" label="Versão alvo" />
                        <Dropdown
                            id="versoes"
                            name="versoes"
                            value={value}
                            placeholder="Selecione uma versão"
                            optionLabel="descricao"
                            optionValue="id"
                            options={versoes}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="importar" label="Importar dados de" />
                        <Dropdown
                            id="importar"
                            name="importar"
                            value={origem}
                            placeholder="Selecione uma versão"
                            optionLabel="descricao"
                            optionValue="id"
                            className="p-mr-2"
                            onChange={setOrigemId}
                            options={versoes}
                        />
                    </div>
                </div>
                <MakoActionsButtons className="p-jc-end">
                    <Button label="Importar" className="p-button" onClick={() => importarDados()} />
                </MakoActionsButtons>
            </Dialog>
        </PageBase>
    );
};
