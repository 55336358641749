import React, { useRef } from "react";
import classNames from "classnames";

import { MakoButton as Button } from "@/components/MakoButton";
import { ModalImpressoras } from "@/components/ModalImpressoras";
import { RELATORIOS_EMITIR_FINANCEIRO_RECIBOQUITACAO } from "@/assets/constants/relatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const Recibo = ({ recebimentoId, className = "", disabled = false }) => {
    const modalImpressorasRef = useRef(null);

    return (
        <>
            <Button
                type="button"
                tooltip="Imprimir recibo"
                tooltipOptions={{ position: "left" }}
                icon={MAKO_ICONS.ARQUIVO}
                onClick={() =>
                    modalImpressorasRef.current?.abrirModal({
                        chave: RELATORIOS_EMITIR_FINANCEIRO_RECIBOQUITACAO,
                        enviar_fila: false,
                        filtros: { recebimento: recebimentoId },
                    })
                }
                className={classNames("p-button p-button-rounded", className)}
                disabled={disabled || !recebimentoId}
            />
            <ModalImpressoras ref={modalImpressorasRef} />
        </>
    );
};
