import { ReceberPix } from "./receber-pix";
import { ReceberCartaoCredito } from "./receber-cartao-credito";
import { ReceberCartaoDebito } from "./receber-cartao-debito";
import { ReceberDinheiro } from "./receber-dinheiro";

export const Modals = {
    ReceberPix,
    ReceberCartaoCredito,
    ReceberCartaoDebito,
    ReceberDinheiro,
};
