import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import MakoListagem from "@/components/MakoListagem";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MakoErrosPersonalizados } from "@/components/MakoErrosPersonalizados";

export const SelecionarArquivoModalForm = ({ esconderDialog, esconderList }) => {
    const [colunas, setColunas] = useState([]);
    const [arquivo, setArquivo] = useState(null);
    const [dados, setDados] = useState([]);
    const [campos, setCampos] = useState([]);
    const [selecaoColunas, setSelecaoColunas] = useState(false);
    const { showSuccess, showError } = useToast();
    const { httpPost } = useHttp();
    const modalErrosRef = useRef();
    const listagemRef = useRef();

    const OPCOES_COLUNA = [
        {
            value: "codigo_externo",
            label: "ID",
        },
        {
            value: "codigo_sku",
            label: "Código",
        },
        { value: "descricao", label: "Descrição *" },
        { value: "descricao_reduzida", label: "Descrição Reduzida" },
        { value: "marca", label: "Marca" },
        { value: "modelo", label: "Modelo" },
        { value: "ncm", label: "NCM" },
        { value: "cest", label: "Cest" },
        { value: "unidade_padrao", label: "Unidade Medida" },
        { value: "preco_sku", label: "Preço" },
    ];

    async function handlePendencias() {
        try {
            const obj = Object.assign(dados[0]);
            delete obj.col;
            const valores = Object.values(obj);
            const chaves = Object.keys(obj);

            let colunas = {};

            for (let v in valores) {
                if (valores[v] !== "col" && valores[v] !== "Selecione a coluna") {
                    colunas[chaves[v]] = valores[v];
                }
            }

            const campo = Object.keys(colunas);
            const valor = Object.values(colunas);
            let json = [];

            for (let d in dados) {
                let obj = {};
                for (let c in campo) {
                    obj[valor[c]] = dados[d][campo[c]];
                }
                json.push(obj);
            }

            try {
                const handlers = {
                    201: ({ data }) => {
                        console.log(data);
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Pendências registradas com sucesso!",
                            life: 1500,
                        });
                        esconderDialog(false);
                        esconderList(false);
                    },
                    400: ({ err }) => {
                        console.log(err);
                        let lista = [];
                        err.forEach((parcela) => {
                            let obj = {};
                            const keys = Object.keys(parcela);
                            const values = Object.values(parcela);
                            lista = keys.map((key, index) => {
                                return {
                                    campo: key,
                                    erro: values[index],
                                };
                            });
                        });
                        if (lista?.length > 0) modalErrosRef.current?.abrirModal(lista);
                        showError({
                            summary: "Erro",
                            detail: "Falha ao realizar importação.",
                            life: 3000,
                        });
                    },
                };

                await httpPost(
                    {
                        url: "/produtos/produtos-importacao/",
                        body: json,
                    },
                    handlers
                );
            } catch (error) {
                showError({
                    summary: "Erro",
                    detail: "Não foi possível registrar as pendências, verifique com o suporte ou tente novamente mais tarde.",
                    life: 2500,
                });
            }
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Falha em preparar objeto para envio de pendências, por favor verifique o arquivo e tente novamente.",
                life: 2500,
            });
        }
    }

    const onFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setArquivo(file);
        }
    };

    const converteCsv = () => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsText(arquivo);
        });
    };

    const editar = async (options, coluna, aux, campo, dados) => {
        options.editorCallback(coluna);
        setDados(
            dados.map((item) => {
                if (item.id === aux) item[campo] = coluna;
                return item;
            })
        );
    };

    const DropdownCol = (options, dados) => {
        if (options.rowData.id === "col")
            return (
                <Dropdown
                    id="coluna"
                    placeholder="Selecione a coluna correspondente"
                    name="coluna"
                    onChange={(e) => editar(options, e.target.value, options.rowData.id, options.field, dados)}
                    value={options.value}
                    optionValue="value"
                    optionLabel="label"
                    options={OPCOES_COLUNA}
                    autoComplete="off"
                    style={{ width: "100%" }}
                />
            );

        return options.value;
    };

    const preencheTabela = async () => {
        try {
            let csv = await converteCsv();
            csv = csv.split("\n");
            for (let c in csv) {
                csv[c] = csv[c].split(",");
            }

            csv.length = csv.length - 1;
            let dados = [];
            const campos = csv[0];

            for (let c in csv) {
                dados.push({
                    id_temporario: parseInt(c) + 1,
                });
            }

            dados.length = dados.length - 1;

            for (let c in campos) {
                for (let d in dados) {
                    dados[d][campos[c]] = null;
                }
            }

            for (let d in dados) {
                for (let c in campos) {
                    try {
                        dados[d][campos[c]] = csv[parseInt(d) + 1][c];
                    } catch (error) {
                        dados[d][campos[c]] = null;
                    }
                }
            }

            let obj = {
                id: "col",
            };

            for (let c in campos) {
                obj[campos[c]] = "Selecione a coluna";
            }

            dados.unshift(obj);
            setCampos(campos);
            setDados(dados);
            setSelecaoColunas(true);
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Não foi possível carregar as informações do arquivo. Por favor, verifique o layout e tente novamente.",
                life: 2500,
            });
        }
    };

    const renderizaColunas = useCallback(
        async (dados) => {
            let lista = [];
            campos.forEach((campo) => {
                lista.push({
                    field: campo,
                    style: { width: "250px" },
                    editor: (e) => DropdownCol(e, dados),
                });
            });
            setColunas(lista);
        },
        [campos]
    );

    useEffect(() => {
        if (dados.length > 0) renderizaColunas(dados);
    }, [renderizaColunas, dados]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <p>Selecione um arquivo:</p>
                <div className="p-fluid p-formgrid p-grid ">
                    <div className="p-field p-col-12 p-md-12">
                        <input
                            type="file"
                            onChange={onFileChange}
                            accept="
                            .csv,
                            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                            application/vnd.ms-excel"
                        />
                    </div>
                </div>
                <Button
                    type="button"
                    icon="pi pi-plus"
                    label="Carregar arquivo"
                    className="p-button-info p-mt-2"
                    disabled={!arquivo}
                    onClick={() => preencheTabela()}
                />
            </div>
            <Dialog
                header="Selecione as colunas correspondentes a informação:"
                visible={selecaoColunas}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "65vw", display: "block" }}
                onHide={() => setSelecaoColunas(false)}
            >
                {colunas?.length > 0 && dados?.length > 0 ? (
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas || []}
                        dadosLocal={dados}
                        configTabela={{
                            scrollable: true,
                            frozenWidth: "0.5vw",
                        }}
                    />
                ) : (
                    "Não existem dados no arquivo informado."
                )}
                <Button
                    type="button"
                    icon="pi pi-save"
                    label="Gravar pendências"
                    className="p-button-info p-mt-2"
                    disabled={!arquivo}
                    onClick={() => handlePendencias()}
                />
            </Dialog>
            <MakoErrosPersonalizados
                ref={modalErrosRef}
                titulo="Inconsistências encontradas"
                colunas={[
                    { field: "campo", header: "Campo" },
                    { field: "erro", header: "Descrição da pendência" },
                ]}
            />
        </div>
    );
};
