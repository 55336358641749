import React from "react";
import classNames from "classnames";
import { addDays } from "date-fns";
import { Panel } from "primereact/panel";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { CamposObrigatorios } from "../CamposObrigatorios";
import { Dropdown } from "../Dropdown";
import { MakoCalendar } from "../MakoCalendar";
import { MakoInputMoeda } from "../MakoInputMoeda";
import { validarProgressaoIntervaloParcelas } from "../MakoInputs/MakoInputIntervaloParcelas";
import { TIPOS_PARCELAS, REPETICAO_DIAS_PARCELAS } from "@/assets/constants/financeiro";
import { gerarParcelas } from "@/assets/util/calcFinanceiros";
import { parseNumber } from "@/assets/helpers/number";

export const LancamentoParcelas = ({ onSubmit, recorrencia, valorBruto, vencimento, minVencimento }) => {
    const formik = useFormik({
        initialValues: {
            tipo_parcela: null,
            repeticao: recorrencia || "30",
            quantidade: recorrencia?.includes(",") ? recorrencia.split(",").filter(Boolean).length : 1,
            valor_bruto: valorBruto || 0,
            data_primeira_parcela: vencimento || null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_parcela: Yup.string()
                    .required("O campo 'tipo de parcela' é obrigatório.")
                    .typeError("Informe um tipo de parcela."),
                repeticao: Yup.string()
                    .required("O campo 'repetição' é obrigatório.")
                    .matches(/[0-9,]/, "Para esse campo são válidos apenas os dígitos: 0 a 9 e ','.")
                    .test("validation-2", "A progressão do intervalo não está correta.", (value) =>
                        validarProgressaoIntervaloParcelas(value)
                    )
                    .test("validation-3", "A quantidade de parcelas difere do número de intervalos.", (value) => {
                        if (!value.includes(",")) return true;
                        const intervalos = value.split(",").length;
                        return intervalos === parseNumber(values.quantidade);
                    })
                    .typeError("Selecione uma repetição."),
                quantidade: Yup.number()
                    .required("O campo 'quantidade' é obrigatório.")
                    .min(1, "O valor mínimo para esse campo é 1.")
                    .max(120, "O valor máximo para esse campo é 120."),
                valor_bruto: Yup.number()
                    .required("O campo 'valor bruto' é obrigatório.")
                    .min(0.01, "Esse campo não pode ser 0 (ZERO)."),
                data_primeira_parcela: Yup.date()
                    .required("O campo 'data 1ª parcela' é obrigatório.")
                    .typeError("Informe uma data válida."),
            });
            const { tipo_parcela, repeticao, valor_bruto, quantidade, data_primeira_parcela } =
                await formSchema.validate(values, {
                    abortEarly: false,
                });
            let valoresParcelas = [];
            if (tipo_parcela === "dividir") {
                valoresParcelas = gerarParcelas(valor_bruto, quantidade);
            } else {
                valoresParcelas = Array(quantidade).fill(valor_bruto);
            }
            const intervalo = repeticao.includes(",")
                ? repeticao
                      .split(",")
                      .filter(Boolean)
                      .map((i) => parseNumber(i))
                : Array(quantidade)
                      .fill(parseNumber(repeticao))
                      .map((q, index) => q * (index + 1));
            const possuiEntrada = intervalo[0] === 0;
            const parcelas = valoresParcelas.map((valor, index) => {
                return {
                    numParcela: possuiEntrada ? index : index + 1,
                    qtdParcelas: quantidade,
                    intervaloParcela: intervalo[index],
                    vencimento: addDays(data_primeira_parcela, intervalo[index]),
                    valorTotal: valor_bruto,
                    valorParcela: valor,
                };
            });
            if (typeof onSubmit === "function") onSubmit(parcelas);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleBlurRepeticao = (event) => {
        const { value } = event;
        if (!value.includes(",")) return;
        const qtdParcelas = value.split(",").filter(Boolean).length;
        formik.setFieldValue("quantidade", qtdParcelas);
    };

    return (
        <Panel header="Definir parcelas" className="p-mb-3">
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="tipo-parcela">Tipo de parcela *</label>
                        <Dropdown
                            id="tipo-parcela"
                            name="tipo_parcela"
                            options={TIPOS_PARCELAS}
                            value={formik.values.tipo_parcela}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_parcela })}
                        />
                        {formik.errors.tipo_parcela && <small className="p-error">{formik.errors.tipo_parcela}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="repeticao">Repetição (dias) *</label>
                        <Dropdown
                            id="repeticao"
                            name="repeticao"
                            options={REPETICAO_DIAS_PARCELAS}
                            placeholder="Selecione ou digite"
                            editable
                            tooltip="Para intervalo customizado, digite e separe com ','."
                            tooltipOptions={{ position: "bottom" }}
                            value={formik.values.repeticao}
                            onChange={formik.handleChange}
                            onBlur={handleBlurRepeticao}
                            className={classNames({ "p-invalid": formik.errors.repeticao })}
                        />
                        {formik.errors.repeticao && <small className="p-error">{formik.errors.repeticao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-1">
                        <label htmlFor="quantidade">Qtd. *</label>
                        <InputNumber
                            id="quantidade"
                            min={1}
                            max={120}
                            useGrouping={false}
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-bruto">Valor bruto *</label>
                        <MakoInputMoeda
                            id="valor-bruto"
                            name="valor_bruto"
                            valueMoeda={formik.values.valor_bruto}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_bruto })}
                        />
                        {formik.errors.valor_bruto && <small className="p-error">{formik.errors.valor_bruto}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data-1-parcela">Data 1ª parcela *</label>
                        <MakoCalendar
                            id="data-1-parcela"
                            name="data_primeira_parcela"
                            minDate={minVencimento}
                            valueCalendar={formik.values.data_primeira_parcela}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_primeira_parcela })}
                        />
                        {formik.errors.data_primeira_parcela && (
                            <small className="p-error">{formik.errors.data_primeira_parcela}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Button type="submit" icon="pi pi-refresh" label="Gerar" />
                    </div>
                </div>
            </form>
        </Panel>
    );
};
