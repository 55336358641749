import React, { useRef } from "react";
import { Button } from "primereact/button";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { Tag } from "primereact/tag";

const url = "/fiscal/cfop/";

export const FiscalCfopPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_CFOP_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar Cadastro de CFOP"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/cadastros/cfop/form",
                            state: { cfop: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_CFOP_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o CFOP <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O serviço não pode ser excluído."
                    tooltip="Deletar CFOP"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_FISCAL_CFOP_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/fiscal/cadastros/cfop/form")}
            />
        </>
    );

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={[
                    { value: true, label: "Ativo" },
                    { value: false, label: "Inativo" },
                ]}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "#ID",
            style: { width: "50px" },
            filter: true,
            showFilterMatchModes: false,
            showFilterMenu: false,
        },
        {
            field: "codigo",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { minWidth: "800px" },
            filter: true,
        },
        {
            field: "tipo_movimentacao",
            header: "Movimentação",
            action: ({ tipo_movimentacao }) => {
                if (tipo_movimentacao === "E") return "Entrada";
                return "Saída";
            },
            style: { minWidth: "120px" },
        },
        {
            header: "Situação",
            field: "ativo",
            filter: true,
            filterElement: statusFiltroTemplate,
            action: ({ ativo }) => {
                if (ativo) return <Tag severity="success" value="ATIVO" />;
                return <Tag severity="danger" value="INATIVO" />;
            },
            style: { width: "10%" },
        },
        {
            frozen: true,
            alignFrozen: "rigth",
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "100px" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                style={{ width: "10px" }}
                ref={listagemRef}
                titulo="CFOP's"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                filtros={{
                    id: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    codigo: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    tipo: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                    scrollable: true,
                    frozenWidth: "0.5vw",
                }}
            />
        </PageBase>
    );
};
