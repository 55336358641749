import React, { useRef } from "react";
import { Button } from "primereact/button";

import { ModalRegraOperacaoFiscalNCM } from "./regras/ncm";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";

export const NcmForm = ({ opFiscal }) => {
    const modalRegraNCMRef = useRef(null);
    const listagemRef = useRef(null);
    useClearRefs(modalRegraNCMRef, listagemRef);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    onClick={() =>
                        modalRegraNCMRef.current?.abrirModal({
                            ...rowData,
                            cst_icms: rowData.cst_icms?.id,
                            cst_pis: rowData.cst_pis?.id,
                            cst_cofins: rowData.cst_cofins?.id,
                            cst_ipi: rowData.cst_ipi?.id,
                            enquadramento_ipi: rowData.enquadramento_ipi?.id,
                        })
                    }
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_EDITAR]}
                    componente={BotaoDelete}
                    url="/fiscal/ncm-operacoes-fiscais/"
                    classNames="p-mb-1"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente remover o NCM <b>{`${rowData.ncm.codigo} - ${rowData.ncm.descricao}`}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O NCM não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Adicionar"
                icon="pi pi-plus"
                onClick={() => modalRegraNCMRef.current.abrirModal()}
                className="p-button-success p-button-outlined"
            />
        </>
    );

    const colunas = [
        { field: "ncm.codigo", header: "Código", style: { width: "18%" } },
        { field: "ncm.descricao", header: "Descrição" },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <>
            <MakoListagem
                ref={listagemRef}
                titulo="NCM's permitidos"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/fiscal/ncm-operacoes-fiscais?operacao_fiscal=${opFiscal?.id}`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
            <ModalRegraOperacaoFiscalNCM
                ref={modalRegraNCMRef}
                operacaoFiscal={opFiscal}
                onGravar={() => listagemRef.current?.buscarDados()}
            />
        </>
    );
};
