import { useContext } from "react";

import PagamentoContext from "@/contexts/pagamentoContext";

const usePagamento = () => {
    const pagamento = useContext(PagamentoContext);

    return pagamento;
};

export default usePagamento;
