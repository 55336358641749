import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import useCrediario from "@/hooks/useCrediario";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";

export const ResumoCrediarioForm = () => {
    const [aumento, setAumento] = useState(0);
    const [reducao, setReducao] = useState(0);
    const { crediario, handleDadosCrediario } = useCrediario();
    const { httpGet } = useHttp();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            val_mensal_orcamentor: crediario.orcamento?.valor_total_av,
            limite_pre_aprovado: 0,
            renda_comprometida: crediario.renda_comprometida,
            renda_deduzida: crediario.renda_deduzida,
            renda_propria: crediario.renda_propria,
            renda_terceiros: crediario.renda_terceiros,
            historico_validacao: crediario.historico_validacao,
            percentual_limite: crediario.regra.percentual_limite,
            limite_liberado: 0,
        },
    });

    const carregarInformacoes = useCallback(() => {
        let renda_propria = 0,
            renda_terceiros = 0;

        if (crediario.relacaoperfil_set?.length > 0)
            crediario.relacaoperfil_set.forEach((relacao) => {
                if (relacao.nome_relacao.tipo.id === 4 && relacao.op !== "deletar")
                    if (relacao.perfil_titular?.id === crediario.perfil_titular?.id) {
                        if (relacao.renda_perfil) renda_propria += relacao.renda_perfil.renda;
                    } else if (relacao.renda_perfil) renda_terceiros += relacao.renda_perfil.renda;
            });

        setFieldValue("renda_propria", renda_propria);
        setFieldValue("renda_terceiros", renda_terceiros);
    }, [crediario, setFieldValue]);

    useEffect(() => {
        carregarInformacoes();
    }, [carregarInformacoes]);

    const carregaIncremento = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                if (data.results?.length > 0) setAumento(data.results[0].limite);
            },
        };

        await httpGet(
            {
                url: `/crediario/criterio-crediario?regra${crediario.regra.id}}&padrao_criterio__chave=incrementar_comprometimento_renda&limit=1`,
            },
            handlers
        );
    }, [crediario.regra, httpGet]);

    useEffect(() => {
        carregaIncremento();
    }, [carregaIncremento]);

    const carregaReducao = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                if (data.results?.length > 0) {
                    const reducao = crediario.regra.percentual_limite - data.results[0].limite;
                    setReducao(reducao < 0 ? 0 : reducao);
                }
            },
        };

        await httpGet(
            {
                url: `/crediario/criterio-crediario?regra${crediario.regra.id}}&padrao_criterio__chave=reduzir_comprometimento_renda&limit=1`,
            },
            handlers
        );
    }, [crediario.regra, httpGet]);

    useEffect(() => {
        carregaReducao();
    }, [carregaReducao]);

    useEffect(() => {
        const percentual = parseFloat(crediario.regra.percentual_limite) + aumento - reducao;
        setFieldValue("percentual_limite", percentual < 0 ? 0 : percentual);
    }, [setFieldValue, aumento, reducao, crediario.regra]);

    const calculaLimite = useCallback(async () => {
        const limite =
            (parseFloat(formik.values.val_mensal_orcamentor || 0) +
                parseFloat(formik.values.renda_propria) +
                parseFloat((formik.values.renda_terceiros || 0) + parseFloat(formik.values.renda_deduzida || 0))) *
                parseFloat(formik.values.percentual_limite / 100) -
            parseFloat(formik.values.renda_comprometida || 0);
        if (typeof limite === "number" && limite >= 0) {
            setFieldValue("limite_liberado", limite);
            await handleDadosCrediario({ limite_liberado: limite.toFixed(2) });
        }
    }, [
        formik.values.val_mensal_orcamentor,
        formik.values.renda_propria,
        formik.values.renda_terceiros,
        formik.values.renda_deduzida,
        formik.values.percentual_limite,
        formik.values.renda_comprometida,
        handleDadosCrediario,
        setFieldValue,
    ]);

    useEffect(() => {
        calculaLimite();
    }, [calculaLimite]);

    return (
        <PageBase>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="val_mensal_orcamentor">Valor mensal orçamento *</label>
                    <InputNumber
                        id="val_mensal_orcamentor"
                        name="val_mensal_orcamentor"
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                        disabled
                        value={formik.values.val_mensal_orcamentor}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.val_mensal_orcamentor })}
                    />
                    {formik.errors.val_mensal_orcamentor && (
                        <small className="p-error">{formik.errors.val_mensal_orcamentor}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="limite_pre_aprovado">Limite pré aprovado *</label>
                    <InputNumber
                        id="limite_pre_aprovado"
                        name="limite_pre_aprovado"
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                        disabled
                        value={formik.values.limite_pre_aprovado}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.limite_pre_aprovado })}
                    />
                    {formik.errors.limite_pre_aprovado && (
                        <small className="p-error">{formik.errors.limite_pre_aprovado}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="renda_comprometida">Renda comprometida *</label>
                    <InputNumber
                        id="renda_comprometida"
                        name="renda_comprometida"
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                        disabled
                        value={formik.values.renda_comprometida}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.renda_comprometida })}
                    />
                    {formik.errors.renda_comprometida && (
                        <small className="p-error">{formik.errors.renda_comprometida}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="renda_deduzida">Renda deduzida *</label>
                    <div className="p-inputgroup">
                        <MakoInputMoeda
                            id="renda_deduzida"
                            name="renda_deduzida"
                            value={formik.values.renda_deduzida}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.renda_deduzida })}
                        />
                        <Button
                            type="button"
                            icon="pi pi-save"
                            className="p-button-success"
                            tooltip="Gravar renda deduzida"
                            onClick={() => handleDadosCrediario({ renda_deduzida: formik.values.renda_deduzida })}
                        />
                    </div>
                    {formik.errors.renda_deduzida && <small className="p-error">{formik.errors.renda_deduzida}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="renda_propria">Renda própria *</label>
                    <InputNumber
                        id="renda_propria"
                        name="renda_propria"
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                        disabled
                        value={formik.values.renda_propria}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.renda_propria })}
                    />
                    {formik.errors.renda_propria && <small className="p-error">{formik.errors.renda_propria}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="renda_terceiros">Renda terceiros *</label>
                    <InputNumber
                        id="renda_terceiros"
                        name="renda_terceiros"
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                        disabled
                        value={formik.values.renda_terceiros}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.renda_terceiros })}
                    />
                    {formik.errors.renda_terceiros && (
                        <small className="p-error">{formik.errors.renda_terceiros}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="percentual_limite">Percentual limite *</label>
                    <InputNumber
                        id="percentual_limite"
                        name="percentual_limite"
                        mode="decimal"
                        prefix="%"
                        maxFractionDigits={2}
                        minFractionDigits={2}
                        disabled
                        value={formik.values.percentual_limite}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.percentual_limite })}
                    />
                    {formik.errors.percentual_limite && (
                        <small className="p-error">{formik.errors.percentual_limite}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="limite_liberado">
                        <b>Limite liberado *</b>
                    </label>
                    <InputNumber
                        id="limite_liberado"
                        name="limite_liberado"
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                        disabled
                        value={formik.values.limite_liberado}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.limite_liberado })}
                    />
                    {formik.errors.limite_liberado && (
                        <small className="p-error">{formik.errors.limite_liberado}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="historico_validacao">Histórico validação</label>
                    <InputTextarea
                        id="historico_validacao"
                        name="historico_validacao"
                        value={formik.values.historico_validacao}
                        onChange={formik.handleChange}
                        autoResize
                        rows={8}
                        disabled
                    />
                </div>
            </div>
        </PageBase>
    );
};
