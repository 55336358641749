import React from "react";

import { Lancar } from "../features/Lancar";

import useRecebimento from "@/hooks/useRecebimento";

export const RecebimentoPrevisaoForm = () => {
    const {
        recebimentos: [recebimento],
    } = useRecebimento();

    return <Lancar.Previsao agrupador={recebimento?.agrupador} />;
};
