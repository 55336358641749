import React from "react";

import { InputText } from "primereact/inputtext";

import { MakoCalendar } from "@/components/MakoCalendar";

import { parseData } from "@/assets/util/datas";

const getTypeConta = (caixa) => {
    if (caixa.conta_financeira) return "conta_financeira";
    if (caixa.conta_bancaria) return "conta_bancaria";
    if (caixa.conta_poupanca) return "conta_poupanca";
    if (caixa.conta_investimento) return "conta_investimento";
    return "";
};

const formatContaVinculada = (caixa) => {
    const tipoConta = getTypeConta(caixa);
    return `${caixa[tipoConta].tipo_conta.descricao} | ${caixa[tipoConta].descricao}`;
};

export const InformacoesCaixa = ({ caixaMov = {} }) => {
    return (
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-1">
                <label htmlFor="id">ID</label>
                <InputText id="id" name="id" value={caixaMov?.id} disabled />
            </div>
            <div className="p-field p-col-12 p-md-3">
                <label htmlFor="conta">Conta vinculada</label>
                <InputText id="conta" name="conta" value={formatContaVinculada(caixaMov?.caixa)} disabled />
            </div>
            <div className="p-field p-col-12 p-md-1">
                <label htmlFor="codigo_caixa">Código</label>
                <InputText
                    id="codigo_caixa"
                    name="codigo_caixa"
                    value={caixaMov?.caixa[getTypeConta(caixaMov?.caixa)]?.codigo}
                    disabled
                />
            </div>
            <div className="p-field p-col-12 p-md-3">
                <label htmlFor="operador">Operador atual</label>
                <InputText id="operador" name="operador" value={caixaMov.operador.nome} disabled />
            </div>
            <div className="p-field p-col-12 p-md-2">
                <label htmlFor="data_abertura">Data de abertura</label>
                <MakoCalendar
                    id="data_abertura"
                    name="data_abertura"
                    valueCalendar={parseData(caixaMov?.data_abertura)}
                    disabled
                />
            </div>
            <div className="p-field p-col-12 p-md-2">
                <label htmlFor="hora_abertura">Hora abertura</label>
                <InputText
                    id="hora_abertura"
                    name="hora_abertura"
                    value={caixaMov?.hora_abertura.slice(0, 5)}
                    disabled
                />
            </div>
        </div>
    );
};
