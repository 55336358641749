import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { MakoCalendar } from "@/components/MakoCalendar";
import { useHistory } from "react-router-dom";
import { dataToStr } from "@/assets/util/datas";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

const url = "/financeiro/competencias/";
const urlvoltar = "/financeiro/cadastros/competencia";

export const FinanceiroCompetenciaForm = () => {
    const { showLoading, hideLoading } = useLoading();
    const { httpPost, httpPatch } = useHttp();
    const { showSuccess, showError } = useToast();
    const { state } = useLocation();
    const history = useHistory();

    const formik = useFormik({
        initialValues: state
            ? state.competencia
            : {
                  codigo: "",
                  grupo_competencia: "",
                  data_inicio: null,
                  data_fim: null,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório.").max(20),
                grupo_competencia: Yup.string().required("O campo 'grupo de competẽncia' é obrigatório.").max(30),
                data_inicio: Yup.date().required("O campo 'data inicial' é obrigatório."),
                data_fim: Yup.date()
                    .required("O campo 'data final' é obrigatório.")
                    .min(values.data_inicio, "A data final não pode ser maior que a data inicial."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const body = {
                ...values,
                data_inicio: dataToStr(values.data_inicio, "yyyy-MM-dd"),
                data_fim: dataToStr(values.data_fim, "yyyy-MM-dd"),
            };

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Competência cadastrada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };

                showLoading();
                await httpPost({ url, body }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Competência alterada com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };

                showLoading();
                await httpPatch({ url: `${url}${values.id}/`, body }, handlers);
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova competência" : "Manutenção de competência"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="codigo">Código *</label>
                        <InputText
                            id="codigo"
                            name="codigo"
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="grupo_competencia">Grupo de competência *</label>
                        <InputText
                            id="grupo_competencia"
                            name="grupo_competencia"
                            value={formik.values.grupo_competencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.grupo_competencia })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.grupo_competencia && (
                            <small className="p-error">{formik.errors.grupo_competencia}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_inicio">Data inicial *</label>
                        <MakoCalendar
                            id="data_inicio"
                            name="data_inicio"
                            valueCalendar={formik.values.data_inicio}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_inicio,
                            })}
                        />
                        {formik.errors.data_inicio && <small className="p-error">{formik.errors.data_inicio}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_fim">Data final *</label>
                        <MakoCalendar
                            id="data_fim"
                            name="data_fim"
                            valueCalendar={formik.values.data_fim}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_fim,
                            })}
                        />
                        {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                    </div>
                </div>

                <p>
                    <b>* Campos obrigatórios.</b>
                </p>

                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    <Button
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
