import React, { useCallback } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoDropdownCompetenciaFinanceira } from "@/components/MakoInputs/MakoDropdownCompetenciaFinanceira";
import { MakoDropdownCompetenciaEstoque } from "@/components/MakoInputs/MakoDropdownCompetenciaEstoque";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { STATUS_ENTRADA } from "@/assets/constants/compras";
import { dataToStr } from "@/assets/util/datas";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";
import useEmpresa from "@/hooks/useEmpresa";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

const INITIAL_VALUES = {
    datahora_entrada__lte: null,
    datahora_entrada__gte: null,
    tipo_entrada: null,
    categoria_titulo: null,
    competencia_estoque: null,
    competencia_financeira: null,
    entradaxml__cpf_cnpj_emitente: null,
    entradaxml__numero_nf__unaccent__icontains: null,
    itementrada__sku: null,
    status: null,
    usuario_cadastro: null,
    entradaxml__xml__contains: null,
    usuario_finalizacao: null,
    usuario_validacao: null,
    ordem_compra_principal: null,
    conhecimento_transporte: null,
    itementrada__sku__codigo: null,
};

export const FiltroAvancadoEntrada = ({ onConfirm, onCancel, baseUrl = "" }) => {
    const { empresaSelecionadaId } = useEmpresa();
    const [formatarDocumento] = useFormatCNPJCPF();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                datahora_entrada__gte: Yup.date().nullable().typeError("Informe um 'data inicial' válida"),
                datahora_entrada__lte: Yup.date().when("datahora_entrada__gte", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            values.datahora_entrada__gte || new Date(),
                            "O campo 'data final' não pode ser anterior a inicial"
                        )
                        .typeError("Informe uma 'data final' válida"),
                    otherwise: Yup.date().nullable(),
                }),
                tipo_entrada: Yup.number().nullable().typeError("Informe um 'tipo entrada' válido"),
                categoria_titulo: Yup.number().nullable().typeError("Informe uma 'categoria titulo' válida"),
                competencia_estoque: Yup.number().nullable().typeError("Informe uma 'competência de estoque' válida"),
                competencia_financeira: Yup.number()
                    .nullable()
                    .typeError("Informe uma 'competência de financeira' válida"),
                entradaxml__numero_nf__unaccent__icontains: Yup.string()
                    .nullable()
                    .typeError("Informe um 'numero nf' válido"),
                entradaxml__xml__contains: Yup.string().nullable().typeError("Informe um 'xml' válido"),
                entradaxml__cpf_cnpj_emitente: Yup.string().nullable().typeError("Informe um 'CPF/CNPJ' válido"),
                usuario_cadastro: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().nullable(),
                    })
                    .typeError("Informe um 'usuario cadastro' válido"),
                usuario_finalizacao: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().nullable(),
                    })
                    .typeError("Informe um 'usuario finalizacao' válido"),
                usuario_validacao: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().nullable(),
                    })
                    .typeError("Informe um 'usuario validacao' válido"),
                ordem_compra_principal: Yup.string().nullable().typeError("Informe um 'codigo' válido"),
                conhecimento_transporte: Yup.number()
                    .nullable()
                    .typeError("Informe um 'conhecimento de transporte' válido"),
                itementrada__sku__codigo: Yup.string().nullable().typeError("Informe um 'EAN' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let params = [];

            Object.keys(values).forEach((key) => {
                if (values[key] !== null) {
                    if (key === "datahora_entrada__lte")
                        return params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd hh:mm:ss")}`);
                    if (key === "datahora_entrada__gte")
                        return params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd hh:mm:ss")}`);
                    if (key === "usuario_cadastro") return params.push(`${key}=${values[key].id}`);
                    if (key === "usuario_finalizacao") return params.push(`${key}=${values[key].id}`);
                    if (key === "usuario_validacao") return params.push(`${key}=${values[key].id}`);
                    if (key === "itementrada__sku") return params.push(`${key}=${values[key].id}`);
                    return params.push(`${key}=${values[key]}`);
                }
            });
            if (params.length > 0) {
                const url = baseUrl.split("?")[0] + "?" + params.join("&");

                if (typeof onConfirm === "function")
                    onConfirm(url, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            } else {
                if (typeof onConfirm === "function")
                    onConfirm(baseUrl, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const aposBuscarOC = useCallback(
        (data = []) => {
            return data.map((oc) => ({
                ...oc,
                label: `${oc.id}: ${oc.fornecedor.nome} - ${formatarDocumento(oc.fornecedor.identificacao)}`,
            }));
        },
        [formatarDocumento]
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="datahora_entrada__gte" label="Período inicial" />
                    <MakoCalendar
                        id="datahora_entrada__gte"
                        name="datahora_entrada__gte"
                        valueCalendar={formik.values.datahora_entrada__gte}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.datahora_entrada__gte })}
                    />
                    {formik.errors.datahora_entrada__gte && (
                        <small className="p-error">{formik.errors.datahora_entrada__gte}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="datahora_entrada__lte" label="Período final" />
                    <MakoCalendar
                        id="datahora_entrada__lte"
                        name="datahora_entrada__lte"
                        valueCalendar={formik.values.datahora_entrada__lte}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.datahora_entrada__lte })}
                    />
                    {formik.errors.datahora_entrada__lte && (
                        <small className="p-error">{formik.errors.datahora_entrada__lte}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="tipo_entrada" label="Tipo de entrada" />
                    <Dropdown
                        id="tipo_entrada"
                        name="tipo_entrada"
                        url={"/compras/tipos-entradas/"}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.tipo_entrada}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tipo_entrada })}
                    />
                    {formik.errors.tipo_entrada && <small className="p-error">{formik.errors.tipo_entrada}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="categoria_titulo" label="Categoria título" />
                    <MakoDropdownCategoriasHierarquicas
                        id="categoria_titulo"
                        name="categoria_titulo"
                        apenasTitulo
                        categoriaTituloSelecionavel
                        showClear
                        value={formik.values.categoria_titulo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.categoria_titulo })}
                    />
                    {formik.errors.categoria_titulo && (
                        <small className="p-error">{formik.errors.categoria_titulo}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="competencia-estoque" label="Competência de estoque" />
                    <MakoDropdownCompetenciaEstoque
                        id="competencia-estoque"
                        name="competencia_estoque"
                        habilitarInativas={true}
                        value={formik.values.competencia_estoque}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.competencia_estoque })}
                    />
                    {formik.errors.competencia_estoque && (
                        <small className="p-error">{formik.errors.competencia_estoque}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="competencia_financeira" label="Competência financeira" />
                    <MakoDropdownCompetenciaFinanceira
                        id="competencia_financeira"
                        name="competencia_financeira"
                        habilitarInativas
                        value={formik.values.competencia_financeira}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.competencia_financeira })}
                    />
                    {formik.errors.competencia_financeira && (
                        <small className="p-error">{formik.errors.competencia_financeira}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="entradaxml__numero_nf__unaccent__icontains" label="Número NF" />
                    <InputText
                        id="entradaxml__numero_nf__unaccent__icontains"
                        name="entradaxml__numero_nf__unaccent__icontains"
                        value={formik.values.entradaxml__numero_nf__unaccent__icontains}
                        onChange={formik.handleChange}
                        className={classNames({
                            "p-invalid": formik.errors.entradaxml__numero_nf__unaccent__icontains,
                        })}
                    />
                    {formik.errors.entradaxml__numero_nf__unaccent__icontains && (
                        <small className="p-error">{formik.errors.entradaxml__numero_nf__unaccent__icontains}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="entradaxml__xml__contains" label="Chave XML" />
                    <InputText
                        id="entradaxml__xml__contains"
                        name="entradaxml__xml__contains"
                        value={formik.values.entradaxml__xml__contains}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.entradaxml__xml__contains })}
                    />
                    {formik.errors.entradaxml__xml__contains && (
                        <small className="p-error">{formik.errors.entradaxml__xml__contains}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="entradaxml__cpf_cnpj_emitente" label="CPF / CNPJ do emitente NF" />
                    <InputText
                        id="entradaxml__cpf_cnpj_emitente"
                        name="entradaxml__cpf_cnpj_emitente"
                        value={formik.values.entradaxml__cpf_cnpj_emitente}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.entradaxml__cpf_cnpj_emitente })}
                    />
                    {formik.errors.entradaxml__cpf_cnpj_emitente && (
                        <small className="p-error">{formik.errors.entradaxml__cpf_cnpj_emitente}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="ordem_compra_principal" label="Nº de ordem de compra" />
                    <Dropdown
                        id="ordem_compra_principal"
                        name="ordem_compra_principal"
                        url={`/compras/ordens-compra/?empresa=${empresaSelecionadaId}`}
                        optionValue="id"
                        optionLabel="label"
                        aposBuscar={aposBuscarOC}
                        value={formik.values.ordem_compra_principal}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.ordem_compra_principal })}
                    />
                    {formik.errors.ordem_compra_principal && (
                        <small className="p-error">{formik.errors.ordem_compra_principal}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="conhecimento_transporte" label="Conhecimento de transporte" />
                    <InputText
                        id="conhecimento_transporte"
                        name="conhecimento_transporte"
                        value={formik.values.conhecimento_transporte}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.conhecimento_transporte })}
                    />
                    {formik.errors.conhecimento_transporte && (
                        <small className="p-error">{formik.errors.conhecimento_transporte}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="itementrada__sku__codigo" label="EAN produto" />
                    <InputText
                        id="itementrada__sku__codigo"
                        name="itementrada__sku__codigo"
                        value={formik.values.itementrada__sku__codigo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.itementrada__sku__codigo })}
                    />
                    {formik.errors.itementrada__sku__codigo && (
                        <small className="p-error">{formik.errors.itementrada__sku__codigo}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="status" label="Situação" />
                    <Dropdown
                        id="status"
                        name="status"
                        options={STATUS_ENTRADA}
                        optionValue="value"
                        optionLabel="label"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="usuario_cadastro" label="Usuário cadastro" />
                    <MakoInputPerfil
                        id="usuario_cadastro"
                        name="usuario_cadastro"
                        value={formik.values.usuario_cadastro}
                        somenteAtivo
                        query="id,nome,identificacao"
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.usuario_cadastro })}
                    />
                    {formik.errors.usuario_cadastro && (
                        <small className="p-error">{formik.errors.usuario_cadastro}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="usuario_finalizacao" label="Usuário finalização" />
                    <MakoInputPerfil
                        id="usuario_finalizacao"
                        name="usuario_finalizacao"
                        value={formik.values.usuario_finalizacao}
                        somenteAtivo
                        query="id,nome,identificacao"
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.usuario_finalizacao })}
                    />
                    {formik.errors.usuario_finalizacao && (
                        <small className="p-error">{formik.errors.usuario_finalizacao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="usuario_validacao" label="Usuario validação" />
                    <MakoInputPerfil
                        id="usuario_validacao"
                        name="usuario_validacao"
                        value={formik.values.usuario_validacao}
                        somenteAtivo
                        query="id,nome,identificacao"
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.usuario_validacao })}
                    />
                    {formik.errors.usuario_validacao && (
                        <small className="p-error">{formik.errors.usuario_validacao}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="itementrada__sku" label="Contém o produto" />
                    <MakoBuscaSkuPersonalizada
                        skuValue={formik.values.itementrada__sku}
                        skuChange={(e) => setFieldValue("itementrada__sku", e)}
                        skuError={formik.errors.itementrada__sku}
                    />
                    {formik.errors.itementrada__sku && (
                        <small className="p-error">{formik.errors.itementrada__sku}</small>
                    )}
                </div>
            </div>
            <MakoActionsButtons className="p-jc-end">
                <Button type="submit" icon={MAKO_ICONS.FILTRAR} label="Filtrar" />
                <Button
                    type="reset"
                    icon={MAKO_ICONS.LIMPAR_FILTROS}
                    label="Limpar"
                    onClick={formik.resetForm}
                    className="p-button-warning"
                />
                <Button
                    type="reset"
                    label="Cancelar"
                    icon={MAKO_ICONS.CANCEL}
                    onClick={onCancel}
                    className="p-button-danger"
                />
            </MakoActionsButtons>
        </form>
    );
};
