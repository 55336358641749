import { useContext } from "react";

import VendaContext from "@/contexts/vendaContext";

const useVenda = () => {
    const venda = useContext(VendaContext);

    return venda;
};

export default useVenda;
