import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import MakoListagem from "@/components/MakoListagem";
import { ProdutoGradeAtributosFormModal } from "./formModal";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { ConfirmDialog } from "primereact/confirmdialog";
import useToast from "@/hooks/useToast";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";

const URL_VOLTAR = "/produto/cadastros/grade-atributos";

export const ProdutoGradeAtributosForm = () => {
    const history = useHistory();
    const [atributo, setAtributo] = useState("");
    const [confirmaInserir, setConfirmaInserir] = useState(false);
    const [gradeDialog, setGradeDialog] = useState(false);
    const [gradeAtributo, setGradeAtributo] = useState(null);
    const listagemRef = useRef(null);
    const { showSuccess } = useToast();
    const { httpPost } = useHttp();

    const handleInsere = async () => {
        const handlers = {
            201: () => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Atributo cadastrado com sucesso!",
                    life: 2000,
                });
                listagemRef.current?.buscarDados();
            },
        };

        await httpPost(
            {
                url: `/produtos/atributos/`,
                body: { codigo: atributo, nome: atributo },
            },
            handlers
        );
    };

    const cancelar = () => {
        history.push(URL_VOLTAR);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GRADEATRIBUTO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de Atributo"
                    tooltipOptions={{ position: "left" }}
                    type="button"
                    onClick={() => exibirGradeDialog(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GRADEATRIBUTO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={`/produtos/grades-atributos/`}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir a grade atributo código: "}
                            <b>{rowData.codigo}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar grade de atributos"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="A grade de atributos não pode ser excluída."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const actionValorNumerico = (rowData) => {
        let valor;
        if (rowData.valor_numerico === true) {
            valor = "Sim";
        } else {
            valor = "Não";
        }
        return (
            <>
                <div className="p-text-normal">{valor}</div>
            </>
        );
    };

    const actionCalculaMedida = (rowData) => {
        let valor;
        if (rowData.calcula_medida === true) {
            valor = "Sim";
        } else {
            valor = "Não";
        }
        return (
            <>
                <div className="p-text-normal">{valor}</div>
            </>
        );
    };

    const actionUsaPreco = (rowData) => {
        let valor;
        if (rowData.usa_preco_medida_servico === true) {
            valor = "Sim";
        } else {
            valor = "Não";
        }
        return (
            <>
                <div className="p-text-normal">{valor}</div>
            </>
        );
    };

    const actionService = (rowData) => {
        let service;
        if (rowData.servico_venda !== null) {
            service = rowData.servico_venda.nome;
        } else {
            service = "";
        }

        return (
            <>
                <div className="p-text-normal">{service}</div>
            </>
        );
    };

    const esconderGradeDialog = () => {
        setGradeAtributo(null);
        setGradeDialog(false);
    };

    const exibirGradeDialog = (grade) => {
        setGradeAtributo(grade);
        setGradeDialog(true);
    };

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "120px" } },
        { field: "atributo.nome", header: "Atributo", style: { minWidth: "250px" } },
        { field: "codigo", header: "Grade", style: { minWidth: "200px" } },
        { field: "opcoes_valor", header: "Opções", style: { minWidth: "350px" } },
        {
            field: "valor_numerico",
            header: "Valor Numérico",
            action: (e) => actionValorNumerico(e),
            style: { minWidth: "200px" },
        },
        {
            field: "calcula_medida",
            header: "Calcula Medida",
            action: (e) => actionCalculaMedida(e),
            style: { minWidth: "120px" },
        },
        {
            field: "servico_venda",
            header: "Serviço",
            action: (e) => actionService(e),
            style: { minWidth: "200px" },
        },
        {
            field: "usa_preco_medida_servico",
            header: "Usa Preço Medida Serviço",
            action: (e) => actionUsaPreco(e),
            style: { minWidth: "250px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "200px" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    return (
        <PageBase>
            <h4>Adicionar Grade Atributo</h4>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <div className="p-inputgroup">
                        <MakoAutoComplete
                            id="atributo"
                            name="atributo"
                            value={atributo}
                            onChange={(e) => setAtributo(e.target.value)}
                            minCaracteresBusca={2}
                            field="nome"
                            urlSearch="produtos/atributos/?limit=100&search="
                            placeholder="Comece a digitar dados de atributos para pesquisar... (min 2 caracteres)"
                        />
                        {atributo?.id ? null : (
                            <MakoControleAcesso
                                permissao={[permissoes.PRODUTO_CADASTRO_ATRIBUTO_INCLUIR]}
                                componente={Button}
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-success"
                                tooltip="Cadastrar atributo"
                                disabled={!atributo}
                                tooltipOptions={{ position: "left" }}
                                onClick={() => setConfirmaInserir(true)}
                            />
                        )}
                    </div>
                </div>
            </div>
            <>
                {atributo?.id ? (
                    <Button
                        type="button"
                        label="Clique aqui para adicionar valor ao atributo"
                        title="Adicionar valor ao atributo"
                        className="p-button-sm p-col-align-end p-mb-2"
                        onClick={() => setGradeDialog(true)}
                    ></Button>
                ) : (
                    <p>Pesquisar um atributo para continuar.</p>
                )}
                {atributo?.id ? (
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        urlPesquisa={`/produtos/grades-atributos/?atributo=${atributo.id}`}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            scrollable: true,
                            frozenWidth: "200px",
                            scrollHeight: "250px",
                        }}
                    />
                ) : null}
            </>
            <Dialog
                header={`Adicionar Grade Atributo (${atributo.label})`}
                visible={gradeDialog}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "65vw", display: "block" }}
                onHide={() => esconderGradeDialog()}
            >
                <ProdutoGradeAtributosFormModal
                    listagemRef={listagemRef}
                    atributo={atributo?.id}
                    esconderDialog={esconderGradeDialog}
                    gradeAtributo={gradeAtributo}
                />
            </Dialog>
            <br></br>
            <div className="p-grid p-col-12 p-md-6">
                <Button label="Voltar" type="reset" className="p-button-danger p-mr-2 p-mb-6" onClick={cancelar} />
            </div>
            <ConfirmDialog
                visible={confirmaInserir}
                onHide={() => setConfirmaInserir(false)}
                header="Confirmação de inserção"
                message={
                    atributo && (
                        <span>
                            {"Deseja cadastrar o atributo: "}
                            <b>{atributo}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={handleInsere}
                acceptLabel="Sim"
                acceptClassName="p-button-success"
                reject={() => setConfirmaInserir(false)}
                rejectLabel="Não"
            />
        </PageBase>
    );
};
