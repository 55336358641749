import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";

import { useFormik } from "formik";
import classNames from "classnames";

import { Label } from "../Label";

export const COMPONENTES_ESPECIAIS = {
    NCM: "NCM",
    CUSTOMFIELD: "CUSTOMFIELD",
    EMPRESA: "EMPRESA",
    DATEPERIOD: "DATEPERIOD",
    SKU: "SKU",
    ORDEMCOMPRA: "ORDEMCOMPRA",
};

// NÃO ME ORGULHO DO COMPONENTE, DA PRA FAZER MELHOR (/;-;)/

const FormComponent = (
    {
        formikProps: { initialValues = {}, onSubmit = async () => {}, ...restProps },
        camposFormularios = [],
        watchValues = null,
        children,
    },
    ref
) => {
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values, formikHelpers) => {
            formik.setStatus();
            onSubmit(values, formikHelpers);
        },
        enableReinitialize: true,
        ...restProps,
    });

    const getFormikInstance = () => {
        return formik;
    };

    const gerarComponenteSku = useCallback((Component, campo, formik) => {
        return (
            <Component
                skuValue={formik.values[campo.inputName]}
                skuChange={(e) => formik.setFieldValue(campo.inputName, e)}
                skuError={formik.errors[campo.inputName]}
                {...campo.componentProps}
            />
        );
    }, []);

    const gerarComponenteNcm = useCallback((Component, campo, formik) => {
        return (
            <Component
                fieldValue={formik.setFieldValue}
                valorNcm={formik.values[campo.inputName]}
                fieldName={campo.inputName}
                {...campo.componentProps}
                className={classNames(campo?.className, {
                    "p-invalid": formik.errors[campo.inputName] || (formik.status && formik.status[campo.inputName]),
                })}
            />
        );
    }, []);

    const gerarComponenteCustomField = useCallback((Component, campo, formik) => {
        let { contentType = null } = campo.componentProps;
        if (typeof contentType === "function") contentType = contentType(formik.values);
        return (
            <Component
                onSubmit={(e) => formik.setFieldValue(campo.inputName, e)}
                {...campo.componentProps}
                contentType={contentType}
            />
        );
    }, []);

    const gerarComponenteEmpresa = useCallback((Component, campo, formik) => {
        const { getOnChange = null, ...empresaProps } = campo.componentProps;
        let onChange = (e) => {
            if (getOnChange) getOnChange(e);
            formik.setFieldValue(campo.inputName, e?.id);
        };
        return (
            <Component
                value={formik.values[campo.inputName]}
                {...empresaProps}
                onChange={(e) => onChange(e)}
                className={classNames(campo?.className, {
                    "p-invalid": formik.errors[campo.inputName] || (formik.status && formik.status[campo.inputName]),
                })}
            />
        );
    }, []);

    const gerarComponenteDatePeriod = useCallback((Component, campo, formik) => {
        const {
            nameInicio,
            nameFinal,
            valueInicio,
            valueFinal,
            errorInicio,
            errorFinal,
            onChangeInicio,
            onChangeFinal,
            ...datePeriodProps
        } = campo.componentProps;
        const changeInicio = (e) => {
            if (onChangeInicio) onChangeInicio(e);
            else formik.handleChange(e);
        };
        const changeFinal = (e) => {
            if (onChangeFinal) onChangeFinal(e);
            else formik.handleChange(e);
        };
        return (
            <Component
                nameInicio={nameInicio}
                nameFinal={nameFinal}
                valueInicio={formik.values[valueInicio]}
                valueFinal={formik.values[valueFinal]}
                errorInicio={formik.errors[errorInicio]}
                errorFinal={formik.errors[errorFinal]}
                onChangeInicio={changeInicio}
                onChangeFinal={changeFinal}
                {...datePeriodProps}
            />
        );
    }, []);

    const gerarComponenteOrdemCompra = useCallback((Component, campo, formik) => {
        const { inputName, componentProps } = campo;
        const onChange = (e) => formik.setFieldValue(inputName, e);
        return (
            <Component
                {...(componentProps || {})}
                onChange={onChange}
                value={formik.values[inputName]}
                className={classNames(campo?.className, {
                    "p-invalid": formik.errors[campo.inputName] || (formik.status && formik.status[campo.inputName]),
                })}
            />
        );
    }, []);

    const gerarComponentEspecial = useCallback(
        (key, Component, campo, formik) => {
            switch (key) {
                case COMPONENTES_ESPECIAIS.NCM:
                    return gerarComponenteNcm(Component, campo, formik);
                case COMPONENTES_ESPECIAIS.CUSTOMFIELD:
                    return gerarComponenteCustomField(Component, campo, formik);
                case COMPONENTES_ESPECIAIS.EMPRESA:
                    return gerarComponenteEmpresa(Component, campo, formik);
                case COMPONENTES_ESPECIAIS.DATEPERIOD:
                    return gerarComponenteDatePeriod(Component, campo, formik);
                case COMPONENTES_ESPECIAIS.SKU:
                    return gerarComponenteSku(Component, campo, formik);
                case COMPONENTES_ESPECIAIS.ORDEMCOMPRA:
                    return gerarComponenteOrdemCompra(Component, campo, formik);
                default:
                    return null;
            }
        },
        [
            gerarComponenteNcm,
            gerarComponenteCustomField,
            gerarComponenteEmpresa,
            gerarComponenteDatePeriod,
            gerarComponenteSku,
            gerarComponenteOrdemCompra,
        ]
    );

    const gerarComponente = useCallback(
        (campo) => {
            const { componentEspecial, render = true } = campo;
            let _render = typeof render === "function" ? render(formik.values) : render;
            if (!_render) return null;
            const handleChangeInput = (event, getChange = null, handleChange = null) => {
                if (getChange) getChange(event);
                !handleChange ? formik.handleChange(event) : handleChange(event);
            };
            const {
                label = "",
                component = <></>,
                fieldSize = "2",
                disabled = false,
                required = null,
                handleDisable = null,
                divClassName = "",
                divStyle = null,
                inputId = "",
                inputName = "",
                componentProps = {},
            } = campo;
            const {
                eventChangeKey = "onChange",
                getOnChange = null,
                className = "",
                valueKey = "value",
                ...restProps
            } = componentProps;
            let _componentProps = {};
            let Component = component;
            if (componentEspecial) {
                Component = gerarComponentEspecial(
                    componentEspecial,
                    Component,
                    { inputId, inputName, componentProps: { ...componentProps, id: inputId, name: inputName } },
                    formik
                );
                if (componentEspecial === COMPONENTES_ESPECIAIS.DATEPERIOD) return Component;
            } else {
                _componentProps = {
                    ...restProps,
                    id: inputId,
                    name: inputName,
                    [valueKey]: formik.values[inputName],
                    [eventChangeKey]: (e) => handleChangeInput(e, getOnChange),
                    disabled: typeof handleDisable === "function" ? handleDisable(formik.values) : disabled,
                };
            }
            return (
                <div className={`p-field p-col-12 p-md-${fieldSize} ${divClassName}`} style={divStyle}>
                    {label && (
                        <Label htmlFor={inputId} label={label} obrigatorio={required !== null ? required : disabled} />
                    )}
                    {componentEspecial ? (
                        Component
                    ) : (
                        <Component
                            {..._componentProps}
                            className={classNames(className, {
                                "p-invalid": formik.errors[inputName] || (formik.status && formik.status[inputName]),
                            })}
                        />
                    )}
                    {formik.errors[inputName] && <small className="p-error">{formik.errors[inputName]}</small>}
                    {formik.status && formik.status[inputName] && (
                        <small className="p-error">{formik.status[inputName]}</small>
                    )}
                </div>
            );
        },
        [formik, gerarComponentEspecial]
    );

    const gerarRowInputs = useCallback(
        (items = []) => {
            return (
                <div className="p-fluid p-formgrid p-grid">
                    {items.map((item) => (
                        <React.Fragment key={item.inputId}>{gerarComponente(item)}</React.Fragment>
                    ))}
                </div>
            );
        },
        [gerarComponente]
    );

    const fields = useMemo(() => {
        const rows = [];
        let row = [];
        let tamanho = 0;
        for (let i = 0; i < camposFormularios.length; i++) {
            tamanho = row.reduce((total, item) => total + parseInt(item.fieldSize), 0);
            let fieldSize = parseInt(camposFormularios[i].fieldSize);
            if (fieldSize === 12) {
                row.push(camposFormularios[i]);
                rows.push(gerarRowInputs(row));
                row = [];
            } else {
                if (!tamanho + fieldSize > 12) row = [];
                else {
                    tamanho = tamanho + fieldSize;
                    row.push(camposFormularios[i]);
                }
                if (!(i + 1 in camposFormularios)) rows.push(gerarRowInputs(row));
            }
        }
        return rows;
    }, [camposFormularios, gerarRowInputs]);

    useEffect(() => {
        if (typeof watchValues === "function") watchValues(formik.values);
    }, [watchValues, formik.values]);

    useImperativeHandle(ref, () => ({ ...formik, getFormikInstance, setStatus: formik.setStatus }));

    return (
        <form onSubmit={formik.handleSubmit}>
            {fields.map((field, index) => (
                <React.Fragment key={index}>{field}</React.Fragment>
            ))}
            {children}
        </form>
    );
};

export const MakoFormGerador = forwardRef(FormComponent);
