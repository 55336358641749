import React from "react";

import { RecebimentoProvider } from "@/contexts/recebimentoContext";
import { TabRecebimentos } from "./tab";

export const TabRecebimentosContext = (props) => {
    return (
        <RecebimentoProvider>
            <TabRecebimentos agrupador={props.location.state} />
        </RecebimentoProvider>
    );
};
