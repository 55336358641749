import React, { useState, useRef, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { Tag } from "primereact/tag";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { UtilizarForm } from "./formUtilizar";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useCaixa from "@/hooks/useCaixa";
import { SITUACAO_VENDA_VOUCHER } from "@/assets/constants/constants";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import {
    ClienteFiltroTemplate,
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";

const BASE_URL = "/vendas/voucher/";

export const VoucherPage = () => {
    const [cancelDialog, setCancelDialog] = useState(false);
    const [url, setUrl] = useState(BASE_URL);
    const [filtro, setFiltro] = useState("A");
    const [voucher, setVoucher] = useState(null);
    const [visible, setVisible] = useState(false);
    const { caixa, loadCaixa } = useCaixa();
    const { httpPatch } = useHttp();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess } = useToast();

    const history = useHistory();

    const listagemRef = useRef(null);
    const menuRef = useRef();

    useClearRefs(menuRef, listagemRef);

    const confirmarCancelamento = (value) => {
        setVoucher(value);
        setCancelDialog(true);
    };

    const esconderDeleteDialog = () => {
        setCancelDialog(false);
    };

    const cancelarVoucher = async () => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Voucher cancelado com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
            },
        };

        showLoading();
        await httpPatch({ url: `/vendas/voucher/${voucher.id}/`, body: { situacao: "C" } }, handlers);
        hideLoading();

        setCancelDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        const items = [
            {
                label: "Selecione uma ação",
                items: [
                    {
                        label: "Utilizar voucher",
                        command: () => handleModal(rowData),
                        disabled: rowData.situacao === "U" || rowData.situacao === "C",
                    },
                ],
            },
        ];

        return (
            <div className="actions">
                <Menu model={items} popup ref={menuRef} id="popup_menu" />
                <MakoControleAcesso
                    componente={Button}
                    permissao={[
                        permissoes.FINANCEIRO_FINANCEIRO_CAIXA_MOVIMENTAR,
                        permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_MOVIMENTAR,
                        permissoes.VENDAS_CADASTRO_VOUCHER_UTILIZAR,
                    ]}
                    exibirDesabilitadoSemPermissao
                    icon="pi pi-cog"
                    className="p-button-secondary p-button-rounded"
                    aria-controls="popup_menu"
                    aria-haspopup
                    disabled={!caixa}
                    onClick={(e) => menuRef.current.toggle(e)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_VOUCHER_CANCELAR]}
                    componente={Button}
                    disabled={rowData.situacao === "C" || rowData.situacao === "U"}
                    icon="pi pi-times-circle"
                    className="p-button-rounded p-button-danger p-ml-2"
                    onClick={() => confirmarCancelamento(rowData)}
                    tooltip="Cancelar voucher"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const filtroStatus = (status) => {
        setFiltro(status.value);
        setUrl(BASE_URL + `?situacao=${status.value}`);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_VOUCHER_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                // disabled
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/cadastros/voucher/form")}
            />
            <Dropdown value={filtro} options={SITUACAO_VENDA_VOUCHER} placeholder="Selecione" onChange={filtroStatus} />
        </>
    );

    const SituacaoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: SITUACAO_VENDA_VOUCHER,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "ID", style: { width: "8%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        { field: "cliente", header: "Cliente", filter: true, filterElement: ClienteFiltroTemplate },
        { field: "referencia", header: "Referência", filter: true, filterElement: TextoFiltroTemplate },
        { field: "valor", header: "Valor", money: true, filter: true, filterElement: MoedaFiltroTemplate },
        {
            field: "data_validade",
            header: "Validade",
            dateFormat: "dd/MM/yyyy",
            style: { width: "15%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "data_emissao",
            header: "Emissão",
            dateFormat: "dd/MM/yyyy",
            style: { width: "15%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "situacao",
            header: "Situação",
            action: ({ situacao }) => {
                const _tag = SITUACAO_VENDA_VOUCHER.find((item) => item.value === situacao);
                return <Tag value={_tag.label.toLocaleUpperCase()} severity={_tag.tag} />;
            },
            filter: true,
            filterElement: SituacaoFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    const onFinish = () => {
        listagemRef.current?.buscarDados();
        fecharModal();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const handleModal = (value) => {
        setVoucher(value);
        setVisible(() => true);
    };

    useEffect(() => {
        loadCaixa();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Vouchers"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    codigo: { value: null, matchMode: "equals" },
                    cliente: { value: null, matchMode: "equals" },
                    referencia: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    valor: { value: null, matchMode: "equals" },
                    data_validade: { value: null, matchMode: "equals" },
                    data_emissao: { value: null, matchMode: "equals" },
                    situacao: { value: null, matchMode: "equals" },
                }}
            />
            <ConfirmDialog
                visible={cancelDialog}
                onHide={esconderDeleteDialog}
                header="Confirmação"
                message="Deseja realmente cancelar este voucher?"
                icon="pi pi-info-circle p-mr-3"
                accept={cancelarVoucher}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderDeleteDialog}
                rejectLabel="Não"
            />
            <Dialog
                header={`Utilizar voucher: ${voucher?.codigo}`}
                visible={visible}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "48vw" }}
                onHide={() => fecharModal()}
            >
                <UtilizarForm voucher={voucher} onFinish={onFinish} fecharModal={fecharModal} caixa={caixa} />
            </Dialog>
        </PageBase>
    );
};
