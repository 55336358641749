import React from "react";
import { Button } from "primereact/button";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const COLOR_ATIVO = "p-button-success";
const COLOR_INATIVO = "p-button-danger";

export const MakoButtonAtivar = ({ ativo = true, className = "", ...props }) => {
    return (
        <Button
            icon={ativo ? MAKO_ICONS.INATIVO : MAKO_ICONS.ATIVO}
            className={`p-button-rounded ${ativo ? COLOR_INATIVO : COLOR_ATIVO} ${className}`}
            tooltip={ativo ? "Desativar" : "Ativar"}
            {...props}
        />
    );
};
