import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { TreeSelect } from "primereact/treeselect";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { Dropdown } from "@/components/Dropdown";
import { dataToStr } from "@/assets/util/datas";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

const url = "/pessoas/hierarquia-funcional/";

export const HierarquiaFuncionalForm = (props) => {
    const [hierarquias, setHierarquias] = useState([]);
    const { empresaSelecionadaId } = useEmpresa();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut, httpGet } = useHttp();
    const history = useHistory();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state?.hierarquia
            ? {
                  ...props.location.state?.hierarquia,
                  perfil: props.location.state?.hierarquia?.perfil.id,
              }
            : {
                  perfil: null,
                  nivel: 0,
                  ativo: true,
                  vigencia_inicial: new Date(),
                  vigencia_final: null,
                  pessoa: null,
                  cargo: null,
                  superior: null,
              },
        onSubmit: handleSubmit,
    });

    const listarHierarquias = useCallback(
        async (signal) => {
            const handlers = {
                200: ({ data }) => setHierarquias(data),
            };
            await httpGet(`/pessoas/hierarquia-funcional-menu/`, { signal }, handlers);
        },
        [httpGet]
    );

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil: Yup.number().required("O campo 'empresa' é obrigatório.").typeError("Selecione uma empresa."),
                pessoa: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'pessoa' é obrigatório"),
                    })
                    .typeError("Informe um 'pessoa' válido"),
                cargo: Yup.number().required("O campo 'cargo' é obrigatório").typeError("Informe um 'cargo' válido"),
                superior: Yup.number().nullable().notRequired(),
                nivel: Yup.number().required("O campo 'nivel' é obrigatório").typeError("Informe um 'nivel' válido"),
                vigencia_inicial: Yup.date().nullable().typeError("Informe uma 'vigência inicial' válida"),
                vigencia_final: Yup.date().when("vigencia_inicial", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            Yup.ref("vigencia_inicial") || new Date(),
                            "O campo 'vigência final' não pode ser anterior a inicial"
                        )
                        .nullable()
                        .typeError("Informe uma 'vigência final' válida"),
                }),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const body = {
                ...values,
                pessoa: values.pessoa.id,
                vigencia_inicial: dataToStr(values.vigencia_inicial, "yyyy-MM-dd"),
                vigencia_final: dataToStr(values.vigencia_final, "yyyy-MM-dd"),
            };
            if (!values.id) {
                values.perfil = empresaSelecionadaId;
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Hierarquia cadastrada com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };
                showLoading();
                await httpPost({ url, body }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Hierarquia alterada com sucesso!",
                            life: 1500,
                        });
                        voltarParaListagem();
                    },
                };
                showLoading();
                await httpPut({ url: `${url}${values.id}/`, body }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        history.push("/gestao/cadastros/hierarquia-funcional");
    };

    const handleChangeSuperior = (e) => {
        const { node } = e;
        setFieldValue("nivel", node.data.nivel + 1);
    };

    useEffect(() => {
        const controller = new AbortController();
        listarHierarquias(controller.signal);
        return () => {
            controller.abort();
        };
    }, [listarHierarquias]);

    return (
        <PageBase>
            {<h3>{!formik.values.id ? "Nova hierarquia funcional" : "Manutenção de hierarquia funcional"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="perfil" label="Empresa" obrigatorio />
                        <MakoDropdownEmpresas
                            id="perfil"
                            name="perfil"
                            value={formik.values.perfil}
                            onChange={(e) => setFieldValue("perfil", e.id)}
                        />
                        {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="pessoa" label="Pessoa" obrigatorio />
                        <MakoInputPerfil
                            id="pessoa"
                            name="pessoa"
                            value={formik.values.pessoa}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.pessoa })}
                            autoComplete="off"
                        />
                        {formik.errors.pessoa && <small className="p-error">{formik.errors.pessoa}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="cargo" label="Cargo" obrigatorio />
                        <Dropdown
                            id="cargo"
                            name="cargo"
                            url="/pessoas/cargos/"
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.cargo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cargo })}
                        />
                        {formik.errors.cargo && <small className="p-error">{formik.errors.cargo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="superior" label="Superior" />
                        <div className="p-inputgroup">
                            <TreeSelect
                                id="superior"
                                name="superior"
                                options={hierarquias}
                                filter
                                filterBy="pessoa.nome"
                                value={formik.values.superior}
                                placeholder="Selecione"
                                onChange={formik.handleChange}
                                onNodeSelect={handleChangeSuperior}
                                className={classNames({ "p-invalid": formik.errors.superior })}
                            />
                            <Button
                                type="button"
                                icon="pi pi-times"
                                className="p-button-secondary"
                                tooltip="Limpar"
                                onClick={() => setFieldValue("superior", null)}
                            />
                        </div>

                        {formik.errors.superior && <small className="p-error">{formik.errors.superior}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-1">
                        <Label htmlFor="nivel" label="Nivel" obrigatorio />
                        <InputNumber
                            id="nivel"
                            name="nivel"
                            disabled
                            showButtons
                            min={0}
                            value={formik.values.nivel}
                            className={classNames({ "p-invalid": formik.errors.nivel })}
                        />
                        {formik.errors.nivel && <small className="p-error">{formik.errors.nivel}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="vigencia_inicial" label="Vigência inicial" />
                        <MakoCalendar
                            id="vigencia_inicial"
                            name="vigencia_inicial"
                            valueCalendar={formik.values.vigencia_inicial}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vigencia_inicial })}
                        />
                        {formik.errors.vigencia_inicial && (
                            <small className="p-error">{formik.errors.vigencia_inicial}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="vigencia_final" label="Vigência final" />
                        <MakoCalendar
                            id="vigencia_final"
                            name="vigencia_final"
                            valueCalendar={formik.values.vigencia_final}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vigencia_final })}
                        />
                        {formik.errors.vigencia_final && (
                            <small className="p-error">{formik.errors.vigencia_final}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-mt-5 p-col-12 p-md-3">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <Label htmlFor="ativo" label="Ativo?" />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        icon="pi pi-save"
                        label="Gravar"
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        type="button"
                        icon="pi pi-angle-double-left"
                        label="Sair"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
