import React from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";

import permissoes from "@/assets/constants/permissoes";

const { OUTROS_PARAMETROS_MANUTENCAO } = permissoes;

const BASE_PARAMETRO = { id: null, chave: null };

export const Remover = ({ className = "", onSuccessCallback = () => {}, parametro = BASE_PARAMETRO }) => {
    return (
        <MakoControleAcesso
            componente={BotaoDelete}
            permissao={[OUTROS_PARAMETROS_MANUTENCAO]}
            url="/configuracoes/parametros"
            objetoId={parametro.id}
            exigeConfirmacao
            msgConfirmacao={
                <span>
                    Deseja realmente excluir o parâmetro <b>{parametro.chave}</b>?
                </span>
            }
            classNames={className}
            tooltip="Deletar parâmetro"
            tooltipOptions={{ position: "left" }}
            msgToastErroExclusao="O parâmetro não pode ser excluído."
            onDelete={onSuccessCallback}
        />
    );
};
