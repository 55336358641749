import React from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

import classNames from "classnames";
import * as Yup from "yup";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoButton as Button } from "@/components/MakoButton";
import useEmpresa from "@/hooks/useEmpresa";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Dropdown } from "@/components/Dropdown";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { Label } from "@/components/Label";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

const url = "/plano-operacional/setores/";
const urlvoltar = "/operacional/plano-operacional/setores";

export const OperacionalSetoresForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpPost, httpPut } = useHttp();
    const { showSuccess } = useToast();
    const history = useHistory();

    const setorVazio = {
        empresa: empresaSelecionadaId,
        nome: "",
        descricao: "",
        responsavel: null,
        centro_resultado: null,
        setor: null,
    };

    const formik = useFormik({
        initialValues: props.location.state?.setores
            ? {
                  ...props.location.state.setores,
                  centro_resultado: props.location.state.setores.centro_resultado.id,
                  setor: props.location.state.setores.setor?.id || null,
              }
            : setorVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo é obrigatório.").max(30),
                descricao: Yup.string().required("O campo é obrigatório.").max(255),
                responsavel: Yup.object().required("O campo é obrigatório."),
                centro_resultado: Yup.number().required("O campo é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Setor cadastrado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPost({ url, body: { ...values, responsavel: values.responsavel.id } }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Setor alterado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push(urlvoltar);
                    },
                };
                showLoading();
                await httpPut(
                    {
                        url: `${url}${values.id}/`,
                        body: {
                            ...values,
                            empresa: values.empresa.id,
                            responsavel: values.responsavel.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo setores" : "Manutenção de setores"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="nome" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                            autoComplete="off"
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="responsavel" label="Responsável" obrigatorio />
                        <MakoInputPerfil
                            id="responsavel"
                            name="responsavel"
                            somenteAtivo
                            value={formik.values.responsavel}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.responsavel })}
                        />
                        {formik.errors.responsavel && <small className="p-error">{formik.errors.responsavel}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="centro_resultado" label="Centro de resultado" obrigatorio />
                        <Dropdown
                            id="centro_resultado"
                            name="centro_resultado"
                            placeholder="Selecione um centro de resultados"
                            url="/plano-operacional/centros-resultados/"
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            value={formik.values.centro_resultado}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_resultado })}
                        />
                        {formik.errors.centro_resultado && (
                            <small className="p-error">{formik.errors.centro_resultado}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="setor" label="Setor" />
                        <Dropdown
                            id="setor"
                            name="setor"
                            placeholder="Selecione um setor"
                            url="/plano-operacional/setores/"
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            showClear
                            value={formik.values.setor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.setor })}
                        />
                        {formik.errors.setor && <small className="p-error">{formik.errors.setor}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="reset"
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
