import React, { forwardRef, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import { Label } from "@/components/Label";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoButton as Button } from "@/components/MakoButton";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

const Modal = ({ onEdit }, ref) => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPut } = useHttp();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            id: null,
            email: null,
            username: "",
            empresa_padrao: null,
            date_joined: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                email: Yup.string().required("O campo 'email' é obrigatório."),
                username: Yup.string()
                    .trim("Não pode ser em branco.")
                    .required("O campo 'nome de usuário' é obrigatório."),
                empresa_padrao: Yup.number().required("O campo 'empresa padrão' é obrigatório."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Usuário editado com sucesso!",
                        life: 1500,
                    });
                    resetForm();
                    setVisible(false);
                    if (typeof onEdit === "function") onEdit();
                },
            };
            showLoading();
            await httpPut({ url: `/pessoas/editar-usuario/${values.id}/`, body: values }, handlers);
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = ({ usuario, emailperfil_set }) => {
        setVisible(true);
        setEmails(emailperfil_set);
        setValues({
            id: usuario.id,
            email: usuario.email,
            username: usuario.username,
            empresa_padrao: usuario.empresa_padrao,
            date_joined: usuario.date_joined,
        });
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            header="Editar usuário"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "30vw" }}
            onHide={() => setVisible(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid ">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="empresa_padrao" label="Empresa padrão" obrigatorio />
                        <MakoDropdownEmpresas
                            id="empresa_padrao"
                            name="empresa_padrao"
                            showClear={false}
                            value={formik.values.empresa_padrao}
                            onChange={(e) => formik.setFieldValue("empresa_padrao", e.id)}
                            className={classNames({ "p-invalid": formik.errors.empresa_padrao })}
                        />
                        {formik.errors.empresa_padrao && (
                            <small className="p-error">{formik.errors.empresa_padrao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12">
                        <Label htmlFor="email" label="Email" obrigatorio />
                        <Dropdown
                            id="email"
                            name="email"
                            options={emails}
                            optionValue="email"
                            optionLabel="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.email && <small className="p-error">{formik.errors.email}</small>}
                    </div>
                    <div className="p-field p-col-12">
                        <Label htmlFor="username" label="Nome de usuário" obrigatorio />
                        <InputText
                            id="username"
                            name="username"
                            autoComplete="off"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.username })}
                        />
                        {formik.errors.username && <small className="p-error">{formik.errors.username}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="date_joined" label="Data de criação" />
                        <MakoCalendarTime
                            id="date_joined"
                            name="date_joined"
                            valueCalendar={formik.values.date_joined}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                </div>
                <MakoActionsButtons>
                    <Button type="submit" icon={MAKO_ICONS.GRAVAR} label="Gravar" loading={loading} />
                </MakoActionsButtons>
            </form>
        </Dialog>
    );
};

export const ModalEditarUsuario = forwardRef(Modal);
