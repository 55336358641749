import React, { memo, useCallback, useEffect, useRef, useState, useMemo } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { DataScroller } from "primereact/datascroller";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoButton as Button } from "@/components/MakoButton";

import { PESSOAS_ENDERECOS_TIPOS_IDENTIFICACAO } from "@/assets/constants/parametros";
import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";

import usePessoa from "@/hooks/usePessoa";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Label } from "@/components/Label";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useHttp from "@/hooks/useHttp";

const EnderecoForm = () => {
    const [loadingMunicipios, setLoadingMunicipio] = useState(false);
    const [loadingEstados, setLoadingEstados] = useState(false);
    const [loadingPaises, setLoadingPaises] = useState(false);
    const [tiposEndereco, setTiposEndereco] = useState([]);
    const [loadingTags, setLoadingTags] = useState(false);
    const [tagsEndereco, setTagsEndereco] = useState([]);
    const [loadingCEP, setLoadingCEP] = useState(false);
    const [municipios, setMunicipios] = useState([]);
    const [endereco, setEndereco] = useState(null);
    const [estados, setEstados] = useState([]);
    const [paises, setPaises] = useState([]);
    const [visible, setVisible] = useState(false);

    const menuAcoesRef = useRef(null);

    const { enderecos, handleEndereco, handleEnderecoPrincipal, checarInformacao } = usePessoa();
    const { showSuccess, showError } = useToast();
    const { httpGet } = useHttp();
    const { getParam } = useParam();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            identificacao: "",
            pais: "BR",
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            estado: null,
            cidade: null,
            ponto_referencia: "",
            principal: false,
            data_alteracao: new Date(),
            ativo: true,
            _status: OP_CRUD_DJANGO.novo,
            tag_endereco: null,
        },
        onSubmit: handleSubmit,
    });

    const listarPaises = useCallback(async () => {
        const handlers = {
            200: ({ data }) => setPaises(data.results),
        };
        setLoadingPaises(true);
        await httpGet({ url: "/pessoas/paises?limit=300" }, handlers);
        setLoadingPaises(false);
    }, [httpGet]);

    const listarEstados = useCallback(async () => {
        if (formik.values.pais) {
            const pais = paises.find((item) => item.sigla === formik.values.pais);
            if (pais) {
                const handlers = {
                    200: ({ data }) => setEstados(data.results),
                };
                setLoadingEstados(true);
                await httpGet(
                    {
                        url: `/pessoas/estados?query={id,nome,uf,codigo_uf}&pais__sigla=${pais.sigla}&limit=100`,
                    },
                    handlers
                );
                setLoadingEstados(false);
            }
        }
    }, [formik.values.pais, paises, httpGet]);

    const listarTagsEndereco = useCallback(async () => {
        const handlers = {
            200: ({ data }) => setTagsEndereco(data.results),
        };
        setLoadingTags(true);
        await httpGet({ url: `/pessoas/tags-enderecos-perfis?limit=300` }, handlers);
        setLoadingTags(false);
    }, [httpGet]);

    const listarCidadesPorEstado = useCallback(async () => {
        if (formik.values.estado) {
            const handlers = {
                200: ({ data }) => setMunicipios(data.results),
            };
            setLoadingMunicipio(true);
            await httpGet(
                { url: `/pessoas/cidades/?estado=${formik.values.estado}&query={id,nome}&limit=1000&ordering=nome` },
                handlers
            );
            setLoadingMunicipio(false);
        }
    }, [formik.values.estado, httpGet]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                identificacao: Yup.string().required("O campo 'identificação do endereço' é obrigatório."),
                cep: Yup.string().required("O campo 'cep' é obrigatório."),
                logradouro: Yup.string().required("O campo 'logradouro' é obrigatório."),
                numero: Yup.string().required("O campo 'numero' é obrigatório."),
                bairro: Yup.string().required("O campo 'bairro' é obrigatório."),
                pais: Yup.string().required("O campo 'país' é obrigatório.").typeError("Selecione um país."),
                estado: Yup.number().required("O campo 'estado' é obrigatório.").typeError("Selecione um estado."),
                cidade: Yup.number()
                    .required("O campo 'municipio' é obrigatório.")
                    .typeError("Selecione um municipio."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let estado = estados.find((el) => el.id === values.estado);
            const pais = paises.find((item) => item.sigla === values.pais);
            estado = {
                ...estado,
                pais: pais,
            };

            let cidade = municipios.find((el) => el.id === values.cidade);
            cidade = {
                ...cidade,
                estado,
            };

            const endereco = {
                ...values,
                localidade: cidade,
            };

            handleEndereco(endereco, values._status);
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const alterarDataAtualizacaoEndereco = useCallback(
        async (id) => {
            const resposta = await checarInformacao("enderecos", id);

            if (resposta === 200) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Endereço verificado com sucesso.",
                    life: 1500,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "A sua requisição não pode ser concluída.",
                    life: 3000,
                });
            }
        },
        [checarInformacao, showSuccess, showError]
    );

    const consultarCEP = async (value) => {
        if (formik.values.pais === "BR") {
            const handlers = {
                200: ({ data }) => {
                    const { logradouro, bairro, municipio, estado } = data;
                    setFieldValue("estado", estado.id);
                    setFieldValue("cidade", municipio.id);
                    setFieldValue("logradouro", logradouro);
                    setFieldValue("bairro", bairro);
                },
            };
            setLoadingCEP(true);
            await httpGet({ url: `/pessoas/consultar-cep/${value}/` }, handlers);
            setLoadingCEP(false);
        }
    };

    const excluirEndereco = () => {
        handleEndereco(endereco, OP_CRUD_DJANGO.deletar);
        setVisible(false);
    };

    const itensMenuAcoes = useMemo(() => {
        return [
            {
                label: "Checar endereço",
                icon: "pi pi-check",
                disabled: !!!endereco?.id,
                command: () => alterarDataAtualizacaoEndereco(endereco?.id),
            },
            {
                label: endereco?.principal ? "Definir como alternativo" : "Definir como prinicipal",
                icon: "pi pi-star-fill",
                command: () => handleEnderecoPrincipal(endereco),
            },
            {
                label: endereco?.ativo ? "Desativar endereço" : "Ativar endereço",
                icon: "pi pi-ban",
                command: () => handleEndereco({ ...endereco, ativo: !endereco.ativo }, OP_CRUD_DJANGO.editar),
            },
            {
                label: "Excluir endereço",
                icon: "pi pi-trash",
                command: () => setVisible(true),
            },
        ];
    }, [endereco, handleEndereco, alterarDataAtualizacaoEndereco, handleEnderecoPrincipal]);

    const itemTemplate = useCallback(
        (data) => {
            return (
                <div className="product-list-item">
                    <div className="product-list-detail">
                        <span className={`product-badge status-${data.principal ? "instock" : "lowstock"}`}>
                            {data.principal ? "Principal" : "Alternativo"}
                        </span>
                        <span className={`p-ml-2 product-badge status-${data.ativo ? "instock" : "lowstock"}`}>
                            {data.ativo ? "Ativo" : "Inativo"}
                        </span>
                        {data?._status === OP_CRUD_DJANGO.deletar && (
                            <span className={"p-ml-2 product-badge status-outofstock"}>A SER EXCLUIDO</span>
                        )}
                        <div className="p-mt-2 product-name">{`${data.logradouro}, ${data.numero}, ${
                            data.complemento ? `${data.complemento} - ` : ""
                        }${data.bairro}`}</div>
                        <div className="p-my-0 product-description">{`${data.localidade.nome} / ${data.localidade.estado.uf} - ${data.cep} - ${data.localidade.estado.pais.nome}`}</div>
                        <i className="pi pi-tag product-category-icon" />
                        <span className="product-category">{data.identificacao}</span>
                        {data.tag_endereco?.tag ? (
                            <span className="product-category">
                                {" "}
                                | <i className="pi pi-tags product-category-icon" />
                                {data.tag_endereco?.tag}
                            </span>
                        ) : null}
                    </div>
                    <div className="product-list-action">
                        <div className="p-text-right">
                            <Button
                                icon="pi pi-pencil"
                                className="p-button-rounded p-button-warning p-mr-2"
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() => {
                                    setValues({
                                        ...data,
                                        pais: data.pais.sigla ? data.pais.sigla : data.pais,
                                        tag_endereco: data.tag_endereco || null,
                                        _status: OP_CRUD_DJANGO.editar,
                                    });
                                }}
                            />
                            <Menu model={itensMenuAcoes} popup ref={menuAcoesRef} id="popup_menu" />
                            <Button
                                icon="pi pi-cog"
                                aria-haspopup
                                aria-controls="popup_menu_acoes"
                                className="p-button-rounded p-button-info"
                                onClick={(e) => {
                                    setEndereco(data);
                                    menuAcoesRef.current?.toggle(e);
                                }}
                            />
                        </div>
                        {(data._id || (data._status && data._status !== OP_CRUD_DJANGO.novo)) && (
                            <div className="product-description p-mb-0" style={{ color: "#f00" }}>
                                **Clique em <b>Finalizar</b> para confirmar**
                            </div>
                        )}
                    </div>
                </div>
            );
        },
        [setValues, itensMenuAcoes]
    );

    useEffect(() => {
        listarPaises();
    }, [listarPaises]);

    useEffect(() => {
        listarCidadesPorEstado();
    }, [listarCidadesPorEstado]);

    useEffect(() => {
        listarTagsEndereco();
        listarEstados();
    }, [listarEstados, listarTagsEndereco]);

    useEffect(() => {
        const tiposEnderecoParam = getParam(PESSOAS_ENDERECOS_TIPOS_IDENTIFICACAO);
        if (tiposEnderecoParam) {
            const tiposEnd = tiposEnderecoParam.valor.split("|");
            setTiposEndereco(tiposEnd.map((tipo) => ({ id: tipo, label: tipo })));
        }
    }, [getParam]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="identificacao" label="Identificação do endereço" obrigatorio />
                        {tiposEndereco.length > 0 ? (
                            <Dropdown
                                id="identificacao"
                                name="identificacao"
                                options={tiposEndereco}
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.identificacao}
                                onChange={formik.handleChange}
                                placeholder="Selecione..."
                                className={classNames({ "p-invalid": formik.errors.identificacao })}
                            />
                        ) : (
                            <InputText
                                id="identificacao"
                                name="identificacao"
                                value={formik.values.identificacao}
                                onChange={formik.handleChange}
                                placeholder="Sugestão: Residencial | Profissional"
                                className={classNames({ "p-invalid": formik.errors.identificacao })}
                            />
                        )}
                        {formik.errors.identificacao && (
                            <small className="p-error">{formik.errors.identificacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="pais" label="País" obrigatorio />
                        <Dropdown
                            id="pais"
                            name="pais"
                            disabled={loadingPaises}
                            placeholder={!loadingPaises ? "Selecione" : "Buscando..."}
                            options={paises}
                            optionLabel="nome"
                            optionValue="sigla"
                            filter
                            filterBy="nome"
                            value={formik.values.pais}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.pais })}
                        />
                        {formik.errors.pais && <small className="p-error">{formik.errors.pais}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="cep" label="CEP" obrigatorio />
                        <span className="p-input-icon-right">
                            {loadingCEP && <i className="pi pi-spin pi-spinner" />}
                            {formik.values.pais === "BR" ? (
                                <InputMask
                                    id="cep"
                                    name="cep"
                                    disabled={!!!formik.values.pais}
                                    value={formik.values.cep}
                                    onChange={formik.handleChange}
                                    onComplete={(e) => consultarCEP(e.value)}
                                    mask="99.999-999"
                                    className={classNames({ "p-invalid": formik.errors.cep })}
                                />
                            ) : (
                                <InputText
                                    id="cep"
                                    name="cep"
                                    disabled={!!!formik.values.pais}
                                    value={formik.values.cep}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cep })}
                                />
                            )}
                            {formik.errors.cep && <small className="p-error">{formik.errors.cep}</small>}
                        </span>
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="estado" label="Estado / Província" obrigatorio />
                        <Dropdown
                            id="estado"
                            name="estado"
                            options={estados}
                            disabled={loadingEstados}
                            placeholder={!loadingEstados ? "Selecione" : "Buscando..."}
                            optionLabel="nome"
                            optionValue="id"
                            filter
                            showClear
                            filterBy="nome"
                            value={formik.values.estado}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.estado,
                            })}
                        />
                        {formik.errors.estado && <small className="p-error">{formik.errors.estado}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="cidade" label="Município" obrigatorio />
                        <Dropdown
                            id="cidade"
                            name="cidade"
                            options={municipios}
                            disabled={loadingMunicipios}
                            placeholder={loadingMunicipios ? "Buscando os municípios..." : "Selecione..."}
                            optionLabel="nome"
                            optionValue="id"
                            filter
                            showClear
                            filterBy="nome"
                            emptyMessage="Nenhuma cidade encontrada."
                            emptyFilterMessage="Nenhuma cidade encontrada."
                            value={formik.values.cidade}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.cidade,
                            })}
                        />
                        {formik.errors.cidade && <small className="p-error">{formik.errors.cidade}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="logradouro" label="Logradouro" obrigatorio />
                        <InputText
                            id="logradouro"
                            name="logradouro"
                            value={formik.values.logradouro}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.logradouro })}
                        />
                        {formik.errors.logradouro && <small className="p-error">{formik.errors.logradouro}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="numero" label="Numero" obrigatorio />
                        <InputText
                            id="numero"
                            name="numero"
                            value={formik.values.numero}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.numero })}
                        />
                        {formik.errors.numero && <small className="p-error">{formik.errors.numero}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="complemento" label="Complemento" />
                        <InputText
                            id="complemento"
                            name="complemento"
                            value={formik.values.complemento}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="bairro" label="Bairro" obrigatorio />
                        <InputText
                            id="bairro"
                            name="bairro"
                            value={formik.values.bairro}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.bairro })}
                        />
                        {formik.errors.bairro && <small className="p-error">{formik.errors.bairro}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="ref" label="Ponto de referência" />
                        <InputText
                            id="ref"
                            name="ponto_referencia"
                            value={formik.values.ponto_referencia}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tag_endereco" label="Tag" />
                        <Dropdown
                            id="tag_endereco"
                            name="tag_endereco"
                            options={tagsEndereco}
                            disabled={loadingTags}
                            placeholder={loadingTags ? "Buscando..." : "Selecione"}
                            optionLabel="tag"
                            filter
                            filterBy="nome"
                            showClear
                            emptyMessage="Nenhuma tag encontrada."
                            emptyFilterMessage="Nenhuma tag encontrada."
                            value={formik.values.tag_endereco}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data-atualizacao" label="Última atualização" />
                        <MakoCalendar
                            id="data-atualizacao"
                            name="data_alteracao"
                            disabled
                            showIcon={false}
                            valueCalendar={formik.values.data_alteracao}
                        />
                    </div>
                </div>
                {enderecos.length === 0 && (
                    <div className="p-fluid p-formgrid p-grid p-mb-2">
                        <div className="p-field-checkbox p-col-12 p-md-3 p-mt-2">
                            <Checkbox
                                id="principal"
                                name="principal"
                                checked={formik.values.principal}
                                onChange={formik.handleChange}
                            />
                            <Label htmlFor="principal" label="Endereço principal?" />
                        </div>
                    </div>
                )}
                <CamposObrigatorios />
                <MakoActionsButtons className="p-mb-4">
                    <Button type="submit" icon={MAKO_ICONS.GRAVAR} label="Gravar" />
                    <Button
                        type="reset"
                        icon={MAKO_ICONS.LIMPAR_FORM}
                        label="Limpar"
                        className="p-button-warning"
                        onClick={formik.resetForm}
                    />
                </MakoActionsButtons>
            </form>
            <div className="list-demo">
                <DataScroller
                    value={enderecos}
                    itemTemplate={itemTemplate}
                    rows={3}
                    inline
                    scrollHeight="300px"
                    header="Endereços cadastrados"
                    emptyMessage="Nenhum endereço encontrado"
                />
            </div>
            <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message={
                    <span>
                        Deseja realmente excluir o endereço? <br />
                        <b>{endereco?.identificacao}</b>
                    </span>
                }
                header="Confirmação de exclusão"
                icon="pi pi-info-circle"
                accept={excluirEndereco}
                acceptLabel="Deletar"
                acceptClassName="p-button-danger"
                rejectLabel="Cancelar"
            />
        </>
    );
};

export default memo(EnderecoForm);
