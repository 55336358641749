import React, { useMemo } from "react";

import {
    PESSOAS_TAGPERFIL_PADRAO_TRANSPORTADOR,
    PESSOAS_TAGPERFIL_PADRAO_ENTREGADOR,
    PESSOAS_TAGPERFIL_PADRAO_MOTORISTA,
} from "@/assets/constants/parametros";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useParam from "@/hooks/useParam";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputEntregador = ({ disabled, somenteAtivo, apenasEntregador = false, ...props }) => {
    const { getParam } = useParam();
    const [formatarDocumento] = useFormatCNPJCPF();

    const tags = useMemo(() => {
        const tagEntregador = getParam(PESSOAS_TAGPERFIL_PADRAO_ENTREGADOR);
        const tagMotorista = getParam(PESSOAS_TAGPERFIL_PADRAO_MOTORISTA);
        const tagTransportador = getParam(PESSOAS_TAGPERFIL_PADRAO_TRANSPORTADOR);
        if (apenasEntregador) {
            if (tagEntregador) {
                return [tagEntregador.valor];
            }
            return null;
        }
        let tags = [];
        if (tagEntregador) {
            tags.push(tagEntregador.valor);
        }
        if (tagMotorista) {
            tags.push(tagMotorista.valor);
        }
        if (tagTransportador) {
            tags.push(tagTransportador.valor);
        }
        return tags;
    }, [getParam, apenasEntregador]);

    const url = useMemo(() => {
        const filtroAtivo = somenteAtivo ? "&ativo=true" : "";
        return `/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,enderecoperfil_set}${filtroAtivo}&tags=${tags?.join(
            ","
        )}&nome__unaccent__icontains=`;
    }, [somenteAtivo, tags]);

    const autoCompleteTransportadorTemplate = (item) => {
        return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
    };

    return (
        <>
            <MakoAutoComplete
                disabled={!!!tags || disabled}
                minCaracteresBusca={4}
                itemTemplate={autoCompleteTransportadorTemplate}
                field="nome"
                urlSearch={url}
                placeholder="Digite o nome do entregador para buscar... (min 4 caracteres)"
                {...props}
            />
            {!!!tags && <small className="p-error">Falta parametrizar a identificação do entregador</small>}
        </>
    );
};
