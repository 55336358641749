import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { Editor } from "primereact/editor";

import MakoListagem from "@/components/MakoListagem";
import { Label } from "@/components/Label";

import { GRUPO_CHAVE_PARAMETRO } from "@/assets/constants/constants";
import { gerarFiltrosUrl } from "@/assets/util/util";

const URL_BACKEND = "/configuracoes/parametros-chaves?";

const grupoChaveBodyTemplate = ({ grupo }) => {
    const value = GRUPO_CHAVE_PARAMETRO.find(({ value }) => value === grupo);
    if (value) return <span>{value.label}</span>;
    return <span>--</span>;
};

const BASE_COLUMNS = [
    {
        field: "descricao",
        header: "Descrição",
    },
    {
        field: "grupo",
        header: "Grupo",
        style: { width: "12%" },
        action: grupoChaveBodyTemplate,
    },
];

const Component = ({ onSelect = () => {} }, ref) => {
    const [pesquisar, setPesquisar] = useState(false);
    const [selecionado, setSelecionado] = useState(null);
    const [url, setUrl] = useState({});

    const buscar = useCallback((filtros) => {
        let _url = URL_BACKEND;
        if (typeof filtros === "object") _url = `${_url}&${gerarFiltrosUrl(filtros)}`;
        setPesquisar(Object.keys(filtros).length > 0);
        setUrl(_url);
    }, []);

    const onSelectionChange = useCallback(
        ({ value }) => {
            setSelecionado(value);
            onSelect(value);
        },
        [onSelect]
    );

    useImperativeHandle(ref, () => ({ buscar }), [buscar]);

    return (
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-8">
                <MakoListagem
                    colunas={BASE_COLUMNS}
                    urlPesquisa={url}
                    fazerBusca={pesquisar}
                    configTabela={{
                        selectionMode: "single",
                        selection: selecionado,
                        onSelectionChange,
                        lazy: true,
                        paginator: true,
                    }}
                />
            </div>
            <div className="p-field p-col-12 p-md-4">
                <Label htmlFor="orientacoes" label="Orientações" />
                <Editor value={selecionado?.orientacao || ""} showHeader={false} readOnly style={{ height: "200px" }} />
            </div>
        </div>
    );
};

export const Selecionar = forwardRef(Component);
