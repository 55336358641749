import React, { useRef } from "react";

import classNames from "classnames";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton } from "@/components/MakoButton";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";
import { Modals } from "../../modals";

const { PRODUTO_PRODUTO_LOTES_INCLUIR } = permissoes;

export const Novo = ({ className = "", disabled = false, onSuccessCallback = () => {} }) => {
    const modalRef = useRef(null);

    return (
        <>
            <MakoControleAcesso
                componente={MakoButton}
                permissao={[PRODUTO_PRODUTO_LOTES_INCLUIR]}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className={classNames("p-button p-component p-button-success", className)}
                disabled={disabled}
                onClick={() => modalRef.current?.show()}
            />
            <Modals.Novo ref={modalRef} onSuccessCallback={onSuccessCallback} />
        </>
    );
};
