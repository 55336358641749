import React, { useRef } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { ProdutoFatorConversaoPage } from "./listFatorConversao";
import { CodigoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";

const url = "/produtos/unidades-medida/";

export const ProdutoUnidadeMedidaPage = () => {
    const history = useHistory();
    const listagemRef = useRef(null);
    const { showSuccess } = useToast();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de unidade de medida"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/produto/unidade-medida/form",
                            state: { unidadeMedida: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDA_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a unidade <b>{rowData.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A unidade de medida não pode ser excluída."
                    tooltip="Deletar unidade de medida"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Unidade de medida deletada com sucesso!",
                            life: 3000,
                        });
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/produto/produto/unidade-medida/form")}
            />
        </>
    );

    const colunasUnidade = [
        { field: "id", header: "Código", filterElement: CodigoFiltroTemplate, style: { width: "10%" }, filter: true },
        { field: "sigla", header: "Sigla", filter: true },
        { field: "nome", header: "Nome", filter: true },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <h5>Unidades de Medida</h5>
            <TabView className="tabview-custom">
                <TabPanel header="Unidade de medida" leftIcon="pi pi-fw pi-sort-numeric-up">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunasUnidade}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        botaoExportar
                        responsiva
                        filtros={{
                            id: { value: "", matchMode: "equals" },
                            sigla: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            nome: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                        }}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                    />
                </TabPanel>
                <TabPanel header="Fator de Conversão" leftIcon="pi pi-fw pi-refresh">
                    <ProdutoFatorConversaoPage />
                </TabPanel>
            </TabView>
        </PageBase>
    );
};
