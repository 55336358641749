import React, { useCallback, useMemo, useState } from "react";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";

import { axiosDelete, axiosPatch, axiosPost } from "@/services/http";
import permissoes from "@/assets/constants/permissoes";

import useToast, { DEFAULT_ERROR_TOAST } from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const { VENDAS_VENDA_CARGAS_VINCULARPREVISAO } = permissoes;

const BASE_URL = "/vendas/vinculo-cargas-previsoes-entrega-item-venda/";

export const Vincular = ({ carga, previsao, successCallback = () => {}, className = "" }) => {
    const [loading, show, hide] = useLoadingLocal();
    const [visible, setVisible] = useState(false);

    const { showSuccess, showError } = useToast();

    const create = useCallback(async () => {
        const { status, data } = await axiosPost(BASE_URL, {
            carga: carga.id,
            previsao: previsao.id,
            item: previsao.item_id,
        });
        const ok = status === 201;
        return { ok, message: ok ? "Vinculado com sucesso!" : null, data };
    }, [carga, previsao]);

    const update = useCallback(async () => {
        const { status, data } = await axiosPatch(`${BASE_URL}${previsao.carga.vinculo_id}/`, { carga: carga.id });
        const ok = status === 200;
        return { ok, message: ok ? "Alterado com sucesso!" : null, data };
    }, [carga, previsao]);

    const remove = useCallback(async () => {
        const { status, data } = await axiosDelete(`${BASE_URL}${previsao.carga.vinculo_id}/`);
        const ok = status === 204;
        return { ok, message: ok ? "Removido com sucesso!" : null, data };
    }, [previsao]);

    const handler = useCallback(async () => {
        show();
        let response = { ok: false, message: DEFAULT_ERROR_TOAST.detail, data: null };
        if (carga && !previsao.carga) response = await create();
        else if (carga && previsao.carga) response = await update();
        else response = await remove();
        const { data, message, ok } = response;
        if (ok) {
            showSuccess({
                summary: "Sucesso!",
                detail: message,
                life: 1500,
            });
            successCallback(data);
        } else showError();
        hide();
    }, [carga, create, hide, previsao.carga, remove, show, showError, showSuccess, successCallback, update]);

    const tooltip = useMemo(() => {
        if (carga && !previsao.carga) return "Vincular carga";
        if (carga && previsao.carga) return "Trocar vinculo carga";
        return "Desvincular carga";
    }, [carga, previsao]);

    const message = useMemo(() => {
        if (carga && !previsao.carga) {
            return (
                <span>
                    Deseja vincular a carga <b>{carga?.codigo}</b> a previsão <b>{previsao?.id}</b>?
                </span>
            );
        }
        if (carga && previsao.carga) {
            return (
                <span>
                    Esta previsão ja esta vinculado a carga <b>{carga?.codigo}</b>
                    <br />
                    Deseja vincular a carga <b>{carga?.codigo}</b> a previsão <b>{previsao?.id}</b>?
                </span>
            );
        }
        return (
            <span>
                Deseja desvincular a carga <b>{previsao?.carga?.codigo}</b> da previsão <b>{previsao?.id}</b>?
            </span>
        );
    }, [carga, previsao]);

    const disabled = useMemo(() => {
        if (previsao?.retirar) return true;
        if (!carga && !previsao.carga) return true;
        if (carga && !previsao.carga) {
            if (carga?.situacao === "F") return true;
            return previsao.carga;
        }
        if (carga?.id === previsao?.carga?.id) return true;
        if (!carga?.situacao === "F") return true;
        if (!previsao.carga) return true;
        return previsao.carga?.situacao === "F";
    }, [carga, previsao]);

    return (
        <>
            <MakoControleAcesso
                componente={Button}
                permissao={[VENDAS_VENDA_CARGAS_VINCULARPREVISAO]}
                tooltip={tooltip}
                tooltipOptions={{ position: "left" }}
                icon="pi pi-fw pi-truck"
                disabled={disabled || loading}
                type="button"
                className={classNames("p-button-success p-button-rounded", className)}
                onClick={() => setVisible(true)}
            />
            <MakoConfirmDialog
                visible={visible}
                setVisible={setVisible}
                showErrorToast={false}
                message={message}
                accept={handler}
            />
        </>
    );
};
