import React, { useCallback, useEffect, useRef } from "react";

import { InputText } from "primereact/inputtext";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/vendas/rotas-entrega/{id}/cidades";

export const InputCidadesRota = ({ rotaId, className }) => {
    const [loading, show, hide] = useLoadingLocal();
    const { httpGet } = useHttp();
    const inputRef = useRef(null);

    const fetch = useCallback(
        async (signal) => {
            if (rotaId) {
                show();
                const url = BASE_URL.replace("{id}", rotaId);

                const handlers = {
                    200: ({ data }) => {
                        inputRef.current.value = data.results
                            ?.map(({ nome, estado }) => `${nome} - ${estado.uf}`)
                            .join(", ");
                    },
                };

                await httpGet({ url, signal }, handlers);
                hide();
            } else inputRef.current.value = "";
        },
        [hide, rotaId, show, httpGet]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetch(controller.signal);
        return () => {
            controller.abort();
        };
    }, [fetch]);

    return (
        <InputText
            ref={inputRef}
            placeholder={loading ? "Buscando cidades..." : undefined}
            className={className}
            readOnly
        />
    );
};
