import React, { useState, useCallback } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { LOGO_SHARKDEV } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

export const RecuperarSenhaPage = () => {
    const [email, setEmail] = useState("");
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showWarning } = useToast();
    const { httpPost } = useHttp();
    const history = useHistory();

    const enviarLink = useCallback(async () => {
        const handlers = {
            204: ({ data }) => {
                showSuccess({
                    summary: "Link enviado!",
                    detail: `Um link de recuperação de senha foi enviado para o e-mail informado.`,
                    life: 4000,
                });
                history.push("/login");
            },
            409: ({ data }) => {
                if (data?.erro === "usuario") {
                    showWarning({
                        summary: "Usuário não encontrado!",
                        detail: `O e-mail informado não corresponde a nenhum usuário do sistema.`,
                        life: 4000,
                    });
                }
            },
        };

        showLoading();
        await httpPost(
            {
                url: "/auth/users/reset_password/",
                body: {
                    email: email,
                },
            },
            handlers
        );
        hideLoading();
    }, [showLoading, hideLoading, email, httpPost, showSuccess, showWarning, history]);

    return (
        <div className="login-body">
            <div className="login-wrapper">
                <div className="login-panel">
                    <img src={LOGO_SHARKDEV} className="logo" alt="sharkdev-logo" />

                    <div className="login-form" style={{ width: "90%" }}>
                        <i className="pi pi-lock" style={{ fontSize: "6em" }}></i>
                        <h4>Problemas para entrar?</h4>
                        <p>
                            Insira um e-mail válido e enviaremos um <b>link</b> para você voltar a acessar sua conta.
                        </p>
                        <InputText placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Button label="ENVIAR" type="button" onClick={enviarLink} />
                    </div>

                    <p>
                        <a href="/login">Voltar a página inicial.</a>
                    </p>
                </div>
                <div className="login-image">
                    <div className="login-image-content">
                        <h1>Olá,</h1>
                        <h1>seja muito bem vindo </h1>
                        <h1>ao SysMako!</h1>
                    </div>
                    <div className="image-footer">
                        <p>FACEBOOK</p>
                        <p>INSTAGRAM</p>
                        <p>
                            {`${new Date().getFullYear()} - Desenvolvido por `}
                            <a href="https://sharkdev.com.br" target="blank">
                                SharkDev
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
