import React, { useCallback, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { BotaoDelete } from "@/components/BotaoDelete";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";

import { ModalApontamento } from "./modalApontamento";
import { DadosCabecalho } from "./dadosCabecalho";

import useContratoServico from "@/hooks/useContratoServico";
import useClearRefs from "@/hooks/useClearRefs";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

const FormApontamentos = () => {
    const initialModal = {
        visible: false,
        title: "Novo apontamento",
        content: null,
    };
    //const [acaoApontamento, setAcaoApontamento] = useState(null);
    const [servico, setServico] = useState(null);
    const [modal, setModal] = useState(initialModal);
    const { contrato, atualizarValorContrato } = useContratoServico();

    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const fecharModal = () => {
        setModal((prev) => ({ ...prev, visible: false }));
    };

    const confirmarDelete = () => {
        listagemRef.current?.buscarDados();
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    disabled={contrato?.status === "F"}
                    onClick={() => {
                        setModal({
                            visible: true,
                            title: "Editar Apontamento",
                            content: (
                                <ModalApontamento
                                    onClose={fecharModal}
                                    onFinish={onFinish}
                                    apontamento={{
                                        id: rowData.id,
                                        tipo: rowData.servico_contratado.servico.tipo,
                                        data_inicial: rowData.data_inicial,
                                        data_final: rowData.data_final,
                                        total_horas: rowData.total_horas,
                                        custo: rowData.custo,
                                        valor: rowData.valor,
                                        valor_nfs: rowData.valor_nfs,
                                        descricao: rowData.descricao,
                                        servico_contratado: {
                                            id: rowData.servico_contratado.id,
                                            label: `${rowData.servico_contratado.servico.codigo} | ${rowData.servico_contratado.servico.descricao}`,
                                        },
                                    }}
                                />
                            ),
                        });
                    }}
                />
                <BotaoDelete
                    url="/servicos/apontamento-servico/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir o apontamento <b>{rowData.codigo}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O apontamento não pode ser excluído."
                    onDelete={confirmarDelete}
                    disabled={contrato?.status === "F"}
                    tooltipOptions={{ position: "bottom" }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        {
            field: "servico_contratado.servico_municipio.descricao",
            header: "Descrição do serviço",
        },
        {
            field: "id",
            header: "N° apontamento",
        },
        {
            field: "data_inicial",
            header: "Data Inical",
            dateFormat: "dd/MM/yyy",
            align: "center",
            style: { width: "10%" },
        },
        {
            field: "data_final",
            header: "Data final",
            dateFormat: "dd/MM/yyy",
            align: "center",
            style: { width: "10%" },
        },
        {
            field: "valor",
            header: "Valor",
            money: true,
        },
        {
            field: "action",
            header: "Ações",
            align: "center",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const formatarServicos = useCallback((data) => {
        return data
            .filter((item) => !item.servico.apontamento)
            .map((item) => ({
                ...item,
                label: `${item.servico.codigo} | ${item.servico.descricao}`,
            }));
    }, []);

    const onFinish = () => {
        atualizarValorContrato();
        listagemRef.current?.buscarDados();
        fecharModal();
    };

    return (
        <div>
            <DadosCabecalho />
            <div className="p-fluid p-formgrid p-grid p-my-4">
                <div className="p-field p-col-12 p-md-10">
                    <Dropdown
                        placeholder="Selecione o serviço que deseja apontar"
                        url={`/servicos/servicos-contrato/?query={id,servico_municipio,contrato}&servico_municipio__servico__requer_apontamento=true&contrato=${contrato.id}`}
                        aposBuscar={formatarServicos}
                        value={servico}
                        onChange={(e) => setServico(e.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Button
                        type="button"
                        className="p-button-success"
                        label="Apontar"
                        disabled={!servico}
                        onClick={() => {
                            setModal({
                                visible: true,
                                title: "Novo Apontamento",
                                content: (
                                    <ModalApontamento onClose={fecharModal} onFinish={onFinish} servico={servico} />
                                ),
                            });
                        }}
                    />
                </div>
            </div>
            <div className="p-my-4">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={`/servicos/apontamento-servico/?servico_contratado__contrato_id=${contrato.id}`}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            </div>
            <Dialog
                visible={modal.visible}
                header={modal.title}
                style={{ width: "60vw" }}
                onHide={() => setModal((prev) => ({ ...prev, visible: false }))}
            >
                {modal.content}
            </Dialog>
        </div>
    );
};

export default React.memo(FormApontamentos);
