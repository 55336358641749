import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { Button } from "primereact/button";

export const Visualizar = ({
    onClick,
    type = "submit",
    setFieldValue,
    fieldVisualizar = "visualizar",
    className = "p-button-info p-mr-2",
    ...props
}) => {
    const handleClick = (event) => {
        if (typeof setFieldValue === "function") setFieldValue(fieldVisualizar, true);
        onClick(event);
    };

    return (
        <Button
            label="Visualizar"
            icon={MAKO_ICONS.VISUALIZAR}
            type={type}
            onClick={handleClick}
            className={className}
            {...props}
        />
    );
};
