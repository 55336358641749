import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Dropdown } from "@/components/Dropdown";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import { TIPO_CALCULO_CST_CHOICE } from "@/assets/constants/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useHttp from "@/hooks/useHttp";

const url = "/fiscal/cst-cofins/";
const urlvoltar = "/fiscal/cadastros/outros/cofins";

export const FiscalCofinsForm = () => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpPost, httpPatch } = useHttp();
    const { state } = useLocation();

    const formik = useFormik({
        initialValues: state?.cofins || {
            codigo: "",
            descricao: "",
            reverso: null,
            tipo_calculo: 1,
            compoe_total: false,
            apropriavel: false,
            destaca_nf: false,
            ativo: true,
            empresa: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                tipo_calculo: Yup.number().required("O campo 'tipo de cálculo' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "COFINS cadastrado com sucesso!",
                            life: 1500,
                        });
                        cancelar();
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: { ...values, empresa: empresaSelecionadaId },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "COFINS alterado com sucesso!",
                            life: 1500,
                        });
                        cancelar();
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const cancelar = () => {
        history.push(urlvoltar);
    };

    const cofinsOptionTemplate = (option) => {
        return <div>{`${option.codigo} - ${option.descricao}`}</div>;
    };

    const cofinsSelecionadoTemplate = (option, props) => {
        if (option) return cofinsOptionTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo COFINS" : "Manutenção de COFINS"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="codigo">Código *</label>
                        <InputText
                            id="codigo"
                            name="codigo"
                            keyfilter={/^[0-9]/}
                            autoComplete="off"
                            autoFocus
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            autoComplete="off"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="reverso">COFINS reverso</label>
                        <Dropdown
                            id="reverso"
                            name="reverso"
                            url={`${url}?empresa=${empresaSelecionadaId}&limit=3000`}
                            optionValue="id"
                            optionLabel="descricao"
                            itemTemplate={cofinsOptionTemplate}
                            valueTemplate={cofinsSelecionadoTemplate}
                            filter
                            filterBy="codigo,descricao"
                            showClear
                            value={formik.values.reverso}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo_calculo">Tipo de cálculo</label>
                        <Dropdown
                            id="tipo_calculo"
                            name="tipo_calculo"
                            options={TIPO_CALCULO_CST_CHOICE}
                            showClear={false}
                            value={formik.values.tipo_calculo}
                            className={classNames({ "p-invalid": formik.errors.tipo_calculo })}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.tipo_calculo && <small className="p-error">{formik.errors.tipo_calculo}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            inputId="apropriavel"
                            name="apropriavel"
                            checked={formik.values.apropriavel}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="apropriavel">Apropriável?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            inputId="compoe_total"
                            name="compoe_total"
                            checked={formik.values.compoe_total}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="compoe_total">Compõe total?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            inputId="destaca_nf"
                            name="destaca_nf"
                            checked={formik.values.destaca_nf}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="destaca_nf">Destaca na NF?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativo?</label>
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" icon="pi pi-save" className="p-button-info p-mr-2" />
                    <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
