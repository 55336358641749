import { useCallback, useEffect } from "react";

import useAuth from "./useAuth";
import api from "@/services/axios";
import {
    axiosDelete as apiDelete,
    axiosGet as apiGet,
    axiosPatch as apiPatch,
    axiosPost as apiPost,
    axiosPut as apiPut,
} from "@/services/http";

/**
 * @deprecated Use useHttp em vez disso
 */
const useAxiosPrivate = () => {
    const { token } = useAuth();

    useEffect(() => {
        const requestIntercept = api.interceptors.request.use((config) => {
            if (!config.headers?.Authorization && token) {
                config.headers = { ...config.headers, Authorization: `token ${token}` };
            }
            return config;
        });

        return () => {
            api.interceptors.request.eject(requestIntercept);
        };
    }, [token]);

    /**
     * @deprecated Use httpGet do hook useHttp em vez disso
     */
    const axiosGet = useCallback((url, options) => {
        return apiGet(url, options, api);
    }, []);

    /**
     * @deprecated Use httpPost do hook useHttp em vez disso
     */
    const axiosPost = useCallback((url, body, options) => {
        return apiPost(url, body, options, api);
    }, []);

    /**
     * @deprecated Use httpPut do hook useHttp em vez disso
     */
    const axiosPut = useCallback((url, body) => {
        return apiPut(url, body, api);
    }, []);

    /**
     * @deprecated Use httpPatch do hook useHttp em vez disso
     */
    const axiosPatch = useCallback((url, body) => {
        return apiPatch(url, body, api);
    }, []);

    /**
     * @deprecated Use httpDelete do hook useHttp em vez disso
     */
    const axiosDelete = useCallback((url) => {
        return apiDelete(url, api);
    }, []);

    return { axiosGet, axiosPost, axiosPut, axiosPatch, axiosDelete };
};

export default useAxiosPrivate;
