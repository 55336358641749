import React, { useCallback, useMemo, useRef, useState } from "react";

import { Button as B } from "primereact/button";

import { totalizadorFiltrosAplicados } from "@/assets/util/util";
import { INITIAL_VALUES } from "./form";
import { Modal } from "./modal";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useClearRefs from "@/hooks/useClearRefs";

export const Button = ({ onFilter = () => {}, className = "" }) => {
    const [filtros, setFiltros] = useState({});

    const modalRef = useRef(null);

    useClearRefs(modalRef);

    const show = useCallback(() => {
        modalRef.current?.show(filtros);
    }, [filtros]);

    const successCallback = useCallback(
        ({ filtros, query }) => {
            setFiltros(filtros);
            onFilter(query);
        },
        [onFilter]
    );

    const total = useMemo(() => {
        return totalizadorFiltrosAplicados(INITIAL_VALUES, filtros);
    }, [filtros]);

    return (
        <>
            <B
                label="Filtro avançado"
                type="button"
                icon={MAKO_ICONS.FILTRAR}
                className={`p-button-help ${className}`}
                badge={total}
                onClick={show}
            />
            <Modal ref={modalRef} successCallback={successCallback} />
        </>
    );
};
