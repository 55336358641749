import React, { useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";

import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { Dropdown } from "@/components/Dropdown";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoMultiSelect } from "@/components/MakoInputs";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Label } from "@/components/Label";
import { PageBase } from "@/components/PageBase";

export const TiposEntradaForm = (props) => {
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost, httpPut } = useHttp();
    const { showSuccess } = useToast();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            descricao: "",
            gera_financeiro: null,
            requer_ordem_compra: null,
            destinacoes: null,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (props.location.state) {
            setValues(props.location.state);
        }
    }, [props.location.state, setValues]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descricao' é obrigatório."),
                gera_financeiro: Yup.bool()
                    .required("O campo 'gera financeiro' é obrigatório.")
                    .typeError("Selecione uma opção válida."),
                requer_ordem_compra: Yup.bool()
                    .required("O campo 'requer ordem de compra' é obrigatório.")
                    .typeError("Selecione uma opção válida."),
                destinacoes: Yup.array()
                    .min(1, "Selecione ao menos uma destinação.")
                    .typeError("Selecione uma opção válida."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de entrada cadastrado com sucesso!",
                            life: 1500,
                        });
                        history.push("/compras/cadastros/tipos-entrada");
                    },
                };
                showLoading();
                await httpPost({ url: "/compras/tipos-entradas/", body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Tipo de entrada alterado com sucesso!",
                            life: 1500,
                        });
                        history.push("/compras/cadastros/tipos-entrada");
                    },
                };
                showLoading();
                await httpPut({ url: `/compras/tipos-entradas/${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo tipo de entrada" : "Manutenção de tipo de entrada"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="gera-financeiro" label="Gera financeiro?" obrigatorio />
                        <Dropdown
                            id="gera-financeiro"
                            name="gera_financeiro"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            placeholder="Selecione"
                            value={formik.values.gera_financeiro}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.gera_financeiro })}
                        />
                        {formik.errors.gera_financeiro && (
                            <small className="p-error">{formik.errors.gera_financeiro}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="requer-ordem-compra" label="Requer OC?" obrigatorio />
                        <Dropdown
                            id="requer-ordem-compra"
                            name="requer_ordem_compra"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            placeholder="Selecione"
                            value={formik.values.requer_ordem_compra}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.requer_ordem_compra })}
                        />
                        {formik.errors.requer_ordem_compra && (
                            <small className="p-error">{formik.errors.requer_ordem_compra}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="destinacoes" label="Destinações" obrigatorio />
                        <MakoMultiSelect
                            id="destinacoes"
                            name="destinacoes"
                            url={"/compras/destinacoes-entradas/?query={id,nome}"}
                            optionValue="id"
                            optionLabel="nome"
                            placeholder="Selecione"
                            selectedItemsLabel="{0} destinações selecionadas"
                            maxSelectedLabels={3}
                            value={formik.values.destinacoes}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.destinacoes })}
                        />
                        {formik.errors.destinacoes && <small className="p-error">{formik.errors.destinacoes}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="button"
                        className="p-button-danger"
                        to={"/compras/cadastros/tipos-entrada"}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
