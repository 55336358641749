import React, { useState, useEffect } from "react";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

import { parseData } from "@/assets/util/datas";

export const MakoCalendarTime = ({ valueCalendar, minDate, maxDate, ...props }) => {
    const [value, setValue] = useState(null);
    const [minDateCalendar, setMinDateCalendar] = useState(null);
    const [maxDateCalendar, setMaxDateCalendar] = useState(null);

    addLocale("br", {
        firstDayOfWeek: 0,
        dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
        dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro",
        ],
        monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
        today: "Hoje",
        clear: "Limpar",
    });

    useEffect(() => {
        if (typeof valueCalendar === "string" && valueCalendar.length > 0) {
            setValue(parseData(valueCalendar));
        } else if (typeof valueCalendar === "object") {
            setValue(valueCalendar);
        }
    }, [valueCalendar]);

    useEffect(() => {
        if (minDate) {
            if (typeof minDate === "string" && minDate.length > 0) {
                setMinDateCalendar(parseData(minDate));
            } else if (typeof minDate === "object") {
                setMinDateCalendar(minDate);
            }
        }

        if (maxDate) {
            if (typeof maxDate === "string" && maxDate.length > 0) {
                setMaxDateCalendar(parseData(maxDate));
            } else if (typeof maxDate === "object") {
                setMaxDateCalendar(maxDate);
            }
        }
    }, [minDate, maxDate]);

    const monthNavigatorTemplate = (e) => {
        return (
            <Dropdown
                value={e.value}
                options={e.options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                style={{ lineHeight: 1 }}
            />
        );
    };

    const yearNavigatorTemplate = (e) => {
        return (
            <Dropdown
                value={e.value}
                options={e.options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                className="p-ml-2"
                style={{ lineHeight: 1 }}
            />
        );
    };

    return (
        <Calendar
            value={value}
            showIcon
            showButtonBar
            locale="br"
            dateFormat="dd/mm/yy"
            mask="99/99/9999 99:99:99"
            showOnFocus={false}
            monthNavigator
            yearNavigator
            showTime
            showSeconds
            monthNavigatorTemplate={monthNavigatorTemplate}
            yearNavigatorTemplate={yearNavigatorTemplate}
            yearRange={`1900:${new Date().getFullYear() + 10}`}
            placeholder="dd/mm/aaaa hh:mm:ss"
            minDate={minDateCalendar}
            maxDate={maxDateCalendar}
            {...props}
        />
    );
};
