import React, { useCallback, useMemo, useRef } from "react";

import { Button } from "primereact/button";

import { VisualizarPendentes } from "../../modals";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useClearRefs from "@/hooks/useClearRefs";

const BASE_ENTREGA = { venda_id: null };

export const Pendentes = ({
    entrega = BASE_ENTREGA,
    selecionados,
    className = "",
    disabled = false,
    getSelecionados = () => {},
    handleSelectionAll = () => {},
}) => {
    const modalRef = useRef(null);

    useClearRefs(modalRef);

    const open = useCallback(() => {
        modalRef.current?.show(entrega?.venda_id);
    }, [entrega]);

    const vendaDiferente = useMemo(() => {
        if (!selecionados?.length) return false;
        return !selecionados.some(({ venda_id }) => venda_id === entrega.venda_id);
    }, [selecionados, entrega]);

    return (
        <>
            <Button
                icon={MAKO_ICONS.VISUALIZAR}
                className={`p-button-rounded p-button-info ${className}`}
                disabled={vendaDiferente || disabled}
                onClick={open}
            />
            <VisualizarPendentes
                ref={modalRef}
                selecionados={selecionados}
                getSelecionados={getSelecionados}
                handleSelectionAll={handleSelectionAll}
            />
        </>
    );
};
