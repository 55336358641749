import React from "react";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";

import { ACTIONS_OPERAR_MODAIS_CAIXA } from "../../../reducers/operar-modais-caixa";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";

const { ROOT } = permissoes;

export const IncluirOperacao = ({ className = "", dispatcher = () => {} }) => {
    const { caixaMov } = useCaixaMovimento();

    return (
        <MakoControleAcesso
            componente={Button}
            permissao={[ROOT]}
            label="Incluir operação"
            className={className}
            onClick={() => {
                dispatcher({
                    type: ACTIONS_OPERAR_MODAIS_CAIXA.INCLUIROPERACAO,
                });
            }}
            disabled={caixaMov?.bloqueado}
        />
    );
};
