import React, { useCallback, useEffect, useReducer } from "react";
import { setDay, subMonths, startOfWeek, endOfWeek } from "date-fns";

import { Button } from "primereact/button";

import {
    MakoCardGraficoPizza,
    MakoCardItemProgresso,
    MakoCardLineChart,
    MakoCardProdutoInfo,
    MakoCardUsuarios,
} from "@/components/MakoCards";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { ACTIONS_DASHBOARD, baseChart, dashboardReducer, initialIndicadoresReducer } from "./dashboardReducer";
import { IndicadorVendasRecentes } from "./indicadorvendasRecentes";
import { DashboardCardsContainer } from "./cardsContainer";

import { SITUACAO_VENDA_CHOICE, TIPOS_MESSES_ANO } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import permissoes from "@/assets/constants/permissoes";

import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MakoUltimosRegistros } from "@/components/MakoUltimosRegistros";

const startSemana = startOfWeek(new Date(), { weekStartsOn: 0 });
const endSemana = endOfWeek(new Date(), { weekStartsOn: 0 });
const hoje = new Date();

const ordersChartOptions = {
    legend: {
        display: true,
    },
    responsive: true,
    hover: {
        mode: "index",
    },
    scales: {
        yaxis: {
            min: 0,
            ticks: {
                precision: 0,
            },
        },
    },
};

export const Dashboard = () => {
    const [indicadores, dispatchIndicadores] = useReducer(dashboardReducer, initialIndicadoresReducer);
    const { empresaSelecionadaId } = useEmpresa();
    const { showError } = useToast();
    const { httpGet } = useHttp();

    function randomRGBAColor(opacidade = 0.6) {
        var color = [];
        for (var i = 0; i < 3; i++) {
            var number = Math.floor(Math.random() * 256);
            color = [...color, number];
        }

        // color = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacidade})`;
        return [...color, opacidade];
    }

    const chartProps = {
        id: "revenue-chart",
        data: {
            labels: ["Direta", "Promovida", "Afiliada"],
            datasets: [
                {
                    data: [40, 35, 25],
                    backgroundColor: ["#64B5F6", "#7986CB", "#4DB6AC"],
                },
            ],
        },
    };

    const actionBodyTemplateUsuarios = (rowData) => {
        return (
            <>
                <Button type="button" className="p-button-rounded p-button-success" icon="pi pi-envelope" disabled />,
                <Button type="button" className="p-button-rounded p-button-warning" icon="pi pi-cog" disabled />
            </>
        );
    };

    const fetchPedidosGraficoInfo = useCallback(
        async (signal) => {
            if (empresaSelecionadaId) {
                const inicio = dataToStr(setDay(subMonths(hoje, 6), 1), "yyyy-MM-dd");
                const fim = dataToStr(hoje, "yyyy-MM-dd");
                let copy = { ...baseChart };
                const handlers = {
                    200: ({ data: d }) => {
                        const data = d.dados;

                        const labels = data.labels.map((item) => {
                            return TIPOS_MESSES_ANO.find((m) => m.value === item).label;
                        });
                        const icons = [
                            { value: "P", icon: "pi pi-plus-circle" },
                            { value: "F", icon: "pi pi-check" },
                            { value: "C", icon: "pi pi-times-circle" },
                            { value: "TR", icon: "pi pi-check-circle" },
                        ];
                        const situacoes = [];
                        Object.keys(data.results).forEach((key) => {
                            const color = randomRGBAColor();
                            const situacao = SITUACAO_VENDA_CHOICE.find((t) => t.value === key);
                            if (situacao) {
                                situacoes.push({
                                    value: situacao.value,
                                    label: situacao.label + "s",
                                    data_fill: `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`,
                                    data_stroke: `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3] + 0.2})`,
                                });
                            }
                        });
                        const color = randomRGBAColor();
                        situacoes.push({
                            value: "TR",
                            label: "Trocas",
                            data_stroke: `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`,
                            data_fill: `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3] + 0.2})`,
                        });

                        copy.tabs = situacoes.map((item) => {
                            return {
                                data_label: item.label,
                                data_stroke: item.data_stroke,
                                data_fill: item.data_fill,
                                icon: icons.find((i) => i.value === item.value).icon,
                                text: item.label,
                                src_graph: null,
                            };
                        });
                        copy.datasets = Object.keys(data.results).map((key) => {
                            const situacao = situacoes.find((s) => s.value === key);
                            return {
                                fill: true,
                                label: situacao.label,
                                borderWidth: 3,
                                data: data.results[key],
                                backgroundColor: situacao.data_fill,
                                borderColor: situacao.data_stroke,
                            };
                        });
                        copy.labels = labels;
                    },
                };
                await httpGet(
                    {
                        url: `/dashboard/pedidos-totalizador/?date_start=${inicio}&date_end=${fim}&empresa_id=${empresaSelecionadaId}`,
                        signal,
                    },
                    handlers
                );
                return copy;
            }
            return baseChart;
        },
        [empresaSelecionadaId, httpGet]
    );

    const fetchTarefas = useCallback(
        async (signal) => {
            if (empresaSelecionadaId) {
                let itens = [];
                const handlers = {
                    200: ({ data }) => (itens = data.itens),
                };
                await httpGet(
                    {
                        url: `/dashboard/tarefas-totalizador/?date_start=${dataToStr(
                            startSemana,
                            "yyyy-MM-dd"
                        )}&date_end=${dataToStr(endSemana, "yyyy-MM-dd")}&empresa_id=${empresaSelecionadaId}`,
                        signal,
                    },
                    handlers
                );

                return itens;
            }
            return [];
        },
        [httpGet, empresaSelecionadaId]
    );

    const fetchPedidosMaisVendidos = useCallback(
        async (signal) => {
            let produtos = [];
            const handlers = {
                200: ({ data }) => (produtos = data.produtos),
            };
            const url = empresaSelecionadaId
                ? `/dashboard/produtos-mais-vendidos/?empresa_id=${empresaSelecionadaId}`
                : `/dashboard/produtos-mais-vendidos/`;
            await httpGet({ url, signal }, handlers);
            return produtos;
        },
        [httpGet, empresaSelecionadaId]
    );

    const fetchIndicadores = useCallback(
        async (signal) => {
            try {
                dispatchIndicadores({ type: ACTIONS_DASHBOARD.SHOW_LOADING });
                const response = await Promise.all([
                    fetchTarefas(signal),
                    fetchPedidosGraficoInfo(signal),
                    fetchPedidosMaisVendidos(signal),
                ]);
                dispatchIndicadores({ type: ACTIONS_DASHBOARD.UPDATE_TAREFAS, payload: { value: response[0] } });
                dispatchIndicadores({ type: ACTIONS_DASHBOARD.UPDATE_PEDIDOS, payload: { value: response[1] } });
                dispatchIndicadores({
                    type: ACTIONS_DASHBOARD.UPDATE_PRODUTOSVENDIDOS,
                    payload: { value: response[2] },
                });
                dispatchIndicadores({ type: ACTIONS_DASHBOARD.HIDE_LOADING });
            } catch (error) {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição",
                    life: 3000,
                });
            }
        },
        [fetchTarefas, fetchPedidosGraficoInfo, fetchPedidosMaisVendidos, showError]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetchIndicadores(controller.signal);
        return () => {
            controller.abort();
        };
    }, [fetchIndicadores]);

    return (
        <div className="layout-dashboard">
            <div className="p-grid">
                <DashboardCardsContainer />
                {empresaSelecionadaId && (
                    <div className="p-col-12 p-xl-6">
                        <MakoControleAcesso
                            permissao={[permissoes.DASHBOARD_INDICATIVO_VENDAS_PEDIDOSTOTALIZADOR]}
                            componente={MakoCardLineChart}
                            title="Pedidos"
                            tabs={indicadores.pedidos.tabs}
                            ordersChart={indicadores.pedidos}
                            ordersChartOptions={ordersChartOptions}
                            idChart="order-chart"
                            menuItems={indicadores.pedidos.itemsMenu}
                            loading={indicadores.loading}
                        />
                    </div>
                )}
                <div className="p-col-12 p-xl-6">
                    <IndicadorVendasRecentes />
                </div>
                <div className="p-col-12 p-lg-6">
                    {empresaSelecionadaId ? (
                        <MakoControleAcesso
                            permissao={[permissoes.DASHBOARD]}
                            componente={MakoCardItemProgresso}
                            title="Tarefas"
                            subtitle="Visão geral de suas tarefas pendentes."
                            items={indicadores.tarefas}
                            loading={indicadores.loading}
                        />
                    ) : null}
                    <MakoControleAcesso
                        permissao={[permissoes.DASHBOARD_INDICATIVO_VENDAS_PRODUTOSMAISVENDIDOS]}
                        componente={MakoCardProdutoInfo}
                        title="Mais vendidos"
                        subtitle="Produtos mais populares de sua coleção."
                        column_1="Produtos"
                        column_2="Vendas"
                        items={indicadores.produtosVendidos}
                        loading={indicadores.loading}
                    />
                    <MakoControleAcesso
                        permissao={[permissoes.DASHBOARD_LOGS_ULTIMOS_REGISTROS]}
                        componente={MakoUltimosRegistros}
                        items={indicadores.produtosVendidos}
                        loading={indicadores.loading}
                    />
                </div>
                <div className="p-col-12 p-lg-6">
                    <MakoControleAcesso
                        permissao={[permissoes.DASHBOARD]}
                        componente={MakoCardGraficoPizza}
                        title="Fluxo de receitas"
                        subtitle="Comparação de suas fontes de receita."
                        chartProps={chartProps}
                    />
                    <MakoControleAcesso
                        permissao={[permissoes.DASHBOARD_INDICATIVO_PESSOAS_LISTARATIVOS]}
                        componente={MakoCardUsuarios}
                        title="Membros do time"
                        actionTemplate={actionBodyTemplateUsuarios}
                    />
                </div>
            </div>
        </div>
    );
};
