import { useContext } from "react";

import NotaFiscalContext from "@/contexts/notaFiscalContext";

const useNotaFiscal = () => {
    const notaFiscal = useContext(NotaFiscalContext);

    return notaFiscal;
};

export default useNotaFiscal;
