import React, { useState, useImperativeHandle, forwardRef, useCallback } from "react";

import { Dialog } from "primereact/dialog";

import { Editar as Form } from "../../forms";

const Modal = ({ parcelas = [], handleEditar = () => {} }, ref) => {
    const [parcela, setParcela] = useState(null);
    const [visible, setVisible] = useState(false);

    const show = (recebimento) => {
        if (recebimento) setParcela(recebimento);
        setVisible(true);
    };

    const successCallback = useCallback(
        (...args) => {
            handleEditar(...args);
            setVisible(false);
        },
        [handleEditar]
    );

    useImperativeHandle(ref, () => ({ show }));

    return (
        <Dialog
            header={`Editando parcela: ${parcela?.index}`}
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "60vw" }}
        >
            <Form parcela={parcela} parcelas={parcelas} successCallback={successCallback} />
        </Dialog>
    );
};

export const Editar = forwardRef(Modal);
