import React, { useCallback, useEffect, useRef, useState } from "react";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { TIPO_ORIGEM_NF_CHOICE } from "@/assets/constants/constants";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { MultiSelect } from "primereact/multiselect";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Dialog } from "primereact/dialog";
import useToast from "@/hooks/useToast";
import { Checkbox } from "primereact/checkbox";
import useHttp from "@/hooks/useHttp";
import useAuth from "@/hooks/useAuth";

const url = "/fiscal/mva/";

export const FiscalMvaForm = (ncm) => {
    const [dialog, setDialog] = useState(false);
    const [estados, setEstados] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpPatch, httpGet } = useHttp();
    const { user } = useAuth();
    const listagemRef = useRef(null);

    const { resetForm, setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            id: null,
            ncm: ncm.props?.id,
            valor: 0,
            vigencia_inicial: "",
            vigencia_final: null,
            estado: null,
            codigo_origem: null,
            ativo: true,
            ajustado: true,
            gravador_por: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                valor: Yup.string().required("O campo 'percentual mva' é obrigatório."),
                vigencia_inicial: Yup.date().required("O campo 'vigência inicial' é obrigatório."),
                estado: Yup.array().required("O campo 'estados' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "MVA cadastrado com sucesso!",
                            life: 1500,
                        });
                        esconderDialog();
                        listagemRef.current?.buscarDados();
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: url,
                        body: {
                            ...values,
                            gravador_por: user?.id,
                            vigencia_inicial: dataToStr(values.vigencia_inicial, "yyyy-MM-dd"),
                            vigencia_final: dataToStr(values.vigencia_final, "yyyy-MM-dd"),
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "MVA alterado com sucesso!",
                            life: 1500,
                        });
                        esconderDialog();
                        listagemRef.current?.buscarDados();
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: {
                            ...values,
                            ncm: values.ncm.id,
                            vigencia_inicial: dataToStr(values.vigencia_inicial, "yyyy-MM-dd"),
                            vigencia_final: dataToStr(values.vigencia_final, "yyyy-MM-dd"),
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_NCM_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de MVA"
                    onClick={() => {
                        setValues(rowData);
                        setDialog(true);
                    }}
                    tooltipOptions={{ position: "left" }}
                />

                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_NCM_EDITAR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir o MVA código "}
                            <b>{rowData.id}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O MVA não pode ser excluído."
                    tooltip="Deletar MVA"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const listarEstados = useCallback(async () => {
        await httpGet(
            {
                url: "/pessoas/estados?limit=300",
            },
            {
                200: ({ data }) => {
                    setEstados(data.results);
                },
            }
        );
    }, [httpGet]);

    useEffect(() => {
        listarEstados();
    }, [listarEstados]);

    const colunasMva = [
        { field: "id", header: "Código", style: { width: "10%" } },
        { field: "valor", header: "% Percentual MVA" },
        { field: "codigo_origem", header: "Código Origem", style: { width: "15%" } },
        { field: "vigencia_inicial", header: "Vigência Inicial", style: { width: "15%" }, dateFormat: "dd/MM/yyyy" },
        { field: "vigencia_final", header: "Vigência Final", style: { width: "15%" }, dateFormat: "dd/MM/yyyy" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    const esconderDialog = () => {
        setDialog(false);
        resetForm();
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_FISCAL_NCM_EDITAR]}
                componente={Button}
                label="Incluir MVA"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setDialog(true)}
            />
        </>
    );

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                colunas={colunasMva}
                titulo={`MVA's do NCM: ${ncm.props.codigo}`}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/fiscal/mva/?filtrar_ativos_vigencias=true&ncm=${ncm.props.id}`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
            <Dialog
                header={formik.values.id ? `Editar MVA (Código: ${formik.values.id})` : "Novo MVA"}
                visible={dialog}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw" }}
                onHide={() => esconderDialog()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor">Percentual MVA *</label>
                            <InputNumber
                                id="valor"
                                name="valor"
                                min={0}
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                prefix="%"
                                onValueChange={formik.handleChange}
                                value={formik.values.valor}
                                className={classNames({ "p-invalid": formik.errors.valor })}
                                tooltip="Valor do MVA Exemplo: Valor '10' ."
                                autoComplete="off"
                                autoFocus
                            />
                            {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label>Estados *</label>
                            <MultiSelect
                                value={formik.values.estado}
                                options={estados}
                                onChange={(e) => setFieldValue("estado", e.value)}
                                optionLabel="nome"
                                optionValue="id"
                                placeholder="Selecione um ou mais estados"
                                display="Estados"
                                className={classNames({
                                    "p-invalid": formik.errors.estado,
                                })}
                            />
                            {formik.errors.estado && <small className="p-error">{formik.errors.estado}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="codigo_origem">Origem</label>
                            <Dropdown
                                id="codigo_origem"
                                name="codigo_origem"
                                placeholder="Selecione uma origem"
                                options={TIPO_ORIGEM_NF_CHOICE}
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.codigo_origem}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.codigo_origem })}
                            />
                            {formik.errors.codigo_origem && (
                                <small className="p-error">{formik.errors.codigo_origem}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="vigencia_inicial">Vigência Inicial *</label>
                            <MakoCalendar
                                id="vigencia_inicial"
                                name="vigencia_inicial"
                                dateFormat="dd/mm/yy"
                                valueCalendar={formik.values.vigencia_inicial}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.vigencia_inicial,
                                })}
                            />
                            {formik.errors.vigencia_inicial && (
                                <small className="p-error">{formik.errors.vigencia_inicial}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="vigencia_final">Vigência Final</label>
                            <MakoCalendar
                                id="vigencia_final"
                                name="vigencia_final"
                                dateFormat="dd/mm/yy"
                                valueCalendar={formik.values.vigencia_final}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.vigencia_final,
                                })}
                            />
                            {formik.errors.vigencia_final && (
                                <small className="p-error">{formik.errors.vigencia_final}</small>
                            )}
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-2" style={{ paddingLeft: "2%" }}>
                            <br></br>
                            <Checkbox
                                inputId="ajustado"
                                id="ajustado"
                                name="ajustado"
                                onChange={formik.handleChange}
                                checked={formik.values.ajustado}
                                value={formik.values.ajustado}
                                className="p-mt-5"
                            />
                            <label htmlFor="ajustado" className="p-mt-5">
                                Ajustado
                            </label>
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-3" style={{ paddingLeft: "2%" }}>
                            <br></br>
                            <Checkbox
                                inputId="ativo"
                                id="ativo"
                                name="ativo"
                                disabled={!formik.values.id}
                                onChange={formik.handleChange}
                                checked={formik.values.ativo}
                                value={formik.values.ativo}
                                className="p-mt-5"
                            />
                            <label htmlFor="ativo" className="p-mt-5">
                                Ativo
                            </label>
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-col-12 p-md-6">
                        <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                        <Button
                            icon="pi pi-trash"
                            label="Limpar"
                            type="reset"
                            className="p-button-warning p-mr-2"
                            onClick={() => resetForm()}
                        />
                    </div>
                </form>
            </Dialog>
        </PageBase>
    );
};
