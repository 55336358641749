import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputNumber } from "primereact/inputnumber";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoButton } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_PRIORIDADE_VINCULO_FORNECEDORPRODUTO } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { PageBase } from "@/components/PageBase";

export const VinculoFornecedorForm = () => {
    const [confirm, setConfirm] = useState(false);
    const [prazo, setPrazo] = useState(null);
    const [loading, setLoading] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { showWarning } = useToast();
    const { state } = useLocation();
    const { httpPost, httpPatch, httpPut } = useHttp();
    const history = useHistory();

    const media_entrega_anterior = state?.media_entrega;
    const alterar_media_sku = useRef({ alterar: false, media: null });

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: !state?.id
            ? {
                  id: null,
                  sku: null,
                  fornecedor: null,
                  prioridade: null,
                  media_entrega: 0,
                  preco_compra: 0,
                  unidade_venda: null,
              }
            : {
                  ...state,
                  fornecedor: {
                      ...state.fornecedor,
                      label: `${state.fornecedor.nome} - ${state.fornecedor.identificacao}`,
                  },
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'sku' é obrigatório"),
                    })
                    .typeError("Informe um 'sku' válido"),
                fornecedor: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'fornecedor' é obrigatório"),
                    })
                    .typeError("Informe um 'fornecedor' válido"),
                prioridade: Yup.string()
                    .max(1)
                    .required("O campo 'prioridade' é obrigatório")
                    .typeError("Informe uma 'prioridade' válida"),
                media_entrega: Yup.number()
                    .min(0, "O valor deve ser maior ou igual a 0.")
                    .typeError("Informe uma 'media de entrega' válida"),
                preco_compra: Yup.number().typeError("Informe um 'preço de compra' válido"),
                unidade_venda: Yup.number().nullable().typeError("Selecione uma unidade de compra."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const body = { ...values, fornecedor: values.fornecedor.id, sku: values.sku.id };

            if (values.id === null) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Vinculo criado com sucesso.",
                            life: 3000,
                        });
                        history.push("/produto/cadastros/vinculo-fornecedor");
                    },
                    400: () => showError(),
                };

                showLoading();
                await httpPost(
                    {
                        url: "/produtos/vinculo-produtos-fornecedor/",
                        body: body,
                    },
                    handlers
                );
                hideLoading();
            } else {
                showLoading();
                let erro = false;
                if (alterar_media_sku.current.alterar) {
                    const handlers = {
                        400: () => (erro = true),
                    };

                    await httpPatch(
                        {
                            url: `/produtos/sku/${values.sku}/`,
                            body: {
                                intervalo_compra_chegada: alterar_media_sku.current.media,
                            },
                        },
                        handlers
                    );
                }

                if (erro) {
                    showError();
                    return;
                }

                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Vinculo atualizado com sucesso",
                            life: 3000,
                        });
                        history.push("/produto/cadastros/vinculo-fornecedor");
                    },
                    400: () => showError(),
                };

                await httpPut(
                    {
                        url: `/produtos/vinculo-produtos-fornecedor/${values.id}/`,
                        body: body,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const handleReset = () => {
        resetForm();
    };

    const setInformacoesItem = useCallback(() => {
        if (formik.values.sku instanceof Object) {
            const { codigo, descricao_derivada, descricao_reduzida, precosku_set, unidade_padrao } = formik.values.sku;
            if (precosku_set?.length === 0) {
                showWarning({
                    summary: "Aviso!",
                    detail: "O produto não possui nenhum preço cadastrado, portanto não pode ser vendido.",
                    life: 5000,
                });
                resetForm();
                return;
            }
            setFieldValue("codigo", codigo);
            setFieldValue("descricao", descricao_derivada);
            setFieldValue("descricao_reduzida", descricao_reduzida);
            if (!formik.values?.id) {
                setFieldValue("unidade_venda", unidade_padrao.id);
            }
        }
    }, [formik.values.sku, resetForm, setFieldValue, showWarning, formik.values.id]);

    useEffect(() => {
        setInformacoesItem();
    }, [setInformacoesItem]);

    const autoCompleteFornecedorTemplate = (fornecedor) => {
        fornecedor.label = `${fornecedor.nome} - ${fornecedor.identificacao}`;
        return <div>{`${fornecedor.nome} - ${fornecedor.identificacao}`}</div>;
    };

    const url = useMemo(() => {
        if (typeof formik.values.sku !== "object") return "/produtos/unidades-medida-sku?tipo_mov_und_medida=C";
        if (formik.values.sku) {
            const { id } = formik.values.sku;
            return `/produtos/unidades-medida-sku/?tipo_mov_und_medida=C&sku__id=${id}`;
        }
    }, [formik.values.sku]);

    const unidadeSelecionada = useCallback((unidades) => {
        return unidades.map((ele) => {
            return { ...ele, label: `${ele.unidade.nome}(${ele.unidade.sigla})` };
        });
    }, []);

    const fecharConfirm = () => {
        setConfirm(false);
    };

    const handleConfirm = ({ alterar = false }) => {
        if (alterar_media_sku.current.alterar) alterar_media_sku.current.alterar = alterar;
        formik.handleSubmit();
        fecharConfirm();
    };

    const verificarPrazo = useCallback(async () => {
        setLoading(() => true);
        let retorno = [];
        const handlers = {
            200: ({ data }) => {
                setPrazo(data);
                retorno = data;
            },
            400: () => setPrazo({}),
        };

        await httpPost(
            {
                url: "/produtos/verificar-maior-prazo-vinculo-fornecedor/",
                body: {
                    produto_id: formik.values?.sku?.id,
                    vinculo_id: formik.values.id,
                    nova_media: formik.values.media_entrega,
                },
            },
            handlers
        );
        setLoading(() => false);

        return retorno;
    }, [httpPost, setLoading, formik.values.sku, formik.values.id, formik.values.media_entrega]);

    const handlePreSubmit = async (e) => {
        if (!media_entrega_anterior || media_entrega_anterior === formik.values.media_entrega)
            return formik.handleSubmit(e);
        const { intervalo } = await verificarPrazo();
        if (intervalo === formik.values.media_entrega) {
            alterar_media_sku.current = false;
            formik.handleSubmit();
        } else setConfirm(() => true);
    };

    const msgConfirmacao = useMemo(() => {
        const maior_prazo =
            formik.values.media_entrega > prazo?.vinculo.media_entrega
                ? formik.values.media_entrega
                : prazo?.vinculo.media_entrega;
        if (alterar_media_sku.current.media) alterar_media_sku.current.media = maior_prazo;
        return !prazo ? (
            ""
        ) : (
            <p>
                O produto de código: <b>{formik.values?.sku?.codigo}</b> atualmente possui o prazo médio de entrega de{" "}
                <b>{prazo.intervalo} dias</b>
                <br />
                {maior_prazo > prazo.intervalo
                    ? "Deseja alterar para a maior média"
                    : "Deseja alterar para a maior média mesmo ela sendo menor que a atual"}
                ?
                <br />
                <b>Nova média: {maior_prazo} dias</b>
            </p>
        );
    }, [prazo, formik.values.sku, formik.values.media_entrega]);

    return (
        <PageBase>
            <h5>Vincular Produto do Fornecedor</h5>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <MakoBuscaSkuPersonalizada
                            statusItem="F"
                            skuValue={formik.values.sku}
                            skuChange={(e) => setFieldValue("sku", e)}
                            skuError={formik.errors.sku}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="und-venda">Unidade de compra</label>
                        <Dropdown
                            id="und-venda"
                            name="unidade_venda"
                            url={url}
                            optionValue="unidade.id"
                            optionLabel="label"
                            filter
                            filterBy="id"
                            aposBuscar={unidadeSelecionada}
                            value={formik.values.unidade_venda}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.unidade_venda })}
                        />
                        {formik.errors.unidade_venda && (
                            <small className="p-error">{formik.errors.unidade_venda}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="fornecedor">Fornecedor *</label>
                        <MakoInputFornecedor
                            inputId="fornecedor"
                            name="fornecedor"
                            key="id"
                            value={formik.values.fornecedor}
                            itemTemplate={autoCompleteFornecedorTemplate}
                            field="label"
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fornecedor })}
                        />
                        {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="prioridade">Prioridade de fornecimento *</label>
                        <Dropdown
                            id="prioridade"
                            name="prioridade"
                            options={TIPO_PRIORIDADE_VINCULO_FORNECEDORPRODUTO}
                            optionValue="value"
                            optionLabel="label"
                            filter
                            filterBy="label"
                            placeholder="Selecione um prioridade..."
                            value={formik.values.prioridade}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.prioridade })}
                        />
                        {formik.errors.prioridade && <small className="p-error">{formik.errors.prioridade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="preco_compra">Preço de compra </label>
                        <MakoInputMoeda
                            inputId="preco_compra"
                            name="preco_compra"
                            valueMoeda={formik.values.preco_compra}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.preco_compra })}
                        />
                        {formik.errors.preco_compra && <small className="p-error">{formik.errors.preco_compra}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="media_entrega">Prazo médio entrega </label>
                        <InputNumber
                            inputId="media_entrega"
                            name="media_entrega"
                            showButtons
                            value={formik.values.media_entrega}
                            min={0}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.media_entrega })}
                        />
                        {formik.errors.media_entrega && (
                            <small className="p-error">{formik.errors.media_entrega}</small>
                        )}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <MakoButton
                            type="button"
                            loading={loading}
                            icon="pi pi-check"
                            label="Gravar"
                            onClick={handlePreSubmit}
                            className="p-mr-2 p-mb-2"
                        />
                        <MakoButton
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            loading={loading}
                            onClick={handleReset}
                        />
                        <MakoButton
                            type="reset"
                            label="Cancelar"
                            className="p-button-danger p-mb-2"
                            loading={loading}
                            onClick={() => history.goBack()}
                        />
                    </div>
                </div>
            </form>
            <ConfirmDialog
                visible={confirm}
                onHide={fecharConfirm}
                message={msgConfirmacao}
                header="Alterar prazo médio de entrega produto"
                accept={() => handleConfirm({ alterar: true })}
                reject={handleConfirm}
                acceptLabel="Alterar"
                rejectClassName="p-button-danger p-button-text"
                rejectLabel="Não alterar"
            />
        </PageBase>
    );
};
