import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

const url = "/plano-operacional/setores/";

export const OperacionalSetoresPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_SETORES_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    tooltip="Alterar cadastro de setor"
                    tooltipOptions={{ position: "left" }}
                    to={{
                        pathname: "/operacional/plano-operacional/setores/form",
                        state: { setores: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_SETORES_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    exigeConfirmacao
                    objetoId={rowData.id}
                    msgConfirmacao={
                        <span>
                            {"Deseja realmente excluir o setor "}
                            <b>{rowData.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O setor não pode ser excluído"
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_SETORES_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/operacional/plano-operacional/setores/form"}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "100px" } },
        { field: "nome", header: "Nome", style: { minWidth: "250px" } },
        { field: "descricao", header: "Descrição", style: { minWidth: "400px" } },
        { field: "centro_resultado.nome", header: "Centro de resultados", style: { minWidth: "200px" } },
        { field: "responsavel.nome", header: "Responsável", style: { minWidth: "250px" } },
        { field: "setor.nome", header: "Setor pai", style: { minWidth: "300px" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "130px" },
            align: "center",
            frozen: true,
            alignFrozen: "right",
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Setores"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                filtarPorEmpresa
                configTabela={{
                    paginator: true,
                    lazy: true,
                    frozenWidth: "0.5vw",
                    flexGrow: 1,
                    flexBasis: "12rem",
                    scrollable: true,
                }}
            />
        </PageBase>
    );
};
