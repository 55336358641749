import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { differenceInDays } from "date-fns";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { Menu } from "primereact/menu";

import { ModalOcorrenciaAssistenciaTecnica } from "./modals/ModalOcorrenciaAssistenciaTecnica";
import { PageBase } from "@/components/PageBase";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { CodigoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import permissoes from "@/assets/constants/permissoes";
import { parseNumber } from "@/assets/helpers/number";
import { dataToStr, parseData } from "@/assets/util/datas";
import useFetchRelatorio from "@/hooks/useFetchRelatorio";
import { RELATORIO_VENDAS_ASSISTENCIATECNICA } from "@/assets/constants/relatorios";
import useRelatorio from "@/hooks/useRelatorio";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useAuth from "@/hooks/useAuth";
// import useAuth from "@/hooks/useAuth";

const BASE_URL_API = "/vendas/assistencias-tecnicas";
const URL_FORM = "/vendas/assistencias-tecnicas/form";
// const BASE_FILTROS_CABECALHO = {
//     id: { value: null, matchMode: "equals" },
//     orcamento: { value: null, matchMode: "equals" },
//     cliente: { value: null, matchMode: "__nome__contains__unaccent" },
//     sku_assistencia: { value: null, matchMode: "__codigo" },
// };

export const AssistenciaTecnicaPage = () => {
    const [assistencia, setAssistencia] = useState(null);

    const { versao } = useFetchRelatorio({ chave: RELATORIO_VENDAS_ASSISTENCIATECNICA });
    const { solicitarRelatorio } = useRelatorio();
    const { verifyPermission } = useAuth();

    const listagemRef = useRef(null);
    const menuAcoesRef = useRef(null);
    const modalOcorrenciaRef = useRef(null);
    const history = useHistory();

    const gerarFicha = useCallback(() => {
        solicitarRelatorio({
            versao: versao,
            chave: RELATORIO_VENDAS_ASSISTENCIATECNICA,
            filtros: { assistencia: assistencia?.id },
            enviar_fila: false,
        });
    }, [solicitarRelatorio, versao, assistencia]);

    const prazoBodyTemplate = (rowData) => {
        if (rowData.situacao === "F" || !rowData.vencimento) return <span>--</span>;
        const prazo = differenceInDays(parseData(rowData.vencimento), new Date());
        let prefixTooltip = "Vence";
        if (prazo < 0) prefixTooltip = "Venceu";
        return (
            <>
                <Tooltip
                    target={`.custom-target-prazo${rowData.id}`}
                    content={`${prefixTooltip} em ${dataToStr(rowData.vencimento, "dd/MM/yyyy")}`}
                    position="top"
                />
                <span className={`custom-target-prazo${rowData.id}`}>{prazo}</span>
            </>
        );
    };

    const qtdOcorrenciasBodyTemplate = (rowData) => {
        let style = { cursor: "pointer" };
        let severity = "info";
        if (rowData.situacao === "A") style.background = "rgb(96, 125, 139)";
        if (rowData.situacao === "F") severity = "success";
        if (rowData.situacao === "C") severity = "danger";
        const qtdOcorrencias = parseNumber(rowData.numero_ocorrencias);
        return (
            <>
                <Tooltip
                    target=".custom-target-icon"
                    content="Clique para incluir ou visualizar as ocorrências"
                    position="top"
                />
                <Tag
                    value={qtdOcorrencias}
                    style={style}
                    severity={severity}
                    onClick={() => modalOcorrenciaRef.current?.abrirModal(rowData)}
                    className="custom-target-icon"
                />
            </>
        );
    };

    const itensMenuAcoes = [
        {
            label: "Imprimir pedido",
            disabled: !verifyPermission([permissoes.RELATORIOS_EMITIR_VENDAS_ASSISTENCIATECNICA]),
            command: gerarFicha,
        },
        {
            label: "Lançar ocorrência",
            // disabled: !verifyPermission([permissoes.VENDAS_VENDA_ASSISTENCIATECNICA_LANCAROCORRENCIA]),
            disabled: true,
            command: () => {},
        },
        {
            label: "Imprimir ocorrências",
            // disabled: !verifyPermission([permissoes.VENDAS_VENDA_ASSISTENCIATECNICA_IMPRIMIR]),
            disabled: true,
            command: () => {},
        },
        {
            label: "Lançar peças",
            // disabled: !verifyPermission([permissoes.VENDAS_VENDA_ASSISTENCIATECNICA_LANCARPECAS]),
            disabled: true,
            command: () => {},
        },
        {
            label: "Cancelar pedido",
            // disabled: !verifyPermission([permissoes.VENDAS_VENDA_ASSISTENCIATECNICA_CANCELAR]),
            disabled: true,
            command: () => {},
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_ASSISTENCIATECNICA_EDITAR]}
                    componente={Button}
                    disabled={rowData.situacao !== "A"}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => history.push({ pathname: URL_FORM, state: rowData })}
                />
                <Button
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    icon={MAKO_ICONS.OPCOES}
                    onClick={(event) => {
                        menuAcoesRef.current?.toggle(event);
                        setAssistencia(rowData);
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.OUTROS_IMPRESSORATERMICA_INCLUIR]}
                componente={Button}
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push(URL_FORM)}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Pedido", filter: true, filterElement: CodigoFiltroTemplate, style: { width: "8%" } },
        {
            field: "venda",
            header: "Orçamento",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            style: { width: "8%" },
        },
        { field: "cliente.nome", header: "Cliente" },
        { field: "sku_assistencia.codigo", header: "Código", style: { width: "10%" } },
        { field: "sku_assistencia.descricao_derivada", header: "Desc. mercadoria" },
        {
            field: "datahora_solicitacao",
            header: "Abertura",
            dateFormat: "dd/MM/yyyy",
            style: { width: "10%" },
        },
        { field: "prazo", header: "Prazo", style: { width: "6%" }, action: prazoBodyTemplate },
        { field: "ocorrencias", header: "Ocorr", style: { width: "6%" }, action: qtdOcorrenciasBodyTemplate },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const rowClass = (rowData) => {
        const dias = differenceInDays(parseData(rowData.vencimento), new Date());
        const overdue = rowData.situacao === "C" || (rowData.situacao === "A" && dias < 0);
        return {
            "table-recebimentos-effective": rowData.situacao === "F",
            "table-recebimentos-overdue": overdue,
        };
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Assistências técnicas"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={BASE_URL_API}
                // filtros={BASE_FILTROS_CABECALHO}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    rowClassName: rowClass,
                }}
            />
            <Menu model={itensMenuAcoes} popup ref={menuAcoesRef} id="popup_menu_acoes" />
            <ModalOcorrenciaAssistenciaTecnica
                ref={modalOcorrenciaRef}
                onFechar={() => listagemRef.current?.buscarDados()}
            />
        </PageBase>
    );
};
