import React, { useRef } from "react";
import { Button } from "primereact/button";

import { Delete } from "../Delete";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const BotaoDelete = ({
    url,
    objetoId,
    disabled,
    exigeConfirmacao,
    msgConfirmacao,
    onDelete,
    msgToastErroExclusao,
    msgErro400,
    tooltip,
    tooltipOptions,
    classNames,
    hideLoad,
    label,
    rounded = true,
    danger = true,
}) => {
    const deleteRef = useRef(null);

    return (
        <>
            <Button
                type="button"
                icon={MAKO_ICONS.DELETE}
                label={label}
                disabled={disabled}
                tooltip={tooltip}
                tooltipOptions={tooltipOptions}
                className={`${rounded && "p-button-rounded"} ${danger && "p-button-danger"} ${classNames}`}
                onClick={() => deleteRef.current?.handleClick()}
            />
            <Delete
                ref={deleteRef}
                url={url}
                objetoId={objetoId}
                exigeConfirmacao={exigeConfirmacao}
                msgConfirmacao={msgConfirmacao}
                onDelete={onDelete}
                msgErro400={msgErro400}
                msgToastErroExclusao={msgToastErroExclusao}
                hideLoad={hideLoad}
            />
        </>
    );
};
