import React, { useRef } from "react";

import { PageBase } from "@/components/PageBase";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import {
    ContaFinanceiraFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";

export const TemplateImportacaoExtratoPage = (props) => {
    const listagemRef = useRef(null);

    const header = (
        <MakoControleAcesso
            permissao={[permissoes.FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_INCLUIR]}
            componente={Button}
            label="Nova"
            icon={MAKO_ICONS.NOVO}
            className="p-button-success"
            to={"/financeiro/cadastros/templates-importacao-extratos/form"}
        />
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    tooltip="Alterar template de importação de extrato"
                    tooltipOptions={{ position: "left" }}
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/financeiro/cadastros/templates-importacao-extratos/form",
                        state: rowData,
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/financeiro/templates-importacao-extratos/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a template de importação de extrato <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A template de importação de extrato não pode ser excluído."
                    tooltip="Deletar template de importação de extrato"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const STATUS = [
        { value: true, label: "Ativo", tag: "success" },
        { value: false, label: "Desativado", tag: "danger" },
    ];

    const statusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: STATUS,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "conta_financeira.descricao",
            header: "Conta financeira",
            style: { width: "25%" },
            filter: true,
            filterElement: ContaFinanceiraFiltroTemplate,
            filterField: "conta_financeira",
        },
        {
            field: "extensao_arquivo",
            header: "Extensão arquivo",
            style: { width: "10%" },
            filter: true,
            action: ({ extensao_arquivo }) => extensao_arquivo?.toUpperCase(),
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Ativo",
            style: { width: "8%" },
            align: "center",
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo),
            filter: true,
            filterElement: statusFiltroTemplate,
        },
        { field: "actions", header: "Ações", style: { width: "8%" }, action: actionBodyTemplate },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Template de importação de extrato"
                urlPesquisa="/financeiro/templates-importacao-extratos/"
                colunas={colunas}
                painelEsquerdo={header}
                configTabela={{ paginator: true, lazy: true }}
                filtros={{
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    conta_financeira: { value: null, matchMode: "equals" },
                    extensao_arquivo: { value: null, matchMode: "equals" },
                    ativo: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
