import React, { useCallback, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { MakoTime } from "@/components/MakoTime";
import classNames from "classnames";
import * as Yup from "yup";
import { horaToStr } from "@/assets/util/datas";
import { Checkbox } from "primereact/checkbox";
import { AutoComplete } from "primereact/autocomplete";
import { InputNumber } from "primereact/inputnumber";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Label } from "@/components/Label";
import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const urlvoltar = "/vendas/cadastros/rota-entrega";

export const RotaEntregaForm = (props) => {
    const [filtered, setFiltered] = useState([]);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [loadingRota, showLoadingRota, hideLoadingRota] = useLoadingLocal();
    const { showSuccess, showWarning } = useToast();
    const { httpGet, httpPatch, httpPost } = useHttp();

    const history = useHistory();
    const rotaVazia = {
        descricao: "",
        hora_inicial: null,
        hora_final: null,
        valor_frete: 0,
        municipios: null,
        segunda: false,
        terca: false,
        quarta: false,
        quinta: false,
        sexta: false,
        sabado: false,
        domingo: false,
    };

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state ? props.location.state.rotas : rotaVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                hora_inicial: Yup.date().required("O campo 'hora inicial' é obrigatório."),
                hora_final: Yup.date().required("O campo 'hora final' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            values.hora_inicial = horaToStr(values.hora_inicial, "HH:mm");
            values.hora_final = horaToStr(values.hora_final, "HH:mm");
            const inicio = values.hora_inicial.split(":"),
                fim = values.hora_final.split(":");
            if (parseInt(inicio[0] + inicio[1]) > parseInt(fim[0] + fim[1])) {
                showWarning({
                    summary: "Horários inválidos",
                    detail: "O horário inicial deve ser menor que o final!",
                    life: 4000,
                });
            } else {
                if (!values.id) {
                    let lista_municipios = [];
                    values.municipios.forEach((cidade) => {
                        lista_municipios.push(cidade.id);
                    });
                    const handlers = {
                        201: () => {
                            showSuccess({
                                summary: "Sucesso",
                                detail: "Rota cadastrada com sucesso!",
                                life: 1500,
                            });
                            formik.resetForm();
                            history.push(urlvoltar);
                        },
                    };
                    showLoading();
                    await httpPost(
                        {
                            url: `/vendas/rotas-entrega/`,
                            body: {
                                ...values,
                                municipios: lista_municipios,
                            },
                        },
                        handlers
                    );
                    hideLoading();
                } else {
                    let lista_municipios = [];
                    values.municipios.forEach((cidade) => {
                        lista_municipios.push(cidade.id);
                    });
                    const handlers = {
                        201: () => {
                            showSuccess({
                                summary: "Sucesso",
                                detail: "Rota alterada com sucesso!",
                                life: 1500,
                            });
                            formik.resetForm();
                            history.push(urlvoltar);
                        },
                    };
                    showLoading();
                    await httpPatch(
                        {
                            url: `/vendas/rotas-entrega/${values.id}/`,
                            body: {
                                ...values,
                                municipios: lista_municipios,
                            },
                        },
                        handlers
                    );
                    hideLoading();
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    async function pesquisaRota(event, setTotalRegistros) {
        let _filtered = [];
        if (event.query?.length >= 3) {
            const handlers = {
                200: ({ data }) => {
                    const { count, results } = data;
                    if (setTotalRegistros) setTotalRegistros(count);
                    if (results.length > 0) _filtered = results;
                },
            };
            showLoadingRota(true);
            await httpGet({ url: `/pessoas/cidades?search=` + event.query }, handlers);
            hideLoadingRota(false);
        }
        setFiltered(_filtered);
    }

    const autoCompleteTemplate = (cidade) => {
        cidade.label = `${cidade.nome} - ${cidade.estado.uf}`;
        return `${cidade.nome} - ${cidade.estado.uf}`;
    };

    const carregaCidades = useCallback(async () => {
        let lista_municipios = [];
        await formik.values.municipios.forEach(async (cidade) => {
            if (typeof cidade === "number") {
                const handlers = {
                    200: ({ data }) => {
                        lista_municipios.push({
                            id: data.id,
                            nome: data.nome,
                            label: `${data.nome} - ${data.estado?.uf}`,
                            estado: data.estado,
                        });
                    },
                };
                await httpGet({ url: `/pessoas/cidades/${cidade}/` }, handlers);
            }
        });
        setFieldValue("municipios", lista_municipios);
    }, [setFieldValue, httpGet]);

    useEffect(() => {
        if (formik.values.id) carregaCidades();
    }, [carregaCidades, formik.values.id]);

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova Rota de Entrega" : "Manutenção de Rota de Entrega"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="hora_inicial" label="Hora inicial" obrigatorio />
                        <MakoTime
                            id="hora_inicial"
                            name="hora_inicial"
                            valueTime={formik.values.hora_inicial}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="hora_final" label="Hora final" obrigatorio />
                        <MakoTime
                            id="hora_final"
                            name="hora_final"
                            valueTime={formik.values.hora_final}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="valor_frete" label="Valor frete" />
                        <InputNumber
                            id="valor_frete"
                            name="valor_frete"
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                            min={0}
                            value={formik.values.valor_frete}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_frete })}
                        />
                        {formik.errors.valor_frete && <small className="p-error">{formik.errors.valor_frete}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-12">
                        <br></br>
                        <Checkbox
                            inputId="segunda"
                            id="segunda"
                            name="segunda"
                            onChange={formik.handleChange}
                            checked={formik.values.segunda}
                            value={formik.values.segunda}
                        />
                        <Label htmlFor="segunda" label="Segunda-feira" />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-12">
                        <br></br>
                        <Checkbox
                            inputId="terca"
                            id="terca"
                            name="terca"
                            onChange={formik.handleChange}
                            checked={formik.values.terca}
                            value={formik.values.terca}
                        />
                        <Label htmlFor="terca" label="Terça-feira" />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-12">
                        <br></br>
                        <Checkbox
                            inputId="quarta"
                            id="quarta"
                            name="quarta"
                            onChange={formik.handleChange}
                            checked={formik.values.quarta}
                            value={formik.values.quarta}
                        />
                        <Label htmlFor="quarta" label="Quarta-feira" />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-12">
                        <br></br>
                        <Checkbox
                            inputId="quinta"
                            id="quinta"
                            name="quinta"
                            onChange={formik.handleChange}
                            checked={formik.values.quinta}
                            value={formik.values.quinta}
                        />
                        <Label htmlFor="quinta" label="Quinta-feira" />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-12">
                        <br></br>
                        <Checkbox
                            inputId="sexta"
                            id="sexta"
                            name="sexta"
                            onChange={formik.handleChange}
                            checked={formik.values.sexta}
                            value={formik.values.sexta}
                        />
                        <Label htmlFor="sexta" label="Sexta-feira" />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-12">
                        <br></br>
                        <Checkbox
                            inputId="sabado"
                            id="sabado"
                            name="sabado"
                            onChange={formik.handleChange}
                            checked={formik.values.sabado}
                            value={formik.values.sabado}
                        />
                        <Label htmlFor="sabado" label="Sábado" />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-12">
                        <br></br>
                        <Checkbox
                            inputId="domingo"
                            id="domingo"
                            name="domingo"
                            onChange={formik.handleChange}
                            checked={formik.values.domingo}
                            value={formik.values.domingo}
                        />
                        <Label htmlFor="domingo" label="Domingo" />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="domingo" label="Municípios" />
                        <AutoComplete
                            value={formik.values.municipios}
                            suggestions={filtered}
                            completeMethod={pesquisaRota}
                            field="label"
                            itemTemplate={autoCompleteTemplate}
                            placeholder="Comece a digitar para buscar... (min 3 caracteres)"
                            multiple
                            disabled={loadingRota}
                            onChange={(e) => setFieldValue("municipios", e.value)}
                            dropdownAriaLabel="Selecione um ou mais municípios"
                            className={classNames({ "p-invalid": formik.errors.municipios })}
                        />
                        {formik.errors.municipios && <small className="p-error">{formik.errors.municipios}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="reset"
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
