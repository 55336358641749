import React, { useImperativeHandle, forwardRef } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";

const Component = ({ successCallback = () => {} }, ref) => {
    const { resetForm, ...formik } = useFormik({
        initialValues: {
            codigo_barras: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo_barras: Yup.string()
                    .required("O campo 'código de barras' é obrigatório.")
                    .min(47, "Esse campo não pode possui MENOS que 47 caracteres.")
                    .max(47, "Esse campo não pode possui MAIS que 47 caracteres."),
            });
            const dadosValidos = await formSchema.validate(values, { abortEarly: false });
            successCallback(dadosValidos.codigo_barras);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    useImperativeHandle(ref, () => ({ resetForm, handleSubmit: formik.handleSubmit }));

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <label htmlFor="codigo-barras">Código de barras</label>
                    <InputText
                        id="codigo-barras"
                        name="codigo_barras"
                        value={formik.values.codigo_barras}
                        autoComplete="off"
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.codigo_barras })}
                    />
                    {formik.errors.codigo_barras && <small className="p-error">{formik.errors.codigo_barras}</small>}
                </div>
            </div>
        </form>
    );
};

export const LerCodigoBarras = forwardRef(Component);
