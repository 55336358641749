import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import { PageBase } from "@/components/PageBase";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import MakoListagem from "@/components/MakoListagem";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "@/components/Dropdown";
import { Button } from "primereact/button";
import useToast from "@/hooks/useToast";
import { parseData } from "@/assets/util/datas";
import { isAfter } from "date-fns";
import { calcularJurosComposto } from "@/assets/util/calcFinanceiros";
import { formatarCasasDecimais } from "@/assets/util/util";
import * as Yup from "yup";

const BASE_URL =
    "/produtos/sku/?query={id,codigo,unidade_padrao,modelo,descricao_reduzida}&exibir_precos=true&ativo=true";

export const ConsultaPrecosPage = () => {
    const [planos, setPlanos] = useState([]);
    const [plano, setPlano] = useState(null);
    const [url, setUrl] = useState(BASE_URL);
    const [parcelas, setParcelas] = useState([]);
    const [parcela, setParcela] = useState(null);
    const [message, setMessage] = useState(null);
    const { showError } = useToast();

    const colunas = [
        {
            field: "codigo",
            header: "COD",
            style: { width: "8%" },
        },
        {
            field: "descricao_reduzida",
            header: "Descrição",
            style: { width: "25%" },
        },
        {
            field: "unidade_padrao.sigla",
            header: "UN",
            style: { width: "5%" },
        },
        {
            field: "preco.preco_base",
            header: "Preço a Vista",
            money: true,
            align: "right",
        },
        {
            field: "valor_calculado",
            header: "Preço Plano",
            money: true,
            align: "right",
        },
        {
            field: "parcelamento",
            header: "Parcelamento",
            align: "right",
        },
    ];

    const { setValues, ...formik } = useFormik({
        initialValues: {
            codigo: null,
            categcategoriasku__categoria_idoriasku__id: null,
            descricao_reduzida: null,
            item__marca: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().nullable().typeError("Informe um 'código' válido"),
                categoriasku__categoria_id: Yup.number().nullable().typeError("Informe uma 'categoria' válida."),
                descricao_reduzida: Yup.string().nullable().typeError("Informe uma 'descrição' válida"),
                item__marca: Yup.object().nullable().typeError("Informe uma 'marca' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (values.item__marca !== null) values.item__marca = values.item__marca.id;

            const filtersType = {
                codigo: "codigo__contains",
                categoriasku__categoria_id: "categoriasku__categoria_id",
                descricao_reduzida: "descricao_reduzida__icontains",
                item__marca: "item__marca",
            };

            const filters = [];

            Object.keys(values).forEach((key) => {
                if (values[key] != null) filters.push(`${filtersType[key]}=${values[key]}`);
            });

            if (plano === null || parcela === null)
                setMessage("Selecione um plano de recebimento é a quantidade de parcelas");
            else setUrl(`${url}&${filters.join("&")}`);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                const { message } = error;
                showError({
                    summary: "Erro :(",
                    detail: message || "Desculpe, não conseguimos processar sua requisição.",
                    life: 3000,
                });
            }
        }
    }

    const gerarParcelas = useCallback(() => {
        if (typeof plano === "number") {
            const _plano = planos.find((item) => item.id === plano);
            const _parcelas = [];

            for (let i = _plano.minimo_parcelas; i <= _plano.maximo_parcelas; i++) {
                _parcelas.push({ value: i, label: `${i} parcela${i > 1 ? "s" : ""}` });
            }
            if (_parcelas.length === 1) setParcela(_parcelas[0].value);
            setParcelas(_parcelas);
        }
    }, [plano, planos]);

    const aposPesquisar = useCallback(
        (data) => {
            let skusValidos = data.filter((item) => item.precosku_set.length > 0);
            if (skusValidos.length > 0) {
                const _plano = planos.find((item) => item.id === plano);
                const dataFilter = parseData(_plano.vigencia_inicial);
                skusValidos = skusValidos.map((sku) => {
                    sku.preco = sku.precosku_set.find((p) => isAfter(dataFilter, parseData(p.vigencia.data_inicio)));
                    if (sku.preco instanceof Object) {
                        sku.valor_calculado = formatarCasasDecimais(
                            calcularJurosComposto(sku.preco.preco_base, _plano.taxa_alvo, parcela ? parcela : 0),
                            4
                        );
                        sku.parcelamento = `${parcela ? parcela : 0} x ${formatarCasasDecimais(
                            sku.valor_calculado / parcela,
                            2
                        )}`;
                    } else {
                        sku.valor_calculado = 0;
                        sku.parcelamento = "Sem preco vigente";
                    }
                    return sku;
                });
            }
            return skusValidos;
        },
        [parcela, plano, planos]
    );

    useEffect(() => {
        gerarParcelas();
    }, [gerarParcelas]);

    return (
        <PageBase>
            <h3>Consultar preços</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="codigo">Código</label>
                        <InputText
                            id="codigo"
                            name="codigo"
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}{" "}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="categoriasku__categoria_id">Categoria</label>
                        <MakoDropdownCategoriasHierarquicas
                            id="categoriasku__categoria_id"
                            name="categoriasku__categoria_id"
                            categoriaTituloSelecionavel
                            onChange={formik.handleChange}
                            showClear
                            value={formik.values.categoriasku__categoria_id}
                            className={classNames({ "p-invalid": formik.errors.categoriasku__categoria_id })}
                        />
                        {formik.errors.categoriasku__categoria_id && (
                            <small className="p-error">{formik.errors.categoriasku__categoria_id}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="descricao_reduzida">Descrição</label>
                        <InputText
                            id="descricao_reduzida"
                            name="descricao_reduzida"
                            value={formik.values.descricao_reduzida}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.descricao_reduzida && (
                            <small className="p-error">{formik.errors.descricao_reduzida}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="item__marca">Marca</label>
                        <MakoAutoComplete
                            id="item__marca"
                            name="item__marca"
                            placeholder="Digite para pesquisar... (min 3 caracteres)"
                            minCaracteresBusca={3}
                            key="id"
                            field="nome"
                            urlSearch="/produtos/marcas/?search="
                            value={formik.values.item__marca}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.item__marca })}
                        />
                        {formik.errors.item__marca && <small className="p-error">{formik.errors.item__marca}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="plano_recebimento">Plano de recebimento</label>
                        <Dropdown
                            id="plano_recebimento"
                            name="plano_recebimento"
                            value={plano}
                            url="/financeiro/planos-recebimentos?query={id,descricao,minimo_parcelas,maximo_parcelas,taxa_alvo,vigencia_inicial,vigencia_final}&limit=300"
                            onChange={(e) => setPlano(e.target.value)}
                            setObjects={setPlanos}
                            optionLabel="descricao"
                            optionValue="id"
                            placeholder="Selecione um plano de recebimento"
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="categoria">Parcelas</label>
                        <Dropdown
                            id="parcelas"
                            name="parcelas"
                            optionValue="value"
                            optionLabel="label"
                            value={parcela}
                            options={parcelas}
                            onChange={(e) => setParcela(e.target.value)}
                            placeholder="Selecione parcelas"
                        />
                    </div>
                </div>
                {message && <small className="p-error">{message}</small>}
                <div className="p-grid p-col-12 p-md-6 p-mt-2">
                    <Button
                        label="Pesquisar"
                        icon="pi pi-search"
                        type="button"
                        onClick={formik.handleSubmit}
                        className="p-button-info p-mr-2"
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={() => formik.resetForm()}
                        className="p-button-warning p-mr-2"
                    />
                </div>
            </form>
            <MakoListagem
                titulo="Preços calculados"
                colunas={colunas}
                urlPesquisa={url}
                fazerBusca={!!plano && !!parcela}
                aposPesquisar={aposPesquisar}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
