import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import useHttp from "@/hooks/useHttp";

export const EditarHistoricoModalForm = ({
    movimentacao,
    esconderDialog,
    toast,
    listagem,
    editarHistorico = false,
}) => {
    const { showLoading, hideLoading } = useLoading();
    const { httpPatch } = useHttp();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            historico: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                historico: Yup.string().max(60).required("O campo 'histórico' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let success = false;
            const handlers = {
                200: () => {
                    success = true;
                    toast.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Histórico alterado com sucesso!",
                        life: 2500,
                    });
                    listagem.current?.buscarDados();
                    esconderDialog(false);
                },
            };

            showLoading();
            await httpPatch(
                {
                    url: `/financeiro/movimentacao-contas-corrente/${movimentacao.id}/`,
                    body: values,
                },
                handlers
            );
            hideLoading();

            if (!success) {
                toast.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 2000,
                });
            }
        } catch (error) {
            toast.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    }

    useEffect(() => {
        setFieldValue("historico", movimentacao?.historico);
    }, [setFieldValue, movimentacao?.historico]);

    return (
        <Dialog
            header={`Editar Histórico - Lançamento N.º ${movimentacao?.id}`}
            visible={editarHistorico}
            breakpoints={{ "960px": "80vw" }}
            style={{ width: "48vw" }}
            onHide={() => esconderDialog()}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <InputText
                            id="historico"
                            name="historico"
                            value={formik.values.historico}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.historico })}
                        />
                        {formik.errors.historico && <small className="p-error">{formik.errors.historico}</small>}
                    </div>
                </div>
                <div className="p-grid p-justify-end p-mt-1 p-mr-1">
                    <Button
                        className="p-button-danger p-button-text"
                        icon="pi pi-times"
                        type="reset"
                        label="Fechar"
                        onClick={() => esconderDialog()}
                    />
                    <Button className="p-button-success" icon="pi pi-save" type="submit" label="Gravar" />
                </div>
            </form>
        </Dialog>
    );
};
