import React from "react";
import { Button } from "primereact/button";

import { PageBase } from "@/components/PageBase";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { STATUS_LOTE_BOLETO } from "@/assets/constants/financeiro";
import permissoes from "@/assets/constants/permissoes";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

export const LotesBoletosPage = () => {
    const statusBodyTemplate = (rowData) => {
        const status = STATUS_LOTE_BOLETO.find((el) => el.value === rowData.status);
        if (status) return <span>{status.label}</span>;
        return <span>N/A</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_BANCO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    // onClick={() =>
                    //     setRedirecionar({
                    //         pathname: "/financeiro/cadastros/bancos/form",
                    //         state: { bancos: rowData },
                    //     })
                    // }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_BANCO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    // onClick={() => confirmarDelete(rowData)}
                />
            </div>
        );
    };

    const statusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: STATUS_LOTE_BOLETO,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "data_geracao",
            header: "Data de geração",
            dateFormat: "dd/MM/yyyy",
            style: { width: "12%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "data_envio",
            header: "Data de envio",
            dateFormat: "dd/MM/yyyy",
            style: { width: "12%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "status",
            header: "Situação",
            style: { width: "10%" },
            action: statusBodyTemplate,
            filter: true,
            filterElement: statusFiltroTemplate,
        },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: actionBodyTemplate },
    ];

    return (
        <PageBase>
            <MakoListagem
                titulo="Lotes de boletos bancários"
                urlPesquisa="/financeiro/lotes-boletos-bancarios/"
                colunas={colunas}
                configTabela={{ paginator: true, lazy: true }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    data_geracao: { value: null, matchMode: "equals" },
                    data_envio: { value: null, matchMode: "equals" },
                    status: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
