import React, { useMemo } from "react";
import { Dropdown } from "@/components/Dropdown";
import { formaRecebimentoPossuiIcone, gerarIconeFormaRecebimento } from "@/assets/helpers/gerarIconeFormaRecebimento";

const BASE_FILTROS = {
    query: "{id,descricao,escopo,tipo}",
    ativo: true,
    limit: 50,
};

const BASE_URL = "/financeiro/formas-recebimentos/";

export const MakoDropdownFormasRecebimentos = ({ filtros = {}, showIcon = false, ...props }) => {
    const itemTemplate = ({ id, descricao, tipo }) => {
        if (!showIcon) return <div>{descricao}</div>;
        if (!formaRecebimentoPossuiIcone(tipo?.id)) return <div>{descricao}</div>;
        const iconProps = {
            style: { fontSize: "inherit" },
            className: "p-pr-2",
        };
        const icon = gerarIconeFormaRecebimento({ tipo: tipo.id, iconProps });
        return (
            <div className="p-d-flex  p-justify-between">
                <span>{descricao}</span>
                {icon}
            </div>
        );
    };

    const url = useMemo(() => {
        let _params = { ...BASE_FILTROS, ...filtros };
        _params = Object.keys(_params)
            .map((k) => `${k}=${_params[k]}`)
            .join("&");
        return `${BASE_URL}?${_params}`;
    }, [filtros]);

    return <Dropdown url={url} optionValue="id" optionLabel="descricao" itemTemplate={itemTemplate} {...props} />;
};
