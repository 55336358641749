import React from "react";

import { Lancar } from "../features/Lancar";

import useRecebimento from "@/hooks/useRecebimento";

export const RecebimentoParcelasForm = () => {
    const {
        submitParcelas,
        recebimento,
        recebimentos,
        handleLancarParcelas,
        handleRemoverParcela,
        handleEditarRecebimento,
    } = useRecebimento();

    return (
        <Lancar.Parcelas
            parcela={recebimento}
            parcelas={recebimentos}
            handleLancar={handleLancarParcelas}
            handleEditar={handleEditarRecebimento}
            handleRemover={handleRemoverParcela}
            submit={submitParcelas}
        />
    );
};
