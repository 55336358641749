import React, { useState, useRef } from "react";

import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoCalendar } from "@/components/MakoCalendar";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Dropdown } from "@/components/Dropdown";
import { PageBase } from "@/components/PageBase";
import { GerarPdf } from "@/components/MakoBaseRelatorio/Buttons/gerarPdf";
import MakoListagem from "@/components/MakoListagem";

import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { TIPO_STATUS_ORDEM_COMPRA_CHOICE } from "@/assets/constants/constants";
import { RELATORIO_COMPRAS_ORDEMCOMPRA } from "@/assets/constants/relatorios";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { dataToStr } from "@/assets/util/datas";
import permissoes from "@/assets/constants/permissoes";

import useFetchRelatorio from "@/hooks/useFetchRelatorio";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useClearRefs from "@/hooks/useClearRefs";
import useRelatorio from "@/hooks/useRelatorio";
import useEmpresa from "@/hooks/useEmpresa";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

const url = "/compras/ordens-compra/";

const rowClassName = ({ status }) => {
    return {
        "table-ordemcompra-validada": status === "VA",
    };
};

export const OrdemCompraPage = () => {
    const [chaveRelatorio, setChaveRelatorio] = useState(RELATORIO_COMPRAS_ORDEMCOMPRA);
    const [ordemCompraSelecionada, setOrdemCompraSelecionada] = useState(null);
    const [email, setEmail] = useState(false);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { empresaSelecionadaId } = useEmpresa();
    const { solicitarRelatorio } = useRelatorio();
    const { verifyPermission } = useAuth();
    const { versao } = useFetchRelatorio({ chave: RELATORIO_COMPRAS_ORDEMCOMPRA });
    const { httpPut } = useHttp();

    const pdfRef = useRef({ emails: [], background: false, versao: "1" });
    const menuAcoesRef = useRef(null);
    const botaoPdfRef = useRef(null);
    const listagemRef = useRef(null);

    useClearRefs(menuAcoesRef, listagemRef);

    const handleChangePdf = (key, value) => {
        pdfRef.current[key] = value;
    };

    const gerarPdf = () => {
        const { emails, background, versao } = pdfRef.current;
        let filtros =
            versao === "2"
                ? { id: ordemCompraSelecionada.id, empresa: empresaSelecionadaId }
                : { id: ordemCompraSelecionada.id };
        solicitarRelatorio({
            emails,
            versao,
            chave: chaveRelatorio,
            enviar_fila: background,
            filtros,
        });
    };

    const validarOrdemCompra = async (ordemCompra) => {
        const handlers = {
            200: () => listagemRef.current?.buscarDados(),
        };
        showLoading();
        await httpPut({ url: `/compras/validar-ordem-compra/${ordemCompra.id}/` }, handlers);
        hideLoading();
    };

    const confirmarValidacaoOC = (value) => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma a validação da ordem de compra número <b>{value.id}</b>?
                </p>
            ),
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "Validar",
            accept: () => validarOrdemCompra(value),
            rejectLabel: "Cancelar",
        });
    };

    const cancelarOrdemCompra = async (ordemCompra) => {
        const handlers = {
            200: () => listagemRef.current?.buscarDados(),
        };
        showLoading();
        await httpPut({ url: `/compras/cancelar-ordem-compra/${ordemCompra.id}/` }, handlers);
        hideLoading();
    };

    const reabrirOrdemCompra = async (ordemCompra) => {
        const handlers = {
            200: () => listagemRef.current?.buscarDados(),
        };
        showLoading();
        await httpPut({ url: `/compras/ordens-compra/${ordemCompra.id}/reabrir/` }, handlers);
        hideLoading();
    };

    const confirmarCancelamentoOC = (value) => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma o cancelamento da ordem de compra número <b>{value.id}</b>?
                </p>
            ),
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "Confirmar",
            accept: () => cancelarOrdemCompra(value),
            rejectLabel: "Cancelar",
        });
    };

    const confirmarReaberturaOC = (value) => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma a reabertura da ordem de compra número <b>{value.id}</b>?
                </p>
            ),
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "Confirmar",
            accept: () => reabrirOrdemCompra(value),
            rejectLabel: "Cancelar",
        });
    };

    const enviarOrdemCompraPorEmail = async (emails, corpo_email) => {
        setEmail(false);
        solicitarRelatorio({
            emails,
            corpo_email,
            versao,
            chave: RELATORIO_COMPRAS_ORDEMCOMPRA,
            enviar_fila: true,
            filtros:
                versao === "2"
                    ? { id: ordemCompraSelecionada.id, empresa: empresaSelecionadaId }
                    : { id: ordemCompraSelecionada.id },
        });
    };

    const itensAcoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Validar",
                    icon: "pi pi-check",
                    disabled:
                        !verifyPermission([permissoes.COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_VALIDAR]) ||
                        ordemCompraSelecionada?.status !== "PE",
                    command: () => confirmarValidacaoOC(ordemCompraSelecionada),
                },
                {
                    label: "Cancelar",
                    icon: "pi pi-times",
                    disabled: !["PE", "BA"].includes(ordemCompraSelecionada?.status),
                    command: () => confirmarCancelamentoOC(ordemCompraSelecionada),
                },
                {
                    label: "Reabrir",
                    icon: "pi pi-unlock",
                    disabled:
                        !verifyPermission([permissoes.COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_REABRIR]) ||
                        ordemCompraSelecionada?.status !== "VA",
                    command: () => confirmarReaberturaOC(ordemCompraSelecionada),
                },
            ],
        },
        {
            label: "Emissão de documentos",
            items: [
                {
                    label: "PDF",
                    icon: "pi pi-file-pdf",
                    disabled: ordemCompraSelecionada?.status === "BA",
                    command: () => {
                        setChaveRelatorio(RELATORIO_COMPRAS_ORDEMCOMPRA);
                        botaoPdfRef.current?.show(true);
                    },
                },
                {
                    label: "Enviar PDF por email",
                    icon: "pi pi-envelope",
                    disabled: ordemCompraSelecionada?.status === "BA",
                    command: () => setEmail(true),
                },
            ],
        },
    ];

    const statusBodyTemplate = (rowData) => {
        const status = TIPO_STATUS_ORDEM_COMPRA_CHOICE.find((st) => st.id === rowData.status);
        return <span>{status?.label || "N/A"}</span>;
    };

    const formaPgtoBodyTemplate = (rowData) => {
        const formaPgto = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.find(
            (forma) => forma.id === rowData.forma_pagamento
        );
        return <span>{formaPgto?.label || "N/A"}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    disabled={loading}
                    to={{
                        pathname: "/compras/ordens-compra/ordens-compra/form",
                        state: { ordemCompraId: rowData.id },
                    }}
                />
                <Menu model={itensAcoes} popup ref={menuAcoesRef} id="popup_menu_acoes" />
                <Button
                    icon={MAKO_ICONS.OPCOES}
                    aria-haspopup
                    aria-controls="popup_menu_acoes"
                    className="p-button-rounded p-button-info"
                    onClick={(e) => {
                        setOrdemCompraSelecionada(rowData);
                        menuAcoesRef.current?.toggle(e);
                    }}
                    disabled={loading}
                />
                <MakoControleAcesso
                    permissao={[permissoes.COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_EXCLUIR]}
                    componente={BotaoDelete}
                    disabled={loading}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente excluir a ordem de compra?</span>}
                    msgToastErroExclusao="A ordem de compra não pode ser excluída."
                    msgErro400={'Apenas ordens de compra com o status "Pendente" podem ser excluídas.'}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_INCLUIR]}
                componente={Button}
                label="Nova"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success "
                to={"/compras/ordens-compra/ordens-compra/form"}
            />
        </>
    );

    const fornecedorFiltroTemplate = (options) => {
        if (options.value?.id) options.filterModel.value = options.value?.id;
        return (
            <MakoInputFornecedor
                value={options.value}
                showClear
                onChange={(e) => options.filterCallback(e.value, options.index)}
                className="p-column-filter"
            />
        );
    };

    const dataFiltroTemplate = (options) => {
        return (
            <MakoCalendar
                valueCalendar={options.value}
                onChange={(e) => options.filterCallback(dataToStr(e.value, "yyyy-MM-dd"))}
                className="p-column-filter"
            />
        );
    };

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                options={TIPO_STATUS_ORDEM_COMPRA_CHOICE}
                value={options.value}
                placeholder="Selecione"
                optionValue="id"
                optionLabel="label"
                showClear
                onChange={(e) => options.filterCallback(e.value, options.index)}
                className="p-column-filter"
            />
        );
    };

    const formaFiltroTemplate = (options) => {
        return (
            <Dropdown
                options={TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE}
                value={options.value}
                placeholder="Selecione"
                optionValue="id"
                optionLabel="label"
                showClear
                onChange={(e) => options.filterCallback(e.value, options.index)}
                className="p-column-filter"
            />
        );
    };

    const codigoFiltroTemplate = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.filterCallback(e.value)} />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            filter: true,
            filterElement: codigoFiltroTemplate,
            style: { width: "8%" },
        },
        {
            field: "fornecedor.nome",
            header: "Fornecedor",
            filter: true,
            filterField: "fornecedor",
            filterElement: (e) => fornecedorFiltroTemplate(e),
        },
        {
            field: "data_pedido",
            header: "Data Pedido",
            style: { width: "10%" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: (e) => dataFiltroTemplate(e),
        },
        {
            field: "status",
            header: "Situação",
            style: { width: "8%" },
            action: (e) => statusBodyTemplate(e),
            filter: true,
            filterElement: (e) => statusFiltroTemplate(e),
        },
        {
            field: "forma_pagamento",
            header: "Forma de pagamento",
            style: { width: "15%" },
            action: (e) => formaPgtoBodyTemplate(e),
            filter: true,
            filterElement: (e) => formaFiltroTemplate(e),
        },
        { field: "valor_total_ordem_compra", header: "Valor Total", style: { width: "10%" }, money: true },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "14%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Ordens de Compras"
                colunas={colunas}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`${url}?query={id,fornecedor,data_pedido,status,valor_total_ordem_compra,forma_pagamento}&status__in=PE,VA,PA,EN`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    rowClassName,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    fornecedor: { value: null, matchMode: "equals" },
                    data_pedido: { value: null, matchMode: "equals" },
                    status: { value: null, matchMode: "equals" },
                    forma_pagamento: { value: null, matchMode: "equals" },
                }}
            />
            <ConfirmDialog />
            <GerarPdf
                ref={botaoPdfRef}
                chave={chaveRelatorio}
                setFieldValue={handleChangePdf}
                handleSubmit={gerarPdf}
                showButton={false}
            />
            <Dialog
                header={`Enviar e-mail`}
                visible={email}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "60vw" }}
                onHide={() => setEmail(false)}
            >
                <MakoSelecionarEmails onCancel={() => setEmail(false)} onConfirm={enviarOrdemCompraPorEmail} />
            </Dialog>
        </PageBase>
    );
};
