import React from "react";

import { InputText } from "primereact/inputtext";

import { Label } from "@/components/Label";

import { formatDocumento } from "@/assets/util/util";

export const InfoConta = ({ conta = {} }) => {
    const { perfil, conta_financeira, banco } = conta;

    return (
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-5">
                <Label label="Loja / Unidade" />
                <InputText disabled value={`${perfil?.nome} - ${formatDocumento(perfil?.identificacao)}` || ""} />
            </div>
            <div className="p-field p-col-12 p-md-4">
                <Label label="Conta financeira" />
                <InputText disabled value={conta_financeira?.descricao || ""} />
            </div>
            <div className="p-field p-col-12 p-md-3">
                <Label label="Banco" />
                <InputText disabled value={banco?.descricao || ""} />
            </div>
        </div>
    );
};
