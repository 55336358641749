import React, { useCallback, useEffect, useReducer } from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoCardInfo } from "@/components/MakoCards";

import { parseMoeda } from "@/assets/util/util";
import permissoes from "@/assets/constants/permissoes";

import useEmpresa from "@/hooks/useEmpresa";
import useAuth from "@/hooks/useAuth";
import useHttp from "@/hooks/useHttp";

const initialCardsReducer = {
    loading: false,
    cards: [
        {
            title: "Vendas",
            levelOverviewClass: 1,
            permissao: [permissoes.DASHBOARD_INDICATIVO_VENDAS_CARDVENDASTABELA],
            icon: "pi pi-shopping-cart",
            content: {
                left: { value: 0, text: "Pendente" },
                right: { value: 0, text: "Completado" },
            },
        },
        {
            title: "Receita",
            levelOverviewClass: 2,
            permissao: [permissoes.DASHBOARD_INDICATIVO_FINANCEIRO_CARDRECEITAS],
            icon: "pi pi-dollar",
            content: {
                left: { value: parseMoeda(0), text: "Despesas" },
                right: { value: parseMoeda(0), text: "Renda" },
            },
        },
        {
            title: "Clientes",
            levelOverviewClass: 3,
            permissao: [permissoes.DASHBOARD_INDICATIVO_PESSOAS_CARDCLIENTES],
            icon: "pi pi-users",
            content: {
                left: { value: 0, text: "Ativo" },
                right: { value: 0, text: "Registrados" },
            },
        },
        {
            title: "Comentários",
            levelOverviewClass: 4,
            permissao: [permissoes.DASHBOARD],
            icon: "pi pi-comment",
            content: {
                left: { value: 12, text: "Novos" },
                right: { value: 85, text: "Respondidos" },
            },
        },
    ],
};

const ACTIONS = {
    SHOW_LOADING: "SHOWLOADING",
    HIDE_LOADING: "HIDELOADING",
    UPDATE_CARDS: "UPDATECARDS",
};

const cardsReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SHOW_LOADING:
            return { ...state, loading: true };
        case ACTIONS.HIDE_LOADING:
            return { ...state, loading: false };
        case ACTIONS.UPDATE_CARDS:
            return { ...state, cards: action.payload.value };
        default:
            return state;
    }
};

export const DashboardCardsContainer = () => {
    const [itemsCardsInfo, dispatchCards] = useReducer(cardsReducer, initialCardsReducer);
    const { empresaSelecionadaId } = useEmpresa();
    const { verifyPermission } = useAuth();
    const { httpGet } = useHttp();

    const fetchVendasTotalizado = useCallback(
        async (signal) => {
            let data = { error: true, item: null };
            if (
                verifyPermission(permissoes.DASHBOARD_INDICATIVO_VENDAS_CARDVENDASTOTALIZADOR) &&
                empresaSelecionadaId
            ) {
                const handlers = {
                    200: ({ data: d }) => {
                        data.error = false;
                        data.item = {
                            title: "Vendas",
                            levelOverviewClass: 1,
                            permissao: [permissoes.DASHBOARD_INDICATIVO_VENDAS_CARDVENDASTABELA],
                            icon: "pi pi-shopping-cart",
                            content: {
                                left: { value: d.valor_pendente, text: "Pendente" },
                                right: { value: d.valor_concluido, text: "Completado" },
                            },
                        };
                    },
                };
                await httpGet({ url: `/dashboard/vendas-totalizador/${empresaSelecionadaId}`, signal }, handlers);
            }
            return data;
        },
        [empresaSelecionadaId, verifyPermission, httpGet]
    );

    const fetchReceitas = useCallback(
        async (signal) => {
            let data = { error: true, item: null };
            if (verifyPermission(permissoes.DASHBOARD_INDICATIVO_FINANCEIRO_CARDRECEITAS)) {
                let response1, response2;
                const handlers1 = {
                    200: ({ data }) => (response1 = data.valor_total),
                };
                const handlers2 = {
                    200: ({ data }) => (response2 = data.valor_total),
                };
                await httpGet({ url: "/dashboard/pagamento-previsto/", signal }, handlers1);
                await httpGet({ url: "/dashboard/recebimento-previsto/", signal }, handlers2);
                if (response1 !== null && response2 !== null) {
                    data.error = false;
                    data.item = {
                        title: "Receita",
                        levelOverviewClass: 2,
                        permissao: [permissoes.DASHBOARD_INDICATIVO_FINANCEIRO_CARDRECEITAS],
                        icon: "pi pi-dollar",
                        content: {
                            left: { value: parseMoeda(response1), text: "Despesas" },
                            right: { value: parseMoeda(response2), text: "Renda" },
                        },
                    };
                }
            }
            return data;
        },
        [verifyPermission, httpGet]
    );

    const fetchClientes = useCallback(
        async (signal) => {
            let data = { error: true, item: null };
            if (verifyPermission(permissoes.DASHBOARD_INDICATIVO_PESSOAS_CARDCLIENTES)) {
                const handlers = {
                    200: ({ data: d }) => {
                        const { ativos, total } = d;
                        data.error = false;
                        data.item = {
                            title: "Clientes",
                            levelOverviewClass: 3,
                            permissao: [permissoes.DASHBOARD_INDICATIVO_PESSOAS_CARDCLIENTES],
                            icon: "pi pi-users",
                            content: {
                                left: { value: ativos, text: "Ativo" },
                                right: { value: total, text: "Registrados" },
                            },
                        };
                    },
                };
                const url = empresaSelecionadaId
                    ? `/dashboard/clientes-totalizador/?empresa_id=${empresaSelecionadaId}`
                    : "/dashboard/clientes-totalizador/";
                await httpGet({ url, signal }, handlers);
            }
            return data;
        },
        [empresaSelecionadaId, verifyPermission, httpGet]
    );

    const fetchCardsInfo = useCallback(
        async (signal) => {
            let _data = [];
            dispatchCards({ type: ACTIONS.SHOW_LOADING });
            const response = await Promise.all([
                fetchVendasTotalizado(signal),
                fetchReceitas(signal),
                fetchClientes(signal),
            ]);
            dispatchCards({ type: ACTIONS.HIDE_LOADING });
            if (response instanceof Array) {
                response.forEach((res) => {
                    if (!res.error) _data.push(res.item);
                });
            }
            _data.push({
                title: "Comentários",
                levelOverviewClass: 4,
                permissao: [permissoes.DASHBOARD],
                icon: "pi pi-comment",
                content: {
                    left: { value: 12, text: "Novos" },
                    right: { value: 85, text: "Respondidos" },
                },
            });
            dispatchCards({ type: ACTIONS.UPDATE_CARDS, payload: { value: _data } });
        },
        [fetchVendasTotalizado, fetchReceitas, fetchClientes]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetchCardsInfo(controller.signal);
        return () => {
            controller.abort();
        };
    }, [fetchCardsInfo]);

    return (
        <>
            {itemsCardsInfo.cards.map((item) => (
                <div key={item.title} className="p-col-12 p-md-6 p-xl-3">
                    <MakoControleAcesso
                        componente={MakoCardInfo}
                        permissao={item.permissao}
                        title={item.title}
                        levelOverviewClass={item.levelOverviewClass}
                        icon={item.icon}
                        content={item.content}
                        loading={itemsCardsInfo.loading}
                    />
                </div>
            ))}
        </>
    );
};
