import { MakoDropdownFormasRecebimentos } from "@/components/MakoInputs/MakoDropdownFormasRecebimentos";

export const FormaRecebimentoFiltroTemplate = ({ dropdownProps, ...options }) => {
    return (
        <MakoDropdownFormasRecebimentos
            showIcon
            {...dropdownProps}
            value={options?.value}
            onChange={(e) => options?.filterCallback(e?.value, options?.index)}
            className={`p-column-filter ${dropdownProps?.className}`}
        />
    );
};
