import React, { useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { DateTimeFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import MakoListagem from "@/components/MakoListagem";
import useRelatorio from "@/hooks/useRelatorio";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";
import useClearRefs from "@/hooks/useClearRefs";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { Dropdown } from "@/components/Dropdown";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import { axiosPost } from "@/services/http";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";

export const RelatoriosEmitidosPage = () => {
    const { carregarRelatorio } = useRelatorio();
    const { axiosDelete } = useAxiosPrivate();
    const { showError, showSuccess } = useToast();
    const today = new Date();
    const [filtro, setFiltro] = useState("todos");
    const [relatorio, setRelatorio] = useState(null);
    const [dataRemocao, setDataRemocao] = useState(today.setDate(today.getDate() - 3));
    const [limpar, setLimpar] = useState(false);
    const [email, setEmail] = useState(false);
    const { user } = useAuth();
    const BASE_URL = `/relatorios/relatorios-emitidos/?usuario=${user.id}`;
    const [url, setUrl] = useState(BASE_URL);
    const listagemRef = useRef(null);
    const menu = useRef(null);

    useClearRefs(listagemRef);

    const handleEmail = async (emails, corpo) => {
        setEmail(false);
        const { status, data } = await axiosPost(`/relatorios/enviar-relatorio-emitido/`, {
            titulo: `${relatorio.nome} (SysMako)`,
            relatorio: relatorio.id,
            emails: emails,
            corpo: corpo,
        });

        if (status === 200) {
            showSuccess({
                summary: "Sucesso",
                detail: data.msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro",
                detail: data.msg,
                life: 3000,
            });
        }
    };

    const deletarRelatorio = async (relatorio) => {
        const resposta = await axiosDelete(`/relatorios/relatorios-emitidos/${relatorio.id}`);
        if (resposta.status === 204) {
            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    };

    const limparRelatoriosAntigos = async () => {
        const resposta = await axiosPost(`/relatorios/remover-relatorios/`, { data_remocao: dataRemocao });
        if (resposta.status === 204) {
            listagemRef.current?.buscarDados();
            setLimpar(false);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    };

    const confirmarDeleteRelatorio = () => {
        confirmDialog({
            message: "Confirma a exclusão do relatório?",
            header: "Confirmação",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Deletar",
            accept: () => deletarRelatorio(relatorio),
            rejectLabel: "Cancelar",
        });
    };

    const opcoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Excluir",
                    icon: "pi pi-trash",
                    command: () => confirmarDeleteRelatorio(),
                },
                {
                    label: "Enviar por e-mail",
                    icon: "pi pi-envelope",
                    command: () => setEmail(true),
                },
                {
                    label: "Download",
                    icon: "pi pi-download",
                    disabled: relatorio?.status !== "pronto",
                    command: async () => await carregarRelatorio(relatorio?.id, true),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-help p-mb-1 p-mr-2"
                    disabled={rowData.status !== "pronto"}
                    onClick={async () => await carregarRelatorio(rowData.id)}
                />
                <Button
                    model={opcoes}
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    icon="pi pi-cog"
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setRelatorio(rowData);
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "nome", header: "Nome do relatório", filter: true },
        {
            field: "data_hora",
            header: "Data / Hora da geração",
            dateFormat: "dd/MM/yyyy HH:mm:ss",
            filter: true,
            filterElement: DateTimeFiltroTemplate,
        },
        {
            field: "tempo_geracao",
            header: "Tempo de geração",
            style: { width: "10%" },
            action: ({ tempo_geracao }) => (tempo_geracao ? `${tempo_geracao}s` : "-"),
        },
        { field: "logs.msg", header: "Mensagem" },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const onFiltro = ({ value }) => {
        setFiltro(value);
        if (value === "todos") {
            setUrl(BASE_URL);
        } else setUrl(`${BASE_URL}&status=${value}`);
    };

    const painelEsquerdo = (
        <>
            <Button
                label="Limpar relatórios"
                icon="pi pi-trash"
                className="p-button-warning p-mr-2"
                onClick={() => setLimpar(true)}
            />
            <Dropdown
                style={{ width: "120px" }}
                value={filtro}
                options={[
                    {
                        value: "todos",
                        label: "Todos",
                    },
                    {
                        value: "pendente",
                        label: "Pendente",
                    },
                    {
                        value: "gerando",
                        label: "Gerando",
                    },
                    {
                        value: "pronto",
                        label: "Pronto",
                    },
                    {
                        value: "erro",
                        label: "Erro",
                    },
                ]}
                showClear={false}
                onChange={onFiltro}
            />
        </>
    );

    const rowClass = (rowData) => {
        return {
            "mako-table-status-inativo": rowData.status === "erro",
            "mako-table-status-normal": rowData.status === "pronto",
            "mako-table-status-pendente": rowData.status === "pendente",
        };
    };

    return (
        <div className="p-grid">
            <Menu model={opcoes} popup ref={menu} id="popup_menu" />
            <ConfirmDialog />
            <Dialog
                header={`Limpar relatórios`}
                visible={limpar}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "35vw", display: "block" }}
                onHide={() => setLimpar(false)}
            >
                <div className="p-fluid p-formgrid p-grid ">
                    <div className="p-field p-col-12 p-md-12">
                        <label>Selecione a data limite:</label>
                        <MakoCalendarTime
                            id="data-remocao"
                            name="data-remocao"
                            valueCalendar={dataRemocao}
                            onChange={(e) => setDataRemocao(e.value)}
                        />
                    </div>
                </div>
                <label style={{ color: "#FF6347" }}>
                    OBS: <b>TODOS</b> os relatórios com data retroativa a informada serão{" "}
                    <b>REMOVIDOS PERNAMENTEMENTE.</b>{" "}
                </label>
                <div className="p-grid p-justify-center p-mt-3 p-mr-1">
                    <Button
                        className="p-button-info"
                        type="button"
                        icon="pi pi-trash"
                        label="Deletar"
                        onClick={() => limparRelatoriosAntigos()}
                    />
                </div>
            </Dialog>
            <Dialog
                header={`Enviar e-mail`}
                visible={email}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "60vw" }}
                onHide={() => setEmail(false)}
            >
                <MakoSelecionarEmails onCancel={() => setEmail(false)} onConfirm={handleEmail} />
            </Dialog>

            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Relatórios emitidos"
                        painelEsquerdo={painelEsquerdo}
                        colunas={colunas}
                        urlPesquisa={url}
                        configTabela={{
                            lazy: true,
                            paginator: true,
                            rowClassName: rowClass,
                        }}
                        filtros={{
                            nome: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            data_hora: { value: null, matchMode: "equals" },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
