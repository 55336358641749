import React, { forwardRef, useImperativeHandle, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { MakoButton } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";
import { TIPO_HISTORICO_PADRAO } from "@/assets/constants/financeiro";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import * as Yup from "yup";

const Component = ({ onFinish = () => {}, tipo = null }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const { showSuccess, showError } = useToast();
    const { httpPost } = useHttp();

    const formik = useFormik({
        initialValues: {
            descricao: "",
            tipo: tipo,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo: Yup.string().required("O campo 'tipo' é obrigatório."),
                descricao: Yup.string().max(140).required("O campo 'descrição' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const handlers = {
                201: ({ data }) => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Histórico cadastrado com sucesso!",
                        life: 1500,
                    });
                    formik.resetForm();
                    if (typeof onFinish === "function") onFinish(data);
                },
                400: (err) => {
                    console.log(err);
                    showError();
                },
            };

            setLoading(true);
            await httpPost({ url: `/financeiro/historico-padrao/`, body: values }, handlers);
            setLoading(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
            showError();
            setLoading(false);
        }
    }

    useImperativeHandle(ref, () => ({ show: () => setVisible(true), hide: () => setVisible(false) }));

    return (
        <Dialog
            header="Adicionar histórico"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "60vw" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="descricao" obrigatorio label="Descrição" />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="tipo" label="Tipo de histórico" />
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            placeholder="Selecione um tipo"
                            options={TIPO_HISTORICO_PADRAO}
                            optionValue="value"
                            optionLabel="label"
                            disabled={tipo}
                            value={formik.values.tipo}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                </div>

                <p>
                    <b>* Campos obrigatórios.</b>
                </p>

                <div className="">
                    <MakoButton
                        label="Gravar"
                        type="submit"
                        className="p-button-info p-mr-2 p-mb-6"
                        loading={loading}
                    />
                    <MakoButton
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={() => setVisible(false)}
                        loading={loading}
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalHistoricoForm = forwardRef(Component);
