import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { Dropdown } from "../Dropdown";
import permissoes from "@/assets/constants/permissoes";
import useAuth from "@/hooks/useAuth";
import useEmpresa from "@/hooks/useEmpresa";
import useRelatorio from "@/hooks/useRelatorio";
import useImpressaoTermica from "@/hooks/useImpressaoTermica";

const Modal = ({ apenasTermicas = false }, ref) => {
    const [visible, setVisible] = useState(false);
    const [impressoraSelecionada, setImpressoraSelecionada] = useState(null);
    const [configImpressao, setConfigImpressao] = useState(null);
    const { verifyPermission } = useAuth();
    const { empresaSelecionadaId } = useEmpresa();
    const { solicitarRelatorio } = useRelatorio();
    const { solicitarImpressao } = useImpressaoTermica();

    async function imprimir() {
        if (!impressoraSelecionada) {
            await solicitarRelatorio(configImpressao);
            fecharModal();
            return;
        }
        await solicitarImpressao({ ...configImpressao, impressora: impressoraSelecionada });
        fecharModal();
    }

    const abrirModal = (config) => {
        setVisible(true);
        setConfigImpressao(config);
    };

    const fecharModal = () => {
        setVisible(false);
        setImpressoraSelecionada(null);
        setConfigImpressao(null);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const definirImpressoras = useCallback(
        (data) => {
            const impressoras = [...data];
            if (!apenasTermicas) impressoras.push({ id: 0, nome: "Gerar o PDF" });
            return impressoras;
        },
        [apenasTermicas]
    );

    const url = useMemo(() => {
        const imprimirLocal = verifyPermission([permissoes.RELATORIOS_IMPRESSORATERMICA_IMPRESSORA_LOCAL]);
        const imprimirQualquer = verifyPermission([permissoes.RELATORIOS_IMPRESSORATERMICA_IMPRESSORA_QUALQUER]);
        if (!imprimirLocal && !imprimirQualquer) return null;
        const urlBase = "/relatorios/impressora-termica/?ativo=True&id_externo__isnull=False";
        if (imprimirQualquer) return urlBase;
        if (imprimirLocal) return `${urlBase}&empresas__in=${empresaSelecionadaId}`;
        return `${urlBase}&id__lte=0`;
    }, [verifyPermission, empresaSelecionadaId]);

    const footer = () => {
        return (
            <Button
                type="button"
                icon="pi pi-print"
                label="Imprimir"
                disabled={[null, undefined].includes(impressoraSelecionada)}
                onClick={imprimir}
            />
        );
    };

    return (
        <Dialog
            header="Selecione a impressora"
            visible={visible}
            onHide={fecharModal}
            footer={footer}
            style={{ width: "20%" }}
        >
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <Dropdown
                        url={url}
                        aposBuscar={definirImpressoras}
                        optionValue="id"
                        optionLabel="nome"
                        value={impressoraSelecionada}
                        onChange={(e) => setImpressoraSelecionada(e.value)}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export const ModalImpressoras = forwardRef(Modal);
