import React from "react";
import { useHistory } from "react-router-dom";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import TabPessoaEstrangeira from "./tabPessoaEstrangeira";
import TabPessoaJuridica from "./tabPessoaJuridica";
import TabPessoaFisica from "./tabPessoaFisica";

export const TabImportarPessoas = () => {
    const history = useHistory();

    const TABS = [
        {
            header: "Pessoa Física",
            icon: "pi pi-fw pi-id-card",
            // visible: tipoForm === "Pessoa Física",
            component: <TabPessoaFisica />,
        },
        {
            header: "Pessoa Jurídica",
            icon: "pi pi-fw pi-id-card",
            // visible: tipoForm === "Pessoa Jurídica",
            component: <TabPessoaJuridica />,
        },
        {
            header: "Pessoa Estrangeira",
            icon: "pi pi-fw pi-id-card",
            // visible: tipoForm === "Pessoa Estrangeira",
            component: <TabPessoaEstrangeira />,
        },
    ];

    const voltarParaListagem = () => {
        history.goBack("/gestao/pessoas/pessoas");
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoMultiTabs
                        tabs={TABS}
                        title="Importação de pessoas"
                        ocultarButtonFinalizar
                        ocultarButtonCancelar
                        propsButtonProx={{ style: { display: "none" } }}
                        propsButtonAnt={{ style: { display: "none" } }}
                        // propsButtonFinalizar={{ disabled: !submit, onClick: persistirDadosPessoa }}
                        propsButtonCancelar={{ onClick: voltarParaListagem }}
                    />
                </div>
            </div>
        </div>
    );
};
